import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'accessLevel',
    pure: true,
    standalone: true
})
export class AccessLevelPipe implements  PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'externalbroker':
        return 'External Broker';
      case 'internalbroker':
        return 'Internal Broker';
      case 'companyadmin':
        return 'Company Admin';
      case 'analyst':
        return 'Finance';
      case 'admin':
        return 'Admin';
      case 'companyoperator':
        return 'Company Operator';
      case 'operations':
        return 'Operations';
      case 'operations24':
        return 'Operations24';
      case 'credit':
        return 'Credit';
      case 'settlement':
        return 'Settlement';
      case 'salesBDM':
        return 'SalesBDM';
      case 'salesAM':
        return 'SalesAM';
      case 'card-admin':
        return 'Card Admin';
      case 'card-member':
        return 'Card Member';
      default:
        return value;
    }
  }

}
