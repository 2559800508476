<div class="generate-payout-dialog m-4">
  <h4 mat-dialog-title>Generate Payout</h4>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-6">Payout Estimation</div>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <div class="col-6">Unpaid Facility Fee</div>
      <div class="col-6">{{unpaidFacilityFee | looseCurrency}}</div>
    </div>
    <div class="row">
      <div class="col-6"> Current Balance</div>
      <div class="col-6">{{outstandingBalance | looseCurrency}}</div>
    </div>
    <div class="row">
      <div class="col-6">Pending Interest</div>
      <div class="col-6">{{pendingInterest | looseCurrency}}</div>
    </div>

    <div class="row">
      <div class="col-6">Operation Adjustment (discount)</div>
      <div class="col-6">
        <!-- <mat-form-field>
        <mat-label>Operation Adjustment (discount)</mat-label>
        <input type="number" matInput [formControl]="formControlAdjustment" placeholder="Enter adjustment...">
        <mat-error *ngFor="let errorKey of errorKeys(formControlAdjustment)">
          <ng-container *ngIf="formControlAdjustment.touched && formControlAdjustment.hasError(errorKey)">
            {{errorMessage(formControlAdjustment, errorKey)}}
          </ng-container>
        </mat-error>
      </mat-form-field> -->
      <currency-input title="Operation Adjustment (discount)"
        [max]="this.maxAdjustment"
        [formControl]="formControlAdjustment">
      </currency-input>
    </div>
  </div>

  <div class="row">
    <div class="col-12">Total Payment = Unpaid Facility Fee + Current Balance + Pending Interest - Adjustment</div>
  </div>

  <mat-divider></mat-divider>

  <div class="row">
    <div class="col-6"></div>
    <div class="col-6 color-primary">= {{totalPayment | looseCurrency}}</div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-checkbox color="primary" [formControl]="formControlAutoDirectDebit">
        Automatically direct debit the amount from the customer's bank account
      </mat-checkbox>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <mat-form-field>
        <mat-label>Reason for Adjustment</mat-label>
        <textarea matInput [formControl]="formControlReason"></textarea>
        @for (errorKey of errorKeys(formControlReason); track errorKey) {
          <mat-error>
            @if (formControlReason.touched && formControlReason.hasError(errorKey)) {
              {{errorMessage(formControlReason, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="width-100" fxLayoutGap="5px"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="center start">
    <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
    <div fxLayoutGap="5px"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="center start">
      <button mat-flat-button class="w-lt-md-100" color="primary" (click)="onSendNotification()" [disabled]="formGroup.invalid">Generate Payout</button>
      <button mat-flat-button class="w-lt-md-100" color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid">Process Payout</button>
    </div>
  </div>
</mat-dialog-actions>
</div>