<div class="add-broker-user-form-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-h2">{{ existingUser ? 'Edit Broker User' : 'Add Broker User' }}</div>
      </div>
    </div>
    <form [formGroup]="formGroup1">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email [formControl]="formControlEmail" [validators]="emailValidators"></email>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <name title="First Name" [formControl]="formControlFirstName"></name>
        </div>
        <div class="col-lg-6 col-md-12">
          <name title="Last Name" [formControl]="formControlLastName"></name>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <name title="Middle Name" [mandatory]="false" [formControl]="formControlMiddleName"></name>
        </div>
        <div class="col-lg-6 col-md-12">
          <aggregator-and-originator-list title="Company name" [getFn]="getFn"
            [formControl]="formControlAggregatorOrOriginator">
          </aggregator-and-originator-list>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <access-level [user]="loggedInUser" [formControl]="formControlAccessLevel"></access-level>
        </div>
        <div class="col-lg-6 col-md-12">
          <mobile [formControl]="formControlMobileNumber"
            [onlyMobile]="true"
          title="Mobile number"></mobile>
        </div>
      </div>
      @if (!existingUser) {
        <confirm-password-admin [formControl]="formControlPassword" ></confirm-password-admin>
      }
      <div class="row">
        <div class="col-12">
          <div fxLayout="row"
            fxLayoutGap="5px"
            fxLayoutAlign="space-between center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <button mat-stroked-button class="w-lt-md-100"
            (click)="cancel($event)">Cancel</button>
            <button mat-flat-button class="w-lt-md-100"
              color="primary" (click)="submit($event)"
            [disabled]="formGroup1.invalid">{{ existingUser ? 'Update' : 'Submit' }}</button>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
