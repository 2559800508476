import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  constants,
  DocumentTag,
  UploadFileDialogData,
  UploadFilesMultiTagsDialogResult
} from '@portal-workspace/grow-shared-library';
import { Subscription } from 'rxjs';
import { UploadFilesMultiTagsComponent } from '../upload-file-component/upload-files-multi-tags.component';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';

@Component({
    templateUrl: './upload-files-multi-tags.dialog.html',
    styleUrls: ['./upload-files-multi-tags.dialog.scss'],
    standalone: true,
    imports: [UploadFileComponent, FormsModule, ReactiveFormsModule, MatCardModule, MatChipsModule, FlexModule, MatButtonModule, MatDialogModule, UploadFilesMultiTagsComponent]
})
export class UploadFilesMultiTagsDialog implements OnInit {

  subscriptions: Subscription[] = [];

  allTags: DocumentTag[] = [];
  defaultTags: DocumentTag[] = [];
  enableCustomTag?: boolean = false;
  enableNonStandardCondition?: boolean = false;
  MAX_TAG_ALLOWED = 10;
  title?: string; 

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: UploadFileDialogData,
              private matDialogRef: MatDialogRef<UploadFilesMultiTagsDialog, UploadFilesMultiTagsDialogResult>) {
  }

  ngOnInit() {
    this.allTags = this.dialogData?.allTags ?? Object.values(constants.documentTypes) as DocumentTag[];
    this.allTags = this.allTags.sort((a, b) => a.type.localeCompare(b.type));
    this.enableCustomTag = !!this.dialogData?.enableCustomTag;
    this.enableNonStandardCondition = !!this.dialogData?.enableNonStandardCondition;
    this.title = this.dialogData.title;

    if (this.dialogData?.tags && this.dialogData?.tags?.length) {
      for (const tagValue of this.dialogData.tags) {
        const tagObj = this.allTags.find(tag => tag.value === tagValue);
        if (tagObj) {
          this.defaultTags.push(tagObj);
        }
      }
    }
  }

  handleDialogEvents(arg: UploadFilesMultiTagsDialogResult) {
    this.matDialogRef.close(arg);
  }
}
