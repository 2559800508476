<form [formGroup]="formGroup" class="aggregator-search-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input type="text"
      placeholder="Search company"
      matInput
      [formControl]="formControlSearch"
      [matAutocomplete]="auto"
      class="ellipsis"
      [disableControl]="disabled"
      matTooltip="{{toolTipValue}}">
      @for (errorKey of errorKeys(formControlSearch); track errorKey) {
        <mat-error>
          @if (formControlSearch.touched && formControlSearch.hasError(errorKey)) {
            {{errorMessage(formControlSearch, errorKey)}}
          }
        </mat-error>
      }
      <span class="mdi mdi-magnify" matSuffix></span>
      <mat-autocomplete #auto="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayWithFn"
        (optionSelected)="onSelected($event)">
        @for (searchEntry of filteredSearchEntries; track searchEntry) {
          <mat-option [value]="searchEntry">
            {{searchEntry.entityName}}@if (searchEntry.abn!==null) {
            <span> - ABN:{{searchEntry.abn}}</span>
          }
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
