import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {AssetCatAndTypeService} from './asset-cat-and-type.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  ApplicationDialogService, countDownLatch,
  createYearInputMask,
  noSpaceValidator,
  setupUntilDestroy,
  validYearValidator
} from '@portal-workspace/grow-ui-library';
import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, filter, first, switchMap, tap} from 'rxjs/operators';
import {
  AssetCategorySelectionValue,
  AssetCategoryType,
  AssetCategoryTypeSelectionValue,
  AssetSelectionComponentValue,
  compareMatch,
  constants,
  getHeavyTruckAssetType,
  getLightTruckAssetType,
  getTruckAssetType,
  TruckGrossVehicleWeight,
  TruckGrossVehicleWeightSelection,
  isAssetCars,
  isAssetNewCars,
  isAssetTrucksOrTrailers,
  isAssetTypeTruck,
  RedbookSearch,
  RedbookSearchByFamiliesResponse,
  RedbookSearchByMakeResponse,
  RedbookSearchByVehiclesResponse,
  RedbookSearchByYearsResponse,
  RedbookSearchResponse,
  RedbookSearchType, TIER2_ASSET_CATS, TIER1_ASSET_CATS
} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {PortalHotToastService} from '../portal-hot-toast-component/hot-toast.service';
import {InputMaskModule} from '@ngneat/input-mask';
import {MatInputModule} from '@angular/material/input';
import {RedbookDetailsComponent} from './redbook-details.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AssetCategoryTypeSelectionComponent} from './asset-category-type-selection.component';
import {MarkDirective} from '../../directives/mark-as-dirty.directive';
import {AssetCategorySelectionComponent} from './asset-category-selection.component';
import {ErrorNotification} from '../message-box/error-notification-component/error-notification.component';
import { AsyncPipe, JsonPipe, NgClass } from '@angular/common';
import {createAsyncState, createAsyncStore, createSyncState, loadingFor} from "@ngneat/loadoff";

import {DisableControlDirective} from "../../directives/disable-control.directive";
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export type AssetSelectionComponentSearchFn = <T extends RedbookSearch>(search: T) => Observable<RedbookSearchResponse<T>>;

export type AssetSelectionComponentEvent = AssetSelectionComponentStateEvent;

export interface AssetSelectionComponentStateEvent {
  type: 'loaded' | 'loading',
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-selection',
    templateUrl: './asset-selection.component.html',
    styleUrls: ['./asset-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetSelectionComponent) }
    ],
    standalone: true,
  imports: [ErrorNotification, AssetCategorySelectionComponent, MarkDirective, FormsModule, ReactiveFormsModule, AssetCategoryTypeSelectionComponent, MatCheckboxModule, MatFormFieldModule, MatSelectModule, MatOptionModule, RedbookDetailsComponent, MatInputModule, InputMaskModule, AsyncPipe, JsonPipe, CustomContentLoaderComponent, NgClass, DisableControlDirective]
})
export class AssetSelectionComponent extends AbstractControlValueAccessor<AssetSelectionComponentValue> implements OnInit, Mark {

  assetMakeComparator = (a: {description: string}, b: {description: string}) => {
    return !!(a && b && a.description && b.description && a.description == b.description);
  }

  assetFamilyComparator = (a: {description: string}, b: {description: string}) => {
    // return !!(a && b && a.familycode && b.familycode && a.familycode == b.familycode);
    return !!(a && b && a.description && b.description && a.description == b.description);
  }

  assetYearsComparator = (a: {year: number}, b: {year: number}) => {
    return !!(a && b && a.year && b.year && a.year == b.year);
  }

  assetModelComparator = (a: {description: string}, b: {description: string}) => {
    return !!(a && b && a.description && b.description && a.description == b.description);
  }



  static counter = 1;

  key = `assetSelection${AssetSelectionComponent.counter++}`;

  subscriptions: Subscription[] = [];

  markObservable: Subject<boolean> = new Subject<boolean>();

  createYearMask = createYearInputMask();
  Object = Object;

  _showMakeFamilyYearAndModel = false;
  _showMakeYearAndModel = false;
  _showMakeYearAndDescription = false;
  _showYearAndDescription = false;
  _showGrossVehicleWeight = false;

  assetSelectionData: AssetSelectionComponentValue = null // the component input value stored when set through outer form
  isAllowToMakeRedbookApiCalls = false
  isClearShowMakeFamilyYearAndModelValue = true

  // only applicable with _showMakeFamilyYearAndModel
  makeSelection: RedbookSearchByMakeResponse = [];
  familySelection: RedbookSearchByFamiliesResponse = [];
  yearSelection: RedbookSearchByYearsResponse = [];
  modelSelection: RedbookSearchByVehiclesResponse = [];
  vehicleSelection: RedbookSearchByVehiclesResponse[number] | string | null = null;
  subscription?:Subscription;

  formGroup: FormGroup<{
    category: FormControl<AssetCategorySelectionValue>,
    type: FormControl<AssetCategoryTypeSelectionValue>,
    year: FormControl<RedbookSearchByYearsResponse[number]|string|null>,
    make: FormControl<RedbookSearchByMakeResponse[number]|string|null>,
    family: FormControl<RedbookSearchByFamiliesResponse[number]|string|null>,
    model: FormControl<RedbookSearchByVehiclesResponse[number]|string|null>,
    description: FormControl<string|null>,
    vehicle: FormControl<RedbookSearchByVehiclesResponse[number]|null>,
    vehicles: FormControl<RedbookSearchByVehiclesResponse|null>,
    OtherCar: FormControl<boolean|null>,
    truckGrossVehicleWeight: FormControl<TruckGrossVehicleWeight | null>, // if truck it's gross weight null otherwise
  }>;
  formControlAssetCategory: FormControl<AssetCategorySelectionValue>;
  formControlAssetType: FormControl<AssetCategoryTypeSelectionValue>;
  formControlAssetMake: FormControl<RedbookSearchByMakeResponse[number]|string|null>;
  formControlAssetFamily: FormControl<RedbookSearchByFamiliesResponse[number]|string|null>;
  formControlAssetYear: FormControl<RedbookSearchByYearsResponse[number]|string|null>;
  formControlAssetModel: FormControl<RedbookSearchByVehiclesResponse[number]|string|null>;
  formControlAssetVehicle: FormControl<RedbookSearchByVehiclesResponse[number]|null>;
  formControlAssetVehicles: FormControl<RedbookSearchByVehiclesResponse|null>;
  formControlAssetDescription: FormControl<string|null>;
  formControlOtherCar: FormControl<boolean|null>;
  formControlGrossVehicleWeight: FormControl<TruckGrossVehicleWeight | null>;
  @Input({required: false}) categoryIndexFilter: string[] = [];
  @Input({required: true}) searchFn!: AssetSelectionComponentSearchFn;
  @Output() events: EventEmitter<AssetSelectionComponentEvent> = new EventEmitter<AssetSelectionComponentEvent>();
  showError = false
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  showAssetCategory = true;
  retry(){
    this.ngOnInit();
  }

  formGroupSubscription?: Subscription;
  makeSubscription?: Subscription;
  familySubscription?: Subscription;
  yearSubscription?: Subscription;
  modelSubscription?: Subscription;
  lgtooltip: boolean = false;
  grossVehicleWeightSelection = [TruckGrossVehicleWeightSelection.Light, TruckGrossVehicleWeightSelection.Heavy, TruckGrossVehicleWeightSelection.Massive];

  constructor(private formBuilder: FormBuilder,
              private portalHotToastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private service: AssetCatAndTypeService) {
    super();

    this.formControlAssetCategory = formBuilder.control(null, [Validators.required]);
    this.formControlAssetType = formBuilder.control(null, [Validators.required]);
    this.formControlAssetYear = formBuilder.control(null, [Validators.required]);
    this.formControlAssetMake = formBuilder.control(null);
    this.formControlAssetFamily = formBuilder.control(null);
    this.formControlAssetModel = formBuilder.control(null);
    this.formControlAssetVehicle = formBuilder.control(null);
    this.formControlAssetVehicles = formBuilder.control(null);
    this.formControlAssetDescription = formBuilder.control(null);
    this.formControlGrossVehicleWeight = formBuilder.control(null);
    this.formControlOtherCar = formBuilder.control(null);

    this.formGroup = formBuilder.group({
      category: this.formControlAssetCategory,
      type: this.formControlAssetType,
      year: this.formControlAssetYear,
      make: this.formControlAssetMake,
      family: this.formControlAssetFamily,
      model: this.formControlAssetModel,
      description: this.formControlAssetDescription,
      vehicle: this.formControlAssetVehicle,
      vehicles: this.formControlAssetVehicles,
      OtherCar: this.formControlOtherCar,
      truckGrossVehicleWeight: this.formControlGrossVehicleWeight,
    });

    this.subscription = combineLatest([
      this.formControlAssetCategory.valueChanges,
      this.formControlAssetType.valueChanges,
      this.formControlOtherCar.valueChanges,
    ]).pipe(
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r: [AssetCategorySelectionValue, AssetCategoryTypeSelectionValue, boolean | null]) => {
        //console.log('***** asset category and type change', r[0], r[1], r[2]);
        const isOtherCarChecked = this.formControlOtherCar.value;
        if (r[0] && r[1] && r[0]?.index && r[1]?.index) {
          // cars & light commercials , passenger or light commercials <= 10 years OR consumer asset, cars
           if (
             // 137 - Passenger Vehicles & Light Commercials,
             //   1 - Passenger or light commercial <= 20 years
             (r[0].index === '137' && r[1].index === '1' && !isOtherCarChecked) ||
             // 139 - Consumer Asset
             //   1 - Cars
             (r[0].index === '139' && r[1].index === '1' && !isOtherCarChecked) ||
             // 132 - Trucks, Trailers and Buses
             //   9 - Trucks
             (r[0].index === '132' && r[1].index === '9' && !isOtherCarChecked)
           ) {
             this.showMakeFamilyYearAndModel();
           } else if (
             // 137 - Passenger Vehicles & Light Commercials,
             //   1 - Passenger or light commercial <= 20 years
             (r[0].index === '137' && r[1].index === '1' && isOtherCarChecked) ||
             // 132 - Trucks, Trailers and Buses
             //   9 - Trucks
             (r[0].index === '132' && r[1].index === '9' && isOtherCarChecked)
           ) {
             this.showMakeYearAndDescription();
           } else if (r[0].index === '130') { // yellow goods / trucks and trailers
             this.showMakeYearAndModel();
           } else if (TIER1_ASSET_CATS.includes(r[0].index)) { //  tier 1 assets, we need to show make, year and description
             this.showMakeYearAndDescription();
           } else if (TIER2_ASSET_CATS.includes(r[0].index)) { // tier 2 assets, we need to show make, year and description
             this.showMakeYearAndDescription();
           } else {
             this.showYearAndDescription();
           }
        }
      })
    ).subscribe();
    this.formGroupSubscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r) => {
        if (this.formGroup.invalid) {
          this.propagateChange(null);
        } else {
          // this.propagateChange(this.formGroup.value);
          const make = r.make && (r.make as any).description ? (r.make as any).description : r.make;
          const family = r.family && (r.family as any).description ? (r.family as any).description : r.family;
          const model = r.model && (r.model as any).description ? (r.model as any).description : r.model;
          const year = r.year && (r.year as any).description ? (r.year as any).description : r.year;
          const description = r.description!;
          const v: AssetSelectionComponentValue = {
            category: r.category!,
            type: r.type!,
            make,
            family,
            year,
            model,
            description,
            vehicle: r.vehicle ?? undefined,
            vehicles: r.vehicles ?? undefined,
            OtherCar: r.OtherCar ?? false,
            truckGrossVehicleWeight: r.truckGrossVehicleWeight,
          };

          if (r?.category?.index === '132' && r?.type?.index === constants.TruckAssetTypeIndex) {
            if (r.OtherCar || this._showGrossVehicleWeight) {
              const grossVehicleWeight = this.formControlGrossVehicleWeight.value;
              if (grossVehicleWeight && grossVehicleWeight === TruckGrossVehicleWeightSelection.Light) {
                v.type = getLightTruckAssetType();
              } else if (grossVehicleWeight && grossVehicleWeight === TruckGrossVehicleWeightSelection.Heavy) {
                v.type = getHeavyTruckAssetType();
              } else if (grossVehicleWeight && grossVehicleWeight === TruckGrossVehicleWeightSelection.Massive) {
                v.type = getHeavyTruckAssetType();
              }
            } else {
              const assetType = this.getTruckAssetType(v.model ?? '');
              if (assetType) {
                v.type = assetType;
              }
            }
          }

          // console.log('propagateChange: ', v);
          this.propagateChange(v);
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControlOtherCar.setValue(false); // trigger assetCat, assetType and otherCar rxjs combineLatest change
    this.service.init(this.key);

    this.subscriptions.push(
      this.formControlAssetCategory.valueChanges.pipe(
        distinctUntilChanged(compareMatch),
        filter(()=>!this.disabled),
        switchMap(value => {
          if (value && value.index === '106') {
            this.lgtooltip = true;
          } else {
            this.lgtooltip = false;
          }

            return this.searchFn({ action: 'makes', type: this.redbookSearchType });


          // if (value && value.index === '132') {
          //   return this.searchFn({ action: 'makes', type: 'truck' })
          // } else {
          //   return this.searchFn({ action: 'makes', type: 'car'})
          // }
        })
      ).subscribe((r: RedbookSearchByMakeResponse) => {
          this.makeSelection = r;
      },
      (err) => {
        this.showError = true
      })
    );


    this.subscriptions.push(
      this.formControlAssetModel.valueChanges.pipe(
        distinctUntilChanged(compareMatch),
        filter(()=>!this.disabled),
        tap((model: RedbookSearchByVehiclesResponse[number] | string | null) => {
          if (this.isAssetTypeTruck()) {
            if (model) {
              // const assetType = this.getTruckAssetType((model as RedbookSearchByVehiclesResponse[number])?.description ?? '');
              const truckGrossWeight = this.getTruckGrossVehicalWeight((model as RedbookSearchByVehiclesResponse[number])?.description ?? '');
              // console.log('***** truck gross weight', truckGrossWeight);
              if (truckGrossWeight) {  // truck and we can figure the weight from redbook response - mandate and don't show truckGrossWeight dropdown
                // remove control
                this.formControlGrossVehicleWeight.clearValidators();
                this.formControlGrossVehicleWeight.setValidators([Validators.required]);
                this.formControlGrossVehicleWeight.setValue(truckGrossWeight);
                this.formControlGrossVehicleWeight.updateValueAndValidity();
                // if (this.formGroup.controls['grossVehicleWeight']) {
                //   this.formGroup.removeControl('grossVehicleWeight');
                // }
                this._showGrossVehicleWeight = false;
              } else {  // truck and we cannot figure out the weight from redbook response - mandate and show truckGrossWeight dropdown
                // add control
                this.formControlGrossVehicleWeight.clearValidators();
                this.formControlGrossVehicleWeight.setValidators([Validators.required]);
                this.formControlGrossVehicleWeight.setValue(null);
                this.formControlGrossVehicleWeight.updateValueAndValidity();
                // this.formGroup.addControl('truckGrossVehicleWeight', this.formControlGrossVehicleWeight);
                this._showGrossVehicleWeight = true;
              }
            } else {  // truck - redbook search returns nothing - mandate and show truckGrossWeight dropdown
                // add control
                this.formControlGrossVehicleWeight.clearValidators();
                this.formControlGrossVehicleWeight.setValidators([Validators.required]);
                this.formControlGrossVehicleWeight.setValue(null);
                this.formControlGrossVehicleWeight.updateValueAndValidity();
                this._showGrossVehicleWeight = true;
            }
          } else { // not truck - don't mandate and don't show truckGrossWeight dropdown
            // remove control
            this.formControlGrossVehicleWeight.clearValidators();
            // this.formControlGrossVehicleWeight.setValidators([Validators.required]);
            this.formControlGrossVehicleWeight.setValue(null);
            // this.formControlGrossVehicleWeight.updateValueAndValidity();
            // if (this.formGroup.controls['grossVehicleWeight']) {
            //   this.formGroup.removeControl('grossVehicleWeight');
            // }
            this._showGrossVehicleWeight = false;
          }
        })
      ).subscribe()
    )

    // NOTE: replaced by category, type and othertypes changes subscription
    // this.formControlOtherCar.valueChanges.pipe(
    //   distinctUntilChanged(compareMatch),
    //   filter(()=>!this.disabled),
    //   filter(()=>{
    //     const assetCat = this.formControlAssetCategory.value?.index;
    //     const assetType = this.formControlAssetType.value?.index;
    //     // these are the ones with othercars option
    //     // 137 - 1,
    //     // 132 - 9
    //     return (
    //       (assetCat == '137' && assetType == '1') ||
    //       (assetCat == '132' && assetType == '9')
    //     );
    //   }),
    //   tap(useOtherCar => {
    //     if (useOtherCar) {
    //       this.showMakeYearAndDescription();
    //     } else {
    //       this.showMakeFamilyYearAndModel();
    //     }
    //   })
    // ).subscribe()

    // Need to put this part after the formControlAssetCategory value changes subscription
    // so that setting values to the form control can trigger redbook search
    if (this.categoryIndexFilter.length === 1 && this.categoryIndexFilter.includes('139')) {
      this.showAssetCategory = false;
      // this.service.init(this.key);
      this.formControlAssetCategory.setValue({ index: '139', value: 'Consumer Asset' })
      this.formControlOtherCar.setValue(false)
      this.service.setAssetCategory(this.key, { index: '139', value: 'Consumer Asset' });
    }
  }

  showMakeFamilyYearAndModel() {        // redbook search
    // console.log('***** show makeFamilyYearModel');
    this._showMakeFamilyYearAndModel = true;
    this._showMakeYearAndModel = false;
    this._showMakeYearAndDescription = false;
    this._showYearAndDescription = false;
    this.formControlAssetDescription.clearValidators();
    this.formControlAssetMake.setValidators([Validators.required ,noSpaceValidator()]);
    this.formControlAssetFamily.setValidators([Validators.required]);
    this.formControlAssetYear.setValidators([Validators.required,validYearValidator()]);
    this.formControlAssetModel.setValidators([Validators.required]);
    this.formControlAssetVehicle.setValidators([Validators.required]);


    if(this.isClearShowMakeFamilyYearAndModelValue){
      this.formControlAssetMake.setValue(null);
      this.formControlAssetFamily.setValue(null);
      this.formControlAssetYear.setValue(null);
      this.formControlAssetModel.setValue(null);
      this.formControlAssetDescription.setValue(null);
      this.formControlAssetVehicle.setValue(null);
      this.formControlAssetVehicles.setValue(null);
    }

    this.formControlAssetDescription.updateValueAndValidity();
    this.formControlAssetVehicle.updateValueAndValidity();
    this.formControlAssetMake.updateValueAndValidity();
    this.formControlAssetYear.updateValueAndValidity();
    this.formControlAssetModel.updateValueAndValidity();
    this.formControlAssetFamily.updateValueAndValidity();

    this.makeSubscription = this.formControlAssetMake.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r: RedbookSearchByMakeResponse[number]|string|null) => {
        // console.log('***** make changed', r);
        if (r && (typeof r != 'string') && r.makecode && !this.isAllowToMakeRedbookApiCalls) {
          this.searchFn({action: 'families', makecode: r.makecode, type: this.redbookSearchType}).pipe(
            delay(0),
            // first(),
            this.portalHotToastService.loadingWithMessage('Loading asset make info ...'),
            tap((r: RedbookSearchByFamiliesResponse) => {
              this.familySelection = r;
              this.yearSelection = [];
              this.modelSelection = [];
              this.formControlAssetFamily.setValue(null, {emitEvent: false});
              this.formControlAssetYear.setValue(null, {emitEvent: false});
              this.formControlAssetModel.setValue(null, {emitEvent: false});
              this.formControlAssetVehicle.setValue(null, {emitEvent: false});
            })
          ).subscribe();
        }
      })
    ).subscribe();
    this.familySubscription = this.formControlAssetFamily.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r: RedbookSearchByFamiliesResponse[number] | string | null) => {
        // console.log('***** family changed', r);
        if (r && (typeof r != 'string') && r.makecode && r.familycode && !this.isAllowToMakeRedbookApiCalls) {
          this.searchFn({action: 'years', makecode: r.makecode, familycode: r.familycode, type: this.redbookSearchType}).pipe(
            delay(0),
            this.portalHotToastService.loadingWithMessage('Loading asset family info ...'),
            tap((r: RedbookSearchByYearsResponse) => {
              this.yearSelection = r;
              this.modelSelection = [];
              this.formControlAssetYear.setValue(null, {emitEvent: false});
              this.formControlAssetModel.setValue(null, {emitEvent: false});
              this.formControlAssetVehicle.setValue(null, {emitEvent: false});
            })
          ).subscribe();
        }
      })
    ).subscribe();
    this.yearSubscription = this.formControlAssetYear.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r: RedbookSearchByYearsResponse[number]|string|null) => {
        // console.log('***** year changed', r);
        if (r && (typeof r != 'string') && r.makecode && r.familycode && r.year && !this.isAllowToMakeRedbookApiCalls) {
          this.searchFn({action: 'vehicles', makecode: r.makecode, familycode: r.familycode, year: r.year, type: this.redbookSearchType}).pipe(
            delay(0),
            this.portalHotToastService.loadingWithMessage('Loading asset year info ...'),
            tap((r: RedbookSearchByVehiclesResponse) => {
              this.modelSelection = r;
              this.formControlAssetVehicles.setValue(r);
              this.formControlAssetModel.setValue(null, {emitEvent: false});
              this.formControlAssetVehicle.setValue(null, {emitEvent: false});
            })
          ).subscribe();
        }
      })
    ).subscribe();
    this.modelSubscription = this.formControlAssetModel.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      filter(()=>!this.disabled),
      tap((r: RedbookSearchByVehiclesResponse[number]|string|null) => {
        if (r && (typeof r != 'string')) {
          this.vehicleSelection = r;
          this.formControlAssetVehicle.setValue(r);
        }
      })
    ).subscribe();
  }

  showMakeYearAndModel() {
    // console.log('**** showMakeYearAndModel');
    this._showMakeFamilyYearAndModel = false;
    this._showMakeYearAndModel = true;
    this._showMakeYearAndDescription = false;
    this._showYearAndDescription = false;
    this.formControlAssetDescription.clearValidators();
    this.formControlAssetFamily.clearValidators();
    this.formControlAssetVehicle.clearValidators();
    this.formControlGrossVehicleWeight.clearValidators();
    this.formControlAssetMake.setValidators([Validators.required, noSpaceValidator()]);
    this.formControlAssetYear.setValidators([Validators.required,validYearValidator()]);
    this.formControlAssetModel.setValidators([Validators.required]);
    this.formControlAssetMake.setValue(null);
    this.formControlAssetFamily.setValue(null);
    this.formControlAssetYear.setValue(null);
    this.formControlAssetModel.setValue(null);
    this.formControlAssetDescription.setValue(null);
    this.formControlAssetVehicle.setValue(null);
    this.formControlAssetVehicles.setValue(null);
    this.formControlGrossVehicleWeight.setValue(null);

    this.makeSubscription && this.makeSubscription.unsubscribe();
    this.familySubscription && this.familySubscription.unsubscribe();
    this.yearSubscription && this.yearSubscription.unsubscribe();
    this.modelSubscription && this.modelSubscription.unsubscribe();

    this.formControlAssetDescription.updateValueAndValidity();
    this.formControlAssetVehicle.updateValueAndValidity();
    this.formControlAssetMake.updateValueAndValidity();
    this.formControlAssetYear.updateValueAndValidity();
    this.formControlAssetModel.updateValueAndValidity();
    this.formControlAssetFamily.updateValueAndValidity();
    this.formControlGrossVehicleWeight.updateValueAndValidity();
  }


  showMakeYearAndDescription() {
    // console.log('**** show MakeYearAndDescription');
    this._showMakeFamilyYearAndModel = false;
    this._showMakeYearAndModel = false;
    this._showMakeYearAndDescription = true;
    this._showYearAndDescription = false;
    this.formControlAssetFamily.clearValidators();
    this.formControlAssetModel.clearValidators();
    this.formControlAssetVehicle.clearValidators();
    this.formControlGrossVehicleWeight.clearValidators();
    this.formControlAssetMake.setValidators([Validators.required, noSpaceValidator()]);
    this.formControlAssetYear.setValidators([Validators.required,validYearValidator()]);
    this.formControlAssetDescription.setValidators([Validators.required,noSpaceValidator()]);
    if (this.isAssetTypeTruck()) {
      this.formControlGrossVehicleWeight.setValidators([Validators.required]);
    }
    this.formControlAssetMake.setValue(null);
    this.formControlAssetFamily.setValue(null);
    this.formControlAssetYear.setValue(null);
    this.formControlAssetModel.setValue(null);
    this.formControlAssetDescription.setValue(null);
    this.formControlAssetVehicle.setValue(null);
    this.formControlAssetVehicles.setValue(null);
    this.formControlGrossVehicleWeight.setValue(null);

    this.makeSubscription && this.makeSubscription.unsubscribe();
    this.familySubscription && this.familySubscription.unsubscribe();
    this.yearSubscription && this.yearSubscription.unsubscribe();
    this.modelSubscription && this.modelSubscription.unsubscribe();

    this.formControlAssetDescription.updateValueAndValidity();
    this.formControlAssetVehicle.updateValueAndValidity();
    this.formControlAssetMake.updateValueAndValidity();
    this.formControlAssetYear.updateValueAndValidity();
    this.formControlAssetModel.updateValueAndValidity();
    this.formControlAssetFamily.updateValueAndValidity();
    this.formControlGrossVehicleWeight.updateValueAndValidity();
  }

  showYearAndDescription() {
    // console.log('***** show YearAndDescription');
    this._showMakeFamilyYearAndModel = false;
    this._showMakeYearAndModel = false;
    this._showMakeYearAndDescription = false;
    this._showYearAndDescription = true;
    this.formControlAssetFamily.clearValidators();
    this.formControlAssetMake.clearValidators();
    this.formControlAssetModel.clearValidators();
    this.formControlAssetVehicle.clearValidators();
    this.formControlGrossVehicleWeight.clearValidators();
    this.formControlAssetYear.setValidators([Validators.required,validYearValidator()]);
    this.formControlAssetDescription.setValidators([Validators.required,noSpaceValidator()]);
    if (this.isAssetTypeTruck()) {
      this.formControlGrossVehicleWeight.setValidators([Validators.required]);
    }
    this.formControlAssetMake.setValue(null);
    this.formControlAssetFamily.setValue(null);
    this.formControlAssetYear.setValue(null);
    this.formControlAssetModel.setValue(null);
    this.formControlAssetDescription.setValue(null);
    this.formControlAssetVehicle.setValue(null);
    this.formControlAssetVehicles.setValue(null);
    this.formControlGrossVehicleWeight.setValue(null);

    this.makeSubscription && this.makeSubscription.unsubscribe();
    this.familySubscription && this.familySubscription.unsubscribe();
    this.yearSubscription && this.yearSubscription.unsubscribe();
    this.modelSubscription && this.modelSubscription.unsubscribe();

    this.formControlAssetDescription.updateValueAndValidity();
    this.formControlAssetVehicle.updateValueAndValidity();
    this.formControlAssetMake.updateValueAndValidity();
    this.formControlAssetYear.updateValueAndValidity();
    this.formControlAssetModel.updateValueAndValidity();
    this.formControlAssetFamily.updateValueAndValidity();
    this.formControlGrossVehicleWeight.updateValueAndValidity();
  }

  get redbookSearchType(): RedbookSearchType {
    if (this.formControlAssetCategory.value?.index === '132') {
      return 'truck';
    } else {
      return 'car';
    }
  }

  store = createAsyncStore();

  doWriteValue(v: AssetSelectionComponentValue | null | undefined): void | AssetSelectionComponentValue {
    // console.log('============= asset-selection dowritevalue', v);
    this.store = createAsyncStore();
    this.events.emit({type: 'loading'})
    this.store.value$.subscribe((r) => {
      if (r.complete || r.success) {
        this.events.emit({type: 'loaded'});
      }
    });
    const timeout = 2000;
    if (v) {
      this.assetSelectionData = v
      this.formControlAssetCategory.setValue(v.category);
      setTimeout(() => {
        // 132 - trucks, trailers and buses
        //  7 - trucks <= 4.5T
        //  8 - trucks > 4.5T
        this.formControlOtherCar.setValue(v.OtherCar);
        if (v.category.index === '132' && (v.type.index === '7' || v.type.index === '8')) {
          this.formControlAssetType.setValue(getTruckAssetType());
        } else {
          this.formControlAssetType.setValue(v.type)
        }


        // 137 -  Passenger Vehicles & Light commercials
        //  1  - Passenger or light commercials <= 20 years
        // 139 - Consumer Asset
        //  1  - Cars
        // 132 - Trucks , Trailers and Busses
        //  7 - Trucks <= 4.5T
        //  8 - Truck s > 4.5T
        if (!v.OtherCar && (
            (v.category.index === '137' && v.type.index === '1') ||
            (v.category.index === '139' && v.type.index === '1') ||
            (v.category.index === '132' && (v.type.index === '7' || v.type.index === '8'))
        )) {

          this.isAllowToMakeRedbookApiCalls = true
          this.isClearShowMakeFamilyYearAndModelValue = false

          if(this.assetSelectionData && v.make && v.family && v.year && v.model){
            if(this.makeSelection.length <= 0){
              this.makeSelection.push({
                makecode: v.make,
                description: v.make,
                latestyear: parseInt(v.year),
                vehicletypes: v.vehicletype!,
              })
            }

            let make = this.makeSelection.find(s => s.description === this.assetSelectionData?.make) as RedbookSearchByMakeResponse[number]|null;
            if(!make){
              const makeData = {
                makecode: v.make,
                description: v.make,
                latestyear: parseInt(v.year),
                vehicletypes: v.vehicletype!,
              }
              this.makeSelection = [makeData]
              make = makeData
            }
            this.formControlAssetMake.setValue(make, {emitEvent: false});

              const f = {
                makecode:this.assetSelectionData.make!,
                familycode: parseInt(v.family),
                description: v.family.toString(),
                latestyear: 2024,
                vehicletype: v.vehicletype!
              }
              const y = {
                makecode: v.make,
                familycode: parseInt(v.family),
                vehicletype: v.vehicletype!,
                year: parseInt(v.year),
                month: v.month!,
                description: v.year,
             }
             const m = {
              avgretail: v.avgretail!,
              avgwhs: v.avgwhs!,
              bodystyle: v.bodystyle!,
              description: v.description,
              familycode: parseInt(v.family) ,
              goodretail: v.goodretail!,
              goodwhs: v.goodwhs!,
              importflag: v.importflag!,
              makecode: v.makecode!,
              month: v.month!,
              newprice: v.newprice!,
              rbc: v.rbc ? String(v.rbc) : "",
              rbid: v.rbid!,
              vehicletype: v.vehicletype!,
              year: parseInt(v.year)
             }

              this.familySelection = [f]
              this.formControlAssetFamily.setValue(f);
              this.yearSelection = [y]
              this.formControlAssetYear.setValue(y);
              this.modelSelection = [m]
              this.formControlAssetModel.setValue(m);
          }
        } else {
          this.formControlOtherCar.setValue(v.OtherCar);
          this.formControlAssetMake.setValue(v.make ?? null);
          this.formControlAssetFamily.setValue(v.family ?? null);
          this.formControlAssetYear.setValue(v.year);
          this.formControlAssetModel.setValue(v.model ?? null);
          this.formControlAssetDescription.setValue(v.description);
          if (
            // 132 - Trucks, Trailers and Buses
            //   7 - Trucks <= 4.5T
            //   8 - Trucks > 4.5T
            v.category.index === '132' &&
            (v.type.index === '7' || v.type.index === '8')
          ) {
            // this.formControlGrossVehicleWeight.setValue(v.type.index === '7' ? TruckGrossVehicleWeightSelection.Light : TruckGrossVehicleWeightSelection.Heavy);
            this.formControlGrossVehicleWeight.setValue(v.truckGrossVehicleWeight ?? null);
          }
        }

      /*   if(this.isAssetTypeTruck() || this.isAssetTypeCar()){
          this.formControlOtherCar.setValue(v.OtherCar, { emitEvent: true });
        } */

        this.store.update({});

     /*    setTimeout(() => {

          if (!v.OtherCar && ((v.category.index === '137' && v.type.index === '1') ||
            (v.category.index === '139' && v.type.index === '1') ||
            (v.category.index === '132' && (v.type.index === '7' || v.type.index === '8')))) {

            countDownLatch(this.store, 4, (ctx)=> {
              setTimeout(()=> {
                const m = this.makeSelection.find(s => s.description === v.make) as RedbookSearchByMakeResponse[number]|null;
                this.formControlAssetMake.setValue(m);
                setTimeout(() => {
                  const f = this.familySelection.find(f => String(f.description) === String(v.family)) as RedbookSearchByFamiliesResponse[number] | null;
                  this.formControlAssetFamily.setValue(f);
                  setTimeout(()=> {
                    const y = this.yearSelection.find(y => String(y.year) == String(v.year)) as RedbookSearchByYearsResponse[number]|null;
                    this.formControlAssetYear.setValue(y);
                    setTimeout(() => {
                      const m = this.modelSelection.find (m => m.description === v.model) as RedbookSearchByVehiclesResponse[number] | null;
                      this.formControlAssetModel.setValue(m);
                      if (v.category.index === '132' && (v.type.index === '7' || v.type.index === '8')) {
                        if (!this.getTruckAssetType(v.model ?? '')) {
                          this.formControlGrossVehicleWeight.clearValidators();
                          this.formControlGrossVehicleWeight.setValidators([Validators.required]);
                          // this.formControlGrossVehicleWeight.setValue(v.type.index === '7' ? GrossVehicleWeightSelection.Light : GrossVehicleWeightSelection.Heavy);
                          this.formControlGrossVehicleWeight.setValue(v.truckGrossVehicleWeight ?? null);
                          this.formControlGrossVehicleWeight.updateValueAndValidity();
                          // this.formGroup.addControl('grossVehicleWeight', this.formControlGrossVehicleWeight);
                          this._showGrossVehicleWeight = true;
                        }
                      }
                      ctx.countDown();
                    }, timeout);
                    ctx.countDown();
                  }, timeout);
                  ctx.countDown();
                }, timeout);
                ctx.countDown();
              }, timeout);
            });

          } else {
            setTimeout(() => {
              this.formControlAssetMake.setValue(v.make ?? null);
              this.formControlAssetFamily.setValue(v.family ?? null);
              this.formControlAssetYear.setValue(v.year);
              this.formControlAssetModel.setValue(v.model ?? null);
              this.formControlAssetDescription.setValue(v.description);
              if (v.category.index === '132' && (v.type.index === '7' || v.type.index === '8')) {
                // this.formControlGrossVehicleWeight.setValue(v.type.index === '7' ? TruckGrossVehicleWeightSelection.Light : TruckGrossVehicleWeightSelection.Heavy);
                this.formControlGrossVehicleWeight.setValue(v.truckGrossVehicleWeight ?? null);
              }
              this.store.update({});
            }, timeout)
          }


        }, timeout); */

      }, timeout);
    } else {
      this.store.update({});
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  isAssetTypeCar(): boolean {
    return (
      (this.formControlAssetCategory.value && this.formControlAssetType.value) ?
        (
          isAssetCars(this.formControlAssetCategory.value?.index)  &&
          isAssetNewCars(this.formControlAssetCategory.value?.index, this.formControlAssetType.value?.index)
        )
      :false)
    // return this.formControlAssetType.value?.index == '1';
  }

  isAssetTypeTruck(): boolean {
    return (
      (this.formControlAssetCategory.value && this.formControlAssetType.value) ?
        (
          isAssetTrucksOrTrailers(this.formControlAssetCategory.value?.index)  &&
          isAssetTypeTruck(this.formControlAssetCategory.value?.index, this.formControlAssetType.value?.index)
        )
        :false)
  }

  getTruckGrossVehicalWeight(description: string): TruckGrossVehicleWeight | null {
    try {
      const descriptionArr = description.split(' ');
      let target = descriptionArr.find(s => s.startsWith('GVM:'));
      if (target) {
        target = target.trim();
        const number = parseFloat(target.slice(4, target.length - 2));
        if (number <= 4500) {
          return TruckGrossVehicleWeightSelection.Light
        } else if (number >= 4500 && number < 12000) {
          return TruckGrossVehicleWeightSelection.Heavy;
        } else {
          return TruckGrossVehicleWeightSelection.Massive;
        }
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  getTruckAssetType(description: string): AssetCategoryType | null {
    try {
      const descriptionArr = description.split(' ');
      let target = descriptionArr.find(s => s.startsWith('GVM:'));
      if (target) {
        target = target.trim();
        const number = parseFloat(target.slice(4, target.length - 2));
        if (number <= 4500) {
          return getLightTruckAssetType();
        } else {
          return getHeavyTruckAssetType();
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }



  onSelectMakeOptionOpened(isOpen: boolean) {
    if(isOpen){
      this.isAllowToMakeRedbookApiCalls = false
    }
  }
}
