<div class="direct-payment-transaction-dialog">
  <mat-dialog-content>
    <h3 class="header">
      @if (!directPayment?.id) {
        Add
        } @if (directPayment?.id) {
        Edit
        } Direct Payment Transaction
      </h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input [formControl]="formControlDate" matInput placeholder="Enter date">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [formControl]="formControlType">
              <mat-option [value]="'direct-debit'">Direct Debit</mat-option>
              <mat-option [value]="'direct-credit'">Direct Credit</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Amount</mat-label>
            <input [formControl]="formControlAmount" matInput placeholder="Enter amount" type="number">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Account Title</mat-label>
            <input [formControl]="formControlAccountTitle" matInput placeholder="Enter account title">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>BSB</mat-label>
            <input [formControl]="formControlBsb" matInput placeholder="Enter BSB">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Account Number</mat-label>
            <input [formControl]="formControlAccountNumber" matInput placeholder="Enter account number">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Reference</mat-label>
            <input [formControl]="formControlReference" matInput placeholder="Enter reference">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
            <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
            <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
