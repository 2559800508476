import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import {ApiResponse, PayloadApiResponse, Configuration} from '@portal-workspace/grow-shared-library';


const URL_UPDATE_CONFIGURATION = () => `${environment.api2Host}/api2/app-configurations`;
const URL_GET_CONFIGURATION = () => `${environment.api2Host}/api2/app-configurations`;
const URL_IS_MAINTENANCE_MODE = () => `${environment.api2Host}/api2/isMaintenanceMode`;



@Injectable()
export class ConfigurationService {

  constructor(private httpClient: HttpClient) {
  }


  isMaintenanceMode(): Observable<PayloadApiResponse<boolean>> {
    return this.httpClient.get<PayloadApiResponse<boolean>>(URL_IS_MAINTENANCE_MODE(), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, payload: boolean}>(URL_IS_MAINTENANCE_MODE(), httpOptions()).pipe(
    //   map(r => {
    //     return {
    //       status: r.status,
    //       message: '',
    //       payload: r.payload
    //     }
    //   })
    // );
  }


  getConfigurations(): Observable<PayloadApiResponse<Configuration>> {
    return this.httpClient.get<PayloadApiResponse<Configuration>>(URL_GET_CONFIGURATION(), httpOptions()).pipe();
    // return this.httpClient.get<Configuration>(URL_GET_CONFIGURATION(), httpOptions()).pipe(
    //   map(r => {
    //     return {
    //       status: true, message: '',
    //       payload: r,
    //     }
    //   })
    // );
  }


  updateConfiguration(data: Partial<Configuration>): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_UPDATE_CONFIGURATION(), data, httpOptions()).pipe();
    // return this.httpClient.put<any>(URL_UPDATE_CONFIGURATION(), data, httpOptions()).pipe(
    //   map(r => {
    //     return {
    //       status: true, message: '',
    //     }
    //   })
    // )
  }

}
