import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CompaniesSearchFn, formControlErrorKeys, formControlErrorMessage, getUser,} from '@portal-workspace/grow-ui-library';
import {
  CompaniesSearchComponentValue,
  ConfirmPasswordAdminValue,
  EmailComponentValue,
  MobileValue,
  User
} from '@portal-workspace/grow-shared-library';
import {map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {navigationUrlForAccreditation, navigationUrlForApplications, navigationUrlForLogin} from '../../service/navigation-urls';
import {Subscription} from 'rxjs';
import {createEmailInputMask, createPhoneNumberInputMask, createNameInputMask } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {RegistrationService} from '../../service/registration.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {
  AggregatorAndOriginatorListValueGetFn
} from '@portal-workspace/grow-shared-library';
import {AdminService} from '../../service/admin.service';
import { validEmailValidator } from '@portal-workspace/grow-ui-library';
import { AuthService } from '../../service/auth.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmPasswordAdminComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CompaniesSearchComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet } from '@angular/common';
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './registration.page.html',
    styleUrls: ['./registration.page.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, FormsModule, FlexModule, ReactiveFormsModule, CompaniesSearchComponent, MatFormFieldModule, MatInputModule, InputMaskModule, MatTooltipModule, ConfirmPasswordAdminComponent, MatCheckboxModule, MatButtonModule, MatDividerModule, NameComponent]
})
export class RegistrationPage implements AfterViewInit, OnInit {

  subscriptions: Subscription[] = [];

  createPhoneNumberInputMask = createPhoneNumberInputMask();
  createNameInputMask = createNameInputMask();
  createEmailInputMask = createEmailInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  getFn: AggregatorAndOriginatorListValueGetFn;
  currentTemplate: TemplateRef<any> | null = null;
  user: User | null = getUser();

  @ViewChild('step1') step1Template!: TemplateRef<any>;
  @ViewChild('confirmation') confirmationTemplate!: TemplateRef<any>;


  formGroupStep1: FormGroup<{
    firstName:  FormControl<string | null>,
    lastName: FormControl<string | null>,
    email: FormControl<string | null>,
    mobileNumber: FormControl<string | null>,
    confirmPassword: FormControl<ConfirmPasswordAdminValue>,
    subscribeMarketing: FormControl<boolean | null>,
    company: FormControl<CompaniesSearchComponentValue>
  }>;

  formControlFirstName: FormControl<string | null>;
  formControlLastName: FormControl<string | null>;
  formControlCompany: FormControl<CompaniesSearchComponentValue>;
  formControlEmail: FormControl<string | null>;
  formControlMobileNumber: FormControl<string | null>;
  formControlConfirmPassword: FormControl<ConfirmPasswordAdminValue>;
  formControlSubscribeMarketing: FormControl<boolean | null>;

  showApplicantFrom = false;
  showNewCompany = false;

  companiesSearchFn!: CompaniesSearchFn;


  constructor(private formBuilder: FormBuilder,
              private registrationService: RegistrationService,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private minimalLayoutService: MinimalLayoutService,
              private adminService: AdminService,
              private authService: AuthService,
              private router: Router) {
    this.getFn = this.adminService.getAggregatorsAndOriginatorsFn;
    // this.getFn = () => {
    //   return this.adminService.getAggregatorsAndOriginators().pipe(
    //     map(r => {
    //       return r.payload
    //     })
    //   );
    // }

    // step 1 form members
    this.formControlFirstName = formBuilder.control('', [Validators.required]);
    this.formControlLastName = formBuilder.control('', [Validators.required]);
    this.formControlEmail = formBuilder.control('', [Validators.required, Validators.email], validEmailValidator(this.authService.validBrokerEmailCheckFn));
    this.formControlMobileNumber = formBuilder.control('', [Validators.required]);
    this.formControlConfirmPassword = formBuilder.control('', [Validators.required]);
    this.formControlCompany = formBuilder.control(null, [Validators.required]);
    this.formControlSubscribeMarketing = formBuilder.control(true);
    this.formGroupStep1 = formBuilder.group({
      firstName:  this.formControlFirstName,
      lastName: this.formControlLastName,
      email: this.formControlEmail,
      mobileNumber: this.formControlMobileNumber,
      confirmPassword: this.formControlConfirmPassword,
      subscribeMarketing: this.formControlSubscribeMarketing,
      company: this.formControlCompany,
    });
    this.companiesSearchFn = this.registrationService.companiesSearchFn;
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: true,
    });
    this.subscriptions.push(this.formControlCompany.valueChanges.pipe(
      tap((comp: CompaniesSearchComponentValue) => {
        this.showApplicantFrom = !!comp;
      })
    ).subscribe());
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch (r.type) {
            case 'accreditation-clicked': {
              await this.router.navigate(navigationUrlForAccreditation());
            }
          }
        }
      })
    ).subscribe());
  }

  navigationUrlForAccreditation () {
    return navigationUrlForAccreditation().toString()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.step1Template;
    });
  }

  async accreditionsPage($event:any) {
    let value = $event.checked;
    if(value){
      await this.router.navigate(navigationUrlForAccreditation());
    }
  }

  onFinishSetup($event: MouseEvent) {
      const o: CompaniesSearchComponentValue = this.formControlCompany.value;
      const originatorBusinessId = o?.originatorBusinessId ?? undefined;
      // const aggregatorId = o?.aggregatorId ?? undefined;

    const comp: CompaniesSearchComponentValue = this.formControlCompany.value;

    this.registrationService.registerBroker({
      FamilyName: this.formControlLastName.value!,
      GivenName: this.formControlFirstName.value!,
      MobileNumber: this.formControlMobileNumber.value!,
      Email: this.formControlEmail.value!,
      Password: this.formControlConfirmPassword.value!,
      companyName: comp?.entityName ?? '',
      OriginatorBusinessId: originatorBusinessId,
      // AggregatorId: aggregatorId,
    }).pipe(
      this.toastService.retryableMessage({
        successMessage: 'User created',
        errorMessage: 'Failed to create the User',
        retryFn: ()=> {
          this.onFinishSetup($event);
        }
      }),
      tap(r => {
        if (r.status) {
          setTimeout(() => {
            this.currentTemplate = this.confirmationTemplate;
          });
        } else {
          this.dialogService.openAlertDialog({
            message: `Error`,
            subMessage: r.message,
          });
        }
      })
    ).subscribe();
  }
  async onLoginAgain($event: MouseEvent) {
    if (this.user) {
      await this.router.navigate(navigationUrlForApplications());
    } else {
      await this.router.navigate(navigationUrlForLogin());
    }
  }
}
