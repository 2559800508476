import {Component, forwardRef, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
    ApplicationDialogService,
    formControlErrorKeys,
    formControlErrorMessage,
    setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatOption, MatOptionModule} from '@angular/material/core';
import {
    BusinessSearchResultValue,
    BusinessSearchValue,
    compareMatch,
    SearchCompanyByABNResult,
    SearchCompanyByNameResult,
    SearchCompanyResult
} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {EntityTypeValue, EntityTypes, EntityTypeValueOptions as EntityTypeOptions, EntityTypeCompanyOption, EntityTypeTrustOption,
  EntityTypePartnershipOption, EntityTypeSoleTraderOption, EntityTypeOtherOption}
  from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {autocompleteObjectValidator} from '@portal-workspace/grow-ui-library';
import _ from 'lodash';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';



export interface BusinessSearchFn {
  (search: string): Observable<SearchCompanyResult>;
};

// NOTE: use value object BusinessSearchValue for FormControl
@UntilDestroy()
@Component({
    selector: 'business-search',
    templateUrl: './business-search.component.html',
    styleUrls: ['./business-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessSearchComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BusinessSearchComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule, MatProgressSpinnerModule]
})
export class BusinessSearchComponent implements OnInit, ControlValueAccessor, Mark {  // the value when using this component will be BusinessSearchValue

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;


  @HostListener('document:click', ['$event'])
  pageClick(event: MouseEvent) {
    // this.loading = false;
  }

  state: 'loading' | 'selected' | 'error' | 'done' | '' = '';

  disabled = false;
  // loading = false;
  // selected = false;
  // searchError = false;
  // notFound = false;
  // displayError = false;
  onChangeFn: any;
  onTouchFn: any;
  val?: BusinessSearchValue = null;


  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;
  @ViewChild('manualEntryOption') manualEntryOption?: MatOption;
  @Input({required: true}) searchFn!: BusinessSearchFn;
  @Input({required: false}) readOnly:boolean =false ;
  @Input({required: false}) placeholder: string="";
  @Input({required: false}) onlyAcnEntries = false;
  @Input({required: false}) allowFreeText: boolean = true;
  // @Output() getBusiness:EventEmitter<BusinessSearchValue>=new EventEmitter();
  formGroup!: FormGroup<{
    search: FormControl<BusinessSearchValue | string>
  }>;
  formControl!: FormControl<BusinessSearchValue | string>;
  subscription?: Subscription;
  filteredEntries: Omit<Exclude<BusinessSearchResultValue, null>, 'result'>[] = [];
  displayWithFn: (v: BusinessSearchValue | string) => string = (v) => {
    if (typeof v == 'string') {
      return v
    }
    return v ? `${v.organisationName} (ABN: ${v.abn})` : '';
  };

  constructor(private formBuilder: FormBuilder,
              private applicationDialogService: ApplicationDialogService){
  }

  validAcnValidatorFn: ValidatorFn = (control: AbstractControl) => {
    const b: BusinessSearchValue = control.value;
    if (b) {
      if (!!!b.acn) {
        return { 'acn': true}; // error
      }
    }
    return null; // no errors
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, this.onlyAcnEntries ? [Validators.required, this.validAcnValidatorFn,autocompleteObjectValidator()] : [Validators.required,autocompleteObjectValidator()]);
    this.formGroup = this.formBuilder.group({
      search: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r === 'string'),
      map(r => /^[0-9 ]+$/.test(r as string) ? _.replace((r ?? '').toString(), / /g, '') : r),
      switchMap(r => {
        if (r === '') {
          // this.selected = false;
          this.propagateChange(null);
          return of(null);
        } else {
          // this.selected = false;
          // this.loading = true;
          // this.notFound = false;
          // this.searchError = false;
          this.state = 'loading';
          return this.searchFn(r as string);
        }
      }),
      tap((r: SearchCompanyResult | null) => {
        if (r && r?.ABRPayloadSearchResults?.response?.exception?.exceptionCode) { // erronous search result
          this.filteredEntries = [];
          this.state = 'error';
          // this.loading = false;
          // this.notFound = true;
          // this.displayError = true;
          // this.searchError = true;
          this.formControl.setErrors({
            businessSearch: true,
          })
          this.propagateChange(null);
        }
        else if (!r) { // no search result
          this.state = 'done';
          this.filteredEntries = [];
          // this.notFound = false;
          // this.displayError = false;
          // this.searchError = false;
          this.formControl.setErrors({
            businessSearch: true,
          });
        }
        else if (this.isSearchResultByName(r)) {  // search result by name
          this.filteredEntries = r.records.map( rec => {
            return {
              type: 'search-result',
              abn: String(rec.abn),
              //organisationName: rec.organisationName,
              organisationName: this.getOrganisationName(r,rec.abn),
              acn: '',
              stateCode: '',
              postcode: '',
              status: rec.status,
              entityTypeValue: this.toEntityTypeValue(r),
            }
          })
        } else {  // serch result by abn
          this.filteredEntries = [{
            type: 'search-result',
            abn: String(Array.isArray(r.ABN) ? r.ABN.length ? r.ABN[0].identifierValue : '' : r.ABN?.identifierValue),
            acn: String(r.ASICNumber),
            status: r.entityStatus && Array.isArray(r.entityStatus) ? r.entityStatus.length ? r.entityStatus[0] ? r.entityStatus[0].entityStatusCode : '' : '' : r.entityStatus ? r.entityStatus.entityStatusCode : '',
            // status: r.entityStatus && r.entityStatus.length ? r.entityStatus[0].entityStatusCode : '',
            // organisationName: r.organisationName,
            organisationName: this.getOrganisationName(r,Number(r.ABN)),
            stateCode: r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].stateCode : '',
            postcode: String(r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].postcode : ''),
            entityTypeValue: this.toEntityTypeValue(r),
          }];
        }
        this.state = 'done';
      })
    ).subscribe();

    if(this.readOnly) {
      this.formControl.clearValidators();
      this.formControl.updateValueAndValidity();
    }
  }


  isSearchResultByName(s: SearchCompanyResult): s is SearchCompanyByNameResult {
    return (s  && (s as any)['numberOfRecords'] !== null && (s as any)['numberOfRecords'] !== undefined);
  }


  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: BusinessSearchValue): void {
    if (obj && obj.organisationName) {
      // HACK: to make autocomplete trigger available, auto-retry fn needed.
      setTimeout(()=> {
        if (this.autocompleteTrigger) {
          const options = this.autocompleteTrigger.autocomplete.options.toArray();
          const optionFound = options.find((o) => {
            const v: BusinessSearchValue = o.value;
            return (v && v.organisationName === obj.organisationName);
          });
          if (optionFound) {
            this.formControl.setValue(optionFound.value);
          } else {
            this.formControl.setValue(obj);
          }
          this.propagateChange(obj);
        }
      }, 1000);
    }
    else if (obj && obj.abn) {
      this.formControl.setValue(obj.abn);
    }
    else {
      this.formControl.reset();
      this.propagateChange(null);
    }
  }

  propagateChange(v?: BusinessSearchValue) {
    this.val = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v);
  }

  toEntityTypeValue(r: SearchCompanyResult): EntityTypeValue {
    let entityTypeOption = null;
    if (r && !this.isSearchResultByName(r) &&  r.entityType) {
      entityTypeOption = EntityTypeOtherOption;
      const description = r.entityType.entityDescription;
      const code = r.entityType.entityTypeCode;
      if ('IND' == code) {
        entityTypeOption = (EntityTypeSoleTraderOption);
      } else if (description && description.toLowerCase().indexOf('trust') >= 0) {
        entityTypeOption = (EntityTypeTrustOption);
      } else if (description && description.toLowerCase().indexOf('company') >= 0) {
        entityTypeOption = (EntityTypeCompanyOption);
      } else if (description && description.toLowerCase().indexOf('partnership') >= 0) {
        entityTypeOption = (EntityTypePartnershipOption);
      } else {
        entityTypeOption = (EntityTypeOtherOption);
      }
    }
    return entityTypeOption;
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const selected: BusinessSearchValue = $event.option.value;
    // this.getBusiness.emit(selected)
    if (selected) {
      this.searchFn(selected.abn)
        .pipe(
          tap((r: SearchCompanyResult) => {
            console.log('======r: ', r);
            let abnAgeMonths = 0
            let gstAgeMonths = 0
            const entityTypeValue = this.toEntityTypeValue(r);
            if (!this.isSearchResultByName(r)) {
              if (r.entityStatus !== undefined) {
                const entityStatus = Array.isArray(r.entityStatus) ? r.entityStatus[0] : r.entityStatus;
                abnAgeMonths = moment().diff(entityStatus.effectiveFrom, 'months');
                r.ABNAgeMonths = abnAgeMonths;
                // this.searchError = false;
                // this.selected  = true;
                // this.loading = false;
                this.state = 'selected';
              }
              if (r.goodsAndServicesTax !== undefined) {
                let goodsAndServicesTax = Array.isArray(r.goodsAndServicesTax) ? r.goodsAndServicesTax[0] : r.goodsAndServicesTax;
                gstAgeMonths = moment().diff(goodsAndServicesTax.effectiveFrom, 'months');
                r.GSTAgeMonths = gstAgeMonths
              }
              // const orgName: any = r;
              // const organisationName = r.organisationName !== '' ? r.organisationName : (
              //   orgName?.mainTradingName?.length ? orgName.mainTradingName[0]?.organisationName : orgName?.mainTradingName?.organisationName
              // );
              // const legalName = Array.isArray(orgName.legalName) ? orgName.legalName[0] : orgName.legalName
              // const EntityName = legalName ? legalName.familyName + ' ' + legalName.givenName + ' ' + legalName.otherGivenName : organisationName
              const rst: BusinessSearchValue = {
                type: 'search-result',
                abn: String(Array.isArray(r.ABN) ? r.ABN.length ? r.ABN[0].identifierValue : '' : r.ABN.identifierValue),
                acn: String(r.ASICNumber),
                organisationName: this.getOrganisationName(r,Number(selected.abn)), // EntityName
                status: r.entityStatus && Array.isArray(r.entityStatus) ? r.entityStatus.length ? r.entityStatus[0].entityStatusCode : '' : r.entityStatus.entityStatusCode,
                stateCode: r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].stateCode : '',
                postcode: String(r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].postcode : ''),
                result: r,
                entityTypeValue,
              };
              console.log('======rst: ', rst);
              this.formControl.setValue(rst);
              if (this.onlyAcnEntries) {
                if (r.ASICNumber) {
                  this.propagateChange(rst);
                } else {
                  this.propagateChange(null);
                }
              } else {
                this.propagateChange(rst);
              }
             }
          })
        ).subscribe();
    }
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formGroup.reset();
  }

  onSearchEnter($event: MouseEvent) {
      this.applicationDialogService.openManualBusinessSearchDialog({
          acnMandatory: this.onlyAcnEntries,
      }).afterClosed().pipe(
          tap(r => {
              if (r) { // not cancel
                 const v: BusinessSearchValue = {
                     type: 'free-text',
                     organisationName: r.organisationName,
                     acn: r.acn,
                     abn: r.abn,
                 };
                 this.state = 'selected';
                 this.formControl.setErrors(null);
                 this.formControl.setValue(v);
                 this.formControl.updateValueAndValidity();
                 if (this.autocompleteTrigger) {
                     this.autocompleteTrigger.closePanel();
                 }
                 this.propagateChange(v);
              }
          })
      ).subscribe();
  }

  

  getOrganisationName(r: SearchCompanyResult,currentAbn:number) {

    if (!this.isSearchResultByName(r)) {
      const orgName = r;
      const organisationName = r.organisationName !== '' ? r.organisationName : (
        Array.isArray(orgName?.mainTradingName) ? orgName.mainTradingName[0]?.organisationName : orgName?.mainTradingName?.organisationName
      );
      const legalName = Array.isArray(orgName.legalName) ? orgName.legalName[0] : orgName.legalName
      const EntityName = legalName ? legalName.familyName + ' ' + legalName.givenName + ' ' + legalName.otherGivenName : organisationName
      return EntityName;
    }  else {
      if (r.records && r.records.length) {
        const activeRecs = r.records.filter(rec => rec.status == 'Active' && rec.abn === currentAbn);
        if (activeRecs.length) {
          return activeRecs[0].organisationName;
        }
        return r.records[0].organisationName;
      }
    }
    return 'unknown';
  }
}

