<div class="originator-relationship-component">
  <mat-form-field>
    <mat-label>Relationship</mat-label>
    <mat-select [formControl]="formControl">
      @for (option of options; track option) {
        <mat-option [value]="option.type">
          {{option.name}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
