import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { AccountSummary } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'transaction-summary',
    templateUrl: './transaction-summary.component.html',
    styleUrls: ['./transaction-summary.component.scss'],
    standalone: true,
    imports: [MatTableModule, DatePipe, LooseCurrencyPipe]
})
export class TransactionSummaryComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: AccountSummary;
  dataSource: AccountSummary[] = [];
  columnsToDisplay = ['transPeriod', 'days', 'openingBalance', 'totalCredits', 'totalDebits', 'minDayEndBalance', 'avgDayEndBalance','daysInNegative'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource = [this.data];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = [this.data];
  }

  getDays(startDate: string, endDate: string) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const Time = date2.getTime() - date1.getTime();
    const Days = Time / (1000 * 3600 * 24);
    return Days
  }
}
