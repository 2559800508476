import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, CustomerApplicantTypeOptions, CustomerApplicantTypeValue, LoanTermValue, LoanTermValueOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '../../directives/disable-control.directive';



@UntilDestroy()
@Component({
  selector: 'customer-applicant',
  templateUrl: './customer-applicant.component.html',
  styleUrls: ['./customer-applicant.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomerApplicantComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CustomerApplicantComponent) },
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class CustomerApplicantComponent extends AbstractControlValueAccessor<CustomerApplicantTypeValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    selection: FormControl<CustomerApplicantTypeValue>;
  }>;
  formControl: FormControl<CustomerApplicantTypeValue>;
  subscription: Subscription;

  // @Input({required: false}) disabled: boolean = false;

  options = CustomerApplicantTypeOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: CustomerApplicantTypeValue): CustomerApplicantTypeValue | undefined {
    if (v) {
      const t = CustomerApplicantTypeOptions.find(opt => opt.type === v.type);
      if (t) {
        this.formControl.setValue(t);
        return t;
      }
    }
    else{
      this.formControl.setValue(null);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
