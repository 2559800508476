import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {AssetCategory, AssetCategoryType, getAssetCategoryTypes as gact, getAssetCategories as gac} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';


@UntilDestroy({ arrayName: 'subscriptions' })
@Injectable()
export class AssetCatAndTypeService {

  subscriptions: Subscription[] = [];

  assetCategorySubjects: Record<string, BehaviorSubject<AssetCategory | null>> = {};
  assetCategoryTypeSubjects: Record<string, BehaviorSubject<AssetCategoryType[]>> = {};

  truckShowGVMTypes = false;

  constructor() {
    setupUntilDestroy(this);
  }

  init(key: string) {
    if (!this.assetCategoryTypeSubjects[key]) {
      const currentAssetCategoryTypesSubject = new BehaviorSubject<AssetCategoryType[]>([]);
      this.assetCategoryTypeSubjects[key] = currentAssetCategoryTypesSubject;
    }
    if (!this.assetCategorySubjects[key]) {
      const currentSelectedAssetCategorySubject = new BehaviorSubject<AssetCategory | null>(null);
      const currentAssetCategoryTypesSubject = this.assetCategoryTypeSubjects[key];
      this.assetCategorySubjects[key] = currentSelectedAssetCategorySubject;
      this.subscriptions.push(currentSelectedAssetCategorySubject
        .pipe(
          tap(assetCategory => {
            if (assetCategory) {
              const assetCategoryTypes = gact(assetCategory.index, this.truckShowGVMTypes);
              currentAssetCategoryTypesSubject.next(assetCategoryTypes);
            } else {
              currentAssetCategoryTypesSubject.next([]);
            }
          })
        ).subscribe());
    }
  }

  setShowGVMTypes(show: boolean) {
    this.truckShowGVMTypes = show;
  }

  setAssetCategory(key: string, a: AssetCategory) {
    const currentSelectedAssetCategorySubject = this.assetCategorySubjects[key];
    if (currentSelectedAssetCategorySubject) {
      currentSelectedAssetCategorySubject.next(a);
    }
  }

  assetCategoryTypes(key: string): BehaviorSubject<AssetCategoryType[]> {
    if (!this.assetCategoryTypeSubjects[key]) {
      throw Error('No asset category type subject configured') ;
    }
    return this.assetCategoryTypeSubjects[key] ?? of([]);
  }

  getAssetCatagories(): AssetCategory[] {
    return gac();
  }

}
