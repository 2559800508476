import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  compareMatch,
  CoverTypeSelectionValue,
  CurrencyInputValue,
  DatepickerValue,
  InsurerSelectionValue,
  Policy
} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Observable, Subject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  nameIncludeDigitsWithoutBlankSpaceValidator
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { PolicyValue } from '@portal-workspace/grow-shared-library';
import { Moment } from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { InsurerSelectionComponent } from './insurer-selection.componen';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CoverTypeSelectionComponent } from './cover-type-selection.component';



export type PolicyComponentCalcFn = (total: number, paidBy: '10' | '12')=>Observable<{monthlyInstallment: number, applicationFee: number, brokerage: number}>;

export type PolicyFormArray = FormArray<PolicyFormGroup>;

export type PolicyFormGroup = FormGroup<{
  coverType: FormControl<CoverTypeSelectionValue>,
  insurer: FormControl<InsurerSelectionValue>,
  policyNumber: FormControl<string | null>,
  inceptionDate: FormControl<DatepickerValue>,
  expiryDate: FormControl<DatepickerValue>,
  premiumAmount: FormControl<CurrencyInputValue>,
  invoiceNumber: FormControl<string | null>
}>

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PolicyComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PolicyComponent) },
    ],
    standalone: true,
    imports: [CoverTypeSelectionComponent, MarkDirective, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InsurerSelectionComponent, DatepickerComponent, CurrencyInputComponent, MatButtonModule]
})
export class PolicyComponent extends AbstractControlValueAccessor<PolicyValue>  implements OnInit, AfterViewInit, Mark  {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: true}) calcFn!: PolicyComponentCalcFn

  MIN_PREMIUM_AMOUNT = 0.01;

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();


  formArray: PolicyFormArray;
  formControlTotal: FormControl<CurrencyInputValue>;
  formControlPaidByMonthlyInstallment: FormControl<'10' | '12' | null>;
  formControlMonthlyInstallment: FormControl<CurrencyInputValue>;
  formControlApplicationFee: FormControl<CurrencyInputValue>;
  formControlBrokerage: FormControl<CurrencyInputValue>;
  formGroup: FormGroup<{
    total: FormControl<CurrencyInputValue>,
    paidByMonthlyInstallment: FormControl<'10' | '12' | null>,
    monthlyInstallment: FormControl<CurrencyInputValue>,
    applicationFee: FormControl<CurrencyInputValue>,
    brokerage: FormControl<CurrencyInputValue>,
    policies: PolicyFormArray
  }>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<PolicyFormGroup>([]);
    this.formControlTotal = formBuilder.control(null);
    this.formControlPaidByMonthlyInstallment = formBuilder.control('10', [Validators.required]);
    this.formControlMonthlyInstallment = formBuilder.control(null);
    this.formControlApplicationFee = formBuilder.control(null);
    this.formControlBrokerage = formBuilder.control(null);
    this.formGroup = formBuilder.group({
      total: this.formControlTotal,
      paidByMonthlyInstallment: this.formControlPaidByMonthlyInstallment,
      monthlyInstallment: this.formControlMonthlyInstallment,
      applicationFee: this.formControlApplicationFee,
      brokerage: this.formControlBrokerage,
      policies: this.formArray,
    });
    const sub1 = this.formControlPaidByMonthlyInstallment.valueChanges.pipe(
      delay(0),
      tap(r => {
        this.calculateTotal();
      })
    ).subscribe();
    this.subscriptions.push(sub1);
    const sub = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid && this.formArray.valid) {
          // NOTE: when form is valid, required values will not be null
          const policyValue: PolicyValue = {
            total: this.formControlTotal.value!,
            paidByMonthlyInstallment: this.formControlPaidByMonthlyInstallment.value!,
            monthlyInstallment: this.formControlMonthlyInstallment.value ?? 0,
            applicationFee: this.formControlApplicationFee.value ?? 0,
            brokerage: this.formControlBrokerage.value ?? 0,
            policies: this.formArray.value.map(v => {
              return {
                coverType: v.coverType!,
                insurer: v.insurer!,
                policyNumber: v.policyNumber!,
                inceptionDate: v.inceptionDate!,
                expiryDate: v.expiryDate!,
                premiumAmount: v.premiumAmount!,
                invoiceNumber: v.invoiceNumber!,
              }
            })
          };
          this.propagateChange(policyValue);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      if (this.formArray.length <= 0) {
        this.addPolicy();
      }
    });
  }

  private calculateTotal() {
    let totalPremiumAmount = 0;
    for (const policyFormGroup of this.formArray.controls) {
      const formControlPremiumAmount = (policyFormGroup as FormGroup).controls['premiumAmount'];
      totalPremiumAmount += Number(formControlPremiumAmount.value ? formControlPremiumAmount.value : 0);
    }
    this.formControlTotal.setValue(totalPremiumAmount);
    this.calcFn(totalPremiumAmount, this.formControlPaidByMonthlyInstallment.value!).pipe(
      tap(r => {
        this.formControlMonthlyInstallment.setValue(r.monthlyInstallment);
        this.formControlApplicationFee.setValue(r.applicationFee);
        this.formControlBrokerage.setValue(r.brokerage);
      }),
    ).subscribe();
  }

  doWriteValue(v?: PolicyValue): void | PolicyValue {
    if (v) {
      for (const policy of v.policies) {
        this.addPolicy(policy);
      }
    }
  }

  policiesFormGroup() {
    return this.formArray.controls;
  }

  policyFromGroupFormControl(policyFormGroup: PolicyFormGroup, controlName: 'coverType' | 'insurer' | 'policyNumber' | 'inceptionDate' | 'expiryDate' | 'premiumAmount' | 'invoiceNumber') {
    return policyFormGroup.controls[controlName];
  }

  addPolicy(p?: Policy) {
    const formControlCoverType = this.formBuilder.control(p ? p.coverType : null, [Validators.required]);
    const formControlInsurer = this.formBuilder.control(p ? p.insurer : null, [Validators.required]);
    const formControlPolicyNumber = this.formBuilder.control(p ? p.policyNumber: null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
    const formControlInceptionDate = this.formBuilder.control(p ? p.inceptionDate : null, [Validators.required]);
    const formControlExpiryDate = this.formBuilder.control(p ? p.expiryDate : null, [Validators.required]);
    const formControlPremiumAmount = this.formBuilder.control(p ? p.premiumAmount : null, [Validators.required]);
    const formControlInvoiceNumber = this.formBuilder.control(p ? p.invoiceNumber : null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
    const formGroup = this.formBuilder.group<{
      coverType: FormControl<CoverTypeSelectionValue>,
      insurer: FormControl<InsurerSelectionValue>,
      policyNumber: FormControl<string | null>,
      inceptionDate: FormControl<DatepickerValue>,
      expiryDate: FormControl<DatepickerValue>,
      premiumAmount: FormControl<CurrencyInputValue>,
      invoiceNumber: FormControl<string | null>
    }>({
      coverType: formControlCoverType,
      insurer: formControlInsurer,
      policyNumber: formControlPolicyNumber,
      inceptionDate: formControlInceptionDate,
      expiryDate: formControlExpiryDate,
      premiumAmount: formControlPremiumAmount,
      invoiceNumber: formControlInvoiceNumber,
    });
    const sub = formControlPremiumAmount.valueChanges.pipe(
      delay(0),
      tap(r => {
        this.calculateTotal();
      })
    ).subscribe();
    (formGroup as any)['sub'] = sub;
    this.subscriptions.push(sub);
    this.formArray.push(formGroup);
  }

  removePolicy(policyFormGroup: FormGroup) {
    const index = this.formArray.controls.findIndex(fg => fg === policyFormGroup);
    if (index !== -1) {
      const formGroup: FormGroup = this.formArray.at(index) as FormGroup;
      const subscription = (formGroup as any)['sub'] as Subscription;
      subscription && subscription.unsubscribe();
      const i = this.subscriptions.indexOf(subscription);
      if (i !== -1) {
        this.subscriptions.splice(i, 1);
      }
      this.formArray.removeAt(index);
    }
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

}
