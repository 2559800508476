<div class="credit-overview">
  <mat-card appearance="outlined">
    <!-- <mat-tab-group #bsTabs
      [mat-stretch-tabs]="false"
      mat-align-tabs="start"
      (selectedIndexChange)="setCurrentSelection($event)">
      @for (currentTab of tabs; track currentTab) {
        <mat-tab>
          <ng-template mat-tab-label>
            {{currentTab.name}}
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group> -->
  


  <!-- @if (currentSection === 'entity') {
    entity
  }

  @if (currentSection === 'director') {
    director
  }

  @if (currentSection === 'guarantor') {
    guarantor
  }

  @if (currentSection === 'summary') {
    summary
  }

  @if (currentSection === 'result') { -->
    <credit-result
      [application]="application"
      [runCreditFlowFn]="runCreditFlowFn"
      [getCreditWebServicesFn]="getCreditWebServicesFn"
      [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
      [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
      [updateCreditStatusFn]="updateCreditStatusFn"
      [getCreditAuditLogFn]="getCreditAuditLogFn"
      [getApplicationByIdFn]="getApplicationByIdFn"
      [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
      [apiUrl]="apiUrl"
      [deleteWebServicesFn]="deleteWebServicesFn"
      [requestMoreInformationFn]="requestMoreInformationFn"
      [getUserFn]="getUserFn"
      [getApplicationOwnerFn]="getApplicationOwnerFn"
      [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
      [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
      [createApplicationNoteFn]="createApplicationNoteFn"
      [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
      [getApplicationDscrFn]="getApplicationDscrFn"
      (viewReport)="onViewReport($event)"
      (clickApplicationEvent)="onClick($event)"
      (refreshCreditTabEvent)="refreshCreditTab($event)"
    ></credit-result>
  
  </mat-card>
</div>
