import {Pipe, PipeTransform} from '@angular/core';
import { SignerRoleTypes } from '@portal-workspace/grow-shared-library';


@Pipe({
    name: 'signerRole',
    pure: true,
    standalone: true
})
export class SignerRolePipe implements  PipeTransform {

  transform(value: SignerRoleTypes, ...args: any[]): any {
    switch (value) {
      case 'GuarantorSigner':
        return 'Guarantor / Signer';
      case 'CorporateGuarantor':
        return 'Corporate Guarantor';
      case null:
        return ''
      default:
        return value;
    }
  }

}
