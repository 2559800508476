import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AccountFilter, AccountMonthlyBalance, AccountSummary, BankStatementAccount, BankStatementData, BankStatementsAnalysisData, BankTransactionsWithSummary, DebitCreditSummary, GetBsaCalculatorFn, isInternalUser, SaveBsaCalculatorFn, User } from '@portal-workspace/grow-shared-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { DecisionMetricsComponent } from './decision-metrics.component';
import { TransactionSummaryComponent } from './transaction-summary.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from "@angular/flex-layout";
import { FullTransactionListComponent } from './full-transaction-list.component';
import { CategoriesTableComponent } from './categories-table.component';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { MonthlyDebitsCreditsTableComponent } from './monthly-debits-credits-table.component';
import { NonPreferredLenderSummaryComponent } from './non-preferred-lender-summary.component';
import { OverdrawnTableComponent } from './overdrawn-table.component';
import { TransactionTableComponent } from './transaction-table.component';
import { DishonoursTableComponent } from './dishonours-table.component';
import { MonthlyRevenueChartComponent } from './monthly-revenue-chart.component';
import { MonthlyRevenueTableComponent } from './monthly-revenue-table.component';
import { getUser } from '@portal-workspace/grow-ui-library';

@Component({
  selector: 'bank-statement-overview',
  templateUrl: './bank-statement-overview.component.html',
  styleUrls: ['./bank-statement-overview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, NgClass, ExtendedModule, CategoriesTableComponent, MonthlyRevenueTableComponent, MonthlyRevenueChartComponent, DishonoursTableComponent, TransactionTableComponent, OverdrawnTableComponent, NonPreferredLenderSummaryComponent, MonthlyDebitsCreditsChartComponent, MonthlyDebitsCreditsTableComponent, FullTransactionListComponent, MatButtonModule, FormsModule, FlexModule, ReactiveFormsModule, MatCheckboxModule, TransactionSummaryComponent, DecisionMetricsComponent, DayEndBalanceChartComponent, LooseCurrencyPipe]
})
export class BankStatementOverviewComponent implements OnInit, OnChanges {
  @Input({ required: true }) data!: BankStatementData;
  @Input({ required: false }) selectedAccounts: string[] = [];
  @Output() accountSelector = new EventEmitter();

  dataSource: MatTableDataSource<BankStatementAccount>;
  columnsToDisplay = ['icon', 'BankName', 'BSBAccNumber', 'AccHolder', 'AccName', 'currentBalance', 'TotalCredit', 'LoanCreditFlag', 'selector'];
  accountFilter: AccountFilter = {};
  expandedElement!: BankStatementAccount | null;
  transactionListSelectedAccounts: string[] = [];
  monthlyBalance: AccountMonthlyBalance | null = null;
  loggedInUser: User | null = getUser();
  isInternalUser = false;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.dataSource = new MatTableDataSource<BankStatementAccount>([]);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedAccounts']) {
      this.init();
    }
  }

  init() {
    this.dataSource.data = this.data?.accounts;
    this.initAccountFilter();
    this.data.accounts.forEach((account: BankStatementAccount) => {
      this.accountFilter[account.id].setValue(this.selectedAccounts.includes(account.id));
    })
    this.transactionListSelectedAccounts = this.selectedAccounts;
    this.cdr.detectChanges();
    this.isInternalUser = isInternalUser(this.loggedInUser);
  }

  initAccountFilter() {
    this.data.accounts.forEach((account: BankStatementAccount) => {
      this.accountFilter[account.id] = new FormControl<boolean>(false);
    })
  }

  isEmpty(obj: BankTransactionsWithSummary, table: 'overdrawn' | 'non-preferred-lender' | 'dishonours'): boolean {
    switch (table) {
      case 'non-preferred-lender':
        return !obj.transactions?.length && !obj.summary.numcredit && !obj.summary.numdebit;
      case 'overdrawn':
        return !obj.summary.numdebit;
      case 'dishonours':
        return !obj.summary.numdebit;
      default:
        return !obj.transactions?.length && !obj.summary.numcredit && !obj.summary.numdebit;
    }
  }

  getColumnTitles(column: string): string {
    const titles = {
      BankName: 'Bank Name',
      BSBAccNumber: 'BSB / Acc. Number',
      AccHolder: 'Acc. Holder',
      AccName: 'Acc. Name',
      currentBalance: 'Current Balance',
      TotalCredit: '$ Total Credit',
      LoanCreditFlag: 'Credit Product',
      icon: '',
      selector: ''
    };
    return titles[column as keyof typeof titles] ?? column;
  }

  getColumnValues(element: BankStatementAccount, column: string): string {
    switch (column) {
      case 'BankName':
        return element.bankName || '';
      case 'BSBAccNumber':
        return element.bsbAccNumber || '';
      case 'AccHolder':
        return element.accountHolder || '';
      case 'AccName':
        return element.accountName || '';
      case 'currentBalance':
        return element.balance.current.toString() || '';
      case 'TotalCredit':
        return element.monthlyBalance?.totalcredit.toString() || '';
      case 'LoanCreditFlag':
        return element.isCreditProduct ? 'Yes' : 'No';
      default:
        return '';
    }
  }

  needCurrencyPipe = (column: string) =>
    ['currentBalance', 'availableBalance', 'TotalCredit'].includes(column);

  needAlignRight = (column: string) =>
    ['currentBalance', 'TotalCredit', 'LoanCreditFlag'].includes(column);

  onClickRow(element: BankStatementAccount) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  onConfirmAccountSelection() {
    this.selectedAccounts = this.data.accounts.filter((account: BankStatementAccount) =>
      this.accountFilter[account.id].value
    ).map((account: BankStatementAccount) => account.id);
    this.transactionListSelectedAccounts = this.selectedAccounts;
    this.accountSelector.emit(this.selectedAccounts);
    console.log(this.selectedAccounts)
  }

  viewAccountTransactions(element: BankStatementAccount) {
    console.log(element);
    this.transactionListSelectedAccounts = [element.id];
    const el = document.getElementById("transaction-list");
    if (el) {
      el.scrollIntoView();
    }
  }
}
