<div class="aggregator-list-component">
  <mat-form-field>
    <mat-label>Aggregators</mat-label>
    <mat-select [formControl]="formControl">
      @for (o of value; track o) {
        <mat-option [value]="o">
          {{o.name}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
