import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Revenue} from '@portal-workspace/grow-shared-library';
import {Chart, TooltipItem} from 'chart.js';
import {getCssVariableContent, hexToRgb} from "@portal-workspace/grow-ui-library";

@Component({
    selector: 'monthly-revenue-chart',
    templateUrl: './monthly-revenue-chart.component.html',
    styleUrls: ['./monthly-revenue-chart.component.scss'],
    standalone: true
})
export class MonthlyRevenueChartComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: Revenue;

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  chart!: Chart | null;
  dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500'); //  '#5D40FF';
  labels: string[] = [];
  revenueList: number[] = [];

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins:{
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<'line'>) =>
            `${item.dataset.label}: ${(item.raw as number)
              .toLocaleString("en-AU",{ style:"currency", currency:"AUD" })}`,
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        },
        grid: {
          display: true,
          borderDash: [5, 5]
        }
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback: function(value: number) {
            return value.toLocaleString("en-AU",{ style:"currency", currency:"AUD" });
          }
        }
      }
    }
  };

  constructor() {}

  ngOnInit(): void {
    this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
    this.initLabels();
    this.initData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initLabels()
    this.initData();

    setTimeout(() => {
      this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  initData() {
    this.revenueList = [...this.data.monthlyrevenue].slice(0, this.labels.length).reverse();
  }

  initLabels() {
    const startDate = this.data.startdate;
    const endDate = this.data.enddate;
    const startMonth = parseInt(startDate?.slice(5, 7), 10);
    const endMonth = parseInt(endDate?.slice(5, 7), 10);
    const startYear = parseInt(startDate?.slice(0, 4), 10);
    const endYear = parseInt(endDate?.slice(0, 4), 10);
    let month = startMonth, year = startYear;

    this.labels = [];
    while (!!month && !!year && (month !== endMonth || year !== endYear)) {
      this.labels.push(`${this.months[month++ - 1]} ${year}`);
      if (month > 12) {
        month = 1;
        year++;
      }
    }
    this.labels.push(`${this.months[month++ - 1]} ${year}`);
  }

  display() {
    const chartElement = document.getElementById('monthly-revenue-chart') as any;
    if (chartElement) {
      const ctx = chartElement.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      const gradientStop0 = hexToRgb(this.dynamoneyBlue, 0.1);
      const gradientStop1 = hexToRgb(this.dynamoneyBlue, 0);
      gradient.addColorStop(0, gradientStop0);
      gradient.addColorStop(1, gradientStop1);
      // gradient.addColorStop(0, 'rgba(93, 64, 255,0.1)');
      // gradient.addColorStop(1, 'rgba(93, 64, 255,0)');
      this.chart = new Chart(chartElement, {
        type: 'line',
        options: this.chartOptions,
        data: {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: gradient,
              label: 'Monthly Revenue',
              fill: true,
              borderColor: this.dynamoneyBlue,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              pointBackgroundColor: this.dynamoneyBlue,
              data: this.revenueList
            }
          ]
        }
      })
    }
  }
}
