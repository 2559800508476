<div class="business-overdraft-pricing-component container-fluid">
  <ng-container *ngTemplateOutlet="currentTemplate">
  </ng-container>
</div>

<ng-template #readMode>
  <div class="readMode">
    <mat-card appearance="outlined">
      <mat-card-content>


        @if (isApplicationStagePostQa || isContractPending) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box type="info"
                title="Unable to edit">
                Can only edit pricing for application that is in stages before QA and
                do not have active or pending contracts generated.
              </message-box>
            </div>
          </div>
        }

        @if (!isInternalUser) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Not available for external brokers
              </message-box>
            </div>
          </div>
        }
        @if (!isAdminOrCreditUserOrSalesAMOrSalesBDM) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Only Admin, Credit, SalesAM or SalesBDM Internal Users can edit this.
              </message-box>
            </div>
          </div>
        }

        @if (lastEditedByUser) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Last edited by: {{lastEditedByUser ? lastEditedByUser : 'N/A'}}
              </message-box>
            </div>
          </div>
        }

        @if (isInternalUser) {
          <div class="row">
            <div class="col-12">
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let data">
                    <span [ngClass]="{highlight: data.highlight && data.highlight(data.value)}">
                      {{data.name}}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef>Value</th>
                  <td mat-cell *matCellDef="let data">
                    @if (data.type == 'text') {
                      <span [ngClass]="{highlight: data.highlight && data.highlight(data.value)}">{{data.value ?? ''}}</span>
                    }
                    @if (data.type == 'currency') {
                      <span [ngClass]="{highlight: data.highlight && data.highlight(data.value)}">{{data.value | looseCurrency}}</span>
                    }
                    @if (data.type == 'percentage') {
                      <span [ngClass]="{highlight: data.highlight && data.highlight(data.value)}">{{(data.value ?? '') | percentage: '1.0-2'}}</span>
                    }
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
              </table>
            </div>
          </div>
        }
      </mat-card-content>
      <mat-card-actions>
        @if (isInternalUser) {
          <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
            fxLayout.lt-md="column" fxLayoutAlign.lt-md="center start">
            <div></div>
            <button mat-flat-button
              [disabled]="!canEditPricing()"
              class="w-lt-md-100" color="primary"
              (click)="switchMode()">
              Edit
            </button>
          </div>
        }
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>

<ng-template #editMode>
  <div class="editMode">

    <mat-card appearance="outlined">
      <mat-card-content>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <facility-establishment
              [mark]="markSubject"
              [maxFacilityEstablishmentFee]="step2MaxFacilityEstablishmentFee"
              [formControl]="formControlStep2FacilityEstablishmentFeePercent">
            </facility-establishment>
          </div>
          <div class="col-lg-3 col-md-12">
            <payment-frequency-selection
              [mark]="markSubject"
              [disableControl]="isDisable"
              [formControl]="formControlStep2PaymentFrequency">
            </payment-frequency-selection>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Property owner"
              [mark]="markSubject"
              [formControl]="formControlStep2PropertyOwner">
            </yes-no>
          </div>
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Adverse on file"
              [mark]="markSubject"
              [formControl]="formControlStep2AdverseOnFile">
            </yes-no>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Equifax Score above 550"
              [mark]="markSubject"
              [formControl]="formControlStep2EquifaxScoreAboveThreshold">
            </yes-no>
          </div>
          @if (!formControlStep2EquifaxScoreAboveThreshold.value) {
            <div class="col-lg-3 col-md-12">
              <yes-no
                title="Director Score 500 - 550"
                [mark]="markSubject"
                [formControl]="formControlStep2DirectorScore">
              </yes-no>
            </div>
          }
        </div>
        @if (!formControlStep2PropertyOwner.value) {
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <yes-no title="Has the business taken another commercial loan in the past 3 years?"
                [formControl]="formControlStep2PreviousLoan" [notselected]="true">
              </yes-no>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <currency-input
              title="Requested limit"
              [mark]="markSubject"
              [formControl]="formControlStep2LoanAmount"
              [min]="step2MinLimit"
              [max]="step2MaxLimit">
            </currency-input>
          </div>
        </div>

        <div class="adjustment-section">
          <div class="row separator-bottom">
            <div class="col-12">
              <div class="mat-h3">Adjustment</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-3 col-md-12">
              <percentage-input
                title="Credit Rate adjustment"
                [mark]="markSubject"
                [min]="MIN_CREDIT_RATE"
                [max]="MAX_CREDIT_RATE"
                [formControl]="formControlCreditRateAdjustment">
              </percentage-input>
            </div>
          </div>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <div class="w-100 mt-4 mx-2" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <button mat-stroked-button color="primary" (click)="switchMode()">
            Cancel
          </button>
          <button mat-flat-button
            color="primary"
            class="w-lt-md-100"
            [disabled]="!canGenerateQuote()"
            (click)="onGenerateQuote()">
            Generate Quote
          </button>
        </div>
      </mat-card-actions>

    </mat-card>
  </div>
</ng-template>
