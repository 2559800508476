<div class="allocate-user-to-pismo-account-dialog">
  <h3 mat-dialog-title>Allocate User to Pismo Account</h3>
  <mat-dialog-content class="pt-2">
    <form [formGroup]="formGroup">
      <div class="row separator-bottom">
        <div class="col-12">
          <message-box type="info">
            Please note that this will create a new Pismo Customer account.
            Consider yourself warned.
          </message-box>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <overdraft-account-available-list
            title="Accounts available"
            [formControl]="formControlAccount"
            [userId]="data.userId"
            [overdraftAccountAvailableForUserFn]="data.overdraftAccountAvailableForUserFn">
          </overdraft-account-available-list>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <overdraft-customer-physical-card-select
            title="Physical Card Type"
            [formControl]="formControlCard">
          </overdraft-customer-physical-card-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <currency-input
            title="Monthly Limit"
            [formControl]="formControlMonthlyLimit">
          </currency-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <customer-access-level-and-role-access
            title="Customer Access level"
            [formControl]="formControlCustomerAccessLevel"
          ></customer-access-level-and-role-access>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100 pl-lt-md-3 pr-lt-md-3"
         fxLayoutGap="10px"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between"
         fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button class="ml-0 w-lt-md-100" color="primary" (click)="onSave()" [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>

