<div class="bank-transfer-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Resume Your Application</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <name title="First Name" placeholder="Enter your first name" [formControl]="formControlFirstName"></name>
      </div>
      <div class="col-lg-6 col-md-12">
        <name title="Last Name" placeholder="Enter your last name" [formControl]="formControlLastName"></name>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <email [formControl]="formControlEmail"></email>
      </div>
    </div>
        
    <br>
    <div class="row">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button> -->
        <div></div>
        <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Next</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
