import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { Subscription } from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {compareMatch, getRevenueOptions, getTradeRevenueOptions, RevenueOption} from '@portal-workspace/grow-shared-library';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '@portal-workspace/grow-ui-library';

import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';

export const nonTradeOptions = getRevenueOptions();
export const tradeOptions = getTradeRevenueOptions();

export type AnnualRevenueValue = number | null;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'annual-revenue',
    templateUrl: './annual-revenue.component.html',
    styleUrls: ['./annual-revenue.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AnnualRevenueComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AnnualRevenueComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class AnnualRevenueComponent extends AbstractControlValueAccessor<AnnualRevenueValue> implements OnInit, AfterContentChecked, Mark {

   
  @Input({required: false}) title: string | null = null;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  options: RevenueOption[] = [];

  formGroup: FormGroup<{
    annualRevenue: FormControl<RevenueOption|null>
  }>;
  formControl: FormControl<RevenueOption|null>;
  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      annualRevenue: this.formControl
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.options = nonTradeOptions;
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        console.log('********* annual rev ', r);
        if (this.formControl.valid) {
          const v = this.formControl.value;
          if (v) {
            this.propagateChange(v.value);
          } else {
            this.propagateChange(null);
          }
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }


  doWriteValue(v: AnnualRevenueValue | undefined): void | AnnualRevenueValue {
    if (v) {
      const opt = this.options.find(opt => opt.value == v);
      if (opt) {
        this.formControl.setValue(opt);
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

}
