import { Component, Inject, OnInit } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CdkTableModule, DataSource } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from "@angular/material/expansion";
import { PismoAccountTransactionsDialogData, PismoTransaction, PismoTransactionsIncludingPending } from "@portal-workspace/grow-shared-library";
import { DatePipe, JsonPipe } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";
import { CollectionViewer } from "@angular/cdk/collections";
import { ExtendedModule } from "@angular/flex-layout";
 
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatDividerModule } from '@angular/material/divider';
import { LooseDatePipe } from "../../pipes/loose-date.pipe";


export class InternalDataSource implements DataSource<PismoTransactionsIncludingPending> {

  subject: BehaviorSubject<PismoTransactionsIncludingPending[]> = new BehaviorSubject<PismoTransactionsIncludingPending[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PismoTransactionsIncludingPending[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(c: PismoTransactionsIncludingPending[]) {
    this.subject.next(c);
  }
}

@Component({
  templateUrl: './pismo-account-transactions.dialog.html',
  styleUrls: ['./pismo-account-transactions.dialog.scss'],
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
],
  standalone: true,
  imports: [
    FlexModule,
    JsonPipe,
    DatePipe,
    LooseDatePipe,
    MatButtonModule,
    ExtendedModule,
    MatExpansionModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    CdkTableModule,
    MatTableModule,
    MatDividerModule
]
})
export class PismoAccountTransactionsDialog implements OnInit {


  displayedColumns = ['id','processing_description','processing_code','created_at']
  expandedElement!: InternalDataSource | null;

  dataSource = new InternalDataSource();
  constructor(
    private dialogRef: MatDialogRef<PismoAccountTransactionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PismoTransactionsIncludingPending[]
  ) { }

  ngOnInit(){
    this.dataSource.update(this.data); 
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'created_at' : return 'Created At';
      case 'id' :  return 'Id';
      case 'processing_description' :  return 'Description';
      case 'processing_code' : return 'Code';
      default: return column;
    }
  }

  onCancel(event: Event) {
    this.dialogRef.close({});
  }
}
