import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subscription, tap } from "rxjs";
import { MinimalLayoutService } from "../../layout/minimal-layout/minimal-layout.service";
import {
  navigationUrlForAccreditation,
  navigationUrlForLogin, navigationUrlForPortalRoot,
} from '../../service/navigation-urls';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { FlexModule } from "@angular/flex-layout/flex";

@UntilDestroy({ arrayName: 'subscriptions', checkProperties: true})
@Component({
    templateUrl: './default-error.page.html',
    styleUrls: ['./default-error.page.scss'],
    standalone: true,
    imports: [FlexModule, RouterLink]
})
export class DefaultErrorPage implements OnInit{

  navigationForLogin = navigationUrlForLogin


  subscriptions: Subscription[] = [];

  constructor(private minimalLayoutService: MinimalLayoutService,
              private router: Router,){}

  navigationForPortalRoot (){
    return navigationUrlForPortalRoot().toString();
  }
  ngOnInit() {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: true,
    });
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch (r.type) {
            case 'accreditation-clicked': {
              await this.router.navigate(navigationUrlForAccreditation());
            }
          }
        }
      })
    ).subscribe());
   }
}
