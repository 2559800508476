<div class="min-max-rate-component">
  @if (title) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h4-custom">{{title}}</div>
      </div>
    </div>
  }
  @for (formGroup of formArray.controls; track formGroup) {
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          @if (formGroupControlReadonly(formGroup, 'min')) {
            <input matInput type="text" [readonly]="true" [formControl]="formGroupControl(formGroup, 'min')">
          }
          @if (!formGroupControlReadonly(formGroup, 'min')) {
            <input matInput type="text" [inputMask]="createTwoDecimalInputMask" [formControl]="formGroupControl(formGroup, 'min')" >
          }
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          @if (formGroupControlReadonly(formGroup, 'max')) {
            <input matInput type="text" [readonly]="true"  [formControl]="formGroupControl(formGroup, 'max')">
          }
          @if (!formGroupControlReadonly(formGroup, 'max')) {
            <input matInput type="text" [inputMask]="createTwoDecimalInputMask" [formControl]="formGroupControl(formGroup, 'max')">
          }
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          <input matInput type="text" [inputMask]="createTwoDecimalInputMask" [readonly]="formGroupControlReadonly(formGroup, 'rate')"  [formControl]="formGroupControl(formGroup, 'rate')">
          @if (type === 'percent') {
            <span class="mdi mdi-percent" matSuffix></span>
          }
          @if (type === 'currency') {
            <span class="mdi mdi-currency-usd" matPrefix></span>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-1 col-md-12 mt-3 mb-3">
        <span class="mdi mdi-close" matTooltip="Delete entry" (click)="onRemove($event)"></span>
      </div>
    </div>
  }
  <div class="row">
    <div class="col-12"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px">
      <button mat-stroked-button color="primary" (click)="onAdd($event)" class="mat-flat-button-custom">
        <span class="mdi mdi-plus cursor-pointer"></span>
        Add
      </button>
      <!-- <button mat-stroked-button color="primary" (click)="onRemove($event)">
      <span class="mdi mdi-minus cursor-pointer"></span>
      Remove
    </button> -->
  </div>
</div>

</div>
