<div class="application-confirmation-dialog">
  <mat-dialog-content>
    <form [formGroup]="formGroup">
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="20px" class="content">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div></div>
            <div class="mat-body">Please confirm</div>
          </div>
          @for (messageAndControl of messagesAndControls; track messageAndControl) {
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <mat-checkbox color="primary" [formControl]="messageAndControl.c"></mat-checkbox>
              <div class="mat-body">
                {{messageAndControl.m.message}}
              </div>
            </div>
          }
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="center center" fxLayoutGap="20px">
          <button color="primary" (click)="onCancel($event)" mat-stroked-button>Cancel</button>
          <button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit($event)" mat-flat-button>Submit</button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
