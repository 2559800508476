import {OriginatorBusiness} from '@portal-workspace/grow-shared-library';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {AdminService} from './admin.service';
import {catchError, map} from 'rxjs/operators';


@Injectable()
export class OriginatorBusinessResolver  {

  constructor(private adminService: AdminService,
              private activatedRoute: ActivatedRoute) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OriginatorBusiness | null> | Promise<OriginatorBusiness> | OriginatorBusiness | null {
    const originatorBusinessId = route.params.originatorBusinessId;
    console.log('*********** originatorBusinessId', originatorBusinessId);
    return this.adminService.getOriginatorById(originatorBusinessId).pipe(
      map(r => {
        console.log('***** r', r);
        return r.payload
      }),
      catchError(err => {
        console.log('OriginatorBusiness resolver err', err);
        return of(null);
      }),
    );
  }
}
