import { Component } from "@angular/core";
import {environment} from '../../../environments/environment';
import { MatButtonModule } from "@angular/material/button";
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { RouterLink } from "@angular/router";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatCardModule } from "@angular/material/card";


@Component({
    templateUrl: './faq.page.html',
    styleUrls: ['./faq.page.scss'],
    standalone: true,
    imports: [MatCardModule, FlexModule, MatDividerModule, MatExpansionModule, RouterLink, TagBoxComponent, MatButtonModule]
})
export class FaqPage {

  onStillNeedHelpClick($event: Event) {
    $event.stopPropagation();
    window.open(environment.contactUsUrl);
    return false;
  }
}
