<div class="image-with-bounding-box-component" id="{{id}}">
  <img [src]="src" class="width-100"/>

  @if (text && imageAvailable) {
    <span class="text">{{text}}</span>
  }
  @if (text && !imageAvailable) {
    <span class="text-unavailable">{{text}} UNAVAILABLE</span>
  }
</div>
