<div class="payment-frequency-selection-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Payment frequency</mat-label>
      <!--mat-select [formControl]="formControl" [compareWith]="compareWithFn" [attr.disabled]="disabled"-->
      <mat-select [formControl]="formControl" [compareWith]="compareWithFn" [disableControl]="disabled">
        @for (option of options; track option) {
          <mat-option [value]="option">{{option.name}}</mat-option>
        }
      </mat-select>
      @if (formControl.touched && formControl.hasError('required')) {
        <mat-error>This field is required.</mat-error>
      }
    </mat-form-field>
  </form>
</div>
