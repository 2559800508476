import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import moment from 'moment';
import numeral from 'numeral';
import { map } from 'rxjs/operators';
import {Moment} from 'moment';
import {
  ApplicationApplicant,
  CheckIfWhitelableTitleExistsFn,
  ConfirmPasswordValue,
  GuarantorValue, Individual,
  IndividualGuarantor,
  ValidEmailCheckFn
} from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
////// originally from app's service/validators

export const requiredAllowEmptyValidator: (control: AbstractControl) => ValidationErrors | null = (control) => {
  return control.value === null || control.value === undefined ?
    {'requiredAllowEmpty': true} :
    null;
};

export const confirmPasswordValidator: <T extends ConfirmPasswordValue>(formControlPassword: FormControl<T>, formControlConfirmPassword: FormControl<T>) => ValidatorFn =
  (formControlPassword, formControlConfirmPassword) =>
    (control) => {
      if (formControlPassword.value && formControlConfirmPassword.value) {
        if (formControlPassword.value.trim() === formControlConfirmPassword.value.trim()) {
          return null; // success
        }
        return {
          'confirmPassword': true // error
        };
      }
      return null; // success
    }

export const productsSelectedValidator: (formGroup: FormGroup) => ValidatorFn = (formGroup) => (control) => {
  const formGroupSelectedProducts = formGroup as FormGroup;
  const anySelected =
    !!((formGroupSelectedProducts.controls as any).assetFinance.value) ||
    !!((formGroupSelectedProducts.controls as any).businessLoan.value) ||
    !!((formGroupSelectedProducts.controls as any).insurancePremium.value)
  return anySelected ?
    null :  // pass validation
    { productSelected: true }; // failed validation
}

export const guarantorValidator: (fn: ()=>{
  mandatoryGuarantors: IndividualGuarantor[],
  guarantors: GuarantorValue,
}) => ValidatorFn = (fn) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const r = fn();
    const individualGuarantors = r.guarantors?.filter(g => g.type === 'Individual');
    if (r.mandatoryGuarantors.length == 0 && individualGuarantors && individualGuarantors.length == 0) {
      return { noGuarantors: true };
    }
    return null;
  }
}

export const trusteeValidator: (fn: () => {
  applicant: ApplicationApplicant
}) => ValidatorFn = (fn) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const r = fn();
    const applicant = r.applicant
    if (Array.isArray(applicant)) {
      for (const trustee of applicant) {
        if (trustee.kind == 'Trustee' &&
            trustee.type == 'Entity' &&
            trustee.organisationType &&
            trustee.organisationType.type == 'trust') {
          return { trustForTrustee: true }
        }
      }
    }
    return null;
  }
}

export const partnerValidator: (fn: () => {
  applicant: ApplicationApplicant
}) => ValidatorFn = (fn) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const r = fn();
    const applicant = r.applicant
    if (Array.isArray(applicant)) {
      for (const partner of applicant) {
        if (partner.kind == 'Partner' && partner.type == 'Entity') {
          if (!partner.acn || partner.acn == "000000000") {
            return { partnerWithNoAcn: true }
          }
        }
      }
    }
    return null;
  }
}

export const nameIncludeDigitsWithoutBlankSpaceValidator = () => {
  return Validators.pattern(/^(?!\s)[a-zA-Z0-9_\s\-/,-]+$/)
}


export const validWhitelabelTitleAsyncValidator = (fn: CheckIfWhitelableTitleExistsFn): AsyncValidatorFn => {
  return (control) => {
    const title = control.value?.toString();
    return fn(title).pipe(
      map(r => {
        if (r) { // exists, not valid
          return {
            whitelabelTitle: true,
          };
        }
        return null;
      })
    );
  };
}


//////////////////////////////////////////////////////////////

const momentDateRequired: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const v = control.value;
  if (!!!v) {
    return { required: true }
  }
  if (moment.isMoment(v) && (!moment(v).isValid())) {
    return { required: true }
  }
  return null;
}


const validAge: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const val = (control as FormControl).value;
  if (val) {
    const m = typeof val == 'string' ? moment(val, 'DD/MM/YYYY') : moment(val);
    const now = moment();
    const diffInYears = Math.abs(now.diff(m, 'year'));
    if (diffInYears < 18) {
      return {
        'lessThan18YearsOld': true
      }
    }
    if(diffInYears > 100){
      return {
        'greaterThan100YearsOld': true
      }
    }
  }
  return null;
}

const validDateFormat: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const val = (control as FormControl).value;
  if (val) {
    const m = moment(val, true);
    if (!m.isValid()) {
      return {
        invalidDateFormat: true
      }
    }
  }
  return null;
}

const validHexColor: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const regExp = /#[0-9A-Fa-f]{6}/g;
  const val = ((control).value ?? '').toString();
  if (val) {
    if (!regExp.test(val)) {
      return {
        invalidHexColor: true
      }
    }
  }
  return null;
}

const validHexOrRgbColor: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const regExpHex   =  /#[0-9A-Fa-f]{6}/g;
  const regExpRgb   =   /rgb\(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\)/g;
  const regExpRgba  =  /rgba\(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]),([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]),(0(\.[0-9])?|1(\.0)?)\)/g;
  const val = ((control).value ?? '').toString();
  const rHex = regExpHex.test(val);
  const rRgb = regExpRgb.test(val);
  const rRgba = regExpRgba.test(val);
  if (val) {
    if (!rHex && !rRgb && !rRgba) {
      return {
        invalidHexOrRgbColor: true
      }
    }
  }
  return null;
}

const validUrl: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const regExp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  const val = ((control).value ?? '').toString();
  if (val) {
    if (!regExp.test(val)) {
      return {
        invalidUrl: true,
      }
    }
  }
  return null;
}


const validBusinessName: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let rgxPattern = /^[A-Za-z0-9 _@#&-]+$/;
  const val = (control as FormControl).value;
  if (val) {
    if (!rgxPattern.test(val)) {
      return {
        invalidBusiness: true
      }
    }
  }
  return null;
}
const minMax: (min: number, max: number) => ValidatorFn = (min, max) => {
  return (control: AbstractControl) => {
    const val = (control as FormControl).value;
    if (val !== undefined && val !== null) {
      const v = numeral(val);
      if (v.value()! < min || v.value()! > max) {
        return {
          minmax: { min, max, actual: v.value() },
        }
      }
    }
    return null;
  }
}

const minMaxExclMax: (min: number, max: number) => ValidatorFn = (min, max) => {
  return (control: AbstractControl) => {
    const val = (control as FormControl).value;
    if (val !== undefined && val !== null) {
      const v = numeral(val);
      if (v.value()! < min || v.value()! >= max) {
        return {
          minmax: { min, max, actual: v.value() },
        }
      }
    }
    return null;
  }
}

const minFn: (min: number) => ValidatorFn = (min) => {
  return (control: AbstractControl) => {
    const val = (control as FormControl).value;
    if (val !== undefined && val !== null) {
      const v = numeral(val);
      if (v.value()! < min) {
        return {
          min: { min, actual: v.value() }
        }
      }
    }
    return null;
  }
}

const maxFn: (max: number) => ValidatorFn = (max) => {
  return (control: AbstractControl) => {
    const val = (control as FormControl).value;
    if (val !== undefined && val !== null) {
      const v = numeral(val);
      if (v.value()! > max) {
        return {
          max: { max, actual: v.value() }
        }
      }
    }
    return null;
  }
}

const validVIN: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let vin = control.value
  let vinLength = 17;
  let regEx = "[A-HJ-NPR-Z0-9]{17}";
  if (vin === '') {
    return { vinRequired: true }
  }
  if (vin) {
    if (vin.length !== vinLength) {
      return { invalidLength: true }
    }
    vin = vin.toUpperCase();

    if (!(new RegExp(regEx, 'g').test(vin))) {
      return { invalidChars: true }
    }
  }
  return null
}

const validRego: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const rego = control.value;
  if (rego) {
    if (rego.length > 10) {
      return { invalidRego: true }
    }
  }
  return null;
}

const uniqueVIN = (VINs: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (VINs.includes(control.value)) {
      return { valuesNotUnique: true }
    }
    return null
  }
}

const requiredToBeTrue = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === true) {
      return null;
    }
    return {requiredToBeTrue: true};
  }
}

const minDate = (min: Moment): ValidatorFn => {
  const v: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const d = control.value;
    if (d) {
      if (typeof d === 'string') {
        const m = moment(d, 'DD/MM/YYYY');
        if(m.isValid()) {
          if (min.startOf('day').isAfter(m)) { // min date is after given date - error
            return {
              minDate: {min: min.format('DD/MM/YYYY')},
            }
          }
        }
      } else if (moment.isMoment(d)) {
        if (min.startOf('day').isAfter(d)) { // min date is after given date - error
          return {
            minDate: {min: min.format('DD/MM/YYYY')},
          }
        }
      }
    }
    return null;
  }
  return v;
}

const maxDate = (max: Moment): ValidatorFn => {
  const v: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const d = control.value;
    if (d) {
      if (typeof d === 'string') {
        const m = moment(d, 'DD/MM/YYYY');
        if(m.isValid()) {
          if (max.startOf('day').isBefore(m)) { // max date is before given date - error
            return {
              maxDate: {max: max.format('DD/MM/YYYY')},
            }
          }
        }
      } else if (moment.isMoment(d)) {
        if (max.startOf('day').isBefore(d)) { // max date is before given date - error
          return {
            maxDate: {max: max.format('DD/MM/YYYY')},
          }
        }
      }
    }
    return null;
  }
  return v;
}

export const maxDateValidator = (max: Moment): ValidatorFn => {
  return maxDate(max);
}

export const minDateValidator = (min: Moment): ValidatorFn => {
  return minDate(min);
}

export const minMaxValidator = (min: number, max: number): ValidatorFn => {
  return minMax(min, max);
}

export const minMaxExcludingMaxValidator = (min: number, max: number): ValidatorFn => {
  return minMaxExclMax(min, max);
}

export const minValidator = (min: number): ValidatorFn => {
  return minFn(min);
}

export const maxValidator = (max: number): ValidatorFn => {
  return maxFn(max);
}

export const validAgeValidator = (): ValidatorFn => {
  return validAge;
}

export const momentDateRequiredValidator = (): ValidatorFn => {
  return momentDateRequired;
}

export const validDateFormatValidator = (): ValidatorFn => {
  return validDateFormat;
}

export const validVINValidator = (): ValidatorFn => {
  return validVIN;
}

export const validRegoValidator = (): ValidatorFn => {
  return validRego;
}

export const requiredToBeTrueValidator = (): ValidatorFn => {
  return requiredToBeTrue();
}

export const uniqueVINValidator = (VINs: string[]): ValidatorFn => {
  return uniqueVIN(VINs)
}

export const validBusinessValidator = (): ValidatorFn => {
  return validBusinessName;
}

export const validHexColorValidator = (): ValidatorFn => {
  return validHexColor;
}

export const validHexOrRgbColorValidator = (): ValidatorFn => {
  return validHexOrRgbColor;
}

export const validUrlValidator = (): ValidatorFn => {
  return validUrl;
}


export const duplicateFileNameValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // if no file is selected or existingFileNames is empty, validation passes
    }
      const duplicateFileNames = Object.entries(
        _.countBy(control.value.map((file:File) => file.name))) // array group by filename and it's count eg. [{'file1': 2, 'file2': 1, 'file3': 1}]
        .filter(entry => entry[1] > 1)      // filter those have count > 1
        .map(entry => entry[0])

     if (duplicateFileNames.length) {
       return {
         uploadDuplicateFile: {
           filename: duplicateFileNames.toString()
         }
       };
     }

    return null; // No error, validation passes
  };
}

export const isMissingTagsCheckValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // if no file is selected or existingFileNames is empty, validation passes
    }

    const isMissingTagsCheck = control.value.filter((file:any) => file.tags && file.tags.length === 0) ;

     if (isMissingTagsCheck.length) {
      const fileNames = isMissingTagsCheck.map( (file:File) => file.name).join(', ');
       return {
        fileMissingTag: {
           filename: fileNames.toString()
         }
       };
     }

    return null; // No error, validation passes
  };
}

export const minFileSizeValidator = (minFileSize: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value){
      return null;
    }
    const oversizedFiles = control.value
      .filter((file: File) => file.size < minFileSize)
      .map((file: File) => file.name);
    if (oversizedFiles.length) {
      return {
        uploadMinFileSize: {
          filename: oversizedFiles.toString(),
          minFileSize: minFileSize
        }
      }
    }
    return null;
  }
}

export const maxFileUploadValidator = (maxFilesAllowed:number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

     if (control.value.length > maxFilesAllowed) {
       return {
        uploadMaximumFile: {
          maxFiles: maxFilesAllowed,
          total: control.value.length
         }
       };
     }

    return null; // No error, validation passes
  };
}

export const duplicateIndividualEmailValidator = (individuals: Individual[], exclude: Individual[] = []): ValidatorFn => {
  return (control: AbstractControl) => {
    const allEmails: string[] = individuals
      .filter(i => !i.deleted)
      .filter(i => !exclude.includes(i))
      .map(i => i.Email.trim().toLowerCase());  // Convert emails to lowercases
    const e = control.value;
    if (e) {
      const normalizedEmail = e.trim().toLowerCase();  // Normalize the control value
      const alreadyExists = allEmails.includes(normalizedEmail);
      if (alreadyExists) {
        return { 'duplicateIndividualEmail': true };
      }
    }
    return null;
  };
};


export const duplicateLocalEmailValidator = (existingEmailsFn: () => string[], errorMessageKey: string, checkLength: number = 0): ValidatorFn => {
  return (control: AbstractControl) => {
    const email = control.value?.trim().toLowerCase(); // Normalize the email
    if (!email) return null; // If email is empty, return null
    const existingEmails = existingEmailsFn().map(e => e.trim().toLowerCase()); // Normalize existing emails
    if (checkLength === 0) {
      if (existingEmails.includes(email)) {
        return { [errorMessageKey]: true };
      }
    } else if (checkLength === 1) {
      const filteredEmails = existingEmails.filter(guarantorEmail => guarantorEmail === email);
      if (filteredEmails.length > 1) {
        return { [errorMessageKey]: true };
      }
    }

    return null;
  };
};





const validatePhone: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let phone = control.value
  const phoneRegEx = /(^1300\d{6}$)|(^1800|1900|1902\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\d{4}$)|(^04\d{2,3}\d{6}$)/;
  if (phone) {
    if (!(new RegExp(phoneRegEx, 'g').test(phone))) {
      return { invalidPhone: true }
    }
  }
  return null
}
export const phoneValidator = (): ValidatorFn => {
  return validatePhone;
}

const validateNoSpace: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let controlValue = control.value
  const noSpaceRegEx = /(^(?!\s*$).+)/;
  if (controlValue) {
    if (!(new RegExp(noSpaceRegEx, 'g').test(controlValue))) {
      return { noSpace: true }
    }
  }
  return null
}

export const noSpaceValidator = (): ValidatorFn => {
  return validateNoSpace;
}

export const duplicateIndividualMobileValidator = (individuals: Individual[], exclude: Individual[] = []): ValidatorFn => {
  return (control: AbstractControl) => {
    const allMobiles: string[] = individuals
      .filter(i => !i.deleted)
      .filter(i => !exclude.includes(i)).map(i => i.MobileNumber);
    const m = control.value;
    if (m) {
      const alreadyExists = allMobiles.includes(m);
      if (alreadyExists) {
        return { 'duplicateIndividualMobile': true }
      }
    }
    return null;
  }
}

// NOTE: use ValidEmailCheckFn from share lib
// export type ValidEmailCheckFn = _ValidEmailCheckFn;
export const validEmailValidator = (validEmailCheckFn: ValidEmailCheckFn): AsyncValidatorFn => {
  return (control: AbstractControl) => {
    return validEmailCheckFn(control.value).pipe(
      map((r => {
        if (r) {
          return null;
        } else {
          return { 'EmailExist': true };
        }
      }))
    );
  }
}

export const validAbnValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string ]: any} | null => {
    if (typeof control.value === 'string') {
      const val = control.value.toString() ?? '';
      const abnRegexp = /^(\d *?){11}$/g;
      const isValidAbn = abnRegexp.test(control.value ?? '');
      return isValidAbn ? null : { 'invalidAbn': true };
    }
    return null;
  }
}

export const autocompleteObjectValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { 'invalidAutocompleteObject': { value: control.value } }
    }
    return null;
  }
}
const validateYear: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.value) {
    let date = new Date();
    let minYear = 1900;
    let currentYear = date.getFullYear();
    if (control.value > currentYear) {
      return {'currentYear': true};
    }
    if(control.value < minYear){
      return {'currentYear': false};
    }
  }
  return null
}
export const validYearValidator = (): ValidatorFn => {
  return validateYear;
}

const mustBeTodayOrFutureDateValidatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.value) { // only validate when there is a date
    let currentDate = moment(new Date()).format('YYYY-MM-DD');
    let value = moment(control.value, "DD/MM/YYYY").format('YYYY-MM-DD');

    if (moment(value).isBefore(currentDate)) {
      return { 'mustBeTodayOrFutureDate': true };
    }
  }
  return null;
}
export const mustBeTodayOrFutureDateValidator = (): ValidatorFn => {
  return mustBeTodayOrFutureDateValidatorFn;
}


const mustSelectFromSelectOptionValidatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const v = control.value;
  if (v) { // assuming that if you have type things in, it will be string instead of object
    if (typeof v === 'string') {
      return { required: true }
    }
  }
  return null;
}

export const mustSelectFromSelectOptionValidator = (): ValidatorFn => {
  return mustSelectFromSelectOptionValidatorFn;
}
