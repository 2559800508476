import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessTypeIconComponent } from './business-type-icon.component';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
@NgModule({
    imports: [
        CommonModule,
        AngularMaterialLibraryModule,
        BusinessTypeIconComponent
    ],
    exports: [
        BusinessTypeIconComponent,
    ]
})
export class BusinessTypeIconModule {

}
