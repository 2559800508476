import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  CollapsableExpandableTextValue,
  countWordsInText,
  textWithLineBreaksToHtml,
  wordsInText
} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'collapsable-expandable-text',
    templateUrl: './collapsable-expandable-text.component.html',
    styleUrls: ['./collapsable-expandable-text.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CollapsableExpandableTextComponent), multi: true }
    ],
    standalone: true,
    imports: []
})
export class CollapsableExpandableTextComponent extends AbstractControlValueAccessor<CollapsableExpandableTextValue> implements OnInit {

  DEFAULT_THRESHOLD = 20;

  v?: CollapsableExpandableTextValue | null;              // the whole text (formatted)
  s?: CollapsableExpandableTextValue | null;              // the shortened text
  c: number = 0;                                          // the total number of words in the original whole text
  mode: 'expandable' | 'collapsable' | 'none' = 'none';   // mode component is in
                                                          //    - expandable      -> can be expanded
                                                          //    - collapsable     -> can be collapsed
                                                          //    - none            -> not collapsable or expandable because words threshold aren't meet (display all)

  @Input({required: false}) value: CollapsableExpandableTextValue | null = null;
  @Input({required: false}) threshold: number = this.DEFAULT_THRESHOLD;

  ngOnInit() {
    this.reformat(this.value);
    this.propagateChange(this.v);
  }

  doWriteValue(v: CollapsableExpandableTextValue | null | undefined): void | CollapsableExpandableTextValue {
    this.reformat(v);
  }

  reformat(text?: string | null) {
    const t = text ?? '';
    const r = textWithLineBreaksToHtml(t);
    this.s = wordsInText(t, this.threshold);
    this.c = countWordsInText(t);
    const newline = t.split(/\r?\n/).length;
    this.mode = this.c > this.threshold ? 'expandable' : 'none'
    if(newline > 10){
      this.mode = newline > 10 ? 'expandable' : 'none'
    }
    this.v = r;
    // this.v = r + '<a class="show-more">Show less..</a>';
  }

  expandText($event: Event) {
    this.mode = 'collapsable';
  }

  collapseText($event: Event) {
    this.mode = 'expandable';
  }
}
