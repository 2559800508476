export type RedbookSearch =
  RedbookSearchByMake | RedbookSearchByFamilies | RedbookSearchByYears | RedbookSearchByVehicles | RedbookSearchByVehspec;
export type RedbookSearchType = 'car' | 'truck' | 'all';
export type RedbookSearchByMake = {action: 'makes', type: RedbookSearchType};
export type RedbookSearchByFamilies = {action: 'families', makecode: string, type: RedbookSearchType};
export type RedbookSearchByYears = {action: 'years', makecode: string, familycode: number, type: RedbookSearchType};
export type RedbookSearchByVehicles = {action: 'vehicles', makecode: string, familycode: number, year: number, type: RedbookSearchType};
export type RedbookSearchByVehspec = {action: 'vehspec', rbc: string, type: RedbookSearchType};
export type RedbookSearchByMakeResponse = {
  makecode: string,
  description: string,
  latestyear: number,
  vehicletypes: string,
}[];
export type RedbookSearchByFamiliesResponse = {
  makecode: string,
  familycode: number,
  vehicletype: string,
  description: string,
  latestyear: number,
}[];
export type RedbookSearchByYearsResponse = {
  makecode: string,
  familycode: number,
  vehicletype: string,
  year: number,
  month: number,
  description: number | string,
}[];
export type RedbookSearchByVehiclesResponse = {
  avgretail: number,
  avgwhs: number,
  bodystyle: string,
  description: string,
  familycode: number,
  goodretail: number,
  goodwhs: number,
  importflag: string,
  makecode: string,
  month: number,
  newprice: number,
  rbc: string | null,
  rbid: number,
  vehicletype: string,
  year: number
}[];
export type RedbookSearchByVehspecResponse = {
  avgkm: number,
  avgretail: number,
  avgwhs: number,
  badge: string,
  badge2nd: string,
  bodyconfig: string,
  bodystyle: string,
  cylinders: number,
  description: string,
  doornum: number,
  drive: string,
  enginedesc: number,
  enginesize: number,
  extrainfo: string,
  familycode: number,
  fueltype: string,
  gdkm: number,
  gearlocation: string,
  gearnum: number,
  geartype: string,
  goodretail: number,
  goodwhs: number,
  importflag: string,
  induction: string,
  limitededition: boolean,
  makecode: string,
  month: number,
  newprice: number,
  newvehicleflag: boolean,
  rbid: number,
  redbookcode: string,
  roofline: string,
  sequenceno: number,
  series: string,
  seriesmodelyear: string,
  vehicletype: string,
  wheelbaseconfig: string,
  year: number,
}[];
export type RedbookSearchResponse<T> =
  T extends RedbookSearchByMake ? RedbookSearchByMakeResponse :
    T extends RedbookSearchByFamilies ? RedbookSearchByFamiliesResponse :
      T extends RedbookSearchByYears ? RedbookSearchByYearsResponse :
        T extends RedbookSearchByVehicles ? RedbookSearchByVehiclesResponse :
          T extends RedbookSearchByVehspec ? RedbookSearchByVehspecResponse : never;

export type TruckGrossVehicleWeight = TruckGrossVehicleWeightSelection.Light | TruckGrossVehicleWeightSelection.Heavy | TruckGrossVehicleWeightSelection.Massive;
export enum TruckGrossVehicleWeightSelection {
  Light = 'Trucks <= 4.5T GVM',
  Heavy = 'Trucks between 4.5T and 12T GVM',
  Massive = 'Trucks > 12T GVM'
}
