import {Component} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { CreditInfoExchangeAlertDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './credit-info-exchange-alert.dialog.html',
    styleUrls: ['./credit-info-exchange-alert.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MessageBoxComponent, MatButtonModule, MatDialogModule]
})
export class CreditInfoExchangeAlertDialog {

  constructor(private matDialogRef: MatDialogRef<CreditInfoExchangeAlertDialog, CreditInfoExchangeAlertDialogResult>) {
  }

  onCancel($event: MouseEvent) {
   this.matDialogRef.close({ proceed: false});
  }

  onProceed($event: MouseEvent) {
    this.matDialogRef.close({ proceed: true});
  }
}
