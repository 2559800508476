<div class="copy-application-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Copy this application into</div>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <mat-form-field>
          <mat-label>Application Type</mat-label>
          <mat-select [formControl]="formControl">
            @for (option of applicationTypeOptions; track option) {
              <mat-option [value]="option.type">{{option.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formControl.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
