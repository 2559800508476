import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { WidgetDetails } from '@portal-workspace/grow-shared-library';
import lodash from 'lodash';
import {PercentagePipe} from '../../../pipes/percentage.pipe';

import { CustomContentLoaderComponent } from '../../custom-content-loader-component/custom-content-loader.component';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'portal-dashboard-top-widget',
    templateUrl: './dashboard-top-widget.component.html',
    styleUrls: ['./dashboard-top-widget.component.scss'],
    standalone: true,
    imports: [CustomContentLoaderComponent,PercentagePipe,MatCardModule]
})
export class DashboardTopWidgetComponent implements OnInit, OnChanges {
  @Input({required: false}) details!: WidgetDetails;
  @Input({required: false}) loading!: boolean | null;

  lodash = lodash;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['loading']) {
      this.loading = changes['loading'].currentValue;
    }
  }
}
