<div class="pismo-edit-account-dialog m-4">
  <h3 mat-dialog-title>Edit Pismo Account</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <currency-input title="Overdraft Limit" type="text" matInput [formControl]="formControlOverdraftLimit">
        </currency-input>
        @if (errorMessage) {
          <mat-error class="matError">
            {{errorMessage}}
          </mat-error>
        }
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-card appearance="outlined" class="mb-4 custom-card">
          <mat-card-content>
            <div class="row">
              <div class="col-md-1 mt-2">
                <span class="mdi mdi-check-circle-outline mt-4"></span>
              </div>
              <div class="col">
                <span class="row list-header">Account is
                  @if (this.data.accountDetails.status === 'NORMAL') {
                    <span class="ml-1"> active</span>
                  }
                  @if (this.data.accountDetails.status === 'BLOCKED') {
                    <span class="ml-1"> blocked</span>
                  }
                  @if (this.data.accountDetails.status === 'CANCELLED') {
                    <span class="ml-1"> cancelled</span>
                  }
                </span>
                <span class="row list-description">Switch the toggle
                  @if (this.data.accountDetails.status === 'NORMAL') {
                    <span class="ml-1 mr-1"> off to block </span>
                  }
                  @if (this.data.accountDetails.status !== 'NORMAL') {
                    <span class="ml-1 mr-1"> on to unblock</span>
                  }
                  this account
                </span>
              </div>
              <div class="col-md-1 mt-2 mr-2">
                <slide-toggle [showCancelConfirmation]="true"
                  cancelConfirmationMessage="Are you sure you want to block this account?"
                  [formControl]="formControlEnabled">
                </slide-toggle>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row" [ngClass]="{hidden: !showStatusReason}">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Reason for blocking the account</mat-label>
          <mat-select placeholder="Select reason..." [formControl]="formControlStatusReason" [compareWith]="accountStatusReasonCompareWithFn">
            @for (option of accountStatusReasons; track option) {
              <mat-option [value]="option">{{option.description}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave()"  [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>
