<div class="armotization-chart-component">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row">
      <div class="mat-h2">Amortization chart</div>
      <div fxFlex="1 1 auto"></div>
      <div>
        <datepicker  title="Estimated draw down date" [formControl]="formControl"></datepicker>
        <!--
        <mat-form-field class="datepicker">
          <mat-label>Estimated draw down date</mat-label>
          <input matInput (dateChange)="onDateChange($event)" [formControl]="formControl" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        -->
      </div>
    </div>
    <div>
      <mat-radio-group aria-label="Select an option" value="annual" (change)="onPeriodChange($event)" fxLayout="row" fxLayoutGap="10px">
        <mat-radio-button color="primary" value="annual">Annual</mat-radio-button>
        <mat-radio-button color="primary" value="quarterly">Quarterly</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="chart">
      <!--canvas #chartCanvas id="{{chartId}}"></canvas-->
      <canvas #chartCanvas></canvas>
    </div>
    <div class="legend" fxLayout="row" fxLayoutGap="20px">
      <div>
        <span class="principal-paid-logo"></span>
        <span class="principal-paid-logo-text mat-body">Principal paid</span>
      </div>
      <div>
        <span class="interest-paid-logo"></span>
        <span class="interest-paid-logo-text mat-body">Interest paid</span>
      </div>
      <div>
        <span class="balance-logo"></span>
        <span class="balance-logo-text mat-body">Balance</span>
      </div>
    </div>
  </div>
</div>
