import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {PaymentChartComponent} from './payment-chart.component';
import {AmortizationChartComponent} from './amortization-chart.component';
import {TotalPaymentBreakupComponent} from './total-payment-breakup.component';
import {RepaymentEstimationComponent} from './repayment-estimation.component';
import {AmortisationScheduleComponent} from './amortisation-schedule.component';
import {DatepickerModule} from '../datepicker-component/datepicker.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { CommissionChartComponent } from './commission-chart.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        DatepickerModule,
        PipeLibraryModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PaymentChartComponent,
        AmortizationChartComponent,
        RepaymentEstimationComponent,
        AmortisationScheduleComponent,
        TotalPaymentBreakupComponent,
        CommissionChartComponent,
    ],
    exports: [
        PaymentChartComponent,
        AmortizationChartComponent,
        RepaymentEstimationComponent,
        AmortisationScheduleComponent,
        TotalPaymentBreakupComponent,
        CommissionChartComponent,
    ],
   // NOTE: not needed, we should use loose-currency pipe
   // providers: [
   //   {provide: CurrencyPipe, useClass: CurrencyPipe},
   // ]
})
export class ChartModule {

}
