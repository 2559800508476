import {Component, forwardRef, Input} from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { TitleSelectionValue, TitleSelectionValueOptions } from '@portal-workspace/grow-shared-library';
import { Subject } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@Component({
    selector: 'title-selection',
    templateUrl: './title-selection.component.html',
    styleUrls: ['./title-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TitleSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => TitleSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, NgClass, ExtendedModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class TitleSelectionComponent extends AbstractControlValueAccessor<TitleSelectionValue> implements Mark{

  options = TitleSelectionValueOptions;
  markObservable: Subject<boolean> = new Subject<boolean>();

  formGroup: FormGroup<{
    state: FormControl<TitleSelectionValue>
  }>;
  formControl: FormControl<TitleSelectionValue>;
  @Input({required: false}) readonly = false;


  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      state: this.formControl,
    })
  }

  onSelectionChange($event: MatSelectChange) {
    const val: TitleSelectionValue = $event.source.value;
    this.propagateChange(val);
  }


  // setDisabledState(isDisabled: boolean): void {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formGroup.disable();
  //   } else {
  //     this.formGroup.enable();
  //   }
  // }

  doWriteValue(v: TitleSelectionValue | undefined): void {
    if (v) {
      const index = this.options.findIndex(r => (r && r.type === v.type));
      if (index !== -1) {
        this.formControl.setValue(this.options[index]);
      }
    } else {
      this.formControl.setValue(null);
    }
  }
  mark(): void {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}
