import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { httpOptions, paginationUrl, PortalHotToastService, toPaginationInfo, } from '@portal-workspace/grow-ui-library';
import {
  CustomerOpportunity,
  CustomerUser,
  CreateOverdraftAccountBody,
  PismoCreateAccountResponse,
  SfAccountDetails,
  PismoAccountMapping,
  UserWithPismoMapping,
  CreateOverdraftUserBody,
  PismoCreateCustomerResponse,
  PismoGetAccountResponse,
  PismoProgram,
  PismoProgramDueDate,
  PismoProgramDueDateResponse,
  OverdraftAccountPostFeeBody,
  KycStatus,
  CreateOverdraftUserResponse,
  PismoAccountTransactionsDialogData,
  PismoCardDetailsWithAccountId,
  PismoActivateCardBody,
  PismoChangeCardStatus,
  DEFAULT_OFFSET,
  DEFAULT_LIMIT,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PismoUpdateCustomerBody,
  PismoUpdateCustomerResponse,
  PismoAccountCustomFields,
  CreateOverdraftAccountData,
  PismoAccountAndOpportunityResponse,
  PismoTransactionsIncludingPending,
  PismoAccountUpdateLimits,
  PismoUpdateLimitsResponse,
  PismoGetAccountStatusReasonsResponse,
  UpdateAccountStatus,
  PismoGetAccountStatusResponse,
  PismoGetTransactionTypesResponse,
  PismoCardDetails,
  SearchOverdraftCustomerResult,
  OverdraftCustomerSearchValue,
  PismoUpdateCardStatusResponse,
  PismoTransaction,
  GetPismoAccountAndOpportunityDetailsFn,
  PismoAccountMappingAndApplication,
  UpdatePismoAccountPaymentLimitFn,
  PismoTransactionType,
  PismoPostManualTransactionBalance,
  PismoTransactionFlow,
  PismoTransactionFlowItems,
  PismoGetTransactionType,
  GetPismoTransactionsFn,
  GetTransactionDetailsFn,
  GetPismoAccountDetailsFn,
  GetCustomerPismoAccountMappingsFn,
  CreateOverdraftAccountFn,
  GetPismoCustomerForAccountFn,
  SearchPismoAccountMapping,
  GetPismoAccountMappingFn,
  Payout,
  PismoReportType,
  ValidEmailCheckFn,
  PismoBuiltInTransactionType,
  PismoCardReIssueReason,
  PismoReissueCardDetails,
  PismoReissueCardResponse,
  PayoutNotificationBody,
  GeneratePayoutBody,
  GetCardFn,
  GetTransactionFromTimelineFn,
  PismoPendingTransaction,
  GetTokenInfoFn,
  UpdatePismoUserAccessBody,
  OverdraftAccountAvailable,
  OverdraftAccountAvailableForUserFn,
  AllocateUserToPismoAccountData,
  AllocateUserToPismoAccountFn,
  PismoAggregatedAccountingRecord,
  GetPismoStatementsFn,
  PismoListStatementsResponse,
  PismoStatement,
  PismoStatementDetail,
  OverdraftStatementTemplateData,
  OverdraftAccountLimitIncreaseBody,
  OverdraftAccountLimitIncreaseResponse,
  ManualDirectDebitBody,
  PismoCardTokenInfo,
  CreateAccountNoteBody,
  PismoAccountNotes,
  CustomerAccessLevelAndRoleAccessValue,
  PismoUpdateMonthlyFacilityFeeBody,
  PismoTimeline,
  PismoUpdateAmortisedRepaymentBody,
  CreateCustomerUserData,
} from '@portal-workspace/grow-shared-library';
import { ApiResponse, PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import { combineLatest, forkJoin, Observable, Observer, of, throwError } from 'rxjs';
import { catchError, combineLatestWith, first, map, switchMap, tap } from 'rxjs/operators';
import { GetPismoNotesByAccountNumberFn } from 'libs/grow-ui-library/src/lib/components/overdraft-customer-components/pismo-notes.component';

const URL_GET_OPPORTUNITIES_FOR_CUSTOMER = (salesforceId: string) => `${environment.api2Host}/api2/get-opportunities-for-customer/${salesforceId}`; // get
const URL_GET_PROGRAMS = () => `${environment.api2Host}/api2/pismo/programs`; //get
const URL_GET_ACCOUNT_DETAILS_FROM_SF = (salesforceId: string) => `${environment.api2Host}/api2/get-account-details-from-sf/${salesforceId}`; // get
const URL_CREATE_OVERDRAFT_ACCOUNT = () => `${environment.api2Host}/api2/create-overdraft-account`; // post
const URL_GET_CUSTOMER_PISMO_ACCOUNT_MAPPINGS = (customerId: number) => `${environment.api2Host}/api2/get-customer-pismo-account-mappings/${customerId}`; // get
const URL_GET_PISMO_ACCOUNT_MAPPING = (pismoAccountNumber: number) => `${environment.api2Host}/api2/pismo/get-pismo-account-mappings/${pismoAccountNumber}`; // get
const URL_GET_PISMO_CUSTOMER_FOR_ACCOUNT = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/get-pismo-customer-for-account`, paginationInfo); // post
const URL_CREATE_OVERDRAFT_USER = () => `${environment.api2Host}/api2/create-overdraft-user`; // post
const URL_GET_PISMO_ACCOUNT_DETAILS = () => `${environment.api2Host}/api2/pismo/get-account-details`; // post
const URL_GET_PROGRAM_DUE_DATE = (programId: number) => `${environment.api2Host}/api2/pismo/get-program-due-date/${programId}` //get
const URL_OVERDRAFT_ACCOUNT_POST_FEE = () => `${environment.api2Host}/api2/overdraft-account-post-fee`;
const URL_UPDATE_USER_KYC_STATUS = () => `${environment.api2Host}/api2/update-user-kyc-status`;
const URL_PISMO_LIST_TRANSACTIONS = (accountId: number, paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/pismo/list-transactions-for-account/${accountId}`, paginationInfo) //get
const URL_GET_CARDS_FOR_CUSTOMER = (accountId: number, customerId: number) => `${environment.api2Host}/api2/pismo/get-cards-for-customer/${accountId}/${customerId}` //get
const URL_GET_CARD = (accountId: number, customerId: number, cardId: number): string => `${environment.api2Host}/api2/pismo/get-card/${accountId}/${customerId}/${cardId}`; // get
const URL_PISMO_CHANGE_CARD_STATUS = (accountId: number, customerId: number, cardId: number) => `${environment.api2Host}/api2/pismo/change-card-status/${accountId}/${customerId}/${cardId}` //post
const URL_UPDATE_PISMO_CUSTOMER = (portalUserId: number) => `${environment.api2Host}/api2/pismo/update-customer/${portalUserId}` //post
const URL_UPDATE_PISMO_ACCOUNT = (pismoAccountId: number) => `${environment.api2Host}/api2/pismo/update-account/${pismoAccountId}` //post
const URL_GET_PISMO_ACCOUNT_AND_OPPORTUNITY_DETAILS = () => `${environment.api2Host}/api2/pismo/get-account-and-opportunity-details`; // post
const URL_UPDATE_PISMO_ACCOUNT_LIMIT = (pismoAccountId: number) => `${environment.api2Host}/api2/pismo/update-account-limits/${pismoAccountId}` //post
const URL_GET_PISMO_ACCOUNT_STATUS_RESPONSES = (accountStatusId: number, accountStatusName: string) => `${environment.api2Host}/api2/pismo/get-account-status-reasons?accountStatusId=${accountStatusId}&accountStatusName=${accountStatusName}` //get
const URL_UPDATE_PISMO_ACCOUNT_STATUS = (pismoAccountId: number) => `${environment.api2Host}/api2/pismo/update-account-status/${pismoAccountId}` //post
const URL_GET_ALL_PISMO_ACCOUNT_STATUS = () => `${environment.api2Host}/api2/pismo/all-account-status` //get
const URL_CREATE_ACCOUNT_INTEREST_RATE = (accountId: number) => `${environment.api2Host}/api2/pismo/create-account-interest-rate/${accountId}`;
const URL_UPDATE_ACCOUNT_PAYMENT_LIMIT = (accountId: number) => `${environment.api2Host}/api2/pismo/update-account-payment-limit/${accountId}`; // POST
const URL_ROLLBACK_PISMO_ACCOUNT_STATUS = (pismoAccountId: number) => `${environment.api2Host}/api2/pismo/rollback-account-status/${pismoAccountId}` //post
const URL_GET_TRANSACTION_TYPES = () => `${environment.api2Host}/api2/pismo/list-transaction-types`; //get
const URL_GET_CARDS_FOR_ACCOUNT = (accountId: number) => `${environment.api2Host}/api2/pismo/get-cards-for-account/${accountId}`; //get
const URL_SEARCH_OVERDRAFT_CUSTOMER = (search: string): string => `${environment.api2Host}/api2/pismo/search-overdraft-customer/${search}`; // GET
const URL_DEACTIVATE_CARD = (): string => `${environment.api2Host}/api2/pismo/deactivate-card`; // POST
const URL_GET_TRANSACTION_BY_ID = (accountId: number, transactionId: number) => `${environment.api2Host}/api2/pismo/get-transaction-by-id/${accountId}/${transactionId}`;
const URL_GET_TRANSACTION_FROM_TIMELINE = (accountId: number, transactionId: number, event_date?: string) => `${environment.api2Host}/api2/pismo/get-transaction-from-timeline/${accountId}/${transactionId}${event_date ? '/' + event_date : ''}`;
const URL_GET_TRANSACTION_TYPE_BY_ID = (transactionTypeId: number) => `${environment.api2Host}/api2/pismo/get-transactionType-by-id/${transactionTypeId}`; //get
const URL_POST_MANUAL_TRANSACTION = (pismoAccountId: number) => `${environment.api2Host}/api2/pismo/post-manual-transaction/${pismoAccountId}`; //post
const URL_GET_TRANSACTION_FLOW = () => `${environment.api2Host}/api2/pismo/transaction-flows`; //get
const URL_SEARCH_PISMO_ACCOUNT = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/pismo/search-pismo-account`, paginationInfo);
const URL_PISMO_GENERATE_REPORT = () => `${environment.api2Host}/api2/pismo/generate-reports`; // post
const URL_PISMO_EMAIL_EXISTS = () => `${environment.api2Host}/api2/pismo/valid-overdraft-email`; // post
const URL_PISMO_GENERATE_RAW_ACCOUNTING_REPORT = () => `${environment.api2Host}/api2/pismo/generate-raw-accounting-reports`; // post
const URL_PISMO_GENERATE_INTERNAL_ACCOUNTS_REPORT = () => `${environment.api2Host}/api2/pismo/generate-internal-accounts-reports`; // post
const URL_PISMO_GENERATE_AGGREGATED_ACCOUNTING_REPORT = () => `${environment.api2Host}/api2/pismo/generate-aggregated-accounting-reports`; // post
const URL_PISMO_DOWNLOAD_AGGREGATED_ACCOUNTING_REPORT = () => `${environment.api2Host}/api2/pismo/download-aggregated-accounting-reports`; // post
const URL_PISMO_LIST_BUILT_IN_TRANSACTION_TYPES = () => `${environment.api2Host}/api2/pismo/list-built-in-transaction-types`; // get
const URL_PISMO_GET_CARD_REISSUE_REASONS = (accountId: number) => `${environment.api2Host}/api2/pismo/get-card-reissue-reasons/${accountId}`;
const URL_PISMO_REISSUE_CARD = (accountId: number, customerId: number, cardId: number) => `${environment.api2Host}/api2/pismo/reissueCard/${accountId}/${customerId}/${cardId}`;
const URL_PISMO_UPDATE_REQUIRE_MONTHLY_FIXED_INSTALLMENT_FLAG = (accountId: number) => `${environment.api2Host}/api2/pismo/update-require-monthly-fixed-installment-flag/${accountId}`;
const URL_PISMO_UPDATE_STOP_DIRECT_DEBIT_FLAG = (accountId: number) => `${environment.api2Host}/api2/pismo/update-stop-direct-debit-flag/${accountId}`;
const URL_PISMO_GET_PAID_FACILITY_FEE = (accountId: number) => `${environment.api2Host}/api2/pismo/get-paid-facility-fee-for-account/${accountId}`;
const URL_PISMO_GET_PENDING_INTEREST = (accountId: number) => `${environment.api2Host}/api2/pismo/get-pending-interest-for-account/${accountId}`;
const URL_PISMO_SEND_PAYOUT_NOTIFICATION = () => `${environment.api2Host}/api2/pismo/send-payout-notification`; // post
const URL_PISMO_GENERATE_PAYOUT = () => `${environment.api2Host}/api2/pismo/generate-payout`;
const URL_GET_PENDING_PAYMENTS = (accountId: number) => `${environment.api2Host}/api2/pismo/get-pending-payments/${accountId}`;
const URL_GET_TOKEN_INFO = (accountId: number, cardId: number) => `${environment.api2Host}/api2/pismo/get-token-info/${accountId}/${cardId}`;
const URL_GET_ACCOUNT_INTEREST_RATE = (accountId: number) => `${environment.api2Host}/api2/pismo/interest-rate/${accountId}`;
const URL_PISMO_UPDATE_USER_ACCESS = (userId: number, accountId: number, customerId: number) => `${environment.api2Host}/api2/pismo/update-user-access/${userId}/${accountId}/${customerId}`
const URL_DISABLE_OVERDRAFT_USER = (userId: number, pismoAccountNumber: number) => `${environment.api2Host}/api2/disable-overdraft-user/${userId}/${pismoAccountNumber}`; // get
const URL_ENABLE_OVERDRAFT_USER = (userId: number, pismoAccountNumber: number) => `${environment.api2Host}/api2/enable-overdraft-user/${userId}/${pismoAccountNumber}`; // get
const URL_OVERDRAFT_ACCOUNT_AVAILABLE_FOR_USER = (userId: number) => `${environment.api2Host}/api2/user-available-pismo-accounts/${userId}`; // get
const URL_ALLOCATE_USER_TO_PISMO_ACCOUNT = () => `${environment.api2Host}/api2/allocate-user-to-pismo-account`; // post
const URL_GET_NUMBER_OF_ACCOUNTS = () => `${environment.api2Host}/api2/pismo/get-number-of-accounts`; // get
const URL_PISMO_LIST_STATEMENTS = (accountId: number) => `${environment.api2Host}/api2/pismo/list-statements/${accountId}`; //get
const URL_PISMO_GENERATE_ARREARS_REPORT = () => `${environment.api2Host}/api2/pismo/generate-arrears-reports`; // post
const URL_DOWNLOAD_STATEMENT_IN_PDF = (accountId: number, statementId: number) => `${environment.api2Host}/api2/generate-overdraft-statement/${accountId}/${statementId}`; //get
const URL_DOWNLOAD_STATEMENT_IN_CSV = (accountId: number, statementId: number) => `${environment.api2Host}/api2/generate-overdraft-statement-in-csv/${accountId}/${statementId}`; //get
const URL_DOWNLOAD_STATEMENT_IN_OFX = (accountId: number, statementId: number) => `${environment.api2Host}/api2/generate-overdraft-statement-in-ofx/${accountId}/${statementId}`; //get
const URL_PISMO_GET_STATEMENT_DATA = (accountId: number, statementId: number) => `${environment.api2Host}/api2/pismo/get-overdraft-statement-data/${accountId}/${statementId}`; //get
const URL_OVERDRAFT_ACCOUNT_LIMIT_INCREASE = () => `${environment.api2Host}/api2/overdraft-account-limit-increase`;
const URL_PISMO_MANUAL_DIRECT_DEBIT = () => `${environment.api2Host}/api2/pismo/manual-direct-debit`;
const URL_CREATE_PISMO_ACCOUNT_NOTE = () => `${environment.api2Host}/api2/add-pismo-account-notes`;//post
const URL_GET_PISMO_NOTES_BY_ACCOUNTNUMBER = (accountNumber: number, limit: number = 20, offset: number = 0,) => `${environment.api2Host}/api2/get-pismo-notes/${accountNumber}?limit=${limit}&offset=${offset}`;   // get
const URL_REMOVE_PISMO_NOTE_BY_NOTESID = (accountNotesId: number) => `${environment.api2Host}/api2/remove-pismo-account-notes/${accountNotesId}`;
const URL_PISMO_UPDATE_MONTHLY_FACILITY_FEE = () => `${environment.api2Host}/api2/pismo/update-monthly-facility-fee`;
const URL_PISMO_GET_ACCOUNT_TIMELINE = (accountId: number) => `${environment.api2Host}/api2/pismo/get-account-timeline/${accountId}`; //get
const URL_PISMO_GENERATE_APPLEPAY_REPORT = () => `${environment.api2Host}/api2/pismo/generate-applepay-reports`; // post

const URL_PISMO_UPDATE_AMORTISED_REPAYMENT = () => `${environment.api2Host}/api2/pismo/update-amortised-repayment`; // post
@Injectable()
export class OverdraftCustomerService {

  constructor(private httpClient: HttpClient,
    private portalHotToastService: PortalHotToastService) {
  }

  getOpportunitiesForCustomerFn = (customerSalesforceId: string): Observable<CustomerOpportunity[]> => {
    return this.httpClient.get<PayloadApiResponse<CustomerOpportunity[]>>(URL_GET_OPPORTUNITIES_FOR_CUSTOMER(customerSalesforceId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getPismoPrograms = (): Observable<PismoProgram[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoProgram[]>>(URL_GET_PROGRAMS(), httpOptions())
      .pipe(map(r => r.payload));
  }

  getAccountDetailsFromSfFn = (salesforceId: string): Observable<SfAccountDetails> => {
    return this.httpClient.get<PayloadApiResponse<SfAccountDetails>>(URL_GET_ACCOUNT_DETAILS_FROM_SF(salesforceId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getTransactionDetailsFn: GetTransactionDetailsFn = (accountId: number, transactionId: number): Observable<PismoTransaction> => {
    return this.httpClient.get<PayloadApiResponse<PismoTransaction>>(URL_GET_TRANSACTION_BY_ID(accountId, transactionId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getTransactionFromTimelineFn: GetTransactionFromTimelineFn = (accountId: number, transactionId: number, event_date?: string): Observable<PismoPendingTransaction | null> => {
    return this.getTransactionFromTimeline(accountId, transactionId, event_date).pipe(
      map((p) => p?.payload ?? null));
  }

  getTransactionFromTimeline(accountId: number, transactionId: number, event_date?: string) {
    return this.httpClient.get<PayloadApiResponse<PismoPendingTransaction | undefined>>(URL_GET_TRANSACTION_FROM_TIMELINE(accountId, transactionId, event_date), httpOptions());
  }


  createOverdraftAccountFn: CreateOverdraftAccountFn = (customer: CustomerUser, data: CreateOverdraftAccountData, opportunityId: string, payout: Payout[], customerData: CreateCustomerUserData): Observable<PayloadApiResponse<PismoCreateAccountResponse>> => {
    return this.httpClient.post<PayloadApiResponse<PismoCreateAccountResponse>>(URL_CREATE_OVERDRAFT_ACCOUNT(), { customer, data, opportunityId, payout, customerData }, httpOptions());
  }

  getCustomerPismoAccountMappingsFn: GetCustomerPismoAccountMappingsFn = (customerId: number): Observable<PismoAccountMappingAndApplication[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoAccountMappingAndApplication[]>>(URL_GET_CUSTOMER_PISMO_ACCOUNT_MAPPINGS(customerId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getPismoAccountMappingFn: GetPismoAccountMappingFn = (pismoAccountNumber: number): Observable<PismoAccountMappingAndApplication> => {
    return this.getPismoAccountMapping(pismoAccountNumber)
      .pipe(map(r => r.payload ?? null))
  }

  getPismoAccountMapping(pismoAccountNumber: number): Observable<PayloadApiResponse<PismoAccountMappingAndApplication>> {
    return this.httpClient.get<PayloadApiResponse<PismoAccountMappingAndApplication>>(URL_GET_PISMO_ACCOUNT_MAPPING(pismoAccountNumber), httpOptions());
  }

  getPismoCustomerForAccountFn: GetPismoCustomerForAccountFn = (pismoAccountNumbers: number[], opts: {
    page: { offset: number, limit: number, },
    sorts?: {
      prop: 'pismoCustomerNumber' | 'name' | 'access' | 'email' | 'dob' | 'mobileNumber' | '', dir: 'ASC' | 'DESC'
    }[]
  } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: undefined },
    pismoCustomerStatus = 'all'):
    Observable<{ total: number, payload: UserWithPismoMapping[] }> => {
    return this.httpClient.post<PaginablePayloadApiResponse<UserWithPismoMapping>>
      (URL_GET_PISMO_CUSTOMER_FOR_ACCOUNT(toPaginationInfo(opts)), {
        pismoAccountNumbers: pismoAccountNumbers,
        pismoCustomerStatus,
      }, httpOptions())
      .pipe(map(r => {
        return {
          total: r.total,
          payload: r.payload,
        }
      }));
  }

  createOverdraftUserFn = (body: CreateOverdraftUserBody): Observable<PayloadApiResponse<CreateOverdraftUserResponse>> => {
    return this.httpClient.post<PayloadApiResponse<CreateOverdraftUserResponse>>(URL_CREATE_OVERDRAFT_USER(), body, httpOptions());
  }

  getPismoAccountDetailsFn: GetPismoAccountDetailsFn = (pismoAccountNumbers: number[]): Observable<PismoGetAccountResponse[]> => {
    return this.httpClient.post<PayloadApiResponse<PismoGetAccountResponse[]>>(URL_GET_PISMO_ACCOUNT_DETAILS(), {
      pismoAccountNumbers
    }, httpOptions())
      .pipe(map(r => r.payload));
  }

  getPismoAccountAndOpportunityDetailsFn: GetPismoAccountAndOpportunityDetailsFn =
    (pismoAccountNumbers: number[]): Observable<PismoAccountAndOpportunityResponse[]> => {
      return this.httpClient.post<PayloadApiResponse<PismoAccountAndOpportunityResponse[]>>(URL_GET_PISMO_ACCOUNT_AND_OPPORTUNITY_DETAILS(), {
        pismoAccountIds: pismoAccountNumbers
      }, httpOptions())
        .pipe(map(r => r.payload));
    }

  getPismoProgramDueDateFn = (programId: number): Observable<PismoProgramDueDateResponse> => {
    return this.httpClient.get<PayloadApiResponse<PismoProgramDueDateResponse>>(URL_GET_PROGRAM_DUE_DATE(programId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getPismoTransactionsFn: GetPismoTransactionsFn = (accountId: number, opts: {
    page: { limit: number, offset: number }
  }): Observable<PaginablePayloadApiResponse<PismoTransactionsIncludingPending>> => {
    return this.httpClient.get<PaginablePayloadApiResponse<PismoTransactionsIncludingPending>>
      (URL_PISMO_LIST_TRANSACTIONS(accountId, toPaginationInfo(opts)), httpOptions());
  }

  getPismoCardsForCustomerFn = (accountId: number, customerId: number): Observable<PismoCardDetailsWithAccountId[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoCardDetailsWithAccountId[]>>(URL_GET_CARDS_FOR_CUSTOMER(accountId, customerId), httpOptions())
      .pipe(map(r => r.payload));
  }

  getCardFn: GetCardFn = (accountId: number, customerId: number, cardId: number): Observable<PismoCardDetails | null> => {
    return this.getCard(accountId, customerId, cardId).pipe(
      map((p) => p?.payload ?? null));
  }

  getCard(accountId: number, customerId: number, cardId: number) {
    return this.httpClient.get<PayloadApiResponse<PismoCardDetails | null>>(URL_GET_CARD(accountId, customerId, cardId), httpOptions());
  }

  pismoChangeCardStatusFn = (accountId: number, customerId: number, cardId: number, body: PismoChangeCardStatus): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_CHANGE_CARD_STATUS(accountId, customerId, cardId), body, httpOptions());
  }

  postOverdraftAccountFeeFn = (body: OverdraftAccountPostFeeBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_OVERDRAFT_ACCOUNT_POST_FEE(), body, httpOptions());
  }

  updateUserKycStatusFn = (kycStatus: KycStatus, userId: number): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_USER_KYC_STATUS(), { userId, kycStatus }, httpOptions());
  }

  updatePismoCustomerFn = (portalUserId: number, body: PismoUpdateCustomerBody): Observable<PismoUpdateCustomerResponse> => {
    return this.httpClient.post<PayloadApiResponse<PismoUpdateCustomerResponse>>(URL_UPDATE_PISMO_CUSTOMER(portalUserId), body, httpOptions())
      .pipe(map(r => r.payload));
  }

  updatePismoAccountFn = (pismoAccountId: number, body: PismoAccountCustomFields): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_PISMO_ACCOUNT(pismoAccountId), body, httpOptions())
      .pipe(map(r => r))
  }

  updatePismoAccountLimitFn = (pismoAccountId: number, body: PismoAccountUpdateLimits): Observable<PayloadApiResponse<PismoUpdateLimitsResponse>> => {
    return this.httpClient.post<PayloadApiResponse<PismoUpdateLimitsResponse>>(URL_UPDATE_PISMO_ACCOUNT_LIMIT(pismoAccountId), body, httpOptions())
      .pipe(map(r => r))
  }

  getPismoAccountStatusReasonsFn = (accountStatusId: number, accountStatusName: string): Observable<PismoGetAccountStatusReasonsResponse[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoGetAccountStatusReasonsResponse[]>>(URL_GET_PISMO_ACCOUNT_STATUS_RESPONSES(accountStatusId, accountStatusName), httpOptions())
      .pipe(map(r => r.payload))
  }

  updatePismoAccountStatusFn = (pismoAccountId: number, body: UpdateAccountStatus): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_PISMO_ACCOUNT_STATUS(pismoAccountId), body, httpOptions())
      .pipe(map(r => r))
  }

  getAllPismoAccountStatusFn = (): Observable<PismoGetAccountStatusResponse[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoGetAccountStatusResponse[]>>(URL_GET_ALL_PISMO_ACCOUNT_STATUS(), httpOptions())
      .pipe(map(r => r.payload))
  }

  getPismoTransactionTypesFn = (): Observable<PismoGetTransactionType[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoGetTransactionTypesResponse>>(URL_GET_TRANSACTION_TYPES(), httpOptions())
      .pipe(map(r => r.payload.pagination.items));
  }

  getPismoTransactionTypeByIdFn = (transactionTypeId: number): Observable<PismoTransactionType> => {
    return this.httpClient.get<PayloadApiResponse<PismoTransactionType>>(URL_GET_TRANSACTION_TYPE_BY_ID(transactionTypeId), httpOptions())
      .pipe(map(r => r.payload));
  }

  createAccountInterestRateFn = (accountId: number): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_CREATE_ACCOUNT_INTEREST_RATE(accountId), {}, httpOptions());
  }

  updatePismoAccountPaymentLimitFn: UpdatePismoAccountPaymentLimitFn = (accountId: number, paymentLimit: number): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_ACCOUNT_PAYMENT_LIMIT(accountId), {
      paymentLimit
    }, httpOptions());
  }

  rollbackPismoAccountStatusFn = (pismoAccountId: number, body: UpdateAccountStatus): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_ROLLBACK_PISMO_ACCOUNT_STATUS(pismoAccountId), body, httpOptions())
  }

  getPismoCardsForAccountFn = (accoundId: number): Observable<PismoCardDetails[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoCardDetails[]>>(URL_GET_CARDS_FOR_ACCOUNT(accoundId), httpOptions())
      .pipe(map(r => r.payload));
  }

  searchOverdraftCustomer = (search: string): Observable<PayloadApiResponse<SearchOverdraftCustomerResult>> => {
    return this.httpClient.get<PayloadApiResponse<SearchOverdraftCustomerResult>>(URL_SEARCH_OVERDRAFT_CUSTOMER(search), httpOptions());
  }

  searchOverdraftCustomerFn = (search: string): Observable<SearchOverdraftCustomerResult> => {
    return this.searchOverdraftCustomer(search).pipe(map(r => r.payload));
  }

  deactivatePismoCard = (pismoAccountId: number, pismoCustomerId: number, pismoCardId: number): Observable<PayloadApiResponse<PismoUpdateCardStatusResponse>> => {
    return this.httpClient.post<PayloadApiResponse<PismoUpdateCardStatusResponse>>(URL_DEACTIVATE_CARD(), {
      accountId: pismoAccountId,
      customerId: pismoCustomerId,
      cardId: pismoCardId,
    }, httpOptions());
  }

  postManualTransactionFn = (pismoAccountId: number, transaction: PismoPostManualTransactionBalance): Observable<PayloadApiResponse<PismoUpdateCardStatusResponse>> => {
    return this.httpClient.post<PayloadApiResponse<PismoUpdateCardStatusResponse>>(URL_POST_MANUAL_TRANSACTION(pismoAccountId),
      transaction
      , httpOptions());
  }

  getPismoTransactionFlowFn = (): Observable<PismoTransactionFlowItems[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoTransactionFlowItems[]>>(URL_GET_TRANSACTION_FLOW(), httpOptions())
      .pipe(map(r => r.payload));
  }

  searchPismoAccount = (opts: {
    page: { offset: number, limit: number, },
    filter?: string | null,
    sorts?: { prop: '', dir: 'ASC' | 'DESC' }[]
  }): Observable<PaginablePayloadApiResponse<SearchPismoAccountMapping>> => {
    return this.httpClient.get<PaginablePayloadApiResponse<SearchPismoAccountMapping>>(URL_SEARCH_PISMO_ACCOUNT(toPaginationInfo(opts)), httpOptions());
  }

  generatePismoReports = (reportType: PismoReportType, startDate: string, endDate: string): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.post<Blob>(URL_PISMO_GENERATE_REPORT(), {
      reportType,
      startDate,
      endDate,
    }, options);
  }

  generatePismoApplePayReport = (startDate: string, endDate: string): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.post<Blob>(URL_PISMO_GENERATE_APPLEPAY_REPORT(), {
      startDate,
      endDate,
    }, options);
  }

  generatePismoRawAccountingReport = (startDate: string, endDate: string): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.post<Blob>(URL_PISMO_GENERATE_RAW_ACCOUNTING_REPORT(), {
      startDate,
      endDate,
    }, options);
  }

  generatePismoInternalAccountsReport = (startDate: string, endDate: string): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.post<Blob>(URL_PISMO_GENERATE_INTERNAL_ACCOUNTS_REPORT(), {
      startDate,
      endDate,
    }, options);
  }

  generatePismoAggregatedAccountingReport = (startDate: string, endDate: string) => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.getNumberOfOverdraftAccounts().pipe(
      switchMap((total: number) => {
        const gap = 400;
        const numberOfGaps = Math.ceil(total / gap);
        const observables = [];
        for (let i = 0; i < numberOfGaps; i++) {
          observables.push(
            this.httpClient.post<PayloadApiResponse<PismoAggregatedAccountingRecord[]>>(URL_PISMO_GENERATE_AGGREGATED_ACCOUNTING_REPORT(), {
              startDate,
              endDate,
              startId: i * gap,
              endId: i * gap + gap - 1
            }, httpOptions()).pipe(map(response => response.payload))
          )
        }
        observables.push(
          this.httpClient.post<PayloadApiResponse<PismoAggregatedAccountingRecord[]>>(URL_PISMO_GENERATE_AGGREGATED_ACCOUNTING_REPORT(), {
            startDate,
            endDate,
            startId: numberOfGaps * gap,
            endId: 99999999
          }, httpOptions()).pipe(map(response => response.payload))
        )
        return combineLatest(observables);
      }),
      switchMap((records: PismoAggregatedAccountingRecord[][]) => {
        const data = records.flat(1);
        console.log('=====data: ', data)
        return this.httpClient.post<Blob>(URL_PISMO_DOWNLOAD_AGGREGATED_ACCOUNTING_REPORT(), {
          data
        }, options);
      })
    )
  }

  pismoCheckEmailExists = (email: string, pismoAccountNumber?: number): Observable<PayloadApiResponse<{ status: boolean }>> => {
    return this.httpClient.post<PayloadApiResponse<{ status: boolean }>>(URL_PISMO_EMAIL_EXISTS(), { Email: email, pismoAccountNumber }, httpOptions());
  }

  pismoValidEmailCheckFn: ValidEmailCheckFn = ((email: string) => {
    return this.pismoCheckEmailExists(email)
      .pipe(
        map((res: any) => {
          if (res.payload.status == true) {
            return true;
          } else {
            return false;
          }
        }),
      );
  }).bind(this);

  pismoListBuiltInTransactionTypesFn = (): Observable<PismoBuiltInTransactionType[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoBuiltInTransactionType[]>>(URL_PISMO_LIST_BUILT_IN_TRANSACTION_TYPES(), httpOptions())
      .pipe(map((result: PayloadApiResponse<PismoBuiltInTransactionType[]>) => result.payload));
  }

  pismoGetCardReissueReasonsFn = (accountId: number): Observable<PismoCardReIssueReason[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoCardReIssueReason[]>>(URL_PISMO_GET_CARD_REISSUE_REASONS(accountId), httpOptions())
      .pipe(map((res: PayloadApiResponse<PismoCardReIssueReason[]>) => res.payload))
  }

  pismoReissueCardFn = (accountId: number, customerId: number, cardId: number, data: PismoReissueCardDetails): Observable<PismoReissueCardResponse> => {
    return this.httpClient.post<PayloadApiResponse<PismoReissueCardResponse>>(URL_PISMO_REISSUE_CARD(accountId, customerId, cardId), data, httpOptions())
      .pipe(map((res: PayloadApiResponse<PismoReissueCardResponse>) => res.payload));
  }

  pismoUpdateRequireMonthlyFixedInstallmentFlagFn = (accountId: number, requireMonthlyFixedInstallment: boolean, reason: string): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_UPDATE_REQUIRE_MONTHLY_FIXED_INSTALLMENT_FLAG(accountId), {
      requireMonthlyFixedInstallment,
      reason
    }, httpOptions());
  }
  
  pismoUpdateStopDirectDebitFlagFn = (accountId: number, stopDirectDebit: boolean): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_UPDATE_STOP_DIRECT_DEBIT_FLAG(accountId), {
      stopDirectDebit
    }, httpOptions());
  }

  pismoGetPaidFacilityFeeFn = (accountId: number): Observable<number> => {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_PISMO_GET_PAID_FACILITY_FEE(accountId), httpOptions())
      .pipe(map((res: PayloadApiResponse<number>) => res.payload));
  }

  pismoGetPendingInterestFn = (accountId: number): Observable<number> => {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_PISMO_GET_PENDING_INTEREST(accountId), httpOptions())
      .pipe(map((res: PayloadApiResponse<number>) => res.payload));
  }

  pismoSendPayoutNotificationFn = (body: PayoutNotificationBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_SEND_PAYOUT_NOTIFICATION(), body, httpOptions());
  }

  pismoGeneratePayoutFn = (body: GeneratePayoutBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_GENERATE_PAYOUT(), body, httpOptions());
  }

  getPendingPaymentsFn = (accountId: number): Observable<number> => {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_GET_PENDING_PAYMENTS(accountId), httpOptions())
      .pipe(map((resposne: PayloadApiResponse<number>) => resposne.payload));
  }

  getTokenInfoFn: GetTokenInfoFn = (accountId: number, cardId: number): Observable<PismoCardTokenInfo[]> => {
    return this.getTokenInfo(accountId, cardId);
  }

  getTokenInfo: GetTokenInfoFn = (accountId: number, cardId: number): Observable<PismoCardTokenInfo[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoCardTokenInfo[]>>(URL_GET_TOKEN_INFO(accountId, cardId), httpOptions())
      .pipe(map(r => r.payload));
  }

  pismoGetAccountInterestRateFn = (accountId: number): Observable<number> => {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_GET_ACCOUNT_INTEREST_RATE(accountId), httpOptions())
      .pipe(map((resposne: PayloadApiResponse<number>) => resposne.payload));
  }

  pismoUpdateUserAccessFn = (userId: number, accountId: number, customerId: number, body: UpdatePismoUserAccessBody) => {
    return this.httpClient.put<ApiResponse>(URL_PISMO_UPDATE_USER_ACCESS(userId, accountId, customerId), body, httpOptions())
  }


  disableOverdraftUserFn = (userId: number, pismoAccountNumber: number) => {
    return this.disableOverdraftUser(userId, pismoAccountNumber).pipe(r => r);
  }

  enableOverdraftUserFn = (userId: number, pismoAccountNumber: number, customerAccessLevel: CustomerAccessLevelAndRoleAccessValue) => {
    return this.enableOverdraftUser(userId, pismoAccountNumber, customerAccessLevel).pipe(r => r);
  }

  enableOverdraftUser = (userId: number, pismoAccountNumber: number, body: CustomerAccessLevelAndRoleAccessValue): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_ENABLE_OVERDRAFT_USER(userId, pismoAccountNumber), body, httpOptions());
  }

  disableOverdraftUser = (userId: number, pismoAccountNumber: number): Observable<ApiResponse> => {
    return this.httpClient.get<ApiResponse>(URL_DISABLE_OVERDRAFT_USER(userId, pismoAccountNumber), httpOptions());
  }

  overdraftAccountAvailableForUserFn: OverdraftAccountAvailableForUserFn = (userId: number) => {
    return this.overdraftAccountAvailableForUser(userId).pipe(
      map(v => v.payload)
    );
  }

  overdraftAccountAvailableForUser = (userId: number): Observable<PayloadApiResponse<OverdraftAccountAvailable[]>> => {
    return this.httpClient.get<PayloadApiResponse<OverdraftAccountAvailable[]>>(URL_OVERDRAFT_ACCOUNT_AVAILABLE_FOR_USER(userId), httpOptions());
  }

  allocateUserToPismoAccountFn: AllocateUserToPismoAccountFn = (data: AllocateUserToPismoAccountData) => {
    return this.allocateUserToPismoAccount(data);
  }

  allocateUserToPismoAccount = (data: AllocateUserToPismoAccountData) => {
    return this.httpClient.post<ApiResponse>(URL_ALLOCATE_USER_TO_PISMO_ACCOUNT(), data, httpOptions());
  }

  getNumberOfOverdraftAccounts = (): Observable<number> => {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_GET_NUMBER_OF_ACCOUNTS(), httpOptions())
      .pipe(map(r => r.payload));
  }

  getPismoStatementsFn: GetPismoStatementsFn = (accountId: number): Observable<PismoStatement[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoListStatementsResponse>>(
      URL_PISMO_LIST_STATEMENTS(accountId), httpOptions()
    ).pipe(map(result => result.payload.items));
  }

  downloadStatement = (
    accountId: number,
    statementId: number
  ) => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(URL_DOWNLOAD_STATEMENT_IN_PDF(accountId, statementId), options)
  }

  downloadStatementInCsv = (
    accountId: number,
    statementId: number
  ) => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(URL_DOWNLOAD_STATEMENT_IN_CSV(accountId, statementId), options)
  }

  downloadStatementInOfx = (
    accountId: number,
    statementId: number
  ) => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(URL_DOWNLOAD_STATEMENT_IN_OFX(accountId, statementId), options)
  }

  getPismoStatementDetails = (
    accountId: number,
    statementId: number): Observable<OverdraftStatementTemplateData> => {
    return this.httpClient.get<PayloadApiResponse<OverdraftStatementTemplateData>>(URL_PISMO_GET_STATEMENT_DATA(accountId, statementId), httpOptions())
      .pipe(map(r => r.payload));
  }

  generatePismoArrearsReport = (): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.post<Blob>(URL_PISMO_GENERATE_ARREARS_REPORT(), {}, options);
  }

  overdraftAccountLimitIncreaseFn = (body: OverdraftAccountLimitIncreaseBody): Observable<PayloadApiResponse<OverdraftAccountLimitIncreaseResponse>> => {
    return this.httpClient.post<PayloadApiResponse<OverdraftAccountLimitIncreaseResponse>>(URL_OVERDRAFT_ACCOUNT_LIMIT_INCREASE(), body, httpOptions());
  }

  manualDirectDebitFn = (body: ManualDirectDebitBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_MANUAL_DIRECT_DEBIT(), body, httpOptions());
  }

  createPismoAccountNote(data: CreateAccountNoteBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_CREATE_PISMO_ACCOUNT_NOTE(), data, httpOptions());
  }

  getPismoNotesByAccountNumber(limit: number, offset: number, accountNumber: number): Observable<PaginablePayloadApiResponse<PismoAccountNotes>> {
    return this.httpClient.get<PaginablePayloadApiResponse<PismoAccountNotes>>(URL_GET_PISMO_NOTES_BY_ACCOUNTNUMBER(accountNumber, limit, offset), httpOptions()).pipe();
  }

  getPismoNotesByAccountNumberFn: GetPismoNotesByAccountNumberFn = (limit: number, offset: number, accountNumber: number): Observable<PaginablePayloadApiResponse<PismoAccountNotes>> => {
    return this.getPismoNotesByAccountNumber(limit, offset, accountNumber);
  }

  removePismoAccountNoteByNoteId(accountNotesId: number): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_REMOVE_PISMO_NOTE_BY_NOTESID(accountNotesId), {}, httpOptions())
  }

  removePismoAccountNoteByNoteIdFn = (accountNotesId: number): Observable<ApiResponse> => {
    return this.removePismoAccountNoteByNoteId(accountNotesId)
  }

  updateMonthlyFacilityFeeFn = (body: PismoUpdateMonthlyFacilityFeeBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_UPDATE_MONTHLY_FACILITY_FEE(), body, httpOptions());
  }

  getPismoAccountTimelineFn = (accountId: number): Observable<PismoTimeline[]> => {
    return this.httpClient.get<PayloadApiResponse<PismoTimeline[]>>(URL_PISMO_GET_ACCOUNT_TIMELINE(accountId), httpOptions())
      .pipe(map(r => r.payload));
  }

  updateAmortisedRepaymentFn = (body: PismoUpdateAmortisedRepaymentBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_PISMO_UPDATE_AMORTISED_REPAYMENT(), body, httpOptions());
  }
}

