import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardTopWidgetComponent } from './dashboard-top-widget/dashboard-top-widget.component';
import { DashboardNewsComponent } from './dashboard-news/dashboard-news.component';
import { DashboardProgressTrackComponent } from './dashboard-progress-track/dashboard-progress-track.component';
 
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
         
        AngularMaterialLibraryModule,
        DashboardTopWidgetComponent,
        DashboardNewsComponent,
        DashboardProgressTrackComponent
    ],
    exports: [
        DashboardTopWidgetComponent,
        DashboardNewsComponent,
        DashboardProgressTrackComponent
    ]
})
export class PortalDashboardModule {

}
