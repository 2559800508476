<div class="editable-percentage-component">
  <editable (modeChange)="modeChange($event)"
            [ngClass]="{'edit': mode == 'edit', 'view': mode == 'view'}">
    <ng-template editMode>
      <div fxLayout="row"
           fxLayoutAlign="start start"
           fxLayoutGap="5px">
        <percentage-input
          title=""
          [formControl]="formControl">
        </percentage-input>
        <button mat-flat-button
                (click)="onSave()"
                editableOnSave>
          <span class="mdi mdi-check-outline"
                matTooltip="Save"></span>
        </button>
      </div>
    </ng-template>
    <ng-template viewMode>
      <div fxLayout="row"
           fxLayoutAlign="start start"
           fxLayoutGap="5px">
        <span class="w-100">{{(readValue ?? '') | percentage: '1.0-2'}}</span>
        <span class="mdi mdi-pencil-outline"
              matTooltip="Edit"></span>
      </div>
    </ng-template>
  </editable>
</div>
