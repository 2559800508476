import {Component, Input} from '@angular/core';
import _ from 'lodash';


@Component({
    selector: 'tag-box',
    templateUrl: './tag-box.component.html',
    styleUrls: ['./tag-box.component.scss'],
    standalone: true
})
export class TagBoxComponent {

  @Input({required: false}) state: string = '';

  toClass() {
    return this.state ? _.toLower(this.state.replace(/\s/g, '')) : '';
  }
}
