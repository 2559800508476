import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service'
import {
  ApplicationNotes,
  CreateApplicationNoteFn,
  CustomerAngularEditorComponentValue,
  isInternalUser,
  RemoveApplicationNoteByNoteIdFn,
  User, DEFAULT_LIMIT, DEFAULT_OFFSET,
  Application,
  DocumentReviewEmailBody,
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { CompleteDocumentWorklistFn, CreateDocumentWorklistFn, getUser, PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { FormControl, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { GetApplicationAuditLogsFn, AuditLogComponent } from './audit-log.component';
import { GetNotesByApplicationIdFn, NotesComponent } from './notes.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';



@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'asset-notes',
    templateUrl: './asset-notes.component.html',
    styleUrls: ['./asset-notes.component.scss'],
    standalone: true,
    exportAs: 'assetNotesComponent',
    imports: [CustomAngularEditorComponent, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatButtonModule, MatTabsModule, NotesComponent, AuditLogComponent],

})
export class AssetNotesComponent implements OnInit {
  subscriptions: Subscription[] = [];

  formControlApplicationNotes!: FormControl<CustomerAngularEditorComponentValue>;
  formControlNotesIsVisible : FormControl<boolean>;
  showButton = false;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  isInternalUser = isInternalUser
  loggedInUser: User | null = getUser();
  placeholder = 'New Note';

  @Input({required: false}) application!: Application;

  @Input({required: false}) applicationNotes: ApplicationNotes[] | [] = [];
  @Input({required: false}) total!: number;
  @Input({required: false}) isAssetUpdated!: Boolean;
  @Input({required: false}) isAssetNoteEditorShow: Boolean = true;
  @Input({required: false}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: false}) onlyShowNotes = true;
  @Input({required: false}) showInternalOnlyButton = true;
  @Input({required: false}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: false}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: false}) alwaysInternalNotes = false;
  @Input({required: false}) creditNotesOnly = false;

  @Output() events: EventEmitter<Boolean> = new EventEmitter();
  @Output() totalNotesEvent: EventEmitter<number> = new EventEmitter<number>();
  constructor(private formBuilder: UntypedFormBuilder,
    private dialogService: ApplicationDialogService,
    private ppsrService: PpsrService,
    private cd: ChangeDetectorRef ,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private toastService: PortalHotToastService,) {
    this.formControlApplicationNotes = formBuilder.control('', [Validators.required])
    this.formControlNotesIsVisible = formBuilder.control(false, [Validators.required])
  }

  ngOnInit(): void {
    this.reload();
  }

  saveNotes() {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || '';
    const data = {
      UserId: this.loggedInUser?.UserId!,
      ApplicationId: parseInt(applicationId),
      Note: this.formControlApplicationNotes.value ?? '',
      visibleToInternalStaff  : this.formControlNotesIsVisible.value,
      notesFromDscrCalculator: true,
      NotificationSubmitter:"",
      DocumentConditionDetail:"",
      creditNote: this.creditNotesOnly,
    }
    this.createApplicationNoteFn(data).pipe(
      this.toastService.retryableMessage({
        successMessage: 'Notes created',
        errorMessage: 'Failed to retrieve the Notes',
        retryFn: () => {
          this.saveNotes();
        }
      }),
      this.toastService.spinnerObservable(),
      tap(r => {
        this.reload();
      })
    ).subscribe();
    this.showButton = false;
    this.formControlApplicationNotes.reset();
    this.formControlNotesIsVisible.setValue(false);
  }

  totalNotes($event: number){
    this.totalNotesEvent.emit($event);
  }

  show() {
    this.showButton = true;
  }

  cancel() {
    this.formControlApplicationNotes.reset();
    this.showButton = false;
  }

  openReviewDocumentsPopup(){

    this.subscriptions.push(
      this.dialogService.openDocumentReviewDialog ({
        title: 'New Note',
        groupNames:[],
        application: this.application,
        isShowRequestReviewSection:false
      }).afterClosed().pipe(
        tap(async (r) => {

          if(r && r.valid == true){

          const notificationSelectedMessage:string[] = [];
            if(r.isInternalOnly){
              notificationSelectedMessage.push("Internal")
            }

            if(r.isSubmitterSelect){
              notificationSelectedMessage.push("Submitter")
            }
            if(r.isSalesSelect){
              notificationSelectedMessage.push("Sales")
            }

            if(r.isCreditSelect){
              notificationSelectedMessage.push("Credit")
            }
            if(r.isSettlementSelect){
              notificationSelectedMessage.push("Settlements")
            }


            let notificationSubmitter = ""
            if(notificationSelectedMessage.length){
              notificationSubmitter = "Notification: "+ notificationSelectedMessage.join(", ")
            }



          if(r.submitterEmailList.length > 0 && r.documentNotes != null){
            let allEmailList = r.submitterEmailList
            const documentReviewEmailBody: DocumentReviewEmailBody = {
              applicationId: this.application.ApplicationId,
              brokerAppId: this.application.BrokerAppId,
              emailList: allEmailList,
              pendingTag:[],
              inProgressTag:[],
              applicationType:this.application.ApplicationType,
              isSubmitterSelect: r.isSubmitterSelect,
              documentNotes: r.documentNotes,
              isFromDocumentReview: false,
              applicationName: this.application.entityName,
              submitterUserId: this.application.UserId,
              salesContact: this.application.sfOwnerEmail ?? '',
              formControlInternalOnly: r.formControlInternalOnly,
              isInternalOnly: r.isInternalOnly,
            }
            this.subscriptions.push(this.applicationService.sendDocumentReviewEmailFn(documentReviewEmailBody).pipe().subscribe())
          }

          if (r.documentNotes != null){
            const applicationId = this.route.snapshot.paramMap.get('applicationId') || ''
            const data = {
              UserId: this.loggedInUser?.UserId!,
              ApplicationId: parseInt(applicationId),
              Note: r.documentNotes ?? '',
              visibleToInternalStaff  : r.isInternalUser ?? false,
              notesFromDscrCalculator: true,
              NotificationSubmitter:notificationSubmitter,
              DocumentConditionDetail:"",
            }
            this.subscriptions.push(this.ppsrService.createNoteFn(data).pipe(
              tap(r => {
                this.reload();
              })
            ).subscribe())
          }

          }
        })
      ).subscribe()
      )
  }
  reload() {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || '';
    this.getNotesByApplicationIdFn(this.limit, this.offset, parseInt(applicationId)).pipe(
      tap(r => {
        this.total = r.total;
        this.applicationNotes = r.payload;
        this.cd.detectChanges();
      })
    ).subscribe();

    if (this.alwaysInternalNotes) {
      this.formControlNotesIsVisible.setValue(true);
    }
  }
}
