import {Component, Input, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'bank-account-chip',
    templateUrl: './bank-account-chip.component.html',
    styleUrls: ['./bank-account-chip.component.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class BankAccountChipComponent implements OnInit {

  @Input({required: false}) bsb : string | null = null;
  @Input({required: false}) accountNumber: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
