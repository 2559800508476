<div class="direct-payment-batch-dialog">
  <mat-dialog-content>
    <h3 class="header">
      Generate Direct Payment ABA File
    </h3>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Date</mat-label>
          <input [formControl]="formControlDate" matInput placeholder="Enter date">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>User Name</mat-label>
          <input [formControl]="formControlUserName" matInput placeholder="Enter user name">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>User Number</mat-label>
          <input [formControl]="formControlUserNumber" matInput placeholder="Enter user number">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input [formControl]="formControlDescription" matInput placeholder="Enter description">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
