import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BusinessLoanRateCardValue, compareMatch, MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {createTwoDecimalInputMask, Mark} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { MinMaxRateComponent } from './min-max-rate.component';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';
  


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-loan-rate-card',
    templateUrl: './business-loan-rate-card.component.html',
    styleUrls: ['./business-loan-rate-card.component.scss'],
    exportAs: 'businessLoanRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessLoanRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BusinessLoanRateCardComponent), multi: false },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, MatDividerModule, MinMaxRateComponent]
})
export class BusinessLoanRateCardComponent extends AbstractControlValueAccessor<BusinessLoanRateCardValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  subscriptions: Subscription[] = [];


  formGroup: FormGroup<{
    loanAMount: FormControl<MinMaxRateValue | null>,
    loanTerms: FormControl<MinMaxRateValue | null>,
    brokerage: FormControl<MinMaxRateValue | null>,
    adverseOnFile: FormControl<number | null>,
    docFee: FormControl<MinMaxRateValue>,
    gstAge: FormControl<MinMaxRateValue>,
    propertyOwner: FormControl<number | null>,
    directorScoreRate: FormControl<number | null>,
    noPropertyOwner: FormControl<number | null>,
    nonPropertyOwnerBetween500And600Rate: FormControl<number | null>,
  }>;

  formControlLoanAmount: FormControl<MinMaxRateValue>;
  formControlLoanTerms: FormControl<MinMaxRateValue>;
  formControlBrokerage: FormControl<MinMaxRateValue>;
  formControlAdverseOnFile: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlPropertyOwner: FormControl<number | null>;
  formControlNoPropertyOwner: FormControl<number | null>;
  formControlDirectorScoreRate: FormControl<number | null>;
  formControlNonPropertyOwnerBetween500And600Rate: FormControl<number | null>;
  formControlGstAge: FormControl<MinMaxRateValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlLoanAmount = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlLoanTerms = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlBrokerage = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAdverseOnFile = formBuilder.control(0, [Validators.required]);
    this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlGstAge = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlPropertyOwner = formBuilder.control(0, [Validators.required]);
    this.formControlNoPropertyOwner = formBuilder.control(0, [Validators.required]);
    this.formControlDirectorScoreRate = formBuilder.control(0,[Validators.required]);
    this.formControlNonPropertyOwnerBetween500And600Rate = formBuilder.control(0,[Validators.required]);
    this.formGroup = formBuilder.group({
      loanAMount: this.formControlLoanAmount,
      loanTerms: this.formControlLoanTerms,
      brokerage: this.formControlBrokerage,
      adverseOnFile: this.formControlAdverseOnFile,
      docFee: this.formControlDocFee,
      gstAge: this.formControlGstAge,
      propertyOwner: this.formControlPropertyOwner,
      noPropertyOwner: this.formControlNoPropertyOwner,
      directorScoreRate: this.formControlDirectorScoreRate,
      nonPropertyOwnerBetween500And600Rate: this.formControlNonPropertyOwnerBetween500And600Rate
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          // NOTE: properties in r should not be null when formGroup is valid, the validators should ensure that
          const v: BusinessLoanRateCardValue = {
            loanAmount: r.loanAMount ?? [],
            loanTerms: r.loanTerms ?? [],
            brokerage: r.brokerage ?? [],
            adverseOnFile: r.adverseOnFile ?? 0,
            docFee: r.docFee ?? [],
            gstAge: r.gstAge ?? [],
            propertyOwner: r.propertyOwner ?? 0,
            noPropertyOwner: r.noPropertyOwner ?? 0,
            directorScoreRate: r.directorScoreRate ?? 0,
            nonPropertyOwnerBetween500And600Rate: r.nonPropertyOwnerBetween500And600Rate ?? 0,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: BusinessLoanRateCardValue | undefined): void | BusinessLoanRateCardValue {
    if (v) {
      this.formControlLoanAmount.setValue(v.loanAmount ?? []);
      this.formControlLoanTerms.setValue(v.loanTerms ?? []);
      this.formControlBrokerage.setValue(v.brokerage ?? []);
      this.formControlAdverseOnFile.setValue(v.adverseOnFile);
      this.formControlPropertyOwner.setValue(v.propertyOwner);
      this.formControlNoPropertyOwner.setValue(v.noPropertyOwner);
      this.formControlDirectorScoreRate.setValue(v.directorScoreRate);
      this.formControlNonPropertyOwnerBetween500And600Rate.setValue(v.nonPropertyOwnerBetween500And600Rate);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlGstAge.setValue(v.gstAge ?? []);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
