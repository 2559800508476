import { Component, Inject, OnInit } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CustomerOpportunity, GeneratePayoutDialogData, GeneratePayoutDialogResult, GetPismoCustomerForAccountFn, PayoutNotificationDialogData, PayoutNotificationDialogResult, PismoGetAccountResponse, PismoGetPaidFacilityFeeFn, PismoGetPendingInterestFn, PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData, PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult, UserWithPismoMapping, compareMatch } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { RadioOptionsComponent } from '../radio-options-component/radio-options.component';
import { CurrencyInputComponent } from "../currency-selection-component/currency-input.component";
import { ApplicationDialogService, Mark, PortalHotToastService, doMarkAll, formControlErrorKeys, formControlErrorMessage,noSpaceValidator, markTriggerSubject, validateEmail } from "@portal-workspace/grow-ui-library";
import { Subject, Subscription, delay, distinctUntilChanged, tap } from "rxjs";
import { MatDividerModule } from '@angular/material/divider';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  templateUrl: './payout-notification.dialog.html',
  styleUrls: ['./payout-notification.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    LooseCurrencyPipe,
    RadioOptionsComponent,
    CurrencyInputComponent
]
})
export class PayoutNotificationDialog implements OnInit, Mark {

  formGroup: FormGroup;
  formControlEmail!: FormControl<string | null>;
  formControlCcEmail!: FormControl<string | null>;
  formControlEmailSet!: FormControl<string | null>;
  emailList: string[] = [];
  ccEmailList: string[] = ['finance@dynamoney.com'];
  emailListError = false;
  ccEmailListError = false;
  addOnBlur = true;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn;
  pismoAccountDetails!: PismoGetAccountResponse;

  markTriggerSubject = markTriggerSubject;
  markObservable: Subject<boolean> = new Subject<boolean>();
  showCard: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PayoutNotificationDialogData,
    private dialogRef: MatDialogRef<PayoutNotificationDialog, PayoutNotificationDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.getPismoCustomerForAccountFn = data.getPismoCustomerForAccountFn;
    this.pismoAccountDetails = data.pismoAccountDetails;
    this.formControlEmail = this.formBuilder.control(null, [Validators.required,Validators.email]);
    this.formControlCcEmail = this.formBuilder.control(null, [Validators.email]);
    this.formControlEmailSet = this.formBuilder.control(null, [Validators.required]);
    
    this.formGroup = this.formBuilder.group({
      email: this.formControlEmail,
      ccEmail: this.formControlCcEmail,
      formControlEmailSet:this.formControlEmailSet
    });

  
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  ngOnInit(): void {
   this.subscriptions.push(
    this.getPismoCustomerForAccountFn([this.pismoAccountDetails.account_id], {
        page: { offset: 0, limit: 999, }
      }, 'enabled'
    ).pipe(
      this.toastService.spinnerObservable()
    ).subscribe((result: { total: number, payload: UserWithPismoMapping[] }) => {
      this.emailList = result.payload.filter(user => user.customerAccessLevel === 'card-admin').map(user => user.Email)
    })
   )
  }

  add(event: MatChipInputEvent, ccEmail = false): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        if (ccEmail) {
          this.ccEmailList.push(value)
        } else {
          this.emailList.push(value);
        }
      }
      // Clear the input value
      event.chipInput!.clear();
      if (ccEmail) {
        this.ccEmailListError = false;
      } else {
        this.emailListError = false;
      }
    } else if (value) {
      if (ccEmail) {
        this.ccEmailListError = true;
      } else {
        this.emailListError = true;
      }
    }
  }

  remove(email: string, ccEmail = false): void {
    if (ccEmail) {
      const index = this.ccEmailList.indexOf(email);

      if (index >= 0) {
        this.ccEmailList.splice(index, 1);
      }
    } else {
      const index = this.emailList.indexOf(email);

      if (index >= 0) {
        this.emailList.splice(index, 1);
      }
    }
  }


  onCancel(event: Event) {
    this.dialogRef.close();
  }

  onSave() {
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);
    this.dialogRef.close({
      readyForSubmission: true,
      email: this.emailList,
      ccEmail: this.ccEmailList
    })
  }
}
