import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MessageBoxComponent} from './message-box.component';
import {MessageBox2Component} from './message-box-2.component';
import {DataBoxComponent} from './data-box.component';
import {TagBoxComponent} from './tag-box.component';
import {StatusBoxComponent} from './status-box.component';
import {DataBox2Component} from './data-box-2.component';
import { ErrorNotification } from './error-notification-component/error-notification.component';
import {ErrorNotification2} from './error-notification-component/error-notification2.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        MessageBoxComponent,
        MessageBox2Component,
        DataBoxComponent,
        TagBoxComponent,
        StatusBoxComponent,
        DataBox2Component,
        ErrorNotification,
        ErrorNotification2
    ],
    exports: [
        MessageBoxComponent,
        MessageBox2Component,
        DataBoxComponent,
        TagBoxComponent,
        StatusBoxComponent,
        DataBox2Component,
        ErrorNotification,
        ErrorNotification2,
    ]
})
export class MessageBoxModule {

}
