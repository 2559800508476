import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminPrivacyConsent, PrivacyConsent} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import { MessageBox2Component } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Subscription, switchMap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './accept-privacy-consent.page.html',
    styleUrls: ['./accept-privacy-consent.page.scss'],
    standalone: true,
    imports: [NgClass, MessageBoxComponent, MessageBox2Component, MatCheckboxModule, ReactiveFormsModule, MatFormFieldModule, PdfViewerModule, MatButtonModule, FlexModule, ExtendedModule]
})
export class AcceptPrivacyConsentPage implements OnInit {
  environment = environment;
  privacyConsent: PrivacyConsent | AdminPrivacyConsent | null = null;
  subscriptions: Subscription[] = [];
  type: 'application' | 'admin' = 'application';

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.privacyConsent = (this.route.snapshot.data as any).privacyConsent;
    this.type = this.privacyConsent?.type ?? 'application';

    if (this.privacyConsent && this.privacyConsent.status !== 'accepted') {
      this.subscriptions.push(
        this.applicationService.getIpAddress().pipe(
          switchMap(r => {
            let ip = ''
            if (r.status) {
              ip = r.payload;
            }
            if (this.type === 'admin') {
              return this.applicationService.updateAdminPrivacyConsentStatusFn((this.privacyConsent as PrivacyConsent).id, 'accepted', ip)
            } else {
              return this.applicationService.updatePrivacyConsentStatusFn((this.privacyConsent as PrivacyConsent).id, 'accepted', ip)
            }
          }),
          this.toastService.spinnerObservable()
        ).subscribe(() => {
          this.reload();
        })
      )
    }
  }

  reload() {
    if (this.privacyConsent) {
      if (this.type === 'admin') {
        this.subscriptions.push(
          this.applicationService.getAdminPrivacyConsentFn(this.privacyConsent.id).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(privacyConsent => {
            this.privacyConsent = privacyConsent;
          })
        )
      } else {
        this.subscriptions.push(
          this.applicationService.getPrivacyConsentById(this.privacyConsent.id).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(privacyConsent => {
            this.privacyConsent = privacyConsent;
          })
        )
      }
    }
  }
}
