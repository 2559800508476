
<div class="acn-component">
  <mat-form-field>
    <mat-label>ABN</mat-label>
    <input type="text" [inputMask]="createAbnInputMask"  matInput [formControl]="formControl" [disableControl]="disabled">
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
