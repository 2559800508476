<div class="disbursement-payee-dialog">
  <mat-dialog-content>
    <h3 class="header">Please select a payee</h3>
    <div class="row">
      <div class="col-8">
        <mat-form-field>
          <mat-label>Payee</mat-label>
          <mat-select [formControl]="formControlPayee" [disabled]="!!formControlManual.value">
            @for (payee of allPayees; track payee) {
              <mat-option [value]="payee">
                @if (payee.type === 'supplier') {
                  {{payee.supplier?.SupplierName ?? ''}} (ABN: {{payee.supplier?.ABN ?? 'unknown'}})
                }
                @if (payee.type === 'private-seller') {
                  @if (payee?.privateSeller?.business) {
                    {{payee.privateSeller?.business?.organisationName ?? ""}} ({{payee.privateSeller?.business?.abn ?? ""}})
                  }
                  @if (!payee?.privateSeller?.business) {
                    {{payee.privateSeller?.firstName ?? ''}} {{payee.privateSeller?.lastName ?? ''}}
                  }
                }
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
    <div class="col-8">
      <mat-checkbox [formControl]="formControlManual" color="primary" class="check-box">Manual Input</mat-checkbox>
    </div>
  </div>
  <div class="row" *ngIf="formControlManual.value">
    <div class="col-8">
      <mat-form-field>
        <mat-label>Payee Manual</mat-label>
        <input [formControl]="formControlPayeeManual" matInput placeholder="Enter payee name">
      </mat-form-field>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
        <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
        <button color="primary" [disabled]="(!formControlManual.value && !formControlPayee.valid) || (formControlManual.value && !formControlPayeeManual.value)"
        (click)="onSubmit(true)" mat-flat-button>Confirm</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
</div>
