<div class="policy-component">
  @for (policyFormGroup of policiesFormGroup(); track policyFormGroup; let index = $index) {
    <div class="row separator-bottom">
      <div class="col">
        <div class="mat-h3">Policy {{index + 1}}</div>
        <div class="mat-body">Minimum 1 policy required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <cover-type-selection [mark]="markObservable"  [formControl]="policyFromGroupFormControl(policyFormGroup, 'coverType')"></cover-type-selection>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Policy number</mat-label>
          <input maxlength="15" matInput [formControl]="policyFromGroupFormControl(policyFormGroup, 'policyNumber')">
          @for (errorKey of errorKeys(policyFromGroupFormControl(policyFormGroup, 'policyNumber')); track errorKey) {
            <mat-error>
              @if (policyFromGroupFormControl(policyFormGroup, 'policyNumber').touched && policyFromGroupFormControl(policyFormGroup, 'policyNumber').hasError(errorKey)) {
                {{errorMessage(policyFromGroupFormControl(policyFormGroup, 'policyNumber'), errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <insurer-selection [mark]="markObservable" [formControl]="policyFromGroupFormControl(policyFormGroup, 'insurer')"></insurer-selection>
      </div>
      <div class="col-3">
        <datepicker [mark]="markObservable" title="Inception date" [formControl]="policyFromGroupFormControl(policyFormGroup, 'inceptionDate')"></datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <datepicker [mark]="markObservable" [allowOnlyTodayOrFutureDate]="true" title="Expiry date" [formControl]="policyFromGroupFormControl(policyFormGroup, 'expiryDate')"></datepicker>
      </div>
      <div class="col-3">
        <currency-input [mark]="markObservable" title="Premium amount" [min]="MIN_PREMIUM_AMOUNT" [formControl]="policyFromGroupFormControl(policyFormGroup, 'premiumAmount')"></currency-input>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Invoice number</mat-label>
          <input maxlength="15"  matInput [formControl]="policyFromGroupFormControl(policyFormGroup, 'invoiceNumber')" placeholder="Enter number">
          @for (errorKey of errorKeys(policyFromGroupFormControl(policyFormGroup, 'invoiceNumber')); track errorKey) {
            <mat-error>
              @if (policyFromGroupFormControl(policyFormGroup, 'invoiceNumber').touched && policyFromGroupFormControl(policyFormGroup, 'invoiceNumber').hasError(errorKey)) {
                {{errorMessage(policyFromGroupFormControl(policyFormGroup, 'invoiceNumber'), errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col">
        <button mat-stroked-button [disabled]="formArray.length <= 1" (click)="removePolicy(policyFormGroup)">
          <span class="mdi mdi-minus" matPrefix></span>
          Remove policy
        </button>
      </div>
    </div>
  }

  <div class="row separator-bottom">
    <div class="col">
      <button mat-stroked-button (click)="addPolicy()">
        <span class="mdi mdi-plus" matPrefix></span>
        Add policy
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="mat-h3">Total</div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <currency-input [formControl]="formControlTotal" title="Total" ></currency-input>
    </div>
    <div class="col-3">
      <div class="installments">Paid by 10 monthly installments</div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <currency-input [readonly]="true" [formControl]="formControlMonthlyInstallment" title="Monthly installment" ></currency-input>
    </div>
    <div class="col-3">
      <currency-input [readonly]="true" [formControl]="formControlApplicationFee" title="Application fee" ></currency-input>
    </div>
    <div class="col-3">
      <currency-input [readonly]="true" [formControl]="formControlBrokerage" title="Brokerage" ></currency-input>
    </div>
  </div>

</div>
