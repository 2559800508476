@if (hasRating) {
  <div class="reference-component">
    <div class="row">
      @if (ratingNumber !== null && ratingNumber !== undefined) {
        <div class="col-lg-3 col-md-12">
          <risk-rating-chart [growRiskRatingNumber]="ratingNumber"></risk-rating-chart>
        </div>
      }
      @if (ratingData) {
        <div class="col-lg-9 col-md-12">
          <risk-rating-table [riskRatingData]="ratingData"></risk-rating-table>
        </div>
      }
    </div>
  </div>
}
