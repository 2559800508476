export type BalloonPaymentType = '0' | '10' | '20' | '25' | '30' | '35' | '40' | '45' | '50';

export type BalloonPaymentValue = {
  type: BalloonPaymentType,
  name: string;
} | null;

export const BalloonPaymentValueOptions: Exclude<BalloonPaymentValue, null>[] =[
  {type: '0',  name: '0%'},
  {type: '10', name: '10%'},
  {type: '20', name: '20%'},
  {type: '25', name: '25%'},
  {type: '30', name: '30%'},
  {type: '35', name: '35%'},
  {type: '40', name: '40%'},
  {type: '45', name: '45%'},
  {type: '50', name: '50%'},
];
