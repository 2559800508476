<div class="authorised-signatory-component">
  @for (sig of authorisedSignatories; track sig; let index = $index) {
    <div class="section">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Authorised Signatory {{index + 1}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{sig.Title}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body><p [innerHTML]="sig.GivenName | ellipsis:10"></p></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Last name</div>
            <div body><p [innerHTML]="sig.SurName | ellipsis:10"></p></div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Middle name</div>
            <div body><p [innerHTML]="sig.MiddleName | ellipsis:10"></p></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Email</div>
            <div body>{{sig.Email}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Mobile number</div>
            <div body>{{sig.MobileNumber}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Role</div>
            <div body>{{sig.Role}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    </div>
  }
</div>
