import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {UploadFileComponent} from './upload-file.component';
import {UploadedFilesListComponent} from './uploaded-files-list.component';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import { UploadFilesMultiTagsComponent } from './upload-files-multi-tags.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        UploadFileComponent,
        UploadedFilesListComponent,
        UploadFilesMultiTagsComponent
    ],
    exports: [
        UploadFileComponent,
        UploadedFilesListComponent,
        UploadFilesMultiTagsComponent
    ]
})
export class UploadFileModule {

}
