import {Component, Input} from '@angular/core';
import {SoleTraderValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'soletraders-summary',
    template: `
      @if (soletraders) {
        @for (soletrader of soletraders; track soletrader; let index = $index) {
          @if (soletrader) {
            <div class="row">
              <div class="col-12">
                <div class="mat-h3">Sole Trader {{index + 1}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Title</div>
                  <div body>{{soletrader?.title?.name}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>First name</div>
                  <div body><p [innerHTML]="soletrader.firstName | ellipsis:10"></p></div>
                </cell>
              </div>
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Last name</div>
                  <div body><p [innerHTML]="soletrader.lastName | ellipsis:10"></p></div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Gender</div>
                  <div body>{{soletrader.gender}}</div>
                </cell>
              </div>
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Date of birth</div>
                  <div body>{{displayableDate(soletrader.dob)}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <cell>
                  <div label>Residential address</div>
                  <div body>{{soletrader.residentialAddress?.address}}</div>
                </cell>
              </div>
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Privacy consent</div>
                  <div body>{{booleanToYesNo(soletrader.privacyConsentObtained)}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            @if (soletrader.propertyOwner) {
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Property Owner</div>
                    <div body>{{booleanToYesNo(soletrader.propertyOwner.propertyOwner)}}</div>
                  </cell>
                </div>
              </div>
            }
            @if (soletrader.propertyOwner && soletrader.propertyOwner.propertyOwner) {
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <cell>
                    <div label>Property address</div>
                    <div body>{{soletrader.propertyOwner.address?.address}}</div>
                  </cell>
                </div>
              </div>
            }
            @if (soletrader.propertyOwner && soletrader.propertyOwner.propertyOwner) {
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Property value</div>
                    <div body>{{soletrader.propertyOwner.propertyValue}}</div>
                  </cell>
                </div>
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Mortgage value</div>
                    <div body>{{soletrader.propertyOwner.mortgageValue}}</div>
                  </cell>
                </div>
              </div>
            }
            <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Guarantor</div>
                  <div body>{{booleanToYesNo(soletrader.guarantor)}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <cell>
                  <div label>Email address</div>
                  <div body>{{soletrader.email}}</div>
                </cell>
              </div>
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Business landline</div>
                  <div body>{{soletrader.mobile}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          }
        }
      }
      
      
      
      
      
      `,
    standalone: true,
    imports: [CellComponent, MatDividerModule, EllipsisPipe]
})
export class SoletradersSummaryComponent {


  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;


  @Input({required: false}) soletraders?: SoleTraderValue[];

}
