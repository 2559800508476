<div class="monthly-debits-credits-table">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"
          [ngClass]="{'width-1': column === 'totalcredit', 'width-2': column !== 'totalcredit'}">
          <div class="cell">{{getColumnTitles(column)}}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
          <div class="cell">
            @if (needCurrencyPipe(column)) {
              {{element[column] | looseCurrency}}
            }
            @if (!needCurrencyPipe(column)) {
              {{element[column]}}
            }
          </div>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
    </tr>
  </table>
</div>

