import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {AssetSupplierSelectComponent} from './asset-supplier-select.component';
import {NewSupplierComponent} from './new-supplier.component';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {RadioOptionsModule} from '../radio-options-component/radio-options.module';
import {AddressModule} from '../address-component/address.module';
import {MobileModule} from '../mobile-component/mobile.module';
import {SelectOrNewAssetSupplierComponent} from './select-or-new-asset-supplier.component';
import {AssetCatAndTypeModule} from '../asset-cat-and-type-component/asset-cat-and-type.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { BsbModule } from '../bsb-component/bsb.module';
import {EmailModule} from '../common fields/email.module';
import {AccountNumberModule} from '../account-number-component/account-number.module';
import {NameModule} from '../name-component/name.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LocalBankAccountDetailModule } from '../local-bank-account-detail-component/local-bank-account-detail.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        TitleSelectionModule,
        CurrencySelectionModule,
        RadioOptionsModule,
        AddressModule,
        MobileModule,
        AssetCatAndTypeModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        BsbModule,
        EmailModule,
        AccountNumberModule,
        NameModule,
        LocalBankAccountDetailModule,
        MatDialogModule,
        AssetSupplierSelectComponent,
        NewSupplierComponent,
        SelectOrNewAssetSupplierComponent
    ],
    exports: [
        AssetSupplierSelectComponent,
        NewSupplierComponent,
        SelectOrNewAssetSupplierComponent,
    ],
    // NOTE: this is done in angular-material-library.module.ts where it makes more sense there
    // providers: [
    //     {
    //         provide: MatDialogRef,
    //         useValue: {}
    //     }
    // ]
})
export class AssetSupplierSelectModule {

}
