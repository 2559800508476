import {Component, Injectable, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import { navigationUrlForLogin } from '../../service/navigation-urls';
import { clearAllStorage } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    templateUrl: './maintenance.page.html',
    styleUrls: ['./maintenance.page.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class MaintenancePage implements OnInit {


  constructor(private m: MinimalLayoutService,private router: Router) {
  }

  ngOnInit(): void {
    this.m.settings({
      showAccreditationInContact: false,
      showContact: true,
      showCross: false,
      showPrev: false
    });
  }
  async reLogin($event: MouseEvent) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin());
  }
}
