import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Accreditation } from '@portal-workspace/grow-shared-library';

import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';

@Component({
    selector: 'accreditation-acc',
    templateUrl: './accreditation-acc.component.html',
    styleUrls: ['./accreditation-acc.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule]
})
export class AccreditationAccComponent implements OnInit {
  @Input({required: true}) accreditation!: Accreditation;


  ngOnInit() {
    console.log(this.accreditation, 'in accc');
    this.getProducts();
  }

  getProducts(){
    const products = JSON.parse(this.accreditation.Products)
    var finalProduct = [];
    var keys = Object.keys(products);
    var filtered = keys.filter(function(key) {
      return products[key]
    });
    if(filtered.includes('assetFinance')) finalProduct.push('Asset Finance')
    if(filtered.includes('tradeFinance')) finalProduct.push('Trade Finance')
    if(filtered.includes('businessLoan')) finalProduct.push('Business Loan')
    if(filtered.includes('insurancePremium')) finalProduct.push('Insurance Premium')
    return finalProduct
  }

  getAddress(){
    return JSON.parse(this.accreditation.businessAddress).UnformattedAddress;
  }

}
