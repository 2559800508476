
<div class="users-page container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
        <mat-tab label="Brokers">
          <ng-container *ngTemplateOutlet="brokerTab"></ng-container>
        </mat-tab>
        @if (isInternalUser()) {
          <mat-tab label="Customers">
            <ng-container *ngTemplateOutlet="customerTab"></ng-container>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>

  <ng-template #brokerTab>
    <user-table
      #brokerUserTable="userTableComponent"
      type="broker-user"
      (events)="onUsersTableEvent($event)"
      [approveOrRejectFn]="approveOrRejectFn"
      [userTableFn] = "getUserTableFn">
    </user-table>
  </ng-template>
  <ng-template #customerTab>
    <user-table
      #customerUserTable="userTableComponent"
      type="customer-user"
      (events)="onUsersTableEvent($event)"
      [approveOrRejectFn]="approveOrRejectFn"
      [userTableFn] = "getUserTableFn">
    </user-table>
  </ng-template>
</div>
