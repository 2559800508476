import {LoanTermValue} from './loan-terms-selection-component.domain';

export const ConsumerLoanTermValueOptions: Exclude<LoanTermValue, null>[] = [
  {type: '12', name: '12 Months'},
  {type: '24', name: '24 Months'},
  {type: '36', name: '36 Months'},
  {type: '48', name: '48 Months'},
  {type: '60', name: '60 Months'},
  {type: '72', name: '72 Months'},
  {type: '84', name: '84 Months'},
];

