<div class="notification-list-component">
  <div class="row separator-bottom">
    <div class="col-12"
      fxLayoutGap="10px"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start"
      >
      <button mat-flat-button color="primary" class="w-lt-md-100"  (click)="markAllAsRead($event)" [disabled]="(isLoadingMarkRead == true || isLoadingDelete == true)">
        <span class="mdi mdi-check-all" matPrefix></span>
        <span>{{isLoadingMarkRead ? "Marking all as read..." : "Mark all as read"}}</span>
      </button>
      <button mat-flat-button color="primary" class="w-lt-md-100" (click)="deleteAll($event)" [disabled]="(isLoadingMarkRead == true || isLoadingDelete == true)">
        <span class="mdi mdi-trash-can-outline" matPrefix></span>
        <span>{{isLoadingDelete ? "Deleting all..." : "Delete all"}}</span>
      </button>
    </div>
  </div>

  @if (notifications && notifications.length <= 0) {
    <div class="row">
      <div class="col-12">
        <div class="no-notifications" fxLayout="row" fxLayoutAlign="center center">
          <span>YOU DON'T HAVE ANY NOTIFICATIONS</span>
        </div>
      </div>
    </div>
  }

  @if (notifications && notifications.length > 0) {
    <div class="row">
      <div class="col-12">
        @for (notification of notifications; track notification) {
          <div fxLayout="row" fxLayoutAlign="start start" class="message">
            <div class="read" fxFlex="0 0 auto">
              @if (!notification.readId) {
                <span class="full-notification"></span>
              }
            </div>
            <div class="content" fxFlex="1 1 auto">
              <div class="mat-body cursor-pointer" (click)="onReadNotification($event, notification)">{{notification.message_title}}</div>
              <div class="mat-caption">{{notification.recieve_time | date:'dd/MM/yyyy'}}</div>
              @if (showNotification(notification)) {
                <div class="description">{{notification.description}}</div>
              }
            </div>
            <div class="avatar" fxFlex="0 0 auto">
              <span class="mdi mdi-information-outline"></span>
            </div>
            <div class="action" fxFlex="0 0 auto">
              <span class="mdi mdi-trash-can-outline cursor-pointer" (click)="delete($event, notification)"></span>
            </div>
          </div>
        }
      </div>
    </div>
  }


</div>
