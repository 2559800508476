import {validApplicationTypeGuard} from './abstract-application.guard';

export const validInsurancePremiumGuard = () => {
  return validApplicationTypeGuard('InsurancePremium', 'e-not-insurance');
}

// NOTE: Deprecated since Angular 16, replaced by validApplicationTypeGuard() instead
// import {Injectable} from '@angular/core';
// import {AbstractApplicationGuard} from './abstract-application.guard';
// import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
//
//
// @Injectable()
// export class ValidInsurancePremiumGuard extends AbstractApplicationGuard {
//
//   getApplicationType(): ApplicationTypes {
//     return 'InsurancePremium';
//   }
//
//   getApplicationTypeErrorCode(): string {
//     return 'e-not-insurance';
//   }
//
// }
