import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  compareMatch,
  PrimaryIndustry,
  PrimaryIndustrySelectionValue,
  PrimaryIndustrySelectionValueOptions
} from '@portal-workspace/grow-shared-library';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {IndustrySelectionModuleService} from './industry-selection-module.service';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'primary-industry-selection',
    templateUrl: './primary-industry-selection.component.html',
    styleUrls: ['./primary-industry-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PrimaryIndustrySelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PrimaryIndustrySelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class PrimaryIndustrySelectionComponent extends AbstractControlValueAccessor<PrimaryIndustrySelectionValue> implements OnInit, Mark {

  formGroup: FormGroup<{
    select: FormControl<PrimaryIndustrySelectionValue>;
  }>;
  formControl: FormControl<PrimaryIndustrySelectionValue>;

  primaryIndustries: PrimaryIndustry[] = PrimaryIndustrySelectionValueOptions;
  subscription?: Subscription;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  compareWithFn: (option: PrimaryIndustrySelectionValue, selected: PrimaryIndustrySelectionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.division === selected.division);
  };

  constructor(private formBuilder: FormBuilder,
              private service: IndustrySelectionModuleService) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      select: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.service.setPrimaryIndustry(r ? r : null);
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v?: PrimaryIndustrySelectionValue): void | PrimaryIndustrySelectionValue {
    if (v) {
      const op = PrimaryIndustrySelectionValueOptions.find(opt => opt.division === v.division);
      if (op) {
        this.formControl.setValue(op);
        return op;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
