import {Component, Input, OnInit} from '@angular/core';
import { Application, Reference } from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { FlexModule } from '@angular/flex-layout/flex';



@Component({
    selector: 'references-details',
    templateUrl: './references-details.component.html',
    styleUrls: ['./references-details.component.scss'],
    standalone: true,
    imports: [FlexModule, CellComponent, MatDividerModule]
})
export class ReferencesDetailsComponent implements OnInit {

  @Input({required: true}) application!: Application;

  references: Reference[] = [];

  ngOnInit(): void {
    this.references = this.application.Reference ?? [];
  }



}
