import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  BPayPayment,
  BPayTransactionDialogData, BPayTransactionDialogResult, DirectPayment, DirectPaymentBatchDialogData, DirectPaymentBatchDialogResult
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';

@Component({
  templateUrl: './direct-payment-batch.dialog.html',
  styleUrls: ['./direct-payment-batch.dialog.scss'],
  standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatOptionModule, MatDialogModule]
})
export class DirectPaymentBatchDialog {
  formControlDate!: FormControl<string | null>;
  formControlUserName!: FormControl<string | null>;
  formControlUserNumber!: FormControl<number | null>;
  formControlDescription!: FormControl<string | null>;
  formGroup!: FormGroup;
  payments: DirectPayment[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: DirectPaymentBatchDialogData,
              private dialogRef: MatDialogRef<DirectPaymentBatchDialog, DirectPaymentBatchDialogResult>,
              private formBuilder: FormBuilder) {
    this.payments = data.payments;
    this.formControlDate = this.formBuilder.control(null, [Validators.required]);
    this.formControlUserName = this.formBuilder.control(null, [Validators.required]);
    this.formControlUserNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlDescription = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      date: this.formControlDate,
      userName: this.formControlUserName,
      userNumber: this.formControlUserNumber,
      description: this.formControlDescription,
    })
    // TO DO - validations
  }

  onSubmit(confirmation: boolean) {
    console.log({
      readyForSubmission: confirmation,
      batchDirectPayment: {
        ...this.formGroup.value,
        payments: this.payments
      }
    })
    this.dialogRef.close({
      readyForSubmission: confirmation,
      batchDirectPayment: {
        ...this.formGroup.value,
        payments: this.payments
      }
    })
  }
}
