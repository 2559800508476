import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { NgClass } from '@angular/common';

export interface BreadcrumbComponentEvent {
  type: 'back' | string;
}

@Component({
    selector: 'breadcrumb',
    templateUrl: 'breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [TruncatePipe, NgClass]
})
export class BreadcrumbComponent {

  @Input({required: false}) trails: string[] = [];
  @Input({required: false}) defaultIcon: string | undefined = undefined;
  @Output() events: EventEmitter<BreadcrumbComponentEvent> = new EventEmitter<BreadcrumbComponentEvent>()

  onBackClicked() {
    this.events.emit({type: 'back'});
  }

  onClick(trial: string) {
    if (trial === 'Applications') {
      this.events.emit({ type: 'back' });
    }
  }
}
