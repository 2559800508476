<div class="sole-trader-component">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Sole Trader</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <title-selection
                       [mark]="markObservable"
                       [formControl]="formControlTitle">
      </title-selection>
    </div>
    <div class="col-lg-3 col-md-12">
      <name title="First Name" [mark]="markObservable" [formControl]=formControlFirstName></name>
    </div>
    <div class="col-lg-3 col-md-12">
      <name title="Last Name" [mark]="markObservable" [formControl]=formControlLastName></name>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <datepicker
                  [mark]="markObservable"
                  title="Date of birth"
                  [formControl]="formControlDob"
                  [allowOnly18YrsPlus]="true">
      </datepicker>
    </div>
    <div class="col-lg-3 col-md-12">
      <gender
              [mark]="markObservable"
              [formControl]="formControlGender"></gender>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <custom-address
                [mark]="markObservable"
                title="Residential address"
                [formControl]="formControlResidentialAddress">
      </custom-address>
    </div>
    <div class="col-lg-3 col-md-12">
      <yes-no
              [mark]="markObservable"
              [notselected]="true"
              title="Privacy consent obtained"
              [formControl]="formControlPrivacyConsent">
      </yes-no>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <yes-no
              [mark]="markObservable"
              title="Guarantor"
              [formControl]="formControlGuarantor">
      </yes-no>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <property-owner-with-address
        [mark]="markObservable"
        [formControl]="formControlPropertyOwner"
        [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(formControlResidentialAddress)"
        [showAddressForm]="showAddressForm">
      </property-owner-with-address>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <email [mark]="markObservable" [formControl]="formControlEmailAddress"></email>
    </div>
    <div class="col-lg-3 col-md-12">
      <mobile
        [mark]="markObservable" [mandatory]="true" [onlyMobile]="true"
        [formControl]="formControlMobileNumber">
      </mobile>
    </div>
  </div>

</div>
