import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  AssetFinanceApplication,
  AssetSettlementGetApplicationAssetFn,
  ConfirmationDialogResult,
  CreateApplicationNoteFn, DeleteApprovalNoticeFileFn,
  GenerateApprovalNoticeFn, GetApplicationByIdFn,
  GetApplicationOwnerFn,
  GetContractStatusFn,
  GetDisbursementByApplicationIdFn,
  GetUserFn,
  RemoveApplicationNoteByNoteIdFn, SendApprovalNoticeEmailFn, SettleLoanFn, UpdateApplicationStageInSfFn
} from '@portal-workspace/grow-shared-library';
import { ApproveApplicationDocumentFn, CompleteDocumentWorklistFn, CreateDocumentWorklistFn, DeclineApplicationDocumentFn, DeleteApplicationDocumentFn, DocumentDetailsComponentEvent, DownloadAllApplicationDocumentUrlFn, DownloadApplicationDocumentUrlFn, ListApplicationDocumentFn, UpdateApplicationDocumentTagsFn, UpdateApplicationRequiredDocumentsFn, DocumentDetailsComponent, UndoApplicationDocumentFn } from '../document-details.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {
  ApplicationDialogService,
  GetApplicationAuditLogsFn,
  GetNotesByApplicationIdFn,
  PortalHotToastService,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import { AssetNotesComponent } from '../../asset-listing-component/asset-notes.component';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-documents',
    templateUrl: './asset-documents.component.html',
    styleUrls: ['./asset-documents.component.scss'],
    standalone: true,
    imports: [DocumentDetailsComponent, AssetNotesComponent]
})
export class AssetDocumentsComponent implements OnInit  {
  
  @ViewChild('assetNotesComponent') assetNotesComponent!: AssetNotesComponent;

  subscriptions: Subscription[] = [];

  @Input() apiUrl!: string;
  @Input() application!: AssetFinanceApplication;
  @Input() uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input() listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input() downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input() approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input() declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input() deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input() downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input() updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input() updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
   
  @Input() createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input() completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn
  @Input() getApplicationAuditLogsFn!:GetApplicationAuditLogsFn
  @Input() generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input() sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input() deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input() getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input() undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input() getUserFn!: GetUserFn;
  @Input() isAssetEmpty: boolean = false;
  @Input() notAllAssetAccepted: boolean = false;
  @Input() missingBankDetails: null | 'customer' | 'broker' = null;
  @Input() getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input() getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input() settleLoanFn!: SettleLoanFn;
  @Input() updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  totalNotesCount: number = 0;

  
  
  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  constructor(private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,) {}


  async onDocumentDetailsEvent($event: DocumentDetailsComponentEvent) {
    const files = $event.files;
    const removeFiles = $event.removefileNames;
    const isNonStandardConditionDocument = $event.isNonStandardConditionDocument;
    this.subscriptions.push(
      (await this.uploadApplicationDocumentFn(this.application.ApplicationId, files, removeFiles))
      .pipe(
        this.toastService.spinnerObservable(),
        tap(app => {
          this.application = app as AssetFinanceApplication;
        })
      ).subscribe(() => {
        if(isNonStandardConditionDocument){
          this.subscriptions.push(
            this.dialogService.openConfirmationDialog({
              message: 'Please confirm',
              subMessage: 'Have you uploaded all documents for non-standard conditions<br/> and wish to submit the application to the credit team for review?'
            }).afterClosed().pipe(
              tap(async (r: ConfirmationDialogResult | undefined) => {
                if (r && r.readyForSubmission) {
                  this.onSubmitForReview();
                }
            })
            ).subscribe()
          );
        }

        if (files.length) {
          if ((files[0] as any).tags.includes('bankstatements')) {
            this.subscriptions.push(
              this.dialogService.openConfirmationDialog({
                message: 'Please confirm',
                subMessage: 'Have you attached all the bank statements and would like to send the application to our Credit team for review?'
              }).afterClosed().pipe(
                tap(async (r: ConfirmationDialogResult | undefined) => {
                  if (r && r.readyForSubmission) {
                    this.onSubmitForCreditReview();
                  }
              })
              ).subscribe()
            );
          }
        }
        // this.toastService.snackbar(
        //   "Click 'Submit for Review' to get our team to review the new documents you have uploaded",
        //   'Close'
        // )
      })
    );
  }

  totalNotes($event: number){
    this.totalNotesCount = $event;
  }

  scrollToChildTwo(){
    const targetElement = document.getElementById('notes-component-section');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  onSaveNote(){
    this.assetNotesComponent.reload();
  }
  onSubmitForReview() {
    this.subscriptions.push(
      this.createDocumentWorklistFn({
        applicationId: this.application.ApplicationId,
        createdTime: new Date(),
        userId: null,
        status: 'pending',
        entityName: this.application.entityName,
        brokerAppId: this.application.BrokerAppId
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Submission success',
          errorMessage: 'Failed to submit for review',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.createDocumentWorklistFn({
              applicationId: this.application.ApplicationId,
              createdTime: new Date(),
              userId: null,
              status: 'pending',
              entityName: this.application.entityName,
              brokerAppId: this.application.BrokerAppId
            })
          }
        })
      ).subscribe(r => {
        if (r) {
          if (!r.payload) {
            this.dialogService.openAlertDialog({
              message: "Warning",
              subMessage: "You have already submitted this application for review"
            })
          }
        }
      })
    )
  }

  onSubmitForCreditReview() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? '',
        stageName: "Submitted to Credit"
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Submission success',
          errorMessage: 'Failed to submit for credit review',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.updateApplicationStageFn({
              salesforceId: this.application.AppInfoSalesforceID ?? '',
              stageName: "Submitted to Credit"
            })
          }
        })
      ).subscribe()
    )
  }
}
