import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';

import {AssetConditionValue, AssetConditionValueOptions, compareMatch} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'asset-condition',
    templateUrl: './asset-condition.component.html',
    styleUrls: ['./asset-condition.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetConditionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetConditionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class AssetConditionComponent extends AbstractControlValueAccessor<AssetConditionValue> implements  OnInit, Mark {

  compareWithFn: (option: AssetConditionValue, selected: AssetConditionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  options = AssetConditionValueOptions;

  formGroup: FormGroup<{
    control: FormControl<AssetConditionValue>
  }>
  formControl: FormControl<AssetConditionValue>;
  subscription: Subscription;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      control: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap( (r: AssetConditionValue)  => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: AssetConditionValue | null | undefined): void | AssetConditionValue {
    if (v) {
      const op = this.options.find(o => o.type === v.type);
      if (op) {
        this.formControl.setValue(op);
        return op;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

}
