import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { AddCreditFlowFn, AllApplicationTypes, ApplicationTypes, CopyCreditFlowFn, CreditFlow, DEFAULT_APPLICATION_TYPE_FILTER, DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_PUBLISHED_STATUS_FILTER, GetCreditFlowsFn, PublishCreditFlowBody, PublishCreditFlowFn, PublishedStatus, UpdateCreditFlowFn } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { EventEmitter } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { ApplicationTypeIconComponent } from '../application-type-icon/application-type-icon.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';

export interface ApplicationTypeFilter {
  type: AllApplicationTypes,
  name: string;
}
export interface PublishedStatusFilter {
  type: PublishedStatus,
  name: string;
}
@Component({
    selector: 'credit-flows',
    templateUrl: './credit-flows.component.html',
    styleUrls: ['./credit-flows.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatTooltipModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      ApplicationTypeIconComponent,
      MatOptionModule,
      MatSelectModule,
      MatLabel
    ]
})
export class CreditFlowsComponent implements OnInit {
  @Input({required: true}) addCreditFlowFn!: AddCreditFlowFn;
  @Input({required: true}) getCreditFlowsFn!: GetCreditFlowsFn;
  @Input({required: true}) updateCreditFlowFn!: UpdateCreditFlowFn;
  @Input({required: true}) copyCreditFlowFn!: CopyCreditFlowFn;
  // @Input({ required: true }) publishCreditFlowFn!: PublishCreditFlowFn;
  // private publishCreditFlowFn: PublishCreditFlowFn

  @Output() onNavigateCreditFlow = new EventEmitter<number>();

  subscriptions: Subscription[] = [];
  moment = moment;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: CreditFlow[] = [];
  filteredDataSource: CreditFlow[] = [];
  displayedData: CreditFlow[] = [];
  columnsToDisplay = ['name', 'products', 'published', 'lastUpdatedTime', 'actions'];
  applicationTypes: ApplicationTypeFilter[] = [
    DEFAULT_APPLICATION_TYPE_FILTER,
    { type: 'AssetFinance', name: 'Asset Finance' },
    { type: 'BusinessLoans', name: 'Business Term Loan' },
    { type: 'BusinessOverdraft', name: 'Business Overdraft' },
    { type: 'InsurancePremium', name: 'Insurance Premium' },
    { type: 'Consumer', name: 'Consumer Asset Finance' },
    { type: 'CorporateLoans', name: 'Corporate Loan' }
  ];
  pusblishedStatus: PublishedStatusFilter[] = [
    DEFAULT_PUBLISHED_STATUS_FILTER,
    { type: true, name: 'Yes' },
    { type: false, name: 'No' },
  ];
  selectedApplicationType: ApplicationTypeFilter = DEFAULT_APPLICATION_TYPE_FILTER;
  selectedPublishedStatus: PublishedStatusFilter = DEFAULT_PUBLISHED_STATUS_FILTER;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private applicationService: ApplicationService,
  ) {
    this.formControlSearch = this.formBuilder.control(null);
    // this.publishCreditFlowFn = this.applicationService.publishCreditFlowFn;
  }
  private resetPagination() {
    this.offset = DEFAULT_OFFSET;
  }

  ngOnInit(): void {
    this.reload();

    this.subscriptions.push(
      this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(r => {
          if (r) {
            const text = r.trim().toLowerCase();
            this.filteredDataSource = this.dataSource.filter(flow => 
              flow.name.trim().toLowerCase().includes(text)
            );
            this.initPagination();
          } else {
            this.filteredDataSource = this.dataSource;
            this.initPagination();
          }
        })
      ).subscribe()
    )
  }

  reload() {
    this.subscriptions.push(
      this.getCreditFlowsFn().pipe(
        this.loader.tableLoading.track()
      ).subscribe(flows => {
        this.dataSource = flows;
        this.filteredDataSource = this.dataSource;
        this.initPagination();
      })
    )
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'name': return 'Name';
      case 'products': return 'Products';
      case 'published': return 'Published';
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'actions': return '';
      default: return column;
    }
  }
  extractTrueKeys(obj: { [key in ApplicationTypes]?: boolean }): ApplicationTypes[]{
    const applicationTypes: ApplicationTypes[] = ['AssetFinance', 'BusinessLoans', 'TradeFinance', 
      'Consumer', 'InsurancePremium', 'Commercial', 'BusinessOverdraft', 'CorporateLoans'];
    return applicationTypes.filter(key => obj[key] === true);
  };

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = 0;
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }
  
  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  addCreditFlow() {
    this.subscriptions.push(
      this.dialogService.openEditCreditFlowDialog({
        name: ''
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.addCreditFlowFn({name: response.name}).pipe(
              this.toastService.spinnerObservable()
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  editCreditFlowName(element: CreditFlow) {
    this.subscriptions.push(
      this.dialogService.openEditCreditFlowDialog({
        name: element.name
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.updateCreditFlowFn({name: response.name}, element.id).pipe(
              this.toastService.snackBarObservable(`Credit flow updated!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  deleteCreditFlow(element: CreditFlow) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Alert",
        subMessage: "Are you sure you want to delete this credit flow"
      }).afterClosed().subscribe(
        response => {
          if(response?.readyForSubmission) {
            this.updateCreditFlowFn({isDeleted: true}, element.id).pipe(
              this.toastService.snackBarObservable(`Credit flow deleted!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  editCreditFlow(element: CreditFlow) {
    this.onNavigateCreditFlow.emit(element.id);
  }

  copyCreditFlow(element: CreditFlow) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Copy Credit Flow",
        subMessage: "Are you sure you want to copy this credit flow?"
      }).afterClosed().subscribe(
        result => {
          if (result?.readyForSubmission) {
            this.copyCreditFlowFn({flowId: element.id}).subscribe((response) => {
              if (response.status) {
                this.dialogService.successDialog({
                  message: 'Success',
                  subMessage: 'Successfully copied the credit flow'
                }).afterClosed().subscribe(() => {
                  this.reload();
                })
              } else {
                this.dialogService.openAlertDialog({
                  message: 'Alert',
                  subMessage: 'Unable to copy the credit flow'
                }).afterClosed().subscribe()
              }
            })
          }
        }
      )
    )
  }

  publishCreditFlow(element: CreditFlow) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Publish Credit Flow",
        subMessage: "Are you sure you want to publish this credit flow?"
      }).afterClosed().subscribe(
        result => {
          if (result?.readyForSubmission) {
            this.toastService.spinnerObservable();
            this.applicationService.publishCreditFlowFn({force: false}, element.id).pipe(this.toastService.spinnerObservable()
              ).subscribe((response) => {
                if(response && response.payload && response.payload.length){
                  this.dialogService.openPublishCreditFlowConfirmation({
                    conflictedFlows: response.payload
                  }).afterClosed().subscribe(
                    (result) => {
                      if (result && result.force) {
                        // loader on
                        this.toastService.spinnerObservable()
                        this.applicationService.publishCreditFlowFn({ force: result.force }, element.id).pipe(
                          this.toastService.spinnerObservable()
                        ).subscribe(() => { 
                          this.toastService.snackBarObservable('Credit flow is published successfully');
                          this.reload();
                        })
                      }
                    }
                  )
                }
                if (response && response.payload && response.payload.length == 0) {
                  this.toastService.snackBarObservable('Credit flow is published successfully');
                  this.reload();
                }
              })
          }
        }
      )
    )
  }

  unpublishCreditFlow(element: CreditFlow) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Unpublish Credit Flow",
        subMessage: "Are you sure you want to unpublish this credit flow?"
      }).afterClosed().subscribe(
        result => {
          if (result?.readyForSubmission) {
            this.updateCreditFlowFn({published: false}, element.id).pipe(
              this.toastService.snackBarObservable(`Credit flow unpublished!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }
  getApplicationValue(item:any, applicationType:string) {
    const value = item[applicationType];
    return value === true || value === 1;
  }
  filterData() {
    this.displayedData = this.filteredDataSource.filter((flow)=> {
      const publishedMatch = (this.selectedPublishedStatus.type === 'All') || (flow.published === this.selectedPublishedStatus.type);
      const applicationMatch = (this.selectedApplicationType.type === 'All') || this.getApplicationValue(flow, this.selectedApplicationType.type)//|| (flow[this.selectedApplicationType] === true);
      return publishedMatch && applicationMatch;
    });
  }
  onApplicationTypeSelected(value: ApplicationTypeFilter) {
    this.selectedApplicationType = value;
    this.resetPagination();
    this.filterData();
  }
  onPublishedStatusSelected(value: PublishedStatusFilter) {
    this.selectedPublishedStatus = value;
    this.resetPagination();
    this.filterData();
  }
}
