import { Injectable } from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  getUser,
  httpOptions,
  AssetSettlementRemoveApplicationAssetFn,
  AssetSettlementSearchAssetsFn,
  AssetSettlementSubmitPpsrFn,
  AssetSettlementGetApplicationPpsrInfoFn,
  AssetSettlementGetPpsrDetailsFn, AssetSettlementUpdatePpsrDetailFn,
} from '@portal-workspace/grow-ui-library';
import {
  ApplicationAssetData,
  ApplicationNotes,
  AssetSearchBody, AssetSettlementGetApplicationAssetFn, CreateApplicationAuditLog, CreateApplicationNoteBody, CreateApplicationNoteFn,
  PaginablePayloadApiResponse,
  parseJSON,
  PartialOrNull,
  PpsrAsset,
  PpsrData,
  PpsrDataRaw,
  PpsrDocument,
  PpsrSubmissionData,
  RequestDoc,
  SaveSupplierData,
  SaveSupplierResult,
  SearchGrantorsResult,
  SendAssetToSfData,
  SendAssetToSFResult,
  UpdateDocumentsBody,
} from '@portal-workspace/grow-shared-library';
import { map } from 'rxjs/operators';
import { ApiResponse, PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import {AssetSupplier, VehicleDetails} from '@portal-workspace/grow-shared-library';
import {GetNotesByApplicationIdFn} from '@portal-workspace/grow-ui-library';

const URL_GET_PPSR_DETAILS = (applicationId: number) => `${environment.api2Host}/api2/get-ppsr-response/${applicationId}`; // get
const URL_GET_APPLICATION_ASSET = (applicationId: number) => `${environment.api2Host}/api2/get-application-assets/${applicationId}`; // get
const URL_GET_PPSR_DOCS = (applicationId: number) => `${environment.api2Host}/api2/get-PPSR-Document/${applicationId}`; //get
// const URL_SEARCH_ASSET_SUPPLIER_BY_NAME = (name: string) => `${environment.api2Host}/api2/search-supplier-by-name/${name}`; // post
const URL_SEARCH_ASSET_SUPPLIER_BY_ABN_ACN = (abn: string) => `${environment.api2Host}/api2/search-supplier-by-abn-acn/${abn}`; // post
const URL_SEARCH_ASSET = (applicationId: number) => `${environment.api2Host}/api2/searchAssets/${applicationId}`; // post
const URL_SEARCH_GRANTORS = (applicationId: number) => `${environment.api2Host}/api2/searchGrantors/${applicationId}`; // post

const URL_SAVE_SUPPLIER  = () => `${environment.api2Host}/api2/add-supplier-sf`;
const URL_REQUEST_DOC  = () => `${environment.api2Host}/api2/request-doc`;
// const URL_SAVE_DOCUMENTS = (applicationId: number) => `${environment.api2Host}/api2/update-application-documents2/${applicationId}`;
const URL_UPDATE_PPSR_DETAIL = () => `${environment.api2Host}/api2/update-ppsr-detail`; // post
const URL_ADD_PPSR_DETAIL = () => `${environment.api2Host}/api2/add-ppsr-data`;  // post
const URL_REMOVE_ASSET = (assetId: number) => `${environment.api2Host}/api2/remove-application-assets/${assetId}`; // post
const URL_UPDATE_ASSET = () => `${environment.api2Host}/api2/update-app-asset`; // post
const URL_SEND_ASSET_TO_SF = () => `${environment.api2Host}/api2/send-asset-to-sf`;  // post`
const URL_SUBMIT_PPSR = (applicationId: number) => `${environment.api2Host}/api2/submit-ppsr/${applicationId}`; // post
const URL_ADD_ASSET = () => `${environment.api2Host}/api2/add-app-asset`; // post

const URL_GET_NOTES_BY_APPLICATIONID = (applicationId: number,limit: number = 20, offset: number = 0,) => `${environment.api2Host}/api2/notes/${applicationId}?limit=${limit}&offset=${offset}`;   // get
const URL_CREATE_NOTE = () => `${environment.api2Host}/api2/add-app-notes`;//post
const URL_ADD_AUDIT_LOG = () => `${environment.api2Host}/api2/add-app-audit-logs`;//post
const URL_REMOVE_NOTE_BY_NOTESID = (notesId: number) => `${environment.api2Host}/api2/remove-notes/${notesId}`


@Injectable()
export class PpsrService {

  constructor(private httpClient: HttpClient) {
  }

  createNote(data: CreateApplicationNoteBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_CREATE_NOTE(), data, httpOptions());
  }

  createNoteFn: CreateApplicationNoteFn = (data: CreateApplicationNoteBody): Observable<ApiResponse> => {
    return this.createNote(data);
  }

  removeApplicationNoteByNoteId(noteId: number): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_REMOVE_NOTE_BY_NOTESID(noteId), {}, httpOptions())
  }

  removeApplicationNoteByNoteIdFn = (noteId: number): Observable<ApiResponse> => {
    return this.removeApplicationNoteByNoteId(noteId)
  }

  addAuditLog(data: CreateApplicationAuditLog ) {
    return this.httpClient.post<CreateApplicationAuditLog>(URL_ADD_AUDIT_LOG(), {
      ...data
    }, httpOptions()).pipe(
      map((r) => {
        return {
          status: true, message: '',
          payload: r
        }
      })
    );
  }

  addAuditLogFn = (data: CreateApplicationAuditLog): Observable<CreateApplicationAuditLog> => {
    return this.addAuditLog(data).pipe(map(r => r.payload));
  }

  getNotesByApplicationId(limit: number, offset: number, applicationId: number): Observable<PaginablePayloadApiResponse<ApplicationNotes>> {
    return this.httpClient.get<PaginablePayloadApiResponse<ApplicationNotes>>(URL_GET_NOTES_BY_APPLICATIONID(applicationId, limit, offset), httpOptions()).pipe();
  }

  getNotesByApplicationIdFn: GetNotesByApplicationIdFn = (limit: number, offset: number, applicationId: number): Observable<PaginablePayloadApiResponse<ApplicationNotes>> => {
    return this.getNotesByApplicationId(limit, offset, applicationId);
  }

  getApplicationPpsrInfoFn: AssetSettlementGetApplicationPpsrInfoFn = (applicationId: number) => {
    return combineLatest([
      this.getPpsrDetails(applicationId).pipe(map(r => r?.payload)),
      this.getApplicationAsset(applicationId).pipe(map(r => r?.payload)),
      this.getPpsrDocuments(applicationId).pipe(map(r => r?.payload)),
    ]);
  }



  addPpsrDetailFn = (data: PpsrDataRaw): Observable<number> => {
    return this.addPpsrDetail(data).pipe(map(r => r.payload));
  }
  addPpsrDetail(data: PpsrDataRaw): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_ADD_PPSR_DETAIL(), {
        ...data
      }, httpOptions()).pipe();
    //return this.httpClient.post<{ recordset: [{ ApplicationId: number }] }>(URL_ADD_PPSR_DETAIL(), {
    //  ...data
    //}, httpOptions()).pipe(
    //  map(r => {
    //    let data: any = r;
    //    return {
    //      status: true,
    //      message: '',
    //      payload: data.payload.recordset[0].ApplicationId
    //    }
    //  })
    //);
  }


  updatePpsrDetailFn: AssetSettlementUpdatePpsrDetailFn = (data: PpsrDataRaw): Observable<ApiResponse> => {
    return this.updatePpsrDetail(data).pipe();
  }
  updatePpsrDetail(data: PpsrDataRaw): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_PPSR_DETAIL(), {
      ...data
    }, httpOptions()).pipe();
    // return this.httpClient.post<any>(URL_UPDATE_PPSR_DETAIL(), {
    //   ...data
    // }, httpOptions()).pipe(
    //   map(r => {
    //     return {
    //       status: true, message: ''
    //     }
    //   })
    // )
  }

  searchAssetsFn: AssetSettlementSearchAssetsFn = (applicationId: number, data: { SearchNumber: string|undefined, NumberType: string|undefined}) => {
    return this.searchAssets(applicationId, data).pipe(map(r => r.payload));
  }
  searchAssets(applicationId: number, data:{
    SearchNumber: string|undefined,
    NumberType: string|undefined,
  }): Observable<PayloadApiResponse<VehicleDetails | {Error: any} | null>> {
    return this.httpClient.post<PayloadApiResponse<VehicleDetails | {Error: any} | null>>(URL_SEARCH_ASSET(applicationId), {
         SearchNumber: data.SearchNumber,
         NumberType: data.NumberType,
         SearchType: 'R',
       }, httpOptions()).pipe();
    // return this.httpClient.post<{ SearchResult: any, SearchSummary: any}>(URL_SEARCH_ASSET(applicationId), {
    //   SearchNumber: data.SearchNumber,
    //   NumberType: data.NumberType,
    //   SearchType: 'R',
    // }, httpOptions()).pipe(
    //   map(r => {
    //     let data:any = r
    //     let payload = null;
    //     if (!data.payload.Error) {
    //       const resultStatus = data.payload.SearchResult?.NevdisData?.VerificationStatus
    //       if (resultStatus === 'NotFound' || resultStatus === null) {
    //         const v: VehicleDetails = {
    //           BodyType: '',
    //           ManufactureYear: '',
    //           Make: '',
    //           Colour: '',
    //           EngineNumber: '',
    //           Model: '',
    //           VehicleType: '',
    //           ComplianceYearMonth: '',
    //           ResultCount: '',
    //           NoVehicleInfo: true
    //         }
    //         payload = v;
    //       } else {
    //         const nevdisVehicles = data.payload.SearchResult?.NevdisData?.NevdisVehicles?.NevdisVehicle;
    //         if (nevdisVehicles && nevdisVehicles.length) {
    //           const vehicleDetails = nevdisVehicles[0]?.VehicleDetail?.VehicleDescription;
    //           const v: VehicleDetails = {
    //             BodyType: vehicleDetails.BodyType,
    //             ManufactureYear: vehicleDetails.ManufactureYear,
    //             Make: vehicleDetails.Make,
    //             Colour: vehicleDetails.Colour,
    //             EngineNumber: vehicleDetails.EngineNumber,
    //             Model: vehicleDetails.Model,
    //             VehicleType: vehicleDetails.VehicleType,
    //             ComplianceYearMonth: vehicleDetails.ComplianceYearMonth,
    //             ResultCount: data.payload.SearchSummary.ResultCount,
    //           };
    //           payload = v;
    //         } else {
    //           const v: VehicleDetails = {
    //             BodyType: '',
    //             ManufactureYear: '',
    //             Make: '',
    //             Colour: '',
    //             EngineNumber: '',
    //             Model: '',
    //             VehicleType: '',
    //             ComplianceYearMonth: '',
    //             ResultCount: '',
    //             NoVehicleInfo: true
    //           }
    //           payload = v;
    //         }
    //       }
    //     } else {
    //       payload = data
    //     }
    //     return {
    //       status: true, message: '', payload,
    //     }
    //   })
    // );
  }

  sendAssetToSfFn = (sfData: SendAssetToSfData): Observable<PayloadApiResponse<SendAssetToSFResult[]>> => {
    return this.sendAssetToSf(sfData).pipe();
  }
  sendAssetToSf(sfData: SendAssetToSfData): Observable<PayloadApiResponse<SendAssetToSFResult[]>> {
    return this.httpClient.post<PayloadApiResponse<SendAssetToSFResult[]>>(URL_SEND_ASSET_TO_SF(), sfData
      , httpOptions()).pipe();
    // return this.httpClient.post<any>(URL_SEND_ASSET_TO_SF(), sfData
    // , httpOptions()).pipe(map(r => {
    //   return {
    //     status: true,
    //     message: '',
    //     payload: r
    //   }
    // }));
  }

  submitPpsrFn: AssetSettlementSubmitPpsrFn = (applicationId: number): Observable<ApiResponse> => {
    return this.submitPpsr(applicationId);
  }
  submitPpsr(applicationId: number): Observable<ApiResponse> {
    return this.httpClient.post<any>(URL_SUBMIT_PPSR(applicationId),{}, httpOptions()).pipe();
    // return this.httpClient.post<any>(URL_SUBMIT_PPSR(applicationId), data, httpOptions()).pipe(map(r => {
    //   console.log('****** submit ppsr endpoint response', r);
    //   return r;
    // }));
  }

  searchSupplierFn = (abn: string, accreditedOnly = false): Observable<AssetSupplier[]> => {
    return this.searchSupplier(abn, accreditedOnly).pipe(
      map(r => r.payload)
    );
  }
  // searchSupplier(name: string): Observable<PayloadApiResponse<AssetSupplier[]>> {
  //   return this.httpClient.post<PayloadApiResponse<AssetSupplier[]>>(URL_SEARCH_ASSET_SUPPLIER_BY_NAME(name), {}, httpOptions()).pipe();
    // return this.httpClient.post<AssetSupplier[]>(URL_SEARCH_ASSET_SUPPLIER_BY_NAME(name), {}, httpOptions()).pipe(
    //   map(r => {
    //     let res:any= r;
    //     return {
    //       status: true,
    //       message: '',
    //       payload: res.payload
    //     }
    //   })
    // );
  // }

  searchSupplier(abn: string, accreditedOnly = false): Observable<PayloadApiResponse<AssetSupplier[]>> {
    console.log('search abn string --------------------------', abn);
    return this.httpClient.post<PayloadApiResponse<AssetSupplier[]>>(URL_SEARCH_ASSET_SUPPLIER_BY_ABN_ACN(abn), {
      accreditedOnly
    }, httpOptions()).pipe();
  }

  getPpsrDocumentsFn = (applicationId: number): Observable<PpsrDocument[]> => {
    return this.getPpsrDocuments(applicationId).pipe(map(r => r.payload));
  }
  getPpsrDocuments(applicationId: number): Observable<PayloadApiResponse<PpsrDocument[]>> {
    return this.httpClient.get<PayloadApiResponse<PpsrDocument[]>>(URL_GET_PPSR_DOCS(applicationId), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, message: string ,payload: any[]}>(URL_GET_PPSR_DOCS(applicationId), httpOptions()).pipe(
    //   map(r => {
    //     const docs: PpsrDocument[] = (r.payload && r.payload.length && r.payload[0].Documents ? parseJSON(r.payload[0].Documents) : []) as PpsrDocument[];
    //     const ppsrDocs = docs.filter(d => d.isPPSRDoc);
    //     return {
    //       status: true, message: '',
    //       payload: ppsrDocs
    //     }
    //   })
    // );
  }

  getPpsrDetailsFn: AssetSettlementGetPpsrDetailsFn = (applicationId: number) => {
    return this.getPpsrDetails(applicationId).pipe(map(r => r.payload));
  }
  getPpsrDetails(applicationId: number): Observable<PayloadApiResponse<PpsrData | null>> {
    return this.httpClient.get<PayloadApiResponse<PpsrData | null>>(URL_GET_PPSR_DETAILS(applicationId), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, message: string ,payload: any[]}>(URL_GET_PPSR_DETAILS(applicationId), httpOptions()).pipe(
    //   map(r => {
    //     const payload: PpsrData | null = Array.isArray(r.payload) && r.payload.length ? r.payload[0] : null;
    //     if (payload) {
    //       payload.PPSRResponse = parseJSON(payload.PPSRResponse);
    //       payload.companySummary = parseJSON(payload.companySummary);
    //       payload.Grantors = parseJSON(payload.Grantors);
    //       payload.Grantors = (payload.Grantors ?? []).map((g: any) => {
    //         if (g.OrganisationNumberType && g.OrganisationNumberType == 'ABN') {
    //           return {
    //             type: 'ABN',
    //             ...g,
    //           }
    //         } else if (g.OrganisationNumberType && g.OrganisationNumberType == 'ACN') {
    //           return {
    //             type: 'ACN',
    //             ...g,
    //           }
    //         } else {
    //           return {
    //             type: 'Individual',
    //             ...g,
    //           }
    //         }
    //       });
    //     }
    //     return {
    //       status: true, message: '', payload
    //     }
    //   })
    // );
  }

  getApplicationAssetFn: AssetSettlementGetApplicationAssetFn = (applicationId: number) => {
    return this.getApplicationAsset(applicationId).pipe(map(r => r.payload));
  }
  getApplicationAsset(applicationId: number): Observable<PayloadApiResponse<PpsrAsset[]>> {
    return this.httpClient.get<PayloadApiResponse<PpsrAsset[]>>(URL_GET_APPLICATION_ASSET(applicationId), httpOptions()).pipe();
    // return this.httpClient.get<{status: boolean, message: string ,payload: any[]}>(URL_GET_APPLICATION_ASSET(applicationId), httpOptions()).pipe(
    //   map(r => {
    //     const payload = (r.payload && Array.isArray(r.payload) ? r.payload : []).map(_r => {
    //       _r.AssetSFRes = parseJSON(_r.AssetSFRes);
    //       _r.SettlementAssetDetails = parseJSON(_r.SettlementAssetDetails);
    //       return _r;
    //     });
    //     return {
    //       status: true, message: '',
    //       payload: payload ?? [],
    //     }
    //   })
    // );
  }
  updateApplicationAssetsFn = (data: PartialOrNull<PpsrAsset>): Observable<number> => {
    return this.updateApplicationAssets(data).pipe(map(r => r.payload));
  }
  updateApplicationAssets(data: PartialOrNull<PpsrAsset>): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_UPDATE_ASSET(), data, httpOptions()).pipe();
  }

  submitApplicationAssetsFn = (data: PartialOrNull<PpsrAsset>): Observable<number> => {
    return this.submitApplicationAssets(data).pipe(map(r => r.payload));
  }
  submitApplicationAssets(data: PartialOrNull<PpsrAsset>): Observable<PayloadApiResponse<number>> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_ADD_ASSET(),data, httpOptions());
    // return this.httpClient.post(URL_ADD_ASSET(),data, httpOptions())
    //   .pipe(
    //     map(r => {
    //       const data:any = r
    //       return {
    //         status: true,
    //         message: '',
    //         payload: data.payload
    //       }
    //     })
    //   );
  }

  searchGrantorsFn = (applicationId: number, data: AssetSearchBody) => {
    return this.searchGrantors(applicationId, data);
  }
  searchGrantors(applicationId: number, data: AssetSearchBody) : Observable<SearchGrantorsResult> {
    return this.httpClient.post(URL_SEARCH_GRANTORS(applicationId), data, httpOptions())
    .pipe(
      map(r => {
        const data:any = r
        if (!data.payload.Error) {
          return {
            status: true,
            message: '',
            payload: data.payload
          }
        }else{
          return {
            status: false,
            message: '',
            payload: data.payload
          }
        }

      })
    );
  }

  // savePPSRDocumentFn = (applicationId: number, data: UpdateDocumentsBody) => {
  //   return this.savePPSRDocument(applicationId, data);
  // }
  // savePPSRDocument(applicationId: number, data: UpdateDocumentsBody): Observable<ApiResponse> {
  //   return this.httpClient.post<ApiResponse>(URL_SAVE_DOCUMENTS(applicationId), {...data, isPPSRDoc: true}, httpOptions());
  //   // return this.httpClient.post(URL_SAVE_DOCUMENTS(applicationId), data, httpOptions())
  //   //   .pipe(
  //   //     map(r => {
  //   //       return {
  //   //         status: true,
  //   //         message: 'Documents saved successfully',
  //   //         payload: r
  //   //       }
  //   //     })
  //   //   );
  // }

  saveSupplierFn = (data: SaveSupplierData) => {
    return this.saveSupplier(data).pipe(
      map(r => r.payload)
    );
  }
  saveSupplier(data: SaveSupplierData): Observable<PayloadApiResponse<SaveSupplierResult>> {
    return this.httpClient.post<any>(URL_SAVE_SUPPLIER(), data, httpOptions()).pipe(map(r => {
      console.log("Save supplier api::", r.payload)
      return {
        status: true,
        message: 'Supplier submitted Successfully',
        payload: r.payload
      }
    }));
  }

  requestDoc(data: RequestDoc): Observable<PayloadApiResponse<any>> {
    return this.httpClient.post<any>( URL_REQUEST_DOC(),parseJSON(data), httpOptions()).pipe(
      map((r: PayloadApiResponse<any>) => {
        return {
          status: true,
          message: 'Successfully',
          payload: r.payload
        }
      })
    );
  }

  requestDocFn = (data: RequestDoc): Observable<PayloadApiResponse<any>> => {
    return this.requestDoc(data);
  }


  removeApplicationAssetFn: AssetSettlementRemoveApplicationAssetFn = (assetId: number) => {
    return this.removeApplicationAsset(assetId).pipe();
    // return this.removeApplicationAsset(assetId).pipe(map(r => r.payload));
  }
  removeApplicationAsset(assetId: number): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_REMOVE_ASSET(assetId), {}, httpOptions());
    // return this.httpClient.post(URL_REMOVE_ASSET(assetId), httpOptions())
    //   .pipe(
    //     map(r => {
    //       return {
    //         status: true,
    //         message: 'Asset Deleted Successfully',
    //         payload: r
    //       }
    //     })
    //   );
  }

}
