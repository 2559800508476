<div class="bpay-transaction-dialog">
  <mat-dialog-content>
    <h3 class="header">
      @if (!bpay?.id) {
        Add
        } @if (bpay?.id) {
        Edit
        } BPay Transaction
      </h3>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input [formControl]="formControlDate" matInput placeholder="Enter date">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Amount</mat-label>
            <input [formControl]="formControlAmount" matInput placeholder="Enter payment amount" type="number">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Biller Code</mat-label>
            <input [formControl]="formControlBillerCode" matInput placeholder="Enter biller code">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>CRN</mat-label>
            <input [formControl]="formControlCrn" matInput placeholder="Enter CRN">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Reference</mat-label>
            <input [formControl]="formControlReference" matInput placeholder="Enter reference">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
            <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
            <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
