import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {createAbnInputMask, setupUntilDestroy,formControlErrorKeys,formControlErrorMessage} from '@portal-workspace/grow-ui-library';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { Subscription } from 'rxjs';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AbnComponentValue, compareMatch} from '@portal-workspace/grow-shared-library';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'abn',
    templateUrl: './abn.component.html',
    styleUrls: ['./abn.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AbnComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AbnComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, DisableControlDirective]
})

export class AbnComponent extends AbstractControlValueAccessor<AbnComponentValue> implements OnInit, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formControl!: FormControl<AbnComponentValue>;
  formGroup!: FormGroup<{
    abn: FormControl<AbnComponentValue>
  }>;
  subscription?: Subscription;
  createAbnInputMask = createAbnInputMask();


  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = new FormControl(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      abn: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        console.log('****** abn comnponent', v);
        if (this.formGroup.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }


  doWriteValue(v: AbnComponentValue) {
    this.formControl.setValue(v);
  }


  mark(): void {
    this.formControl.markAllAsTouched();
  }
}
