import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PortalHotToastService} from '../../portal-hot-toast-component/hot-toast.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import { FlexModule } from '@angular/flex-layout/flex';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'error-notification2',
    templateUrl: './error-notification2.component.html',
    styleUrls: ['./error-notification2.component.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class ErrorNotification2 implements OnInit {

  subscriptions: Subscription[] = [];

  showError = false;
  errorTitle!: string;
  errorMessage!: string;
  errorSolution!: string;
  retryFn!: ()=>void;

  constructor(private p: PortalHotToastService) { }

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.p.publishNotificationSubject.pipe(
      tap(publishedNotification => {
        console.log('****** publishedNotification', publishedNotification);
        if (publishedNotification) {
          switch(publishedNotification.type) {
            case 'success': {
              this.showError = false;
              break;
            }
            case 'error': {
              this.errorTitle = publishedNotification.errorTitle;
              this.errorMessage = publishedNotification.errorMessage;
              this.errorSolution = publishedNotification.errorSolution;
              this.retryFn = publishedNotification.retryFn;
              this.showError = true;
              break;
            }
          }
        }
      })
    ).subscribe());
  }


  retryClicked(): void {
    this.showError = false;
    setTimeout(()=> {
      this.retryFn();
    }, 1000);
  }
}
