<div class="accreditation-page">
  <mat-stepper linear #stepper>
    <mat-step [stepControl]="formGroupStep1">
      <ng-container *ngTemplateOutlet="step1"></ng-container>
    </mat-step>
    <mat-step [stepControl]="formGroupStep2">
      <ng-container *ngTemplateOutlet="step2"></ng-container>
    </mat-step>
    @if (isBroker() || isVendor() || isDealer()) {
      <mat-step [stepControl]="formGroupStep3">
        <ng-container *ngTemplateOutlet="step3"></ng-container>
      </mat-step>
    }
    @if (!isDealer()) {
      <mat-step [stepControl]="formGroupStep4">
        <ng-container *ngTemplateOutlet="step4"></ng-container>
      </mat-step>
    }
    <mat-step [stepControl]="formGroupStep5">
      <ng-container *ngTemplateOutlet="step5"></ng-container>
    </mat-step>
    @if (isBroker()||isDealer()) {
      <mat-step [stepControl]="formGroupStep6Broker">
        <ng-container *ngTemplateOutlet="step6_broker"></ng-container>
      </mat-step>
    }
    @if (isVendor()) {
      <mat-step [stepControl]="formGroupStep6Vendor">
        <ng-container *ngTemplateOutlet="step6_vendor"></ng-container>
      </mat-step>
    }
    @if (isSupplier()) {
      <mat-step [stepControl]="formGroupStep6Supplier">
        <ng-container *ngTemplateOutlet="step6_supplier"></ng-container>
      </mat-step>
    }
    <mat-step [stepControl]="formGroupStep7">
      <ng-container *ngTemplateOutlet="step7"></ng-container>
    </mat-step>
  </mat-stepper>
</div>

<ng-template #step1>
  <div class="step1">
    <div class="mat-h3 text-align-center">Accreditation application</div>
    <div class="mat-body text-align-center separator-bottom">This form is for new brokers, vendors, dealers and
    suppliers that do not currently have a relationship with Dynamoney</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep1" >
      <div fxFlex="1 0 auto" class="form-field">
        <business-search [searchFn]="abnCompanySearchFn" [formControl]="formControlCompany" ></business-search>
      </div>
      <div fxFlex="1 0 auto" class="form-field">
        <product-selection [formControl]="formControlProductSelection" ></product-selection>
      </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-flat-button color="primary" [disabled]="formGroupStep1.invalid" (click)="onContinueToStep2($event)">
          Continue
          <span class="mdi mdi-arrow-right float-right"></span>
        </button>
      </div>
      <div class="form-field separator-bottom">
        <mat-divider></mat-divider>
      </div>
      <div fxFlex="1 0 auto" class="form-text">
        <div class="mat-h4-custom already-broker-title">Already a broker?</div>
      </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-stroked-button (click)="onSignUpClicked($event)">Sign Up</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #step2>
  <div class="step2">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep2" >

      <!-- section 1 -->
      <div class="mat-h4-custom text-align-center">Understanding your company</div>
      <div fxFlex="1 0 auto" class="form-field">
        <nature-of-business [formControl]="formControlNatureOfBusiness"  [mark]="step2MarkTriggerSubject"></nature-of-business>
      </div>
      @if (isBroker()) {
        <div class="cls-pdf" >
          <div class="cls-text">
            <span class="btn btn-default">Please download the file below, fill it and upload it back</span>
            <div fxFlex="1 0 auto" class="form-field p-2 form-div">
              <span class="mdi mdi-paperclip float-left"></span>
              <span class="pl-2">Introducer Referral Agreement</span>
              <a href="assets/Introducer Referral Agreement.pdf"
                target="_blank"
                download="Introducer Referral Agreement.pdf">
                <span class="mdi mdi-tray-arrow-down float-right"></span>
              </a>
            </div>
          </div>
          <div fxFlex="1 0 auto" class="form-button subheading">
            <upload-file [mandatory]="true" [mark]="step2MarkTriggerSubject" [formControl]="formControlreferralAgreement"  [maxFilesAllowed]="10"
              title="Introducer Referral Agreement" (events)="onUploadIntroducerReferralAgreement($event)" subMessage="Only PDF is allowed" [allowMultiple]="true">
            </upload-file>
          </div>
        </div>
      }
      <div fxFlex="1 0 auto" class="form-field">
        <entity-type title="Entity type" [formControl]="formControlEntityType"  [mark]="step2MarkTriggerSubject"></entity-type>
      </div>


      <!-- section 2 -->
      <div class="mat-h4-custom text-align-center">Company details and address</div>
      <div class="width-100">
        <abn [formControl]="formControlAbn" class="abn">
        </abn>
      </div>
      <div fxFlex="1 0 auto" class="form-field">
        <name title="Entity Name" [formControl]=formControlEntityName placeholder="Entity Name"></name>
      </div>
      <!-- <mat-form-field>
      <mat-label>Entity Name</mat-label>
      <input matInput type="text" [formControl]="formControlEntityName" placeholder="Entity Name">
    </mat-form-field> -->
    @if (showAcn) {
      <div class="width-100">
        <acn [formControl]="formControlAcn" class="acn">
        </acn>
      </div>
    }
    @if (showTrusteeInfo) {
      <div fxFlex="1 0 auto" class="form-field">
        <name title="Trustee company name" [formControl]=formControlTrustCompanyName></name>
      </div>
      <!-- <mat-form-field>
      <mat-label>Trustee company name</mat-label>
      <input matInput type="text" [formControl]="formControlTrustCompanyName">
    </mat-form-field> -->
    <mat-form-field>
      <mat-label>Trustee company ACN</mat-label>
      <input matInput [inputMask]="createAcnInputMask" type="text" [formControl]="formControlTrustCompanyAcn">
    </mat-form-field>
  }
  <div fxFlex="1 0 auto" class="form-field">
    <custom-address [formControl]="formControlBusinessAddress" [mark]="step2MarkTriggerSubject"></custom-address>
  </div>



  <!-- section 3 -->
  <div fxFlex="1 0 auto" class="form-field" fxLayout="column" fxLayoutAlign="center start">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="mat-h4-custom" fxFlex="0 0 400px">
        Have you ever held an accreditation with Dynamoney?
      </div>
      <div fxFlex="0 0 auto">
        <mat-slide-toggle color="primary" [formControl]="formControlHeldAccreditationBefore">
        </mat-slide-toggle>
      </div>
    </div>
    <mat-form-field [ngClass]="{hidden: !this.formControlHeldAccreditationBefore.value}">
      <mat-label>Dynamoney ID</mat-label>
      <input type="text" matInput placeholder="Dynamoney ID below or firm name if unknown"
        [formControl]="formControlHeldAccreditationName">
      </mat-form-field>
    </div>


    <!-- section 4 -->
    <div fxFlex="1 0 auto" class="form-field" fxLayout="column" fxLayout="center start">
      <div fxLayout="row" fxLayoutAlign="center start">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Are you part of a group or aggregator?
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlIsPartOfGroupOrAggregator">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="form-field" [ngClass]="{hidden: !showAggregatorSearch}">
        <aggregator-search  [formControl]="formControlGroupOrAggregator"
          [mandatory]="showAggregatorSearch" [searchFn]="aggregatorSearchFn">
        </aggregator-search>
      </div>
    </div>


    <!-- section 5 -->
    <div class="mat-h4-custom">Contact details</div>
    <div class="width-100">
      <mobile
        [mandatory]="true" title="Office number"
        [mark]="step2MarkTriggerSubject"
        [onlyMobile]="false"
        [formControl]="formControlOfficePhoneNumber">
      </mobile>
    </div>

    <div fxFlex="1 0 auto" class="form-button">
      <div fxLayout="row" fxLayoutGap="5px">
        <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep1($event)">Back</button>
        <button fxFlex="2 1 auto" mat-stroked-button (click)="onContinueToStep3($event)">
          Continue
          <span class="mdi mdi-arrow-right float-right"></span>
        </button>
      </div>
    </div>
  </form>
</div>
</ng-template>

<ng-template #step3>
  <div class="step3">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>
    <div class="mat-body subheading text-align-center">This refers to the applicant who is interested in becoming
    accredited with Dynamoney.</div>
    <message-box type="details" title="Important">
      The details you enter must match the detail shown on your Australian Drivers Licence.
    </message-box>

    <div class="mat-h4-custom subheading text-align-center">Upload Driving licence</div>
    <message-box type="info" title="Before uploading">
      <ul>
        <li>Ensure both the front and back of the licence are supplied</li>
        <li>Ensure the text is clear and readable</li>
        <li>If using PDF, ensure the front of the driver licence is on the first page</li>
        <li>We don’t accept pictures of digital driver licences</li>
      </ul>
    </message-box>

    <div class="subheading">
      <upload-file [maxFilesAllowed]="2" [mandatory]="true" [formControl]="formControlDriverLicence"
      (events)="onUploadDriverLicenceEvent($event)" [allowMultiple]="true"></upload-file>
    </div>

    @if (showApplicantFrom) {
      <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep3"
        >
        <div fxFlex="1 0 auto" class="form-field">
          <title-selection [formControl]="formControlTitle" [mark]="step3MarkTriggerSubject"></title-selection>
        </div>
        <!-- <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput [formControl]="formControlFirstName" placeholder="Enter name ...">
        <mat-error *ngFor="let errorKey of errorKeys(formControlFirstName)">
          <ng-container *ngIf="formControlFirstName.touched && formControlFirstName.hasError(errorKey)">
            {{errorMessage(formControlFirstName, errorKey)}}
          </ng-container>
        </mat-error>
      </mat-form-field> -->
      <div fxFlex="1 0 auto" class="form-field">
        <name title="First name" [formControl]=formControlFirstName [mark]="step3MarkTriggerSubject"></name>
      </div>
      <div fxFlex="1 0 auto" class="form-field">
        <name title="Last name" [formControl]=formControlLastName [mark]="step3MarkTriggerSubject"></name>
      </div>
      <!-- <mat-form-field>
      <mat-label>Last name</mat-label>
      <input matInput [formControl]="formControlLastName" placeholder="Enter name...">
      <mat-error *ngFor="let errorKey of errorKeys(formControlLastName)">
        <ng-container *ngIf="formControlLastName.touched && formControlLastName.hasError(errorKey)">
          {{errorMessage(formControlLastName, errorKey)}}
        </ng-container>
      </mat-error>
    </mat-form-field> -->
    <div fxFlex="1 0 auto" class="form-field">
      <datepicker  title="Date of birth" [allowOnly18YrsPlus]="true" [formControl]="formControlDob" [mark]="step3MarkTriggerSubject"></datepicker>
    </div>
    <div class="width-100">
      <mobile [formControl]="formControlMobileNumber" [onlyMobile]="true" [mark]="step3MarkTriggerSubject"></mobile>
    </div>
    <div class="width-100">
      <email [formControl]="formControlEmail" [mark]="step3MarkTriggerSubject"></email>
    </div>
    <div fxFlex="1 0 auto" class="form-field">
    <!--google-address  label="Residential address" [formControl]="formControlResidentialAddress"></google-address-->
    <custom-address title="Residential address" [formControl]="formControlResidentialAddress" [mark]="step3MarkTriggerSubject"></custom-address>
  </div>
  <mat-form-field>
    <mat-label>Driver's licence number</mat-label>
    <input matInput placeholder="Enter number..." [formControl]="formControlDriverLicenceNumber" maxlength="15">
    @for (errorKey of errorKeys(formControlDriverLicenceNumber); track errorKey) {
      <mat-error>
        @if (formControlDriverLicenceNumber.touched && formControlDriverLicenceNumber.hasError(errorKey)) {
          {{errorMessage(formControlDriverLicenceNumber, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
  <div fxFlex="1 0 auto" class="form-field">
    <state-selection [formControl]="formControlDriverLicenceIssuingState"></state-selection>
  </div>
  <div fxFlex="1 0 auto" class="form-field">
    <datepicker  title="Expiry date" [allowOnlyTodayOrFutureDate]="true" [formControl]="formControlDriverLicenceExpiryDate">
    </datepicker>
  </div>
  <div fxFlex="1 0 auto" class="form-button">
    <div fxLayout="row" fxLayoutGap="5px">
      <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep2($event)">Back</button>
      <button fxFlex="2 1 auto" mat-stroked-button
        (click)="onContinueToStep4($event)">
        Continue
        <span class="mdi mdi-arrow-right float-right"></span>
      </button>
    </div>
  </div>
</form>
}

</div>
</ng-template>

<ng-template #step4>
  <div class="step4">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>
    <div class="mat-h4-custom text-align-center">Credit licence details</div>

    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep4" >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Did you hold an Australian Credit Licence in your own right?
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlHoldAustralianCreditLicence">
          </mat-slide-toggle>
        </div>
      </div>
      <mat-form-field [ngClass]="{hidden: !showAustralianCreditLicenceNumber}">
        <mat-label>Australian Credit Licence number</mat-label>
        <input type="text" matInput [formControl]="formControlAustralianCreditLicenceNumber">
      </mat-form-field>


      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Are you a directory/employee of a licensed entity?
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlDirectorOrEmployeeOfLicencedEntity">
          </mat-slide-toggle>
        </div>
      </div>


      <div fxFlex="1 0 auto" class="form-button">
        <div fxLayout="row" fxLayoutGap="5px">
          <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep3($event)">Back</button>
          <button fxFlex="2 1 auto" mat-stroked-button [disabled]="formGroupStep4.invalid"
            (click)="onContinueToStep5($event)">
            Continue
            <span class="mdi mdi-arrow-right float-right"></span>
          </button>
        </div>
      </div>
    </form>

  </div>
</ng-template>

<ng-template #step5>
  <div class="step5">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>
    <div class="mat-h4-custom text-align-center">Acknowledgements</div>

    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep5" >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Have you ever been declared bankrupt or subject to a Part 9 or 10 debt arrangement?
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlHasBankruptcy">
          </mat-slide-toggle>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Have you ever been refused accreditation with any other lender
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlHasAccreditationRefusal">
          </mat-slide-toggle>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mat-h4-custom" fxFlex="0 0 400px">
          Have you ever been convicted of fraud or has any financial institution cancelled your accreditation?
        </div>
        <div fxFlex="0 0 auto">
          <mat-slide-toggle color="primary" [formControl]="formControlHasConvictedFraudOrAccreditationCancellation">
          </mat-slide-toggle>
        </div>
      </div>
      <mat-form-field [ngClass]="{hidden: !showConvictedFraudOrAccreditationCancellationDetails}">
        <mat-label>Details</mat-label>
        <input type="textarea" matInput [formControl]="formControlConvictedFraudOrAccreditationCancellationDetails">
      </mat-form-field>


      <div fxFlex="1 0 auto" class="form-button">
        <div fxLayout="row" fxLayoutGap="5px">
          <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep4($event)">Back</button>
          <button fxFlex="2 1 auto" mat-stroked-button [disabled]="formGroupStep5.invalid"
            (click)="onContinueToStep6($event)">
            Continue
            <span class="mdi mdi-arrow-right float-right"></span>
          </button>
        </div>
      </div>
    </form>

  </div>
</ng-template>

<ng-template #step6_broker>
  <div class="step6">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>

    <message-box type="details" title="Important">
      Failure to provide the required documents will result in the accreditation application being denied.
    </message-box>

    <div class="mat-h4-custom subheading">
      Documents
    </div>

    <message-box type="info" title="Attach the following documents">
      <ul>
        <li>{{isDealer()?'Motor Dealer Licence':'Industry membership'}}</li>
        <li>Public Indemnity Insurance</li>
        <li>Other supporting documents</li>
      </ul>
    </message-box>


    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep6Broker" >

      <div fxFlex="1 0 auto" class="form-button subheading">
        <upload-file [mandatory]="true"  [maxFilesAllowed]="10"
          [formControl]="formControlIndustryMembershipFileUpload"
          title="{{isDealer()?'Motor Dealer Licence':'Industry membership'}}" [allowMultiple]="true">
        </upload-file>
      </div>

      <div fxFlex="1 0 auto" class="form-button subheading">
        <upload-file [mandatory]="true"  [maxFilesAllowed]="10"
          [formControl]="formControlPublicIndemnityInsuranceFileUpload" title="Public Indemnity Insurance" [allowMultiple]="true">
        </upload-file>
      </div>

      <div fxFlex="1 0 auto" class="form-button subheading">
        <upload-files-multi-tags
          title="Other Supporting Documents"
          [allTags]="getAllDefaultTags()"
          (docEvent)="onStep6DocEvent($event)"
        ></upload-files-multi-tags>
      </div>

      <div fxFlex="1 0 auto" class="form-button">
        <div fxLayout="row" fxLayoutGap="5px">
          <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep5($event)">Back</button>
          @if (!(loader.submit.inProgress$ | async)) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="formGroupStep6Broker.invalid"
            (click)="onContinueToStep7($event)">Submit application</button>
          }
          @if (loader.submit.inProgress$ | async) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="true"
            (click)="onContinueToStep7($event)">Submitting ...</button>
          }
        </div>
      </div>
      <div fxFlex="1 0 auto" class="mat-caption form-field align-center">
        By clicking 'Submit application', I agree to the
        <a href="{{environment.submitApplicationTermsAndConditionsUrl}}" target="_blank" rel="noopener">Terms and Conditions</a>
      </div>

    </form>

  </div>
</ng-template>

<ng-template #step6_vendor>
  <div class="step6">
    <div class="mat-h3 text-align-center">Application reference #{{applicationId}}</div>

    <message-box type="details" title="Important">
      Failure to provide the required documents will result in the accreditation application being denied.
    </message-box>

    <div class="mat-h4-custom subheading">
      Documents
    </div>

    <message-box type="info" title="Attach the following documents">
      <ul>
        <li>Professional indemnity insurance</li>
        <li>Professional affiliation membership (CAFBA)</li>
        <li>Drivers licence</li>
        <li>Bank deposit slip</li>
      </ul>
    </message-box>

    <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="formGroupStep6Vendor" >
      <div fxFlex="1 0 auto" class="form-button subheading">
        <upload-files-multi-tags
          [allTags]="getAllDefaultTags()"
          (docEvent)="onStep6DocEvent($event)"
        ></upload-files-multi-tags>
      </div>

      <div fxFlex="1 0 auto" class="form-button">
        <div fxLayout="row" fxLayoutGap="5px">
          <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep5($event)">Back</button>
          @if (!(loader.submit.inProgress$ | async)) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="formGroupStep6Vendor.invalid"
            (click)="onContinueToStep7($event)">Submit application</button>
          }
          @if ((loader.submit.inProgress$ | async)) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="true"
            (click)="onContinueToStep7($event)">Submitting ...</button>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #step6_supplier>
  <div class="step6">
    <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="formGroupStep6Supplier" >
      <div fxFlex="1 0 auto" class="form-button subheading">
        <upload-files-multi-tags
          [allTags]="getAllDefaultTags()"
          (docEvent)="onStep6DocEvent($event)"
        ></upload-files-multi-tags>
      </div>

      <div fxFlex="1 0 auto" class="form-button">
        <div fxLayout="row" fxLayoutGap="5px">
          <button fxFlex="1 2 auto" mat-stroked-button (click)="onBackToStep5($event)">Back</button>
          @if (!(loader.submit.inProgress$ | async)) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="formGroupStep6Supplier.invalid"
            (click)="onContinueToStep7($event)">Submit application</button>
          }
          @if ((loader.submit.inProgress$ | async)) {
            <button fxFlex="2 1 auto" mat-flat-button [disabled]="true"
            (click)="onContinueToStep7($event)">Submitting ...</button>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #step7>
  <div class="step7">
    <div class="mat-h3 primary-color align-center"><span class="mdi mdi-check-circle"></span></div>
    <div class="mat-h3 align-center">Your application has been submitted</div>
    <div class="mat-body align-center subheading">
      Usually, the reviewing process takes from 2 to 14 business days, depending on information provided. We’ll notify
      you via Email once the process has been done.
    </div>
    <div class="mat-h4-custom align-center subheading">
      Meanwhile, you could read some insights about how Dynamoney helps brokers achieve their financial goals faster.
    </div>

    <mat-divider></mat-divider>

    <div class="mat-body align-center subheading primary-color">
      Still have questions? Reach out our <a href="{{environment.helpCenterUrl}}" target="_blank" rel="noopener">Help center</a> to
      learn more about the application and review process.
    </div>

    <mat-divider></mat-divider>

    <div class="mat-body align-center subheading">
      Follow us on social media to stay in touch with our latest updates
    </div>

    <div class="form-field align-center subheading" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
      <a href="{{environment.linkedinUrl}}" target="_blank" rel="noopener"><img src="/assets/images/linkedin.png"></a>
      <a href="{{environment.facebookUrl}}" target="_blank" rel="noopener"><img src="/assets/images/facebook.png"></a>
    </div>

  </div>
</ng-template>
