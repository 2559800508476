import { AsyncPipe, CommonModule, DecimalPipe, JsonPipe } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import {
  InterestRateCalculationLog,
  InterestRateCalculationLogDialogData,
  InterestRateCalculationLogDialogResult,
  isInternalUser
} from "@portal-workspace/grow-shared-library";
import { getUser } from "@portal-workspace/grow-ui-library";
import { PercentagePipe } from "../../pipes/percentage.pipe";
import { MessageBoxComponent } from "../message-box/message-box.component";


@Component({
  templateUrl: './interest-rate-calculation-log.dialog.html',
  styleUrls: ['./interest-rate-calculation-log.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    JsonPipe,
    AsyncPipe,
    DecimalPipe,
    MessageBoxComponent,
    PercentagePipe,
    CommonModule
]
})
export class InterestRateCalculationLogDialog implements OnInit {

  matDialogRef: MatDialogRef<InterestRateCalculationLogDialog, InterestRateCalculationLogDialogResult> =
    inject(MatDialogRef<InterestRateCalculationLogDialog, InterestRateCalculationLogDialogResult>);
  data: InterestRateCalculationLogDialogData = inject(MAT_DIALOG_DATA);

  logs: (InterestRateCalculationLog & {isSubLog?: boolean})[] = [];
  user = getUser();
  isInternalUser = isInternalUser(this.user);

  ngOnInit(): void {
    const logs: (InterestRateCalculationLog & {isSubLog?: boolean})[] = [];
    for (const log of this.data.logs) {
      logs.push(log);
      if (log.subLog?.length && this.isInternalUser) {
        for (const subLog of log.subLog) {
          logs.push({...subLog, isSubLog: true});
        }
      }
    }
    this.logs = logs;
  }

  hasSubLog(idx: number): boolean {
    const log = this.data.logs[idx];
    if (!log) return false;
    return !!log.subLog?.length;
  }

  displayedColumns = ['log', 'rate'];

  onClose() {
    this.matDialogRef.close({});
  }
}