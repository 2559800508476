<div class="lockeduser-page container-fluid">
  <div fxLayout="column" fxLayoutAlign="center center">
    <img src="/assets/images/faq-empty.png" />
    <div class="mat-h2 title">
      Account is locked. Reset your password to have it unlocked.
    </div>
    <button mat-flat-button color="primary" (click)="resetPassword($event)">
      Reset Password
    </button>
  </div>
</div>
