<div class="radio-options-component">
  @if (title) {
    <label>{{title}}</label>
  }
  <mat-radio-group color="primary"
    fxLayout="column"
    [formControl]="formControl">
    @for (radioOption of radioOptions ; track radioOption) {
      <mat-radio-button [ngClass]="{'single-option': (radioOptions && radioOptions.length <= 1)}" [value]="radioOption.type">{{radioOption.name}}</mat-radio-button>
    }
  </mat-radio-group>
</div>
