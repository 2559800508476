import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UploadFileDialogData, UploadFileDialogResult, UploadFileValue} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';
import { duplicateFileNameValidator, maxFileUploadValidator } from '../../validators/validators';




@Component({
    templateUrl: './upload-files.dialog.html',
    styleUrls: ['./upload-files.dialog.scss'],
    standalone: true,
    imports: [UploadFileComponent, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class UploadFilesDialog {

  formGroup: FormGroup<{
    upload: FormControl<UploadFileValue>
  }>;
  formControl: FormControl<UploadFileValue>;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: UploadFileDialogData,
              private formBuilder: FormBuilder,
              private matDialogRef: MatDialogRef<UploadFilesDialog, UploadFileDialogResult>) {
    this.formControl = this.formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(5)]);
    this.formGroup = this.formBuilder.group({
      upload: this.formControl,
    });
  }


  onCancel($event: MouseEvent) {
    this.matDialogRef.close({
      valid: false, files: this.formControl.value ?? [],
    })
  }

  onSubmit($event: MouseEvent) {
    this.matDialogRef.close({
      valid: true, files: this.formControl.value ?? [],
    });
  }
}
