import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AlertDialogData, ConfirmationDialogResult} from '@portal-workspace/grow-shared-library';
import {FormBuilder} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    templateUrl: './confirmation.dialog.html',
    styleUrls: ['./confirmation.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class ConfirmationDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
              private dialogRef: MatDialogRef<ConfirmationDialog, ConfirmationDialogResult>,
              private formBuilder: FormBuilder) {

  }

  onSubmit(readyForSubmission: boolean) {
    const result: ConfirmationDialogResult = { readyForSubmission: readyForSubmission };
    this.dialogRef.close(result);
  }

}
