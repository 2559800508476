<div class="status-box-component">
  @if (type === 'draft') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-close"></span>
      <span class="label">Draft</span>
    </div>
  }

  @if (type === 'under-review') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-alert"></span>
      <span class="label">Under Review</span>
    </div>
  }

  @if (type === 'waiting-for-bank-statments') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-clock"></span>
      <span class="label">Waiting for Bank Statements</span>
    </div>
  }

  @if (type === 'submitted-to-credit') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-alert"></span>
      <span class="label">Submitted to Credit</span>
    </div>
  }

  @if (type === 'more-info') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-question"></span>
      <span class="label">More Info</span>
    </div>
  }

  @if (type === 'not-verified') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-close"></span>
      <span class="label">Not Verified</span>
    </div>
  }

  @if (type === 'paid') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-check"></span>
      <span class="label-paid">Paid</span>
    </div>
  }

  @if (type === 'credit-approved') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-check"></span>
      <span class="label">Credit Approved </span>
    </div>
  }

  @if (type === 'payment-pending') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-check"></span>
      <span class="label-payment">Pending Payment</span>
    </div>
  }

  @if (type === 'success') {
    <div class="row title {{type}} ">
      <span class="mdi mdi-progress-check"></span>
      <span class="label-success">Success</span>
    </div>
  }
</div>