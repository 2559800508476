import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { ApplicationTilesComponent } from './application-tiles.component';
import { ApplicationTypeIconModule } from '../application-type-icon/application-type-icon.module';
import { ApplicationTypeAmountModule } from '../application-type-amount/application-type-amount.module';


@NgModule({
    imports: [
        CommonModule,
        AngularMaterialLibraryModule,
        ApplicationTypeIconModule,
        ApplicationTypeAmountModule,
        ApplicationTilesComponent
    ],
    exports: [
        ApplicationTilesComponent,
    ]
})
export class ApplicationTilesModule {

}
