import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminPrivacyConsent, PrivacyConsent} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, BreakpointService, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import { MessageBox2Component } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Subscription, switchMap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './privacy-consent.page.html',
    styleUrls: ['./privacy-consent.page.scss'],
    standalone: true,
    imports: [
    NgClass,
    MessageBoxComponent,
    MessageBox2Component,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PdfViewerModule,
    MatButtonModule,
    FlexModule,
    ExtendedModule
]
})
export class PrivacyConsentPage implements OnInit {
  environment = environment;
  privacyConsent: PrivacyConsent | AdminPrivacyConsent | null = null;
  subscriptions: Subscription[] = [];
  privacyConsentBuffer!: Uint8Array;
  formControlAgree!: FormControl<boolean | null>;
  showLargeViewer = false;
  showMediumViewer = false;
  showSmallViewer = false;
  showXsmallViewer = false;
  zoom = 0.9;
  ip: string = '';
  type: 'application' | 'admin' = 'application';

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private formBuilder: FormBuilder,
    private breakpointService: BreakpointService,
  ) {
    this.formControlAgree = this.formBuilder.control(false, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.privacyConsent = (this.route.snapshot.data as any).privacyConsent;
    this.type = this.privacyConsent?.type ?? 'application';
    console.log(this.privacyConsent, this.type)


    this.adjustmentBreakpoints();

    this.subscriptions.push(
      this.applicationService.getIpAddress().subscribe(r => {
        console.log('===IP: ', r);
        if (r.status) {
          this.ip = r.payload;
        }
      })
    )

    if (this.privacyConsent && this.privacyConsent.status === 'created') {
      this.subscriptions.push(
        this.applicationService.getIpAddress().pipe(
          switchMap(r => {
            console.log('===IP: ', r);
            if (r.status) {
              this.ip = r.payload;
            }
            if (this.type === 'admin') {
              return this.applicationService.updateAdminPrivacyConsentStatusFn((this.privacyConsent as PrivacyConsent).id, 'opened', this.ip)
            } else {
              return this.applicationService.updatePrivacyConsentStatusFn((this.privacyConsent as PrivacyConsent).id, 'opened', this.ip)
            }
          })
        ).subscribe()
      )
    }

    if (this.privacyConsent) {
      this.subscriptions.push(
        this.applicationService.getPrivacyConsentDocFn().pipe(
          this.toastService.spinnerObservable()
        )
        .subscribe(
          base64 => {
            this.base64ToArrayBuffer(base64);
          }
        )
      )
    }
  }

  adjustmentBreakpoints() {
    this.subscriptions.push(
      this.breakpointService.breakpointsSubject.subscribe(breakpoints => {
        console.log(breakpoints);
        if (breakpoints.includes('Large') || breakpoints.includes('XLarge')) {
          this.showLargeViewer = true;
          this.showMediumViewer = false;
          this.showSmallViewer = false;
          this.showXsmallViewer = false;
          this.zoom = 0.9;
        } else if (breakpoints.includes('Medium')) {
          this.showLargeViewer = false;
          this.showMediumViewer = true;
          this.showSmallViewer = false;
          this.showXsmallViewer = false;
          this.zoom = 0.9;
        } else if (breakpoints.includes('Small')) {
          this.showLargeViewer = false;
          this.showMediumViewer = false;
          this.showSmallViewer = true;
          this.showXsmallViewer = false;
          this.zoom = 1.2;
        } else if (breakpoints.includes('XSmall')) {
          this.showLargeViewer = false;
          this.showMediumViewer = false;
          this.showSmallViewer = false;
          this.showXsmallViewer = true;
          this.zoom = 1.8;
        } else {
          this.showLargeViewer = false;
          this.showMediumViewer = false;
          this.showSmallViewer = false;
          this.showXsmallViewer = false;
          this.zoom = 0.9;
        }
      })
    );
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.privacyConsentBuffer = (bytes.buffer as Uint8Array);
  }

  reload() {
    if (this.privacyConsent) {
      if (this.type === 'admin') {
        this.subscriptions.push(
          this.applicationService.getAdminPrivacyConsentFn(this.privacyConsent.id).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(privacyConsent => {
            this.privacyConsent = privacyConsent;
          })
        )
      } else {
        this.subscriptions.push(
          this.applicationService.getPrivacyConsentById(this.privacyConsent.id).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(privacyConsent => {
            this.privacyConsent = privacyConsent;
          })
        )
      }
    }
  }

  onAccept() {
    if (this.privacyConsent) {
      if (this.type === 'admin') {
        this.subscriptions.push(
          this.applicationService.updateAdminPrivacyConsentStatusFn(this.privacyConsent.id, 'accepted', this.ip).pipe(
            this.toastService.spinnerObservable(),
            this.toastService.retryableMessage({
              successMessage: 'You have successfully accepted the privacy consent',
              errorMessage: 'There are some issues with our services. Please contact Dynamoney on 1300 001 420 ',
              retryFn: () => {
                console.log('**** retry ', this);
                this.applicationService.updateAdminPrivacyConsentStatusFn(this.privacyConsent?.id ?? 0, 'accepted', this.ip);
              }
            }),
          ).subscribe(() => {
            this.reload();
          })
        )
      } else {
        this.subscriptions.push(
          this.applicationService.updatePrivacyConsentStatusFn(this.privacyConsent.id, 'accepted', this.ip).pipe(
            this.toastService.spinnerObservable(),
            this.toastService.retryableMessage({
              successMessage: 'You have successfully accepted the privacy consent',
              errorMessage: 'There are some issues with our services. Please contact Dynamoney on 1300 001 420 ',
              retryFn: () => {
                console.log('**** retry ', this);
                this.applicationService.updatePrivacyConsentStatusFn(this.privacyConsent?.id ?? 0, 'accepted', this.ip);
              }
            }),
          ).subscribe(() => {
            this.reload();
          })
        )
      }
    }
  }

  onDownload() {
    const url = this.environment.api2Host + "/api2/download-privacy-consent";
    const res = window.open(url);
    if (!res || res.closed || typeof res.closed === 'undefined') {
      this.dialogService.openAlertDialog({
        message: 'Warn',
        subMessage: 'Please disable Popup blocker and try again',
      });
      return;
    }
  }

}
