<div class="notification-page container-fluid">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
        <mat-tab label="UNREAD"><ng-content *ngTemplateOutlet="unreadNotificationsTemplate"></ng-content></mat-tab>
        <mat-tab label="ALL NOTIFICATIONS"><ng-content *ngTemplateOutlet="allNotificationsTemplate"></ng-content></mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #allNotificationsTemplate>

  @if (loader.tableLoading.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  <div [ngClass]="{hidden: loader.tableLoading.inProgress$ | async}">
    <notification-list [notifications]="allNotifications"
    (events)="onNotificationEvents($event)"></notification-list>
  </div>
</ng-template>

<ng-template #unreadNotificationsTemplate>

  @if (loader.tableLoading.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  <div [ngClass]="{hidden: loader.tableLoading.inProgress$ | async}">
    <notification-list [notifications]="unreadNotifications"  (events)="onNotificationEvents($event)"></notification-list>
  </div>
</ng-template>
