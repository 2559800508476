import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTableComponent } from './user-table.component';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { CustomPaginatorModule } from '../custom-paginator-component/custom-paginator.module';
 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomContentLoaderModule } from '../custom-content-loader-component/custom-content-loader.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomContentLoaderModule,
        CustomPaginatorModule,
        PipeLibraryModule,
        AngularMaterialLibraryModule,
        UserTableComponent,
    ],
    exports: [
        UserTableComponent
    ]
})
export class UserTableModule {

}
