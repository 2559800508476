<div class="total-payment-breakup-component">
  <div class="mat-h2">Break-up of total payment</div>
  <div class="mat-body separator">
    This estimate is not an offer or approval of finance. The actual repayment amount will be confirmed if an application is submitted, approved and contract offered to you. All applications are subject to credit assessment and eligibility criteria. Terms, conditions, fees and charges apply.
    The below schedule excludes account servicing fee, late payment fee and any other applicable fees
  </div>
  <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
    @if (showPaymentChart) {
      <mat-tab label="PAYMENT DETAILS">
        <ng-template matTabContent>
          <div class="wrapper separator-top">
            <payment-chart
              [data]="paymentChartData"
              [calculationLog]="calculationLog"
              [hideBalloonPayment]="paymentChartHideBalloonPayment"
              [hideBrokerOrigiantionFee]="paymentChartHideBrokerOriginationFee"
              [hideBrokerage]="paymentChartHideBrokerage">
            </payment-chart>
          </div>
        </ng-template>
      </mat-tab>
    }
    @if (showArmotizationChart) {
      <mat-tab label="AMORTISATION CHART">
        <ng-template matTabContent>
          <div class="wrapper separator-top">
            <amortization-chart (events)="onDateChange($event)" [data]="amortizationChartData"></amortization-chart>
          </div>
        </ng-template>
      </mat-tab>
    }
    @if (showRepaymentEstimation) {
      <mat-tab label="REPAYMENT ESTIMATION">
        <ng-template matTabContent>
          <div class="wrapper separator-top">
            <repayment-estimation [data]="repaymentEstimationData"></repayment-estimation>
          </div>
        </ng-template>
      </mat-tab>
    }
    @if (showArmotisationSchedule) {
      <mat-tab label="AMORTISATION SCHEDULE">
        <ng-template matTabContent>
          <div class="wrapper separator-top">
            <amortisation-schedule [data]="amortizationScheduleData"></amortisation-schedule>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
