import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {AccreditationService} from './accreditation.service';
import {Accreditation, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';


@Injectable()
export class AccreditationResolver  {

  constructor(private accreditationService: AccreditationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Accreditation | null> | Promise<Accreditation | null> | Accreditation | null {
    const accreditationId = route.params.accreditationId;
    if (accreditationId) {
      return this.accreditationService.getAccreditationById(accreditationId).pipe(
        map(r => {
          console.log('************ RESOLVE ', accreditationId, r.payload);
          return r.payload;
        }),
        catchError((err) => {
          console.log(`error resolving accreditation with id ${accreditationId}`, err);
          return of(null);
        })
      );
    }
    return null;
  }

}
