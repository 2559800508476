import {Component, inject, OnInit} from "@angular/core";
import {
  MessageBoxComponent,
  OriginatorListComponent, PortalHotToastService, setupUntilDestroy,
  UrlComponent,
  WhitelabelStylesComponent, WhitelabelTitleComponent
} from "@portal-workspace/grow-ui-library";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {
  UrlValue,
  WhitelabelEntityDetails,
  WhitelabelStyles,
  WhitelabelStylesValue, WhitelabelTitleValue
} from "@portal-workspace/grow-shared-library";
import {MatButtonModule} from "@angular/material/button";
import {ALL_STYLES, WhitelabelService} from "../../service/whitelabel.service";
import {navigationUrlForWhitelabelSettings} from "../../service/navigation-urls";
import {Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './edit-whitelabel-settings-details.page.html',
  styleUrls: ['./edit-whitelabel-settings-details.page.scss'],
  standalone: true,
  imports: [
    MessageBoxComponent,
    AsyncPipe,
    MatCardModule,
    FlexLayoutModule,
    JsonPipe,
    ReactiveFormsModule,
    FormsModule,
    OriginatorListComponent,
    UrlComponent,
    WhitelabelStylesComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    WhitelabelTitleComponent,
  ],
})
export class EditWhitelabelSettingsDetailsPage implements OnInit {


  subscriptions: Subscription[] = [];

  allPredefinedStyles = ALL_STYLES;

  portalHotToastService = inject(PortalHotToastService);
  router = inject(Router);
  whitelabelService = inject(WhitelabelService);
  activatedRoute = inject(ActivatedRoute);
  whitelabel?: WhitelabelEntityDetails;


  checkIfWhitelabelTitleExistsFn = this.whitelabelService.checkIfWhitelabelTitleExistsFn;

  formBuilder = inject(FormBuilder);
  formControlTitle: FormControl<WhitelabelTitleValue> = this.formBuilder.control(null, [Validators.required]);
  formControlLogoUrl: FormControl<UrlValue> = this.formBuilder.control(null, [Validators.required]);
  formControlStyles: FormControl<WhitelabelStylesValue> = this.formBuilder.control(null, [Validators.required]);
  formControlSelectedPredefinedStyle: FormControl<{name: string, style: WhitelabelStyles} | null> = this.formBuilder.control(null);

  formGroup = this.formBuilder.group({
    title: this.formControlTitle,
    logoUrl: this.formControlLogoUrl,
    styles: this.formControlStyles,
  });

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.whitelabel = (this.activatedRoute.snapshot.data as any).whitelabel;

    this.formControlTitle.setValue(this.whitelabel?.title ?? null);
    this.formControlLogoUrl.setValue(this.whitelabel?.logoLink ?? null);
    this.formControlStyles.setValue(this.whitelabel?.styles ?? null);
  }

  async onClickBack() {
    await this.router.navigate(navigationUrlForWhitelabelSettings());
  }

  onApply() {
    const styles = this.formControlStyles.value;
    const logoLink = this.formControlLogoUrl.value!;
    if (styles) {
      this.whitelabelService.triggerApplyWhitelabelEvent({
        logoLink,
        styles,
      });
    }
  }

  onReset() {
    this.whitelabelService.loadFromLocalStorageAndTriggerWhitelabelEvent();
  }

  onSave() {
    // NOTE: if we are here all those values should be valid else
    //       save button should be disabled
    this.subscriptions.push(this.whitelabelService.saveWhitelabel(
      this.whitelabel!.id,
      {
        title: this.formControlTitle.value!,
        logoLink: this.formControlLogoUrl.value!,
        styles: this.formControlStyles.value!,
      }
    ).pipe(
      this.portalHotToastService.snackBarObservable(`Whitelabel saved`),
    ).subscribe());
  }

  onPopulatePredefinedStyle() {
   const selectedPredefinedStyle = this.formControlSelectedPredefinedStyle.value;
   if (selectedPredefinedStyle) {
     this.formControlStyles.setValue(selectedPredefinedStyle.style);
   }
  }

}
