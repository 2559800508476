
import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, FinanceTypeValue, FinanceTypeValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';



export const ASSET_FINANCE_OPTIONS_FILTER = (opt: Exclude<FinanceTypeValue, null>): boolean => {
  return (opt && opt.type === 'chattel-mortgage'|| opt.type === 'rental' );
}

export const CONSUMER_ASSET_FINANCE_OPTIONS_FILTER = (opt: Exclude<FinanceTypeValue, null>): boolean => {
  return (opt && (opt.type === 'chattel-mortgage' || opt.type === 'rental'));
}

export type OptionsFilter = (option: Exclude<FinanceTypeValue, null>) => boolean;

@UntilDestroy()
@Component({
    selector: 'finance-type-selection',
    templateUrl: './finance-type-selection.component.html',
    styleUrls: ['./finance-type-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FinanceTypeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => FinanceTypeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class FinanceTypeSelectionComponent extends AbstractControlValueAccessor<FinanceTypeValue> implements OnInit, AfterContentChecked, Mark {
  // @Input({required: false}) financeTypeFilter: boolean = false;
  // @Input({required: false}) applicationType:string='BusinessLoans'

  @Input({required: false}) optionsFilter: OptionsFilter | null = null;

  compareWithFn: (option: FinanceTypeValue, selected: FinanceTypeValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formGroup: FormGroup<{
    selection: FormControl<FinanceTypeValue>
  }>
  formControl: FormControl<FinanceTypeValue>;
  subscription: Subscription;

  options = FinanceTypeValueOptions;


  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: FinanceTypeValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.options = this.optionsFilter ? FinanceTypeValueOptions.filter(this.optionsFilter) : FinanceTypeValueOptions;
    // if (this.financeTypeFilter === true){
    //   this.options = options.filter(item => item.type==='chattel-mortgage' || item.type==='rental');
    // }
    // else {
    //   this.options=options
    // }
  }

  doWriteValue(v: FinanceTypeValue): FinanceTypeValue | undefined {
    if (v) {
      const type = FinanceTypeValueOptions.find(opt => opt.type === v.type);
      if (type) {
        this.formControl.setValue(type);
        return type;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

}
