import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ApplicationStepperComponent} from './application-stepper.component';
import {ApplicationHeaderSegmentComponent} from './application-header-segment.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {ApplicationStepper2Component} from "./application-stepper2.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        ApplicationStepperComponent,
        ApplicationStepper2Component,
        ApplicationHeaderSegmentComponent,
    ],
    exports: [
        ApplicationStepperComponent,
        ApplicationStepper2Component,
        ApplicationHeaderSegmentComponent,
    ]
})
export class ApplicationStepperModule {

}
