<div class="corporate-loans-contract-component">
  <mat-card appearance="outlined">
@if (application.ApplicationType==='CorporateLoans') {
  <mat-card>
        <h3 class="header">Contract Details</h3>
        <corporate-loans-quote
          [application]="application"
          [data]="data">
        </corporate-loans-quote>
      </mat-card>
    }
    <div class="break"></div>
    <mat-card>
      <asset-notes
        [isAssetUpdated]="isAssetUpdated"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
      ></asset-notes>
    </mat-card>
  </mat-card>
</div>
