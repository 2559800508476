import {Component} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';


@Component({
    templateUrl: './progress-spinner.dialog.html',
    styleUrls: ['./progress-spinner.dialog.scss'],
    standalone: true,
    imports: [MatProgressSpinnerModule, MatDialogModule]
})
export class ProgressSpinnerDialog {

}
