<div class="total-payment-breakup-component" fxLayout="column" fxLayoutGap="20px">
  <mat-dialog-content class= 'max-height-100-vh'>
    <div fxLayout="row" fxLayoutAlign="end center">
      <span class="mdi mdi-close cursor-pointer"(click)="onClose($event)"></span>
    </div>
    <div class="dialog-wrapper">
      <div>
        @if (data) {
          <total-payment-breakup
            (events)="onDateChange($event)"
            [amortizationChartData]="data.amortizationChartData"
            [paymentChartData]="data.paymentChartData"
            [repaymentEstimationData]="data.repaymentEstimationData"
            [amortizationScheduleData]="data.amortizationScheduleData"
            [calculationLog]="data.calculationLog"
            [paymentChartHideBrokerOriginationFee]="opt.paymentChartHideBrokerOriginationFee"
            [paymentChartHideBalloonPayment]="opt.paymentChartHideBalloonPayment"
            [paymentChartHideBrokerage]="opt.paymentChartHideBrokerage"
            [showPaymentChart]="opt.showPaymentChart"
            [showArmotizationChart]="opt.showArmotizationChart"
            [showRepaymentEstimation]="opt.showRepaymentEstimation"
            [showArmotisationSchedule]="opt.showArmotisationSchedule">
          </total-payment-breakup>
        }
      </div>
      <div fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="space-between center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start"
        class="accept-button">
        @if (!isQuote) {
          <button mat-stroked-button color="primary"
            (click)="onClose($event)">
            Cancel
          </button>
        }
        <button mat-flat-button
          class="w-lt-md-100"
          color="primary"
          (click)="onAcceptQuotation($event)">
          {{isQuote ? 'Close' : 'Accept Quotation'}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
