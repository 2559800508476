<div class="url-component">
  <mat-form-field>
    <mat-label>{{title}}</mat-label>
    <input
      matInput
      [inputMask]="createUrlInputMask"
      [disableControl]="disabled"
      [formControl]="formControl">
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>

  @if (preview) {
    <fieldset>
      <legend>Preview</legend>
      <div>
        @if (previewError) {
          <message-box type="warn" title="Error">
            <span>Error Loading preview for {{title}}</span>
          </message-box>
        } @else {
              <img src="{{formControl.value}}"/>
        }
        <img [ngStyle]="{display: 'none', visibility: 'invisible'}"
             src="{{formControl.valueChanges | async}}"
             (load)="onPreviewLoad($event)"
             (error)="onPreviewError($event)"
        />
      </div>
    </fieldset>
  }
</div>
