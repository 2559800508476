import {Component, Input, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule, 
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApplicationDialogService, getUser,
  PortalHotToastService,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {
  AddBankingTransactionFn, BankingPaymentType,
  BPayPayment, BPayTransactionDialogResult,
  ConfirmationDialogResult, DeleteTransactionFn, DirectPayment, EditTransactionFn, GeneratePaymentFileFn, GetTransactionsFn, User,
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {loadingFor} from '@ngneat/loadoff';
import { Sort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';

export class BPayDataSource extends DataSource<BPayPayment> {

  subject = new BehaviorSubject<BPayPayment[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<BPayPayment[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(payments: BPayPayment[]) {
    this.subject.next(payments);
  }
}
@UntilDestroy()
@Component({
  selector: 'bpay-table',
  templateUrl: './bpay-table.component.html',
  styleUrls: ['./bpay-table.component.scss'],
  standalone: true,
  imports: [FlexModule, FormsModule, ReactiveFormsModule, DatePipe, LooseCurrencyPipe, MatSortModule, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatMenuModule, MatDividerModule, NgClass, ExtendedModule, MatTableModule, AsyncPipe]
})

export class BPayTableComponent implements OnInit {
  displayColumnsBPay = ['date', 'amount', 'billerCode', 'crn', 'reference', 'status', 'action'];
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  subscriptions: Subscription[] = [];
  formControlSearchBPay!: FormControl<string | null>;
  loader = loadingFor('bpayTable');
  bpayDataSource = new BPayDataSource();
  user: User | null = getUser();
  payments: BPayPayment[] = [];
    //= [{
  //   id: 1,
  //   amount: 2000,
  //   billerCode: "295386",
  //   crn: "118737345360418",
  //   reference: "1386",
  //   date: "27022023",
  // }];
  @Input({required: true}) addBankingTransactionFn!: AddBankingTransactionFn;
  @Input({required: true}) editTransactionFn!: EditTransactionFn;
  @Input({required: true}) getTransactionsFn!: GetTransactionsFn;
  @Input({required: true}) deleteTransactionFn!: DeleteTransactionFn;
  @Input({required: true}) generatePaymentFileFn!: GeneratePaymentFileFn;
  @Input({required: false}) batchId!: number | null;

  constructor(
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {
    this.formControlSearchBPay = formBuilder.control(null);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
  }

  reload() {
    if (this.batchId) {
      this.subscriptions.push(
        this.getTransactionsFn(this.batchId).subscribe((r: BPayPayment[] | DirectPayment[]) => {
          this.payments = r as BPayPayment[];
          this.bpayDataSource.update(this.payments)
        })
      )
    }
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = {prop: $event.active, dir: $event.direction};
    } else {
      this.sorts = null;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'id': return 'ID';
      case 'date': return 'Date';
      case 'type': return 'Type';
      case 'amount': return 'Amount';
      case 'billerCode': return 'Biller Code';
      case 'crn': return 'CRN';
      case 'reference': return 'Reference';
      case 'bsb': return 'BSB';
      case 'accountNumber': return 'Account Number';
      case 'accountTitle': return 'Account Title';
      case 'status': return 'Status';
      case 'action': return '';
      default: return column;
    }
  }

  editBPay(bpay: BPayPayment | undefined = undefined) {
    this.subscriptions.push(
      this.applicationDialogService.openBPayTransactionDialog({
        bpay
      }).afterClosed().subscribe((r: BPayTransactionDialogResult | undefined) => {
        if (r && r?.readyForSubmission) {
          // edit
          if (r?.bpay?.id) {
            const index = this.payments.findIndex(bpay => bpay.id === r.bpay.id);
            if (index > -1) {
              // TO DO: Edit transactions
              this.subscriptions.push(
                this.editTransactionFn(r.bpay).subscribe(() => {
                  this.reload();
                })
              )
              //
              // // TO DO: reload
              // this.payments[index] = r.bpay;
            }
          } else { // add new
            // TO DO: Add new transaction
            this.subscriptions.push(
              this.addBankingTransactionFn({
                ...r.bpay,
                userId: this.user?.UserId ?? 0,
                batchId: this.batchId ?? 0,
                type: BankingPaymentType.BPAY,
                bsb: '',
                accountNumber: '',
                accountTitle: '',
                trackingId: '',
              }).subscribe((id: number) => {
                this.reload();
              })
            )

            // TO DO: reload
            // const lastId = this.payments[this.payments.length - 1].id;
            // this.payments.push({
            //   ...r.bpay,
            //   id: lastId + 1,
            // })
          }
          //this.reload();
        }
      })
    )
  }

  makePayment() {
    this.applicationDialogService.openConfirmationDialog({
      message: "Please confirm",
      subMessage: "Incorrect payment details can lead to big issues"
    }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        // connect to backend endpoint
        if (this.batchId) {
          this.subscriptions.push(
            this.generatePaymentFileFn(this.batchId).subscribe((fileName: string) => {
              this.applicationDialogService.successDialog({
                message: 'Success',
                subMessage: `Payment file ${fileName} generated and sent to iLink.`
              }).afterClosed().subscribe()
            })
          )
        } else {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: "Batch Id not found"
          })
        }
      }
    })
  }

  delete(element: BPayPayment) {
    this.subscriptions.push(
      this.deleteTransactionFn(element).subscribe(() => {
        this.reload();
      })
    )
  }
}
