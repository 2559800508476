import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, YesNoSelectionValue } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'yes-no-selection',
    templateUrl: './yes-no-selection.component.html',
    styleUrls: ['./yes-no-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YesNoSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => YesNoSelectionComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class YesNoSelectionComponent extends AbstractControlValueAccessor<YesNoSelectionValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: false}) title = 'Deposit';

  formControl: FormControl<boolean | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: YesNoSelectionValue | null): void | YesNoSelectionValue {
    this.formControl.setValue(v);
    return undefined;
  }
  mark() {
    this.formControl.markAllAsTouched();
  }

}
