<div class="originator-type-component">
  <mat-form-field>
    <mat-label>Type</mat-label>
    <mat-select [formControl]="formControl" [disableControl]="disabled">
      @for (option of options; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
