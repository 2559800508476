<div class="disbursement-amount-dialog">
  <mat-dialog-content>
    <h3 class="header">Credit Alert</h3>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input [formControl]="formControlName" matInput placeholder="Enter credit flow name" type="text">
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [formControl]="formControlStatus" placeholder="Select Status">
            @for (option of creditAlertOptions; track option) {
              <mat-option [value]="option">{{option}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input [formControl]="formControlCode" matInput placeholder="Enter Code" type="text">

        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput [formControl]="formControlDescription" placeholder="Enter description">
          </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formControlName.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
