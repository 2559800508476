import {DestroyRef, inject, Injectable} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {environment} from "../../environments/environment";

@Injectable()
export class UpdateService {

      // updates: SwUpdate = inject(SwUpdate);
      // destroyDef: DestroyRef = inject(DestroyRef);

      // constructor() { }

      // // NOTE: angular service do not have init lifecycle, we trigger this from APP_INITIALIZER
      // //       in main.ts
      // init() {
      //   // if (environment.enableServiceWorker && this.updates.isEnabled) {
      //   if (environment.enableServiceWorker && this.updates && this.updates.isEnabled) {
      //     console.log(`Update Service (Service Worker) is Enabled`);
      //     interval(5 * 60 * 1000).pipe(
      //       takeUntilDestroyed(this.destroyDef),
      //     ).subscribe((i) => {
      //       if (i === 0) {
      //         console.log(`Update Service (Service Worker) initialized`);
      //       }
      //       this.triggerCheck();
      //     });
      //   } else {
      //     console.log(`Update Service (Service Worker) is Disabled`);
      //   }
      // }

      // triggerCheck() {
      //   if(environment.enableServiceWorker && this.updates && this.updates.isEnabled) {
      //     this.updates.checkForUpdate() .then(() => {
      //       // NOTE: comment this out so it doesn't spam the console logs
      //       console.log('checking for updates every 5 min')
      //     }).catch((err: any) => {
      //       console.error(`Error checking for sw update`, err);
      //     });
      //   } else {
      //     console.log(`Unable to trigger service worker update service worker is not enabled`);
      //   }
      // }

      // public checkForUpdates(): void {
      //   this.updates.unrecoverable.pipe(
      //     takeUntilDestroyed(this.destroyDef),
      //   ).subscribe((event) => {
      //     switch(event.type) {
      //       case 'UNRECOVERABLE_STATE': {
      //         console.log(`service worker unrecoverable state`, event);
      //         document.location.reload();
      //         break;
      //       }
      //     }
      //   });
      //   this.updates.versionUpdates.pipe(
      //     takeUntilDestroyed(this.destroyDef),
      //   ).subscribe((event) => {
      //     if (event) {
      //       switch(event.type) {
      //         case "NO_NEW_VERSION_DETECTED": {
      //           break;
      //         }
      //         case "VERSION_DETECTED": {
      //           console.log(`ngsw update service `, event);
      //           break;
      //         }
      //         case "VERSION_INSTALLATION_FAILED": {
      //           console.log(`ngsw update service `, event);
      //           break;
      //         }
      //         case "VERSION_READY": {
      //           console.log(`ngsw update service `, event);
      //           this.promptUser();
      //           break;
      //         }
      //       }
      //     }
      //   });
      // }

      // private promptUser(): void {
      //   console.log('updating to new version');
      //   // this.updates.activateUpdate().then(() => document.location.reload());
      //   this.updates.activateUpdate();
      // }

}
