<div class="minimal-layout-header container-minimal-layout-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    @if (showPrev) {
      <div class="prev" fxLayoutAlign="start center">
        <div class="icon-hover" fxLayout="column" fxLayoutAlign="center center">
          <span class="mdi mdi-arrow-left cursor-pointer" (click)="onPrevClicked($event)"></span>
        </div>
      </div>
    }
    <div class="logo-wrapper" fxLayout="column" fxLayoutAlign="start center">
      <img class="logo" [src]="logo">
    </div>
  </div>
  <div fxLayout="row">
    @if (showContact) {
      <div class="contact" fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center" class="phonenumber icon-hover">
          <span class="mdi mdi-phone-outline"></span><span class="mat-h4-custom mb-0">1300 001 420</span>
        </div>
        @if (showAccreditationInContact) {
          <div class="accreditation">
            <button mat-flat-button color="primary" (click)="onAccreditationClicked($event)">Accreditation Application</button>
          </div>
        }
        <div class="help icon-hover cursor-pointer" fxLayout="column" fxLayoutAlign="center center" (click)="onHelpClicked($event)"><span class="mdi mdi-help-circle-outline"></span></div>
      </div>
      } @if (showCross) {
      <div class="cross" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <div class="icon-hover" [matTooltip]="crossTooltip" fxLayout="column" fxLayoutAlign="center center">
          <span class="mdi mdi-close cursor-pointer" (click)="onCrossClicked($event)"></span>
        </div>
      </div>
    }
  </div>
</div>
