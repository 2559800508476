import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
    ApiResponse,
    EnableDisable2FA,
    MatchPassword,
    Verify2faTokenPayload,
    PayloadApiResponse,
    SendEmailResponse,
    UpdateIpToken,
    Add2faSecretKeyFn,
    EnableDisable2faFn,
    MatchConfirmPasswordFn,
    Get2faTotpVerificationCodeUriFn, Verify2faTokenFn,
} from '@portal-workspace/grow-shared-library';
import {map, tap} from 'rxjs/operators';
import { httpOptions} from '@portal-workspace/grow-ui-library';
import { Observable } from 'rxjs';

const URL_ADD_2FA_SECRET_KEY = () => `${environment.api2Host}/api2/2fa/add-2fa-secret-key-to-user`;
const URL_MATCH_CONFIRM_PASSWORD = () => `${environment.api2Host}/api2/match-confirm-password`;
const URL_GET_2FA_TOTP_VERIFICATION_CODE_URI = () => `${environment.api2Host}/api2/2fa/get-2fa-totp-verification-code-uri`;
const URL_DISABLE_ENABLE_2FA = (flag : number) => `${environment.api2Host}/api2/2fa/enable-disable-2fa/${flag}`;
const URL_VERIFY_RESET_2FA_SMS_CODE = ()=>`${environment.api2Host}/api2/2fa/verify-reset-2fa-sms-code`; // post
const URL_SEND_RESET_2FA_TOKEN_EMAIL = () => `${environment.api2Host}/api2/2fa/send-reset-2fa-token-email`;  // post
const URL_VERIFY_2FA_TOKEN = (digit:number) => `${environment.api2Host}/api2/2fa/verify-2fa-token/${digit}`;  // post
// const URL_REGISTER_2FA_TOKEN = (userId:number) => `${environment.api2Host}/api2/2fa/register-2fa-token/${userId}`; //post
// const URL_GET_REGISTERED_2FA_TOKEN = (userId:number) => `${environment.api2Host}/api2/2fa/get-registered-2fa-token/${userId}`; //post

@Injectable()
export class TwoFactorAuthService {

  // events = new BehaviorSubject<MatchPassword>;

  constructor(private httpClient: HttpClient ) {}

  // send reset 2fa email
  sendResetCode2faTokenEmail(opt: {
    UserId: number |undefined,
    fname: string,
    mobile: string |undefined,
    email: string,
  }): Observable<PayloadApiResponse<SendEmailResponse>> {
    return this.httpClient.post<PayloadApiResponse<SendEmailResponse>>(URL_SEND_RESET_2FA_TOKEN_EMAIL(), opt, httpOptions()).pipe();
  }

  get2faTotpVerificationCodeUri(): Observable<PayloadApiResponse<string | null>> {
    return this.httpClient.get<PayloadApiResponse<string | null>>(URL_GET_2FA_TOTP_VERIFICATION_CODE_URI(), httpOptions()).pipe();
  }
  get2faTotpVerificationCodeUriFn: Get2faTotpVerificationCodeUriFn = () => {
      return this.get2faTotpVerificationCodeUri().pipe(map(r => r.payload ?? ''));
  }

  verify2faToken(digit:number, remember: boolean = false): Observable<PayloadApiResponse<Verify2faTokenPayload>> {
    return this.httpClient.get<PayloadApiResponse<Verify2faTokenPayload>>(URL_VERIFY_2FA_TOKEN(digit), httpOptions()).pipe(
      tap(r => {})
    );
  }
  verify2faTokenFn: Verify2faTokenFn = (digit: number) => {
      return this.verify2faToken(digit).pipe(map(r => r.payload));
  }

  // NOTE: not used anymore WEB-3306
  // getRegistered2faToken(userId: number): Observable<PayloadApiResponse<UpdateIpToken>> {
  //   return this.httpClient.get<PayloadApiResponse<UpdateIpToken>>(URL_GET_REGISTERED_2FA_TOKEN(userId), httpOptions()).pipe(
  //     tap(r => {
  //       return r.payload;
  //     })
  //   );
  // }


  // NOTE: not used anymore WEB-3306
  // register2faToken(rememberDeviceChecked: 0 | 1, userId: number): Observable<void> {
  //   return this.httpClient.post<void>(URL_REGISTER_2FA_TOKEN(userId),{
  //     rememberDeviceChecked: rememberDeviceChecked
  //   }).pipe();
  // }

  verifyReset2faSmsCode(code: string, userId: number, phoneNumber: string): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_VERIFY_RESET_2FA_SMS_CODE(), {
      code: code,
      user_id: userId,
      phone_number: phoneNumber,
    }, httpOptions()).pipe(
      // tap(r => {
      //   return {
      //     status: r.status,
      //     message: r.message,
      //   }
      // })
    )
  }

  add2faSecretKeyFn: Add2faSecretKeyFn = (email: string, password: string) => {
    return this.add2faSecretKey(email, password).pipe(map(r=>r.payload));
  }
  add2faSecretKey(email: string|undefined, password: string): Observable<PayloadApiResponse<MatchPassword>> {
    return this.httpClient.post<PayloadApiResponse<MatchPassword>>(URL_ADD_2FA_SECRET_KEY(), {
      Email: email, Password: password
    }).pipe(
      tap(r => {
        if (r.payload) {
          const payload = {
            user: r.payload.user,
           message: r.message
          }
        }
      })
    );
  }

  matchConfirmPasswordFn: MatchConfirmPasswordFn = (email: string, password: string): Observable<MatchPassword> => {
    return this.matchConfirmPassword(email, password).pipe(map(p => p.payload));
  };
  matchConfirmPassword(email: string|undefined, password: string): Observable<PayloadApiResponse<MatchPassword>> {
    return this.httpClient.post<PayloadApiResponse<MatchPassword>>(URL_MATCH_CONFIRM_PASSWORD(), {
      Email: email, Password: password
    },httpOptions()).pipe(
      tap(r => {
        if (r.payload) {
          const payload = {
            user: r.payload.user,
           message: r.message
          }
        }
      })
    );
  }

  enableDisable2faFn: EnableDisable2faFn = (email: string, flag:0| 1 ) => {
    return this.enableDisable2FA(email, flag).pipe(map(r => r.payload));
  }
  enableDisable2FA(email : string | undefined , flag: number): Observable<PayloadApiResponse<EnableDisable2FA>>{
    return this.httpClient.put<PayloadApiResponse<EnableDisable2FA>>(URL_DISABLE_ENABLE_2FA(flag),{
      Email:  email
    }, httpOptions()).pipe();
  }
}
