<div class="top-menu-component"
    fxLayout="column"
    fxLayoutAlign="top center">
  <mat-toolbar color="primary">
    <img class="logo" [src]="logo">

    <!-- separator / spacer -->
    <span fxFlex="grow"></span>


    <span class="version mat-caption">{{gitTagOrVersion}}</span>
    <ng-container>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="top-menu-component-menu">
        <button class="menu-items" mat-menu-item (click)="onToolbarItemClick('logout')">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="mt-1"><mat-icon>logout</mat-icon></div>
            <div>Logout</div>
          </div>
        </button>
      </mat-menu>
    </ng-container>
  </mat-toolbar>
  <mat-progress-bar [ngClass]="{visible: isProgressBarVisible}" mode="indeterminate"></mat-progress-bar>
</div>
