import {Component, Input, OnInit} from '@angular/core';
import {InsurancePremiumApplication} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'authorised-signatories-details',
    templateUrl: './authorised-signatories-details.component.html',
    styleUrls: ['./authorised-signatories-details.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule, EllipsisPipe]
})
export class AuthorisedSignatoriesDetailsComponent implements OnInit {

  @Input({required: true}) application!: InsurancePremiumApplication;

  authorisedSignatories: InsurancePremiumApplication['AuthorisedSignatory'] = []

  ngOnInit(): void {
    this.authorisedSignatories = this.application.AuthorisedSignatory ?? [];
  }

}
