<div class="yes-no-component" fxLayout="column" [formGroup]="formGroup">
  <mat-label class="mat-caption">{{title}}</mat-label>
  <!--mat-button-toggle-group appearance="legacy" [formControl]="formControl"-->
  <div [ngClass]="{'mat-form-field-invalid':formGroup.invalid && formGroup.touched} ">
    <mat-button-toggle-group
      [hideMultipleSelectionIndicator]="true"
      [hideSingleSelectionIndicator]="true"
      [formControl]="formControl"
      [disableControl]="disabled">
      <mat-button-toggle [value]="true">{{yesText}}</mat-button-toggle>
      <mat-button-toggle [value]="false">{{noText}}</mat-button-toggle>
    </mat-button-toggle-group>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </div>
</div>
