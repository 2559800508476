import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PpsrStatusChipComponent} from './ppsr-status-chip.component';


const modules = [
  CommonModule,
]

@NgModule({
    imports: [
        ...modules,
        PpsrStatusChipComponent
    ],
    exports: [
        PpsrStatusChipComponent,
        ...modules,
    ]
})
export class PpsrStatusModule {

}
