import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {TopMenuService} from './top-menu.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {
  getUser,
  hasAccessBrokerDashboard, hasAccessOverdraftDashboard,
  hasMultipleRole,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatToolbarModule } from '@angular/material/toolbar';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'direct-application-topmenu',
    templateUrl: './direct-application-top-menu.component.html',
    styleUrls: ['./direct-application-top-menu.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, FlexModule, MatButtonModule, MatMenuModule, MatIconModule, MatProgressBarModule, NgClass, ExtendedModule]
})
export class DirectApplicationTopMenuComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: false}) gitTagOrVersion: string = '';

  isProgressBarVisible: boolean = false;

  formControlSearch: FormControl<string | null>;

  hasMultipleRole: boolean = false;
  hasAccessTradeDashboard = false;
  hasAccessOverdraftDashboard = false;
  hasAccessBrokerDashboard = false;

  constructor(private formBuilder: FormBuilder,
              private topMenuService: TopMenuService) {
    this.formControlSearch = this.formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const user = getUser()
    if (user) {
      this.hasMultipleRole = hasMultipleRole(user)
      this.hasAccessBrokerDashboard = hasAccessBrokerDashboard(user);
      this.hasAccessOverdraftDashboard = hasAccessOverdraftDashboard(user);
    }
    this.subscriptions.push(this.topMenuService.subject.pipe(
      tap(r => {
        const v = !!r;
        this.isProgressBarVisible = v;
      })
    ).subscribe());
  }
}
