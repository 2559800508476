import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import {FlexModule} from "@angular/flex-layout";
import {MatSliderModule} from "@angular/material/slider";
import {
  FormBuilder,
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {AsyncPipe, CurrencyPipe, JsonPipe, NgClass} from "@angular/common";
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {compareMatch, RangeSliderValue} from "@portal-workspace/grow-shared-library";
import {Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import _ from 'lodash';
import numeral from 'numeral';
import {setupUntilDestroy} from "../component-utils";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: "range-slider",
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>RangeSliderComponent), multi:true},
    {provide: MARK, useExisting: forwardRef(() => RangeSliderComponent)},
  ],
  imports: [
    FlexModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
    CurrencyPipe,
    NgClass,
  ]
})
export class RangeSliderComponent extends AbstractControlValueAccessor<RangeSliderValue> implements OnInit, OnChanges, AfterViewInit, Mark {

  _originalMin = 5000;
  _originalMax = 500000;

  @Input({required: false}) showPermanentTick = false;
  @Input({required: false}) title: string | null = null;
  @Input({required: false}) min: number = 5000;
  @Input({required: false}) max: number = 500000;
  @Input({required: false}) step: number = 1;
  @Input({required: false, transform: numberAttribute}) numOfDecimals: number = 2;
  @Input({required: false}) autoAdjustMax: boolean = false; // only on set value

  formBuilder: FormBuilder = inject(FormBuilder);
  formControlStart!: FormControl<number | null>;
  formControlEnd!: FormControl<number | null>;
  formGroup!: FormGroup<{
    start: FormControl<number | null>,
    end: FormControl<number | null>,
  }>;

  formatLabel = (value: number | null): string => {
    if (value != null) {
      if (value >= 1000) {
        return numeral(_.round(value / 1000, this.numOfDecimals)).format('#.'+'#'.repeat(this.numOfDecimals)) + 'k';
      }
      return numeral(value).format('#');
    }
    return '';
  }

  subscriptions: Subscription[] = [];

  ngOnInit() {
    setupUntilDestroy(this);
    this._originalMin = this.min;
    this._originalMax = this.max;
    this.formControlStart = this.formBuilder.control(null, [Validators.required]);
    this.formControlEnd = this.formBuilder.control(null, [Validators.required]);
    this.formGroup= this.formBuilder.group({
      start: this.formControlStart,
      end: this.formControlEnd,
    });
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        this._propagateChange();
      })
    ).subscribe());
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['max'] && this.formControlEnd) {
        this._propagateChange();
    }
    if (changes && changes['min'] && this.formControlEnd) {
      this._propagateChange();
    }
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

  private _propagateChange() {
    if (this.formGroup.valid) {
      this.propagateChange({
        start: this.formControlStart.value!,
        end: this.formControlEnd.value!,
      });
    } else {
      this.propagateChange(null);
    }
  }

  override doWriteValue(v?: RangeSliderValue | null): void | RangeSliderValue {
    if (v != null || v != undefined) {
      if (this.autoAdjustMax) {
        const diff = v.end - v.start;
        const ratio = (diff / (this._originalMax - this._originalMin)) * 100;
        if (ratio < 20) {
          this.max = v.end + (diff/2);
          this.min = v.start - (diff/2);
        } else {
          this.max = this._originalMax;
          this.min = this._originalMin;
        }
        setTimeout(()=>{
          this.formControlStart.setValue(v.start);
          this.formControlEnd.setValue(v.end);
          setTimeout(()=>{
            this.formControlStart.setValue(v.start);
            this.formControlEnd.setValue(v.end);
          } );
        } );
      } else {
        this.formControlStart.setValue(v.start);
        this.formControlEnd.setValue(v.end);
      }
      // console.log('******** slider adjust', this.min, v.start, v.end, this.max);
    } else {
      this.formControlStart.setValue(this.min);
      this.formControlEnd.setValue(this.max);
    }
    return undefined;
  }
}
