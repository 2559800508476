import {Component} from '@angular/core';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
@Component({
    templateUrl: './manual-payment.page.html',
    styleUrls: ['./manual-payment.page.scss'],
    standalone: true,
    imports: [MatButtonModule]
})
export class ManualPaymentPage {

  isLoading=false;
  constructor(private portalHotToastService: PortalHotToastService) {}

  onRunManualPayment(){
    this.isLoading = true;
  }
}
