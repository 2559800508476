<div class="business-loan-rate-card-component">
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Property Owner</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlPropertyOwner" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlPropertyOwner); track errorKey) {
          <mat-error>
            @if (formControlPropertyOwner.touched && formControlPropertyOwner.hasError(errorKey)) {
              {{errorMessage(formControlPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Non property Owner</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlNoPropertyOwner" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNoPropertyOwner); track errorKey) {
          <mat-error>
            @if (formControlNoPropertyOwner.touched && formControlNoPropertyOwner.hasError(errorKey)) {
              {{errorMessage(formControlNoPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Property Owner (500-550) </mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlDirectorScoreRate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlDirectorScoreRate); track errorKey) {
          <mat-error>
            @if (formControlDirectorScoreRate.touched && formControlDirectorScoreRate.hasError(errorKey)) {
              {{errorMessage(formControlDirectorScoreRate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Non Property Owner (500-550) </mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlNonPropertyOwnerBetween500And600Rate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNonPropertyOwnerBetween500And600Rate); track errorKey) {
          <mat-error>
            @if (formControlNonPropertyOwnerBetween500And600Rate.touched && formControlNonPropertyOwnerBetween500And600Rate.hasError(errorKey)) {
              {{errorMessage(formControlNonPropertyOwnerBetween500And600Rate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan amount" [formControl]="formControlLoanAmount"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan terms" [formControl]="formControlLoanTerms"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Brokerage" [formControl]="formControlBrokerage"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="mat-h3 separator-top">Risk Profile</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Adverse on file</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlAdverseOnFile" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAdverseOnFile); track errorKey) {
          <mat-error>
            @if (formControlAdverseOnFile.touched && formControlAdverseOnFile.hasError(errorKey)) {
              {{errorMessage(formControlAdverseOnFile, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Doc fee" [type]="'currency'" [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="ABN Age (months)" [formControl]="formControlGstAge"></min-max-rate>
    </div>
  </div>


</div>
