import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccessLevel,
  EmailComponentValue,
  MobileValue,
  NameComponentValue,
  User,
  UserandPriviledges
} from '@portal-workspace/grow-shared-library';
import { createEmailInputMask, createMobilePhoneNumberInputMask } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {
  AggregatorAndOriginatorListValue,
  AggregatorAndOriginatorListValueGetFn,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { map, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { navigationUrlForPendingApprovals } from '../../service/navigation-urls';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { loadingFor} from '@ngneat/loadoff';
// import {AccessLevelValue} from '../../component/access-level-component/access-level.component';
import {AccessLevelValue} from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { AccessLevelComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AggregatorAndOriginatorListComponent } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { MatCardModule } from '@angular/material/card';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './pending-approval-user.page.html',
    styleUrls: ['./pending-approval-user.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, MatCardModule, NameComponent, FormsModule, ReactiveFormsModule, AggregatorAndOriginatorListComponent, MatFormFieldModule, MatInputModule, InputMaskModule, MatDividerModule, AccessLevelComponent, FlexModule, MatButtonModule]
})
export class PendingApprovalUserPage implements OnInit, AfterViewInit {

  subscriptions: Subscription[] = [];

  loader = loadingFor('saving');
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }
  createMobilePhoneNumberInputMask = createMobilePhoneNumberInputMask();

  createEmailInputMask = createEmailInputMask();

  user?: UserandPriviledges;
  getFn: AggregatorAndOriginatorListValueGetFn;

  formGroup1: FormGroup<{
    firstName:FormControl<NameComponentValue>;
    lastName:FormControl<NameComponentValue>;
    aggregatorOrOriginator:FormControl<AggregatorAndOriginatorListValue>;
    email:FormControl<EmailComponentValue>;
    mobileNumber: FormControl<MobileValue>
    accessLevel:FormControl<AccessLevelValue>;
  }>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlAggregatorOrOriginator: FormControl<AggregatorAndOriginatorListValue>;
  formControlMobileNumber: FormControl<MobileValue>;
  formControlEmail: FormControl<EmailComponentValue>;
  formControlAccessLevel: FormControl<AccessLevelValue>;

  constructor(private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private toastService: PortalHotToastService,
    private router: Router,
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,) {
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlAggregatorOrOriginator = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required]);
    this.formControlAccessLevel = formBuilder.control(null, [Validators.required]);
    this.formGroup1 = this.formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      aggregatorOrOriginator: this.formControlAggregatorOrOriginator,
      email: this.formControlEmail,
      mobileNumber: this.formControlMobileNumber,
      accessLevel: this.formControlAccessLevel,
    });

    this.getFn = this.adminService.getAggregatorsAndOriginatorsFn;
  }

  reload() {
    if (this.user) {
      this.authService.getUser(this.user.UserId,false).pipe(
        tap(r => {
          if (r && r.payload) {
            this.user = r.payload;
          }
        })
      ).subscribe();
    }
  }


  ngAfterViewInit() {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = (this.activatedRoute.snapshot.data as any).user;
    console.log('****** user resolver', this.user);
    if (this.user) {
      const givenName = this.user.GivenName ?? (this.user.Name ? this.user.Name.split(' ')?.[0] : '');
      const familyName = this.user.FamilyName ?? (this.user.Name ? this.user.Name.split(' ')?.[1] : '');
      this.formControlFirstName.setValue(givenName);
      this.formControlLastName.setValue(familyName);
      const v: AggregatorAndOriginatorListValue =  this.user.OriginatorBusinessId ? {
        kind: this.user.priviledges.includes('aggregator') ? 'Aggregator' : 'Originator',
        OriginatorBusinessId: this.user.OriginatorBusinessId,
        EntityName: '',
        SalesforceId: '',
      } : null;
      this.formControlAggregatorOrOriginator.setValue(v);
      this.formControlMobileNumber.setValue(this.user.MobileNumber ? this.user.MobileNumber: '' );
      this.formControlEmail.setValue(this.user.Email);
      this.formControlAccessLevel.setValue(this.user.AccessLevel ? this.user.AccessLevel: null );
    }

    if(this.user && (this.user.approval || this.user.isDisabled)){
      this.formGroup1.disable();
      this.formControlMobileNumber.disable();
    }
  }

  approveUser(user?: User) {
    if (user && this.formGroup1.valid) {
      const agOrComp: AggregatorAndOriginatorListValue = this.formControlAggregatorOrOriginator.value;
      const givenName = this.formControlFirstName.value;
      const familyName = this.formControlLastName.value;
      const email = this.formControlEmail.value!;
      const mobileNumber = this.formControlMobileNumber.value;
      const accessLevel: AccessLevelValue = this.formControlAccessLevel.value;
      const data = {
        originatorBusinessId: agOrComp!.OriginatorBusinessId,
        givenName: givenName!,
        familyName: familyName!,
        email,
        mobileNumber: mobileNumber!,
        accessLevel: accessLevel!,
      };
      const sub = this.authService.approveOrRejectUser(user.UserId, true, data).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.retryableMessage({
          successMessage: 'User Approved',
          errorMessage: (err) => {
            const msg = (err as any)?.error?.message;
            return msg ? msg : 'Failed to approve the User';
          },
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.approveUser(user);
          }
        }),
        tap(async r => {
          // this.reload();
          await this.router.navigate(navigationUrlForPendingApprovals());
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  rejectUser(user?: User) {
    if (user) {
      this.authService.approveOrRejectUser(user.UserId, false).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.retryableMessage({
          successMessage: 'User Rejected',
          errorMessage: 'Failed to reject the User',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.approveUser(user);
          }
        }),
        tap(async r => {
          // this.reload();
          await this.router.navigate(navigationUrlForPendingApprovals())
        })
      ).subscribe();
    }
  }

  onSave(user?: User) {
    if (user) {
      const data: {
        GivenName: string,
        FamilyName: string,
        AccessLevel: AccessLevel,
        Email: string,
        OriginatorBusinessId: null | number,
        MobileNumber: string,
      } = {
        GivenName: this.formControlFirstName.value as string,
        FamilyName: this.formControlLastName.value as string,
        AccessLevel: this.formControlAccessLevel.value!,
        Email: this.formControlEmail.value as string,
        OriginatorBusinessId: null,
        MobileNumber: this.formControlMobileNumber.value as string,
      };
      const o: AggregatorAndOriginatorListValue = this.formControlAggregatorOrOriginator.value;
      // if (o && o.kind === 'Aggregator') {
      //   data.AggregatorId = o.AggregatorId;
      // } else if (o && o.kind === 'Originator') {
      //   data.OriginatorBusinessId = o.OriginatorBusinessId;
      // }
      if (o) {
        data.OriginatorBusinessId = o.OriginatorBusinessId;
      }
      const sub = this.authService.updateUser(user.UserId, data).pipe(
        this.loader.saving.track(),
        this.toastService.retryableMessage({
          successMessage: 'User details saved',
          errorMessage: 'Failed to save the User details',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onSave(user);
          }
        }),
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  approvalChangetoggle(approvedChecked: boolean) {
    if(approvedChecked) { // approve
      this.approveUser(this.user);
    } else { // reject
      this.rejectUser(this.user);
    }
  }

  async onClickBack() {
    await this.router.navigate(navigationUrlForPendingApprovals())
  }
}
