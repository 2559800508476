import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { BasiqCompanyDetailsBody, BasiqUserCustomerMapping } from '../basiq.domain';
import { ApiResponse, PayloadApiResponse } from '../types.domain';
import { BankStatementData } from '../bank.domain';

export type GetBasiqCustomerMappingFn = (reference: string) => Observable<PayloadApiResponse<BasiqUserCustomerMapping | null>>;
export type GetBasiqStatementDataForCompanyFn = (params: BasiqUserCustomerMapping) => Observable<PayloadApiResponse<BankStatementData | null>>;
export type GetBasiqAuthLinkFn = (id: string) => Observable<PayloadApiResponse<string | null>>;
export type RefreshBasiqConnectionsFn = (userId: string, type: 'business' | 'consumer') => Observable<ApiResponse>;

export interface BasiqCategoryDetailsTableData {
    date: string;
    description: string;
    credit: number;
    debit: number;
    id: string;
}