import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {inject, Injectable} from '@angular/core';
import {StorageService} from '../service/storage.service';
import {navigationUrlForLogin} from '../service/navigation-urls';

export const validJwtGuard = (): CanActivateFn => {
   return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
       const storageService = inject(StorageService);
       const router = inject(Router);
       const accessToken = storageService.getAccessToken();
       if (accessToken) {
           return true;
       }
       console.log("---invalid session token---");
       return router.navigate(navigationUrlForLogin());
   }
}

// NOTE: Deprecated since Angular16
// @Injectable()
// export class ValidJwtGuard  {
//
//   constructor(private storageService: StorageService,
//               private router: Router,
//               private hotToastService: PortalHotToastService) { }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     const accessToken = this.storageService.getAccessToken();
//     if (accessToken) {
//       return true;
//     }
//     console.log("---invalid session token---");
//     // this.hotToastService.warn(`Invalid session token`, 'Session expired',  {duration: undefined});
//     return this.router.navigateByUrl(navigationUrlForLogin(this.router));
//   }
//
// }
