import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FlexModule} from "@angular/flex-layout/flex";
import {
  createPostcodeInputMask,
  formControlErrorKeys,
  formControlErrorMessage
} from "@portal-workspace/grow-ui-library";
import {DisableControlDirective} from "../../directives/disable-control.directive";
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import { PostcodeValue } from "@portal-workspace/grow-shared-library";
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import {Subscription} from "rxjs";
import {delay, tap} from "rxjs/operators";
import {InputMaskModule} from "@ngneat/input-mask";
import numeral from 'numeral';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'postcode',
  templateUrl: './postcode.component.html',
  styleUrls: ['./postcode.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>PostcodeComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>PostcodeComponent)},
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    DisableControlDirective,
    InputMaskModule,
  ],
})
export class PostcodeComponent extends AbstractControlValueAccessor<PostcodeValue> implements OnInit {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  createPostcodeInputMask = createPostcodeInputMask();

  @Input({required: false}) title: string = 'Postcode';
  @Input({required: false}) required: boolean = false;
  @Input({required: false}) hint: string = '';


  subscriptions: Subscription[]= [];

  formBuilder = inject(FormBuilder);
  formControl!: FormControl<PostcodeValue>;

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control<PostcodeValue>(null, this.required ? [Validators.required] : []);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      tap(v => {
        if (this.formControl.valid || this.formControl.disabled) {
          const val = numeral(this.formControl.value).value();
          this.propagateChange(val);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  override doWriteValue(v?: PostcodeValue | null | undefined): void | PostcodeValue {
    this.formControl.setValue(v ?? null);
  }
}


