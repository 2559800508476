<div class="asset-tier4-component">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">{{title}}</div>
    </div>
  </div>
  <div class="separator-bottom row">
    <div class="col-12">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>New</mat-label>
          <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlNewAssetBacked" required  >
          <span class="mdi mdi-percent" matSuffix></span>
          @for (errorKey of errorKeys(formControlNewAssetBacked); track errorKey) {
            <mat-error>
              @if (formControlNewAssetBacked.touched && formControlNewAssetBacked.hasError(errorKey)) {
                {{errorMessage(formControlNewAssetBacked, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Used</mat-label>
          <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlUsedAssetBacked" required  >
          <span class="mdi mdi-percent" matSuffix></span>
          @for (errorKey of errorKeys(formControlUsedAssetBacked); track errorKey) {
            <mat-error>
              @if (formControlUsedAssetBacked.touched && formControlUsedAssetBacked.hasError(errorKey)) {
                {{errorMessage(formControlUsedAssetBacked, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>

</div>
