<div class="date-chip-component">
  <div fxLayout="column" fxLayoutGap="20px">
    <div class="top" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="10px" style="height: 42px">
        <div class="data-box-component">
          <span class="title">{{title}}</span>
          <span class="content {{colorTheme}}">{{date | date: '' + dateFormat + ''}}</span>
        </div>
      </div>
    </div>
  </div>
</div>