import { Component, Input, OnInit } from '@angular/core';
import {CustomContentLoaderType} from '@portal-workspace/grow-shared-library';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ContentLoaderModule} from "@ngneat/content-loader";

@Component({
    selector: 'custom-content-loader',
    templateUrl: './custom-content-loader.component.html',
    styleUrls: ['./custom-content-loader.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, NgClass, ContentLoaderModule]
})
export class CustomContentLoaderComponent implements OnInit {

  @Input({required: false}) type: CustomContentLoaderType  = 'list'

  constructor() { }

  ngOnInit(): void {
  }

}
