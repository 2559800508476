<div class="applicants-privacy-consent-dialog">
  <h3 mat-dialog-title>Applicants Privacy Consent</h3>
  <mat-dialog-content class="pt-2">
    <div class="row separator-bottom">
      <div class="col-12">
        <message-box type="warn">
          We require that at least one individual provides privacy consent. Evidence of privacy documentation may be requested during the submission process. Please confirm this requirement for the individual/s or reach out to us on 1300 001 420.
        </message-box>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table mat-table [dataSource]="data.applicants">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let applicant">{{applicant.firstName}} {{applicant.lastName}}</td>
          </ng-container>
          <ng-container matColumnDef="control">
            <th mat-header-cell *matHeaderCellDef>Privacy Consent</th>
            <td mat-cell *matCellDef="let applicant">
              <mat-slide-toggle
                color="primary"
                (change)="toggleApplicantPrivacyConsent($event, applicant)"
                [checked]="applicant.privacyConsentObtained">
              </mat-slide-toggle>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let applicant; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100 pl-lt-md-3 pr-lt-md-3"
         fxLayoutGap="10px"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between"
         fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button class="ml-0 w-lt-md-100" color="primary" (click)="onSave()">Save</button>
    </div>
  </mat-dialog-actions>
</div>
