import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  BreadcrumbComponent,
  BreadcrumbComponentEvent,
  LooseDatePipe, MessageBoxComponent,
  OverdraftAccountStatementDetailsComponent,
  PortalHotToastService,
  setupUntilDestroy,
  TagBoxComponent
} from '@portal-workspace/grow-ui-library';
import { PismoGetAccountResponse, PismoTransactionsIncludingPending, GetPismoStatementDetailsFn, PismoDownloadStatementFn, GetPismoAccountDetailsFn } from '@portal-workspace/grow-shared-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import {
   navigationUrlForPismoAccount,
} from '../../service/navigation-urls';
import { MatTableModule } from '@angular/material/table';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { DatePipe } from '@angular/common';
import { OverdraftCustomerService } from '../../service/overdraft-customer.service';
import _ from 'lodash';
import { CdkTableModule } from '@angular/cdk/table';

export class InternalDataSource implements DataSource<PismoTransactionsIncludingPending> {

  subject: BehaviorSubject<PismoTransactionsIncludingPending[]> = new BehaviorSubject<PismoTransactionsIncludingPending[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PismoTransactionsIncludingPending[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(c: PismoTransactionsIncludingPending[]) {
    this.subject.next(c);
  }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './pismo-statement-details.page.html',
  styleUrls: ['./pismo-statement-details.page.scss'],

  standalone: true,
  imports: [
    FlexModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    MatTableModule,
    CdkTableModule,
    DatePipe,
    LooseDatePipe,
    BreadcrumbComponent,
    TagBoxComponent,
    MessageBoxComponent,
    OverdraftAccountStatementDetailsComponent
]
})
export class PismoStatementDetailsPage implements OnInit {

  stage!: 'done' | 'error';

  breadcrumbTrails: string[] = ['Pismo Accounts'];
  subscriptions: Subscription[] = [];

  pismoAccountNumber !: number;
  statementId!: number;

  getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
  statementPeriod!: string;

  accountDetails: PismoGetAccountResponse | null = null;
  getPismoStatementDetailsFn!: GetPismoStatementDetailsFn;
  downloadStatementInPDF!: PismoDownloadStatementFn;
  downloadStatementInCSV!: PismoDownloadStatementFn;
  downloadStatementInOFX!: PismoDownloadStatementFn;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private overdraftCustomerService: OverdraftCustomerService,
    private toastService: PortalHotToastService
  ) {
    this.getPismoAccountDetailsFn = this.overdraftCustomerService.getPismoAccountDetailsFn;
    this.getPismoStatementDetailsFn = this.overdraftCustomerService.getPismoStatementDetails;
    this.downloadStatementInPDF = this.overdraftCustomerService.downloadStatement;
    this.downloadStatementInCSV = this.overdraftCustomerService.downloadStatementInCsv;
    this.downloadStatementInOFX = this.overdraftCustomerService.downloadStatementInOfx;
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.pismoAccountNumber = this.activatedRoute.snapshot.params.pismoAccountNumber;
    this.statementId = this.activatedRoute.snapshot.params.statementId;

    this.subscriptions.push(
      this.overdraftCustomerService.getPismoAccountDetailsFn([this.pismoAccountNumber]).pipe(
        this.toastService.spinnerObservable(),
        tap(r => {
          this.accountDetails = r[0];
          const accountName = this.accountDetails.account_name
          if (accountName) {
            this.breadcrumbTrails.push(accountName, 'Statement Details');
          }
          if (this.pismoAccountNumber && this.accountDetails) {
            this.stage = 'done';
          } else {
            this.stage = 'error';
          }
        })
      ).subscribe())
  }

  onStatementPeriodChange(period: string) {
    this.statementPeriod = period;
  }

  async onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    await this.router.navigate(navigationUrlForPismoAccount(this.pismoAccountNumber))
  }

}
