import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BusinessDisbursementInvoiceDialogData,
  BusinessDisbursementInvoicesDialogResult,
} from '@portal-workspace/grow-shared-library';
import { formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    templateUrl: './business-disbursement-invoice.dialog.html',
    styleUrls: ['./business-disbursement-invoice.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class BusinessDisbursementInvoiceDialog {
  formControlInvoiceNumber!: FormControl<string | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: BusinessDisbursementInvoiceDialogData,
    private dialogRef: MatDialogRef<BusinessDisbursementInvoiceDialog, BusinessDisbursementInvoicesDialogResult>,
    private formBuilder: FormBuilder) {
    this.formControlInvoiceNumber = this.formBuilder.control(null, [Validators.required, Validators.maxLength(30)]);
    if (data.currentInvoices?.invoiceNumber) {
      this.formControlInvoiceNumber.setValue(data.currentInvoices.invoiceNumber)
    }
  }

  ngOnInit() {
    this.formControlInvoiceNumber.valueChanges.subscribe(() => {
      console.log(this.formControlInvoiceNumber)
    })
    // this.formControlInvoiceNumber.valueChanges.subscribe((value: string | null) => {
    //   if (value) {
    //     const identifier = value?.trim()?.toLowerCase() ?? "";
    //     this.filteredInvoices = this.invoicesExceptSelected.filter(i => i.invoiceNumber.toLowerCase().includes(identifier))
    //   } else {
    //     this.filteredInvoices = this.invoicesExceptSelected;
    //   }
    // })
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      type: confirmation ? 'submit' : 'cancel',
      invoices: { 'invoiceNumber': this.formControlInvoiceNumber.value! },
    })
  }

  // updateRemainingInvoices() {
  //   this.invoicesExceptSelected = this.allInvoices.filter(i => !this.selectedInvoices.map(invoice => invoice.invoiceNumber).includes(i.invoiceNumber));
  //   this.filteredInvoices = this.invoicesExceptSelected;
  // }

  // remove(invoice: DisbursementInvoice): void {
  //   const index = this.selectedInvoices.indexOf(invoice);

  //   if (index >= 0) {
  //     this.selectedInvoices.splice(index, 1);
  //   }

  //   this.updateRemainingInvoices();
  // }

  // add(event: MatChipInputEvent): void {
  //   // const value = event.value;
  //   //
  //   //   // Add chip
  //   //   if (value) {
  //   //     this.selectedInvoices.push(value);
  //   //   }
  //   //   // Clear the input value
  //   //   event.chipInput!.clear();
  //   //   this.formControlInvoice.setValue(null);
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   const invoiceNumber = event.option.viewValue;
  //   const invoiceObj = this.allInvoices.find(i => i.invoiceNumber === invoiceNumber);
  //   if (invoiceObj) {
  //     this.selectedInvoices.push(invoiceObj);
  //   }
  //   this.invoiceInput.nativeElement.value = '';
  //   this.formControlInvoiceNumber.setValue(null);
  //   this.updateRemainingInvoices();
  // }

}
