<div class="business-disbursement-table-component">
  <h3 class="header">Disbursement</h3>
  <div fxLayout="row"
    class="header"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center start">
    <button mat-flat-button (click)="addNewDisbursement()">
      <span class="mdi mdi-plus" matPrefix></span>
      Add New
    </button>
  </div>
  <div class="disbursement-table">

    @if (loader.disbursementTable.inProgress$ | async) {
      <custom-content-loader type="list"></custom-content-loader>
    }
    <div [ngClass]="{hidden: (loader.disbursementTable.inProgress$ | async)}">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100">
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-4': column === 'action',
          'column-width-3': column === 'amount',
          'column-width-2': column === 'bankDetails',
          'column-width-1': column === 'invoices',
          'column-width-0': column === 'payee'
          }">
              <div class="cell">{{getColumnTitles(column)}}</div>
            </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex;" class="cell-alignment">
              <div class="cell">
                @if (column === 'payee') {
                  @if (element.payee) {
                    <div class="cell-content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" (click)="openPayeeDialog(element, i)">
                      <div class="cell-text">
                        <span class="payee-text">{{displayPayee(element)}}</span>
                      </div>
                      <div class="cell-icon">
                        <span class="mdi mdi-pencil hide" matTooltip="Edit payee"></span>
                      </div>
                    </div>
                  }
                  @if (!element.payee) {
                    <a (click)="openPayeeDialog(element, i)"><span class="mdi mdi-plus" matTooltip="Click to add a payee"></span></a>
                  }
                }
                @if (column === 'invoices') {
                  @if (element && element.invoices?.invoiceNumber) {
                    <a (click)="openInvoicesDialog(element, i)">
                      <div class="cell-content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                        <div class="cell-text">
                          <span>{{displayInvoices(element)}}</span>
                        </div>
                        <div class="cell-icon">
                          <span class="mdi mdi-pencil hide" matTooltip="Edit invoices"></span>
                        </div>
                      </div>
                    </a>
                  }
                  @if (!element.invoices?.invoiceNumber) {
                    <a (click)="openInvoicesDialog(element, i)"><span class="mdi mdi-plus" matTooltip="Click to add invoices"></span></a>
                  }
                }
                @if (column === 'amount') {
                  <a (click)="openAmountDialog(element, i)">
                    <div class="cell-content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                      <div class="cell-text">
                        <span>{{element.amount | looseCurrency}}</span>
                      </div>
                      <div class="cell-icon">
                        <span class="mdi mdi-pencil hide" matTooltip="Edit amount"></span>
                      </div>
                    </div>
                  </a>
                }
                @if (column === 'bankDetails') {
                  @if (element.bankDetails) {
                    <a (click)="openBankDetailsDialog(element, i)">
                      <div class="bank-details-text cell-content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                        <div class="cell-text">
                          @if (element && element.bankDetails.paymentType === 'direct-debit') {
                            <div>
                              <span>{{element.bankDetails?.financialInstitution}}</span> <br>
                              <span>Account Name: {{element.bankDetails?.bankAccountName}}</span> <br>
                              <span>BSB: {{element.bankDetails?.bankAccountBsb}}</span> <br>
                              <span>Account Number: {{element.bankDetails?.bankAccountNumber}}</span>
                            </div>
                          }
                          @if (element && element.bankDetails.paymentType === 'bpay') {
                            <div>
                              <span>Biller Name: {{element.bankDetails?.bpayBillerName  | truncate:19}}</span> <br>
                              <span>Biller Code: {{element.bankDetails?.bpayBillerCode}}</span> <br>
                              <span>CRN: {{element.bankDetails?.bpayCrn}}</span> <br>
                              <span [ngStyle]="{'color': bpayError[i]? 'red' : 'green' }">{{bpayMessage[i]}}</span>
                            </div>
                          }
                        </div>
                        <div class="cell-icon">
                          <span class="mdi mdi-pencil hide" matTooltip="Edit bank details"></span>
                        </div>
                      </div>
                    </a>
                  }
                  @if (!element.bankDetails) {
                    <a (click)="openBankDetailsDialog(element, i)"><span class="mdi mdi-plus" matTooltip="Click to add bank details"></span></a>
                  }
                }
                @if (column === 'action') {
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <span class="mdi mdi-trash-can-outline cursor-pointer" matTooltip="Delete" (click)="onDelete(element, i)"></span>
                  </div>
                }
              </div>
            </td>
          </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
      </table>
      @if (!this.disbursementData.length) {
        <table class="width-100">
          <tr><div class="not-found"> Please add disbursement</div></tr>
        </table>
      }
    </div>
  </div>
  @if (this.disbursementData.length) {
    <div class="disbursement-statistics">
      <div class="statistic-row" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start center">
        <div class="statistic-title">Total:</div>
        <div class="statistic-number">{{totalAmount | looseCurrency}}</div>
      </div>
      <div class="statistic-row sum-up-break" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start center">
        <div class="statistic-title">Brokerage:</div>
        <div class="statistic-number">{{paymentChartData.brokerageAmount | looseCurrency}}</div>
      </div>
      @if (docFeeFinanced) {
        <div class="statistic-row" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start center">
          <div class="statistic-title">
            Doc Fee:
          </div>
          <div class="statistic-number">{{paymentChartData.docFee | looseCurrency}}</div>
        </div>
      }
      <div class="statistic-row" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start center">
        <div class="statistic-title">Calculated Amount Financed:</div>
        <div class="statistic-number">{{sumUpAmount | looseCurrency}}</div>
      </div>
      <div class="statistic-row" fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start center">
        <div class="statistic-title">Amount Financed (incl Brokerage):</div>
        <div class="statistic-number">
          @if (application && application.ApplicationType === 'Consumer') {
            {{paymentChartData.principalAmt | looseCurrency}}
          }
          @if (application && application.ApplicationType !== 'Consumer') {
            {{(paymentChartData.principalAmt + paymentChartData.brokerageAmount) | looseCurrency}}
          }
        </div>
      </div>
    </div>
  }
</div>
