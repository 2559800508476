import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
// import {AngularMaterialComponentsModule} from '../../angular-material-components.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {DateChipComponent} from './date-chip.component';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
// import { DirectivesModule } from '../../directive/directives.module';
// import {GrowUiLibraryModule} from '@portal-workspace/grow-ui-library';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialLibraryModule,
  InputMaskModule,
  DirectiveLibraryModule,
]

@NgModule({
    imports: [
        ...modules,
        DateChipComponent
    ],
    exports: [
        DateChipComponent,
        ...modules,
    ]
})
export class DateChipModule {

}
