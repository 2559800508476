import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {isMoment, Moment} from 'moment';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {createDateInputMask, formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {validAgeValidator, mustBeTodayOrFutureDateValidator} from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { CustomErrorStateMatcher } from '@portal-workspace/grow-ui-library';
import { compareMatch, DatepickerValue } from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => DatepickerComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, InputMaskModule, ReactiveFormsModule, DisableControlDirective]
})
export class DatepickerComponent extends AbstractControlValueAccessor<DatepickerValue> implements OnInit, AfterContentChecked, Mark {

  createDateInputMask = createDateInputMask();
  errorStateMatcher = new CustomErrorStateMatcher();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  @Input({required: true}) title?: string;
  @Input({required: false}) allowOnly18YrsPlus: boolean = false;
  @Input({required: false}) disabled: boolean = false;
  @Input({required: false}) allowOnlyTodayOrFutureDate:boolean =false
  @Input({required: false}) optional: boolean = false;

  subscriptions: Subscription[] = [];

  formControl!: FormControl<string|null>;

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    super();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable()
  //   } else {
  //     this.formControl.enable();
  //   }
  // }


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const validators = [];
    if (!this.optional) {
      validators.push(Validators.required);
    }
    if (this.allowOnly18YrsPlus) {
      validators.push(validAgeValidator());
    }
    if (this.allowOnlyTodayOrFutureDate) {
      validators.push(mustBeTodayOrFutureDateValidator());
    }
    this.formControl = this.formBuilder.control(null, validators);
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      // distinctUntilChanged(compareMatch),
      tap(r => {
        // https://github.com/angular/components/issues/11523
        if (this.formControl.valid || (this.formControl.errors as any)?.matDatepickerParse || this.formControl.disabled) {
          // https://github.com/angular/components/issues/11523
          if (!!(this.formControl.errors as any)?.matDatepickerParse?.text) {
            this.formControl.setErrors(null);
          }
          if (typeof r == 'string') {
            const m = moment(r, 'DD/MM/YYYY');
            if (m && m.isValid()) {
              this.propagateChange(m);
            } else {
              this.propagateChange(null);
            }
          } else {
            this.propagateChange(r);
          }
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: DatepickerValue | null | undefined): void | DatepickerValue {
    if (v && isMoment(v)) {
      this.formControl.setValue(v.format('DD/MM/YYYY'));
    } else if (v == null || v == undefined) {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  // getAge() {
  //    let age = 0;
  //    let today = new Date();
  //    let selectedDate = new Date(this.formControl.value);
  //    age = today.getFullYear() - selectedDate.getFullYear();
  //    let m = today.getMonth() - selectedDate.getMonth();
  //    if (m < 0 || (m === 0 && today.getDate() < selectedDate.getDate())) {
  //      age--;
  //    }
  //    if (age < 18) {
  //      this.applicationDialogService.openAlertDialog({
  //        message: `Error`,
  //        subMessage: 'Age must be a minimum 18 years old. '
  //      });
  //      this.formControl.reset();
  //    }
  //  }

  // onDateChange($event: Event) {
  //  const dateValue = ($event.target as HTMLInputElement).value;
  //   const date = moment(dateValue, 'DD/MM/YYYY', false);
  //   const valid = date.isValid();
  //   if (valid) {
  //     if(this.allowOnly18YrsPlus===true){
  //       this.formControl.valueChanges.subscribe(r=>{
  //         this.getAge();
  //       })
  //     }
  //   } else {
  //     this.formControl.setErrors({'invalidDateFormat': true});
  //   }
  // }
  onDateChange($event: MatDatepickerInputEvent<Moment>) {
    if ($event && $event.value) {
      // if(this.allowOnlyTodayOrFutureDate){
      //   this.formControl.setValidators(mustBeTodayOrFutureDateValidator());
      //   this.formControl.updateValueAndValidity();
      // }
      this.formControl.setValue($event.value.format('DD/MM/YYYY'));
      this.formControl.updateValueAndValidity();
    }
  }
  mark() {
    this.formControl.markAllAsTouched();
    this.formControl.updateValueAndValidity();
  }
}
