import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CurrencyInputValue, EditAmortisedRepaymentDialogData, EditAmortisedRepaymentDialogResult, EditMonthlyFacilityFeePercentageDialogData, EditMonthlyFacilityFeePercentageDialogResult } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import _ from 'lodash';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { createTwoDecimalInputMask, formControlErrorKeys, formControlErrorMessage } from '../component-utils';
import { InputMaskModule } from '@ngneat/input-mask';

@Component({
  templateUrl: './edit-amortised-repayment.dialog.html',
  styleUrls: ['./edit-amortised-repayment.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass,
    MatFormFieldModule,
    LooseCurrencyPipe,
    InputMaskModule,
]
})
export class EditAmortisedRepaymentDialog implements OnInit {
  createTwoDecimalInputMask = createTwoDecimalInputMask();
  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;

  formControlRepayment!: FormControl<CurrencyInputValue>;
  formControlComment!: FormControl<string | null>;
  creditLimit: number = 0;
  monthlyFacilityFee: number = 0;
  formGroup!: FormGroup<{
    comment: FormControl<string | null>,
    repayment: FormControl<CurrencyInputValue>,
  }>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditAmortisedRepaymentDialogData,
    private dialogRef: MatDialogRef<EditAmortisedRepaymentDialog, EditAmortisedRepaymentDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.creditLimit = data.creditLimit;
    this.formControlRepayment = formBuilder.control(data.repayment ?? 0, [Validators.required]);
    this.formControlComment = formBuilder.control('', [Validators.required]);
    this.formGroup = this.formBuilder.group({
      comment: this.formControlComment,
      repayment: this.formControlRepayment,
    })
  }

  ngOnInit() {

  }


  onSave() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        repayment: this.formControlRepayment.value ?? 0,
        comment: this.formControlComment.value ?? '',
      });
    }
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }
}
