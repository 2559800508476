export type AssetPurposeType = 'replacing-existing-business-asset' | 'support-business-expansion' | 'income-generating-reason'| 'other-non-income-generating-reason';

export type AssetPurposeValue = {
  type: AssetPurposeType,
  name: string,
} | null;

export const AssetPurposeValueOptions: Exclude<AssetPurposeValue, null>[] = [
  {type: 'replacing-existing-business-asset', name: 'Replacing Existing Business Asset'},
  {type: 'support-business-expansion', name: 'Support Business Expansion'},
  {type: 'income-generating-reason', name: 'Income Generating Reason'},
  {type: 'other-non-income-generating-reason', name: 'Other Non Income Generating Reason'},
]

export type AssetPurposeComponentValue = {
  assetPurpose?:AssetPurposeValue | null,
  assetPurposeDescription?:string|null
  assetExistingFinanceCommitment?:AssetExistingFinanceCommitmentValue | null,
} | null;

 


export type AssetExistingFinanceCommitmentValue = {
  type: 'Yes' | 'No',
  name: string;
} | null;


export const AssetExistingFinanceCommitmentOptions: Exclude<AssetExistingFinanceCommitmentValue, null>[] = [
  { type: 'Yes', name: 'Yes' },
  { type: 'No', name: 'No' },
];
