<div class="monthly-revenue">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        <!--Total/Avg revenue-->
        <ng-container matColumnDef="revenue">
            <th mat-header-cell *matHeaderCellDef class="text-align-right width-1">
              <div class="cell">Total Revenue <br> Avg Revenue</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <div class="cell">{{element.totalrevenue | looseCurrency}} <br> {{element.avgrevenue | looseCurrency}}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="wages">
            <th mat-header-cell *matHeaderCellDef class="text-align-right width-2">
              <div class="cell">Total Wages <br> Avg Wages</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <div class="cell">{{element.totalwages | looseCurrency}} <br> {{element.avgwages | looseCurrency}}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="credit">
            <th mat-header-cell *matHeaderCellDef class="text-align-right width-2">
              <div class="cell">Total Other Credit <br> Avg Other Credit</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <div class="cell">{{element.totalothercredit | looseCurrency}} <br> {{element.avgothercredit | looseCurrency}}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="income">
            <th mat-header-cell *matHeaderCellDef class="text-align-right width-2">
              <div class="cell">Total Other Income <br> Avg Other Income</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <div class="cell">{{element.totalotherincome | looseCurrency}} <br> {{element.avgotherincome | looseCurrency}}</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
    </table>
</div>
