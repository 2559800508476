import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';

import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatOptionModule} from '@angular/material/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  compareMatch,
  CustomerListValue, CustomerListValueGetFn,
  NotNullable
} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomerListComponent), multi: true },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatOptionModule,
    DisableControlDirective
],
})
export class CustomerListComponent extends AbstractControlValueAccessor<CustomerListValue> implements OnInit {

  @Input({required: true}) title?: string;
  @Input({required: true}) getFn!: CustomerListValueGetFn;

  subscriptions: Subscription[] = [];

  formControl: FormControl<CustomerListValue>;

  customers: NotNullable<CustomerListValue>[] = [];

  constructor(private formBuilder:FormBuilder) {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.getFn().pipe(
      tap((r: Exclude<CustomerListValue, null>[]) => {
        if (r && r.length) {
          const acc = r.reduce((acc: { orig: NotNullable<CustomerListValue>[] }, current) => {
            if (current) {
              acc.orig.push(current);
            }
            return acc;
          }, {
            orig: []
          });
          this.customers = acc.orig;
        }
      }),
    ).subscribe();
    this.subscriptions.push(sub);


    const sub2 = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: CustomerListValue) => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe();
    this.subscriptions.push(sub2);

    // if (this.disable) {
    //       this.formControl.disable();
    // }
  }

  doWriteValue(v: CustomerListValue | null | undefined): void | CustomerListValue {
    if (v) {
      console.log('******** this originators', this.customers);
      setTimeout(()=> {
        const a  = this.customers.find(a => a.customerId === v.customerId);
        if (a) {
          this.formControl.setValue(a);
        }
      }, 2000);
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable()
  //   } else {
  //     this.formControl.enable();
  //   }
  // }
}
