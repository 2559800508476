import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import {infoNeeded, PeriodicElement} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { GridModule } from '@angular/flex-layout/grid';
import { FlexModule } from '@angular/flex-layout/flex';

const ELEMENT_DATA: PeriodicElement[] = [
  { icon: 'mdi mdi-check-decagram-outline', content: '1 year minimum trading', symbol: 'mdi mdi-check-circle-outline' },
  { icon: 'mdi mdi-wallet-outline', content: 'Turnover $75,000+ p.a', symbol: 'mdi mdi-check-circle-outline' },
  { icon: 'mdi mdi-flag-outline', content: 'Permanent Australian resident', symbol: 'mdi mdi-check-circle-outline' },
  { icon: 'mdi mdi-credit-card-outline', content: 'Clear credit file', symbol: 'mdi mdi-check-circle-outline' },
];
const ELEMENT_DATA1: infoNeeded[] = [
  { icon1: 'mdi mdi-email-outline', content1: 'Email address', symbol1: 'mdi mdi-check-circle-outline' },
  { icon1: 'mdi mdi-cellphone', content1: 'Mobile phone', symbol1: 'mdi mdi-check-circle-outline' },
  { icon1: 'mdi mdi-card-account-details-outline', content1: `Either an Australian driver's licence or Australian passport (mandatory)`, symbol1: 'mdi mdi-check-circle-outline' },
  { icon1: 'mdi mdi-card-text-outline', content1: 'Medicare card (optional)', symbol1: 'mdi mdi-check-circle-outline' },
];

@UntilDestroy()
@Component({
    selector: 'eligibility',
    templateUrl: './eligibility.component.html',
    styleUrls: ['./eligibility.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EligibilityComponent), multi: true },
    ],
    standalone: true,
    imports: [FlexModule, GridModule, MessageBoxComponent, MatTableModule, MatButtonModule]
})
export class EligibilityComponent implements OnInit {
  @Output() onGotIt = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  whoCanApplyColumns: string[] = ['icon', 'content', 'symbol'];
  whoCanApply = ELEMENT_DATA;

  infoNeededColumns: string[] = ['icon1', 'content1', 'symbol1'];
  infoNeeded = ELEMENT_DATA1;

  @Input({required: false}) title = 'Operates in a commercial premise?';

  // formGroup: FormGroup<{
  //   control: FormControl<boolean | null>
  // }>;
  // formControl: FormControl<boolean | null>;
  // subscription?: Subscription;

  constructor(private formBuilder: FormBuilder) {
    // this.formControl = formBuilder.control(true, [Validators.required]);
    // this.formGroup = formBuilder.group({
    //   control: this.formControl,
    // });
    // this.subscription = this.formControl.valueChanges.pipe(
    //   tap(r => {
    //   })
    // ).subscribe();
  }

  onGotItChild() {
    this.onGotIt.emit();
  }
  onGoBackChild() {
    this.onGoBack.emit();
  }
  ngOnInit(): void {
    setupUntilDestroy(this);
  }
}
