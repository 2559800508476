import {inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { getUser } from '@portal-workspace/grow-ui-library';
import { navigationUrlForNewApplication } from '../service/navigation-urls';


export const adminAccessGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser()
    if(user?.priviledges.includes('admin')){
      return true;
    }
    return router.navigate(navigationUrlForNewApplication())
  }
}


// Deprecated since Angular 16
// @Injectable()
// export class AdminAccessGuard  {
//   constructor(private router: Router){}
//
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//       const user = getUser()
//       if(user?.priviledges.includes('admin')){
//         return true;
//       }
//       return this.router.createUrlTree(navigationUrlForNewApplication())
//   }
//
// }
