import {Component, forwardRef, Input, numberAttribute, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import { compareMatch, SliderComponentValue } from '@portal-workspace/grow-shared-library';
import { MatSliderModule } from '@angular/material/slider';
import {JsonPipe, CurrencyPipe, NgClass} from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import numeral from 'numeral';
import _ from 'lodash';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SliderComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => SliderComponent), multi: false },
    ],
    standalone: true,
  imports: [FlexModule, MatSliderModule, FormsModule, ReactiveFormsModule, JsonPipe, CurrencyPipe, NgClass]
})
export class SliderComponent extends AbstractControlValueAccessor<SliderComponentValue> implements Mark, OnInit, OnChanges {

  formatLabel = (value: number): string => {
    return `${(value ?? 0)}${this.titlePostfix}`;
    // if (value >= 1000) {
    //   return numeral(_.round(value / 1000, this.numOfDecimals)).format('#.'+'#'.repeat(this.numOfDecimals)) + 'k';
    // }
    // return numeral(value).format('#');
  }
  subscriptions: Subscription[] = [];

  @Input({required: false}) showPermanentTick = false;
  @Input({required: false}) title: string | null = null;
  @Input({required: false}) min: number = 5000;
  @Input({required: false}) max: number = 500000;
  @Input({required: false}) step: number = 1000;
  @Input({required: false}) titlePostfix: string = '';
  @Input({required: false, transform: numberAttribute}) numOfDecimals: number = 2;
  value: number = this.min;

  formControl!: FormControl<SliderComponentValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(this.min, [Validators.required]);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(v => {
        this.f();
      })
    ).subscribe());
    setTimeout(()=> {
      this.f();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['max'] && this.formControl) {
      if(this.formControl.value !== null){
        if (this.formControl.value > changes['max']?.currentValue) {
          this.formControl.setValue(this.max);
          this.f();
        }
      }
    }
  }

  f(){
    if (this.formControl.valid) {
      this.propagateChange(this.formControl.value);
    } else {
      this.propagateChange(null);
    }
  }


  mark(): void {
    if (this.formControl) {
      this.formControl.markAsTouched();
    }
  }

  doWriteValue(v: SliderComponentValue | undefined): void | SliderComponentValue {
    this.formControl.setValue(v ? v : this.min);
    this.value = v ? v : this.min;
    return undefined;
  }

  onValueChanged($event: number) {
    this.formControl.setValue($event);
  }
}
