<div class="section">
  <div class="row">
    <div class="col">
      <div class="mat-h3">Company</div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <cell>
        <div label>Accreditation ID</div>
        <div body>{{accreditation.AccreditationId}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>Salesforce ID</div>
        <div body>{{accreditation.SalesforceId}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>Company name</div>
        <div body>{{accreditation.companyName}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <div class="row">
    <div class="col-12">
      <cell>
        <div label>Products</div>
        <div body>{{getProducts()}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <div class="row">
    <div class="col">
      <div class="mat-h3 mt-2">Company details and address</div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <cell>
        <div label>Entity type</div>
        <div body>{{accreditation.entityType}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>ABN</div>
        <div body>{{accreditation.abn}}</div>
      </cell>
    </div>
    @if (accreditation.acn != null) {
      <div class="col-3">
        <cell>
          <div label>ACN</div>
          <div body>{{accreditation.acn}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

@if (accreditation.entityType == 'TRST') {
  <div class="section">
    <div class="row">
      <div class="col-6">
        <cell>
          <div label>Trustee Company Name</div>
          <div body>{{accreditation.trusteeCompanyName ? accreditation.trusteeCompanyName : '-'}}</div>
        </cell>
      </div>
      <div class="col-6">
        <cell>
          <div label>Trustee Company ACN</div>
          <div body>{{accreditation.trusteeCompanyACN ? accreditation.trusteeCompanyACN : '-'}}</div>
        </cell>
      </div>
    </div>
    <mat-divider class="ml-0" [inset]="true"></mat-divider>
  </div>
}

<div class="section">
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Business Address</div>
        <div body>{{getAddress()}}</div>
      </cell>
    </div>
    <div class="col-6">
      <cell>
        <div label>Office Number</div>
        <div body>{{accreditation.officeNumber}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Held an accreditation with Dynamoney</div>
        <div body>{{accreditation.heldAccreditationBefore == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
    @if (accreditation.heldAccreditationBefore == '1') {
      <div class="col-6">
        <cell>
          <div label>Dynamoney Accreditation Id</div>
          <div body>{{accreditation.growbizAccreditationId}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Part of a group or aggregator</div>
        <div body>{{accreditation.ispartOfgroupOrAggregator == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
    @if (accreditation.ispartOfgroupOrAggregator == '1') {
      <div class="col-6">
        <cell>
          <div label>Group/Aggregator Name</div>
          <div body>{{accreditation.groupOrAggregatorName}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <!--*ngIf="accreditation.natureofBusiness != 'dealer'"-->
  <div class="row">
    <div class="col">
      <div class="mat-h3 mt-2">Credit Card Details</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Hold an Australian Credit Licence in your own right</div>
        <div body>{{accreditation.hasAustralianCreditLicence == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
    @if (accreditation.hasAustralianCreditLicence == '1') {
      <div class="col-6">
        <cell>
          <div label>Australian Credit Licence Number</div>
          <div body>{{accreditation.australianCreditLicenceNumber}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Directory/employee of a licensed entity</div>
        <div body>{{accreditation.dirORemployeeOfLicenceEntity == '0' ? 'No' : 'Yes'}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

<div class="section">
  <div class="row">
    <div class="col">
      <div class="mat-h3 mt-2">Acknowledgements</div>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <cell>
        <div label>Ever been declared bankrupt or subject to a Part 9 or 10 debt arrangement</div>
        <div body>{{accreditation.declaredBankrupt == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Ever been refused accreditation with any other lender</div>
        <div body>{{accreditation.refusedAccreditation == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-12">
      <cell>
        <div label>Ever been convicted of fraud or has any financial institution cancelled your accreditation</div>
        <div body>{{accreditation.convictedOfFraud == '0' ? 'No' : 'Yes' }}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    @if (accreditation.convictedOfFraud == '1') {
      <div class="col-12">
        <cell>
          <div label>Details</div>
          <div body>{{accreditation.convictedOfFraudDetails}}</div>
        </cell>
      </div>
    }
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>
