import {Component, Input, OnInit} from '@angular/core';
import {RedbookSearchByVehiclesResponse} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'redbook-details',
    templateUrl: './redbook-details.component.html',
    styleUrls: ['./redbook-details.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, FlexModule, LooseCurrencyPipe]
})
export class RedbookDetailsComponent implements OnInit {

  @Input({required: false}) vehicle!: RedbookSearchByVehiclesResponse[number] | null;
  @Input({required: false}) expand: boolean = false;

  ngOnInit(): void {
  }

}
