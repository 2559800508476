import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  DisbursementInvoice,
  AssetDisbursementInvoicesDialogData,
  AssetDisbursementInvoicesDialogResult
} from '@portal-workspace/grow-shared-library';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOptionModule } from '@angular/material/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { noSpaceValidator } from '../../validators/validators';

@Component({
    templateUrl: './asset-disbursement-invoices.dialog.html',
    styleUrls: ['./asset-disbursement-invoices.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatChipsModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule, MatOptionModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class AssetDisbursementInvoicesDialog implements OnInit{
  addOnBlur = true;
  allInvoices: DisbursementInvoice[] = [];
  invoicesExceptSelected: DisbursementInvoice[] = [];
  filteredInvoices: DisbursementInvoice[] = [];
  selectedInvoices: DisbursementInvoice[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formControlInvoiceNumber!: FormControl<string | null>;
  MAX_INVOICE_LENGTH = 30;

  @ViewChild('invoiceInput') invoiceInput!: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AssetDisbursementInvoicesDialogData,
              private dialogRef: MatDialogRef<AssetDisbursementInvoicesDialog, AssetDisbursementInvoicesDialogResult>,
              private formBuilder: FormBuilder) {
    this.allInvoices = [...this.data.allInvoices];
    this.selectedInvoices = [...this.data.currentInvoices];
    this.filteredInvoices = [...this.data.allInvoices];
    this.invoicesExceptSelected = [...this.data.allInvoices];
    this.formControlInvoiceNumber = this.formBuilder.control(null,[Validators.required,noSpaceValidator()]);
  }

  ngOnInit() {
    this.formControlInvoiceNumber.valueChanges.subscribe((value: string | null) => {
      if (value) {
        const identifier = value?.trim()?.toLowerCase() ?? "";
        this.filteredInvoices = this.invoicesExceptSelected.filter(i => i.invoiceNumber.toLowerCase().includes(identifier))
      } else {
        this.filteredInvoices = this.invoicesExceptSelected;
      }
    })
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      invoices: this.selectedInvoices,
    })
  }

  updateRemainingInvoices() {
    this.invoicesExceptSelected = this.allInvoices.filter(i => !this.selectedInvoices.map(invoice => invoice.invoiceNumber).includes(i.invoiceNumber));
    this.filteredInvoices = this.invoicesExceptSelected;
  }

  remove(invoice: DisbursementInvoice): void {
    const index = this.selectedInvoices.indexOf(invoice);

    if (index >= 0) {
      this.selectedInvoices.splice(index, 1);
    }

    this.updateRemainingInvoices();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

      // Add chip
      if ((value || '').trim() && this.formControlInvoiceNumber.valid) {
        this.selectedInvoices.push({
          invoiceNumber: value,
          invoiceAmount: 0,
          invoiceGst: 0,
          depositAmount: 0,
          depositAmountSupplier:0,
          depositAmountDynamoney: 0,
        });
      }
      // Clear the input value
      event.chipInput!.clear();
      this.formControlInvoiceNumber.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const invoiceNumber = event.option.viewValue;
    const invoiceObj = this.allInvoices.find(i => i.invoiceNumber === invoiceNumber);
    if (invoiceObj) {
      this.selectedInvoices.push(invoiceObj);
    }
    this.invoiceInput.nativeElement.value = '';
    this.formControlInvoiceNumber.setValue(null);
    this.updateRemainingInvoices();
  }

  validateInvoiceLength() {
    return this.selectedInvoices.map(i => i.invoiceNumber).join(",").length <= this.MAX_INVOICE_LENGTH;
  }

}
