import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  EditCreditFlowDialogData, EditCreditFlowDialogResult,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    templateUrl: './edit-credit-flow.dialog.html',
    styleUrls: ['./edit-credit-flow.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class EditCreditFlowDialog {
  formControlName!: FormControl<string | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCreditFlowDialogData,
              private dialogRef: MatDialogRef<EditCreditFlowDialog, EditCreditFlowDialogResult>,
              private formBuilder: FormBuilder) {
    const name = this.data.name ?? '';
    this.formControlName = this.formBuilder.control(name, [Validators.required]);
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      name: this.formControlName.value ?? '',
    })
  }
}
