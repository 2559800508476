<div class="edit-address-dialog">
  <mat-dialog-content>
    <h3 class="header">Please edit the address</h3>
    <div class="row">
      <div class="col-8">
        <custom-address
          title="Address"
          [formControl]="formControlAddress"
          [mark]="markTriggerSubject(formGroup)">
        </custom-address>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formControlAddress.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
