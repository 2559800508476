import {Pipe, PipeTransform} from "@angular/core";
import {Address, parseAddressComponentsToString} from "@portal-workspace/grow-shared-library";


@Pipe({
  name: 'address',
  pure: true,
  standalone: true,
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, ...args: any[]): any {
    if (value) {
      if ((value.UnformattedAddress ?? '').trim()) {
        return value.UnformattedAddress;
      }
      const unformattedAddress = parseAddressComponentsToString(
        value.UnitNumber,
        value.StreetNumber,
        value.StreetName,
        value.StreetType,
        value.Suburb,
        value.State,
        value.Postcode,
      );
      return unformattedAddress;
    }
    return '';
  }

}
