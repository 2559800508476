import {validApplicationTypeGuard, validDirectSaleApplicationTypeGuard} from './abstract-application.guard';

export const validBusinessOverdraftGuard = (isDirectSale: boolean = false) => {
  return isDirectSale ? validDirectSaleApplicationTypeGuard('BusinessOverdraft', 'e-not-business') : validApplicationTypeGuard('BusinessOverdraft', 'e-not-business');
}


// NOTE: Deprecated since Angular 16, replaced by validApplicationTypeGuard() instead
// import {Injectable} from '@angular/core';
// import {AbstractApplicationGuard} from './abstract-application.guard';
// import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
//
//
// @Injectable()
// export class ValidBusinessOverdraftGuard extends AbstractApplicationGuard {
//
//   getApplicationType(): ApplicationTypes {
//     return 'BusinessOverdraft';
//   }
//
//   getApplicationTypeErrorCode(): string {
//     return 'e-not-business';
//   }
//
// }
