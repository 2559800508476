import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


@Pipe({
    name: 'addressSplit',
    pure: true,
    standalone: true
})
export class AddressSplitPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, ...args: any[]): any {
    if (value) {
      let s = 2;  // 2 lines only
      if (args) {
        s = isNaN(parseInt(args[0])) ? 2 : parseInt(args[0]);
      }
      const address: string = value.toString();
      const parsedAddress = address.split(',').reduce((acc, add, index: number) => {
        return acc +=  (index < (s - 1)) ? `<div>${add}</div>` : add;
      }, '');
      return parsedAddress;
      // return this.sanitizer.bypassSecurityTrustHtml(parsedAddress);
    }
    return value;
  }
}
