import { Component, inject, Input, OnInit } from "@angular/core";
import {
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  CurrencyInputValue,
  DscrIndustry,
  DscrIndustryEbitda,
  calcApplicationDscrCalculatorValue,
  Application,
  getPaymentPeriod,
  BankStatementsAnalysisCategory,
  BankStatementsAnalysisTransactionDetails,
  TransactionTag,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  CreateApplicationNoteFn,
  RemoveApplicationNoteByNoteIdFn,
  GetDscrCalculatorHistoryFn,
  DscrCalculatorHistory,
  BankStatementTransactionRow,
  BankTransactionsByCategory,
  BankStatementTransaction,
  AccountSummary,
  BankStatementAccount,
} from "@portal-workspace/grow-shared-library";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest, Subscription, switchMap, tap } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { CurrencyInputComponent } from "../currency-selection-component/currency-input.component";
import { MatCardModule } from "@angular/material/card";
import { FlexModule } from "@angular/flex-layout/flex";
import { LooseCurrencyPipe } from "../../pipes/loose-currency.pipe";
import {
  createThreeDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from "../component-utils";
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { DscrIndustrySelectionComponent } from "../dscr-industry-selection-component/dscr-industry-selection.component";
import { DscrIndustryEbitdaSelectionComponent } from "../dscr-industry-selection-component/dscr-industry-ebitda-selection.component";
import { InputMaskModule } from "@ngneat/input-mask";
import _ from 'lodash';
import { MatButtonModule } from '@angular/material/button';
import { AssetNotesComponent } from '../asset-listing-component/asset-notes.component';
import { GetNotesByApplicationIdFn } from "../asset-listing-component/notes.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { NgClass } from "@angular/common";
import { DscrHistoryTableComponent } from "./dscr-history-table.component";
import { ChangeDetectorRef } from '@angular/core';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'bank-statement-dscr-calculator',
  templateUrl: './bank-statement-dscr-calculator.component.html',
  styleUrls: ['./bank-statement-dscr-calculator.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyInputComponent,
    MatCardModule,
    FlexModule,
    LooseCurrencyPipe,
    DscrIndustrySelectionComponent,
    DscrIndustryEbitdaSelectionComponent,
    InputMaskModule,
    MatButtonModule,
    AssetNotesComponent,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    MatTableModule,
    NgClass,
    DscrHistoryTableComponent,
  ],
})
export class BankStatementDscrCalculatorComponent implements OnInit {

  createThreeDecimalInputMask = createThreeDecimalInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  moment = moment;

  @Input({ required: true }) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({ required: true }) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({ required: true }) application!: Application;
  @Input({ required: true }) loanPaymentTransactions: BankStatementTransactionRow[] = [];
  @Input({ required: true }) incomeTransactions: BankStatementTransactionRow[] = [];
  @Input({ required: true }) atoPaymentTransactions: BankStatementTransactionRow[] = [];
  @Input({ required: true }) data: BankTransactionsByCategory[] = [];
  @Input({ required: true }) selectedAccounts: string[] = [];
  @Input({ required: true }) accountSummary: BankStatementAccount[] = [];
  @Input({ required: true }) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({ required: true }) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({ required: true }) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;

  history: DscrCalculatorHistory[] = [];
  lastSave: DscrCalculatorHistory[] = [];
  subscriptions: Subscription[] = [];
  hotToastService!: PortalHotToastService;
  allTransactions: BankStatementTransactionRow[] = [];
  maxDays: number = 0;
  // paymentPeriod!: string

  formBuilder!: FormBuilder;
  formGroup!: FormGroup<{
    industry: FormControl<DscrIndustry | null>,
    industryEbitda: FormControl<DscrIndustryEbitda | null>,
    adjustmentPercent: FormControl<CurrencyInputValue>,
    monthlySalesAverage3Months: FormControl<CurrencyInputValue>,
    monthlySalesAverage6Months: FormControl<CurrencyInputValue>,
    monthlySalesAverage12Months: FormControl<CurrencyInputValue>,
    averageMonthlySalesAdopted: FormControl<CurrencyInputValue>,
    salesAdjustment: FormControl<CurrencyInputValue>,
    averageMonthlySalesAdoptedAfterAdjustment: FormControl<CurrencyInputValue>,
    existingDebtCommitmentsMonthly: FormControl<CurrencyInputValue>,
    atoPaymentPlanMonthly: FormControl<CurrencyInputValue>,
    additionalCommitments: FormControl<CurrencyInputValue>,
    notes: FormControl<string | null>,
  }>;
  formControlCustomerName!: FormControl<string | null>; //application
  formControlLoanAmount!: FormControl<CurrencyInputValue>; //application
  formControlIndustry!: FormControl<DscrIndustry | null>;
  formControlIndustryEbitda!: FormControl<DscrIndustryEbitda | null>;
  formControlAdjustmentPercent!: FormControl<CurrencyInputValue>;
  formControlMarginAdoptedPercent!: FormControl<CurrencyInputValue>; //calculated
  formControlMonthlySalesAverage3Months!: FormControl<CurrencyInputValue>;
  formControlMonthlySalesAverage6Months!: FormControl<CurrencyInputValue>;
  formControlMonthlySalesAverage12Months!: FormControl<CurrencyInputValue>;
  formControlAverageMonthlySalesAdopted!: FormControl<CurrencyInputValue>;
  formControlSalesAdjustment!: FormControl<CurrencyInputValue>;
  formControlAverageMonthlySalesAdoptedAfterAdjustment!: FormControl<CurrencyInputValue>;
  formControlCashAvailableForDebtServiceMonthly!: FormControl<CurrencyInputValue>; //calculated
  formControlExistingDebtCommitmentsMonthly!: FormControl<CurrencyInputValue>;
  formControlAtoPaymentPlanMonthly!: FormControl<CurrencyInputValue>;
  formControlAdditionalCommitments!: FormControl<CurrencyInputValue>;
  formControlProposedCommitmentsMonthly!: FormControl<CurrencyInputValue>; //application
  formControlTotalFinancingCommitmentsMonthly!: FormControl<CurrencyInputValue>; //calculated
  formControlDebtServiceCoverRatio!: FormControl<CurrencyInputValue>; //calculated
  formControlSelectMonthlySalesAverage3Months!: FormControl<boolean | null>;
  formControlSelectMonthlySalesAverage6Months!: FormControl<boolean | null>;
  formControlSelectMonthlySalesAverage12Months!: FormControl<boolean | null>;
  formControlNotes!: FormControl<string | null>;

  constructor(private cd: ChangeDetectorRef) {
    this.formBuilder = inject(FormBuilder);
    this.hotToastService = inject(PortalHotToastService);
    this.formControlCustomerName = this.formBuilder.control(null);
    this.formControlCustomerName.disable();
    this.formControlLoanAmount = this.formBuilder.control(null);
    this.formControlLoanAmount.disable();
    this.formControlIndustry = this.formBuilder.control(null);
    this.formControlIndustryEbitda = this.formBuilder.control(null);
    this.formControlAdjustmentPercent = this.formBuilder.control(null);
    this.formControlMarginAdoptedPercent = this.formBuilder.control(null);
    this.formControlMarginAdoptedPercent.disable();
    this.formControlMonthlySalesAverage3Months = this.formBuilder.control(null);
    this.formControlMonthlySalesAverage6Months = this.formBuilder.control(null);
    this.formControlMonthlySalesAverage12Months = this.formBuilder.control(null);
    this.formControlAverageMonthlySalesAdopted = this.formBuilder.control(null);
    this.formControlSalesAdjustment = this.formBuilder.control(null);
    this.formControlAverageMonthlySalesAdoptedAfterAdjustment = this.formBuilder.control(null);
    this.formControlCashAvailableForDebtServiceMonthly = this.formBuilder.control(null);
    this.formControlCashAvailableForDebtServiceMonthly.disable();
    this.formControlExistingDebtCommitmentsMonthly = this.formBuilder.control(null);
    this.formControlAtoPaymentPlanMonthly = this.formBuilder.control(null);
    this.formControlAdditionalCommitments = this.formBuilder.control(null);
    this.formControlProposedCommitmentsMonthly = this.formBuilder.control(null);
    this.formControlTotalFinancingCommitmentsMonthly = this.formBuilder.control(null);
    this.formControlTotalFinancingCommitmentsMonthly.disable();
    this.formControlDebtServiceCoverRatio = this.formBuilder.control(null);
    this.formControlDebtServiceCoverRatio.disable();
    this.formControlSelectMonthlySalesAverage3Months = this.formBuilder.control(null);
    this.formControlSelectMonthlySalesAverage6Months = this.formBuilder.control(null);
    this.formControlSelectMonthlySalesAverage12Months = this.formBuilder.control(null);
    this.formControlNotes = this.formBuilder.control(null);


    this.formGroup = this.formBuilder.group({
      industry: this.formControlIndustry,
      industryEbitda: this.formControlIndustryEbitda,
      adjustmentPercent: this.formControlAdjustmentPercent,
      monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months,
      monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months,
      monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months,
      averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted,
      salesAdjustment: this.formControlSalesAdjustment,
      averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment,
      existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly,
      atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly,
      additionalCommitments: this.formControlAdditionalCommitments,
      notes: this.formControlNotes,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.initAllTransactions();
    this.populate();
    this.calc();

    this.subscriptions.push(this.getBsaCalculatorFn(this.application.ApplicationId).pipe(
      this.hotToastService.spinnerObservable(),
      tap(r => {
        if (r && r?.averageMonthlySalesAdopted !== null) {
          this.formControlIndustry.setValue(r.industry);
          this.formControlIndustryEbitda.setValue(r.industryEbitda);
          this.formControlAdjustmentPercent.setValue(r.adjustmentPercent);
          this.formControlAverageMonthlySalesAdopted.setValue(r.averageMonthlySalesAdopted);
          // this.formControlMarginAdoptedPercent.setValue(r.marginAdoptedPercent);
          // this.formControlMonthlySalesAverage3Months.setValue(r.monthlySalesAverage3Months);
          // this.formControlMonthlySalesAverage6Months.setValue(r.monthlySalesAverage6Months);
          // this.formControlMonthlySalesAverage12Months.setValue(r.monthlySalesAverage12Months);
          // this.formControlAverageMonthlySalesAdopted.setValue(r.averageMonthlySalesAdopted);
          this.formControlSalesAdjustment.setValue(r.salesAdjustment);
          // this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(r.averageMonthlySalesAdoptedAfterAdjustment);
          // this.formControlCashAvailableForDebtServiceMonthly.setValue(r.cashAvailableForDebtServiceMonthly);
          // this.formControlExistingDebtCommitmentsMonthly.setValue(r.existingDebtCommitmentsMonthly);
          // this.formControlAtoPaymentPlanMonthly.setValue(r.atoPaymentPlanMonthly);
          this.formControlAdditionalCommitments.setValue(r.additionalCommitments);
          this.formControlProposedCommitmentsMonthly.setValue(r.proposedCommitmentsMonthly);
          this.formControlTotalFinancingCommitmentsMonthly.setValue(r.totalFinancingCommitmentsMonthly);
          // this.formControlDebtServiceCoverRatio.setValue(r.debtServiceCoverRatio);
        } else {
          this.initCal();
          this.reCal();
        }
      }),
    ).subscribe());

    this.subscriptions.push(
      this.getDscrCalculatorHistoryFn(this.application.ApplicationId).pipe(
        this.hotToastService.spinnerObservable(),
      ).subscribe(history => {
        console.log('==history: ', history);
        if (history.length > 0) {
          this.lastSave = [history[0]];
          this.history = history.slice(1);
        }
      })
    )

    this.subscriptions.push(
      this.formControlSelectMonthlySalesAverage3Months.valueChanges.subscribe(value => {
        if (value) {
          this.formControlSelectMonthlySalesAverage6Months.setValue(false);
          this.formControlSelectMonthlySalesAverage12Months.setValue(false);

          this.formControlAverageMonthlySalesAdopted.setValue(this.formControlMonthlySalesAverage3Months.value);
          this.reCal();
        }
      })
    )

    this.subscriptions.push(
      this.formControlSelectMonthlySalesAverage6Months.valueChanges.subscribe(value => {
        if (value) {
          this.formControlSelectMonthlySalesAverage3Months.setValue(false);
          this.formControlSelectMonthlySalesAverage12Months.setValue(false);
          const value = _.round((
            (this.formControlMonthlySalesAverage3Months.value ?? 0) +
            (this.formControlMonthlySalesAverage6Months.value ?? 0)
          ) / 2, 2);

          this.formControlAverageMonthlySalesAdopted.setValue(value);
          this.reCal();
        }
      })
    )

    this.subscriptions.push(
      this.formControlSelectMonthlySalesAverage12Months.valueChanges.subscribe(value => {
        if (value) {
          this.formControlSelectMonthlySalesAverage3Months.setValue(false);
          this.formControlSelectMonthlySalesAverage6Months.setValue(false);
          const value = _.round((
            (this.formControlMonthlySalesAverage3Months.value ?? 0) +
            (this.formControlMonthlySalesAverage6Months.value ?? 0) +
            (this.formControlMonthlySalesAverage12Months.value ?? 0)
          ) / 3, 2);

          this.formControlAverageMonthlySalesAdopted.setValue(value);
          this.reCal();
        }
      })
    )
  }

  initAllTransactions() {
    let id = 0;
    this.data.forEach((d: BankTransactionsByCategory) => {
      d.transactions.forEach((detail: BankStatementTransaction) => {
        let row: BankStatementTransactionRow = {
          date: detail.date,
          transaction: detail.description,
          debit: 0,
          credit: 0,
          party: '',
          category: '',
          otherInfo: '',
          accountId: detail.accountId
        };
        detail?.tags?.forEach((obj: TransactionTag) => {
          const keyArray: string[] = Object.keys(obj);
          const key: string = keyArray.length ? keyArray[0] : '';
          switch (key) {
            case 'thirdParty':
              row.party = obj['thirdParty'];
              break;
            case 'category':
              if (row.category?.length) {
                row.category += ', ' + obj['category'];
              } else {
                row.category = obj['category'];
              }
              break;
            case 'creditDebit':
              obj['creditDebit'] === 'credit' ? row.credit = detail.amount : row.debit = detail.amount;
              break;
            default:
              if (row.otherInfo?.length) {
                row.otherInfo += ` | ${key}: ${obj[key]}`;
              } else {
                row.otherInfo = `${key}: ${obj[key]}`;
              }
          }
        })

        if (this.selectedAccounts.includes(row.accountId)) {
          const rowWithId = {
            ...row,
            id: id++,
          }
          this.allTransactions.push(rowWithId);
        }
      })
    })
  }

  populate() {
    this.formControlCustomerName.setValue(this.application?.CompanyName);
    const amountFinanced = (this.application?.PricingDetails as any).AmountFinance;
    const loan = (this.application?.PricingDetails as any).LoanAmount;
    const loanAmount = amountFinanced ?? loan ?? 0;
    this.formControlLoanAmount.setValue(loanAmount);

    let repayment = (this.application?.PricingDetails as any).Repayment ?? 0;
    const paymentFrequency = (this.application?.PricingDetails as any).PaymentPeriod ?? "Monthly";
    if (paymentFrequency === 'Weekly') {
      repayment = _.round(repayment * 52 / 12, 2);
    } else if (paymentFrequency === 'Fortnightly') {
      repayment = _.round(repayment * 26 / 12, 2);
    }
    this.formControlProposedCommitmentsMonthly.setValue(repayment);

    const selectedAccountsLastDates = this.accountSummary.filter(a => this.selectedAccounts.includes(a.id)).map(a => new Date(`${a.lastDate}T00:00:00Z`)).sort();
    const latestDate = selectedAccountsLastDates.length ? selectedAccountsLastDates[selectedAccountsLastDates.length - 1] : new Date();

    // oldest transaction days
    this.allTransactions = this.allTransactions.sort((a, b) => a.date > b.date && -1 || 1);
    if (this.allTransactions.length) {
      const oldestTranasction = this.allTransactions[this.allTransactions.length - 1];
      this.maxDays = Math.round(((latestDate as any) - (new Date(oldestTranasction.date) as any)) / (1000 * 60 * 60 * 24));
    }

    const lastMonth = new Date(new Date(latestDate).setMonth(new Date(latestDate).getMonth() - 1));
    const last3Months = new Date(new Date(latestDate).setMonth(new Date(latestDate).getMonth() - 3));
    const last6Months = new Date(new Date(latestDate).setMonth(new Date(latestDate).getMonth() - 6));
    const last12Months = new Date(new Date(latestDate).setMonth(new Date(latestDate).getMonth() - 12));

    // income
    const last3MonthsIncome = _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last3Months).reduce((a, b) => a + b.credit + b.debit, 0) / 3, 2);
    const last6MonthsIncome = _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last6Months).reduce((a, b) => a + b.credit + b.debit, 0) / 6, 2);
    const last12MonthsIncome = _.round(this.incomeTransactions.filter(obj => (new Date(obj.date)) >= last12Months).reduce((a, b) => a + b.credit + b.debit, 0) / 12, 2) ;
    this.formControlMonthlySalesAverage3Months.setValue(last3MonthsIncome);
    this.formControlMonthlySalesAverage6Months.setValue(last6MonthsIncome);
    this.formControlMonthlySalesAverage12Months.setValue(last12MonthsIncome);

    // loan payment
    const last30DaysLoanPayment = Math.abs(_.round(this.loanPaymentTransactions.reduce((a, b) => a + b.credit + b.debit, 0), 2)); //.filter(obj => (new Date(obj.date)) >= last30Days)
    this.formControlExistingDebtCommitmentsMonthly.setValue(last30DaysLoanPayment);

    // ato payment plan
    const last30DaysAtoPayment = Math.abs(_.round(this.atoPaymentTransactions.filter(obj =>
      (new Date(obj.date)) >= lastMonth &&
      (`${obj.category} ${obj.party} ${obj?.transaction}`.includes('ATODD') || `${obj.category} ${obj.party} ${obj?.transaction}`.includes('Atodd'))
    ).reduce((a, b) => a + b.credit + b.debit, 0), 2));
    this.formControlAtoPaymentPlanMonthly.setValue(last30DaysAtoPayment);
  }

  // calculate the computed values based on manual inputs
  calc() {
    // calculations
    this.subscriptions.push(
      combineLatest([
        this.formControlIndustryEbitda.valueChanges,
        this.formControlAdjustmentPercent.valueChanges,
        this.formControlMonthlySalesAverage3Months.valueChanges,
        this.formControlMonthlySalesAverage6Months.valueChanges,
        this.formControlMonthlySalesAverage12Months.valueChanges,
        this.formControlSalesAdjustment.valueChanges,
        this.formControlAverageMonthlySalesAdopted.valueChanges,
        //this.formControlMarginAdoptedPercent.valueChanges,
        this.formControlExistingDebtCommitmentsMonthly.valueChanges,
        this.formControlAtoPaymentPlanMonthly.valueChanges,
        this.formControlAdditionalCommitments.valueChanges,
        this.formControlProposedCommitmentsMonthly.valueChanges
      ]).subscribe(([
        industryEbitdaValue,
        adjustmentPercentValue,
        monthlySalesAverage3MonthsValue,
        monthlySalesAverage6MonthsValue,
        monthlySalesAverage12MonthsValue,
        salesAdjustmentValue,
        averageMonthlySalesAdoptedValue,
        existingDebtCommitmentsMonthlyValue,
        atoPaymentPlanMonthlyValue,
        additionalCommitmentsValue,
        proposedDynaCommitmentsMonthlyValue,
      ]) => {
        const industryEbitda = industryEbitdaValue;
        const adjustmentPercent = Number(adjustmentPercentValue ?? 0);
        const monthlySalesAverage3Months = monthlySalesAverage3MonthsValue ?? 0;
        const monthlySalesAverage6Months = monthlySalesAverage6MonthsValue ?? 0;
        const monthlySalesAverage12Months = monthlySalesAverage12MonthsValue ?? 0;
        const salesAdjustment = salesAdjustmentValue ?? 0;
        const averageMonthlySalesAdopted = averageMonthlySalesAdoptedValue ?? 0;
        const existingDebtCommitmentsMonthly = existingDebtCommitmentsMonthlyValue ?? 0;
        const atoPaymentPlanMonthly = atoPaymentPlanMonthlyValue ?? 0;
        const additionalCommitments = additionalCommitmentsValue ?? 0;
        const proposedDynaCommitmentsMonthly = proposedDynaCommitmentsMonthlyValue ?? 0;

        const marginAdoptedPercent = adjustmentPercent + (industryEbitda?.percentage ?? 0);

        const averageMonthlySalesAdoptedAfterAdjustment = _.round(averageMonthlySalesAdopted + salesAdjustment, 2);
        const cashAvailableForDebtServiceMonthly = _.round(averageMonthlySalesAdoptedAfterAdjustment * marginAdoptedPercent / 100, 2);
        const totalFinancingCommitmentsMonthly = _.round(existingDebtCommitmentsMonthly + atoPaymentPlanMonthly + additionalCommitments + proposedDynaCommitmentsMonthly, 2);
        const debtServiceCoverRatio = _.round(totalFinancingCommitmentsMonthly ? cashAvailableForDebtServiceMonthly / totalFinancingCommitmentsMonthly : 0, 2);

        this.formControlMarginAdoptedPercent.setValue(marginAdoptedPercent);
        //this.formControlAverageMonthlySalesAdopted.setValue(averageMonthlySalesAdopted);
        this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(averageMonthlySalesAdoptedAfterAdjustment);
        this.formControlCashAvailableForDebtServiceMonthly.setValue(cashAvailableForDebtServiceMonthly);
        this.formControlTotalFinancingCommitmentsMonthly.setValue(totalFinancingCommitmentsMonthly);
        this.formControlDebtServiceCoverRatio.setValue(debtServiceCoverRatio);
      })
    )
  }

  reCal() {
    const salesAdjustment = this.formControlSalesAdjustment.value ?? 0;
    const existingDebtCommitmentsMonthly = this.formControlExistingDebtCommitmentsMonthly.value ?? 0;
    const atoPaymentPlanMonthly = this.formControlAtoPaymentPlanMonthly.value ?? 0;
    const additionalCommitments = this.formControlAdditionalCommitments.value ?? 0;
    const proposedDynaCommitmentsMonthly = this.formControlProposedCommitmentsMonthly.value ?? 0;
    const averageMonthlySalesAdopted = this.formControlAverageMonthlySalesAdopted.value ?? 0;
    const industryEbitda = this.formControlIndustryEbitda.value;
    const adjustmentPercent = Number(this.formControlAdjustmentPercent.value ?? 0);
    const marginAdoptedPercent = adjustmentPercent + (industryEbitda?.percentage ?? 0);

    const averageMonthlySalesAdoptedAfterAdjustment = _.round(averageMonthlySalesAdopted + salesAdjustment, 2);
    const cashAvailableForDebtServiceMonthly = _.round(averageMonthlySalesAdoptedAfterAdjustment * marginAdoptedPercent / 100, 2);
    const totalFinancingCommitmentsMonthly = _.round(existingDebtCommitmentsMonthly + atoPaymentPlanMonthly + additionalCommitments + proposedDynaCommitmentsMonthly, 2);
    const debtServiceCoverRatio = _.round(totalFinancingCommitmentsMonthly ? cashAvailableForDebtServiceMonthly / totalFinancingCommitmentsMonthly : 0, 2);

    this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(averageMonthlySalesAdoptedAfterAdjustment);
    this.formControlCashAvailableForDebtServiceMonthly.setValue(cashAvailableForDebtServiceMonthly);
    this.formControlTotalFinancingCommitmentsMonthly.setValue(totalFinancingCommitmentsMonthly);
    this.formControlDebtServiceCoverRatio.setValue(debtServiceCoverRatio);
  }

  initCal() {
    const value = _.round((
      (this.formControlMonthlySalesAverage3Months.value ?? 0) +
      (this.formControlMonthlySalesAverage6Months.value ?? 0) +
      (this.formControlMonthlySalesAverage12Months.value ?? 0)
    ) / 3, 2);

    this.formControlAverageMonthlySalesAdopted.setValue(value);
  }

  save() {
    if (this.formGroup.valid) {
      this.saveBsaCalculatorFn({
        applicationId: this.application.ApplicationId,
        data: {
          industry: this.formControlIndustry.value!,
          industryEbitda: this.formControlIndustryEbitda.value!,
          adjustmentPercent: this.formControlAdjustmentPercent.value ?? 0,
          marginAdoptedPercent: this.formControlMarginAdoptedPercent.value ?? 0,
          monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months.value ?? 0,
          monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months.value ?? 0,
          monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months.value ?? 0,
          averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted.value ?? 0,
          salesAdjustment: this.formControlSalesAdjustment.value ?? 0,
          averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment.value ?? 0,
          existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly.value ?? 0,
          atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly.value ?? 0,
          additionalCommitments: this.formControlAdditionalCommitments.value ?? 0,
          cashAvailableForDebtServiceMonthly: this.formControlCashAvailableForDebtServiceMonthly.value ?? 0,
          proposedCommitmentsMonthly: this.formControlProposedCommitmentsMonthly.value ?? 0,
          totalFinancingCommitmentsMonthly: this.formControlTotalFinancingCommitmentsMonthly.value ?? 0,
          debtServiceCoverRatio: this.formControlDebtServiceCoverRatio.value ?? 0,
          notes: this.formControlNotes.value ?? '',
        },
        saveDscrCalculator: true,
    }).pipe(
      this.hotToastService.snackBarObservable(`Result saved!`),
      this.hotToastService.spinnerObservable(),
      switchMap(() => this.getDscrCalculatorHistoryFn(this.application.ApplicationId)),
    ).subscribe(history => {
      console.log('==history: ', history);
      if (history.length > 0) {
        this.lastSave = [history[0]];
        this.history = history.slice(1);
        this.cd.detectChanges();
      }
    });
  }
  }
}
