import { AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { BpayComponentValue, BpayValidationData, BusinessNumberSearchResult, BusinessNumberSearchValue, compareMatch, GetBillerNameFn, NotNullable } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Mark, MARK } from '@portal-workspace/grow-ui-library/mark';
import {
  createBillerCodeMask,
  createBpayCRNMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import {debounceTime, filter} from "rxjs/operators";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'bpay-bank-details',
    templateUrl: './bpay-bank-details.component.html',
    styleUrls: ['./bpay-bank-details.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BpayBankDetailsComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BpayBankDetailsComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, CurrencyInputComponent, DisableControlDirective]
})
export class BpayBankDetailsComponent extends AbstractControlValueAccessor<BpayComponentValue> implements OnInit, Mark, AfterContentChecked {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  createBillerCodeMask = createBillerCodeMask();
  createBpayCRNMask = createBpayCRNMask();

  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: false}) hideAmount = false;

  subscriptions: Subscription[] = [];

  formControlBpayBillerCode!: FormControl<string | null>
  formControlBpayCrnCode!: FormControl<string | null>
  formControlBpayBillerName!: FormControl<string | null>
  formControlAmount!: FormControl<number | null>

  formGroup!: FormGroup<{
    BillerCode: FormControl<string | null>,
    CRN: FormControl<string | null>,
    BillerName: FormControl<string | null>,
    Amount: FormControl<number | null>
  }>;

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlBpayBillerCode = this.formBuilder.control(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(6)]);
    this.formControlBpayCrnCode = this.formBuilder.control(null, [Validators.required, Validators.maxLength(20)]);
    this.formControlBpayBillerName = this.formBuilder.control(null, [Validators.required])
    this.formControlAmount = this.formBuilder.control(null, this.hideAmount ? [] : [Validators.required]);
    this.formGroup = this.formBuilder.group({
      BillerCode: this.formControlBpayBillerCode,
      CRN: this.formControlBpayCrnCode,
      BillerName: this.formControlBpayBillerName,
      Amount: this.formControlAmount
    })

    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.formGroup.valid) {
          this.propagateChange(v)
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe())

    this.subscriptions.push(this.formControlBpayBillerCode.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(r => this.formControlBpayBillerCode.valid),
      tap(r => {
        if (r && r?.length >= 4) {
          this.getBillerNameFn(r).pipe(
            tap((result) => {
              const billerName = result?.longName ?? '';
              this.formControlBpayBillerName.setValue(billerName.trim())
            })
          ).subscribe()
        }
      })
    ).subscribe())
  }


  doWriteValue(v: BpayComponentValue | null | undefined): void | BpayComponentValue {
    if (v) {
      this.formControlBpayBillerName.setValue(v.BillerName.trim())
      this.formControlBpayBillerCode.setValue(v.BillerCode)
      this.formControlBpayCrnCode.setValue(v.CRN)
      this.formControlAmount.setValue(v.Amount ?? null)
    } else {
      this.propagateChange(null);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
