<div class="non-asset-rate-card-component">
  <div class="row separator">
    <div class="col-3">
      <div class="mat-h3">Base interest rate</div>
      <mat-form-field>
        <mat-label>Base interest rate</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlBaseInterestRate">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlBaseInterestRate); track errorKey) {
          <mat-error>
            @if (formControlBaseInterestRate.touched && formControlBaseInterestRate.hasError(errorKey)) {
              {{errorMessage(formControlBaseInterestRate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan amount" [formControl]="formControlLoanAmount"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan terms" [formControl]="formControlLoanTerms"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="EOT Age" [formControl]="formControlEotAge"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Brokerage" [formControl]="formControlBrokerage"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="mat-h3 separator-top">Private Sale</div>
  <div class="row">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Private sale uplift</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlPrivateSale">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlPrivateSale); track errorKey) {
          <mat-error>
            @if (formControlPrivateSale.touched && formControlPrivateSale.hasError(errorKey)) {
              {{errorMessage(formControlPrivateSale, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-3">
      <currency-input title="Private sale doc fee"  [formControl]="formControlPrivateSaleDocFee"></currency-input>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="mat-h3 separator-top">Risk Profile</div>
  <div class="row separator">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Adverse on file</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlAdverseOnFile">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAdverseOnFile); track errorKey) {
          <mat-error>
            @if (formControlAdverseOnFile.touched && formControlAdverseOnFile.hasError(errorKey)) {
              {{errorMessage(formControlAdverseOnFile, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Low equifax score</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlLowEquifaxScore">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlLowEquifaxScore); track errorKey) {
          <mat-error>
            @if (formControlLowEquifaxScore.touched && formControlLowEquifaxScore.hasError(errorKey)) {
              {{errorMessage(formControlLowEquifaxScore, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="row separator">
    <div class="col-3">
      <mat-form-field>
        <mat-label>No property ownership</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlNoPropertyOwnership">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNoPropertyOwnership); track errorKey) {
          <mat-error>
            @if (formControlNoPropertyOwnership.touched && formControlNoPropertyOwnership.hasError(errorKey)) {
              {{errorMessage(formControlNoPropertyOwnership, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Cars property owner</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlCarsPropertyOwner">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlCarsPropertyOwner); track errorKey) {
          <mat-error>
            @if (formControlCarsPropertyOwner.touched && formControlCarsPropertyOwner.hasError(errorKey)) {
              {{errorMessage(formControlCarsPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field>
        <mat-label>Asset condition used</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlAssetConditionUsed">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAssetConditionUsed); track errorKey) {
          <mat-error>
            @if (formControlAssetConditionUsed.touched && formControlAssetConditionUsed.hasError(errorKey)) {
              {{errorMessage(formControlAssetConditionUsed, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="row separator">
    <div class="col-12">
      <min-max-rate  title="Doc Fee" [type]="'currency'" [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <asset-category-rate  title="Asset Category" [formControl]="formControlAssetCategory"></asset-category-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <asset-type-rate title="Asset Type"  [formControl]="formControlAssetType"></asset-type-rate>
    </div>
  </div>

</div>
