import {Component, Input} from '@angular/core';
import _ from 'lodash';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    selector: 'accreditation-stage-icon',
    templateUrl: './accreditation-stage-icon.component.html',
    styleUrls: ['./accreditation-stage-icon.component.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class AccreditationStageIconComponent {

  @Input({required: false}) stage: string = '';

  toClass() {
    return this.stage ? _.toLower(this.stage.replace(/\s/g, '')) : '';
  }

  stageIcon(): string {
    const stageClass = this.toClass();

    switch (stageClass) {
      case 'lead':
        return 'progress-star';
      case 'submittedtocredit':
        return 'progress-alert';
      case 'waitingforbankstatements':
        return 'progress-clock';
      case 'moreinformation':
        return 'progress-question';
      case 'creditapproved':
        return 'progress-check';
      case 'dealpreparation':
        return 'progress-clock';
      case 'documentation':
        return 'progress-check';
      case 'docsout':
        return 'progress-upload';
      case 'settlementpending':
        return 'progress-clock';
      case 'qa':
        return 'progress-question';
      case 'settlement':
        return 'progress-clock';
      case 'closedwon':
        return 'progress-star';
      case 'closedlost':
        return 'progress-close';
      default:
        return 'progress-clock';
    }
  }
}
