import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ApiResponse, GetTokenInfoFn, PismoActivateOrDeactivateCardDialogData, PismoCardDetailsWithAccountId, PismoCardStatus, PismoCardTokenInfo, PismoChangeCardStatus, PismoChangeCardStatusFn, PismoGetCardReissueReasonsFn, PismoReissueCardDialogResult, PismoReissueCardFn, SlideToggleValue } from "@portal-workspace/grow-shared-library";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { MatTableModule } from "@angular/material/table";
import { DatePipe, JsonPipe } from "@angular/common";
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import {UntilDestroy} from '@ngneat/until-destroy';
import { ApplicationDialogService, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDividerModule } from "@angular/material/divider";
import { MessageBoxComponent } from "../message-box/message-box.component";
import { MatCardModule } from "@angular/material/card";


export type DataSourceEntry = PismoCardDetailsWithAccountId;


export class InternalDataSource implements DataSource<DataSourceEntry> {
  subject: BehaviorSubject<DataSourceEntry[]> = new BehaviorSubject<DataSourceEntry[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<DataSourceEntry[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(c: DataSourceEntry[]) {
    this.subject.next(c);
  }
}

export class TokenDetailsDataSource implements DataSource<PismoCardTokenInfo> {
  subject: BehaviorSubject<PismoCardTokenInfo[]> = new BehaviorSubject<PismoCardTokenInfo[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PismoCardTokenInfo[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(c: PismoCardTokenInfo[]) {
    this.subject.next(c);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  standalone: true,
  templateUrl: './pismo-activate-or-deactivate-card-dialog.component.html',
  styleUrls: ['./pismo-activate-or-deactivate-card-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  imports: [
    FlexModule,
    JsonPipe,
    MatButtonModule,
    DatePipe,
    MatTooltipModule,
    SlideToggleComponent,
    MatTableModule, MatDividerModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSlideToggleModule, MessageBoxComponent, MatCardModule
]
})
export class PismoActivateOrDeactivateCardDialog implements OnInit {

  @Output() onResult: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  pismoChangeCardStatusFn!: PismoChangeCardStatusFn;
  pismoGetCardReissueReasonsFn!: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn!: PismoReissueCardFn;
  getTokenInfoFn!: GetTokenInfoFn;

  displayColumns = ['printed_name', 'last_4_digits', 'expiration_date', 'status', 'actions'];
  expandedElement: any | null = null;

  subscriptions: Subscription[] = [];
  dataSource = new InternalDataSource()

  tokenDetails: PismoCardTokenInfo[] | null = null;
  tokenDetailsDataSource = new TokenDetailsDataSource();
  slideToggleFormControl: FormControl<boolean | null>;
  cardDetails: PismoCardDetailsWithAccountId;
  // [
  //   { dpan: '1234567890123456', deviceType: 'Mobile', platform: 'iOS' },
  //   { dpan: '2345678901234567', deviceType: 'Tablet', platform: 'Android' },
  // ];


  constructor(
      private dialogRef: MatDialogRef<PismoActivateOrDeactivateCardDialog>,
      @Inject(MAT_DIALOG_DATA) public data: PismoActivateOrDeactivateCardDialogData,
      private dialogService: ApplicationDialogService,
      private formBuilder: FormBuilder,
      private toastService: PortalHotToastService,) {

    this.pismoChangeCardStatusFn = data.pismoChangeCardStatus;
    this.pismoGetCardReissueReasonsFn = data.pismoGetCardReissueReasonsFn;
    this.pismoReissueCardFn = data.pismoReissueCardFn;
    this.tokenDetails = data.tokenInfos;
    this.cardDetails = data.cardDetails;
    this.dataSource.update([this.cardDetails])
    console.log('token details', this.tokenDetails)
    this.tokenDetailsDataSource.update(this.tokenDetails);


    const allowActivationOrInactivation = (this.cardDetails.status === 'NORMAL' || this.cardDetails.status === 'BLOCKED');
    const isActivated = this.cardDetails.status === 'NORMAL';
    this.slideToggleFormControl = this.formBuilder.control(isActivated, [Validators.required]);
    if (!allowActivationOrInactivation) { // only allow toggle for NORMAL or BLOCKED cards
      this.slideToggleFormControl.disable();
    }
    this.subscriptions.push(this.slideToggleFormControl.valueChanges.pipe(
      this.toastService.snackBarObservable(`Card status updated`),
      tap(r => {
        let cardStatus: PismoChangeCardStatus
        console.log("update card status response", r);
        if (r == true)
          cardStatus = { status: 'NORMAL' };
        else
          cardStatus = { status: 'BLOCKED' };
        this.pismoChangeCardStatusFn(
          data.cardDetails.accountId,
          data.cardDetails.customerId,
          data.cardDetails.id,
          cardStatus
        ).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.snackBarObservable(`Card status updated`),
        ).subscribe((resp: ApiResponse) => {
          this.dialogRef.close();
          console.log('change card status response', resp);
        })
      })
    ).subscribe());
  }

  ngOnInit() {
    setupUntilDestroy(this);
    // Check if dataSourceEntries is not empty
  }

  onCancel(event: Event) {
    this.dialogRef.close({});
  }

  onReissueCard() {
    this.subscriptions.push(
      this.dialogService.openPismoReissueCardDialog({
        cardDetails: this.cardDetails,
        pismoGetCardReissueReasonsFn: this.pismoGetCardReissueReasonsFn,
        pismoReissueCardFn: this.pismoReissueCardFn,
      }).afterClosed().subscribe()
    )
  }
}
