<div class="customer-access-level-and-role-access-component">
  <div class="row">
    <div class="col">
      <customer-access-level
        [formControl]="formControlCustomerAccessLevel">
      </customer-access-level>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-checkbox
        color="primary"
        [formControl]="formControlIsAllTransaction">
        All Transactions
      </mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-checkbox
        color="primary"
        [formControl]="formControlIsThirdPartyPayment">
        Third Party Payment
      </mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-checkbox
        color="primary"
        [formControl]="formControlIsBpay">
        BPay
      </mat-checkbox>
    </div>
  </div>
</div>
