import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CurrencyInputValue, GetAllPismoAccountStatusFn, GetPismoAccountStatusReasonsFn, PismoEditAccountDialogData, PismoEditAccountDialogResult, PismoGetAccountStatusReasonsResponse, PismoGetAccountStatusResponse, PismoRollbackAccountStatusFn, PismoUpdateAccountLimitFn, PismoUpdateAccountStatusFn, SlideToggleValue } from '@portal-workspace/grow-shared-library';
import { Subscription, forkJoin, of, tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';


@Component({
  templateUrl: './pismo-edit-account.dialog.html',
  styleUrls: ['./pismo-edit-account.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass
]
})
export class PismoEditAccountDialog implements OnInit {

  pismoUpdateAccount!: PismoUpdateAccountLimitFn
  formGroup: FormGroup<{
    overdraftLimit: FormControl<CurrencyInputValue>,
    statusReason: FormControl<PismoGetAccountStatusReasonsResponse | null>
  }>;
  formControlOverdraftLimit: FormControl<CurrencyInputValue>;
  formControlEnabled: FormControl<SlideToggleValue>;
  formControlStatusReason: FormControl<PismoGetAccountStatusReasonsResponse | null>
  errorMessage: string = '';
  accountStatuses: PismoGetAccountStatusResponse[] = [];
  showStatusReason: boolean = false
  accountStatusReasons: PismoGetAccountStatusReasonsResponse[] = [];
  subscriptions: Subscription[] = [];
  pismoGetAllStatus !: GetAllPismoAccountStatusFn;
  pismoGetAccountStatusReasons!: GetPismoAccountStatusReasonsFn
  pismoUpdateAccountStatus!: PismoUpdateAccountStatusFn;
  pismoRollbackAccountStatus!: PismoRollbackAccountStatusFn;

  accountStatusReasonCompareWithFn = (a: PismoGetAccountStatusReasonsResponse, b: PismoGetAccountStatusReasonsResponse) => {
    return (a && b && a.reason_id == b.reason_id);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: PismoEditAccountDialogData,
    private dialogRef: MatDialogRef<PismoEditAccountDialog, PismoEditAccountDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.pismoUpdateAccount = data.pismoUpdateAccountFn;
    this.pismoGetAllStatus = data.pismoGetAllStatusFn;
    this.pismoGetAccountStatusReasons = data.pismoGetAccountStatusReasonsFn;
    this.pismoUpdateAccountStatus = data.pismoUpdateAccountStatusFn;
    this.pismoRollbackAccountStatus = data.pismoRollbackAccountStatusFn;
    this.formControlOverdraftLimit = formBuilder.control(data.accountDetails.max_credit_limit ? data.accountDetails.max_credit_limit! : 0, [Validators.required]);
    this.formControlEnabled = formBuilder.control(false);
    this.formControlStatusReason = formBuilder.control(null)
    const v: PismoGetAccountStatusReasonsResponse | null = this.data.accountDetails.status_reason_description ? {
      code: '',
      reason_id: this.data.accountDetails.status_reason_id!,
      show_user: false,
      description: this.data.accountDetails.status_reason_description,
      account_status_id: 0
    } : null;
    if (v) {
        this.formControlStatusReason.setValue(v);
    }

    this.formGroup = formBuilder.group({
      overdraftLimit: this.formControlOverdraftLimit,
      statusReason: this.formControlStatusReason
    })

    this.formControlEnabled.valueChanges.pipe(
      tap(r => {
        if (r === true) {
          this.formControlStatusReason.setValue(null)
          this.formControlStatusReason.clearValidators()
          this.formControlStatusReason.updateValueAndValidity()
          this.showStatusReason = false;
        } else {
          this.formControlStatusReason.setValidators([Validators.required])
          this.formControlStatusReason.updateValueAndValidity();
          this.showStatusReason = true;
        }
      })
    ).subscribe();
  }

  ngOnInit() {
    if (this.data.accountDetails.status === 'NORMAL') {
      this.formControlEnabled.setValue(true);
    } else {
      this.formControlEnabled.setValue(false);
    }
    this.onToggleChange(this.formControlEnabled.value)
    this.formControlEnabled.valueChanges.pipe(
      tap(r => {
        this.onToggleChange(r === true);
        // if (r === true) {
        //   this.onToggleChange(r)
        // }
        // else {
        //   this.onToggleChange(r)
        // }
      })).subscribe();
  }

  onToggleChange(value: boolean | null) {
    this.subscriptions.push(
      this.pismoGetAllStatus().pipe(this.toastService.spinnerObservable())
        .subscribe((response: PismoGetAccountStatusResponse[]) => {
          if (response) {
            this.accountStatuses = Array.isArray(response) ? response : [];
            if (!value) {
              const blockedStatuses = this.accountStatuses.filter(item => item.name === 'BLOCKED'); 
              if (blockedStatuses.length > 0) {
                this.subscriptions.push(
                  this.pismoGetAccountStatusReasons(blockedStatuses[0].status_id, blockedStatuses[0].name)
                    .pipe(this.toastService.spinnerObservable())
                    .subscribe((response: PismoGetAccountStatusReasonsResponse[]) => {
                      if (response) {
                        
                        this.accountStatusReasons = response;
                      }
                    })
                );
              }
            } else {
              const normalStatuses = this.accountStatuses.filter(item => item.name === 'NORMAL');
              if (normalStatuses.length > 0) {
                this.subscriptions.push(
                  this.pismoGetAccountStatusReasons(normalStatuses[0].status_id, normalStatuses[0].name)
                    .pipe(this.toastService.spinnerObservable())
                    .subscribe((response: PismoGetAccountStatusReasonsResponse[]) => {
                      if (response) {
                        this.accountStatusReasons = response;
                      }
                    })
                );
              }
            }
          }
        })
    );
  }

  onSave() {
    const limit = this.formControlOverdraftLimit.value!;
    const updateAccountBody = {
      max_credit_limit: limit,
      total_credit_limit: limit,
    };

    const updateAccountStatus = () => {
      let updateAccountStatusBody;
      if (!this.formControlEnabled.value) {
        const blockedStatuses = this.accountStatuses.filter(item => item.name === 'BLOCKED');
        if (blockedStatuses.length > 0) {
          updateAccountStatusBody = {
            status: blockedStatuses[0].name,
            reason_id: this.formControlStatusReason.value?.reason_id ?? null,
            description: this.formControlStatusReason.value?.description ?? null,
            update_children: false
          };
  
          return this.pismoUpdateAccountStatus(this.data.accountDetails.account_id!, updateAccountStatusBody)
            .pipe(this.toastService.spinnerObservable());
        } else {
          console.error('No BLOCKED status found');
          return of(null);
        }
      } else {
        const normalStatuses = this.accountStatuses.filter(item => item.name === 'NORMAL');
        if (normalStatuses.length > 0) {
          updateAccountStatusBody = {
            status: normalStatuses[0].name,
            reason_id: this.accountStatusReasons[0].reason_id,
            description: this.accountStatusReasons[0].description,
            update_children: false
          };
  
          return this.pismoRollbackAccountStatus(this.data.accountDetails.account_id!, updateAccountStatusBody)
            .pipe(this.toastService.spinnerObservable());
        } else {
          console.error('No NORMAL status found');
          return of(null);
        }
      }


    };

    // Combine the observables and execute them sequentially
    const combinedObservables = [
      this.pismoUpdateAccount(this.data.accountDetails.account_id!, updateAccountBody).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Account Details updated'),
      ),
      updateAccountStatus()
    ];

    this.subscriptions.push(
      forkJoin(combinedObservables).subscribe(
        ([updateAccountResponse, updateAccountStatusResponse]) => {
          console.log(updateAccountResponse, 'updateAccountResponse');
          console.log(updateAccountStatusResponse, 'updateAccountStatusResponse');

          if (updateAccountResponse && updateAccountStatusResponse) {
            this.dialogRef.close({
              valid: true,
              updatedDetails: {
                ...this.data.accountDetails,
                ...updateAccountBody,
              }
            });
          } else {
            this.dialogRef.close({
              valid: false
            });
          }
        },
        (error: any) => {
          if (error.message) {
            this.errorMessage = (error as HttpErrorResponse).error ? (error as HttpErrorResponse).error.message.message : 'Error occurred';
          }
          this.dialogRef.close({
            valid: false
          });
        })
    );
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }
}
