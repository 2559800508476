export type BrokerageSelectionType = '0' |
  '0.1' | '0.2' | '0.3' | '0.4' | '0.5' | '0.6' | '0.7' | '0.8' | '0.9' | '1.0' | '1.1' | '1.2' | '1.3' | '1.4' | '1.5' | '1.6' | '1.7' | '1.8' | '1.9' | '2.0' |
  '2.1' | '2.2' | '2.3' | '2.4' | '2.5' | '2.6' | '2.7' | '2.8' | '2.9' | '3.0' | '3.1' | '3.2' | '3.3' | '3.4' | '3.5' | '3.6' | '3.7' | '3.8' | '3.9' | '4.0' |
  '4.1' | '4.2' | '4.3' | '4.4' | '4.5' | '4.6' | '4.7' | '4.8' | '4.9' | '5.0' | '5.1' | '5.2' | '5.3' | '5.4' | '5.5' | '5.6' | '5.7' | '5.8' | '5.9' | '6.0' |
  '6.1' | '6.2' | '6.3' | '6.4' | '6.5' | '6.6' | '6.7' | '6.8' | '6.9' | '7.0' | '7.1' | '7.2' | '7.3' | '7.4' | '7.5' | '7.6' | '7.7' | '7.8' | '7.9' | '8.0'
  ;
export type BrokerageSelectionValue = {
  type: BrokerageSelectionType,
  name: string;
} | null;


export const BrokerageSelectionValueOptions: Exclude<BrokerageSelectionValue, null>[] = [
  {type: '0', name: '0%'},
  {type: '0.1', name: '0.10%'},
  {type: '0.2', name: '0.20%'},
  {type: '0.3', name: '0.30%'},
  {type: '0.4', name: '0.40%'},
  {type: '0.5', name: '0.50%'},
  {type: '0.6', name: '0.60%'},
  {type: '0.7', name: '0.70%'},
  {type: '0.8', name: '0.80%'},
  {type: '0.9', name: '0.90%'},
  {type: '1.0', name: '1.00%'},
  {type: '1.1', name: '1.10%'},
  {type: '1.2', name: '1.20%'},
  {type: '1.3', name: '1.30%'},
  {type: '1.4', name: '1.40%'},
  {type: '1.5', name: '1.50%'},
  {type: '1.6', name: '1.60%'},
  {type: '1.7', name: '1.70%'},
  {type: '1.8', name: '1.80%'},
  {type: '1.9', name: '1.90%'},
  {type: '2.0', name: '2.00%'},
  {type: '2.1', name: '2.10%'},
  {type: '2.2', name: '2.20%'},
  {type: '2.3', name: '2.30%'},
  {type: '2.4', name: '2.40%'},
  {type: '2.5', name: '2.50%'},
  {type: '2.6', name: '2.60%'},
  {type: '2.7', name: '2.70%'},
  {type: '2.8', name: '2.80%'},
  {type: '2.9', name: '2.90%'},
  {type: '3.0', name: '3.00%'},
  {type: '3.1', name: '3.10%'},
  {type: '3.2', name: '3.20%'},
  {type: '3.3', name: '3.30%'},
  {type: '3.4', name: '3.40%'},
  {type: '3.5', name: '3.50%'},
  {type: '3.6', name: '3.60%'},
  {type: '3.7', name: '3.70%'},
  {type: '3.8', name: '3.80%'},
  {type: '3.9', name: '3.90%'},
  {type: '4.0', name: '4.00%'},
  {type: '4.1', name: '4.10%'},
  {type: '4.2', name: '4.20%'},
  {type: '4.3', name: '4.30%'},
  {type: '4.4', name: '4.40%'},
  {type: '4.5', name: '4.50%'},
  {type: '4.6', name: '4.60%'},
  {type: '4.7', name: '4.70%'},
  {type: '4.8', name: '4.80%'},
  {type: '4.9', name: '4.90%'},
  {type: '5.0', name: '5.00%'},
  {type: '5.1', name: '5.10%'},
  {type: '5.2', name: '5.20%'},
  {type: '5.3', name: '5.30%'},
  {type: '5.4', name: '5.40%'},
  {type: '5.5', name: '5.50%'},
  {type: '5.6', name: '5.60%'},
  {type: '5.7', name: '5.70%'},
  {type: '5.8', name: '5.80%'},
  {type: '5.9', name: '5.90%'},
  {type: '6.0', name: '6.00%'},
  {type: '6.1', name: '6.10%'},
  {type: '6.2', name: '6.20%'},
  {type: '6.3', name: '6.30%'},
  {type: '6.4', name: '6.40%'},
  {type: '6.5', name: '6.50%'},
  {type: '6.6', name: '6.60%'},
  {type: '6.7', name: '6.70%'},
  {type: '6.8', name: '6.80%'},
  {type: '6.9', name: '6.90%'},
  {type: '7.0', name: '7.00%'},
  {type: '7.1', name: '7.10%'},
  {type: '7.2', name: '7.20%'},
  {type: '7.3', name: '7.30%'},
  {type: '7.4', name: '7.40%'},
  {type: '7.5', name: '7.50%'},
  {type: '7.6', name: '7.60%'},
  {type: '7.7', name: '7.70%'},
  {type: '7.8', name: '7.80%'},
  {type: '7.9', name: '7.90%'},
  {type: '8.0', name: '8.00%'},
];

export interface EditBrokerageDialogData {
  brokerageAmount: number;
  loanAmount: number;
  maxBrokeragePercentage?: number;
}

export interface EditBrokerageDialogResult {
  readyForSubmission: boolean;
  brokerageAmount: number;
  brokeragePercentage: number;
}