import {Component, Input, OnInit} from '@angular/core';
import {InsurancePremiumApplication} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';



@Component({
    selector: 'policies-details',
    templateUrl: './policies-details.component.html',
    styleUrls: ['./policies-details.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule]
})
export class PoliciesDetailsComponent implements OnInit {

  @Input({required: true}) application!: InsurancePremiumApplication;

  policies: InsurancePremiumApplication['Policies'] = [];

  ngOnInit(): void {
    this.policies = this.application.Policies ?? [];
  }

}
