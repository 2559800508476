
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplicationDialogService, getUser, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { confirmPassword, ConfirmPasswordDialogData, ConfirmPasswordDialogResult, ConfirmPasswordValue, OldPasswordValue, User } from '@portal-workspace/grow-shared-library';
import { navigationUrlForForgotPassword } from 'apps/portal2/src/app/service/navigation-urls';
import { TwoFactorAuthService } from 'apps/portal2/src/app/service/2fa.service';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterLink, UrlTree } from '@angular/router';
import { FlexModule } from '@angular/flex-layout/flex';
import { ConfirmPasswordComponent } from '../confirm-password-component/confirm-password.component';



@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './confirm-password.dialog.html',
    styleUrls: ['./confirm-password.dialog.scss'],
    standalone: true,
    imports: [ConfirmPasswordComponent, FormsModule, ReactiveFormsModule, FlexModule, RouterLink, MatButtonModule, MatDialogModule]
})

export class ConfirmPasswordDialog implements OnInit {


  formGroup: FormGroup<{
    password : FormControl<ConfirmPasswordValue>;
  }>;
  formControlPassword: FormControl<ConfirmPasswordValue>;

  subscription: Subscription;
  user: User | null = null;
  confirmPasswordData: confirmPassword = {};

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPasswordDialogData,
    // private TwoFA: TwoFactorAuthService,
    // private authService: AuthService,
    private matRef: MatDialogRef<ConfirmPasswordDialog, ConfirmPasswordDialogResult>
  ) {
    this.formControlPassword = formBuilder.control('', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z@./#&+-])([a-zA-Z0-9@./#&+-]+).{7,}$/)]);
    this.formGroup = formBuilder.group({
      password: this.formControlPassword,
    });
    this.subscription = combineLatest([
      this.formControlPassword.valueChanges,
    ]).pipe(
      tap(r => {
        this.formControlPassword.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      })
    ).subscribe();
  }

  ngOnInit(): void {
     this.user = getUser();
    setupUntilDestroy(this);
  }
  getData($event: confirmPassword) {
    this.confirmPasswordData = $event
  }

  navigationForForgotPassword() {
    return navigationUrlForForgotPassword();
  }

  changePassword($event: Event) {
    if (this.data) {
      const password = this.confirmPasswordData.oldPassword
      const email = this.user?.Email ?? '';
      this.data.matchConfirmPasswordFn(email,password!).pipe(
        this.toastService.spinnerObservable(),
        tap(r=>{
          if(r.status == true){
            const newPassword = this.confirmPasswordData.confirmPassword;
            if (newPassword) {
              this.submit(newPassword);
            }
            // this.authService.updateUserPasswordFn(this.data.userId, {
            //   password: newPassword as string
            // }).pipe(
            //   this.toastService.retryableMessage({
            //     successMessage: 'Password Changed',
            //     errorMessage: 'Failed to change the password',
            //     retryFn: ()=> {
            //       console.log('**** retry ', this);
            //       this.changePassword($event);
            //     }
            //   }),
            // ).subscribe();
          }
          else{
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: `Please check your password.`
            });
          }
        })
      ).subscribe();
      // this.cancel($event);
    }
  }

  cancel($event: Event) {
    this.matRef.close({
      valid: false,
      newPassword: null,
    });
  }

  submit(newPassword: string) {
    this.matRef.close({
      valid: true,
      newPassword,
    });
  }


}
