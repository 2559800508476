import {Injectable} from '@angular/core';
import {AuditLogContext, AuditLogSubContext, PaginablePayloadApiResponse, PaginationInfo} from '@portal-workspace/grow-shared-library';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {httpOptions, paginationUrl, toPaginationInfo} from '@portal-workspace/grow-ui-library';
import {AuditLog} from '@portal-workspace/grow-shared-library';


const URL_GET_AUDIT_LOGS = (paginationInfo: PaginationInfo, searchFilter: string, subContextFilter: string) => paginationInfo.filter == null ? `${environment.api2Host}/api2/audit-logs?limit=${paginationInfo.limit}&offset=${paginationInfo.offset}&searchFilter=${searchFilter}&subContextFilter=${subContextFilter}` : `${environment.api2Host}/api2/audit-logs?limit=${paginationInfo.limit}&offset=${paginationInfo.offset}&filter=${paginationInfo.filter}&searchFilter=${searchFilter}&subContextFilter=${subContextFilter} `;

@Injectable()
export class AuditService {

  constructor(private httpClient: HttpClient) {
  }


  getAuditLogs<T extends AuditLogContext | 'All Audit Logs'>(opts: {
    page: {limit: number, offset: number},
    filter: T,
  },
  searchFilter: string | null,
  subContextFilter: T extends AuditLogContext ? AuditLogSubContext<T> : string
  ): Observable<PaginablePayloadApiResponse<AuditLog>> {
    return this.httpClient.get<PaginablePayloadApiResponse<AuditLog>>(URL_GET_AUDIT_LOGS(toPaginationInfo(opts),searchFilter!, subContextFilter), httpOptions());
  }

}
