import {AfterViewInit, Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration} from 'chart.js';
import {AppCalculator, PaymentChartData, RepaymentFrequencyType, TermRate} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';
import {ApplicationDialogService} from "../application-dialog-component/application-dialog.service";
import {PercentagePipe} from "../../pipes/percentage.pipe";
import { getCssVariableContent } from '@portal-workspace/grow-ui-library';

@Component({
    selector: 'payment-chart',
    templateUrl: './payment-chart.component.html',
    styleUrls: ['./payment-chart.component.scss'],
    standalone: true,
    imports: [
    GridModule,
    FlexModule,
    MatDividerModule,
    DecimalPipe,
    CurrencyPipe,
    LooseCurrencyPipe,
    PercentagePipe
]
})
export class PaymentChartComponent implements OnInit, AfterViewInit {

  @ViewChild('canvasElement') canvasElementRef!: ElementRef;
  
  static id = 1;
  @Input({required: false}) delay = 0;
  @Input({required: true}) hideBrokerOrigiantionFee: boolean = false;
  @Input({required: true}) hideBalloonPayment: boolean = false;
  @Input({required: true}) hideBrokerage: boolean = false;
  @Input({required: false}) calculationLog?: {log: string, rate?: number}[];
  @Input({required: true}) data: PaymentChartData = {
    totalInterest: 0,
    amountFinanced: 0,
    emiAmt: 0,
    paymentFrequency: 'Monthly',
    principalAmt: 0,
    interestAmt: 0,
    totalAmt: 0,
    loanTerm: 0, // loanValue from calculator
    lvr: 0,
    rv: 0,
    brokerageAmount: 0,
    docFee: 0,
    brokerOriginationFee: 0,
    applicationType:'AssetFinance',
    deposit: 0,
    invoiceAmount: 0,
    displayedInterest: 0,
    monthlyAccountKeepingFee: 0,
    repaymentPlusMonthlyAccountKeepingFee: 0,
  };
  
  public dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
  public dynamoneyGrey = getCssVariableContent('--portal2-sg-blue-grey-lighten-1');
  chart!: Chart;
  chartId: string;

  applicationDialogService: ApplicationDialogService = inject(ApplicationDialogService);

  constructor() {
    this.chartId = `Payment-Chart-${PaymentChartComponent.id++}`;
  }

  ngOnInit(): void {
    this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
    this.dynamoneyGrey = getCssVariableContent('--portal2-sg-blue-grey-lighten-1');
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      const canvasElement: HTMLCanvasElement = this.canvasElementRef.nativeElement;
      const context = canvasElement.getContext('2d');
      // const context = (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d');
      const chartConfig: ChartConfiguration<'doughnut', number[], never> = {
        type: 'doughnut' as const,
        options: {
          cutout: '85%',
          responsive: true,
          maintainAspectRatio: true,
          animation: false,
        },
        data: {
          labels: [],
          datasets: [{
            label: 'Payment',
            data: [this.data.amountFinanced, this.data.interestAmt],
            backgroundColor: [
              this.dynamoneyBlue,//#5D40FF
              this.dynamoneyGrey//'#78909C',
            ],
            hoverOffset: 4,
          }]
        }
      };
      // NOTE: chartConfig casted to any due to issue with typescript & chartjs, see https://github.com/chartjs/Chart.js/issues/10896
      this.chart = new Chart(context!, chartConfig as any);
    }, this.delay);
  }

  onShowRateCalculation() {
    if (this.calculationLog) {
      this.applicationDialogService.openInterestRateCalculationLogDialog({logs: this.calculationLog});
    }
  }
}
