import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy,formControlErrorKeys,formControlErrorMessage, pxToRem} from '@portal-workspace/grow-ui-library';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { Subscription } from 'rxjs';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import {compareMatch, CustomerAngularEditorComponentValue} from '@portal-workspace/grow-shared-library';
import { Editor, Toolbar, NgxEditorModule } from 'ngx-editor';
import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'custom-angular-editor',
    templateUrl: './custom-angular-editor.component.html',
    styleUrls: ['./custom-angular-editor.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomAngularEditorComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CustomAngularEditorComponent) },
    ],
    standalone: true,
    imports: [NgxEditorModule, FormsModule, ReactiveFormsModule,DisableControlDirective]
})

export class CustomAngularEditorComponent extends AbstractControlValueAccessor<CustomerAngularEditorComponentValue> implements OnInit, AfterContentChecked, Mark, OnDestroy {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formControl!: FormControl<CustomerAngularEditorComponentValue>;
  formGroup!: FormGroup<{
    editor: FormControl<CustomerAngularEditorComponentValue>
  }>;
  subscription?: Subscription;

  @Input({required: false}) placeholder: string = "";
  @Input({required: false}) height: number = 80;
  pxToRem = pxToRem;

  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    // [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.editor = new Editor();
    this.formControl = new FormControl(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      editor: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.formGroup.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }


  doWriteValue(v: CustomerAngularEditorComponentValue) {
    this.formControl.setValue(v);
  }


  mark(): void {
    this.formControl.markAllAsTouched();
  }
}
