import { Component, Inject, OnInit } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData, PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { RadioOptionsComponent } from '../radio-options-component/radio-options.component';
import { CurrencyInputComponent } from "../currency-selection-component/currency-input.component";
import { ApplicationDialogService, Mark, PortalHotToastService, doMarkAll, markTriggerSubject } from "@portal-workspace/grow-ui-library";
import { Subject, Subscription } from "rxjs";


@Component({
  templateUrl: './pismo-update-require-fixed-monthly-installment-flag.dialog.html',
  styleUrls: ['./pismo-update-require-fixed-monthly-installment-flag.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    LooseCurrencyPipe,
    RadioOptionsComponent,
    CurrencyInputComponent
]
})
export class PismoUpdateRequireFixedMonthlyInstallmentFlagDialog implements OnInit, Mark {

  formGroup: FormGroup<{
    reason: FormControl<string | null>,
  }>;
  formControlReason!: FormControl<string | null>;

  markTriggerSubject = markTriggerSubject;
  markObservable: Subject<boolean> = new Subject<boolean>();
  showCard: boolean = false;
  subscriptions: Subscription[] = [];

  accountId!: number;
  currentRequireMonthlyFixedInstallment!: boolean;
  requireMonthlyFixedInstallment!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PismoUpdateRequireFixedMonthlyInstallmentFlagDialogData,
    private dialogRef: MatDialogRef<PismoUpdateRequireFixedMonthlyInstallmentFlagDialog, PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.accountId = data.accountId;
    this.currentRequireMonthlyFixedInstallment = data.currentRequireMonthlyFixedInstallment;
    this.requireMonthlyFixedInstallment = !this.currentRequireMonthlyFixedInstallment;
    this.formControlReason = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      reason: this.formControlReason,
    });
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  ngOnInit(): void {
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }

  onSave(event: Event) {
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);
    this.dialogRef.close({
      readyForSubmission: true,
      requireMonthlyFixedInstallment: this.requireMonthlyFixedInstallment,
      reason: this.formControlReason.value ?? '',
      accountId: this.accountId
    })
  }

}
