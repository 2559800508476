import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AssetSupplier, compareMatch, GetInstitutionNameFn, NewSupplierValue, SelectOrNewAssetSupplierValue} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AssetSupplierSelectValue } from '@portal-workspace/grow-shared-library';
import { GetAssetSupplierFn, AssetSupplierSelectComponent } from './asset-supplier-select.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import { NewSupplierComponent } from './new-supplier.component';
import { MatDividerModule } from '@angular/material/divider';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'select-or-new-asset-supplier',
    templateUrl: './select-or-new-asset-supplier.component.html',
    styleUrls: ['./select-or-new-asset-supplier.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectOrNewAssetSupplierComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => SelectOrNewAssetSupplierComponent) },
    ],
    standalone: true,
    imports: [AssetSupplierSelectComponent, MarkDirective, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatDividerModule, NewSupplierComponent]
})
export class SelectOrNewAssetSupplierComponent extends AbstractControlValueAccessor<SelectOrNewAssetSupplierValue> implements OnInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  @Input({required: true}) getAssetSupplierFn!: GetAssetSupplierFn;
  getInstitutionNameFn!: GetInstitutionNameFn;
  
  formGroup: FormGroup<{
    newSupplier: FormControl<NewSupplierValue>,
    existingSupplier: FormControl<AssetSupplierSelectValue>,
  }>;
  formControlAssetSupplier: FormControl<AssetSupplierSelectValue>;
  formControlNewAssetSupplier: FormControl<NewSupplierValue>;
  formControlUseNewSupplier: FormControl<boolean | null>;
  isNewSupplier: boolean = false;
  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlAssetSupplier = formBuilder.control(null, [Validators.required]);
    this.formControlNewAssetSupplier = formBuilder.control(null);
    this.formControlUseNewSupplier = formBuilder.control(false);
    this.formControlAssetSupplier.enable();
    this.formControlNewAssetSupplier.disable();
    this.formGroup = formBuilder.group({
      newSupplier: this.formControlNewAssetSupplier,
      existingSupplier: this.formControlAssetSupplier,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControlUseNewSupplier.valueChanges.pipe(
      tap(useNewSupplier => {
        if (useNewSupplier) {
          this.isNewSupplier = true
          this.formControlNewAssetSupplier.setValidators([Validators.required]);
          this.formControlAssetSupplier.clearValidators();
          this.formControlNewAssetSupplier.enable();
          this.formControlAssetSupplier.disable();
          this.formControlNewAssetSupplier.updateValueAndValidity();
          this.formControlAssetSupplier.updateValueAndValidity();
        } else {
          this.isNewSupplier = false
          this.formControlNewAssetSupplier.clearValidators();
          this.formControlAssetSupplier.setValidators([Validators.required]);
          this.formControlNewAssetSupplier.disable();
          this.formControlAssetSupplier.enable();
          this.formControlNewAssetSupplier.updateValueAndValidity();
          this.formControlAssetSupplier.updateValueAndValidity();
        }
      })
    ).subscribe());
    this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          const useNewSupplier = this.formControlUseNewSupplier.value;
          this.propagateChange({
            type: useNewSupplier ? 'new' : 'existing',
            existingSupplier: useNewSupplier ? null : this.formControlAssetSupplier.value,
            newSupplier: !useNewSupplier ? null : this.formControlNewAssetSupplier.value,
          });
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
  }

  doWriteValue(v: SelectOrNewAssetSupplierValue | undefined): void | SelectOrNewAssetSupplierValue {
    if (v) {
      this.formControlAssetSupplier.setValue(v.existingSupplier);
      this.formControlNewAssetSupplier.setValue(v.newSupplier ?? null);
      this.formControlUseNewSupplier.setValue(v.type! === 'new' ? true : false);
    }
    return undefined;
  }

  mark(): void {
    this.markObservable.next(true);
    this.formGroup.markAsTouched();
  }

}
