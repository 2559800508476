import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {inject} from '@angular/core';
import {getUser} from '@portal-workspace/grow-ui-library';
import {navigationUrlForUnactivatedAccount} from '../service/navigation-urls';


export const activatedAccountGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser();
    if (user) {
      if (user.approval == 0 || user.rejected == 1) {
        return router.navigate(navigationUrlForUnactivatedAccount())
      }
    }
    return true;
  }
}


// NOTE: deprecated since Angular 16
// @Injectable()
// export class ActivatedAccountGuard  {
//
//   constructor(private router: Router) {
//   }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     const user = getUser();
//     if (user) {
//       if (user.approval == 0 || user.rejected == 1) {
//         return this.router.navigateByUrl(navigationUrlForUnactivatedAccount())
//       }
//     }
//     return true;
//   }
//
// }
