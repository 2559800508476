import {Component, OnInit} from '@angular/core';
import {loadingFor} from '@ngneat/loadoff';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {AuditService} from '../../service/audit.service';
import {AuditLog, AuditLogContext, AuditLogSubContext, DEFAULT_LIMIT, DEFAULT_OFFSET} from '@portal-workspace/grow-shared-library';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatOptionModule } from '@angular/material/core';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';


export class InternalDataSource extends DataSource<AuditLog> {

  subject = new BehaviorSubject<AuditLog[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<AuditLog[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(auditLogs: AuditLog[]) {
    this.subject.next(auditLogs);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './audit-logs.page.html',
    styleUrls: ['./audit-logs.page.scss'],
    standalone: true,
    imports: [FlexModule, MatInputModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, CustomPaginatorComponent, AsyncPipe, DatePipe]
})
export class AuditLogsPage implements OnInit {

  subscriptions: Subscription[] = [];

  loader = loadingFor('tableLoading');

  total = 0;
  offset = DEFAULT_OFFSET;
  limit = DEFAULT_LIMIT;
  searchFilter: string = ''
  auditLogContextfilter: AuditLogContext | 'All Audit Logs' = 'All Audit Logs';
  auditLogSubContextFilter: AuditLogSubContext<AuditLogContext> | 'All Audit Logs' | 'All'  = 'All Audit Logs';

  dataSource = new InternalDataSource();
  displayColumns = ['name', 'email', 'context', 'creationDate', 'message'];
  contextTypes: (AuditLogContext | 'All Audit Logs')[] = ['All Audit Logs', 'Broker', 'Auth', 'Salesforce', 'Cron', 'System', 'Application', 'Pismo', 'User', 'Overdraft', 'KycVerification', 'Accreditation'];
  subContextTypes!: (AuditLogSubContext<AuditLogContext> | 'All Audit Logs' | 'All')[];
  formControlSearch: FormControl<string | null>;
  formControlFilter!: FormControl<AuditLogContext | 'All Audit Logs' | null>;
  formControlSubContextFilter!: FormControl<AuditLogSubContext<AuditLogContext> | 'All Audit Logs' | 'All' | null>;

  constructor(
    private auditService: AuditService,
    private formBuilder: FormBuilder
  ) {
    this.formControlFilter = formBuilder.control('All Audit Logs');
    this.formControlSearch = formBuilder.control('');
    this.formControlSubContextFilter = formBuilder.control('All Audit Logs');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reset();
    this.reload();
    this.subContextTypes = this.getSubContextTypes('All Audit Logs');

    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        if (r != null) {
          this.searchFilter = r;
          this.reset();
          this.reload();
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    this.subscriptions.push(
      this.formControlFilter.valueChanges.subscribe((filter) => {
        this.subContextTypes = this.getSubContextTypes(filter!);
        this.formControlSubContextFilter.setValue(this.subContextTypes[0] ?? 'All Audit Logs');
        this.auditLogContextfilter = filter ?? 'All Audit Logs';
        console.log('=====this.filter: ', this.auditLogContextfilter)
        this.limit = this.limit;
        this.offset = DEFAULT_OFFSET;
        this.reload();
      })
    )

    const sub2 = this.formControlSubContextFilter.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        // if (r == null || r == 'All Audit Logs' || !r) {
        //   this.subContextFilter = ''
        //   this.reload();
        // } else {
        //   this.subContextFilter = r
        //   this.reload();
        // }
        // this.subContextFilter = ''
        this.auditLogSubContextFilter = r ?? 'All Audit Logs';
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub2);
  }

  private getSubContextTypes(context: string): (AuditLogSubContext<AuditLogContext> | 'All Audit Logs' | 'All') [] {
    switch (context) {
      case 'Broker':
        return ['ApplicationUpdate'];
      case 'Auth':
        return [
          'All',
          'Login',
          'JWTTokenExpired',
          'JWTTokenInvalid',
          'Logout',
          'InvalidExpoAppId',
          'RefreshJWTToken',
        ];
      case 'Salesforce':
        return ['StageChange', 'Contact'];
      case 'Cron':
        return [
          'All',
          'syncOfac',
          'fetchRbaRate',
          'refreshDocusignToken',
          'syncSuppliers',
          'businessNews',
          'syncDocuments',
          'cronFetchPaymentResponse',
          'cronGenerateBatchPayment',
          'cronChargeAnnualFee',
          'ofacReport'
        ];
      case 'System':
        return ['sendVerificationSMS'];
      case 'Application':
        return ['All','PPSRRegistered', 'NonStandardCondition', 'DeletedDraft', 'RateChange','NewVerimotoInspection'];
      // these categories are in Overdraft
      // case 'Pismo':
      //   return ['PaymentLimit', 'ManualTransaction'];
      case 'User':
        return ['UserDetailsChange'];
      case 'Overdraft':
        return [
          'All',
          'createOverdraftAccount',
          'createOverdraftUser',
          'postPayout',
          'postFacilityEstablishmentFee',
          'postBrokerage',
          'postDocumentationFee',
          'postDocumentationFeeReversal',
          'transferredBalance',
          'createOverdraftUser',
          'manualTransaction',
          'changeAccountStatus',
          'paymentLimit',
          'updateAccountLimit',
          'updateCustomer',
          'changeCardStatus',
          'reissueCard',
          'updateRequireMonthlyFixedInstallmentFlag',
          'sendPayoutEstimation',
          'generatePayout',
          'cardEmbossing',
          'disablePismoCustomer',
          'increaseAccountLimit',
          'manualDirectDebit',
          'updateMonthlyFacilityFee',
          'updateStopDirectDebitFlag',
        ];
      case "KycVerification":
        return ['mobile-verification', 'admin-verification', 'portal-verification'];
      case "Accreditation":
        return ['approveAccreditation', 'declineAccreditation'];
      default:
        return [
          'All Audit Logs',
          'ApplicationUpdate',
          'Login',
          'JWTTokenExpired',
          'JWTTokenInvalid',
          'Logout',
          'InvalidExpoAppId',
          'RefreshJWTToken',
          'StageChange',
          'syncOfac',
          'fetchRbaRate',
          'refreshDocusignToken',
          'syncSuppliers',
          'businessNews',
          'syncDocuments',
          'cronFetchPaymentResponse',
          'cronGenerateBatchPayment',
          'cronChargeAnnualFee',
          'ofacReport',
          'sendVerificationSMS',
          'PPSRRegistered',
          'NonStandardCondition',
          'NewVerimotoInspection',
          'paymentLimit',
          'UserDetailsChange',
          'DeletedDraft',
          'RateChange',
          'manualTransaction',
          'updateRequireMonthlyFixedInstallmentFlag',
          'sendPayoutEstimation',
          'generatePayout',
          'cardEmbossing',
          'disablePismoCustomer',
          'approveAccreditation', 
          'declineAccreditation',
        ];
    }
  }


  private reload() {
    this.subscriptions.push(this.auditService.getAuditLogs(
      {
        page: {
          limit: this.limit, offset: this.offset,
        },
        filter: this.auditLogContextfilter
      },
      this.searchFilter,
      this.auditLogSubContextFilter!).pipe(
      this.loader.tableLoading.track(),
      tap(r => {
        this.total = r.total;
        this.dataSource.update(r.payload);
      })
    ).subscribe());
  }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }
}
