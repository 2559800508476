import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {BpayComponentValue, BpayDialogData, BpayDialogResult, GetBillerNameFn, LocalBankAccountDetailValue, NewBPayPaymentBody, NewDirectPaymentBody} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { BpayBankDetailsComponent } from '../bpay-bank-details-component/bpay-bank-details.component';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './bpay.dialog.html',
    styleUrls: ['./bpay.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, BpayBankDetailsComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class BpayDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    bpay: FormControl<BpayComponentValue | null>,
    reference: FormControl<string | null>,
  }>;
  formControlBpay!: FormControl<BpayComponentValue | null>;
  formControlReference!: FormControl<string | null>;
  formControlAmount!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  getBillerNameFn!: GetBillerNameFn;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: BpayDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<BpayDialog, BpayDialogResult>) {
    this.getBillerNameFn = dialogData.getBillerNameFn;
    this.formControlBpay = formBuilder.control(dialogData ? {
      BillerCode: dialogData.data.billerCode ?? '',
      BillerName: '',
      CRN: dialogData.data.crn ?? '',
      Amount: dialogData.data.amount,
    } : null, [Validators.required]);
    this.formControlReference = formBuilder.control(dialogData ? dialogData.data.reference : null, [Validators.required, Validators.maxLength(25)]);
    this.formGroup = this.formBuilder.group({
      bpay : this.formControlBpay,
      reference: this.formControlReference,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  get dialogResult(): NewBPayPaymentBody {
    return {
      id: 0,
      type: 'bpay',
      date: moment(Date.now()).format('DDMMYYYY'),
      billerCode: this.formControlBpay.value?.BillerCode ?? '',
      accountTitle: this.formControlBpay.value?.BillerName ?? '',
      crn: this.formControlBpay.value?.CRN ?? '',
      amount: this.formControlBpay.value?.Amount ?? 0,
      reference: `${this.formControlReference.value ?? ''}-${this.dialogData.data.id}`,
      userId: this.dialogData.userId,
      customerId: this.dialogData.data.PismoCustomerId,
      PismoAccountId: this.dialogData.data.PismoAccountId,
      PismoCustomerId: this.dialogData.data.PismoCustomerId,
    }
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        result: this.dialogResult
      })
    }

  }

  cancel() {
    this.dialogRef.close({
      readyForSubmission: false,
      result: this.dialogResult
    });
  }
}
