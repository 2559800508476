import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularMaterialLibraryModule} from '../../lib/angular-material/angular-material-library.module';
import {TruncatePipe} from './truncate.pipe';
import {InversePipe} from './inverse.pipe';
import {LooseCurrencyPipe} from './loose-currency.pipe';
import {SanitizeHtmlPipe} from './sanitize-html.pipe';
import {AddressSplitPipe} from './address-split.pipe';
import {LooseDatePipe} from './loose-date.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { AbbreviateNumberPipe } from './abbreviateNumber.pipe';
import { AccessLevelPipe } from './access-level.pipe';
import { SignerRolePipe } from './signer-role.pipe';
import {AddressPipe} from "./address.pipe";
import {PercentagePipe} from "./percentage.pipe";

const pipes = [
  TruncatePipe,
  InversePipe,
  LooseCurrencyPipe,
  SanitizeHtmlPipe,
  AddressSplitPipe,
  LooseDatePipe,
  EllipsisPipe,
  AbbreviateNumberPipe,
  AccessLevelPipe,
  SignerRolePipe,
  AddressPipe,
  PercentagePipe,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        ...pipes,
    ],
    exports: [
        ...pipes,
    ],
    // NOTE: not needed anymore
    // providers: [
    //   CurrencyPipe,
    // ]
})
export class PipeLibraryModule {

}
