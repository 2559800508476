import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EntityTypeComponent} from './entity-type.component';
import {CustomerEntityTypeComponent} from './customer-entity-type.component';
import {OriginatorTypeComponent} from './originator-type.component';
import {OriginatorRelationshipComponent} from './originator-relationship.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        EntityTypeComponent,
        CustomerEntityTypeComponent,
        OriginatorTypeComponent,
        OriginatorRelationshipComponent
    ],
    exports: [
        EntityTypeComponent,
        CustomerEntityTypeComponent,
        OriginatorTypeComponent,
        OriginatorRelationshipComponent,
    ]
})
export class EntityTypeModule {

}
