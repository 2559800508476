<div class="asset-rate-card-component">

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <asset-tier1 [title]="tier1AssetTitle" [maxYear]="15"  [mark]="triggerMark" [formControl]="formControlTier1Asset"></asset-tier1>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <asset-tier2 [title]="tier2AssetTitle" [maxYear]="25"  [mark]="triggerMark" [formControl]="formControlTier2Asset"></asset-tier2>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-lg-6 col-md-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-md-12">
      <asset-tier3 [title]="tier3AssetTitle"  [mark]="triggerMark" [maxYear]="15" [formControl]="formControlTier3Asset"></asset-tier3>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <asset-tier4 [title]="tier4AssetTitle"  [mark]="triggerMark" [formControl]="formControlTier4Asset"></asset-tier4>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mat-h3">Cap Rate</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>Cap Rate</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlCapRate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlCapRate); track errorKey) {
          <mat-error>
            @if (formControlCapRate.touched && formControlCapRate.hasError(errorKey)) {
              {{errorMessage(formControlCapRate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mat-h3">12T Trucks</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>Truck (2T) Rate</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlTruck12T" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlTruck12T); track errorKey) {
          <mat-error>
            @if (formControlTruck12T.touched && formControlTruck12T.hasError(errorKey)) {
              {{errorMessage(formControlTruck12T, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12"></div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mat-h3">No Asset Backed Rate</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>No Asset Backed Rate</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlNoAssetBacked" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNoAssetBacked); track errorKey) {
          <mat-error>
            @if (formControlNoAssetBacked.touched && formControlNoAssetBacked.hasError(errorKey)) {
              {{errorMessage(formControlNoAssetBacked, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12"></div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mat-h3">Transaction Type Rate</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 separator-bottom">
      <transaction-type-rate [mark]="triggerMark" [formControl]="formControlTransactionTypeRate"></transaction-type-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>


  <!--
  <div class="row">
    <div class="col-6">
      <div class="mat-h3">Private sale</div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <mat-form-field>
        <mat-label>Private sale</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlPrivateSale">
        <span class="mdi mdi-percent" matSuffix></span>
        <mat-error *ngFor="let errorKey of errorKeys(formControlPrivateSale)">
          <ng-container *ngIf="formControlPrivateSale.touched && formControlPrivateSale.hasError(errorKey)">
            {{errorMessage(formControlPrivateSale, errorKey)}}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-3">
      <currency-input title="Doc fee" [mark]="triggerMark"  title="Doc fee" [formControl]="formControlPrivateSaleDocFee"></currency-input>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>
  -->


  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="mat-h3">Risk Profile</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Adverse on file</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlAdverseOnFile" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAdverseOnFile); track errorKey) {
          <mat-error>
            @if (formControlAdverseOnFile.touched && formControlAdverseOnFile.hasError(errorKey)) {
              {{errorMessage(formControlAdverseOnFile, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Low equifax score</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlLowEquifax"  required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlLowEquifax); track errorKey) {
          <mat-error>
            @if (formControlLowEquifax.touched && formControlLowEquifax.hasError(errorKey)) {
              {{errorMessage(formControlLowEquifax, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Not asset backed low deposit</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlLowDepositUplift" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlLowDepositUplift); track errorKey) {
          <mat-error>
            @if (formControlLowDepositUplift.touched && formControlLowDepositUplift.hasError(errorKey)) {
              {{errorMessage(formControlLowDepositUplift, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>




  <div class="row separator-bottom">
    <div class="col-12">
      <min-max-rate [mark]="triggerMark"  title="ABN Age (months)" [formControl]="formControlGstAge"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>




  <div class="row separator-bottom">
    <div class="col-12">
      <min-max-rate [mark]="triggerMark"  title="Doc Fee" [type]="'currency'"  [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>



  <div class="row separator-bottom">
    <div class="col-12">
      <min-max-rate [mark]="triggerMark"  title="Brokerage" [formControl]="formControlBrokerage"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan amount" [formControl]="formControlLoanAmount"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate [mark]="triggerMark" formControlErrorsIgnore title="Loan Terms" [formControl]="formControlLoanTerms"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator-bottom">
    <div class="col-12">
      <asset-category-rate [mark]="triggerMark" title="Asset Category"  [formControl]="formControlAssetCategpry"></asset-category-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>



  <div class="row separator-bottom">
    <div class="col-12">
      <asset-type-rate [mark]="triggerMark" title="Asset Type"  [formControl]="formControlAssetType"></asset-type-rate>
    </div>
  </div>

</div>
