import {Component, Input, OnInit} from '@angular/core';
import { Accreditation, NatureOfBusinessTypes, NatureOfBusinessValue } from '@portal-workspace/grow-shared-library';
import { DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';

// @UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'accreditation-driving-licence',
    templateUrl: './accreditation-driving-licence.component.html',
    styleUrls: ['./accreditation-driving-licence.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule, DatePipe, EllipsisPipe]
})
export class AccreditationDrivingLicenceComponent implements OnInit {

  @Input({required: true}) accreditation!: Accreditation;

  ngOnInit(){

  }

  isBroker(): boolean {
    const natureOfBusiness: NatureOfBusinessTypes = this.accreditation.natureofBusiness;
    return (natureOfBusiness === 'broker');
  }

  isVendor(): boolean {
    const natureOfBusiness: NatureOfBusinessTypes = this.accreditation.natureofBusiness;
    return (natureOfBusiness === 'vendor');
  }

  isSupplier(): boolean {
    const natureOfBusiness: NatureOfBusinessTypes = this.accreditation.natureofBusiness;
    return (natureOfBusiness === 'supplier');
  }

  getAddress(){
    return JSON.parse(this.accreditation.businessAddress).UnformattedAddress;
  }
}
