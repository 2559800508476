import {Component, inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import { Sort, MatSortModule } from '@angular/material/sort';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  PaginatorData,
  SearchPismoAccountMapping
} from "@portal-workspace/grow-shared-library";
import {CustomPaginatorComponent, LooseDatePipe, setupUntilDestroy,CustomContentLoaderComponent} from "@portal-workspace/grow-ui-library";
import {OverdraftCustomerService} from "../../service/overdraft-customer.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {debounceTime, distinctUntilChanged, tap} from "rxjs";
import {loadingFor} from "@ngneat/loadoff";

import { Router } from '@angular/router';
import {navigationUrlForPismoAccount, navigationUrlForPismoAccounts} from "../../service/navigation-urls";
import {MatInputModule} from "@angular/material/input";
import { MatFormFieldModule } from '@angular/material/form-field';

export class PismoAccountsPageDataSource implements DataSource<SearchPismoAccountMapping> {
    subject = new BehaviorSubject<SearchPismoAccountMapping[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<readonly SearchPismoAccountMapping[]> {
      return this.subject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
      this.subject.complete();
    }

    update(data: SearchPismoAccountMapping[]) {
      this.subject.next(data);
    }
}


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './pismo-accounts.page.html',
    styleUrls: ['./pismo-accounts.page.scss'],
    standalone: true,
  imports: [
    FlexModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    JsonPipe,
    AsyncPipe,
    MatTableModule,
    LooseDatePipe,
    CustomPaginatorComponent,
    MatSortModule,
    CustomContentLoaderComponent,
    MatFormFieldModule,
    MatInputModule
]
})
export class PismoAccountsPage implements OnInit {

    total: number = 0;
    limit: number = DEFAULT_LIMIT;
    offset: number = DEFAULT_OFFSET;

    loader = loadingFor('loadingAccounts');

    displayedColumns = ['pismoAccountNumber', 'EntityName', 'programName', 'creationDate'];
    subscriptions: Subscription[] = [];

    datasource = new PismoAccountsPageDataSource();
    overdraftUserService: OverdraftCustomerService;
    router: Router;
    formBuilder: FormBuilder;
    formControlSearch: FormControl<string | null>;



    sort?: { prop: '', dir: 'ASC' | 'DESC' };

    constructor() {
      this.formBuilder = inject(FormBuilder);
      this.overdraftUserService = inject(OverdraftCustomerService);
      this.router = inject(Router);
      this.formControlSearch = this.formBuilder.control(null);
    }

    ngOnInit() {
      setupUntilDestroy(this);
      this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(r => {
          this.reload();
        })
      ).subscribe());
      this.reload();
    }

    reload() {
      this.subscriptions.push(this.overdraftUserService.searchPismoAccount({
        page: {
          offset: this.offset, limit: this.limit
        },
        filter: this.formControlSearch.value ?? '',
        sorts: this.sort ? [this.sort] : undefined
      }).pipe(
        this.loader.loadingAccounts.track(),
        tap(r => {
          this.total = r.total;
          this.datasource.update(r.payload);
        })
      ).subscribe());
    }

    onSortData($event: Sort) {
      console.log('****** sort ', $event);
      if ($event.direction) {
        const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
        let prop = 'pismoAccountNumber';
        switch ($event.active) {
          case 'pismoAccountNumber':
            prop = 'pismoAccountNumber';
            break;
          case 'EntityName':
            prop = 'EntityName';
            break;
          case 'programName':
            prop = 'programName';
            break;
          case 'creationDate':
            prop = 'creationDate';
            break;
        }
        this.limit = DEFAULT_LIMIT;
        this.offset = DEFAULT_OFFSET;
        this.sort = {
          prop: prop as any, dir
        };
        this.reload();
      } else {
        this.sort = undefined;
        this.reload();
      }
    }


  onPagination($event: PaginatorData) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.reload();
  }

  async onRowClick(row: SearchPismoAccountMapping) {
    await this.router.navigate(navigationUrlForPismoAccount(row.pismoAccountNumber))
  }
}
