<div class="identity-verification-link-dialog">
  <mat-dialog-content>
    <div class="row pt-4 mb-4">
      <div class="col">
        <h3 class="subheading-2">ID Verify Link</h3>
        <span>
          ID verification helps us verify the identity of an individual using device camera and identity documentation. Copy this link or select Send to email request the individual to complete the verification process that takes less than 2 minutes to complete.
        </span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <div class="row width-100">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Copy ID Verify Link</mat-label>
            <input matInput type="text" readonly [formControl]="formControlLink">
            <span class="mdi mdi-content-copy cursor-pointer" matSuffix [cdkCopyToClipboard]="formControlLink.value ?? ''"></span>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
        <button color="primary" (click)="onCancel()" mat-stroked-button>Close</button>
        <button
          color="primary"
          [cdkCopyToClipboard]="formControlLink.value ?? ''"
          (click)="onSubmit()"
          mat-flat-button>Send</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
