import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Application, AssetFinanceApplication, AssetSpec } from '@portal-workspace/grow-shared-library';
// import { GetRiskAnalysisDataFn } from '../application-details-component/application-details.module';
// import {tap} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {RiskRatingInterface} from '@portal-workspace/grow-shared-library';
import { RiskRatingTableComponent } from './risk-rating-table-component/risk-rating-table.component';
import { RiskRatingChartComponent } from './risk-rating-chart-component/risk-rating-chart.component';


export class GrowRiskRating {
  _riskRatingNumber: number;

  constructor(riskRatingNumber: number){
    this._riskRatingNumber = riskRatingNumber;
  }

  public GetImage() : string {
    if (this._riskRatingNumber > 850) return "/assets/images/chart-very-low.svg";
    if (this._riskRatingNumber >= 701 && this._riskRatingNumber <= 850) return "/assets/images/chart-low.svg";
    if (this._riskRatingNumber >= 501 && this._riskRatingNumber <= 700) return "/assets/images/chart-moderate.svg";
    if (this._riskRatingNumber >= 451 && this._riskRatingNumber <= 500) return "/assets/images/chart-high.svg";
    return "/assets/images/chart-very-high.svg"; // if riskRatingNumber <= 450
  }

  public GetLabel() : string {
    if (this._riskRatingNumber > 850) return "Very Low";
    if (this._riskRatingNumber >= 701 && this._riskRatingNumber <= 850) return "Low";
    if (this._riskRatingNumber >= 501 && this._riskRatingNumber <= 700) return "Moderate";
    if (this._riskRatingNumber >= 451 && this._riskRatingNumber <= 500) return "High";
    return "Very High"; // if riskRatingNumber <= 450
  }

}

@UntilDestroy()
@Component({
    selector: 'risk-analysis',
    templateUrl: './risk-analysis.component.html',
    styleUrls: ['./risk-analysis.component.scss'],
    providers: [],
    standalone: true,
    imports: [RiskRatingChartComponent, RiskRatingTableComponent]
})
export class RiskAnalysisComponent implements OnInit  {

  @Input({required: true}) application!: Application;
  subscriptions: Subscription[] = [];

  hasRating = false;
  ratingNumber: number | null = null;
  ratingData?: RiskRatingInterface | null = null;
  constructor() {
  }

  ngOnInit(): void {
    this.hasRating = (!!this.application.GrowRiskRatingData &&
      this.application.GrowRiskRatingData.growRiskRating !== null &&
      this.application.GrowRiskRatingData.growRiskRating !== undefined);
    if (this.hasRating) {
      this.ratingNumber = this.application.GrowRiskRatingData!.growRiskRating;
      this.ratingData = this.application.GrowRiskRatingData;
    }
    console.log('Risk-analysis', this.application.GrowRiskRatingData);
  }
}
