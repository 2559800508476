<div class="whitelabel-settings-page">
  <div class="row separator-bottom">
    <div class="col-12">
      <div class="mat-h3">Whitelabel Settings</div>
      <div class="mat-body-1">
        Whitelabel related settings
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <input matInput type="text" [readonly]="loader.tableLoading.inProgress$ | async" [formControl]="formControlSearch" placeholder="Search ...">
        <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12">
      <div
        fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center center">
          <button mat-flat-button (click)="onAdd()">Add</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      @if (loader.tableLoading.inProgress$ | async) {
        <custom-content-loader type="list"></custom-content-loader>
      }
      <div [ngClass]="{hidden:(loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
        <table mat-table [dataSource]="datasource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="id">id</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="onRowClicked($event, element)">{{element.id}}</td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="title">title</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer" (click)="onRowClicked($event, element)">{{element.title}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="top-menu-component-menu">
                <button class="menu-items" mat-menu-item (click)="onEdit(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>Edit</div>
                  </div>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
        @if (total) {
          <app-custom-paginator
            [length]="total"
            [pageIndex]="offset"
            [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]"
            (page)="onPagination($event)"
          ></app-custom-paginator>
        }
      </div>
    </div>
  </div>
</div>
