import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {compareMatch, NotNullable, UserSelectionValue} from '@portal-workspace/grow-shared-library';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';


export type GetUsersFunc = (brokerSalesforceId: string) => Observable<NotNullable<UserSelectionValue>[]>;

export interface UserSelectionComponentEvent {
  value: UserSelectionValue;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'user-selection',
    templateUrl: './user-selection.component.html',
    styleUrls: ['./user-selection.component.scss'],
    providers: [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UserSelectionComponent), multi: true },
      { provide: MARK, useExisting: forwardRef(() => UserSelectionComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class UserSelectionComponent extends AbstractControlValueAccessor<UserSelectionValue> implements OnInit, Mark {

  @Input({required: true}) getFunc!: GetUsersFunc;
  @Input({required: true}) userId!: number;   // current userId
  @Input({required: false}) companySalesforceId: string | null | undefined = null;  // company salesforce id, to find matching users
  @Input({required: false}) title: string | null = null;
  // @Input({required: false}) disabled: boolean = false;
  @Input({required: false}) isMandatory: boolean = true;
  // @Input({required: false}) showThisField: boolean = true;
  @Output() events: EventEmitter<UserSelectionComponentEvent> = new EventEmitter<UserSelectionComponentEvent>();


  formGroup!: FormGroup<{
    user: FormControl<UserSelectionValue>;
  }>;
  formControl!: FormControl<UserSelectionValue>;

  compareWith: (a: UserSelectionValue, b: UserSelectionValue) => boolean = (a, b) => {
    return (!!a && !!b && a?.UserId === b?.UserId);
  }

  // @Input({required: false}) otherUsers?: UserSelectionValue[];
  users: NotNullable<UserSelectionValue>[] = [];

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  ngOnInit(): void {
    console.log('************ init user-selection user Id', this.userId);
    this.formControl = this.formBuilder.control(null, this.isMandatory ? [Validators.required] : null);
    this.formGroup = this.formBuilder.group({
      user: this.formControl
    });
    if (this.disabled) {
      this.formControl.disable();
    }
    // if(this.otherUsers){
    //   this.users = this.otherUsers as NotNullable<UserSelectionValue>[]
    // }
    // else{
    if (this.companySalesforceId) {
      this.subscriptions.push(this.getFunc(this.companySalesforceId).pipe(
        tap(r => {
          this.users = r;
        })
      ).subscribe());
    }
    // }
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(this.formControl.value);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
    this.doWriteValue({UserId: this.userId, Name: '', Email: '', sfContactId: ''});
  }

  doWriteValue(v: UserSelectionValue | undefined): void | UserSelectionValue {
    if (v) {
      // if(v.Name && v.Name !== ''){
      //   this.users.push(v)
      //   this.formControl.setValue(v)
      //   console.log("Setting Value==="+v.Name)
      //   console.log(this.formControl.value)
      // }
      // else{
      if (this.companySalesforceId) {
        this.subscriptions.push(this.getFunc(this.companySalesforceId).pipe(
          tap(r => {
            const users = r;
            const u = users.find(u => u.UserId == v.UserId);
            if (u) {
              this.formControl.setValue(u);
            }
          })
        ).subscribe());
      }
    }
    // }
    return undefined;
  }



  onSelectionChanged($event: MatSelectChange) {
    const v: UserSelectionValue = $event.value;
    if (v) {
      this.events.next({ value: v});
    }
  }
}

