import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {Application, CreditFlowDetails, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';


@Injectable()
export class CreditFlowResolver  {

  constructor(private applicationService: ApplicationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CreditFlowDetails | null> | Promise<CreditFlowDetails | null> | CreditFlowDetails | null {
    const creditFlowId = route.params.creditFlowId;
    if (creditFlowId) {
      return this.applicationService.getCreditFlowByIdFn(creditFlowId).pipe(
        map(r => {
          console.log('************ RESOLVE ', creditFlowId, r);
          return r;
        }),
        catchError((err) => {
          console.log(`error resolving application with id ${creditFlowId}`, err);
          return of(null);
        })
      );
    }
    return null;
  }

}
