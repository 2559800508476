import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';



@Component({
    templateUrl: './application-error.page.html',
    styleUrls: ['./application-error.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent]
})
export class ApplicationErrorPage {

  constructor(public activatedRoute: ActivatedRoute) {
  }

}
