import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { concatMap, map, of } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ApplicationService } from '../service/application.service'
import { navigationUrlForApplications } from '../service/navigation-urls';
import {getUser, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {Priviledges} from '@portal-workspace/grow-shared-library';


export const applicationAccessGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {

    const applicationService = inject(ApplicationService);
    const authService = inject(AuthService);
    const portalHotToastService = inject(PortalHotToastService);
    const router = inject(Router);

    const allowedUserPrivileges: Priviledges = ['admin', 'analyst', 'internalbroker','operations', 'operations24', 'credit', 'settlement','salesBDM' , 'salesAM']
    const applicationId = route.params.applicationId
    const currentUser = getUser();

    if (!currentUser) {
      return false;
    }

    if (currentUser.priviledges.some(r => allowedUserPrivileges.includes(r))) {
      // console.log("Admin/Analyst/InternalBroker is allowed to access all applications")
      return true
    }
    else {
      return applicationService.getApplicationById(applicationId).pipe(
        concatMap(application => {
          const app = application.payload;
          const applicationUserId = application.payload.UserId
          if (currentUser?.BrokerSalesforceId === app.AppInfoBrokerSalesforceID) {
            // console.log("Same Broker Created the application")
            return of(true)
          }
          else if (currentUser.priviledges.includes('companyadmin') || currentUser.priviledges.includes('companyoperator')) {
            return authService.getOriginatorSalesforceIdsForUser(currentUser.UserId).pipe(
              map(userOriginatorSalesforceIds => {
                if (userOriginatorSalesforceIds.payload.includes(app.AppInfoBrokerSalesforceID ?? '')) {
                  // console.log("User is the company admin of same company")
                  return true
                }
                // console.log("Access Not allowed: User is admin of some else company")
                portalHotToastService.info(`Insufficient management access to application ${applicationId}`);
                return router.navigate(navigationUrlForApplications())
              }))
          }
          // console.log("The user does not have access to this application")
          portalHotToastService.info(`Insufficient access to application ${applicationId}`);
          return of(router.navigate(navigationUrlForApplications()))
        })
      )
    }
  }
}





// NOTE: Depreacated since Angular 16
// @UntilDestroy({ arrayName: 'subscription' })
// @Injectable()
// export class ApplicationAccessGuard  {
//
//   subscriptions: Subscription[] = [];
//
//   applicationUserId?: number;
//   result = false;
//
//   constructor(private authService: AuthService,
//               private portalHotToastService: PortalHotToastService,
//               private applicationService: ApplicationService,
//               private router: Router) { }
//
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//
//     const allowedUserPrivileges: Priviledges = ['admin', 'analyst', 'internalbroker']
//     const applicationId = route.params.applicationId
//     const currentUser = getUser();
//
//     if (!currentUser) {
//       return false;
//     }
//
//     if (currentUser.priviledges.some(r => allowedUserPrivileges.includes(r))) {
//       // console.log("Admin/Analyst/InternalBroker is allowed to access all applications")
//       return true
//     }
//     else {
//       return this.applicationService.getApplicationById(applicationId).pipe(
//         concatMap(application => {
//           const app = application.payload;
//           this.applicationUserId = application.payload.UserId
//           if (currentUser?.BrokerSalesforceId === app.AppInfoBrokerSalesforceID) {
//             // console.log("Same Broker Created the application")
//             return of(true)
//           }
//           else if (currentUser.priviledges.includes('companyadmin') || currentUser.priviledges.includes('companyoperator')) {
//             return this.authService.getOriginatorSalesforceIdsForUser(currentUser.UserId).pipe(
//               map(userOriginatorSalesforceIds => {
//                 if (userOriginatorSalesforceIds.payload.includes(app.AppInfoBrokerSalesforceID ?? '')) {
//                   // console.log("User is the company admin of same company")
//                   return true
//                 }
//                 // console.log("Access Not allowed: User is admin of some else company")
//                 this.portalHotToastService.info(`Insufficient management access to application ${applicationId}`);
//                 return this.router.createUrlTree(navigationUrlForApplications())
//               }))
//           }
//           // console.log("The user does not have access to this application")
//           this.portalHotToastService.info(`Insufficient access to application ${applicationId}`);
//           return of(this.router.createUrlTree(navigationUrlForApplications()))
//         })
//       )
//     }
//   }
//
// }
