import {AfterViewInit, Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration} from 'chart.js';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';
import {ApplicationDialogService} from "../application-dialog-component/application-dialog.service";
import {PercentagePipe} from "../../pipes/percentage.pipe";

@Component({
    selector: 'commission-chart',
    templateUrl: './commission-chart.component.html',
    styleUrls: ['./commission-chart.component.scss'],
    standalone: true,
    imports: [
    GridModule,
    FlexModule,
    MatDividerModule,
    DecimalPipe,
    CurrencyPipe,
    LooseCurrencyPipe,
    PercentagePipe
]
})
export class CommissionChartComponent implements OnInit, AfterViewInit {

  @ViewChild('canvasElement') canvasElementRef!: ElementRef;

  static id = 1;

  @Input({required: false}) delay = 0;
  @Input({required: true}) amountFinanced: number = 0;
  @Input({required: true}) interestAmt: number = 0;
  @Input({required: true}) commission: number = 0;
  @Input({required: true}) repayment: number = 0;
  @Input({required: true}) creditAssitanceFee: number = 0;
  @Input({required: true}) totalEarned: number = 0;
  @Input({required: true}) totalEarnedInclGst: number = 0;
  @Input({required: true}) comparisonRate: number = 0;
  @Input({required: true}) paymentFrequency: string = '';
  @Input({required: true}) interestRate: number = 0;
  @Input({required: true}) totalPayment: number = 0;
  @Input({required: true}) loanTerms: number = 0;
  @Input({required: true}) invoiceAmt: number = 0;
  @Input({required: true}) deposit: number = 0;
  @Input({required: true}) residual: number = 0;
  @Input({required: true}) docFee: number = 0;
  @Input({required: true}) monthlyAccountKeepingFee: number = 0;
  @Input({required: true}) repaymentPlusMonthlyAccountKeepingFee: number = 0;


  chart!: Chart;
  // chartId: string;

  applicationDialogService: ApplicationDialogService = inject(ApplicationDialogService);

  constructor() {
    // this.chartId = `Commission-Chart-${CommissionChartComponent.id++}`;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // setTimeout(()=> {
    //   const canvasElement: HTMLCanvasElement = this.canvasElementRef.nativeElement;
    //   const context = canvasElement.getContext('2d');
    //   // const context = (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d');
    //   const chartConfig: ChartConfiguration<'doughnut', number[], never> = {
    //     type: 'doughnut' as const,
    //     options: {
    //       cutout: '85%',
    //       responsive: true,
    //       maintainAspectRatio: true,
    //       animation: false,
    //     },
    //     data: {
    //       labels: [],
    //       datasets: [{
    //         label: 'Payment',
    //         data: [this.amountFinanced, this.interestAmt],
    //         backgroundColor: [
    //           '#5D40FF',
    //           '#78909C',
    //         ],
    //         hoverOffset: 4,
    //       }]
    //     }
    //   };
    //   // NOTE: chartConfig casted to any due to issue with typescript & chartjs, see https://github.com/chartjs/Chart.js/issues/10896
    //   this.chart = new Chart(context!, chartConfig as any);
    // }, this.delay);
  }

  // onShowRateCalculation() {
  //   if (this.calculationLog) {
  //     this.applicationDialogService.openInterestRateCalculationLogDialog({logs: this.calculationLog});
  //   }
  // }
}
