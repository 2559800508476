<div class="web-service-json-dialog">
  <mat-dialog-content class="max-height-100-vh">
    <div class="row">
      <div class="col-12">
        <h3 class="header">{{header}}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ngx-json-viewer [json]="json" [expanded]="false"></ngx-json-viewer>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel()">Close</button>
        <button mat-flat-button color="primary" [cdkCopyToClipboard]="jsonString" (click)="onCopy()">Copy to Clipboard</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
