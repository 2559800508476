import {LoanTermValue} from '../component/loan-terms-selection-component.domain';

export const CommercialLoanTermsValueOptions: Exclude<LoanTermValue, null>[] = [
  {type: '24', name: '24 Months'},
  {type: '36', name: '36 Months'},
  {type: '48', name: '48 Months'},
  {type: '60', name: '68 Months'},
  {type: '72', name: '72 Months'},
  {type: '84', name: '84 Months'},
];
