<div class="employer-component">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Employer</mat-label>
          <input matInput type="text" [formControl]="formControlCurrentEmployer" >
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Employer contact</mat-label>
          <input matInput [inputMask]="createPhoneNumberInputMask" required type="text" [formControl]="formControlCurrentEmployerContact">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-form-field>
          <mat-label>Years at current employment</mat-label>
          <input type="number" min="0" max="99" matInput [formControl]="formControlYearsAtCurrentEmployer">
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Months at current employment</mat-label>
          <mat-select [formControl]="formControlMonthsAtCurrentEmployer">
            @for (monthsInCurrentEmployerOption of monthsAtCurrentEmployerOptions; track monthsInCurrentEmployerOption) {
              <mat-option  [value]="monthsInCurrentEmployerOption">
                {{monthsInCurrentEmployerOption}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    @if (requirePreviousEmployerInfo) {
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Previous employer</mat-label>
            <input type="text" matInput [formControl]="formControlPreviousEmployer">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Previous employer contact</mat-label>
            <input type="text" matInput [inputMask]="createPhoneNumberInputMask" [formControl]="formControlPreviousEmployerContact">
          </mat-form-field>
        </div>
      </div>
    }
  </form>
</div>
