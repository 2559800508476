<div class="pismo-update-require-fixed-monthly-installment-flag-dialog m-4">
  <h4 mat-dialog-title>Update Require Fixed Monthly Installment Flag</h4>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        @if (requireMonthlyFixedInstallment) {
          By update the flag to 'Yes', this account will be blocked and fixed monthly installments will apply.
        }

        @if (!requireMonthlyFixedInstallment) {
          By update the flag to 'No', this account will be activated and normal monthly payment will apply.
        }
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Reason</mat-label>
          <textarea matInput [formControl]="formControlReason"></textarea>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayoutGap="5px"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button class="w-lt-md-100" color="primary" (click)="onSave($event)" [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>