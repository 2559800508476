import {Component, OnInit} from "@angular/core";
import {FlexModule} from "@angular/flex-layout/flex";
import {ErrorNotification2} from "@portal-workspace/grow-ui-library";
import {RouterOutlet} from "@angular/router";
import {LayoutService} from "../../service/layout.service";
import {MinimalLayoutHeaderComponent} from "../minimal-layout/minimal-layout-header.component";
import {MinimalLayoutService} from "../minimal-layout/minimal-layout.service";


@Component({
  templateUrl: './card-operator.layout.html',
  styleUrls: ['./card-operator.layout.scss'],
  standalone: true,
  providers: [
    { provide: LayoutService, useClass: LayoutService },
    { provide: MinimalLayoutService, useClass: MinimalLayoutService },
  ],
  imports: [
      FlexModule,
      ErrorNotification2,
      RouterOutlet,
      MinimalLayoutHeaderComponent,
  ]
})
export class CardOperatorLayout implements OnInit {

  ngOnInit(): void {
  }
}
