<div class="application-confirmation-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutGap="20px" class="content">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div></div>
          <div class="mat-h4-custom">Please confirm</div>
        </div>
        <ng-container>
          <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <div class="mat-body">
              Please verify all the asset details.
              Once PPSR registration is completed, you will not be able to make further changes.
            </div>
          </div>
        </ng-container>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button color="primary" (click)="onCancel($event)" mat-stroked-button>Cancel</button>
        <button color="primary" (click)="onSubmit($event)" mat-flat-button>Submit</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
