<div class="customer-user-details-component">
  @if (user && user.isDisabled) {
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box title="Disabled" type="warn">
          <span>This user is disabled</span>
        </message-box>
      </div>
    </div>
  }

  <mat-card appearance="outlined">
    <mat-card-content>

      <!--
      ### Customer user details
      -->
      <ng-container *ngTemplateOutlet="customerUserDetailsTemplate">
      </ng-container>


      <!--
      ### Allocate user to pismo account
      -->
      <ng-container *ngTemplateOutlet="allocateUserToPismoAccountTemplate">
      </ng-container>

      <!--
      ### Access: Enabled users
      -->
      <ng-container *ngTemplateOutlet="enabledPismoCustomersTemplate">
      </ng-container>

      <!--
      ### Access: Disabled users
      -->
      <ng-container *ngTemplateOutlet="disabledPismoCustomersTemplate">
      </ng-container>

      <!--
      ### Status
      -->
      <ng-container *ngTemplateOutlet="userStatusTemplate">
      </ng-container>

      <!--
      ###  Password and Authentication
      -->
      <ng-container *ngTemplateOutlet="passwordAndAuthenticationTemplate">
      </ng-container>

      @if (canAccess) {  
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <button mat-flat-button color="primary"
            class="w-lt-md-100"
            [disabled]="formGroup1.invalid || ((loader.saving.inProgress$ | async)) || ((loader.loadingUser.inProgress$ | async))"
            (click)="onSave(user)">
            {{(loader.saving.inProgress$ | async) ? "Saving...":"Save"}}
          </button>
        </div>
      </div>
    }
    </mat-card-content>
  </mat-card>
</div>



<!--
### passwordAndAuthenticationTemplate
-->
<ng-template #passwordAndAuthenticationTemplate>
  <div class="separator-bottom">
    <div class="row mb-4 ml-0">
      <div class="col-12">
        <span class="mat-body-strong">
          Password and Authentication
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-12">
        <mat-card appearance="outlined" class="mb-4 custom-card">
          <mat-card-content>
            <div class="row pt-2 pb-2">
              <div class="col-1 mr-2">
                <span class="mdi mdi-lock-outline mt-4"></span>
              </div>
              <div class="col-9">
                <span class="row list-header">Change password</span>
              </div>
              <div class="col-1" (click)="openConfirmPasswordAdmin($event)">
                <span class="mdi mdi-chevron-right mt-4"></span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>
</ng-template>

<!--
### UserStatusTemplate
-->
<ng-template #userStatusTemplate>
  <div class="separator">
    <div class="row mb-4 ml-0">
      <div class="col-12">
        <span class="mat-body-strong">
          Status
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 col-md-12">
        <mat-card appearance="outlined" class="mb-4 custom-card">
          <mat-card-content>
            <div class="row">
              <div class="col-1 mr-2">
                <span class="mdi mdi-check-circle-outline mt-4"></span>
              </div>
              <div class="col-8">
                <span class="row list-header">User is
                  @if (this.user.isDisabled) {
                    <span class="ml-1"> not active</span>
                  }
                  @else {
                    <span class="ml-1"> active</span>
                  }
                </span>
                  <span class="row list-description">Switch the toggle
                   
                    @if (this.user.isDisabled) {
                      <span class="ml-1 mr-1"> on to enable</span>
                    }
                    @else {
                      <span class="ml-1 mr-1"> off to disable </span>
                    }
                    this user
                  </span>
                </div>
                <div class="col-1">
                  <slide-toggle
                    [showCancelConfirmation]="true"
                    cancelConfirmationMessage="Are you sure you want to disable this user?"
                    [formControl]="formControlEnabled">
                  </slide-toggle>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <mat-card appearance="outlined" class="mb-4 custom-card">
            <mat-card-content>
              <div class="row">
                <div class="col-1 mr-2">
                  <span class="mdi mdi-check-circle-outline mt-4"></span>
                </div>
                <div class="col-8">
                  <span class="row list-header">User is
                    @if (this.user.approval) {
                      <span class="ml-1"> approved</span>
                    }
                    @else  {
                      <span class="ml-1"> not approved</span>
                    }
                  </span>
                    <span class="row list-description">Switch the toggle
                      @if (this.user.approval) {
                        <span class="ml-1 mr-1"> off to reject </span>
                      }
                      @else  {
                        <span class="ml-1 mr-1"> on to approve</span>
                      }
                      this user
                    </span>
                  </div>
                  <div class="col-1">
                    <slide-toggle
                      [showCancelConfirmation]="true"
                      cancelConfirmationMessage="Are you sure you want to reject this user?"
                      [formControl]="formControlApproved">
                    </slide-toggle>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-md-12">
            <mat-card appearance="outlined" class="mb-4 custom-card">
              <mat-card-content>
                <div class="row">
                  <div class="col-1 mr-2">
                    <span class="mdi mdi-check-circle-outline mt-4"></span>
                  </div>
                  <div class="col-8">
                    <span class="row list-header">User is
                      @if (this.user.isLock) {
                        <span class="ml-1"> locked</span>
                      }
                      @else {
                        <span class="ml-1"> unlocked</span>
                      }
                    </span>
                      <span class="row list-description">Switch the toggle
                        @if (this.user.isLock) {
                          <span class="ml-1 mr-1"> off to unlock </span>
                        }
                        @else {
                          <span class="ml-1 mr-1"> on to lock</span>
                        }
                        this user
                      </span>
                    </div>
                    <div class="col-1">
                      <slide-toggle
                        [showCancelConfirmation]="true"
                        cancelConfirmationMessage="Are you sure you want to unlock this user?"
                        [formControl]="formControlLocked">
                      </slide-toggle>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </ng-template>


      <!--
      ### customerUserDetailsTemplate
      -->
      <ng-template #customerUserDetailsTemplate>
        <div class="separator-bottom">
          <div class="row mb-4 ml-0">
            <div class="col-12">
              <span class="mat-body-strong">
                Customer User details
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <name title="First Name" [formControl]="formControlFirstName"></name>
            </div>
            <div class="col-lg-3 col-md-12">
              <name title="Last Name" [formControl]="formControlLastName"></name>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Mobile number</mat-label>
                <input type="text" [inputMask]="createMobilePhoneNumberInputMask" matInput
                  [formControl]="formControlMobileNumber">
                  @if (user && user.mobile_verified) {
                    <span class="mdi mdi-check-circle-outline" matSuffix></span>
                  }
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input matInput [inputMask]="createEmailInputMask" type="text" [formControl]="formControlEmail" placeholder="Email">
                  @for (errorKey of errorKeys(formControlEmail); track errorKey) {
                    <mat-error>
                      @if (formControlEmail.touched && formControlEmail.hasError(errorKey)) {
                        {{errorMessage(formControlEmail, errorKey)}}
                      }
                    </mat-error>
                  }
                </mat-form-field>
              </div>
              @if (canAccess) {  
              <div class="col-lg-3 col-md-12">
                <button mat-flat-button color="primary"
                  class="w-lt-md-100"
                  [disabled]="formControlEmail.invalid || !isInternalUser(loggedInUser) || (loader.loadingUser.inProgress$ | async) || ((loader.saving.inProgress$ | async))"
                  (click)="updateEmail(user)">
                  {{(loader.saving.inProgress$ | async) ? "Saving...":"Save"}}
                </button>
              </div>
            }
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <mat-divider [inset]="true"></mat-divider>
              </div>
            </div>
          </div>
        </ng-template>


        <!--
        ### allocateUserToPismoAccountTemplate
        -->
        
        <ng-template #allocateUserToPismoAccountTemplate>
         
          <div class="separator-bottom">
            @if (canAccess) {  
            <div class="row mb-4 ml-0">
              <div class="col-12">
                <span class="mat-body-strong">
                  Allocate User to Pismo Account
                </span>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <div fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutGap="10px">
                  <button mat-flat-button
                    color="primary"
                    [disabled]="(loader.loadingUser.inProgress$ | async) || (loader.allocatingUserToPismoAccount.inProgress$ | async)"
                    (click)="allocatePismoAccountToUser()">
                    {{(loader.allocatingUserToPismoAccount.inProgress$ | async) ? "Allocating ...":"Allocate User to Pismo Account"}}
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <mat-divider [inset]="true"></mat-divider>
              </div>
            </div>
          }
          </div>
        
        </ng-template>
    
        <!--
        ### enablePismoCustomersTemplate
        -->
        <ng-template #enabledPismoCustomersTemplate>
          <div class="separator-bottom">
            <div class="row mb-4 ml-0">
              <div class="col-12">
                <span class="mat-body-strong">
                  Access - Enabled users
                </span>
              </div>
            </div>
            <div class="row mb-4 pismo-access-row">
              <div class="col-12">
                @if (loader.loadingUser.inProgress$ | async) {
                  <div class="m-2">
                    <custom-content-loader type="list" ></custom-content-loader>
                  </div>
                }

                @if ((!(loader.loadingUser.inProgress$ | async)) && !enabledUserPismoMappings.length) {
                  <table>
                    <tr>
                      <td>No Pismo accounts</td>
                    </tr>
                  </table>
                }
                @if ((!(loader.loadingUser.inProgress$ | async)) && enabledUserPismoMappings.length) {
                  <table
                    [dataSource]="enabledUserPismoMappings"
                    class="accounts-table"
                    mat-table
                    multiTemplateDataRows>
                    <ng-container matColumnDef="expand">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        @if (isEnabledPismoAccessTableRowExpanded(element)) {
                          <span class="mdi mdi-chevron-up cursor-pointer"></span>
                        }
                        @if (!isEnabledPismoAccessTableRowExpanded(element)) {
                          <span class="mdi mdi-chevron-down cursor-pointer"></span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>Name</th>
                      <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pismoAccountNumber">
                      <th mat-header-cell *matHeaderCellDef>Pismo Account Number</th>
                      <td mat-cell *matCellDef="let element">{{element.pismoAccountNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pismoCustomerNumber">
                      <th mat-header-cell *matHeaderCellDef>Pismo Customer Number</th>
                      <td mat-cell *matCellDef="let element">{{element.pismoCustomerNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="expandedRowDetails">
                      <td mat-cell *matCellDef="let element" [attr.colspan]="4">
                        <div class="expandedRowDetailsContent separator"
                          [@detailExpand]="isEnabledPismoAccessTableRowExpanded(element) ? 'expanded' : 'collapsed'">
                          <table mat-table
                            class="details-table"
                       [dataSource]="[
                             {prop: 'customerId', name: 'Customer Id'},
                             {prop: 'customerName', name: 'Customer Name'},
                             {prop: 'pismoAccountNumber', name: 'Pismo Account Number'},
                             {prop: 'pismoCustomerNumber', name: 'Pismo Customer Number'},
                             {prop: 'customerAccessLevel', name: 'Customer Access Level'},
                             {prop: 'roleAccessIsAllTransaction', name: 'Allow All Transaction'},
                             {prop: 'roleAccessIsThirdPartyPayment', name: 'Allow Third Party Payment'},
                             {prop: 'roleAccessIsBpay', name: 'Allow BPay'},
                           ]">
                            <ng-container matColumnDef="prop">
                              <th mat-header-cell *matHeaderCellDef>Property</th>
                              <td mat-cell *matCellDef="let entry">{{entry.name}}</td>
                            </ng-container>
                            <ng-container matColumnDef="val">
                              <th mat-header-cell *matHeaderCellDef>Value</th>
                              <td mat-cell *matCellDef="let entry">
                                <div class="row">
                                  @if (entry.prop == 'customerId') {
                                    <div class="col"><span>{{element.customerId}}</span></div>
                                  }
                                  @if (entry.prop == 'customerName') {
                                    <div class="col"><span>{{element.customerName}}</span></div>
                                  }
                                  @if (entry.prop == 'pismoAccountNumber') {
                                    <div class="col"><span>{{element.pismoAccountNumber}}</span></div>
                                  }
                                  @if (entry.prop == 'pismoCustomerNumber') {
                                    <div class="col"><span>{{element.pismoCustomerNumber}}</span></div>
                                  }
                                  @if (entry.prop == 'customerAccessLevel') {
                                    <div class="col mt-4 mb-4">
                                      <customer-access-level-and-role-access
                                        [formControl]="customerAccessLevelRecords[element.pismoCustomerNumber].formControlAccessLevelAndRoleAccess">
                                      </customer-access-level-and-role-access>
                                    </div>
                                    <!--
                                    <div class="col mt-4">
                                      <customer-access-level
                                        [formControl]="customerAccessLevelRecords[element.pismoCustomerNumber].formControlAccessLevel">
                                      </customer-access-level>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="entry.prop == 'roleAccessIsAllTransaction'">
                                    <div class="col">
                                      <mat-checkbox
                                        color="primary"
                                        [formControl]="customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsAllTransaction">
                                      </mat-checkbox>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="entry.prop == 'roleAccessIsThirdPartyPayment'">
                                    <div class="col">
                                      <mat-checkbox
                                        color="primary"
                                        [formControl]="customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsThirdPartyPayment">
                                      </mat-checkbox>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="entry.prop == 'roleAccessIsBpay'">
                                    <div class="col">
                                      <mat-checkbox
                                        color="primary"
                                        [formControl]="customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsBpay">
                                      </mat-checkbox>
                                    </div>
                                    -->
                                  }
                                </div>
                              </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="footer">
                              <td mat-footer-cell *matFooterCellDef [attr.colspan]="2">
                                <div class="row mt-5 mb-4">
                                  <div class="col">
                                    <div fxLayout="row"
                                      fxLayout.lt-md="column"
                                      fxLayoutGap="10px">
                                      <button mat-stroked-button
                                    [disabled]="
                                      (loader.disablePismoCustomer.inProgress$ | async) ||
                                      (loader.savePismoCustomer.inProgress$ | async)
                                    "
                                        (click)="disablePismoCustomer(element)">
                                        Disable
                                      </button>
                                      <button mat-flat-button
                                        color="primary"
                                    [disabled]="
                                      customerAccessLevelRecords[element.pismoCustomerNumber].formControlAccessLevelAndRoleAccess.invalid ||
                                      (loader.savePismoCustomer.inProgress$ | async) ||
                                      (loader.disablePismoCustomer.inProgress$ | async)
                                    "
                                        (click)="savePismoCustomer(element)">
                                        Save
                                      </button>
                                    </div>
                               
                                  </div>                                
                                </div>
                              </td>                            
                            </ng-container>
                          
                          <!--tr mat-header-row *matHeaderRowDef="['prop', 'val']"></tr-->
                          <tr mat-row *matRowDef="let entry; columns: ['prop', 'val']"></tr>
                          @if (canAccess) { <tr mat-footer-row *matFooterRowDef="['footer']"></tr> }
                        </table>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="pismoAccessTableColumnsToDisplay"></tr>
                  <tr mat-row
                    class="pismo-access-table-row cursor-pointer"
                    (click)="onEnabledPismoAccessTableRowClicked(element)"
                  *matRowDef="let element; columns: pismoAccessTableColumnsToDisplay"></tr>
                  <tr mat-row
                    class="pismo-access-table-details-row"
                    [ngStyle]="{'display': isEnabledPismoAccessTableRowExpanded(element) ? null : 'none'}"
                  *matRowDef="let element; columns: ['expandedRowDetails']"></tr>
                </table>
              }
            </div>
          </div>
        </div>
      </ng-template>

      <!--
      ### disabledPismoCustmerTemplate
      -->
      <ng-template #disabledPismoCustomersTemplate>
        <div class="separator-bottom">
          <div class="row mb-4 ml-0">
            <div class="col-12">
              <span class="mat-body-strong">
                Access - Disabled users
              </span>
            </div>
          </div>
          <div class="row mb-4 pismo-access-row">
            <div class="col-12">
              @if (loader.loadingUser.inProgress$ | async) {
                <div class="m-2">
                  <custom-content-loader type="list" ></custom-content-loader>
                </div>
              }
              @if ((!(loader.loadingUser.inProgress$ | async)) && !disabledUserPismoMappings.length) {
                <table>
                  <tr>
                    <td>No Pismo accounts</td>
                  </tr>
                </table>
              }
              @if ((!(loader.loadingUser.inProgress$ | async)) && disabledUserPismoMappings.length) {
                <table
                  [dataSource]="disabledUserPismoMappings"
                  class="accounts-table"
                  mat-table
                  multiTemplateDataRows>
                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      @if (isDisabledPismoAccessTableRowExpanded(element)) {
                        <span class="mdi mdi-chevron-up cursor-pointer"></span>
                      }
                      @if (!isDisabledPismoAccessTableRowExpanded(element)) {
                        <span class="mdi mdi-chevron-down cursor-pointer"></span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="pismoAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>Pismo Account Number</th>
                    <td mat-cell *matCellDef="let element">{{element.pismoAccountNumber}}</td>
                  </ng-container>
                  <ng-container matColumnDef="pismoCustomerNumber">
                    <th mat-header-cell *matHeaderCellDef>Pismo Customer Number</th>
                    <td mat-cell *matCellDef="let element">{{element.pismoCustomerNumber}}</td>
                  </ng-container>
                  <ng-container matColumnDef="expandedRowDetails">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="4">
                      <div class="expandedRowDetailsContent separator"
                        [@detailExpand]="isDisabledPismoAccessTableRowExpanded(element) ? 'expanded' : 'collapsed'">
                        <table mat-table
                          class="details-table"
                       [dataSource]="[
                             {prop: 'customerId', name: 'Customer Id'},
                             {prop: 'customerName', name: 'Customer Name'},
                             {prop: 'pismoAccountNumber', name: 'Pismo Account Number'},
                             {prop: 'pismoCustomerNumber', name: 'Pismo Customer Number'},
                             {prop: 'customerAccessLevel', name: 'Customer Access Level'},
                             {prop: 'roleAccessIsAllTransaction', name: 'Allow All Transaction'},
                             {prop: 'roleAccessIsThirdPartyPayment', name: 'Allow Third Party Payment'},
                             {prop: 'roleAccessIsBpay', name: 'Allow BPay'},
                           ]">
                          <ng-container matColumnDef="prop">
                            <th mat-header-cell *matHeaderCellDef>Property</th>
                            <td mat-cell *matCellDef="let entry">{{entry.name}}</td>
                          </ng-container>
                          <ng-container matColumnDef="val">
                            <th mat-header-cell *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let entry">
                              <div class="row">
                                @if (entry.prop == 'customerId') {
                                  <div class="col"><span>{{element.customerId}}</span></div>
                                }
                                @if (entry.prop == 'customerName') {
                                  <div class="col"><span>{{element.customerName}}</span></div>
                                }
                                @if (entry.prop == 'pismoAccountNumber') {
                                  <div class="col"><span>{{element.pismoAccountNumber}}</span></div>
                                }
                                @if (entry.prop == 'pismoCustomerNumber') {
                                  <div class="col"><span>{{element.pismoCustomerNumber}}</span></div>
                                }
                                @if (entry.prop == 'customerAccessLevel') {
                                  <div class="col">
                                    {{element.customerAccessLevel}}
                                  </div>
                                }
                                @if (entry.prop == 'roleAccessIsAllTransaction') {
                                  <div class="col">
                                    @if (!element.RoleAccess.isAllTransaction) {
                                      <span class="mdi mdi-close-circle-outline"></span>
                                    }
                                    @if (element.RoleAccess.isAllTransaction) {
                                      <span class="mdi mdi-check-circle-outline"></span>
                                    }
                                  </div>
                                }
                                @if (entry.prop == 'roleAccessIsThirdPartyPayment') {
                                  <div class="col">
                                    @if (!element.RoleAccess.isThirdPartyPayment) {
                                      <span class="mdi mdi-close-circle-outline"></span>
                                    }
                                    @if (element.RoleAccess.isThirdPartyPayment) {
                                      <span class="mdi mdi-check-circle-outline"></span>
                                    }
                                  </div>
                                }
                                @if (entry.prop == 'roleAccessIsBpay') {
                                  <div class="col">
                                    @if (!element.RoleAccess.isBpay) {
                                      <span class="mdi mdi-close-circle-outline"></span>
                                    }
                                    @if (element.RoleAccess.isBpay) {
                                      <span class="mdi mdi-check-circle-outline"></span>
                                    }
                                  </div>
                                }
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="footer">
                            <td mat-footer-cell *matFooterCellDef [attr.colspan]="2">
                              <div class="row mt-4 mb-4">
                                <div class="col">
                                  <div fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxLayoutGap="10px">
                                  </div>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                        <!--tr mat-header-row *matHeaderRowDef="['prop', 'val']"></tr-->
                        <tr mat-row *matRowDef="let entry; columns: ['prop', 'val']"></tr>
                      <!--tr mat-footer-row *matFooterRowDef="['footer']"></tr-->
                    </table>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="pismoAccessTableColumnsToDisplay"></tr>
              <tr mat-row
                class="pismo-access-table-row cursor-pointer"
                (click)="onDisabledPismoAccessTableRowClicked(element)"
              *matRowDef="let element; columns: pismoAccessTableColumnsToDisplay"></tr>
              <tr mat-row
                class="pismo-access-table-details-row"
                [ngStyle]="{'display': isDisabledPismoAccessTableRowExpanded(element) ? null : 'none'}"
              *matRowDef="let element; columns: ['expandedRowDetails']"></tr>
            </table>
          }
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-divider [inset]="true"></mat-divider>
      </div>
    </div>
  </ng-template>
