import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { AlertDialogData, ConfirmationDialogResult, DisbursementDialogData, DisbursementDialogResult } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    templateUrl: './disbursement.dialog.html',
    styleUrls: ['./disbursement.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonToggleModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatDialogModule]
})
export class DisbursementDialog {

  defaultDisbursement: boolean;
  // WEB-3702:
  // selectedSupplier: 'Dynamoney' | 'Supplier' | null = null;
  // formControlDepositPaidTo!: FormControl<'Dynamoney' | 'Supplier' | null>

  constructor(@Inject(MAT_DIALOG_DATA) public data: DisbursementDialogData,
    private dialogRef: MatDialogRef<DisbursementDialog, DisbursementDialogResult>,
  ) {
    this.defaultDisbursement = data.type == 'Default'
    // WEB-3702
    // this.formControlDepositPaidTo = formBuilder.control(null, [Validators.required])
    // this.formControlDepositPaidTo.valueChanges.subscribe(r => {
    //   this.selectedSupplier = r
    // })
  }

  onSubmit(readyForSubmission: boolean) {
    const result: DisbursementDialogResult = {
      readyForSubmission: readyForSubmission,
      // WEB-3702: depositPaidTo: this.selectedSupplier!
    }
    this.dialogRef.close(result);
  }

}
