import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AggregatorListValueGetFn, GetFn, httpOptions,} from '@portal-workspace/grow-ui-library';
import {
  AggregatorAndOriginatorListValueGetFn, CustomerListValue, CustomerListValueGetFn, NotNullable,
  OriginatorListSortType,
  parseJSON,
  PromotionResponse,
  Ratecard,
  RatecardList, RatecardListSortType, UpdateOriginatorInput,
  UpdateRatecardInput, UpdateRatecardResult,
} from '@portal-workspace/grow-shared-library';
import {ApiResponse, OriginatorBusiness, PaginablePayloadApiResponse, PayloadApiResponse} from '@portal-workspace/grow-shared-library';
import {map} from 'rxjs/operators';
import {RatecardDetails} from '@portal-workspace/grow-shared-library';
import {AssetTypeRateValue} from '@portal-workspace/grow-shared-library';
import { PaginationInfo } from '@portal-workspace/grow-shared-library';
import { paginationUrl, toPaginationInfo } from '@portal-workspace/grow-ui-library';


const URL_RATECARD_LIST = (paginationInfo : PaginationInfo)=> paginationUrl(`${environment.api2Host}/api2/ratecard-list`, paginationInfo); // post
const URL_RATE_CARD_DETAILS = (rateCardId: number)=>`${environment.api2Host}/api2/ratecard/${rateCardId}`; // get
const URL_CREATE_RATE_CARD = () => `${environment.api2Host}/api2/ratecard`; // post
const URL_CREATE_PROMOTION = () => `${environment.api2Host}/api2/promotions`; // post
const URL_UPDATE_RATE_CARD = (ratecardId: number) => `${environment.api2Host}/api2/ratecard/${ratecardId}`; // put
const URL_GET_AGGREGATORS_AND_ORIGINATORS = (onlyActive = true) => `${environment.api2Host}/api2/aggregators-originators?onlyActive=${onlyActive}`; // get
const URL_GET_CUSTOMER_LIST = (onlyActive = true) => `${environment.api2Host}/api2/customer-list?onlyActive=${onlyActive}`;
const URL_GET_ALL_AGGREGATORS = (onlyActive = true) => `${environment.api2Host}/api2/aggregators?onlyActive=${onlyActive}`; // get
// const URL_GET_AGGREGATORS_LIST = (paginationInfo : PaginationInfo) => paginationUrl( `${environment.api2Host}/api2/aggregators-list`,paginationInfo); // post
const URL_GET_ORIGINATORS_LIST = (paginationInfo : PaginationInfo) =>paginationUrl(`${environment.api2Host}/api2/all-companies-list`,paginationInfo); // post
// const URL_GET_AGGREGATOR_BY_ID = (aggregatorId: number) => `${environment.api2Host}/api2/aggregator/${aggregatorId}`;   // get
const URL_GET_PROMOTION_BY_TYPE = (promotionType : string) => `${environment.api2Host}/api2/promotions/all/${promotionType}`; //get
const URL_GET_ORIGINATOR_BY_ID = (originatorId: number) => `${environment.api2Host}/api2/originator_business/${originatorId}`; // get
const URL_GET_RATE_CARDS = () => `${environment.api2Host}/api2/ratecards`; // get
// const URL_UPDATE_AGGREGATOR = () => `${environment.api2Host}/api2/aggregator`; // put
const URL_UPDATE_ORIGINATOR_BUSINESS = (originatorBusinessId: number) => `${environment.api2Host}/api2/originator_business/${originatorBusinessId}`; // put
// const URL_SEND_VERIFY_ACCOUNT = () => `${environment.apiHost}/api/accountVerify`;  // post
// const URL_SEND_VERIFY_SMS = ()=> `${environment.apiHost}/api/sendVerificationReminderSMS`; // post


@Injectable()
export class AdminService {


  constructor(private httpClient: HttpClient) { }



  AggregatorsGetFn: AggregatorListValueGetFn = () => {
    return this.getAllAggregators(false).pipe(
      map(r => {
        return r.payload.map(_r => ({
          aggregatorId: _r.OriginatorBusinessId,
          name: _r.EntityName
        }))
      })
    );
  }
  getAllAggregators(onlyActive = true): Observable<PayloadApiResponse<OriginatorBusiness[]>> {
    return this.httpClient.get<PayloadApiResponse<OriginatorBusiness[]>>(URL_GET_ALL_AGGREGATORS(onlyActive), httpOptions()).pipe();
  }

  updateOriginatorBusiness(originatorBusinessId: number, data: UpdateOriginatorInput): Observable<ApiResponse> {
    console.log("update originator business", data);
    return this.httpClient.put<ApiResponse>(URL_UPDATE_ORIGINATOR_BUSINESS(originatorBusinessId), { ...data }, httpOptions()).pipe();
  }


  // updateAggregator(aggregatorId: number, data: UpdateAggregatorInput): Observable<ApiResponse> {
  //   return this.httpClient.put<{status: boolean, message: string}>(URL_UPDATE_AGGREGATOR(), {
  //     aggregatorId,
  //     data: {
  //       EntityName: data.EntityName, EntityType: data.EntityType, ABN: data.ABN,
  //       ACN: data.ACN, Website: data.Website, Email: data.Email, TelephoneNumber: data.TelephoneNumber,
  //       RatecardId: data.RatecardId,
  //     }
  //   }, httpOptions()).pipe(
  //     map(r => {
  //       return {
  //         status: r.status, message: r.message,
  //       }
  //     })
  //   );
  // }

  RatecardsGetFn: GetFn = () => {
    return this.getRatecards().pipe(map(r => r.payload));
  }
  getRatecards(): Observable<PayloadApiResponse<Ratecard[]>> {
    return this.httpClient.get<PayloadApiResponse<Ratecard[]>>(URL_GET_RATE_CARDS(), httpOptions()).pipe();
  }

  getOriginatorByIdFn = (originatorBusinessId: number): Observable<OriginatorBusiness | null> => {
    return this.getOriginatorById(originatorBusinessId).pipe( map(r => r.payload));
  }

  getOriginatorById(originatorBusinessId: number): Observable<PayloadApiResponse<OriginatorBusiness | null>> {
    return this.httpClient.get<PayloadApiResponse<OriginatorBusiness | null>>(URL_GET_ORIGINATOR_BY_ID(originatorBusinessId), httpOptions()).pipe();
  }


  // getAggregatorById(aggregatorId: number): Observable<PayloadApiResponse<OriginatorBusiness | null>> {
  //   return this.httpClient.get<PayloadApiResponse<OriginatorBusiness | null>>(URL_GET_AGGREGATOR_BY_ID(aggregatorId), httpOptions()).pipe();
  // }

  getPromotionByType(promotionType : string): Observable<PayloadApiResponse<PromotionResponse>>{
    return this.httpClient.get<PayloadApiResponse<PromotionResponse>>(URL_GET_PROMOTION_BY_TYPE(promotionType),httpOptions()).pipe();
  }


  getOriginatorsList(opt: {
    page: {
      limit: number,
      offset: number,
    },
    filter: string,
    sorts: OriginatorListSortType,
  }): Observable<PaginablePayloadApiResponse<OriginatorBusiness>> {
    return this.httpClient.post<PaginablePayloadApiResponse<OriginatorBusiness>>(URL_GET_ORIGINATORS_LIST(toPaginationInfo(opt)),{},
     httpOptions())
  }



  // getAggregatorsList(opt: {
  //   page: {
  //     offset: number,
  //     limit: number,
  //   },
  //   filter: string,
  //   sorts: AggregatorListSortType,
  // }): Observable<PaginablePayloadApiResponse<OriginatorBusiness>> {
  //   return this.httpClient.post<PaginablePayloadApiResponse<OriginatorBusiness>>(URL_GET_AGGREGATORS_LIST(toPaginationInfo(opt)), {}, httpOptions())
  // }

  getCustomerListFn: CustomerListValueGetFn = () => {
    return this.getCustomerList(false).pipe(map(r => r.payload));
  }

  getCustomerList(onlyActive=true): Observable<PayloadApiResponse<NotNullable<CustomerListValue>[]>> {
    return this.httpClient.get<PayloadApiResponse<NotNullable<CustomerListValue>[]>>(URL_GET_CUSTOMER_LIST(onlyActive), httpOptions()).pipe();
  }


  getAggregatorsAndOriginatorsFn: AggregatorAndOriginatorListValueGetFn = () => {
    return this.getAggregatorsAndOriginators(false).pipe(map(r => {
      const originatorBusinesses =  r.payload;
      return originatorBusinesses.map(o => {
        switch(o.Type) {
          case 'aggregator': {
            return {
              kind: 'Aggregator',
              OriginatorBusinessId: o.OriginatorBusinessId,
              EntityName: o.EntityName,
              SalesforceId: o.SalesforceId,
            }
          }
          default: {
            return {
              kind: 'Originator',
              OriginatorBusinessId: o.OriginatorBusinessId,
              EntityName: o.EntityName,
              SalesforceId: o.SalesforceId,
            }
          }
        }
      });
    }));
  }

  getAggregatorsAndOriginators(onlyActive=true): Observable<PayloadApiResponse<OriginatorBusiness[]>> {
    return this.httpClient.get<PayloadApiResponse<OriginatorBusiness[]>>(URL_GET_AGGREGATORS_AND_ORIGINATORS(onlyActive), httpOptions()).pipe();
  }


  createRatecard(data: UpdateRatecardInput): Observable<ApiResponse> {
    return this.httpClient.post<{message: string}>(URL_CREATE_RATE_CARD(), data, httpOptions()).pipe(
      map(r => {
        return {
          status: true,
          message: r.message
        }
      })
    );
  }

  createPromotion(data : PromotionResponse) : Observable<ApiResponse>{
    return this.httpClient.post<ApiResponse>(URL_CREATE_PROMOTION() , data, httpOptions());
  }

  updateRatecard(ratecardId: number, data: UpdateRatecardInput): Observable<PayloadApiResponse<UpdateRatecardResult>> {
    return this.httpClient.put<PayloadApiResponse<UpdateRatecardResult>>(URL_UPDATE_RATE_CARD(ratecardId), data, httpOptions()).pipe();
  }

  getRatecardList(opt: {
    page: {limit: number, offset: number},
    filter: string
    sorts?: RatecardListSortType}): Observable<PaginablePayloadApiResponse<RatecardList>> {
    return this.httpClient.post<PaginablePayloadApiResponse<RatecardList>>(URL_RATECARD_LIST(toPaginationInfo(opt)), {}, httpOptions())
  }

  getRatecardDetails(rateCardId: number): Observable<PayloadApiResponse<RatecardDetails[]>> {
    return this.httpClient.get<PayloadApiResponse<RatecardDetails[]>>(URL_RATE_CARD_DETAILS(rateCardId), httpOptions()).pipe(
      map(r => {
        if (r.payload) {
          for (const _r of r.payload) {
            let assetTypes: AssetTypeRateValue = parseJSON(_r.AssetType);
            if (assetTypes && Array.isArray(assetTypes)) {
              assetTypes = assetTypes.map(assetType => ({
                Cat: assetType.Cat,
                Type: assetType.Type.split('-')[1] ?? assetType.Type,
                Rate: assetType.Rate,
              }))
            }

            _r.LoanAmount = parseJSON(_r.LoanAmount);
            _r.AssetCategory = parseJSON(_r.AssetCategory);
            _r.AssetType = assetTypes ?? [];
            _r.EOTAge = parseJSON(_r.EOTAge);
            _r.LoanTerms = parseJSON(_r.LoanTerms);
            _r.Brokerage = parseJSON(_r.Brokerage);
            _r.DocFee = parseJSON(_r.DocFee);
            _r.ABNorGSTAge = parseJSON(_r.ABNorGSTAge);
            _r.AssetFinanceTier1 = parseJSON(_r.AssetFinanceTier1);
            _r.AssetFinanceTier2 = parseJSON(_r.AssetFinanceTier2);
            _r.AssetFinanceTier3 = parseJSON(_r.AssetFinanceTier3);
            _r.CarsAndCaravans = parseJSON(_r.CarsAndCaravans);
            _r.Motorbikes = parseJSON(_r.Motorbikes);
            _r.SolarSystems = parseJSON(_r.SolarSystems);
          }
        }
        return {
          status: true, message: '',
          payload: r.payload,
        }
      })
    );
  }
}
