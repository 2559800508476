import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  AggregatorAndOriginatorListValueGetFn,
  ApproveOrRejectUserFn,
  CustomerAccessLevel,
  CustomerListValue,
  CustomerListValueGetFn,
  DisableUsersFn,
  EnableDisable2faFn,
  EnableUsersFn,
  Add2faSecretKeyFn,
  Get2faTotpVerificationCodeUriFn,
  GetUserFn,
  isInternalUser,
  canChangeOthers2faAccessLevel,
  NameComponentValue,
  SlideToggleValue,
  UpdateUserFn,
  UpdateUserPasswordFn,
  User,
  UserandPriviledges,
  Verify2faTokenFn,
  RoleAccess,
  canUser2faAccessBeChangedByInternalUser,
  PortalLoginUser,
  PismoUpdateUserAccessFn,
  DisableOverdraftUserFn,
  OverdraftAccountAvailableListValue,
  OverdraftAccountAvailableForUserFn,
  AllocateUserToPismoAccountFn,
  CustomerAccessLevelValue,
  CustomerAccessLevelAndRoleAccessValue,
  PismoUpdateCustomerBody,
  PismoUpdateCustomerFn
} from '@portal-workspace/grow-shared-library';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { AsyncPipe, Location, NgStyle, NgTemplateOutlet } from '@angular/common';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {loadingFor} from '@ngneat/loadoff';
import {MatInputModule} from '@angular/material/input';
import {InputMaskModule} from '@ngneat/input-mask';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { NameComponent } from '../name-component/name.component';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { SlideToggleComponent } from '../slide-toggle-component/slide-toggle.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import {CustomerListComponent } from '../aggregator-search-component/customer-list.component';
import { CustomerAccessLevelComponent } from '../access-level-component/customer-access-level.component';
import {
  createEmailInputMask,
  createMobilePhoneNumberInputMask,
  createPhoneNumberInputMask,
  formControlErrorMessage,
  formControlErrorKeys,
  getUser,
  setupUntilDestroy,
} from '../component-utils';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';
import { validEmailValidator} from '../../validators/validators';

import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  OverdraftAccountAvailableListComponent
} from "../overdraft-customer-components/overdraft-account-available-list.component";
 
import {
  CustomerAccessLevelAndRoleAccessComponent
} from "../access-level-component/customer-access-level-and-role-access.component";
import { CustomContentLoaderComponent } from '../custom-content-loader-component/custom-content-loader.component';
 


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'customer-user-details',
  templateUrl: './customer-user-details.component.html',
  styleUrls: ['./customer-user-details.component.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0', display: 'none', visibility: 'hidden'})),
      state('expanded', style({height: '*', display: 'block', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  imports: [
    NameComponent,
    ReactiveFormsModule,
    DisableControlDirective,
    MatInputModule,
    InputMaskModule,
    MatDividerModule,
    MatCardModule,
    SlideToggleComponent,
    AsyncPipe,
    MessageBoxComponent,
    CustomerListComponent,
    CustomerAccessLevelComponent,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgStyle,
    FlexModule,
    MatTooltipModule,
    MatCheckboxModule,
    OverdraftAccountAvailableListComponent,
    CustomContentLoaderComponent,
    NgTemplateOutlet,
    CustomerAccessLevelAndRoleAccessComponent
]
})
export class CustomerUserDetailsComponent implements OnInit, AfterViewInit {

  pismoAccessTableColumnsToDisplay = ['expand', 'name', 'pismoAccountNumber', 'pismoCustomerNumber'];

  loader = loadingFor('saving', 'loadingUser', 'savePismoCustomer', 'disablePismoCustomer', 'allocatingUserToPismoAccount');

  subscriptions: Subscription[] = [];

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: true}) user!: UserandPriviledges;
  @Input({required: true}) customerListValueGetFn!: CustomerListValueGetFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) approveOrRejectFn!: ApproveOrRejectUserFn;
  @Input({required: true}) updateUserFn!: UpdateUserFn;
  @Input({required: true}) add2faSecretKeyFn!: Add2faSecretKeyFn;
  @Input({required: true}) enableDisable2faFn!: EnableDisable2faFn;
  @Input({required: true}) verify2faTokenFn!: Verify2faTokenFn;
  @Input({required: true}) get2faTotpVerificationCodeUriFn!: Get2faTotpVerificationCodeUriFn;
  @Input({required: true}) updateUserPasswordFn!: UpdateUserPasswordFn;
  @Input({required: true}) disableUserFn!: DisableUsersFn;
  @Input({required: true}) enableUserFn!: EnableUsersFn;
  @Input({required: true}) pismoUpdateUserAccessFn!: PismoUpdateUserAccessFn;
  @Input({required: true}) disableOverdraftUserFn!: DisableOverdraftUserFn;
  @Input({required: true}) overdraftAccountAvailableForUserFn!: OverdraftAccountAvailableForUserFn;
  @Input({required: true}) allocateUserToPismoAccountFn!: AllocateUserToPismoAccountFn;
  @Input({ required: true }) updatePismoCustomerFn!: PismoUpdateCustomerFn;

  enabledUserPismoMappings: UserandPriviledges['pismo'] = [];
  disabledUserPismoMappings: UserandPriviledges['pismo'] = [];

  createPhoneNumberInputMask = createPhoneNumberInputMask();
  createMobilePhoneNumberInputMask = createMobilePhoneNumberInputMask();
  createEmailInputMask = createEmailInputMask();
  isInternalUser = isInternalUser;
  
  loggedInUser = getUser();
  canAccess = this.loggedInUser?.priviledges?.includes('admin') || 
  this.loggedInUser?.priviledges?.includes('operations');
  // canUser2faBeChanged = true;

  formGroup1: FormGroup<{
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    // customer: FormControl<CustomerListValue>,
    // email: FormControl<string | null>,
    // accessLevel: FormControl<CustomerAccessLevelValue>,
    mobileNumber: FormControl<string | null>
  }>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  // formControlCustomerListValue: FormControl<CustomerListValue>;
  formControlOverdraftAccountAvailable: FormControl<OverdraftAccountAvailableListValue>;
  formControlMobileNumber: FormControl<string | null>;
  formControlEmail: FormControl<string | null>;
  // formControlCustomerAccessLevel: FormControl<CustomerAccessLevelValue>;
  formControlApproved: FormControl<SlideToggleValue>;
  // formControl2FA: FormControl<SlideToggleValue>;
  formControlEnabled: FormControl<SlideToggleValue>;
  formControlLocked: FormControl<SlideToggleValue>;

  enabledPismoAcessTableExpandedElement: UserandPriviledges['pismo'][number] | null = null;
  disabledPismoAcessTableExpandedElement: UserandPriviledges['pismo'][number] | null = null;

  customerAccessLevelRecords: Record<number, {
    formControlAccessLevelAndRoleAccess: FormControl<CustomerAccessLevelAndRoleAccessValue>
    // formControlAccessLevel: FormControl<CustomerAccessLevelValue>,
    // formControlIsAllTransaction: FormControl<boolean | null>,
    // formControlIsThirdPartyPayment: FormControl<boolean | null>,
    // formControlIsBpay: FormControl<boolean | null>,
  }> = {};


  constructor(
    private toastService: PortalHotToastService,
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private authService: AuthService,
    private _location: Location) {
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    // this.formControlCustomerListValue = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required],validEmailValidator(this.authService.validCustomerEmailCheckFn));
    // this.formControlCustomerAccessLevel = formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftAccountAvailable = formBuilder.control(null, [Validators.required]);
    this.formControlApproved = formBuilder.control(false);
    // this.formControl2FA = formBuilder.control(false);
    this.formControlEnabled = formBuilder.control(false);
    this.formControlLocked = formBuilder.control(false);
    this.formGroup1 = this.formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      // customer: this.formControlCustomerListValue,
      // email: this.formControlEmail,
      // accessLevel: this.formControlCustomerAccessLevel,
      mobileNumber: this.formControlMobileNumber,
    });
  }


  reload() {
    if (this.user) {
      this.subscriptions.push(this.getUserFn(this.user.UserId,true).pipe(
        this.loader.loadingUser.track(),
        tap(usr => {
          if (usr) {
            this.user = usr;
            this.setupUserPIsmo();
          }
        })
      ).subscribe());
    }
  }

  setupUserPIsmo() {
    this.disabledUserPismoMappings = [];
    this.enabledUserPismoMappings = [];
    if (this.user && this.user.pismo) {
      this.user.pismo.forEach(userPismoRecord => {

        console.log('*** push mapping', userPismoRecord);
        if (userPismoRecord.isPismoCustomerDisabled) {
          this.disabledUserPismoMappings.push(userPismoRecord);
        } else {
          this.enabledUserPismoMappings.push(userPismoRecord);
        }

        const formControlAccessLevelAndRoleAccess = this.formBuilder.control({
          customerAccessLevel: userPismoRecord.customerAccessLevel,
          roleAccess: userPismoRecord.RoleAccess,
        }, [Validators.required]);
        // const formControlAccessLevel = this.formBuilder.control(userPismoRecord.customerAccessLevel);
        // const formControlIsAllTransaction = this.formBuilder.control(userPismoRecord.RoleAccess.isAllTransaction);
        // const formControlIsThirdPartyPayment = this.formBuilder.control(userPismoRecord.RoleAccess.isThirdPartyPayment);
        // const formControlIsBpay = this.formBuilder.control(userPismoRecord.RoleAccess.isBpay);

        this.customerAccessLevelRecords[userPismoRecord.pismoCustomerNumber] = {
          formControlAccessLevelAndRoleAccess: formControlAccessLevelAndRoleAccess,
          // formControlAccessLevel: formControlAccessLevel,
          // formControlIsAllTransaction: formControlIsAllTransaction,
          // formControlIsThirdPartyPayment: formControlIsThirdPartyPayment,
          // formControlIsBpay: formControlIsBpay,
        };

        // this.subscriptions.push(formControlAccessLevel.valueChanges.pipe(
        //   tap(v => {
        //     if (v == 'card-admin') {
        //       formControlIsAllTransaction.setValue(true);
        //       formControlIsThirdPartyPayment.setValue(true);
        //       formControlIsBpay.setValue(true);
        //       formControlIsAllTransaction.disable();
        //       formControlIsThirdPartyPayment.disable();
        //       formControlIsBpay.disable();
        //     } else { // card member
        //       formControlIsAllTransaction.setValue(userPismoRecord.RoleAccess.isAllTransaction);
        //       formControlIsThirdPartyPayment.setValue(userPismoRecord.RoleAccess.isThirdPartyPayment);
        //       formControlIsBpay.setValue(userPismoRecord.RoleAccess.isBpay);
        //       formControlIsAllTransaction.enable();
        //       formControlIsThirdPartyPayment.enable();
        //       formControlIsBpay.enable();
        //     }
        //   })
        // ).subscribe());
      });
    } else {
      this.customerAccessLevelRecords = {};
    }
  }


  ngAfterViewInit() {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    if (this.user) {
      const givenName = this.user.GivenName ?? (this.user.Name ? this.user.Name.split(' ')?.[0] : '');
      const familyName = this.user.FamilyName ?? (this.user.Name ? this.user.Name.split(' ')?.[1] : '');
      this.formControlApproved.setValue(!!this.user?.approval);
      this.formControlEnabled.setValue(!this.user?.isDisabled);
      this.formControlLocked.setValue(this.user?.isLock);
      this.formControlFirstName.setValue(givenName);
      this.formControlLastName.setValue(familyName);
      // const v: CustomerListValue = this.user.CustomerId ? {
      //     customerId: this.user.CustomerId,
      //     EntityName: '',
      //     SalesforceId: '',
      // } : null;
      // this.formControlCustomerListValue.setValue(v);
      this.formControlMobileNumber.setValue(this.user.MobileNumber ?? null);
      this.formControlEmail.setValue(this.user.Email ?? null);
      // this.formControlCustomerAccessLevel.setValue(this.user.customerAccessLevel ?? null);
      if (!isInternalUser(this.loggedInUser)) {
          // this.formControlCustomerListValue.disable();
          this.formControlEmail.disable();
      }
      // this.formControl2FA.setValue(!!this.user?.is2FA, {emitEvent: false});
      this.setupUserPIsmo();
    }

    this.subscriptions.push(this.formControlEnabled.valueChanges.pipe(
      tap(enabledChecked => {
        this.enableChangetoggle(enabledChecked!);
      })
    ).subscribe());
    this.subscriptions.push(this.formControlApproved.valueChanges.pipe(
      tap(approvedChecked => {
        this.approvalChangetoggle(approvedChecked!);
      })
    ).subscribe());
    this.subscriptions.push(this.formControlLocked.valueChanges.pipe(
      tap(lockUnlockChecked => {
        this.lockUnlockChangetoggle(lockUnlockChecked!);
      })
    ).subscribe());

    // const _canChangeOthers2faAccessLevel = canChangeOthers2faAccessLevel(this.loggedInUser);
    // this.canUser2faBeChanged = canUser2faAccessBeChangedByInternalUser(this.user);
    // if(_canChangeOthers2faAccessLevel && this.canUser2faBeChanged){
    //   this.formControl2FA.enable();
    //   this.setup2FaFormControl();
    // } else{
    //   this.formControl2FA.disable();
    // }
  }

  approveUser(user?: User) {
    if (user) {
      this.approveOrRejectFn(user.UserId, true).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User approved`),
        tap(r => {
          this.reload();
        })
      ).subscribe()
    }
  }

  rejectUser(user?: User) {
    if (user) {
      this.subscriptions.push(this.approveOrRejectFn(user.UserId, false).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User rejected`),
        tap(r => {
          this.reload();
        })
      ).subscribe());
    }
  }

  lockUser(user?:User){
    if(user){
      this.authService.lockOrUnlockUser(user.UserId,true).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('User locked'),
        tap(r => {
          this.reload();
        })
      ).subscribe();
    }
  }

  unlockUser(user?: User){
    if(user){
      this.authService.lockOrUnlockUser(user.UserId,false).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('User unlocked'),
        tap(r => {
          this.reload();
        })
      ).subscribe();
    }
  }

  updateEmail(user?: User){
    if(user){
      const data: PismoUpdateCustomerBody = {
        email: this.formControlEmail.value!
      }
      this.subscriptions.push(this.updatePismoCustomerFn(user.UserId, data).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User Email saved`),
        tap(r => {
          this.reload();
        })
      ).subscribe());
    }
  }

  // NOTE: saving the whole user form
  onSave(user?: User) {
    // NOTE: when saving FormGroup should be valid, due to validators set
    if (user) {
      const data: PismoUpdateCustomerBody = {
        firstName: this.formControlFirstName.value!,
        lastName: this.formControlLastName.value!,
        // customerAccessLevel: this.formControlCustomerAccessLevel.value!,
        // RoleAccess: this.formControlCustomerAccessLevel.value! === 'card-admin' ? {"isAllTransaction":true,"isThirdPartyPayment":true,"isBpay":true} : {"isAllTransaction":false,"isThirdPartyPayment":false,"isBpay":false},
        email: this.formControlEmail.value!,
        // CustomerId: undefined,
        mobileNumber: this.formControlMobileNumber.value!,
      };

      // const o: CustomerListValue = this.formControlCustomerListValue.value;
      // if (o) {
      //   data.CustomerId = o.customerId;
      // }
      this.subscriptions.push(this.updatePismoCustomerFn(user.UserId, data).pipe(
        this.loader.saving.track(),
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User details saved`),
        tap(r => {
          this.reload();
        })
      ).subscribe());
    }
  }

  // setup2FaFormControl() {
  //   this.subscriptions.push(this.formControl2FA.valueChanges.pipe(
  //       tap(twoFAChecked => {
  //           const email = this.user?.Email;
  //             if (email) {
  //                 this.subscriptions.push(this.enableDisable2faFn(email, twoFAChecked ? 1 : 0).pipe(
  //                     this.toastService.spinnerObservable(),
  //                     this.toastService.snackBarObservable(twoFAChecked ? `2fa enabled for ${email}` : `2fa disabled for ${email}`),
  //                 ).subscribe());
  //             } else {
  //                 this.applicationDialogService.openAlertDialog({
  //                     message: `Email` ,
  //                     subMessage: `No email address exists for user, cannot enable 2FA`,
  //                 });
  //             }
  //       })
  //   ).subscribe());
  // }


  openConfirmPasswordAdmin($event: Event) {
    let id = this.user?.UserId;
    if (id) {
      this.applicationDialogService.openConfirmPasswordAdminDialog({
        userId: id,
        updateUserPasswordFn: this.updateUserPasswordFn,
      })
        .afterClosed()
        .pipe(
          tap(async (r) => {
          })
        ).subscribe();
    }
  }

  enableChangetoggle(enabledChecked: boolean) {
    if(enabledChecked) {
      let id: number | undefined = this.user?.UserId;
      if (id) {
        this.enableUser([id]);
      }
    } else {
      let Ids: number[] = [];
      let id: number | undefined = this.user?.UserId;
      if (id) {
        Ids.push(id);
      }
      this.disableUser(Ids);
    }
  }

  approvalChangetoggle(approvedChecked: boolean) {
    if(approvedChecked) { // approve
      this.approveUser(this.user);
    } else { // reject
      this.rejectUser(this.user);
    }
  }

  lockUnlockChangetoggle(lockUnlock: boolean){
    if(lockUnlock) {
      this.lockUser(this.user);
    }
    else{
      this.unlockUser(this.user);
    }
  }

  disableUser(Ids: number[]) {
    this.subscriptions.push(this.disableUserFn(Ids).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`User disabled`),
      tap(r => {
        this.reload();
      })
    ).subscribe());
  }


  enableUser(Ids: number[]) {
    this.subscriptions.push(this.enableUserFn(Ids).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`User enabled`),
      tap(r => {
        this.reload();
      })
    ).subscribe());
  }

  isEnabledPismoAccessTableRowExpanded(element: UserandPriviledges['pismo'][number]) {
    return this.enabledPismoAcessTableExpandedElement == element;
  }

  onEnabledPismoAccessTableRowClicked(element: UserandPriviledges['pismo'][number]) {
    this.enabledPismoAcessTableExpandedElement = this.enabledPismoAcessTableExpandedElement == element ? null : element;
  }

  isDisabledPismoAccessTableRowExpanded(element: UserandPriviledges['pismo'][number]) {
    return this.disabledPismoAcessTableExpandedElement == element;
  }

  onDisabledPismoAccessTableRowClicked(element: UserandPriviledges['pismo'][number]) {
    this.disabledPismoAcessTableExpandedElement = this.disabledPismoAcessTableExpandedElement == element ? null : element;
  }

  disablePismoCustomer(element: UserandPriviledges['pismo'][number]) {
    // NOTE: when we are here all the form controls should be valid (not null)
    this.disableOverdraftUserFn(
      this.user.UserId,
      element.pismoAccountNumber,
    ).pipe(
      this.loader.disablePismoCustomer.track(),
      this.toastService.snackBarObservable(`Pismo customer info saved`),
      tap((a) => {
        this.reload();
      })
    ).subscribe();
  }

  savePismoCustomer(element: UserandPriviledges['pismo'][number]) {
    // NOTE: when we are here all the form controls should be valid (not null)
    const customerAccessLevelAndRoleAccess = this.customerAccessLevelRecords[element.pismoCustomerNumber].formControlAccessLevelAndRoleAccess;
    // const customerAccessLevel= this.customerAccessLevelRecords[element.pismoCustomerNumber].formControlAccessLevel.value!;
    // const isAllTransaction = this.customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsAllTransaction.value ?? false;
    // const isThirdPartyPayment = this.customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsThirdPartyPayment.value ?? false;
    // const isBpay = this.customerAccessLevelRecords[element.pismoCustomerNumber].formControlIsBpay.value ?? false;
    this.pismoUpdateUserAccessFn(
      this.user.UserId,
      element.pismoAccountNumber,
      element.pismoCustomerNumber, {
        RoleAccess: customerAccessLevelAndRoleAccess.value!.roleAccess,
        customerAccessLevel: customerAccessLevelAndRoleAccess.value!.customerAccessLevel,
      }).pipe(
        this.loader.savePismoCustomer.track(),
        this.toastService.snackBarObservable(`Pismo customer info saved`),
        tap((a)=>{
            this.reload();
        })
      ).subscribe();
  }

  allocatePismoAccountToUser() {
    this.applicationDialogService.openAllocateUserToPismoAccountDialog({
      overdraftAccountAvailableForUserFn: this.overdraftAccountAvailableForUserFn,
      userId: this.user.UserId,
    }).afterClosed().pipe(
      tap(v => {
        if (v && v.type == 'submit') {
          this.allocateUserToPismoAccountFn({
            userId: v.userId,
            pismoAccountNumber: v.account.pismoAccountNumber,
            cardType: v.card.type,
            monthlyLimit: v.monthlyLimit,
            customerAccessLevel: v.customerAccessLevel,
          }).pipe(
            this.loader.allocatingUserToPismoAccount.track(),
            this.toastService.snackBarObservable(
              `Allocated user ${this.user.UserId} to pismo account ${v.account.pismoAccountNumber}`),
            tap((v) => {
              // if(!v.status) { // error occurred
              //   this.applicationDialogService.openAlertDialog({
              //     message: `Error`,
              //     subMessage: v.message
              //   });
              // }
              this.reload();
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }
}
