import {validApplicationTypeGuard, validDirectSaleApplicationTypeGuard} from './abstract-application.guard';

export const validBusinessFinanceGuard = (isDirectSale: boolean = false) => {
  return isDirectSale ? validDirectSaleApplicationTypeGuard('BusinessLoans', 'e-not-business') : validApplicationTypeGuard('BusinessLoans', 'e-not-business');
}

// NOTE:
//import {Injectable} from '@angular/core';
//import {AbstractApplicationGuard} from './abstract-application.guard';
//import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
//
//
//@Injectable()
//export class ValidBusinessFinanceGuard extends AbstractApplicationGuard {
//
//  getApplicationType(): ApplicationTypes {
//    return 'BusinessLoans';
//  }
//
//  getApplicationTypeErrorCode(): string {
//    return 'e-not-business';
//  }
//
//}
