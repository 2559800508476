import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DisbursementAmountDialogData, DisbursementAmountDialogResult,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import _ from 'lodash';

@Component({
    templateUrl: './disbursement-amount.dialog.html',
    styleUrls: ['./disbursement-amount.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class DisbursementAmountDialog {
  formControlAmount!: FormControl<number | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DisbursementAmountDialogData,
              private dialogRef: MatDialogRef<DisbursementAmountDialog, DisbursementAmountDialogResult>,
              private formBuilder: FormBuilder) {
    const amount = _.round(this.data.amount ?? 0, 2);

    this.formControlAmount = this.formBuilder.control(amount, [Validators.required]);
  }

  onSubmit(confirmation: boolean) {
    console.log(typeof this.formControlAmount.value)
    this.dialogRef.close({
      readyForSubmission: confirmation,
      amount: this.formControlAmount.value ?? 0,
    })
  }
}
