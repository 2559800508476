<div class="risk-rating-table">
  <div gdArea="data" class="data">
    <!-- row 1 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">Dynamoney Risk Rating</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">
        {{this.growRiskRating.GetLabel()}}
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 2 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">ABN Age</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ abnAge }}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 3 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">GST Age</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.gstAge }}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 4 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">Adverse</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.adverse }}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 5 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">Property Ownership</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.propertyOwnership }}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 6 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="text">Exception to Policy</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.exceptionToPolicy }}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpen = true" (closed)="panelOpen = false">

        <!--row 7 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Creditor Watch Score</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.creditorWatchScore}}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <!--row 7 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Company Equifax Score</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.companyEquifaxScore }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <!--row 7 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Lowest Individual Equifax Score</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.lowestIndividualEquifaxScore
          }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>


        <!--row 8 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Highest Individual Equifax Score</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.highestIndividualEquifaxScore
          }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <!--row 9 -->
        @if (riskRatingData.lossReasonCategory !== '' && riskRatingData.creditDecisionFlag !== 'APPROVED') {
          <div>
            <div fxLayout="row" class="separator">
              <div class="mat-body width-50">
                <div class="text">Loss Reason Category</div>
              </div>
              <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.lossReasonCategory }}</div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>
        }


        <!--row 10 -->
        @if (riskRatingData.lossReasonDetails !=='' &&  riskRatingData.creditDecisionFlag !== 'APPROVED') {
          <div>
            <div fxLayout="row" class="separator">
              <div class="mat-body">
                <div class="text">Loss Reason Detail
                  <div class="mat-body loss-reason-text">{{ riskRatingData.lossReasonDetails }}</div>
                </div>
              </div>
              <mat-divider [inset]="true"></mat-divider>
            </div>
          </div>
        }

        <!--row 11 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Credit Decision Flag</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.creditDecisionFlag }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <!--row 12 -->
        <div fxLayout="row" class="separator">
          <div class="mat-body width-50">
            <div class="text">Closed Lost Datetime</div>
          </div>
          <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{ riskRatingData.closedLostDatetime }}</div>
        </div>
        <mat-divider [inset]="true"></mat-divider>

        <!--row 13 -->
        @if (riskRatingData.creditDecisionFlag == 'APPROVED') {
          <div fxLayout="row" class="separator">
            <div class="mat-body width-50">
              <div class="text">Approval Condition</div>
            </div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">
              <ul class="listClass">
                @for (i of riskRatingData.additionalSecurity.split('\n'); track i) {
                  <li>{{i}}</li>
                }
              </ul>
            </div>
          </div>
        }
        <mat-divider [inset]="true"></mat-divider>

        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (panelOpen) {
              Less Details
            }
            @if (!panelOpen) {
              More Details
            }
            <span class="mdi mdi-dots-horizontal"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>