import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {
  OverdraftAccountBalanceType,
  TransferredBalanceDialogResult,
  TransferredbalanceDialogData,
} from '@portal-workspace/grow-shared-library';
import { formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
    templateUrl: './transferred-balance.dialog.html',
    styleUrls: ['./transferred-balance.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, MatOptionModule, MatSelectModule]
})
export class TransferredBalanceDialog {
  formGroup!: FormGroup;
  formControlBalanceType!: FormControl<OverdraftAccountBalanceType | null>;
  formControlBalance!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TransferredbalanceDialogData,
    private dialogRef: MatDialogRef<TransferredBalanceDialog, TransferredBalanceDialogResult>,
    private formBuilder: FormBuilder) {
    this.formControlBalanceType = this.formBuilder.control(null, [Validators.required]);
    this.formControlBalance = this.formBuilder.control(null, [Validators.required, Validators.min(0.01)]);
    this.formGroup = this.formBuilder.group({
      balanceType: this.formControlBalanceType,
      balanceAmount: this.formControlBalance,
    })
  }

  ngOnInit() {
  }

  onSubmit(confirmation: boolean) {
    if (confirmation) {
      this.dialogRef.close(this.formGroup.value);
    } else {
      this.dialogRef.close();
    }
  }
}
