<div class="whitelabel-title-component">
  <mat-form-field>
    <mat-label>
      Title
    </mat-label>
    <input matInput [formControl]="formControl">
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
