import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {AssetCategoryType, compareMatch} from '@portal-workspace/grow-shared-library';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '../component-utils';
import {AssetCatAndTypeService} from './asset-cat-and-type.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AssetCategoryTypeSelectionValue} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from "../../directives/disable-control.directive";


@UntilDestroy()
@Component({
    selector: 'asset-type',
    templateUrl: './asset-category-type-selection.component.html',
    styleUrls: ['./asset-category-type-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetCategoryTypeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetCategoryTypeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class AssetCategoryTypeSelectionComponent extends AbstractControlValueAccessor<AssetCategoryTypeSelectionValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;


  compareWithFn: (option: AssetCategoryTypeSelectionValue, selected: AssetCategoryTypeSelectionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.index === selected.index);
  };

  formGroup: FormGroup<{
    selection: FormControl<AssetCategoryTypeSelectionValue>
  }>;
  formControl: FormControl<AssetCategoryTypeSelectionValue>

  subscription?: Subscription;
  formControlSubscription: Subscription;

  @Input({required: false}) key = 'key1';
  @Input({required: false}) categoryTypes: AssetCategoryType[] = []; // input - fixed category types
  assetCategoryTypes: AssetCategoryType[] = [];
  // @Input({required: false}) disabled: boolean = false;
  constructor(private formBuilder: FormBuilder,
              private service: AssetCatAndTypeService) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.formControlSubscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    if (this.categoryTypes && this.categoryTypes.length) { // category types is given, use this instead
      this.assetCategoryTypes = [...this.categoryTypes];
    } else {
      this.subscription = this.service.assetCategoryTypes(this.key).pipe(
        delay(0),
        tap(s => {
          this.assetCategoryTypes = s;
          if(this.assetCategoryTypes.length == 1){
            const t = this.assetCategoryTypes.find(t => t.index);
              if (t) {
                this.formControl.setValue(t);
                this.propagateChange(t);
              }
          }
          // if (this.v) {
          //   const t = this.assetCategoryTypes.find(t => t.index = this.v!.index);
          //   if (t) {
          //     this.formControl.setValue(t);
          //     this.propagateChange(t);
          //   }
          //   // this.v = null; // only run this blocks once
          // }
        })
      ).subscribe();
    }
  }

  // v: AssetCategorySelectionValue = null;
  doWriteValue(v: AssetCategoryTypeSelectionValue | null | undefined): void | AssetCategoryTypeSelectionValue {
    // this.v = v ? v : null;
    if (v) {
      const t = this.assetCategoryTypes.find(t => t.index === v.index);
      if (t) {
        this.formControl.setValue(t);
        return t;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
