export type LtvSelectionType = 'low' | 'medium' | 'high';

export type LtvSelectionValue = {
  type: LtvSelectionType,
  name: string,
} | null;

export const LtvSelectionValueOptions: Exclude<LtvSelectionValue, null>[] = [
  {type: 'low', name: 'below 70%'},
  {type: 'medium', name: '70% to 80%'},
  {type: 'high', name: 'above 80%'},
];
