import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '../component-utils';
import { loadingFor } from '@ngneat/loadoff';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { CreditAuditLog, PaginablePayloadApiResponse, DEFAULT_LIMIT, DEFAULT_OFFSET, GetCreditAuditLogFn, Application, } from '@portal-workspace/grow-shared-library';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'credit-audit-log',
    templateUrl: './credit-audit-log.component.html',
    styleUrls: ['./credit-audit-log.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatSortModule, FlexModule, CustomPaginatorComponent, TitleCasePipe, DatePipe]
})

export class CreditAuditLogComponent implements OnInit {
  displayedColumns: string[] = ['date', 'context', 'message', 'user'];
  dataSource: CreditAuditLog[] = [];
  subscriptions: Subscription[] = [];

  @Input({required: true}) application!: Application;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;

  loader = loadingFor('tableLoading');

  total = 0;
  offset = DEFAULT_OFFSET;
  limit = DEFAULT_LIMIT;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reset();
    this.reload();
  }


  ngOnChanges(){
    this.reload();
  }

  private reload() {
    if (this.application) {
      this.subscriptions.push(this.getCreditAuditLogFn(this.application.ApplicationId, {
        page: { limit: this.limit, offset: this.offset },
        filter: '',
      }).pipe(
        this.loader.tableLoading.track(),
        tap((r: PaginablePayloadApiResponse<CreditAuditLog>) => {
          this.total = r.total;
          this.dataSource = r.payload;
        })
      ).subscribe());
    }
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }
}
