import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  CustomerOpportunity,
  CustomerUser,
  GetAccountDetailsFromSfFn,
  GetOpportunitiesForCustomerFn,
  SfAccountDetails,
  PismoAccountMapping,
  PismoProgram,
  GetRbaRateFn,
  OverdraftAccountLimitIncreaseDialogData,
  OverdraftAccountLimitIncreaseDialogResult,
  GetCustomerFn,
  PismoGetAccountResponse,
  OverdraftAccountLimitIncreaseFn,
  OverdraftAccountLimitIncreaseResponse,
  PayloadApiResponse,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatChipsModule } from '@angular/material/chips';
import { combineLatest, debounceTime, Subscription, tap } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTableModule } from '@angular/material/table';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet, DecimalPipe } from '@angular/common';
import { ApplicationStepper2Component } from '../application-stepper-component/application-stepper2.component';
import {
  setupUntilDestroy,
  PortalHotToastService,
  ApplicationDialogService,
  setPrevNextButtonVisibilityFn, setSecondaryButtonFn, setSecondaryButtonText,
  setStepper2StepConfig,
  formControlErrorKeys, formControlErrorMessage,
} from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BpayBankDetailsComponent } from '../bpay-bank-details-component/bpay-bank-details.component';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import {PercentagePipe} from '../../pipes/percentage.pipe';
@UntilDestroy()
@Component({
    templateUrl: './overdraft-account-limit-increase.dialog.html',
    styleUrls: ['./overdraft-account-limit-increase.dialog.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, MatOptionModule, MatSelectModule, NgTemplateOutlet, FormsModule, MatButtonModule, ReactiveFormsModule, FlexModule, MatTooltipModule, MatFormFieldModule, MatInputModule, InputMaskModule, MatTableModule, MatCheckboxModule, MatChipsModule, PdfViewerModule, DecimalPipe, LooseCurrencyPipe, MatDialogModule, BpayBankDetailsComponent, LocalBankAccountDetailComponent,PercentagePipe]
})
export class OverdraftAccountLimitIncreaseDialog implements OnInit{

  getOpportunitiesForCustomerFn!: GetOpportunitiesForCustomerFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  // getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
  getRbaRateFn!: GetRbaRateFn;
  getCustomerFn!: GetCustomerFn;
  overdraftAccountLimitIncreaseFn!: OverdraftAccountLimitIncreaseFn;
  customer: CustomerUser | null = null;
  opportunitySelections: CustomerOpportunity[] = [];
  programSelections : PismoProgram[] = [];
  subscriptions: Subscription[] = [];
  existingPismoAccountMappings: PismoAccountMapping[] = [];
  customerSfDetails!: SfAccountDetails;
  brokerSfDetails!: SfAccountDetails;
  pismoAccountDetails!: PismoGetAccountResponse;
  
   
  setSecondaryButtonText = setSecondaryButtonText;
 

  setSecondaryButtonFn = setSecondaryButtonFn;
  setPrevNextButtonVisibilityFn = setPrevNextButtonVisibilityFn;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  // step 1
  formGroupStep1!: FormGroup;
  formControlStep1Opportunity!: FormControl<CustomerOpportunity | null>;

  // step 2
  formGroupStep2!: FormGroup;
  formControlStep2OverdraftLimit!: FormControl<number | null>;
  formControlStep2Rate!: FormControl<number | null>;
  formControlStep2Margin!: FormControl<number | null>;
  formControlStep2DocFee!: FormControl<number| null>;
  formControlStep2DocFeeFinanced!: FormControl<boolean | null>;
  formControlStep2FacilityEstablishmentFee!: FormControl<number| null>;
  formControlStep2Brokerage!: FormControl<number| null>;
  formControlStep2CustomerName!: FormControl<string | null>;
  formControlStep2CustomerAccountName!: FormControl<string | null>;
  formControlStep2CustomerBsb!: FormControl<string | null>;
  formControlStep2CustomerAccountNumber!: FormControl<string | null>;
  formControlStep2CustomerFinancialInstitutionName!: FormControl<string | null>;
  formControlStep2BrokerName!: FormControl<string | null>;
  formControlStep2BrokerAccountName!: FormControl<string | null>;
  formControlStep2BrokerBsb!: FormControl<string | null>;
  formControlStep2BrokerAccountNumber!: FormControl<string | null>;
  formControlStep2BrokerFinancialInstitutionName!: FormControl<string | null>;
  feePostingStatusStep2 = false;
  limitIncreaseResponse!: OverdraftAccountLimitIncreaseResponse;

  // step 3
  formGroupStep3!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OverdraftAccountLimitIncreaseDialogData,
    private dialogRef: MatDialogRef<OverdraftAccountLimitIncreaseDialog, OverdraftAccountLimitIncreaseDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
      this.getOpportunitiesForCustomerFn = data.getOpportunitiesForCustomerFn;
      this.getAccountDetailsFromSfFn = data.getAccountDetailsFromSfFn;
      this.getRbaRateFn = data.getRbaRateFn;
      this.getCustomerFn = data.getCustomerFn;
      this.overdraftAccountLimitIncreaseFn = data.overdraftAccountLimitIncreaseFn;
      this.pismoAccountDetails = data.pismoAccountDetails;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.initStep1();
    this.initStep2();
    this.initStep3();
    this.populate();
  }

  populate() {
    this.subscriptions.push(this.getCustomerFn(this.data.customerId).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
       this.customer = r;
       if (this.customer && this.customer.SalesforceId) {
           this.subscriptions.push(
             this.getOpportunitiesForCustomerFn(this.customer.SalesforceId).pipe(
                 this.toastService.spinnerObservable(),
             ).subscribe((opportunities: CustomerOpportunity[]) => {
               this.opportunitySelections = opportunities.filter(o => o.RecordTypeId === this.data.overdraftCustomerSfRecordId && o.Stage === this.data.overdraftSfOpportunityStage);
               console.log('this.opportunitySelections: ', this.opportunitySelections);
             })
           )
       }
     })
    ).subscribe());
  }

  initStep1() {
    this.formControlStep1Opportunity = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      opportunity: this.formControlStep1Opportunity,
    });

    setStepper2StepConfig(this.formGroupStep1, {
      previousStepButtonText:"Close",
      previousStepClickedFn: async (stepper) => {
        this.onClickClose()
      },
      nextStepClickedFn: async stepper => {
        if (this.formGroupStep1.valid) {
          const opportunity = this.formControlStep1Opportunity.value as CustomerOpportunity;
  
          this.subscriptions.push(
            combineLatest([
              this.getAccountDetailsFromSfFn(this.customer?.SalesforceId ?? ''),
              this.getAccountDetailsFromSfFn(this.formControlStep1Opportunity.value?.BrokerSalesforceId ?? ''),
              this.getRbaRateFn(),
            ]).pipe(
              this.toastService.spinnerObservable(),
            ).subscribe(([customerDetails, brokerDetails, rbaRate]: [SfAccountDetails, SfAccountDetails, number]) => {
              console.log('======customersfDetails: ', customerDetails);
              console.log('======brokerDetails: ', brokerDetails);
              console.log('======rbaRate: ', rbaRate);
              this.customerSfDetails = customerDetails;
              this.brokerSfDetails = brokerDetails;
              this.formControlStep2CustomerName.setValue(customerDetails.Name);
              // use bank details on the opportunity level
              if (opportunity.BankAccountNumber) {
                this.formControlStep2CustomerAccountName.setValue(opportunity.BankAccountName);
                this.formControlStep2CustomerBsb.setValue(opportunity.BankAccountBsb);
                this.formControlStep2CustomerAccountNumber.setValue(opportunity.BankAccountNumber);
                this.formControlStep2CustomerFinancialInstitutionName.setValue(opportunity.FinancialInstitutionName);
              } else { // if no bank details on the opportunity level, we use the bank details on account level
                this.formControlStep2CustomerAccountName.setValue(customerDetails.AccountName);
                this.formControlStep2CustomerBsb.setValue(customerDetails.BSB);
                this.formControlStep2CustomerAccountNumber.setValue(customerDetails.AccountNumber);
                this.formControlStep2CustomerFinancialInstitutionName.setValue(customerDetails.InstitutionName);
              }
              // if bank details are on account level, sync it to customer level
              // if (opportunity.BankAccountNumber && customerDetails.Id) {
              //   this.syncBankDetailsToSfFn({
              //     salesforceId: customerDetails.Id,
              //     financialInstitution: opportunity.FinancialInstitutionName,
              //     bankAccountName: opportunity.BankAccountName,
              //     bankAccountBsb: opportunity.BankAccountBsb,
              //     bankAccountNumber: opportunity.BankAccountNumber,
              //   }).subscribe()
              // }
  
              this.formControlStep2BrokerName.setValue(brokerDetails.Name);
              this.formControlStep2BrokerAccountName.setValue(brokerDetails.AccountName);
              this.formControlStep2BrokerBsb.setValue(brokerDetails.BSB);
              this.formControlStep2BrokerAccountNumber.setValue(brokerDetails.AccountNumber);
              this.formControlStep2BrokerFinancialInstitutionName.setValue(brokerDetails.InstitutionName);
  
              this.formControlStep2OverdraftLimit.setValue(opportunity.Amount);
              this.formControlStep2Margin.setValue(opportunity.Margin);
              this.formControlStep2Rate.setValue((opportunity.Margin ?? 0) + rbaRate);
              this.formControlStep2DocFee.setValue(opportunity.DocFee);
              this.formControlStep2DocFeeFinanced.setValue(opportunity.DocFeeFinanced);
              this.formControlStep2FacilityEstablishmentFee.setValue(opportunity.FacilityEstablishmentFee);
              this.formControlStep2Brokerage.setValue(opportunity.Brokerage);
              stepper.next();
            })
          )
        }
      },
    });

    
    this.setPrevNextButtonVisibilityFn(this.formGroupStep1, false, true);
  }

  initStep2() {
    this.formControlStep2OverdraftLimit = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Rate = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Margin = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DocFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2FacilityEstablishmentFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Brokerage = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerAccountName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerBsb = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerAccountNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerFinancialInstitutionName = this.formBuilder.control(null);
    this.formControlStep2BrokerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerAccountName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerBsb = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerAccountNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerFinancialInstitutionName = this.formBuilder.control(null);

    this.formGroupStep2 = this.formBuilder.group({
      limitIncrease: this.formControlStep2OverdraftLimit,
      rate: this.formControlStep2Rate,
      margin: this.formControlStep2Margin,
      docFee: this.formControlStep2DocFee,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
      facilityEstablishmentFee: this.formControlStep2FacilityEstablishmentFee,
      brokerage: this.formControlStep2Brokerage,
      //customerName: this.formControlStep2CustomerName,
      customerAccountName: this.formControlStep2CustomerAccountName,
      customerBsb: this.formControlStep2CustomerBsb,
      customerAccountNumber: this.formControlStep2CustomerAccountNumber,
      customerFinancialInstitutionName: this.formControlStep2CustomerFinancialInstitutionName,
      brokerName: this.formControlStep2BrokerName,
      brokerAccountName: this.formControlStep2BrokerAccountName,
      brokerBsb: this.formControlStep2BrokerBsb,
      brokerAccountNumber: this.formControlStep2BrokerAccountNumber,
      brokerFinancialInstitutionName: this.formControlStep2BrokerFinancialInstitutionName,
    });

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        console.log(this.formGroupStep2.value);
          if (!this.customer) {
            this.applicationDialogService.openAlertDialog({
              message: `Warning`,
              subMessage: `Failed to load customer details.`,
            })
            return;
          }

          this.increaseAccountLimit(stepper);
      },
    });

    
   
  }

  initStep3() {
    this.formGroupStep3 = this.formBuilder.group({});
    this.setPrevNextButtonVisibilityFn(this.formGroupStep3, false, true);
    setStepper2StepConfig(this.formGroupStep3, {
      nextStepButtonText: 'Got it',
      nextStepClickedFn: async stepper => {
        this.dialogRef.close({
          readyForSubmission: true
        });
      },
    });
    
  }

  onClickClose() {
    this.dialogRef.close();
  }

  increaseAccountLimit(stepper: CdkStepper) {
    if (this.customer && this.formControlStep1Opportunity.value) {
      this.subscriptions.push(
        this.overdraftAccountLimitIncreaseFn({
          data: this.formGroupStep2.value,
          pismoAccountId: this.pismoAccountDetails.account_id,
          pismoAccountDetails: this.pismoAccountDetails,
          customer: this.customer,
          opportunityId: (this.formControlStep1Opportunity.value as CustomerOpportunity).SalesforceId,
        }).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((result: PayloadApiResponse<OverdraftAccountLimitIncreaseResponse>) => {
          console.log('=====result: ', result);
          if (result) {
            this.feePostingStatusStep2 = result.status;
            this.limitIncreaseResponse = result.payload;
          }
          stepper.next();
        })
      )
    } else {
      this.applicationDialogService.openAlertDialog({
        message: `Warning`,
        subMessage: `You cannot increase the account limit because you have missing information.`,
      });
    }
  }

  get failedFeePostingMessage() {
    if (!this.feePostingStatusStep2 && this.limitIncreaseResponse) {
      const failedFees = [];
      for (const key of Object.keys(this.limitIncreaseResponse)) {
        if (!!!(this.limitIncreaseResponse as any)[key]) {
          failedFees.push(key);
        }
      }

      return failedFees.join(', ');
    } 

    return '';
  }
}
