import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Observable, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { CompaniesSearchComponentValue, compareMatch } from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Router} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import { navigationUrlForAccreditation } from 'apps/portal2/src/app/service/navigation-urls';

export interface CompaniesSearchFn {
  (name: string): Observable<Exclude<CompaniesSearchComponentValue, null>[]>;
}

@UntilDestroy()
@Component({
    selector: 'companies-search',
    templateUrl: './companies-search.component.html',
    styleUrls: ['./companies-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CompaniesSearchComponent), multi: true },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatTooltipModule, DisableControlDirective, MatOptionModule, MatIconModule]
})
export class CompaniesSearchComponent extends AbstractControlValueAccessor<CompaniesSearchComponentValue> implements OnInit, OnChanges, ControlValueAccessor {

  formGroup: FormGroup<{
    companySearch: FormControl<string|CompaniesSearchComponentValue>
  }>;
  formControlCompanySearch: FormControl<string|CompaniesSearchComponentValue>;

  value?: CompaniesSearchComponentValue;

  subscription!: Subscription;

  filteredCompanies: Exclude<CompaniesSearchComponentValue, null>[] = [];

  @Input({required: true}) searchFn!: CompaniesSearchFn;
  displayWithFn: (value: CompaniesSearchComponentValue) => string;
  @Input({required: false}) allowCancellation = false;
  selected: boolean = false;
  @Input({required: false}) mandatory = true;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    super();
    this.displayWithFn = (company) => {
      if (company) {
        if (company.abn) {
          return `${company.entityName ?? 'No entity name'} (ABN: ${company.abn})`;
        }
        return `${company.entityName ?? 'No entity name'} (ABN: Not available)`;
      }
      return '';
    }
    this.formControlCompanySearch = formBuilder.control('', [Validators.required]);
    this.formGroup = formBuilder.group({
      companySearch: this.formControlCompanySearch,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes as any).mandatory) {
      const simpleChange = (changes as any).mandatory;
      const currentValue = simpleChange.currentValue as boolean;
      if (currentValue) {
        this.formControlCompanySearch.setValidators([Validators.required]);
      } else {
        this.formControlCompanySearch.clearValidators();
      }
      this.formControlCompanySearch.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscription = this.formControlCompanySearch.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter((r: string|CompaniesSearchComponentValue) => typeof r === 'string'),
      switchMap(r => {
        if (r === '') {
          this.propagateChange(null);
          return of([]);
        } else {
          return this.searchFn(r as string);
        }
      }),
      tap(r => {
        // this.filteredCompanies = (!!!r ? [] as any[] : r) ;
        this.filteredCompanies = r ?? [];
      })
    ).subscribe();
  }

  // setDisabledState(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  //   if (isDisabled) {
  //     this.formControlCompanySearch.disable()
  //   } else {
  //     this.formControlCompanySearch.enable();
  //   }
  // }


  doWriteValue(v: CompaniesSearchComponentValue | undefined): void | CompaniesSearchComponentValue {
    this.value = v;
    return undefined;
  }

  onCompanySelected($event: MatAutocompleteSelectedEvent) {
    const companySelected: CompaniesSearchComponentValue = $event.option.value;
    // this.onSelected.emit(companySelected)
    this.propagateChange(companySelected);
    this.selected = true;
  }

  clearValue(){
    this.filteredCompanies=[]
    this.formGroup.reset();
    // this.onSelected.emit(null);
    this.propagateChange(null);
  }

  getTooltips() {
    if (this.formControlCompanySearch.value) {
      return `${(this.formControlCompanySearch.value as CompaniesSearchComponentValue)?.entityName ?? ''} ABN : ${(this.formControlCompanySearch.value as CompaniesSearchComponentValue)?.abn ?? ''}`;
    }
    return '';
  }

  async navigateToAccreditation() {
    await this.router.navigate(navigationUrlForAccreditation());
  }

}
