import {Customer} from "./types.domain";
export interface SupplierTransactionData {
  transactionList: SupplierTransaction[],
  limitAmount: number,
  // totalBalance: number,
  availableBalance: number,
  effectiveTotalBalance: number,
  effectiveAvailableBalance: number,
}

export interface SupplierTransaction { SupplierId: number, supplierName: string, amount: number, balancePayable: number, overDue: number }

export type CustomerData = Customer & {Name: string, Email: string};
export interface SalesforceCustomerRecord {
  Tradelimit__c: number,
  ec1_ABN__c: string,
  ec1_ACN__c: string,
  Bank_Account_BSB__c: string,
  Bank_Account_Name__c: string,
  Bank_Account_Number__c: string,
  Broker__c: string,
  Floorplan_Limit__c: number,
  Floorplan_Balance__c: number,
  Trade_Current_Outstanding__c: number,
  Floorplan_Finance__c: boolean,
  Insurance_Premium_Finance__c: boolean,
  Invoice_Finance__c: boolean,
  Trade_Finance__c: boolean,
  Asset_Finance__c: boolean,
  Business_Loans__c: boolean,
  Active__c: boolean,
  RecordTypeId: string,
  Name: string,
  Interflow_Entity_Type__c: string,
  BillingStreet: string,
  BillingCity: string,
  BillingState: string,
  BillingPostalCode: string,
}
export interface SyncSalesforceAccountCallback {
  Id: string,
  ec1_ABN__c: string,
  Name: string,
  OwnerId: string | null,
  RecordTypeId: string,
  ec1_Accredited__c: boolean,
  Aggregator__c?: string,
  ec1_RelationshipType__c?: string,
  ec1_ACN__c: string | null,
  Website?: string,
  Phone?: string,
  Active__c: boolean,
  AnnualRevenue: number | null,
  ec1_ANZSICCode__c: string | null,
  Bank_Account_BSB__c: string | null,
  Bank_Account_Name__c: string | null,
  Bank_Account_Number__c: string | null,
  Broker__c: string | null,
  ec1_Email__c: string,
  Insurance_Premium_Finance__c: boolean,
  Invoice_Finance__c: boolean,
  Trade_Finance__c: boolean,
  Asset_Finance__c: boolean,
  Business_Loans__c: boolean,
  Tradelimit__c: number,
  Trade_Current_Outstanding__c: number,
  PayWay_ID__c: string,
  BillingStreet: string,
  BillingCity: string,
  BillingState: string,
  BillingPostalCode: string,
  ec1_EntityType__c: string | null, // SME ($1m+ Revenue | $0.5, to $5m Capital)
  Interflow_Entity_Type__c: string | null,
  'PrimaryContact__r.Email': string | null,
  'PrimaryContact__r.MobilePhone': string | null,
  PrimaryContact__r: {
    Email: string,
    MobilePhone: string | null,
  } | null,
  'PrimaryContact.FirstName': string | null,
  'PrimaryContact.LastName': string | null,
  PrimaryContact: {
    FirstName: string,
    LastName: string,
  } | null,
  Floorplan_Finance__c: boolean,
  Floorplan_Limit__c: number | null,
  Floorplan_Balance__c: number | null,
  CreatedById: string,
  Credit_Rating__c: string | null, // B+
  NumberOfEmployees: number | null,
  EntityKey__c: number | null,
  ec1_AccFiscalCode__c: string | null,
  Hardship_Claim__c: boolean,
  ec1_Industry__c: string | null, // A
  Interfi_Party_Id__c: string | null,
  Interfi_Party_Object_ID__c: string | null,
  LastModifiedById: string | null,
  PrimaryContact__c: string | null,
  Primary_Contact_Email__c: string | null,
  Private_Seller: boolean,
  RCTI__c: boolean,
  Dialpad__TotalNumberOfTimesDialed__c: number | null,
  ec1_TradingName__c: string | null,
  ec1_Trust__c: boolean,
  Type: string | null,  // Customer
  Private_Seller__c: boolean | null,
  Financial_Institution_Name__c: string | null,
}

export interface SyncSfUserCallback {
  Id: string, // sf owner id
  FirstName: string,
  MiddleName: string,
  LastName: string,
  Email: string,
  IsActive: boolean,
  MobilePhone: string,
}

export interface GetCustomerFromSalesforceResult {
  totalSize: number,
  done: boolean,
  records: {
    attributes: {
      type: string,
      url: string,
    },
    Id: string,
    ec1_ABN__c: string,
    Name: string,
    RecordTypeId: string,
    ec1_ACN__c: string,
    Active__c: boolean,
    Bank_Account_BSB__c: string | null,
    Bank_Account_Name__c: string | null,
    Bank_Account_Number__c: string | null,
    Broker__c: string | null,
    ec1_Email__c: string,
    Insurance_Premium_Finance__c: boolean,
    Invoice_Finance__c: boolean,
    Trade_Finance__c: boolean,
    Asset_Finance__c: boolean,
    Business_Loans__c: boolean,
    Tradelimit__c: number,
    Trade_Current_Outstanding__c: number,
    PayWay_ID__c: string,
    BillingStreet: string,
    BillingCity: string,
    BillingState: string,
    BillingPostalCode: string,
    Interflow_Entity_Type__c: string | null,
    PrimaryContact__r: {
      attributes: {
        type: string,
        url: string,
      },
      FirstName: string,
      LastName: string,
      MobilePhone: string | null,
      // PhoneNumber: string | null,
    } | null,
    Floorplan_Finance__c: boolean,
    Floorplan_Limit__c: number | null,
    Floorplan_Balance__c: number | null,
  }[],
}
export interface FileUploadToInteflowInput {
  Title: string,                      // file name
  PathOnClient: string,               // file name
  Description: string,                // description eg. 'Uploaded from Dynamoney.app'
  FirstPublishLocationId: string | null | undefined,     // salesforceId
  VersionData: string,                // signedUrl
}


