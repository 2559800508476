import { Component, Input, OnInit } from '@angular/core';
import { Application } from '@portal-workspace/grow-shared-library';
import {
  applicationToWhatBusinessBuy,
  applicationToWhatBusinessSell,
  applicationToWhatBussinessSellTo
} from '@portal-workspace/grow-ui-library';
import { CellComponent } from '../../application-summary-component/cell.component';



@Component({
    selector: 'corporate-loans-background-details',
    templateUrl: './corporate-loans-background-details.component.html',
    styleUrls: ['./corporate-loans-background-details.component.scss'],
    standalone: true,
    imports: [CellComponent]
})
export class CorporateLoansBackgroundDetailsComponent implements OnInit {

  @Input({required: true}) application!: Application;

  background?: {
    whatDoesTheBusinessSell: string,
    whatDoesTheBusinessSellTo: string,
  };

  ngOnInit(): void {
    this.background = {
      whatDoesTheBusinessSell: applicationToWhatBusinessSell(this.application) ?? '',
      whatDoesTheBusinessSellTo: applicationToWhatBussinessSellTo(this.application) ?? '',
    }

    // NOTE: we don't need background, got to remove all background related changes
    // MOTE: Background should never be string, if it is we did something wrong.
    // if (typeof this.applicationBackground.Background == "string") {
    //   this.background = this.applicationBackground.Background ?? '';
    //   this.data = (JSON.parse(this.background));
    // }
  }



}
