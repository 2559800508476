<div class="document-worklist-page container-fluid">
  <div class="row separator-bottom">
    <div class="col-12">
      @if (menuItemId === 'credit-worklist') {
        <div class="mat-h3">Credit Worklist</div>
      }
      @if (menuItemId === 'settlements-worklist') {
        <div class="mat-h3">Settlements Worklist</div>
      }
      @if (menuItemId === 'client-services-worklist') {
        <div class="mat-h3">Client Services Worklist</div>
      }
      @if (menuItemId !== 'client-services-worklist' && menuItemId !== 'credit-worklist' && menuItemId !== 'settlements-worklist') {
        <div class="mat-h3">Document Worklist</div>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <input matInput type="text" [readonly]="loader.tableLoading.inProgress$ | async" [formControl]="formControlSearch" placeholder="Search worklist...">
        <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
    </div>
  </div>

  <div class="row auto-assignment">
    <div class="col-12">
      <div fxLayoutAlign="start start" fxLayout="column wrap">
        <mat-checkbox [formControl]="formControlAutoAssignment" color="primary">Auto assignment</mat-checkbox>
        <span class="date-text">
          When auto assignment is checked, after a user double clicks an unassigned application, the user will become the assignee
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      @if (loader.tableLoading.inProgress$ | async) {
        <custom-content-loader></custom-content-loader>
      }

      <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
        <table mat-table [dataSource]="dataSource" class="width-100 mb-2"
          [ngClass]="{'table-hover': formControlAutoAssignment.value}" matSort (matSortChange)="onSortData($event)">
          <ng-container matColumnDef="applicationId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="brokerAppId" style="width: 12%;">App ID</th>
            <td mat-cell *matCellDef="let element">{{element.brokerAppId}}</td>
          </ng-container>
          <ng-container matColumnDef="createdTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdTime" style="width: 16%;">Time Entered</th>
            <td mat-cell *matCellDef="let element">
              <span class="time-entered">{{element.createdTime | date:'dd/MM/yyyy h:mm:ss a'}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" style="width: 35%;">Name</th>
            <td mat-cell *matCellDef="let element" class="customer-name">
              <span>{{element.name}}</span><br/>
              @if (element.brokerName) {
                <span class="date-text">{{element.brokerName}}</span>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="stage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="stage" style="width: 17%;">Stage</th>
            <td mat-cell *matCellDef="let element">
              <tag-box [state]="element.stage"></tag-box>
            </td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="userName" style="width: 14%;">Assign to</th>
            <td mat-cell *matCellDef="let element">{{element.userName}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 7%; padding-right: 4px!important;">Actions</th>
            <td mat-cell *matCellDef="let element" style="padding-right: 0!important;">
              <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="menu-items" (click)="takeOwnership(element)">Take Ownership</button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="menu-items" (click)="onAssign(element)">Assign</button>
                <mat-divider></mat-divider>
                <button mat-menu-item [disabled]="!element.assignedTo" class="menu-items" (click)="unassign(element)">Unassign</button>
                <mat-divider></mat-divider>
                <button mat-menu-item class="menu-items" (click)="complete(element)">Complete</button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns"  (dblclick)="autoAssign(row)"></tr>
        </table>
        @if (total) {
          <app-custom-paginator
            [length]="total"
            [pageIndex]="offset"
            [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]"
            (page)="onPagination($event)"
          ></app-custom-paginator>
        }
      </div>
    </div>
  </div>

</div>

