import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '../component-utils';
import { loadingFor } from '@ngneat/loadoff';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ApplicationAuditLog, PaginablePayloadApiResponse, DEFAULT_LIMIT, DEFAULT_OFFSET, } from '@portal-workspace/grow-shared-library';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

export type GetApplicationAuditLogsFn = (limit: number, offset: number, applicationId: number) => Observable<PaginablePayloadApiResponse<ApplicationAuditLog>>;

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'audit-log',
    templateUrl: './audit-log.component.html',
    styleUrls: ['./audit-log.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatSortModule, FlexModule, CustomPaginatorComponent, TitleCasePipe, DatePipe]
})

export class AuditLogComponent implements OnInit {
  displayedColumns: string[] = ['date', 'field', 'oldValue', 'newValue', 'user', 'assetNumber'];
  dataSource: any;
  subscriptions: Subscription[] = [];

  @Input({required: true}) isAssetUpdated!: Boolean;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;

  loader = loadingFor('tableLoading');

  total = 0;
  offset = DEFAULT_OFFSET;
  limit = DEFAULT_LIMIT;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reset();
    this.reload();
  }


  ngOnChanges(){
    // console.log('this is the value changes in the audit log', this.isAssetUpdated);
    if(this.isAssetUpdated){
      this.reload();
    }
  }

  private reload() {
    const applicationId = this.route.snapshot.paramMap.get('applicationId') || ''
    this.subscriptions.push(this.getApplicationAuditLogsFn(this.limit, this.offset, parseInt(applicationId)).pipe(
      this.loader.tableLoading.track(),
      tap((r: PaginablePayloadApiResponse<ApplicationAuditLog>) => {
        this.total = r.total;
        this.dataSource = r.payload;
      })
    ).subscribe());
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }
}
