<div class="promotion-page container-fluid">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
          <mat-tab label="LOGIN PAGE">
            <div class="row mb-4 mt-4">
              <div class="col-lg-6 col-md-12">
                <div fxLayout="column" fxLayoutGap="5px">
                  <mat-checkbox [formControl]="formControlShowPromo" color="primary">Show Promo Message</mat-checkbox>
                </div>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row mb-4 ml-0 mt-4">
              <div class="col-lg-6 col-md-12">
                    <span class="mat-body-strong">
                        Promo Message Details
                    </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Promo Message</mat-label>
                  <textarea [formControl]="formControlPromotionMessage" matInput
                            placeholder="Enter message to be shown"
                            [required] = "false"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Link of Message</mat-label>
                  <input [formControl]="formControlPromotionLink" type="text" matInput
                         placeholder="Enter link of message to be redirected"
                         [required] = "false">
                </mat-form-field>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </mat-tab>

          <mat-tab label="APPLICATION LISTING PAGE">
            <div class="row mb-4 mt-4">
              <div class="col-lg-6 col-md-12">
                <div fxLayout="column" fxLayoutGap="5px">
                  <mat-checkbox [formControl]="formControlShowPromo" color="primary">Show Promo Message</mat-checkbox>
                </div>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div class="row mb-4 ml-0 mt-4">
              <div class="col-lg-6 col-md-12">
                <span class="mat-body-strong">
                    Promo Message Details
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Promo Message</mat-label>
                  <textarea [formControl]="formControlPromotionMessage" matInput placeholder="Enter message to be shown"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Link of Message</mat-label>
                  <input type="text" [formControl]="formControlPromotionLink"  matInput placeholder="Enter link of message to be redirected">
                </mat-form-field>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-3">
          <div class="col-12"
               fxLayout="row"
               fxLayoutGap="10px"
               fxLayoutAlign="space-between center"
               fxLayout.lt-md="column"
               fxLayoutAlign.lt-md="center start">
            <button mat-stroked-button color="primary" class="w-lt-md-100">Cancel</button>
            <button mat-flat-button color="primary" class="w-lt-md-100" (click)="savePromotion()">Save</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
</div>
