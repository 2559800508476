import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {
  PortalHotToastErrorTemplate,
  PortalHotToastInfoTemplate,
  PortalHotToastRetryableErrorTemplate,
  PortalHotToastRetryableErrorTemplate2,
  PortalHotToastRetryableSuccessTemplate,
  PortalHotToastService,
  PortalHotToastSuccessTemplate,
  PortalHotToastWarnTemplate
} from './hot-toast.service';
import {MessageBoxModule} from '../message-box/message-box.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MessageBoxModule,
        AngularMaterialLibraryModule,
        PipeLibraryModule,
        DirectiveLibraryModule,
        PortalHotToastInfoTemplate,
        PortalHotToastSuccessTemplate,
        PortalHotToastWarnTemplate,
        PortalHotToastErrorTemplate,
        PortalHotToastRetryableErrorTemplate,
        PortalHotToastRetryableErrorTemplate2,
        PortalHotToastRetryableSuccessTemplate
    ],
    exports: [],
    providers: [
        { provide: PortalHotToastService, useClass: PortalHotToastService },
    ]
})
export class PortalHotToastModule {

}
