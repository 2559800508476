<div class="privacy-consent-page container">
  @if (!privacyConsent) {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Invalid link">
          <div>Please use a valid link to access your privacy consent</div>
        </message-box>
      </div>
    </div>
  }

  @if (privacyConsent) {
    <div class="row separator-bottom">
      <div class="col-12">
        <span class="mat-h2 title">Dynamoney Privacy Consent</span>
      </div>
    </div>
    @if (privacyConsent.status === 'accepted') {
      <div class="row separator-bottom">
        <div class="col-lg-9 col-md-12">
          <message-box type="success" title="Thank you for accepting the Dynamoney Privacy Consent.">
          </message-box>
        </div>
      </div>
    }
    @if (privacyConsentBuffer) {
      <div class="row separator-bottom">
        <div class="col-12">
          <pdf-viewer [src]="privacyConsentBuffer"
            [original-size]="false"
            [zoom]="zoom"
            class="viewer"
            [ngClass]="{'large-viewer': showLargeViewer, 'medium-viewer': showMediumViewer, 'small-viewer': showSmallViewer, 'xsmall-viewer': showXsmallViewer}"
          ></pdf-viewer>
        </div>
      </div>
    }
    @if (privacyConsent.status !== 'accepted') {
      <div class="row separator-bottom">
        <div class="col-12">
          <mat-checkbox color="primary" [formControl]="formControlAgree">
            I agree to the Dynamoney Privacy Consent and Disclosure Statement
          </mat-checkbox>
        </div>
      </div>
    }
    <div class="row separator-bottom">
      <div class="col-12" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button color="primary" (click)="onDownload()">Download</button>
        @if (privacyConsent.status !== 'accepted') {
          <button mat-flat-button [disabled]="!formControlAgree.value" color="primary" (click)="onAccept()">Accept</button>
        }
      </div>
    </div>
  }
</div>
