export type StateSelectionType = 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
export type StateSelectionValue  = {
  type: StateSelectionType,
  name: string;
} | null;


export const StateSelectionValueOptions: Exclude<StateSelectionValue, null>[] = [
  {type: 'ACT',     name: 'ACT'},
  {type: 'NSW',     name: 'NSW'},
  {type: 'NT',      name: 'NT'},
  {type: 'QLD',     name: 'QLD'},
  {type: 'SA',      name: 'SA'},
  {type: 'TAS',     name: 'TAS'},
  {type: 'VIC',     name: 'VIC'},
  {type: 'WA',      name: 'WA'},
];
