import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {compareMatch, OriginatorTypeValue, OriginatorTypeValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { DisableControlDirective } from '../../directives/disable-control.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'originator-type',
    templateUrl: './originator-type.component.html',
    styleUrls: ['./originator-type.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OriginatorTypeComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, DisableControlDirective, MatOptionModule]
})
export class OriginatorTypeComponent extends AbstractControlValueAccessor<OriginatorTypeValue> implements OnInit {

  options = OriginatorTypeValueOptions;

  subscriptions: Subscription[] = [];

  formControl: FormControl<OriginatorTypeValue|null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: OriginatorTypeValue) => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: OriginatorTypeValue | null | undefined): void | OriginatorTypeValue {
    if (v) {
      const o = OriginatorTypeValueOptions.find(opt => opt.type == v.type);
      if (o) {
        this.formControl.setValue(o);
      }
    }
    return undefined;
  }
}
