import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, ValidatorFn, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { createMobilePhoneNumberInputMask,createPhoneNumberInputMask, formControlErrorKeys, formControlErrorMessage, phoneValidator } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, MobileValue} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

export interface MobileComponentEvent {
  errors: ValidationErrors | null;
  value: MobileValue,
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'mobile',
    templateUrl: './mobile.component.html',
    styleUrls: ['./mobile.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MobileComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => MobileComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective]
})
export class MobileComponent extends AbstractControlValueAccessor<MobileValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: false}) validators: ValidatorFn [] = [];
  @Input({required: false}) title = 'Mobile number';
  @Input({required: false}) mandatory = true;
  @Input({required: false}) onlyMobile = true;

  @Output() events = new EventEmitter<MobileComponentEvent>();

  formGroup!: FormGroup<{
    mobile: FormControl<MobileValue>
  }>;
  createMobilePhoneNumberInputMask = createMobilePhoneNumberInputMask();
  createPhoneInputMask = createPhoneNumberInputMask();
  subscriptions: Subscription[] = [];
  formControl!: FormControl<MobileValue>;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    let vals = this.mandatory ? [...this.validators, Validators.required] : [...this.validators];
    if (!this.onlyMobile) {
      vals = [...vals, phoneValidator()]
    }
    this.formControl = this.formBuilder.control(null, vals);
    this.formGroup = this.formBuilder.group({
      mobile: this.formControl,
    });
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: MobileValue) => {
        this.events.next({errors: this.formControl.errors, value: r});
        if (this.formControl.valid || this.formControl.disabled) {
          if (r) {
            const v = r.replace(/ /g, '');
            this.propagateChange(v);
          } else {
            this.propagateChange(r);
          }
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  doWriteValue(v: MobileValue) {
    this.formControl.setValue(v);
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

}
