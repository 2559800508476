import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Application, ApplicationTypes, CopyApplicationDialogData, CopyApplicationDialogResult} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    templateUrl: './copy-application.dialog.html',
    styleUrls: ['./copy-application.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class CopyApplicationDialog implements OnInit {

  formControl!: FormControl<ApplicationTypes | null>;
  subscription?: Subscription;
  application!: Application;
  applicationTypeOptions = [
    { name: 'Asset Finance', type: 'AssetFinance' },
    { name: 'Business Term Loan', type: 'BusinessLoans' },
    { name: 'Business Overdraft', type: 'BusinessOverdraft' },
    { name: 'Corporate Loan', type: 'CorporateLoans' },
  ]

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: CopyApplicationDialogData,
    private dialogRef: MatDialogRef<CopyApplicationDialog, CopyApplicationDialogResult>,
  ) {
    this.formControl = this.formBuilder.control(null, [Validators.required]);
    this.application = data.application;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onSubmit(readyForSubmission: boolean) {
    this.dialogRef.close({
      readyForSubmission,
      applicationType: this.formControl.value,
    })
  }
}
