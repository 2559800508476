import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DateRangeDialogData, DateRangeDialogOkResult, DateRangeDialogResult, DateRangeValue} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { setupUntilDestroy } from '../component-utils';
import {Moment} from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

import { DateRangeComponent } from '../date-range-component/date-range.component';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './date-range.dialog.html',
    styleUrls: ['./date-range.dialog.scss'],
    standalone: true,
    imports: [DateRangeComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class DateRangeDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    dateRange : FormControl<DateRangeValue>
    // disableCheckbox : FormControl<boolean | null>
  }>;
  formControlDateRangePicker : FormControl<DateRangeValue>;
  // formControlCheckbox!: FormControl<boolean | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DateRangeDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<DateRangeDialog, DateRangeDialogResult>) {
    this.formControlDateRangePicker = formBuilder.control(null, data.mandatory ? [Validators.required] : []);
    // this.formControlCheckbox = formBuilder.control(false);
    this.formGroup = this.formBuilder.group({
      dateRange : this.formControlDateRangePicker,
      // disableCheckbox : this.formControlCheckbox
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
    ).subscribe(r => {
      if (r.dateRange && r.dateRange.startDate && r.dateRange.endDate)
        this.disableControls = true;
      else
        this.disableControls = false;
    });
    this.disableControls = false
    this.subscriptions.push(sub);
  }

  submit() {
    if (this.formGroup.valid) {
      const v = this.formControlDateRangePicker.value;
      const data: DateRangeDialogOkResult = {
        type: 'ok',
        startDate: v?.startDate ?? null,
        endDate: v?.endDate ?? null,
        // isChecked: this.formControlCheckbox.value ?? false,
      };
      this.dialogRef.close(data)
    }

  }

  cancel($event: Event) {
    const data: DateRangeDialogResult = {
      type: 'cancel',
    };
    this.dialogRef.close(data);
  }
}
