import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Component, forwardRef, OnInit} from '@angular/core';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {CoverType, CoverTypeSelectionValue, getCoverTypes, CoverTypeSelectionValueOptions, compareMatch} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'cover-type-selection',
    templateUrl: './cover-type-selection.component.html',
    styleUrls: ['./cover-type-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CoverTypeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CoverTypeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class CoverTypeSelectionComponent extends AbstractControlValueAccessor<CoverTypeSelectionValue> implements OnInit,Mark {

  options = CoverTypeSelectionValueOptions;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    selection: FormControl<CoverTypeSelectionValue>
  }>;
  formControl: FormControl<CoverTypeSelectionValue>;
  coverTypes: CoverType[] = [];

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
        selection: this.formControl,
    });
    const sub = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: CoverTypeSelectionValue) => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: CoverTypeSelectionValue | null): void | CoverTypeSelectionValue {
    if (v) {
      const val = this.options.find(o => o.InsuranceProductId === v.InsuranceProductId);
      if (val) {
        this.formControl.setValue(val);
      }
    }
    return undefined;
  }
  mark() {
    this.formControl.markAllAsTouched();
    this.formControl.updateValueAndValidity();
  }

}
