import { Component, Inject, OnInit } from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe, PercentPipe } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FlexModule } from "@angular/flex-layout/flex";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import {
  ConsumerPricingComparisonDialogData, ConsumerPricingComparisonDialogDatasource, ConsumerPricingComparisonDialogResult, CurrencyInputValue,
  TotalPaymentBreakupDialogData
} from "@portal-workspace/grow-shared-library";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PercentageInputComponent} from '../percentage-input-component/percentage-input.component';
import {CurrencyInputComponent} from '../currency-selection-component/currency-input.component';
import {EditableTextComponent} from '../editable-component/editable-text.component';
import {EditablePercentageComponent} from '../editable-component/editable-percentage.component';
import {EditableNumberComponent} from '../editable-component/editable-number.component';
import {EditableCurrencyComponent} from '../editable-component/editable-currency.component';
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {setupUntilDestroy, ApplicationDialogService} from "@portal-workspace/grow-ui-library";
import {PercentagePipe} from '../../pipes/percentage.pipe';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './consumer-pricing-comparison.dialog.html',
  styleUrls: ['./consumer-pricing-comparison.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    CommonModule,
    MatDialogModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    JsonPipe,
    AsyncPipe,
    MatButtonModule,
    LooseCurrencyPipe,
    MatTooltipModule,
    PercentageInputComponent,
    CurrencyInputComponent,
    EditableTextComponent,
    EditablePercentageComponent,
    EditableNumberComponent,
    EditableCurrencyComponent,
    PercentagePipe
]
})
export class ConsumerPricingComparisonDialog implements OnInit {

  subscriptions: Subscription[] = [];

  dataSource: ConsumerPricingComparisonDialogDatasource = [];
  currentValue: TotalPaymentBreakupDialogData;
  newValue: TotalPaymentBreakupDialogData;

  formControlDocFee!: FormControl<CurrencyInputValue>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConsumerPricingComparisonDialogData,
    private dialogRef: MatDialogRef<ConsumerPricingComparisonDialog, ConsumerPricingComparisonDialogResult>,
    private applicationDialogService: ApplicationDialogService,
    private formBuilder: FormBuilder,
  ) {
    this.currentValue = data.currentValue
    this.newValue = data.newValue
    console.log('current value: ', this.currentValue);
    console.log('new value: ', this.newValue);
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlDocFee = this.formBuilder.control(this.newValue.paymentChartData.docFee, [Validators.required]);
    this.subscriptions.push(this.formControlDocFee.valueChanges.pipe(
      // doc fee was changed
    ).subscribe());

    this.dataSource = [
      {
        type: 'currency',
        name: 'Your Monthly Payment',
        currentValue: this.currentValue.paymentChartData.repaymentPlusMonthlyAccountKeepingFee,
        newValue: this.newValue.paymentChartData.repaymentPlusMonthlyAccountKeepingFee,
      },
      {
        type: 'currency',
        name: 'Amount financed',
        currentValue: this.currentValue.paymentChartData.principalAmt ?? 0,
        newValue: this.newValue.paymentChartData.principalAmt ?? 0,
      },
      {
        type: 'currency',
        name: 'Total Interest',
        currentValue: this.currentValue.paymentChartData.interestAmt,
        newValue: this.newValue.paymentChartData.interestAmt,
      },
      {
        type: 'currency',
        name: 'Total Payment',
        currentValue: this.currentValue.paymentChartData.totalAmt,
        newValue: this.newValue.paymentChartData.totalAmt,
      },
      {
        type: 'percentage',
        name: 'Interest Rate',
        currentValue: this.currentValue.paymentChartData.displayedInterest,
        newValue: this.newValue.paymentChartData.displayedInterest,
        newValueClick: () => {this.onInterestRateClick()},
      },
      {
        type: 'text',
        name: 'Loan Terms',
        currentValue: `${this.currentValue.paymentChartData.loanTerm} months`,
        newValue: `${this.newValue.paymentChartData.loanTerm} months`
      },
      {
        type: 'currency',
        name: 'Invoice Amount',
        currentValue: this.currentValue.paymentChartData.invoiceAmount,
        newValue: this.newValue.paymentChartData.invoiceAmount,
      },
      {
        type: 'currency',
        name: 'Deposit',
        currentValue: this.currentValue.paymentChartData.deposit,
        newValue: this.newValue.paymentChartData.deposit,
      },
      {
        type: 'currency',
        name: 'Balloon Payment',
        currentValue: this.currentValue.paymentChartData.rv,
        newValue: this.newValue.paymentChartData.rv,
      },
      {
        type: 'currency',
        name: 'Doc fee',
        currentValue: this.currentValue.paymentChartData.docFee,
        newValue: this.newValue.paymentChartData.docFee,
        formControl: this.formControlDocFee,
      },
      {
        type: 'currency',
        name: 'Credit Assistance Fee',
        currentValue: this.currentValue.paymentChartData.brokerOriginationFee,
        newValue: this.newValue.paymentChartData.brokerOriginationFee,
      },
      {
        type: 'percentage',
        name: 'Comparison Rate',
        currentValue: this.currentValue.paymentChartData.comparisionRate,
        newValue: this.newValue.paymentChartData.comparisionRate,
      },
      {
        type: 'currency',
        name: 'Commission',
        currentValue: this.currentValue.paymentChartData.commission,
        newValue: this.newValue.paymentChartData.commission,
      },
      {
        type: 'currency',
        name: 'Total Earnings (ex GST)',
        currentValue: this.currentValue.paymentChartData.totalEarned,
        newValue: this.newValue.paymentChartData.totalEarned,
      },
      {
        type: 'currency',
        name: 'Total Earnings (incl GST)',
        currentValue: this.currentValue.paymentChartData.totalEarnedInclGst,
        newValue: this.newValue.paymentChartData.totalEarnedInclGst,
      },
    ];
  }

  onAcceptQuotation($event: Event) {
    this.dialogRef.close({
      acceptedQuotation: true,
      docFee: this.formControlDocFee.value ?? this.newValue.paymentChartData.docFee,
    });
  }

  onClose($event: Event) {
    this.dialogRef.close();
  }

  onInterestRateClick() {
    console.log(this.newValue)
    if (this.newValue?.calculationLog) {
      this.applicationDialogService.openInterestRateCalculationLogDialog({ logs: this.newValue?.calculationLog ?? [] });
    } else {
      this.applicationDialogService.openAlertDialog({
        message: `Info`,
        subMessage: `No interest calculation log available`,
      });
    }
  }
}
