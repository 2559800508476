<div class="whitelabel-selection-component">
  <mat-form-field>
    <mat-label>Whitelabel</mat-label>
    <mat-select [formControl]="formControl" [compareWith]="compareWith">
      @for(entry of entries; track entry?.id) {
        <mat-option [value]="entry">{{entry.id}} - {{entry.title}}</mat-option>
      }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
