import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {BusinessNumberSearchComponent} from './business-number-search.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        InputMaskModule,
        BusinessNumberSearchComponent
    ],
    exports: [
        BusinessNumberSearchComponent,
    ]
})
export class BusinessNumberSearchModule {

}
