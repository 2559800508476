import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputMaskModule } from '@ngneat/input-mask';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { CustomPaginatorModule } from '../custom-paginator-component/custom-paginator.module';
import { CreditOverviewComponent } from './credit-overview.component';
import { CreditResultComponent } from './credit-result.component';
import { CreditFlowsComponent } from './credit-flows.component';
import { EditCreditFlowComponent } from './edit-credit-flow.component';
import { CreditAlertsComponent } from './credit-alerts.component';
import { CreditRuleComponent } from './credit-rule.component';
import { CreditRuleItemsComponent } from './credit-rule-items.component';
import { CreditResultAlertsComponent } from './credit-result-alerts.component';
import { CreditResultWebServicesComponent } from './credit-result-web-services.component';
import { CreditAuditLogComponent } from './credit-audit-log.component';
import { CreditFlowDetailsComponent } from './credit-flow-details.component';
import { CreditReportComponent } from './credit-report.component';
import { DuplicatedApplicationsComponent } from './duplicated-applications.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PipeLibraryModule,
        CustomPaginatorModule,
        CreditOverviewComponent,
        CreditResultComponent,
        CreditFlowsComponent,
        EditCreditFlowComponent,
        CreditAlertsComponent,
        CreditRuleComponent,
        CreditRuleItemsComponent,
        CreditResultAlertsComponent,
        CreditResultWebServicesComponent,
        CreditAuditLogComponent,
        CreditFlowDetailsComponent,
        CreditReportComponent,
        DuplicatedApplicationsComponent,
    ],
    exports: [
        CreditOverviewComponent,
        CreditResultComponent,
        CreditFlowsComponent,
        EditCreditFlowComponent,
        CreditAlertsComponent,
        CreditRuleComponent,
        CreditRuleItemsComponent,
        CreditResultAlertsComponent,
        CreditResultWebServicesComponent,
        CreditAuditLogComponent,
        CreditFlowDetailsComponent,
        CreditReportComponent,
        DuplicatedApplicationsComponent,
    ]
})
export class CreditModule {}
