import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { GetBsaLenderListFn, GetBsaExcludedLenderListFn, UpdateBsaLenderFn, UpdateBsaExcludedLenderFn, DeleteBsaLenderFn, DeleteBsaExcludedLenderFn, BsaLender, AddBsaLenderFn, AddBsaExcludedLenderFn} from '@portal-workspace/grow-shared-library';
import { FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccessLevelPipe } from "../../pipes/access-level.pipe";
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { ApplicationDialogService } from "../application-dialog-component/application-dialog.service";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'lender-table',
  templateUrl: './lender-table.component.html',
  styleUrls: ['./lender-table.component.scss'],
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, MatTooltipModule, FlexModule, ExtendedModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe, DatePipe, LooseCurrencyPipe, AccessLevelPipe, MatSlideToggleModule],
  exportAs: 'lenderTableComponent',
})
export class LenderTableComponent implements OnInit {
  subscriptions: Subscription[] = [];
  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  constructor(
      private formBuilder: FormBuilder,
      private toastService: PortalHotToastService,
      private dialogService: ApplicationDialogService
      ) {
      this.formControlSearch = formBuilder.control(null);
  }

  @Input({required: true}) type: 'lender' | 'excluded-lender' = 'lender';
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) updateBsaLenderFn!: UpdateBsaLenderFn;
  @Input({required: true}) updateBsaExcludedLenderFn!: UpdateBsaExcludedLenderFn;
  @Input({required: true}) deleteBsaLenderFn!: DeleteBsaLenderFn;
  @Input({required: true}) deleteBsaExcludedLenderFn!: DeleteBsaExcludedLenderFn;
  @Input({required: true}) addBsaLenderFn!: AddBsaLenderFn;
  @Input({required: true}) addBsaExcludedLenderFn!: AddBsaExcludedLenderFn;

  getLenderListFn!: GetBsaLenderListFn;
  updateLenderFn!: UpdateBsaLenderFn;
  deleteLenderFn!: DeleteBsaLenderFn;
  addLenderFn!: AddBsaLenderFn;

  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  formControlSearch: FormControl<string | null>;
  dataSource: BsaLender[] = [];
  filteredDataSource: BsaLender[] = [];
  displayedData: BsaLender[] = [];
  columnsToDisplay = ['lender', 'lastUpdatedTime', 'actions'];

  ngOnInit() {
    this.getLenderListFn = this.type === 'lender' ? this.getBsaLenderListFn : this.getBsaExcludedLenderListFn;
    this.updateLenderFn = this.type === 'lender' ? this.updateBsaLenderFn : this.updateBsaExcludedLenderFn;
    this.deleteLenderFn = this.type === 'lender' ? this.deleteBsaLenderFn : this.deleteBsaExcludedLenderFn;
    this.addLenderFn = this.type === 'lender' ? this.addBsaLenderFn : this.addBsaExcludedLenderFn;

    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(r => {
            this.onSearch();
        })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'id': return 'ID';
      case 'lender': return this.type === 'lender' ? 'Lender' : 'Excluded Lender';
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'actions': return '';
      default: return column;
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }
  
  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  reload() {
    this.subscriptions.push(
      this.getLenderListFn().pipe(
        this.loader.tableLoading.track()
      ).subscribe(lenders => {
        this.dataSource = lenders;
        this.filteredDataSource = this.dataSource;
        this.initPagination();
      })
    )
  }

  onSearch() {
    const value = this.formControlSearch.value ? this.formControlSearch.value.trim().toLowerCase() : '';
    if (value) {
      this.filteredDataSource = this.dataSource.filter(d => d.lender?.trim()?.toLowerCase()?.includes(value));
    } else {
      this.filteredDataSource = this.dataSource
    }

    this.initPagination()
  }

  onEdit(element: BsaLender) {
    this.subscriptions.push(
      this.dialogService.openEditLenderDialog({
        type: 'Edit',
        title: this.type === 'lender' ? 'Lender' : 'Excluded Lender',
        lender: element.lender
      }).afterClosed().subscribe(
        result => {
          if(result?.readyForSubmission) {
            this.updateLenderFn({
              id: element.id,
              lender: result.lender
            }).pipe(
              this.toastService.snackBarObservable(`Lender edited!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  onDelete(element: BsaLender) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Alert",
        subMessage: "Are you sure you want to delete this record?"
      }).afterClosed().subscribe(
        response => {
          if(response?.readyForSubmission) {
            this.deleteLenderFn(element.id).pipe(
              this.toastService.snackBarObservable(`Lender deleted!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  add() {
    this.subscriptions.push(
      this.dialogService.openEditLenderDialog({
        type: 'Add',
        title: this.type === 'lender' ? 'Lender' : 'Excluded Lender',
        lender: ''
      }).afterClosed().subscribe(
        result => {
          if(result?.readyForSubmission) {
            this.addLenderFn({lender: result.lender}).pipe(
              this.toastService.snackBarObservable(`Lender added!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

}
