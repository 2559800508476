<div class="percentage-input-component" [formGroup]="formGroup">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input [inputMask]="createTwoDecimalInputMask"
      [formControl]="formControl"
      [disableControl]="disabled"
      matInput
      type="text">
      <span class="mdi mdi-percent" matSuffix></span>
      @if (hint) {
        <mat-hint>{{hint}}</mat-hint>
      }
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </div>
