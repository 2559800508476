import {Injectable} from '@angular/core';
import {AuthService,} from './auth.service';
import {LoginPayload} from '@portal-workspace/grow-shared-library';
import {User} from '@portal-workspace/grow-shared-library';
import {tap} from 'rxjs/operators';
import {
  clearStorage,
  getAccessToken as utilGetAccessToken,
  getRefreshToken as utilGetRefreshToken,
  getUser,
  storeTokens
} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';

@UntilDestroy({checkProperties: true})
@Injectable()
export class StorageService {

  subscription: Subscription;

  constructor(private authService: AuthService) {
    setupUntilDestroy(this);
    this.subscription = this.authService.events.pipe(
      tap(r => {
        if (r && r.type === 'login') {
          this.setupLogin(r);
        } else if (r && r.type === 'logout') {
          this.clear();
        }
      })
    ).subscribe();
  }

  setupLogin(loginPayload: LoginPayload) {
    storeTokens({
      user: loginPayload.user ?? undefined,
      accessToken: loginPayload.accessToken ?? undefined,
      refreshToken: loginPayload.refreshToken ?? undefined,
    });
  }

  getUser(): User | null {
    return getUser();
  }

  getAccessToken(): string | null {
    return utilGetAccessToken();
  }

  getRefreshToken(): string | null {
    return utilGetRefreshToken();
  }

  clear() {
    clearStorage();
  }
}
