import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SelectContactComponent} from './select-contact.component';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {EmailModule} from '../common fields/email.module';
import {MobileModule} from '../mobile-component/mobile.module';
import { NameModule } from '../name-component/name.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        TitleSelectionModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        MobileModule,
        NameModule,
        SelectContactComponent
    ],
    exports: [
        SelectContactComponent,
    ]
})
export class SelectContactModule {

}
