import {Component, inject, OnInit} from "@angular/core";
import {FlexModule} from "@angular/flex-layout/flex";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import {OverdraftCustomerService} from "../../service/overdraft-customer.service";
import {
  LooseCurrencyPipe,
  LooseDatePipe, MessageBoxComponent,
  OverdraftCustomerSearchComponent, OverdraftCustomerSearchFn, CustomContentLoaderComponent, setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import {
  compareMatch, isAssetTypeLightOrHeavyTruck,
  NotNullable,
  OverdraftCustomerSearchValue, PayloadApiResponse,
  PismoCardDetails, PismoCardDetailsWithAccountId
} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {of, Subscription} from "rxjs";
import {distinctUntilChanged, startWith, switchMap, tap} from "rxjs/operators";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTableModule} from "@angular/material/table";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonModule} from "@angular/material/button";
import {MinimalLayoutService} from "../../layout/minimal-layout/minimal-layout.service";
import {loadingFor} from "@ngneat/loadoff";
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";
import {navigationUrlForLogin} from "../../service/navigation-urls";



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './card-operator-dashboard.page.html',
  styleUrls: ['./card-operator-dashboard.page.scss'],
  standalone: true,
  imports: [
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
    OverdraftCustomerSearchComponent,
    MatCardModule,
    MatInputModule,
    MatAutocompleteModule,
    MatExpansionModule,
    LooseDatePipe,
    LooseCurrencyPipe,
    MessageBoxComponent,
    MatTableModule,
    MatRadioModule,
    MatButtonModule,
    NgClass,
    CustomContentLoaderComponent
],
})
export class CardOperatorDashboardPage implements OnInit {

  loader = loadingFor('cardsLoader', 'deactivateCard');

  cardTableDisplayColumns = ['selection', 'cardNumber', 'activationTime'];

  stage:
      'initial' |              // step 1: initial state nothing is selected
      'customer-selected' |    // step 2: a valid customer search entry has been selected
      'user-selected'     |    // step 3: a valid user based on customer search entry has been selected
      'card-selected'          // step 4: a card is selected
  = 'initial';

  overdraftCustomerService: OverdraftCustomerService;

  userListDisplayWithFn = (v: NotNullable<OverdraftCustomerSearchValue>['users'][number] | null ) => {
    if (v) {
      return `${v?.name ?? ''} (${v?.email ?? ''})`;
    }
    return '';
  }

  subscriptions: Subscription[] = [];

  searchFn!: OverdraftCustomerSearchFn;

  formBuilder: FormBuilder;
  formControlCustomerSearch: FormControl<OverdraftCustomerSearchValue>;
  formControlUserList: FormControl<NotNullable<OverdraftCustomerSearchValue>['users'][number] | string | null>;

  selectedCustomer: OverdraftCustomerSearchValue = null;
  filterdUsers: NotNullable<OverdraftCustomerSearchValue>['users'] = []
  selectedUser: NotNullable<OverdraftCustomerSearchValue>['users'][number] | null = null;
  filteredCards: PismoCardDetailsWithAccountId[] = [];
  selectedCard: PismoCardDetailsWithAccountId | null = null;

  constructor() {
    const layoutService = inject(MinimalLayoutService);
    const authService  = inject(AuthService);
    const router = inject(Router);
    layoutService.settings({
        showAccreditationInContact: false,
        showContact: false,
        showCross: true,
        showPrev: false,
        crossTooltips: 'Logout',
    });
    this.subscriptions.push(layoutService.headerEventChanges()
      .pipe(
        tap(e => {
          if (e.type == 'cross-clicked') {
            this.subscriptions.push(authService.logout().pipe(
              tap(async r => {
                await router.navigate(navigationUrlForLogin());
              })
            ).subscribe());
          }
        })
    ).subscribe())
    this.overdraftCustomerService = inject(OverdraftCustomerService);
    this.formBuilder = inject(FormBuilder);
    this.searchFn = this.overdraftCustomerService.searchOverdraftCustomerFn;
    this.formControlCustomerSearch = this.formBuilder.control(null, [Validators.required]);
    this.formControlUserList = this.formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControlCustomerSearch.valueChanges.pipe(
        tap(r => {
          if (r) { // a valid customer was selected
            this.stage = 'customer-selected';
            this.selectedCustomer = r;
            this.filterdUsers = this.selectedCustomer.users ?? [];
            this.formControlUserList.setValue(null);
          }
        })
    ).subscribe());

    this.subscriptions.push(this.formControlUserList.valueChanges.pipe(
        startWith(null),
        // debounceTime(1000),
        distinctUntilChanged(compareMatch),
        tap(r => {
          console.log('***** user selected ', r);
          if (this.selectedCustomer && r) { // a valid user was selected
            if (typeof r === 'string') {
              this.filterdUsers = this.selectedCustomer.users.filter(ops => {
                const v =  `${ops.name} ${ops.email}`;
                return v.includes(r);
              });
            }
          }
        })
    ).subscribe());
  }

  userSelected($event: MatAutocompleteSelectedEvent) {
    const selectedUser = $event.option.value;
    console.log('**** user selected (bbb)', selectedUser);
    this.stage = 'user-selected';
    this.selectedUser = selectedUser;
    this.formControlUserList.setValue(selectedUser);
    if (this.selectedCustomer && this.selectedUser) {
        this.subscriptions.push(this.overdraftCustomerService.getPismoCardsForCustomerFn(
          this.selectedUser.pismoAccountNumber,
          this.selectedUser.pismoCustomerNumber).pipe(
            this.loader.cardsLoader.track(),
            tap(r => {
              console.log('cards for customer', r);
              this.filteredCards = r.filter(c => {
                return ['CREATED', 'UNBOUND', 'NORMAL'].includes(c.status);
              });
            })
        ).subscribe())
    }
  }

  onUserCardSelected($event: Event, card: PismoCardDetailsWithAccountId) {
    $event.stopPropagation();
    this.stage = 'card-selected';
    this.selectedCard = card;
  }

  deactivateCard($event: Event, card: PismoCardDetailsWithAccountId) {
    // this.overdraftCustomerService
    if (this.selectedCustomer && this.selectedUser && this.selectedCard) {
      this.subscriptions.push(this.overdraftCustomerService.deactivatePismoCard(
        this.selectedUser.pismoAccountNumber,
        this.selectedUser.pismoCustomerNumber,
        this.selectedCard.id
      )
          .pipe(
            this.loader.deactivateCard.track(),
            tap(r => {
            })
          ).subscribe());
    }
  }
}

