import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { PPSRConfirmationDialogData, PPSRConfirmationDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './ppsr-confirmation.dialog.html',
    styleUrls: ['./ppsr-confirmation.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class PPSRConfirmationDialog {

  // messagesAndControls: { m: PPSRConfirmationDialogData['messages'][number] }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data: PPSRConfirmationDialogData,
    private dialogRef: MatDialogRef<PPSRConfirmationDialog, PPSRConfirmationDialogResult>,) {
  }

  onSubmit($event: MouseEvent) {
    const result: PPSRConfirmationDialogResult = {
      readyForSubmission: true
    };
    this.dialogRef.close(result);
  }

  onCancel($event: MouseEvent) {
    const result: PPSRConfirmationDialogResult = {
      readyForSubmission: false
    };
    this.dialogRef.close(result);
  }
}
