import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Mark, requiredAllowEmptyValidator, setupUntilDestroy, createTwoDecimalInputMask} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AssetTypeRateValue, compareMatch, ConsumerRateCardValue, MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { AssetTypeRateComponent } from './asset-type-rate.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MinMaxRateComponent } from './min-max-rate.component';
import {
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'consumer-rate-card',
    templateUrl: './consumer-rate-card.component.html',
    styleUrls: ['./consumer-rate-card.component.scss'],
    exportAs: 'consumerRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConsumerRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => ConsumerRateCardComponent), multi: false },
    ],
    standalone: true,
    imports: [MinMaxRateComponent, FormsModule, ReactiveFormsModule, MatDividerModule, MatFormFieldModule, MatInputModule, InputMaskModule, CurrencyInputComponent, AssetTypeRateComponent]
})
export class ConsumerRateCardComponent extends AbstractControlValueAccessor<ConsumerRateCardValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  
  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formGroup: FormGroup<{
    carsAndCaravans: FormControl<MinMaxRateValue>
    motorbikes: FormControl<MinMaxRateValue>
    solarSystems: FormControl<MinMaxRateValue>
    eotAge: FormControl<MinMaxRateValue>
    loanTerms: FormControl<MinMaxRateValue>
    adverseOnFile: FormControl<number | null>
    privateSale: FormControl<number | null>
    privateSaleDocFee: FormControl<number | null>
    noPropertyOwnership: FormControl<number | null>
    lowEquifaxScore: FormControl<number | null>
    assetConditionUsed: FormControl<number | null>
    docFee: FormControl<MinMaxRateValue>
    assetType: FormControl<AssetTypeRateValue>
    hybrid: FormControl<number | null>
    monthlyAccountKeepingFee: FormControl<number | null>
    maxBrokerRateDiscount: FormControl<number | null>
    creditAssitanceFee: FormControl<number | null>
    ppsrFee: FormControl<number | null>
  }>;
  formControlCarsAndCaravans: FormControl<MinMaxRateValue>;
  formControlMotorbikes: FormControl<MinMaxRateValue>;
  formControlSolarSystems: FormControl<MinMaxRateValue>;
  formControlEotAge: FormControl<MinMaxRateValue>;
  formControlLoanTerms: FormControl<MinMaxRateValue>;
  formControlAdverseOnFile: FormControl<number | null>;
  formControlPrivateSale: FormControl<number | null>;
  formControlPrivateSaleDocFee: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlNoPropertyOwnership: FormControl<number | null>;
  formControlLowEquifaxScore: FormControl<number | null>;
  formControlAssetConditionUsed: FormControl<number | null>;
  formControlAssetType: FormControl<AssetTypeRateValue>;
  formControlHybrid: FormControl<number | null>;
  formControlMonthlyAccountKeepingFee: FormControl<number | null>;
  formControlMaxBrokerRateDiscount: FormControl<number | null>;
  formControlCreditAssitanceFee: FormControl<number | null>;
  formControlPpsrFee: FormControl<number | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlCarsAndCaravans = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlMotorbikes = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlSolarSystems = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlEotAge = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlLoanTerms = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAdverseOnFile = formBuilder.control(0, [Validators.required]);
    this.formControlPrivateSale = formBuilder.control(0, [Validators.required]);
    this.formControlPrivateSaleDocFee = formBuilder.control(0, [Validators.required]);
    this.formControlNoPropertyOwnership = formBuilder.control(0, [Validators.required]);
    this.formControlLowEquifaxScore = formBuilder.control(0, [Validators.required]);
    this.formControlAssetConditionUsed = formBuilder.control(0, [Validators.required]);
    this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAssetType = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlHybrid = formBuilder.control(0, [Validators.required]);
    this.formControlMonthlyAccountKeepingFee = formBuilder.control(0, [Validators.required]);
    this.formControlMaxBrokerRateDiscount = formBuilder.control(0, [Validators.required]);
    this.formControlCreditAssitanceFee = formBuilder.control(0, [Validators.required]);
    this.formControlPpsrFee = formBuilder.control(0, [Validators.required]);
    this.formGroup = formBuilder.group({
      carsAndCaravans: this.formControlCarsAndCaravans,
      motorbikes: this.formControlMotorbikes,
      solarSystems: this.formControlSolarSystems,
      eotAge: this.formControlEotAge,
      loanTerms: this.formControlLoanTerms,
      adverseOnFile: this.formControlAdverseOnFile,
      privateSale: this.formControlPrivateSale,
      privateSaleDocFee: this.formControlPrivateSaleDocFee,
      noPropertyOwnership: this.formControlNoPropertyOwnership,
      lowEquifaxScore: this.formControlLowEquifaxScore,
      assetConditionUsed: this.formControlAssetConditionUsed,
      docFee: this.formControlDocFee,
      assetType: this.formControlAssetType,
      hybrid: this.formControlHybrid,
      monthlyAccountKeepingFee: this.formControlMonthlyAccountKeepingFee,
      maxBrokerRateDiscount: this.formControlMaxBrokerRateDiscount,
      creditAssitanceFee: this.formControlCreditAssitanceFee,
      ppsrFee: this.formControlPpsrFee,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          const v: ConsumerRateCardValue = {
            carsAndCaravans: r.carsAndCaravans ?? [],
            motorbikes: r.motorbikes ?? [],
            solarSystems: r.solarSystems ?? [],
            eotAge: r.eotAge ?? [],
            loanTerms: r.loanTerms ??  [],
            adverseOnFile: r.adverseOnFile ?? 0,
            privateSale: r.privateSale ?? 0,
            privateSaleDocFee: r.privateSaleDocFee ?? 0,
            noPropertyOwnership: r.noPropertyOwnership ?? 0,
            lowEquifaxScore: r.lowEquifaxScore?? 0,
            assetConditionUsed: r.assetConditionUsed ?? 0,
            hybrid: r.hybrid ?? 0,
            docFee: r.docFee ?? [],
            assetType: r.assetType ?? [],
            monthlyAccountKeepingFee: r.monthlyAccountKeepingFee ?? 0,
            maxBrokerRateDiscount: r.maxBrokerRateDiscount ?? 0,
            creditAssitanceFee: r.creditAssitanceFee ?? 0,
            ppsrFee: r.ppsrFee ?? 0,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: ConsumerRateCardValue | undefined): void | ConsumerRateCardValue {
    if (v) {
      this.formControlCarsAndCaravans.setValue(v.carsAndCaravans?? []);
      this.formControlMotorbikes.setValue(v.motorbikes?? []);
      this.formControlSolarSystems.setValue(v.solarSystems?? []);
      this.formControlEotAge.setValue(v.eotAge ?? []);
      this.formControlLoanTerms.setValue(v.loanTerms ?? []);
      this.formControlAdverseOnFile.setValue(v.adverseOnFile);
      this.formControlPrivateSale.setValue(v.privateSale);
      this.formControlPrivateSaleDocFee.setValue(v.privateSaleDocFee);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlNoPropertyOwnership.setValue(v.noPropertyOwnership);
      this.formControlLowEquifaxScore.setValue(v.lowEquifaxScore);
      this.formControlAssetConditionUsed.setValue(v.assetConditionUsed);
      this.formControlAssetType.setValue(v.assetType ?? []);
      this.formControlHybrid.setValue(v.hybrid);
      this.formControlMonthlyAccountKeepingFee.setValue(v.monthlyAccountKeepingFee);
      this.formControlMaxBrokerRateDiscount.setValue(v.maxBrokerRateDiscount);
      this.formControlCreditAssitanceFee.setValue(v.creditAssitanceFee);
      this.formControlPpsrFee.setValue(v.ppsrFee);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
