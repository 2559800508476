<div class="consumer-rate-card-component">
  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Cars & Caravans" [formControl]="formControlCarsAndCaravans"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Motorbikes" [formControl]="formControlMotorbikes"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Solar Systems" [formControl]="formControlSolarSystems"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan terms" [formControl]="formControlLoanTerms"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="EOT Age" [formControl]="formControlEotAge"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Private Sale</div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Private sale uplift</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlPrivateSale" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlPrivateSale); track errorKey) {
          <mat-error>
            @if (formControlPrivateSale.touched && formControlPrivateSale.hasError(errorKey)) {
              {{errorMessage(formControlPrivateSale, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <currency-input title="Private sale doc fee"  [formControl]="formControlPrivateSaleDocFee" ></currency-input>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Risk Profile</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Adverse on file</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlAdverseOnFile" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAdverseOnFile); track errorKey) {
          <mat-error>
            @if (formControlAdverseOnFile.touched && formControlAdverseOnFile.hasError(errorKey)) {
              {{errorMessage(formControlAdverseOnFile, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Low equifax score</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlLowEquifaxScore" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlLowEquifaxScore); track errorKey) {
          <mat-error>
            @if (formControlLowEquifaxScore.touched && formControlLowEquifaxScore.hasError(errorKey)) {
              {{errorMessage(formControlLowEquifaxScore, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>No property ownership</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlNoPropertyOwnership" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNoPropertyOwnership); track errorKey) {
          <mat-error>
            @if (formControlNoPropertyOwnership.touched && formControlNoPropertyOwnership.hasError(errorKey)) {
              {{errorMessage(formControlNoPropertyOwnership, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Asset condition used</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlAssetConditionUsed" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAssetConditionUsed); track errorKey) {
          <mat-error>
            @if (formControlAssetConditionUsed.touched && formControlAssetConditionUsed.hasError(errorKey)) {
              {{errorMessage(formControlAssetConditionUsed, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Hybrid/EV Discount</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Hybrid/EV Rate Discount</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlHybrid" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlHybrid); track errorKey) {
          <mat-error>
            @if (formControlHybrid.touched && formControlHybrid.hasError(errorKey)) {
              {{errorMessage(formControlHybrid, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate  title="Doc Fee" [type]="'currency'" [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <asset-type-rate title="Asset Type"  [formControl]="formControlAssetType"></asset-type-rate>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Max Broker Discount</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Max Broker Discount</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlMaxBrokerRateDiscount" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlMaxBrokerRateDiscount); track errorKey) {
          <mat-error>
            @if (formControlMaxBrokerRateDiscount.touched && formControlMaxBrokerRateDiscount.hasError(errorKey)) {
              {{errorMessage(formControlMaxBrokerRateDiscount, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Monthly Account Keeping Fee</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Monthly Account Keeping Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlMonthlyAccountKeepingFee" required>
        @for (errorKey of errorKeys(formControlMonthlyAccountKeepingFee); track errorKey) {
          <mat-error>
            @if (formControlMonthlyAccountKeepingFee.touched && formControlMonthlyAccountKeepingFee.hasError(errorKey)) {
              {{errorMessage(formControlMonthlyAccountKeepingFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">Credit Assistance Fee</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Credit Assistance Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlCreditAssitanceFee" required>
        @for (errorKey of errorKeys(formControlCreditAssitanceFee); track errorKey) {
          <mat-error>
            @if (formControlCreditAssitanceFee.touched && formControlCreditAssitanceFee.hasError(errorKey)) {
              {{errorMessage(formControlCreditAssitanceFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </div>

  <div class="mat-h4-custom separator-top">PPSR Fee</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>PPSR Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlPpsrFee" required>
        @for (errorKey of errorKeys(formControlPpsrFee); track errorKey) {
          <mat-error>
            @if (formControlPpsrFee.touched && formControlPpsrFee.hasError(errorKey)) {
              {{errorMessage(formControlPpsrFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>

</div>

