import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {OverdraftCustomerSidemenuComponent} from './overdraft-customer-sidemenu.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {CustomersTableComponent} from "./customers-table.component";
import { OvedraftCustomerDetailsComponent } from './overdraft-customer-details.component';
import { OverdraftAccountUsersComponent } from './overdraft-account-users.component';
import {OverdraftAccountDetailsComponent} from "./overdraft-account-details.component";
import {OverdraftAccountTransactionsComponent} from "./overdraft-account-transactions.component";
import {OverdraftAccountAvailableListComponent} from "./overdraft-account-available-list.component";
import {OverdraftCustomerPhysicalCardSelectComponent} from "./overdraft-customer-physical-card-select.component";
import { OverdraftAccountStatementsComponent } from './overdraft-account-statements.component';
import { OverdraftAccountStatementDetailsComponent } from './overdraft-account-statement-details.component';
import { PismoNotesComponent } from './pismo-notes.component';
import { OverdraftAccountTimelineComponent } from './overdraft-account-timeline.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        OverdraftCustomerSidemenuComponent,
        CustomersTableComponent,
        OvedraftCustomerDetailsComponent,
        OverdraftAccountUsersComponent,
        OverdraftAccountDetailsComponent,
        OverdraftAccountTransactionsComponent,
        OverdraftAccountAvailableListComponent,
        OverdraftCustomerPhysicalCardSelectComponent,
        OverdraftAccountStatementsComponent,
        OverdraftAccountStatementDetailsComponent,
        PismoNotesComponent,
        OverdraftAccountTimelineComponent,
    ],
    exports: [
        OverdraftCustomerSidemenuComponent,
        CustomersTableComponent,
        OvedraftCustomerDetailsComponent,
        OverdraftAccountUsersComponent,
        OverdraftAccountDetailsComponent,
        OverdraftAccountTransactionsComponent,
        OverdraftAccountAvailableListComponent,
        OverdraftCustomerPhysicalCardSelectComponent,
        OverdraftAccountStatementsComponent,
        OverdraftAccountStatementDetailsComponent,
        PismoNotesComponent,
        OverdraftAccountTimelineComponent,
    ]
})
export class OverdraftModule {

}
