<div class="collpasable-expandable-text-component">
  @if (mode == 'expandable') {
    <span class="cursor-pointer" (click)="expandText($event)" [innerHTML]="this.s"></span>
    <span class="cursor-pointer" (click)="expandText($event)"><a class="show-more">Show more..</a></span>
  }
  @if (mode == 'collapsable') {
    <span class="cursor-pointer" (click)="collapseText($event)" [innerHTML]="this.v"></span>
    <span class="cursor-pointer" (click)="collapseText($event)"><a class="show-less">Show less..</a></span>
  }
  @if (mode == 'none') {
    <span [innerHTML]="this.v"></span>
  }
</div>
