import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import {User, UserandPriviledges} from '@portal-workspace/grow-shared-library';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';



@Injectable()
export class UserResolver  {

  constructor(private authService: AuthService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserandPriviledges | null> | Promise<UserandPriviledges | null> | UserandPriviledges | null {
    const userId = route.params.userId;
    const includesDisable = true;
    return this.authService.getUser(userId,includesDisable).pipe(
      map(r => r.payload)
    );
  }

}
