// single, married, defacto, other

export type MaritialStatusSelectionName = 'Single' | 'Married' | 'De Facto' | 'Other';
export type MaritialStatusSelectionType = 'single' | 'married' | 'defacto' | 'other';

export const MaritialStatusSelectionValueOptions: Exclude<MaritialStatusSelectionValue, null>[] = [
  { type: 'single', name: 'Single' },
  { type: 'married', name: 'Married' },
  { type: 'defacto', name: 'De Facto' },
  { type: 'other', name: 'Other' },
]

export type MaritialStatusSelectionValue = {
  type: MaritialStatusSelectionType, // 'single' | 'married' | 'defacto' | 'other',
  name: MaritialStatusSelectionName, // string;
} | null;
