<upload-files-multi-tags
  [allTags]="allTags"
  [defaultTags]="defaultTags"
  [MAX_TAG_ALLOWED]="MAX_TAG_ALLOWED"
  [enableCustomTag]="enableCustomTag"
  [enableNonStandardCondition]="enableNonStandardCondition"
  [title]="title"
  [isDialog]=true
  (dialogEvents)="handleDialogEvents($event)"
></upload-files-multi-tags>
