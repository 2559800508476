import {Component, OnInit} from '@angular/core';
import {ExchangeDocusignTokenPayload} from '@portal-workspace/grow-shared-library';
import {catchError, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {of, Subscription } from 'rxjs';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {DocusignService} from '../../service/docusign.service';
import { NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './oauth.page.html',
    styleUrls: ['./oauth.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, FlexModule, MatButtonModule, NgTemplateOutlet]
})
export class OauthPage implements OnInit {

  subscriptions: Subscription[] = [];

  refreshDocusignTokenPayload?: ExchangeDocusignTokenPayload;
  currentDocusignTokenPayload?: ExchangeDocusignTokenPayload;

  docusignResult?: { $implicit: {
      title: string,
      isError: boolean,
    } & ExchangeDocusignTokenPayload};

  constructor(
    private docusignService: DocusignService,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
  }

  doDocusignAuth() {
    const subs = this.docusignService.getDocusignConsentUrl()
      .pipe(
        this.toastService.retryableMessage({
          errorMessage: (err) => {
            return (err as any)?.error?.error?.toString() ??  'unable to get token';
          },
          successMessage: `Navigate to docusign user consent page`,
          retryFn: ()=>{},
        }),
        tap((url: string) => {
          if (url) {
            document.location.href = url;
          }
        })
      ).subscribe();
    this.subscriptions.push(subs);
  }

  refreshDocusignToken() {
    this.clearAllDocusign();

    this.subscriptions.push(
      this.docusignService.refreshDocusignToken().pipe(
        this.toastService.retryableMessage({
          errorMessage: (err) => {
            return (err as any)?.error?.error?.toString() ??  'unable to refresh token';
          },
          successMessage: `Token refreshed`,
          retryFn: ()=>{},
        }),
        tap((r: ExchangeDocusignTokenPayload) => {
          console.log("refresh token", r);
          this.refreshDocusignTokenPayload = r;
          this.docusignResult = {
            $implicit: {
              isError: !!r?.error,
              title: `Refreshed Token`,
              ...r,
            },
          };
        }),
        catchError((err: Error) => {
          console.log('*** catchError', err);
          return of(null);
        })
      ).subscribe()
    )
  }

  checkCurrentDocusignToken() {
    this.clearAllDocusign();
    const subs = this.docusignService.getDocusignToken().pipe(
      tap((r: ExchangeDocusignTokenPayload) => {
        if (r) {
          this.currentDocusignTokenPayload = r;
          this.docusignResult = {
            $implicit: {
              isError: !!r?.error,
              title: `Current Token`,
              ...r,
            }
          };
        }
      })
    ).subscribe()
    this.subscriptions.push(subs);
  }


  clearAllDocusign() {
    this.docusignResult = undefined;
    this.refreshDocusignTokenPayload = undefined;
    this.currentDocusignTokenPayload = undefined;
  }
}
