import { Component, Input, OnInit } from '@angular/core';
import { AssetSpec, RiskRatingInterface } from '@portal-workspace/grow-shared-library';
import { GrowRiskRating } from '../risk-analysis.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';
import { EllipsisPipe } from '../../../pipes/ellipsis.pipe';
 

@Component({
    selector: 'risk-rating-table',
    templateUrl: './risk-rating-table.component.html',
    styleUrls: ['./risk-rating-table.component.scss'],
    standalone: true,
    imports: [GridModule, FlexModule, MatDividerModule, MatExpansionModule, EllipsisPipe]
})
export class RiskRatingTableComponent implements OnInit {

  panelOpen = false;

  @Input({required: true}) riskRatingData!: RiskRatingInterface;
  
  growRiskRating!: GrowRiskRating;
  growRiskRatingData!: RiskRatingInterface;
  abnAge: number | undefined;

  constructor() {
  }

  ngOnInit(): void {
    console.log("this.riskRatingData",this.riskRatingData)
    this.growRiskRatingData = this.riskRatingData;
    this.growRiskRating = new GrowRiskRating(this.riskRatingData.growRiskRating);
    this.abnAge = Math.round(this.riskRatingData.abnAge);
  }
}
