import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {MatInputModule} from "@angular/material/input";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AsyncPipe, JsonPipe, NgOptimizedImage} from "@angular/common";
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {UrlValue} from "@portal-workspace/grow-shared-library";
import {Subscription} from "rxjs";
import {createUrlInputMask, formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from "../component-utils";
import {delay, tap} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";
import {InputMaskModule} from "@ngneat/input-mask";
import {DisableControlDirective} from "../../directives/disable-control.directive";
import {validUrlValidator} from "../../validators/validators";
import {MessageBoxComponent} from "../message-box/message-box.component";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>UrlComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => UrlComponent)},
  ],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    JsonPipe,
    AsyncPipe,
    InputMaskModule,
    DisableControlDirective,
    NgOptimizedImage,
    MessageBoxComponent,
  ]
})
export class UrlComponent extends AbstractControlValueAccessor<UrlValue> implements OnInit, Mark {

  @Input({required: false}) title: string  = 'URL';
  @Input({required: false}) preview: boolean = false;

  subscriptions: Subscription[] = [];

  previewError = false;

  createUrlInputMask = createUrlInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formBuilder = inject(FormBuilder);
  formControl: FormControl<UrlValue> = this.formBuilder.control(null, [Validators.required, validUrlValidator()]);
  formGroup = this.formBuilder.group({
    url: this.formControl,
  });

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      tap(v => {
        if (this.formControl.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe());
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  override doWriteValue(v?: UrlValue | null): void | UrlValue {
    this.formControl.setValue(v ?? null);
  }

  onPreviewError(t: any) {
    this.previewError = true;
  }

  onPreviewLoad(t: any) {
    console.log('onPreviewLoad', t);
    this.previewError = false;
  }
}
