import {Component, Input} from '@angular/core';
import {
  PersonalAsset,
  PersonalAssetsValue,
  PersonalNonPropertyAsset, PersonalPropertyAsset
} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';



@Component({
    selector: 'assets',
    template: `
    @if (assets) {
      <div class="row">
        <div class="col">
          <div class="mat-h2">Personal assets</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>Type</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Address</div>
          </cell>
        </div>
        <div class="col-3">
          <cell>
            <div label>Value</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @for (asset of assets.assets; track asset; let index = $index) {
        <div class="row">
          <div class="col-3">
            <cell>
              <div body>{{asset.assetType.name}}</div>
            </cell>
          </div>
          <div class="col-6">
            <cell>
              <div body>{{descriptionOrAddress(asset)}}</div>
            </cell>
          </div>
          <div class="col-3">
            <cell>
              <div body><span class="mdi mdi-currency-usd"></span>{{asset.value}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6"></div>
        <div class="col-3">
          <cell>
            <div body><span class="mdi mdi-currency-usd"></span>{{assets.total}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
    `,
    standalone: true,
    imports: [CellComponent, MatDividerModule]
})
export class AssetsSummaryComponent {

  @Input({required: true}) assets?: PersonalAssetsValue;


  descriptionOrAddress(asset: PersonalAsset): string {
    if (asset.assetType.type === 'property') {
      return (asset as PersonalPropertyAsset).address?.address ?? '';
    }
    return (asset as PersonalNonPropertyAsset).description;
  }

}
