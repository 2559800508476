import {NgModule} from '@angular/core';
import {MarkDirective} from './mark-as-dirty.directive';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularMaterialLibraryModule} from '../angular-material/angular-material-library.module';
import {AutoSelectOnClickDirective} from './auto-select-on-click.directive';
import {DisableControlDirective} from './disable-control.directive';
import {SecurityDirective} from "./security.directive";

const directives = [
  MarkDirective,
  AutoSelectOnClickDirective,
  DisableControlDirective,
  SecurityDirective,
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        ...directives
    ],
    exports: [
        ...directives,
    ]
})
export class DirectiveLibraryModule {
}
