import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import { AsyncPipe, JsonPipe } from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule, Validators
} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatOptionModule} from "@angular/material/core";
import {MARK, Mark} from "@portal-workspace/grow-ui-library/mark";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {
  compareMatch,
  OverdraftAccountAvailable,
  OverdraftAccountAvailableForUserFn,
  OverdraftAccountAvailableListValue
} from "@portal-workspace/grow-shared-library";
import {DisableControlDirective} from '../../directives/disable-control.directive';
import {Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'overdraft-account-available-list',
  templateUrl: './overdraft-account-available-list.component.html',
  styleUrls: ['./overdraft-account-available-list.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatOptionModule,
    DisableControlDirective,
    JsonPipe,
    AsyncPipe
],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>OverdraftAccountAvailableListComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>OverdraftAccountAvailableListComponent) },
  ]
})
export class OverdraftAccountAvailableListComponent extends AbstractControlValueAccessor<OverdraftAccountAvailableListValue> implements OnInit, Mark {

  @Input({required: true}) title?: string;
  @Input({required: true}) userId!: number;
  @Input({required: true}) overdraftAccountAvailableForUserFn!: OverdraftAccountAvailableForUserFn;

  subscriptions: Subscription[] = [];

  overdraftAccountsAvailable: OverdraftAccountAvailable[] = [];

  formBuilder = inject(FormBuilder);
  formControl: FormControl<OverdraftAccountAvailableListValue> = this.formBuilder.control(null, [Validators.required]);


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.overdraftAccountAvailableForUserFn(this.userId).pipe(
      tap(v => {
        this.overdraftAccountsAvailable = v ?? [];
      })
    ).subscribe());

    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formControl.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })).subscribe());
  }

  mark(): void {
    this.formControl.markAsTouched();
  }

  override doWriteValue(v?: OverdraftAccountAvailableListValue): void | OverdraftAccountAvailableListValue {
    this.formControl.setValue(v ?? null);
  }

}
