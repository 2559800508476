import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import {AssetCategory, getAssetCategories, AssetCategorySelectionValue, compareMatch} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AssetCatAndTypeService} from './asset-cat-and-type.service';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'asset-category',
    templateUrl: './asset-category-selection.component.html',
    styleUrls: ['./asset-category-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetCategorySelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetCategorySelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class AssetCategorySelectionComponent extends AbstractControlValueAccessor<AssetCategorySelectionValue> implements OnInit, Mark {

  formGroup: FormGroup<{
    selection: FormControl<AssetCategorySelectionValue>
  }>;
  formControl: FormControl<AssetCategorySelectionValue>

  assetCategories: AssetCategory[] = [];

  subscription?: Subscription;

  @Input({required: false}) key: string = 'key1';
  @Input({required: false}) indexFilter: string[] = [];
  // @Input({required: false}) disabled: boolean = false;
  @Input({required: false}) truckShowGVMTypes = false;
  @Input({required: false}) showPayout = false

  compareWithFn: (option: AssetCategorySelectionValue, selected: AssetCategorySelectionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.index === selected.index);
  };

  constructor(private formBuilder: FormBuilder,
              private service: AssetCatAndTypeService) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.service.setShowGVMTypes(this.truckShowGVMTypes);
    this.service.init(this.key);

    // NOTE: Payout will not be included in any other screen.
    // It should be seen only on asset-listing screen
    let assetCategories: AssetCategory[] = getAssetCategories(this.indexFilter)
    this.assetCategories = assetCategories.filter(e => e.index !== '141')
    // for asset listing screen show payout and hide consumer asset
    if (this.showPayout) {
      this.assetCategories = getAssetCategories(this.indexFilter)
      this.assetCategories = assetCategories.filter(e => e.index !== '139')
    }
    this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (r) {
          this.service.setAssetCategory(this.key, r);
          this.propagateChange(r ? r : null);
        }
      })
    ).subscribe();
  }

  doWriteValue(v: AssetCategorySelectionValue | null | undefined): void | AssetCategorySelectionValue {
    if (v) {
      const c = this.assetCategories.find(c => c.index === v.index);
      if (c) {
        this.formControl.setValue(c);
        return c;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
