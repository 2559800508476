<div class="forgot-password-page container-fluid" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-lg="center start">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>

<ng-template #formTemplate>
  <div class="form-template">
    <div class="mat-h3 text-align-center">Forgot your password?</div>
    <div class="mat-body text-align-center separator-bottom">Enter your email address below and we'll send you password reset instructions.</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroup">
      <div class="col-12 px-0">
      <email fxFlex="1 0 auto" [formControl]="formControlEmail"></email>
    </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onContinue($event)">Continue</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmationTemplate>
  <div class="confirmation-template">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center separator-bottom">
      An email to reset your password has been sent. You can use that link to reset
      your password and then you can now login by clicking the button below.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="form-button">
      <button fxFlex="1 0 auto" mat-stroked-button color="primary" class="mt-2" (click)="onLoginAgain($event)">Login Now</button>
    </div>
  </div>
</ng-template>
