<div class="editable-text-component">
  <editable (modeChange)="modeChange($event)"
    [ngClass]="{'edit': mode == 'edit', 'view': mode == 'view'}">
    <ng-template editMode>
      <div fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">
        <mat-form-field>
          <mat-label>Email </mat-label>
          <input matInput [inputMask]="createEmailInputMask"
            placeholder="Enter your email..." type="text"
          [formControl]="formControl">
          @for (errorKey of errorKeys(formControl); track trackByFn) {
            <mat-error>
              @if (formControl.touched && formControl.hasError(errorKey)) {
                {{errorMessage(formControl, errorKey)}}
              }
            </mat-error>
          }
          </mat-form-field>
          @if(mode === 'edit'){
          <button mat-flat-button 
            (click)="onSave()"
            editableOnSave>
            <span class="mdi mdi-check"
            matTooltip="Save"></span>
          </button>
          <button mat-stroked-button 
          (click)="onCancel()">
          <span class="mdi mdi-close"
          matTooltip="Cancel"></span>
        </button>
      }
        </div>
      </ng-template>
      <ng-template viewMode>
        <div fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="5px">
          <mat-form-field>
          <mat-label>Email </mat-label>
          <input matInput [inputMask]="createEmailInputMask"
          type="text"
          [formControl]="formControl" [attr.disabled]="true">
          </mat-form-field>
          <span class="mdi mdi-pencil-outline mt-3 ml-1"
          matTooltip="Edit"></span>
        </div>
      </ng-template>
    </editable>
  </div>
