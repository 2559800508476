import {DocumentTag} from "../types.domain";

export interface SelectableTagsDialogData {
  allTags: DocumentTag[];
  fileName?: string;
  previouslySelectedTags?: DocumentTag[] | string[];
  enableCustomTag?: boolean;
  enableNonStandardCondition?: boolean;
  maxTags?: number;
}

export interface SelectableTagsDialogResult {
  valid: boolean;
  selectedTags: DocumentTag[];
}