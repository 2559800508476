import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { getUser } from '@portal-workspace/grow-ui-library';
import { navigationUrlForNewApplication } from '../service/navigation-urls';
import { Priviledges} from '@portal-workspace/grow-shared-library';



export const adminAndAnalystAccessGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const allowedUserPrivileges: Priviledges =  [
      'admin',
      'analyst',
      'operations',
      'operations24',
      'credit',
      'settlement',
      'salesAM',
      'salesBDM'
    ];

    const user = getUser()
    if (user?.priviledges.some(priviledge => allowedUserPrivileges.includes(priviledge))) {
      return true;
    }
    return router.navigate(navigationUrlForNewApplication())
  }
}



// NOTE: Depreacated since Angular 16
// @Injectable()
// export class AdminAndCompanyadminAccessGuard  {
//   constructor(private router: Router) { }
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//
//     const allowedUserPrivileges: Priviledges =  ['admin', 'companyadmin'];
//
//     const user = getUser()
//     if (user?.priviledges.some(priviledge => allowedUserPrivileges.includes(priviledge))) {
//       return true;
//     }
//     return this.router.createUrlTree(navigationUrlForNewApplication())
//   }
//
// }
