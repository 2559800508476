<div class="disbursement-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      @if (defaultDisbursement) {
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
          <div class="mat-h3">
            <span class="mdi mdi-alert-circle-outline color-warn"></span>
            Important
          </div>
          <!-- WEB-3702: don't need this anymore
          <div class="mat-body">
            <div fxFlex="1 1 400px" fxLayout="row" fxFlexAlign="center" class="deposit-paid-to">
              <p fxFlexAlign="center">Deposit Paid To: </p>
              <mat-button-toggle-group
                [hideSingleSelectionIndicator]="true"
                [hideMultipleSelectionIndicator]="true"
                [formControl]="formControlDepositPaidTo">
                <mat-button-toggle [value]="'Dynamoney'">Dynamoney</mat-button-toggle>
                <mat-button-toggle [value]="'Supplier'">Supplier</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          -->
          <div class="mat-body width-100">
            <span [innerHTML]="'Default disbursement will overwrite all your existing settings. Do you wish to continue?'"></span>
          </div>
        </div>
      }
      @if (!defaultDisbursement) {
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
          <div class="mat-h3">
            <span class="mdi mdi-alert-circle-outline color-warn"></span>
            Important
          </div>
          <!-- WEB-3702: don't need this anymore
          <div class="mat-body">
            <div fxFlex="1 1 400px" fxLayout="row" fxFlexAlign="center" class="deposit-paid-to">
              <p fxFlexAlign="center">Deposit Paid To: </p>
              <mat-button-toggle-group [formControl]="formControlDepositPaidTo">
                <mat-button-toggle [value]="'Dynamoney'">Dynamoney</mat-button-toggle>
                <mat-button-toggle [value]="'Supplier'">Supplier</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          -->
          <div class="mat-body width-100">
            <span [innerHTML]="'Do you wish to continue?'"></span>
          </div>
        </div>
      }
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
        <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
      <!-- WEB-3702: button color="primary" [disabled]="!!!selectedSupplier" (click)="onSubmit(true)" mat-flat-button>Confirm</button-->
      <button color="primary" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
    </div>
  </div>
</mat-dialog-content>
</div>
