import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BusinessOverdraftRateCardValue, compareMatch, MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Mark } from '@portal-workspace/grow-ui-library/mark';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { MinMaxRateComponent } from './min-max-rate.component';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-overdraft-rate-card',
    templateUrl: './business-overdraft-rate-card.component.html',
    styleUrls: ['./business-overdraft-rate-card.component.scss'],
    exportAs: 'businessOverdraftRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessOverdraftRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BusinessOverdraftRateCardComponent), multi: false },
    ],
    standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, MatDividerModule, MinMaxRateComponent]
})
export class BusinessOverdraftRateCardComponent extends AbstractControlValueAccessor<BusinessOverdraftRateCardValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  subscriptions: Subscription[] = [];


  formGroup: FormGroup<{
    propertyOwner: FormControl<number | null>,
    noPropertyOwner: FormControl<number | null>,
    facilityFee: FormControl<number | null>,
    brokerShareFacilityFee: FormControl<number | null>,
    docFee: FormControl<MinMaxRateValue | null>,
    directorScoreRate: FormControl<number | null>,
    nonPropertyOwnerBetween500And600Rate: FormControl<number | null>,
    rbaRate: FormControl<number | null>,
    maxFacilityEstablishmentFee: FormControl<number | null>
  }>;

  formControlPropertyOwner: FormControl<number | null>;
  formControlNoPropertyOwner: FormControl<number | null>;
  formControlFacilityFee: FormControl<number | null>;
  formControlBrokerShareFacilityFee: FormControl<number | null>;
  formControlRbaRate: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlDirectorScoreRate: FormControl<number | null>;
  formControlNonPropertyOwnerBetween500And600Rate: FormControl<number | null>;
  formControlMaxFacilityEstablishmentFee: FormControl<number | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlPropertyOwner = formBuilder.control(0, [Validators.required]);
    this.formControlNoPropertyOwner = formBuilder.control(0, [Validators.required]);
    this.formControlFacilityFee = formBuilder.control(0, [Validators.required]);
    this.formControlBrokerShareFacilityFee = formBuilder.control(0, [Validators.required]);
    this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlDirectorScoreRate = formBuilder.control(0,[Validators.required]);
    this.formControlNonPropertyOwnerBetween500And600Rate = formBuilder.control(0,[Validators.required]);
    this.formControlRbaRate = formBuilder.control(0)
    this.formControlMaxFacilityEstablishmentFee = formBuilder.control(0, [Validators.required]);
    this.formGroup = formBuilder.group({
      propertyOwner: this.formControlPropertyOwner,
      noPropertyOwner: this.formControlNoPropertyOwner,
      facilityFee: this.formControlFacilityFee,
      brokerShareFacilityFee: this.formControlBrokerShareFacilityFee,
      docFee: this.formControlDocFee,
      directorScoreRate: this.formControlDirectorScoreRate,
      nonPropertyOwnerBetween500And600Rate: this.formControlNonPropertyOwnerBetween500And600Rate,
      rbaRate: this.formControlRbaRate,
      maxFacilityEstablishmentFee: this.formControlMaxFacilityEstablishmentFee,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          // NOTE: properties in r should not be null when formGroup is valid
          const v: BusinessOverdraftRateCardValue = {
            propertyOwner: r.propertyOwner ?? 0,
            noPropertyOwner: r.noPropertyOwner ?? 0,
            facilityFee: r.facilityFee ?? 0,
            brokerShareFacilityFee: r.brokerShareFacilityFee ?? 0,
            docFee: r.docFee ?? [],
            directorScoreRate: r.directorScoreRate ?? 0,
            nonPropertyOwnerBetween500And600Rate: r.nonPropertyOwnerBetween500And600Rate ?? 0,
            rbaRate: r.rbaRate ?? 0,
            maxFacilityEstablishmentFee: r.maxFacilityEstablishmentFee ?? 0,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: BusinessOverdraftRateCardValue | undefined): void | BusinessOverdraftRateCardValue {
    if (v) {
      this.formControlPropertyOwner.setValue(v.propertyOwner);
      this.formControlNoPropertyOwner.setValue(v.noPropertyOwner);
      this.formControlFacilityFee.setValue(v.facilityFee);
      this.formControlBrokerShareFacilityFee.setValue(v.brokerShareFacilityFee);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlDirectorScoreRate.setValue(v.directorScoreRate);
      this.formControlNonPropertyOwnerBetween500And600Rate.setValue(v.nonPropertyOwnerBetween500And600Rate);
      this.formControlRbaRate.setValue(v.rbaRate);
      this.formControlMaxFacilityEstablishmentFee.setValue(v.maxFacilityEstablishmentFee);
      console.log("this.formControlBrokerShareFacilityFee",this.formControlBrokerShareFacilityFee)
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
