import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';


@Injectable()
export class AppInsightService {

  appInsights: ApplicationInsights | null = null;

  constructor() {
    if (environment.appInsightsKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsKey!,
          enableAutoRouteTracking: true,
          autoExceptionInstrumented: true,
          autoTrackPageVisitTime: true,
          autoUnhandledPromiseInstrumented: true,
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (this.appInsights) {
      this.appInsights.trackPageView({name, uri});
    } else {
      console.log('[AppInsightService] logPageView ', {name, uri});
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name}, properties);
    } else {
      console.log(`[AppInsightService] logEvent`, {name, properties});
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    } else {
      console.log(`[AppInsightService] logMetric`, {name, average, properties});
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    } else {
      console.log(`[AppInsightService] logException`, {exception, severityLevel}, exception);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message}, properties);
    } else {
      console.log(`[AppInsightService] logTrace`, {message, properties});
    }
  }

}
