import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AssetSettlementGetApplicationAssetFn,
  BusinessLoansApplication, BusinessOverdraftApplication, CreateApplicationNoteFn,
  CreateNewDisbursementFn,
  GetBankDetailsFromOpportunitySfFn,
  GetBillerNameFn,
  GetDisbursementByApplicationIdFn,
  GetInstitutionNameFn,
  isInternalUser,
  PaymentChartData,
  SyncBankDetailsToSfFn,
  SyncDisbursementToSfFn,
  TermRateForBusinessOverdraft,
  UpdateDisbursementFn,
  User,
  ValidateBPAYFn,
  RemoveApplicationNoteByNoteIdFn,
  AddAuditLogFn
} from '@portal-workspace/grow-shared-library';
import {SearchSupplierFn} from '../../asset-cat-and-type-component/ppsr-asset-supplier-search.component';
import {GetApplicationAuditLogsFn} from '../../asset-listing-component/audit-log.component';
import {GetNotesByApplicationIdFn} from '../../asset-listing-component/notes.component';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {Subject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { getUser, setupUntilDestroy } from '../../component-utils';
import { BusinessNumberSearchFn } from '@portal-workspace/grow-ui-library';
import { AssetNotesComponent } from '../../asset-listing-component/asset-notes.component';
import { BusinessOverdraftQuoteComponent } from '../business-overdraft/business-overdraft-quote.component';
import { BusinessDisbursementTableComponent } from '../../asset-listing-component/business-disbursement-table.component';

import { MatCardModule } from '@angular/material/card';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-settlement',
    templateUrl: './business-settlement.component.html',
    styleUrls: ['./business-settlement.component.scss'],
    providers: [
        { provide: MARK, useExisting: forwardRef(() => BusinessSettlementComponent) }
    ],
    standalone: true,
    imports: [MatCardModule, BusinessDisbursementTableComponent, BusinessOverdraftQuoteComponent, AssetNotesComponent]
})
export class BusinessSettlementComponent implements OnInit, Mark {

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();

  @Input({required: true}) application!: BusinessLoansApplication | BusinessOverdraftApplication;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: false}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) validateBpayFn!: ValidateBPAYFn;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;

  isAssetUpdated!: Boolean;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;

  isInternalUser = isInternalUser
  loggedInUser: User | null = getUser()

  mark(): void {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
     
  }
}
