import {Address2ComponentValue} from '../component/address2-component.domain';

export type LiabilityType = 'mortgage' | 'carLoans' | 'otherLoans';
export type PersonalMortgageLiability = {
  liabilityType: {
    type: 'mortgage',
    name: string,
  };
  address: Address2ComponentValue,
  value: number,
}
export type PersonalNonMortgageLiability = {
  liabilityType: {
    type: 'carLoans' | 'otherLoans',
    name: string,
  };
  financier: string,
  value: number,
}
export type PersonalLiability  = PersonalMortgageLiability | PersonalNonMortgageLiability;
export type PersonalLiabilitiesValue = {
  total: number,
  liabilities: PersonalLiability[]
} | null;

export type PersonalLiabilitiesValueOptionType = Exclude<PersonalLiabilitiesValue, null>['liabilities'][number]['liabilityType'][];

export const PersonalLiabilitiesValueOption: PersonalLiabilitiesValueOptionType = [
  {type: 'mortgage',    name: 'Mortgage'},
  {type: 'carLoans',     name: 'Car Loan'},
  {type: 'otherLoans',   name: 'Other Loan'},
];
