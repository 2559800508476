import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CreditRuleItem, CreditRuleItemSelections, GetCreditRuleItemsFn, SelectCreditRuleItemDialogData, SelectCreditRuleItemDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatOptionModule} from '@angular/material/core';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './select-credit-rule-item.dialog.html',
    styleUrls: ['./select-credit-rule-item.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, MatOptionModule, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, MatSelectModule, MatAutocompleteModule]
})
export class SelectCreditRuleItemDialog implements OnInit {

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  disableControls = false;
  subscriptions: Subscription[] = [];
  formControlCreditRuleItem!: FormControl<CreditRuleItem | null>;
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  creditRuleItemOptions: CreditRuleItem[] = [];
  filteredCreditRuleItemOptions: CreditRuleItem[] = [];

  allCreditRuleItems: CreditRuleItem[] = [];
  creditRuleItemSelections: CreditRuleItemSelections = {
    'Default': [],
    'Application': [] ,
    'Bank Statement': [],
    'Bureau': [],
  };

  displayItem: (a: CreditRuleItem) => string = (a) => {
    return a ? `${a.name}` : ``
  }

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SelectCreditRuleItemDialogData,
              private toastService: PortalHotToastService,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<SelectCreditRuleItemDialog, SelectCreditRuleItemDialogResult>) {
    this.formControlCreditRuleItem = formBuilder.control(null, [Validators.required]);
    this.getCreditRuleItemsFn = dialogData.getCreditRuleItemsFn;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);

    this.subscriptions.push(
      this.getCreditRuleItemsFn().pipe(
        this.toastService.spinnerObservable()
      ).subscribe((creditRuleItems: CreditRuleItem[]) => {
        this.allCreditRuleItems = creditRuleItems;
        this.creditRuleItemOptions = this.allCreditRuleItems;
        this.filteredCreditRuleItemOptions = this.creditRuleItemOptions.slice();
      })
    )

  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredCreditRuleItemOptions = this.creditRuleItemOptions.filter(item => 
      `${item.name}`.toLowerCase().includes(filterValue)
    );
  }

  submit() {
    if (this.formControlCreditRuleItem.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        creditRuleItem: this.formControlCreditRuleItem.value as CreditRuleItem,
      })
    }

  }

  cancel() {
    this.dialogRef.close();
  }
}
