<div class="business-quote-component">
  <payment-chart
    [data]="paymentChartData"
    [hideBalloonPayment]="isBusinessLoans"
    [hideBrokerOrigiantionFee]="isBusinessLoans"
    [hideBrokerage]="false"
    [delay]="1000"
    [calculationLog]="calculationLog">
  </payment-chart>
</div>
