import {Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOption, MatOptionModule } from '@angular/material/core';
import {
  BusinessSearch2ResultValue,
  BusinessSearch2Value,
  compareMatch,
  SearchCompanyByABNResult,
  SearchCompanyByNameResult,
  SearchCompanyResult
} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {EntityTypeValue, EntityTypes, EntityTypeValueOptions as EntityTypeOptions, EntityTypeCompanyOption, EntityTypeTrustOption,
  EntityTypePartnershipOption, EntityTypeSoleTraderOption, EntityTypeOtherOption}
  from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {autocompleteObjectValidator, validBusinessValidator} from '@portal-workspace/grow-ui-library';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface BusinessSearch2Fn {
  (search: string): Observable<SearchCompanyResult>;
};


// NOTE: use value object BusinessSearch2Value for FormControl
@UntilDestroy()
@Component({
    selector: 'business-search2',
    templateUrl: './business-search2.component.html',
    styleUrls: ['./business-search2.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessSearch2Component), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BusinessSearch2Component) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule, MatProgressSpinnerModule]
})
export class BusinessSearch2Component implements OnInit, ControlValueAccessor, Mark { // the value when using this component will be BusinessSearch2Value

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @HostListener('document:click', ['$event'])
  pageClick(event: MouseEvent) {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.closePanel();
    }
    // this.loading = false;
    if( this.formControl.value && (typeof this.formControl.value == 'string')) { // free-text
      // this.selected = true;
      this.formControl.setErrors(null);
      this.propagateChange({
        type: 'free-text',
        organisationName: this.formControl.value,
      });
    } else {
      if(this.formControl.touched || this.formControl.dirty) {
        this.formControl.markAsTouched();
      }
    }
  }

  state: 'loading' | 'selected' | 'error' | 'done' | '' = '';

  disabled = false;
  // loading = false;
  // selected = false;
  onChangeFn: any;
  onTouchFn: any;
  val?: BusinessSearch2Value | null = null;

  @Input({required: true}) title = 'Business name';
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;
  @Input({required: true}) searchFn!: BusinessSearch2Fn;
  @Input({required: false}) readOnly:boolean =false ;
  @Input({required: false}) placeholder: string="";
  @Input({required: false}) onlyAcnEntries = false;
  @Input({required: false}) allowFreeText = true;
  // @Output() getBusiness:EventEmitter<BusinessSearchValue>=new EventEmitter();
  formGroup!: FormGroup<{
    search: FormControl<BusinessSearch2Value | string >
  }>;
  formControl!: FormControl<BusinessSearch2Value | string >;
  subscription?: Subscription;
  filteredEntries: Omit<BusinessSearch2ResultValue, 'result'>[] = [];
  displayWithFn: (v: BusinessSearch2Value)=>string = (v) => {
    if (v) {
      if (v.type === 'search-result') {
        return `${v.organisationName} (ABN: ${v.abn})`;
      } else {
        return v.organisationName;
      }
    }
    return '';
    // return v ? `${v.organisationName} (ABN: ${v.abn})` : '';
  };

  constructor(private formBuilder: FormBuilder){
  }

  validAcnValidatorFn: ValidatorFn = (control: AbstractControl) => {
    const b: BusinessSearch2Value = control.value;
    if (b && b.type === 'search-result') {
      if (!!!b.acn) {
        return { 'acn': true}; // error
      }
    }
    return null; // no errors
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const validators = [Validators.required];
    if (this.onlyAcnEntries) {
      validators.push(this.validAcnValidatorFn);
    }
    if (!this.allowFreeText) {
      validators.push(autocompleteObjectValidator());
    }
    this.formControl = this.formBuilder.control('', validators);
    this.formGroup = this.formBuilder.group({
      search: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r === 'string'),
      switchMap(r => {
        if (r === '') {
          this.propagateChange(null);
          return of(null);
        } else {
          // this.selected = false;
          // this.loading = true;
          this.state = 'loading';
          return this.searchFn(r as string);
        }
      }),
      tap((r: SearchCompanyResult | null) => {
        if (r && r?.ABRPayloadSearchResults?.response?.exception?.exceptionCode) { // erronous search result
          this.filteredEntries = [];
          this.state = 'error';
          if (!this.allowFreeText) {
            this.formControl.setErrors({
              businessSearch: true,
            });
          } else {
            // if (!this.selected) {
              this.formControl.setErrors({
                businessSearchOrEnter: true,
              })
            // }
          }
          this.propagateChange(null);
        }
        else if (!r) {  // no search result
          this.state = 'done';
          this.filteredEntries = [];
          if (!this.allowFreeText) {
            this.formControl.setErrors({
              businessSearch: true,
            });
          } else {
            // if (!this.selected) {
              this.formControl.setErrors({
                businessSearchOrEnter: true,
              })
            // }
          }
        }
        else if (this.isSearchResultByName(r)) { // search result by name
          this.filteredEntries = r.records.map( rec => {
            return {
              type: 'search-result',
              abn: String(rec.abn),
              organisationName: rec.organisationName,
              acn: '',
              stateCode: '',
              postcode: '',
              status: rec.status,
              entityTypeValue: this.toEntityTypeValue(r),
            }
          })
        } else { // search result by abn
          this.filteredEntries = [{
            type: 'search-result',
            abn: String(Array.isArray(r.ABN) ? r.ABN.length ? r.ABN[0].identifierValue : '' : r.ABN?.identifierValue),
            acn: String(r.ASICNumber),
            status: r.entityStatus && Array.isArray(r.entityStatus) ? r.entityStatus.length ? r.entityStatus[0] ? r.entityStatus[0].entityStatusCode : '' : '' : r.entityStatus ? r.entityStatus.entityStatusCode : '',
            // status: r.entityStatus && r.entityStatus.length ? r.entityStatus[0].entityStatusCode : '',
            organisationName: r.organisationName,
            stateCode: r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].stateCode : '',
            postcode: String(r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].postcode : ''),
            entityTypeValue: this.toEntityTypeValue(r),
          }];
        }
        this.state = 'done';
      })
    ).subscribe();

    if(this.readOnly) {
      this.formControl.clearValidators();
      this.formControl.updateValueAndValidity();
    }
  }


  isSearchResultByName(s: SearchCompanyResult): s is SearchCompanyByNameResult {
    return (s  && (s as any)['numberOfRecords'] !== null && (s as any)['numberOfRecords'] !== undefined);
  }


  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: BusinessSearch2Value): void {
    if (obj && obj.organisationName) {
      // HACK: to make autocomplete trigger available, auto-retry fn needed.
      setTimeout(()=> {
        if (this.autocompleteTrigger) {
          const options = this.autocompleteTrigger.autocomplete.options.toArray();
          const optionFound = options.find((o) => {
            const v: BusinessSearch2Value = o.value;
            return (v && v.organisationName === obj.organisationName);
          });
          if (optionFound) {
            this.formControl.setValue(optionFound.value);
          } else {
            this.formControl.setValue(obj);
          }
          this.propagateChange(obj);
        }
      }, 1000);
    }
  }

  propagateChange(v?: BusinessSearch2Value) {
    this.val = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v);
  }

  toEntityTypeValue(r: SearchCompanyResult): EntityTypeValue {
    let entityTypeOption = null;
    if (r && !this.isSearchResultByName(r) &&  r.entityType) {
      entityTypeOption = EntityTypeOtherOption;
      const description = r.entityType.entityDescription;
      const code = r.entityType.entityTypeCode;
      if ('IND' == code) {
        entityTypeOption = (EntityTypeSoleTraderOption);
      } else if (description && description.toLowerCase().indexOf('trust') >= 0) {
        entityTypeOption = (EntityTypeTrustOption);
      } else if (description && description.toLowerCase().indexOf('company') >= 0) {
        entityTypeOption = (EntityTypeCompanyOption);
      } else if (description && description.toLowerCase().indexOf('partnership') >= 0) {
        entityTypeOption = (EntityTypePartnershipOption);
      } else {
        entityTypeOption = (EntityTypeOtherOption);
      }
    }
    return entityTypeOption;
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const selected: BusinessSearch2Value = $event.option.value;
    // this.getBusiness.emit(selected)
    if (selected && selected.type === 'search-result') {
      this.searchFn(selected.abn)
        .pipe(
          tap((r: SearchCompanyResult) => {
            let abnAgeMonths = 0
            let gstAgeMonths = 0
            const entityTypeValue = this.toEntityTypeValue(r);
            if (!this.isSearchResultByName(r)) {
              if (r.entityStatus !== undefined) {
                const entityStatus = Array.isArray(r.entityStatus) ? r.entityStatus[0] : r.entityStatus;
                abnAgeMonths = moment().diff(entityStatus.effectiveFrom, 'months');
                r.ABNAgeMonths = abnAgeMonths;
                // this.selected  = true;
                // this.loading = false;
                this.state = 'selected';
              }
              if (r.goodsAndServicesTax !== undefined) {
                let goodsAndServicesTax = Array.isArray(r.goodsAndServicesTax) ? r.goodsAndServicesTax[0] : r.goodsAndServicesTax;
                gstAgeMonths = moment().diff(goodsAndServicesTax.effectiveFrom, 'months');
                r.GSTAgeMonths = gstAgeMonths
              }
              const rst: BusinessSearch2Value = {
                type: 'search-result',
                abn: String(Array.isArray(r.ABN) ? r.ABN.length ? r.ABN[0].identifierValue : '' : r.ABN.identifierValue),
                acn: String(r.ASICNumber),
                organisationName: r.organisationName,
                status: r.entityStatus && Array.isArray(r.entityStatus) ? r.entityStatus.length ? r.entityStatus[0].entityStatusCode : '' : r.entityStatus.entityStatusCode,
                stateCode: r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].stateCode : '',
                postcode: String(r.mainBusinessPhysicalAddress && r.mainBusinessPhysicalAddress.length ? r.mainBusinessPhysicalAddress[0].postcode : ''),
                result: r,
                entityTypeValue,
              };
              this.formControl.setValue(rst, {emitEvent: false});
              if (this.onlyAcnEntries) {
                if (r.ASICNumber) {
                  this.propagateChange(rst);
                } else {
                  this.propagateChange(null);
                }
              } else {
                this.propagateChange(rst);
              }
            }
          })
        ).subscribe();
    }
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  onSearchEnter($event: Event) {
    if (this.allowFreeText) {
      this.formControl.setErrors(null);
      this.formControl.updateValueAndValidity();
      this.propagateChange({
        type: 'free-text',
        organisationName: this.formControl.value as string,
      });
      // this.selected  = true;
      // this.loading = false;
      this.state = 'selected';
      if (this.autocompleteTrigger) {
        this.autocompleteTrigger.closePanel();
      }
    }
  }
}

