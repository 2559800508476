import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import numeral from 'numeral';
import {AssetTier1Value, compareMatch} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {
  createNoDecimalInputMask,
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from '../component-utils';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-tier1',
    templateUrl: './asset-tier1.component.html',
    styleUrls: ['./asset-tier1.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetTier1Component), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetTier1Component), multi: false },
    ],
    standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, FlexModule, MatDividerModule]
})
export class AssetTier1Component extends AbstractControlValueAccessor<AssetTier1Value> implements OnInit, Mark {

    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;

    @Input({required: false}) title: string = '';
    @Input({required: false}) maxYear: number = 15;

    subscriptions: Subscription[] = [];

    createNoDecimalInputMask = createNoDecimalInputMask();
    createTwoDecimalInputMask = createTwoDecimalInputMask();

    formGroup: FormGroup<{
        new: FormControl<number | null>,
        used: FormControl<number | null>,
        year1: FormControl<number | null>,
        year2: FormControl<number | null>,
        assetBackedLowYear: FormControl<number | null>,
        assetBackedMediumYear: FormControl<number | null>,
        assetBackedHighYear: FormControl<number | null>
        noAssetBackedLowYear: FormControl<number | null>,
        noAssetBackedMediumYear: FormControl<number | null>,
        noAssetBackedHighYear: FormControl<number | null>,
    }>;
    formControlNewAssetCondition: FormControl<number | null>;
    formControlUsedAssetCondition: FormControl<number | null>;
    formControlYear1: FormControl<number | null>;
    formControlYear2: FormControl<number | null>;
    formControlAssetBackedLowYear: FormControl<number | null>;
    formControlAssetBackedMediumYear: FormControl<number | null>;
    formControlAssetBackedHighYear: FormControl<number | null>;
    formControlNoAssetBackedLowYear: FormControl<number | null>;
    formControlNoAssetBackedMediumYear: FormControl<number | null>;
    formControlNoAssetBackedHighYear: FormControl<number | null>;

    constructor(private formBuilder: FormBuilder) {
        super();
        this.formControlNewAssetCondition = formBuilder.control(0, [Validators.required]);
        this.formControlUsedAssetCondition = formBuilder.control(0, [Validators.required]);
        this.formControlYear1 = formBuilder.control(5, [Validators.required]);
        this.formControlYear2 = formBuilder.control(10, [Validators.required, Validators.max(this.maxYear)]);
        this.formControlAssetBackedLowYear = formBuilder.control(0, [Validators.required]);
        this.formControlAssetBackedMediumYear = formBuilder.control(0, [Validators.required])
        this.formControlAssetBackedHighYear = formBuilder.control(0, [Validators.required]);
        this.formControlNoAssetBackedLowYear = formBuilder.control(0, [Validators.required]);
        this.formControlNoAssetBackedMediumYear = formBuilder.control(0, [Validators.required]);
        this.formControlNoAssetBackedHighYear = formBuilder.control(0, [Validators.required]);
        this.formGroup = formBuilder.group({
            new: this.formControlNewAssetCondition,
            used: this.formControlUsedAssetCondition,
            year1: this.formControlYear1,
            year2: this.formControlYear2,
            assetBackedLowYear: this.formControlAssetBackedLowYear,
            assetBackedMediumYear: this.formControlAssetBackedMediumYear,
            assetBackedHighYear: this.formControlAssetBackedHighYear,
            noAssetBackedLowYear: this.formControlNoAssetBackedLowYear,
            noAssetBackedMediumYear: this.formControlNoAssetBackedMediumYear,
            noAssetBackedHighYear: this.formControlNoAssetBackedHighYear,
        });
    }

    ngOnInit() {
        setupUntilDestroy(this);
        const sub = this.formGroup.valueChanges.pipe(
            delay(0),
            distinctUntilChanged(compareMatch),
            tap(r  => {
                if (this.formGroup.valid) {
                    const v: Exclude<AssetTier1Value, null> = {
                        YearRangeThresholds: [String(r.year1), String(r.year2)],
                        AssetBacked: {
                            AssetCondition: [
                              { Type: 'New', Rate: (this.formControlNewAssetCondition.value ?? 0).toString() },
                              { Type: 'Used', Rate: (this.formControlUsedAssetCondition.value ?? 0).toString() },
                            ],
                            YearRanges: [
                                {
                                    YearRange: `0 - ${r.year1} years`,
                                    YearRangeType: 'Low',
                                    Operator: '<=',
                                    Threshold: `${r.year1}`,
                                    Rate: `${r.assetBackedLowYear}`,
                                },
                                {
                                    YearRange: ` ${this.parseToInt(r.year1) + 1} - ${r.year2} years`,
                                    YearRangeType: 'Medium',
                                    Operator: '-',
                                    Threshold: `${this.parseToInt(r.year1) + 1}-${r.year2}`,
                                    Rate: `${r.assetBackedMediumYear}`,
                                },
                                {
                                    YearRange: `${this.parseToInt(r.year2) + 1} - ${this.maxYear} years`,
                                    YearRangeType: 'High',
                                    Operator: '>',
                                    Threshold: `${r.year2}`,
                                    Rate: `${r.assetBackedHighYear}`,
                                },
                            ]
                        },
                        // NotAssetBacked: {
                        //     YearRanges: [
                        //         {
                        //             YearRange: `0 - ${r.year1} years`,
                        //             YearRangeType: 'Low',
                        //             Operator: '<=',
                        //             Threshold: `${r.year1}`,
                        //             Rate: `${r.noAssetBackedLowYear}`,
                        //         },
                        //         {
                        //             YearRange: ` ${this.parseToInt(r.year1) + 1} - ${r.year2} years`,
                        //             YearRangeType: 'Medium',
                        //             Operator: '-',
                        //             Threshold: `${this.parseToInt(r.year1) + 1}-${r.year2}`,
                        //             Rate: `${r.noAssetBackedMediumYear}`,
                        //         },
                        //         {
                        //             YearRange: `${this.parseToInt(r.year2) + 1} - ${this.maxYear} years`,
                        //             YearRangeType: 'High',
                        //             Operator: '>',
                        //             Threshold: `${r.year2}`,
                        //             Rate: `${r.noAssetBackedHighYear}`,
                        //         },
                        //     ]
                        // },
                    };
                    this.propagateChange(v);
                } else {
                    this.propagateChange(null);
                }
            })).subscribe();
    }

    doWriteValue(v: AssetTier1Value | null | undefined): void | AssetTier1Value {
        if (v) {
            const newAssetConditionRate = (v.AssetBacked.AssetCondition ?? []).find(m => m.Type == 'New');
            const usedAssetConditionRate = (v.AssetBacked.AssetCondition ?? []).find(m => m.Type == 'Used');
            this.formControlNewAssetCondition.setValue(numeral(newAssetConditionRate?.Rate).value());
            this.formControlUsedAssetCondition.setValue(numeral(usedAssetConditionRate?.Rate).value());
            this.formControlYear1.setValue(numeral(v.YearRangeThresholds[0]).value());
            this.formControlYear2.setValue(numeral(v.YearRangeThresholds[1]).value());
            if (v.YearRangeThresholds && v.YearRangeThresholds.length && numeral(v.YearRangeThresholds).value()) {
                if (v.YearRangeThresholds.length >=1 && numeral(v.YearRangeThresholds[0]).value()) {
                    this.formControlYear1.setValue(numeral(v.YearRangeThresholds[0]).value());
                }
                if (v.YearRangeThresholds.length >=2 && numeral(v.YearRangeThresholds[1]).value()) {
                    this.formControlYear2.setValue(numeral(v.YearRangeThresholds[1]).value());
                }
            }
            if (v.AssetBacked) {
                const lowYear = (v.AssetBacked.YearRanges ??[]).find(opt => opt.YearRangeType === 'Low');
                if (lowYear && numeral(lowYear.Rate).value()) {
                    this.formControlAssetBackedLowYear.setValue(numeral(lowYear.Rate).value());
                }
                const mediumYear = (v.AssetBacked.YearRanges ?? []).find(opt => opt.YearRangeType === 'Medium');
                if (mediumYear && numeral(mediumYear.Rate).value()) {
                    this.formControlAssetBackedMediumYear.setValue(numeral(mediumYear.Rate).value());
                }
                const highYear = (v.AssetBacked.YearRanges ?? []).find(opt => opt.YearRangeType === 'High');
                if (highYear && numeral(highYear.Rate).value()) {
                    this.formControlAssetBackedHighYear.setValue(numeral(highYear.Rate).value());
                }
            }
            // if (v.NotAssetBacked) {
            //     const lowYear = (v.NotAssetBacked.YearRanges ?? []).find(opt => opt.YearRangeType === 'Low');
            //     if (lowYear && numeral(lowYear.Rate).value()) {
            //         this.formControlNoAssetBackedLowYear.setValue(numeral(lowYear.Rate).value());
            //     }
            //     const mediumYear = (v.NotAssetBacked.YearRanges ?? []).find(opt => opt.YearRangeType === 'Medium');
            //     if (mediumYear && numeral(mediumYear.Rate).value()) {
            //         this.formControlNoAssetBackedMediumYear.setValue(numeral(mediumYear.Rate).value());
            //     }
            //     const highYear = (v.NotAssetBacked.YearRanges ?? []).find(opt => opt.YearRangeType === 'High');
            //     if (highYear && numeral(highYear.Rate).value()) {
            //         this.formControlNoAssetBackedHighYear.setValue(numeral(highYear.Rate).value());
            //     }
            // }
        }
        return undefined;
    }

    parseToInt(i: any): number {
        return parseInt(i, 10);
    }

    mark(): void {
        this.formGroup.markAllAsTouched();
    }
}
