import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart, ChartItem, TooltipItem} from 'chart.js';
import {AccountSummary, DayEndBalanceSummary} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {getCssVariableContent} from "@portal-workspace/grow-ui-library";

@Component({
    selector: 'day-end-balance-chart',
    templateUrl: './day-end-balance-chart.component.html',
    styleUrls: ['./day-end-balance-chart.component.scss'],
    standalone: true
})
export class DayEndBalanceChartComponent implements OnInit, OnChanges {
  @Input({required: false}) data!: DayEndBalanceSummary[];
  @Input({required: false}) index: number = 0;
  @Input({required: false}) lightTheme = false;

  chart!: Chart;
  dynamoneyBlue =  getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
  dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200');  // '#B9A6F0';
  labels: string[] = [];
  balances: number[] = [];

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins:{
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<'line'>) =>
            `${item.dataset.label}: ${(item.raw as number)
              .toLocaleString("en-AU",{ style:"currency", currency:"AUD" })}`,
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        },
        grid: {
          display: true,
          borderDash: [5, 5]
        },
        ticks: {
          maxTicksLimit: 13
        }
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback: function(value: number) {
            return value.toLocaleString("en-AU",{ style:"currency", currency:"AUD" });
          }
        }
      }
    }
  };

  constructor() {}

  ngOnInit(): void {
    this.dynamoneyBlue =  getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
    this.dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200');  // '#B9A6F0';
    this.initDataAndLabels();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dynamoneyBlue =  getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
      this.dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200');  // '#B9A6F0';
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    this.initDataAndLabels();
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  initDataAndLabels() {
    this.labels = [...this.data].reverse()
      .map((obj) => moment(obj.date).format('DD MMM YYYY'));
    this.balances = [...this.data].reverse().map(obj => parseFloat(obj.balance));
    // const reversedDayAndBalances = [...this.data.dayEndBalances].reverse();
    // reversedDayAndBalances.forEach((obj: DayEndBalanceSummary, index: number) => {
    //   if (index < reversedDayAndBalances.length - 1) {
    //     const currentMonth = obj.date.slice(5, 7);
    //     const nextMonth = reversedDayAndBalances[index + 1].date.slice(5, 7);
    //     if (currentMonth !== nextMonth) {
    //       this.labels.push(moment(obj.date).format('DD MMM YYYY'));
    //     } else {
    //       this.labels.push('');
    //     }
    //   } else {
    //     this.labels.push(moment(obj.date).format('DD MMM YYYY'));
    //   }
    // })
  }

  display() {
    const chartElement = document.getElementById('day-end-balance-chart' + this.index) as any;
    if (chartElement) {
      this.chart = new Chart(chartElement, {
        type: 'line',
        options: this.chartOptions,
        data: {
          labels: this.labels,
          datasets: [
            {
              label: 'Balance',
              borderColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              pointRadius: 0,
              pointBackgroundColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              data: this.balances
            },
          ]
        }
      });
    }

  }
}
