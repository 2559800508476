<div class="corporate-loans-application-details">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <div class="separator-bottom row">
      <div class="col-lg-8 col-md-12 mb-1" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button class="mat-flat-button-custom w-lt-md-100 btn-black-border">
          <application-type-icon applicationType="CorporateLoans" />
          Corporate Loan
          @if (application.Source == 'external') {
            <span class="ml-2 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
          }
          @if (application.Source == 'direct') {
            <span class="ml-2 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
          }
        </button>
      </div>
      <div class="col-lg-4 col-md-12 mb-1">
        <application-stage-icon [stage]="getApplicationStage(application)"></application-stage-icon>
      </div>
    </div>
  </div>
  @if (application.SystemCreditStatus && isInternalUser(loggedInUser)) {
    <div class="row">
      <div class="col-12">
        <message-box type="warn">This application was processed through the newly implemented credit evaluation system</message-box>
      </div>
    </div>
  }
  <mat-tab-group
    [mat-stretch-tabs]="false"
    mat-align-tabs="start"
    [(selectedIndex)]="currentSectionIndex"
    (selectedTabChange)="onSelectedTabChange($event)">
    @for (currentTab of tabs; track currentTab) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi {{currentTab.iconClass}}"></span>
          {{currentTab.name}}
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>

  @if (currentSection === 'asset') {
    <div class="row separator-bottom">
      <div class="col-12 btn-space header"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start"
        >
        @if (allowContractGeneration) {
          <button mat-flat-button [disabled]="!applicationStageAllowGenerateContract(application)" (click)="openGenerateContractDialog()">
            <span class="mdi mdi-file-sign pr-2" matPrefix></span>
            <span class="label">Generate Contract</span>
          </button>
        }
        @if (activeContract && allowContractGeneration) {
          <button mat-flat-button (click)="openContractStatusDialog()">
            <span class="mdi mdi-account-supervisor-outline pr-2" matPrefix></span>
            <span class="label">Signer Status</span>
          </button>
        }
        <button mat-flat-button [disabled]="!applicationStageAllowSettleLoan(application)" (click)="onSettleLoan()">
          <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
          <span class="label">Request Settlement</span>
        </button>
      </div>
    </div>
  }
  <div>
    @if (currentSection === 'app') {
      <corporate-loans-app
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        (events)="events.emit($event)"
        [copyApplicationFn]="copyApplicationFn"
        [getGeoLocationFn]="getGeoLocationFn"
        [ip]="ip"
        [application]="application"
        [withdrawApplicationFn]="withdrawApplicationFn"
        (navigateToApplications)="navigateToApplications.emit()">
      </corporate-loans-app>
    }
    @if (currentSection === 'bank-statement') {
      <bank
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [application]="application"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn">
      </bank>
    }
    @if (currentSection === 'asset') {
       
        <corporate-loans-settlement
        [application]="application"
        [data]="data"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn">
      </corporate-loans-settlement>
    }
    @if (currentSection === 'kyc-aml') {
      <kyc-verification
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [idVerifyUrl]="idVerifyUrl"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [application]="application">
      </kyc-verification>
    }
    @if (currentSection === 'credit') {
      <corporate-loans-credit
        [application]="application"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        [apiUrl]="apiUrl"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [getUserFn]="getUserFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getApplicationDscrFn]="getApplicationDscrFn"
        (viewReport)="onViewReport($event)"
        (clickApplicationEvent)="onClick($event)"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        />
    }
    @if (currentSection === 'credit-report') {
      <credit-report
        [getWebServiceReportFn]="getWebServiceReportFn"
        [apiUrl]="apiUrl"
        (leaveCreditReport)="onLeaveReport($event)"
      ></credit-report>
    }
    @if (currentSection === 'documents') {
      <corporate-loans-documents
        [apiUrl]="apiUrl"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
         
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [application]="application"
        [getUserFn]="getUserFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
      ></corporate-loans-documents>
    }
    @if (currentSection === 'pricing') {
      <corporate-loans-pricing
        [application]="application"
        [currentRstValue] = "rst"
        [getContractStatusFn]="getContractStatusFn"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [getRateCardDetailsFn]="getRatecardDetailsFn"
        (events)="onCorporateLoansPricingEvents($event)">
      </corporate-loans-pricing>
    }
  </div>

</div>
