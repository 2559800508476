<div class="currency-selection-component">
  <form [formGroup]="formGroup">
    <mat-form-field [ngClass]="{readonly: readonly}">
      <mat-label>{{title}}</mat-label>
      <mat-select [formControl]="formControl">
        <mat-select-trigger>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <!--img width="{{pxToRem(32)}}" height="{{pxToRem(15)}}" src="{{getImage(formControl.value)}}"><span>{{formControl.value}}</span-->
            <img width="32px" height="12px" src="{{formControl.value ? getImage(formControl.value):''}}"><span>{{formControl.value}}</span>
          </div>
        </mat-select-trigger>
        @for (flagMapping of flagMappings; track flagMapping) {
          <mat-option [value]="flagMapping.currencyCode">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <!--img width="{{pxToRem(32)}}" height="{{pxToRem(15)}}" src="{{flagMapping.image}}"-->
              <img width="32px" height="13px" src="{{flagMapping.image}}">
              <span>{{flagMapping.currencyCode}}</span>
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
