import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {EditBrokerageDialogData, EditBrokerageDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { PercentageInputComponent } from '../percentage-input-component/percentage-input.component';
import _ from 'lodash';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './edit-brokerage.dialog.html',
    styleUrls: ['./edit-brokerage.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, NgxCurrencyDirective, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, CurrencyInputComponent, PercentageInputComponent]
})
export class EditBrokerageDialog implements OnInit {

  disableControls = false;
  formGroup: FormGroup<{
    brokerageAmount: FormControl<number | null>,
    brokeragePercentage: FormControl<number | null>,
  }>;
  formControlBrokerageAmount!: FormControl<number | null>;
  formControlBrokeragePercentage!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  options = { prefix: '', thousands: ',', decimal: '.', inputMode: NgxCurrencyInputMode.Natural, align: 'left' }

  subscriptions: Subscription[] = [];

  loanAmount!: number;

  MAX_BROKERAGE_AMOUNT: number;
  MAX_BROKERAGE_PERCENTAGE = 8;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: EditBrokerageDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EditBrokerageDialog, EditBrokerageDialogResult>) {
    this.loanAmount = dialogData.loanAmount;
    let maxBrokerage = _.round(this.MAX_BROKERAGE_PERCENTAGE * this.loanAmount / 100, 2);
    this.MAX_BROKERAGE_AMOUNT = maxBrokerage;
    if (dialogData.maxBrokeragePercentage !== undefined) {
      this.MAX_BROKERAGE_PERCENTAGE = dialogData.maxBrokeragePercentage;
      maxBrokerage = _.round(this.MAX_BROKERAGE_PERCENTAGE * this.loanAmount / 100, 2);
      this.MAX_BROKERAGE_AMOUNT = maxBrokerage;
    }
    
    const initialPercentage = _.round(dialogData.brokerageAmount * 100 / this.loanAmount, 2);

    this.formControlBrokerageAmount = formBuilder.control(dialogData.brokerageAmount, [Validators.required, Validators.min(0), Validators.max(maxBrokerage)]);
    this.formControlBrokeragePercentage = formBuilder.control(initialPercentage, [Validators.required, Validators.min(0), Validators.max(this.MAX_BROKERAGE_PERCENTAGE)]);
    this.formGroup = this.formBuilder.group({
      brokerageAmount: this.formControlBrokerageAmount,
      brokeragePercentage: this.formControlBrokeragePercentage
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onChangeBrokerageAmount(event: KeyboardEvent) {
    const value = this.formControlBrokerageAmount.value ?? 0;
    this.formControlBrokeragePercentage.setValue(_.round((value ?? 0) * 100 / this.loanAmount, 2))
  }

  onChangeBrokeragePercentage(event: KeyboardEvent) {
    const value = this.formControlBrokeragePercentage.value ?? 0;
    this.formControlBrokerageAmount.setValue(_.round((value ?? 0) * this.loanAmount / 100, 2))
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        brokerageAmount: this.formControlBrokerageAmount.value as number,
        brokeragePercentage: this.formControlBrokeragePercentage.value as number,
      })
    }

  }

  cancel() {
    this.dialogRef.close();
  }
}
