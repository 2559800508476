<div class="disbursement-payee-dialog">
  <mat-dialog-content>
    <h3 class="header">Bank Details</h3>
    <div class="row">
      <div class="col-8">
      </div>
    </div>
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Payment Type</mat-label>
            <mat-select [formControl]="formControlPaymentType">
              <mat-option [value]="'direct-debit'">Direct Credit</mat-option>
              <mat-option [value]="'bpay'">Bpay</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      @if (formControlPaymentType.value !== 'bpay') {
        <div style="width: 100%;" class="row">
          <local-bank-account-detail [getInstitutionNameFn]="getInstitutionNameFn"
          [formControl]="formControlDirectCredit"></local-bank-account-detail>
        </div>
      }
      @if (formControlPaymentType.value === 'bpay') {
        <div style="width: 100%;">
          <bpay-bank-details [formControl]="formControlBpay" [getBillerNameFn]="getBillerNameFn"></bpay-bank-details>
        </div>
      }
    </form>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
