import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CESNPSmodalComponent} from './CES-NPS-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        CESNPSmodalComponent
    ],
    exports: [
        CESNPSmodalComponent
    ]
})
export class CESNPSmodalModule {
}
