<div class="disbursement-amount-dialog">
  <mat-dialog-content>
    <h3 class="header">Please edit the disbursement amount</h3>
    <div class="row">
      <div class="col-8">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input [formControl]="formControlAmount" matInput placeholder="Enter disbursement amount" type="number">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formControlAmount.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
