import {Component, Input} from '@angular/core';
import {GuarantorComponentEvent, } from '@portal-workspace/grow-ui-library';
import {GuarantorValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { LiabilitiesSummaryComponent } from './liabilities-summary.component';
import { AssetsSummaryComponent } from './assets-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'guarantors-summary',
    template: `
    @if (guarantors) {
      @for (guarantor of guarantors; track guarantor; let index = $index) {
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">Guarantor {{index + 1}}</div>
          </div>
        </div>
        @if (guarantor.type === 'Entity') {
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Type</div>
                <div body>{{guarantor.type}}</div>
              </cell>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Organisation</div>
                <div body>{{guarantor.organisation.name}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Organisation type</div>
                <div body>{{guarantor.organisationType.name}}</div>
              </cell>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>ABN</div>
                <div body>{{guarantor.abn}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>ACN</div>
                <div body>{{guarantor.acn}}</div>
              </cell>
            </div>
          </div>
        }
        @if (guarantor.type === 'Individual') {
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Title</div>
                <div body>{{guarantor.title?.name}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Type</div>
                <div body>{{guarantor.type}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>First name</div>
                <div body><p [innerHTML]="guarantor.firstName | ellipsis:10"></p></div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Last name</div>
                <div body><p [innerHTML]="guarantor.lastName | ellipsis:10"></p></div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Gender</div>
                <div body>{{guarantor.gender}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Date of birth</div>
                <div body>{{displayableDate(guarantor.dob)}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Residential address</div>
                <div body>{{guarantor.residentialAddress?.address}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Privacy consent</div>
                <div body>{{booleanToYesNo(guarantor.privacyConsentObtained)}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          @if (guarantor && guarantor.kind ==='WithoutPropertyAddress') {
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Property Owner</div>
                  <div body>{{booleanToYesNo(guarantor.propertyOwner)}}</div>
                </cell>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          }
          @if (guarantor && guarantor.kind === 'WithPropertyAddress') {
            @if (guarantor.propertyOwner) {
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Property Owner</div>
                    <div body>{{booleanToYesNo(guarantor.propertyOwner.propertyOwner)}}</div>
                  </cell>
                </div>
              </div>
            }
            @if (guarantor.propertyOwner && guarantor.propertyOwner.propertyOwner) {
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <cell>
                    <div label>Property address</div>
                    <div body>{{guarantor.propertyOwner.address?.address}}</div>
                  </cell>
                </div>
              </div>
            }
            @if (guarantor.propertyOwner && guarantor.propertyOwner.propertyOwner) {
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Property value</div>
                    <div body>{{guarantor.propertyOwner.propertyValue}}</div>
                  </cell>
                </div>
                <div class="col-lg-3 col-md-12">
                  <cell>
                    <div label>Mortgage value</div>
                    <div body>{{guarantor.propertyOwner.mortgageValue}}</div>
                  </cell>
                </div>
              </div>
            }
            <mat-divider [inset]="true"></mat-divider>
          }
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Guarantor</div>
                <div body>Yes</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Email address</div>
                <div body>{{guarantor.email}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Business landline</div>
                <div body>{{guarantor.mobileNumber}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <assets [assets]="guarantor.personalAssets"></assets>
          <liabilities-summary [liabilities]="guarantor.personalLiabilities"></liabilities-summary>
        }
      }
    }
    `,
    standalone: true,
    imports: [CellComponent, MatDividerModule, AssetsSummaryComponent, LiabilitiesSummaryComponent, EllipsisPipe]
})
export class GuarantorsSummaryComponent {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) guarantors?: GuarantorValue;
}
