import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'error-notification',
    templateUrl: './error-notification.component.html',
    styleUrls: ['./error-notification.component.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class ErrorNotification {
  @Input({required: true}) errorTitle!: string;

  @Input({required: true}) errorMessage!: string;

  @Input({required: false}) errorSolution!: string;

  @Output() onRetry = new EventEmitter();

  retryClicked(): void {
    this.onRetry.emit('');
  }
}
