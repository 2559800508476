<div class="entity-type-component">
  <form [formGroup]="formGroup">
    @if (readonly) {
      <mat-form-field>
        <mat-label>{{title + (readonly?' (readonly)': '')}}</mat-label>
        <input matInput readonly [value]="formControl.value?.name">
      </mat-form-field>
    } @else {
      <mat-form-field>
        <mat-select
          [disableControl]="disabled"
          [formControl]="formControl"
          [compareWith]="compareWith">
          @for (option of options; track option) {
            <mat-option [value]="option">{{option.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </form>
</div>
