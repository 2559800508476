<div class="lvr-calculator-component">
  <!-- assets and liabilities table -->
  <div class="assets-and-liabilities-table">
    <!-- asset table -->
    <div class="row separator">
      <div class="col-12"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start"
        >
        <button mat-flat-button color="primary" (click)="addAsset()">
          <span class="mdi mdi-plus-box-outline" matPrefix></span>
          Add Asset
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title><span class="mat-h3">Assets</span></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="asset-table-section">
              <table mat-table [dataSource]="assets" #assetTable="matTable">
                <ng-container matColumnDef="assetDescription">
                  <th mat-header-cell *matHeaderCellDef>Assets</th>
                  <td mat-cell *matCellDef="let assetFormControls; let i = index;" class="pt-3">
                    <mat-form-field>
                      <mat-label>Description</mat-label>
                      <input matInput [formControl]="assetFormControls.descriptionFormControl">
                      @for (errorKey of errorKeys(assetFormControls.descriptionFormControl); track errorKey) {
                        <mat-error>
                          @if (assetFormControls.descriptionFormControl.touched && assetFormControls.descriptionFormControl.hasError(errorKey)) {
                            {{errorMessage(assetFormControls.descriptionFormControl, errorKey)}}
                          }
                        </mat-error>
                      }
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>Total Assets</td>
                </ng-container>
                <ng-container matColumnDef="assetValue">
                  <th mat-header-cell *matHeaderCellDef>Valuations</th>
                  <td mat-cell *matCellDef="let assetFormControls; let i = index" class="pt-3">
                    <currency-input title="Valuation" [formControl]="assetFormControls.valueFormControl"></currency-input>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>{{totalAssets | looseCurrency}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let assetFormControls">
                    <span class="mdi mdi-delete-outline" matPrefix matTooltip="Remove asset" (click)="removeAsset(assetFormControls)"></span>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="assetDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: assetDisplayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="assetDisplayedColumns"></tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>


    <!-- liabilities table -->
    <div class="row separator">
      <div class="col" fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" (click)="addLiability()">
          <span class="mdi mdi-plus-box-outline" matPrefix></span>
          Add Liability
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title><span class="mat-h3">Liabilities</span></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="liabilities-table-section">
              <table mat-table [dataSource]="liabilities" #liabilitiesTable="matTable">
                <ng-container matColumnDef="liabilityDescription">
                  <th mat-header-cell *matHeaderCellDef>Liabilities</th>
                  <td mat-cell *matCellDef="let liabilityFormControls; let i = index;" class="pt-3">
                    <mat-form-field>
                      <mat-label>Description</mat-label>
                      <input matInput [formControl]="liabilityFormControls.descriptionFormControl">
                      @for (errorKey of errorKeys(liabilityFormControls.descriptionFormControl); track errorKey) {
                        <mat-error>
                          @if (liabilityFormControls.descriptionFormControl.touched && liabilityFormControls.descriptionFormControl.hasError(errorKey)) {
                            {{errorMessage(liabilityFormControls.descriptionFormControl, errorKey)}}
                          }
                        </mat-error>
                      }
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>Total Liabilities</td>
                </ng-container>
                <ng-container matColumnDef="liabilityValue">
                  <th mat-header-cell *matHeaderCellDef>Valuations</th>
                  <td mat-cell *matCellDef="let liabilityFormControls; let i = index" class="pt-3">
                    <currency-input title="Valuation" [formControl]="liabilityFormControls.valueFormControl"></currency-input>
                  </td>
                  <td mat-footer-cell *matFooterCellDef>{{totalLiabilities | looseCurrency}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let liabilityFormControls">
                    <span class="mdi mdi-delete-outline" matPrefix (click)="removeLiability(liabilityFormControls)" matTooltip="Remove liability"></span>
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="liabilitiesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: liabilitiesDisplayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="liabilitiesDisplayedColumns"></tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>


  <!-- computed fields -->
  <div class="computed-fields separator">
    <mat-card>
      <mat-card-header>
        <mat-card-title><span class="mat-h3">Calculations</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row separator">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Current LVR</mat-label>
              <input matInput [formControl]="formControlCurrentLvr">
              <span class="mdi mdi-percent" matSuffix></span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <currency-input
              title="Proposed Loan"
              [required]="true"
              [formControl]="formControlProposedLoan">
            </currency-input>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Proposed LVR with Loan</mat-label>
              <input matInput [formControl]="formControlProposedLvr">
              <span class="mdi mdi-percent" matSuffix></span>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
