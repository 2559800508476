<div class="asset-condition-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Asset condition</mat-label>
      <mat-select [formControl]="formControl" placeholder="Select condition..." [compareWith]="compareWithFn">
        @for (option of options; track option) {
          <mat-option [value]="option">
            {{option.name}}
          </mat-option>
        }
      </mat-select>
      @if (formControl.touched && formControl.hasError('required')) {
        <mat-error>This field is required.</mat-error>
      }
    </mat-form-field>
  </form>
</div>
