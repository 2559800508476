import {validApplicationTypeGuard} from './abstract-application.guard';

export const validAssetFinanceGuard = () => {
  return validApplicationTypeGuard('AssetFinance', 'e-not-asset');
}



// NOTE: Deprecated since Angular 16, replaced by validApplicationTypeGuard() instead
// import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// import {Observable, Subject, Subscription} from 'rxjs';
// import {ApplicationService, } from '../service/application.service';
// import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
// import {
//   navigationUrlForApplicationErrorPage,
// } from '../service/navigation-urls';
// import {Injectable} from '@angular/core';
// import {UntilDestroy} from '@ngneat/until-destroy';
// import {catchError, tap} from 'rxjs/operators';
// import {AbstractApplicationGuard} from './abstract-application.guard';
//
//
// @Injectable()
// export class ValidAssetFinanceGuard extends AbstractApplicationGuard {
//
//
//   constructor(applicationService: ApplicationService,
//               router: Router) {
//     super(applicationService, router);
//   }
//
//   getApplicationType(): ApplicationTypes {
//     return 'AssetFinance';
//   }
//
//   getApplicationTypeErrorCode(): string {
//     return 'e-not-asset';
//   }
// }
