<div class="user-same-company-selection-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input #input
           type="text"
           matInput
           [formControl]="formControl"
           [matAutocomplete]="auto"
           (input)="filter()"
           (focus)="filter()">
    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayBroker">
      @for (option of filteredOptions; track option) {
        <mat-option [value]="option">{{option.FirstName}} {{option.LastName}} ({{option.Email}})</mat-option>
      }
    </mat-autocomplete>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
