import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  BankStatementsAnalysisTransactionDetails,
  CategoryDetailsTableData, CategoryTransactionCheckbox, compare,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET
} from '@portal-workspace/grow-shared-library';
import {PageEvent} from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'category-details-table',
    templateUrl: './category-details-table.component.html',
    styleUrls: ['./category-details-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatSortModule, NgClass, ExtendedModule, MatCheckboxModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class CategoryDetailsTableComponent implements OnInit, OnChanges {
  @Input({required: false}) data: BankStatementsAnalysisTransactionDetails[] = [];
  @Output() checkbox: EventEmitter<{ [id: number]: boolean }> = new EventEmitter();
  columnsToDisplay: string[] = ['date', 'party', 'text', 'credit', 'debit', 'action'];
  dataSource: CategoryDetailsTableData[] = [];
  displayedData: CategoryDetailsTableData[] = [];
  transactionCheckbox: { [id: number]: boolean } = {};

  limit!: number;
  offset!: number;
  total!: number;
  actionButtonClicks = 0;
  constructor() {}

  ngOnInit(): void {
    this.initPageData();
    this.initTransactionCheckbox();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initPageData();
    this.initTransactionCheckbox();
  }

  initPageData(): void {
    this.dataSource = [];
    this.data.forEach((d: BankStatementsAnalysisTransactionDetails) => {
      let row: CategoryDetailsTableData = {
        date: d.date,
        text: d.text,
        debit: 0,
        credit: 0,
        party: '',
        id: d.id,
      };
      d?.tags?.forEach(obj => {
        if ("creditDebit" in obj) obj['creditDebit'] === 'credit' ? row.credit = d.amount : row.debit = d.amount;
        if ("thirdParty" in obj) row.party = obj['thirdParty'];
      })
      this.dataSource.push(row);
    })

    this.total = this.dataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  initTransactionCheckbox() {
    for (const d of this.data) {
      this.transactionCheckbox[d.id] = true;
    }
  }

  onToggleCheckbox(id: number) {
    this.transactionCheckbox[id] = !this.transactionCheckbox[id];
    this.checkbox.emit(this.transactionCheckbox);
  }

  clickAll() {
    for (const key of Object.keys(this.transactionCheckbox)) {
      this.transactionCheckbox[Number(key)] = !(this.actionButtonClicks % 2);
    }
    this.checkbox.emit(this.transactionCheckbox);
    this.actionButtonClicks ++;
  }

  updateDisplayedData() {
    this.displayedData = this.dataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onSort(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'text':
          return compare(a.text, b.text, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        case 'party':
          return compare(a.party, b.party, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'text': return 'Description';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'party': return 'Party';
      case 'action': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit', 'action'].includes(column);
  }
}
