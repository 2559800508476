<div class="error-page container-fluid">
  <!-- e-not-operator: not an operator -->
  @if (activatedRoute.snapshot.params.errorCode === 'e-not-operator') {
    <div class="row separator-bottom">
      <div class="col-12">
        <message-box type="danger" title="Error">
          <div>Only accessible by card operator</div>
        </message-box>
      </div>
    </div>
  }
</div>
