<div class="asset-category-rate-component">
  @if (title) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h4-custom">{{title}}</div>
      </div>
    </div>
  }
  @for (formGroup of formArray.controls; track formGroup) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Asset Category</mat-label>
          <mat-select [formControl]="formGroupControl(formGroup, 'category')">
            @for (category of categories; track category) {
              <mat-option [value]="category.index">
                {{category.index}} {{category.value}}
              </mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(formGroupControl(formGroup, 'category')); track errorKey) {
            <mat-error>
              @if (formGroupControl(formGroup, 'category').touched && formGroupControl(formGroup, 'category').hasError(errorKey)) {
                {{errorMessage(formGroupControl(formGroup, 'category'), errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formGroupControl(formGroup, 'rate')">
          <span class="mdi mdi-percent" matSuffix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-1 col-md-12 mt-3">
        <span class="mdi mdi-close"(click)="onRemove($event)"></span>
      </div>
    </div>
  }
  <div class="row">
    <div class="col-12"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="10px">
      <button mat-stroked-button (click)="onAdd($event)" class="mat-flat-button-custom">
        <span class="mdi mdi-plus"></span>
        Add
      </button>
      <!-- <button mat-stroked-button (click)="onRemove($event)">
      <span class="mdi mdi-minus"></span>
      Remove
    </button> -->
  </div>
</div>
</div>
