import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {AlertDialogData, AlertDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    templateUrl: './alert.dialog.html',
    styleUrls: ['./alert.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class AlertDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
              private dialogRef: MatDialogRef<AlertDialog, AlertDialogResult>,
              private formBuilder: FormBuilder) {

  }

  onSubmit($event: Event) {
    const result: AlertDialogResult = {}
    this.dialogRef.close(result);
  }
}
