<div class="business-and-overdraft-borrowing-estimator-page container-fluid">

  <message-box title="Note">
    <ul>
      <li>Overdue ATO debt is not acceptable (manageable ATO payment plans accepted)</li>
      <li>We will not lend alongside other non-bank cashflow providers/facilities or consolidate multiple facilities</li>
      <li>History of regularly dishonouring payments is not acceptable</li>
      <li>Subject to satisfactory review of applicant and director credit files, risk profile & bank statements</li>
    </ul>
  </message-box>

  <div class="calculator-content mt-4">
    <div class="section-format mt-1 p-4">
      <div class="row">
        <div class="col-12">
          <h3>Borrowing Estimator</h3>
        </div>
      </div>

      <!-- Monthly Turnover Retrieval:  "Bank statement" or "Manual" -->
      <div class="row mt-2">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Monthly turnover retrieval"
            [yesText]="'Bank Statement'"
            [noText]="'Manual'"
            [formControl]="formControlMonthlyTurnoverRetrieval"
          />
        </div>
      </div>

      <ng-container *ngTemplateOutlet="(formControlMonthlyTurnoverRetrieval.value) ? bankstatement : manual">
      </ng-container>

    </div>
  </div>

</div>


<!--
  #
  # Calculation results - overdraft
  #
-->
<ng-template #overdraftEstimationResultTemplate>
  @if (overdraftPreliminaryBorrowingEstimationResult) {
    <div class="section-format mt-1 p-4">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="section-notice-format p-4">
            <div class="row">
              <div class="col">
                <h3>Business Overdraft</h3>
              </div>
            </div>
            <!-- accepted: Overdraft -->
            @if (overdraftPreliminaryBorrowingEstimationResult.type == 'success') {
              <div class="row mt-4">
                <div class="col">
                  <range-slider
                    title="Borrowing Estimation: "
                    [min]="OVERDRAFT_SLIDER_BORROWING_MIN"
                    [max]="OVERDRAFT_SLIDER_BORROWING_MAX"
                    [step]="1"
                    [disableControl]="true"
                    [showPermanentTick]="true"
                    [autoAdjustMax]="true"
                    [formControl]="formControlOverdraftBorrowingRange"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    mat-stroked-button color="primary"
                    (click)="onSubmitOverdraftApplication($event)">
                    Submit Application
                  </button>
                </div>
              </div>
            }
            @if (overdraftPreliminaryBorrowingEstimationResult.type == 'failed') {
              <!-- rejection: Overdraft -->
              <div class="row mt-2">
                <div class="col-12">
                  <div fxLayout="row" class="mb-1">
                    <span class="alert p-2 mdi mdi-alert-rhombus"></span>
                    <span fxLayout="column">
                  <span class="mb-2">
                    We are unable to provide an estimate.
                    Please Contact us prior to application
                    submission
                  </span>
                  <button
                    fxFlex="0 1 auto"
                    (click)="onContactUsClick($event)"
                    fxFlexAlign="baseline"
                    mat-stroked-button
                    color="accent">
                      Contact Us
                      <span matSuffix class="mdi mdi-arrow-top-right"></span>
                  </button>
                </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 mat-caption">
          Total limit will include non-bank cashflow providers that will need to be
          refinanced
        </div>
      </div>
    </div>
  }
</ng-template>

<!--
  #
  # Calculation results - business
  #
-->
<ng-template #businessEstimationResultTemplate>
  @if (businessPreliminaryBorrowingEstimationResult) {
    <div class="section-format mt-1 p-4">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="section-notice-format p-4">
            <div class="row">
              <div class="col">
                <h3>Business Term Loan</h3>
              </div>
            </div>
            <!-- accepted: Business -->
            @if (businessPreliminaryBorrowingEstimationResult.type == 'success') {
              <div class="row mt-4">
                <div class="col-12">
                  <range-slider
                    title="Borrowing Estimation: "
                    [min]="BUSINESS_SLIDER_BORROWING_MIN"
                    [max]="BUSINESS_SLIDER_BORROWING_MAX"
                    [step]="1"
                    [disableControl]="true"
                    [showPermanentTick]="true"
                    [autoAdjustMax]="true"
                    [formControl]="formControlBusinessBorrowingRange"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    (click)="onSubmitBusinessApplication($event)"
                    mat-stroked-button
                    color="primary">
                    Submit Application
                  </button>
                </div>
              </div>
            }
            <!-- rejection: Business -->
            @if (businessPreliminaryBorrowingEstimationResult.type == 'failed') {
              <div class="row mt-2">
                <div class="col-12">
                  <div fxLayout="row" class="mb-1">
                    <span class="alert p-2 mdi mdi-alert-rhombus"></span>
                    <span fxLayout="column">
                  <span class="mb-2">
                    We are unable to provide an estimate.
                    Please Contact us prior to application
                    submission
                  </span>
                  <button
                    fxFlex="0 1 auto"
                    fxFlexAlign="baseline"
                    mat-stroked-button
                    (click)="onContactUsClick($event)"
                    color="accent">
                      Contact Us
                      <span matSuffix class="mdi mdi-arrow-top-right"></span>
                  </button>
                </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 mat-caption">
          Total limit will include non-bank cashflow providers that will need to be
          refinanced
        </div>
      </div>
    </div>
  }
</ng-template>

<!--
  #
  # Manual Monthly Turnover retrieval
  #
-->
<ng-template #manual>
  <!-- average monthly turnover -->
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <currency-input
        title="Average monthly turnover"
        [min]="1"
        [formControl]="formControlAverageMonthlyTurnover"
      />
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-lg-6 col-md-12 mat-caption">
      Average monthly turnover refers to the average amount of money a company
      receives form the sale of goods and services within a month. It reflects
      the company's revenue generated through its primary trading activities.
    </div>
  </div>


  <!-- overdue ATO debts -->
  <div class="row mt-2">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Overdue ATO debts"
        [formControl]="formControlHasOverdueAtoDebts"
      />
    </div>
    @if (formControlHasOverdueAtoDebts.value == true) {
      <div class="col-lg-3 col-md-12">
        <currency-input
          title="Overdue ATO debts amount"
          [min]="1"
          [formControl]="formControlOverdueAtoDebtsAmount"
        />
      </div>
    }
  </div>

  <!-- borrowing from non-bank cashflow lenders -->
  <div class="row mt-1">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Borrowing from non-bank cashflow lenders"
        [formControl]="formControlHasBorrowFromNonBankLenders"
      />
    </div>
    @if (formControlHasBorrowFromNonBankLenders.value == true) {
      <div class="col-lg-3 col-md-12">
        <number-input
          title="How many lenders"
          [min]="1"
          [formControl]="formControlHowManyNonBankLenders"
        />
      </div>
      <div class="col-lg-3 col-md-12">
        <currency-input
          title="How much in total"
          [min]="1"
          [formControl]="formControlNonBankLendersTotalAmount"
        />
      </div>
    }
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 mat-caption">
      We will not lend alongside other non-bank cashflow providers/facilities or
      consolidate multiple facilities (refinance of single facility accepted)
    </div>
  </div>


  <!-- missed / dishonoured payments in 3 months -->
  <div class="row mt-5">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Missed/Dishonoured payments last 3 months"
        [formControl]="formControlHasDishonouredPayments"
      />
    </div>
    @if (formControlHasDishonouredPayments.value == true) {
      <div class="col-lg-3 col-md-12">
        <number-input
          title="How many dishonour payments"
          [min]="1"
          [formControl]="formControlHowManyDishonouredPayments"
        />
      </div>
      <div class="col-lg-3 col-md-12">
        <currency-input
          title="How much in total"
          [min]="1"
          [formControl]="formControlDishonouredPaymentsTotalAmount"
        />
      </div>
    }
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 mat-caption">
      History of regularly dishonouring payments is not acceptable.
    </div>
  </div>

  <!-- Result: Overdraft -->
  <ng-container *ngTemplateOutlet="overdraftEstimationResultTemplate"></ng-container>

  <!-- Result: Business Loan -->
  <ng-container *ngTemplateOutlet="businessEstimationResultTemplate"></ng-container>

  <div class="row mt-4">
    <div class="col-12" fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-flat-button
        [disabled]="formGroup.invalid"
        color="primary"
        class="w-lt-md-100"
        (click)="onCalculateEstimation($event)">
        Calculate
      </button>
    </div>
  </div>
</ng-template>


<!--
  #
  # Bank Statement Monthly Turnover retrieval
  #
-->
<ng-template #bankstatement>
  <div class="row mb-5">
    <div class="col-lg-6 col-md-12">
      <message-box type="info" title="Beta version">
        Bankstatement estimation is in Beta stage.
      </message-box>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <yes-no
        title="Bankstatement Source"
        [yesText]="'Brokerflow'"
        [noText]="'Basiq'"
        [formControl]="formControlBankstatementSource"
      />
    </div>
  </div>
  <ng-container *ngTemplateOutlet="(formControlBankstatementSource.value) === true ? bankstatementBrokerflow : bankstatementBasiq"></ng-container>
</ng-template>


<!--
  #
  # Bank Statement - Basiq
  #
-->
<ng-template #bankstatementBasiq>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      Coming soon
    </div>
  </div>
</ng-template>

<!--
  #
  # Bank Statement - Brokerflow
  #
-->
<ng-template #bankstatementBrokerflow>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Brokerflow ID</mat-label>
        <input matInput placeholder="Brokerflow ID" [formControl]="formControlBrokerflowId">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <abn [formControl]="formControlBrokerflowAbn"></abn>
    </div>
  </div>
  <div class="row separator-bottom">
    <div class="col-12">
      <button
        mat-flat-button
        [disabled]="formGroupBrokerflowQuery.invalid"
        (click)="queryBrokerFlow()">Query</button>
    </div>
  </div>
  @if (loader.brokerflowQuery.inProgress$ | async) {
    <div class="row">
      <div class="col-12">
        <list-content-loader></list-content-loader>
      </div>
    </div>
  }
  @if (!(loader.brokerflowQuery.inProgress$ | async) && brokerflowShowForm) {
    @if (brokerflowFormStatus == 'failed-prepopulate') {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="warn">Failed to prepopluate</message-box>
        </div>
      </div>
    }
    @if (brokerflowFormStatus == 'failed-fetch-from-brokerflow') {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="warn">Failed to fetch from brokerflow API</message-box>
        </div>
      </div>
    }
    <ng-container *ngTemplateOutlet="manual"></ng-container>
  }
</ng-template>
