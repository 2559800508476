<div class="category-details-table">
  <table mat-table [dataSource]="displayedData" multiTemplateDataRows class="width-100 mb-2" matSort (matSortChange)="onSort($event)">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef
          [mat-sort-header]="column!=='action' ? column : ''" [disabled]="column==='action'"
          [arrowPosition]="needAlignRight(column) ? 'before' : 'after'"
          [ngClass]="{
          'column-width-1': column === 'date',
          'column-width-2': column === 'party',
          'column-width-3': column === 'text',
          'column-width-4': column === 'action',
          'text-align-right': needAlignRight(column)
          }"
          >
          @if (column !== 'action') {
            <div class="cell">
              {{getColumnTitles(column)}}
            </div>
          }
          @if (column === 'action') {
            <div class="cell" (click)="clickAll()">
              <span
                class="cursor-pointer mdi mdi-checkbox-multiple-outline"
              ></span>
            </div>
          }
        </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="{'text-align-right': needAlignRight(column)}">
          <div class="cell">
            @if (column === 'action') {
              <mat-checkbox
                color="primary"
                (change)="onToggleCheckbox(element.id)"
                [checked]="transactionCheckbox[element.id]"
              ></mat-checkbox>
            }
            @if (column !== 'action') {
              @if (needCurrencyPipe(column)) {
                @if (!element[column]) {
                }
                @if (element[column]) {
                  {{element[column] | looseCurrency}}
                }
              }
              @if (!needCurrencyPipe(column)) {
                {{element[column]}}
              }
            }
          </div>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
    </tr>
  </table>
  @if (total) {
    <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
    [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
  }
</div>
