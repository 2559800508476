<form [formGroup]="formGroup" class="companies-search-component">
  <mat-form-field>
    <input type="text"
      placeholder="Search company"
      matInput
      [formControl]="formControlCompanySearch"
      [matAutocomplete]="auto"
      [matTooltip]="getTooltips()"/>
      @if (allowCancellation && !selected) {
        <span class="mdi mdi-magnify" matSuffix></span>
      }
      @if (allowCancellation && selected) {
        <span class="mdi mdi-close" (click)="clearValue()" matSuffix></span>
      }
      @if (!allowCancellation) {
        <span class="mdi mdi-magnify" matSuffix></span>
      }
      <mat-autocomplete #auto="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayWithFn"
        (optionSelected)="onCompanySelected($event)">
        @for (company of filteredCompanies; track company) {
          <mat-option [value]="company">
            <div class="option-title">{{company.entityName}}</div>
            <div class="option-details mat-caption">
              @if (company.abn) {
                <span>ABN:{{company.abn}}</span>
              }
            </div>
          </mat-option>
        }
        @if (!filteredCompanies || !filteredCompanies.length) {
          <mat-option (click)="navigateToAccreditation()">
            Couldn't find your company. Click here <mat-icon class="mdi mdi-arrow-top-right" matSuffix></mat-icon>
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

  </form>
