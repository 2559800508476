<div class="bpay-table-component">
  <div fxLayout="row" fxLayoutAlign="space-between flex-start" class="header">
    <div class="search-input">
      <mat-form-field fxFlex="0 1 425px" class="search">
        <input matInput class="ml-4" [readonly]="loader.bpayTable.inProgress$ | async" type="text" [formControl]="formControlSearchBPay" placeholder="Search payment...">
        <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start flex-start" fxLayoutGap="10px">
      <button class="custom-button" (click)="editBPay()">
        <span class="mdi mdi-plus icon"></span>
        <span class="text">Add BPay</span>
      </button>
      <button class="custom-button" (click)="makePayment()">
        <span class="mdi mdi-cash icon"></span>
        <span class="text">Make Payment</span>
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="bpayDataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
    @for (column of displayColumnsBPay; track column) {
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="cell">{{getColumnTitles(column)}}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="cell">
            @if (column === 'amount') {
              {{element[column] | looseCurrency}}
            }
            @if (column === 'date') {
              {{element[column]}}
            }
            @if (column === 'action') {
              <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
              <mat-menu #menu="matMenu">
                <button class="menu-items" mat-menu-item (click)="editBPay(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>Edit</div>
                    <div><span class="mdi mdi-pencil-outline"></span></div>
                  </div>
                </button>
                <mat-divider></mat-divider>
                <button class="menu-items" mat-menu-item (click)="delete(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>Delete</div>
                    <div><span class="mdi mdi-trash-can-outline"></span></div>
                  </div>
                </button>
              </mat-menu>
            }
            @if (column !== 'amount' && column !== 'date' && column !== 'action') {
              {{element[column]}}
            }
          </div>
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayColumnsBPay"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumnsBPay"></tr>
  </table>
</div>
