<div class="application-submitted-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
      <span class="mdi mdi-check-circle color-primary">
      </span>
        <div class="mat-h3 width-100">
          Your application has been submitted successfully
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="width-100">
        <button color="primary" (click)="onSubmit($event)" mat-stroked-button>Got it</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
