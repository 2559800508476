import {Component, Input, OnInit} from '@angular/core';
import { Application, BankStatement, BankStatementsAnalysisData, CreateApplicationNoteFn, GetBankStatementsAnalysisFn, GetBsaCalculatorFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, GetDscrCalculatorHistoryFn, GetDscrCalculatorValueFn, PayloadApiResponse, RefreshBankStatementFn, RemoveApplicationNoteByNoteIdFn, SaveBsaCalculatorFn, UpdateDscrCalculatorValueFn, User, isAdmin, isAnalyst, isInternalUser, BankStatementData, GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, BasiqUserCustomerMapping, getAcn, ApiResponse } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import {ApplicationDialogService, GetNotesByApplicationIdFn, getAccessToken, getUser} from '@portal-workspace/grow-ui-library';
import {PortalHotToastService} from '../portal-hot-toast-component/hot-toast.service';
import { getAbn } from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { CustomerAnalysisComponent } from '../bank-statement-component/customer-analysis.component';
import { AccountSummaryComponent } from '../bank-statement-component/account-summary.component';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { BankStatementDecisioningComponent } from '../bank-statement-component/bank-statement-decisioning.component';

import { MatCardModule } from '@angular/material/card';
import { Response } from 'request';
import { DscrCalculatorComponent } from '../application-details-component/dscr-calculator.component';
import {FlexModule} from "@angular/flex-layout";
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'bank-statement',
    templateUrl: './bank-statement.component.html',
    styleUrls: ['./bank-statement.component.scss'],
    standalone: true,
    imports: [
    MatCardModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatButtonModule,
    AccountSummaryComponent,
    CustomerAnalysisComponent,
    DscrCalculatorComponent,
    FlexModule,
    BankStatementDecisioningComponent,
    CustomContentLoaderComponent,
]
})
export class BankStatementComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({ required: false }) type: 'Illion' | 'Basiq' = 'Illion'
  @Input() apiUrl!: string;
  @Input() bankStatementsUrl!: string;
  @Input() application!: Application;
  @Input() getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input() refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;


  @Input() getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input() getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input() refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input() reference!: string | null;

  selectedAccounts: string[] = [];

  isLoading = true;
  salesforceId?: string | null;
  formControlBankStatementLink: FormControl<string | null>;
  getAbn = getAbn
  hasBankStatement: boolean = false;
  isAdmin = isAdmin;
  isAnalyst = isAnalyst;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  showCalculator: boolean = false;
  bankStatementMessage!: string;
  customerMapping: BasiqUserCustomerMapping | null = null;

  isAuthCompleted = false;
  portalLink = '';
  statementData: BankStatementData | null = null;

  toggleCalculator() {
    this.showCalculator = !this.showCalculator;
  }

  // TODO: refactoring move all services out, replace with functions passed in as @Input()
  constructor(private formBuilder: FormBuilder,
              private applicationDialogService: ApplicationDialogService,
              private portalHotToastService: PortalHotToastService,) {
    this.formControlBankStatementLink = formBuilder.control(null);
  }

  ngOnInit(): void {
    this.loadData();
    if (this.application.ApplicationType === 'Consumer') {
      this.formControlBankStatementLink.setValue(`${this.bankStatementsUrl}/bank-statements/basiq?reference=${this.reference}`)
    } else {
      this.formControlBankStatementLink.setValue(`${this.bankStatementsUrl}/bank-statements?reference=${this.reference}`)
    }
  }

  onSelectAccounts(selectedAccounts: string[]) {
    // const ref = this.applicationDialogService.openProgressSpinnerDialog();
    // setTimeout(() => {
    //   ref.close();
    // }, 3000);
    this.selectedAccounts = selectedAccounts;
  }

  loadData() {
    this.isLoading = true;
    let reference;
    if (this.application.ApplicationType !== 'Consumer') {
      reference = getAbn(this.application) ?? getAcn(this.application)
    } else {
      const applicant = (this.application.Individuals ?? []).find(i => i.Role === 'Applicant');
      //TODO: handle the condition when consumer application do not have Identification
      console.log('applicant', applicant)
      reference = applicant?.Identification?.idNumber ?? '';
    }
    if (this.type == 'Illion') {
      this.subscriptions.push(this.getBankStatementsAnalysisFn({ reference: getAbn(this.application) })
        .pipe(
          this.portalHotToastService.spinnerObservable(),
          tap((r: PayloadApiResponse<BankStatementData | null>) => {
            console.log('result', r)
            if (r.status && r.payload) {
              this.statementData = r.payload
              if (!Object.keys(this.statementData).length) {
                this.hasBankStatement = false;
              } else {
                this.hasBankStatement = true;
                if (this.statementData?.accounts.length) {
                  this.selectedAccounts = this.statementData.accounts.map(a => a.id);
                  this.onSelectAccounts(this.selectedAccounts);
                }
              }
            }
            else {
              this.hasBankStatement = false;
              this.bankStatementMessage = r.message;
            }
          })
        )
        .subscribe(r => {this.isLoading = false}))
    } else if (this.type == 'Basiq') {
      console.log('getBasiqCustomerMappingByAbnFn reference', reference)
      this.getBasiqCustomerMappingByAbnFn(reference).pipe(
        this.portalHotToastService.spinnerObservable(),
        switchMap(customerMapping => {
          console.log('Auth Completed', customerMapping)
          this.customerMapping = customerMapping.payload;
          const authCompleted = customerMapping.payload?.AuthLinkStatus === 'COMPLETED' ?? false;
          this.isAuthCompleted = authCompleted;
          if (authCompleted && customerMapping.payload) {
            return this.getBasiqStatementDataForCompanyFn(customerMapping.payload)
          } else {
            return of({
              status: true,
              payload: `${this.bankStatementsUrl}/bank-statements?reference=${this.reference}`,
              message: 'Success'
            })
          }
        })
      ).subscribe(
        result => {
          console.log('auth completed at subscribe', this.isAuthCompleted)
          if (this.isAuthCompleted) {
            if (!result.payload) {
              this.bankStatementMessage = result.message;
              this.isAuthCompleted = false;
              this.hasBankStatement = false;
              this.isLoading = false;
              return;
            }
            this.statementData = result.payload as BankStatementData;
            if (this.statementData?.accounts.length) {
              this.selectedAccounts = this.statementData.accounts.map(a => a.id);
              this.onSelectAccounts(this.selectedAccounts);
            }
            console.log('Statement Data Result', result);
            this.hasBankStatement = true;
          } else {
            console.log('Portal URL for Auth', result);
            if (this.application.ApplicationType === 'Consumer') {
              this.formControlBankStatementLink.setValue(`${this.bankStatementsUrl}/bank-statements/basiq?reference=${this.reference}`)
            } else {
              this.formControlBankStatementLink.setValue(`${this.bankStatementsUrl}/bank-statements?reference=${this.reference}`)
            }
          }
          this.isLoading = false;
        },
        error => {
          console.error('Error loading data:', error);
          this.isLoading = false;
        }
      );
    }
  }

  refreshBankStatement() {
    console.log('refreshBankStatement', this.type)
    if (this.type == 'Illion') {
    this.subscriptions.push(
      this.refreshBankStatementFn(getAbn(this.application)).pipe(
        this.portalHotToastService.spinnerObservable()
      ).subscribe(
        (result: PayloadApiResponse<Response | {Error: string}>) => {
          console.log('refresh result: ', result);
          if (result.status) {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: 'The bank statement has been refreshed'
            }).afterClosed().subscribe(() => {
              this.getBankStatementsAnalysisFn({ reference: getAbn(this.application) })
              .pipe(this.portalHotToastService.spinnerObservable())
              .subscribe(
                (r: PayloadApiResponse<BankStatementData | null>) => {
                  console.log('result', r)
                  if (r.status && r.payload) {
                    this.statementData = r.payload
                    if (!Object.keys(this.statementData).length) {
                      this.hasBankStatement = false;
                    } else {
                      this.hasBankStatement = true;
                    }
                  }
                  else {
                    this.hasBankStatement = false;
                    this.bankStatementMessage = r.message;
                  }
                })
            })
          } else {
            this.applicationDialogService.openAlertDialog({
              message: 'Error',
              subMessage: 'Unable to refresh this bank statement'
            })
          }
        }
      )
    )
  }
    else if (this.type === 'Basiq') {
      console.log('customerMapping', this.customerMapping)
      if (this.customerMapping?.BasiqUserId) {
        this.subscriptions.push(
          this.refreshBasiqConnectionsFn(this.customerMapping?.BasiqUserId!, this.application.ApplicationType === 'Consumer' ? 'consumer' : 'business').pipe(
            this.portalHotToastService.spinnerObservable()
          ).subscribe(
            (result: ApiResponse) => {
              console.log('refresh result: ', result);
              if (result.status) {
                this.applicationDialogService.successDialog({
                  message: 'Success',
                  subMessage: 'The bank statement refresh process has been started, please check back after some time.'
                }).afterClosed().subscribe(() => {
                  this.loadData();
                })
              } else {
                this.applicationDialogService.openAlertDialog({
                  message: 'Error',
                  subMessage: 'Unable to refresh this bank statement'
                })
              }
            }
          )
        )
      }
    }
  }

  convertToInternationalFormat(mobileNumber: string): string {
    // Check if the mobile number starts with '0'
    if (mobileNumber.startsWith('0')) {
      // Replace the leading '0' with '+61'
      return '+61' + mobileNumber.substring(1);
    }
    // If the mobile number does not start with '0', return it as is
    return mobileNumber;
  }
}
