<div class="transferred-balance-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <div class="mat-h2">Transferred Balance</div>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <mat-form-field>
          <mat-label>What type of balance?</mat-label>
          <mat-select placeholder="Balance type" [formControl]="formControlBalanceType">
            <mat-option [value]="'debit'">Debit</mat-option>
            <mat-option [value]="'credit'">Credit</mat-option>
          </mat-select>
          @for (errorKey of errorKeys(formControlBalanceType); track errorKey) {
            <mat-error>
              @if (formControlBalanceType.touched && formControlBalanceType.hasError(errorKey)) {
                {{errorMessage(formControlBalanceType, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input [formControl]="formControlBalance" type="number" matInput currencyMask placeholder="0.00"/>
          @for (errorKey of errorKeys(formControlBalance); track errorKey) {
            <mat-error>
              @if (formControlBalance.touched && formControlBalance.hasError(errorKey)) {
                {{errorMessage(formControlBalance, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
