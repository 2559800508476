import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import { AsyncPipe, JsonPipe } from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatOptionModule} from "@angular/material/core";
import {DisableControlDirective} from "../../directives/disable-control.directive";
import {MARK, Mark} from "@portal-workspace/grow-ui-library/mark";
import {
  OverdraftCustomerPhysicalCardSelectionValueOptions,
  OverdraftCustomerPhysicalCardSelectValue, compareMatch
} from "@portal-workspace/grow-shared-library";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {UntilDestroy} from "@ngneat/until-destroy";
import {setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {Subscription} from "rxjs";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'overdraft-customer-physical-card-select',
  templateUrl: './overdraft-customer-physical-card-select.component.html',
  styleUrls: ['./overdraft-customer-physical-card-select.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>OverdraftCustomerPhysicalCardSelectComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>OverdraftCustomerPhysicalCardSelectComponent) },
  ],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatOptionModule,
    DisableControlDirective,
    JsonPipe,
    AsyncPipe
]
})
export class OverdraftCustomerPhysicalCardSelectComponent extends AbstractControlValueAccessor<OverdraftCustomerPhysicalCardSelectValue> implements OnInit, Mark {

  @Input({required: true}) title?: string;

  subscriptions: Subscription[] = [];

  options =  OverdraftCustomerPhysicalCardSelectionValueOptions

  formBuilder = inject(FormBuilder);
  formControl: FormControl =  this.formBuilder.control(null, [Validators.required]);

  mark(): void {
    this.formControl.markAsTouched();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formControl.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  override doWriteValue(v?: OverdraftCustomerPhysicalCardSelectValue): void | OverdraftCustomerPhysicalCardSelectValue {
    this.formControl.setValue(v);
  }

}
