import { Component, Inject, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  AddCustomerUserFormDialogDialogData,
  AddCustomerUserFormDialogResult,
  ConfirmPasswordAdminValue, CustomerAccessLevelValue, CustomerListValue, CustomerListValueGetFn,
  EmailComponentValue, GetUserByEmailFn,
  MobileValue,
  NameComponentValue,
  PismoAccountMappingWithCustomer,
  PismoAccountMappingWithCustomerListFn,
  PismoAccountMappingWithCustomerListValue,
  User
} from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ConfirmPasswordAdminComponent } from '../confirm-password-admin-component/confirm-password-admin.component';
import { EmailComponent } from '../common fields/email.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { NameComponent } from '../name-component/name.component';
import {CustomerListComponent} from '../aggregator-search-component/customer-list.component';
import {CustomerAccessLevelComponent} from '../access-level-component/customer-access-level.component';
import { NgFor, NgIf } from '@angular/common';
import { formControlErrorKeys, formControlErrorMessage} from '@portal-workspace/grow-ui-library';
import { MatSelectModule } from '@angular/material/select';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './add-customer-user-form.dialog.html',
  styleUrls: ['./add-customer-user-form.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NameComponent,
    MobileComponent,
    EmailComponent,
    ConfirmPasswordAdminComponent,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    CustomerListComponent,
    CustomerAccessLevelComponent,
    MatSelectModule
  ],
})
export class AddCustomerUserFormDialog implements OnInit {

  existingUser = false; // if this user already exists (identified by email)

  subscriptions: Subscription[] = [];
  getFn: CustomerListValueGetFn;
  getUserByEmailFn: GetUserByEmailFn;
  getAllPismoWithCustomerAccountFn: PismoAccountMappingWithCustomerListFn;
  pismoAccountCustomers: PismoAccountMappingWithCustomer[] = [];
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  user !: User
  formGroup1: FormGroup<{
    pismoAccountCustomer:FormControl<PismoAccountMappingWithCustomerListValue>;
  }>;

  formControlPismoAccountCustomer: FormControl<PismoAccountMappingWithCustomerListValue>;


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AddCustomerUserFormDialogDialogData,
    private matRef: MatDialogRef<AddCustomerUserFormDialog, AddCustomerUserFormDialogResult>,
    private toastService: PortalHotToastService,
  ) {


    this.formControlPismoAccountCustomer = formBuilder.control(null,[Validators.required]);
    this.formGroup1 = this.formBuilder.group({
       pismoAccountCustomer:this.formControlPismoAccountCustomer
    });



    this.getFn = this.data.getFn;
    this.getUserByEmailFn = this.data.getUserByEmailFn;
    this.getAllPismoWithCustomerAccountFn = this.data.getAllPismoWithCustomerAccountFn
    const sub = this.getAllPismoWithCustomerAccountFn().pipe(
      tap((r: PismoAccountMappingWithCustomerListValue[]) => {
          if(r && r.length > 0){
            this.pismoAccountCustomers = r.filter((value): value is PismoAccountMappingWithCustomer => value !== null);
          }

      }),
    ).subscribe();
    this.subscriptions.push(sub);
  }

  ngOnInit() {
  }



  cancel($event: Event) {
    this.matRef.close({
      valid: false,
      pismoCustomer:null
    });
  }

  submit($event: Event) {
    this.doSubmit($event);
  }

  doSubmit($event: Event) {
    // NOTE: 4552 no longer customer field
      this.matRef.close({
        valid: true,
        pismoCustomer:this.formControlPismoAccountCustomer.value
      });
  }
}
