import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApplicationDialogService, PortalHotToastService, getUser} from '@portal-workspace/grow-ui-library';
import { Sort, MatSortModule } from '@angular/material/sort';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe } from '@angular/common';
import { BankingService } from '../../service/banking.service';
import { LooseCurrencyPipe } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { PendingApprovalTransactionsTableComponent } from '@portal-workspace/grow-ui-library';
import { ProcessedPendingTransactionsTableComponent } from '@portal-workspace/grow-ui-library';
import { GetPendingApprovalTransactionsFn, GetProcessedPendingTransactionsFn, UpdateAllPendingTransactionsFn, UpdatePendingTransactionsFn } from '@portal-workspace/grow-shared-library';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    templateUrl: './pending-approval-transactions.page.html',
    styleUrls: ['./pending-approval-transactions.page.scss'],
    standalone: true,
    imports: [MatFormFieldModule, NgTemplateOutlet, FlexModule, MatCardModule, LooseCurrencyPipe, MatTabsModule, ProcessedPendingTransactionsTableComponent, PendingApprovalTransactionsTableComponent, MatInputModule, MatTooltipModule, MatMenuModule, MatButtonModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe]
})
export class PendingApprovalTransactionsPage implements OnInit {
  getPendingApprovalTransactionsFn!: GetPendingApprovalTransactionsFn;
  getProcessedPendingTransactionsFn!: GetProcessedPendingTransactionsFn;
  updatePendingTransactionsFn!: UpdatePendingTransactionsFn;
  updateAllPendingTransactionsFn!: UpdateAllPendingTransactionsFn;

  constructor(
    private bankingService: BankingService,
    private toastService: PortalHotToastService,
  ) {
    this.getPendingApprovalTransactionsFn = this.bankingService.getPendingApprovalTransactionsFn;
    this.getProcessedPendingTransactionsFn = this.bankingService.getProcessedPendingTransactionsFn;
    this.updatePendingTransactionsFn = this.bankingService.updatePendingTransactionsFn;
    this.updateAllPendingTransactionsFn = this.bankingService.updateAllPendingTransactionsFn;
  }

  ngOnInit(): void {
  }
 
}
