<div class="commercial-finance-page container-fluid">
  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnwardsOrganisationType === 'trust') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_1" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'sole-trader') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_2" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'partnership') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_3" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'company') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_4" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'other') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_5" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9" ></ng-content></cdk-step>
  </application-stepper2>
</div>

<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <business-search

            [formControl]="formControlStep1Business"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
            [searchFn]="step1BusinessSearchFn"
            >
          </business-search>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-6">
          <aggregator-search
            [searchFn]="step2BrokerSearchFn"
            [disabled]="isExternalUser"
            title="Broker / Vendor"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2Broker">
          </aggregator-search>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mat-h3">How much would you like to borrow?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <currency-input
            title="Invoice amount (inc GST)"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2InvoiceAmount">
          </currency-input>
        </div>
        <div class="col-3">
          <currency-input
            title="Deposit amount"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [min]="step2DepositMin"
            [max]="step2DepositMax"
            [formControl]="formControlStep2DepositAmount">
          </currency-input>
        </div>
        <div class="col-3">
          <currency-input
            [readonly]="true"
            title="Loan amount"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2LoanAmount">
          </currency-input>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col">
          <div class="mat-h3">Asset details</div>
          <div class="mat-body">Please provide details on the asset you would like to finance</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <asset-type

            [categoryTypes]="step2AssetTypes"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2AssetType">
          </asset-type>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Asset description</mat-label>
            <textarea matInput [formControl]="formControlStep2AssetDescription"></textarea>
            <mat-hint>Please include the size of the system, number and type of panels, number and type of inverters and the panel mounting type.</mat-hint>
          </mat-form-field>
        </div>
      </div>


      <div class="row separator">
        <div class="col">
          <div class="mat-h3">Finance details</div>
          <div class="mat-body">Let us know your preferred repayment terms</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <commercial-loan-terms-selection

            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2LoanTerms">
          </commercial-loan-terms-selection>
        </div>
        <div class="col-3">
          <payment-frequency-selection

            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [formControl]="formControlStep2PaymentFrequency">
          </payment-frequency-selection>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col">
          <div class="mat-h3">Company details</div>
          <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <yes-no
            title="Property owner"

            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [formControl]="formControlStep2PropertyOwner"></yes-no>
        </div>
      </div>

    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col">
          <div class="mat-h3">Company details</div>
          <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <entity-type
            title="Organisation type"

            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OrganisationType">
          </entity-type>
        </div>
        <div class="col-3">
          <abn [formControl]="formControlStep3Abn" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </abn>
        </div>
        @if (step3OnwardsOrganisationType === 'company') {
          <div class="col-3">
            <acn [formControl]="formControlStep3Acn"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
      </div>

      <div class="row">
        <div class="col-3">
          <annual-revenue
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            title="Annual revenue"
            [formControl]="formControlStep3AnnualRevenue"
            >
          </annual-revenue>
        </div>
        <div class="col-3">
          <yes-no

            title="Operates in a commercial premise?"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OperatesInCommercialPremises">
          </yes-no>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <primary-industry-selection

            title="Primary industry"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryIndustry">
          </primary-industry-selection>
        </div>
        <div class="col-6">
          <secondary-industry-selection

            title="Industry sector"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3IndustrySector">
          </secondary-industry-selection>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <custom-address
            title="Primary business address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryBusinessAddress">
          </custom-address>
        </div>
        <div class="col-3">
          <mobile [formControl]="formControlStep3BusinessLandline"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Business Landline"></mobile>
        </div>
      </div>

    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4_1> <!--trust -->
  <div class="step4">
    <form [formGroup]="formGroupStep4" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <trustee
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicants"
            [showAddressForm]="showAddressFormFlag"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </trustee>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_2> <!-- sole trader -->
  <div class="step4">
    <form [formGroup]="formGroupStep4" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <sole-trader
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicants"
            [showAddressForm]="showAddressFormFlag">
          </sole-trader>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_3>  <!-- partnership -->
  <div class="step4">
    <form [formGroup]="formGroupStep4" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <partner
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicants"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn"
            [showAddressForm]="showAddressFormFlag">
          </partner>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_4> <!-- company -->
  <div class="step4">
    <form [formGroup]="formGroupStep4" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <director
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicants"
            [showAddressForm]="showAddressFormFlag">
          </director>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_5> <!-- other (member) -->
  <div class="step4">
    <form [formGroup]="formGroupStep4" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <member
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicants"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn"
            [showAddressForm]="showAddressFormFlag">
          </member>
        </div>
      </div>
    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <form [formGroup]="formGroupStep5" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <guarantor
            [showAssetsAndLiabilities]="false"
            [mandatoryGuarantors]="step5MandatoryGuarantors"
            [businessNumberSearchFn]="step5BusinessNumberSearchFn"
            [formControl]="formControlStep5Guarantors"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep5)"
          [showAddressForm]="showAddressFormFlag"></guarantor>
        </div>
      </div>
    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <form [formGroup]="formGroupStep6" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <select-contact
            [predefinedContactSelection]="step6PossiblePrimaryContacts"
            [formControl]="formControlStep6PrimaryContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep6)">
          </select-contact>
        </div>
      </div>
    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupStep7" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <reference [formControl]="formControlStep7References"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep7)">
          </reference>
        </div>
      </div>
    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <form [formGroup]="formGroupStep8" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mat-h3">Upload Driver Licence</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-6">
          <message-box type="info" title="Before uploading">
            <ul>
              <li>Ensure both the front and back of the licence are supplied</li>
              <li>Ensure the text is clear and readable</li>
              <li>If using PDF, ensure the front of the driver licence is on the first page</li>
              <li>We don’t accept pictures of digital driver licences</li>
            </ul>
          </message-box>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-6">
          <uploaded-files-list [uploadedFiles]="step8UploadedDocs" (events)="onStep8DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>


      @for (dr of step8DriverLicenses; track dr) {
        <div class="row separator-bottom">
          <div class="col-6">
            <upload-file [maxFilesAllowed]="2" [mandatory]="true" title="{{dr.name}}'s Driver Licence" [formControl]="dr.formControl" [allowMultiple]="true"></upload-file>
          </div>
        </div>
      }

      <div class="row">
        <div class="col">
          <div class="mat-h3">Other supporting documents</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-6">
          <upload-files-multi-tags
            title="Other Supporting Documents"
            [allTags]="documentTypes"
            (docEvent)="onStep8DocEvent($event)"
          ></upload-files-multi-tags>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Application notes </mat-label>
            <textarea matInput maxlength="7000" [formControl]="formControlStep8ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <form [formGroup]="formGroupStep9" >
      <div class="row separator-bottom">
        <div class="col">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <commercial-finance-summary [summary]="step9Summary"></commercial-finance-summary>
        </div>
      </div>
    </form>
  </div>
</ng-template>


