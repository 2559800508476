import {Component, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DateRangeComponent} from './date-range.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DateRangeComponent
    ],
    exports: [
        DateRangeComponent
    ]
})
export class DaterangeModule {

}
