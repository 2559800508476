export type SecurityTypeSelectionType = 'first-mortgage' | 'second-mortgage' | 'caveat';

export type SecurityTypeSelectionValue = {
  type: SecurityTypeSelectionType,
  name: string,
} | null;

export const SecurityTypeSelectionValueOptions: Exclude<SecurityTypeSelectionValue, null>[] = [
  { type: 'first-mortgage', name: 'First Mortgage'},
  { type: 'second-mortgage', name: 'Second Mortgage'},
  { type: 'caveat', name: 'Caveat'},
];
