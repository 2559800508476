import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {PortalHotToastService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Application, CreditApprovedDialogData, CreditApprovedDialogResult, CreditDecisioningReasonDialogData, CreditDecisioningReasonDialogResult, CreditReference, DealStatus, GetApplicationDscrFn, ValuationSource, creditDeclineApprovalReasonOptions, creditDeclineReasonOptions, creditReferenceOptions, dealStatusOptions, valuationSourceOptions} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOptionModule } from '@angular/material/core';
import moment from 'moment';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';

@UntilDestroy()
@Component({
    templateUrl: './credit-approved.dialog.html',
    styleUrls: ['./credit-approved.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, CurrencyInputComponent, MatInputModule, ReactiveFormsModule, MatOptionModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class CreditApprovedDialog implements OnInit {

  formControlExceptionToPolicy!: FormControl<'Yes' | 'No' | null>;
  formControlCreditReference!: FormControl<CreditReference | null>;
  formControlDealStatus!: FormControl<DealStatus | null>;
  formControlFinancials!: FormControl<'Yes' | 'No' | null>;
  formControlValuationSource!: FormControl<ValuationSource | null>;
  formControlApprovalConditions!: FormControl<string | null>;
  formControlLvr!: FormControl<number | null>;
  formControlDscr!: FormControl<number | null>;
  creditReferenceOptions = creditReferenceOptions;
  dealStatusOptions = dealStatusOptions;
  valuationSourceOptions = valuationSourceOptions;
  yesNoOptions = ['No', 'Yes'];
  application!: Application;
  getApplicationDscrFn!: GetApplicationDscrFn;
  formGroup!: FormGroup<{
    exceptionToPolicy: FormControl<'Yes' | 'No' | null>;
    creditReference: FormControl<CreditReference | null>;
    dealStatus: FormControl<DealStatus | null>;
    financials: FormControl<'Yes' | 'No' | null>;
    valuationSource: FormControl<ValuationSource | null>;
    lvr: FormControl<number | null>;
    dscr: FormControl<number | null>;
    approvalConditions: FormControl<string | null>;
  }>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: CreditApprovedDialogData,
    private dialogRef: MatDialogRef<CreditApprovedDialog, CreditApprovedDialogResult>,
    private toastService: PortalHotToastService,
  ) {
    this.formControlExceptionToPolicy = this.formBuilder.control(null, [Validators.required]);
    this.formControlCreditReference = this.formBuilder.control(null, [Validators.required]);
    this.formControlDealStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlFinancials = this.formBuilder.control(null, [Validators.required]);
    this.formControlValuationSource = this.formBuilder.control(null, [Validators.required]);
    this.formControlLvr = this.formBuilder.control(0, [Validators.required]);
    this.formControlDscr = this.formBuilder.control(0, [Validators.required])
    this.formControlApprovalConditions = this.formBuilder.control(null);

    this.formGroup = formBuilder.group({
      exceptionToPolicy: this.formControlExceptionToPolicy,
      creditReference: this.formControlCreditReference,
      dealStatus: this.formControlDealStatus,
      financials: this.formControlFinancials,
      valuationSource: this.formControlValuationSource,
      lvr: this.formControlLvr,
      dscr: this.formControlDscr,
      approvalConditions: this.formControlApprovalConditions,
    })

    this.application = data.application;
    this.getApplicationDscrFn = data.getApplicationDscrFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    if (this.application && this.application?.AssetSpec?.LVR) {
      const lvr = isNaN(Number(this.application?.AssetSpec?.LVR ?? 0)) ? 0 : Number(this.application?.AssetSpec?.LVR);
      this.formControlLvr.setValue(lvr);
    }

    if (this.application && (this.application?.AppInfo as any)?.productType == 'LOW DOC') {
      this.formControlDscr.setValue(9999);
    } else {
      // populate DSCR
      this.getApplicationDscrFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(dscr => {
        console.log('====dscr: ', dscr)
        if (dscr) {
          this.formControlDscr.setValue(dscr)
        }
      })
    }
  }

  onSubmit(readyForSubmission: boolean) {
    if (readyForSubmission) {
      this.dialogRef.close({
        creditApprovalDate: moment().format('YYYY-MM-DD'),
        exceptionToPolicy: this.formControlExceptionToPolicy.value as 'Yes' | 'No',
        creditReference: this.formControlCreditReference.value as CreditReference,
        dealStatus: this.formControlDealStatus.value as DealStatus,
        financials: this.formControlFinancials.value as 'Yes' | 'No',
        valuationSource: this.formControlValuationSource.value as ValuationSource,
        approvalConditions: this.formControlApprovalConditions.value ?? '',
        lvr: this.formControlLvr.value ?? 0,
        dscr: this.formControlDscr.value ?? 0,
      })
    } else {
      this.dialogRef.close()
    }
  }
}
