import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  compareMatch,
  CurrencyInputValue,
  TransactionTypeRateValue,
  TransactionValue,
  TransactionValueOptions
} from '@portal-workspace/grow-shared-library';
import {Component, forwardRef, inject, OnInit,Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import {MatChipsModule} from '@angular/material/chips';

import { Subscription } from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MatInputModule} from '@angular/material/input';
import {createNoDecimalInputMask, createTwoDecimalInputMask, formControlErrorKeys, formControlErrorMessage} from '../component-utils';
import {InputMaskModule} from '@ngneat/input-mask';
import numeral from 'numeral';
import {CurrencyInputComponent} from '../currency-selection-component/currency-input.component';


@UntilDestroy()
@Component({
  selector: 'transaction-type-rate',
  templateUrl: './transaction-type-rate.component.html',
  styleUrls: ['./transaction-type-rate.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TransactionTypeRateComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(() => TransactionTypeRateComponent) },
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatInputModule,
    InputMaskModule,
    CurrencyInputComponent
]
})
export class TransactionTypeRateComponent extends AbstractControlValueAccessor<TransactionTypeRateValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: false}) mandatory = true;

  createNoDecimalInputMask = createNoDecimalInputMask();
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  subscriptions: Subscription[] = [];

  transactionTypeOptions = [...TransactionValueOptions];

  private formBuilder: FormBuilder;

  formGroup: FormGroup<{
    chips: FormControl<Exclude<TransactionValue, null>[] | null>,
    rate: FormControl<number | null>,
    additionalDocFee: FormControl<CurrencyInputValue>,
  }>;
  formControlChips: FormControl<Exclude<TransactionValue, null>[] | null>;
  formControlRate: FormControl<number | null>;
  formControlAdditionalDocFee: FormControl<number | null>;

  constructor() {
    super();
    this.formBuilder = inject(FormBuilder);
    this.formControlRate = this.formBuilder.control(null, [Validators.required]);
    this.formControlChips = this.formBuilder.control(null, [Validators.required]);
    this.formControlAdditionalDocFee = this.formBuilder.control(0, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      chips: this.formControlChips,
      rate: this.formControlRate,
      additionalDocFee: this.formControlAdditionalDocFee,
    });
  }


  ngOnInit(): void {
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(form => {
        if (this.formGroup.valid) {
          this.propagateChange({
            transactionTypes: (this.formControlChips.value ?? []).map(v => v.type),
            rate: numeral(this.formControlRate.value ?? 0).value() ?? 0,
            additionalDocFee: this.formControlAdditionalDocFee.value ?? 0,
          })
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  doWriteValue(v: TransactionTypeRateValue | null | undefined): void | TransactionTypeRateValue {
    if (v) {
      this.formControlRate.setValue(v.rate);
      const transactionOptions = this.transactionTypeOptions.filter(o => (v.transactionTypes ?? []).includes(o.type));
      this.formControlChips.setValue(transactionOptions);
      this.formControlAdditionalDocFee.setValue(v.additionalDocFee);
    }
    return undefined;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  protected readonly formControlErrorKeys = formControlErrorKeys;
  protected readonly formControlErrorMessage = formControlErrorMessage;
}
