<div class="overdraft-account-limit-increase-dialog">
  <div>
    <mat-dialog-content class= 'max-height-100-vh'>
      <application-stepper2 [linear]="false"
        [showErrorDetailsInPopup]="true"
        [showHeader]="false"
        #applicationStepper="applicationStepper2">
        <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
      </application-stepper2>
    </mat-dialog-content>
  </div>
</div>


<!--
##########################################
#  STEP 1: Select Opportunity
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Select Opportunity</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Opportunity</mat-label>
            <mat-select placeholder="Select Opportunity..." [formControl]="formControlStep1Opportunity">
              @if (!opportunitySelections.length) {
                <mat-option [value]="null">No existing overdraft opportunity</mat-option>
              }
              @for (option of opportunitySelections; track option) {
                <mat-option [value]="option">{{option.Name}} (Amount: {{option.Amount | looseCurrency}} Opportunity Id: {{option.SalesforceId}})</mat-option>
              }
            </mat-select>
            @for (errorKey of errorKeys(formControlStep1Opportunity); track errorKey) {
              <mat-error>
                @if (formControlStep1Opportunity.touched && formControlStep1Opportunity.hasError(errorKey)) {
                  {{errorMessage(formControlStep1Opportunity, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>


</ng-template>

<!--
##########################################
#  STEP 2: Overdraft Info
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Limit Increase Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Increased Limit</div>
            <div class="postfix-icon-padding">
              {{formControlStep2OverdraftLimit.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility Establishment Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep2FacilityEstablishmentFee.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Rate</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Rate.value | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Margin</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Margin.value | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Documentation Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep2DocFee.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Doc Fee Financed</div>
            <div class="postfix-icon-padding">
              {{formControlStep2DocFeeFinanced.value ? 'Yes' : 'No'}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Brokerage</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Brokerage.value | looseCurrency}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Customer Bank Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerAccountName.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Financial Institution Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerFinancialInstitutionName.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>BSB</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerBsb.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Number</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerAccountNumber.value}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Broker Bank Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerAccountName.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Financial Institution Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerFinancialInstitutionName.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>BSB</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerBsb.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Number</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerAccountNumber.value}}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 3: Account Create Success
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="success-icon">
            <span class="mdi mdi-file-sign"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="mat-subtitle-1">Overdraft account limit has been increased successfully</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          @if (!feePostingStatusStep2) {
            <span>However, some fees are posted failed. Please see below information.</span>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span>{{failedFeePostingMessage}}</span>
        </div>
      </div>
    </form>
  </div>
</ng-template>

