// ==========================
// ====   JSON Parsing
// ==========================
export const stringifyJSON = (o: any) => {
    try {
        return JSON.stringify(o);
    } catch (err) {
        console.log(`error stringify json`, o, err);
        return '';
    }
}

export const parseJSON = (s: any) => {
    try {
        return JSON.parse(s);
    } catch (err) {
        return s;
    }
}

export const isJson = (str: string) => {
    try {
        if (str === null || str === undefined) {
            return false;
        }
        JSON.parse(str);
        return true;
    } catch (err) {
        return false;
    }
};

