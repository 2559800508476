<form class="employment-selection-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Employment status</mat-label>
    <mat-select placeholder="Select status..." [formControl]="formControl">
      @for (option of options; track option) {
        @if(!(!allowUnemploymentOption && option.type == 'unemployed')){
        <mat-option
          [value]="option">
          {{option.name}}
        </mat-option>
      }
    }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</form>
