<div class="xero-oauth-callback container-fluid">
  <div class="row">
    <div class="col">
      @if (!ready) {
        Loading...
      }
      @if (ready) {
        @if (exchangeDocusignTokenPayload?.error) {
          <div>
            <p>Docusign Generate Token failed reason: </p>
            <ul>
              <li>{{$any(exchangeDocusignTokenPayload)?.error}}</li>
            </ul>
          </div>
        }
        @if (!exchangeDocusignTokenPayload?.error) {
          <div>
            <p>Xero Exchange Token Success</p>
            <table class="mat-table width-100">
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">access_token</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.access_token}}</td>
              </tr>
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">expires_in</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.expires_in}}</td>
              </tr>
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">id_token</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.id_token}}</td>
              </tr>
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">refresh_token</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.refresh_token}}</td>
              </tr>
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">scope</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.scope}}</td>
              </tr>
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">token_type</td>
                <td class="mat-header-cell cdk-header-cell">{{$any(exchangeDocusignTokenPayload)?.token_type}}</td>
              </tr>
            </table>
          </div>
        }
      }
    </div>
  </div>
</div>

