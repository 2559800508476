import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '../component-utils';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CellComponent } from '../application-summary-component/cell.component';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'asset-ppsr-details',
    templateUrl: './asset-ppsr-details.component.html',
    styleUrls: ['./asset-ppsr-details.component.scss'],
    standalone: true,
    imports: [CellComponent, MatFormFieldModule, MatDividerModule]
})
export class AssetPpsrDetailsComponent implements OnInit {

  @Input({required: true}) vehicleDetails!: any;

  constructor() {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    // console.log('Vehicle details', this.vehicleDetails) 
  }

}
