import {DisbursementTableComponent} from './disbursement-table.component';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ApplicationDialogService, BusinessNumberSearchFn, PortalHotToastService, SearchSupplierFn, getUser, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  AssetDisbursement,
  AssetSettlementGetApplicationAssetFn,
  AssetSupplier,
  BusinessDisbursement,
  CreateNewDisbursementFn,
  Disbursement,
  AssetDisbursementPayeeDialogResult,
  GetDisbursementByApplicationIdFn,
  parseJSON,
  PpsrAsset,
  SyncDisbursementToSfFn,
  UpdateDisbursementFn,
  Application,
  SyncBankDetailsToSfFn,
  PaymentChartData,
  DisbursementBusinessPayee,
  AssetDisbursementInvoicesDialogResult,
  DisbursementBankDetailsDialogResult,
  DisbursementAmountDialogResult,
  ConfirmationDialogResult,
  DisbursementInvoice,
  DisbursementBankDetails,
  BusinessDisbursementPayeeDialogResult,
  BusinessDisbursementInvoicesDialogResult,
  GetBillerNameFn,
  ValidateBPAYFn,
  BpayValidationData,
  GetInstitutionNameFn,
  GetBankDetailsFromOpportunitySfFn,
  AddAuditLogFn,
  User,
  stringifyJSON
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, tap } from 'rxjs';
import { loadingFor } from '@ngneat/loadoff';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export class BusinessDisbursementTableComponentInternalDataSource extends DataSource<BusinessDisbursement> {

  subject: Subject<BusinessDisbursement[]> = new BehaviorSubject<BusinessDisbursement[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<BusinessDisbursement[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(disbursements: BusinessDisbursement[]) {
    this.subject.next(disbursements);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-disbursement-table',
    templateUrl: './business-disbursement-table.component.html',
    styleUrls: ['./business-disbursement-table.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatFormFieldModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatTooltipModule, NgStyle, AsyncPipe, TruncatePipe, LooseCurrencyPipe]
})
export class BusinessDisbursementTableComponent implements OnInit, OnChanges  {

  // @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) validateBpayFn!: ValidateBPAYFn;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;


  subscriptions: Subscription[] = [];
  loggedinUser: User | null = getUser();
  dataSource = new BusinessDisbursementTableComponentInternalDataSource();
  columnsToDisplay = ["payee", "invoices", "bankDetails", "amount", "action"];
  loader = loadingFor('disbursementTable');
  disbursementData: BusinessDisbursement[] = [];
  bpayMessage: string[] = []
  bpayError: boolean[] = []
  //  disbursementData: Disbursement[] = [{
  //    payee: null,
  //    invoices: [],
  //    amount: 0,
  //    bankDetails: null,
  //    newDisbursement: true,
  //    id: 1,
  //    lastUpdatedTime: "",
  //    isDeleted: false,
  //    syncToSF: false,
  //    applicationId: 3635,
  //  }];

  allAssets: PpsrAsset[] = [];
  allPayees: DisbursementBusinessPayee[] = [];
  allInvoices: DisbursementInvoice[] = [];
  totalAmount: number = 0;
  docFeeFinanced = true;
  sumUpAmount: number = 0;
  constructor(
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
    setupUntilDestroy(this)
    this.loadDisbursement();
    // this.loadAsset();

    if ((this.application?.PricingDetails as any).DocFeeFinanced == 'Yes') {
      this.docFeeFinanced = true;
    } else {
      this.docFeeFinanced = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['paymentChartData']) {
      this.paymentChartData = changes['paymentChartData'].currentValue;
    }
  }

  loadDisbursement() {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn<BusinessDisbursement>(this.application.ApplicationId).pipe(
        this.loader.disbursementTable.track(),
      ).subscribe((r: BusinessDisbursement[]) => {
        this.disbursementData = r;
        console.log('disbursement data: ', r);
        this.setValidityofBpay();
        this.dataSource.update(this.disbursementData);
        this.updateTotalAmount();
      })
    )
  }

  setValidityofBpay = () => {
    this.disbursementData.map((data, index) => {
      if (data.bankDetails?.paymentType == 'bpay' && data.bankDetails.bpayAmount) {
        this.validateBpay(data.bankDetails, index)
      }
    })
  }

  validateBpay(bankDetails: DisbursementBankDetails, index: number) {
    if (bankDetails.paymentType == 'bpay' && bankDetails.bpayAmount) {
      const bpayValidationData: BpayValidationData = {
        BillerCode: bankDetails.bpayBillerCode!,
        CRN: bankDetails.bpayCrn!,
        Amount: bankDetails.bpayAmount
      }

      this.validateBpayFn(bpayValidationData).pipe(
        tap(r => {
          if (r) {
            console.log(bpayValidationData.Amount, r.validationErrorMessage)
            this.bpayError[index] = r.isValidationSuccessful,
            this.bpayMessage[index] = r.validationErrorMessage
          }
        })
      ).subscribe()
    }
  }

  // loadAsset() {
  //   this.subscriptions.push(
  //     this.getApplicationAssetFn(this.application.ApplicationId).pipe(
  //       this.toastService.spinnerObservable(),
  //     ).subscribe((assets: PpsrAsset[]) => {
  //       this.allAssets = assets;
  //       // get all payees and all invoices
  //       for (const asset of assets) {
  //         // 1. Business Term Loan not need to fill allPayees
  //         // 2. fill allInvoices
  //         if (!this.allInvoices.find(invoice => invoice.invoiceNumber === asset.invoiceNumber)){
  //           this.allInvoices.push({
  //             invoiceNumber: asset.invoiceNumber ?? "",
  //             invoiceAmount: asset?.SettlementAssetDetails?.InvoicePrice ?? 0,
  //             invoiceGst: asset?.SettlementAssetDetails?.GST ?? 0,
  //           })
  //         }
  //       }

  //       console.log("all payees: ", this.allPayees);
  //       console.log("all invoices: ", this.allInvoices);
  //     })
  //   )
  // }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'payee': return "Payee";
      case 'invoices': return "Reference";
      case 'amount': return "Amount";
      case 'bankDetails': return "Bank Details";
      case 'action':
        return '';
      default: return column;
    }
  }

  displayPayee(element: BusinessDisbursement) {
    // console.log(element)
    return element.payee?.customerSfId ? this.application.entityName : element.payee?.supplier?.SupplierName
  }

  displayInvoices(element: BusinessDisbursement) {
    return element.invoices?.invoiceNumber
  }

  // updateAmount(element: Disbursement, index: number) {
  //   this.disbursementData[index] = {
  //     ...this.disbursementData[index],
  //     amount: this.disbursementData[index].invoices.reduce((a, b) => a + b.invoiceAmount, 0),
  //   };
  // }

  updateTotalAmount() {
    this.totalAmount =  this.disbursementData.reduce((a, b) => a + b.amount, 0);
    this.sumUpAmount = this.totalAmount + Number(this.paymentChartData.brokerageAmount) +
      Number(this.paymentChartData.brokerOriginationFee) + (this.docFeeFinanced ? Number(this.paymentChartData.docFee) : 0);
  }

  updateDisbursement(disbursement: Disbursement, callback?: ()=>void) {
    this.subscriptions.push(
      this.updateDisbursementFn(disbursement).pipe(
        this.toastService.snackBarObservable("Disbursement updated")
      ).subscribe((rst) => {
        callback && callback();
      })
    )
  }

  openPayeeDialog(element: BusinessDisbursement, index: number) {
    console.log(this.application.AppInfo)
    this.dialogService.openBusinessDisbursementPayeeDialog({
      allPayees: this.allPayees,
      currentPayee: element.payee,
      searchSupplierFn: this.searchSupplierFn,
      customerSfId: this.application.AppInfo.CustomerId!
    }).afterClosed().subscribe((r: BusinessDisbursementPayeeDialogResult | undefined) => {
      if (r && r.type === 'submit') {
        if (r.payee.type == 'customer') {
          const payee: DisbursementBusinessPayee = { i: 'disbursement-business-payee', 'customerSfId': r.payee.customerSfId }
          this.subscriptions.push(this.getBankDetailsFromOpportunitySfFn(this.application.AppInfo.SalesforceId!).subscribe(bankDetails => {
            if (bankDetails) {
              console.log("Bank details", bankDetails)
              this.disbursementData[index] = {
                ...this.disbursementData[index],
                payee: payee,
                bankDetails: {
                  paymentType: 'direct-debit',
                  bankAccountName: bankDetails.AccountName!,
                  bankAccountNumber: bankDetails.AccountNumber!,
                  bankAccountBsb: bankDetails.BSB!,
                  financialInstitution: bankDetails.InstitutionName!
                }
              }
            }
            else {
              this.disbursementData[index] = {
                ...this.disbursementData[index],
                payee: payee
              }
            }
            this.dataSource.update(this.disbursementData);
            this.updateDisbursement(this.disbursementData[index]);
          }))
        }
        else {
          const payee: DisbursementBusinessPayee = { i: 'disbursement-business-payee', supplier: r.payee.supplier }
          let bankDetails: DisbursementBankDetails | undefined;
          if (r.payee.supplier?.BankAccountBsb) {
            bankDetails = {
              paymentType: 'direct-debit',
              bankAccountBsb: r.payee.supplier.BankAccountBsb,
              bankAccountName: r.payee.supplier.BankAccountName,
              bankAccountNumber: r.payee.supplier.BankAccountNumber,
              financialInstitution: r.payee.supplier.FinancialInstitution
            }
          }
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            payee: payee,
            bankDetails: bankDetails ?? null
          }
        }
        this.dataSource.update(this.disbursementData);
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Payee`,
          stringifyJSON(element.payee),
          stringifyJSON(r.payee),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  openInvoicesDialog(element: BusinessDisbursement, index: number) {
    // todo:
    this.dialogService.openBusinessDisbursementInvoicesDialog({
      allInvoices: this.allInvoices,
      currentInvoices: element.invoices,
    }).afterClosed().subscribe((r: BusinessDisbursementInvoicesDialogResult | undefined) => {
      if (r && r.type === 'submit') {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          invoices: r.invoices,
        };
        // this.updateAmount(element, index);
        this.updateTotalAmount();
        this.dataSource.update(this.disbursementData);
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Invoices`,
          stringifyJSON(element.invoices),
          stringifyJSON(r.invoices),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  openBankDetailsDialog(element: Disbursement, index: number) {
    const amount = this.disbursementData[index].amount ?? null
    this.dialogService.openDisbursementBankDetailsDialog({
      bankDetails: element.bankDetails,
      getBillerNameFn: this.getBillerNameFn,
      getInstitutionNameFn: this.getInstitutionNameFn

    }).afterClosed().subscribe((r: DisbursementBankDetailsDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          bankDetails: r.bankDetails,
        };
        if (r.bankDetails.bpayAmount) {
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            amount: r.bankDetails.bpayAmount
          }
          this.validateBpay(r.bankDetails, index)
        }
        this.dataSource.update(this.disbursementData);
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Bank Details`,
          stringifyJSON(element.bankDetails),
          stringifyJSON(r.bankDetails),
          index,
          this.disbursementData[index].id
        )
        // todo: Business Term Loan / overdraft - no supplier
        // if (this.disbursementData[index].payee?.supplier?.SalesForceId) {
        //   this.syncSupplierBankDetailsToSf(this.disbursementData[index]?.payee?.supplier?.SalesForceId ?? "", r.bankDetails);
        // }
      }
    })
  }

  openAmountDialog(element: Disbursement, index: number) {
    const bankDetails = this.disbursementData[index].bankDetails
    this.dialogService.openDisbursementAmountDialog({
      amount: element.amount
    }).afterClosed().subscribe((r: DisbursementAmountDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          amount: r.amount,
        };
        if (bankDetails && bankDetails.paymentType == 'bpay') {
          const newBankDetails = { ...bankDetails, bpayAmount: r.amount }
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            bankDetails: newBankDetails
          }
          this.validateBpay(newBankDetails, index)
        }
        this.dataSource.update(this.disbursementData);
        this.updateTotalAmount();
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Amount`,
          stringifyJSON(element.amount),
          stringifyJSON(r.amount),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  onDelete(element: Disbursement, index: number) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to delete this disbursement?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          if (!element?.newDisbursement) {
            // delete in database
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            isDeleted: true
          };
          this.updateDisbursement(this.disbursementData[index], () => {
            this.loadDisbursement()
            this.saveAuditLog(
              `Delete Disbursement`,
              '',
              '',
              index,
              this.disbursementData[index].id
            )
          });
        }
          this.disbursementData.splice(index, 1);
          this.setValidityofBpay()
          this.dataSource.update(this.disbursementData);
          // this.loadDisbursement()
        }
      })
    )
  }

  addNewDisbursement() {
    const newDisbursementData: BusinessDisbursement = {
      i: 'business-disbursement',
      payee: null,
      invoices: null,
      amount: 0,
      bankDetails: null,
      newDisbursement: true,
      id: 0,
      applicationId: this.application?.ApplicationId,
      lastUpdatedTime: "",
      isDeleted: false,
      // WEB-3702: syncToSF: false,
      salesforceId: null,
    };
    this.subscriptions.push(
      this.createNewDisbursementFn(newDisbursementData).subscribe((r: number) => {
        newDisbursementData.id = r;
        this.disbursementData.push(newDisbursementData);
        this.dataSource.update(this.disbursementData);

        this.saveAuditLog(
          `Add New Disbursement`,
          '',
          stringifyJSON(newDisbursementData),
          this.disbursementData.length - 1,
          r
        )
      })
    )
  }

  batchDeleteDisbursement() {
    const subs = [];
    for (let i = 0; i < this.disbursementData.length; i++)  {
      subs.push(this.updateDisbursementFn({
        ...this.disbursementData[i],
        isDeleted: true,
      }))

      this.saveAuditLog(
        `Delete Disbursement`,
        '',
        '',
        i,
        this.disbursementData[i].id
      )
    }
    this.subscriptions.push(combineLatest(subs).subscribe());
  }

  batchCreateDisbursement() {
    const subs = [];
    for (const disbursement of this.disbursementData) {
      subs.push(this.createNewDisbursementFn(disbursement))
    }
    this.subscriptions.push(
      combineLatest(subs).pipe(
        this.toastService.snackBarObservable("Disbursement created")
      ).subscribe(() => {
        this.saveAuditLog(
          `Default Disbursement`,
          '',
          stringifyJSON(this.disbursementData),
          0,
          0,
        )
      })
    );
  }

  get defaultDisbursement(): BusinessDisbursement[] {
    const disbursementList: BusinessDisbursement[] = this.allPayees.map((payee: DisbursementBusinessPayee) => {
      return {
        i: 'business-disbursement',
        payee: payee,
        invoices: null,
        amount: 0,
        bankDetails: null,
        id: 0,
        applicationId: this.application?.ApplicationId,
        lastUpdatedTime: "",
        isDeleted: false,
        syncToSF: false,
        salesforceId: null,
      }
    });
    return disbursementList;
  }

  syncDisbursementToSF() {
    // this.subscriptions.push(this.syncDisbursementToSfFn(disbursementId).pipe().subscribe());
    if (!this.validateDisbursementDataHaveBankDetails()) {
      this.dialogService.openAlertDialog({
        message: "Warning",
        subMessage: `Please make sure you enter payment details for all disbursements.`,
      }).afterClosed().subscribe()
    } else {
      const subs = [];
      for (const data of this.disbursementData) {
        subs.push(this.syncDisbursementToSfFn({
          disbursement: data,
          salesforceId: this.application.AppInfoSalesforceID ?? ""
        }))
      }
      this.subscriptions.push(
        combineLatest(subs).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.snackBarObservable("Sync to SF success"),
        ).subscribe(() => {
          this.loadDisbursement();
        })
      )
    }
  }

  syncSupplierBankDetailsToSf(salesforceId: string, bankDetails: DisbursementBankDetails) {
    this.subscriptions.push(
      this.syncBankDetailsToSfFn({
        salesforceId,
        financialInstitution: bankDetails.financialInstitution ?? "",
        bankAccountName: bankDetails.bankAccountName ?? "",
        bankAccountBsb: bankDetails.bankAccountBsb ?? "",
        bankAccountNumber: bankDetails.bankAccountNumber ?? "",
      }).subscribe()
    )
  }

  private validateDisbursementDataHaveBankDetails(): boolean {
    for (const data of this.disbursementData) {
      if (!data?.bankDetails || !data?.bankDetails?.paymentType) {
        return false;
      }
    }
    return true;
  }

  saveAuditLog(field: string, oldValue: string, newValue: string, index: number, id: number) {
    this.addAuditLogFn({
      UserId: this.loggedinUser?.UserId!,
      ApplicationId: this.application.ApplicationId,
      Field: field,
      OldValue: oldValue,
      NewValue: newValue,
      AssetNumber: index,
      AssetId: id,
    }).subscribe()
  }
}
