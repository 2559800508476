import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {
    Enable2FAPasswordDialogData, Enable2FAPasswordDialogResult,
    User
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getUser } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy({ arrayName: 'subscription' })
@Component({
    templateUrl: './enable-2fa-password.dialog.html',
    styleUrls: ['./enable-2fa-password.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, FlexModule, MatInputModule, MatIconModule, MatButtonModule, MatDialogModule]
})
export class Enable2FAPasswordDialog implements OnInit {
  user: User | null = null;
  formGroup!: FormGroup<{
    password: FormControl<number | null>
  }>;
  passwordControl!: FormControl;
  hide = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Enable2FAPasswordDialogData,
    private matDialogRef: MatDialogRef<Enable2FAPasswordDialog, Enable2FAPasswordDialogResult>,
    private fb: FormBuilder) {
    this.passwordControl = this.fb.control(null, Validators.required);
    this.formGroup = this.fb.group({
      password: this.passwordControl
    })
  }

  ngOnInit(): void {
    this.user = getUser()
  }

  onCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {
    const email = this.user?.Email;
    const password = this.passwordControl.value ?? '';
    const dialogResult: Enable2FAPasswordDialogResult = {
        email: email!,
        password: password
    };
    this.matDialogRef.close(dialogResult);
  }

}
