import {Address2ComponentValue} from '../component/address2-component.domain';

export type PersonalAssetType = 'property' | 'vehicle' | 'cash' | 'share';
export type PersonalPropertyAsset = {
  assetType: {
    type: 'property',
    name: string,
  };
  address: Address2ComponentValue;
  value: number;
}

export type PersonalNonPropertyAsset = {
  assetType: {
    type: 'vehicle' | 'cash' | 'share';
    name: string,
  },
  description: string,
  value: number,
}

export type PersonalAsset = PersonalPropertyAsset | PersonalNonPropertyAsset;

export type PersonalAssetsValue = {
  total: number,
  assets: PersonalAsset[]
} | null;

export type PersonalAssetValueOptionsType = Exclude<PersonalAssetsValue, null>['assets'][number]['assetType'][];

export const PersonalAssetValueOptions: PersonalAssetValueOptionsType = [
  {type: 'property',    name: 'Property'},
  {type: 'vehicle',     name: 'Motor Vehicle'},
  {type: 'share',       name: 'Shares'},
  {type: 'cash',        name: 'Cash'},
];
