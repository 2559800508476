<div class="overdraft-customer-side-menu-component">
  <div class="menuset-2">
    <div class="title mat-caption">ACCOUNT</div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-profile'}" (click)="onMenuItemClicked($event, 'overdraft-customer-profile')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-account-circle-outline"></span><span>{{user?.GivenName ?? ''}} {{user?.FamilyName ?? ''}}</span></div>
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-notifications'}" (click)="onMenuItemClicked($event, 'overdraft-customer-notifications')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-bell-outline"></span><span>Notifications</span></div>
    </div>
  </div>

  <div class="menuset-2 mat-caption">
    <div class="title">PRODUCT</div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-dashboard'}" (click)="onMenuItemClicked($event, 'overdraft-customer-dashboard')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-chart-box-outline"></span><span>Dashboard</span></div>

    </div>
  </div>

  <div class="menuset-2">
    <div class="title mat-caption">GROW</div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-other-products'}" (click)="onMenuItemClicked($event, 'overdraft-customer-other-products')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-cube-outline"></span><span>Other Products</span></div>
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-special-offers'}" (click)="onMenuItemClicked($event, 'overdraft-customer-special-offers')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-gift-outline"></span><span>Special Offers</span></div>
      <div class="menuitem" [ngClass]="{activated: menuItemId==='overdraft-customer-contact-us'}" (click)="onMenuItemClicked($event, 'overdraft-customer-contact-us')" fxLayout="row" fxLayoutGap="10px"><span class="mdi mdi-information-outline"></span><span>Contact Us</span></div>
    </div>
  </div>
</div>
