import {Component, Inject} from "@angular/core";
import {FlexModule} from "@angular/flex-layout/flex";
import { AsyncPipe, CommonModule, JsonPipe, PercentPipe } from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {EditableTextComponent} from "../editable-component/editable-text.component";
import {EditablePercentageComponent} from "../editable-component/editable-percentage.component";
import {EditableNumberComponent} from "../editable-component/editable-number.component";
import {EditableCurrencyComponent} from "../editable-component/editable-currency.component";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {
  CorporateLoanPaymentBreakupDialogData,
  CorporateLoanPricingComparisonDialogData,
  CorporateLoanPricingComparisonDialogDatasource,
  CorporateLoanPricingComparisonDialogResult,
  CurrencyInputValue,
} from "@portal-workspace/grow-shared-library";
import {setupUntilDestroy} from "../component-utils";
import {ApplicationDialogService} from "./application-dialog.service";
import {PercentagePipe} from "../../pipes/percentage.pipe";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './corporate-loan-pricing-comparison.dialog.html',
  styleUrls: ['./corporate-loan-pricing-comparison.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    JsonPipe,
    AsyncPipe,
    MatButtonModule,
    LooseCurrencyPipe,
    EditableTextComponent,
    EditablePercentageComponent,
    EditableNumberComponent,
    EditableCurrencyComponent,
    PercentagePipe
]
})
export class CorporateLoanPricingComparisonDialog {

  subscriptions: Subscription[] = [];

  dataSource: CorporateLoanPricingComparisonDialogDatasource = [];
  currentValue: CorporateLoanPaymentBreakupDialogData;
  newValue: CorporateLoanPaymentBreakupDialogData;

  formControlDocFee!: FormControl<CurrencyInputValue>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CorporateLoanPricingComparisonDialogData,
    private dialogRef: MatDialogRef<CorporateLoanPricingComparisonDialog, CorporateLoanPricingComparisonDialogResult>,
    private applicationDialogService: ApplicationDialogService,
    private formBuilder: FormBuilder,
  ) {
    this.currentValue = data.currentValue
    this.newValue = data.newValue
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlDocFee = this.formBuilder.control(this.newValue.term.docFee, [Validators.required]);
    this.subscriptions.push(this.formControlDocFee.valueChanges.pipe(
      // doc fee was changed
    ).subscribe());
    this.dataSource = [
      {
        type: 'currency',
        name: 'Facility Limit',
        currentValue: this.currentValue.term.facilitylimit,
        newValue: this.newValue.term.facilitylimit,
      },
      {
        type: 'percentage',
        name: 'Interest Rate',
        currentValue: this.currentValue.term.interestRate,
        newValue: this.newValue.term.interestRate,
        // currentValueClick: (currentValue) => {
        //   this.applicationDialogService.openInterestRateCalculationLogDialog({
        //     logs: this.currentValue?.term?.calculationLog ?? [],
        //   });
        // },
        newValueClick: (newValue) => {
          this.applicationDialogService.openInterestRateCalculationLogDialog({
            logs: this.newValue?.term?.calculationLog ?? [],
          });
        },
      },
      {
        type: 'percentage',
        name: 'RBA Rate',
        currentValue: this.currentValue.term.rbaRate,
        newValue: this.newValue.term.rbaRate,
      },
      {
        type: 'currency',
        name: 'Facility Establishment Fee',
        currentValue: this.currentValue.term.facilityEstablishmentFee,
        newValue: this.newValue.term.facilityEstablishmentFee,
      },
      {
        type: 'currency',
        name: 'Monthly Facility Fee',
        currentValue: this.currentValue.term.monthlyFacilityFee,
        newValue: this.newValue.term.monthlyFacilityFee,
      },
      {
        type: 'currency',
        name: 'Doc/Annual fee ($)',
        currentValue: this.currentValue.term.docFee,
        newValue: this.newValue.term.docFee,
        formControl: this.formControlDocFee,
      },
      {
        type: 'currency',
        name: 'Brokerage(ex GST)',
        currentValue: this.currentValue.term.brokerageDollar,
        newValue: this.newValue.term.brokerageDollar,
      },
    ];
  }

  onAcceptQuotation($event: Event) {
    this.dialogRef.close({
      acceptedQuotation: true,
      docFee: this.formControlDocFee.value ?? this.currentValue.term.docFee,
    });
  }

  onClose($event: Event) {
    this.dialogRef.close();
  }


}
