import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ConfigurationService} from '../../service/configuration.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import { SlideToggleValue } from '@portal-workspace/grow-shared-library';
import { StatusToggleComponent } from '@portal-workspace/grow-ui-library';
import { MatCardModule } from '@angular/material/card';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './set-maintenance.page.html',
    styleUrls: ['./set-maintenance.page.scss'],
    standalone: true,
    imports: [MatCardModule, StatusToggleComponent, FormsModule, ReactiveFormsModule]
})
export class SetMaintenancePage implements OnInit {

  subscriptions: Subscription[] = [];

  formControlMaintenanceMode: FormControl<SlideToggleValue>;

  constructor(private formBuilder: FormBuilder,
              private configurationService: ConfigurationService) {
    this.formControlMaintenanceMode = this.formBuilder.control(null);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.configurationService.getConfigurations().pipe(
      // this.toastService.toastObserver('Loading configuration'),
      tap(r => {
        const m = r.payload.MaintenanceMode;
        m === 'enable' ? this.formControlMaintenanceMode.setValue(true) : this.formControlMaintenanceMode.setValue(false);
      })
    ).subscribe();
    this.subscriptions.push(sub);

    const formChangeSub = this.formControlMaintenanceMode.valueChanges.subscribe(changes => {
        this.onMaintenanceChange(changes);
      }
    );
    this.subscriptions.push(formChangeSub);
  }

  onMaintenanceChange($event: boolean | null) {
    const b = $event ? 'enable' : 'disable';
    this.configurationService.updateConfiguration({
      MaintenanceMode: b,
    }).pipe(
      // this.toastService.toastObserver('Updating configuration'),
    ).subscribe();
  }
}
