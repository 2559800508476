<div class="overdraft-account-users-component">
  <!--
  ########################
  enabled users table
  ########################
  -->
  <div class="row">
    <div class="col"><span class="mat-h3 ml-3">Active Users</span></div>
  </div>
  <div class="row cursor-pointer">
    <div class="col-12">
      @if (loader.loadingEnabledOverdraftUser.inProgress$ | async) {
        <custom-content-loader type="list"></custom-content-loader>
      }
      <div
        [ngClass]="{
          hidden: (loader.loadingEnabledOverdraftUser.inProgress$ | async),
          'table-section': true,
          'pb-3': true
        }"
      >
      <table mat-table
      mat-table
          [dataSource]="enabledUsersDataSource"
          multiTemplateDataRows
          class="width-100 mb-2"
          matSort
          (matSortChange)="onEnabledUsersSort($event)"
      >
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
          <td mat-cell *matCellDef="let element" class="cell cell-padding">
            @if (enabledUsersExpandedElement === element) {
              <span class="mdi mdi-chevron-up"></span>
            }
            @if (enabledUsersExpandedElement !== element) {
              <span class="mdi mdi-chevron-down"></span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="pismoCustomerNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="pismoCustomerNumber"
            class="column-width-1"
          >
            Pismo Customer#
          </th>
          <td mat-cell *matCellDef="let element" class="cell ellipsis">
            {{ element.pismoCustomerNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-width-1">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>
        <ng-container matColumnDef="access">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="access" class="column-width-1">
            Access
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customerAccessLevel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="email" class="column-width-1">
            Email
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Email }}</td>
        </ng-container>
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="dob" class="column-width-1">DoB</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.dob ? moment(element.dob).format('DD/MM/YYYY') : 'N/A'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="mobileNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="mobileNumber"
            class="column-width-1"
          >
            Mobile
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.MobileNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="enabledUsersDisplayColumns.length">
            <div class="element-detail" [@detailExpand]="element == enabledUsersExpandedElement ? 'expanded' : 'collapsed'">
              <div class="row pismo-account-details">
                <div class="col-3 title">Verified</div>
                <div class="col-6">{{ element.verified ? 'Yes' : 'No' }}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-3 title">Kyc Status</div>
                <div class="col-3">
                  {{ element.kycStatus }}
                  @if(canAccess){
                  <span
                    class="mdi mdi-square-edit-outline cursor-pointer"
                    matTooltip="Update Kyc Status"
                    (click)="onUpdateKycVerificationStatus(element)"
                  ></span> }
                </div>
                <div class="col-6">
                  <table class="kyc-verification-result-table">
                    @if (element.kycVerificationResult) {
                      <tr>
                        <th>Verification status</th>
                        <td>
                          <!-- <tag-box [state]="verificationStatusDisplay(element.kycVerificationResult?.verification_status)"></tag-box> -->
                        </td>
                      </tr>
                    }
                    @if(element.kycVerificationResult){
                      <tr>
                        <th>Data source</th>
                        <td>
                          @if(element.kycVerificationResult?.data_source_events
                          ?.length){
                            <ul>
                              @for(event of element.kycVerificationResult.data_source_events; track event){
                                <li>
                                  {{ datasourceEventDisplay(event) }}
                                </li>
                              }
                            </ul>
                          }
                          @if(!element.kycVerificationResult?.data_source_events
                          ?.length){
                            <span>Not available</span>
                            }
                        </td>
                      </tr>
                    }
                    @if(element.kycVerificationResult){
                      <tr>
                        <th>Transaction ID</th>
                        <td>
                          {{
                            defaultString(
                              element.kycVerificationResult?.transaction_id
                            )
                          }}
                        </td>
                      </tr>
                    }
                  </table>
                </div>
              </div>
              @if(loader.loadingCardDetails.inProgress$ | async){
                <custom-content-loader
                  type="list"
                ></custom-content-loader>
              }
              <div
                [ngClass]="{
                  hidden: (loader.loadingCardDetails.inProgress$ | async) || !(cardsTotalPerUser > 0),
                  'table-section': true,
                  'pb-3': true
                }"
              >
              <table mat-table [dataSource]="cardsDataSource" multiTemplateDataRows>
                <ng-container matColumnDef="printed_name">
                    <th mat-header-cell *matHeaderCellDef>Printed Name</th>
                    <td mat-cell *matCellDef="let element">{{element.printed_name}}</td>
                </ng-container>
                <ng-container matColumnDef="last_4_digits">
                    <th mat-header-cell *matHeaderCellDef>Card last 4 digits</th>
                    <td mat-cell *matCellDef="let element">{{element.last_4_digits}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{element.status}}</td>
                </ng-container>
                <ng-container matColumnDef="expiration_date">
                    <th mat-header-cell *matHeaderCellDef >Expiration Date</th>
                    <td mat-cell *matCellDef="let element">{{element.expiration_date | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                @if(canAccess){
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
                            <span class="mdi mdi-credit-card-edit-outline cursor-pointer" matTooltip="Card Settings" (click)="openCardsSettings(element)" ></span>
                        </div>
                    </td>
                </ng-container>
              }
                <tr mat-header-row *matHeaderRowDef="cardsDisplayColumns ; sticky: true"></tr>
                <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: cardsDisplayColumns"></tr>
              </table>
              </div>
              @if(!(loader.loadingCardDetails.inProgress$ | async) && cardsTotalPerUser < 1){
                <table class="width-100 mb-3 mt-3">
                  <tr>
                    <td class="not-found">No cards found for this customer</td>
                  </tr>
                </table>
              }
              @if(canAccess){
              <div class="row pismo-account-details mb-2">
                <div
                  class="col"
                  fxLayoutGap="10px"
                  fxLayout="row"
                  fxLayout.lt-md="column"
                  fxLayoutAlign="start center"
                  fxLayoutAlign.lt-md="center start"
                >
                  <button
                    mat-stroked-button
                    color="accent"
                    class="w-lt-md-100"
                    (click)="resetPassword(element.Email)"
                    matTooltip="Reset Password"
                  >
                    <span
                      class="mdi mdi-lock-reset cursor-pointer"
                      matPrefix
                    ></span>
                    Reset Password
                  </button>
                  <button
                    mat-stroked-button
                    color="accent"
                    class="w-lt-md-100"
                    (click)="editPismoCustomer(element)"
                    matTooltip="Edit customer {{ element.Name }}"
                  >
                    <span
                      class="mdi mdi-account-edit-outline cursor-pointer"
                      matPrefix
                    ></span>
                    Edit Pismo Customer
                  </button>
                  <button
                    mat-stroked-button
                    color="accent"
                    class="w-lt-md-100"
                    [disabled]="
                      (loader.loadingEnabledOverdraftUser.inProgress$
                        | async) ||
                      (loader.disablingOverdraftUser.inProgress$ | async)
                    "
                    (click)="
                      disableUser(element.userId, element.pismoAccountNumber)
                    "
                    matTooltip="Disable Pismo Customer"
                  >
                    <span
                      class="mdi mdi-account-cancel-outline cursor-pointer"
                      matPrefix
                    ></span>
                    Disable User
                  </button>
                </div>
              </div>
            }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="enabledUsersDisplayColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: enabledUsersDisplayColumns"
            class="cursor-pointer"
            [class.expanded-row]="enabledUsersExpandedElement &&
              enabledUsersExpandedElement === element"
            (click)="onRowClickEnabledUser(element)"></tr>
        <tr class="detail-row"
            [ngClass]="{'detail-row-expanded': enabledUsersExpandedElement &&
              enabledUsersExpandedElement === element}"
            [ngStyle]="{'display': enabledUsersExpandedElement &&
              enabledUsersExpandedElement === element ? null : 'none'}"
            mat-row
            *matRowDef="let element; columns: ['expandedDetail']"></tr>
        </table>
        @if (enabledUsersTotal<1) {
          <table class="width-100">
            <tr>
              <td class="not-found"> No account users found</td>
            </tr>
          </table>
        }
        <div class="ml-3">
          <app-custom-paginator
            [length]="enabledUsersTotal"
            [pageIndex]="enabledUsersOffset"
            [pageSize]="enabledUsersLimit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]"
            (page)="onEnabledUsersPagination($event)"
          >
          </app-custom-paginator>
        </div>
      </div>
    </div>
  </div>

  <!--
  ########################
  disabled users table
  ########################
  -->
  <div class="row separator-top">
    <div class="col"><span class="mat-h3 ml-3">Inactive Users</span></div>
  </div>
  <div class="row cursor-pointer">
    <div class="col-12">
      @if(loader.loadingDisabledOverdraftUser.inProgress$ | async){
        <custom-content-loader
          type="list"
        ></custom-content-loader>
      }
      <div
        [ngClass]="{
          hidden: (loader.loadingDisabledOverdraftUser.inProgress$ | async),
          'table-section': true,
          'pb-3': true
        }"
      >
      <table mat-table
      mat-table
          [dataSource]="disabledUsersDataSource"
          multiTemplateDataRows
          class="width-100 mb-2"
          matSort
          (matSortChange)="onDisabledUsersSort($event)"
      >
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
          <td mat-cell *matCellDef="let element" class="cell cell-padding">
            @if (disabledUsersExpandedElement === element) {
              <span class="mdi mdi-chevron-up"></span>
            }
            @if (disabledUsersExpandedElement !== element) {
              <span class="mdi mdi-chevron-down"></span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="pismoCustomerNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="pismoCustomerNumber"
            class="column-width-1"
          >
            Pismo Customer#
          </th>
          <td mat-cell *matCellDef="let element" class="cell ellipsis">
            {{ element.pismoCustomerNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="column-width-1">
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>
        <ng-container matColumnDef="access">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="access" class="column-width-1">
            Access
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customerAccessLevel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="email" class="column-width-1">
            Email
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Email }}</td>
        </ng-container>
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="dob" class="column-width-1">DoB</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.dob ? moment(element.dob).format('DD/MM/YYYY') : 'N/A'
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="mobileNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="mobileNumber"
            class="column-width-1"
          >
            Mobile
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.MobileNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="disabledUsersDisplayColumns.length">
            <div class="element-detail" [@detailExpand]="element == disabledUsersExpandedElement ? 'expanded' : 'collapsed'">
              <div class="row pismo-account-details">
                <div class="col-3 title">Verified</div>
                <div class="col-6">{{ element.verified ? 'Yes' : 'No' }}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-3 title">Kyc Status</div>
                <div class="col-3">
                  {{ element.kycStatus }}
                  @if(canAccess){
                    <span
                    class="mdi mdi-square-edit-outline cursor-pointer"
                    matTooltip="Update Kyc Status"
                    (click)="onUpdateKycVerificationStatus(element)"
                  ></span> }
                </div>
                <div class="col-6">
                  <table class="kyc-verification-result-table">
                    @if(element.kycVerificationResult)
                    {
                      <tr>
                        <th>Verification status</th>
                        <td>
                          <!-- <tag-box [state]="verificationStatusDisplay(element.kycVerificationResult?.verification_status)"></tag-box> -->
                        </td>
                      </tr>
                      <tr>
                        <th>Data source</th>
                        <td>
                          @if(element.kycVerificationResult?.data_source_events
                          ?.length){
                            <ul>
                            @for(event of element.kycVerificationResult.data_source_events; track event){
                              <li>
                                {{ datasourceEventDisplay(event) }}
                              </li>
                            }
                            </ul>
                          }
                          @if(!element.kycVerificationResult?.data_source_events
                          ?.length){
                            <span>Not available</span
                            >
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>Transaction ID</th>
                        <td>
                          {{
                            defaultString(
                              element.kycVerificationResult?.transaction_id
                            )
                          }}
                        </td>
                      </tr>
                    }
                  </table>
                </div>
              </div>
              @if(canAccess){
                <div class="row pismo-account-details mb-2">
                    <div
                      class="col"
                      fxLayoutGap="10px"
                      fxLayout="row"
                      fxLayout.lt-md="column"
                      fxLayoutAlign="start center"
                      fxLayoutAlign.lt-md="center start"
                    >
                    <button
                    mat-stroked-button
                    color="accent"
                    class="w-lt-md-100"
                    [disabled]="
                      (loader.loadingEnabledOverdraftUser.inProgress$
                        | async) ||
                      (loader.disablingOverdraftUser.inProgress$ | async)
                    "
                    (click)="
                      enableUser(element.userId, element.pismoAccountNumber)
                    "
                    matTooltip="Enable Pismo Customer"
                  >
                    <span
                      class="mdi mdi-account-outline cursor-pointer"
                      matPrefix
                    ></span>
                    Enable User
                  </button>
                  </div>
                </div>
              }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="disabledUsersDisplayColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: disabledUsersDisplayColumns"
            class="cursor-pointer"
            [class.expanded-row]="disabledUsersExpandedElement &&
              disabledUsersExpandedElement === element"
            (click)="onRowClickDisabledUser(element)"></tr>
        <tr class="detail-row"
            [ngClass]="{'detail-row-expanded': disabledUsersExpandedElement &&
              disabledUsersExpandedElement === element}"
            [ngStyle]="{'display': disabledUsersExpandedElement &&
              disabledUsersExpandedElement === element ? null : 'none'}"
            mat-row
            *matRowDef="let element; columns: ['expandedDetail']"></tr>
        </table>
        @if (disabledUsersTotal<1) {
          <table class="width-100">
            <tr>
              <td class="not-found"> No account users found</td>
            </tr>
          </table>
        }
       
        <div class="ml-3">
          <app-custom-paginator
            [length]="disabledUsersTotal"
            [pageIndex]="disabledUsersOffset"
            [pageSize]="disabledUsersLimit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]"
            (page)="onDisabledUsersPagination($event)"
          >
          </app-custom-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
