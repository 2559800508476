<div class="business-overdraft-rate-card-component">
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Property Owner</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlPropertyOwner" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlPropertyOwner); track errorKey) {
          <mat-error>
            @if (formControlPropertyOwner.touched && formControlPropertyOwner.hasError(errorKey)) {
              {{errorMessage(formControlPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Non property Owner</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlNoPropertyOwner" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNoPropertyOwner); track errorKey) {
          <mat-error>
            @if (formControlNoPropertyOwner.touched && formControlNoPropertyOwner.hasError(errorKey)) {
              {{errorMessage(formControlNoPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Property Owner (500-550) </mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlDirectorScoreRate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlDirectorScoreRate); track errorKey) {
          <mat-error>
            @if (formControlDirectorScoreRate.touched && formControlDirectorScoreRate.hasError(errorKey)) {
              {{errorMessage(formControlPropertyOwner, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Non Property Owner (500-550) </mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlNonPropertyOwnerBetween500And600Rate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlNonPropertyOwnerBetween500And600Rate); track errorKey) {
          <mat-error>
            @if (formControlNonPropertyOwnerBetween500And600Rate.touched && formControlNonPropertyOwnerBetween500And600Rate.hasError(errorKey)) {
              {{errorMessage(formControlNonPropertyOwnerBetween500And600Rate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Facility Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlFacilityFee" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlFacilityFee); track errorKey) {
          <mat-error>
            @if (formControlFacilityFee.touched && formControlFacilityFee.hasError(errorKey)) {
              {{errorMessage(formControlFacilityFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Broker’s share of Facility Establishment Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlBrokerShareFacilityFee" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlBrokerShareFacilityFee); track errorKey) {
          <mat-error>
            @if (formControlBrokerShareFacilityFee.touched && formControlBrokerShareFacilityFee.hasError(errorKey)) {
              {{errorMessage(formControlBrokerShareFacilityFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>RBA Rate (readonly)</mat-label>
        <input readonly matInput type="text" [formControl]="formControlRbaRate">
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlRbaRate); track errorKey) {
          <mat-error>
            @if (formControlRbaRate.touched && formControlRbaRate.hasError(errorKey)) {
              {{errorMessage(formControlRbaRate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Max Facility Establishment Fee</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlMaxFacilityEstablishmentFee" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlMaxFacilityEstablishmentFee); track errorKey) {
          <mat-error>
            @if (formControlMaxFacilityEstablishmentFee.touched && formControlMaxFacilityEstablishmentFee.hasError(errorKey)) {
              {{errorMessage(formControlMaxFacilityEstablishmentFee, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>


  <mat-divider [inset]="true"></mat-divider>


  <div class="row separator">
    <div class="col-12">
      <min-max-rate title="Doc fee" [type]="'currency'" [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
</div>
