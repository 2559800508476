
<div class="account-number-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Account Number</mat-label>
      @if (!isInternational) {
        <input matInput [inputMask]="createAccountNumberInputMask" type="text"
          [formControl]="formControl"  required [disableControl]="disabled">
      }
      @if (isInternational) {
        <input matInput type="text"
          [formControl]="formControl"  required>
      }
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </form>
</div>
