import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DigitalIdComponent} from './digital-id.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        PipeLibraryModule,
        DirectiveLibraryModule,
        DigitalIdComponent
    ],
    exports: [
        DigitalIdComponent,
    ]
})
export class DigitalIdModule {

}
