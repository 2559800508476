import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  AccountNumberValue,
  Address2ComponentValue,
  AddressComponentValue, BankAccount, BsbComponentValue,
  compareMatch,
  CurrencyCode, CurrencySelectionValue, EmailComponentValue, GetInstitutionNameFn, MobileValue,
  NameComponentValue,
  NewCommercialSupplierValue,
  NewPrivateSupplierValue,
  NewSupplierValue
} from '@portal-workspace/grow-shared-library';
import {RadioOption} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {BsbComponent} from '../bsb-component/bsb.component';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CustomGoogleAddressComponent } from '../address-component/custom-google-address.component';
import { AccountNumberComponent } from '../account-number-component/account-number.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RadioOptionsComponent } from '../radio-options-component/radio-options.component';
import { CurrencySelectionComponent } from '../currency-selection-component/currency-selection.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { NameComponent } from '../name-component/name.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';


const INTERNATIONAL_BANK_RADIO_OPTIONS: RadioOption[] = [{type: 'domestic', name: 'Domestic'}, { type: 'international', name: 'International'}];
const DOMESTIC_BANK_RADIO_OPTIONS: RadioOption[] = [{ type: 'domestic', name: 'Domestic'}];


// NOTE: this is for Floorplan Supplier
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'new-supplier',
    templateUrl: './new-supplier.component.html',
    styleUrls: ['./new-supplier.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewSupplierComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => NewSupplierComponent) },
    ],
    standalone: true,
    imports: [MatButtonToggleModule, FormsModule, ReactiveFormsModule, NgTemplateOutlet, NameComponent, MarkDirective, CustomAddressComponent, EmailComponent, MobileComponent, CurrencySelectionComponent, RadioOptionsComponent, MatFormFieldModule, MatInputModule, AccountNumberComponent, CustomGoogleAddressComponent, LocalBankAccountDetailComponent]
})
export class NewSupplierComponent extends AbstractControlValueAccessor<NewSupplierValue> implements OnInit, Mark {

  @Input({required: false}) internationBank = false;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  markObservable: Subject<boolean> = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  bankTypeRadioOptions: RadioOption[] = [
    { type: 'domestic', name: 'Domestic' },
    { type: 'international', name: 'International' },
  ];

  formGroup!: FormGroup<{
    type: FormControl<'commercial' | 'private' | null>,
    supplierName: FormControl<NameComponentValue>,
    supplierAddress: FormControl<Address2ComponentValue>,
    supplierEmail: FormControl<EmailComponentValue>
    bankDetails: FormControl<BankAccount>
  }>;
  formControlSupplierType!: FormControl<'commercial' | 'private' | null>;
  formControlSupplierName!: FormControl<NameComponentValue>;
  formControlSupplierEmail!: FormControl<EmailComponentValue>;
  formControlSupplierAddress!: FormControl<Address2ComponentValue>;
  formControlBankDetails: UntypedFormControl;
  // commercial supplier
  formControlCommercialSupplierBusinessNumber!: FormControl<MobileValue>;
  formControlCommercialSupplierCurrencyCode!: FormControl<CurrencySelectionValue>;
  formControlCommercialSupplierBankAccountType: FormControl<'domestic' | 'international' | null>;
  formControlCommercialSupplierBankAccountName!: FormControl<string | null>;
  formControlCommercialSupplierBankAccountNumber!: FormControl<AccountNumberValue>;
  formControlCommercialSupplierDomesticBankAccountBsb!: FormControl<BsbComponentValue>;
  formControlCommercialSupplierInternationalBankName!: FormControl<string | null>;
  formControlCommercialSupplierInternationalBankSwiftCode!: FormControl<string | null>;
  formControlCommercialSupplierInternationalBankIban!: FormControl<string | null>;
  formControlCommercialSupplierInternationaBanklAddress!: FormControl<AddressComponentValue | null>;

  // private supplier
  formControlPrivateSupplierMobileNumber!: FormControl<MobileValue>;
  formControlPrivateSupplierPhoneNumber!: FormControl<MobileValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlBankDetails = formBuilder.control(null, [Validators.required]);
    this.formControlSupplierType = formBuilder.control('commercial', [Validators.required]);
    this.formControlSupplierName = formBuilder.control(null, [Validators.required]);
    this.formControlSupplierEmail = formBuilder.control(null, [Validators.required]);
    this.formControlSupplierAddress = formBuilder.control(null, [Validators.required]);

    this.formControlCommercialSupplierBusinessNumber = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierCurrencyCode = formBuilder.control('AUD', [Validators.required]);
    this.formControlCommercialSupplierBankAccountType = formBuilder.control('domestic');
    this.formControlCommercialSupplierBankAccountName = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierBankAccountNumber = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierDomesticBankAccountBsb = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierInternationalBankName = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierInternationalBankSwiftCode = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierInternationalBankIban = formBuilder.control(null, [Validators.required]);
    this.formControlCommercialSupplierInternationaBanklAddress = formBuilder.control(null, [Validators.required]);

    this.formControlPrivateSupplierMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlPrivateSupplierPhoneNumber = formBuilder.control(null);

    this.formGroup = formBuilder.group({
      type: this.formControlSupplierType,
      supplierName: this.formControlSupplierName,
      supplierAddress: this.formControlSupplierAddress,
      supplierEmail: this.formControlSupplierEmail,
      bankDetails: this.formControlBankDetails
    });

    this.swapToCommercialSupplier();
  }

  doWriteValue(v: NewSupplierValue | null | undefined): void | NewSupplierValue {
    if (v) {
      switch(v.type) {
        case 'commercial': {
          this.formControlSupplierType.setValue('commercial');
          this.formControlSupplierAddress.setValue(v.supplierAddress);
          this.formControlSupplierName.setValue(v.supplierName);
          this.formControlSupplierEmail.setValue(v.supplierEmail);
          this.formControlCommercialSupplierBusinessNumber.setValue(v.businessNumber);
          this.formControlCommercialSupplierCurrencyCode.setValue(v.currencyCode);
          switch(v.bankAccount.type) {
            case 'domestic': {
              this.formControlCommercialSupplierBankAccountType.setValue('domestic');
              console.log("v.bankAccount-----------------",v.bankAccount)
              this.formControlBankDetails.setValue(v.bankAccount)
              // this.formControlCommercialSupplierBankAccountName.setValue(v.bankAccount.accountName);
              // this.formControlCommercialSupplierBankAccountNumber.setValue(v.bankAccount.accountNumber);
              // this.formControlCommercialSupplierDomesticBankAccountBsb.setValue(v.bankAccount.accountBsb);
              break;
            }
            case 'international': {
              this.formControlCommercialSupplierBankAccountType.setValue('international');
              this.formControlCommercialSupplierInternationalBankName.setValue(v.bankAccount.bankName);
              this.formControlCommercialSupplierInternationalBankSwiftCode.setValue(v.bankAccount.swiftCode);
              this.formControlCommercialSupplierInternationalBankIban.setValue(v.bankAccount.iban);
              this.formControlCommercialSupplierInternationaBanklAddress.setValue(v.bankAccount.address);
              break;
            }
          }
          this.swapToCommercialSupplier();
          break;
        }
        case 'private': {
          this.formControlSupplierType.setValue('private');
          this.formControlSupplierAddress.setValue(v.supplierAddress);
          this.formControlSupplierName.setValue(v.supplierName);
          this.formControlPrivateSupplierMobileNumber.setValue(v.mobileNumber);
          this.formControlPrivateSupplierPhoneNumber.setValue(v.phoneNumber);
          this.swapToPrivateSupplier();
          break;
        }
      }
      return v;
    }
    return undefined;
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
    if (isDisabled) {
      this.formControlSupplierType.disable();
      this.formControlSupplierName.disable();
      this.formControlSupplierEmail.disable();
      this.formControlSupplierAddress.disable();
      this.formControlCommercialSupplierBusinessNumber.disable();
      this.formControlCommercialSupplierCurrencyCode.disable();
      this.formControlCommercialSupplierBankAccountType.disable();
      // this.formControlCommercialSupplierBankAccountName.disable();
      // this.formControlCommercialSupplierBankAccountNumber.disable();
      // this.formControlCommercialSupplierDomesticBankAccountBsb.disable();
      this.formControlCommercialSupplierInternationalBankName.disable();
      this.formControlCommercialSupplierInternationalBankSwiftCode.disable();
      this.formControlCommercialSupplierInternationalBankIban.disable();
      this.formControlCommercialSupplierInternationaBanklAddress.disable();
      this.formControlPrivateSupplierMobileNumber.disable();
      this.formControlPrivateSupplierPhoneNumber.disable();
    } else {
      this.formControlSupplierType.enable();
      this.formControlSupplierName.enable();
      this.formControlSupplierEmail.enable();
      this.formControlSupplierAddress.enable();
      this.formControlCommercialSupplierBusinessNumber.enable();
      this.formControlCommercialSupplierCurrencyCode.enable();
      this.formControlCommercialSupplierBankAccountType.enable();
      this.formControlCommercialSupplierBankAccountName.enable();
      this.formControlCommercialSupplierBankAccountNumber.enable();
      this.formControlCommercialSupplierDomesticBankAccountBsb.enable();
      this.formControlCommercialSupplierInternationalBankName.enable();
      this.formControlCommercialSupplierInternationalBankSwiftCode.enable();
      this.formControlCommercialSupplierInternationalBankIban.enable();
      this.formControlCommercialSupplierInternationaBanklAddress.enable();
      this.formControlPrivateSupplierMobileNumber.enable();
      this.formControlPrivateSupplierPhoneNumber.enable();
    }
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.bankTypeRadioOptions = this.internationBank ? INTERNATIONAL_BANK_RADIO_OPTIONS : DOMESTIC_BANK_RADIO_OPTIONS;
    this.subscriptions.push(this.formControlSupplierType.valueChanges.pipe(
      delay(0),
      tap((r: 'commercial' | 'private' | null) => {
        if (r === 'commercial') {
          this.swapToCommercialSupplier();
        } else if (r === 'private') {
          this.swapToPrivateSupplier();
        }
      })
    ).subscribe());
    this.subscriptions.push(this.formControlCommercialSupplierBankAccountType.valueChanges.pipe(
      delay(0),
      tap((r : 'international' | 'domestic' | null) => {
        if (r === 'international') {
          this.swapToCommercialSupplierInternationalBank();
        } else {
          this.swapToCommercialSupplierDomesticBank();
        }
      })
    ).subscribe());
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          let b = null;
          let bankAccount = this.formControlBankDetails.value;
          if (this.formControlSupplierType.value === 'commercial') {
            // commerical
            const c: NewCommercialSupplierValue = {
              type: 'commercial',
              supplierName: this.formControlSupplierName.value!,
              supplierEmail: this.formControlSupplierEmail.value!,
              businessNumber: this.formControlCommercialSupplierBusinessNumber.value!,
              currencyCode: this.formControlCommercialSupplierCurrencyCode.value!,
              supplierAddress: this.formControlSupplierAddress.value,
              bankAccount: this.formControlCommercialSupplierBankAccountType.value === 'international' ? {
                type: 'international',
                AccountName: this.formControlCommercialSupplierBankAccountName.value!,
                AccountNumber: this.formControlCommercialSupplierBankAccountNumber.value!,
                iban: this.formControlCommercialSupplierInternationalBankIban.value!,
                bankName: this.formControlCommercialSupplierInternationalBankName.value!,
                swiftCode: this.formControlCommercialSupplierInternationalBankSwiftCode.value!,
                address: this.formControlCommercialSupplierInternationaBanklAddress.value!,
              } : {
                type: 'domestic',
                AccountName: bankAccount.AccountName!,
                AccountNumber: bankAccount.AccountNumber!,
                BSB: bankAccount.BSB!,
                InstitutionName: 'test'
              }
            }
            console.log('the new Supplier Value',c)
            this.propagateChange(c);
          }
          if (this.formControlSupplierType.value === 'private') {
            // private
            const c: NewPrivateSupplierValue = {
              type: 'private',
              supplierName: this.formControlSupplierName.value!,
              supplierAddress: this.formControlSupplierAddress.value,
              mobileNumber: this.formControlPrivateSupplierMobileNumber.value!,
              phoneNumber: this.formControlPrivateSupplierPhoneNumber.value!,
            }
            this.propagateChange(c);
          }
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  swapToCommercialSupplier() {
    // this.formGroup.removeControl('mobileNumber');
    // this.formGroup.removeControl('phoneNumber');
    (<FormGroup>this.formGroup).removeControl('mobileNumber');
    (<FormGroup>this.formGroup).removeControl('phoneNumber');

    // this.formGroup.addControl('businessNumber', this.formControlCommercialSupplierBusinessNumber);
    // this.formGroup.addControl('currencyCode', this.formControlCommercialSupplierCurrencyCode);
    // this.formGroup.addControl('bankAccountType', this.formControlCommercialSupplierBankAccountType);
    // this.formGroup.addControl('bankAccountName', this.formControlCommercialSupplierBankAccountName);
    // this.formGroup.addControl('bankAccountNumber', this.formControlCommercialSupplierBankAccountNumber);

    (<FormGroup>this.formGroup).addControl('businessNumber', this.formControlCommercialSupplierBusinessNumber);
    (<FormGroup>this.formGroup).addControl('currencyCode', this.formControlCommercialSupplierCurrencyCode);
    (<FormGroup>this.formGroup).addControl('bankAccountType', this.formControlCommercialSupplierBankAccountType);
    // (<FormGroup>this.formGroup).addControl('bankAccountName', this.formControlCommercialSupplierBankAccountName);
    // (<FormGroup>this.formGroup).addControl('bankAccountNumber', this.formControlCommercialSupplierBankAccountNumber);

    const accountType = this.formControlCommercialSupplierBankAccountType.value;
    if (accountType === 'international') {
      this.swapToCommercialSupplierInternationalBank();
    } else {
      this.swapToCommercialSupplierDomesticBank();
    }
  }

  swapToCommercialSupplierInternationalBank() {
    // this.formGroup.removeControl('bankBsb');
    (<FormGroup>this.formGroup).removeControl('bankBsb');


    // this.formGroup.addControl('bankName', this.formControlCommercialSupplierInternationalBankName);
    // this.formGroup.addControl('bankSwiftCode', this.formControlCommercialSupplierInternationalBankSwiftCode);
    // this.formGroup.addControl('bankIban', this.formControlCommercialSupplierInternationalBankIban);
    // this.formGroup.addControl('bankAddress', this.formControlCommercialSupplierInternationaBanklAddress);

    (<FormGroup>this.formGroup).addControl('bankName', this.formControlCommercialSupplierInternationalBankName);
    (<FormGroup>this.formGroup).addControl('bankSwiftCode', this.formControlCommercialSupplierInternationalBankSwiftCode);
    (<FormGroup>this.formGroup).addControl('bankIban', this.formControlCommercialSupplierInternationalBankIban);
    (<FormGroup>this.formGroup).addControl('bankAddress', this.formControlCommercialSupplierInternationaBanklAddress);


  }

  swapToCommercialSupplierDomesticBank() {
    // this.formGroup.removeControl('bankName');
    // this.formGroup.removeControl('bankSwiftCode');
    // this.formGroup.removeControl('bankIban');
    // this.formGroup.removeControl('bankAddress');
    (<FormGroup>this.formGroup).removeControl('bankName');
    (<FormGroup>this.formGroup).removeControl('bankSwiftCode');
    (<FormGroup>this.formGroup).removeControl('bankIban');
    (<FormGroup>this.formGroup).removeControl('bankAddress');

    // this.formGroup.addControl('bankBsb', this.formControlCommercialSupplierDomesticBankAccountBsb);
    (<FormGroup>this.formGroup).addControl('bankDetails', this.formControlBankDetails);

  }

  swapToPrivateSupplier() {
    // this.formGroup.removeControl('businessNumber');
    // this.formGroup.removeControl('currencyCode');
    // this.formGroup.removeControl('bankAccountType');
    // this.formGroup.removeControl('bankAccountName');
    // this.formGroup.removeControl('bankAccountNumber');
    // this.formGroup.removeControl('bankBsb');
    // this.formGroup.removeControl('bankName');
    // this.formGroup.removeControl('bankSwiftCode');
    // this.formGroup.removeControl('bankIban');
    // this.formGroup.removeControl('bankAddress');
    // this.formGroup.removeControl('supplierEmail');

    (<FormGroup>this.formGroup).removeControl('businessNumber');
    (<FormGroup>this.formGroup).removeControl('currencyCode');
    (<FormGroup>this.formGroup).removeControl('bankAccountType');
    (<FormGroup>this.formGroup).removeControl('bankAccountName');
    (<FormGroup>this.formGroup).removeControl('bankAccountNumber');
    (<FormGroup>this.formGroup).removeControl('bankBsb');
    (<FormGroup>this.formGroup).removeControl('bankName');
    (<FormGroup>this.formGroup).removeControl('bankSwiftCode');
    (<FormGroup>this.formGroup).removeControl('bankIban');
    (<FormGroup>this.formGroup).removeControl('bankAddress');
    (<FormGroup>this.formGroup).removeControl('supplierEmail');

    // this.formGroup.addControl('mobileNumber', this.formControlPrivateSupplierMobileNumber);
    // this.formGroup.addControl('phoneNumber', this.formControlPrivateSupplierPhoneNumber);

    (<FormGroup>this.formGroup).addControl('mobileNumber', this.formControlPrivateSupplierMobileNumber);
    (<FormGroup>this.formGroup).addControl('phoneNumber', this.formControlPrivateSupplierPhoneNumber);
  }



  mark(): void {
    this.markObservable.next(true);
    this.formGroup.markAllAsTouched();
  }
}
