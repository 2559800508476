import {Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  Application,
  constants,
  User,
  isInternalUser,
  isAdmin, GetAssetInspectionsForApplicationFn, VerimotoInspectionTableDataWithInspectionDetails, DownloadVerimotoReportFn, getTransactionType, VerimotoInspectionTypeSelection,
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subject, Subscription, combineLatest} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  ApplicationDialogService,
  getUser,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { loadingFor } from '@ngneat/loadoff';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { AssetPpsrDetailsComponent } from './asset-ppsr-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatMenuModule } from '@angular/material/menu';
import { PpsrStatusChipComponent } from '../ppsr-status-component/ppsr-status-chip.component';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { CellComponent } from '../application-summary-component/cell.component';
import { AssetInspectionStatusChipComponent } from '../asset-inspection-status-component/asset-inspection-status-chip.component';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';
import{CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";
import { Buffer } from 'buffer';

export class AssetInspectionsDataSource implements DataSource<VerimotoInspectionTableDataWithInspectionDetails> {

  subject: Subject<VerimotoInspectionTableDataWithInspectionDetails[]> = new BehaviorSubject<VerimotoInspectionTableDataWithInspectionDetails[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<VerimotoInspectionTableDataWithInspectionDetails[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: VerimotoInspectionTableDataWithInspectionDetails[]) {
    this.subject.next(data);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-inspections',
    templateUrl: './asset-inspections.component.html',
    styleUrls: ['./asset-inspections.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [CustomContentLoaderComponent, NgClass, ExtendedModule, AssetInspectionStatusChipComponent, CellComponent, EllipsisPipe, MatTableModule, DatePipe, MatFormFieldModule, MatTooltipModule, TagBoxComponent, PpsrStatusChipComponent, MatMenuModule, FlexModule, MatDividerModule, AssetPpsrDetailsComponent, AsyncPipe, LooseCurrencyPipe]
})
export class AssetInspectionsComponent implements OnInit, OnChanges {

  subscriptions: Subscription[] = [];
  dataSource: AssetInspectionsDataSource = new AssetInspectionsDataSource();
  displayedColumns: string[] = ['icon', 'id', 'seller', 'asset', 'state', 'inspectionType', 'status', 'lastUpdatedTime', 'actions'];
  @Input({required: true}) getAssetInspectionsForApplicationFn!: GetAssetInspectionsForApplicationFn;
  @Input({required: true}) downloadVerimotoReportFn!: DownloadVerimotoReportFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Input({required: false}) verimotoAssetInspectionTypes!: VerimotoInspectionTypeSelection[];

  expandedElement!: any | null;
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'

  loader = loadingFor('tableLoading');
  loggedInUser: User | null = getUser()
  isInternalUser = isInternalUser;
  isAdmin = isAdmin;

  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ){}

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.dataSource.update(this.assetInspectionTableData);
    
    // this.subscriptions.push(
    //   this.getAssetInspectionsForApplicationFn(this.application.ApplicationId).pipe(
    //       this.loader.tableLoading.track(),
    //     ).subscribe((data: VerimotoInspectionTableDataWithInspectionDetails[]) => {
    //       console.log('===asset inspections: ', data);
    //       this.dataSource.update(data);
    //     })
    // )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assetInspectionTableData']) {
      this.dataSource.update(this.assetInspectionTableData);
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'id': return 'ID';
      case 'icon': return '';
      case 'seller': return 'Seller';
      case 'asset': return 'Asset';
      case 'inspectionType': return 'Inspection Type';
      case 'status': return 'Status';
      case 'state': return 'State';
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'actions': return '';
      default: return column;
    }
  }

  getAssetName(assetGuid: string) {
    const assetObj = constants.verimotoAssets.find(obj => obj.guid === assetGuid);
    return assetObj?.name ?? assetGuid;
  }

  getInspectionType(inspectionTypeGuid: string) {
    const inspectionTypeObj = this.verimotoAssetInspectionTypes.find(obj => obj.guid === inspectionTypeGuid);
    const inspectionType = inspectionTypeObj?.inspectionType ?? inspectionTypeGuid;
    if (inspectionType === 'New Lending') {
      return 'Private Sale'
    } else if (inspectionType === 'Personal Loan') {
      return getTransactionType(this.application);
    } else {
      return inspectionType;
    }
  }

  downloadPdf(element: VerimotoInspectionTableDataWithInspectionDetails) {
    const encodedInspectionId = Buffer.from(element.VerimotoInspectionId.toString()).toString('base64');
    const url = this.apiUrl + "/download-verimoto-report/" +  encodedInspectionId;
    window.open(url);
  }
}



