<form class="balloon-payment-component" [formGroup]="formGroup">
  <mat-label>Balloon Payment(Residual)</mat-label>
  <mat-button-toggle-group
    [hideSingleSelectionIndicator]="true"
    [hideMultipleSelectionIndicator]="true"
    [formControl]="formControl"
    [vertical]="vertical"
    [disableControl]="disabled">
    @for (option of options; track option) {
      <mat-button-toggle [value]="option" [disabled]="option.disabled">{{option.name}}</mat-button-toggle>
    }
  </mat-button-toggle-group>
</form>
