import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  findInvalidControlsRecursive,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import { tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import {
  createEmailInputMask,
} from '@portal-workspace/grow-ui-library';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {compareMatch, EmailComponentValue} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

export interface EmailComponentEvent {
  errors: ValidationErrors | null;
   value: EmailComponentValue;
}


@UntilDestroy()
@Component({
    selector: 'email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmailComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => EmailComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective]
})
export class EmailComponent extends AbstractControlValueAccessor<EmailComponentValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formControl!: FormControl<EmailComponentValue>;
  createEmailInputMask = createEmailInputMask();
  formGroup!: FormGroup<{
    email: FormControl<EmailComponentValue>
  }>;
  subscription?: Subscription;

  @Input({required: false}) validators: ValidatorFn [] = [];
  @Input({required: false}) asyncValidators: AsyncValidatorFn[] | null = null;
  @Input({required: false}) title = "Email";
  @Input({required: false}) placeholder = "Enter your email...";
  @Input({required: false}) mandatory = true;

  @Output() events = new EventEmitter<EmailComponentEvent>();

  constructor(private formBuilder: FormBuilder) {
    super();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const vals = this.mandatory ? [...this.validators, Validators.email, Validators.required] : [...this.validators, Validators.email];
    const asyncVals = this.asyncValidators ? this.asyncValidators : [];
    this.formControl = this.formBuilder.control('', vals, asyncVals);
    this.formGroup = this.formBuilder.group({
      email: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v) => {
        this.events.next({errors: this.formControl.errors, value: v});
        if (this.formControl.valid  || this.formControl.disabled) {
          this.propagateChange(v);
        } else {
          // NOTE: returning v cause asyncValidators doesn't seems to trigger formControl.valid when it is valid
          //       Until we can figure out why, we might have to keep this
          if (asyncVals.length) {
            this.propagateChange(v);
          } else {
            this.propagateChange(null);
          }
        }
      })
    ).subscribe();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  doWriteValue(v: EmailComponentValue) {
    this.formControl.setValue(v);
  }

  mark() {
    this.formControl.markAllAsTouched();
  }

}
