import {AfterViewInit, Component} from '@angular/core';
import { environment } from '../../../environments/environment';
import {LayoutService} from '../../service/layout.service';
import { RouterOutlet } from '@angular/router';
import { ErrorNotification2, ToolbarComponentEvent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { DirectApplicationTopMenuComponent } from '@portal-workspace/grow-ui-library';


@Component({
    templateUrl: './direct-application.layout.html',
    styleUrls: ['./direct-application.layout.scss'],
    providers: [
        { provide: LayoutService, useClass: LayoutService },
    ],
    standalone: true,
    imports: [FlexModule, ErrorNotification2, RouterOutlet, DirectApplicationTopMenuComponent]
})
export class DirectApplicationLayout {
  gitTagOrVersion = environment.gitTag ?? environment.gitHash;

  onHelpClicked($event: MouseEvent) {
    window.open(environment.helpCenterUrl, '_blank');
  }
}
