import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { SettleLoanDialogData, SettleLoanDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './settle-loan.dialog.html',
    styleUrls: ['./settle-loan.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class SettleLoanDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: SettleLoanDialogData,
              private dialogRef: MatDialogRef<SettleLoanDialog, SettleLoanDialogResult>) {
  }

  onSubmit($event: MouseEvent) {
    const result: SettleLoanDialogResult = {}
    this.dialogRef.close(result);
  }

  onCancel($event: MouseEvent){
    this.dialogRef.close()
  }
}

