
export type CustomerApplicantTypeValue = {
  type: 'Yes' | 'No',
  name: string;
} | null;

export const CustomerApplicantTypeOptions: Exclude<CustomerApplicantTypeValue, null>[] = [
  { type: 'Yes', name: 'Yes' },
  { type: 'No', name: 'No' },
];
