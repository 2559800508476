import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {Application, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import { Buffer } from 'buffer';

@Injectable()
export class DirectSaleApplicationResolver  {

  constructor(private applicationService: ApplicationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Application | null> | Promise<Application | null> | Application | null {
    const encodedApplicationId = route.params.encodedApplicationId;
    const code = Buffer.from(encodedApplicationId, 'base64').toString('binary');
    const codeSplits = code.split('/');
    if (codeSplits.length == 4 && !isNaN(Number(codeSplits[3]))) {
      const applicationId = Number(codeSplits[3]);
      const firstName = codeSplits[0];
      const lastName = codeSplits[1];
      const email = codeSplits[2];
      const token = Buffer.from(`${applicationId}=${firstName}=${lastName}=${email}`).toString('base64');
      return this.applicationService.getApplicationByIdForDirectSale(applicationId, token).pipe(
        map(r => {
          console.log('************ RESOLVE ', applicationId, r.payload);
          return r.payload;
        }),
        catchError((err) => {
          console.log(`error resolving application with id ${encodedApplicationId}`, err);
          return of(null);
        })
      );
    }
    return null;
  }

}
