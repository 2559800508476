<div class="overdrawn-table">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows [ngClass]="{'width-100': moreWidth, 'width-33': !moreWidth}" class="mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef
          [ngClass]="{'width-1': moreWidth && (column === 'totaldebit'), 'width-2': moreWidth && (column !== 'totaldebit')}"
          class="text-align-right">
          <div class="cell">{{getColumnTitles(column)}} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
          <div class="cell">
            @if (needCurrencyPipe(column)) {
              {{element[column] | looseCurrency}}
            }
            @if (!needCurrencyPipe(column)) {
              {{element[column]}}
            }
          </div>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
    </tr>
  </table>
</div>

