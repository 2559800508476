import {Component, inject, OnInit} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {MatTableModule} from "@angular/material/table";
import {MatCardModule} from "@angular/material/card";
import {BankfeedsService} from "../../service/bankfeeds.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  ApplicationDialogService, LooseDatePipe, MessageBoxComponent,
  PortalHotToastService,
  setupUntilDestroy,
  CustomContentLoaderComponent
} from "@portal-workspace/grow-ui-library";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {InstitutionStatus} from "@portal-workspace/grow-shared-library";
import {loadingFor} from "@ngneat/loadoff";
 
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FlexModule} from "@angular/flex-layout/flex";
import {MinimalLayoutService} from "../../layout/minimal-layout/minimal-layout.service";
import moment from 'moment';
import {MatSortModule, Sort} from "@angular/material/sort";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import _ from 'lodash';
import { ActivatedRoute } from "@angular/router";
import { NgClass } from "@angular/common";

export class BankfeedsInstituationDatasource implements DataSource<InstitutionStatus> {

    subject = new BehaviorSubject<InstitutionStatus[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<readonly InstitutionStatus[]> {
      return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.subject.complete();
    }

    update(institutions: InstitutionStatus[]) {
      this.subject.next(institutions);
    }
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './bankfeeds-institutions.page.html',
  styleUrls: ['./bankfeeds-institutions.page.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0', display: 'none', visibility: 'hidden'})),
      state('expanded', style({height: '*', display: 'block', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  imports: [
    CommonModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatCardModule,
    MatSortModule,
    AsyncPipe,
    JsonPipe,
    CustomContentLoaderComponent,
    LooseDatePipe,
    MessageBoxComponent,
    MatInputModule,
    NgClass,
  ],
  providers: [
    MinimalLayoutService
  ]
})
export class BankfeedsInstitutionsPage implements OnInit {

    now = moment();

    columnsToDisplay = ['expand', 'name', 'type', 'connectionStatus'];

    loader = loadingFor(`institutions`);

    subscriptions: Subscription[] = [];

    institutions: InstitutionStatus[] = [];

    datasource: BankfeedsInstituationDatasource = new BankfeedsInstituationDatasource();

    expandedElement: InstitutionStatus | null = null;

    bankfeedsService: BankfeedsService = inject(BankfeedsService);
    portalHotToastService = inject(PortalHotToastService);
    dialogService = inject(ApplicationDialogService);
    minimalLayoutService = inject(MinimalLayoutService);
    formBuilder = inject(FormBuilder);
    formControlSearch!: FormControl<string | null>;
    fullWidth: boolean = false;

    constructor(private route: ActivatedRoute) {
      this.fullWidth = !!route?.snapshot?.data?.fullWidth;
    }

    ngOnInit(): void {
      setupUntilDestroy(this);
      this.minimalLayoutService.settings({
        showAccreditationInContact: false,
        showContact: true,
        showCross: false,
        showPrev: false
      });
      this.formControlSearch = this.formBuilder.control(null);
      this.subscriptions.push(this.bankfeedsService.queryBankfeedsInstitutions()
        .pipe(
          this.loader.institutions.track(),
          tap(r => {
            if (r.status) {
              this.institutions = r.payload;
              this.datasource.update(this.institutions);
            } else {
              this.dialogService.openAlertDialog({
                message: `Error`,
                subMessage: `Unable to load bankfeeds institutions`,
              });
            }
          })
        ).subscribe());
      this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap(r => {
          this.onFilter(r);
        })
      ).subscribe());
    }

    isTableRowExpanded(element: InstitutionStatus) {
      return element === this.expandedElement;
    }

    onTableRowClicked(element: InstitutionStatus) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }

    onFilter(search: string | null) {
      const _search = (search ?? '').trim().toLowerCase();
      if (_search) {
        const filteredInstitutions = this.institutions.filter(i => (i.name ?? '').toLowerCase().indexOf(_search) >= 0)
        this.datasource.update(filteredInstitutions);
      } else {
        this.datasource.update(this.institutions);
      }
    }

    onSort($event: Sort) {
      console.log('**** sort', $event);
      switch($event.active) {
        case 'name': {
          if ($event.direction) {
            const sortedInstitutions = _.orderBy(
              this.institutions,
              [i => (i?.name ?? '').toLowerCase()],
              [$event.direction == 'asc' ? 'asc' : 'desc' ]);
            this.datasource.update(sortedInstitutions);
          } else {
            this.datasource.update(this.institutions);
          }
          break;
        }
        case 'connectionStatus': {
          if ($event.direction) {
            const sortedInstitutions = _.orderBy(
              this.institutions,
              [i => (i?.connectionStatus ?? '').toLowerCase()],
              [$event.direction == 'asc' ? 'asc' : 'desc' ]);
            this.datasource.update(sortedInstitutions);
          } else {
            this.datasource.update(this.institutions);
          }
          break;
        }
      }
    }
}
