import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacilityEstablishmentComponent } from '@portal-workspace/grow-ui-library';
import {
  FacilityEstablishmentValue,
  LoanTermType,
  LoanTermValueOptions,
  OverdraftPaymentBreakupDialogData,
  PaymentFrequencyValue,
  PaymentFrequencyValueOptions,
  Quote,
  SliderComponentValue, YesNoValue, calculateBusinessOverdraftEstimation,
} from '@portal-workspace/grow-shared-library';
import { Subscription } from 'rxjs';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import {
  doMarkAll,
  markTriggerSubject
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../service/application.service';
import { RatecardDetails, } from '@portal-workspace/grow-shared-library';
import { LoanTermValue } from '@portal-workspace/grow-shared-library';
import { navigationUrlForApplications } from '../../service/navigation-urls';
import { MatButtonModule } from '@angular/material/button';
import { BrokerageSelectionComponent } from '@portal-workspace/grow-ui-library';

import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import moment from 'moment';
import { DisableControlDirective } from '@portal-workspace/grow-ui-library';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './business-overdraft-calculator.page.html',
  styleUrls: ['business-overdraft-calculator.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MarkDirective,
    MatButtonModule,
    YesNoComponent,
    BrokerageSelectionComponent,
    PaymentFrequencySelectionComponent,
    CurrencyInputComponent,
    FacilityEstablishmentComponent,
    DisableControlDirective
],
})

export class BusinessOverdraftCalculatorPage implements OnInit {

  maxLimit: number = 500000;
  brokerageDollar?: number;
  isDisable = true

  subscriptions: Subscription[] = [];

  markTriggerSubject = markTriggerSubject;

  formGroupCalculator!: FormGroup<{
    loanAmount: FormControl<SliderComponentValue>,
    facilityEstablishmentFeePercent: FormControl<FacilityEstablishmentValue>,
    loanTerms: FormControl<LoanTermValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScore: FormControl<YesNoValue>,
    directorScore: FormControl<YesNoValue>,
    previousLoan: FormControl<YesNoValue>,
  }>;

  formControlLoanTerms!: FormControl<LoanTermValue>;
  formControlPaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlPropertyOwner!: FormControl<YesNoValue>;
  formControlAdverseOnFile!: FormControl<YesNoValue>;
  formControlFacilityEstablishmentFeePercent!: FormControl<FacilityEstablishmentValue>;
  formControlEquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlLoanAmount!: FormControl<SliderComponentValue>;
  formControlDirectorScore!: FormControl<YesNoValue>;
  formControlPreviousLoan!: FormControl<YesNoValue>;

  rateCard!: RatecardDetails;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
    private activatedRoute: ActivatedRoute,
    private appService: ApplicationService,
    private router: Router) {

    this.initCalculator();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.rateCard = (this.activatedRoute.snapshot.data as any).ratecard;

    // update max limit
    this.subscriptions.push(this.formControlPropertyOwner.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
      })
    ).subscribe());

    this.subscriptions.push(this.formControlEquifaxScoreAboveThreshold.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
        if(r){
          this.formControlDirectorScore.setValue(false)
        }else{
          this.formControlDirectorScore.setValue(true)
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlDirectorScore.valueChanges.pipe(
      tap(r => {
        this.updateMaxLimit();
      })
    ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlPropertyOwner.valueChanges.pipe(
      tap(r => {
        this.updatePreviousLoanValidation();
      })
    ).subscribe())

  }

  private updateMaxLimit() {
    const isPropertyOwner = this.formControlPropertyOwner.value;
    if (isPropertyOwner) {
      this.maxLimit = 500000;
    } else {
      this.maxLimit = 100000;
    }
  }

  updatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlPropertyOwner.value;
    if (propertyOwner) {
      this.formControlPreviousLoan.clearValidators();
    } else {
      this.formControlPreviousLoan.setValidators([Validators.required]);
    }
    this.formControlPreviousLoan.updateValueAndValidity();
  }

  initCalculator() {

    this.formControlLoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlFacilityEstablishmentFeePercent = this.formBuilder.control(null, [Validators.required]);
    this.formControlLoanTerms = this.formBuilder.control(LoanTermValueOptions[2], [Validators.required]);
    this.formControlPaymentFrequency = this.formBuilder.control(PaymentFrequencyValueOptions[2], [Validators.required]);
    this.formControlPropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlAdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlEquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlDirectorScore = this.formBuilder.control(true, [Validators.required])
    this.formControlPreviousLoan = this.formBuilder.control(null);

    this.formGroupCalculator = this.formBuilder.group({
      loanAmount: this.formControlLoanAmount,
      facilityEstablishmentFeePercent: this.formControlFacilityEstablishmentFeePercent,
      loanTerms: this.formControlLoanTerms,
      paymentFrequency: this.formControlPaymentFrequency,
      propertyOwner: this.formControlPropertyOwner,
      adverseOnFile: this.formControlAdverseOnFile,
      equifaxScore: this.formControlEquifaxScoreAboveThreshold,
      directorScore: this.formControlDirectorScore,
      previousLoan: this.formControlPreviousLoan,
    });
  }

  onCalculateEstimation(event: Event) {
    markTriggerSubject(this.formGroupCalculator).next(true);
    doMarkAll(this.formGroupCalculator);
    if (this.formGroupCalculator.invalid) {
      this.applicationDialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields across tabs are filled in.',
      });
    } else {
      this.subscriptions.push(this.applicationDialogService.openBusinessOverdraftQuotationDialog({
        loadFn: () => {
          return this.calculateEstimation();
        },
        isQuote: true,
        hideBrokerage: false,
      }).afterClosed()
        .pipe(
          tap(r => {
            if (r && r.acceptedQuotation) {
              const quoteData = this.saveQuoteData();
              this.appService.saveQuote(quoteData).subscribe(async res => {
                if (res.status) {
                  // const quoteId = res.payload.quoteId;
                  // this.toastService.quickInfoToast(`Quote saved (Id: ${quoteId})`);
                  await this.router.navigate(navigationUrlForApplications())
                } else {
                  this.applicationDialogService.openAlertDialog({
                    message: `Error`,
                    // subMessage: " Couldn\'t save quote. Try again later."
                    subMessage: "Something is wrong. Try again."
                  });
                }
              })
            }
          })
        ).subscribe());
    }
  }

  private saveQuoteData(): Quote {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const loanCalValues = this.formGroupCalculator.getRawValue();
    // const brokerageValue: BrokerageSelectionValue = this.formControlBrokerage.value;
    const loanAmount = this.formControlLoanAmount.value!;
    const quoteValue: Quote = {
      LoanAmount: (loanCalValues.loanAmount ?? '0').toString(),
      RepaymentFrequency: loanCalValues.paymentFrequency?.type!,
      LoanTerm: parseInt(loanCalValues.loanTerms?.type ?? '0'),
      FacilityEstablishmentPercentage: parseFloat(loanCalValues.facilityEstablishmentFeePercent?.type ?? '0'),
      BrokerageAmount: this.brokerageDollar ?? 0,
      QuoteAsJson: encodeURI(JSON.stringify(loanCalValues)),
    };
    return quoteValue
  }

  private calculateEstimation(): OverdraftPaymentBreakupDialogData {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const date = moment().add(1, 'day');
    const loanTermValue: LoanTermValue = this.formControlLoanTerms.value;
    const loanTermType: LoanTermType | null = loanTermValue?.type ?? null;
    // const loanAmount = 0;
    const propertyOwner = this.formControlPropertyOwner.value ?? false;
    const adverseOnFile = this.formControlAdverseOnFile.value ?? false;
    const lowEquifaxScore = this.formControlEquifaxScoreAboveThreshold.value ?? false;
    const directorScore = this.formControlDirectorScore.value ?? false;
    const facilityLimit = this.formControlLoanAmount.value ?? 0;
    const facilityEstablishmentFeePercent = this.formControlFacilityEstablishmentFeePercent.value ? Number(this.formControlFacilityEstablishmentFeePercent.value.type) : 0;

    const r = calculateBusinessOverdraftEstimation(date, {
      type: 'BusinessOverdraft',
      loanTermType,
      // loanAmount,
      propertyOwner,
      adverseOnFile,
      lowEquifaxScore,
      directorScore,
      rateCard: this.rateCard,
      facilityLimit,
      facilityEstablishmentFeePercent,
    });

    this.brokerageDollar = r.term.brokerageDollar;
    return {
      term: r.term,
    };
  }

}
