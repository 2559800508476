import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {AdminPrivacyConsent, Application, PrivacyConsent, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import { Buffer } from 'buffer';

@Injectable()
export class PrivacyConsentResolver  {

  constructor(private applicationService: ApplicationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PrivacyConsent | AdminPrivacyConsent | null> | Promise<PrivacyConsent | AdminPrivacyConsent | null> | PrivacyConsent | AdminPrivacyConsent | null {
    const token = route.params.token;
    const decryptedToken = Buffer.from(token, 'base64').toString('binary');
    const codeSplits = decryptedToken.split('/');
    if (codeSplits.length == 2 && !isNaN(Number(codeSplits[1]))) {
      if (codeSplits[0] === 'admin') {
        const privacyConsentId = Number(codeSplits[1]);
        return this.applicationService.getAdminPrivacyConsentFn(privacyConsentId).pipe(
          map(r => {
            return r;
          }),
          catchError((err) => {
            console.log(`error resolving privacy consent with id ${privacyConsentId}`, err);
            return of(null);
          })
        )
      } else {
        const individualId = codeSplits[0];
        const privacyConsentId = Number(codeSplits[1]);
        return this.applicationService.getPrivacyConsentById(privacyConsentId).pipe(
          map(r => {
            if (r?.individualId === individualId) {
              console.log('************ RESOLVE privacy consent', privacyConsentId, r);
              return r;
            } else {
              return null;
            }
          }),
          catchError((err) => {
            console.log(`error resolving privacy consent with id ${privacyConsentId}`, err);
            return of(null);
          })
        );
      }
    }
    return null;
  }

}
