import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {BankTransferDialogData, BankTransferDialogResult, LocalBankAccountDetailValue, NewDirectPaymentBody} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './bank-transfer.dialog.html',
    styleUrls: ['./bank-transfer.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class BankTransferDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    bankTransfer: FormControl<LocalBankAccountDetailValue | null>,
    reference: FormControl<string | null>,
    amount: FormControl<number | null>,
  }>;
  formControlBankTransfer!: FormControl<LocalBankAccountDetailValue | null>;
  formControlReference!: FormControl<string | null>;
  formControlAmount!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: BankTransferDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<BankTransferDialog, BankTransferDialogResult>) {
    this.formControlBankTransfer = formBuilder.control(dialogData ? {
      InstitutionName: '',
      AccountName: dialogData.data.accountTitle,
      BSB: dialogData.data.bsb,
      AccountNumber: dialogData.data.accountNumber,
    } : null, [Validators.required]);
    this.formControlReference = formBuilder.control(dialogData ? dialogData.data.reference : null, [Validators.required, Validators.maxLength(25)]);
    this.formControlAmount = formBuilder.control(dialogData ? dialogData.data.amount : null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      bankTransfer : this.formControlBankTransfer,
      reference: this.formControlReference,
      amount: this.formControlAmount
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  get dialogResult(): NewDirectPaymentBody {
    return {
      type: 'direct-credit',
      date: moment(Date.now()).format('DDMMYYYY'),
      bsb: this.formControlBankTransfer.value?.BSB ?? '',
      accountNumber: this.formControlBankTransfer.value?.AccountNumber ?? '',
      amount: this.formControlAmount.value ?? 0,
      accountTitle: this.formControlBankTransfer.value?.AccountName ?? '',
      reference: `${this.formControlReference.value ?? ''}-${this.dialogData.data.id}`,
      userId: this.dialogData.userId,
      customerId: this.dialogData.data.PismoCustomerId,
      PismoAccountId: this.dialogData.data.PismoAccountId,
      PismoCustomerId: this.dialogData.data.PismoCustomerId,
    }
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        result: this.dialogResult
      })
    }

  }

  cancel() {
    this.dialogRef.close({
      readyForSubmission: false,
      result: this.dialogResult
    });
  }
}
