<div class="extend-terms-modal">
  <mat-dialog-content>
    <form class="modal-content" [formGroup]="formGroup">
      <h3 class="header">Extend terms request</h3>
      <div class="vehicle">
        <h4>{{title}}</h4>
        <span class="description">{{description}}</span><br/>
        <span class="description">{{vin}}</span>
        <br><br/>
        <span class="description">{{currentTerm}} days</span>
      </div>
      <div class="extend-terms">
        <div class="title">Extend to</div>
        <div class="terms">
          <mat-radio-group [formControl]="formControlTerm">
            @for (term of terms; track term) {
              <div class="term">
                <div>{{term}} Days</div>
                <mat-radio-button color="primary" [disabled]="term == currentTerm" [checked]="true" [value]="term"></mat-radio-button>
              </div>
            }
          </mat-radio-group>
        </div>
      </div>
      <div class="footer">
        <button color="primary" fxFlexAlign="start" mat-stroked-button (click)="onCancel()">Cancel</button>
        <button color="primary" fxFlexAlign="end" type="submit" mat-flat-button [disabled]="formGroup.invalid"
        (click)="onSubmit()">Submit</button>
      </div>
    </form>
  </mat-dialog-content>
</div>
