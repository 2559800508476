export type IncomePeriodType = 'Monthly' | 'Weekly' | 'Fortnightly';

export const IncomePeriodOptions: IncomeSelectionPeriod[] = [
  {type: 'Weekly', name: 'Week'},
  {type: 'Monthly', name: 'Month'},
  {type: 'Fortnightly', name: 'Fortnight'},
];

export type IncomeSelectionPeriod = {
  type: IncomePeriodType,
  name: string,
}

export type IncomeSelectionValue = {
  income: number,
  period: IncomeSelectionPeriod
} | null;
