<div class="audit-logs-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Audit logs</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="header w-100"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
        fxLayoutGap.lt-md="0"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <div class="header w-100"
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
          fxLayoutGap.lt-md="0"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <div class="select w-lt-md-100"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <div class="label">Context</div>
            <mat-form-field class="w-lt-md-100">
              <mat-select [formControl]="formControlFilter">
                @for (type of contextTypes; track type) {
                  <mat-option [value]="type">{{type}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="select w-lt-md-100"
            fxFlexAlign="end"
            fxFlexAlign.lt-md="start"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <div class="label">Sub Context</div>
            <mat-form-field class="w-lt-md-100">
              <mat-select [formControl]="formControlSubContextFilter">
                @for (type of subContextTypes; track type) {
                  <mat-option [value]="type">{{type}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field class="search">
        <input matInput [readonly]="loader.tableLoading.inProgress$ | async" type="text" [formControl]="formControlSearch"
          placeholder="Search">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width">{{element.user?.name ?? ''}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width">{{element.user?.email ?? ''}}</td>
            </ng-container>
            <ng-container matColumnDef="context">
              <th mat-header-cell *matHeaderCellDef>Context</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width">{{element.context ?? ''}}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
              <th mat-header-cell *matHeaderCellDef class="td-width">
                Creation date
                <!-- <div class="mat-caption">dd/mm/yyyy h:mm:a</div> -->
              </th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width">
                {{element.creationDate | date : 'dd/MM/yyyy h:mm a' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef>Message</th>
              <td mat-cell *matCellDef="let element">
                <div class="word-wrap">{{element.message}}</div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>

