<div class="custom-document-tag-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        @if (!isNonStandardCondition) {
          <div class="mat-h4-custom">Add custom tag</div>
        }
        @if (isNonStandardCondition) {
          <div class="mat-h4-custom">Add non-standard condition</div>
        }
      </div>
    </div>

    <div class="row tag-name">
      <div class="col">
        <mat-form-field hintLabel="* A valid tag name should only contain letters, numbers, and underscores. Must start with an underscore or a letter.">
          <mat-label>Tag Name</mat-label>
          <input type="text" matInput [formControl]="formControlTagName">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput rows="5" wrap="hard" [formControl]="formControlDescription"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit()">Save Tag</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
