import {Component, inject} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageBoxComponent} from "@portal-workspace/grow-ui-library";

import {MinimalLayoutService} from "../../layout/minimal-layout/minimal-layout.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {navigationUrlForLogin} from "../../service/navigation-urls";
import {AuthService} from "../../service/auth.service";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  standalone: true,
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss'],
  imports: [
    MessageBoxComponent
]
})
export class ErrorPage {

  subscriptions: Subscription[] = [];

  activatedRoute = inject(ActivatedRoute);

  constructor() {
    const mi = inject(MinimalLayoutService);
    const authService = inject(AuthService);
    const router = inject(Router);
    mi.settings({
      showAccreditationInContact: false,
      showContact: false,
      showCross: true,
      showPrev: false,
    });

    this.subscriptions.push(mi.headerEventChanges()
      .pipe(
        tap(e => {
          if (e.type == 'cross-clicked') {
            this.subscriptions.push(authService.logout().pipe(
              tap(async r => {
                await router.navigate(navigationUrlForLogin());
              })
            ).subscribe());
          }
        })
      ).subscribe())
  }


}
