import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DscrIndustrySelectionComponent} from './dscr-industry-selection.component';
import {DscrIndustryEbitdaSelectionComponent} from './dscr-industry-ebitda-selection.component';
import {DscrIndustrySelectionModuleService} from './dscr-industry-selection-module.service';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        DscrIndustrySelectionComponent,
        DscrIndustryEbitdaSelectionComponent
    ],
    exports: [
        DscrIndustrySelectionComponent,
        DscrIndustryEbitdaSelectionComponent,
    ],
    providers: [
        { provide: DscrIndustrySelectionModuleService, useClass: DscrIndustrySelectionModuleService },
    ]
})
export class DscrIndustrySelectionModule {

}
