<ng-template #content><ng-content></ng-content></ng-template>

<div
  class="message-box-2 {{ type }}"
  fxLayout="row"
  fxLayoutAlign="center center"
  >
  <div class="icon">
    @if (type === 'details') {
      <span
        fxFlex="0 0 auto"
        class="mdi mdi-account-circle-outline"
      ></span>
    }
    @if (type === 'info') {
      <span
        fxFlex="0 0 auto"
        class="mdi mdi-information-outline"
      ></span>
    }
    @if (type === 'danger') {
      <span
        fxFlex="0 0 auto"
        class="mdi mdi-alert-box-outline"
      ></span>
    }
    @if (type === 'warn') {
      <span
        fxFlex="0 0 auto"
        class="mdi mdi-help-circle-outline"
      ></span>
    }
  </div>
  @if (title) {
    <div fxLayout="column" class="message-body">
      <div class="title mat-h4-custom mb-1">
        {{ title }}
      </div>
      <div>
        <div class="content" fxLayout="column">
          <div class="message mat-body">
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </div>
        </div>
      </div>
    </div>
  }
  <div class="close-icon">
    <span class="mdi mdi-close" (click)="onClose()"></span>
  </div>
</div>
