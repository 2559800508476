<div class="add-asset-component">
  <div class="asset-top">
    <div class="header"
      fxLayoutGap="10px"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start">
      <h3 class="title"> {{!editAssetData?'Add Asset':'Edit Asset'}}</h3>
      <div fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button [disabled]="disableSaveButton"
          (click)="updateApplicationAssetsData('Save');">
          @if (!disableSaveButton) {
            <span class="mdi mdi-check" matPrefix></span>
          }
          @if (disableSaveButton) {
            <span class="mdi mdi-loading" matPrefix></span>
          }
          @if (!disableSaveButton) {
            <span>Save</span>
          }
          @if (disableSaveButton) {
            <span>Loading...</span>
          }
        </button>
        <button mat-flat-button (click)="onClickBack()">
          <span class="mdi mdi-close" matPrefix></span>
          Cancel
        </button>
      </div>
    </div>
  </div>

  <div>
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start" (selectedIndexChange)="setCurrentSelection($event)" [selectedIndex]="tabIndex">
          <mat-tab label="SUPPLIER">
            <div class="row">
              <div class="col-12 mt-3">
                <supplier2
                  [searchSupplierFn]="searchSupplierFn"
                  [formControl]="formControlSupplier"
                  (events)="getSupplierData($event)"
                  [mark]="markTriggerSubject(formGroup)">
                </supplier2>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="PRIVATE SELLER">
            <div>
              <private-seller
                [getInstitutionNameFn]="getInstitutionNameFn"
                [businessSearchFn]="businessSearchFn"
                [privateSupplier]="privateSupplier"
                [mark]="markTriggerSubject(formGroup)"
                #getData>
              </private-seller>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <cell>
                  <div label>Salesforce ID</div>
                  <div body>{{editAssetData?.PrivateSellerDetails?.SalesForceId ?? '-'}}</div>
                </cell>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <p class="invoice-number">Invoice number</p>
            <mat-form-field>
              <mat-label>Invoice number</mat-label>
              <input maxlength="15" matInput type="input" [formControl]="formControlInvoiceNumber">
              @for (errorKey of errorKeys(formControlInvoiceNumber); track errorKey) {
                <mat-error>
                  @if (formControlInvoiceNumber.touched && formControlInvoiceNumber.hasError(errorKey)) {
                    {{errorMessage(formControlInvoiceNumber, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12 tax-invoice">
            <p class="invoice-number">Tax invoice</p>
            <uploaded-files-list [isAzureDocument]="true" [uploadedFiles]="uploadedDocs"
            (events)="onDeleteUploadedDoc($event)"></uploaded-files-list>
            <upload-file [maxFilesAllowed]="5" [mandatory]="!skipFileUploadValidation()" [mark]="markTriggerSubject(formGroup)"
            title="Please upload the tax invoice" [acceptFileTypes]="['.csv','.doc','.docx','.pdf','.xls','.xlsx','image/*']" [formControl]="formControlTaxInvoice" (events)="onUploadTaxInvoice($event)" [allowMultiple]="true"></upload-file>
          </div>
        </div>

        <div class="container-fluid asset-table-section">
          @if (dataSource && dataSource.length>0 && !editAssetValue) {
            <table
              mat-table
              [dataSource]="dataSource"
              multiTemplateDataRows
              class="width-100 mb-2"
              matSort>
              @for (column of displayedColumns; track column) {
                <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="{{getColumnTitles(column)}}">{{getColumnTitles(column)}}
                  </th>
                  <td mat-cell *matCellDef="let element" class="asset-column">
                    @if (column === 'asset') {
                      <div class="title">
                        {{element && element.assetType.value ? element.assetType.value : ''}}
                      </div>
                      <div class="description">
                        {{element.year}} - {{element.description}}
                      </div>
                    }
                    @if (column === 'VINCodeOrChassisNumber') {
                      <div class="serialNumber">
                        {{ element.serialNumberType ? element.serialNumberType: 'Serial Number' }}
                      </div>
                      <div class="serialType">
                        {{element.serialNumber}}
                      </div>
                    }
                    @if (column === 'invoiceAmount') {
                      {{element.invoicePrice | looseCurrency}}
                    }
                    @if (column === 'actions') {
                      <div class="buttons">
                        <span class="mdi mdi-trash-can-outline cursor-pointer" (click)="deleteAsset(element)"></span>
                        <span class="mdi mdi-pencil-outline cursor-pointer" (click)="disableRow ? editDisable($event) : editAsset(element)"></span>
                      </div>
                    }
                  </td>
                </ng-container>
              }
              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="row mt-3">
                      <div class="col">
                        <add-asset-details
                          [updateAssetData]="updateAssetData"
                          [isRentalApplication]="isRentalApplication"
                          [privateSeller]="tabIndex === 1"
                          [application]="application" [invoiceNumber]="formControlInvoiceNumber.value"
                          (savedDataEvent)="getSavedAssetData($event)">
                        </add-asset-details>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element || disableRow ? editDisable($event) : element;disableRow ? editDisable($event) : editAsset(element)">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
          }
        </div>

        @for (control of formArrayAssets.controls; track control; let index = $index) {
          <div class="row">
            <div class="col-12">
              <add-asset-details
                [isRentalApplication]="isRentalApplication"
                [allowDelete]="dataSource.length > 0"
                [disableControl]="false"
                [formControl]="$any(control)"
                title="Asset {{dataSource.length+1}} details"
                (savedDataEvent)="getSavedAssetData($event)"
                [editAssetData]="editAssetValue"
                [application]="application"
                [invoiceNumber]="formControlInvoiceNumber.value"
                [firstAssetData]="firstAssetDataValue"
                (disableAssetRowEvent)="onDisableRow($event)"
                (events)="onAssetEvent($event,$any(control))"
                (disableAddButtonEvent)="ondisableAddButton($event)"
                (finishLoadingEvent)="handleAssetDetailsFinishLoading()"
                [privateSeller]="tabIndex === 1"
                [assetInspectionTableData]="assetInspectionTableData"
                #formArray>
              </add-asset-details>
            </div>
          </div>
        }

        @if (disableButton) {
          <button mat-flat-button class="addAsset mb-4 ml-3" (click)="addAsset(false)" >
            <span class="mdi mdi-plus" matPrefix></span>
            Add Asset
          </button>
        }
      </mat-card-content>
    </mat-card>
  </div>

</div>
