<div class="mark-as-sold-dialog">
  <mat-dialog-content>
    <div class="header">
      <h3 class="mb-0">Mark as sold</h3>
    </div>

    <form [formGroup]="formGroup">
      <div class="vehicle">
        <br>
        <h4>{{title}}</h4>
        <span class="description">{{description}}</span><br/>
        <span class="description">vin: {{vin}}</span>
      </div>
      <div class="priceSold">
        <h4>Price sold</h4>
        <currency-input

          title="Asset Price"
          [formControl]="assetPriceControl">
        </currency-input>
      </div>

      <div class="footer">
        <button color="primary" fxFlexAlign="start" mat-stroked-button (click)="onCancel()">Cancel</button>
        <button color="primary" fxFlexAlign="end" type="submit" mat-flat-button
                [disabled]="formGroup.invalid" (click)="onSubmit()">Submit</button>
      </div>

    </form>
  </mat-dialog-content>
</div>
