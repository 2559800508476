import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import {AppCalculator, TermRate} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {Moment} from 'moment';
import { AmortisationScheduleComponent } from './amortisation-schedule.component';
import { RepaymentEstimationComponent } from './repayment-estimation.component';
import { AmortizationChartComponent } from './amortization-chart.component';
import { PaymentChartComponent } from './payment-chart.component';
import { MatTabsModule } from '@angular/material/tabs';


@Component({
    selector: 'total-payment-breakup',
    templateUrl: './total-payment-breakup.component.html',
    styleUrls: ['./total-payment-breakup.component.scss'],
    standalone: true,
    imports: [MatTabsModule, PaymentChartComponent, AmortizationChartComponent, RepaymentEstimationComponent, AmortisationScheduleComponent]
})
export class TotalPaymentBreakupComponent {

  @Input({required: true}) amortizationScheduleData: AmortisationScheduleEntry[] = [];
  @Input({required: true}) amortizationChartData!: AmortizationChartData;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: true}) repaymentEstimationData: RepaymentEstimationEntry[] = [];
  @Input({required: false}) calculationLog?: {log: string, rate?: number}[];
  @Input({required: true}) paymentChartHideBrokerage: boolean = false;
  @Input({required: true}) paymentChartHideBalloonPayment: boolean = false;
  @Input({required: true}) paymentChartHideBrokerOriginationFee: boolean = false;
  @Input({required: true}) showPaymentChart: boolean = true;
  @Input({required: true}) showArmotizationChart: boolean = true;
  @Input({required: true}) showRepaymentEstimation: boolean = true;
  @Input({required: true}) showArmotisationSchedule: boolean = true;
  // @Input({required: false}) onlyShowPaymentChartData?: boolean = false;

  @Output() events: EventEmitter<Moment> = new EventEmitter<Moment>();

  onDateChange($event: moment.Moment) {
    console.log('****** total-payment-breakup emit event', $event);
    this.events.emit($event);
  }
}
