<div class="datepicker2-component">
  <mat-form-field class="datepicker">
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input placeholder="dd/mm/yyyy"
      [inputMask]="createDateInputMask"
      (dateChange)="onDateChange($event)"
      [errorStateMatcher]="errorStateMatcher"
      matInput [matDatepicker]="picker"
      [formControl]="formControl" [disabled]="disabled" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
