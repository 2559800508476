import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {MomentBuiltinFormat} from 'moment';

const COMBINATIONS: ({parseFormat: string | MomentBuiltinFormat, strict: boolean})[] = [
  {parseFormat: moment.ISO_8601, strict: true},
  {parseFormat: moment.RFC_2822, strict: true},
  {parseFormat:'DD/MM/YYYY', strict: true},
  {parseFormat:'YYYY-MM-DD', strict: true},
  {parseFormat:'', strict: false},
]

@Pipe({
    name: 'looseDate',
    pure: true,
    standalone: true
})
export class LooseDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    if (value) {
      const format = args[0] ?? 'DD/MM/YYYY';
      for (const combination of COMBINATIONS) {
        const m = (!!combination.parseFormat ? moment(value, combination.parseFormat, combination.strict) : moment(value, combination.strict));
        if (m.isValid()) {
          return m.format(format);
        }
      }
    }
    return '';
  }
}
