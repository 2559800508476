<div class="pismo-reissue-card-dialog m-4">
  <h3 mat-dialog-title>Reissue Card</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Reason for reissue the card</mat-label>
          <mat-select placeholder="Select reason..." [formControl]="formControlReissueReason">
            @for (option of allReissueReasons; track option) {
              <mat-option [value]="option">{{displayCardReissueReason(option)}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSubmit()"  [disabled]="formGroup.invalid">Reissue Card</button>
    </div>
  </mat-dialog-actions>
</div>
