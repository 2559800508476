import {Component, OnInit} from '@angular/core';
import {clearAllStorage, getUser} from '@portal-workspace/grow-ui-library';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {Router} from '@angular/router';
import {User} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {PortalHotToastService,CustomContentLoaderComponent} from '@portal-workspace/grow-ui-library';
import {catchError, tap} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {AuthService} from '../../service/auth.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {loadingFor} from '@ngneat/loadoff';
import {RegistrationService} from '../../service/registration.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';

import { AsyncPipe } from '@angular/common';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './unverified.page.html',
    styleUrls: ['./unverified.page.scss'],
    standalone: true,
    imports: [CustomContentLoaderComponent, FlexModule, MatCardModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatDividerModule, AsyncPipe]
})
export class UnverifiedPage implements OnInit {

  user: User | null = null;

  loader = loadingFor('loading');

  subscriptions: Subscription[] = [];

  formControlSmsVerificationCode!: FormControl<string | null>;

  constructor(private minimalLayoutService: MinimalLayoutService,
              private formBuilder: FormBuilder,
              private adminService: AdminService,
              private registerService: RegistrationService,
              private registrationService: RegistrationService,
              private dialogService: ApplicationDialogService,
              private toastService: PortalHotToastService,
              private authService: AuthService,
              private router: Router) {
  }

  async ngOnInit() {
    const user = getUser();
    if (user) {
      this.subscriptions.push(this.authService.getUser(user?.UserId,false).pipe(
        this.loader.loading.track(),
        tap(p => {
          this.user = p.payload;
        })
      ).subscribe());
    }
    this.minimalLayoutService.settings({
      showAccreditationInContact: false,
      showContact: true,
      showCross: false,
      showPrev: false,
    });
    this.formControlSmsVerificationCode = this.formBuilder.control(null, [Validators.required]);
    if (!this.user) {
      await this.router.navigate(navigationUrlForLogin());
    }
  }

  async reLogin($event: MouseEvent) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin());
  }

  checkValidMobile(): string | null {
    // if(!!!this.user?.PhoneNumber) {
    //   this.dialogService.openAlertDialog({
    //     message: 'Error',
    //     subMessage: 'No phone number to send SMS'
    //   });
    //   return null;
    // }
    if (!!!this.user?.MobileNumber) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'No mobile number to send SMS'
      })
      return null;
    }
    return this.user.MobileNumber;
  }

  verifySms($event: Event) {
    const number = this.checkValidMobile();
    const code = this.formControlSmsVerificationCode.value;
    const userId = this.user?.UserId;
    if (number && code && userId) {
      // this.adminService.verifySmsVerificationCode(userId, number, code).pipe(
      this.registrationService.verifySmsVerificationCode(code, userId, number).pipe(
        tap(apiResponse => {
          if (apiResponse.status) {
            if (this.user) {
              this.user.mobile_verified = 1;
            }
          }
          this.dialogService.openAlertDialog({
            message: `Verification`,
            subMessage: apiResponse.message,
          });
        }),
        catchError(err => {
          this.dialogService.openAlertDialog({
            message: `Verification`,
            subMessage: `Code verification failed`,
          });
          return of(null);
        }),
      ).subscribe();
    }
  }

  sendSmsVerification($event: Event) {
    const number = this.checkValidMobile();
    if (number && this.user) {
      // this.adminService.sendVerificationSMSCode(number).pipe(
      this.registrationService.sendSmsVerificationCode(this.user.UserId, number).pipe(
        this.toastService.snackBarObservable(`Verification SMS send to ${number}`),
      ).subscribe();
    }
  }

  sendEmailVerification($event: Event) {
    if(!!!this.user?.Email) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'No Email to send to'
      });
      return;
    }
    this.registerService.sendVerificationEmail({
      fname: this.user.Name ?? `${this.user.GivenName ?? ''} ${this.user.FamilyName ?? ''}`,
      UserId: this.user.UserId,
      mobile: this.user.MobileNumber ?? this.user.MobileNumber,
      email: this.user.Email
    }).pipe(
      this.toastService.snackBarObservable(`Verification email sent to ${this.user.Email}`),
    ).subscribe();
  }

  async switchUser($event: Event) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin());
  }
}
