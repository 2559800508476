import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {RatecardDetails} from '@portal-workspace/grow-shared-library';
import {Observable, of} from 'rxjs';
import {AdminService} from './admin.service';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


@Injectable()
export class RatecardsResolver  {

  constructor(private adminService: AdminService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<RatecardDetails[] | null> {
    const rateCardId = route.params.rateCardId;
    if (rateCardId) {
      const v = this.adminService.getRatecardDetails(rateCardId).pipe(
        map(r => {
          if (r && r.status) {
            return r.payload;
          }
          return null;
        }),
        catchError(err => {
          console.log('ratecards resolver error', err);
          return of(null);
        })
      );
      return v;
    }
    return of(null);
  }

}
