<div class="card-operator-dashboard-page width-100" fxLayout="column" fxLayoutAlign="top center">
  <mat-card class="width-100">
    <mat-card-header class="separator-bottom">
      <mat-card-title class="mat-h3">Card Operation</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        <overdraft-customer-search
          [formControl]="formControlCustomerSearch"
          [searchFn]="searchFn">
        </overdraft-customer-search>
      </p>

      @if (stage === 'customer-selected' || stage === 'user-selected' || stage === 'card-selected') {
        <p>
          @if (selectedCustomer) {
            <mat-form-field>
              <mat-label>Account Users</mat-label>
              <input type="text"
                placeholder="Select Account User"
                matInput
                [formControl]="formControlUserList"
                [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete"
                  autoActiveFirstOption
                  [displayWith]="userListDisplayWithFn"
                  (optionSelected)="userSelected($event)">
                  @for (user of filterdUsers; track user) {
                    <mat-option [value]="user">
                      {{user.name}} ({{user.email}} - {{user.pismoAccountNumber}})
                    </mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            }
          </p>
        }


        @if (stage === 'user-selected' || stage == 'card-selected') {
          <p>
            @if (selectedUser) {
              <!-- User Info -->
              <message-box title="User Info" type="info">
                <div>
                  <p>Email: {{selectedUser.email}}</p>
                  <p>Mobile Number: {{selectedUser.mobileNumber}}</p>
                  <p>DoB: {{selectedUser.dob | looseDate}}</p>
                  <p>Pismo Account Number: {{selectedUser.pismoAccountNumber}}</p>
                  <p>Pismo Customer Number: {{selectedUser.pismoCustomerNumber}}</p>
                </div>
              </message-box>
              <!-- User Cards Info -->
              @if (loader.cardsLoader.inProgress$ | async) {
                <custom-content-loader type="bullet"></custom-content-loader>
              }
              @if (!(loader.cardsLoader.inProgress$ | async) && filteredCards.length <= 0) {
                <div class="separator">
                  <message-box  title="Card(s) Info" type="info">No cards found</message-box>
                </div>
              }
              @if (!(loader.cardsLoader.inProgress$ | async) && filteredCards.length > 0) {
                <div class="separator">
                  <table mat-table [dataSource]="filteredCards" class="cards-table">
                    <ng-container matColumnDef="selection">
                      <th mat-header-cell *matHeaderCellDef>
                      </th>
                      <td mat-cell *matCellDef="let card">
                        <mat-radio-button color="primary" (click)="onUserCardSelected($event, card)"></mat-radio-button>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="cardNumber">
                      <th mat-header-cell *matHeaderCellDef>
                        Card Number (last 4 digits)
                      </th>
                      <td mat-cell *matCellDef="let card">
                        {{card.last_4_digits}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="activationTime">
                      <th mat-header-cell *matHeaderCellDef>
                        Activation Time
                      </th>
                      <td mat-cell *matCellDef="let card">
                        {{card.issuing_date | looseDate}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="cardTableDisplayColumns"></tr>
                    <tr mat-row *matRowDef="let card; columns: cardTableDisplayColumns;"></tr>
                  </table>
                </div>
              }
            }
          </p>
        }

        @if (stage === 'card-selected' && selectedCard) {
          <div>
            <div class="separator">
              <message-box  title="Warning" type="warn">
                Please advice the customer that card deactivation cannot be reversed.
              </message-box>
            </div>
            <button
              mat-flat-button
              class="w-lt-md-100"
              color="primary"
              [disabled]="(!selectedCard || (loader.deactivateCard.inProgress$ | async))"
            (click)="deactivateCard($event, selectedCard)">Deactivate Card</button>
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
