<div class="asset-save-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
        <div class="mat-h3 width-100">
          <span class="mdi mdi-alert-circle-outline color-warn"></span>
          {{data.message}}
        </div>
        <div class="mat-body width-100">
          {{data.subMessage}}
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
        <button  mat-stroked-button color="primary" (click)="onCancel($event)">Cancel</button>
        <button mat-flat-button (click)="onSubmit($event)">Submit</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
