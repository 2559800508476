import { AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import {
  createAccountNumberInputMask,
} from '@portal-workspace/grow-ui-library';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {AccountNumberValue, compareMatch} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy()
@Component({
    selector: 'account-number',
    templateUrl: './account-number.component.html',
    styleUrls: ['./account-number.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AccountNumberComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AccountNumberComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective]
})
export class AccountNumberComponent extends AbstractControlValueAccessor<AccountNumberValue> implements OnInit, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formControl!: FormControl<AccountNumberValue>;
  createAccountNumberInputMask = createAccountNumberInputMask();
  formGroup!: FormGroup<{
    accountNumber: FormControl<AccountNumberValue>
  }>;
  subscription?: Subscription;
  @Input({required: false}) isInternational?: boolean;

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control('', [Validators.required]);
    this.formGroup = this.formBuilder.group({
      accountNumber: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.formGroup.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  doWriteValue(v: string | null) {
    this.formControl.setValue(v);
  }

  mark() {
    this.formControl.markAllAsTouched();
  }
}
