import {Component, OnInit, ViewChild} from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AdminService } from '../../service/admin.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Sort, MatSortModule } from '@angular/material/sort';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { User, isInternalUser, DEFAULT_LIMIT, DEFAULT_OFFSET, ApproveOrRejectUserFn, } from '@portal-workspace/grow-shared-library';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { navigationUrlForPismoAccount, navigationUrlForUserDetail } from '../../service/navigation-urls';
import {PortalHotToastService, UserTableComponentEvent} from '@portal-workspace/grow-ui-library';
import { AuthService } from '../../service/auth.service';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import {loadingFor} from '@ngneat/loadoff';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { ErrorNotification, getUser,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { NgClass, AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { MatTabChangeEvent, MatTabsModule } from "@angular/material/tabs";
import { UserTableComponent, UserTableFn } from 'libs/grow-ui-library/src/lib/components/user-table-component/user-table.component';
import {isInternalUser as utilIsInternalUser} from '@portal-workspace/grow-shared-library';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './users.page.html',
    styleUrls: ['./users.page.scss'],
    standalone: true,
    imports: [UserTableComponent, ErrorNotification, MatTabsModule, FlexModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, MatSlideToggleModule, CustomPaginatorComponent, AsyncPipe, NgTemplateOutlet]
})
export class UsersPage implements OnInit {

  @ViewChild("brokerUserTable") brokerUserTable!: UserTableComponent;
  @ViewChild("customerUserTable") customerUserTable!: UserTableComponent;
  approveOrRejectFn!: ApproveOrRejectUserFn;

  subscriptions: Subscription[] = [];

  getUserTableFn!: UserTableFn;



  constructor(private formBuilder: FormBuilder,
    private dialogService: ApplicationDialogService,
    private router: Router,
    private toastService: PortalHotToastService,
    private adminService: AdminService,
    private authService: AuthService) {
    this.getUserTableFn = this.authService.getUsersFn;
    this.approveOrRejectFn = this.authService.approveOrRejectUserFn;
  }

  ngOnInit(): void {
  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  async onUsersTableEvent($event: UserTableComponentEvent) {
    switch($event.kind) {
      case 'add-user': {
        switch ($event.type) {
          case 'broker-user': {
            // todo:
            this.dialogService.openAddBrokerUserDialog({
                // validEmailCheckFn: this.authService.validEmailCheckFn,
                getUserByEmailFn: this.authService.getUserByEmailFn,
                getFn: this.adminService.getAggregatorsAndOriginatorsFn,
                createUserFn: this.authService.createBrokerUserFn,
                updateUserFn: this.authService.updateUserFn
            }).afterClosed().subscribe((r) => {
              if (r && r.valid && this.brokerUserTable) {
                this.brokerUserTable.reload();
              }
            });
            break;
          }
          case 'customer-user': {
            // todo:
            this.dialogService.openAddCustomerUserDialog({
                // validEmailCheckFn: this.authService.validEmailCheckFn,
                getUserByEmailFn: this.authService.getUserByEmailFn,
                getFn: this.adminService.getCustomerListFn,
                //createUserFn: this.authService.createCustomerUserFn,
                //updateUserFn: this.authService.updateUserFn,
                getAllPismoWithCustomerAccountFn: this.authService.getAllPismoWithCustomerAccountFn
            }).afterClosed().subscribe(async (r) => {
              if (r && r.valid && this.customerUserTable) {
                if(r && r.pismoCustomer){
                  await this.router.navigate(navigationUrlForPismoAccount(r.pismoCustomer.pismoAccountNumber));
                }else {
                  this.customerUserTable.reload();
                }
              }
            });
            break;
          }
        }
        break;
      }
      case 'user-click': {
        const user = $event.user;
        switch($event.type) {
          case 'broker-user': {
            await this.router.navigate(navigationUrlForUserDetail(user.UserId, 'broker-user'));
            break;
          }
          case 'customer-user': {
            await this.router.navigate(navigationUrlForUserDetail(user.UserId, 'customer-user'));
            break;
          }
        }
        break;
      }
    }
  }
}
