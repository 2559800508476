<div class="bank-component">
  <mat-card appearance="outlined">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Bank Statements</div>
        </div>
      </div>


    @if (bankStatementMessage) {
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <message-box type="warn">
            {{bankStatementMessage}}
          </message-box>
        </div>
      </div>
    }

    @if (bankStatementNotAvailable) {
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <message-box type="warn">
            Bank statement is not available for this applicant.
          </message-box>
        </div>
      </div>
    }
    @if (bankStatementInfo && !bankStatementNotAvailable) {
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <message-box type="info">
            {{bankStatementInfo}}
          </message-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-9 col-md-12">
          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="space-between center"
            fxLayoutGap="pxToRem(5)"
            fxLayoutAlign.lt-md="center start">
            <button mat-flat-button
                    class="w-lt-md-100"
                    [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''">
              <span class="mdi mdi-content-copy cursor-pointer" matSuffix [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''"></span>
              <span class="label"> Regenerate the link </span>
            </button>
            @if (isInternalUser(loggedInUser) && (canRefreshBankStatement || canRefreshBasiq)) {
              <button mat-flat-button
                      (click)="refreshBankStatement()"
                      class="w-lt-md-100">
                <span class="mdi mdi-refresh-circle cursor-pointer" matSuffix></span>
                <span class="label"> Refresh</span>
              </button>
            }
          </div>
        </div>
      </div>
    }

    @if (!salesforceId) {
      <div class="row separator">
        <div class="col-lg-6 col-md-12">
          <message-box type="warn">
            Bankstatement upload link cannot be generated because there is no salesforce id.
          </message-box>
        </div>
      </div>
    }

    @if (!hasBankStatement && !hasBasiqStatement && !bankStatementInfo && !bankStatementNotAvailable) {
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <mat-form-field>
            <mat-label>Copy BankStatements link</mat-label>
            <input matInput type="text" readonly [formControl]="formControlBankStatementLink">
            <span class="mdi mdi-content-copy cursor-pointer" matSuffix [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''"></span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <p class="mat-body">
            If you need customers to send us their bankstatements, send them this link.
          </p>
          <p class="mat-body">
            The link allows the customer to select the independent vendors Illion or alternatively Basiq to efficiently share bank statement information.
          </p>
          <p class="mat-body">
            When customers use the link, bank statement files are sent directly to the Documents for this application.
          </p>
        </div>
      </div>
    }

    @if(hasBankStatement && hasBasiqStatement && !bankStatementNotAvailable){
      <div class="row">
        <div class="col-12 mb-2">
          <p class="description">
            This applicant has provided bank statements through both Basiq and Illion platforms.
            You can review and analyze the financial data from either source using the selector below.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-12">
          <div class="source-selection">
            <mat-form-field>
              <mat-label>Select Statement Source</mat-label>
              <mat-select [(ngModel)]="selectedStatementSource" (selectionChange)="onStatementSourceChange()">
                <mat-option value="basiq">Basiq</mat-option>
                <mat-option value="illion">Illion</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    }

    @if ((hasBankStatement && !hasBasiqStatement) || (hasBankStatement && hasBasiqStatement && selectedStatementSource == 'illion') && !bankStatementNotAvailable) {

      <bank-statement
      [reference]="reference"
      [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
      [application]="application"
      ></bank-statement>
      }

      @if((!hasBankStatement && hasBasiqStatement) || (hasBankStatement && hasBasiqStatement && selectedStatementSource == 'basiq') && !bankStatementNotAvailable) {

        <bank-statement
        type="Basiq"
        [reference]="reference"
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
      [application]="application"
      [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
      ></bank-statement>
        <!-- <basiq-statement
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [reference]="reference"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
      [application]="application"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        ></basiq-statement> -->
      }
    </mat-card>
  </div>
