import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
 
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {CustomerUserDetailsComponent} from './customer-user-details.component';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { CustomPaginatorModule } from '../custom-paginator-component/custom-paginator.module';
import { MessageBoxModule } from '../message-box/message-box.module';
import { AggregatorSearchModule } from '../aggregator-search-component/aggregator-search.module';
import {NameModule} from '../name-component/name.module';
import { AccessLevelModule } from '../access-level-component/access-level.module';
import { SlideToggleModule } from '../slide-toggle-component/slide-toggle.module';
import {UserTableModule} from '../user-table-component/user-table.module';
import {BrokerUserDetailsComponent} from './broker-user-details.component';
import { CustomContentLoaderModule } from "../custom-content-loader-component/custom-content-loader.module";
 
 

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
     
    CustomPaginatorModule,
    PipeLibraryModule,
    AngularMaterialLibraryModule,
    MessageBoxModule,
    NameModule,
    AggregatorSearchModule,
    DirectiveLibraryModule,
    InputMaskModule,
    AccessLevelModule,
    SlideToggleModule,
    UserTableModule,
    BrokerUserDetailsComponent,
    CustomerUserDetailsComponent,
    CustomContentLoaderModule
  ],
  declarations: [],
  exports: [
    BrokerUserDetailsComponent,
    CustomerUserDetailsComponent,
  ]
})
export class UserDetailsModule {

}
