import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { InformationConfirmationDialogData, InformationDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { Router } from '@angular/router';

@Component({
  templateUrl: './show-user-lock.dialog.html',
  styleUrls: ['./show-user-lock.dialog.scss'],
  standalone: true,
  imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class ShowUserLockDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: InformationConfirmationDialogData,
    private dialogRef: MatDialogRef<ShowUserLockDialog, InformationDialogResult>,
    private formBuilder: FormBuilder,
    private router: Router) {

  }

  onClose($event: Event) {
    const result: InformationDialogResult = {type: 'close'}
    this.dialogRef.close(result);
  }

  onReset($event: Event) {
    const result: InformationDialogResult = {type: 'reset'}
    this.dialogRef.close(result);
  }
}
