import {Component} from '@angular/core';

@Component({
    selector: 'cell',
    template: `
    <div class="cell-component">
      <div class="label mat-label"><ng-content select="[label]"></ng-content></div>
      <div class="body mat-body"><ng-content select="[body]"></ng-content></div>
    </div>
  `,
    styleUrls: ['./cell.component.scss'],
    standalone: true
})
export class CellComponent {

}
