import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {StatusToggleComponent} from './status-toggle.component';


const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialLibraryModule,
  InputMaskModule,
  DirectiveLibraryModule,
]

@NgModule({
    imports: [
        ...modules,
        StatusToggleComponent
    ],
    exports: [
        StatusToggleComponent,
        ...modules,
    ]
})
export class ToggleModule {

}
