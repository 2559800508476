<div class="kyc-verification-component">

  <div class="row">
    <div class="col-12 header"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="end start"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start"
      >
      @if (isInternalUser(loggedInUser)) {
        <button mat-flat-button color="primary" class="active w-lt-md-100" (click)="runVerificationsOnAllIndividuals()">Run Digital ID Verifications on all Individuals</button>
      }
      @if (isInternalUser(loggedInUser)) {
        <button mat-flat-button class="inactive w-lt-md-100" (click)="addNewIndividual()"><span class="mdi mdi-plus" matPrefix></span>Add individual</button>
      }
      <button mat-flat-button class="inactive w-lt-md-100" (click)="refresh()"><span class="mdi mdi-refresh" matPrefix></span></button>
    </div>
  </div>


  <mat-card appearance="outlined">
    <mat-card-content class="px-0">
      <div class="row">
        <div class="col-12 individual-details-section">
          <table mat-table
            class="individuals-table"
            [dataSource]="dataSource"
            multiTemplateDataRows>
            <ng-container matColumnDef="dir">
              <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
              <td mat-cell *matCellDef="let element" class="cell cell-padding">
                @if (isTableRowExpanded(element)) {
                  <span class="mdi mdi-chevron-up"></span>
                }
                @if (!isTableRowExpanded(element)) {
                  <span class="mdi mdi-chevron-down"></span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">First Name</th>
              <td mat-cell *matCellDef="let element" class="cell ellipsis" matTooltip="{{element.GivenName}}">{{element.GivenName}}</td>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">Last Name</th>
              <td mat-cell *matCellDef="let element" class="cell ellipsis" matTooltip="{{element.SurName}}">{{element.SurName}}</td>
            </ng-container>
            <ng-container matColumnDef="dob">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">Date of Birth</th>
              <td mat-cell *matCellDef="let element" class="cell">{{element.DoB | date : 'dd/MM/yyyy' }}</td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef>Address</th>
              <td mat-cell *matCellDef="let element" class="cell">
                <span [innerHTML]="element?.HomeAddress | address | addressSplit | sanitizeHtml"></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="signerRole">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">Signer Role</th>
              <td mat-cell *matCellDef="let element" class="cell">{{element.SignerRole | signerRole}}</td>
            </ng-container>
            <ng-container matColumnDef="additionalRole">
              <th mat-header-cell *matHeaderCellDef class="column-width-1">Additional Role</th>
              <td mat-cell *matCellDef="let element" class="cell">
                @if (element?.additionalRoles && element?.additionalRoles?.length) {
                  <span class="mdi mdi-account-multiple-check-outline"
                    [matTooltip]="displayAdditionalRoles(element)"
                    matTooltipPosition="below"
                    matTooltipClass="mat-tooltip-class"
                  ></span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="column-width-0">Status</th>
              <td mat-cell *matCellDef="let element" class="cell">
                @if (isStatusOk(element)) {
                  <span class="mdi mdi-check-circle-outline ok"></span>
                }
                @if (isStatusIncomplete(element)) {
                  <span class="mdi mdi-progress-question incomplete"></span>
                }
                @if (isStatusError(element)) {
                  <span class="mdi mdi-alert-circle-outline error"></span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="column-width-0"></th>
              <td mat-cell *matCellDef="let element" class="cell">
                @if (isInternalUser(loggedInUser)) {
                  <span class="mdi mdi-dots-vertical cursor-pointer" (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"></span>
                }
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="deleteIndividual($event, element)" class="pr-0">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div>Delete</div>
                      <div><span class="mdi mdi-trash-can-outline"></span></div>
                    </div>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="element-detail" [@detailExpand]="isTableRowExpanded(element) ? 'expanded' : 'collapsed'">
                  @if (individualFormGroup(element)) {
                    <div class="">
                      @if (duplicatedEmailOrMobile(element)) {
                        <div class="row separator-bottom">
                          <div class="col">
                            <message-box type="warn">
                              <p>The email & mobile should be unique</p>
                            </message-box>
                          </div>
                        </div>
                      }
                      <div class="row">
                        <div class="col-12">
                          <div fxLayout="row"
                            fxLayoutAlign="space-between start"
                            fxLayout.lt-md="column"
                            fxLayoutAlign.lt-md="center start">
                            <div class="mb-3">
                              <div class="mat-h3 ellipsis" style="max-width:100px" matTooltip="{{element.GivenName}} {{element.SurName}}">{{element.GivenName}} {{element.SurName}}</div>
                              <div class="mat-body">{{element.Email}}</div>
                            </div>
                            <div class="w-lt-md-100"
                              fxLayout="row"
                              fxLayoutGap="5px"
                              fxLayout.lt-md="column"
                              fxLayoutAlign.lt-md="center start">
                              @if (isInternalUser(loggedInUser)) {
                                <button mat-stroked-button (click)="addAdditionalRole(element)" class="w-lt-md-100"><span class="mdi mdi-plus"></span> Additional Role</button>
                              }
                              @if (isInternalUser(loggedInUser)) {
                                <button mat-stroked-button [disabled]="(!!!element?.id) || disableSubmission(element)" (click)="_runVerificationOnIndividual(element)" class="w-lt-md-100">Run Digital ID Verifications</button>
                              }
                              <button mat-stroked-button (click)="_sendVerificationEmailToIndividual(element)" [disabled]="(!!!element?.id) || disableSubmission(element)" matTooltip="Send verification email to individual" [disabled]="!!!element?.id" class="w-lt-md-100"><span class="mdi mdi-email-outline"></span></button>
                              <button mat-stroked-button (click)="_saveIndividual(element)" matTooltip="Save individual" [disabled]="(!!!element?.id) || disableSubmission(element)" class="w-lt-md-100"><span class="mdi mdi-check"></span>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row separator">
                        <div class="col-12">
                          <div class="mat-caption">Salesforce contact ID</div>
                          <div class="mat-body">{{element.sfContactId ?? 'Not available'}}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <mat-form-field>
                            <mat-label>Business name</mat-label>
                            <input matInput type="text" [value]="getApplicationCustomerName(application)" [disabled]="true">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <mat-form-field>
                            <mat-label>Signer Role</mat-label>
                            <mat-select [attr.disabled]="!isInternalUser(loggedInUser)" [formControl]="individualFormGroupControl(element, 'signerRole')">
                              <mat-option [value]="'Signer'">Signer</mat-option>
                              <mat-option [value]="'Guarantor'">Guarantor</mat-option>
                              <mat-option [value]="'GuarantorSigner'">Guarantor / Signer</mat-option>
                              <mat-option [value]="'Others'">Others</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      @for (additionalSignersForm of individualAdditionalRolesFormArray(element).controls; track additionalSignersForm; let i = $index) {
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <business-number-search
                              [disableControl]="!isInternalUser(loggedInUser)"
                              [searchFn]="businessNumberSearchFn"
                              [formControl]="additionalSignersForm.controls.business"
                            ></business-number-search>
                          </div>
                          <div class="col-lg-3 col-md-12">
                            <mat-form-field>
                              <mat-label>Signer Role</mat-label>
                              <mat-select [formControl]="additionalSignersForm.controls.signerRole" [attr.disabled]="true">
                                <mat-option [value]="'Signer'">Signer</mat-option>
                                <mat-option [value]="'Guarantor'">Guarantor</mat-option>
                                <mat-option [value]="'GuarantorSigner'">Guarantor / Signer</mat-option>
                                <mat-option [value]="'Others'">Others</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          @if (additionalSignersForm.controls.business.invalid || (additionalSignersForm.controls.business.valid && isAnalyst(loggedInUser))) {
                            <div class="col-1 delete-icon-padding">
                              <span class="mdi mdi-trash-can-outline cursor-pointer" (click)="deleteAdditionalRole(element, i)"></span>
                            </div>
                          }
                        </div>
                      }
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <name title="First Name" [disableControl]="!isInternalUser(loggedInUser)" [formControl]="individualFormGroupControl(element,'firstName')"></name>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <mat-form-field>
                            <mat-label>Middle name</mat-label>
                            <input matInput type="text" [disableControl]="!isInternalUser(loggedInUser)"  [formControl]="individualFormGroupControl(element, 'middleName')">
                          </mat-form-field>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <name title="Last Name" [disableControl]="!isInternalUser(loggedInUser)" [formControl]="individualFormGroupControl(element, 'lastName')"></name>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <title-selection [disableControl]="!isInternalUser(loggedInUser)"  [formControl]="individualFormGroupControl(element, 'title')"></title-selection>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <datepicker [disableControl]="!isInternalUser(loggedInUser)" [allowOnly18YrsPlus]="true" title="Date of birth"  [formControl]="individualFormGroupControl(element, 'dob')"></datepicker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <custom-address [disableControl]="!isInternalUser(loggedInUser)"  title="Residential address" [formControl]="individualFormGroupControl(element, 'residentialAddress')"></custom-address>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <email [mandatory]="!isInternalUser(loggedInUser)" (events)="onEmailEvent($event, element)" [validators]="[duplicateIndividualEmailValidator(application.Individuals, [element])]" [formControl]="individualFormGroupControl(element, 'email')"></email>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <mobile [mandatory]="!isInternalUser(loggedInUser)" (events)="onMobileEvent($event, element)" [validators]="[duplicateIndividualMobileValidator(application.Individuals, [element])]" [formControl]="individualFormGroupControl(element, 'mobile')"></mobile>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-lg-9 col-md-12">
                          @if (!element.result) {
                            <div>
                              <message-box type="info">No verification results.</message-box>
                            </div>
                          }
                          @if (element?.result?.status === 'RECEIVED_ERROR') {
                            <div >
                              <message-box type="danger">Verification cannot be performed on individual {{element.id}}, please contact support for details.</message-box>
                            </div>
                          }
                          @if (element?.result?.status === 'RECEIVED_COMPLETE') {
                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                              <message-box fxFlex="grow" type="info">Digital ID has been verified successfully</message-box>
                              <span class="mdi mdi-dots-horizontal cursor-pointer" (click)="expandVerificationResultDetails(element)"></span>
                              <span class="mdi mdi-printer cursor-pointer" (click)="printDigitalIdResult(element)"></span>
                            </div>
                          }
                          @if (element?.result?.status === 'RECEIVED_INCOMPLETE') {
                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                              <message-box fxFlex="grow" type="warn">Digital ID verification incomplete</message-box>
                              <span class="mdi mdi-dots-horizontal cursor-pointer" matTooltip="Expand digital Id verification result" (click)="expandVerificationResultDetails(element)"></span>
                              <span class="mdi mdi-printer-outline cursor-pointer" matTooltip="Print digital Id verification result" (click)="printDigitalIdResult(element)"></span>
                            </div>
                          }
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          @if (canActionDigitalVerification(element)) {
                            <div class="separator">
                              <button mat-stroked-button [disabled]="(!!!element?.id) || disableSubmission(element)" (click)="_verifyNow(element)" class="w-lt-md-100">Verify Now</button>
                            </div>
                          }
                          <!-- Expandable individual info table -->
                          @if (isVerificationResultDetailsExpanded(element)) {
                            <div>
                              @if (element.result) {
                                <table class="verification-result-table">
                                  <tr>
                                    <th>Last update</th>
                                    <td>{{element.result.lastUpdate | date: 'medium'}}</td>
                                  </tr>
                                  <tr>
                                    <th>Request status</th>
                                    <td>{{element.result.status}}</td>
                                  </tr>
                                  <tr>
                                    <th>Verification status</th>
                                    <td><tag-box [state]="verificationStatusDisplay(element.result.digitalIdResponse?.verification_status)"></tag-box></td>
                                  </tr>
                                  <tr>
                                    <th>Verification session token</th>
                                    <td><span class="text-wrap text-break">{{defaultString(element.result.digitalIdResponse?.verification_session_token) | truncate: [30] }}</span></td>
                                  </tr>
                                  <tr>
                                    <th>Data source</th>
                                    <td>
                                      @if (element.result.digitalIdResponse?.data_source_events?.length) {
                                        <ul>
                                          @for (event of element.result.digitalIdResponse?.data_source_events; track event) {
                                            <li>
                                              {{datasourceEventDisplay(event)}}
                                            </li>
                                          }
                                        </ul>
                                      }
                                      @if (!element.result.digitalIdResponse?.data_source_events?.length) {
                                        <span>Not available</span>
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Transaction ID</th>
                                    <td>
                                      {{defaultString(element.result.digitalIdResponse?.transaction_id)}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Watch list</th>
                                    <td>
                                      @if (!element.result.digitalIdResponse?.watchlist) {
                                        Not Available
                                      }
                                      @if (element.result.digitalIdResponse?.watchlist) {
                                        <div>Check Performed: {{capitalize(element.result.digitalIdResponse.watchlist.check_performed)}}</div>
                                        <div>Check Performed Date: {{element.result.digitalIdResponse.watchlist.check_performed_date | date: 'MMM d, y, h:mm:ss a'}}</div>
                                        <div>Found: {{capitalize(element.result.digitalIdResponse.watchlist.found)}}</div>
                                        <div>Sources Category: {{sourcesCategoryDisplay(element.result.digitalIdResponse.watchlist.sources_category)}}</div>
                                        @if (element.result.digitalIdResponse.watchlist.found_sources && element.result.digitalIdResponse.watchlist.found_sources.length) {
                                          <div>
                                            <div>Found Categories and sources (category - found sources) as follows :-</div>
                                            <ul>
                                              @for (source of element.result.digitalIdResponse.watchlist.found_sources; track source) {
                                                <li>
                                                  {{source.category}} - {{source.name}}
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        }
                                      }
                                    </td>
                                  </tr>
                                </table>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <mat-divider [inset]="true" class="mb-3"></mat-divider>
                      <div class="row separator">
                        <div class="col-md-12">
                          <div fxLayout="row"
                            fxLayoutAlign="space-between start"
                            fxLayout.lt-md="column"
                            fxLayoutAlign.lt-md="center start">
                            <div class="mb-3">
                              <div class="mat-h3">Privacy Consent</div>
                            </div>
                            <div class="w-lt-md-100"
                              fxLayout="row"
                              fxLayoutGap="5px"
                              fxLayout.lt-md="column"
                              fxLayoutAlign.lt-md="center start">
                              @if (!element?.privacyConsent?.length) {
                                <button mat-stroked-button class="w-lt-md-100" (click)="onSendPrivacyConsent(element)">
                                  Send Privacy Consent
                                </button>
                              }
                              @if (element?.privacyConsent?.length) {
                                <button mat-stroked-button class="w-lt-md-100" (click)="onSendPrivacyConsent(element)">
                                  Resend Privacy Consent
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      @if (!(element.privacyConsent && element.privacyConsent.length)) {
                        <div class="row separator">
                          <div class="col-lg-9 col-md-12">
                            <message-box type="info">
                              <p>No privacy consent records yet</p>
                            </message-box>
                          </div>
                        </div>
                      }
                      @if (element.privacyConsent && element.privacyConsent.length) {
                        <div class="row separator">
                          <div class="col-md-12">
                            <table mat-table
                              [dataSource]="element.privacyConsent"
                              multiTemplateDataRows>
                              <ng-container matColumnDef="userId">
                                <th mat-header-cell *matHeaderCellDef>Sent By</th>
                                <td mat-cell *matCellDef="let privacyConsentElement" class="cell cell-padding">
                                  {{privacyConsentElement.GivenName ?? ''}} {{privacyConsentElement.FamilyName ?? ''}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let privacyConsentElement" class="cell cell-padding">
                                  <privacy-consent-status-chip status="{{privacyConsentElement.status}}"></privacy-consent-status-chip>
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="createdTime">
                                <th mat-header-cell *matHeaderCellDef>Sent Time</th>
                                <td mat-cell *matCellDef="let privacyConsentElement" class="cell cell-padding">
                                  {{privacyConsentElement.createdTime | date:'dd/MM/yyyy h:mm:ss a'}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="lastUpdatedTime">
                                <th mat-header-cell *matHeaderCellDef>Last Updated Time</th>
                                <td mat-cell *matCellDef="let privacyConsentElement" class="cell cell-padding">
                                  {{privacyConsentElement.lastUpdatedTime | date:'dd/MM/yyyy h:mm:ss a'}}
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="['userId', 'status', 'createdTime', 'lastUpdatedTime']"></tr>
                              <tr mat-row *matRowDef="let privacyConsentElement; columns: ['userId', 'status', 'createdTime', 'lastUpdatedTime']"
                              class="example-element-row"></tr>
                            </table>
                          </div>
                        </div>
                      }
                      <mat-divider [inset]="true" class="mb-3"></mat-divider>
                      <div class="row separator">
                        <div class="col-md-12">
                          <div fxLayout="row"
                            fxLayoutAlign="space-between start"
                            fxLayout.lt-md="column"
                            fxLayoutAlign.lt-md="center start">
                            <div class="mb-3">
                              <div class="mat-h3">ID Verify</div>
                            </div>
                            <div class="w-lt-md-100"
                              fxLayout="row"
                              fxLayoutGap="5px"
                              fxLayout.lt-md="column"
                              fxLayoutAlign.lt-md="center start">
                              @if (!element?.identityVerificationResult?.passFaceCompare) {
                                <button mat-stroked-button (click)="generateVerificationLink(element)" class="w-lt-md-100">
                                  Generate Link
                                </button>
                              }
                              @if (element.identityVerificationResult && !element?.identityVerificationResult?.passFaceCompare && isInternalUser(loggedInUser)) {
                                <button mat-stroked-button (click)="bypassFaceCompare(element)" class="w-lt-md-100">
                                  Bypass Face Compare
                                </button>
                              }
                              @if (element.identityVerificationResult && isInternalUser(loggedInUser)) {
                                <button mat-stroked-button (click)="removeIdentityVerification(element)" class="w-lt-md-100">
                                  Remove
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      @if (!element.identityVerificationResult) {
                        <div class="row separator">
                          <div class="col-lg-9 col-md-12">
                            <message-box type="info">
                              <p>No ID Verify results yet</p>
                            </message-box>
                          </div>
                        </div>
                      }
                      @if (element.identityVerificationResult) {
                        <div class="row separator">
                          <div class="col-lg-6 col-md-12">
                            <div class="id-verify-item" fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-calendar-clock"></span></div>
                                <div>Verification Time</div>
                              </div>
                              <div>{{element?.identityVerificationResult?.identityVerificationTime | date : 'dd/MM/yyyy h:mm a'}}</div>
                            </div>
                            <div class="id-verify-item" fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-face-recognition"></span></div>
                                <div>Liveness Confidence</div>
                              </div>
                              <div>{{element?.identityVerificationResult?.livenessConfidence}}</div>
                            </div>
                            <div class="id-verify-item" fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-select-compare"></span></div>
                                <div>Face Compare Score</div>
                              </div>
                              <div>{{element?.identityVerificationResult?.faceCompareScore?.toFixed(5)}}</div>
                            </div>
                            @if (element?.identityVerificationResult?.passLivenessTest) {
                              <div class="id-verify-success-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-check-decagram"></span></div>
                                <div>Passed Liveness Test</div>
                              </div>
                            }
                            @if (!element?.identityVerificationResult?.passLivenessTest) {
                              <div class="id-verify-warning-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-alert-box-outline"></span></div>
                                <div>Not Passed Liveness Test</div>
                              </div>
                            }
                            @if (element?.identityVerificationResult?.passFaceCompare) {
                              <div class="id-verify-success-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-check-decagram"></span></div>
                                <div>Passed Face Compare</div>
                              </div>
                              @if (element?.identityVerificationResult?.lastUpdatedBy) {
                                <div class="id-verify-item" fxLayout="row" fxLayoutAlign="space-between center">
                                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div><span class="mdi mdi-alert-box-outline"></span></div>
                                    <div>Bypassed by {{element?.identityVerificationResult?.lastUpdatedGivenName ?? ''}} {{element?.identityVerificationResult?.lastUpdatedFamilyName ?? ''}}</div>
                                  </div>
                                  <div>{{element?.identityVerificationResult?.lastUpdatedTime | date : 'dd/MM/yyyy h:mm a'}}</div>
                                </div>
                              }
                            }
                            @if (!element?.identityVerificationResult?.passFaceCompare) {
                              <div class="id-verify-warning-item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div><span class="mdi mdi-alert-box-outline"></span></div>
                                <div>Not Passed Face Compare</div>
                              </div>
                            }
                          </div>
                          <div class="col-lg-6 col-md-12">
                            @if (livenessPicture) {
                              <div class="row">
                                <div class="col-12 image">
                                  <image-with-bounding-box [src]="livenessPicture" [boundingBoxes]="livenessBoundingBoxes" [text]="'LIVENESS PICTURE'"></image-with-bounding-box>
                                </div>
                              </div>
                            }
                            @if (driverLicence) {
                              <div class="row">
                                <div class="col-12 image">
                                  <image-with-bounding-box [src]="driverLicence" [boundingBoxes]="driverLicenceBoundingBoxes" [text]="'DRIVER LICENCE'"></image-with-bounding-box>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay"
              class="cursor-pointer"
              [class.expanded-row]="isTableRowExpanded(element)"
            (click)="expandTableRow(element)"></tr>
            <tr class="detail-row"
              [ngClass]="{'detail-row-expanded': isTableRowExpanded(element)}"
              [ngStyle]="{'display': isTableRowExpanded(element) ? null : 'none'}"
              mat-row
            *matRowDef="let element; columns: ['expandedDetail']"></tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
