import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {ApplicationTypes, isInternalUser} from '@portal-workspace/grow-shared-library';
import {environment} from '../../environments/environment';
import {ApplicationDialogService, getUser} from '@portal-workspace/grow-ui-library';
import {navigationUrlForNewApplication} from '../service/navigation-urls';


const allowApplicationSubmissionGuard = (applicationType: ApplicationTypes) => {
 return (
   route: ActivatedRouteSnapshot, state: RouterStateSnapshot
 )  => {
   const dialogService = inject(ApplicationDialogService);
   const router = inject(Router);
   const config = environment.applicationSubmission;
   if (config[applicationType]==='enabled') {
     return true;
   } else if(config[applicationType]==='disabled'){
     dialogService.openInformationDialog({
       message: 'Information',
       subMessage: config.disabledMessage,
     });
   } else if(config[applicationType]==='terminated'){
     return router.navigate(navigationUrlForNewApplication());
   } else if (config[applicationType] === 'internal') {
     const user = getUser();
     if (isInternalUser(user)) {
       return true;
     } else {
       dialogService.openInformationDialog({
         message: 'Information',
         subMessage: config.internalAccessMessage,
       });
     }
   }
   return router.navigate(navigationUrlForNewApplication());
 }
}

export const allowAssetFinanceApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('AssetFinance');
}
export const allowTradeFinanceApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('TradeFinance');
}
export const allowCorporateLoansApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('CorporateLoans');
}

 
export const allowBusinessLoanApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('BusinessLoans');
}
export const allowInsurancePremiumApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('InsurancePremium');
}

export const allowConsumerFinanceApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('Consumer');
}

export const allowBusinessOverdraftApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('BusinessOverdraft');
}

export const allowCommercialFinanceApplicationSubmissionGuard = () => {
  return allowApplicationSubmissionGuard('Commercial');
}





