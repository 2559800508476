import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl
} from '@angular/forms';
import {
  BusinessSearchFn,
  PpsrAssetSupplierSearchEvent,
  SaveSupplierFn,
  toEntityType
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { SupplierDialogData, SupplierDialogResult, BusinessSearchValue, PpsrAssetSupplierSearchValue } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { BusinessSearchComponent } from '../business-search-component/business-search.component';
import { MatDividerModule } from '@angular/material/divider';
import { CustomAddressComponent} from '../address-component/custom-address.component';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './private-supplier-form.dialog.html',
    styleUrls: ['./private-supplier-form.dialog.scss'],
    standalone: true,
    imports: [MatDividerModule, FormsModule, BusinessSearchComponent, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, CustomAddressComponent]
})

export class PrivateSupplierFormDialog implements OnInit {
  subscriptions: Subscription[] = [];

  @Output() events: EventEmitter<PpsrAssetSupplierSearchEvent> = new EventEmitter<PpsrAssetSupplierSearchEvent>();

  businessSearchPlaceholder:string="Enter name or ABN…"
  step1SearchFn!: BusinessSearchFn;
  saveSupplierFn!: SaveSupplierFn;
  newSupplierEntityType: any;
  showOtherField = false;
  supplierResult!: BusinessSearchValue

  formGroupNewSupplier: UntypedFormGroup;
  formControlNewSupplierName: FormControl<BusinessSearchValue>;
  formControlNewSupplierAddress: UntypedFormControl
  formControlNewSupplierAbn: UntypedFormControl;
  formControlSupplierId: UntypedFormControl;
  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: SupplierDialogData,
    private matRef: MatDialogRef<PrivateSupplierFormDialog, PpsrAssetSupplierSearchValue>,
    private toastService: PortalHotToastService,
    private applicationService: ApplicationService,
    private ppsrService: PpsrService
  ) {
    this.formControlNewSupplierName = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierAddress = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierAbn = formBuilder.control(null, [Validators.required]);
    this.formControlSupplierId = formBuilder.control(null);

    this.formGroupNewSupplier = formBuilder.group({
      name: this.formControlNewSupplierName,
      abn: this.formControlNewSupplierAbn,
      address: this.formControlNewSupplierAddress
    })
  }

  ngOnInit() {
    this.step1SearchFn = this.applicationService.businessSearchFn;
    const sub = this.formControlNewSupplierName.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        this.supplierResult = r
        this.formControlNewSupplierAbn.setValue(r?.abn);
        this.newSupplierEntityType = 'sole-trader'
        this.showOtherField = true;
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.formControlNewSupplierName.setValue(this.supplierResult);
    console.log('save',this.saveSupplierFn);
    // if (this.data.abn) {
    //   const businessSearchValue: BusinessSearchValue = {
    //     type: 'free-text',
    //     abn: this.data.abn,
    //     organisationName: '',
    //     acn: '',,
    //   };
    //   this.formControlNewSupplierName.setValue(businessSearchValue);
    // }
  }

  cancel($event: Event) {
    this.matRef.close();
  }

  submitForAccreditation() {
    const e: PpsrAssetSupplierSearchEvent = {
      abn: this.formControlNewSupplierAbn.value,
      address: this.formControlNewSupplierAddress.value,
      supplierType: this.newSupplierEntityType,
      contact: '',
      supplierName: this.formControlNewSupplierName.value?.organisationName ?? '',
      type: 'submitForAccreditation',
    };
    let supplier: any = {}
    const supplierName: any = e.supplierName
    console.log(this.newSupplierEntityType)
    supplier.businessAddress = e.address;
    supplier.abn = e.abn
    supplier.Products = { "assetFinance": true, "tradeFinance": false, "businessLoan": false, "insurancePremium": false };
    supplier.entityType = toEntityType(this.newSupplierEntityType)
    supplier.natureofBusiness = 'supplier'
    supplier.entityName = supplierName
    supplier.officeNumber = e.contact
    supplier.companyName = supplierName
    supplier.heldAccreditationBefore = false
    supplier.ispartOfgroupOrAggregator = false
    supplier.hasAnotherName = false
    supplier.hasAustralianCreditLicence = false
    supplier.declaredBankrupt = false
    supplier.refusedAccreditation = false
    supplier.convictedOfFraud = false
    // this.ppsrService.saveSupplier(supplier).pipe(tap(r => {
    // })).subscribe()
    this.subscriptions.push(this.ppsrService.saveSupplierFn(supplier).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        // this.formControlSearch.setValue(this.formControlNewSupplierName.value)
        // this.formControlContact.setValue(this.formControlNewSupplierContact.value)
        // this.formControlAbn.setValue(this.formControlNewSupplierAbn.value)
        // this.formControlSupplierId.setValue(r.Oppid)
        const data: PpsrAssetSupplierSearchValue = {
          SalesForceId: r.Accountid,
          SupplierName: supplier.entityName,
          ABN: this.formControlNewSupplierAbn.value,
          SupplierAddress: this.formControlNewSupplierAddress.value,
          Contact: '',
          AssetSupplierId: 0,
          SupplierType: this.newSupplierEntityType,
          isDeleted: false,
          isAccredited:false,
          isPrivateSeller:false,
          FinancialInstitution: '',
          BankAccountName: '',
          BankAccountBsb: '',
          BankAccountNumber: '',
        }
        this.matRef.close(data);

        this.events.next(e);
        // this.propagateChange(data)
      })
    ).subscribe());
  }

  show(){
    this.showOtherField =  true;
  }
}
