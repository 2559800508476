<div class="insurance-premium-rate-card-component">
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <div class="mat-h3">Base interest rate</div>
      <mat-form-field>
        <mat-label>Base interest rate</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlBaseInterestRate" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlBaseInterestRate); track errorKey) {
          <mat-error>
            @if (formControlBaseInterestRate.touched && formControlBaseInterestRate.hasError(errorKey)) {
              {{errorMessage(formControlBaseInterestRate, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan amount" [formControl]="formControlLoanAmount"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate formControlErrorsIgnore title="Loan terms" [formControl]="formControlLoanTerms"></min-max-rate>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>

  <div class="mat-h3 separator-top">Risk Profile</div>
  <div class="row separator">
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Adverse on file</mat-label>
        <input matInput [inputMask]="createTwoDecimalInputMask" type="text" [formControl]="formControlAdverseOnFile" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlAdverseOnFile); track errorKey) {
          <mat-error>
            @if (formControlAdverseOnFile.touched && formControlAdverseOnFile.hasError(errorKey)) {
              {{errorMessage(formControlAdverseOnFile, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-3 col-md-12">
      <mat-form-field>
        <mat-label>Low equifax score</mat-label>
        <input [inputMask]="createTwoDecimalInputMask" matInput type="text" [formControl]="formControlLowEquifaxScore" required>
        <span class="mdi mdi-percent" matSuffix></span>
        @for (errorKey of errorKeys(formControlLowEquifaxScore); track errorKey) {
          <mat-error>
            @if (formControlLowEquifaxScore.touched && formControlLowEquifaxScore.hasError(errorKey)) {
              {{errorMessage(formControlLowEquifaxScore, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="row separator">
    <div class="col-12">
      <min-max-rate  title="Doc Fee" [type]="'currency'" [formControl]="formControlDocFee"></min-max-rate>
    </div>
  </div>
  <!-- <mat-divider [inset]="true"></mat-divider> -->

</div>
