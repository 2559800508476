<div class="new-application-page container-fluid">
  @if (isApproved !== 1) {
    <div>
      <div class="row">
        <div class="col">
          <message-box type="details" title="Email verification">
            Please verify your Email address. You can resend the verification email by clicking the <a class="cursor-pointer" (click)="onResendEmailClicked($event)">link</a> here.
          </message-box>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <message-box type="warn" title="Approval of your account in progress">
            You can create draft applications now. But you will only be able to submit them once you have completed the verification process and your broker is accredited with us.
          </message-box>
        </div>
      </div>
    </div>
  }
  <div class="row mt-2">
    <div class="col">
      <message-box-2 type="info" title="Start your finance application now">
        It’s easy to apply online and only takes a few minutes. Should you need assistance to choose the right application type for your customer, please give us a call on 1300 001 420.
      </message-box-2>
    </div>
  </div>
  <application-tiles [enableTiles]="environment.applicationSubmission" (clickedUrl)="navigationHandler($event)"></application-tiles>
</div>
