
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogContainer, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import {AngularMaterialLibraryModule, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ConfirmPasswordAdminDialogData, ConfirmPasswordAdminDialogResult, ConfirmPasswordAdminValue, ConfirmPasswordDialogData, ConfirmPasswordDialogResult, ConfirmPasswordValue } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ConfirmPasswordAdminComponent } from '../confirm-password-admin-component/confirm-password-admin.component';



@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './confirm-password-admin.dialog.html',
  styleUrls: ['./confirm-password-admin.dialog.scss'],
  standalone: true,
  imports: [ConfirmPasswordAdminComponent, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule],
})

export class ConfirmPasswordAdminDialog implements OnInit {
  formGroup: FormGroup<{
    password : FormControl<ConfirmPasswordAdminValue>;
  }>;
  formControlPassword: FormControl<ConfirmPasswordAdminValue>;

  subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPasswordAdminDialogData,
    private matRef: MatDialogRef<ConfirmPasswordAdminDialog, ConfirmPasswordAdminDialogResult>
  ) {
    this.formControlPassword = formBuilder.control('', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z@./#&+-])([a-zA-Z0-9@./#&+-]+).{7,}$/)]);

    this.formGroup = formBuilder.group({
      password: this.formControlPassword
    });
    this.subscription = combineLatest([
      this.formControlPassword.valueChanges,
    ]).pipe(
      tap(r => {
        this.formControlPassword.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  changePassword($event: Event) {
    if (this.data) {
      // NOTE: formGroup should already be valid when this function is called
      const password = this.formControlPassword.value!;
      this.data.updateUserPasswordFn(this.data.userId, {
        password: password as string
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Password Changed',
          errorMessage: 'Failed to change the password',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.changePassword($event);
          }
        }),
      ).subscribe();
      this.cancel($event);
    }
  }

  cancel($event: Event) {
    this.matRef.close({
      valid: false,
    });
  }
}
