import {Component, Input} from '@angular/core';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import {ContactValue} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'primary-contact-summary',
    template: `
    @if (primaryContact) {
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Primary Contact</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{primaryContact.title?.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body>
              <p [innerHTML]="primaryContact.firstName | ellipsis:10"></p></div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Last name</div>
              <div body><p [innerHTML]="primaryContact.lastName | ellipsis:10"></p></div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Mobile number</div>
              <div body>{{primaryContact.mobileNumber}}</div>
            </cell>
          </div>
          <!-- <div class="col-3">
          <div label>Telephone</div>
          <div body>{{primaryContact.telephone}}</div>
        </div> -->
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Email address</div>
            <div body>{{primaryContact.email}}</div>
          </cell>
        </div>
        @if (primaryContact.areaCode) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Area code</div>
              <div body>{{primaryContact.areaCode}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
    `,
    standalone: true,
    imports: [CellComponent, MatDividerModule, EllipsisPipe]
})
export class PrimaryContactSummaryComponent {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: false}) primaryContact?: ContactValue | null;

}
