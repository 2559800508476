<div class="card-operator-layout"
     fxLayout="column"
     fxLayoutAlign="start center">
  <div class="header-2 width-100">
      <minimal-layout-header></minimal-layout-header>
  </div>
  <div class="body">
      <error-notification2></error-notification2>
      <router-outlet></router-outlet>
  </div>
</div>
