import {Component, Inject, inject, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout/flex";
import { AsyncPipe, JsonPipe } from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {
  AllocateUserToPismoAccountDialogData, AllocateUserToPismoAccountDialogResult,
  CurrencyInputValue, CustomerAccessLevel, CustomerAccessLevelAndRoleAccessValue, CustomerAccessLevelValue,
  OverdraftAccountAvailableListValue,
  OverdraftCustomerPhysicalCardSelectValue
} from "@portal-workspace/grow-shared-library";
import {
  OverdraftAccountAvailableListComponent
} from "../overdraft-customer-components/overdraft-account-available-list.component";
import {CurrencyInputComponent} from '../../components/currency-selection-component/currency-input.component';
import {
  CustomerAccessLevelAndRoleAccessComponent,
} from '../../components/access-level-component/customer-access-level-and-role-access.component';
import { PortalHotToastService } from "../../components/portal-hot-toast-component/hot-toast.service";
import {
  OverdraftCustomerPhysicalCardSelectComponent
} from "../overdraft-customer-components/overdraft-customer-physical-card-select.component";
import {MessageBoxComponent} from "../message-box/message-box.component";


@Component({
  templateUrl: './allocate-user-to-pismo-account.dialog.html',
  styleUrls: ['./allocate-user-to-pismo-account.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    JsonPipe,
    AsyncPipe,
    OverdraftAccountAvailableListComponent,
    OverdraftCustomerPhysicalCardSelectComponent,
    CurrencyInputComponent,
    CustomerAccessLevelAndRoleAccessComponent,
    MessageBoxComponent
],
})
export class AllocateUserToPismoAccountDialog implements OnInit {

  formBuilder: FormBuilder = inject(FormBuilder);

  formControlAccount: FormControl<OverdraftAccountAvailableListValue> = this.formBuilder.control(null, [Validators.required]);
  formControlCard: FormControl<OverdraftCustomerPhysicalCardSelectValue> = this.formBuilder.control(null, [Validators.required]);
  formControlMonthlyLimit: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlCustomerAccessLevel: FormControl<CustomerAccessLevelAndRoleAccessValue> = this.formBuilder.control(null, [Validators.required]);

  formGroup: FormGroup<{
    account: FormControl<OverdraftAccountAvailableListValue>,
    card: FormControl<OverdraftCustomerPhysicalCardSelectValue>,
    monthlyLimit: FormControl<CurrencyInputValue>,
    customerAccessLevel: FormControl<CustomerAccessLevelAndRoleAccessValue>,
  }> = this.formBuilder.group({
    account: this.formControlAccount,
    card: this.formControlCard,
    monthlyLimit: this.formControlMonthlyLimit,
    customerAccessLevel: this.formControlCustomerAccessLevel,
  });

  data: AllocateUserToPismoAccountDialogData = inject(MAT_DIALOG_DATA);
  dialogRef: MatDialogRef<AllocateUserToPismoAccountDialog, AllocateUserToPismoAccountDialogResult>
    = inject(MatDialogRef<AllocateUserToPismoAccountDialog, AllocateUserToPismoAccountDialogResult>);
  toastService = inject(PortalHotToastService);

  ngOnInit(): void {
  }

  onCancel($event: Event) {
    this.dialogRef.close({
      type: 'close',
    });
  }

  onSave() {
    // NOTE: when we are here all the form controls should be valid
    const accessLevel = 
    this.dialogRef.close({
      type: 'submit',
      userId: this.data.userId,
      account: this.formControlAccount.value!,
      card: this.formControlCard.value!,
      monthlyLimit: this.formControlMonthlyLimit.value!,
      customerAccessLevel: this.formControlCustomerAccessLevel.value!,
    });
  }
}
