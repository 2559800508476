import { AfterViewInit, Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { pxToRem } from '@portal-workspace/grow-ui-library';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {SignaturePadValue} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';



@Component({
    selector: 'signature-pad',
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SignaturePadComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => SignaturePadComponent) },
    ],
    standalone: true,
    imports: [FlexModule, NgClass, ExtendedModule, MatButtonModule]
})
export class SignaturePadComponent extends AbstractControlValueAccessor<SignaturePadValue> implements OnInit, AfterViewInit, Mark {

  _mark = false;

  pxToRem = pxToRem;

  static SIGN_PAD_COUNT = 1;

  SIGN_PAD_ID = `signature-pad-${SignaturePadComponent.SIGN_PAD_COUNT++}`;

  @ViewChild('canvas') canvasElementRef!: ElementRef;

  signaturePad!: SignaturePad;

  data: string | null = null;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const canvas: HTMLCanvasElement = this.canvasElementRef.nativeElement;
      // canvas.style.width ='100%';
      // canvas.style.height='100%';
      // canvas.width  = canvas.offsetWidth;
      // canvas.height = canvas.offsetHeight;
      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'transparent'
      });
      if (this.data) {
        this.signaturePad.fromDataURL(this.data);
      }
      this.signaturePad.addEventListener('endStroke', () => {
        const data = this.signaturePad.toDataURL();
        this._mark = false;
        this.propagateChange(data);
      });
      this.signaturePad.on();
      console.log('***** signature pad after view init', canvas);
    });
  }

  onClear($event: MouseEvent) {
    this.signaturePad.clear();
    this.propagateChange(null);
  }

  doWriteValue(v: SignaturePadValue | null | undefined): void | string {
    if (v) {
      this.data = v;
    }
    return undefined;
  }

  mark(): void {
    this._mark = this.signaturePad.isEmpty();
  }

}
