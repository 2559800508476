import {Component, OnInit} from '@angular/core';
import {CustomersTableComponentEvent} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {TradeAdminService} from '../../service/trade-admin.service';
import {GetCustomersFn} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Router} from '@angular/router';
import {
  navigationUrlForCustomerDetails,
} from '../../service/navigation-urls';
import {
  CustomersTableComponent
} from "@portal-workspace/grow-ui-library";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './customers.page.html',
    styleUrls: ['./customers.page.scss'],
    standalone: true,
    imports: [
      CustomersTableComponent
    ],
})
export class CustomersPage implements OnInit {

  getAllCustomersFn!: GetCustomersFn;

  subscriptions: Subscription[] = [];

  constructor(private tradeAdminService: TradeAdminService,
              private router: Router) {
  }

  async onCustomersTableEvent($event: CustomersTableComponentEvent) {
    if ($event) {
      const customerId = $event.customerId;
      await this.router.navigate(navigationUrlForCustomerDetails(customerId));
    }
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.getAllCustomersFn = this.tradeAdminService.getAllCustomersFn;
  }
}
