<div class="accreditation-documents-sub-table">
  <table mat-table [dataSource]="displayedData" multiTemplateDataRows class="width-100 mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{
        'column-width-3': column === 'action',
        'column-width-2': column === 'status',
        'column-width-1': column === 'name',
        'column-width-0': column === 'icon'
        }"> <div class="cell">{{getColumnTitles(column)}}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="cell">
            @if (column === 'name') {
              <div class="file-name">{{element?.metadata[column]}}</div>
              <div class="date-text">{{element?.properties?.lastModified | date:'yyyy-MM-dd h:mm:ss a'}}</div>
            }
            @if (column === 'status') {
              <tag-box [state]="element?.metadata[tagName]"></tag-box>
            }
            @if (column === 'action') {
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="mdi mdi-download cursor-pointer icon-margin" matTooltip="Download" (click)="onDownloadDoc(element)"></span>
                  @if (isInternalUser(user)) {
                    <span class="mdi mdi-pencil cursor-pointer icon-margin" matTooltip="Edit" (click)="onEditDoc(element)"></span>
                  }
                  @if (isInternalUser(user)) {
                    <span class="mdi mdi-check cursor-pointer icon-margin" matTooltip="Accept" (click)="onApproveDoc(element)"></span>
                  }
                  @if (isInternalUser(user)) {
                    <span class="mdi mdi-close cursor-pointer icon-margin" matTooltip="Reject" (click)="onDeclineDoc(element)"></span>
                  }
                  @if (element?.metadata[tagName] === 'Accepted' && isInternalUser(user)) {
                    <span class="file-name">Accepted by {{element?.metadata?.statuschangeuser}}</span>
                  }
                  @if (element?.metadata[tagName] === 'Rejected' && isInternalUser(user)) {
                    <span class="file-name">Rejected by {{element?.metadata?.statuschangeuser}}</span>
                  }
                </div>
                @if (isInternalUser(user)) {
                  <span class="mdi mdi-trash-can-outline cursor-pointer" matTooltip="Delete" (click)="onDeleteDoc(element)"></span>
                }
              </div>
            }
          </div>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
    </tr>
  </table>
</div>
