<div class="business-contract-component">
  <mat-card appearance="outlined">
    @if (application.ApplicationType==='BusinessLoans' && isInternalUser(loggedInUser)) {
      <mat-card>
        <business-disbursement-table
          [application]="application"
          [createNewDisbursementFn]="createNewDisbursementFn"
          [updateDisbursementFn]="updateDisbursementFn"
          [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
          [syncDisbursementToSfFn]="syncDisbursementToSfFn"
          [businessNumberSearchFn]="businessNumberSearchFn"
          [paymentChartData]="paymentChartData"
          [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
          [getBillerNameFn]="getBillerNameFn"
          [getInstitutionNameFn]="getInstitutionNameFn"
          [validateBpayFn]="validateBpayFn"
          [searchSupplierFn]="searchSupplierFn"
          [getBankDetailsFromOpportunitySfFn]="getBankDetailsFromOpportunitySfFn"
          [addAuditLogFn]="addAuditLogFn"
        ></business-disbursement-table>
      </mat-card>
    }
    <div class="break"></div>
    <mat-card>
      <asset-notes
        [onlyShowNotes]="false"
        [isAssetUpdated]="isAssetUpdated"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
      ></asset-notes>
    </mat-card>
  </mat-card>
</div>
