import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';

@Component({
    selector: 'edit-credit-flow',
    templateUrl: './edit-credit-flow.component.html',
    styleUrls: ['./edit-credit-flow.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
    ]
})
export class EditCreditFlowComponent implements OnInit {
  subscriptions: Subscription[] = [];


  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
  }
}
