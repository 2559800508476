import {AbstractRatecardResolver} from './abstract-ratecard.resolver';
import {ApplicationService} from './application.service';
import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
import {Injectable} from '@angular/core';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import {Router} from '@angular/router';

@Injectable()
export class TradeFinanceRatecardResolver extends AbstractRatecardResolver {

  constructor(applicationService: ApplicationService, dialogService:ApplicationDialogService,router:Router) {
    super(applicationService,dialogService,router);
  }

  type(): ApplicationTypes {
    return 'TradeFinance';
  }
}
