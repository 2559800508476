import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FinanceTypeValue, SummaryValue} from '@portal-workspace/grow-shared-library';
import {EntityTypeValue} from '@portal-workspace/grow-shared-library';
import {YesNoValue} from '@portal-workspace/grow-shared-library';
import {AddressComponentValue} from '@portal-workspace/grow-shared-library';
import {DirectorValue} from '@portal-workspace/grow-shared-library';
import {GuarantorValue, IndividualGuarantor} from '@portal-workspace/grow-shared-library';
import {ContactValue, SelectContactValue} from '@portal-workspace/grow-shared-library';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import {AssetCategorySelectionValue} from '@portal-workspace/grow-shared-library';
import {AssetCategoryTypeSelectionValue} from '@portal-workspace/grow-shared-library';
import {AssetSelectionComponentValue} from '@portal-workspace/grow-shared-library';
import {LoanTermValue} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue} from '@portal-workspace/grow-shared-library';
import {PaymentFrequencyValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import {PrimaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {TrusteeValue} from '@portal-workspace/grow-shared-library';
import {MemberValue} from '@portal-workspace/grow-shared-library';
import {SoleTraderValue} from '@portal-workspace/grow-shared-library';
import {PartnerValue} from '@portal-workspace/grow-shared-library';
import {AssetConditionValue} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {tap} from 'rxjs/operators';
import {LoanPurposeValue} from '@portal-workspace/grow-shared-library';
import { GuarantorsSummaryComponent } from './guarantors-summary.component';
import { SoletradersSummaryComponent } from './soletraders-summary.component';
import { MembersSummaryComponent } from './members-summary.component';
import { PartnersSummaryComponent } from './partners-summary.component';
import { TrusteesSummaryComponent } from './trustees-summary.component';
import { DirectorsSummaryComponent } from './directors-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';




@Component({
    selector: 'application-summary',
    templateUrl: './application-summary.component.html',
    styleUrls: ['./application-summary.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, CellComponent, MatDividerModule, DirectorsSummaryComponent, TrusteesSummaryComponent, PartnersSummaryComponent, MembersSummaryComponent, SoletradersSummaryComponent, GuarantorsSummaryComponent]
})
export class ApplicationSummaryComponent implements OnInit {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) summaryValue?: SummaryValue;

  _trustee?: Exclude<TrusteeValue, null>;
  _members?: Exclude<MemberValue, null>;
  _soleTraders?: (Exclude<SoleTraderValue, null>)[];
  _directors?: Exclude<DirectorValue, null>;
  _partners?: Exclude<PartnerValue, null>;

  constructor(private dialogService: ApplicationDialogService) {
  }


  ngOnInit(): void {
    if (this.summaryValue && this.summaryValue.applicants) {
      const directors: Exclude<DirectorValue, null> = [];
      const members: Exclude<MemberValue, null> = [];
      const partners: Exclude<PartnerValue, null> = [];
      const trustees: Exclude<TrusteeValue, null> = [];
      const soleTraders: Exclude<SoleTraderValue, null>[] = [];
      for (const app of this.summaryValue.applicants) {
        switch (app.kind) {
          case 'Director': {
            const director: Exclude<DirectorValue, null>[number] = app;
            directors.push(director);
            break;
          }
          case 'Partner': {
            const partner: Exclude<PartnerValue, null>[number] = app;
            partners.push(partner);
            break;
          }
          case 'Member': {
            const member: Exclude<MemberValue, null>[number] = app;
            members.push(member);
            break;
          }
          case 'Trustee': {
            const trustee: Exclude<TrusteeValue, null>[number] = app;
            trustees.push(trustee);
            break;
          }
          case 'SoleTrader': {
            const soleTrader: Exclude<SoleTraderValue, null> = app;
            soleTraders.push(soleTrader);
            break;
          }
        }
      }
      this._directors = directors.length ? directors : undefined;
      this._trustee = trustees.length ? trustees : undefined;
      this._members = members.length ? members : undefined;
      this._partners = partners.length ? partners : undefined;
      this._soleTraders = soleTraders.length ? soleTraders :undefined;
    }
  }
}
