import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BpayComponentValue,
  LocalBankAccountDetailValue,
  DisbursementBankDetails,
  DisbursementBankDetailsDialogData,
  DisbursementBankDetailsDialogResult, DisbursementPayee, DisbursementPaymentType, GetBillerNameFn, GetInstitutionNameFn,
} from '@portal-workspace/grow-shared-library';
import {
  createAccountNumberInputMask,
  createBsbInputMask,
} from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { BpayBankDetailsComponent } from '../bpay-bank-details-component/bpay-bank-details.component';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';

import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    templateUrl: './disbursement-bank-details.dialog.html',
    styleUrls: ['./disbursement-bank-details.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, LocalBankAccountDetailComponent, BpayBankDetailsComponent, FlexModule, MatButtonModule, MatDialogModule]
})
export class DisbursementBankDetailsDialog implements OnInit, AfterContentChecked {
  formGroup!: FormGroup<{
    paymentType: FormControl<DisbursementPaymentType | null>,
    directCredit: FormControl<LocalBankAccountDetailValue | null>,
    bpay: FormControl<BpayComponentValue | null>
  }>;
  formControlPaymentType!: FormControl<DisbursementPaymentType | null>;
  formControlBpay!: FormControl<BpayComponentValue | null>
  formControlDirectCredit!: FormControl<LocalBankAccountDetailValue | null>;
  bankDetails: DisbursementBankDetails | null = null;
  paymentType: DisbursementPaymentType = 'direct-debit'

  getBillerNameFn!: GetBillerNameFn;
  getInstitutionNameFn!: GetInstitutionNameFn;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DisbursementBankDetailsDialogData,
    private cdr: ChangeDetectorRef,
              private dialogRef: MatDialogRef<DisbursementBankDetailsDialog, DisbursementBankDetailsDialogResult>,
              private formBuilder: FormBuilder) {
    this.bankDetails = this.data?.bankDetails;
    this.getBillerNameFn = this.data.getBillerNameFn
    this.getInstitutionNameFn = this.data.getInstitutionNameFn
    this.formControlPaymentType = this.formBuilder.control(null, [Validators.required]);
    this.formControlBpay = this.formBuilder.control(null, [Validators.required])
    this.formControlDirectCredit = this.formBuilder.control(null, [Validators.required])
    this.formGroup = this.formBuilder.group({
      paymentType: this.formControlPaymentType,
      directCredit: this.formControlDirectCredit,
      bpay: this.formControlBpay
    })
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.populate();

    if (this.formControlPaymentType.value) {
      this.updateValidity(this.formControlPaymentType.value);
    }

    this.formControlPaymentType.valueChanges.subscribe((paymentType: DisbursementPaymentType | null) => {
      if (paymentType) {
        this.paymentType = paymentType
        this.updateValidity(paymentType)
      }
    })
  }

  updateValidity(paymentType: DisbursementPaymentType) {
    this.formControlBpay.clearValidators();
    this.formControlDirectCredit.clearValidators();
    if (paymentType === "direct-debit") {
      this.formControlDirectCredit.setValidators([Validators.required]);
      } else if (paymentType === "bpay") {
      this.formControlBpay.setValidators([Validators.required])
      }

    this.formControlBpay.updateValueAndValidity()
    this.formControlDirectCredit.updateValueAndValidity();
  }

  populate() {
    if (this.bankDetails) {
      this.formControlPaymentType.setValue(this.bankDetails.paymentType ?? 'direct-debit');
      if (this.bankDetails.paymentType === 'direct-debit') {
        const directCredit: LocalBankAccountDetailValue = {
          InstitutionName: this.bankDetails.financialInstitution ?? "",
          AccountName: this.bankDetails.bankAccountName ?? "",
          BSB: this.bankDetails.bankAccountBsb ?? "",
          AccountNumber: this.bankDetails.bankAccountNumber ?? "",
        }
        this.formControlDirectCredit.setValue(directCredit);
      } else if (this.bankDetails.paymentType === 'bpay') {
        const bpayValue: BpayComponentValue = {
          BillerCode: this.bankDetails.bpayBillerCode ?? "",
          BillerName: this.bankDetails.bpayBillerName ?? "",
          CRN: this.bankDetails.bpayCrn ?? "",
          Amount: this.bankDetails.bpayAmount ?? 0
        }
        this.formControlBpay.setValue(bpayValue)
      }
    }
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      bankDetails: this.formGroupBankDetails,
    })
  }

  get formGroupBankDetails() {
    return {
      paymentType: this.formControlPaymentType.value ?? "direct-debit",
      bpayBillerCode: this.formControlBpay.value?.BillerCode ?? "",
      bpayCrn: this.formControlBpay.value?.CRN ?? "",
      bpayBillerName: this.formControlBpay.value?.BillerName ?? "",
      bpayAmount: this.formControlBpay.value?.Amount ?? 0,
      financialInstitution: this.formControlDirectCredit.value?.InstitutionName ?? "",
      bankAccountName: this.formControlDirectCredit.value?.AccountName ?? "",
      bankAccountNumber: this.formControlDirectCredit.value?.AccountNumber ?? "",
      bankAccountBsb: this.formControlDirectCredit.value?.BSB ?? "",
    }
  }
}
