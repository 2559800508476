<div class="new-supplier-component">
  <div class="row">
    <div class="col">
      <div class="mat-h3">New supplier details</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-button-toggle-group
        [hideMultipleSelectionIndicator]="true"
        [hideSingleSelectionIndicator]="true"
        [formControl]="formControlSupplierType">
        <mat-button-toggle [value]="'commercial'">Commercial supplier</mat-button-toggle>
        <mat-button-toggle [value]="'private'">Private supplier</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  @if (formControlSupplierType.value === 'commercial') {
    <ng-container *ngTemplateOutlet="commercialSupplier"></ng-container>
  }

  @if (formControlSupplierType.value === 'private') {
    <ng-container *ngTemplateOutlet="privateSupplier"></ng-container>
  }
</div>

<ng-template #commercialSupplier>
  <div class="row">
    <div class="col-12">
      <!-- <mat-form-field>
      <mat-label>Supplier name</mat-label>
      <input matInput [formControl]="formControlSupplierName" placeholder="Enter name...">
    </mat-form-field> -->
    <name [mark]="markObservable" title="Supplier Name" [formControl]="formControlSupplierName"></name>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <custom-address
      [mark]="markObservable"
    title="Supplier address" [formControl]="formControlSupplierAddress"></custom-address>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <!-- <mat-form-field>
    <mat-label>Supplier Email</mat-label>
    <input matInput [formControl]="formControlSupplierName" placeholder="Enter Email...">
  </mat-form-field> -->
  <email [mark]="markObservable" [formControl]="formControlSupplierEmail"></email>
</div>
</div>
<div class="row">
  <div class="col-6">
    <!-- <mat-form-field>
    <mat-label>Business number</mat-label>
    <input matInput [formControl]="formControlCommercialSupplierBusinessNumber" placeholder="Enter number...">
  </mat-form-field> -->
  <mobile
    [mark]="markObservable" title="Business number"
    [onlyMobile]="false"
    [mandatory]="true" [formControl]="formControlCommercialSupplierBusinessNumber" required>
  </mobile>
</div>
<div class="col-6">
  <currency-selection
    [readonly]="true"
    [mark]="markObservable"

  [formControl]="formControlCommercialSupplierCurrencyCode"></currency-selection>
</div>
</div>

<div class="row">
  <div class="col">
    <div class="mat-h3">Supplier's bank account details</div>
  </div>
</div>
<div class="row separator-bottom">
  <div class="col-12">
    <radio-options
      [mark]="markObservable"

      [formControl]="formControlCommercialSupplierBankAccountType"
      [radioOptions]="bankTypeRadioOptions">
    </radio-options>
  </div>
</div>

@if (formControlCommercialSupplierBankAccountType.value === 'international') {
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Bank name</mat-label>
        <input matInput [formControl]="formControlCommercialSupplierInternationalBankName">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>BIC/SWIFT Code</mat-label>
        <input matInput [formControl]="formControlCommercialSupplierInternationalBankSwiftCode">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Account name</mat-label>
        <input matInput maxlength = "100" [formControl]="formControlCommercialSupplierBankAccountName">
      </mat-form-field>
    </div>
    <div class="col-6">
      <!-- <mat-form-field>
      <mat-label>Account number</mat-label>
      <input matInput [formControl]="formControlCommercialSupplierBankAccountNumber">
    </mat-form-field> -->
    <account-number [mark]="markObservable" [formControl]="formControlCommercialSupplierBankAccountNumber"></account-number>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-form-field>
      <mat-label>IBAN</mat-label>
      <input matInput [formControl]="formControlCommercialSupplierInternationalBankIban">
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <custom-google-address
      [mark]="markObservable"
    title="Address" [formControl]="formControlCommercialSupplierInternationaBanklAddress"></custom-google-address>
  </div>
</div>
}
@if (formControlCommercialSupplierBankAccountType.value === 'domestic') {
  <local-bank-account-detail [getInstitutionNameFn]="getInstitutionNameFn" [mark]="markObservable"
  [formControl]="formControlBankDetails"></local-bank-account-detail>
  <!-- <div class="row">
  <div class="col-6">
    <name [mark]="markObservable" title="Account Name" [formControl]="formControlCommercialSupplierBankAccountName"></name>
  </div>
  <div class="col-6">
    <account-number [mark]="markObservable" [formControl]="formControlCommercialSupplierBankAccountNumber"></account-number>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <bsb [formControl]="formControlCommercialSupplierDomesticBankAccountBsb" [mark]="markObservable">
    </bsb>
  </div>
</div> -->
}
</ng-template>

<ng-template #privateSupplier>
  <div class="row">
    <div class="col-12">
      <!-- <mat-form-field>
      <mat-label>Supplier full name</mat-label>
      <input matInput [formControl]="formControlSupplierName" placeholder="Enter name...">
    </mat-form-field> -->
    <name [mark]="markObservable" title="Supplier Name" [formControl]="formControlSupplierName"></name>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <custom-address
      [mark]="markObservable"
    [formControl]="formControlSupplierAddress"></custom-address>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <mobile
      [mark]="markObservable"
      [onlyMobile]="true"
    [mandatory]="true" [formControl]="formControlPrivateSupplierMobileNumber"></mobile>
  </div>
  <div class="col-6">
    <mobile
      [mark]="markObservable"
      [onlyMobile]="false"
    title="Phone number (optional)" [mandatory]="false"  [formControl]="formControlPrivateSupplierPhoneNumber"></mobile>
  </div>
</div>
</ng-template>
