<div class="bank-statement-component">

  @if (isLoading) {
    <div class="loader-padding">
      <custom-content-loader type="list" ></custom-content-loader>
    </div>
  }

  @if(!isLoading) {
    <mat-card appearance="outlined">
      @if (bankStatementMessage) {
        <div class="row separator-bottom">
          <div class="col-lg-9 col-md-12">
            <message-box type="warn">
              {{bankStatementMessage}}
            </message-box>
          </div>
        </div>
      }

      @if (!hasBankStatement) {
        <div class="row">
          <div class="col-lg-9 col-md-12">
            <mat-form-field>
              <mat-label>Copy BankStatements link</mat-label>
              <input matInput type="text" readonly [formControl]="formControlBankStatementLink">
              <span class="mdi mdi-content-copy cursor-pointer" matSuffix [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''"></span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-md-12">
            <p class="mat-body">
              If you need customers to send us their bankstatements, send them this link.
            </p>
            <p class="mat-body">
              The link allows the customer to select the independent vendors Illion or alternatively Basiq to efficiently share bank statement information.
            </p>
            <p class="mat-body">
              When customers use the link, bank statement files are sent directly to the Documents for this application.
            </p>
          </div>
        </div>
      }

      @if (hasBankStatement) {
        <div class="row">
          <div class="col-12">
            <div fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutGap="10px"
              fxLayout.lt-md="column"
              fxLayoutAlign.lt-md="center start">
              @if (isInternalUser(loggedInUser)) {
                <button mat-flat-button
                  class="w-lt-md-100"
                  (click)="refreshBankStatement()">
                  <span class="mdi mdi-refresh" matPrefix></span>
                  <span class="label"> Refresh </span>
                </button>
              }
              <button mat-flat-button color="primary"
                class="w-lt-md-100"
                [cdkCopyToClipboard]="formControlBankStatementLink.value ?? ''">
                <span class="mdi mdi-content-copy cursor-pointer"></span>
                Copy Link for New Bank Statement
              </button>
            </div>
          </div>
        </div>
        <br>
        @if(statementData) {
          <div class="row">
            <div class="col-12">
              <bank-statement-decisioning
                [selectedAccounts]="selectedAccounts"
                [data]="statementData"
                [getBsaLenderListFn]="getBsaLenderListFn"
                [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
                [application]="application"
                [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
                [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
                [saveBsaCalculatorFn]="saveBsaCalculatorFn"
                [getBsaCalculatorFn]="getBsaCalculatorFn"
                [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
                [createApplicationNoteFn]="createApplicationNoteFn"
                [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
                [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
              ></bank-statement-decisioning>
            </div>
          </div>
        }
        }
      </mat-card>
  }
  </div>
