import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Accreditation, AccreditationWebService, ApproveAccreditationFn, CreditDecisioningReasonDialogResult, CreditDeclineDialogResult, DeclineAccreditationFn, DownloadCreditorWatchReportFn, GetAccreditationWebServicesFn } from '@portal-workspace/grow-shared-library';

import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { AccreditationWebServicesComponent } from './accreditation-web-services.component';
import { Subscription } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import moment from 'moment';

@Component({
    selector: 'accreditation-credit',
    templateUrl: './accreditation-credit.component.html',
    styleUrls: ['./accreditation-credit.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule, FlexModule, MatButtonModule, AccreditationWebServicesComponent]
})
export class AccreditationCreditComponent implements OnInit {
  subscriptions: Subscription[] = [];
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) accreditation!: Accreditation;
  @Input({required: true}) getAccreditationWebServicesFn!: GetAccreditationWebServicesFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) approveAccreditationFn!: ApproveAccreditationFn;
  @Input({required: true}) declineAccreditationFn!: DeclineAccreditationFn;
  @Output() creditStatusUpdate = new EventEmitter;

  webServices: AccreditationWebService[] = [];

  constructor(
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {

  }

  ngOnInit() {
    console.log(this.accreditation);

    this.subscriptions.push(
      this.getAccreditationWebServicesFn(this.accreditation.AccreditationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(result => {
        console.log('==web services: ', result)
        this.webServices = result;
      })
    )
  }

  onApprove() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Credit Approve",
        subMessage: "Are you sure to approve this accreditation?",
      }).afterClosed().subscribe(result => {
        if (result && result.readyForSubmission) {
          this.approveAccreditationFn({
            accreditationId: this.accreditation.AccreditationId,
            creditApprovalDate: moment().format('YYYY-MM-DD'),
            creditApprovalTime: moment().format('YYYY-MM-DD hh:mm a'),
          }).pipe(
            this.toastService.spinnerObservable(),
            this.toastService.snackBarObservable(`Accreditation Approved`),
          ).subscribe(() => {
            this.creditStatusUpdate.emit();
          })
        }
      })
    )
  }

  onDecline() {
    this.subscriptions.push(
      this.applicationDialogService.openCreditDeclineDialog({})
      .afterClosed().subscribe((result: CreditDeclineDialogResult | undefined) => {
          if (result && result.readyForSubmission) {
            this.declineAccreditationFn({
              accreditationId: this.accreditation.AccreditationId,
              reasonSelection: result.reasonSelection,
              reason: result.reason,
              category: result.category,
              closedLostTime: moment().format('YYYY-MM-DD hh:mm a'),
            }).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Accreditataion Declined`),
            ).subscribe(() => {
              this.creditStatusUpdate.emit();
            })
          }
        }
      )
    )
  }
 
}
