import {Component, Input} from '@angular/core';
import {
  PersonalAsset,
  PersonalAssetsValue,
  PersonalNonPropertyAsset,
  PersonalPropertyAsset
} from '@portal-workspace/grow-shared-library';
import {
  PersonalLiabilitiesValue,
  PersonalLiability,
  PersonalMortgageLiability, PersonalNonMortgageLiability
} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';



@Component({
    selector: 'liabilities-summary',
    template: `
    @if (liabilities) {
      <div class="row">
        <div class="col">
          <div class="mat-h3">Personal liabilities</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>Type</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Address</div>
          </cell>
        </div>
        <div class="col-3">
          <cell>
            <div label>Value</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @for (liability of liabilities.liabilities; track liability; let index = $index) {
        <div class="row">
          <div class="col-3">
            <cell>
              <div body>{{liability.liabilityType.name}}</div>
            </cell>
          </div>
          <div class="col-6">
            <cell>
              <div body>{{financierOrAddress(liability)}}</div>
            </cell>
          </div>
          <div class="col-3">
            <cell>
              <div body>
                <span class="mdi mdi-currency-usd"></span>{{liability.value}}
              </div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6"></div>
        <div class="col-3">
          <cell>
            <div body><span class="mdi mdi-currency-usd"></span>{{liabilities.total}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
    
    `,
    standalone: true,
    imports: [CellComponent, MatDividerModule]
})
export class LiabilitiesSummaryComponent {

  @Input({required: true}) liabilities?: PersonalLiabilitiesValue;

  financierOrAddress(liability: PersonalLiability): string {
    if (liability.liabilityType.type === 'mortgage') {
      return (liability as PersonalMortgageLiability).address?.address ?? '';
    }
    return (liability as PersonalNonMortgageLiability).financier;
  }
}
