import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';

@Component({
    selector: 'data-box',
    templateUrl: './data-box.component.html',
    styleUrls: ['./data-box.component.scss'],
    standalone: true,
    imports: [NgClass, ExtendedModule]
})
export class DataBoxComponent implements OnChanges {
  @Input({required: false}) type: 'green' | 'black' | 'red' = 'green';
  @Input({required: false}) icon?: string;
  @Input({required: false}) title: string = '';
  @Input({required: false}) data: string = '';

  @Input({required: false}) clickable = true;
  @Input({required: false}) selected = false;

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.selected) {
    //   const simpleChange = changes.selected;
    //   this.selected = simpleChange.currentValue;
    // }
  }

}
