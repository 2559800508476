import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AssetCatAndTypeService} from './asset-cat-and-type.service';
import {AssetCategoryTypeSelectionComponent} from './asset-category-type-selection.component';
import {AssetCategorySelectionComponent} from './asset-category-selection.component';
import {AssetSelectionComponent} from './asset-selection.component';
import {AssetMakeComponent} from './asset-make.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {AssetConditionComponent} from './asset-condition.component';
import {PpsrAssetComponent} from './ppsr-asset.component';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {PpsrAssetSupplierSearchComponent} from './ppsr-asset-supplier-search.component';
import {AddressModule} from '../address-component/address.module';
import {BusinessSearchModule} from '../business-search-component/business-search.module'
import {MessageBoxModule} from '../message-box/message-box.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {MobileModule} from '../mobile-component/mobile.module'
import {NameModule} from '../name-component/name.module';
import {BsbModule} from '../bsb-component/bsb.module';
import {AccountNumberModule} from '../account-number-component/account-number.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {RedbookDetailsComponent} from './redbook-details.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        CurrencySelectionModule,
        AddressModule,
        BusinessSearchModule,
        MessageBoxModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        MobileModule,
        NameModule,
        BsbModule,
        AccountNumberModule,
        PipeLibraryModule,
        AssetCategoryTypeSelectionComponent,
        AssetCategorySelectionComponent,
        AssetSelectionComponent,
        AssetMakeComponent,
        AssetConditionComponent,
        PpsrAssetComponent,
        PpsrAssetSupplierSearchComponent,
        RedbookDetailsComponent
    ],
    exports: [
        AssetCategoryTypeSelectionComponent,
        AssetCategorySelectionComponent,
        AssetSelectionComponent,
        AssetMakeComponent,
        AssetConditionComponent,
        PpsrAssetComponent,
        PpsrAssetSupplierSearchComponent,
        RedbookDetailsComponent,
    ],
    providers: [
        { provide: AssetCatAndTypeService, useClass: AssetCatAndTypeService },
    ]
})
export class AssetCatAndTypeModule {

}
