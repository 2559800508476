<div class="pismo-account-transactions-dialog m-4">
  <h3 mat-dialog-title>Pismo transactions</h3>
  <mat-dialog-content>
    <ng-container>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2" matSort>
        @for (column of displayedColumns; track column) {
          <ng-container matColumnDef="{{column}}">
            <th mat-header-cell *matHeaderCellDef>{{getColumnTitles(column)}}</th>
            <td mat-cell *matCellDef="let element">
              @if (column === 'processing_code') {
                {{element[column]}}
              }
              @if (column === 'processing_description') {
                {{element[column]}}
              }
              @if (column === 'id') {
                {{element[column]}}
              }
              @if (column === 'created_at') {
                {{element[column] | looseDate}}
              }
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row mt-3">
                <div class="col">
                  <div class="row pt-0">
                    <div class="col-12">
                      <div class="description ml-5">Amount Details</div>
                      <div class="row pt-0 ml-5">
                        @for (amount of element.amount; track amount) {
                          <div class="col-3 ">
                            <div class="title">Type</div>
                            <div class="description">{{amount.type }}</div>
                          </div>
                          <div class="col-3">
                            <div class="title">Currency</div>
                            <div class="description">{{amount.currency}}</div>
                          </div>
                          <div class="col-3">
                            <div class="title">Value</div>
                            <div class="description">{{amount.value }}</div>
                          </div>
                        }

                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="col-12">
                      <div class="description ml-5">Transaction Type Details</div>
                      <div class="row pt-0 ml-5">
                        <div class="col-3 ">
                          <div class="title">Id</div>
                          <div class="description">{{element.transaction_type.id }}</div>
                        </div>
                        <div class="col-3">
                          <div class="title">Description</div>
                          <div class="description">{{element.transaction_type.description}}</div>
                        </div>
                        <div class="col-3">
                          <div class="title">Credit</div>
                          <div class="description">{{element.transaction_type.credit }}</div>
                        </div>
                        <div class="col-3">
                          <div class="title">Posted Transaction</div>
                          <div class="description">{{element.transaction_type.posted_transaction
                          }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
        (click)="expandedElement = null"></tr>
      </table>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <!-- <button mat-flat-button color="primary" (click)="onSave($event)">Save</button> -->
    </div>
  </mat-dialog-actions>
</div>

