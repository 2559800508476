import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EligibilityComponent} from './eligibility.component';
import { MessageBoxModule } from '../message-box/message-box.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MessageBoxModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        EligibilityComponent
    ],
    exports: [
        EligibilityComponent,
    ]
})
export class EligibilityModule {

}
