import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ApiResponse, Application, ApplicationWebService, DirectDebitDialogData, DirectDebitDialogResult, EditBrokerageDialogData, EditBrokerageDialogResult, EditInvoiceAmountDialogData, EditInvoiceAmountDialogResult, FinanceType, LocalBankAccountDetailValue, ManualDirectDebitFn, NewDirectPaymentBody, OverdraftDirectDebitDialogData, OverdraftDirectDebitDialogResult, PismoOnetimeRepaymentBody, SelectWebServicesDialogData, SelectWebServicesDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, distinctUntilChanged, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import _ from "lodash";
import { ApplicationDialogService } from './application-dialog.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './select-web-services.dialog.html',
    styleUrls: ['./select-web-services.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule, InputMaskModule, NgxCurrencyDirective, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, CurrencyInputComponent]
})
export class SelectWebServicesDialog implements OnInit {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  webServices: ApplicationWebService[] = [];
  application!: Application;
  checkBoxes: {id: number, check: boolean, webService: ApplicationWebService, applicant: string}[] = [];
 
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SelectWebServicesDialogData,
              private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService,
              private dialogRef: MatDialogRef<SelectWebServicesDialog, SelectWebServicesDialogResult>) {
    this.webServices = dialogData.webServices;
    this.application = dialogData.application;
    this.checkBoxes = this.webServices.map(webService => { 
      let applicant = '';
      if (webService.level === 'individual') {
        const individual = this.application.Individuals.find(individual => individual.id === webService.individualId);
        if (individual) {
          applicant = `${individual.GivenName} ${individual.SurName} (${individual.DoB})`;
        }
      } else {
        const entity = this.application.CommercialEntities.find(entity => webService.ABN ? (webService.ABN === entity.ABN) : (webService.ACN === entity.ACN))
        if (entity) {
          applicant = `${entity.LegalName}`;
        }
      }

      return {
        id: webService.id,
        check: false,
        webService: webService,
        applicant: applicant,
      }
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  submit() {
    this.dialogRef.close({
      webServiceIds: this.checkBoxes.filter(obj => obj.check).map(obj => obj.id)
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  update(check: boolean, index: number) {
    this.checkBoxes[index] = {
      ...this.checkBoxes[index],
      check: check
    }
  }
}
