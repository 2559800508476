import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {pxToRem } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';

import {compareMatch, CurrencyCode, CurrencySelectionValue} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSelectModule } from '@angular/material/select';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

const flagMappings: {currencyCode: CurrencyCode, image: string }[] = [
  { currencyCode: 'EUR' , image: '/assets/images/currencies/eur.svg'},
  { currencyCode: 'BGN' , image: '/assets/images/currencies/bgn.svg'},
  { currencyCode: 'BRL' , image: '/assets/images/currencies/brl.svg'},
  { currencyCode: 'RUB' , image: '/assets/images/currencies/rub.svg'},
  { currencyCode: 'RON' , image: '/assets/images/currencies/ron.svg'},
  { currencyCode: 'JPY' , image: '/assets/images/currencies/jpy.svg'},
  { currencyCode: 'GBP' , image: '/assets/images/currencies/gbp.svg'},
  { currencyCode: 'HRK' , image: '/assets/images/currencies/hrk.svg'},
  { currencyCode: 'HUF' , image: '/assets/images/currencies/huf.svg'},
  { currencyCode: 'HKD' , image: '/assets/images/currencies/hkd.svg'},
  { currencyCode: 'PHP' , image: '/assets/images/currencies/php.svg'},
  { currencyCode: 'PLN' , image: '/assets/images/currencies/pln.svg'},
  { currencyCode: 'ZAR' , image: '/assets/images/currencies/zar.svg'},
  { currencyCode: 'MYR' , image: '/assets/images/currencies/myr.svg'},
  { currencyCode: 'MXN' , image: '/assets/images/currencies/mxn.svg'},
  { currencyCode: 'ILS' , image: '/assets/images/currencies/ils.svg'},
  { currencyCode: 'NOK' , image: '/assets/images/currencies/nok.svg'},
  { currencyCode: 'NZD' , image: '/assets/images/currencies/nzd.svg'},
  { currencyCode: 'CHF' , image: '/assets/images/currencies/chf.svg'},
  { currencyCode: 'CNY' , image: '/assets/images/currencies/cny.svg'},
  { currencyCode: 'CAD' , image: '/assets/images/currencies/cad.svg'},
  { currencyCode: 'CZK' , image: '/assets/images/currencies/czk.svg'},
  { currencyCode: 'KRW' , image: '/assets/images/currencies/krw.svg'},
  { currencyCode: 'SGD' , image: '/assets/images/currencies/sgd.svg'},
  { currencyCode: 'SEK' , image: '/assets/images/currencies/sek.svg'},
  { currencyCode: 'DKK' , image: '/assets/images/currencies/dkk.svg'},
  { currencyCode: 'USD' , image: '/assets/images/currencies/usd.svg'},
  { currencyCode: 'TRY' , image: '/assets/images/currencies/try.svg'},
  { currencyCode: 'THB' , image: '/assets/images/currencies/thb.svg'},
  { currencyCode: 'AUD' , image: '/assets/images/currencies/aud.svg'},
  { currencyCode: 'INR' , image: '/assets/images/currencies/inr.svg'},
  { currencyCode: 'IDR' , image: '/assets/images/currencies/idr.svg'},
];
const CURRENCY_FLAG_MAPPINGS = flagMappings.sort((a,b) => a.currencyCode<b.currencyCode ? -1 : 1);

@UntilDestroy()
@Component({
    selector: 'currency-selection',
    templateUrl: './currency-selection.component.html',
    styleUrls: ['./currency-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencySelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CurrencySelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, NgClass, ExtendedModule, MatSelectModule, FlexModule, DisableControlDirective, MatOptionModule]
})
export class CurrencySelectionComponent extends AbstractControlValueAccessor<CurrencySelectionValue> implements  OnInit, Mark {

  pxToRem = pxToRem;

  subscriptions: Subscription[] = [];

  @Input({required: false}) title = 'Currency';
  @Input({required: false}) readonly = false;

  formGroup: FormGroup<{
    currency: FormControl<CurrencyCode|null>
  }>;
  formControl: FormControl<CurrencyCode|null>;

  flagMappings = CURRENCY_FLAG_MAPPINGS;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      currency: this.formControl,
    });
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  ngOnInit() {
    setupUntilDestroy(this);
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  doWriteValue(v: CurrencySelectionValue | null | undefined): void | CurrencySelectionValue {
    if (v) {
      this.formControl.setValue(v);
    }
    return undefined;
  }

  getImage(currencyCode: CurrencyCode): string {
    const mapping =  flagMappings.find(f => f.currencyCode === currencyCode);
    if (mapping) {
      return mapping.image;
    }
    return '';
  }



}
