<form class="primary-industry-selection-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Primary industry</mat-label>
    <mat-select [formControl]="formControl" placeholder="Select industry..." [compareWith]="compareWithFn">
      @for (primaryIndustry of primaryIndustries; track primaryIndustry) {
        <mat-option [value]="primaryIndustry">
          {{primaryIndustry.division}} {{primaryIndustry.name}}
        </mat-option>
      }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</form>


