import {NgModule} from '@angular/core';
import {TopMenuComponent} from './top-menu.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TopMenuService} from './top-menu.service';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { DirectApplicationTopMenuComponent } from './direct-application-top-menu.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        TopMenuComponent,
        DirectApplicationTopMenuComponent,
    ],
    exports: [
        TopMenuComponent,
        DirectApplicationTopMenuComponent,
    ],
    providers: [
        { provide: TopMenuService, useClass: TopMenuService },
    ]
})
export class TopMenuModule {


}
