import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  AddAuditLogFn,
  Address2,
  ApiResponse,
  AssetFinanceApplication,
  AssetSearchBody,
  AssetSettlementGetApplicationAssetFn,
  CommercialEntity,
  CreateApplicationNoteFn,
  CreateAssetInspectionFn,
  CreateNewDisbursementFn,
  DownloadVerimotoReportFn,
  filesToBase64Files,
  GetApplicationOwnerFn,
  getAssetCategories,
  getAssetCategory,
  getAssetCategoryType, GetAssetInspectionsForApplicationFn, GetBillerNameFn, GetDisbursementByApplicationIdFn,
  GetInstitutionNameFn,
  PartialOrNull,
  PayloadApiResponse, PaymentChartData,
  PpsrDataRaw,
  RemoveApplicationNoteByNoteIdFn,
  SearchGrantorsResult,
  SendAssetToSfData,
  SendAssetToSFResult, SyncBankDetailsToSfFn, SyncDepositPaidToSfFn, SyncDisbursementToSfFn, SyncPrivateSellerBankDetailsToSfFn, UpdateDisbursementFn,
  UpdateDocumentsBody,
  ValidateBPAYFn,
  VerimotoInspectionTableDataWithInspectionDetails,
  VerimotoInspectionTypeSelection,
  VerimotoLenderType
} from '@portal-workspace/grow-shared-library';
import {
  applicationToAbn,
  applicationToAcn,
  applicationToApplicants,
  applicationToAsset,
  applicationToGuarantors,
  applicationToPrivateSales,
  getUser,
  createAbnInputMask,
  createAcnInputMask,
  BusinessSearchFn,
  SearchSupplierFn,
  SaveSupplierFn,
  PpsrAssetSupplierSearchEvent,
  ListApplicationDocumentFn, DownloadApplicationDocumentUrlFn,
} from '@portal-workspace/grow-ui-library';
import {
  stringifyJSON,
  primaryCommercialEntity,
  isInternalUser,
} from '@portal-workspace/grow-shared-library';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GuarantorValue} from '@portal-workspace/grow-shared-library';
import {AssetSelectionComponentValue} from '@portal-workspace/grow-shared-library';
import {PpsrAsset, PpsrData, PpsrDocument, VehicleDetails} from '@portal-workspace/grow-shared-library';
import {catchError, Observable, of, Subject, Subscription} from 'rxjs';
import {delay,tap} from 'rxjs/operators';
import moment from 'moment';
import {PpsrAssetComponentEvent, } from '@portal-workspace/grow-ui-library';
import {PpsrAssetValue} from '@portal-workspace/grow-shared-library';
// import {PpsrAssetSupplierSearchEvent, } from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PortalHotToastService} from '../../portal-hot-toast-component/hot-toast.service';
import {ApplicationDialogService} from '../../application-dialog-component/application-dialog.service';
import {isAssetVehicles} from '@portal-workspace/grow-shared-library';
import {Base64File, NotNullable, User} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {GetApplicationAuditLogsFn} from '../../asset-listing-component/audit-log.component';
import {GetNotesByApplicationIdFn} from '../../asset-listing-component/notes.component';
import { AssetNotesComponent } from '../../asset-listing-component/asset-notes.component';
import { DisbursementTableComponent } from '../../asset-listing-component/disbursement-table.component';

import { AssetDetailsComponent } from '../../asset-listing-component/asset-details.component';
import { MatCardModule } from '@angular/material/card';
import { AssetInspectionsComponent } from '../../asset-listing-component/asset-inspections.component';

export type AssetSettlementGetApplicationPpsrInfoFn = (applicationId: number) => Observable<[PpsrData | null, PpsrAsset[], PpsrDocument[]]>;
export type AssetSettlementRemoveApplicationAssetFn = (assetId: number) => Observable<ApiResponse>;
export type AssetSettlementSearchAssetsFn = (applicationId: number, data: {SearchNumber: string|undefined, NumberType: string|undefined}) => Observable<VehicleDetails | {Error: any} | null>;
// export type AssetSettlementSubmitPpsrFn = (applicationId: number, data: {
//   ID: string,
//   Type: 'Consumer' | 'Commercial',
//   Grantors: ({ OrganisationNumber: string, OrganisationNumberType: 'ABN' | 'ACN' } | { FamilyName: string, GivenNames: string, DateOfBirth: string /*YYYY-MM-DDT00:00:00*/ })[],
//   Assets: ({ CollateralClassType: 'MotorVehicle', SerialNumberType: string |undefined| null/* no space*/, SerialNumber: string|undefined | null, RegistrationEndTime: string |undefined/*YYYY-MM-DDT23:59:59*/, CollateralDescription: string |undefined | null} | { CollateralClassType: 'OtherGoods', RegistrationEndTime: string|undefined /*YYYY-MM-DDT23:59:59*/ })[],
// }) => Observable<any>;
export type AssetSettlementSubmitPpsrFn = (applicationId: number) => Observable<any>;
// export type AssetSettlementGetApplicationAssetFn = (applicationId: number) => Observable<PpsrAsset[]>;
export type AssetSettlementSendAssetToSfFn = (sfData: SendAssetToSfData) => Observable<PayloadApiResponse<SendAssetToSFResult[]>>;
export type AssetSettlementGetPpsrDetailsFn = (applicationId: number) => Observable<PpsrData | null>;
export type AssetSettlementAddPpsrDetailFn = (data: {
  ApplicationId: number,
  Grantors: string,
}) => Observable<number>;
export type AssetSettlementUpdatePpsrDetailFn = (data: {
  ApplicationId: number,
  Grantors: string,
}) => Observable<ApiResponse>;
export type AssetSettlementUpdateApplicationAssetsFn = (data: PpsrAsset) => Observable<number>;
export type AssetSettlementSubmitApplicationAssetsFn = (data: PpsrAsset) => Observable<number>;
export type AssetSettlementSearchGrantorsFn = (applicationId: number, data: AssetSearchBody) => Observable<PayloadApiResponse<SearchGrantorsResult>>;
export type AssetSettlementGetPpsrDocumentsFn = (applicationId: number) => Observable<PpsrDocument[]>;
export type AssetSettlementSavePpsrDocumentFn = (applicationId: number, data: UpdateDocumentsBody) => Observable<ApiResponse>;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-settlement',
    templateUrl: './asset-settlement.component.html',
    styleUrls: ['./asset-settlement.component.scss'],
    providers: [
        { provide: MARK, useExisting: forwardRef(() => AssetSettlementComponent) },
    ],
    standalone: true,
    imports: [MatCardModule, AssetDetailsComponent, DisbursementTableComponent, AssetNotesComponent, AssetInspectionsComponent]
})

export class AssetSettlementComponent implements OnInit,Mark, OnChanges {

  @Input() getApplicationPpsrInfoFn!: AssetSettlementGetApplicationPpsrInfoFn;
  @Input() removeApplicationAssetFn!: AssetSettlementRemoveApplicationAssetFn;
  @Input() searchAssetFn!: AssetSettlementSearchAssetsFn;
  @Input() submitPpsrFn!: AssetSettlementSubmitPpsrFn;
  @Input() getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input() sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  @Input() getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input() addPpsrDetailFn!: AssetSettlementAddPpsrDetailFn;
  @Input() updatePpsrDetailFn!: AssetSettlementUpdatePpsrDetailFn;
  @Input() updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  @Input() submitApplicationAssetsFn!: AssetSettlementSubmitApplicationAssetsFn;
  @Input() searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input() getPpsrDocumentsFn!: AssetSettlementGetPpsrDocumentsFn;
  @Input() savePpsrDocumentFn!: AssetSettlementSavePpsrDocumentFn;
  @Input() disableControl: boolean = false;
  @Input() searchSupplierFn!: SearchSupplierFn;
  @Input() saveSupplierFn!: SaveSupplierFn;
  @Input() businessSearchFn!: BusinessSearchFn;
  @Input() getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() assetSettlementSearchAssetsFn!: AssetSettlementSearchAssetsFn;
  @Input() listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input() downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input() createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input() updateDisbursementFn!: UpdateDisbursementFn;
  @Input() getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input() syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input() syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input() syncPrivateSellerBankDetailsToSfFn!: SyncPrivateSellerBankDetailsToSfFn;
  @Input() syncDepositPaidToSfFn!: SyncDepositPaidToSfFn;
  @Input() getBillerNameFn!: GetBillerNameFn;
  @Input() getInstitutionNameFn!: GetInstitutionNameFn;
  @Input() validateBpayFn!: ValidateBPAYFn;
  @Input() getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input() createAssetInspectionFn!: CreateAssetInspectionFn;
  @Input() getAssetInspectionsForApplicationFn!: GetAssetInspectionsForApplicationFn;
  @Input() downloadVerimotoReportFn!: DownloadVerimotoReportFn;
  @Input() paymentChartData!: PaymentChartData;
  @Input() apiUrl!: string;
  @Input() application!: any;
  @Input() isPpsrChecked!: boolean;
  @Input() isPpsrRegistered!: boolean;
  @Input() sendToSF: boolean = false;
  @Input() assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Input() verimotoLender!: VerimotoLenderType;
  @Input() verimotoAssetInspectionTypes!: VerimotoInspectionTypeSelection[];
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;
  @Output() supplierEvent: EventEmitter<PpsrAssetSupplierSearchEvent> = new EventEmitter<PpsrAssetSupplierSearchEvent>();
  @Output() onEditAssetEvent: EventEmitter<PpsrAssetValue> = new EventEmitter<PpsrAssetValue>();
  @Output() emptyAsset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() settleLoan: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ppsrStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteAsset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newAssetInspection: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @ViewChild(AssetNotesComponent) assetNotesComponent!: AssetNotesComponent;
  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();

  ready = false;
  showIndividuals=false;
  formGroup: UntypedFormGroup;
  entityFormGroup: UntypedFormGroup;
  formArrayIndividuals: UntypedFormArray; // array of formgroup
  formArrayEntities: UntypedFormArray; // array of formgroup
  formArrayAssets: UntypedFormArray; // array of formcontrol
  formControlPPSRDocuments: UntypedFormControl
  formControlSupplier: UntypedFormControl
  assetSubmitted: boolean|undefined |null= false;
  applicationAssets: any;
  grantorDetails: any = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
  isCompanyOrEntityOrg = false;
  asset: AssetSelectionComponentValue = null;
  PPSRDocs: any=[];
  showSummaryTable:any=false
  loggedInUser: User | null = getUser()
  hasTrustee: boolean = false;
  disableControls: boolean = false;
  isPPSRsubmitted:boolean =false;
  isSFsubmitted:boolean =false
  allowPPSR:boolean=true
  allowSF:boolean=true;
  isAssetUpdated!: Boolean;
  displayedColumns: string[] = ['name', 'download'];

  // isAddAsset:boolean=false;

  isInternalUser = isInternalUser;

  constructor(private formBuilder: UntypedFormBuilder,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              ) {
    this.formArrayEntities = formBuilder.array([]);
    this.formArrayIndividuals = formBuilder.array([]);
    this.formArrayAssets = formBuilder.array([]);
    this.formGroup = formBuilder.group({
      entities: this.formArrayEntities,
      individuals: this.formArrayIndividuals,
      assets: this.formArrayAssets,
    });
    this.entityFormGroup = formBuilder.group({
      'organisationNameABN': new UntypedFormControl(''),
      'organisationNameACN': new UntypedFormControl(''),
      'ACN': new UntypedFormControl(''),
      'ABN': new UntypedFormControl(''),
    });
    this.formControlPPSRDocuments = this.formBuilder.control(null,[Validators.required]);
    this.formControlSupplier = this.formBuilder.control(null);
  }

  reload() {
    this.ready = false;
    this.formArrayAssets.clear();
    this.formArrayIndividuals.clear();
    this.formArrayEntities.clear();
    const applicationId = this.application.ApplicationId;
    // combineLatest([
    //   this.ppsrService.getPpsrDetails(applicationId).pipe(map(r => r?.payload)),
    //   this.ppsrService.getApplicationAsset(applicationId).pipe(map(r => r?.payload)),
    //   this.ppsrService.getPpsrDocuments(applicationId).pipe(map(r => r?.payload)),
    // ]).pipe(
    this.subscriptions.push(this.getApplicationPpsrInfoFn(applicationId).pipe(
      delay(0),
      tap(r => {
        const r1: PpsrData | null = r[0] ?? null;
        const r2: PpsrAsset[] = r[1] ?? [];
        const r3: PpsrDocument[] = r[2] ?? [];
        if (r2 === null) {
          this.isPPSRsubmitted = false
          this.isSFsubmitted = false
        }
        if (r1 == null) {
          this.isPPSRsubmitted = false
        } else if (r1.PPSRResponse !== null) {
          this.isPPSRsubmitted = true
        }
        //ppsr details
        this.showSummaryTable = r1?.isCompanySearch
        const companySummary = r1?.companySummary
        if (this.showSummaryTable) {
          this.grantorDetails.Account = companySummary?.Account
          this.grantorDetails.MotorVehicleCount = companySummary?.MotorVehicleCount
          this.grantorDetails.OtherGoodsCount = companySummary?.OtherGoodsCount
          this.grantorDetails.AllPapNoExceptCount = companySummary?.AllPapNoExceptCount
          this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
        }

        if (r3.length > 0) {
          for (let i = 0; i < r3.length; i++) {
            if (r3[i].isPPSRDoc) {
              this.PPSRDocs.push(r3[i])
            }
          }
        }
        console.log('***** asset-settlement', r1, r2, r3, this.PPSRDocs);
        // ===== assets
        if (r2 && r2.length) {

          if (r2[0].AssetSFRes === 'undefined' || r2[0].AssetSFRes === 'null' || r2[0].AssetSFRes === '' || r2[0].AssetSFRes === null) {
            this.isSFsubmitted = false
          } else {
            this.isSFsubmitted = true
          }
          this.assetSubmitted = r2[0].isAssetSubmitted
          this.applicationAssets = r2;
          for (const ppsrAsset of r2) {

            let assetCat:any=ppsrAsset.SettlementAssetDetails?.assetCategory
            const _assetCategory = getAssetCategory(assetCat);
            let assetType:any=ppsrAsset.SettlementAssetDetails?.assetType
            const  _assetType = getAssetCategoryType(assetCat, assetType);
            const as: PpsrAssetValue = {
              assetCategory: _assetCategory, // { index: assetCat, value: ''},
              assetType: _assetType, // {index: assetType, value: ''},
              description: ppsrAsset.SettlementAssetDetails?.description ?? '',
              serialNumberType: ppsrAsset.SettlementAssetDetails?.SerialNumberType ?? '',
              serialNumber: ppsrAsset.SettlementAssetDetails?.serialNumber ?? '',
              invoicePrice: parseFloat(String(ppsrAsset.SettlementAssetDetails?.InvoicePrice)),
              gst: parseFloat(String(ppsrAsset.SettlementAssetDetails?.GST)),
              AssetId:ppsrAsset.AssetId ?? null,
              year: ppsrAsset.SettlementAssetDetails?.year ?? null,
              supplier: ppsrAsset.SettlementAssetDetails?.Supplier!,
              salesforceId: ppsrAsset.SalesForceId ?? null,
              vehicleDetails: ppsrAsset.SettlementAssetDetails?.VehicleDetails,
              assetNumber: null,
              // vehicleDetails: {
              //   SupplierName: ppsrAsset.SettlementAssetDetails.supplier?.SupplierName ?? '',
              //   ABN: ppsrAsset.SettlementAssetDetails.supplier?.ABN,
              //   Contact: ppsrAsset.SettlementAssetDetails.supplier.Contact,
              //   SalesForceId: ppsrAsset.SettlementAssetDetails.supplier.SalesForceId,
              //   SupplierAddress: {UnformattedAddress: ppsrAsset.SettlementAssetDetails.supplier?.SupplierAddress?.RawAddress} as any,
              //   SupplierType: ppsrAsset.SettlementAssetDetails.supplier.SupplierType,
              //   isDeleted: false,
              //   AssetSupplierId: ppsrAsset.SettlementAssetDetails.supplier.SupplierId,
              // },
            };
            const fc = this.formBuilder.control(as, [Validators.required]);
            this.formArrayAssets.push(fc);
            if(this.assetSubmitted){
             //disable asset details
            this.disableControls=true;
            }
          }
        } else if (this.asset) {
          this.allowPPSR = false;
          this.allowSF = false;
          const as: PpsrAssetValue = {
            assetCategory: this.asset.category,
            assetType: this.asset.type,
            description: this.asset.description,
            serialNumberType: '',
            serialNumber: '',
            invoicePrice: 0,
            gst: 0,
            year: 0,
            supplier: null as any,
            AssetId:null,
            assetNumber: null,
            salesforceId: null,
            vehicleDetails: null
          };
          const fc = this.formBuilder.control(as, [Validators.required]);
          this.formArrayAssets.push(fc);
        }

        // =========== entities
        if (this.isCompanyOrEntityOrg) {
          for (const entity of this.application.CommercialEntities) {
            const formControlAcn = this.formBuilder.control(entity.ACN, [Validators.required]);
            const formControlAbn = this.formBuilder.control(entity.ABN);
            const formControlOrganisationName = this.formBuilder.control(entity.LegalName, [Validators.required]);
            let formGroup:any
            let primaryPTY = entity.Type==='Primary' && entity.EntityType==='PTY'
            if (entity.Type === 'Trustee' || primaryPTY) {
              formGroup = this.formBuilder.group({
                // abn: formControlAbn,
                acn: formControlAcn,
                organisationName: formControlOrganisationName,
              });
              this.entityFormGroup.get('ACN')?.setValue(formControlAcn.value)
              this.entityFormGroup.get('organisationNameACN')?.setValue(formControlOrganisationName.value)
            } else {
              formControlAcn.clearValidators();
              formControlAcn.updateValueAndValidity();
              formGroup = this.formBuilder.group({
                abn: formControlAbn,
                acn: formControlAcn,
                organisationName: formControlOrganisationName,
              })
              this.entityFormGroup.get('ABN')?.setValue(formControlAbn.value)
              this.entityFormGroup.get('organisationNameABN')?.setValue(formControlOrganisationName.value)
            }
            this.formArrayEntities.push(formGroup);
          }
        } else {
          const primary = primaryCommercialEntity(this.application);
          if (primary) {
            // const formControlAcn = this.formBuilder.control(primary.ACN);
            const formControlAbn = this.formBuilder.control(primary.ABN, [Validators.required]);
            const formControlOrganisationName = this.formBuilder.control(primary.LegalName, [Validators.required]);
            const formGroup = this.formBuilder.group({
              abn: formControlAbn,
              // acn: formControlAcn,
              organisationName: formControlOrganisationName,
            });
            this.entityFormGroup.get('ABN')?.setValue(formControlAbn.value)
            this.entityFormGroup.get('organisationNameABN')?.setValue(formControlOrganisationName.value)
            this.formArrayEntities.push(formGroup);
          }
        }

        // ===================== individuals
        if (this.showIndividuals) {
          if (r1 && r1.Grantors && r1.Grantors.length) {
            for (const grantor of r1.Grantors) {
              switch (grantor.type) {
                case 'Individual':
                  const formControlLastName = this.formBuilder.control(grantor.FamilyName, [Validators.required]);
                  const formControlFirstName = this.formBuilder.control(grantor.GivenNames, [Validators.required]);
                  const formControlDob = this.formBuilder.control(moment(grantor.DateOfBirth, 'YYYY-MM-DD'), [Validators.required]);
                  const formGroup: UntypedFormGroup = this.formBuilder.group({
                    lastName: formControlLastName,
                    firstName: formControlFirstName,
                    dob: formControlDob,
                  });
                  this.formArrayIndividuals.push(formGroup);
                  break;
              }
            }
          } else if (this.application.Individuals && this.application.Individuals.length) {
            for (const individual of this.application.Individuals) {
              const formControlLastName = this.formBuilder.control(individual.SurName, [Validators.required]);
              const formControlFirstName = this.formBuilder.control(individual.GivenName, [Validators.required]);
              const formControlDob = this.formBuilder.control(moment(individual.DoB, 'YYYY-MM-DD'), [Validators.required]);
              const formGroup: UntypedFormGroup = this.formBuilder.group({
                lastName: formControlLastName,
                firstName: formControlFirstName,
                dob: formControlDob,
              });
              this.formArrayIndividuals.push(formGroup);
            }
          }
        }
        if(this.assetSubmitted){
          this.individualControl.disable();
        }
        this.ready = true;
      })
    ).subscribe());
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.isCompanyOrEntityOrg = this._isCompanyOrEntityOrg();
    this.asset = applicationToAsset(this.application);
    this.reload();

    this.formArrayAssets.valueChanges.subscribe((changes: any) => {
      // console.log('changes: ', changes)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(this.sendToSF){
    //   this.sendToSf();
    // }
    console.log('asset settlement changes: ', changes)
  }

  get individualControl(): UntypedFormArray {
    return this.formGroup.get('individuals') as UntypedFormArray;
  }

  onEditAsset($event: PpsrAssetValue){
    this.onEditAssetEvent.emit($event);
  }

  private _isCompanyOrEntityOrg(): boolean {
    const primary = primaryCommercialEntity(this.application);
    if(primary) {
      const isPty = primary.EntityType === 'PTY';
      const isTrust = primary.EntityType === 'TRST';
      const isSLTR = primary.EntityType === 'SLTR';
      const isPTNR = primary.EntityType === 'PTNR';
      if (this.application.CommercialEntities.length > 1) {
        for (let i = 0; i < this.application.CommercialEntities.length; i++) {
          if (this.application.CommercialEntities[i].Type === 'Trustee' && this.application.CommercialEntities[i].EntityType === 'PTY') {
            this.hasTrustee = true
          }
        }
      }
      if (!isPty && !this.hasTrustee) {
        this.showIndividuals = true
      } else {
        this.showIndividuals = false
      }
      let multiCommercialEntity:boolean=false
      for (let i = 0; i < this.application.CommercialEntities.length; i++) {
        if(this.application.CommercialEntities[i].Type==='Trustee')
         multiCommercialEntity = true
      }
      return (isPty ||  multiCommercialEntity);
    }
    return false;
  }

  _isPrimaryApplicantWithABN(): boolean {
    const primary = primaryCommercialEntity(this.application);
    if (primary) {
      const isTrust = primary?.EntityType === 'TRST';
      const isSLTR = primary?.EntityType === 'SLTR';
      const isPTNR = primary?.EntityType === 'PTNR';
      return (isPTNR || isSLTR || isTrust )
    }
    return false
  }
  formControlInGroup(formGroup: AbstractControl, name: string): UntypedFormControl {
    const fc =  (formGroup as UntypedFormGroup).controls[name] as UntypedFormControl;
    if (!fc) {
    }
    return fc;

  }

  addAsset() {
    const as: PpsrAssetValue = {
      assetCategory: null,
      assetType: null,
      description: '',
      serialNumberType: '',
      serialNumber: '',
      invoicePrice: 0,
      gst: 0,
      year: 0,
      supplier: null as any,
      assetNumber: null,
      AssetId: null,
      salesforceId: null,
      vehicleDetails: null
    };
    const fc = this.formBuilder.control(as, [Validators.required]);
    this.formArrayAssets.push(fc);
  }

  removeAsset(control: AbstractControl) {
    const fc = control as UntypedFormControl;
    const index = this.formArrayAssets.controls.findIndex(c => c === fc);
    if (control.value !== null) {
    if (!isNaN(control.value.assetId) && control.value.assetId !== null) {
      // this.ppsrService.removeApplicationAsset(control.value.assetId).pipe(
      this.subscriptions.push(this.removeApplicationAssetFn(control.value.assetId).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable("Asset Deleted"),
        tap(r => {
          if (index == 0){
            this.disableControls = true;
            this.assetSubmitted = true
            this.dialogService.openAlertDialog({
              message: 'Alert',
              subMessage: 'You can not delete this asset'
            })
          }
          else{
            this.formArrayAssets.removeAt(index);
          }
        })
      ).subscribe());
    } else {
      if (index > -1) {
        if (index == 0){
          this.disableControls = true;
          this.assetSubmitted = true;
          this.dialogService.openAlertDialog({
            message: 'Alert',
            subMessage: 'You can not delete this asset'
          })
        } else{
            this.formArrayAssets.removeAt(index);
        }
      }
    }
  }else{
    this.formArrayAssets.removeAt(index);
  }
  }
  test() {
    console.log('***** asset-settlement formgroup', this.formGroup);
  }

  searchAsset(serialNumber: string, serialNumberType: string, control: AbstractControl) {
    const fc = control as UntypedFormControl;
    const index = this.formArrayAssets.controls.findIndex(c => c === fc);
    // this.ppsrService.searchAssets(this.application.ApplicationId, {
    this.searchAssetFn(this.application.ApplicationId, {
      SearchNumber: serialNumber,
      NumberType: serialNumberType.replace(/ +/g, ''),
    }).pipe(
      // delay(5000),
      this.toastService.spinnerObservable(),
      // this.toastService.snackBarObservable("Asset search success"),
      tap(r => {
        const data=r
        if((data as any)?.Error){
          this.dialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try after sometime',
          });
        }else{
        if (r) {
          // (fc as any).vehicleDetails = r;
          const value = this.formArrayAssets.at(index).value;
          this.formArrayAssets.at(index).patchValue({
            ...value,
            vehicleDetails: r
          })
          // this.getPPSRDocument();
        } else {
          this.dialogService.openAlertDialog({
            message: 'Warn',
            subMessage: 'Unable to find asset',
          });
        }
      }
      })
    ).pipe(
      // delay(5000),
      tap(r => {
        this.getPPSRDocument();
      })
    ).subscribe();
  }
  onSupplierEvent($event: PpsrAssetSupplierSearchEvent) {
    console.log("Event::",$event)
    this.supplierEvent.next($event)
    // this.formControlSupplier.setValue($event)
  }
  toSettlementAssetDetails(assetId: number | null, ppsrAssetValue: Exclude<PpsrAssetValue, null>) {
    const isVehicle = isAssetVehicles(ppsrAssetValue.assetCategory?.index ?? '')
    return stringifyJSON({
      assetID: assetId,
      assetCategory: ppsrAssetValue.assetCategory?.index,
      assetType: ppsrAssetValue.assetType?.index,
      description: ppsrAssetValue.description,
      InvoicePrice: ppsrAssetValue.invoicePrice,
      GST: ppsrAssetValue.gst,
      SerialNumberType: ppsrAssetValue.serialNumberType,
      serialNumber: ppsrAssetValue.serialNumber,
      Supplier: ppsrAssetValue.supplier,
      VehicleDetails: isVehicle ? this.application.AssetSpec : null
    });
  }

  emptyAssetFn($event: any) {
    console.log('asset-settlement',$event);
    this.emptyAsset.emit($event);
  }

  // savePpsr() {
  //   // save or update ppsr asset
  //   const observables: any[] = [];
  //   for (const formControl of this.formArrayAssets.controls) {
  //     const ppsrAssetValue: PpsrAssetValue = formControl.value;
  //     console.log('******************** save ppsr', ppsrAssetValue);
  //     if (ppsrAssetValue && (formControl as any).AssetId) { // existing asset
  //       const assetId = (formControl as any).AssetId;
  //       const observable = this.ppsrService.updateAsset({
  //         AssetId: assetId,
  //         SettlementAssetDetails: this.toSettlementAssetDetails(assetId, ppsrAssetValue),
  //         ApplicationId: this.application.ApplicationId,
  //         SalesForceId: ppsrAssetValue?.supplier?.SupplierId ?? null,
  //       }).pipe(
  //       );
  //       observables.push(observable);
  //     } else if (ppsrAssetValue) { // new asset
  //       const observable = this.ppsrService.addAsset({
  //         SettlementAssetDetails: this.toSettlementAssetDetails(null, ppsrAssetValue),
  //         ApplicationId: this.application.ApplicationId,
  //         SalesForceId: ppsrAssetValue?.supplier?.SupplierId ?? null,
  //       }).pipe(
  //         tap(r => {
  //           const assetId = r.payload;
  //         })
  //       );
  //       observables.push(observable);
  //     }
  //   }

  //   // save or update ppsr details
  //   const obs = this.ppsrService.getPpsrDetails(this.application.ApplicationId).pipe(
  //     tap(r => {
  //       if (r.payload) {  // ppsr details exists
  //         const grantors = this.toGrantors();
  //         this.ppsrService.updatePpsrDetail({
  //           ApplicationId: this.application.ApplicationId,
  //           Grantors: stringifyJSON(grantors),
  //         }).pipe(
  //         ).subscribe();
  //       } else {  // new ppsr details
  //         const grantors = this.toGrantors();
  //         this.ppsrService.addPpsrDetail({
  //           ApplicationId: this.application.ApplicationId,
  //           Grantors: stringifyJSON(grantors),
  //         }).pipe(
  //         ).subscribe();
  //       }
  //     })
  //   );
  //   observables.push(obs);
  //   combineLatest(observables).pipe(
  //     tap(r => {
  //       this.reload();
  //     })
  //   ).subscribe();
  // }

  ppsrRegistration() {
    this.dialogService.openPPSRConfirmationDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r && r.readyForSubmission) {
            // grantors
            const grantors = this.toGrantors();

            // assets
            const assets = [];
            let addDays = 1 + this.application.PricingDetails.LoanTerm
            for (const abstractControl of this.formArrayAssets.controls) {
              const formControl = abstractControl as UntypedFormControl;
              const v: PpsrAssetValue = formControl.value;
              if (v && v.assetCategory) {
                if (isAssetVehicles(v.assetCategory.index)) {
                  const serialNumberType = v.serialNumberType?.replace(/ +/g, '');
                  const serialNumber = v.serialNumber;
                  if (!serialNumberType || !serialNumber) {
                    this.dialogService.openAlertDialog({
                      message: `Error`,
                      subMessage: `Please ensure Serial number and serial number type are valid for all vehicles`,
                    });
                    return;
                  }
                  assets.push({
                    CollateralClassType: 'MotorVehicle' as const,
                    SerialNumberType: serialNumberType,
                    SerialNumber: serialNumber,
                    RegistrationEndTime: moment().add(addDays, 'month').format('YYYY-MM-DDT23:59:59'),
                    CollateralDescription: v.description,
                  });
                } else {
                  assets.push({
                    CollateralClassType: 'OtherGoods' as const,
                    RegistrationEndTime: moment().add(addDays, 'month').format('YYYY-MM-DDT23:59:59'),
                  });
                }
              }
            }
            // const sub = this.ppsrService.submitPpsr(this.application.ApplicationId, {
            // const sub = this.submitPpsrFn(this.application.ApplicationId, {
            //   ID: this.application.BrokerAppId,
            //   Type: 'Commercial',
            //   Assets: assets,
            //   Grantors: grantors,
            // }).pipe(
              const sub = this.submitPpsrFn(this.application.ApplicationId).pipe(
              // this.toastService.toastObserver('submit ppsr'),
              this.toastService.spinnerObservable(),
              // delay(3000),
              // this.toastService.snackBarObservable("PPSR registration done"),
              tap(r => {
                const data:any=r
                        if(!data || data.Error){
                          this.dialogService.openAlertDialog({
                            message: 'Error',
                            subMessage: 'Please try after sometime',
                          });
                        }
                console.log('***** submitPpsr response', r);
                this.isPPSRsubmitted = true
              }),
              catchError(err => {
                this.dialogService.openAlertDialog({
                  message: 'Error',
                  subMessage: 'Please try after sometime',
                });
                return of(null);
              })
            ).pipe(
              // delay(5000),
               tap(r => {
                 this.getPPSRDocument();
               })
            ).subscribe();
            this.subscriptions.push(sub);
          }
        })
      ).subscribe();
  }

  sendToSf() {
    // const sub = this.ppsrService.getApplicationAsset(this.application.ApplicationId).pipe(
    const sub = this.getApplicationAssetFn(this.application.ApplicationId).pipe(
      tap(async r => {
        const ppsrAssets = r;
        if (ppsrAssets && ppsrAssets.length) {
          const ppsrAsset = ppsrAssets[0];
          if (ppsrAsset.AssetSFRes == null) {
            await this.doSendToSf();
          } else {
            this.dialogService.openAlertDialog({
              message: 'Alert',
              subMessage: 'Asset are already sent to salesforce'
            });
          }
        } else {
          // ?????
          await this.doSendToSf();
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  private async doSendToSf() {
    const sfDatas = [];
    for (const abstractControl of this.formArrayAssets.controls) {
      const formControl = abstractControl as UntypedFormControl;
      const v: PpsrAssetValue = formControl.value;

      if (v) {
        const sfData: any = {
          attributes: {type: 'Asset', referenceId: String(v.AssetId)},
          Name: (v.description)?.substring(0, 255),
          Asset_Category__c: v.assetCategory?.index,
          Asset_Class__c: `${v.assetCategory?.index}-${v.assetType?.index}`,
          // Asset_Class__c: `${v.assetCategory?.index}`,
          Opportunity__c: this.application.AppInfo.SalesforceId,
          Description: v.description,
          Invoice_Price_incl_GST__c: (v.invoicePrice),
          GST__c: (v.gst),
          Supplier__c: v.supplier?.SalesForceId,
          SerialNumber: v.serialNumber,
        };
        if (isAssetVehicles(v?.assetCategory?.index ?? '')) {
          // const res = await this.ppsrService.searchAssets(this.application.ApplicationId, {
          // const res = await this.searchAssetFn(this.application.ApplicationId, {
          //   SearchNumber: v.serialNumber,
          //   NumberType: v.serialNumberType.replace(/ +/g, ''),
          // }).toPromise();
          // const vdetails =  res; // res.NevdisData.NevdisVehicles.NevdisVehicle[0].VehicleDetail.VehicleDescription
          const vdetails= v.vehicleDetails
          if (vdetails) {
            const manufacture_year=vdetails.ComplianceYearMonth ? vdetails.ComplianceYearMonth.slice(0,4) : '';
            sfData.Make__c = vdetails.Make
            sfData.Model__c = vdetails.Model
            sfData.Manufacture_Year__c = manufacture_year
          }
          sfDatas.push(sfData);
        } else {
          sfDatas.push(sfData);
        }
      }
    }
    //const sub = this.ppsrService.sendAssetToSf({"records":sfDatas}).pipe(
    const sub = this.sendAssetToSfFn({"records":sfDatas}).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable("Asset sent to SF success"),
      tap(r=>{
      this.settleLoan.emit(true);
      if(r){
        r.payload.forEach(a => {
          if (a.errors && a.errors.length) {
            this.dialogService.openAlertDialog({
              message: 'Alert',
              subMessage: a.errors[0].message,
            })
          }
        })
        // if(r.message=='success'){
        //   if(r.res.hasErrors){
        //     const errorMsg = r.res.results[0].errors[0].message
        //     this.dialogService.openAlertDialog({
        //       message: 'Alert',
        //       subMessage: errorMsg
        //     });
        //   }else{
        //     return
        //   }
        // }
      }
    })
      // this.toastService.toastObserver('send asset to sf'),
    ).subscribe();
    this.subscriptions.push(sub);
  }

  private toGrantors() {
    // grantors
    const entityData = this.entityFormGroup.value
    const ABN = entityData.ABN
    const ACN = entityData.ACN

    const grantors = [];
    // for (const abstractControl of this.formArrayEntities.controls) {
    //   const formGroup = abstractControl as FormGroup;
    //   if (formGroup.controls.acn) {
    //     grantors.push({
    //       OrganisationNumber: formGroup.controls.acn.value,
    //       OrganisationNumberType: 'ACN' as const,
    //     });
    //   } else if (formGroup.controls.abn) {
    //     grantors.push({
    //       OrganisationNumber: formGroup.controls.abn.value,
    //       OrganisationNumberType: 'ABN' as const,
    //     });
    //   }
    // }
    if (ABN !== '' && ABN !== undefined) {
      let gdata: any = {}
      gdata.OrganisationNumber = ABN
      gdata.OrganisationNumberType = 'ABN'
      grantors.push(gdata)
    }
    if (ACN !== '' && ACN !== undefined) {
      let gdata: any = {}
      gdata.OrganisationNumber = ACN
      gdata.OrganisationNumberType = 'ACN'
      grantors.push(gdata)
    }
    for (const abstractControl of this.formArrayIndividuals.controls) {
      const formGroup = abstractControl as UntypedFormGroup;
      grantors.push({
        FamilyName: (formGroup.controls as any).lastName.value,
        GivenNames: (formGroup.controls as any).firstName.value,
        DateOfBirth: moment((formGroup.controls as any).dob.value).format('YYYY-MM-DDT00:00:00'),
      });
    }
    return grantors;
  }

  onPpsrAssetEvent($event: PpsrAssetComponentEvent, control: AbstractControl) {
    switch($event.type) {
      case 'Search': {
        const serialNumberType = $event.serialNumberType;
        const serialNumber = $event.serialNumber;
        this.searchAsset(serialNumber, serialNumberType, control);
        break;
      }
      case 'Add': {
        this.addAsset();
        break;
      }
      case 'Remove': {
        this.removeAsset(control);
        break;
      }
    }
  }

  onPpsrSupplierEvent($event: PpsrAssetSupplierSearchEvent) {
    // todo submit supplier for accreditation
    console.log('***** supplier event', $event);
  }

  canEdit(): boolean |undefined |null {
    return (this.canSave() && this.assetSubmitted);
  }

  canSave(): boolean {
    // todo this user can click save??
    if (this.loggedInUser) {
      if (isInternalUser(this.loggedInUser)) {
        return true;
      }
    }
    return false;
  }

  canRegister(): boolean {
    // todo this user can click register?
    if (this.loggedInUser) {
      if (isInternalUser(this.loggedInUser)) {
        return true;
      }
    }
    return false;

  }

  canSaveToSf() {
    // todo this user can save to sf
    if (this.loggedInUser) {
      if (isInternalUser(this.loggedInUser)) {
        return true;
      }
    }
    return false;
  }

  canSubmit(){
    if (this.loggedInUser) {
      if(this.loggedInUser.AccessLevel === 'externalbroker'){
        return true;
      }
    }
    return false;
  }
  // get assetControl(): FormArray {
  //   return this.formArrayAssets.get('assets') as FormArray;
  // }
  async updateApplicationAssetsData(key: string) {
    let isinvalidCheckdigit: any
      if (this.formGroup.touched || (key === 'Submit')) {
        if (isinvalidCheckdigit) {
          this.dialogService.openAlertDialog({message:'Alert',subMessage:'Please Check VIN'})
        } else {
          await this.saveAssets(key)
        }
      } else {
        this.assetSubmitted = true
      }
    }

  async saveAssets(key: string) {
    if (this.formGroup.valid) {
      console.log("Key::::",key, this.formGroup.value);
      let updateData: PartialOrNull<PpsrAsset>[] = []
      let ppsrData: {
        ApplicationId: number,
        Grantors: string,
      }[] = []
      const data = this.formGroup.value
      const ABN = data.ABN
      const ACN = data.ACN
      let grantorData: PpsrData['Grantors'] = []
      if (ABN !== '' && ABN !== undefined) {
        grantorData.push({
          type: 'ABN',
          OrganisationNumber: ABN,
          OrganisationNumberType: 'ABN'
        });
      }
      if (ACN !== '' && ACN !== undefined) {
        grantorData.push({
          type: 'ACN',
          OrganisationNumber: ACN,
          OrganisationNumberType: 'ACN',
        });
      }
      for (let i = 0; i <= this.formArrayIndividuals.length - 1; i++) {
        // grantorData.push(this.formArrayIndividuals.at(i).value)
        const g = this.formArrayIndividuals.at(i).value;
        grantorData.push({
          type: 'Individual',
          FamilyName: g.firstName,
          GivenNames: g.lastName,
          DateOfBirth: g.dob,
        });
      }
      for (const ele of data.assets) {
        const element: NotNullable<PpsrAssetValue> = ele;
        const asset = {
          ApplicationId: this.application.ApplicationId,
          AssetId: element.AssetId,
          SalesForceId: element?.supplier?.SalesForceId,
          SettlementAssetDetails: {
            assetCategory: element.assetCategory?.index ?? '',
            assetType: element.assetType?.index ?? '',
            depositAmount: element.depositAmount ?? 0,
            // WEB-3702
            depositAmountDynamoney: element.depositAmountDynamoney ?? 0,
            depositAmountSupplier: element.depositAmountSupplier ?? 0,
            amountFinanced: element.amountFinanced ?? 0,
            description: element.description as string,
            SerialNumberType: element.serialNumberType as any,
            serialNumber: element.serialNumber as string ,
            InvoicePrice: element.invoicePrice as number,
            GST: element.gst as number,
            year: element.year as number,
            VehicleDetails: element.vehicleDetails ?? undefined,
            Supplier: {
              SupplierType: element?.supplier?.SupplierType ?? '',
              ABN: element?.supplier?.ABN ?? '',
              ACN: element?.supplier?.ACN ?? '',
              SupplierName: element?.supplier?.SupplierName as string,
              SupplierAddress: element?.supplier?.SupplierAddress as Address2,
              Contact: element?.supplier?.Contact as string,
              isManualSupplier: element?.supplier?.isManualSupplier ?? false,
              isExistingSupplier: element?.supplier?.isExistingSupplier ?? false,
              SupplierId: element?.supplier?.SupplierId ?? '',
              SalesForceId: element?.supplier?.SalesForceId ?? '',
            },
          },
          isAssetSubmitted: false,
        };
        updateData.push(asset);
        const ppsr: PpsrDataRaw = {
          ApplicationId: this.application.ApplicationId,
          Grantors: stringifyJSON(grantorData)
        };
        ppsrData.push(ppsr);
      }
      for (let i = 0; i <= updateData.length - 1; i++) {
        if (key === 'Submit') {
          updateData[i].isAssetSubmitted = true
        }
        if (updateData[i].AssetId !== null) {
          if (key === 'Submit' && (this.applicationAssets[i].submittedByBroker === null || this.applicationAssets[i].submittedByAdmin === null)) {
            // this.isAdmin() ? [updateData[i].submittedByAdmin = true, updateData[i].submittedByBroker = false] : [updateData[i].submittedByAdmin = false, updateData[i].submittedByBroker = true];
            if (this.isAdmin()) {
              updateData[i].submittedByAdmin = true;
              updateData[i].submittedByBroker = false;
            } else {
              updateData[i].submittedByAdmin = false;
              updateData[i].submittedByBroker = true;
            }
          }
          await this.updateAssetsToDatabase(updateData[i], key)
        } else {
          delete updateData[i].AssetId
          if (key === 'Submit') {
            // this.isAdmin() ? [updateData[i].submittedByAdmin = true, updateData[i].submittedByBroker = false] : [updateData[i].submittedByAdmin = false, updateData[i].submittedByBroker = true];
            if (this.isAdmin()) {
              updateData[i].submittedByAdmin = true;
              updateData[i].submittedByBroker = false;
            } else {
              updateData[i].submittedByAdmin = false;
              updateData[i].submittedByBroker = true;
            }
          }
          await this.saveAssetsToDatabase(updateData[i], key)
        }
      }
      console.log('****************** SAVE ASSET', updateData);

      this.getPpsrDetailsFn(this.application.ApplicationId).pipe(tap(async r => {
        let res = r;
        if (res === null) {
          await this.savePPSRToDatabase(ppsrData[0])
        }
        else {
          await this.updatePPSRToDatabase(ppsrData[0])
        }
        })).subscribe();
    } else { // form is invalid
      console.log("Form group::::",this.formGroup)
      this.dialogService.openAlertDialog({message:'Alert',subMessage:'Please check details and try again.'})
      if (this.formGroup.invalid) {
        this.mark()
      }
    }
  }

  async savePPSRToDatabase(assetData: {
    ApplicationId: number,
    Grantors: string,
  }) {
    // this.ppsrService.addPpsrDetail(assetData).subscribe(res => {
    this.addPpsrDetailFn(assetData).subscribe(res => {
      this.assetSubmitted = true
      this.isPPSRsubmitted = false
      this.isSFsubmitted = false
    })
  }

  async updatePPSRToDatabase(assetupdatedata: {
    ApplicationId: number,
    Grantors: string,
  }) {
    // this.ppsrService.updatePpsrDetail(assetupdatedata).subscribe(res => {
    this.updatePpsrDetailFn(assetupdatedata).subscribe(res => {
      this.assetSubmitted = true
      this.isPPSRsubmitted = false
      this.isSFsubmitted = false
    })
  }

  // sendAssetSubmittedMail() {
  //   let emaildata: any = {}
  //   emaildata.applicationId = this.data.ApplicationId
  //   emaildata.companyName = this.data.CommercialEntities[0].LegalName
  //   this.appService.sendAssetSubmittedMail(emaildata).subscribe(res => {
  //   })
  // }


  async updateAssetsToDatabase(assetupdatedata: PpsrAsset, key: string) {
    // this.ppsrService.updateApplicationAssets(assetupdatedata).pipe(
    this.updateApplicationAssetsFn(assetupdatedata).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      tap(r => {
        if (key === 'Submit') {
              this.assetSubmitted = true
              this.isPPSRsubmitted = false
              this.isSFsubmitted = false
              this.disableControls = true;
              this.individualControl.disable();
            }
    }
    )).subscribe();
  }

  async saveAssetsToDatabase(assetData: PartialOrNull<PpsrAsset>, key: string) {
    // this.ppsrService.submitApplicationAssets(assetData).pipe(
    const data = assetData;
    if (data.SettlementAssetDetails) {
      (data.SettlementAssetDetails as any) = data.SettlementAssetDetails;
    }
    if (data.AssetSFRes) {
      (data.AssetSFRes as any) = stringifyJSON(data.AssetSFRes);
    }
    this.submitApplicationAssetsFn(data).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.snackBarObservable('Asset saved'),
          tap(r => {
            if (key === 'Submit') {
                  this.assetSubmitted = true
                  this.isPPSRsubmitted = false
                  this.isSFsubmitted = false
                  this.disableControls = true;
                  this.individualControl.disable();
                }
            this.allowPPSR = true;
            this.allowSF = true;
        }
        )).subscribe();

  }

  onEdit() {
    //enable asset controls
    this.assetSubmitted=false;
    this.individualControl.enable();
    this.disableControls=false;
  }

  searchGrantors(type:string) {
    let data: any = {}
    let organisationName : string = '';
    if (type === 'ACN') {
      data = {
        "SearchNumber":this.entityFormGroup.get('ACN')?.value,
        "NumberType": type,
        "SearchType": "G"
      }
      organisationName = this.entityFormGroup.get('organisationNameACN')?.value
    } if (type === 'ABN') {
      data = {
        "SearchNumber":this.entityFormGroup.get('ABN')?.value,
        "NumberType": type,
        "SearchType": "G"
      }
      organisationName = this.entityFormGroup.get('organisationNameABN')?.value
    }
    // this.ppsrService.searchGrantors(this.application.ApplicationId, {
    this.searchGrantorsFn(this.application.ApplicationId, {
      SearchNumber: data.SearchNumber,
      NumberType: data.NumberType,
      SearchType: "G"
    }).pipe(
      // delay(5000),
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable("Grantor search success"),
      tap(r => {
        const data:any=r
              if(data.Error){
                  this.dialogService.openAlertDialog({
                    message: 'Error',
                    subMessage: 'Please try after sometime',
                  });
                }else{
        if (r.payload && r.status) {
          const grantorDetails = r.payload['SearchResult'].GrantorOrganisationSearchCriteriaVerificationDetail;
          if (grantorDetails === 'NotFound') {
            this.dialogService.openAlertDialog({message:'Alert',subMessage:'Grantor details not found'})
          } else {
            const collateralClassSummary = r.payload.SearchResult.SearchResultSummary.CollateralClassSummary.CollateralClassSearchSummaryDetail
            if (collateralClassSummary.length > 0) {
              collateralClassSummary.forEach((element: { CollateralClass: string; CollateralClassResultCount: any; }) => {
                if (element.CollateralClass === 'OtherGoods') {
                  this.grantorDetails.OtherGoodsCount = element.CollateralClassResultCount
                }
                if (element.CollateralClass === 'Account') {
                  this.grantorDetails.Account = element.CollateralClassResultCount
                }
                if (element.CollateralClass === 'MotorVehicle') {
                  this.grantorDetails.MotorVehicleCount = element.CollateralClassResultCount
                }
                if (element.CollateralClass === 'AllPapNoExcept') {
                  this.grantorDetails.AllPapNoExceptCount = element.CollateralClassResultCount
                }
              });
            } else {
              this.dialogService.openInformationDialog({message:'Info',subMessage:`${organisationName} has no PPSR registrations`})
              this.grantorDetails = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
            }
            this.showSummaryTable = true
            this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
            // this.getPPSRDocument();
          }
        } else {
          this.dialogService.openAlertDialog({message:'Alert',subMessage:'Please check details and try again.'})
        }
      }
      })
    ).pipe(
      delay(5000),
      tap(r => {
        this.getPPSRDocument();
      })).subscribe();
  }

  // async saveDocuments() {
  //   if(this.formControlPPSRDocuments.valid){
  //     const file: File[] = this.formControlPPSRDocuments.value;
  //     let uploadFiles = []
  //     const base64Files: Base64File[] = await filesToBase64Files(file);
  //     uploadFiles.push(...base64Files)
  //     const data: any = {
  //       remove: [],
  //       new: uploadFiles
  //     };
  //     // this.ppsrService.savePPSRDocument(this.application.ApplicationId, data
  //     this.savePpsrDocumentFn(this.application.ApplicationId, data).pipe(
  //       this.toastService.spinnerObservable(),
  //       this.toastService.snackBarObservable('Document Submitted'),
  //       tap(r => {
  //         if (r.status === true) {
  //           this.formControlPPSRDocuments.reset()
  //           this.PPSRDocs = [];
  //           this.getPPSRDocument();
  //         }
  //       })).subscribe();
  //   } else {
  //     this.dialogService.openAlertDialog({message:'Alert',subMessage:'Please uplaod document before submit'})
  //   }
  // }

  getPPSRDocument() {
    // this.ppsrService.getPpsrDocuments(this.application.ApplicationId
    this.PPSRDocs = [];
    this.getPpsrDocumentsFn(this.application.ApplicationId).pipe(tap(r => {
      let res = r;
      if (res.length > 0) {
        for (let i = 0; i <= res.length - 1; i++) {
          console.log('**********---------', res[i]);
          if (res[i].isPPSRDoc === true) {
            this.PPSRDocs.push(res[i])
          }
        }
      }
    })).subscribe();
  }

  isAdmin() {
    if (this.loggedInUser) {
      if (isInternalUser(this.loggedInUser)) {
        return true
      }
    }
    return false
  }

  onDeleteClick($event: boolean) {
    // console.log('this asset is deleted', $event);
    this.isAssetUpdated = $event;
    this.deleteAsset.emit($event);
  }

  onPPSRStatus($event: boolean){
    this.ppsrStatus.emit($event);
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  onCreateNewAssetInspection(event: boolean) {
    if (event) {
      this.newAssetInspection.emit(true);
    }
  }

  onAddAuditLog(event: boolean) {
    this.isAssetUpdated = event
    if(event) {
      if(this.assetNotesComponent) {
          this.assetNotesComponent.reload();
      }
    }
  }

}
