<div class="authorised-signatory-component">
  @for (formGroup of authorisedSignatoryFormGroups(); track formGroup; let index = $index) {
    <div class="row separator-bottom">
      <div class="col">
        <div class="mat-h2">Authorised signatory {{index + 1}}</div>
        <div class="mat-body">Minimum of 1 authorised signatory is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-form-field>
          <mat-label>Role</mat-label>
          <mat-select [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'role')">
            @for (option of authorisedSignatoryRoleOptions; track option) {
              <mat-option [value]="option">{{option.name}}</mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(authorisedSignatoryFormGroupFormControl(formGroup, 'role')); track errorKey) {
            <mat-error>
              @if (authorisedSignatoryFormGroupFormControl(formGroup, 'role').touched && authorisedSignatoryFormGroupFormControl(formGroup, 'role').hasError(errorKey)) {
                {{errorMessage(authorisedSignatoryFormGroupFormControl(formGroup, 'role'), errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-3">
        <title-selection [mark]="markObservable" [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'title')"></title-selection>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <name title="First Name" [mark]="markObservable" [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'firstName')"></name>
      </div>
      <div class="col-3">
        <mat-form-field>
          <mat-label>Middle name</mat-label>
          <input  matInput type="text" [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'middleName')">
        </mat-form-field>
      </div>
      <div class="col-3">
        <name title="Last Name" [mark]="markObservable" [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'lastName')"></name>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <email [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'email')" [mark]="markObservable"></email>
      </div>
      <div class="col-3">
        <mobile [formControl]="authorisedSignatoryFormGroupFormControl(formGroup, 'mobile')" [mark]="markObservable" [onlyMobile]="true"></mobile>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col">
        <button type="button" mat-stroked-button [disabled]="formArray.length <= 1" (click)="removeAuthorisedSignatory(formGroup)" >
          <span class="mdi mdi-minus" matPrefix></span>
          Remove authorised signatory
        </button>
      </div>
    </div>
  }

  <div class="row separator-bottom">
    <div class="col">
      <button type="button" mat-stroked-button (click)="addAutorisedSignatory()">
        <span class="mdi mdi-plus" matPrefix></span>
        Add authorised signatory
      </button>
    </div>
  </div>

</div>
