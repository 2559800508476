import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DocumentTag,
  FileWithTags,
  UploadAzureFiles,
  UploadAzureFilesValue,
  UploadFilesMultiTagsDialogResult
} from '@portal-workspace/grow-shared-library';
import { ApplicationDialogService, duplicateFileNameValidator, maxFileUploadValidator } from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { UploadFileComponent } from './upload-file.component';

@Component({
  selector: 'upload-files-multi-tags',
  templateUrl: './upload-files-multi-tags.component.html',
  styleUrls: ['./upload-files-multi-tags.component.scss'],
  standalone: true,
  exportAs: 'uploadFilesMultiTagsComponent',
  imports: [UploadFileComponent, FormsModule, ReactiveFormsModule, MatCardModule, MatChipsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class UploadFilesMultiTagsComponent implements OnInit {

  @Input({required: true}) allTags: DocumentTag[] = [];
  @Input({required: false}) defaultTags: DocumentTag[] = [];
  @Input({required: false}) enableCustomTag?: boolean = false;
  @Input({required: false}) enableNonStandardCondition?: boolean = false;
  @Input({required: false}) MAX_TAG_ALLOWED = 10;
  @Input({required: false}) title? = "Upload Documents";
  @Input({required: false}) isDialog? = false;
  @Output() docEvent = new EventEmitter<UploadAzureFiles>();
  @Output() dialogEvents = new EventEmitter<UploadFilesMultiTagsDialogResult>();

  formGroup: FormGroup<{
    upload: FormControl<UploadAzureFilesValue>,
  }>;
  formControl: FormControl<UploadAzureFilesValue>;
  subscriptions: Subscription[] = [];
  maxFileUpload = 3;

  files: File[] = [];
  filesWithTags: FileWithTags[] = [];

  constructor(private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService) {
    this.formControl = this.formBuilder.control(null, [Validators.required,duplicateFileNameValidator(), maxFileUploadValidator(this.maxFileUpload)]);
    this.formGroup = this.formBuilder.group({
      upload: this.formControl,
    });
  }

  ngOnInit() {
    this.allTags = this.allTags.sort((a, b) => a.type.localeCompare(b.type));
  }

  onCancel($event: MouseEvent) {
    this.dialogEvents.emit({
      valid: false,
      files: this.fileData ?? [],
      selectedTags: this.defaultTags
    });
  }

  onSubmit($event: MouseEvent) {
    const isMissingTagsCheck = this.fileData?.filter(file => file.tags && file.tags.length === 0);
    if(isMissingTagsCheck?.length){
      const fileNames = isMissingTagsCheck.map(file => file.name).join(', ');
      this.dialogService.openAlertDialog({
        message: `Validation Error`,
        subMessage: `The following files are missing tags: ${fileNames}`
      });
    } else {
      this.dialogEvents.emit({
        valid: true,
        files: this.fileData ?? [],
        selectedTags: this.defaultTags
      }); 
    }
  }
  
  onSelectedFileTags(arg: FileWithTags[]) {
    this.filesWithTags = arg;
    this.docEvent.emit(this.fileData);
  }

  onSelectOrDeleteFile(arg: File[]) {
    if(arg.length <= 0){
      this.files = [];
      this.formControl.setValue(null);
    }
    this.files = arg;
    this.formControl.setValue(arg);
    this.filesWithTags = this.filesWithTags.filter((file) => {
      for (const f of this.files) {
        if (f.name == file.fileName) return true;
      }
      return false;
    });
    
    this.docEvent.emit(this.fileData);
  }

  getFileIndexByFilename(file: File){
    return this.files.indexOf(file);
  }

  getTagsValueByFileName(file: File) {
    const index = this.getFileIndexByFilename(file);
    const tagList = this.filesWithTags[index];
    if(tagList){
      const tagValue = tagList.fileTags.map(tag => tag.value);
      return tagValue ? tagValue : []; 
    }else {
      return [];
    }
  }

  get fileData() {
    return this.formControl?.value?.map((file) => {
      const tagValue = this.getTagsValueByFileName(file);
      file.tags = tagValue;
      return file;
    });
  }
}
