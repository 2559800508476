import {Injectable} from '@angular/core';
import {getDscrIndustryNames as gdi, getDscrIndustryEbitda as gei, DscrIndustryEbitda, DscrIndustry} from '@portal-workspace/grow-shared-library';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class DscrIndustrySelectionModuleService {

  currentSelectedIndustry  = new BehaviorSubject<DscrIndustry | null>(null);
  industryEbitdas = new BehaviorSubject<DscrIndustryEbitda[]>([]);

  subscription: Subscription;

  constructor() {
    setupUntilDestroy(this);
    this.subscription = this.currentSelectedIndustry
      .pipe(
        tap(dscrIndustry => {
          if (dscrIndustry) {
            const industryEbitdas = gei(dscrIndustry.name);
            this.industryEbitdas.next(industryEbitdas);
          } else {
            this.industryEbitdas.next([]);
          }
        })
      ).subscribe();
  }


  setDscrIndustry(p: DscrIndustry | null) {
    this.currentSelectedIndustry.next(p);
  }

  getDscrIndustryNames(): DscrIndustry[] {
    return gdi();
  }

}
