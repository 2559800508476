import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {getUser, is2faEnteredWhenLogin} from '@portal-workspace/grow-ui-library';
import {inject} from '@angular/core';
import {TwoFactorAuthService} from '../service/2fa.service';
import { Subject } from 'rxjs';
import {navigationUrlFor2FA} from '../service/navigation-urls';

export const valid2faAccessGuard = (): CanActivateFn => {
  return async (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const subject = new Subject<boolean>;
    const router = inject(Router);
    const tfaService = inject(TwoFactorAuthService);
    const user = getUser();
    const is2faOn = user?.is2FA ?? false;

    if (user && is2faOn) {
      // tfaService.getRegistered2faToken(user.UserId).pipe(
      //   tap(async (t) => {
          // const registered2faToken = t.payload;
          // const is2faTokenRegistered = !!registered2faToken;
          const is2faEntered = is2faEnteredWhenLogin();
          // if (!is2faTokenRegistered) { // no remember 2fa on device
            if (is2faEntered) { // correct 2fa code entered before
              // subject.next(true);
              // subject.complete();
              // return subject
            } else { // no correct 2fa code entered before
              await router.navigate(navigationUrlFor2FA());
              // subject.next(false);
              // subject.complete();
              // return subject;
            // }
          }
          // true
          // subject.next(true);
          // subject.complete();
      //   }),
      // ).subscribe();
      // return subject;
    }
    return true;
    // else {
    //   return true;
    // }
  }
}
