<div class="pending-approval-transactions-page container-fluid">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
        <mat-tab label="Pending">
          <ng-template matTabContent>
            <pending-approval-transactions-table
              [getPendingApprovalTransactionsFn]="getPendingApprovalTransactionsFn"
              [updatePendingTransactionsFn]="updatePendingTransactionsFn"
              [updateAllPendingTransactionsFn]="updateAllPendingTransactionsFn"
            ></pending-approval-transactions-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="Processed">
          <ng-template matTabContent>
            <processed-pending-transactions-table
              [getProcessedPendingTransactionsFn]="getProcessedPendingTransactionsFn"
            ></processed-pending-transactions-table>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>