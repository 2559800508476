import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialLibraryModule,
        CustomPaginatorComponent
    ],
    exports: [
        CustomPaginatorComponent
    ]
})
export class CustomPaginatorModule {
}
