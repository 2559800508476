import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { BusinessNumberSearchFn, BusinessSearchFn, getEntityType, } from '@portal-workspace/grow-ui-library';
import {BusinessNumberSearchValue, compareMatch, MemberValue} from '@portal-workspace/grow-shared-library';
import { PersonalAssetComponentEvent, PersonalAssetsComponent } from './personal-assets.component';
import { PersonalLiabilitiesComponentEvent, PersonalLiabilitiesComponent } from './personal-liabilities.component';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  createAcnInputMask,
  createAbnInputMask,
  createEmailInputMask,
  createPhoneNumberInputMask,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, getAddress2ComponentValueFormControlValueFn} from '@portal-workspace/grow-ui-library';
import {Subject, Subscription} from 'rxjs';
import {EntityMember, EntityTrustee, IndividualMember, IndividualTrustee, TrusteeValue} from '@portal-workspace/grow-shared-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {IndividualOrEntity} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {BusinessSearch2Value} from '@portal-workspace/grow-shared-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EntityTypeComponent } from '../entity-type-component/entity-type.component';
import { BusinessNumberSearchComponent } from '../business-number-search-component/business-number-search.component';
import { MatButtonModule } from '@angular/material/button';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { PropertyOwnerWithAddressComponent } from '../property-owner-with-address-component/property-owner-with-address.component';
import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';



export interface MemberComponentEvent {
  valid: boolean;
  entries: (IndividualMember | EntityMember)[];
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'member',
    templateUrl: './member.component.html',
    styleUrls: ['./member.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MemberComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => MemberComponent) },
    ],
    standalone: true,
    imports: [MatButtonToggleModule, FormsModule, ReactiveFormsModule, TitleSelectionComponent, MarkDirective, NameComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, YesNoComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, MatButtonModule, PersonalAssetsComponent, PersonalLiabilitiesComponent, BusinessNumberSearchComponent, EntityTypeComponent, MatFormFieldModule]
})
export class MemberComponent extends AbstractControlValueAccessor<MemberValue> implements OnInit, AfterViewInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();


  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  formGroup: FormGroup;
  formArray: FormArray;

  subscription?: Subscription;
  subscriptions: Subscription[] = [];

  // @Input({required: false}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: false}) showAssetsAndLiabilities = true;
  @Input({required: false}) showAddressForm = true;
  @Output() events: EventEmitter<MemberComponentEvent>;

  disableControls:boolean = true;
  entityTypeReadonly = true;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.events = new EventEmitter<MemberComponentEvent>();
    this.formBuilder = formBuilder;
    this.formArray = formBuilder.array([]);
    this.formGroup = formBuilder.group({
      members: this.formArray,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscription = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(array => {
        if (this.formArray.invalid) {
          this.propagateChange(null);
        } else {
          this.propagateChange(this.formArray.value);
        }
        this.events.emit({valid: this.formArray.valid, entries: this.formArray.value});
      })
    ).subscribe();
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      // this.addMember();
    });
  }

  doWriteValue(v: MemberValue | null | undefined): void | MemberValue {
    if (v) {
      for (const o of v) {
        this.addMember(o);
      }
    } else {
      this.addMember();
    }
    return undefined;
  }

  memberFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  copyFormControlValueFn = (formControl: FormControl) => {
    return () => {
      return formControl.value();
    }
  }

  memberFormGroupFormControl(memberFormGroup: FormGroup, controlName: string) {
    return memberFormGroup.controls[controlName] as FormControl;
  }

  onPersonalAssetEvent($event: PersonalAssetComponentEvent, memberFormGroup: FormGroup) {
    if ($event.entries && $event.entries.length) { // there are assets entries
      const formControl = this.memberFormGroupFormControl(memberFormGroup, 'personalAssets');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.memberFormGroupFormControl(memberFormGroup, 'personalAssets');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }

  onPersonalLiabilitiesEvent($event: PersonalLiabilitiesComponentEvent, memberFormGroup: FormGroup) {
    if ($event.entries && $event.entries.length) {
      const formControl = this.memberFormGroupFormControl(memberFormGroup, 'personalLiabilities');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.memberFormGroupFormControl(memberFormGroup, 'personalLiabilities');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }

  removeMember(memberFormGroup: FormGroup) {
    const index = this.formArray.controls.indexOf(memberFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    this.events.emit({valid: this.formArray.valid, entries: this.formArray.value});
  }

  addMember(g?: Exclude<MemberValue, null>[number]) {
    const formControlIndividualOrEntity = this.formBuilder.control<IndividualOrEntity>('Entity', [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(null);
    const formControlPersonalLiabilities = this.formBuilder.control(null);
    const formControlKind= this.formBuilder.control('Member');
    const formGroup = this.formBuilder.group({
      kind: formControlKind,
      type: formControlIndividualOrEntity,
      personalAssets: formControlPersonalAssets,
      personalLiabilities: formControlPersonalLiabilities,
    });
    if (g) {
      switch(g.type) {
        case 'Individual': {
          (formGroup.controls as any).type.setValue('Individual');
          this.switchToIndividualMember(formGroup, g);
          break;
        }
        case 'Entity': {
          (formGroup.controls as any).type.setValue('Entity');
          this.switchToEntityMember(formGroup, g);
          break;
        }
      }
    } else {
      this.switchToEntityMember(formGroup, g);
    }
    this.formArray.push(formGroup);

    const subscription = formControlIndividualOrEntity.valueChanges.pipe(
      delay(0),
      tap((r: IndividualOrEntity|null) => {
        switch (r) {
          case 'Individual': {
            this.switchToIndividualMember(formGroup);
            break;
          }
          case 'Entity': {
            this.switchToEntityMember(formGroup);
            break;
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
    this.events.emit({valid: this.formArray.valid, entries: this.formArray.value});
  }

  private switchToIndividualMember(formGroup: FormGroup, member?: IndividualMember) {
    const formControlTitle = this.formBuilder.control(member ? member.title : null, [Validators.required]);
    const formControlFirstName = this.formBuilder.control(member ? member.firstName : null, [Validators.required]);
    const formControlLastName = this.formBuilder.control(member ? member.lastName : null, [Validators.required]);
    const formControlMiddleName = this.formBuilder.control(member ? member.middleName : null);
    const formControlGender = this.formBuilder.control(member ? member.gender : 'Male', [Validators.required]);
    const formControlDob = this.formBuilder.control(member ? member.dob : null, [Validators.required]);
    const formControlResidentialAddress = this.formBuilder.control(member ? member.residentialAddress : null, [Validators.required]);
    const formControlPrivacyConsentObtained = this.formBuilder.control(member ? member.privacyConsentObtained : null, [Validators.required]);
    const formControlPropertyOwner = this.formBuilder.control(member ? member.propertyOwner : {propertyOwner: false}, [Validators.required]);
    const formControlGuarantor = this.formBuilder.control(member ? member.guarantor : null, [Validators.required]);
    const formControlEmail = this.formBuilder.control(member ? member.email : null, [Validators.required, Validators.required]);
    const formControlMobileNumber = this.formBuilder.control(member ? member.mobileNumber : null, [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(member ? member.personalAssets : null);
    const formControlPersonalLiabilities = this.formBuilder.control(member ? member.personalLiabilities : null);


    formGroup.removeControl('organisation');
    formGroup.removeControl('organisationType');
    formGroup.removeControl('acn');
    formGroup.removeControl('abn');

    formGroup.addControl('title', formControlTitle);
    formGroup.addControl('firstName', formControlFirstName);
    formGroup.addControl('lastName', formControlLastName);
    formGroup.addControl('middleName', formControlMiddleName);
    formGroup.addControl('gender', formControlGender);
    formGroup.addControl('dob', formControlDob);
    formGroup.addControl('residentialAddress', formControlResidentialAddress);
    formGroup.addControl('privacyConsentObtained', formControlPrivacyConsentObtained);
    formGroup.addControl('propertyOwner', formControlPropertyOwner);
    formGroup.addControl('guarantor', formControlGuarantor);
    formGroup.addControl('email', formControlEmail);
    formGroup.addControl('mobileNumber', formControlMobileNumber);
    formGroup.addControl('personalAssets', formControlPersonalAssets);
    formGroup.addControl('personalLiabilities', formControlPersonalLiabilities);
  }

  private switchToEntityMember(formGroup: FormGroup, member?: EntityMember) {
    const formControlOrganisation = this.formBuilder.control(member ? member.organisation : null, [Validators.required]); // business-search
    const formControlOrganisationType = this.formBuilder.control(member ? member.organisationType : { type: 'company'}, [Validators.required]); // entity-type-selection
    const formControlAcn = this.formBuilder.control(member ? member.acn : null );
    const formControlAbn = this.formBuilder.control(member ? member.abn : null);

    formGroup.removeControl('title');
    formGroup.removeControl('firstName');
    formGroup.removeControl('lastName');
    formGroup.removeControl('middleName')
    formGroup.removeControl('gender');
    formGroup.removeControl('dob');
    formGroup.removeControl('residentialAddress');
    formGroup.removeControl('privacyConsentObtained');
    formGroup.removeControl('propertyOwner');
    formGroup.removeControl('guarantor');
    formGroup.removeControl('email');
    formGroup.removeControl('mobileNumber');
    formGroup.removeControl('personalAssets');
    formGroup.removeControl('personalLiabilities');

    formGroup.addControl('organisation', formControlOrganisation);
    formGroup.addControl('organisationType', formControlOrganisationType);
    formGroup.addControl('acn', formControlAcn);
    formGroup.addControl('abn', formControlAbn);

    this.subscriptions.push(formControlOrganisation.valueChanges.pipe(
      tap((r: BusinessNumberSearchValue) => {
        if (r) {
          const abn = r.abn;
          let acn = r.acn;
          if (acn) {
            // add leading zeros if less than 9 digits
            acn = 9 - acn.length > 0 ? '0'.repeat(9 - acn.length) + acn : acn;
            formControlAcn.setValue(acn);
          } else {
            formControlAcn.setValue('');
          }
          if (abn) {
            formControlAbn.setValue(abn);
          } else {
            formControlAbn.setValue('');
          }
          if (r.result) {
            const entityType = getEntityType(r.result)
            formControlOrganisationType.setValue(entityType)
            if (entityType?.type == 'other' || !entityType) {
              this.entityTypeReadonly = false
            } else {
              this.entityTypeReadonly = true
            }
          } else {
            this.entityTypeReadonly = !!formControlOrganisationType.value
          }
        }
      })
    ).subscribe());
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}
