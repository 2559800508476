import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
 
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {MatTableModule} from "@angular/material/table";
import {FlexModule} from "@angular/flex-layout";
import {MatInputModule} from "@angular/material/input";
import {MatSortModule, Sort} from "@angular/material/sort";
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  GetCustomersFn,
  SearchedCustomer,
  User
} from "@portal-workspace/grow-shared-library";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {PageEvent} from "@angular/material/paginator";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {createAsyncStore, loadingFor} from "@ngneat/loadoff";
import {UntilDestroy} from "@ngneat/until-destroy";
import {CustomPaginatorComponent} from "../custom-paginator-component/custom-paginator/custom-paginator.component";
import {CurrencyChipComponent} from "../currency-chip-component/currency-chip.component";
import {PortalHotToastService} from '../portal-hot-toast-component/hot-toast.service';
import {getUser, setupUntilDestroy} from "../component-utils";
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";


export class CustomersTableComponentInternalDataSource implements DataSource<SearchedCustomer> {

  subject: BehaviorSubject<SearchedCustomer[]> = new BehaviorSubject<SearchedCustomer[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SearchedCustomer[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(customers: SearchedCustomer[]) {
    this.subject.next(customers);
  }
}

export interface CustomersTableComponentEvent {
  type: 'rowclick' | 'edit',
  customerId: number;
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AsyncPipe,
    JsonPipe,
    CustomPaginatorComponent,
    MatTableModule,
    CustomContentLoaderComponent,
    CurrencyChipComponent,
    FlexModule,
    MatInputModule,
    MatSortModule
],
})
export class CustomersTableComponent {

  retry(){
    this.reload();
  }

  @Input({required: true}) getCustomersFn!: GetCustomersFn;
  @Output() events: EventEmitter<CustomersTableComponentEvent> = new EventEmitter<CustomersTableComponentEvent>();

  displayColumns = ['SalesforceId', 'acn','entityName','abn'];

  loader = loadingFor('tableLoading');
  user: User | null = null;
  dataSource = new CustomersTableComponentInternalDataSource();
  store = createAsyncStore();
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'

  subscriptions: Subscription[] = [];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  sorts?: {
    prop:  'ABN' | 'EntityName' | 'SalesforceId' | 'ACN',
    dir: 'ASC' | 'DESC'
  };
  formControlSearch: FormControl<string | null>;


  constructor(private formBuilder: FormBuilder,
              private portalHotToastService: PortalHotToastService) {
    this.formControlSearch = formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser();
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(2000),
      distinctUntilChanged(),
      tap(r => {
        // this.limit = DEFAULT_LIMIT;
        this.offset = DEFAULT_OFFSET;
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  reload() {
    if (this.user) {
      const sub = this.getCustomersFn({
        page: {
          offset: this.offset,
          limit: this.limit,
        },
        filter: this.formControlSearch.value ?? '',
        sorts: this.sorts ? [this.sorts] : undefined,
      }).pipe(
        this.portalHotToastService.publishErrorNotificationObservable({
          errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
        }),
        this.store.track(),
        this.loader.tableLoading.track(),
        tap(r => {
          console.log('************ r', r);
          this.total = r.total;
          this.limit = r.limit;
          this.offset = r.offset;
          this.dataSource.update(r.payload);
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      let prop = 'Email';
      switch($event.active) {
        case 'SalesforceId' :
          prop = 'SalesforceId';
          break;
        case 'entityName':
          prop = 'EntityName';
          break;
        case 'acn':
          prop = 'ACN';
          break;
        case 'abn':
          prop = 'ABN';
          break;
      }
      // this.limit = DEFAULT_LIMIT;
      this.offset = DEFAULT_OFFSET;
      this.sorts = {
        prop: prop as any, dir
      };
      this.reload();
    } else {
      this.sorts = undefined;
      this.reload();
    }
  }

  onRowClicked($event: Event, element: SearchedCustomer) {
    this.events.emit({
      type: 'rowclick',
      customerId: element.CustomerId,
    })
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.reload();
  }

  async edit($event: MouseEvent, element: SearchedCustomer) {
    this.events.emit({
      type: 'edit',
      customerId: element.CustomerId,
    })
  }
}
