import { Component, Inject, OnInit } from "@angular/core";
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { GetPismoTransactionFlowFn, GetPismoTransactionTypeByIdFn, PismoCardDetails, PismoGetTransactionType, PismoPostManualTransactionDialogData, PismoPostManualTransactionDialogResult, PismoTransaction, PismoTransactionType, PostManualTransactionFn, RadioOptionValue, PismoTransactionFlow, PismoTransactionFlowItems, PismoGetTransactionTypesFn, PismoGetCardsForAccountFn, PismoGetTransactionTypesResponse, PismoGetBuiltInTransactionTypesFn, PismoBuiltInTransactionType } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { RadioOptionsComponent } from '../radio-options-component/radio-options.component';
import { CurrencyInputComponent } from "../currency-selection-component/currency-input.component";
import { ApplicationDialogService, Mark, PortalHotToastService, doMarkAll, formControlErrorKeys, formControlErrorMessage, markTriggerSubject, noSpaceValidator } from "@portal-workspace/grow-ui-library";
import { Subject, Subscription, combineLatest, switchMap, tap } from "rxjs";


@Component({
  templateUrl: './pismo-post-manual-transaction.dialog.html',
  styleUrls: ['./pismo-post-manual-transaction.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    LooseCurrencyPipe,
    RadioOptionsComponent,
    CurrencyInputComponent
]
})
export class PismoPostManualTransactionDialog implements OnInit, Mark {

  formGroup: FormGroup<{
    transactionType: FormControl<PismoGetTransactionType | null>,
    transactionLevel: FormControl<RadioOptionValue>,
    amount: FormControl<number | null>,
    description: FormControl<string | null>,
    card: FormControl<PismoCardDetails | null>
  }>;
  formControlPismoTransactionType!: FormControl<PismoGetTransactionType | null>;
  formControlTransactionLevel!: FormControl<RadioOptionValue>
  formControlAmount !: FormControl<number | null>
  formControlDescription !: FormControl<string | null>
  formControlCard!: FormControl<PismoCardDetails | null>;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  markTriggerSubject = markTriggerSubject;
  markObservable: Subject<boolean> = new Subject<boolean>();
  showCard: boolean = false;
  subscriptions: Subscription[] = [];

  accountId!: number
  pismoTransactionTypes: PismoGetTransactionType[] = [];
  builtInTransactionTypes: PismoBuiltInTransactionType[] = [];
  pismoCardsByAccount!: PismoCardDetails[];
  getPismoTransactionTypeByIdFn!: GetPismoTransactionTypeByIdFn;
  postManualTransactionFn!: PostManualTransactionFn;
  pismoTransactionType!: PismoTransactionType;
  getPismoTransactionFlowFn!: GetPismoTransactionFlowFn;
  pismoGetTransactionTypesFn!: PismoGetTransactionTypesFn;
  pismoGetCardsForAccountFn!: PismoGetCardsForAccountFn;
  pismoGetBuiltInTransactionTypesFn!: PismoGetBuiltInTransactionTypesFn;
  processingCode!: string;
  transactionFlows: PismoTransactionFlowItems[] = [];
  radioOptions = [
    { type: 'account', name: 'Account Level' },
    { type: 'card', name: 'Card Level' },
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PismoPostManualTransactionDialogData,
    private dialogRef: MatDialogRef<PismoPostManualTransactionDialog, PismoPostManualTransactionDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.getPismoTransactionTypeByIdFn = data.getPismoTransactionTypeByIdFn;
    this.postManualTransactionFn = data.postManualTransactionFn;
    this.accountId = data.pismoAccountId;
    this.getPismoTransactionFlowFn = data.getPismoTransactionFlowFn;
    this.pismoGetCardsForAccountFn = data.pismoGetCardsForAccountFn;
    this.pismoGetTransactionTypesFn = data.pismoGetTransactionTypesFn;
    this.pismoGetBuiltInTransactionTypesFn = data.pismoGetBuiltInTransactionTypesFn;

    this.formControlPismoTransactionType = formBuilder.control(null, [Validators.required]);
    this.formControlTransactionLevel = formBuilder.control(null, [Validators.required]);
    this.formControlAmount = formBuilder.control(0, [Validators.required]);
    this.formControlDescription = formBuilder.control(null, [Validators.required, noSpaceValidator()]);
    this.formControlCard = formBuilder.control(null);

    this.formGroup = formBuilder.group({
      transactionType: this.formControlPismoTransactionType,
      transactionLevel: this.formControlTransactionLevel,
      amount: this.formControlAmount,
      description: this.formControlDescription,
      card: this.formControlCard
    }, { validators: [this.cardValidator] });
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  cardValidator(control: AbstractControl): ValidationErrors | null {
    const transactionLevel = control.get('transactionLevel')?.value;
    const card = control.get('card')?.value;

    if (transactionLevel === 'card' && card === null) {
      return { cardRequired: true };
    }

    return null;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.formControlTransactionLevel.valueChanges.pipe(
        tap((r: RadioOptionValue) => {
          if (r == 'card') {
            this.showCard = true
            this.formControlCard.setValidators(Validators.required)
            this.formControlCard.updateValueAndValidity();
          }
          else {
            this.showCard = false
            this.formControlCard.setValue(null)
            this.formControlCard.clearValidators()
            this.formControlCard.updateValueAndValidity();

          }
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.pismoGetCardsForAccountFn(this.accountId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe((cards: PismoCardDetails[]) => {
        this.pismoCardsByAccount = cards.filter(card => card.stage !== 'BLOCKED');
      })
    )

    this.subscriptions.push(
      combineLatest([
        this.getPismoTransactionFlowFn(),
        this.pismoGetTransactionTypesFn(),
        this.pismoGetBuiltInTransactionTypesFn()
      ]).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(([transactionFlows, transactionTypes, builtInTransactionTypes]: [PismoTransactionFlowItems[], PismoGetTransactionType[], PismoBuiltInTransactionType[]]) => {
        this.transactionFlows = transactionFlows;
        this.builtInTransactionTypes = builtInTransactionTypes;
        this.pismoTransactionTypes = transactionTypes.filter(type =>
          this.transactionFlows.find(flow => flow.transaction_type_id === type.transaction_type_id || 
          this.builtInTransactionTypes.find(builtIn => builtIn.transaction_type_id === type.transaction_type_id )
        ));
        console.log('===transactionTypes: ', transactionTypes);
        console.log('===builtInTransactionTypes: ', builtInTransactionTypes);
        console.log('====manual transaction types: ', this.pismoTransactionTypes)
      })
    )

    this.subscriptions.push(
      this.formControlPismoTransactionType.valueChanges.subscribe((transactionType: PismoGetTransactionType | null) => {
        if (transactionType) {
          this.processingCode = 
            (this.transactionFlows.find(flow => flow.transaction_type_id === transactionType.transaction_type_id))?.processing_code ?? 
            (this.builtInTransactionTypes.find(builtIn => builtIn.transaction_type_id === transactionType.transaction_type_id))?.processing_code ?? "";
          console.log('processing code: ', this.processingCode);
        }
      })
    )
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }

  onSave(event: Event) {
    // alert('Not implemented');
    let data = this.formGroup.value;
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);
    if (this.formGroup.invalid) {
      console.log('inside the invalid state')
    }
    else if (this.formControlTransactionLevel.value == 'card' && this.formControlCard.value == null) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Please select a card or change the transaction level',
      });
    }
    else {
      this.subscriptions.push(this.postManualTransactionFn(this.accountId, {
        amount: this.formControlAmount.value!,
        card: this.formControlCard.value,
        description: this.formControlDescription.value ?? '',
        transactionLevel: this.formControlTransactionLevel.value,
        processingCode: this.processingCode,
        transactionType: this.formControlPismoTransactionType.value?.transaction_type_description!
      }).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Manual transaction posted'),
        tap(r => {
          this.dialogRef.close({
            valid: true,
          });
        })
      ).subscribe());
    }
  }

}
