import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AssetSupplier, compareMatch, PpsrAssetSupplierSearchValue} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  autocompleteObjectValidator,
  createAbnInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy,
  validAbnValidator
} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { Mark, MARK } from '@portal-workspace/grow-ui-library/mark';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { CellComponent } from '../application-summary-component/cell.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export type Supplier2ComponentEvent = Supplier2ComponentAddSupplierEvent;

export interface Supplier2ComponentAddSupplierEvent  {
  type: 'add-supplier',
  supplier: AssetSupplier,
}

export type SearchSupplier2Fn = (abn: string, accreditedOnly?: boolean)=> Observable<AssetSupplier[]>;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'supplier2',
    templateUrl: './supplier2.component.html',
    styleUrls: ['./supplier2.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => Supplier2Component), multi: true },
        { provide: MARK, useExisting: forwardRef(() => Supplier2Component) }
    ],
    standalone: true,
    imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    InputMaskModule,
    MatOptionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CellComponent,
    AsyncPipe,
    JsonPipe
]
})
export class Supplier2Component extends AbstractControlValueAccessor<PpsrAssetSupplierSearchValue> implements OnInit, Mark {

  // NOTE: input mask do not work well with mat-autocomplete
  // createAbnInputMask = createAbnInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  displayWithFn: (assetSupplier: AssetSupplier) => string = (assetSupplier) => {
    return assetSupplier?.ABN ?? '';
  };

  @Output() events = new EventEmitter<Supplier2ComponentEvent>();

  @Input({required: true}) searchSupplierFn!: SearchSupplier2Fn;
  @Input({required: false}) title: string = 'Supplier';

  state: 'loading' | 'selected' | 'error' | 'done' | '' = '';

  @Input({required: false}) accreditedOnly: boolean = false;
  flag = true;

  filteredAssetSuppliers: AssetSupplier[] = [];
  selectedSupplier: PpsrAssetSupplierSearchValue = null;

  formGroup!: FormGroup<{
    search: FormControl<PpsrAssetSupplierSearchValue | string>
  }>;
  formControlSearch!: FormControl<PpsrAssetSupplierSearchValue | string>;

  constructor(private formBuilder: FormBuilder,
              private applicationDialogService: ApplicationDialogService) {
    super();
    this.formControlSearch = this.formBuilder.control(null, [Validators.required, validAbnValidator(), autocompleteObjectValidator()]);
    this.formGroup = this.formBuilder.group({
      search: this.formControlSearch
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlSearch.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      debounceTime(1000),
      filter(v => (typeof v === 'string')),
      filter(v => !isNaN(parseInt(v))),
      switchMap(v => {
        this.flag = true;
        if (!v) {
          this.propagateChange(null);
          return of(null);
        } else {
          this.state = 'loading';
          return this.searchSupplierFn(v as string, this.accreditedOnly)
        }
      }),
      map(v => {
        console.log('=v: ', v)
        this.state = 'done';
        if (v?.length) {
          this.flag = true
          this.filteredAssetSuppliers = v;
          // if(this.selectedSupplier){
          //   this.formControlSearch.clearValidators();
          //   this.formControlSearch.updateValueAndValidity();
          // }
        } else {
          this.filteredAssetSuppliers = [];
          this.flag = false
          this.selectedSupplier = null;
          this.propagateChange(null);
        }
      })
    ).subscribe();
  }

  doWriteValue(v: PpsrAssetSupplierSearchValue | null | undefined): void | PpsrAssetSupplierSearchValue {
    if (v) {
      this.formControlSearch.setValue(v);
      // this.formControlSearch.setValue(v.ABN);
      this.selectedSupplier = v;
    } else {
      this.formControlSearch.reset();
      this.propagateChange(null);
    }
    return undefined;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
    if (!this.selectedSupplier) {
      this.formControlSearch.setErrors({'required': true});
    }
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const v: AssetSupplier | '-1' = $event.option.value;
    if (v != '-1') {
      this.state = 'selected';
      this.selectedSupplier = v;
      this.propagateChange(v);
      // this.formControlSearch.clearValidators();
      // this.formControlSearch.updateValueAndValidity();
    }
  }

  addSupplier() {
    let abn = '';
    const searchValue = this.formControlSearch.value;
    if (searchValue) {
      if (typeof searchValue == 'string') {
        abn = searchValue;
      } else {
        abn = searchValue.ABN ?? '';
      }
    }
    this.applicationDialogService.openPrivateSupplierDialog({
      abn,
      businessName: '',
      supplierAddress: ''
    }).afterClosed().pipe(
      tap(r => {
        if (r) {
          this.selectedSupplier = r;
          this.events.emit({
            type: 'add-supplier',
            supplier: r
          });
        }
      })
    ).subscribe();
  }
}
