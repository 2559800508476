import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {BankTransferDialogData, BankTransferDialogResult, LocalBankAccountDetailValue, ManageApplicationDialogData, ManageApplicationDialogResult, NewDirectPaymentBody, parseString} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { NameComponent } from '../name-component/name.component';
import { EmailComponent } from '../common fields/email.component';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './manage-application.dialog.html',
    styleUrls: ['./manage-application.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NameComponent, EmailComponent, InputMaskModule, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class ManageApplicationDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    email: FormControl<string | null>;
  }>;

  formControlFirstName!: FormControl<string | null>;
  formControlLastName!: FormControl<string | null>;
  formControlEmail!: FormControl<string | null>;
  
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ManageApplicationDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<ManageApplicationDialog, ManageApplicationDialogResult>) {
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required]);

    this.formGroup = this.formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      email: this.formControlEmail,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        firstName: parseString(this.formControlFirstName.value ?? ''),
        lastName: parseString(this.formControlLastName.value ?? ''),
        email: parseString(this.formControlEmail.value ?? ''),
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
