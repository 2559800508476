import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CustomDocumentTagDialogData, CustomDocumentTagDialogResult} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './custom-document-tag.dialog.html',
    styleUrls: ['./custom-document-tag.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class CustomDocumentTagDialog implements OnInit {

  formControlTagName!: FormControl<string | null>;
  formControlTagValue!: FormControl<string | null>;
  formControlDescription!: FormControl<string | null>;
  formGroup!: FormGroup;
  isNonStandardCondition: boolean = false;

  tagNamePattern = /^[_a-zA-Z][_a-zA-Z0-9\s]{0,255}$/;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: CustomDocumentTagDialogData,
    private matDialogRef: MatDialogRef<CustomDocumentTagDialog, CustomDocumentTagDialogResult>
  ) {
    this.formControlTagName = this.fb.control('', [Validators.required, Validators.pattern(this.tagNamePattern)]);
    this.formControlTagValue = this.fb.control('');
    this.formControlDescription = this.fb.control('');
    this.formGroup = this.fb.group({
      type: this.formControlTagName,
      value: this.formControlTagValue,
      description: this.formControlDescription
    })
    this.isNonStandardCondition = this.dialogData.nonStandardCondition;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.formControlTagName.valueChanges.subscribe(r => {
        this.updateValueControl();
      })
    )
  }

  updateValueControl() {
    const tagName = this.formControlTagName.value as string;
    const tagValue = tagName.toLowerCase().replace(/\s+/g, '');
    this.formControlTagValue.setValue(tagValue);
  }

  onCancel() {
    this.matDialogRef.close({
      readyForSubmission: false,
      tag: this.tagValue
    })
  }

  onSubmit() {
    this.matDialogRef.close({
      readyForSubmission: true,
      tag: this.tagValue
    })
  }

  get tagValue() {
    if (this.isNonStandardCondition) {
      return {
        ...this.formGroup.value,
        nonStandardCondition: true,
        nonStandardConditionStatus: "Pending"
      }
    } else {
      return {...this.formGroup.value}
    }
  }
}
