import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {
  CreditFlowNode,
  CreditFlowNodeType,
  CreditWebServiceOptions,
  WebServiceType,
  EditCreditFlowNodeDialogData, EditCreditFlowNodeDialogResult, CreditRuleMappingLevel,
  GetCreditRuleItemsFn,
  CreditRuleItem,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';

@Component({
    templateUrl: './edit-credit-flow-node.dialog.html',
    styleUrls: ['./edit-credit-flow-node.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class EditCreditFlowNodeDialog  implements OnInit{
  creditFlowNode: CreditFlowNode | null = null;
  creditFlowId!: number;
  nextNodeOptions: CreditFlowNode[] = [];
  formControlName!: FormControl<string | null>;
  formControlType!: FormControl<CreditFlowNodeType | null>;
  formControlWebService!: FormControl<WebServiceType | null>;
  formControlNextNode!: FormControl<number | null>;
  formControlLevel!: FormControl<CreditRuleMappingLevel | null>;
  formControlConditionVariable!: FormControl<number | null>;
  formGroup!: FormGroup<{
    name: FormControl<string | null>,
    type: FormControl<CreditFlowNodeType | null>,
    // isStart: FormControl<boolean | null>,
    // isEnd: FormControl<boolean | null>,
    webService: FormControl<WebServiceType | null>,
    nextNode: FormControl<number | null>,
    webServiceLevel: FormControl<CreditRuleMappingLevel | null>,
    conditionVariable: FormControl<number | null>,
  }>

  webServiceOptions = CreditWebServiceOptions;
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  creditRuleItems: CreditRuleItem[] = [];
  creditRuleItemOptions: CreditRuleItem[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCreditFlowNodeDialogData,
              private toastService: PortalHotToastService,
              private dialogRef: MatDialogRef<EditCreditFlowNodeDialog, EditCreditFlowNodeDialogResult>,
              private formBuilder: FormBuilder) {
    this.creditFlowNode = this.data.creditFlowNode;
    this.creditFlowId = this.data.creditFlowId;
    this.getCreditRuleItemsFn = this.data.getCreditRuleItemsFn;
    this.nextNodeOptions = [{name: 'No Next Node', id: 0} as any, ...this.data.nextNodeList];
    this.formControlName = this.formBuilder.control(null, [Validators.required]);
    this.formControlType = this.formBuilder.control(null, [Validators.required]);
    // this.formControlIsStart = this.formBuilder.control(null, [Validators.required]);
    // this.formControlIsEnd = this.formBuilder.control(null, [Validators.required]);
    this.formControlWebService = this.formBuilder.control(null);
    this.formControlNextNode = this.formBuilder.control(null);
    this.formControlLevel = this.formBuilder.control(null);
    this.formControlConditionVariable = this.formBuilder.control(null);

    this.formGroup = this.formBuilder.group({
      name: this.formControlName,
      type: this.formControlType,
      webService: this.formControlWebService,
      nextNode: this.formControlNextNode,
      webServiceLevel: this.formControlLevel,
      conditionVariable: this.formControlConditionVariable,
    });

    if (this.creditFlowNode) {
      this.formControlName.setValue(this.creditFlowNode.name);
      this.formControlType.setValue(this.creditFlowNode.type);
      // this.formControlIsStart.setValue(this.creditFlowNode.isStart);
      this.formControlWebService.setValue(this.creditFlowNode.webService);
      this.formControlNextNode.setValue(this.creditFlowNode.nextNode ?? 0);
      this.formControlLevel.setValue(this.creditFlowNode.webServiceLevel);
      this.formControlConditionVariable.setValue(this.creditFlowNode.conditionVariable);
    }
  }

  ngOnInit(): void {
    this.formControlType.valueChanges.subscribe(type => {
      if (type === 'web service') {
        this.formControlLevel.clearValidators();
        this.formControlLevel.setValidators([Validators.required]);
        this.formControlLevel.updateValueAndValidity();
      } else {
        this.formControlLevel.clearValidators();
        this.formControlLevel.updateValueAndValidity();
      }
    })

    this.formControlWebService.valueChanges.subscribe(webService => {
      if (webService === 'CreditorWatch') {
        this.formControlLevel.setValue('Entity');
      } else if (webService === 'EquifaxConsumer') {
        this.formControlLevel.setValue('Individual');
      } else if (webService === 'EquifaxFraudCheck') {
        this.formControlLevel.setValue('Individual');
      }
    })

    this.getCreditRuleItemsFn().pipe(
      this.toastService.spinnerObservable(),
    ).subscribe(creditRuleItems => {
      this.creditRuleItems = creditRuleItems;
      const level = this.formControlLevel.value;
      if (level) {
        this.creditRuleItemOptions = [{id: 0, name: 'No Condition'} as any, ...this.creditRuleItems.filter(item => item.level === level && item.outputType === 'Boolean')];
      }
    })

    this.formControlLevel.valueChanges.subscribe(level => {
      if (level) {
        this.creditRuleItemOptions = [{id: 0, name: 'No Condition'} as any, ...this.creditRuleItems.filter(item => item.level === level && item.outputType === 'Boolean')];
      }
    })
  }

  onCancel() {
    this.dialogRef.close({
      readyForSubmission: false,
      creditFlowNode: null
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        creditFlowNode: {
          name: this.formControlName.value as string,
          type: this.formControlType.value as CreditFlowNodeType,
          // isStart: this.formControlIsStart.value as boolean,
          webService: this.formControlWebService.value,
          creditFlowId: this.creditFlowId,
          nextNode: this.formControlNextNode.value !== 0 ? this.formControlNextNode.value : null,
          webServiceLevel: this.formControlLevel.value,
          conditionVariable: this.formControlConditionVariable.value !== 0 ? this.formControlConditionVariable.value : null,
        }    
      })
    }
  }
}
