export type EntityTypes = 'trust' | 'sole-trader' | 'partnership' | 'company' | 'other';

export type EntityTypeValue = {
  type: EntityTypes;
  name: string;
} | null;


export const EntityTypeValueOptions: Exclude<EntityTypeValue, null>[] =  [
  { type: 'trust', name: 'Trust'},
  { type: 'sole-trader', name: 'SoleTrader'},
  { type: 'partnership', name: 'Partnership'},
  { type: 'company', name: 'Company'},
  { type: 'other', name: 'Other'},
];
export const EntityTypeTrustOption = EntityTypeValueOptions[0];
export const EntityTypeSoleTraderOption = EntityTypeValueOptions[1];
export const EntityTypePartnershipOption = EntityTypeValueOptions[2];
export const EntityTypeCompanyOption = EntityTypeValueOptions[3];
export const EntityTypeOtherOption = EntityTypeValueOptions[4];
