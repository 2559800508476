export type FinanceType = 'rental' | 'operating-lease' | 'chattel-mortgage' | 'finance-lease' | 'software-agreement';
export type FinanceTypeValue = {
  type: FinanceType,
  name: string,
} | null;


export const FinanceTypeValueOptions: Exclude<FinanceTypeValue, null>[] = [
  {type: 'chattel-mortgage', name: 'Chattel Mortgage'},
  {type: 'rental', name: 'Rental'},
  {type: 'operating-lease', name: 'Operating Lease'},
  {type: 'finance-lease', name: 'Finance Lease'},
  {type: 'software-agreement', name: 'Software Agreement'},
];
