import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {isMoment, Moment} from 'moment';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {createDateInputMask} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import moment from 'moment';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { CustomErrorStateMatcher } from '@portal-workspace/grow-ui-library';
import { compareMatch, Datepicker2Value } from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'datepicker2',
    templateUrl: './datepicker2.component.html',
    styleUrls: ['./datepicker2.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => Datepicker2Component), multi: true },
        { provide: MARK, useExisting: forwardRef(() => Datepicker2Component) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, InputMaskModule, ReactiveFormsModule]
})
export class Datepicker2Component extends AbstractControlValueAccessor<Datepicker2Value> implements OnInit, OnChanges, Mark {

  createDateInputMask = createDateInputMask();
  errorStateMatcher = new CustomErrorStateMatcher();

  @Input({required: true}) title?: string;
  @Input({required: false}) disabled: boolean = false;
  @Input({required: false}) minDate: Moment | null = null;
  @Input({required: false}) maxDate: Moment | null = null;

  subscriptions: Subscription[] = [];

  formControl!: FormControl<string|null>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const validators = [Validators.required];
    validators.push((control: AbstractControl) => {
      if (this.minDate) {
        const d = control.value;
        if (d) {
          if (typeof d === 'string') {
            const m = moment(d, 'DD/MM/YYYY');
            if(m.isValid()) {
              console.log('**** min ', this.minDate.toString(), m.toString());
              if (this.minDate.startOf('day').isAfter(m)) { // min date is after given date - error
                return {
                  minDate: {min: this.minDate.format('DD/MM/YYYY')},
                }
              }
            }
          } else if (moment.isMoment(d)) {
            if (this.minDate.startOf('day').isAfter(d)) { // min date is after given date - error
              return {
                minDate: {min: this.minDate.format('DD/MM/YYYY')},
              }
            }
          }
        }
      }
      return null;
    });
    validators.push((control: AbstractControl) => {
      if (this.maxDate) {
        const d = control.value;
        if (d) {
          if (typeof d === 'string') {
            const m = moment(d, 'DD/MM/YYYY');
            if(m.isValid()) {
              if (this.maxDate.startOf('day').isBefore(m)) { // max date is before given date - error
                return {
                  maxDate: {max: this.maxDate.format('DD/MM/YYYY')},
                }
              }
            }
          } else if (moment.isMoment(d)) {
            if (this.maxDate.startOf('day').isBefore(d)) { // max date is before given date - error
              return {
                maxDate: {max: this.maxDate.format('DD/MM/YYYY')},
              }
            }
          }
        }
      }
      return null;
    });

    this.formControl = this.formBuilder.control(null, validators);
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        // https://github.com/angular/components/issues/11523
        if (this.formControl.valid || (this.formControl.errors as any)?.matDatepickerParse) {
          // https://github.com/angular/components/issues/11523
          if (!!(this.formControl.errors as any)?.matDatepickerParse?.text) {
            this.formControl.setErrors(null);
          }
          if (typeof r == 'string') {
            const m = moment(r, 'DD/MM/YYYY');
            this.propagateChange(m);
          } else {
            this.propagateChange(r);
          }
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl) {
      if ((changes as any).minDate || (changes as any).maxDate) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  doWriteValue(v: Datepicker2Value | null | undefined): void | Datepicker2Value {
    if (v && isMoment(v)) {
      this.formControl.setValue(v.format('DD/MM/YYYY'));
    } else if (v == null || v == undefined) {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  // getAge() {
  //    let age = 0;
  //    let today = new Date();
  //    let selectedDate = new Date(this.formControl.value);
  //    age = today.getFullYear() - selectedDate.getFullYear();
  //    let m = today.getMonth() - selectedDate.getMonth();
  //    if (m < 0 || (m === 0 && today.getDate() < selectedDate.getDate())) {
  //      age--;
  //    }
  //    if (age < 18) {
  //      this.applicationDialogService.openAlertDialog({
  //        message: `Error`,
  //        subMessage: 'Age must be a minimum 18 years old. '
  //      });
  //      this.formControl.reset();
  //    }
  //  }

  // onDateChange($event: Event) {
  //  const dateValue = ($event.target as HTMLInputElement).value;
  //   const date = moment(dateValue, 'DD/MM/YYYY', false);
  //   const valid = date.isValid();
  //   if (valid) {
  //     if(this.allowOnly18YrsPlus===true){
  //       this.formControl.valueChanges.subscribe(r=>{
  //         this.getAge();
  //       })
  //     }
  //   } else {
  //     this.formControl.setErrors({'invalidDateFormat': true});
  //   }
  // }
  onDateChange($event: MatDatepickerInputEvent<Moment>) {
    if ($event && $event.value) {
      // if(this.allowOnlyTodayOrFutureDate){
      //   this.formControl.setValidators(mustBeTodayOrFutureDateValidator());
      //   this.formControl.updateValueAndValidity();
      // }
      this.formControl.setValue($event.value.format('DD/MM/YYYY'));
      this.formControl.updateValueAndValidity();
    }
  }
  mark() {
    this.formControl.markAllAsTouched();
    this.formControl.updateValueAndValidity();
  }

}
