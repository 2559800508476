import {Component, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {StateSelectionValue, StateSelectionValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';


@Component({
    selector: 'state-selection',
    templateUrl: './state-selection.component.html',
    styleUrls: ['./state-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StateSelectionComponent), multi: true },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class StateSelectionComponent extends AbstractControlValueAccessor<StateSelectionValue> {

  options = StateSelectionValueOptions;


  formGroup: FormGroup<{
    state: FormControl<StateSelectionValue>
  }>;
  formControl: FormControl<StateSelectionValue>;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      state: this.formControl,
    })
  }

  onSelectionChange($event: MatSelectChange) {
    const v: StateSelectionValue = $event.value;
    this.propagateChange(v ? v : null);
  }

  doWriteValue(v?: StateSelectionValue): void {
    if (v) {
      const index = this.options.findIndex( r => r && r.type === v.type);
      if (index !== -1) {
        this.formControl.setValue(this.options[index]);
      }
    } else {
      this.formControl.setValue(null);
    }
  }


}
