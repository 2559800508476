<div class="serviceability-ratio-component">
  @if (loader.serviceabilityCalculation.inProgress$ | async) {
    <div>
      <custom-content-loader type="facebook" ></custom-content-loader>
    </div>
  }
  @if (serviceabilityCalculationResult && !(loader.serviceabilityCalculation.inProgress$ | async)) {
    <div class="row">
      <div class="col-8">
        <div class="mat-body separator-bottom">
          The serviceability calculation below is subject to change pending on the confirmation of income and expense provided at the time of application
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" fxLayout="row">
        <div fxLayoutAlign="center center">
          @if (serviceabilityCalculationRequirementStage() == 'fail') {
            <img src="/assets/images/serviceability-ratio/serviceability-chart-fail.png">
          }
          @if (serviceabilityCalculationRequirementStage() == 'weak') {
            <img src="/assets/images/serviceability-ratio/serviceability-chart-weak.png">
          }
          @if (serviceabilityCalculationRequirementStage() == 'standard') {
            <img src="/assets/images/serviceability-ratio/serviceability-chart-standard.png">
          }
          @if (serviceabilityCalculationRequirementStage() == 'strong') {
            <img src="/assets/images/serviceability-ratio/serviceability-chart-strong.png">
          }
        </div>
        <table mat-table [dataSource]="dataSource" class="ml-5">
          <ng-container matColumnDef="serviceabilityCriteria">
            <td mat-cell *matCellDef="let element">{{element.serviceabilityCriteria}}</td>
          </ng-container>
          <ng-container matColumnDef="serviceabilityValue">
            <td mat-cell *matCellDef="let element">{{element.serviceabilityValue}}</td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['serviceabilityCriteria', 'serviceabilityValue'];"></tr>
        </table>
      </div>
    </div>
    @if (serviceabilityCalculationRequirementStage() == 'fail') {
      <div class="row separator">
        <div class="col">
          <div class="color-warn">The customer does not meet our minimum serviceability requirements</div>
        </div>
      </div>
    }
    <div class="row separator-top">
      <div class="col">
        @if (serviceabilityCalculationResult) {
          <button color="primary" mat-flat-button (click)="calculateServiceability($event)">Recalculate Serviceability</button>
        }
      </div>
    </div>
  }
  @if (!serviceabilityCalculationResult && !(loader.serviceabilityCalculation.inProgress$ | async)) {
    <div class="row">
      <div class="col">
        <button color="primary" mat-flat-button (click)="calculateServiceability($event)">Calculate Serviceability</button>
      </div>
    </div>
  }
</div>
