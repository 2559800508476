import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyInputDialogData, CurrencyInputDialogResult, CurrencyInputValue } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    templateUrl: './currency-input.dialog.html',
    styleUrls: ['./currency-input.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class CurrencyInputDialog {

  formGroup: FormGroup<{
    value: FormControl<CurrencyInputValue>;
  }>;
  formControl: FormControl<CurrencyInputValue>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CurrencyInputDialogData,
              private matDialogRef: MatDialogRef<CurrencyInputDialog, CurrencyInputDialogResult>,
              private formBuilder: FormBuilder) {
    this.formControl = formBuilder.control(data.value, [Validators.required]);
    if (data.nothingGreater) {
      this.formControl.addValidators(this.nothingGreater(this.data));
    }
    if (data.nothingLower) {
      this.formControl.addValidators(this.nothingLower(this.data));
    }
    this.formGroup = formBuilder.group({
      value: this.formControl,
    })
  }

  nothingGreater(data: CurrencyInputDialogData): ValidatorFn {
    return (control: AbstractControl) => {
      if(control.value > this.data.value) {
        return {nothingGreater: true};
      }
      return null;
    }
  }

  nothingLower(data: CurrencyInputDialogData): ValidatorFn {
    return (control: AbstractControl) => {
      console.log('**** nothing lowere', control.value, data.value);
      if (control.value < this.data.value) {
        return {nothingLower: true}
      }
      return null;
    }
  }

  onCancel($event: Event) {
    this.matDialogRef.close({valid: false, value: this.formControl.value ?? 0});
  }

  onSubmit($event: Event) {
    this.matDialogRef.close({valid: true, value: this.formControl.value ?? 0});
  }
}
