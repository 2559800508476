<div class="application-error-page container-fluid">
  <!-- e1 : invalid application id -->
  @if (activatedRoute.snapshot.queryParams.type === 'e1') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Invalid application id">
          <div>Unrecognised application id {{activatedRoute.snapshot.params.applicationId}}</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-asset : not an asset finance application -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-asset') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Invalid application type">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not an asset finance application</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-draft : not in draft mode -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-draft') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Not editable">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not in draft mode, uneditable</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-business:  -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-business') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Not a Business finance application">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not a Business application</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-commercial:  -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-commercial') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Not a Commercial finance application">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not a Commercial application</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-consumer -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-consumer') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Not a Consumer finance application">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not a consumer application</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-not-insurance -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-not-insurance') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Not a Insurance finance application">
          <div>Application {{activatedRoute.snapshot.params.applicationId}} is not a Insurance application</div>
        </message-box>
      </div>
    </div>
  }


  <!-- e-general -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-general') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Error">
          <div>Error with Application {{activatedRoute.snapshot.params.applicationId}}</div>
        </message-box>
      </div>
    </div>
  }

  <!-- e-invalid-link -->
  @if (activatedRoute.snapshot.queryParams.type === 'e-invalid-link') {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Error">
          <div>Invalid link</div>
        </message-box>
      </div>
    </div>
  }


</div>
