@if (!((store.value$ | async)?.error)) {
  <div class="transactions-page container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Transactions</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search transaction..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" multiTemplateDataRows matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.id}}</td>
            </ng-container>
            <ng-container matColumnDef="batchId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="batchId">Batch</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.batchId}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Banking</th>
              <td mat-cell *matCellDef="let element">{{getBankingType(element)}}</td>
            </ng-container>
            <ng-container matColumnDef="processingCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processingCode">Type</th>
              <td mat-cell *matCellDef="let element">{{getProcessingType(element.processingCode)}}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">Amount</th>
              <td mat-cell *matCellDef="let element">{{element.amount | looseCurrency}}</td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
              <td mat-cell *matCellDef="let element">{{moment(element.date, "DDMMYYYY").format('DD/MM/YYYY')}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">{{getTransactionStatus(element)}}</td>
            </ng-container>
            <ng-container matColumnDef="PismoAccountId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoAccountId">Pismo Acc</th>
              <td mat-cell *matCellDef="let element">{{element.PismoAccountId}}</td>
            </ng-container>
            <ng-container matColumnDef="PismoCustomerId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoCustomerId">Pismo Cus</th>
              <td mat-cell *matCellDef="let element">{{element.PismoCustomerId}}</td>
            </ng-container>
            <ng-container matColumnDef="lastUpdatedTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUpdatedTime">Last Updated</th>
              <td mat-cell *matCellDef="let element">{{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                @if (showRetryButton(getTransactionStatus(element))) {
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
                  </div>
                }
                <mat-menu #menu="matMenu">
                  @if (true) {
                    <button mat-menu-item class="menu-items" (click)="retryTransaction(element)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Retry</div>
                        <div><span class="mdi mdi-refresh"></span></div>
                      </div>
                    </button>
                  }
                </mat-menu>
              </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element;" class="expanded-row" [attr.colspan]="displayColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="row mt-3">
                    <div class="col-4 title">Authorization ID</div>
                    <div class="col-8">{{element.authorizationId}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-4 title">Tracking ID</div>
                    <div class="col-8">{{element.trackingId}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-4 title">Processing Code</div>
                    <div class="col-8">{{element.processingCode}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-4 title">Created Time</div>
                    <div class="col-8">{{moment(element.createdTime).format('DD/MM/YYYY hh:mm:ss')}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-4 title">Account Title</div>
                    <div class="col-8">{{element.accountTitle}}</div>
                  </div>
                  @if (element.type==='bpay') {
                    <div class="row mt-2">
                      <div class="col-4 title">Biller Code</div>
                      <div class="col-8">{{element.billerCode}}</div>
                    </div>
                  }
                  @if (element.type==='bpay') {
                    <div class="row mt-2">
                      <div class="col-4 title">CRN</div>
                      <div class="col-8">{{element.crn}}</div>
                    </div>
                  }
                  @if (element.type!=='bpay') {
                    <div class="row mt-2">
                      <div class="col-4 title">BSB</div>
                      <div class="col-8">{{element.bsb}}</div>
                    </div>
                  }
                  @if (element.type!=='bpay') {
                    <div class="row mt-2">
                      <div class="col-4 title">Account Number</div>
                      <div class="col-8">{{element.accountNumber}}</div>
                    </div>
                  }
                  <div class="row mt-2">
                    <div class="col-4 title">Reference</div>
                    <div class="col-8">{{element.reference}}</div>
                  </div>
                  @if (element.processingCode === PismoTransactionProcessingCode.BROKERAGE) {
                    <div class="row mt-2">
                      <div class="col-4 title">Net Brokerage</div>
                      <div class="col-8">{{(element.netBrokerage ?? 0) | looseCurrency}}</div>
                    </div>
                  }
                  @if (element.processingCode === PismoTransactionProcessingCode.BROKERAGE) {
                    <div class="row mt-2">
                      <div class="col-4 title">GST</div>
                      <div class="col-8">{{(element.brokerageGst ?? 0) | looseCurrency}}</div>
                    </div>
                  }
                  @if (element.pendingTransactionCreatedTime) {
                    <div class="row mt-2">
                      <div class="col-4 title">Sent to the Bank</div>
                      <div class="col-8">{{moment(element.pendingTransactionCreatedTime).format('DD/MM/YYYY HH:mm:ss')}}</div>
                    </div>
                  }
                  @if (element.processedTransactionCreatedTime) {
                    <div class="row mt-2">
                      <div class="col-4 title">Processed Time</div>
                      <div class="col-8">{{moment(element.processedTransactionCreatedTime).format('DD/MM/YYYY HH:mm:ss')}}</div>
                    </div>
                  }
                  @if (element.processedTransactionErrorMessage) {
                    <div class="row mt-2">
                      <div class="col-4 title">Error Message</div>
                      <div class="col-8">{{element.processedTransactionErrorMessage}}</div>
                    </div>
                  }
                  @if (element.batchId) {
                    <div class="row mt-2">
                      <div class="col-4 title">Batch ID</div>
                      <div class="col-8">{{element.batchId}}</div>
                    </div>
                  }
                  @if (element.batchPaymentFile) {
                    <div class="row mt-2">
                      <div class="col-4 title">Payment File</div>
                      <div class="col-8">{{element.batchPaymentFile}}</div>
                    </div>
                  }
                  @if (element.responseFile) {
                    <div class="row mt-2">
                      <div class="col-4 title">Response File</div>
                      <div class="col-6 word-wrap">{{element.responseFile}}</div>
                    </div>
                  }
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayColumns"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total"
              [pageIndex]="offset"
              [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]"
              (page)="onPagination($event)"
            ></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
