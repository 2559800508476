<!-- <div class="message-box-component">
  <div [ngClass]="{
          details: type === 'details',
          info: type === 'info',
          danger: type === 'danger',
          warn: type === 'warn',
          'drawdown-submitted': type === 'drawdown-submitted'
       }"
  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div class="icon">
    <span *ngIf="type === 'details'" class="mdi mdi-account-circle-outline"></span>
    <span *ngIf="type === 'info'" class="mdi mdi-information-outline"></span>
    <span *ngIf="type === 'danger'" class="mdi mdi-information-outline"></span>
    <span *ngIf="type === 'warn'" class="mdi mdi-information-outline"></span>
    <span *ngIf="type === 'drawdown-submitted'" class="mdi mdi-forwardburger"></span>
  </div>
  <div class="content" fxLayout="column">
    <div class="title mat-h4 mb-1"  *ngIf="title">
      {{title}}
    </div>
    <div class="message mat-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
</div> -->
<ng-template #content><ng-content></ng-content></ng-template>

<div class="message-box-component">
  <div [ngClass]="{
          details: type === 'details',
          info: type === 'info',
          danger: type === 'danger',
          warn: type === 'warn',
          disable: type === 'disable',
          'drawdown-submitted': type === 'drawdown-submitted',
          'promo-style1': type === 'promo-style1',
          'promo-style2': type === 'promo-style2',
          success: type === 'success'
       }">
    <div fxLayout="row" fxLayoutGap="10px">
      @if (type !== 'promo-style1') {
        <div class="icon ml-3">
          @if (type === 'details') {
            <span class="mdi mdi-account-circle-outline"></span>
          }
          @if (type === 'info') {
            <span class="mdi mdi-information-outline"></span>
          }
          @if (type === 'disable') {
            <span class="mdi mdi-information-outline"></span>
          }
          @if (type === 'danger') {
            <span class="mdi mdi-alert-box-outline"></span>
          }
          @if (type === 'warn') {
            <span class="mdi mdi-help-circle-outline"></span>
          }
          @if (type === 'drawdown-submitted') {
            <span class="mdi mdi-forwardburger"></span>
          }
          @if (type === 'promo-style2') {
            <span class="mdi mdi-gift-outline"></span>
          }
          @if (type === 'success') {
            <span class="mdi mdi-check-circle-outline"></span>
          }
        </div>
      }
      <!--
      <div class="alert-message" *ngIf="!title">
        <span class="icon mdi mdi-{{classInput}}"></span>
        {{alertMessage}}
      </div>
      -->
      @if (!!!title) {
        <div fxLayout="column" class="content">
          @if (type !== 'promo-style2') {
            <div class="message mat-body">
              <ng-container *ngTemplateOutlet="content"></ng-container>
              @if (image) {
                <div>
                  <img [src]="image" class="image"/>
                </div>
              }
              @if (url) {
                <div class="icon">
                  <span class="mdi mdi-arrow-top-right" (click)="onClickUrl()"></span>
                </div>
              }
            </div>
          }
          @if (type === 'promo-style2') {
            <div class="message mat-body" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row">
                <ng-container *ngTemplateOutlet="content"></ng-container>
                @if (url) {
                  <div>
                    <div class="learn-more" (click)="onClickUrl()">Learn more</div>
                  </div>
                }
              </div>
              @if (type === 'promo-style2') {
                <div class="close-icon">
                  <span class="mdi mdi-close" (click)="onClose()"></span>
                </div>
              }
            </div>
          }
        </div>
      }

      @if (title) {
        <div fxLayout="column">
          <div class="title mat-h4-custom mb-1">
            {{title}}
          </div>
          <div>
            <div class="content" fxLayout="column">
              <div class="message mat-body">
                <ng-container *ngTemplateOutlet="content"></ng-container>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
