import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {inject} from '@angular/core';
import {getUser} from '@portal-workspace/grow-ui-library';
import {navigationUrlForLockedUser} from '../service/navigation-urls';


export const activatedUserLockedGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser();
    if (user) {
      if (user.isLock == true) {
        return router.navigate(navigationUrlForLockedUser())
      }
    }
    return true;
  }
}

