<div class="user-list-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" placeholder="Select a user...">
      @for (user of users; track user) {
        <mat-option [value]="user">
          {{user.name}} ({{user.email}})
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
