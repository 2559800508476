import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Notifications} from '@portal-workspace/grow-shared-library';
import { DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


export type NotificationListComponentEvent = GeneralNotificationListComponentEvent | DeleteNotificationListComponentEvent;

export interface GeneralNotificationListComponentEvent {
  type: 'markAllAsRead' | 'deleteAll'
}
export interface DeleteNotificationListComponentEvent {
  type: 'delete' | 'markAsRead',
  notificationId: number;
}

@Component({
    selector: 'notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatFormFieldModule, DatePipe]
})
export class NotificationListComponent implements OnChanges{

  @Input({required: false}) notifications: Notifications = [];
  @Output() events = new EventEmitter<NotificationListComponentEvent>();
  isLoadingMarkRead : boolean = false;
  isLoadingDelete : boolean = false;

  showMessage: {[notificationId: number]: boolean} = {};

  ngOnChanges(changes: SimpleChanges): void {
       this.isLoadingMarkRead = false;
       this.isLoadingDelete = false;
  }

  markAllAsRead($event: Event) {
    this.events.next({
      type: 'markAllAsRead',
    });
    this.isLoadingMarkRead = true;
  }

  deleteAll($event: Event) {
    this.events.next({
      type: 'deleteAll'
    });
    this.isLoadingDelete = true;
  }

  delete($event: MouseEvent, notification: Notifications[number]) {
    this.events.next({
      type: 'delete',
      notificationId: notification.id
    });
  }

  onReadNotification($event: MouseEvent, notification: Notifications[number]) {
    this.showMessage[notification.id] = !!!this.showMessage[notification.id];
    const openForReading = this.showMessage[notification.id];
    if (openForReading) {
      this.events.next({
        type: 'markAsRead',
        notificationId: notification.id
      })
    }
  }

  showNotification(notification: Notifications[number]): boolean {
    return !!this.showMessage[notification.id];
  }
}
