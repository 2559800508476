import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Application, ApplicationOwner, CreateAssetInspectionFn, GetApplicationOwnerFn, NewAssetInspectionDialogData, NewAssetInspectionDialogResult, PayloadApiResponse, PpsrAsset, VerimotoAssetInspectionBuyer, VerimotoAssetInspectionData, VerimotoAssetSelection, VerimotoCreateInspectionResponse, VerimotoInspectionTypeSelection, VerimotoLenderType, VerimotoStateSelection, constants, getInspectionTypeGuid, getVerimotoAssetGuid, stringifyJSON } from '@portal-workspace/grow-shared-library';
import { Subscription, forkJoin, tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NameComponent } from '../name-component/name.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { formControlErrorKeys, formControlErrorMessage } from '../component-utils';
import { ApplicationDialogService } from './application-dialog.service';

@Component({
  templateUrl: './new-asset-inspection.dialog.html',
  styleUrls: ['./new-asset-inspection.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass,
    NameComponent,
    MobileComponent,
    EmailComponent,
    MatFormFieldModule
]
})
export class NewAssetInspectionDialog implements OnInit {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    sellerFirstName: FormControl<string | null>,
    sellerLastName: FormControl<string | null>,
    sellerEmail: FormControl<string | null>,
    sellerMobile: FormControl<string | null>,
    buyerFirstName: FormControl<string | null>,
    buyerLastName: FormControl<string | null>,
    buyerEmail: FormControl<string | null>,
    buyerMobile: FormControl<string | null>,
    asset: FormControl<VerimotoAssetSelection | null>,
    state: FormControl<VerimotoStateSelection | null>,
    inspectionType: FormControl<VerimotoInspectionTypeSelection | null>,
    lenderReference: FormControl<string | null>,
    externalReference: FormControl<string | null>,
  }>;
  formControlSellerFirstName!: FormControl<string | null>;
  formControlSellerLastName!: FormControl<string | null>;
  formControlSellerEmail!: FormControl<string | null>;
  formControlSellerMobile!: FormControl<string | null>;
  formControlBuyerFirstName!: FormControl<string | null>;
  formControlBuyerLastName!: FormControl<string | null>;
  formControlBuyerEmail!: FormControl<string | null>;
  formControlBuyerMobile!: FormControl<string | null>;
  formControlAsset!: FormControl<VerimotoAssetSelection | null>;
  formControlState!: FormControl<VerimotoStateSelection | null>;
  formControlInspectionType!: FormControl<VerimotoInspectionTypeSelection | null>;
  formControlLenderReference!: FormControl<string | null>; // max 50 char
  formControlExternalReference!: FormControl<string | null>; // max 50
  assetSelections: VerimotoAssetSelection[] = [];
  stateSelections: VerimotoStateSelection[] = [];
  inspectionTypeSelections: VerimotoInspectionTypeSelection[] = [];
  allInspectionTypeSelections: VerimotoInspectionTypeSelection[] = [];

  application!: Application;
  asset!: PpsrAsset;
  applicationOwner!: ApplicationOwner;
  createAssetInspectionFn!: CreateAssetInspectionFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn;
  verimotoLender!: VerimotoLenderType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NewAssetInspectionDialogData,
    private dialogRef: MatDialogRef<NewAssetInspectionDialog, NewAssetInspectionDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.application = data.application;
    this.asset = data.asset;
    this.verimotoLender = data.verimotoLender;
    this.getApplicationOwnerFn = data.getApplicationOwnerFn;
    this.createAssetInspectionFn = data.createAssetInspectionFn;
    this.allInspectionTypeSelections = data.verimotoAssetInspectionTypes;
    this.formControlSellerFirstName = formBuilder.control(null, [Validators.required, Validators.maxLength(50)]);
    this.formControlSellerLastName = formBuilder.control(null, [Validators.required, Validators.maxLength(50)]);
    this.formControlSellerEmail = formBuilder.control(null, [Validators.required, Validators.maxLength(256)]);
    this.formControlSellerMobile = formBuilder.control(null, [Validators.required]);
    this.formControlBuyerFirstName = formBuilder.control(null, [Validators.maxLength(50)]);
    this.formControlBuyerLastName = formBuilder.control(null, [Validators.maxLength(50)]);
    this.formControlBuyerEmail = formBuilder.control(null, [Validators.maxLength(256)]);
    this.formControlBuyerMobile = formBuilder.control(null);
    this.formControlAsset = formBuilder.control(null, [Validators.required]);
    this.formControlState = formBuilder.control(null, [Validators.required]);
    this.formControlInspectionType  = formBuilder.control(null, [Validators.required]);
    this.formControlLenderReference = formBuilder.control(null, [Validators.required, Validators.maxLength(50)]);
    this.formControlExternalReference = formBuilder.control(null, [Validators.required, Validators.maxLength(50)]);
    
    this.formGroup = formBuilder.group({
      sellerFirstName: this.formControlSellerFirstName,
      sellerLastName: this.formControlSellerLastName,
      sellerEmail: this.formControlSellerEmail,
      sellerMobile: this.formControlSellerMobile,
      buyerFirstName: this.formControlBuyerFirstName,
      buyerLastName: this.formControlBuyerLastName,
      buyerEmail: this.formControlBuyerEmail,
      buyerMobile: this.formControlBuyerMobile,
      asset: this.formControlAsset,
      state: this.formControlState,
      inspectionType: this.formControlInspectionType,
      lenderReference: this.formControlLenderReference,
      externalReference: this.formControlExternalReference,
    })
  }

  ngOnInit() {
    this.assetSelections = constants.verimotoAssets;
    this.stateSelections = constants.verimotoStates;

    this.subscriptions.push(
      this.formControlAsset.valueChanges.subscribe((value : VerimotoAssetSelection | null) => {
        if (value) {
          this.inspectionTypeSelections = this.allInspectionTypeSelections.filter(obj => obj.assetName === value.name);
          this.formControlInspectionType.setValue(null);
        }
      })
    )

    this.subscriptions.push(
      this.getApplicationOwnerFn(this.application?.AppInfoSalesforceID ?? '')
        .pipe(this.toastService.spinnerObservable())
        .subscribe(
          (r: ApplicationOwner | null) => {
            if (r) {
              this.applicationOwner = r;
            }
          }
        )
    )

    // fill in all the fields
    const applicant = this.application.Contacts;
    if (applicant) {
      this.formControlBuyerFirstName.setValue(applicant.GivenName ?? null);
      this.formControlBuyerLastName.setValue(applicant.SurName ?? null);
      this.formControlBuyerEmail.setValue(applicant.Email ?? null);
      this.formControlBuyerMobile.setValue(applicant.Mobile ?? null);
    }

    if (this.asset.PrivateSellerDetails) {
      this.formControlSellerFirstName.setValue(this.asset.PrivateSellerDetails?.name ?? null);
      this.formControlSellerLastName.setValue(this.asset.PrivateSellerDetails?.lastName ?? null);
      this.formControlSellerMobile.setValue(this.asset.PrivateSellerDetails?.mobile ?? null);
      this.formControlSellerEmail.setValue(this.asset.PrivateSellerDetails?.email ?? null);
    }

    const assetGuid: string | null = getVerimotoAssetGuid(this.asset.SettlementAssetDetails?.assetCategory ?? '', this.asset.SettlementAssetDetails?.assetType ?? '');
    if (assetGuid) {
      this.formControlAsset.setValue(this.assetSelections.find(obj => obj.guid === assetGuid) ?? null)
    }

    const stateCode = this.asset.PrivateSellerDetails?.business ? this.asset.PrivateSellerDetails.state : this.asset.PrivateSellerDetails?.address?.State;
    if (stateCode) {
      this.formControlState.setValue(this.stateSelections.find(obj => obj.stateCode === stateCode) ?? null);
    }

    const inspectionTypeGuid: string | null = getInspectionTypeGuid(this.application, assetGuid ?? '', this.allInspectionTypeSelections);
    if (inspectionTypeGuid) {
      this.formControlInspectionType.setValue(this.allInspectionTypeSelections.find(obj => obj.guid === inspectionTypeGuid) ?? null);
    }

    this.formControlExternalReference.setValue(`${this.application.AppInfoSalesforceID ?? ''}-${this.asset.AssetId ?? ''}`);
    this.formControlLenderReference.setValue(`${this.application.AppInfoSalesforceID ?? ''}-${this.asset.AssetId ?? ''}`);
  }

  onSave() {
    if (this.formGroup.valid) {
      const brokersOrLenders = [
        {
        email: constants.verimotoLenderEmail,
        isOwner: true
      }
    ]

      let buyer: VerimotoAssetInspectionBuyer = {
        name: this.formControlBuyerFirstName.value,
        surName: this.formControlBuyerLastName.value,
        email: this.formControlBuyerEmail.value,
        phoneNumberWithCountryCode: this.formControlBuyerMobile.value ?? null,
      }
      if (!this.formControlBuyerFirstName.value && !this.formControlBuyerLastName.value && !this.formControlBuyerEmail.value && !this.formControlBuyerMobile.value) {
        buyer = null;
      }
      const data: VerimotoAssetInspectionData =  {
        seller: {
          name: this.formControlSellerFirstName.value ?? '',
          surName: this.formControlSellerLastName.value ?? '',
          email: this.formControlSellerEmail.value ?? '',
          phoneNumberWithCountryCode: this.formControlSellerMobile.value ?? '',
        },
        buyer: buyer,
        lenderGuid: this.verimotoLender.guid,
        lenderRef: this.formControlLenderReference.value ?? '',
        externalRef: this.formControlExternalReference.value ?? '',
        assetGuid: this.formControlAsset.value?.guid ?? '',
        planGuid: this.formControlInspectionType.value?.guid ?? null,
        stateCode: this.formControlState.value?.guid ?? '',
        brokersOrLenders: brokersOrLenders
      }

      console.log(data);

      this.subscriptions.push(
        this.createAssetInspectionFn(this.asset.AssetId ?? 0, this.application.ApplicationId, data).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((result: PayloadApiResponse<VerimotoCreateInspectionResponse>) => {
          console.log(result);
          if (result.status) {
            this.applicationDialogService.successDialog({
              message: "Success",
              subMessage: "New Verimoto inspection is created."
            }).afterClosed().subscribe(() => {
              this.dialogRef.close({
                newInspection: true
              });
            })
          } else {
            this.applicationDialogService.openAlertDialog({
              message: "Error",
              subMessage: `${result.message}`
            })
          }
        })
      )
    }
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }
}
