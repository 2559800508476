<div class="section">
  <div class="row">
    <div class="col">
      <div class="mat-h3">Services</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <accreditation-web-services
        [apiUrl]="apiUrl"
        [data]="webServices"
        [accreditation]="accreditation"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
      ></accreditation-web-services>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="mat-h3">Actions</div>
    </div>
  </div>
  <div class="row mb-4">
      <div class="col-12"
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="start start"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onApprove()">Approve</button>
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onDecline()">Decline</button>
      </div>
    </div>
</div>
