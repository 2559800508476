import {Injectable, OnDestroy} from '@angular/core';
import {
  PortalHotToastService
} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject} from 'rxjs';
import {OfflineDetectionServiceEvent} from '@portal-workspace/grow-shared-library';



@Injectable()
export class OfflineDetectionService implements OnDestroy {

  private _onlineStatus: 'online' | 'offline' = 'online';

  subject: BehaviorSubject<OfflineDetectionServiceEvent> =
    new BehaviorSubject<OfflineDetectionServiceEvent>({type: 'online'});


  get onlineStatus() {
    return this._onlineStatus;
  }

  onlineHandler = (evt: Event) => {
    this._onlineStatus = 'online';
    this.subject.next({type: 'online'});
  };

  offlineHandler = (evt: Event) => {
    this._onlineStatus = 'offline';
    this.subject.next({type: 'offline'});
  };

  constructor(private hotToastService: PortalHotToastService) {
    window.addEventListener('online', this.onlineHandler);
    window.addEventListener('offline', this.offlineHandler);
  }

  ngOnDestroy(): void {
    this.subject.complete();
    window.removeEventListener('online', this.onlineHandler);
    window.removeEventListener('offline', this.offlineHandler);
  }
}
