<div class="partner-component">

  @for (partnerFormGroup of partnerFormGroups(); track partnerFormGroup; let index = $index) {
    <ng-container class="separator">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Partner {{index + 1}}</div>
          <div class="mat-body separator-bottom">Minimum 2 partners required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [hideMultipleSelectionIndicator]="true"
            [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'type')">
            <mat-button-toggle value="Entity">Entity</mat-button-toggle>
            <mat-button-toggle value="Individual">Individual</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <!-- INDIVIDUAL -->
      @if (partnerFormGroupFormControl(partnerFormGroup, 'type').value === 'Individual' &&
        partnerFormGroupFormControl(partnerFormGroup, 'title')) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <title-selection
              [mark]="markObservable"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'title')">
            </title-selection>
          </div>
          <div class="col-lg-3 col-md-12">
            <name title="First Name" [mark]="markObservable" [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'firstName')"></name>
          </div>
          <div class="col-lg-3 col-md-12">
            <name title="Last Name" [mark]="markObservable" [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'lastName')"></name>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <datepicker
              [mark]="markObservable"
              title="Date of birth"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'dob')"
              [allowOnly18YrsPlus]="true">
            </datepicker>
          </div>
          <div class="col-lg-3 col-md-12">
            <gender
              [mark]="markObservable"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'gender')">
            </gender>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <custom-address title="Residential address"
              [mark]="markObservable"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'residentialAddress')">
            </custom-address>
          </div>
          <div class="col-lg-3 col-md-12">
            <yes-no title="Privacy consent obtained"
              [mark]="markObservable"
              [notselected]="true"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'privacyConsentObtained')">
            </yes-no>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no title="Guarantor"
              [mark]="markObservable"
              [notselected]="true"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'guarantor')">
            </yes-no>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <property-owner-with-address
              [mark]="markObservable"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'propertyOwner')"
              [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(partnerFormGroupFormControl(partnerFormGroup, 'residentialAddress'))"
              [showAddressForm]="showAddressForm">
            </property-owner-with-address>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <email [mark]="markObservable" [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'email')"></email>
          </div>
          <div class="col-lg-3 col-md-12">
            <mobile
              [mark]="markObservable" [mandatory]="true" [onlyMobile]="true"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'mobileNumber')">
            </mobile>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-12">
            <button mat-stroked-button color="warn" [disabled]="formArray.length <= 2" (click)="removePartner(partnerFormGroup)">Remove Partner</button>
          </div>
        </div>
        @if (showAssetsAndLiabilities) {
          <div>
            <personal-assets
              [mark]="markObservable"
              (events)="onPersonalAssetEvent($event, partnerFormGroup)"
            [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'personalAssets')"></personal-assets>
          </div>
        }
        @if (showAssetsAndLiabilities) {
          <div>
            <personal-liabilities
              [mark]="markObservable"
              (events)="onPersonalLiabilitiesEvent($event, partnerFormGroup)"
            [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'personalLiabilities')"></personal-liabilities>
          </div>
        }
      }
      <!-- ENTITY -->
      @if (partnerFormGroupFormControl(partnerFormGroup, 'type').value === 'Entity' &&
        partnerFormGroupFormControl(partnerFormGroup, 'organisation')) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <business-number-search
              [mark]="markObservable"
              [searchFn]="businessNumberSearchFn"
            [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'organisation')"></business-number-search>
          </div>
          <div class="col-lg-3 col-md-12">
            <entity-type
              [readonly]="entityTypeReadonly"
              [mark]="markObservable"
              [formControl]="partnerFormGroupFormControl(partnerFormGroup, 'organisationType')">
            </entity-type>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-12">
            <button mat-stroked-button color="warn" [disabled]="formArray.length <= 2" (click)="removePartner(partnerFormGroup)">Remove Partner</button>
          </div>
        </div>
      }
    </ng-container>
  }

  <div class="row separator-bottom">
    <div class="col-12">
      <button mat-stroked-button (click)="addPartner()">
        <span class="mdi mdi-plus" matPrefix></span>
        Add Partner
      </button>
    </div>
  </div>

</div>
