import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FlexModule} from '@angular/flex-layout/flex';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {
    AbnComponentValue,
    AcnComponentValue,
    ManualBusinessSearchDialogData,
    ManualBusinessSearchDialogResult
} from '@portal-workspace/grow-shared-library';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {AbnComponent} from '../abn-component/abn.component';
import {AcnComponent} from '../acn-component/acn.component';
import { formControlErrorKeys, formControlErrorMessage } from '../component-utils';
import {nameIncludeDigitsWithoutBlankSpaceValidator} from "@portal-workspace/grow-ui-library";


@Component({
   templateUrl: './manual-business-search.dialog.html',
   styleUrls: ['./manual-business-search.dialog.scss'],
   standalone: true,
    imports: [
    MatDialogModule,
    FlexModule,
    MatButtonModule,
    AsyncPipe,
    JsonPipe,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    AbnComponent,
    AcnComponent
],
})
export class ManualBusinessSearchDialog {

    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;

    formControlOrganisationName: FormControl<string | null>;
    formControlAcn: FormControl<AcnComponentValue>;
    formControlAbn: FormControl<AbnComponentValue>;

    acnMandatory: boolean = false;

    formGroup: FormGroup<{
        organisationName: FormControl<string | null>,
        acn: FormControl<AcnComponentValue>,
        abn: FormControl<AbnComponentValue>,
    }>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ManualBusinessSearchDialogData,
        private matDialogRef: MatDialogRef<ManualBusinessSearchDialog, ManualBusinessSearchDialogResult>,
        private formBuilder: FormBuilder) {
        this.acnMandatory = this.data.acnMandatory;
        this.formControlOrganisationName = this.formBuilder.control(null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
        this.formControlAbn = this.formBuilder.control(null, [Validators.required]);
        this.formControlAcn = this.formBuilder.control(null, this.acnMandatory ? [Validators.required] : []);
        this.formGroup = this.formBuilder.group({
            organisationName: this.formControlOrganisationName,
            abn: this.formControlAbn,
            acn: this.formControlAcn,
        });
    }

    onCancel($event: MouseEvent) {
        this.matDialogRef.close(undefined);
    }

    onSave($event: MouseEvent) {
        // NOTE: these values should not be falsy as the validation would prevent saving of they are invalid
        this.matDialogRef.close({
            organisationName: this.formControlOrganisationName.value ?? '',
            abn: this.formControlAbn.value ?? '',
            acn: this.formControlAcn.value ?? '',
        });
    }

    protected readonly formControlErrorMessage = formControlErrorMessage;
    protected readonly formControlErrorKeys = formControlErrorKeys;
}
