import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  ApiResponse,
  ApproveOrRejectUserFn,
  ApproveRejectUserData,
  BrokerContactType,
  CreateBrokerUserData,
  CreateBrokerUserFn,
  CreateCustomerUserData,
  CreateCustomerUserFn,
  Customer,
  DisableUsersFn,
  EnableUsersFn,
  GetCustomerFn,
  GetUserByEmailFn,
  GetUserFn,
  GetUserSameCompanyFunc,
  LogoutFn,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PayloadApiResponse,
  PismoAccountMappingWithCustomer,
  PismoAccountMappingWithCustomerListFn,
  PortalLoginUser,
  RefreshTokenPayload,
  SortTypes,
  UpdateUserData,
  UpdateUserFn,
  UpdateUserPasswordFn,
  User,
  UserandCustomerPayway,
  UserandPriviledges,
  UserOmitPriviledges,
  UserPublicStatus,
  UserType,
  UserWithOriginatorBusinessAndCustomerEntityNames,
  ValidEmailCheckFn
} from '@portal-workspace/grow-shared-library';
import {getUser, clearStorage} from '@portal-workspace/grow-ui-library';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {
  hasAccessBrokerDashboard,
  hasAccessCardOperatorDashboard,
  hasAccessOverdraftDashboard,
  hasMultipleRole,
  httpOptions,
  paginationUrl,
  PortalHotToastService,
  storeTokens,
  toPaginationInfo,
  UserTableFn,
} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import { GetUsersFunc } from '@portal-workspace/grow-ui-library';
import {CustomerUser, GetUser, LoginPayload, LogoutPayload} from '@portal-workspace/grow-shared-library';
import {
  navigationUrlForApplications, navigationUrlForApplicationsWithQueryParams,
  navigationUrlForCardOperatorDashboard, navigationUrlForOverdraftCustomerDashboard
} from './navigation-urls';
import {Router} from '@angular/router';

const URL_LOGIN = () => `${environment.api2Host}/api2/login`;
// const URL_GET_LG_BROKERS = () => `${environment.api2Host}/api2/getLGBrokers`;
const URL_GET_USER = (userId: number,includesDisable:boolean) => `${environment.api2Host}/api2/user/${userId}/${includesDisable}`;
const URL_GET_CUSTOMER = (customerId: number) => `${environment.api2Host}/api2/customer/${customerId}`;
const URL_CHANGE_PASSWORD = () => `${environment.api2Host}/api2/users/changePassword`;  // put
const URL_CREATE_BROKER_USER = () => `${environment.api2Host}/api2/users`;//post
// const URL_CREATE_CUSTOMER_USER = () => `${environment.api2Host}/api2/customer-user`;//post
const URL_UPDATE_USER = (userId: number) => `${environment.api2Host}/api2/users/${userId}`; // put
const URL_APPROVE_USER = (userId: number) => `${environment.api2Host}/api2/users/approve-reject/${userId}`; // put
const URL_LOCK_UNLOCK_USER = (userId: number) => `${environment.api2Host}/api2/users/lock-unlock/${userId}` // put
const URL_GET_USERS = (paginationInfo: PaginationInfo, type: UserType = 'broker-user') => paginationUrl(`${environment.api2Host}/api2/all-users-list?type=${type}`, paginationInfo); // post
const URL_GET_UNAPPROVED_USERS = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/all-unapproved-list`, paginationInfo); // post
const URL_UPDATE_PROFILE = () => `${environment.api2Host}/api2/users/profile`; // post
const URL_GET_UNVERIFIED_USERS = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/getUnverifiedUsers`, paginationInfo);
// const URL_GET_USERS_IN_SAME_COMPANY_AGGREGATOR = (userId: number) => `${environment.api2Host}/api2/all-users-in-same-company/${userId}`;
const URL_GET_USERS_IN_SAME_COMPANY_AGGREGATOR_BY_SALESFORCE_ID = (salesforceId: string) => `${environment.api2Host}/api2/all-users-in-same-company/salesforce/${salesforceId}`;
const URL_GET_SALESFORCE_CONTACT_SAME_ACOUNT_URL = (salesforceAccountId: string) => `${environment.api2Host}/api2/salesforce-contact-same-account/salesforce/${salesforceAccountId}`;
const URL_GET_ORIGINATOR_SALESFORCE_IDS_FOR_USER = (userId: number) => `${environment.api2Host}/api2/get-originator-salesforceids-for-user/${userId}`;
const URL_EMAIL_EXISTS=()=>`${environment.api2Host}/api2/users/validateEmail`;
const URL_BROKER_EMAIL_EXISTS=()=>`${environment.api2Host}/api2/users/validateBrokerEmail`;
const URL_CUSTOMER_EMAIL_EXISTS=()=>`${environment.api2Host}/api2/users/validateCustomerEmail`;
// const URL_EMAIL_USER_EXISTS=() => `${environment.apiHost}/api/broker-signup/validateEmail`;
const URL_VERIFY_EMAIL = () => `${environment.api2Host}/api2/login/verify-email`;
const URL_VERIFY_PHONE = () => `${environment.api2Host}/api2/login/verify-mobile`;
const URL_UNVERIFY_EMAIL = () => `${environment.api2Host}/api2/login/unverify-email`;
const URL_UNVERIFY_PHONE = () => `${environment.api2Host}/api2/login/unverify-mobile`;
const URL_SEND_ALL = () => `${environment.api2Host}/api2/sendVerificationReminderAll`
// const URL_GET_ORIGINATOR_BUSINESS = (originatorBusinessId: number) => `${environment.apiHost}/api/get-originator-business/${originatorBusinessId}`

const URL_DISABLE_USERS = () => `${environment.api2Host}/api2/delete-users`;
const URL_ENABLE_USERS = () => `${environment.api2Host}/api2/enable-users`;
const URL_LOGOUT = () => `${environment.api2Host}/api2/logout`;
const URL_REFRESH_TOKEN  = () => `${environment.api2Host}/api2/refresh-token`; // post
const URL_GET_USER_BY_EMAIL = (email: string) => `${environment.api2Host}/api2/user-email/${email}`;
const URL_GET_USER_PUBLIC_STATUS = (email: string) => `${environment.api2Host}/api2/user/public/email/${email}`;
const URL_GET_ALL_PISMO_ACCOUNT_WITH_CUSTOMER = () => `${environment.api2Host}/api2/pismo/get-all-pismo-account-mappings`;

@Injectable()
export class AuthService {

  events = new BehaviorSubject<LoginPayload | LogoutPayload | undefined>(undefined);

  constructor(private httpClient: HttpClient, private hotToastService: PortalHotToastService) {}

  refreshToken(refreshToken: string): Observable<PayloadApiResponse<RefreshTokenPayload>> {
    return this.httpClient.post<PayloadApiResponse<RefreshTokenPayload>>(URL_REFRESH_TOKEN(), {
      refreshToken,
    }, httpOptions());
  }

  getUserByEmailFn: GetUserByEmailFn = (email: string) => {
    return this.getUserByEmail(email).pipe(map(r => r.payload));
  }

  getUserByEmail(email: string): Observable<PayloadApiResponse<UserandCustomerPayway>> {
    return this.httpClient.get<PayloadApiResponse<UserandCustomerPayway>>(URL_GET_USER_BY_EMAIL(email), httpOptions());
  }


  getAllPismoWithCustomerAccountFn: PismoAccountMappingWithCustomerListFn = () => {
    return this.getAllPismoWithCustomerAccount().pipe(map(r => r.payload));
  }

  getAllPismoWithCustomerAccount(): Observable<PayloadApiResponse<PismoAccountMappingWithCustomer[]>> {
    return this.httpClient.get<PayloadApiResponse<PismoAccountMappingWithCustomer[]>>(URL_GET_ALL_PISMO_ACCOUNT_WITH_CUSTOMER(), httpOptions());
  }

  validEmailCheckFn: ValidEmailCheckFn = ((email: string) => {
    let id = 0;
    return this.validateEmail(email, id)
      .pipe(
        map((res: any) => {
          if (res.payload.status == true) {
            return true;
          } else {
            return false;
          }
        }),
      );
  }).bind(this);

  getUsersInCompanyFn: GetUsersFunc = (companySalesforceId) => {
    return this.getUsersInSameCompanyAggregatorBySalesforceId(companySalesforceId).pipe(
      map(r => {
        return r.payload.map(u => ({
          UserId: u.UserId,
          Name: u.Name ?? '',
          Email: u.Email ?? '',
          sfContactId: u.sfContactId ?? '',
        }))
      })
    );
  }

  // NOTE: not used anymore
  // gets all the users in the same company except the current user
  // getOtherUsersInCompanyFn: GetUsersFunc = (userId) => {
  //   return this.getUsersInSameCompanyAggregator(userId).pipe(
  //     map(r => {
  //       return r.payload.filter(ou => ou.UserId !==userId).map(u => ({
  //         UserId: u.UserId,
  //         Name: u.Name ?? '',
  //         Email: u.Email ?? '',
  //       }))
  //     })
  //   );
  // }

  // get all users in the same company (by company salesforce id)
  getOtherUsersInCompanyBySalesforceIdFn: GetUserSameCompanyFunc = (companySalesforceId: string) => {
    return this.getUsersInSameCompanyAggregatorBySalesforceId(companySalesforceId).pipe(
      map(r => {
        return r.payload.map(u => ({
          UserId: u.UserId,
          Name: u.Name ?? '',
          Email: u.Email ?? '',
          sfContactId: u.sfContactId ?? '',
        }))
      })
    );
  }

  // NOTE: not used anymore
  // getUsersInSameCompanyAggregator(userId: number): Observable<PayloadApiResponse<UserOmitPriviledges[]>> {
  //   return this.httpClient.get<PayloadApiResponse<UserOmitPriviledges[]>>(URL_GET_USERS_IN_SAME_COMPANY_AGGREGATOR(userId), httpOptions()).pipe();
  // }

  getSalesforceContactSameAccountFn = (salesforceAccountId: string) => {
    return this.getSalesforceContactSameAccount(salesforceAccountId).pipe(map(p => p.payload));
  }

  getSalesforceContactSameAccount(salesforceAccountId: string) {
    return this.httpClient.get<PayloadApiResponse<BrokerContactType[]>>(URL_GET_SALESFORCE_CONTACT_SAME_ACOUNT_URL(salesforceAccountId), httpOptions());
  }

  getUsersInSameCompanyAggregatorBySalesforceId(companySalesforceId: string): Observable<PayloadApiResponse<UserOmitPriviledges[]>> {
    return this.httpClient.get<PayloadApiResponse<UserOmitPriviledges[]>>(URL_GET_USERS_IN_SAME_COMPANY_AGGREGATOR_BY_SALESFORCE_ID(companySalesforceId), httpOptions()).pipe();
  }

  getOriginatorSalesforceIdsForUser(userId: number): Observable<PayloadApiResponse<string[]>> {
    return this.httpClient.get<PayloadApiResponse<string[]>>(URL_GET_ORIGINATOR_SALESFORCE_IDS_FOR_USER(userId), httpOptions()).pipe();
  }



  getUnverifiedUsers(opt: {
    page: {
      limit: number, offset: number,
    },
    filter: string,
    sorts?: { prop: string, dir: 'asc' | 'desc' }[],
  }): Observable<PaginablePayloadApiResponse<User>> {

    return this.httpClient.post<PaginablePayloadApiResponse<User>>(
      URL_GET_UNVERIFIED_USERS(toPaginationInfo(opt)), {}, httpOptions());


    // const accessToken = getAccessToken();
    // return this.getLgBrokers(accessToken!).pipe(
    //   switchMap((lgUsers: User[]) => {
    //     return this.httpClient.post<{ totalRows: number, records: User[] }>(URL_GET_UNVERIFIED_USERS(), opt, httpOptions()).pipe(
    //       map(r => {
    //         this.addInPriviledgets(r, lgUsers);
    //         return {
    //           status: true, message: '',
    //           payload: r.records,
    //           limit: opt.page.limit,
    //           offset: opt.page.offset,
    //           total: r.totalRows,
    //         }
    //       }))
    //   })
    // );
  }

  verifyEmail(userId: number): Observable<ApiResponse> {
    return this.httpClient.post(URL_VERIFY_EMAIL(), {user_id:userId}, httpOptions()).pipe(
      map(r => {
        return { status: true, message: '' }
      })
    );
  }

  verifyPhone(userId: number): Observable<ApiResponse> {
    return this.httpClient.post(URL_VERIFY_PHONE(), {user_id:userId}, httpOptions()).pipe(
      map(r => {
        return { status: true, message: '' }
      })
    );
  }

  unverifyEmail(userId: number): Observable<ApiResponse> {
    return this.httpClient.post(URL_UNVERIFY_EMAIL(), {user_id:userId}, httpOptions()).pipe(
      map(r => {
        return { status: true, message: '' }
      })
    );
  }

  unverifyPhone(userId: number): Observable<ApiResponse> {
    return this.httpClient.post(URL_UNVERIFY_PHONE(), {user_id:userId}, httpOptions()).pipe(
      map(r => {
        return { status: true, message: '' }
      })
    );
  }

  sendAll(users: User[]): Observable<ApiResponse> {
    return this.httpClient.post(URL_SEND_ALL(), {users:users}, httpOptions()).pipe(
      map(r => {
        return { status: true, message: '' }
      })
    );
  }

  updateProfilePassword(data: {
    password: string,
  }): Observable<PayloadApiResponse<User | null>> {

    return this.httpClient.post<PayloadApiResponse<User | null>>(
      URL_UPDATE_PROFILE(), {
      Password: data.password,
    }, httpOptions());

    // return this.httpClient.post<User>(URL_UPDATE_PROFILE(), {
    //   userId: data.userId,
    //   Password: data.password,
    // }, httpOptions()).pipe(
    //   map(r => {
    //     return {
    //       status: true, message: '',
    //     }
    //   }));
  }

  updateProfileData(data: {
    FirstName: string,
    LastName: string,
    MobileNumber: string,
    Email: string,
    MiddleName: string
  }): Observable<ApiResponse> {
    const name = `${data.FirstName} ${data.LastName}`.trim();
    return this.httpClient.post<User>(URL_UPDATE_PROFILE(), {
      FamilyName: data.LastName,
      GivenName: data.FirstName,
      MiddleName: data.MiddleName,
      // Name: name,
      Email: data.Email,
      MobileNumber: data.MobileNumber,
    }, httpOptions()).pipe(
      map(r => {
        const user = getUser();
        if (user) {
          user.GivenName = data.FirstName;
          user.FamilyName = data.LastName;
          user.MiddleName = data.MiddleName;
          user.Email = data.Email;
          user.MobileNumber = data.MobileNumber;
          storeTokens({ user });
        }
        return {
          status: true, message: '',
        }
      }));
  }

  // NOTE: not being used
  // private addInPriviledgets(r: { totalRows: number, records: User[] }, lgUsers: User[]) {
  //   const lgUserIds = lgUsers.map(lgu => lgu.UserId);
  //   for (const u of r.records) {
  //     if (!u.priviledges) {
  //       u.priviledges = [];
  //     }
  //     if (u.AccessLevel) {
  //       u.priviledges.push(u.AccessLevel as any);
  //     }
  //     if (lgUserIds.includes(u.UserId)) {
  //       u.priviledges.push('lg' as any);
  //     }
  //   }
  // }


  getUnapprovedUsers(opt: {
    onlyRejected: boolean,
    page: {
      limit: number,
      offset: number,
    },
    filter: string,
    sorts?: SortTypes,
  }): Observable<PaginablePayloadApiResponse<UserWithOriginatorBusinessAndCustomerEntityNames>> {
    const {onlyRejected, ...paginationOptions} = opt;
    return this.httpClient.post<PaginablePayloadApiResponse<UserWithOriginatorBusinessAndCustomerEntityNames>>(
      URL_GET_UNAPPROVED_USERS(toPaginationInfo(paginationOptions)), {onlyRejected}, httpOptions());


    // const accessToken = getAccessToken();
    // return this.getLgBrokers(accessToken!).pipe(
    //   switchMap((lgUsers: User[]) => {
    //     return this.httpClient.post<{ totalRows: number, records: User[] }>(URL_GET_UNAPPROVED_USERS(), opt, httpOptions()).pipe(
    //       map(r => {
    //         this.addInPriviledgets(r, lgUsers);
    //         return {
    //           status: true, message: '',
    //           payload: r.records,
    //           limit: opt.page.limit,
    //           offset: opt.page.offset,
    //           total: r.totalRows,
    //         }
    //       })
    //     );
    //   })
    // );
  }

  getUsersFn: UserTableFn = (opt, type) => {
    return this.getUsers(opt, type);
  }

  getUsers(opt: {
    page: {
      limit: number, offset: number
    },
    filter: string,
    sorts?: SortTypes,
  }, type: UserType = 'broker-user'): Observable<PaginablePayloadApiResponse<GetUser>> {
    return this.httpClient.post<PaginablePayloadApiResponse<GetUser>>(URL_GET_USERS(toPaginationInfo(opt), type), {}, httpOptions());

    // const accessToken = getAccessToken();
    // return this.getLgBrokers(accessToken!).pipe(
    //   switchMap((lgUsers: User[]) => {
    //     return this.httpClient.post<{ totalRows: number, records: GetUser[] }>(URL_GET_USERS(), opt, httpOptions()).pipe(
    //       map(r => {
    //         this.addInPriviledgets(r, lgUsers);
    //         return {
    //           status: true,
    //           message: '',
    //           payload: r.records,
    //           limit: opt.page.limit,
    //           offset: opt.page.offset,
    //           total: r.totalRows
    //         }
    //       })
    //     );
    //   }),
    // );
  }

  disableUsersFn: DisableUsersFn = (userIds: number[]) => {
    return this.disableUsers(userIds).pipe(map(r=>{}));
  }

  /* Disable users */
  disableUsers(userIds: number[]): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_DISABLE_USERS(), {
      userIds: userIds,
    }, httpOptions());

    // let url = `${environment.apiHost}/api/delete-users`;
    // url += '?userIds=' + JSON.stringify(userId);
    // return this.httpClient.delete<void>(url, httpOptions()).pipe(
    //   map(r => {
    //     return { status: true, message: '' }
    //   })
    // );
  }

  enableUsersFn: EnableUsersFn = (userIds: number[]) => {
    return this.enableUser(userIds).pipe(map(r=>{}));
  }

  enableUser(userIds: number[]): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_ENABLE_USERS(), {
      userIds: userIds,
    }, httpOptions());
  }

  lockOrUnlockUser(userId: number, isLock: boolean): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_LOCK_UNLOCK_USER(userId), { isLock }, httpOptions());
  }


  approveOrRejectUserFn: ApproveOrRejectUserFn = (userId: number, approve: boolean) => {
    return this.approveOrRejectUser(userId, approve).pipe(map(r=>{}));
  }
  approveOrRejectUser(userId: number, approve: boolean, d?: Omit<ApproveRejectUserData, 'approval' | 'rejected'>): Observable<ApiResponse> {
    const data = {
      approval: approve ? true : false,
      rejected: approve ? false : true,
    };
    if (d) {
      if (d.email) {
        (data as any).Email = d.email;
      }
      // if (d.aggregatorId) {
      //   (data as any).AggregatorId = d.aggregatorId;
      //   (data as any).OriginatorBusinessId = null;
      // }
      if (d.originatorBusinessId) {
        (data as any).OriginatorBusinessId = d.originatorBusinessId;
        // (data as any).AggregatorId = null;
      }
      if (d.givenName) {
        (data as any).GivenName = d.givenName;
      }
      if (d.familyName) {
        (data as any).FamilyName = d.familyName;
      }
      // if (d.givenName || d.familyName) {
      //   (data as any).Name = `${d.givenName} ${d.familyName}`;
      // }
      if (d.email) {
        (data as any).Email = d.email;
      }
      if (d.mobileNumber) {
        (data as any).MobileNumber = d.mobileNumber;
      }
      if (d.accessLevel) {
        (data as any).AccessLevel = d.accessLevel;
      }
    }
    return this.httpClient.put<ApiResponse>(URL_APPROVE_USER(userId), data, httpOptions());
  }

  // NOTE: not used anymore
  // createCustomerUserFn: CreateCustomerUserFn = (data: CreateCustomerUserData) => {
  //   return this.createCustomerUser(data).pipe(map(r => r.payload));
  // }
  // createCustomerUser(data: CreateCustomerUserData) {
  //   return this.httpClient.post<User>(URL_CREATE_CUSTOMER_USER(), {
  //     ...data
  //   }, httpOptions()).pipe(
  //     map((r) => {
  //       return {
  //         status: true, message: '',
  //         payload: r
  //       }
  //     })
  //   );
  // }

  createBrokerUserFn: CreateBrokerUserFn = (data: CreateBrokerUserData) => {
    return this.createBrokerUser(data).pipe(map(r => r.payload));
  }
  createBrokerUser(data: CreateBrokerUserData) {
    return this.httpClient.post<User>(URL_CREATE_BROKER_USER(), {
      ...data
    }, httpOptions()).pipe(
      map((r) => {
        return {
          status: true, message: '',
          payload: r
        }
      })
    );
  }

  // validateUserEmail(email:String){
  //   const user = {
  //         'Email': email
  //       };
  //       return this.httpClient.post(URL_EMAIL_USER_EXISTS(),{
  //         Email:user.Email
  //       },httpOptions()).pipe(
  //         map((r) => {
  //           return {
  //             payload: r
  //           }
  //         })
  //       );
  // }

  validateEmail(email: string, id: number): Observable<PayloadApiResponse<{ status: boolean }>> {

    return this.httpClient.post<PayloadApiResponse<{ status: boolean }>>(URL_EMAIL_EXISTS(), {
      Email: email,
      ExceptUserId: id,
    }, httpOptions());


    // const user:any = {
    //       'Email': email
    //     };
    //     if (id != 0) {
    //       user['ExceptUserId'] = id;
    //     } else {
    //       user['ExceptUserId'] = 0;
    //     }
    //     return this.httpClient.post(URL_EMAIL_EXISTS(), {
    //      Email:user.Email,
    //      ExceptUserId:user.ExceptUserId
    //     }, httpOptions()).pipe(
    //       map((r) => {
    //         return {
    //           payload: r
    //         }
    //       })
    //     );
  }
  validCustomerEmail(email: string, id: number): Observable<PayloadApiResponse<{ status: boolean }>> {
    return this.httpClient.post<PayloadApiResponse<{ status: boolean }>>(URL_CUSTOMER_EMAIL_EXISTS(), {
      Email: email,
      ExceptUserId: id,
    }, httpOptions());
  }
  validBrokerEmail(email: string, id: number): Observable<PayloadApiResponse<{ status: boolean }>> {
    return this.httpClient.post<PayloadApiResponse<{ status: boolean }>>(URL_BROKER_EMAIL_EXISTS(), {
      Email: email,
      ExceptUserId: id,
    }, httpOptions());
  }

  validBrokerEmailCheckFn: ValidEmailCheckFn = ((email: string) => {
    let id = 0;
    return this.validBrokerEmail(email, id)
      .pipe(
        map((res: any) => {
          if (res.payload.status == true) {
            return true;
          } else {
            return false;
          }
        }),
      );
  }).bind(this);
  validCustomerEmailCheckFn: ValidEmailCheckFn = ((email: string) => {
    let id = 0;
    return this.validCustomerEmail(email, id)
      .pipe(
        map((res: any) => {
          if (res.payload.status == true) {
            return true;
          } else {
            return false;
          }
        }),
      );
  }).bind(this);

  updateUserPasswordFn: UpdateUserPasswordFn = (userId: number, data: {password: string}): Observable<UserandPriviledges | null> => {
    return this.updateUser(userId, {
      Password: data.password
    }).pipe(map(r => r.payload))
  }
  updateUserFn: UpdateUserFn = (userId: number, data: UpdateUserData) => {
    return this.updateUser(userId, data).pipe(map(r => r.payload));
  }
  updateUser(userId: number, data: UpdateUserData): Observable<PayloadApiResponse<UserandPriviledges | null>> {
    return this.httpClient.put<PayloadApiResponse<UserandPriviledges | null>>(URL_UPDATE_USER(userId), {
      ...data
    }, httpOptions());

    // return this.httpClient.put<User>(URL_UPDATE_USER(userId), {
    //   ...data,
    //   Name: `${data.GivenName} ${data.FamilyName}`.trim(),
    // }, httpOptions()).pipe(
    //   map((r: User) => {
    //     return {
    //       status: true, message: '',
    //       payload: r
    //     }
    //   })
    // );
  }

  changePassword(email: string, password: string): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_CHANGE_PASSWORD(), {
      Email: email,
      Password: password,
    }, httpOptions());

    // return this.httpClient.put<any>(URL_CHANGE_PASSWORD(), {
    //   Email: email,
    //   Password: password,
    // }, httpOptions()).pipe(map(r => {
    //   console.log('**** change passsword response', r);
    //   return {
    //     status: true,
    //     message: '',
    //   }
    // }));
  }

  getCustomerFn: GetCustomerFn = (customerId: number) => {
    return this.getCustomer(customerId).pipe(map(r => r.payload))
  }

  getCustomer(customerId: number): Observable<PayloadApiResponse<CustomerUser | null>> {
    return this.httpClient.get<PayloadApiResponse<CustomerUser | null>>(URL_GET_CUSTOMER(customerId), httpOptions());
  }

  getUserFn: GetUserFn = (userId: number, includesDisable:boolean = false) => {
    return this.getUser(userId,includesDisable).pipe(map(r=>r.payload));
  }

  getUser(userId: number, includesDisable: boolean = false): Observable<PayloadApiResponse<UserandPriviledges | null>> {
    return this.httpClient.get<PayloadApiResponse<UserandPriviledges| null>>(URL_GET_USER(userId,includesDisable), httpOptions());
  }


  login(email: string, password: string,ipAddress?: string): Observable<PayloadApiResponse<LoginPayload>> {
    return this.httpClient.post<PayloadApiResponse<LoginPayload>>(URL_LOGIN(), {
      Email: email, Password: password, ipAddress:ipAddress
    }).pipe(
      tap(r => {
        if (r.payload) {
          const payload = {
            type: 'login' as const,
            user: r.payload.user,
            salesforceid: r.payload.salesforceid,
            accessToken: r.payload.accessToken,
            refreshToken: r.payload.refreshToken,
            status: r.payload.status,
          }
          this.events.next(payload);
        }
      })
    );
  }


  logoutFn: LogoutFn = () => {
    return this.logout().pipe(map(r => r.payload));
  }
  logout(): Observable<PayloadApiResponse<LogoutPayload>> {
    const user = getUser() ?? undefined;
    // const accessToken = util.getAccessToken() ?? undefined;
    // const refreshToken = util.getRefreshToken() ?? undefined;
    const subject = new Subject<PayloadApiResponse<LogoutPayload>>();
    if(user){
      this.httpClient.get<PayloadApiResponse<LogoutPayload>>(URL_LOGOUT(), httpOptions()).pipe(
        this.hotToastService.loadingWithMessage('Logging off'),
        tap(r => {
          subject.next(r);
          subject.complete();
        }),
        finalize(() => {
          clearStorage();
        }),
      ).subscribe();
    }
    return subject.asObservable();
  }


  // getPriviledges(user: User, accessToken: string): Observable<User | null> {
  //   console.log('*** auth login user', user);
  //   if (user) {
  //     user.priviledges = [];
  //     if (user.AccessLevel) {
  //       user.priviledges.push(user.AccessLevel as any);
  //     }
  //     return this.getLgBrokers(accessToken).pipe(
  //       switchMap((lgBrokers: User[]) => {
  //         const isLgBroker = lgBrokers.map(b => b.UserId).includes(user.UserId);
  //         if (isLgBroker) {
  //           user.priviledges.push('lg' as any);
  //         }
  //         // localStorage.setItem('PriviledgeUser',JSON.stringify(user));
  //         return this.getOriginatorBusiness(user.OriginatorBusinessId).pipe(map(p => p.payload));
  //       }),
  //       map((originatorBusiness: OriginatorBusiness | null) => {
  //         const type = originatorBusiness?.Type;
  //         if (type) {
  //           user.priviledges.push(type);
  //         }
  //         return user;
  //       })
  //     );
  //   } else {
  //     return of(null);
  //   }
  // }

  // getLgBrokers(accessToken: string): Observable<User[]> {
  //   return this.httpClient.get<User[]>(URL_GET_LG_BROKERS(), ({
  //     headers: {
  //       'Authorization': `Bearer ${accessToken}`
  //     }
  //   }));
  // }

  // getOriginatorBusiness(id: number | undefined): Observable<PayloadApiResponse<OriginatorBusiness | null>> {
  //   if (!id) {
  //     const apiResponse: PayloadApiResponse<null> =  {
  //       status: true,
  //       message: `Originator business retrieved`,
  //       payload: null,
  //     };
  //     return of(apiResponse);
  //   } else {
  //     return this.httpClient.get<PayloadApiResponse<OriginatorBusiness>>(URL_GET_ORIGINATOR_BUSINESS(id), httpOptions());
  //   }

  //   // if (id === undefined) {
  //   //   return of(null)
  //   // } else {
  //   //   return this.httpClient.get<OriginatorBusiness>(URL_GET_ORIGINATOR_BUSINESS(id), httpOptions());
  //   // }
  // }


  async redirectUser(router: Router, user: PortalLoginUser | null) {
    if (user) {
        if (hasAccessCardOperatorDashboard(user)) {
          await router.navigate(navigationUrlForCardOperatorDashboard());
        } else if (hasAccessBrokerDashboard(user)) {
          await router.navigate(navigationUrlForApplications());
        }
        else if(hasAccessOverdraftDashboard(user)) {
          await router.navigate(navigationUrlForOverdraftCustomerDashboard());
        }
        else if(hasAccessCardOperatorDashboard(user)) {
          await router.navigate(navigationUrlForCardOperatorDashboard());
        }
        else {
          await router.navigate(navigationUrlForApplications());
        }
    }
  }

  getUserPublicStatus(email: string): Observable<PayloadApiResponse<UserPublicStatus>> {
    return this.httpClient.get<PayloadApiResponse<UserPublicStatus>>(URL_GET_USER_PUBLIC_STATUS(email), httpOptions());
  }
}
