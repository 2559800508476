import {Component, Input, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule, 
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApplicationDialogService, getUser,
  PortalHotToastService,
  pxToRem,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, of, tap} from 'rxjs';
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  GetPendingApprovalTransactionsFn, 
  PendingApprovalTransaction, 
  PendingTransactionSortType, 
  PismoTransactionProcessingCode, 
  UpdateAllPendingTransactionsFn, 
  UpdatePendingTransactionsFn, 
  User, 
  getProcessingType,
  isAdminOrOperationsOrAnalyst,
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource, SelectionModel} from '@angular/cdk/collections';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import {Sort, MatSortModule} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import moment from 'moment';
import { PageEvent } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { CustomContentLoaderComponent } from '../custom-content-loader-component/custom-content-loader.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
export class PendingApprovalTransactionDataSource extends DataSource<PendingApprovalTransaction> {

  subject = new BehaviorSubject<PendingApprovalTransaction[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PendingApprovalTransaction[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: PendingApprovalTransaction[]) {
    this.subject.next(data);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'pending-approval-transactions-table',
  templateUrl: './pending-approval-transactions-table.component.html',
  styleUrls: ['./pending-approval-transactions-table.component.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
],
  imports: [FlexModule, FormsModule, FlexLayoutModule, MatCheckboxModule, TagBoxComponent, MatInputModule, CustomContentLoaderComponent, CustomPaginatorComponent, ReactiveFormsModule, DatePipe, MatSortModule, LooseCurrencyPipe, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatMenuModule, MatDividerModule, NgClass, ExtendedModule, MatTableModule, AsyncPipe]
})

export class PendingApprovalTransactionsTableComponent implements OnInit {
  @Input({required: true}) getPendingApprovalTransactionsFn!: GetPendingApprovalTransactionsFn;
  @Input({required: true}) updatePendingTransactionsFn!: UpdatePendingTransactionsFn;
  @Input({required: true}) updateAllPendingTransactionsFn!: UpdateAllPendingTransactionsFn;
  
  loader = loadingFor('tableLoading');
  store = createAsyncStore();
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  moment = moment;
  PismoTransactionProcessingCode = PismoTransactionProcessingCode;
  getProcessingType = getProcessingType;
  loggedInUser: User | null = getUser()
  expandedElement!: PendingApprovalTransaction | null;
  pxToRem = pxToRem;
  isAdminOrOperationsOrAnalyst = isAdminOrOperationsOrAnalyst(this.loggedInUser);

  retry(){
    this.reload();
  }

  subscriptions: Subscription[] = [];

  dataSource = new PendingApprovalTransactionDataSource();

  displayColumns = ['select', 'processingCode', 'amount', 'date', 'PismoAccountId', 'PismoCustomerId', 'status', 'createdTime', 'actions'];
    //['processingCode', 'amount', 'date', 'PismoAccountId', 'PismoCustomerId', 'status', 'createdTime'];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = 0;
  filter = '';
  sorts: PendingTransactionSortType = null;

  formControlSearch: FormControl<string | null>;
  selection = new SelectionModel<PendingApprovalTransaction>(true, []);

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.formControlSearch = this.formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = [{prop: $event.active, dir: $event.direction}] as PendingTransactionSortType;
    } else {
      this.sorts = null;
    }
    this.reset();
    this.reload();
  }

  private reload() {
    this.store = createAsyncStore();
    const sub = this.getPendingApprovalTransactionsFn({
      page: {
        limit: this.limit, offset: this.offset,
      },
      filter: this.filter,
      sorts: this.sorts,
    }).pipe(
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        console.log('==============r: ', r);
        this.total = r.total;
        this.selection.clear();
        this.dataSource.update(r.payload);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  getBankingType(transaction: PendingApprovalTransaction) {
    const type = transaction.type;
    switch (type) {
      case 'bpay':
        return 'BPAY';
      case 'direct-credit':
        return 'Direct Credit';
      case 'direct-debit':
        return 'Direct Debit';
      default:
        return type;
    }
  }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onApprove(transaction: PendingApprovalTransaction) {
    this.subscriptions.push(
      this.updatePendingTransactionsFn({
        transactions: [transaction.id],
        status: 'Approved'
      }).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Transaction Approved'),
      ).subscribe(() => {
        this.reload()
      })
    )
  }

  onReject(transaction: PendingApprovalTransaction) {
    this.subscriptions.push(
      this.updatePendingTransactionsFn({
        transactions: [transaction.id],
        status: 'Rejected'
      }).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Transaction Rejected'),
      ).subscribe(() => {
        this.reload()
      })
    )
  }

  approveAll() {
    this.subscriptions.push(
      this.updateAllPendingTransactionsFn({
        status: 'Approved'
      }).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('All Transactions Approved'),
      ).subscribe(() => {
        this.reload()
      })
    )
  }

  approveSelected() {
    this.subscriptions.push(
      this.updatePendingTransactionsFn({
        transactions: this.selection.selected.map(transition => transition.id),
        status: 'Approved'
      }).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Transaction Approved'),
      ).subscribe(() => {
        this.reload()
      })
    )
  }

  toggleAllSelection() {
    const transactions = this.dataSource.subject.getValue();
    for (const transaction of transactions) {
      this.selection.toggle(transaction);
    }
  }
}
