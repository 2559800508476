import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  TransactionTag, DEFAULT_LIMIT, DEFAULT_OFFSET, AccountSummary, bankTransactionCatogory, AccountFilter, GetBsaLenderListFn, GetBsaExcludedLenderListFn, SaveBsaCalculatorFn, GetBsaCalculatorFn, BankTransactionsByCategory, BankStatementTransaction, BankStatementAccount, BankStatementTransactionRow,
} from '@portal-workspace/grow-shared-library';
import {PageEvent} from '@angular/material/paginator';
import { FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApplicationDialogService, PortalHotToastService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Sort, MatSortModule } from '@angular/material/sort';
import {compare} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import _ from 'lodash';
import moment from 'moment';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'bank-statement-loan-payment',
    templateUrl: './bank-statement-loan-payment.component.html',
    styleUrls: ['./bank-statement-loan-payment.component.scss'],
    standalone: true,
    imports: [FlexModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatTableModule, MatSortModule, NgClass, ExtendedModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class BankStatementLoanPaymentComponent implements OnInit {
  @Input({ required: true }) data: BankTransactionsByCategory[] = [];
  @Input({ required: true }) accounts: BankStatementAccount[] = [];
  @Input({ required: true }) selectedTransactions: BankStatementTransactionRow[] = [];
  @Input({ required: true }) selectedAccounts: string[] = [];
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) confirmation: boolean = false;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Output() loanPaymentTransactions = new EventEmitter<BankStatementTransactionRow[]>();

  columnsToDisplay: string[] = ['date', 'category', 'party', 'transaction', 'credit', 'debit', 'actions'];
  dataSource: BankStatementTransactionRow[] = [];
  filteredDataSource: BankStatementTransactionRow[] = [];
  displayedData: BankStatementTransactionRow[] = [];
  bankTransactionCatogory = bankTransactionCatogory;
  allTransactionsDataSource: BankStatementTransactionRow[] = [];

  subscriptions: Subscription[] = [];
  formControlSearch!: FormControl<string | null>;
  last30Days = new Date();
  currentSort: Sort = { active: '', direction: '' };
  
  limit!: number;
  offset!: number;
  total!: number;
  filter: string[] = [];
  lenerList: string[] = [];
  excludedLenderList: string[] = [];
  showMessage!: string;

  constructor(
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private cdr: ChangeDetectorRef,
  ) {
    this.formControlSearch = this.formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.initAllTransactions();

    if (this.confirmation) {
      this.dataSource = this.selectedTransactions.sort((a, b) => a.date > b.date && -1 || 1);
      this.filteredDataSource = this.dataSource;
      this.initPagination();
    } else {
      this.subscriptions.push(
        combineLatest([
          this.getBsaLenderListFn(),
          this.getBsaExcludedLenderListFn()
        ]).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(([lenderList, excludedLenderList]) => {
          this.lenerList = lenderList.map(obj => obj.lender);
          this.excludedLenderList = excludedLenderList.map(obj => obj.lender);
          this.initPageData();
        })
      )
    }

    this.subscriptions.push(
      this.formControlSearch.valueChanges.subscribe(() => {
        this.onSearch();
        this.initPagination();
      })
    )

  }

  initAllTransactions() {
    let id = 0;
    const newAllTransactions: BankStatementTransactionRow[] = [];
    this.data.forEach((d: BankTransactionsByCategory) => {
      d.transactions.forEach((detail: BankStatementTransaction) => {
        let row: BankStatementTransactionRow = {
          date: detail.date,
          transaction: detail.description,
          debit: detail.type == 'debit' ? detail.amount : 0,
          credit: detail.type == 'credit' ? detail.amount : 0,
          party: '',
          category: detail.category ?? '',
          otherInfo: '',
          accountId: detail.accountId
        };
        detail?.tags?.forEach((obj: TransactionTag) => {
          const keyArray: string[] = Object.keys(obj);
          const key: string = keyArray.length ? keyArray[0] : '';
          switch (key) {
            case 'thirdParty':
              row.party = obj['thirdParty'];
              break;
            case 'category':
              if (row.category?.length) {
                row.category += ', ' + obj['category'];
              } else {
                row.category = obj['category'];
              }
              break;
            case 'creditDebit':
              obj['creditDebit'] === 'credit' ? row.credit = detail.amount : row.debit = detail.amount;
              break;
            default:
              if (row.otherInfo?.length) {
                row.otherInfo += ` | ${key}: ${obj[key]}`;
              } else {
                row.otherInfo = `${key}: ${obj[key]}`;
              }
          }
        })

        if (this.selectedAccounts.includes(row.accountId)) {
          const rowWithId = {
            ...row,
            id: id++,
          }
          newAllTransactions.push(rowWithId);
        }
      })
    })
    this.allTransactionsDataSource = newAllTransactions.filter(t => t.debit);

    const selectedAccountsLastDates = this.accounts.filter(a => this.selectedAccounts.includes(a.id)).map(a => new Date(`${a.lastDate}T00:00:00Z`)).sort();
    const latestDate = selectedAccountsLastDates.length ? selectedAccountsLastDates[selectedAccountsLastDates.length - 1] : new Date();
    this.last30Days = new Date(new Date(latestDate).setDate(new Date(latestDate).getDate() - 30));
  }

  initPageData(): void {
    this.dataSource = [];
    for (const record of this.allTransactionsDataSource) {
      if (this.validateTransaction(record)) {
        this.dataSource.push(record);
      }
    }

    console.log('loan payment transactions: ', this.dataSource);

    this.dataSource = this.dataSource.sort((a, b) => a.date > b.date && -1 || 1);
    this.filteredDataSource = this.dataSource;
    this.initPagination();
    this.cdr.detectChanges();
  }

  validateTransaction(transaction: BankStatementTransactionRow) {
    const text = `${transaction.category} ${transaction.party} ${transaction?.transaction}`.toLowerCase();
    let containsKeyword = false;
    for (const lender of this.lenerList) {
      if (text.includes(lender.toLowerCase())) {
        containsKeyword = true;
        break;
      }
    }

    if (containsKeyword) {
      for (const lender of this.excludedLenderList) {
        if (text.includes(lender.toLowerCase())) {
          return false;
        }
      }
      return new Date(transaction.date) >= this.last30Days;
    } else {
      return false;
    }
  }

  displayColumnValue(element: BankStatementTransactionRow, column: string): string {
    if (column == 'date') {
      return moment(element.date).format('DD/MM/YYYY')
    }
    else if (column == 'transaction') {
      return element.transaction
    }
    else if (column == 'credit') {
      return element.credit ? element.credit.toString() : ''
    }
    else if (column == 'debit') {
      return element.debit ? element.debit.toString() : ''
    }
    else if (column == 'category') {
      return element.category;
    }
    else if (column == 'party') {
      return element.party ?? '';
    }
    else {
      return 'N/A';
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    // this.limit = DEFAULT_LIMIT;
    this.limit = this.total;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  onSearch() {
    const value = this.formControlSearch.value ? this.formControlSearch.value.trim().toLowerCase() : '';
    if (value) {
      this.filteredDataSource = this.dataSource.filter(data => {
        const s = (data.date + data.transaction + data.credit.toString() +
          data.debit.toString() + data.party + data.category + data.otherInfo).toLowerCase();
        return s.includes(value);
      })
    } else {
      this.filteredDataSource = this.dataSource;
    }
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onSort(sort: Sort) {
    this.currentSort = sort;

    const data = this.filteredDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredDataSource = data;
      return;
    }

    this.filteredDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'category':
          return compare(a.category ?? '', b.category ?? '', isAsc);
        case 'party':
          return compare(a.party ?? '', b.party ?? '', isAsc);
        case 'transaction':
          return compare(a.transaction, b.transaction, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'transaction': return 'Transaction';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'party': return 'Party';
      case 'category': return 'Category';
      case 'otherInfo': return 'Other Info';
      case 'actions': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  exportCsv() {
    const rawCsvData = this.filteredDataSource.map((d: any) => {
      let row: any = {};
      [...this.columnsToDisplay, 'otherInfo', 'account'].forEach((col: string) => {
        if (col === 'account') {
          const account = this.accounts.find(a => a.id === d.accountId);
          row['Account'] = [account?.bankName, account?.bsbAccNumber, account?.accountName].join(' ');
        } else {
          row[this.getColumnTitles(col)] = d[col];
        }
      });
      return row;
    });
    const header = Object.keys(rawCsvData[0]);
    const csv = rawCsvData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, "income.csv");
  }


  delete(element: BankStatementTransactionRow) {
    this.dataSource = this.dataSource.filter(d => d.id !== element.id);
    this.onSearch();
    this.total = this.filteredDataSource.length;
    if (this.currentSort.active && this.currentSort.direction) {
      this.onSort(this.currentSort);
    } else {
      this.updateDisplayedData();
    }
  }

  deleteAll() {
    const ids = this.filteredDataSource.map(t => t.id);
    this.dataSource = this.dataSource.filter(d => !ids.includes(d.id));
    this.onSearch();
    this.total = this.filteredDataSource.length;
    this.updateDisplayedData();
  }

  onConfirmLoanPayment() {
    this.loanPaymentTransactions.emit(this.dataSource);
  }

  addTransactions() {
    const onTableTransactionIDs = this.dataSource.map(t => t.id);
    this.subscriptions.push(
      this.applicationDialogService.openBankStatementSelectTransactionsDialog({
        transactions: this.allTransactionsDataSource.filter(t => !onTableTransactionIDs.includes(t.id))
      }).afterClosed().subscribe((result) => {
        if (result?.readyForSubmission) {
          const ids = this.dataSource.map(transaction => transaction.id);
          const uniqueSelectedTransactions = result.selectedTransactions.filter(t => !ids.includes(t.id));
          this.dataSource = [
            ...this.dataSource,
            ...uniqueSelectedTransactions
          ].sort((a, b) => a.date > b.date && -1 || 1);
          
          this.filteredDataSource = this.dataSource;
          this.initPagination();

          if (this.currentSort.active && this.currentSort.direction) {
            this.onSort(this.currentSort);
          }
          this.cdr.detectChanges();
        }
      })
    )
  }
}
