import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Application, WithdrawApplicationDialogData, WithdrawApplicationDialogResult, withdrawApplicationReasonOptions} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@UntilDestroy()
@Component({
    templateUrl: './withdraw-application.dialog.html',
    styleUrls: ['./withdraw-application.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, MatInputModule, ReactiveFormsModule, MatOptionModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class WithdrawApplicationDialog implements OnInit {

  formControlReason!: FormControl<string | null>;
  formControlComment!: FormControl<string | null>;
  subscription?: Subscription;
  application!: Application;
  reasonOptions = withdrawApplicationReasonOptions;
  formGroup!: FormGroup<{
    reason: FormControl<string | null>,
    comment: FormControl<string | null>,
  }>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: WithdrawApplicationDialogData,
    private dialogRef: MatDialogRef<WithdrawApplicationDialog, WithdrawApplicationDialogResult>,
  ) {
    this.formControlComment = this.formBuilder.control(null, [Validators.required, Validators.max(10000)]);
    this.formControlReason = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      reason: this.formControlReason,
      comment: this.formControlComment,
    })
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onSubmit(readyForSubmission: boolean) {
    if (readyForSubmission) {
      this.dialogRef.close({
        readyForSubmission,
        reason: this.formControlReason.value ?? '',
        comment: this.formControlComment.value ?? ''
      })
    } else {
      this.dialogRef.close()
    }
  }
}
