<div class="asset-supplier-select-component" [formGroup]="formGroup">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input type="text"
      placeholder="Select supplier ..."
      matInput
      [formControl]="formControl"
      [matAutocomplete]="auto">
      <mat-autocomplete [displayWith]="displayWithFn"
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)">
        @for (supplier of suppliers; track supplier) {
          <mat-option [value]="supplier">
            {{supplier.SupplierName}}
          </mat-option>
        }
      </mat-autocomplete>
      @if (loading&&!(suppliers.length>0)) {
        <mat-spinner diameter="25" matSuffix></mat-spinner>
      }
      @if (selected) {
        <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix />
      }
      @if (searchError) {
        <img src="/assets/images/cross-danger.png" alt="chevron"  matSuffix />
      }
    </mat-form-field>

  </div>
