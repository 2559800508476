<mat-card appearance="outlined" class="mb-4 custom-card">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-11 col-md-11"
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <div class="mt-2"><span class="mdi mdi-wrench" [ngClass]="{'enabled': formControl.value, 'disabled': !formControl.value}"></span></div>
        <div class="details">
          <span class="row list-header">Maintenance mode</span>
          @if (!formControl.value) {
            <span class="row list-description"
              [ngClass]="{'enabled': formControl.value, 'disabled': !formControl.value}"
            > Disabled. Switch the toggle to enable maintenance mode.</span>
          }
          @if (formControl.value) {
            <span class="row list-description"
              [ngClass]="{'enabled': formControl.value, 'disabled': !formControl.value}"
            > Enabled. Switch the toggle to disabled maintenance mode.</span>
          }
        </div>
      </div>
      <div class="col-lg-1 col-md-1 mt-2 ml-n2">
        <mat-slide-toggle
          color="primary"
          [checked]="checkedFlag"
          [formControl]="formControl"
          (change)="onEnableChangeToggle($event)">
        </mat-slide-toggle>
      </div>
    </div>
  </mat-card-content>
</mat-card>
