import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {createTwoDecimalInputMask, Mark} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {compareMatch, InsurancePremiumRateCardValue, MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import {requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { MinMaxRateComponent } from './min-max-rate.component';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'insurance-premium-rate-card',
    templateUrl: './insurance-premium-rate-card.component.html',
    styleUrls: ['./insurance-premium-rate-card.component.scss'],
    exportAs: 'insurancePremiumRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InsurancePremiumRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => InsurancePremiumRateCardComponent), multi: false },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, MatDividerModule, MinMaxRateComponent]
})
export class InsurancePremiumRateCardComponent extends AbstractControlValueAccessor<InsurancePremiumRateCardValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formGroup: FormGroup<{
    baseInterestRate: FormControl<number | null>
    loanAMount:FormControl<MinMaxRateValue>
    loanTerms:FormControl<MinMaxRateValue>
    adverseOnFile:FormControl<number | null>
    lowEquifaxScore:FormControl<number | null>
    docFee:FormControl<MinMaxRateValue>
  }>;
  formControlBaseInterestRate: FormControl<number | null>;
  formControlLoanAmount: FormControl<MinMaxRateValue>;
  formControlLoanTerms: FormControl<MinMaxRateValue>;
  formControlAdverseOnFile: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlLowEquifaxScore: FormControl<number | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlBaseInterestRate = formBuilder.control(0, [Validators.required]);
    this.formControlLoanAmount = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlLoanTerms = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAdverseOnFile = formBuilder.control(0, [Validators.required]);
    this.formControlLowEquifaxScore = formBuilder.control(0, [Validators.required]);
    this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formGroup = formBuilder.group({
      baseInterestRate: this.formControlBaseInterestRate,
      loanAMount: this.formControlLoanAmount,
      loanTerms: this.formControlLoanTerms,
      adverseOnFile: this.formControlAdverseOnFile,
      lowEquifaxScore: this.formControlLowEquifaxScore,
      docFee: this.formControlDocFee,
    });
  }


  ngOnInit() {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          // NOTE: properties of r will not be null if formGroup is valid, validators make sure of that
          const v: InsurancePremiumRateCardValue = {
            baseInterestRate: r.baseInterestRate ?? 0,
            loanAmount: r.loanAMount ?? [],
            loanTerms: r.loanTerms ?? [],
            adverseOnFile: r.adverseOnFile ?? 0,
            lowEquifaxScore: r.lowEquifaxScore ?? 0,
            docFee: r.docFee ?? [],
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: InsurancePremiumRateCardValue | undefined): void | InsurancePremiumRateCardValue {
    if (v) {
      this.formControlBaseInterestRate.setValue(v.baseInterestRate);
      this.formControlLoanAmount.setValue(v.loanAmount ?? []);
      this.formControlLoanTerms.setValue(v.loanTerms ?? []);
      this.formControlAdverseOnFile.setValue(v.adverseOnFile);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlLowEquifaxScore.setValue(v.lowEquifaxScore);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}

