<div class="upload-file-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <upload-file
          title="{{dialogData.title}}"
          [maxFilesAllowed]="5"
          [formControl]="formControl"
          [allowMultiple]="true"></upload-file>
      </div>
    </div>
    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit($event)">Save Documents</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
