import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { LooseDatePipe } from '../../pipes/loose-date.pipe';
import { getUser, openWindowAndDownloadWithFilename, setupUntilDestroy, } from "../component-utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { loadingFor } from "@ngneat/loadoff";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import {
    GetPismoStatementsFn, PismoStatement, PismoDownloadStatementFn, STANDARD_MOMENT_DATE_FORMAT,
} from "@portal-workspace/grow-shared-library";
import { tap } from "rxjs/operators";
import moment from 'moment';
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export type OverdraftAccountStatementsComponentEvent = OverdraftAccountStatementsComponentDisplayStatementsEvent;

export type OverdraftAccountStatementsComponentDisplayStatementsEvent = {
    type: 'display-statements';
    userId: number;
    accountId: number;
    statementId: number;
}

export class OverdraftAccountStatementsComponentInternalDataSource implements DataSource<PismoStatement> {

    subject: BehaviorSubject<PismoStatement[]> = new BehaviorSubject<PismoStatement[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<PismoStatement[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
    }

    update(c: PismoStatement[]) {
        this.subject.next(c);
    }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'overdraft-account-statements',
    standalone: true,
    templateUrl: './overdraft-account-statements.component.html',
    styleUrls: ['./overdraft-account-statements.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    AsyncPipe,
    JsonPipe,
    FlexModule,
    MatInputModule,
    ContentLoaderModule,
    NgClass,
    MatFormFieldModule,
    LooseDatePipe,
    CustomContentLoaderComponent
  ],
})
export class OverdraftAccountStatementsComponent implements OnInit {

    moment = moment
    loggedInUser = getUser();
    STANDARD_MOMENT_DATE_FORMAT = STANDARD_MOMENT_DATE_FORMAT;

    loader = loadingFor('tableLoading');
    subscriptions: Subscription[] = [];

    displayedColumns = ['statements', 'download']
    dataSource = new OverdraftAccountStatementsComponentInternalDataSource();
    total = 0;
    @Input({ required: true }) pismoAccountNumber!: number;
    @Input({ required: true }) getPismoStatementsFn!: GetPismoStatementsFn;
    @Input({ required: true }) downloadStatementInCSV!: PismoDownloadStatementFn;
    @Input({ required: true }) downloadStatementInOFX!: PismoDownloadStatementFn;
    @Input({ required: true }) downloadStatementInPDF!: PismoDownloadStatementFn;

    @Output() events: EventEmitter<OverdraftAccountStatementsComponentEvent> = new EventEmitter<OverdraftAccountStatementsComponentEvent>();

    toastService: PortalHotToastService;

    constructor() {
        this.toastService = inject(PortalHotToastService);
    }


    ngOnInit() {
        setupUntilDestroy(this);
        this.reload();
    }

    getColumnTitles(column: string): string {
        switch (column) {
            case 'statements': return 'List of Statements';
            case 'download': return 'Download';
            default: return column;
        }
    }

    reload() {
        this.subscriptions.push(
            this.getPismoStatementsFn(this.pismoAccountNumber)
                .pipe(
                    this.loader.tableLoading.track(),
                    tap(r => {
                        this.dataSource.update(r);
                        this.total = r.length;
                    })
                ).subscribe());
    }

    downloadInPDF(element: PismoStatement) {
        this.subscriptions.push(this.downloadStatementInPDF(
            this.pismoAccountNumber, element.statement_id
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}`, blob);
            })
        ).subscribe());
    }

    downloadInCSV(element: PismoStatement) {
        this.subscriptions.push(this.downloadStatementInCSV(
            this.pismoAccountNumber, element.statement_id
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}`, blob);
            })
        ).subscribe());
    }

    downloadInOFX(element: PismoStatement) {
        this.subscriptions.push(this.downloadStatementInOFX(
            this.pismoAccountNumber, element.statement_id
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}.ofx`, blob);
            })
        ).subscribe());
    }

    onRowClicked(element: PismoStatement) {
        this.events.emit({
            type: 'display-statements',
            userId: this.loggedInUser?.UserId!,
            accountId: this.pismoAccountNumber,
            statementId: element.statement_id
        });
    }

}
