<div class="credit-decisioning-reason-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Decline The Application</div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Select Reasons</mat-label>
          <mat-select [formControl]="formControlReasonSelection">
            @for (reasonOption of reasonOptions; track reasonOption) {
              <mat-option  [value]="reasonOption">
                {{reasonOption}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>Comment</mat-label>
          <textarea matInput rows="5" wrap="hard" maxlength="7000" [formControl]="formControlReason"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Cancel</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
