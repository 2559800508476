import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationTypeIconComponent } from './application-type-icon.component';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
@NgModule({
    imports: [
        CommonModule,
        AngularMaterialLibraryModule,
        ApplicationTypeIconComponent
    ],
    exports: [
        ApplicationTypeIconComponent,
    ]
})
export class ApplicationTypeIconModule {

}
