import { Component, forwardRef, NgModule } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencySelectionComponent } from './currency-selection.component';
import { CurrencyInputComponent } from './currency-input.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgxCurrencyDirective } from "ngx-currency";
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {CurrencyWithPeriodComponent} from "./currency-with-period.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        NgxCurrencyDirective,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        CurrencySelectionComponent,
        CurrencyInputComponent,
        CurrencyWithPeriodComponent,
    ],
    exports: [
        CurrencySelectionComponent,
        CurrencyInputComponent,
        CurrencyWithPeriodComponent,
    ]
})
export class CurrencySelectionModule {

}
