@if (references) {
  <div class="reference-details-component">
    @if (!(references && references.length)) {
      <div class="no-entries">
        <div fxLayout="column" fxLayoutAlign="center center">
          <img src="/assets/images/faq-empty.png">
          <div class="mat-h3 title">No entries</div>
        </div>
      </div>
    }
    @if (references && references.length) {
      @for (reference of references; track reference; let index = $index) {
        <div class="section">
          <div class="row">
            <div class="col-12">
              <div class="mat-h3">Reference {{index + 1}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Company name</div>
                <div body>{{reference.CompanyName}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Telephone</div>
                <div body>{{reference.Telephone}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Contact person</div>
                <div body>{{reference.ContactPerson}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Type</div>
                <div body>{{reference.Type}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        </div>
      }
    }
  </div>
}
