import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { CurrencySelectionModule } from '../currency-selection-component/currency-selection.module';
import { LocalBankAccountDetailComponent } from './local-bank-account-detail.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        InputMaskModule,
        CurrencySelectionModule,
        LocalBankAccountDetailComponent
    ],
    exports: [
        LocalBankAccountDetailComponent,
    ]
})
export class LocalBankAccountDetailModule {

}
