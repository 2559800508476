<div class="overdraft-account-timeline-component">
  <div class="row">
    <div class="col-12">
      <!-- <ngx-json-viewer [json]="timeline"></ngx-json-viewer> -->
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{element.type}}</td>
        </ng-container>
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">{{element.category}}</td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef>Timestamp</th>
          <td mat-cell *matCellDef="let element">{{element.timestamp}}</td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element;" class="expanded-row" [attr.colspan]="displayColumns.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <ngx-json-viewer [json]="element" [expanded]="false"></ngx-json-viewer>
            </div>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayColumns"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
      @if (total < 1) {
        <table class="width-100">
          <tr>
            <td class="not-found"> No timeline found</td>
          </tr>
        </table>
      }
      @if (total) {
        <app-custom-paginator
          [length]="total"
          [pageIndex]="offset"
          [pageSize]="limit"
          [pageSizeOptions]="[10, 20, 25, 50, 100]"
          (page)="onPagination($event)"
        ></app-custom-paginator>
      }
    </div>
  </div>
</div>