import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {ReferenceComponent} from './reference.component';
import {NgModule} from '@angular/core';
import {BusinessSearchModule} from '../business-search-component/business-search.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TitleSelectionModule,
        BusinessSearchModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        ReferenceComponent
    ],
    exports: [
        ReferenceComponent,
    ]
})
export class ReferenceModule {

}
