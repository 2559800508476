import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {AdminPrivacyConsent, Application, BankStatementsUploadObject, PrivacyConsent, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import { Buffer } from 'buffer';

@Injectable()
export class BankStatementsUploadResolver  {

  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BankStatementsUploadObject | null> | Promise<BankStatementsUploadObject | null> | BankStatementsUploadObject | null {
    const token = route.params.token;
    const decryptedToken = Buffer.from(token, 'base64').toString('binary');
    const codeSplits = decryptedToken.split('/');
    if (codeSplits.length == 2 && !isNaN(Number(codeSplits[1])) && codeSplits[0] === 'quarterly-review') {
      const applicationId = Number(codeSplits[1]);
      return of({
        applicationId
      });
    }
    return null;
  }

}
