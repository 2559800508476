import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  BreadcrumbComponent,
  BreadcrumbComponentEvent,
  LooseDatePipe, MessageBoxComponent,
  PortalHotToastService,
  setupUntilDestroy,
  TagBoxComponent
} from '@portal-workspace/grow-ui-library';
import { CustomerUser, PismoGenericTransaction, PismoGetAccountResponse, PismoTransaction, PismoTransactionsIncludingPending, getAmount, getCreatedDate, getDate, getIsCredit, getIsPendingTransaction, getPismoTransactionType, getRelativeTransactionAmount, getSoftDescriptor, getTime, getTransactionAttributes, getTransactionCategory, GetPismoTransactionsFn, GetTransactionDetailsFn, GetPismoAccountDetailsFn, GetCardFn, GetTransactionFromTimelineFn } from '@portal-workspace/grow-shared-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatSortModule } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import {
  navigationUrlForCustomerDetails,
} from '../../service/navigation-urls';
import { createAsyncStore, loadingFor } from '@ngneat/loadoff';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, AsyncPipe, DatePipe, NgStyle } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { OverdraftCustomerService } from '../../service/overdraft-customer.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import _ from 'lodash';
import { OverdraftAccountTransactionsComponent } from "@portal-workspace/grow-ui-library";

export class InternalDataSource implements DataSource<PismoTransactionsIncludingPending> {

  subject: BehaviorSubject<PismoTransactionsIncludingPending[]> = new BehaviorSubject<PismoTransactionsIncludingPending[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PismoTransactionsIncludingPending[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(c: PismoTransactionsIncludingPending[]) {
    this.subject.next(c);
  }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './pismo-display-transactions.page.html',
  styleUrls: ['./pismo-display-transactions.page.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    FlexModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CustomContentLoaderComponent,
    NgClass,
    ExtendedModule,
    MatTableModule,
    MatSortModule,
    CustomPaginatorComponent,
    AsyncPipe,
    MatDividerModule,
    DatePipe,
    LooseDatePipe,
    BreadcrumbComponent,
    TagBoxComponent,
    NgStyle,
    OverdraftAccountTransactionsComponent,
    MessageBoxComponent
]
})
export class PismoDisplayTransactionsPage implements OnInit {

  stage : 'init' | 'done' | 'error' = 'init';

  breadcrumbTrails: string[] = ['Admin', 'Customer'];
  // loader = loadingFor('tableLoading');
  subscriptions: Subscription[] = [];
  // dataSource = new InternalDataSource();
  // store = createAsyncStore();
  // total = 0;
  // limit = DEFAULT_LIMIT;
  // offset = DEFAULT_OFFSET;
  // sorts?: {
  //   prop: 'ABN' | 'EntityName' | 'SalesforceId' | 'ACN',
  //   dir: 'ASC' | 'DESC'
  // };
  pismoAccountNumber !: number
  // pismoAccountId:number[]=[];
  // pismoTransactionDetails!: PismoTransactionsIncludingPending[];
  customer: CustomerUser | null = null;
  // displayedColumns = ['icon','description', 'amount']
  // expandedElement!: InternalDataSource | null;
  // @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent) => void = (evt) => { };
  // transactionType = getPismoTransactionType
  // createdAt = getCreatedDate
  // getTime = getTime
  // getRelativeTransactionAmount = getRelativeTransactionAmount
  // is_credit = getIsCredit
  // amount = getAmount
  // soft_descriptor = getSoftDescriptor
  // isPendingTransaction = getIsPendingTransaction
  // transactionCategory = getTransactionCategory
  // getTransactionAttributes = getTransactionAttributes
  // getDate = getDate
  // transactionDetails:PismoTransaction | null = null;
  // accountDetails:PismoGetAccountResponse | null = null;

  // toClass(element: PismoGenericTransaction) {
  //   return this.transactionCategory(element) ? _.toLower(this.transactionCategory(element).replace(/\s/g, '')) : '';
  // }

  getPismoTransactionsFn!: GetPismoTransactionsFn;
  getTransactionDetailsFn!: GetTransactionDetailsFn;
  getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
  getTransactionFromTimelineFn!: GetTransactionFromTimelineFn;
  getCardFn!: GetCardFn;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private overdraftCustomerService: OverdraftCustomerService,
    // private toastService: PortalHotToastService
  ) {
    this.getPismoTransactionsFn = this.overdraftCustomerService.getPismoTransactionsFn;
    this.getTransactionDetailsFn = this.overdraftCustomerService.getTransactionDetailsFn;
    this.getPismoAccountDetailsFn = this.overdraftCustomerService.getPismoAccountDetailsFn;
    this.getCardFn = this.overdraftCustomerService.getCardFn;
    this.getTransactionFromTimelineFn = this.overdraftCustomerService.getTransactionFromTimelineFn;
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.pismoAccountNumber = this.activatedRoute.snapshot.params.pismoAccountNumber;
    // this.pismoAccountId.push(this.accountId);
    this.customer = (this.activatedRoute.snapshot.data as any).customerUser;
    const customerName = this.customer?.EntityName
    if (customerName) {
      this.breadcrumbTrails.push(customerName, 'Overdraft Account Details', `Account # ${this.pismoAccountNumber}`);
    }
    if (this.pismoAccountNumber && this.customer) {
      this.stage = 'done';
    } else {
      this.stage = 'error';
    }
    // this.reset();
    // this.reload();
  }

  // reload() {
  //   this.subscriptions.push(
  //     this.overdraftCustomerService.getPismoTransactionsFn(this.accountId,
  //       {
  //         page: {
  //           limit: this.limit, offset: this.offset,
  //         }
  //       })
  //       .pipe(
  //         this.loader.tableLoading.track(),
  //         tap(r => {
  //           this.total = r.total;
  //           this.dataSource.update(r.payload);
  //         })
  //       ).subscribe());
  // }

  // onRowClicked(element: any) {
  //   // fromAccount
  //   this.subscriptions.push(
  //     this.overdraftCustomerService.getTransactionDetailsFn(this.accountId, element.id).pipe(
  //       this.toastService.spinnerObservable(),
  //       tap(r => {
  //         this.transactionDetails = r;
  //       })
  //     ).subscribe())

  //   this.subscriptions.push(
  //   this.overdraftCustomerService.getPismoAccountDetailsFn(this.pismoAccountId).pipe(
  //       this.toastService.spinnerObservable(),
  //       tap(r => {
  //         this.accountDetails = r[0];
  //       })
  //   ).subscribe())
  // }

  // getColumnTitles(column: string): string {
  //   switch (column) {
  //     case 'icon': return ' ';
  //     case 'description': return 'Description';
  //     case 'amount': return 'Amount';
  //     default: return column;
  //   }
  // }

  // private reset() {
  //   this.limit = DEFAULT_LIMIT;
  //   this.offset = 0;
  // }

  // onPagination($event: PageEvent) {
  //   this.limit = $event.pageSize;
  //   this.offset = $event.pageIndex;
  //   this.reload();
  // }

  async onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    await this.router.navigate(navigationUrlForCustomerDetails(this.customer?.CustomerId!))
  }

}
