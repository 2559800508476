<div class="direct-application-layout" fxLayout="column">
  <div class="header">
    <div class="header-1">
      <div class="header-2">
        <direct-application-topmenu [gitTagOrVersion]="gitTagOrVersion">
        </direct-application-topmenu>
      </div>
    </div>
  </div>

  <div class="body"
      fxLayout="row"
      fxLayoutAlign="center top">
  <div class="body-1">
    <div class="body-2">
      <error-notification2></error-notification2>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
