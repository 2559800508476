import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {confirmPasswordValidator, formControlErrorKeys, formControlErrorMessage} from '@portal-workspace/grow-ui-library';
import {combineLatest, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { compareMatch, ConfirmPasswordAdminValue, ConfirmPasswordValue } from '@portal-workspace/grow-shared-library';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

// NOTE: please use value object: ConfirmPasswordAdminValue
// NOTE: The name of this component is a bit confusing, putting this down so it hopefully make it less confusing
//       - This is basically a confirm password, so you have 2 text field to stick in your new password twice
//       - No Old password is required
@UntilDestroy()
@Component({
    selector: 'confirm-password-admin',
    templateUrl: './confirm-password-admin.component.html',
    styleUrls: ['./confirm-password-admin.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConfirmPasswordAdminComponent), multi: true },
    ],
    standalone: true,
    imports: [FormsModule, FlexModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule]
})
export class ConfirmPasswordAdminComponent implements OnInit, ControlValueAccessor {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    password: FormControl<ConfirmPasswordAdminValue>,
    confirmPassword: FormControl<ConfirmPasswordAdminValue>
  }>;
  formControlPassword: FormControl<ConfirmPasswordAdminValue>;
  formControlConfirmPassword: FormControl<ConfirmPasswordAdminValue>;

  onTouchFn?: ()=>void;
  onChangeFn?: (v?: ConfirmPasswordValue)=>void;
  disabled: boolean = false;

  value?: ConfirmPasswordValue;

  subscription: Subscription;

  constructor(private formBuilder: FormBuilder) {
    this.formControlPassword = formBuilder.control('', [Validators.required,Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
    this.formControlConfirmPassword = formBuilder.control('', [Validators.required,Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
    const validator = confirmPasswordValidator(this.formControlPassword, this.formControlConfirmPassword);
    this.formControlConfirmPassword.addValidators([validator]);
    this.formGroup = formBuilder.group({
      password: this.formControlPassword,
      confirmPassword: this.formControlConfirmPassword,
    });
    this.subscription = combineLatest([
      this.formControlPassword.valueChanges,
      this.formControlConfirmPassword.valueChanges,
    ]).pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        const password = r[0];
        const confirmPassword = r[1];
        this.formControlPassword.updateValueAndValidity({onlySelf: false, emitEvent: false});
        this.formControlConfirmPassword.updateValueAndValidity({onlySelf: false, emitEvent: false});
        this.propagateChange(this.formGroup.valid? password : null);
      })
    ).subscribe();
  }

  hide = true;
  confirmPassword_hide = true;
  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  writeValue(password?: ConfirmPasswordAdminValue): void {
    this.formControlPassword.setValue(password ? password : '');
    this.formControlConfirmPassword.setValue(password ? password: '');
    this.propagateChange(password);
  }

  propagateChange(password?: ConfirmPasswordAdminValue) {
    this.value = password;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(password ? password : null);
  }



}

