<div class="application-summary-component">
  @if (summaryValue) {
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summaryValue.monthlyRepayment || summaryValue.estimatedDrawDownDate) {
        <div class="row">
          <div class="col-3">
            <mat-form-field>
              <mat-label>Monthly repayments</mat-label>
              <input matInput type="input" readonly name="monthlyRepayment" [ngModel]="summaryValue.monthlyRepayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Estimated draw down date</mat-label>
              <input matInput type="input" readonly name="estimatedDrawDownDate"
                [ngModel]="displayableDate(summaryValue.estimatedDrawDownDate)">
              </mat-form-field>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-3">
            <cell>
              <div label>Application ID</div>
              <div body>{{summaryValue.applicationId}}</div>
            </cell>
          </div>
          <div class="col-3">
            <cell>
              <div label>Organisation</div>
              <div body>{{summaryValue.organisation}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-3">
            <cell>
              <div label>ABN</div>
              <div body>{{summaryValue.abn}}</div>
            </cell>
          </div>
          @if (summaryValue.organisationType?.name=='Company') {
            <div class="col-3">
              <cell>
                <div label>ACN</div>
                <div body>{{summaryValue.acn}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summaryValue.organisationType) {
            <div class="col-3">
              <cell>
                <div label>Organisation type</div>
                <div body>{{summaryValue.organisationType.name}}</div>
              </cell>
            </div>
          }
          @if (summaryValue.financeType) {
            <div class="col-3">
              <cell>
                <div label>Finance type</div>
                <div body>{{summaryValue.financeType.name}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-3">
            <cell>
              <div label>Revenue</div>
              <div body>{{summaryValue.revenue}}</div>
            </cell>
          </div>
          @if (summaryValue.limitRequest) {
            <div class="col-3">
              <cell>
                <div label>Limit request</div>
                <div body>{{summaryValue.limitRequest}}</div>
              </cell>
            </div>
          }
          <div class="col-3">
            <cell>
              <div label>Commercial premises</div>
              <div body>{{booleanToYesNo(summaryValue.commercialPremises)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summaryValue.primaryIndustry) {
            <div class="col-6">
              <cell>
                <div label>Primary industry</div>
                <div body>{{summaryValue.primaryIndustry.name}}</div>
              </cell>
            </div>
          }
          @if (summaryValue.industrySector) {
            <div class="col-3">
              <cell>
                <div label>Industry sector</div>
                <div body>{{summaryValue.industrySector.name}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summaryValue.primaryBusinessAddress) {
            <div class="col-6">
              <cell>
                <div label>Primary business address</div>
                <div body>{{summaryValue.primaryBusinessAddress.address}}</div>
              </cell>
            </div>
          }
          <div class="col-3">
            <cell>
              <div label>Business landline</div>
              <div body>{{summaryValue.businessLandline}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        @if (summaryValue.asset) {
          <div class="row">
            <div class="col-3">
              <cell>
                <div label>Asset category</div>
                <div body>{{summaryValue.asset.category.value}}</div>
              </cell>
            </div>
            <div class="col-6">
              <cell>
                <div label>Asset type</div>
                <div body>{{summaryValue.asset.type.value}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        }
        @if (summaryValue.asset) {
          <div class="row">
            <div class="col-3">
              <cell>
                <div label>Make</div>
                <div body>{{summaryValue.asset.make}}</div>
              </cell>
            </div>
            <div class="col-3">
              <cell>
                <div label>Family</div>
                <div body>{{summaryValue.asset.family}}</div>
              </cell>
            </div>
            <div class="col-3">
              <cell>
                <div label>Year</div>
                <div body>{{summaryValue.asset.year}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        }
        @if (summaryValue.asset || summaryValue.assetCondition) {
          <div class="row">
            @if (summaryValue.asset) {
              <div class="col-6">
                <cell>
                  <div label>Model</div>
                  <div body>{{summaryValue.asset.model}}</div>
                </cell>
              </div>
            }
            @if (summaryValue.assetCondition) {
              <div class="col-3">
                <cell>
                  <div label>Asset condition</div>
                  <div body>{{summaryValue.assetCondition.name}}</div>
                </cell>
              </div>
            }
          </div>
        }
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summaryValue.invoiceAmount) {
            <div class="col-3">
              <cell>
                <div label>Invoice amount</div>
                <div body>{{summaryValue.invoiceAmount}}</div>
              </cell>
            </div>
          }
          @if (summaryValue.loanPurpose) {
            <div class="col-3">
              <cell>
                <div label>Loan Purpose</div>
                <div body>{{summaryValue.loanPurpose.name}}</div>
              </cell>
            </div>
          }
          @if (summaryValue.loanTerms) {
            <div class="col-3">
              <cell>
                <div label>Loan term (in months)</div>
                <div body>{{summaryValue.loanTerms.name}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-3">
            <cell>
              <div label>Private sale or Sales lease back</div>
              <div body>{{booleanToYesNo(summaryValue.privateSales)}}</div>
            </cell>
          </div>
          <div class="col-3">
            <cell>
              <div label>Equifax Score above 600</div>
              <div body>{{booleanToYesNo(summaryValue.equifaxScoreAboveThreshold)}}</div>
            </cell>
          </div>
          <div class="col-3">
            <cell>
              <div label>Adverse on file</div>
              <div body>{{booleanToYesNo(summaryValue.adverseOnFile)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-3">
            <cell>
              <div label>Doc Fee financed</div>
              <div body>{{booleanToYesNo(summaryValue.docFee)}}</div>
            </cell>
          </div>
          @if (summaryValue.brokerOriginationFee) {
            <div class="col-3">
              <cell>
                <div label>Broker origination fee (excl GST)</div>
                <div body>{{summaryValue.brokerOriginationFee}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <!-- DIRECTOR -->
        <directors-summary [directors]="_directors"></directors-summary>
        <!-- TRUSTEE -->
        <trustees-summary [trustees]="_trustee"></trustees-summary>
        <!-- PARTNER -->
        <partners-summary [partners]="_partners"></partners-summary>
        <!-- MEMBER -->
        <members-summary [members]="_members"></members-summary>
        <!-- SOLE TRADER -->
        <soletraders-summary></soletraders-summary>
        <!-- GUARANTOR -->
        <guarantors-summary [guarantors]="summaryValue.guarantors"></guarantors-summary>
      </div>
    }
  </div>