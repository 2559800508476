import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PortalHotToastService, ApplicationDialogService, MessageBoxComponent, TagBoxComponent, getUser } from '@portal-workspace/grow-ui-library';
// import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ConfirmationDialogResult,
  CustomerUser,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DigitalIdResponse, DisableOverdraftUserFn,
  EditKycStatusDialogResult,
  EnableOverdraftUserFn,
  GetCustomerPismoAccountMappingsFn,
  GetPismoCardsForCustomerFn,
  GetPismoCustomerForAccountFn,
  GetTokenInfoFn, isAdminOrOperationUser, KycStatus,
  PismoAccountMapping,
  PismoCardDetailsWithAccountId,
  PismoCardTokenInfo,
  PismoChangeCardStatusFn,
  PismoGetCardReissueReasonsFn,
  PismoReissueCardFn,
  PismoUpdateCustomerFn,
  PortalLoginUser,
  SendForgotPasswordFn,
  SlideToggleValue,
  UpdateUserKycStatusFn,
  UserWithPismoMapping
} from '@portal-workspace/grow-shared-library';
import { AsyncPipe, DatePipe, JsonPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { loadingFor } from '@ngneat/loadoff';
import { MatSortModule, Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexModule } from "@angular/flex-layout";
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";

export interface DataSourceEntry {
  slideToggleFormControl: FormControl<SlideToggleValue>,
  item: PismoCardDetailsWithAccountId;
}

export class OverdraftUserDataSource extends DataSource<UserWithPismoMapping> {

  subject = new BehaviorSubject<UserWithPismoMapping[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<UserWithPismoMapping[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(users: UserWithPismoMapping[]) {
    this.subject.next(users);
  }
}

@UntilDestroy()
@Component({
  selector: 'overdraft-account-users',
  templateUrl: './overdraft-account-users.component.html',
  styleUrls: ['./overdraft-account-users.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    FormsModule, InputMaskModule,
    ReactiveFormsModule, NgFor, NgIf,
    DisableControlDirective,
    CustomContentLoaderComponent,
    AsyncPipe,
    JsonPipe,
    NgClass,
    MatTableModule,
    MatDividerModule,
    MessageBoxComponent,
    TagBoxComponent,
    MatTooltipModule,
    CustomPaginatorComponent,
    MatButtonModule,
    MatSortModule,
    MatExpansionModule, FlexModule,
    DatePipe,
    NgStyle,
  ]
})

export class OverdraftAccountUsersComponent implements OnInit, OnChanges {

  @Input({ required: true }) disableOverdraftUserFn!: DisableOverdraftUserFn;
  @Input({ required: true }) enableOverdraftUserFn!: EnableOverdraftUserFn;
  @Input({ required: true }) existingPismoAccountMappings: PismoAccountMapping[] = [];
  @Input({ required: true }) getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn
  @Input({ required: true }) updateUserKycStatusFn!: UpdateUserKycStatusFn
  @Input({ required: true }) sendForgotPasswordFn!: SendForgotPasswordFn
  @Input({ required: true }) pismoUpdateCustomerFn !: PismoUpdateCustomerFn
  @Input({ required: true }) getPismoCardsForCustomerFn!: GetPismoCardsForCustomerFn
  @Input({ required: true }) pismoChangeCardStatusFn!: PismoChangeCardStatusFn;
  @Input({ required: true }) pismoGetCardReissueReasonsFn!: PismoGetCardReissueReasonsFn;
  @Input({ required: true }) pismoReissueCardFn!: PismoReissueCardFn;
  @Input({ required: true }) getTokenInfoFn!: GetTokenInfoFn;

  subscriptions: Subscription[] = [];
  moment = moment
  isAdminOrOperationUser = isAdminOrOperationUser;
  // enabled users table
  enabledUsersDataSource = new OverdraftUserDataSource();
  enabledUsersTotal = 0;
  enabledUsersLimit = DEFAULT_LIMIT;
  enabledUsersOffset = DEFAULT_OFFSET;
  enabledUsersSorts?: {
    prop: 'pismoCustomerNumber' | 'name' | 'access' | 'email' | 'dob' | 'mobileNumber',
    dir: 'ASC' | 'DESC'
  };
  enabledUsersDisplayColumns = ['pismoCustomerNumber', 'name', 'email', 'dob', 'mobileNumber', 'icon'];// 'access', 'verified', 'kycStatus', 'action'];
  enabledUsersExpandedElement: UserWithPismoMapping | null = null;

  // disabled users table
  disabledUsersDataSource: OverdraftUserDataSource = new OverdraftUserDataSource();
  disabledUsersTotal = 0;
  disabledUsersLimit = DEFAULT_LIMIT;
  disabledUsersOffset = DEFAULT_OFFSET;
  disabledUsersSorts?: {
    prop: 'pismoCustomerNumber' | 'name' | 'access' | 'email' | 'dob' | 'mobileNumber',
    dir: 'ASC' | 'DESC'
  };
  disabledUsersDisplayColumns = ['pismoCustomerNumber', 'name', 'email', 'dob', 'mobileNumber', 'icon'];// 'access', 'verified', 'kycStatus', 'action'];
  disabledUsersExpandedElement: UserWithPismoMapping | null = null;
  user: PortalLoginUser | null = getUser();
  canAccess = isAdminOrOperationUser(this.user)
  //cards
  cardsDataSource = new MatTableDataSource<PismoCardDetailsWithAccountId>([]);
  cardsDisplayColumns = this.canAccess? ['printed_name', 'last_4_digits', 'expiration_date', 'status', 'actions'] : ['printed_name', 'last_4_digits', 'expiration_date', 'status'];
  cardsTotalPerUser = 0;


  // common
  pismoAccountNumbers: number[] = [];
  loader = loadingFor(
    'loadingEnabledOverdraftUser',
    'loadingDisabledOverdraftUser',
    'disablingOverdraftUser',
    'loadingCardDetails'
  );

  constructor(private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['existingPismoAccountMappings'];
    if (change && change.currentValue && change.currentValue.length) {
      this.reload();
    }
  }

  reload() {
    this.reloadEnabledUsers();
    this.reloadDisabledUsers();
  }

  reloadEnabledUsers() {
    if (this.existingPismoAccountMappings.length) {
      this.pismoAccountNumbers = (this.existingPismoAccountMappings ?? []).map(m => m.pismoAccountNumber);

      // load enabled users (pismo customers)
      this.subscriptions.push(this.getPismoCustomerForAccountFn(this.pismoAccountNumbers, {
        page: {
          offset: this.enabledUsersOffset,
          limit: this.enabledUsersLimit,
        },
        sorts: this.enabledUsersSorts ? [this.enabledUsersSorts] : undefined,
      }, 'enabled').pipe(tap(r => {
        this.loader.loadingEnabledOverdraftUser.track();
        this.enabledUsersTotal = r.total;
        this.enabledUsersDataSource.update(r.payload);
        this.enabledUsersExpandedElement = null;
      })).subscribe());
    }
  }

  reloadDisabledUsers() {
    if (this.existingPismoAccountMappings.length) {
      this.pismoAccountNumbers = (this.existingPismoAccountMappings ?? []).map(m => m.pismoAccountNumber);

      // load disabled users (pismo customer)
      this.subscriptions.push(this.getPismoCustomerForAccountFn(this.pismoAccountNumbers, {
        page: {
          offset: this.disabledUsersOffset,
          limit: this.disabledUsersLimit,
        },
        sorts: this.disabledUsersSorts ? [this.disabledUsersSorts] : undefined,
      }, 'disabled').pipe(tap(r => {
        this.loader.loadingDisabledOverdraftUser.track();
        this.disabledUsersTotal = r.total;
        this.disabledUsersDataSource.update(r.payload);
        this.disabledUsersExpandedElement = null;

      })).subscribe());
    }
  }

  onRowClickEnabledUser(row: UserWithPismoMapping) {
    console.log('row clicked', row)
    this.enabledUsersExpandedElement = this.enabledUsersExpandedElement && this.enabledUsersExpandedElement.pismoCustomerNumber === row.pismoCustomerNumber ? null : row;
    this.subscriptions.push(
      this.getPismoCardsForCustomerFn(row.pismoAccountNumber, row.pismoCustomerNumber)
        .pipe(
          this.loader.loadingCardDetails.track()
        )
        .subscribe(
          (response: PismoCardDetailsWithAccountId[]) => {
            console.log('=====pismo customer card details: ', response)
            const pismoCustomerCardDetails = response;
            if (pismoCustomerCardDetails.length > 0) {
              this.cardsDataSource.data = response
              this.cardsTotalPerUser = pismoCustomerCardDetails.length;
            }
            else {
              this.cardsDataSource.data = [];
              this.cardsTotalPerUser = 0;
            }
          }
        )
    )
  }

  onRowClickDisabledUser(row: UserWithPismoMapping) {
    this.disabledUsersExpandedElement = this.disabledUsersExpandedElement ? null : row;
  }

  onEnabledUsersSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      let prop = 'email';
      switch ($event.active) {
        case 'pismoCustomerNumber':
          prop = 'pismoCustomerNumber';
          break;
        case 'name':
          prop = 'name';
          break;
        case 'access':
          prop = 'access';
          break;
        case 'email':
          prop = 'email';
          break;
        case 'dob':
          prop = 'dob';
          break;
        case 'mobileNumber':
          prop = 'mobileNumber';
          break;
      }
      this.enabledUsersLimit = DEFAULT_LIMIT;
      this.enabledUsersOffset = DEFAULT_OFFSET;
      this.enabledUsersSorts = {
        prop: prop as any, dir
      };
      this.reloadEnabledUsers();
    } else {
      this.enabledUsersSorts = undefined;
      this.reloadEnabledUsers();
    }
  }

  onDisabledUsersSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      let prop = 'email';
      switch ($event.active) {
        case 'pismoCustomerNumber':
          prop = 'pismoCustomerNumber';
          break;
        case 'name':
          prop = 'name';
          break;
        case 'access':
          prop = 'access';
          break;
        case 'email':
          prop = 'email';
          break;
        case 'dob':
          prop = 'dob';
          break;
        case 'mobileNumber':
          prop = 'mobileNumber';
          break;
      }
      this.disabledUsersLimit = DEFAULT_LIMIT;
      this.disabledUsersOffset = DEFAULT_OFFSET;
      this.disabledUsersSorts = {
        prop: prop as any, dir
      };
      this.reloadDisabledUsers();
    } else {
      this.disabledUsersSorts = undefined;
      this.reloadDisabledUsers();
    }
  }

  onEnabledUsersPagination($event: PageEvent) {
    this.enabledUsersLimit = $event.pageSize;
    this.enabledUsersOffset = $event.pageIndex;
    this.reloadEnabledUsers();
  }

  onDisabledUsersPagination($event: PageEvent) {
    this.disabledUsersLimit = $event.pageSize;
    this.disabledUsersOffset = $event.pageIndex;
    this.reloadDisabledUsers();
  }

  onUpdateKycVerificationStatus(element: UserWithPismoMapping) {
    this.subscriptions.push(
      this.dialogService.openEditKycStatusDialog({
        kycStatus: element.kycStatus
      }).afterClosed().subscribe((result: EditKycStatusDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          console.log('============result: ', result)
          this.updateUserKycStatusFn(result.kycStatus as KycStatus, element.UserId)
            .pipe(
              this.toastService.spinnerObservable(),
            ).subscribe(() => { this.reload() })
        }
      })
    )
  }


  // NOTE: not used
  // verificationStatusDisplay(verificationStatus?: DigitalIdResponse['verification_status'] | 'failed'): string {
  //   if (verificationStatus) {
  //     switch (verificationStatus) {
  //       case 'completed':
  //         return 'Completed';
  //       case 'in_progress':
  //         return 'In Progress';
  //       case 'failed':
  //         return 'Failed';
  //       default:
  //         return 'Not available';
  //     }
  //   }
  //   return 'Not available';
  // }

  datasourceEventDisplay(event: DigitalIdResponse['data_source_events'][number]): string {
    if (event) {
      switch (event) {
        case 'credit_header_check_failed':
          return 'Credit header check failed';
        case 'name_and_address_matched':
          return 'Name and address matched';
        case 'name_and_dob_matched':
          return 'Name and DOB matched';
        case 'kyc_basic_callback_result_success':
          return 'KYC basic Callback Result Success';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  defaultString(s: any): string {
    return s ? s : 'Not available';
  }

  resetPassword(email: string) {
    this.subscriptions.push(
      this.sendForgotPasswordFn({
        Email: email,
      }).pipe(
        this.toastService.spinnerObservable(),
        tap(r => {
          if (r.status) {
            this.dialogService.successDialog({
              message: 'Success',
              subMessage: `An email has been sent to ${email} to reset password.`
            })
          } else {
            this.dialogService.openAlertDialog({
              message: 'Error',
              subMessage: r.message,
            });
          }
        })
      ).subscribe()
    );
  }

  editPismoCustomer(userData: UserWithPismoMapping) {
    this.dialogService.openPismoEditCustomerDialog({
      item: userData,
      pismoUpdateCustomer: this.pismoUpdateCustomerFn
    })
      .afterClosed()
      .pipe(tap(r => {
        if (r)
          this.reload();
      })
      ).subscribe()

  }

  openCardsSettings(element: PismoCardDetailsWithAccountId) {
    this.subscriptions.push(
      this.getTokenInfoFn(element.accountId, element.id)
        .pipe(
          this.toastService.spinnerObservable()
        )
        .subscribe(
          (response: PismoCardTokenInfo[]) => {
            console.log('=====pismo customer card details: ', response)
            this.dialogService.openPismoActivateOrDeactivateCardDialog({
              tokenInfos: response,
              cardDetails: element,
              pismoChangeCardStatus: this.pismoChangeCardStatusFn,
              pismoGetCardReissueReasonsFn: this.pismoGetCardReissueReasonsFn,
              pismoReissueCardFn: this.pismoReissueCardFn
            })
              .afterClosed()
              .pipe(
                tap(r => {
                  this.reload();
                })
              ).subscribe();
          }
        )
    )
  }

  disableUser(userId: number, pismoAccountNumber: number) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to disable this user?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          this.subscriptions.push(this.disableOverdraftUserFn(userId, pismoAccountNumber).pipe(
            this.toastService.spinnerObservable(),
            this.loader.disablingOverdraftUser.track(),
            this.toastService.snackBarObservable(`User ${userId} with pismo account number ${pismoAccountNumber} disabled`),
            tap(r => {
              this.reload();
            })
          ).subscribe());
        }
      })
    )
  }

  enableUser(userId: number, pismoAccountNumber: number) {
      this.dialogService.openEnablePismoCustomerAccountDialog({
        userId:userId,
        pismoAccountNumber,
        enableOverdraftUserFn: this.enableOverdraftUserFn,
      })
      .afterClosed()
      .subscribe((r)=>{
        if(r && r.valid){
           this.reload();
        }
      });
  }

}
