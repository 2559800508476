import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, Validators, FormBuilder, FormArray, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {createTwoDecimalInputMask, Mark} from '@portal-workspace/grow-ui-library';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import numeral from 'numeral';
import {MARK} from '@portal-workspace/grow-ui-library/mark';

import {compareMatch, MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import {MatTooltipModule} from "@angular/material/tooltip";


export type MinMaxFormGroup = FormGroup<{
  min: FormControl<string | null>,
  max: FormControl<string | null>,
  rate: FormControl<string | null>,
}>;

export type MinMaxFormControls = FormControl<string | null>

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'min-max-rate',
    templateUrl: './min-max-rate.component.html',
    styleUrls: ['./min-max-rate.component..scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MinMaxRateComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => MinMaxRateComponent), multi: false },
    ],
    standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, InputMaskModule, FlexModule, MatButtonModule, MatTooltipModule]
})
export class MinMaxRateComponent extends AbstractControlValueAccessor<MinMaxRateValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: false}) type: 'percent' | 'currency' = 'percent';
  @Input({required: false}) title?: string = undefined;
   
  formArray: FormArray<MinMaxFormGroup>;
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<MinMaxFormGroup>([]);
  }

  mark(): void {
    this.formArray.markAllAsTouched();
  }

  ngOnInit(): void {
    untilDestroyed(this);
    const sub = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r) => {
        this.change();
      }),
    ).subscribe();
    this.subscriptions.push(sub);
  }

  private change() {
    if (this.formArray.valid) {
      const r = this.formArray.value;
      const v: Exclude<MinMaxRateValue, null> = r.map(_r => {
        return {
          From: (numeral(_r.min).value() || numeral(_r.min).value() === 0)  ? String(numeral(_r.min).value()) : undefined,
          To: (numeral(_r.max).value() || numeral(_r.max).value() === 0) ? String(numeral(_r.max).value()) : undefined,
          Rate: numeral(_r.rate).value() ? String(numeral(_r.rate).value()) :  '0',
        }
      });
      this.propagateChange(v);
    } else {
      this.propagateChange(null);
    }
  }

  add(r?: Exclude<MinMaxRateValue, null>[number]) {
    if (r) { // populating mode
      const control1 = this.formBuilder.control((r && (r.From == null || r.From == undefined)) ? 'min': r.From!);
      (control1 as any).readonly = (r && (r.From  == null || r.From == undefined)) ? true : false;
      const control2 = this.formBuilder.control((r && (r.To == null || r.To == undefined)) ? 'max' : r.To!);
      (control2 as any).readonly = (r && (r.To  == null || r.To == undefined))  ? true : false;
      const control3 = this.formBuilder.control(r && r.Rate ? r.Rate : '0');
      (control3 as any).readonly = false;
      const group = this.formBuilder.group({
        min: control1,
        max: control2,
        rate: control3,
      });
      this.formArray.push(group);
    } else { // non populating mode
      if (this.formArray.length === 0) {
        const control1 = this.formBuilder.control('min');
        (control1 as any).readonly = true;
        const control2 = this.formBuilder.control('max');
        (control2 as any).readonly = true;
        const control3 = this.formBuilder.control('0');
        control3.setValidators([Validators.required]);
        (control3 as any).readonly = false;
        const group = this.formBuilder.group({
          min: control1,
          max: control2,
          rate: control3,
        });
        this.formArray.push(group);
      } else if (this.formArray.length === 1) {
        const formGroup = this.formArray.at(0);
        (formGroup.controls ).max.setValue('0');
        (formGroup.controls ).max.setValidators([Validators.required]);
        ((formGroup.controls ).max as any).readonly = false;
        (formGroup.controls ).max.updateValueAndValidity();

        const control1 = this.formBuilder.control('0');
        control1.setValidators([Validators.required]);
        (control1 as any).readonly = false;
        const control2 = this.formBuilder.control('max');
        (control2 as any).readonly = true;
        const control3 = this.formBuilder.control('0', [Validators.required]);
        (control3 as any).readonly = false;
        const group = this.formBuilder.group({
          min: control1,
          max: control2,
          rate: control3,
        });
        this.formArray.push(group);
      } else {
        const newMax = ((this.formArray.at(this.formArray.length-1) ).controls ).min.value; // get min value to use as max value
        const newMin = ((this.formArray.at(this.formArray.length-2) ).controls ).max.value; // get max value to use as min value

        const control1 = this.formBuilder.control(newMin, [Validators.required]);
        (control1 as any).readonly = false;
        const control2 = this.formBuilder.control(newMax, [Validators.required]);
        (control2 as any).readonly = false;
        const control3 = this.formBuilder.control('0', [Validators.required]);
        (control3 as any).readonly = false;
        const group = this.formBuilder.group({
          min: control1,
          max: control2,
          rate: control3,
        });
        this.formArray.insert(this.formArray.length-1, group);
      }
    }
  }

  remove() {
    if (this.formArray.length === 1) {
      this.formArray.removeAt(0);
    } else if (this.formArray.length === 2) {
      this.formArray.removeAt(this.formArray.length -1);
      const formGroup = this.formArray.at(0);
      ((formGroup.controls ).min ).setValue('min');
      ((formGroup.controls ).min ).setValidators([]);
      ((formGroup.controls ).min ).updateValueAndValidity();
      ((formGroup.controls ).min as any).readonly = true;
      ((formGroup.controls ).max ).setValue('max');
      ((formGroup.controls ).max ).setValidators([]);
      ((formGroup.controls ).max ).updateValueAndValidity();
      ((formGroup.controls ).max as any).readonly = true;
    } else if (this.formArray.length > 2) {
      this.formArray.removeAt(this.formArray.length - 2);
    }
  }

  formGroupControl(fg: AbstractControl, name: 'min' | 'max' | 'rate'): MinMaxFormControls {
    return ((fg as MinMaxFormGroup).controls[name]);
  }

  formGroupControlReadonly(fg: AbstractControl, name: 'min' | 'max' | 'rate'): boolean {
    return !!((fg as MinMaxFormGroup).controls[name] as any).readonly;
  }


  doWriteValue(v: MinMaxRateValue | null | undefined): void | MinMaxRateValue {
    if (v) {
      for (const _v of v) {
        this.add(_v);
      }
    }
    return undefined;
  }

  onAdd($event: MouseEvent) {
    this.add();
  }

  onRemove($event: MouseEvent) {
    this.remove();
  }
}
