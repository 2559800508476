<div class="pismo-post-manual-transaction-dialog m-4">
  <h3 mat-dialog-title>Post manual transaction</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Pismo Transaction Type</mat-label>
          <mat-select placeholder="Select Pismo Transaction Type ..." [formControl]="formControlPismoTransactionType">
            @for (pismoTransactionType of pismoTransactionTypes; track pismoTransactionType) {
              <mat-option [value]=pismoTransactionType>
                {{pismoTransactionType.transaction_type_description}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <radio-options [radioOptions]="radioOptions" [formControl]="formControlTransactionLevel">
        </radio-options>
      </div>
    </div>

    @if (showCard) {
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Select Card</mat-label>
            <mat-select placeholder="Select Card ..." [formControl]="formControlCard">
              <!-- Check if pismoCardsByAccount is empty -->
              @if (pismoCardsByAccount.length === 0) {
                <mat-option disabled value="no-cards-found">No cards found</mat-option>
              }
              <!-- Iterate through pismoCardsByAccount if it's not empty -->
              @for (pismoCard of pismoCardsByAccount; track pismoCard) {
                <mat-option [value]="pismoCard">
                  {{ pismoCard.printed_name }} - {{ pismoCard.last_4_digits }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }


    <div class="row">
      <div class="col-12">
        <currency-input title="Amount" [formControl]="formControlAmount" [min]=0.1></currency-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput [formControl]="formControlDescription" maxlength="150"></textarea>
          @for (errorKey of errorKeys(formControlDescription); track errorKey) {
            <mat-error>
              @if (formControlDescription.touched && formControlDescription.hasError(errorKey)) {
                {{errorMessage(formControlDescription, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave($event)" [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>