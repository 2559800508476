<div class="show-user-dialog">
    <mat-dialog-content>
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
                <div class="col-auto">
                    <div class="icon icon-shape red-lighten rounded-circle shadow">
                        <i class="mdi mdi-alert-rhombus red color-warn"></i>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="mat-h3 mb-3" class="title">
                        {{data.message}}
                    </div>
                    <div class="message">
                        <p>{{data.subMessage}}</p>
                    </div>
                    <div>
                        <button color="primary" class="custom-button" (click)="onClose($event)" mat-stroked-button
                            class="ml-3">
                            <span>Close</span>
                        </button>
                        <button class="custom-button" (click)="onReset($event)" class="ml-3" mat-stroked-button>Reset
                            Password</button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>