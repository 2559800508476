import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { AddressComponentValue } from '@portal-workspace/grow-shared-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';

@Component({
    selector: 'custom-google-address',
    templateUrl: './custom-google-address.component.html',
    styleUrls: ['./custom-google-address.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomGoogleAddressComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CustomGoogleAddressComponent) },
    ],
    standalone: true,
    imports: [FormsModule, NgClass, ExtendedModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule]
})
export class CustomGoogleAddressComponent implements ControlValueAccessor, OnInit, Mark, AfterViewInit {

  static num = 0;
  id = `address-component-${CustomGoogleAddressComponent.num++}`;

  options: AutocompleteOptions = {
  };

  @Input({required: false}) localOnly = true;
  @Input({required: false}) label: string = 'Business address';
  @Input({required: false}) required:boolean = true;
  @Input({required: false}) types: ('establishment' | 'address' | 'geocode')[] = ['address'];

  @ViewChild('mapInput') mapInput?: ElementRef<any>;

  onChangeFn?: (add?: AddressComponentValue)=>void;
  onTouchFn?: ()=>void;
  disabled = false;
  val?: AddressComponentValue;
  placeSelected?: PlaceResult;

  formGroup!: FormGroup<{
    address: FormControl<string | null>
  }>;
  formControlAddress!: FormControl<string | null>;

  constructor(private formBuilder: FormBuilder) {
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  ngOnInit(): void {
    if (this.val && this.val.address) {
    }
    this.formControlAddress = this.formBuilder.control('', this.required ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      address: this.formControlAddress,
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: AddressComponentValue): void {
    this.val = obj;
    // HACK: to repopulate google address input text field
    setTimeout(()=> {
      const inputElement = this.mapInput?.nativeElement;
      if (inputElement) {
        inputElement.value = obj ? obj.address : '';
      }
    });
    this.propagateChange(obj);
  }

  propagateChange(v?: AddressComponentValue) {
    this.val = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v ? v : null);
  }

  async ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.mapInput?.nativeElement,
      {
        componentRestrictions: {country: this.localOnly ? ['au'] : []},
        types: this.types
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const placeResult: PlaceResult = autocomplete.getPlace();
      this.onPlaceSelected(placeResult);
    });
  }

  onLocationSelected($event: any) {
    if (this.placeSelected && this.placeSelected.formatted_address) {
      this.propagateChange({
        address: this.placeSelected.formatted_address,
        location: $event,
        place: this.placeSelected,
      })
    }
  }

  onPlaceSelected($event: google.maps.places.PlaceResult) {
    this.placeSelected = $event;
    this.onLocationSelected($event)
  }

  onTextChange($event: Event) {
    const htmlInputElement: HTMLInputElement | null = $event.target as HTMLInputElement;
    if (htmlInputElement) {
      const val = htmlInputElement.value;
      if (!val.trim()) {
        this.propagateChange(null);
      } else {
        this.val = { address: val };
        this.propagateChange(this.val);
      }
    }
  }
}
