<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->

@if (!((store.value$ | async)?.error)) {
  <div class="accreditation-listing-page container-fluid">
    @if (promo?.showMessage) {
      <div class="promo">
        <message-box type="promo-style2" [url]="this.promo?.link||''" (close)="closePromo($event)">{{ promo?.message }}
        </message-box>
      </div>
    }
    <div class="header-bar">
      <div class="row">
        <div class="col-12">
          <div fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="space-between center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <mat-form-field class="search"
              fxFlex="1 1 400px"
              fxFlex.lt-md="1 1 100%"
              fxFlexAlign="start">
              <input matInput #searchText [formControl]="formControlSearch"
                [readonly]="(loader.tableLoading.inProgress$ | async)" class="ml-4" type="text"
                placeholder="Search accreditation">
              <span class="mdi mdi-magnify" matPrefix></span>
            </mat-form-field>
            <div class="select w-lt-md-100"
              fxFlexAlign="end"
              fxFlex.lt-md="1 1 100%"
              fxFlexAlign.lt-md="start"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayout.lt-md="column"
              fxLayoutAlign.lt-md="center start">
              <div class="label">Show</div>
              <mat-form-field class="w-lt-md-100">
                <mat-select [(value)]="selectedBusinessType">
                  @for (businessType of businessTypes; track businessType) {
                    <mat-option
                      [value]="businessType"
                      (click)="onBusinessTypeSelected(businessType)">
                      <business-type-icon [businessType]="businessType.type"></business-type-icon>
                      {{ businessType.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row separator-top">
      <div class="col body">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="accreditationId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="AccreditationId">Accreditation ID</th>
              <td class="col-accreditation-id" mat-cell *matCellDef="let accreditation">
                <business-type-icon [businessType]="accreditation.natureofBusiness"></business-type-icon>
                {{ getAccreditationId(accreditation.AccreditationId).applicantNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="companyName">
              <th class="col-companyName" mat-header-cell *matHeaderCellDef mat-sort-header="companyName">
                <span>Company Name</span>
              </th>
              <td mat-cell *matCellDef="let accreditation">
                {{ accreditation.companyName }}<br/>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let accreditation">
                <tag-box [state]="getApplicationStatus(accreditation.Stage,accreditation.SalesforceId) ?? ''"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="stage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stage</th>
              <td mat-cell *matCellDef="let accreditation">
                <tag-box [state]="accreditation.Stage"></tag-box>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr class="cursor-pointer" (click)="onAccreditationClick($event, accreditation)" mat-row
            *matRowDef="let accreditation; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
