<form class="asset-category-selection-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Asset category</mat-label>
    <mat-select [formControl]="formControl" placeholder="Select asset category..." [compareWith]="compareWithFn" [disableControl]="disabled">
      @for (assetCategory of assetCategories; track assetCategory) {
        <mat-option [value]="assetCategory">
          {{assetCategory.value}}
        </mat-option>
      }
    </mat-select>
    @if (formControl.touched && formControl.hasError('required')) {
      <mat-error>This field is required.</mat-error>
    }
  </mat-form-field>
</form>
