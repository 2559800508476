<div class="application-stepper2-component">
  @if (showHeader) {
    <header fxLayout="column">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </header>
  }
  <ng-content select="[stepper-header]"></ng-content>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </mat-card-content>
  </mat-card>
  <footer fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutGap="10px"
          fxLayoutAlign.lt-md="center start">
    @if (isPreviousButtonVisible()) {
      <button color="primary"
              class="w-lt-md-100"
              mat-stroked-button
              (click)="invokePreviousStepFn()">
        {{getPreviousButtonText()}}
      </button>
    }
    @if (isSecondarySubmitButtonVisible()) {
      <button color="primary"
        class="w-lt-md-100"
        type="submit"
        (click)="invokeSecondaryButtonFn()"
        mat-stroked-button>
        {{getSecondaryButtonText()}}
      </button>
    }
    @if (isNextButtonVisible()) {
      <button color="primary"
              class="w-lt-md-100"
              type="submit"
              (click)="invokeNextStepFn()"
              mat-flat-button>
        {{getNextButtonText()}}
      </button>
    }
  </footer>
</div>
