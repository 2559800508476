import {Component, OnInit} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {OriginatorBusiness, OriginatorListSortType, DEFAULT_LIMIT, DEFAULT_OFFSET} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PortalHotToastService,CustomContentLoaderComponent} from '@portal-workspace/grow-ui-library';
import {AdminService} from '../../service/admin.service';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Sort, MatSortModule } from '@angular/material/sort';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {navigationUrlForCompanyDetail} from '../../service/navigation-urls';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe } from '@angular/common';


export class InternalDataSource extends DataSource<OriginatorBusiness> {

  subject = new BehaviorSubject<OriginatorBusiness[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<OriginatorBusiness[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(originator: OriginatorBusiness[]) {
    this.subject.next(originator);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './companies.page.html',
    styleUrls: ['./companies.page.scss'],
    standalone: true,
    imports: [MatFormFieldModule, FlexModule, MatInputModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe]
})
export class CompaniesPage implements OnInit {

  loader = loadingFor('tableLoading');
  store = createAsyncStore();
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }

  subscriptions: Subscription[] = [];

  dataSource = new InternalDataSource();

  displayColumns = ['ID', 'companyName', 'abn', 'type', 'salesforceId'];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = 0;
  filter = '';
  sorts: OriginatorListSortType = null;

  formControlSearch: FormControl<string | null>;


  constructor(private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private router: Router,
              private adminService: AdminService) {
    this.formControlSearch = this.formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = [{prop: $event.active, dir: $event.direction}] as OriginatorListSortType;
    } else {
      this.sorts = null;
    }
    this.reset();
    this.reload();
  }

  private reload() {
    this.store = createAsyncStore();
    const sub = this.adminService.getOriginatorsList({
      page: {
        limit: this.limit, offset: this.offset,
      },
      filter: this.filter,
      sorts: this.sorts,
    }).pipe(
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        this.total = r.total;
        this.dataSource.update(r.payload);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  async onEdit($event: MouseEvent, element: OriginatorBusiness) {
    await this.router.navigate(navigationUrlForCompanyDetail(element.OriginatorBusinessId))
  }

  async onRowClicked($event: MouseEvent, element: OriginatorBusiness) {
    await this.router.navigate(navigationUrlForCompanyDetail(element.OriginatorBusinessId))
  }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = 0;
  }

}
