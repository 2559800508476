<div class="digital-id-user-verification-page container-fluid">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class=" mat-h3 text-align-center circular-icon">!</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      @for (alert of alerts; track alert) {
        <message-box title="Alert" type="danger">
          {{alert}}
        </message-box>
      }
      @for (message of messages; track message) {
        <message-box title="Info" type="info">
          {{message}}
        </message-box>
      }
    </div>
  </div>

  @if (checkResult) {
    <div class="row">
      <div class="col-12 text-align-center mb-4">
        We are unable to verify you and further verification is required. Dynamoney has partnered with AUSPOST Digital iD to
        complete this process. You will be redirected to Digital iD for further verification. This process is secured and
        handled by Digital iD.
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-align-center">
        <ul class="list-unstyled">
          <li>{{checkResult.firstName}} {{checkResult.lastName}} ({{checkResult.email}})</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-align-center">
        @if (allowDigitalIdVerification) {
          <app-digital-id
            [type]="'basic'"
            [uxMode]="'redirect'"
            [token]="token"
            [getClientFn]="getClientFn"
            [authenticateFn]="authenticateFn"
            [verificationSessionToken]="verificationSessionToken"
            (events)="onDigitalIdEvent($event)">
          </app-digital-id>
        }
      </div>
    </div>
  }

  @if (doingVerification) {
    <div class="row">
      <div class="col-12">
        <message-box>Trying to show verification result ...</message-box>
      </div>
    </div>
  }

  @if (verificationResult) {
    <div class="row">
      <div class="col-12">
        @if (verificationResult.status === 'RECEIVED_ERROR') {
          <!-- error -->
          <table class="mat-table width-100">
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">status</td>
              <td class="mat-cell cdk-cell">{{verificationResult.status}}</td>
            </tr>
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">Creation Date</td>
              <td class="mat-cell cdk-cell">{{verificationResult.creationDate}}</td>
            </tr>
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">Last Update</td>
              <td class="mat-cell cdk-cell">{{verificationResult.lastUpdate}}</td>
            </tr>
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">Error</td>
              <td class="mat-cell cdk-cell">{{verificationResult.error}}</td>
            </tr>
          </table>
        }
        @if (verificationResult.status !== 'RECEIVED_ERROR') {
          <!-- success -->
          <table class="mat-table width-100">
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">status</td>
              <td class="mat-cell cdk-cell">{{verificationResult.status}}</td>
            </tr>
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">Creation Date</td>
              <td class="mat-cell cdk-cell">{{verificationResult.creationDate}}</td>
            </tr>
            <tr class="mat-header-row cdk-header-row">
              <td class="mat-header-cell cdk-header-cell">Last Update</td>
              <td class="mat-cell cdk-cell">{{verificationResult.lastUpdate}}</td>
            </tr>
            @if (verificationResult.digitalIdResponse.verification_status) {
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">Verification Status</td>
                @if (verificationResult.digitalIdResponse) {
                  <td class="mat-cell cdk-cell">{{verificationResult.digitalIdResponse.verification_status}}</td>
                }
              </tr>
            }
            @if (verificationResult.digitalIdResponse.verification_session_token) {
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">Verification Session Token</td>
                @if (verificationResult.digitalIdResponse) {
                  <td class="mat-cell cdk-cell">{{verificationResult.digitalIdResponse.verification_session_token}}</td>
                }
              </tr>
            }
            @if (verificationResult.digitalIdResponse.data_source_events) {
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">Data Source Events</td>
                @if (verificationResult.digitalIdResponse) {
                  <td class="mat-cell cdk-cell">{{verificationResult.digitalIdResponse.data_source_events}}</td>
                }
              </tr>
            }
            @if (verificationResult.digitalIdResponse.transaction_id) {
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">Transaction Id</td>
                <td class="mat-cell cdk-cell">{{verificationResult.digitalIdResponse.transaction_id}}</td>
              </tr>
            }
            @if (verificationResult.digitalIdResponse.watchlist) {
              <tr class="mat-header-row cdk-header-row">
                <td class="mat-header-cell cdk-header-cell">Watchlist</td>
                <td calss="mat-cell cdk-cell">
                  <ul>
                    <li>{{verificationResult.digitalIdResponse.watchlist.sources_category}}</li>
                    <li>{{verificationResult.digitalIdResponse.watchlist.check_performed}}</li>
                    <li>{{verificationResult.digitalIdResponse.watchlist.check_performed_date}}</li>
                    @for (foundSource of verificationResult.digitalIdResponse.watchlist.found_sources; track foundSource) {
                      <ul>
                        <li>{{foundSource?.name}} - {{foundSource?.category}}</li>
                      </ul>
                    }
                  </ul>
                </td>
              </tr>
            }
          </table>
        }
      </div>
    </div>
  }
</div>
