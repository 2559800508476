

export const fieldErrorMessages = {
  email: 'Email is invalid',
  required: 'This field is required',
  requiredToBeTrue: 'This field must be Yes / True',
  confirmPassword: 'Password and confirmed password do not match',
  productSelected: 'A product selection is required',
  uploadFile: (c: {maxFiles: number, total: number}) => `Upload a maximum of ${c.maxFiles} files only, delete ${c.total - c.maxFiles} of the file(s)`,
  uploadMaximumFile: (c: {maxFiles: number, total: number}) => `Upload a maximum of ${c.maxFiles} files only, delete ${c.total - c.maxFiles} of the file(s)`,
  uploadMinFileSize: (c: {filename: string, minFileSize: number}) => `Upload file ${c.filename} exceeded a minimun file size of ${c.minFileSize == 0 ? 1 : c.minFileSize} bytes`,
  inputMask: 'Invalid format',
  acn: 'Only entries with ACN is allowed',
  invalidAbn: 'Invalid ABN',
  companyOnly: 'Only company is allowed',
  pattern: 'Invalid pattern',
  invalidDateFormat: 'Invalid date format',
  lessThan18YearsOld: 'Age is less than 18 years old',
  greaterThan100YearsOld: 'Age is greater than 100 years old',
  EmailExist:'Email already exists',
  minmax: (n: {min: number, max: number, actual: number}) => `Value needs to be between ${n.min} and ${n.max}`,
  min: (n: {min: number, actual: number})=>`Minimum of ${n.min} is required`,
  max: (n: {max: number, actual: number}) => `Maximum of ${n.max} is required`,
  businessSearch: `Please select a business from dropdown`,
  businessSearchOrEnter: `Please select or key in followed by enter a business`,
  businessNumberSearch: `Please enter a valid ACN or ABN`,
  aggregatorSearch: `Please select from dropdown`,
  invalidLength: `Please check VIN must be ' 17 ' characters `,
  invalidChars: `Please check VIN `,
  invalidHexColor: `Please enter valid color in hex format eg. #FFFFFF`,
  invalidHexOrRgbColor: `Please enter valid color in hex #FFFFFF or RGB rgb(255,255,255) or RGBA rgba(255,255,255,0.9)`,
  invalidUrl: `Please enter valid URL`,
  invalidRego: `Rego must not be longer than 10 characters`,
  vinRequired: `VIN is required `,
  valuesNotUnique: `VIN must be different than other assets`,
  invalidAutocompleteObject: 'Please select a value from dropdown',
  currentYear: `Please enter the valid year`,
  invalidBusiness: `Please enter a valid name as special characters are not allowed`,
  mustBeTodayOrFutureDate: `Please enter a valid (today or future) date`,
  minDate: (n: {min: string}) =>  `Needs to be greater than or equal to ${n.min}`,
  maxDate: (n: {max: string}) => `Needs to be less than or equal to ${n.max}`,
  supplierSearch: `Select supplier from list`,
  requiredDocDrawdown:`Please submit the draw down request with all relevant attachments`,
  duplicateIndividualEmail: `Email already exists for another individual`,
  duplicateDirectorEmail: (n: {details: string[]}) => `Emails [${(n.details ?? []).join(', ')}] exists for multiple directors`,
  duplicateGuarantorEmail: (n: {details: string[]}) => `Emails [${(n.details ?? []).join(', ')}] exists for multiple guarantors`,
  uploadDuplicateFile: (n: {filename: string}): string => `File ${n.filename} was uploaded before`,
  fileMissingTag: (n: {filename: string}): string => `The following files are missing tags: ${n.filename}`,
  duplicateIndividualMobile: `Mobile already exists for another individual`,
  trustForTrustee: `Trust cannot be a trustee`,
  partnerWithNoAcn: `Please select a company having ACN`,
  validBillerCode: `Please enter valid biller code`,
  invalidPhone: `Please add a valid phone number`,
  noSpace: `Field cannot be left blank`,
  minlength: (n: {requiredLength: number, actualLength: number}) => `Minimum ${n.requiredLength} characters are allowed for this field`,
  maxlength: (n: {requiredLength: number, actualLength: number}) => `Maximum ${n.requiredLength} characters are allowed for this field`,
  noGuarantors:'At least one individual guarantor is required.',
  whitelabelTitle: 'Whitelabel title already exists',
};
