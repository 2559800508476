<div class="credit-rule-item-dialog">
  <mat-dialog-content>
    <h3 class="header">Credit Rule Item</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input [formControl]="formControlName" matInput placeholder="Enter credit rule name" type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput rows="5" wrap="hard" maxlength="3000" [formControl]="formControlDescription"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-checkbox [formControl]="formControlUseDefaultValue">Use a default value when this item generates an error</mat-checkbox>
      </div>
    </div>
    @if (formControlUseDefaultValue.value) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Default Value</mat-label>
            <input [formControl]="formControlDefaultValue" matInput placeholder="Default Value" type="text">
          </mat-form-field>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Mapping Type</mat-label>
          <mat-select [formControl]="formControlType">
            @for (option of CreditRuleItemTypeOptions; track option) {
              <mat-option [value]="option">{{option}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Output Type</mat-label>
          <mat-select [formControl]="formControlOutputType">
            @for (option of CreditRuleItemOutputTypeOptions; track option) {
              <mat-option [value]="option">{{option}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
   
    <div class="row">
      @if (formControlType.value === 'JSON' || formControlType.value === 'Database' || formControlType.value === 'Inteflow Database') {
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Function</mat-label>
            <mat-select [formControl]="formControlFunction">
              @for (option of CreditRuleItemFunctionOptions; track option) {
                <mat-option [value]="option">{{option}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (formControlType.value === 'JSON') {
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Level</mat-label>
            <mat-select [formControl]="formControlLevel">
              @for (option of CreditRuleItemLevelOptions; track option) {
                <mat-option [value]="option">{{option}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
    </div>

    <mat-divider></mat-divider>
    
    <div class="row">
      <div class="col-12">
        <!-- <mat-form-field>
          <mat-label>Value</mat-label>
          <textarea matInput rows="5" wrap="hard" maxlength="3000" [formControl]="formControlValue"></textarea>
        </mat-form-field> -->
        <credit-rule 
          [formControl]="formControlValue" 
          [getCreditRuleItemsFn]="getCreditRuleItemsFn"
          [creditRuleType]="formControlType.value ?? 'Formula'"
        ></credit-rule>
      </div>
    </div>

    <mat-divider class="mb-4"></mat-divider>

    @if (formControlType.value === 'JSON') {
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sample {{formControlLevel.value}} Object
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Evaluate Sample Object</mat-label>
                <textarea matInput [formControl]="formControlSampleObjectEvaluation" rows="8"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <button mat-flat-button color="primary" class="inactive w-lt-md-100" (click)="evaluateSampleObject()">Evaluate</button>
                <button mat-flat-button class="active w-lt-md-100" (click)="restoreSampleObject()">Restore</button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <ngx-json-viewer [json]="displayedSampleObject" [expanded]="false"></ngx-json-viewer>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="row mb-4"></div>
    }

    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onCancel()" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit()" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
