<div class="direct-sale-page container">
  <div class="row mt-2">
    <div class="col">
      <message-box-2 type="info" title="Start your finance application now">
        It’s easy to apply online and only takes a few minutes. Should you need assistance to choose the right application type for your business needs, please give us a call on 1300 001 420.
      </message-box-2>
    </div>
  </div>

  <application-tiles [enableTiles]="environment.directSaleSubmission" (clickedUrl)="navigationHandler($event)"></application-tiles>
</div>
