/**
 * NOTE: source code are from
 *  - wildcard - https://www.npmjs.com/package/wildcard
 *  - mime-match - https://www.npmjs.com/package/mime-match
 *
 * We are just moving the essential bits that we use here
 * so that they are typed and ESM compliance.
 */
const REGEXP_PARTS = /(\*|\?)/g;

export class WildcardMatcher {
  private text: string;
  private separator: RegExp;
  private hasWild: boolean;
  private parts: (string | RegExp)[] = [];

  constructor(pattern: string, separator: RegExp) {
    this.text = pattern;
    this.separator = separator;
    this.hasWild = pattern.indexOf('*') >= 0;
    this.parts =  pattern.split(separator).map(this.classifyPart);
  }

  public match(input: string) {
    let matches = true;
    let parts = this.parts;
    let partsCount = parts.length;
    let testParts;

    if (!this.hasWild && this.text != input) {
      matches = false;
    } else {
      testParts = (input || '').split(this.separator);
      for (let ii = 0; matches && ii < partsCount; ii++) {
        if (parts[ii] === '*')  {
          continue;
        } else if (ii < testParts.length) {
          matches = parts[ii] instanceof RegExp
            ? (parts[ii] as RegExp).test(testParts[ii])
            : parts[ii] === testParts[ii];
        } else {
          matches = false;
        }
      }
    }

    return matches;
  }

  classifyPart(part: string) {
    // in the event that we have been provided a part that is not just a wildcard
    // then turn this into a regular expression for matching purposes
    if (part === '*') {
      return part;
    } else if (part.indexOf('*') >= 0 || part.indexOf('?') >= 0) {
      return new RegExp(part.replace(REGEXP_PARTS, '\.$1'));
    }
    return part;
  }
}

export const mimeMatch = (targetMimeType: string, mimeTypeFormat: string) => {
  return new WildcardMatcher(mimeTypeFormat, /\//).match(
    (targetMimeType ?? '').split(';')[0]);
}
