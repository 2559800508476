import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { httpOptions, paginationUrl, toPaginationInfo, } from '@portal-workspace/grow-ui-library';
import { GetCustomersFn} from '@portal-workspace/grow-shared-library';
import {
  SearchedCustomer,
  PaginationInfo, SortTypes,
} from '@portal-workspace/grow-shared-library';
import {DEFAULT_FILTER, DEFAULT_LIMIT, DEFAULT_OFFSET} from '@portal-workspace/grow-shared-library';
import {Observable} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ApiResponse,
  PaginablePayloadApiResponse, PayloadApiResponse,
} from '@portal-workspace/grow-shared-library';
import moment from 'moment';



const URL_GET_CUSTOMERS = (paginationInfo: PaginationInfo)=>paginationUrl(`${environment.api2Host}/api2/all-customers-list`, paginationInfo);
const URL_REPORT_BANKING_TRANSACTION = () => `${environment.api2Host}/api2/reporting/banking-transactions`;
const URL_REPORT_BROKERAGE_TRANSACTION = () => `${environment.api2Host}/api2/reporting/brokerage-transactions`;


@Injectable()
export class TradeAdminService {


  constructor(private httpClient: HttpClient) {
  }

  getReportForBankingTransactions(startDate?: string , endDate?: string): Observable<Blob> {
    const options = {...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json'}
    return this.httpClient.post<Blob>(URL_REPORT_BANKING_TRANSACTION(), {
      startDate : startDate,
      endDate : endDate
    },options);
  }

  getReportForBrokerageTransactions(startDate?: string , endDate?: string): Observable<Blob> {
    const options = {...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json'}
    return this.httpClient.post<Blob>(URL_REPORT_BROKERAGE_TRANSACTION(), {
      startDate : startDate,
      endDate : endDate
    },options);
  }

  // marginFee / overDueMarginFee
  calculateMarginFee(invoiceAmount: number, ManagementFee: number, TermCharge: number, terms: number) {
    const _invoiceAmount = Number(invoiceAmount);
    const _managementFee = Number(ManagementFee);
    const _termCharge = Number(TermCharge);
    const _terms = Number(terms);
    let fee = 0;
    const principal = _invoiceAmount;
    const convertedManagementFee = _managementFee / 100;
    const convertedTermCharge = _termCharge / 100;
    const termsCount = _terms / 30;
    fee = principal * (convertedTermCharge * termsCount + convertedManagementFee);
    return fee;
  }

  backCalculateTermCharge(invoiceAmount: number, managementCharge: number, marginFee: number, terms: number) {
    const _invoiceAmount = Number(invoiceAmount);
    const _managementCharge = Number(managementCharge);
    const _marginFee = Number(marginFee);
    const _terms = Number(terms);
    let r = 0;
    const principal = _invoiceAmount;
    const convertedManagementCharge = _managementCharge / 100;
    const termsCount = _terms / 30;
    r = (((principal + _marginFee) * 100 / principal / 100)  - 1 - convertedManagementCharge) / termsCount * 100;
    return r;
  }

  getAllCustomersFn: GetCustomersFn = (opts) => {
    return this.getAllCustomers(opts);
  }

  getAllCustomers(opts: {
    page: { offset: number, limit: number, },
    filter: string,
    sorts?: { prop: 'SalesforceId' | 'ABN' | 'ACN' | 'EntityName' | '', dir: 'ASC' | 'DESC' }[],
  } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, filter: DEFAULT_FILTER, sorts: undefined}): Observable<PaginablePayloadApiResponse<SearchedCustomer>> {
    return this.httpClient.post<PaginablePayloadApiResponse<SearchedCustomer>>(URL_GET_CUSTOMERS(toPaginationInfo(opts)), {}, httpOptions());
  }

}
