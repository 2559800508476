<div class="privacy-consent-page container">
  @if (!privacyConsent) {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Invalid link">
          <div>Please use a valid link to accept your privacy consent</div>
        </message-box>
      </div>
    </div>
  }

  @if (privacyConsent) {
    @if (privacyConsent.status === 'accepted') {
      <div class="row separator-bottom">
        <div class="col-lg-9 col-md-12">
          <message-box type="success" title="Thank you for accepting the Dynamoney Privacy Consent">
          </message-box>
        </div>
      </div>
    }
  }
</div>
