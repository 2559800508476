import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AssetSupplier, compareMatch} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {UntilDestroy} from '@ngneat/until-destroy';
import {mustSelectFromSelectOptionValidator, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { AssetSupplierSelectValue } from '@portal-workspace/grow-shared-library';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

export type GetAssetSupplierFn = (supplierName: string)=> Observable<AssetSupplier[]>;


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-supplier-select',
    templateUrl: './asset-supplier-select.component.html',
    styleUrls: ['./asset-supplier-select.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetSupplierSelectComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetSupplierSelectComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule, MatProgressSpinnerModule, DisableControlDirective]
})
export class AssetSupplierSelectComponent extends AbstractControlValueAccessor<AssetSupplierSelectValue | AssetSupplier[]> implements  OnInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: true}) title?: string;
  @Input({required: true}) getAssetSupplierFn!: GetAssetSupplierFn;

  suppliers: AssetSupplier[] = [];
  selected=false;
  searchError = false;
  loading = false;

  formGroup!: FormGroup<{
    supplier: FormControl<AssetSupplierSelectValue | AssetSupplier[]>
  }>;
  formControl!: FormControl<AssetSupplierSelectValue | AssetSupplier[]>;

  displayWithFn: (value?: AssetSupplier | string | null)=>string = (value) => {
    if (typeof value == 'string') {
      return value;
    }
    return value?.SupplierName ?? '';
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, [Validators.required, mustSelectFromSelectOptionValidator()]);
    this.formGroup = this.formBuilder.group({
      supplier: this.formControl
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (v) {
          if (typeof v == 'string') {
            this.selected = false;
            this.loading = false;
            this.subscriptions.push(this.getAssetSupplierFn(v).pipe(
              tap(assetSuppliers => {
                console.log("hheer",assetSuppliers)
                this.loading = true;
                this.suppliers = (assetSuppliers ?? []);
                if(assetSuppliers.length<=0){
                  console.log("hheer")
                  this.searchError = true;
                  this.loading = false;
                }
              })
            ).subscribe());
          } else { // will be AssetSupplier
            this.propagateChange(v);
            // this.loading = true;
          }

        }
      })
    ).subscribe());
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formGroup.disable();
  //     this.formControl.disable();
  //   } else {
  //     this.formGroup.enable();
  //     this.formControl.enable();
  //   }
  // }

  doWriteValue(v: AssetSupplierSelectValue | null | undefined): void | AssetSupplierSelectValue {
    if (v && v.SupplierName) { // todo also search by id
      this.subscriptions.push(this.getAssetSupplierFn(v.SupplierName).pipe(
        tap((assetSuppliers: AssetSupplier[]) => {
          const assetSupplierFound = (assetSuppliers ?? []).find(as => as.AssetSupplierId == v.AssetSupplierId);
          if (assetSupplierFound) {
            this.formControl.setValue(assetSupplierFound);
          }
        })
      ).subscribe());
    }
    return undefined;
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.selected  = true;
    this.searchError = false;
    const assetSupplier: AssetSupplier = $event.option.value;
  }
}
