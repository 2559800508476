<div class="notifications-management-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Notifications</div>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="separator-bottom">
        <div class="row mb-4 ml-0">
          <span class="mat-body-strong">
            Send message to
          </span>
        </div>
        <div class="row separator">
          <div class="col-lg-6 col-md-12">
            <mat-radio-group [formControl]="formControlSendTo" fxLayout="column" fxLayoutAlign="center start"
              fxLayoutGap="10px">
              <mat-radio-button color="primary" value="all" [checked]="true">All</mat-radio-button>
              <mat-radio-button color="primary" value="user">Specific User</mat-radio-button>
              <mat-radio-button color="primary" value="company">Specific Company</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        @if (formControlSendTo.value === 'user') {
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <user-list [getFn]="usersGetFn" [title]="userTitle"  [formControl]="formControlUser"></user-list>
            </div>
          </div>
        }
        @if (formControlSendTo.value === 'company') {
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <originator-list [getFn]="originatorsGetFn" [title]="companyTitle"  [formControl]="formControlCompany">
              </originator-list>
            </div>
          </div>
        }
        <mat-divider [inset]="true"></mat-divider>
      </div>
      <div class="separator-bottom">
        <div class="row mb-4 ml-0">
          <span class="mat-body-strong">
            Message
          </span>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Notification title</mat-label>
              <input type="text" matInput [formControl]="formControlTitle" placeholder="Add title">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Message</mat-label>
              <textarea matInput [formControl]="formControlMessage" placeholder="Enter message"></textarea>
            </mat-form-field>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      </div>

      <div class="row">
        <div class="col-12"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="10px"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-md="center start">
          <button mat-stroked-button color="primary" class="w-lt-md-100" (click)="onCancel()">Cancel</button>
          <button mat-flat-button color="primary" class="w-lt-md-100" [disabled]="formGroup.invalid || isLoading==true"
          (click)="sendNotification($event)">{{isLoading?"Sending Message...":"Send Message"}}</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
