import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import _ from "lodash";
import { navigationUrlForAssetProductSelectorWithQueryParams } from "../service/navigation-urls";
import {isValidAssetProductType, isValidBoolean} from "@portal-workspace/grow-ui-library";
import {Application} from "@portal-workspace/grow-shared-library";


export const assetFinanceProductTypeGuard = (): CanActivateFn => {
  return (
    snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ) => {
    const router = inject(Router);
    const queryParams = snapshot.queryParams;
    const productType = queryParams.productType;
    const isSwitchToEasyDoc = queryParams.isSwitchToEasyDoc;

    if ((_.isNil(productType)) ||
        (_.isNil(isSwitchToEasyDoc)) ||
        (!isValidAssetProductType(productType)) ||
        (!isValidBoolean(isSwitchToEasyDoc))
    ) {
      const application: Application | null | undefined = snapshot.data.application;
      const applicationId = application ? application.ApplicationId : undefined;
      const url = navigationUrlForAssetProductSelectorWithQueryParams(router, applicationId, queryParams);
      return router.navigateByUrl(url);
    }

    return true;
  }
}

