import {Component, OnInit} from '@angular/core';
import {CollectionViewer, DataSource, SelectionModel} from '@angular/cdk/collections';
import {
  User,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  UserWithOriginatorBusinessAndCustomerEntityNames,
  PortalLoginUser,
  isSalesAM,
  isSalesBDM,
} from '@portal-workspace/grow-shared-library';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Router} from '@angular/router';
import {getUser, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {AuthService} from '../../service/auth.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {navigationUrlForPendingApprovalUserDetail, navigationUrlForUserDetail} from '../../service/navigation-urls';
import { Sort, MatSortModule } from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe } from '@angular/common';
import {FlexModule} from "@angular/flex-layout";

export class InternalDataSource extends DataSource<UserWithOriginatorBusinessAndCustomerEntityNames> {

  subject = new BehaviorSubject<UserWithOriginatorBusinessAndCustomerEntityNames[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<UserWithOriginatorBusinessAndCustomerEntityNames[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(users: UserWithOriginatorBusinessAndCustomerEntityNames[]) {
    this.subject.next(users);
  }

}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './pending-approvals.page.html',
    styleUrls: ['./pending-approvals.page.scss'],
    standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe, FlexModule]
})
export class PendingApprovalsPage implements OnInit {
  loggedInUser: PortalLoginUser | null = getUser();
  isSalesAM = isSalesAM(this.loggedInUser)
  isSalesBDM = isSalesBDM(this.loggedInUser)
  loader = loadingFor('tableLoading');
  store = createAsyncStore();
  // showError = false
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }

  subscriptions: Subscription[] = [];

  dataSource = new InternalDataSource();
  // displayColumns = ['email', 'name', 'phoneNumber', 'actions'];
  displayColumns = ['userId', 'name', 'email', 'mobileNumber', 'companyName', 'entityName', 'actions'];

  formControlSearch: FormControl<string | null>;
  formControlOnlyRejectedEntries: FormControl<boolean | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  sorts: {prop: string, dir: 'asc' | 'desc'} | null = null;
  onlyRejectedEntries = false;

  selection = new SelectionModel<User>(true, []);

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private applicationDialogService: ApplicationDialogService,
              private toastService: PortalHotToastService,
              private authService: AuthService) {
    this.formControlSearch = formBuilder.control(null);
    this.formControlOnlyRejectedEntries = formBuilder.control(false);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
    this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlOnlyRejectedEntries.valueChanges.pipe(
      tap(r => {
        this.selection.clear();
        this.onlyRejectedEntries = r ?? false;
        this.reset();
        this.reload();
      })
    ).subscribe());
  }


  private reload() {
    this.store = createAsyncStore();
    this.subscriptions.push(this.authService.getUnapprovedUsers({
      onlyRejected: this.onlyRejectedEntries,
      page: {
        limit: this.limit,  offset: this.offset,
      },
      filter: this.filter,
      sorts: this.sorts ? [this.sorts] : []
    }).pipe(
      // this.toastService.toastObserver('loading pending users'),
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        this.total = r.total;
        const users = r.payload;
        this.dataSource.update(users);
      })
    ).subscribe());
  }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  async onEdit($event: Event, element: User) {
    await this.router.navigate(navigationUrlForPendingApprovalUserDetail(element.UserId));
  }

  onRowClicked($event: Event, element: User) {
  if(this.isSalesAM || this.isSalesBDM){
    if(element.AccessLevel !== 'externalbroker' && element.AccessLevel !== 'companyadmin' && element.AccessLevel !== 'companyoperator' && element.AccessLevel !== 'salesAM' && element.AccessLevel !== 'salesBDM'){
      this.applicationDialogService.openAlertDialog({
        message: `Invalid Access`,
        subMessage: `You do not have this access.`,
      }).afterClosed().pipe(
        tap()
      ).subscribe();
    }else{
      this.router.navigate(navigationUrlForUserDetail(element.UserId))
  }
}else{
  this.router.navigate(navigationUrlForUserDetail(element.UserId))
}
}

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = {prop: $event.active, dir: $event.direction};
    } else {
      this.sorts = null;
    }
    this.reset();
    this.reload();
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  validateSelectedUsers() {
    const selectedUsers = this.selection.selected as User[];
    for (let user of selectedUsers) {
      // users must have first name, given name, company name, email, and access level
      // if (!(user.FamilyName && user.GivenName && (user.AggregatorId || user.OriginatorBusinessId) && user.Email && user.AccessLevel)) {
      if (!(user.FamilyName && user.GivenName && (user.OriginatorBusinessId) && user.Email && user.AccessLevel)) {
       return false;
      }
    }
    return true;
  }

  approveSelectedUsers() {
    if (this.validateSelectedUsers()) {
      if (this.isSalesAM || this.isSalesBDM) {
        const selectedUserIds = this.selection.selected.map(user => user.AccessLevel);
        const unauthorizedUserIds = selectedUserIds.filter(accessLevel =>
          accessLevel !== 'externalbroker' && accessLevel !== 'companyadmin' && accessLevel !== 'companyoperator' && accessLevel !== 'salesAM' && accessLevel !== 'salesBDM'
        );
        if (unauthorizedUserIds.length > 0) {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'You do not have access'
          });
        }else{
          this.approveUser()
        }
      } else {
        this.approveUser();
      }
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Please confirm your selected users have all their details entered.'
      });
    }
  }

  approveUser(){
    const approveUserObservables: Observable<any>[] = [];
    const selectedUserIds = this.selection.selected.map(user => user.UserId);
    if (selectedUserIds.length > 0) {
      selectedUserIds.forEach(userId => {
        approveUserObservables.push(this.authService.approveOrRejectUser(userId, true))
      });
      this.subscriptions.push(
        combineLatest(approveUserObservables).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.retryableMessage({
            successMessage: 'Users Approved',
            errorMessage: 'Failed to approve the Users',
            retryFn: () => {
              this.approveSelectedUsers();
            }
          }),
          tap(r => {
            this.formControlSearch.setValue(null)
            this.selection.clear();
            this.reload();
          })
        ).subscribe()
      );
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Please select the user.'
      });
    }
  }

  rejectSelectedUsers() {
    if (this.isSalesAM || this.isSalesBDM) {
      const selectedUserIds = this.selection.selected.map(user => user.AccessLevel);
      const unauthorizedUserIds = selectedUserIds.filter(accessLevel =>
        accessLevel !== 'externalbroker' && accessLevel !== 'companyadmin' && accessLevel !== 'companyoperator' && accessLevel !== 'salesAM' && accessLevel !== 'salesBDM'
      );
      if (unauthorizedUserIds.length > 0) {
        this.applicationDialogService.openAlertDialog({
          message: 'Error',
          subMessage: 'You do not have access'
        });
      }else{
        this.rejectUser()
      }
    } else {
      this.rejectUser();
    }
  }

  rejectUser() {
    const rejectUserObservables: Observable<any>[] = [];
    const selectedUserIds = this.selection.selected.map(user => user.UserId);
    if (selectedUserIds.length > 0) {
      selectedUserIds.forEach(userId => {
        rejectUserObservables.push(this.authService.approveOrRejectUser(userId, false))
      });
      this.subscriptions.push(
        combineLatest(rejectUserObservables).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.retryableMessage({
            successMessage: 'Users Rejected',
            errorMessage: 'Failed to reject the Users',
            retryFn: () => {
              console.log('**** retry ', this);
              this.rejectSelectedUsers();
            }
          }),
          tap(r => {
            this.formControlSearch.setValue(null)
            this.selection.clear();
            this.reload();
          })
        ).subscribe()
      );
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Please select the user.'
      });
    }
  }

  toggleAllSelection() {
    const users = this.dataSource.subject.getValue();
    for (const user of users) {
      this.selection.toggle(user);
    }
  }
}
