import {Injectable} from '@angular/core';
import {PaginablePayloadApiResponse} from '@portal-workspace/grow-shared-library';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import {ApplicationAuditLog} from '@portal-workspace/grow-shared-library';
import {
  GetApplicationAuditLogsFn
} from '@portal-workspace/grow-ui-library';
import {map} from 'rxjs/operators';


const URL_GET_APPLICATION_AUDIT_LOGS = (limit: number = 20, offset: number = 0,applicationId: number) => `${environment.api2Host}/api2/application-audit-logs/${applicationId}?limit=${limit}&offset=${offset}`;   // get

@Injectable()
export class ApplicationAuditService {

  constructor(private httpClient: HttpClient) {
  }


  getApplicationAuditLogs(limit: number, offset: number, applicationId: number): Observable<PaginablePayloadApiResponse<ApplicationAuditLog>> {
    return this.httpClient.get<PaginablePayloadApiResponse<ApplicationAuditLog>>(URL_GET_APPLICATION_AUDIT_LOGS(limit, offset,applicationId), httpOptions());
  }

  getApplicationAuditLogsFn: GetApplicationAuditLogsFn = (limit: number, offset: number, applicationId: number): Observable<PaginablePayloadApiResponse<ApplicationAuditLog>> => {
    return this.getApplicationAuditLogs(limit, offset, applicationId)
  }

}
