<div class="slider-component">
  <div fxLayout="column" fxLayoutGap="20px" [ngClass]="{'show-permanent-tick': showPermanentTick}">
    @if (title) {
      <div>{{title}} <span class="amount-label">: {{formControl.value}}{{titlePostfix}}</span></div>
    }
    <div>
      <mat-slider
        class="w-100"
        color="primary"
        [disabled]="disabled"
        [max]="max"
        [min]="min"
        [step]="step"
        [discrete]="true"
        [displayWith]="formatLabel">
        <input matSliderThumb [value]="value" (valueChange)="onValueChanged($event)" />
      </mat-slider>
    </div>
  </div>
</div>
