<div class="business-disbursement-payee-dialog">
  <mat-dialog-content>
    <h3 class="header">Please add a payee</h3>
    <div class="mat-subheading-4 mb-4">Select whether to add a creditor or use the current customer as the payee.</div>
    <div class="row">
      <div class="col-8">
        <yes-no [formControl]="formControlCreditorOrCustomer" title='' yesText="Creditor"
        noText="Customer"></yes-no>
      </div>
    </div>
    @if (!isCustomer) {
      <div class="row mb-3">
        <div class="col">
          <supplier2 title="Creditor" [searchSupplierFn]="searchSupplierFn" [formControl]="formControlSupplier"
            (events)="getSupplierData($event)" [mark]="markTriggerSubject(formGroup)">
          </supplier2>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
