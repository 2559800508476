<div class="credit-flow-details">
    <div class="row">
        <div class="col-12">
          <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
            <table mat-table [dataSource]="dataSource" class="width-100 mb-4">
              @for (column of columnsToDisplay; track column) {
                <ng-container matColumnDef="{{column}}">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{
                'column-width-3': column === 'alert',
                'column-width-2': column === 'calculation',
                'column-width-1': column !== 'calculation' && column !== 'alert',
              }"> 
                <div class="cell">{{getColumnTitles(column)}}</div> </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell">
                        @if (column === 'alertStatus') {
                          @if (element.alertStatus) {
                            <tag-box [state]="element.alertStatus"></tag-box>
                          }
                        }
                        @else {
                          {{element[column]}}
                        }
                      </div>
                    </td>
                  </ng-container>
                }
        
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                </tr>
            </table>

            @if (!dataSource.length) {
              <table>
                <tr>
                  <td class="not-found"> No credit flow details found</td>
                </tr>
              </table>
            }
          </div>
        </div>
    </div>
</div>
