<form class="nature-of-business-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Nature of business</mat-label>
    <mat-select  [formControl]="formControl" (selectionChange)="onSelectionChange($event)" (openedChange)="onOpenedChange($event)">
      <mat-option [value]="null">Select one ...</mat-option>
      @for (option of options; track option) {
        <mat-option [value]="option.id">
          {{option.name}}
        </mat-option>
      }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</form>
