import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription, tap} from 'rxjs';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {OverdraftCustomerSideMenuComponentMenuType, User} from '@portal-workspace/grow-shared-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';


export interface OverdraftCustomerSidemenuComponentEvent {
  type:
    'overdraft-customer-profile' |
    'overdraft-customer-notifications' |
    'overdraft-customer-dashboard' |
    'overdraft-customer-other-products' |
    'overdraft-customer-special-offers' |
    'overdraft-customer-contact-us',
}



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'overdraft-customer-sidemenu',
    templateUrl: './overdraft-customer-sidemenu.component.html',
    styleUrls: ['./overdraft-customer-sidemenu.component.scss'],
    standalone: true,
    imports: [FlexModule, NgClass, ExtendedModule]
})
export class OverdraftCustomerSidemenuComponent implements OnInit {
  @Output() menuTypeEvent: EventEmitter<OverdraftCustomerSideMenuComponentMenuType>;
  @Input({required: false}) user: User | null = null;
  @Output() events: EventEmitter<OverdraftCustomerSidemenuComponentEvent> = new EventEmitter<OverdraftCustomerSidemenuComponentEvent>();

  subscriptions: Subscription[] = [];

  menuItemId: OverdraftCustomerSidemenuComponentEvent['type'] |  OverdraftCustomerSideMenuComponentMenuType= 'overdraft-customer-dashboard';

  constructor(private route: ActivatedRoute,
              private router: Router) {
                this.menuTypeEvent = new EventEmitter<OverdraftCustomerSideMenuComponentMenuType>();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const routeSplits = this.router.url.split('/');
    if (routeSplits && routeSplits.length >= 3) {
      this.menuItemId = routeSplits[2] === 'dashboard' ? 'overdraft-customer-dashboard' : 'overdraft-customer-'+routeSplits[2] as OverdraftCustomerSidemenuComponentEvent['type'];
    }

    const sub = this.router.events.pipe(
      tap((r) => {
        if (r instanceof ActivationStart) {
          console.log('****', (r.snapshot.data));
          this.menuItemId = (r.snapshot.data as any).menuItemId;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }
  onMenuClick($event: Event, type: OverdraftCustomerSideMenuComponentMenuType) {
    this.menuItemId = type;
    this.menuTypeEvent.emit(type)
  }
  onMenuItemClicked($event: Event, eventType: OverdraftCustomerSidemenuComponentEvent['type']) {
    this.menuItemId = eventType
    this.events.emit({type: eventType});
  }
}
