import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'date-chip',
    templateUrl: './date-chip.component.html',
    styleUrls: ['./date-chip.component.scss'],
    standalone: true,
    imports: [FlexModule, DatePipe]
})
export class DateChipComponent implements OnInit {

  @Input({required: false}) colorTheme: string | 'green' | 'black' | 'red' = 'green';
  @Input({required: false}) title: string | null = null;
  @Input({required: false}) date: string | null = null;
  @Input({required: false}) dateFormat: string = 'dd/MM/YYYY'

  constructor() {
  }

  ngOnInit(): void {
  }

}
