import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, PaymentTermValue, PaymentTermValueOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'payment-terms-selection',
    templateUrl: './payment-terms-selection.component.html',
    styleUrls: ['./payment-terms-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PaymentTermsSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PaymentTermsSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})


export class PaymentTermsSelectionComponent extends AbstractControlValueAccessor<PaymentTermValue> implements OnInit, Mark{
  compareWithFn: (option: PaymentTermValue, selected: PaymentTermValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  formGroup: FormGroup<{
    selection:FormControl<PaymentTermValue>;
  }>;
  formControl: FormControl<PaymentTermValue>;
  subscription: Subscription;

  options = PaymentTermValueOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: PaymentTermValue) => {
        console.log('****** payment-terms-selection propagate change', r);
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }


  doWriteValue(v: PaymentTermValue): void | PaymentTermValue {
    if (v) {
      const op = this.options.find(o => o.type === v.type);
      if (op) {
        this.formControl.setValue(op);
        return op;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
