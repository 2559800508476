import { Component, Input } from "@angular/core";
import {DirectorValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { LiabilitiesSummaryComponent } from "./liabilities-summary.component";
import { AssetsSummaryComponent } from "./assets-summary.component";
import { MatDividerModule } from "@angular/material/divider";
import { CellComponent } from "./cell.component";

import { EllipsisPipe } from "../../pipes/ellipsis.pipe";


@Component({
    selector: 'directors-summary',
    template: `

@if (directors) {
  @for (director of directors; track director; let index = $index) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Director {{index + 1}}</div>
      </div>
    </div>
    <div class="row">
      @if (director.title) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{director.title.name}}</div>
          </cell>
        </div>
      }
      @if (director.firstName) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body><p [innerHTML]="director.firstName | ellipsis:10"></p></div>
          </cell>
        </div>
      }
      @if (director.lastName) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Last name</div>
            <div body><p [innerHTML]="director.lastName | ellipsis:10"></p></div>
          </cell>
        </div>
      }
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Gender</div>
          <div body>{{director.gender}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Date of birth</div>
          <div body>{{displayableDate(director.dob)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Residential address</div>
          <div body>{{director.residentialAddress?.address}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Privacy consent</div>
          <div body>{{booleanToYesNo(director.privacyConsentObtained)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    @if (director.type === 'WithoutPropertyAddress') {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Property Owner</div>
            <div body>{{booleanToYesNo(director.propertyOwner)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
    @if (director.propertyOwner && director.type === 'WithPropertyAddress') {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Property Owner</div>
            <div body>{{booleanToYesNo(director.propertyOwner.propertyOwner)}}</div>
          </cell>
        </div>
      </div>
      @if (director.propertyOwner && director.propertyOwner.propertyOwner) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Property address</div>
              <div body>{{director.propertyOwner.address?.address}}</div>
            </cell>
          </div>
        </div>
      }
      @if (director.propertyOwner && director.propertyOwner.propertyOwner) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property value</div>
              <div body>{{director.propertyOwner.propertyValue}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Mortgage value</div>
              <div body>{{director.propertyOwner.mortgageValue}}</div>
            </cell>
          </div>
        </div>
      }
      <mat-divider [inset]="true"></mat-divider>
    }
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Guarantor</div>
          <div body>{{booleanToYesNo(director.guarantor)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Email address</div>
          <div body>{{director.email}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Business landline</div>
          <div body>{{director.mobileNumber}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <assets [assets]="director.personalAssets"></assets>
    <liabilities-summary [liabilities]="director.personalLiabilities"></liabilities-summary>
  }
}


`,
    standalone: true,
    imports: [CellComponent, MatDividerModule, AssetsSummaryComponent, LiabilitiesSummaryComponent, EllipsisPipe]
})
export class DirectorsSummaryComponent {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) directors?: DirectorValue;

}
