import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {
  CreditAlert,
  CreditFlowNode,
  CreditFlowNodeType,
  CreditRuleItem,
  CreditRuleMapping,
  CreditRuleMappingAction,
  CreditRuleMappingActionOptions,
  CreditRuleMappingLevel,
  EditCreditRuleMappingDialogData, EditCreditRuleMappingDialogResult, GetCreditRuleItemsFn, processCreditRule, stringifyJSON, validateCreditRule,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from "@angular/material/expansion";
import { CreditRuleComponent } from '../credit-component/credit-rule.component';
import { ApplicationDialogService } from './application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
@Component({
    templateUrl: './edit-credit-rule-mapping.dialog.html',
    styleUrls: ['./edit-credit-rule-mapping.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatSelectModule, CreditRuleComponent, MatExpansionModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, MatDividerModule]
})
export class EditCreditRuleMappingDialog implements OnInit {
  creditRuleMapping: CreditRuleMapping | null = null;
  creditFlowNodeId!: number;
  nextNodeOptions: CreditFlowNode[] = [];
  nodeType!: CreditFlowNodeType;
  formControlName!: FormControl<string | null>;
  formControlLevel!: FormControl<CreditRuleMappingLevel | null>;
  formControlConditionVariable!: FormControl<number | null>;
  formControlRule!: FormControl<string | null>;
  formControlAlertId!: FormControl<number | null>;
  formControlNextNode!: FormControl<number | null>;
  formControlAction!: FormControl<CreditRuleMappingAction | null>;
  formGroup!: FormGroup<{
    name: FormControl<string | null>,
    level: FormControl<CreditRuleMappingLevel | null>,
    creditRule: FormControl<string | null>,
    alertId:  FormControl<number | null>,
    nextNode: FormControl<number | null>,
    conditionVariable: FormControl<number | null>,
    action: FormControl<CreditRuleMappingAction | null>,
  }> 
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  creditRuleItems: CreditRuleItem[] = [];
  creditRuleItemOptions: CreditRuleItem[] = [];
  creditRuleMappingActionOptions = CreditRuleMappingActionOptions;

  alertOptions: CreditAlert[] = []

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCreditRuleMappingDialogData,
              private dialogService: ApplicationDialogService,
              private toastService: PortalHotToastService,
              private dialogRef: MatDialogRef<EditCreditRuleMappingDialog, EditCreditRuleMappingDialogResult>,
              private formBuilder: FormBuilder) {
    this.creditRuleMapping = this.data.creditRuleMapping;
    this.creditFlowNodeId = this.data.creditFlowNodeId;
    this.getCreditRuleItemsFn = this.data.getCreditRuleItemsFn;
    this.nodeType = this.data.nodeType;
    this.nextNodeOptions = [{
      id: 0,
      name: 'No Next Node'
    } as any, ...this.data.nextNodeList.filter(node => node.id !== this.creditFlowNodeId)];
    this.alertOptions = [{
      id: 0,
      name: 'No Alert'
    } as any, ...this.data.creditAlerts];
    this.formControlName = this.formBuilder.control(null, [Validators.required]);
    this.formControlLevel = this.formBuilder.control(null, [Validators.required]);
    this.formControlRule = this.formBuilder.control(null);
    this.formControlAlertId = this.formBuilder.control(null, [Validators.required]);
    this.formControlConditionVariable = this.formBuilder.control(null);
    this.formControlNextNode = this.formBuilder.control(null);
    this.formControlAction = this.formBuilder.control(null);

    this.formGroup = this.formBuilder.group({
      name: this.formControlName,
      level: this.formControlLevel,
      creditRule: this.formControlRule,
      alertId:  this.formControlAlertId,
      nextNode: this.formControlNextNode,
      conditionVariable: this.formControlConditionVariable,
      action: this.formControlAction,
    });

    if (this.creditRuleMapping) {
      this.formControlName.setValue(this.creditRuleMapping.name);
      this.formControlLevel.setValue(this.creditRuleMapping.level);
      this.formControlRule.setValue(this.creditRuleMapping.creditRule);
      this.formControlAlertId.setValue(this.creditRuleMapping.alertId);
      this.formControlNextNode.setValue(this.creditRuleMapping.nextNode);
      this.formControlConditionVariable.setValue(this.creditRuleMapping.conditionVariable);
      this.formControlAction.setValue(this.creditRuleMapping.action);
    }
  }

  ngOnInit(): void {
    this.getCreditRuleItemsFn().pipe(
      this.toastService.spinnerObservable()
    ).subscribe(creditRuleItems => {
      this.creditRuleItems = creditRuleItems;
      const level = this.formControlLevel.value;
      if (level) {
        this.creditRuleItemOptions = [{id: 0, name: 'No Condition'} as any, ...this.creditRuleItems.filter(item => item.level === level && item.outputType === 'Boolean')];
      }
    })

    this.formControlLevel.valueChanges.subscribe(level => {
      if (level) {
        this.creditRuleItemOptions = [{id: 0, name: 'No Condition'} as any, ...this.creditRuleItems.filter(item => item.level === level && item.outputType === 'Boolean')];
      }
    })
  }

  onCancel() {
    this.dialogRef.close({
      readyForSubmission: false,
      creditRuleMapping: null
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const rule = this.formControlRule.value;
      if (!rule) {
        this.dialogService.openAlertDialog({
          message: "Error",
          subMessage: "The formula is invalid. Please double check."
        }).afterClosed().subscribe()
      } else {
        this.dialogRef.close({
          readyForSubmission: true,
          creditRuleMapping: {
            creditFlowNodeId: this.creditFlowNodeId,
            name: this.formControlName.value as string,
            level: this.formControlLevel.value as CreditRuleMappingLevel,
            creditRule: rule,
            alertId:  this.formControlAlertId.value as number,
            nextNode: (this.formControlNextNode.value ?? null) as number | null,
            conditionVariable: this.formControlConditionVariable.value !== 0 ? this.formControlConditionVariable.value : null,
            action: this.formControlAction.value,
          }
        })
      }
    }
  }
}
