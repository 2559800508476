import {Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, GenderValue } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';




// NOTE: value object for this component would be: GenderValue
@UntilDestroy()
@Component({
    selector: 'gender',
    templateUrl: './gender.component.html',
    styleUrls: ['./gender.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GenderComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => GenderComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class GenderComponent implements ControlValueAccessor, OnInit, Mark {

  onChangeFn?: (v?: GenderValue) => void;
  onTouchFn?: ()=>void;
  disabled = false;
  val?: GenderValue;

  formGroup: FormGroup<{
    gender:FormControl<GenderValue>,
  }>;
  formControl: FormControl<GenderValue>;
  subscription: Subscription;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;


  constructor(private formBuilder: FormBuilder) {
    this.formControl = formBuilder.control('Male', [Validators.required]);
    this.formGroup = formBuilder.group({
       gender: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((v?: GenderValue) => {
        this.propagateChange(v);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: GenderValue): void {
    this.formControl.setValue(obj ?? null);
    this.propagateChange(obj);
  }

  propagateChange(v?: GenderValue) {
    this.val = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v ? v : null);
  }
  mark(): void {
    this.formGroup.markAllAsTouched();
  }

}
