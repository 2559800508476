<form class="local-bank-account-detail-component" [formGroup]="formGroup">
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Account Name</mat-label>
        <input [formControl]="formControlAccountName" matInput maxlength = "100" placeholder="Enter Account Name">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>BSB*</mat-label>
        <input type="text" [inputMask]="createBsbInputMask" placeholder="Enter BSB" matInput
          [formControl]="formControlBSB">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Account Number*</mat-label>
          <input matInput placeholder="Enter Account Number" [inputMask]="createAccountNumberInputMask"
            [formControl]="formControlAccountNumber">
          </mat-form-field>
        </div>
        @if (!hideInstitutionName) {
          <div class="col-6">
            <mat-form-field>
              <mat-label>Financial institution Name</mat-label>
              <input [formControl]="formControlInstitutionName" matInput placeholder="Enter Financial Institution Name">
              @if (institutionBranchName) {
                <mat-hint>{{institutionBranchName}}</mat-hint>
              }
            </mat-form-field>
          </div>
        }
      </div>
    </form>
