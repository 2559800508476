import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, RadioOption, RadioOptionValue } from '@portal-workspace/grow-shared-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatRadioModule } from '@angular/material/radio';
import { NgClass } from '@angular/common';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@Component({
    selector: 'radio-options',
    templateUrl: './radio-options.component.html',
    styleUrls: ['./radio-options.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioOptionsComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => RadioOptionsComponent) },
    ],
    standalone: true,
    imports: [MatRadioModule, FlexModule, FormsModule, ReactiveFormsModule, NgClass, ExtendedModule, DisableControlDirective]
})
export class RadioOptionsComponent extends AbstractControlValueAccessor<RadioOptionValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: false}) title: string = '';
  @Input({required: false}) radioOptions: RadioOption[] = [];

  formControl: FormControl<string | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit() {
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
    this.subscriptions.push(sub);
    if (!this.formControl.value && this.radioOptions.length) {
      this.formControl.setValue(this.radioOptions[0].type);
    }
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }


  doWriteValue(v: RadioOptionValue | null): void | string {
    if (v) {
      this.formControl.setValue(v);
    }
    return undefined;
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

}
