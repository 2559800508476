import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

// const _NUMBER_FORMAT_REGEXP = /^(\d+)?\.((\d+)(-(\d+))?)?$/;
const _NUMBER_FORMAT_REGEXP = /^(\d+)?(\.((\d+)(-(\d+))?)+)?$/;

@Pipe({
    name: 'looseCurrency',
    standalone: true
})
export class LooseCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, currencyCode?: string, symbolDisplay?: boolean | string, digits?: string): any {
    if (typeof value === 'number' || (!isNaN(value)) || _NUMBER_FORMAT_REGEXP.test(value)) {
      const s =  super.transform(value, currencyCode, symbolDisplay, digits);
      if (s) {
        return s;
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}
