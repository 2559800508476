import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CreditUserListDialogData, CreditUserListDialogResult, User} from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import {tap} from "rxjs/operators";


@Component({
  templateUrl: './credit-user-list.dialog.html',
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass
]
})
export class CreditUserListDialog implements OnInit {

  creditUsers: User[] = [];
  formControlUser: FormControl<User | null>

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreditUserListDialogData,
    private dialogRef: MatDialogRef<CreditUserListDialog, CreditUserListDialogResult>,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private toastService: PortalHotToastService) {
      this.formControlUser = formBuilder.control(null,Validators.required)
  }

  ngOnInit() {
    this.applicationService.getActiveCreditUsers().pipe(
      this.toastService.spinnerObservable(),
      tap((r) => {
        if(r && r.status){
          this.creditUsers = r.payload
        }
      })
    ).subscribe()
  }

  onConfirm() {
    // NOTE: userId will always be there, due to a required validator on that formControl
    this.dialogRef.close({
      userId: this.formControlUser.value!.UserId
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
