<div class="unsaved-changes-dialog">
  <mat-dialog-content>
    <h3 class="header"></h3>
    <div class="unsaved-changes-dialog">
      <div class="mb-3">
        <h3 class="header">Unsaved Changes</h3>
        <div>
          <p>The following changes have not been saved:</p>
          <ul class="changes-list">
            @for (changes of unsavedChanges; track changes) {
              <li>
                {{changes}}
              </li>
            }
          </ul>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
        <button color="primary" (click)="onContinueWithoutSaving()" mat-stroked-button>Cancel</button>
        <button color="primary" (click)="onSaveChanges()" mat-flat-button>Save</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
