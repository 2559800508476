<ng-container>
  @if (showLoader) {
    <div class="loader-padding">
      <custom-content-loader type="list" ></custom-content-loader>
    </div>
  }
  <div class="asset-detail" [ngClass]="{hidden: showLoader}">
    @if (title) {
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <p class="title">{{title}}</p>
        </div>
      </div>
    }
    <div class="row mt-1">
      <div class="col-lg-6 col-md-12">
        <asset-category [key]="key" [formControl]="formControlAssetCategory"
          [truckShowGVMTypes]="true"
          [hidden]="application.ApplicationType == 'Consumer' ? true : false"
          [disabled]="disableControl"
          [mark]="markTriggerSubject(formGroup)"
          [showPayout]="true"
          [disableControl]="(!!firstAssetData) && (!!isExternalUser(loggedInUser))"
          [indexFilter]="assetCategoryIndexFilter">
        </asset-category>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <asset-type [key]="key" [formControl]="formControlAssetType"
          [categoryTypes]="consumerAssetTypes"
          [disabled]="disableControl" [mark]="markTriggerSubject(formGroup)"
        [disableControl]="(!!firstAssetData) && (!!isExternalUser(loggedInUser))"></asset-type>
      </div>
    </div>
    @if (!payoutSelected) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field >
            <mat-label>Year</mat-label>
            <input type="text" required [inputMask]="createYearMask" matInput [formControl]="formControlAssetYear">
          </mat-form-field>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Asset description</mat-label>
          <textarea matInput [formControl]="formControlAssetDescription" maxlength="500" [readonly]="disableControl"></textarea>
        </mat-form-field>
      </div>
    </div>
    @if (isVehicleSelected || isCarsSelected) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Serial number type</mat-label>
            <mat-select [formControl]="formControlSerialNumberType">
              @for (option of vehicleSerialNumberTypeOptions; track option) {
                <mat-option [value]="option.key">{{option.key}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
    @if (!payoutSelected) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>{{isVehicleSelected || isCarsSelected ? formControlSerialNumberType.value : 'Serial Number'}}</mat-label>
            <input matInput type="text" [formControl]="formControlSerialNumber">
            @for (errorKey of errorKeys(formControlSerialNumber); track errorKey) {
              <mat-error>
                @if (formControlSerialNumber.touched && formControlSerialNumber.hasError(errorKey)) {
                  {{errorMessage(formControlSerialNumber, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    }
    @if (isVehicleSelected || isCarsSelected) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Rego Number</mat-label>
            <input matInput type="text" [formControl]="formControlRego">
          </mat-form-field>
        </div>
      </div>
    }

    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input
          [title]="isRentalApplication ? 'Invoice price (excl GST)': 'Invoice price (incl GST)'"
          [formControl]="formControlInvoicePrice"
          [mark]="markTriggerSubject(formGroup)"
        [disabled]="disableControl"></currency-input>

      </div>
      <div class="col-3">
        <currency-input
          title="GST"
          [mark]="markTriggerSubject(formGroup)"
          [formControl]="formControlGst"
          [disabled]="disableControl">
        </currency-input>
        @if (invalidGST) {
          <p class="invalidGst">Invalid GST</p>
        }
      </div>
    </div>

    @if (!payoutSelected) {
      <div class="row">
        <!-- WEB-3702 -->
        <div class="col-lg-3 col-md-12">
          <currency-input
            [min]="0"
            [max]="maxDespoit"
            title="Deposit Amount (Dynamoney)"
            [formControl]="formControlDepositAmountDynamoney"
            [mark]="markTriggerSubject(formGroup)"
            [disabled]="disableControl">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            [min]="0"
            [max]="maxDespoit"
            title="Deposit Amount (Supplier)"
            [formControl]="formControlDepositAmountSupplier"
            [mark]="markTriggerSubject(formGroup)"
            [disabled]="disableControl">
          </currency-input>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            [min]="0"
            [max]="maxDespoit"
            title="Deposit Amount (Total) (incl GST)"
            [formControl]="formControlDepositAmount"
            [mark]="markTriggerSubject(formGroup)"
            [disabled]="disableControl">
          </currency-input>
        </div>
      </div>
    }
    @if (!payoutSelected) {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Amount Financed"
            [mark]="markTriggerSubject(formGroup)"
            [formControl]="formControlAmountFinanced"
            [disabled]="disableControl">
          </currency-input>
        </div>
      </div>
    }

    @if (!payoutSelected) {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Residual"
            [formControl]="formControlResidual"
            [mark]="markTriggerSubject(formGroup)"
            [disabled]="disableControl">
          </currency-input>
        </div>
      </div>
    }

    @if (showAutoGenerateInspection && showAutoGenerateInspectionConditions()) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-checkbox [formControl]="formControlAutoGenerateInspection" color="primary" class="check-box">
            Automatically order an asset inspection when the contract is signed
          </mat-checkbox>
        </div>
      </div>
    }

    <div  class="row">
      <div class="col-12 mb-5">
        @if (!disableControl && allowDelete) {
          <button mat-stroked-button   (click)="onRemoveAsset()"
          >Cancel</button>
        }
      </div>
    </div>

    @if (!editAssetFlag) {
      <button mat-flat-button class="addAsset mb-4" (click)="onSaveAsset()">
        Save Asset
      </button>
    }

    <!-- <button *ngIf="updateAssetData!=null" mat-flat-button class="addAsset mb-4" (click)="onSaveAsset()">
    Edit Asset
  </button> -->
</div>
</ng-container>

