import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {formControlErrorKeys, formControlErrorMessage} from '@portal-workspace/grow-ui-library';
import {NatureOfBusinessTypes} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';

import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    selector: 'nature-of-business',
    templateUrl: './nature-of-business.component.html',
    styleUrls: ['./nature-of-business.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NatureOfBusinessComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => NatureOfBusinessComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class NatureOfBusinessComponent implements ControlValueAccessor, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formGroup: FormGroup<{
    natureOfBusiness: FormControl<NatureOfBusinessTypes>
  }>;
  formControl: FormControl<NatureOfBusinessTypes>;

  onChangeFn?: (v?: NatureOfBusinessTypes) => void;
  onTouchFn?: () => void;
  disabled = false;
  dirty = false;
  value?: NatureOfBusinessTypes;

  options: {id: NatureOfBusinessTypes, name: string}[] =  [
    { id: 'broker', name: 'Broker'},
    { id: 'vendor', name: 'Vendor'},
    { id: 'supplier', name: 'Supplier'},
    { id: 'dealer', name: 'Dealer' },
  ];

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    this.formControl = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      natureOfBusiness: this.formControl,
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: NatureOfBusinessTypes): void {
    if (obj) {
      this.formControl.setValue(obj);
    }
    this.propagateChange(obj);
  }

  propagateChange(v?: NatureOfBusinessTypes) {
    this.value = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v);
  }

  onSelectionChange($event: MatSelectChange) {
    const v: NatureOfBusinessTypes = $event.value;
    this.propagateChange(v ? v : null);
  }

  showError() {
    // return (!!!this.value &&  this.dirty);
    return !!!this.value;
  }

  onOpenedChange($event: boolean) {
    this.dirty = true;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }
}
