import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {getUser, hasAccessCardOperatorDashboard} from '@portal-workspace/grow-ui-library';
import {navigationUrlForOpsErrorPage} from "../service/navigation-urls";


export const validCardOperatorGuard = () => {
    return async (
       route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ) => {
        const router = inject(Router);
        const user = getUser();
        if (user) {
            if (hasAccessCardOperatorDashboard(user)) {
                return true;
            }
            return router.navigate(navigationUrlForOpsErrorPage('e-not-operator'));
        }
        return true;
    }
}
