<div class="product-selection-component">
  <section class="section" [formGroup]="formGroup" >
    <h4>Select products</h4>
    <p><mat-checkbox color="primary" name="formControlSelectedAllProducts" (change)="onChange($event)" value="true" [formControl]="formControlSelectedAllProducts" >Select All</mat-checkbox></p>
    <p><mat-checkbox color="primary" name="formControlSelectedAssetFinance"  (change)="onChange($event)" value="true" [formControl]="formControlSelectedAssetFinance">Asset Finance</mat-checkbox></p>
    <p><mat-checkbox color="primary" name="formControlSelectedBusinessLoan"  (change)="onChange($event)" value="true" [formControl]="formControlSelectedBusinessLoan" >Business Term Loan</mat-checkbox></p>
    <p><mat-checkbox color="primary" name="formControlSelectedInsurancePremium"  (change)="onChange($event)" value="true" [formControl]="formControlSelectedInsurancePremium">Insurance Premium</mat-checkbox></p>
     
  </section>
</div>
