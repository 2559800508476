<form class="custom-address">
  <div class="row">
    <div class="col-12">
      <mat-form-field>
        <mat-label>{{title}}</mat-label>
        <input
          #mapInput
          matInput
          [errorStateMatcher]="errorStateMatcher"
          placeholder="Search for an address ..."
          (click)="mark()"
          [formControl]="formControlAddress" >
          <mat-icon class="mdi mdi-magnify" matSuffix></mat-icon>
          @for (errorKey of errorKeys(formControlAddress); track errorKey) {
            <mat-error>
              @if (formControlAddress.touched && formControlAddress.hasError(errorKey)) {
                {{errorMessage(formControlAddress, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Unit number</mat-label>
          <input [errorStateMatcher]="errorStateMatcher" maxlength="8" matInput type="text" [formControl]="formControlUnitNumber">
          @for (errorKey of errorKeys(formControlUnitNumber); track errorKey) {
            <mat-error>
              @if (formControlUnitNumber.touched && formControlUnitNumber.hasError(errorKey)) {
                {{errorMessage(formControlUnitNumber, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Street number</mat-label>
          <input [errorStateMatcher]="errorStateMatcher" maxlength="8"  matInput type="text" [formControl]="formControlStreetNumber">
          @for (errorKey of errorKeys(formControlStreetNumber); track errorKey) {
            <mat-error>
              @if (formControlStreetNumber.touched && formControlStreetNumber.hasError(errorKey)) {
                {{errorMessage(formControlStreetNumber, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Street name</mat-label>
          <input [errorStateMatcher]="errorStateMatcher" maxlength="64"  matInput type="text" [formControl]="formControlStreetName">
          @for (errorKey of errorKeys(formControlStreetName); track errorKey) {
            <mat-error>
              @if (formControlStreetName.touched && formControlStreetName.hasError(errorKey)) {
                {{errorMessage(formControlStreetName, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Street type</mat-label>
          <mat-select [errorStateMatcher]="errorStateMatcher" [formControl]="formControlStreetType"
            [compareWith]="streetTypeCompareWith" >
            @for (streetType of streetTypes; track streetType) {
              <mat-option [value]="streetType.type">
                {{streetType.type}}
              </mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(formControlStreetType); track errorKey) {
            <mat-error>
              @if (formControlStreetType.touched && formControlStreetType.hasError(errorKey)) {
                {{errorMessage(formControlStreetType, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Postcode</mat-label>
          <input [errorStateMatcher]="errorStateMatcher"  matInput type="text" [formControl]="formControlPostcode">
          @for (errorKey of errorKeys(formControlPostcode); track errorKey) {
            <mat-error>
              @if (formControlPostcode.touched && formControlPostcode.hasError(errorKey)) {
                {{errorMessage(formControlPostcode, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Suburb</mat-label>
          <input [errorStateMatcher]="errorStateMatcher" maxlength="64"  matInput type="text" [formControl]="formControlSuburb">
          @for (errorKey of errorKeys(formControlSuburb); track errorKey) {
            <mat-error>
              @if (formControlSuburb.touched && formControlSuburb.hasError(errorKey)) {
                {{errorMessage(formControlSuburb, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select [errorStateMatcher]="errorStateMatcher" [formControl]="formControlState"
            [compareWith]="stateCompareWith" >
            @for (state of states; track state) {
              <mat-option [value]="state.type">
                {{state.type}}
              </mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(formControlState); track errorKey) {
            <mat-error>
              @if (formControlState.touched && formControlState.hasError(errorKey)) {
                {{errorMessage(formControlState, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
  </form>
