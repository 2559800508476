import { Component, OnInit, ViewChild } from '@angular/core';
import {
  applicantsThatAreGuarantor,
  fromApplicantToInteflowCommercialEntities,
  fromApplicantToInteflowIndividualsData,
  fromContactToInteflowContacts,
  fromGuarantorToInteflowCommercialEnttities,
  fromGuarantorToInteflowIndividuals,
  fromReferenceToInteflowReferences,
  getUser,
  toInteflowEntityTypes,
  toInteflowLegalName,
  setupUntilDestroy,
  applicantsToPrimaryContact,
  individualGarantors,
  toInteflowAddress,
  applicationToDirectorScore,
  GetUsersFunc,
  applicationToHasAdditionalBrokerCorrespondent,
  applicationToAdditionalBrokerCorrespondent, AnnualRevenueValue, BusinessNumberSearchFn,
  applicationToPreviousLoan,
  applicationToPreviousBusiness,
  UserSameCompanySelectionComponent,
  applicationToApplicantType,
  applicationToLoanAmount,
  formControlErrorKeys,
  formControlErrorMessage, applicationToPrivacyConfirmation, applicationToBusinessSearchValue, applicationToLegalName, applicationToBrokerSearchValue, applicationToLoanTerms, applicationToFacilityEstablishmentFeePercent, applicationToPaymentFrequency, applicationToPropertyOwner, applicationToAdverseOnFile, applicationToEquifaxScoreAboveThreshold, applicationToOrganisationType, applicationToAbn, applicationToAcn, applicationToRevenue, applicationToOperatesInCommercialPremise, applicationToPrimaryIndustry, applicationToIndustrySector, applicationToPrimaryAddress, applicationToBusinessLandline, applicationToApplicants, applicationToGuarantors, applicationToPrimaryContact, applicationToBrokerFlowDocumentId, applicationToReferences, applicationToApplicationNotes, applicationToDirectSalesUserFirstName , applicationToDirectSalesUserLastName, applicationToDirectSalesUserEmail,
  UploadedFilesListComponentEvent,
  duplicateFileNameValidator,
  maxFileUploadValidator,
} from '@portal-workspace/grow-ui-library';
import {
  patchAcn,
  getFileTags,
  booleanToYesNo,
  filesToBase64Files,
  defaultDocuments,
  AzureStorageDocument, Metadata, DocumentTag, EntityTypePartnershipOption,
  filterOutUniquePrimaryContacts, UserSelectionValue, NotNullable, BusinessOverdraftApplication,
  LoanTermValueOptions,
  PaymentFrequencyValue,
  YesNoValue,
  FacilityEstablishmentValue,
  SliderComponentValue,
  AbnComponentValue,
  AcnComponentValue,
  MobileValue,
  SelectContactValue,
  PaymentFrequencyValueOptions,
  UploadAzureFiles, UploadFileValue,
  BusinessNumberSearchValue,
  SaveApplicationPricingDetailsData,
  GetUserSameCompanyFunc,
  UserSameCompanySelectionValue,
  CustomerApplicantTypeValue,
  OverdraftPaymentBreakupDialogData, calculateBusinessOverdraftEstimation, LoanTermType,
  yesNoToBoolean,
  TermRateForBusinessOverdraft,
  directSaleBrokerValue,
  directSaleBrokerOfUser,
  FacilityEstablishmentValueOptions,
  EmailComponentValue,
  PayloadApiResponse,
  SaveApplicationResult,
  parseString,
  ManageApplicationDialogResult,
  individualApplicants,
  applicantsPrivacyConsent,
  maxLoanForBusinessOverdraft,
  BusinessSearchResultValue,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RedbookService } from '../../service/redbook.service';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import {Observable, of, Subscription } from 'rxjs';
import { BusinessSearchFn, } from '@portal-workspace/grow-ui-library';
import { BusinessSearchValue } from '@portal-workspace/grow-shared-library';
import { AggregatorSearchFn } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponentValue, } from '@portal-workspace/grow-shared-library';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import moment from 'moment';
import { map, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import {
  TrusteeValue
} from '@portal-workspace/grow-shared-library';
import {
  SoleTraderValue
} from '@portal-workspace/grow-shared-library';
import {
  DirectorValue
} from '@portal-workspace/grow-shared-library';
import {
  PartnerValue
} from '@portal-workspace/grow-shared-library';
import { MemberValue } from '@portal-workspace/grow-shared-library';
import {
  GuarantorComponentEvent,
} from '@portal-workspace/grow-ui-library';
import {
  GuarantorValue,
  IndividualGuarantor
} from '@portal-workspace/grow-shared-library';
import { ContactValue } from '@portal-workspace/grow-shared-library';
import { guarantorValidator, requiredAllowEmptyValidator } from '@portal-workspace/grow-ui-library';
import { ReferenceValue } from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import { LoanTermValue } from '@portal-workspace/grow-shared-library';
import {
  ApplicationService,
} from '../../service/application.service';
import {
  BrokerOfUser,
  GeoLocation,
  RatecardDetails,
  UpdateApplicationData
} from '@portal-workspace/grow-shared-library';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationApplicant, Base64File, OriginatorBusiness, User } from '@portal-workspace/grow-shared-library';
import {
  AppCalculator,
  FormDataForBusinessOverdraft,
  TermRate,
} from '@portal-workspace/grow-shared-library';
import { constants, getSecondaryIndustry } from '@portal-workspace/grow-shared-library';
import { PrimaryIndustrySelectionValue } from '@portal-workspace/grow-shared-library';
import { SecondaryIndustrySelectionValue } from '@portal-workspace/grow-shared-library';
import { UploadAzureFilesValue } from '@portal-workspace/grow-shared-library';
import { DriverLicenceUpload } from '../asset-finance/asset-finance.page';
import { Address2ComponentValue } from '@portal-workspace/grow-shared-library';
import {
  navigationUrlForApplications,
  navigationUrlForApplicationsWithQueryParams,
  navigationUrlForDirectSale
} from '../../service/navigation-urls';
import {
  EntityTypeValue, EntityTypes,
}
  from '@portal-workspace/grow-shared-library';
import { BusinessOverdraftSummary } from '@portal-workspace/grow-shared-library';
import { OverdraftPaymentBreakupDialogData2 } from '@portal-workspace/grow-shared-library';
import {environment} from '../../../environments/environment';
import {EntityTypeTrustOption} from '@portal-workspace/grow-shared-library';
import {AdminService} from '../../service/admin.service';
import { AuthService } from '../../service/auth.service';
import { BusinessOverdraftSummaryComponent } from '@portal-workspace/grow-ui-library';
import { UploadFilesMultiTagsComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectContactComponent } from '@portal-workspace/grow-ui-library';
import { GuarantorComponent } from '@portal-workspace/grow-ui-library';
import { MemberComponent } from '@portal-workspace/grow-ui-library';
import { DirectorComponent } from '@portal-workspace/grow-ui-library';
import { PartnerComponent } from '@portal-workspace/grow-ui-library';
import { SoleTraderComponent } from '@portal-workspace/grow-ui-library';
import { TrusteeComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { SecondaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { PrimaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { AnnualRevenueComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { FacilityEstablishmentComponent } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { UserSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MarkDirective } from '@portal-workspace/grow-ui-library/mark';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { ApplicationStepper2Component,setStepper2StepConfig } from '@portal-workspace/grow-ui-library';
import { BusinessNumberSearchComponent } from '@portal-workspace/grow-ui-library';
import { CustomerApplicantComponent } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { Buffer } from 'buffer';

// NOTE: Page to land when you are in DRAFT (new unsubmitted) business overdraft application
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './direct-sale-business-overdraft.page.html',
    styleUrls: ['./direct-sale-business-overdraft.page.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, DisableControlDirective, NameComponent, EmailComponent, CdkStepperModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, BusinessSearchComponent, MarkDirective, MatCheckboxModule, MatTooltipModule, UserSelectionComponent, AggregatorSearchComponent, FacilityEstablishmentComponent, PaymentFrequencySelectionComponent, YesNoComponent, CurrencyInputComponent, EntityTypeComponent, AbnComponent, AcnComponent, AnnualRevenueComponent, PrimaryIndustrySelectionComponent, SecondaryIndustrySelectionComponent, CustomAddressComponent, MobileComponent, ApplicationHeaderSegmentComponent, TrusteeComponent, SoleTraderComponent, PartnerComponent, DirectorComponent, MemberComponent, GuarantorComponent, SelectContactComponent, MatFormFieldModule, MatInputModule, ReferenceComponent, MessageBoxComponent, UploadedFilesListComponent, UploadFileComponent, UploadFilesMultiTagsComponent, BusinessOverdraftSummaryComponent, BusinessNumberSearchComponent, UserSameCompanySelectionComponent, CustomerApplicantComponent],
})
export class DirectSaleBusinessOverdraftPage implements OnInit {

  @ViewChild('uploadFilesMultiTagsComponent') uploadFilesMultiTagsComponent!: UploadFilesMultiTagsComponent;
  businessSearchPlaceholder: string = "Enter name or ABN…"

  subscriptions: Subscription[] = [];
  existingApplication: BusinessOverdraftApplication | null = null; // will exists if there is a draft
  existingApplicationBureauReport: BusinessOverdraftApplication['CompanyDetails'] | null = null;
  rateCard: RatecardDetails | null = null;
  isDisable = true
  // global:
  ip: string = '';
  geoLocation: GeoLocation = {};
  // user!: User;
  otherUsersObservable?: Observable<NotNullable<UserSelectionValue>[]>;
  brokerOfUser: BrokerOfUser | null = directSaleBrokerOfUser(environment.production);
  acceptedQuotation = false;
  brokerApplicationId: string | null = null;
  applicationId: number | null = null;

  // global: step 3 onwards
  organisationName: string = 'Not available';
  // terms: TermRate | null = null;          // available after chart popups
  terms: TermRateForBusinessOverdraft | null = null;          // available after chart popups
  calculator: AppCalculator | null = null;   // available after chart popups

  // after popup confirmation
  businessUseOnly = false;
  selfServiceability = false;
  // privacyConfirmation = false;
  brokerageDollar?: number;
  interestRate?: number;
  rbaRate?: number;
  facilityEstablishmentFee?: number;
  docFee?: number;
  brokerage?: number;
  // amountMin: number | null = 5000;
  // amountMax: number | null = 500000;

  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;
  defaultDocuments = defaultDocuments;

  getFileTags = getFileTags;

  // STEP 1:
  step1BusinessSearchFn!: BusinessSearchFn;
  // step1GetUsersFn!: GetUsersFunc;
  step1GetUserSameCompanyFn!: GetUserSameCompanyFunc;
  step1OrgSalesforceId: string | null = null;
  // noOtherUsersInCompany = true;
  formControlStep1Broker!: FormControl<AggregatorSearchComponentValue>;
  formGroupStep1!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    business: FormControl<BusinessSearchValue>,
    showCorrespondent: FormControl<boolean | null>,
    previousBusiness: FormControl<BusinessNumberSearchValue>,
    firstName: FormControl<string | null>,
    lastName: FormControl<string | null>,
    email: FormControl<EmailComponentValue>;
  }>;
  formControlStep1Business!: FormControl<BusinessSearchValue>;
  formControlStep1ShowCorrespondent!: FormControl<boolean | null>;
  formControlStep1PreviousBusiness!: FormControl<BusinessNumberSearchValue>;
  formControlStep1FirstName!: FormControl<string | null>;
  formControlStep1LastName!: FormControl<string | null>;
  formControlStep1Email!: FormControl<EmailComponentValue>;

  // STEP 1_b:
  // formGroupStep1_b!: FormGroup<{
  //   customerApplicantType: FormControl<CustomerApplicantTypeValue>;
  //   privacyStatementCheck: FormControl<boolean | null>;
  // }>;
  // formControlStep1_bCustomerApplicantType!: FormControl<CustomerApplicantTypeValue>;
  // formControlStep1_bPrivacyStatementCheck!: FormControl<boolean | null>;

  // STEP 2:
  // step2BrokerSearchFn!: AggregatorSearchFn;
  step2MonthlyRepayment: string = '';
  step2EstimatedDrawdownDate: Moment = moment();
  step2MaxLimit: number = 500000;
  // formControlStep2Broker!: FormControl<AggregatorSearchComponentValue>;
  formControlStep2LoanTerms!: FormControl<LoanTermValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2FacilityEstablishmentFeePercent!: FormControl<FacilityEstablishmentValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlStep2LoanAmount!: FormControl<SliderComponentValue>;
  formControlStep2DirectorScore!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formGroupStep2!: FormGroup<{
    // broker: FormControl<AggregatorSearchComponentValue>,
    loanAmount: FormControl<SliderComponentValue>,
    facilityEstablishmentFeePercent: FormControl<FacilityEstablishmentValue>,
    loanTerms: FormControl<LoanTermValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScore: FormControl<YesNoValue>,
    directorScore: FormControl<YesNoValue>,
    previousLoan: FormControl<YesNoValue>,
  }>;

  // STEP 3:
  formControlStep3OrganisationType!: FormControl<EntityTypeValue>;
  formControlStep3Abn!: FormControl<AbnComponentValue>;
  formControlStep3Acn!: FormControl<AcnComponentValue>;
  formControlStep3AnnualRevenue!: FormControl<AnnualRevenueValue>;
  formControlStep3OperatesInCommercialPremises!: FormControl<YesNoValue>;
  formControlStep3PrimaryIndustry!: FormControl<PrimaryIndustrySelectionValue>;
  formControlStep3IndustrySector!: FormControl<SecondaryIndustrySelectionValue>;
  formControlStep3PrimaryBusinessAddress!: FormControl<Address2ComponentValue>;
  formControlStep3BusinessLandline!: FormControl<MobileValue>;
  formGroupStep3!: FormGroup<{
    organisationType: FormControl<EntityTypeValue>,
    abn: FormControl<AbnComponentValue>,
    acn: FormControl<AcnComponentValue>,
    annualRevenue: FormControl<AnnualRevenueValue>,
    operatesInCommercialPremises: FormControl<YesNoValue>,
    primaryIndustry: FormControl<PrimaryIndustrySelectionValue>,
    industrySector: FormControl<SecondaryIndustrySelectionValue>,
    primaryBusinessAddress: FormControl<Address2ComponentValue>,
    businessLandline: FormControl<MobileValue>,
  }>;

  // STEP 4:
  step4BusinessSearchFn!: BusinessSearchFn;
  step4BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step3OnwardsOrganisationType: EntityTypes | null = null;
  formControlStep4Applicant!: FormControl<ApplicationApplicant>;
  formGroupStep4!: FormGroup<{
    applicant: FormControl<ApplicationApplicant>,
  }>;

  // STEP 5:
  step5CompanySearchFn!: BusinessSearchFn;
  step5BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step5MandatoryGuarantors: IndividualGuarantor[] = [];
  formControlStep5Guarantors!: FormControl<GuarantorValue>;
  formGroupStep5!: FormGroup<{
    guarantors: FormControl<GuarantorValue>
  }>;

  // STEP 6:
  step6PredefinedContactSelection!: ContactValue[];
  formControlStep6PrimaryContact!: FormControl<SelectContactValue>;
  step6PossiblePrimaryContacts: ContactValue[] = [];
  formGroupStep6!: FormGroup<{
    primaryContact: FormControl<SelectContactValue>,
  }>;

  // STEP 7:
  formControlStep7BrokerflowDocumentId!: FormControl<string | null>;
  formControlStep7References!: FormControl<ReferenceValue>;
  formGroupStep7!: FormGroup<{
    brokerflowDocumentId: FormControl<string | null>,
    references: FormControl<ReferenceValue>,
  }>;

  // STEP 8:
  step8DriverLicenses: { id: string, name: string, formControl: FormControl<UploadAzureFilesValue> }[] = [];
  step8UploadedDocs: AzureStorageDocument[] = [];
  step9UploadedDocs: AzureStorageDocument[] = [];
  step9DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  step8UploadedDrDoc: AzureStorageDocument[] = [];
  step8DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  formGroupStep8!: FormGroup<{}>;
  step8And9FilesUploadedToAzure: Base64File[] = [];

  // STEP 9:
  formControlStep9OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  formGroupStep9!: FormGroup<{
    otherSupportingDocuments: FormControl<UploadAzureFilesValue>,
  }>;

  // STEP 10:
  formControlStep10ApplicationNotes!: FormControl<string | null>;
  formGroupStep10!: FormGroup<{
    applicationNotes: FormControl<string | null>,
  }>;

  // STEP 11:
  step11Summary!: BusinessOverdraftSummary;
  formGroupStep11!: FormGroup<{}>;


  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
    private router: Router,
    private applicationService: ApplicationService,
    ) {
  }

  async ngOnInit() {
    setupUntilDestroy(this);
    this.existingApplication = (this.route.snapshot.data as any).application;
    // this.rateCard = (this.route.snapshot.data as any).ratecard;
    console.log("Resolved [BusinessOverdraft] application", this.existingApplication)
    if (this.existingApplication) {
      if (this.existingApplication.ApplicationType != 'BusinessOverdraft') {
        this.toastService.error(`This application ${this.existingApplication.ApplicationId} is not an Business Overdraft application`, `Invalid application type`);
      }
    }

    // get user
    // this.user = getUser()!;

    this.initStep1();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep5();
    this.initStep6();
    this.initStep7();
    this.initStep8();
    this.initStep9();
    this.initStep10();
    this.initStep11();

    this.populate();

    // get ip
    const sub1 = this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
          console.log('**** ip', this.ip);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdateMaxLimit();
      })
    ).subscribe());
    // get geolocation
    try {
      const geoLocation = await this.applicationService.getGeoLocation();
      if (geoLocation) {
        this.geoLocation = geoLocation;
      }
      console.log('**** geolocation', geoLocation);
    } catch (err) {
      alert(err);
    }
  }

  populate() {
    const brokerSearchValue = directSaleBrokerValue(environment.production);
    this.formControlStep1Broker.setValue(brokerSearchValue);

    this.subscriptions.push(
      this.applicationService.getDefaultRatecardDetails('BusinessOverdraft').subscribe(response => {
        this.rateCard = response.payload;
      })
    )

    if (this.existingApplication) {
      // pop up the validation dialog
      this.applicationDialogService.openDirectSaleApplicationDialog({})
        .afterClosed().subscribe((result: ManageApplicationDialogResult | undefined) => {
          if (result) {
            // validate the details
            if (
              this.existingApplication &&
              applicationToDirectSalesUserFirstName (this.existingApplication) === result.firstName &&
              applicationToDirectSalesUserLastName(this.existingApplication) === result.lastName &&
              applicationToDirectSalesUserEmail(this.existingApplication) === result.email
            ) {
              // 1
              this.formControlStep1Business.setValue(applicationToBusinessSearchValue(this.existingApplication));
              this.formControlStep1ShowCorrespondent.setValue(applicationToHasAdditionalBrokerCorrespondent(this.existingApplication));
              this.formControlStep1PreviousBusiness.setValue(applicationToPreviousBusiness(this.existingApplication));
              this.formControlStep1FirstName.setValue(applicationToDirectSalesUserFirstName (this.existingApplication));
              this.formControlStep1LastName.setValue(applicationToDirectSalesUserLastName(this.existingApplication));
              this.formControlStep1Email.setValue(applicationToDirectSalesUserEmail(this.existingApplication));
              console.log('set value: ', this.formControlStep1PreviousBusiness.value)
              this.brokerApplicationId = this.existingApplication.BrokerAppId;
              this.applicationId = this.existingApplication.ApplicationId;
              this.acceptedQuotation = this.existingApplication.AcceptQuotation;
              this.organisationName = applicationToLegalName(this.existingApplication) ?? '';
              const brokerSearchValue = applicationToBrokerSearchValue(this.existingApplication);
              this.formControlStep1Broker.setValue(brokerSearchValue);
              this.step1OrgSalesforceId = brokerSearchValue?.salesforceId ?? null;


              // 2
              this.acceptedQuotation = this.existingApplication.AcceptQuotation;
              // this.formControlStep2Broker.setValue(applicationToBrokerSearchValue(this.existingApplication));
              this.formControlStep2LoanTerms.setValue(applicationToLoanTerms(this.existingApplication));
              this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.existingApplication));
              this.formControlStep2FacilityEstablishmentFeePercent.setValue(applicationToFacilityEstablishmentFeePercent(this.existingApplication) ??
              FacilityEstablishmentValueOptions.find(o => o.type === '2.5') ?? null);
              // this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication));
              this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication) ??
              PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
              this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.existingApplication));
              this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.existingApplication));
              this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.existingApplication));
              this.formControlStep2DirectorScore.setValue(applicationToDirectorScore(this.existingApplication));
              this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.existingApplication));

              // 3
              this.formControlStep3OrganisationType.setValue(applicationToOrganisationType(this.existingApplication));
              this.formControlStep3Abn.setValue(applicationToAbn(this.existingApplication));
              this.formControlStep3Acn.setValue(applicationToAcn(this.existingApplication));
              this.formControlStep3AnnualRevenue.setValue(applicationToRevenue(this.existingApplication));
              this.formControlStep3OperatesInCommercialPremises.setValue(applicationToOperatesInCommercialPremise(this.existingApplication));
              this.formControlStep3PrimaryIndustry.setValue(applicationToPrimaryIndustry(this.existingApplication));
              this.formControlStep3IndustrySector.setValue(applicationToIndustrySector(this.existingApplication));
              this.formControlStep3PrimaryBusinessAddress.setValue(applicationToPrimaryAddress(this.existingApplication));
              this.formControlStep3BusinessLandline.setValue(applicationToBusinessLandline(this.existingApplication));

              // 4
              const applicants = applicationToApplicants(this.existingApplication);
              this.formControlStep4Applicant.setValue(applicants);

              // 5
              this.formControlStep5Guarantors.setValue(applicationToGuarantors(this.existingApplication));

              // 6
              this.formControlStep6PrimaryContact.setValue(applicationToPrimaryContact(this.existingApplication));

              // 7
              this.formControlStep7BrokerflowDocumentId.setValue(applicationToBrokerFlowDocumentId(this.existingApplication));
              this.formControlStep7References.setValue(applicationToReferences(this.existingApplication));

              // 8-10
              this.formControlStep10ApplicationNotes.setValue(applicationToApplicationNotes(this.existingApplication));
              this.listUploadedDocuments();

            } else {
              // invalid details
              this.applicationDialogService.openAlertDialog({
                message: "Error",
                subMessage: "The information you provided does not match our record. You can not access this application."
              }).afterClosed().subscribe(() => {
                this.router.navigate(navigationUrlForDirectSale());
              })
            }
          }
      })
    }
  }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  onClickBack() {
    this.router.navigate(navigationUrlForDirectSale());
  }

  initStep1() {
    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1PreviousBusiness =  this.formBuilder.control(null);
    this.formControlStep1ShowCorrespondent = this.formBuilder.control(false);
    this.formControlStep1FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Email = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      broker: this.formControlStep1Broker,
      business: this.formControlStep1Business,
      showCorrespondent: this.formControlStep1ShowCorrespondent,
      previousBusiness: this.formControlStep1PreviousBusiness,
      firstName: this.formControlStep1FirstName,
      lastName: this.formControlStep1LastName,
      email: this.formControlStep1Email,
    });

    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: async stepper => {
        stepper.next();
      },
      previousStepClickedFn: async (stepper) => {
        if(this.applicationId){
          // await this.router.navigate(navigationUrlForApplications({ reload: true }))
          await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, {reload: true}));
        }else {
          await this.router.navigate(navigationUrlForDirectSale())
        }
      }
    });


    this.step1BusinessSearchFn = this.applicationService.businessSearchFn;

    this.subscriptions.push(this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r && r.type === 'search-result') { // NOTE: we can deduce only when business is a ASIC/ACN search
          if (r.entityTypeValue) {
            this.formControlStep3OrganisationType.setValue(r.entityTypeValue);
          }
          // if (r.result?.entityType) {
          //   const description = r.result.entityType.entityDescription;
          //   const code = r.result.entityType.entityTypeCode;

          //   if ('IND' == code) {
          //     this.formControlStep3OrganisationType.setValue(EntityTypeSoleTraderOption);
          //   } else if (description && description.toLowerCase().indexOf('trust') >= 0) {
          //     this.formControlStep3OrganisationType.setValue(EntityTypeTrustOption);
          //   } else if (description && description.toLowerCase().indexOf('company') >= 0) {
          //     this.formControlStep3OrganisationType.setValue(EntityTypeCompanyOption);
          //   } else if (description && description.toLowerCase().indexOf('partnership') >= 0) {
          //     this.formControlStep3OrganisationType.setValue(EntityTypePartnershipOption);
          //   } else {
          //     this.formControlStep3OrganisationType.setValue(EntityTypeOtherOption);
          //   }
          // }
        }
      })
    ).subscribe());
    // population
    // if (this.existingApplication) {
    //   this.formControlStep1Business.setValue(applicationToBusinessSearchValue(this.existingApplication));
    //   this.brokerApplicationId = this.existingApplication.BrokerAppId;
    //   this.applicationId = this.existingApplication.ApplicationId;
    //   this.acceptedQuotation = this.existingApplication.AcceptQuotation;
    //   this.organisationName = applicationToLegalName(this.existingApplication) ?? '';
    // }

    const sub = this.formControlStep1Business.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if (r) {
          if (r.abn && this.formControlStep3Abn) {
            this.formControlStep3Abn.setValue(r.abn);
          }
          if (r.acn && this.formControlStep3Acn) {
            const acn = patchAcn(r.acn);
            this.formControlStep3Acn.setValue(acn ?? null);
          }
          this.organisationName = toInteflowLegalName(r) ?? '';
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

    const sub2 = this.formControlStep1Business.valueChanges.subscribe(v => {
      if (v && v.type === 'search-result') { // NOTE only when business is done through ASIC/ABN search
          const ABNAge = v?.result?.ABNAgeMonths ?? 0;
          if (v && ABNAge < 12) {
              this.formControlStep1PreviousBusiness.setValidators([Validators.required]);
              this.formControlStep1PreviousBusiness.updateValueAndValidity();
          } else {
              this.formControlStep1PreviousBusiness.setValidators(null);
              this.formControlStep1PreviousBusiness.updateValueAndValidity();
          }
      } else { // else we assume no previous business is needed
          this.formControlStep1PreviousBusiness.setValidators(null);
          this.formControlStep1PreviousBusiness.updateValueAndValidity();
      }
    })
    this.subscriptions.push(sub2);
  }

  initStep2() {

    // this.formControlStep2LoanPurpose = this.formBuilder.control({type: 'cash-flow'}, [Validators.required]);
    // this.formControlStep2Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2FacilityEstablishmentFeePercent = this.formBuilder.control(FacilityEstablishmentValueOptions[25], [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(LoanTermValueOptions[2], [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control(PaymentFrequencyValueOptions[2], [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2DirectorScore = this.formBuilder.control(true, [Validators.required])
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);

    this.formGroupStep2 = this.formBuilder.group({
      // broker: this.formControlStep2Broker,
      loanAmount: this.formControlStep2LoanAmount,
      facilityEstablishmentFeePercent: this.formControlStep2FacilityEstablishmentFeePercent,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      equifaxScore: this.formControlStep2EquifaxScoreAboveThreshold,
      directorScore: this.formControlStep2DirectorScore,
      previousLoan: this.formControlStep2PreviousLoan,
    });


    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        const adverseOnFile = this.formControlStep2AdverseOnFile.value
        const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value
        const directorScore = this.formControlStep2DirectorScore.value
        const propertyOwner = this.formControlStep2PropertyOwner.value
        if (adverseOnFile || (!propertyOwner && !equifaxScore && !directorScore) || (!adverseOnFile && propertyOwner && !equifaxScore && !directorScore)) {
          this.applicationDialogService.openAlertDialog({
            message: "Warning",
            subMessage: `Approvals for business overdraft can only be obtained for borrowers without
            prior adverse credit with<br/> at least one director scored above 500.
            The borrower may still be eligible for a term loan or invoice finance.
            <br/><br/>
            Please contact your sales representative or call us on 1300 001 420.`
          })
        } else {
          this.step2ShowQuotation(stepper);
        }
      },
      nextStepButtonText:"Calculate Repayments"
    });



    // update max limit
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdateMaxLimit();
      })
    ).subscribe());

    this.subscriptions.push(this.formControlStep2EquifaxScoreAboveThreshold.valueChanges.pipe(
      tap(r => {
        this.step2UpdateMaxLimit();
      })
    ).subscribe());

    this.subscriptions.push(this.formControlStep2DirectorScore.valueChanges.pipe(
      tap(r => {
        this.step2UpdateMaxLimit();
      })
    ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe())

    // populate
    // if (this.existingApplication) {
    //   this.acceptedQuotation = this.existingApplication.AcceptQuotation;
    //   this.existingApplicationBureauReport = this.existingApplication.CompanyDetails;
    //   this.formControlStep2LoanPurpose.setValue(applicationToLoanPurpose(this.existingApplication));
    //   this.formControlStep2Broker.setValue(applicationToBrokerSearchValue(this.existingApplication));
    //   this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.existingApplication));
    //   this.formControlStep2LoanTerms.setValue(applicationToLoanTerms(this.existingApplication));
    //   this.formControlStep2Brokerage.setValue(applicationToBrokerage(this.existingApplication));
    //   this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication));
    //   this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.existingApplication));
    //   this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.existingApplication));
    //   this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.existingApplication));
    // }

  }

  initStep3() {
    this.formControlStep3OrganisationType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Abn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Acn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3AnnualRevenue = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3OperatesInCommercialPremises = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PrimaryIndustry = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3IndustrySector = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrimaryBusinessAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3BusinessLandline = this.formBuilder.control('');
    this.formGroupStep3 = this.formBuilder.group({
      organisationType: this.formControlStep3OrganisationType,
      abn: this.formControlStep3Abn,
      acn: this.formControlStep3Acn,
      annualRevenue: this.formControlStep3AnnualRevenue,
      operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises,
      primaryIndustry: this.formControlStep3PrimaryIndustry,
      industrySector: this.formControlStep3IndustrySector,
      primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress,
      businessLandline: this.formControlStep3BusinessLandline,
    });
    const subscription = this.formControlStep3OrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => { // org type changed, clear applicant field
        if (r) {
          if (this.formControlStep4Applicant) {
            // this.formControlStep4Applicant.setValue(null);
          }
          if (this.step3OnwardsOrganisationType && this.step3OnwardsOrganisationType !== r.type) {
            // the org type changes and is different from prev value
            this.formControlStep4Applicant.setValue(null)
          }
          this.step3OnwardsOrganisationType = r.type;
          if (r.type === 'company') {
            /* Not required to set it again as validation is already set */
            // this.formControlStep3Acn.setValidators([Validators.required]);
          } else {
            this.formControlStep3Acn.setValidators(null);
            this.formControlStep3Acn.updateValueAndValidity();
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);


    setStepper2StepConfig(this.formGroupStep3, {
      nextStepClickedFn: async stepper => {
         if (this.formGroupStep3.value.businessLandline === null) {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: 'Invalid bussiness landline'
            });
          }
          else {
            const subscription = this.applicationDialogService.openCreditInfoExchangeAlertDialog()
              .afterClosed()
              .pipe(
                tap(async (r) => {
                  if (r && r.proceed) {
                    await this.saveApplication(stepper,3);
                  }
                })
              ).subscribe();
            this.subscriptions.push(subscription);
          }
      },
    });

  }

  initStep4() {
    this.step4BusinessSearchFn = this.applicationService.businessSearchFn;
    this.step4BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep4Applicant = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep4 = this.formBuilder.group({
      applicant: this.formControlStep4Applicant,
    });


    setStepper2StepConfig(this.formGroupStep4, {
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper,4);
      },
    });

  }

  initStep5() {
    this.step5CompanySearchFn = this.applicationService.businessSearchFn;
    this.step5BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep5Guarantors = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep5 = this.formBuilder.group({
      guarantors: this.formControlStep5Guarantors,
    });
    this.formGroupStep5.setValidators([
      guarantorValidator(() => ({
        mandatoryGuarantors: this.step5MandatoryGuarantors,
        guarantors: this.formControlStep5Guarantors.value,
      }))
    ]);


    setStepper2StepConfig(this.formGroupStep5, {
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper,5);
      },
      stepSelectionEventFn: e => {
      const guarantors = this.step5OnwardsApplicantsThatAreGuarantor();
        this.step5MandatoryGuarantors = guarantors;
      }
    });
  }

  initStep6() {
    this.step6PredefinedContactSelection = [];
    this.formControlStep6PrimaryContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep6 = this.formBuilder.group({
      primaryContact: this.formControlStep6PrimaryContact,
    });

    setStepper2StepConfig(this.formGroupStep6, {
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper,6);
      },
      stepSelectionEventFn: e => {
       const mandatoryGuarantors: IndividualGuarantor[] = this.step5MandatoryGuarantors;
          const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
          const possiblePrimaryContacts: ContactValue[] = [];
          const applicants: ApplicationApplicant = this.formControlStep4Applicant.value;

          //individual can be primary contact
          if (applicants) {
            const c: ContactValue[] = applicantsToPrimaryContact(applicants);
            if (c) {
              possiblePrimaryContacts.push(...c);
            }
          }

          // all guarantors can be primary contact
          for (const g of mandatoryGuarantors) {
            const c: ContactValue = {
              isManual: false,
              email: g.email,
              mobileNumber: g.mobileNumber,
              areaCode: '',
              lastName: g.lastName,
              firstName: g.firstName,
              title: g.title,
              telephone: g.mobileNumber,
            };
            possiblePrimaryContacts.push(c);
          }
          if (guarantors) {
            for (const g of guarantors) {
              if (g.type === 'Individual') {
                const c: ContactValue = {
                  isManual: false,
                  email: g.email,
                  mobileNumber: g.mobileNumber,
                  areaCode: '',
                  lastName: g.lastName,
                  firstName: g.firstName,
                  title: g.title,
                  telephone: g.mobileNumber,
                };
                possiblePrimaryContacts.push(c);
              }
            }
          }
          this.step6PossiblePrimaryContacts = filterOutUniquePrimaryContacts(possiblePrimaryContacts);
          const filledPrimaryContact = this.formControlStep6PrimaryContact.value;
          const contactObj = this.step6PossiblePrimaryContacts.find(a =>
            a.email === filledPrimaryContact?.email && a.mobileNumber === filledPrimaryContact?.mobileNumber &&
            a.lastName === filledPrimaryContact?.lastName && a.firstName === filledPrimaryContact?.firstName &&
            a.title === filledPrimaryContact?.title);
          if (contactObj) {
            this.formControlStep6PrimaryContact.setValue(contactObj);
          }
      }
    });

  }

  initStep7() {
    this.formControlStep7BrokerflowDocumentId = this.formBuilder.control(null);
    this.formControlStep7References = this.formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formGroupStep7 = this.formBuilder.group({
      brokerflowDocumentId: this.formControlStep7BrokerflowDocumentId,
      references: this.formControlStep7References,
    });

    setStepper2StepConfig(this.formGroupStep7, {
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper,7);
      },

    });
  }

  initStep8() {
    this.formGroupStep8 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep8, {
      nextStepClickedFn: async stepper => {
        const checkUploadFileMoreThenTwo = this.step8DriverLicenses.filter(formArg=> {
          if(formArg.formControl.value && formArg.formControl.value.length > 0){
            const existingEntry  = this.step8UploadedDrDoc.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === formArg.name)
            if (existingEntry &&  (existingEntry.length + formArg.formControl.value.length) > 2) {
              return true
            }else {
              return false;
            }
          }else {
            return false
          }
      })

      if(checkUploadFileMoreThenTwo.length > 0){
        const fileNamesArray = checkUploadFileMoreThenTwo.map(item => item.name).join(", ");
        const validationMessage = `You have uploaded too many Driver Licence files (${fileNamesArray}). Please upload only up to two files.`;

        this.applicationDialogService.openAlertDialog({
          message: `Validation Error`,
          subMessage: validationMessage
        });

      }else {
        await this.saveApplication(stepper, 8);
      }
            // await this.saveApplication(stepper,8);
      },
      nextStepButtonText: 'Next',
      stepSelectionEventFn: e => {
          const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();


            for (const prop in this.formGroupStep8.controls) {
              if (prop.startsWith('upload_')) {
                (this.formGroupStep8 as FormGroup).removeControl(prop);
              }
            }

            const driverLicences: { id: string, name: string, formControl: FormControl<UploadAzureFilesValue> }[] = [];
            for (const requireDriverLicence of allThatRequireDriverLicences) {
              const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
              const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
              if (existingEntry) {
                driverLicences.push(existingEntry);
                (this.formGroupStep8 as FormGroup).addControl(`upload_${id}`, existingEntry.formControl);
              } else {
                const name = `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`;
                const skipValidation = (this.step8UploadedDocs && this.step8UploadedDocs.length);
                const formControl = this.formBuilder.control(null, skipValidation ? [] : [Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
                driverLicences.push({ id, name, formControl });
                (this.formGroupStep8 as FormGroup).addControl(`upload_${id}`, formControl);
              }
            }
            this.step8DriverLicenses = driverLicences;
      }

    });
  }

  initStep9() {
    this.formControlStep9OtherSupportingDocuments = this.formBuilder.control(null,[duplicateFileNameValidator()]);
    this.formGroupStep9 = this.formBuilder.group({
      otherSupportingDocuments: this.formControlStep9OtherSupportingDocuments
    });

    setStepper2StepConfig(this.formGroupStep9, {
      nextStepClickedFn: async stepper => {
        const isMissingTagsCheck = this.formGroupStep9.value.otherSupportingDocuments?.filter(file => file.tags && file.tags.length === 0) ;
        if(isMissingTagsCheck?.length){
          const fileNames = isMissingTagsCheck.map(file => file.name).join(', ');
          this.applicationDialogService.openAlertDialog({
            message: `Validation Error`,
            subMessage: `The following files are missing tags: ${fileNames}`
          });

        }else {
          await this.saveApplication(stepper, 9);
        }
      },
      nextStepButtonText: 'Next',
    });

  }

  initStep10() {
    this.formControlStep10ApplicationNotes = this.formBuilder.control(null);
    this.formGroupStep10 = this.formBuilder.group({
      applicationNotes: this.formControlStep10ApplicationNotes,
    });
    setStepper2StepConfig(this.formGroupStep10, {
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper,10);
      },
      nextStepButtonText: 'Next to Review',
    });
  }

  initStep11() {

    this.formGroupStep11 = this.formBuilder.group({});
    setStepper2StepConfig(this.formGroupStep11, {
      nextStepClickedFn: async stepper => {
          this.finalSubmission(stepper, this.step11Summary!);
      },
      nextStepButtonText: 'Submit',
      stepSelectionEventFn: e => {
          const industryDivision = this.formControlStep3PrimaryIndustry.value?.division;
            const industryCode = this.formControlStep3IndustrySector.value?.code;
            const industrySector = (industryDivision && industryCode) ? getSecondaryIndustry(industryDivision, industryCode) : null;
            this.step11Summary = {
              broker: this.formControlStep1Broker.value,
              loanAmount: this.formControlStep2LoanAmount.value!,
              loanPurpose: 'Cash Flow',
              references: this.formControlStep7References.value,

              monthlyRepayment: this.step2MonthlyRepayment,
              estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
              applicationId: String(this.applicationId),
              organisation: this.formControlStep1Business.value,
              organisationType: this.formControlStep3OrganisationType.value,
              addtionalBrokerCorrespondent: null,
              abn: this.formControlStep3Abn.value!,
              acn: this.formControlStep3Acn.value!,
              annualRevenue: this.formControlStep3AnnualRevenue.value!,
              operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises.value,
              primaryIndustry: this.formControlStep3PrimaryIndustry.value,
              industrySector: industrySector ?? this.formControlStep3IndustrySector.value,
              primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress.value,
              businessLandline: this.formControlStep3BusinessLandline.value!,
              loanTerms: this.formControlStep2LoanTerms.value,
              equifaxScoreAboveThreshold: this.formControlStep2EquifaxScoreAboveThreshold.value,
              adverseOnFile: this.formControlStep2AdverseOnFile.value,
              propertyOwner: this.formControlStep2PropertyOwner.value,
              facilityEstablishmentFee: this.facilityEstablishmentFee,
              facilityEstablishmentFeePercent: this.formControlStep2FacilityEstablishmentFeePercent.value,
              brokerflowDocumentId: this.formControlStep7BrokerflowDocumentId.value!,
              paymentFrequency: this.formControlStep2PaymentFrequency.value,
              applicants: this.formControlStep4Applicant.value ?? [],

              guarantors: this.formControlStep5Guarantors.value,

              primaryContact: this.formControlStep6PrimaryContact.value!,
              applicationNotes: this.formControlStep10ApplicationNotes.value!,
              directorScore: this.formControlStep2DirectorScore.value!,
              brokerContact:null
            };
      }

    });

  }

  step5GuarantorEvent($event: GuarantorComponentEvent) {
    /// $event.entries
  }

  async finalSubmission(stepper: CdkStepper, summary: BusinessOverdraftSummary) {
    const _individualApplicants = individualApplicants(this.formControlStep4Applicant.value);
    const {
      atLeastOneWithPrivacyConsent,
      allHasPrivacyConsent,
      withoutPrivacyConsent
    } = applicantsPrivacyConsent(_individualApplicants);

    if (!allHasPrivacyConsent && !atLeastOneWithPrivacyConsent) {
      this.subscriptions.push(this.applicationDialogService.openApplicantsPrivacyConsentDialog({
        atLeastOneWithPrivacyConsent,
        applicants: withoutPrivacyConsent,
      }).afterClosed().pipe(
        tap(async (r) => {
          // console.log(await this.mapToInteflowData());
          if(r && r.type === 'save') {
            await this.updateDraftInBg(11);
            await this.agreeToTermsOfSubmission(stepper);
          }
        })
      ).subscribe());
    } else {
      await this.agreeToTermsOfSubmission(stepper);
    }
  }


  async agreeToTermsOfSubmission(stepper: CdkStepper) {
    this.subscriptions.push(this.applicationDialogService.openBusinessOverdraftApplicationConfirmationDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r && r.readyForSubmission) {
            this.businessUseOnly = true;
            this.selfServiceability = true;
            // this.privacyConfirmation = true;
            await this.sendFinalSubmission(stepper);
          }
      })
    ).subscribe());
  }

  async sendFinalSubmission(stepper: CdkStepper) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData(true);

      // update driver licence doc metadata - as the individualId will be changed
      if (this.applicationId) {
        this.subscriptions.push(
          this.applicationService.listApplicationDocumentForDirectSaleFn(this.applicationId, false, this.authorizationToken).subscribe(  //!!!!!
            (r: AzureStorageDocument[]) => {
              const driverLicenceDocs = r.filter(obj => Object.values(obj?.tags ?? {}).includes('driverslicence'));
              for (const doc of driverLicenceDocs) {
                const individual = data.Individuals.find(obj => (doc.metadata?.driverslicencename ?? '') == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
                if (individual && individual.id) {
                  this.applicationService.updateDocumentMetadataForDirectSaleFn(doc.name, "", {  //!!!!!
                    ...doc.metadata,
                    individualid: individual.id ?? ''
                  }, this.authorizationToken).subscribe()
                }
              }
            }
          )
        )
      }

      const sub = this.applicationService.submitApplicationForDirectSale(this.applicationId, data, this.authorizationToken).pipe( //!!!!!
        // this.toastService.toastObserver('submit application'),
        this.toastService.spinnerObservable(),
        tap(r => {
          if (r && r.status) {
            this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(async r => {
                stepper.reset();
                stepper.next();
                await this.router.navigate(navigationUrlForDirectSale());
              })
            ).subscribe();
          } else {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: r.message
            });
          }
        })).subscribe();
      this.subscriptions.push(sub);
    }
  }

  calculateEstimation(): OverdraftPaymentBreakupDialogData {
    const date = moment().add(1, 'day');
    const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;
    const loanTermType: LoanTermType | null = loanTermValue?.type ?? null;
    //const loanAmount = 0;
    const propertyOwner = this.formControlStep2PropertyOwner.value ?? false;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value ?? false;
    const lowEquifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value ?? false;
    const directorScore = this.formControlStep2DirectorScore.value ?? false;
    const facilityLimit = this.formControlStep2LoanAmount.value ?? 0;
    const facilityEstablishmentFeePercent = this.formControlStep2FacilityEstablishmentFeePercent.value ? Number(this.formControlStep2FacilityEstablishmentFeePercent.value.type) : 0;

    const r = calculateBusinessOverdraftEstimation(date, {
      type: 'BusinessOverdraft',
      loanTermType,
      //loanAmount,
      propertyOwner,
      adverseOnFile,
      lowEquifaxScore,
      directorScore,
      rateCard: this.rateCard,
      facilityLimit,
      facilityEstablishmentFeePercent,
    });
    this.calculator = r.calculator;
    this.brokerageDollar = r.term.brokerageDollar;
    this.brokerage = r.term.brokerage;
    this.interestRate = r.term.interestRate;
    this.rbaRate = this.rateCard?.rbaRate;
    this.facilityEstablishmentFee = r.term.facilityEstablishmentFee;
    this.docFee = r.term.docFee;
    this.terms = r.term;
    return {
      term: r.term,
    };
  }

  private step5OnwardsApplicantsThatAreGuarantor(): IndividualGuarantor[] {
    const applicant: TrusteeValue | MemberValue | PartnerValue | SoleTraderValue | DirectorValue = this.formControlStep4Applicant.value;
    const guarantors = applicantsThatAreGuarantor(applicant);
    return guarantors;
  }

  private step5OnwardsIndividualGuarantors(): IndividualGuarantor[] {
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    return individualGarantors(guarantors);
  }

  private async step2ShowQuotation(stepper: CdkStepper) {
    // todo: workout monthly repayment and estimated darwdown date, populate chart data
    // calculate and populate
    // this.step2MonthlyRepayment = '12000';
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');

    // if (!this.acceptedQuotation) { // have not accepted quotation yet
    const subscription = this.applicationDialogService.openBusinessOverdraftQuotationDialog({
      loadFn: () => {
        return this.calculateEstimation();
      },
      isQuote: false,
      hideBrokerage: true,
    }).afterClosed()
      .pipe(
        tap(r => {
          if (r && r.acceptedQuotation) {
            this.acceptedQuotation = true;
            // this.saveApplication(stepper);
            stepper.next();
          }
        })
      ).subscribe();
    this.subscriptions.push(subscription);
    // } else { // accepted quotation before
    //   this.calculateEstimation();
    //   await this.saveApplication(stepper);
    // }
  }

  private async saveApplication(stepper: CdkStepper,step: number) {
    if (!this.applicationId) { // application was not saved before (new draft)
      // console.log("Loan Purpose::::", this.formControlStep2LoanPurpose)
      const inteflowData = await this.mapToInteflowData();
      console.log('***** save application (new)', this.applicationId, inteflowData);
      this.applicationService.saveApplicationForDirectSale(inteflowData).pipe( //!!!!!
        // this.toastService.toastObserver('save draft'),
        tap(r => {
          const applicationId = r.payload.ApplicationId;
          const brokerApplicationId = r.payload.BrokerApplicationId;
          this.brokerApplicationId = brokerApplicationId;
          this.applicationId = applicationId;
          const business: BusinessSearchValue = this.formControlStep1Business.value
          if (business) {
            this.organisationName = business.organisationName;
          }
          stepper.next();
        })
      ).subscribe((response: PayloadApiResponse<SaveApplicationResult>) => {
        // send direct sale notification to the customer
        this.applicationService.sendDirectSaleDraftNotificationFn( //!!!!!
          this.formControlStep1FirstName.value ?? '',
          this.formControlStep1LastName.value ?? '',
          this.formControlStep1Email.value ?? '',
          response.payload.ApplicationId,
          'BusinessOverdraft',
          this.formControlStep1Business.value?.organisationName ?? '',
          this.authorizationToken,
        ).subscribe();
      });

    } else {  // existing draft
      const inteflowData = await this.mapToInteflowData();
      console.log('***** save application draft');
      console.log('**** inteflowdata', inteflowData);
      await this.updateDraftInBg(step);
      stepper.next();
    }
  }

  async uploadFileToAzure(step: number, data: UpdateApplicationData) {
    let base64Files: Base64File[] = [];
    let allToBeDeleted: string[] = [];
    let allFiles: File[] = [];
    if (step === 8) { // driver's licence
      const driverLicencesUpload: DriverLicenceUpload[] = this.step8DriverLicenses;
      allToBeDeleted = [...this.step8DeletedUploadedDocs].map(file => file.name);
      if (driverLicencesUpload && driverLicencesUpload.length) {
        const fileArr = driverLicencesUpload.flatMap(upload =>{
          if(upload.formControl.value){
            return upload.formControl.value!.map((f) => {
              const individual = data.Individuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
              (f as any).tags = ['driverslicence'];
              (f as any).metadata = individual?.id ? {
                ...this.step9OtherDocMetadata,
                individualid: individual.id,
                driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              } : this.step9OtherDocMetadata;
              return f;
            })
          }else {
            return []
          }
      });
        allFiles = fileArr.filter((f: File | null) => !!f) as File[];
      }

    } else if (step === 9) { // other supporting doc
      const otherSupportingDocs: UploadFileValue = this.formControlStep9OtherSupportingDocuments.value;
      allToBeDeleted = [...this.step9DeletedUploadedDocs].map(file => file.name);
      if (otherSupportingDocs && otherSupportingDocs.length) {
        allFiles = [...otherSupportingDocs];
      }
    } else {
      return;
    }
    base64Files = [...await filesToBase64Files(allFiles)];
    // double check if any changes with existing files
    // remove file that has been uploaded
    base64Files = base64Files.map(file => {
      file.metadata = file.tags?.includes('driverslicence') ? file.metadata : this.step9OtherDocMetadata
      return file;
    }).filter(f => {
      const fileIdentifiers = this.step8And9FilesUploadedToAzure.map(m => `${m.name}${m.tags?.join(' ')}`);
      return !fileIdentifiers.includes(`${f.name}${f.tags?.join(' ')}`);
    })

    this.step8And9FilesUploadedToAzure = [...this.step8And9FilesUploadedToAzure, ...base64Files];

    if (this.applicationId) {
      this.subscriptions.push(
        (await this.applicationService.uploadApplicationDocumentToAzureStorageForDirectSale( //!!!!!
            this.applicationId,
            [...base64Files],
            allToBeDeleted,
            this.authorizationToken
          )
        ).subscribe(r =>{
          if(step === 8){
            this.listUploadedDocuments()
            this.step8DriverLicenses.map(arg=>{
              arg.formControl.setValue(null)
              arg.formControl.removeValidators([Validators.required])
              arg.formControl.updateValueAndValidity();
            })
          }else if(step === 9){
            this.uploadFilesMultiTagsComponent.files = []
            this.uploadFilesMultiTagsComponent.filesWithTags = []
            this.uploadFilesMultiTagsComponent.formControl.setValue(null)
            this.listUploadedDocuments()
            this.formControlStep9OtherSupportingDocuments.setValue(null)
          }
        })
      );
    }
  }

  async mapToInteflowData(finalSubmission = false, shouldUploadDocs = false) {

    const firstName = parseString(this.formControlStep1FirstName.value ?? '');
    const lastName = parseString(this.formControlStep1LastName.value ?? '');
    const email = parseString(this.formControlStep1Email.value ?? '');

    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const correspondentDetails = null;
    const o: EntityTypeValue = this.formControlStep3OrganisationType.value;
    const applicationNotes = this.formControlStep10ApplicationNotes.value;
    const brokerflowDocumentId = this.formControlStep7BrokerflowDocumentId.value;
    const contact: SelectContactValue = this.formControlStep6PrimaryContact.value;
    const broker: AggregatorSearchComponentValue = this.formControlStep1Broker.value;
    const abn = this.formControlStep3Abn.value;
    const acn = this.formControlStep3Acn.value;
    const applicants: ApplicationApplicant = this.formControlStep4Applicant.value;
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    const refValue: ReferenceValue = this.formControlStep7References.value;
    let previousBusiness: BusinessNumberSearchValue = this.formControlStep1PreviousBusiness.value;
    // const userApplicant: CustomerApplicantTypeValue = this.formControlStep1_bCustomerApplicantType.value;

    if (b) {
        if (b.type === 'search-result') { // NOTE: when business is done throguh ASIC/ABN search
            if ((b?.result?.ABNAgeMonths ?? 0) > 12) {
                previousBusiness = null;
            }
        } else {
            previousBusiness = null
        }
    }

    // console.log("Loan ")
    // workout broker salesforce id
    let brokerSalesforceId = broker!.salesforceId;
    let brokerAbn = broker!.abn;
    let brokerEntityName = broker!.entityName;
    // if (user!.BrokerSalesforceId === environment.GrowFinanceGroupSalesforceId) {
    //   if (broker && broker.salesforceId) {
    //     brokerSalesforceId = broker.salesforceId;
    //     brokerAbn = broker.abn;
    //     brokerEntityName = broker.entityName;
    //   } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId) {
    //     brokerSalesforceId = this.brokerOfUser.SalesforceId;
    //     brokerAbn = this.brokerOfUser.ABN;
    //     brokerEntityName = this.brokerOfUser.EntityName;
    //   }
    // } else {
    //   if (this.brokerOfUser && this.brokerOfUser.SalesforceId !== undefined) {
    //     brokerSalesforceId = this.brokerOfUser.SalesforceId;
    //     brokerAbn = this.brokerOfUser.ABN;
    //     brokerEntityName = this.brokerOfUser.EntityName;
    //   }
    // }

    // workout disclosure
    let disclosed = true;
    let introducerGroup = 'ECLIPXCOMMERCIAL';
    if (this.brokerOfUser) {
      if (this.brokerOfUser.Relationship !== 'disclosed') {
        disclosed = false;
        introducerGroup = 'UNDISCLOSED_ECLIPXCOMMERCIAL';
      }
    }

    // work out legalName & entityTYpe
    let legalName = toInteflowLegalName(b) ?? undefined;
    let entityType = o ? toInteflowEntityTypes(o.type) : undefined;

    // work out industries
    const primaryIndustry: PrimaryIndustrySelectionValue = this.formControlStep3PrimaryIndustry.value;
    const secondaryIndustry: SecondaryIndustrySelectionValue = this.formControlStep3IndustrySector.value;
    const revenue = this.formControlStep3AnnualRevenue.value;
    const operateOnCommercialPremises: boolean = this.formControlStep3OperatesInCommercialPremises.value ?? false;
    const landline = this.formControlStep3BusinessLandline.value;
    const phoneNumber = landline ? landline.substring(2).trim() : undefined;
    const areaCode = landline ? landline.substring(0, 2).trim() : undefined;
    const address: Address2ComponentValue = this.formControlStep3PrimaryBusinessAddress.value;


    // entities
    const primaryCommercialEntity = {
      LegalName: legalName ?? undefined,
      EntityType: entityType,
      ABN: abn ?? undefined,
      ACN: _.isEmpty(acn) ? '0' : acn ?? undefined,
      TradingName: '',
      Type: 'Primary',
      PrimaryIndustry: primaryIndustry?.division ?? undefined,
      IndustrySector: secondaryIndustry?.code ?? undefined,
      Revenue: revenue ? Number(revenue) : undefined,
      OperateatCommercialAddressFlag: operateOnCommercialPremises,
      PhoneNumber: phoneNumber,
      AreaCode: areaCode,
      BusinessCategory: 'SME ($1m+ Revenue | $0.5m to $5m Capital)',
      PrinciplePlaceofBusiness: toInteflowAddress(address),
    }
    if (entityType !== 'PTY') {
      delete primaryCommercialEntity.ACN
    }

    // commercial entities
    const otherCommercialEntities = fromApplicantToInteflowCommercialEntities(applicants);
    const guarantorCommercialEnttities = fromGuarantorToInteflowCommercialEnttities(guarantors);
    const otherCommercialEntity = [
      ...otherCommercialEntities,
      ...guarantorCommercialEnttities,
    ];

    // individuals
    const applicantIndividuals = fromApplicantToInteflowIndividualsData(applicants);
    const guarantorIndividuals = fromGuarantorToInteflowIndividuals(guarantors);
    const otherIndividuals = [
      ...applicantIndividuals,
      ...guarantorIndividuals,
    ];


    // reference
    const references = fromReferenceToInteflowReferences(refValue);
    // pricing details
    const loanTerm: LoanTermValue = this.formControlStep2LoanTerms.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    // const brokerage: BrokerageSelectionValue = this.formControlStep2Brokerage.value;
    const paymentPeriod = 'Monthly';
    const docFeeFinanced = false;
    const docFee = this.docFee;
    // const brokerOriginationFee = this.terms?.brokerOriginationFee ?? 0;
    const brokerOriginationFee = 0; // NOTE: no broker origination fee for overdraft
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    const rate = this.interestRate;
    const rbaRate = this.rbaRate;
    const loanPurpose = 'Cash Flow';
    const brokerage = this.brokerage;
    const facilityEstablishmentFeePercent = this.formControlStep2FacilityEstablishmentFeePercent.value?.type ?? 0;
    const facilityEstablishmentFee = this.facilityEstablishmentFee
    const directorScoreRate = this.formControlStep2DirectorScore.value
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const margin = (rate == undefined || this.rbaRate == undefined) ? undefined : Math.round((rate -this.rbaRate) * 100) / 100; // to prevent from substraction overflow
    let apiBodyPricingDetails: SaveApplicationPricingDetailsData = {
      LoanTerm: loanTerm?.type ? Number(loanTerm.type) : undefined,
      // Brokerage: brokerage ? numeral(brokerage.type).value() ?? undefined : undefined
      BrokerOriginationFee: brokerOriginationFee,
      LoanAmount: loanAmount,
      PaymentPeriod: paymentPeriod,
      DocFee: (docFee != null ? Number(docFee) : undefined),
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      PropertyOwner: booleanToYesNo(propertyOwner),
      BankStatementSubmitted: 'No' as const,
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      Rate: rate,
      RbaRate: rbaRate,
      Brokerage: brokerage,
      BrokerageDollar: this.brokerageDollar,
      BrokerageAmount: this.brokerageDollar,
      FacilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent),
      FacilityEstablishmentFee: facilityEstablishmentFee,
      DirectorScoreRate: booleanToYesNo(directorScoreRate),
      Margin: margin,
      CreditRateAdjustment: 0, // when creating an application, creditRateAdjustment is 0
      LastUpdatedByUser: null, // when creating an application, lastUpdatedByUser is null
    }

    if (!propertyOwner) {
      apiBodyPricingDetails = {
        ...apiBodyPricingDetails,
        PreviousLoan: booleanToYesNo(previousLoan)
      }
    }

    // file tags
    const otherSupportingDocs: UploadAzureFilesValue = this.formControlStep9OtherSupportingDocuments.value;
    const driverLicencesUpload: DriverLicenceUpload[] = this.step8DriverLicenses;
    const allToBeDeleted = [...this.step8DeletedUploadedDocs,...this.step9DeletedUploadedDocs].map(file => file.name);
    let allFiles: File[] = [];
    if (driverLicencesUpload && driverLicencesUpload.length) {
      const fileArr = driverLicencesUpload.map(upload => {
        if(upload.formControl.value) {
         return upload.formControl.value.map((f) => {
           const individual = otherIndividuals.find(obj => upload.name == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
           (f as any).tags = ['driverslicence'];
           (f as any).metadata = individual?.id ? {
             individualid: individual?.id,
             driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
             ...this.step9OtherDocMetadata
           } : this.step9OtherDocMetadata;
           return f;
         })
        }else {
         return []
        }
     });
      allFiles = fileArr.map((f: File[]) => f && f.length ? f[0] : null).filter((f: File | null) => !!f) as File[];
    }
    if (otherSupportingDocs && otherSupportingDocs.length) {
      allFiles = [...allFiles, ...otherSupportingDocs];
    }
    allFiles = [...allFiles].filter(file => !allToBeDeleted.includes(file?.name));

    // get doc types
    let uploadedTags = allFiles.reduce((tags: string[], file) => [...tags, ...((file as any).tags ?? [])], []);
    uploadedTags = [...uploadedTags, ...this.step8UploadedDocs.reduce((tags: string[], file: AzureStorageDocument) => [...tags, ...Object.values(file?.tags ?? {})], [])];
    uploadedTags = [...new Set(uploadedTags)];
    const defaultTags = this.applicationDefaultDocuments(false);
    const allTags = this.applicationDefaultDocuments(true);
    for (const tag of uploadedTags) {
      if (!defaultTags.map(t => t.value).includes(tag)) {
        const tagObj = allTags.find(obj => obj.value === tag);
        if (tagObj) {
          defaultTags.push(tagObj);
        }
      }
    }

    const companyDetails = (b && b.type === 'search-result' && b?.result) ?
      b.result : undefined

    const apiBodyReferences = [
      ...references
    ];

    const apiBodyIndividuals = [
      ...otherIndividuals
    ];

    const apiBodyCommercialEntities = [
      primaryCommercialEntity,
      ...otherCommercialEntity,
    ];

    const apiBodyContact = fromContactToInteflowContacts(contact);

    const apiBodyAppInfo = {
      BrokerAppID: this.brokerApplicationId ?? undefined,
      IntroducerGroup: introducerGroup,
      Disclosed: disclosed,
      ApplicationType: 'BusinessOverdraft',
      FinanceType: 'Business Overdraft',
      LoanPurpose: loanPurpose,
      UserId: environment.directSaleUserId,
      CompanyId: environment.directSaleCompanyId,
      UserEmail: environment.directSaleUserEmail,
      UserFirstName: environment.directSaleUserFirstName,
      UserLastName: environment.directSaleUserLastName,
      Notes: applicationNotes ?? undefined,
      BusinessUse: booleanToYesNo(this.businessUseOnly),
      SelfServiceability: booleanToYesNo(this.selfServiceability),
      // PrivacyConfirmation: booleanToYesNo(this.formControlStep1_bPrivacyStatementCheck.value ?? null),
      BrokerflowDocumentID: brokerflowDocumentId ?? undefined,
      BrokerSalesforceID: brokerSalesforceId,
      BrokerAbn: brokerAbn,
      BrokerEntityName: brokerEntityName,
      AssetCategory: '138',
      AssetType: '1',
      ContactId: undefined,
      CustomerId: undefined,
      SalesforceId: undefined,
      StageName: 'Draft',
      // MetApplicant: userApplicant ? userApplicant.type : undefined,
    };

    const apiBody: UpdateApplicationData = {
      ApplicationType: 'BusinessOverdraft',
      IP: this.ip,
      ApplicantLocation: this.geoLocation,
      UserId: environment.directSaleUserId,
      CompanyId: environment.directSaleCompanyId,
      AppInfo: apiBodyAppInfo,
      AssetSpec: undefined,
      Contacts: apiBodyContact,
      CommercialEntities: apiBodyCommercialEntities,
      Individuals: apiBodyIndividuals,
      Reference: apiBodyReferences,
      PricingDetails: apiBodyPricingDetails,
      AcceptQuotation: this.acceptedQuotation,
      ApplicationNotes: applicationNotes ?? undefined,
      CompanyDetails: companyDetails ?? undefined,
      AdditionalBrokerCorrespondent: correspondentDetails ?? null,
      // Documents: apiBodyDocuments,
      DocumentTypes: defaultTags,
      PreviousBusiness: previousBusiness,
      Source: 'direct',
      DirectSalesUserFirstName: firstName,
      DirectSalesUserLastName: lastName,
      DirectSalesUserEmail: email,
    }
    return apiBody;
  }

  private async updateDraftInBg(step: number, fn?: () => void) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();
      await this.uploadFileToAzure(step, data);
      console.log("data---------", data)
      const sub = this.applicationService.updateApplicationForDirectSale(this.applicationId, data, this.authorizationToken).pipe( //!!!!!
        fn ? this.toastService.loadingWithMessage(`Saving, please be patient`) : tap(()=>{}),
        tap(r => {
          if (fn) {
            fn();
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onStep9DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.step9DeletedUploadedDocs = [...$event.deleted];
  }

  onStep8DeleteUploadedDoc($event: UploadedFilesListComponentEvent) { //UploadedFilesListComponentEvent

    this.step8UploadedDrDoc = this.step8UploadedDrDoc.filter(file => {
      // Check if the file should be kept in the array
      return !(file.metadata &&
               file.metadata.driverslicencename &&
               $event.deletedFile.metadata &&
               $event.deletedFile.metadata.driverslicencename &&
               $event.deletedFile.metadata.driverslicencename === file.metadata.driverslicencename);
    });

    this.step8DeletedUploadedDocs = [...$event.deleted];
    const allThatRequireDriverLicences = this.getAllThatRequireDriverLicences();

    for (const requireDriverLicence of allThatRequireDriverLicences) {
      const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
      const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
      if (existingEntry && $event.deletedFile && $event.deletedFile.metadata && $event.deletedFile.metadata.driverslicencename && $event.deletedFile.metadata.driverslicencename === existingEntry.name) {
        const isDriverslicenceExist  = $event.existing.filter(arg =>  arg.metadata && arg.metadata.driverslicencename && arg.metadata.driverslicencename === existingEntry.name)
        if(isDriverslicenceExist.length === 0){
          existingEntry.formControl.addValidators([Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)])
          existingEntry.formControl.updateValueAndValidity();
        }
      }
    }
  }

  skipFileUploadValidation() {
    return (this.step8UploadedDocs && this.step8UploadedDocs.length > 0);
  }

  private step2UpdateMaxLimit() {
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const GSTAge = (b as BusinessSearchResultValue)?.result?.GSTAgeMonths ?? 0;
    const PropertyOwner = this.formControlStep2PropertyOwner.value
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
          this.step2MaxLimit = maxLoanForBusinessOverdraft(GSTAge,PropertyOwner);
      })
    ).subscribe());
  }
  
  get step9OtherDocMetadata() {
    const b: BusinessSearchValue = this.formControlStep1Business.value;
    const abn = this.formControlStep3Abn.value ?? '';
    const legalName = toInteflowLegalName(b) ?? '';

    return {
      abn: abn,
      legalname: legalName
    };
  }

  getAllThatRequireDriverLicences() :IndividualGuarantor[] {
    const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
    const guarantors: IndividualGuarantor[] = this.step5OnwardsIndividualGuarantors();
    return [...applicantsThatAreGuarantor, ...guarantors];
  }

  onStep9DocEvent(files: UploadAzureFiles) {
    if(files && files.length > 0) {
      this.formControlStep9OtherSupportingDocuments.setValue(files)
    } else {
      this.formControlStep9OtherSupportingDocuments.setValue(null)
    }
  }

  applicationDefaultDocuments(includeOptions = false): DocumentTag[] {
    const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
    const guarantors: IndividualGuarantor[] = individualGarantors(this.formControlStep5Guarantors.value);
    const names = [...applicantsThatAreGuarantor, ...guarantors]
      .map(requireDriverLicence => `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`);
    return this.defaultDocuments(
      'BusinessOverdraft',
      names,
      toInteflowLegalName(this.formControlStep1Business.value) ?? "",
      "",
      "",
      this.formControlStep2LoanAmount.value ?? 0,
      0,
      false,
      this.formControlStep3OrganisationType.value === EntityTypeTrustOption,
      this.formControlStep3OrganisationType.value === EntityTypePartnershipOption,
      includeOptions
    );
  }

  listUploadedDocuments() {
    if (this.applicationId) {
      this.subscriptions.push(
        this.applicationService.listApplicationDocumentForDirectSaleFn(this.applicationId, false, this.authorizationToken).subscribe(
          (r: AzureStorageDocument[]) => {
            const driverslicenceDoc  = r.filter(arg =>  arg.metadata && arg.metadata.driverslicencename)
            const supportDoc  = r.filter(arg =>  arg.metadata && !arg.metadata.driverslicencename)
            this.step8UploadedDocs = driverslicenceDoc;
            this.step9UploadedDocs = supportDoc;
            this.step8UploadedDrDoc  = driverslicenceDoc
            console.log("=====uploaded driverslicenceDoc docs: ", driverslicenceDoc);
            console.log("=====uploaded supportDoc docs: ", supportDoc);
          }
        )
      )
    }
  }

  get authorizationToken() {
    const firstName = parseString(this.formControlStep1FirstName.value ?? '');
    const lastName = parseString(this.formControlStep1LastName.value ?? '');
    const email = parseString(this.formControlStep1Email.value ?? '');
    return Buffer.from(`${this.applicationId}=${firstName}=${lastName}=${email}`).toString('base64');;
  }
}
