import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {getCustomerEntityTypes, CustomerEntityType, CustomerEntityTypeValue, CustomerEntityTypeValueOptions, compareMatch} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'customer-entity-type',
    templateUrl: './customer-entity-type.component.html',
    styleUrls: ['./customer-entity-type.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomerEntityTypeComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class CustomerEntityTypeComponent extends AbstractControlValueAccessor<CustomerEntityTypeValue> implements OnInit {

  @Input({required: false}) title = 'Aggregator type';

  subscriptions: Subscription[] = [];

  options = CustomerEntityTypeValueOptions;

  formGroup: FormGroup<{
    entityType: FormControl<CustomerEntityTypeValue>
  }>;
  formControl: FormControl<CustomerEntityTypeValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      entityType: this.formControl,
    })
  }

  ngOnInit(): void {
    const subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  doWriteValue(v: CustomerEntityTypeValue | undefined): void | CustomerEntityTypeValue {
    if (v) {
      const op = CustomerEntityTypeValueOptions.find(opt => opt?.type === v.type);
      this.formControl.setValue(op ?? null);
    }
    return undefined;
  }

}
