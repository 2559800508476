<div class="currency-input-component" [formGroup]="formGroup">
  <mat-form-field [ngClass]="{'highlight-form-field': highlight}">
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    @if (prefix){
      <div class="mdi mdi-currency-usd" matPrefix></div>
    }
    
    <input matInput currencyMask [disableControl]="disabled" maxlength="11"   [options]="options" placeholder="{{placeholder? placeholder : '0.00'}}" [readonly]="readonly" type="text"
      [formControl]="formControlValue">
     
      @if (hint) {
        <mat-hint #currency_input_hint id="currency_input_hint">{{hint}}</mat-hint>
      }
      @for (errorKey of errorKeys(formControlValue); track errorKey) {
        <mat-error>
          @if (formControlValue.touched && formControlValue.hasError(errorKey)) {
            {{errorMessage(formControlValue, errorKey)}}
          }
        </mat-error>
      }
  </mat-form-field>
</div>
