import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ApiResponse, DirectDebitDialogData, DirectDebitDialogResult, LocalBankAccountDetailValue, ManualDirectDebitFn, NewDirectPaymentBody, OverdraftDirectDebitDialogData, OverdraftDirectDebitDialogResult, PismoOnetimeRepaymentBody} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationDialogService } from './application-dialog.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './overdraft-direct-debit.dialog.html',
    styleUrls: ['./overdraft-direct-debit.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class OverdraftDirectDebitDialog implements OnInit {

  disableControls = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    amount: FormControl<number | null>,
  }>;
  formControlAmount!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  manualDirectDebitFn!: ManualDirectDebitFn;
  pismoAccountId!: number;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: OverdraftDirectDebitDialogData,
              private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private applicationDialogService: ApplicationDialogService,
              private dialogRef: MatDialogRef<OverdraftDirectDebitDialog, OverdraftDirectDebitDialogResult>) {
    this.manualDirectDebitFn = dialogData.manualDirectDebitFn;
    this.pismoAccountId = dialogData.pismoAccountId;
    this.formControlAmount = formBuilder.control(null, [Validators.required, Validators.min(0.01)]);
    this.formGroup = this.formBuilder.group({
      amount: this.formControlAmount
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  submit() {
    if (this.formGroup.valid) {
      const amount = this.formControlAmount.value;
      if (amount) {
        this.subscriptions.push(
          this.manualDirectDebitFn({
            pismoAccountId: this.pismoAccountId,
            amount: amount,
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((response: ApiResponse) => {
            if (response.status) {
              this.applicationDialogService.successDialog({
                message: "Success",
                subMessage: response.message
              }).afterClosed().subscribe(() => {
                this.dialogRef.close({
                  readyForSubmission: true
                });
              })
            } else {
              this.applicationDialogService.openAlertDialog({
                message: "Error",
                subMessage: response.message
              }).afterClosed().subscribe(() => {
                this.dialogRef.close();
              })
            }
          })
        )
      } else {
        this.applicationDialogService.openAlertDialog({
          message: "Error",
          subMessage: "Invalid amount"
        }).afterClosed().subscribe(() => {
          this.dialogRef.close();
        })
      }
    }

  }

  cancel() {
    this.dialogRef.close();
  }
}
