
export type RepaymentTypeValue = {
  type: 'Advance' | 'Arrears',
  name: string;
} | null;

export const RepaymentTypeOptions: Exclude<RepaymentTypeValue, null>[] = [
  { type: 'Advance', name: 'Advance' },
  { type: 'Arrears', name: 'Arrears' },
];
