import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ExtendTermsDialogData, ExtendTermsDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';

import { MatRadioModule } from '@angular/material/radio';

@UntilDestroy({ arrayName: 'subscription' })
@Component({
    selector: 'extend-terms-dialog',
    templateUrl: './extend-terms.dialog.html',
    styleUrls: ['./extend-terms.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatRadioModule, MatButtonModule, FlexModule, MatDialogModule]
})
export class ExtendTermsDialog implements OnInit {
  subscriptions: Subscription[] = [];
  formGroup!: FormGroup<{
    requestedTerm : FormControl<number | null>
  }>;
  formControlTerm!: FormControl<number | null>;

  title!: string;
  description!: string;
  vin!: string;
  terms: number[] = [];
  currentTerm!: number;
  maxTerm!: number;
  termGap = 30;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExtendTermsDialogData,
    private matDialogRef: MatDialogRef<ExtendTermsDialog, ExtendTermsDialogResult>,
    private fb: FormBuilder,
  ) {
    this.formControlTerm = fb.control(null, [Validators.required]);
    this.formGroup = fb.group({
      requestedTerm: this.formControlTerm
    })
  }

  ngOnInit(): void {
    this.title = this.data.vehicleTitle;
    this.description = this.data.vehicleDescription;
    this.vin = this.data.vehicleVIN;
    this.currentTerm = this.data.currentTerm;
    this.maxTerm = this.data.maxTerm;

    this.getAvailableTerms();
  }

  getAvailableTerms() {
    this.terms = [];
    let tmp = this.termGap;
    while (tmp <= this.maxTerm) {
      this.terms.push(tmp);
      tmp += this.termGap;
    }
  }

  onCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {
    const extendTermsDialogResult: ExtendTermsDialogResult =  {
      requestedTerm:  Number(this.formControlTerm.value),
    };

    this.matDialogRef.close(extendTermsDialogResult);
  }
}
