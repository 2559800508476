<div class="datepicker-component">

  <!-- optional & disabled -->
  @if (optional && disabled) {
    <mat-form-field class="datepicker">
      @if (title) {
        <mat-label>{{title}}</mat-label>
      }
      <input
        placeholder="dd/mm/yyyy"
        [inputMask]="createDateInputMask"
        (dateChange)="onDateChange($event)"
        [errorStateMatcher]="errorStateMatcher"
        matInput
        [matDatepicker]="picker1"
        [formControl]="formControl"
        readonly="readonly"
        >
        <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="disabled"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        @for (errorKey of errorKeys(formControl); track errorKey) {
          <mat-error>
            @if (formControl.touched && formControl.hasError(errorKey)) {
              {{errorMessage(formControl, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    }

    <!-- optional & not disabled -->
    @if (optional && !disabled) {
      <mat-form-field class="datepicker">
        @if (title) {
          <mat-label>{{title}}</mat-label>
        }
        <input
          placeholder="dd/mm/yyyy"
          [inputMask]="createDateInputMask"
          (dateChange)="onDateChange($event)"
          [errorStateMatcher]="errorStateMatcher"
          matInput
          [matDatepicker]="picker2"
          [formControl]="formControl"
          >
          <mat-datepicker-toggle matSuffix [for]="picker2" [disabled]="disabled"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          @for (errorKey of errorKeys(formControl); track errorKey) {
            <mat-error>
              @if (formControl.touched && formControl.hasError(errorKey)) {
                {{errorMessage(formControl, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      }

      <!-- not optional & disabled -->
      @if (!optional && disabled) {
        <mat-form-field class="datepicker">
          @if (title) {
            <mat-label>{{title}}</mat-label>
          }
          <input
            placeholder="dd/mm/yyyy"
            [inputMask]="createDateInputMask"
            (dateChange)="onDateChange($event)"
            [errorStateMatcher]="errorStateMatcher"
            matInput
            [matDatepicker]="picker3"
            [formControl]="formControl"
            required
            readonly="readonly"
            >
            <mat-datepicker-toggle matSuffix [for]="picker3" [disabled]="disabled"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            @for (errorKey of errorKeys(formControl); track errorKey) {
              <mat-error>
                @if (formControl.touched && formControl.hasError(errorKey)) {
                  {{errorMessage(formControl, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        }

        <!-- not optional & not disabled -->
        @if (!optional && !disabled) {
          <mat-form-field class="datepicker">
            @if (title) {
              <mat-label>{{title}}</mat-label>
            }
            <input
              placeholder="dd/mm/yyyy"
              [inputMask]="createDateInputMask"
              (dateChange)="onDateChange($event)"
              [errorStateMatcher]="errorStateMatcher"
              matInput
              [matDatepicker]="picker4"
              [formControl]="formControl"
              required
              >
              <mat-datepicker-toggle matSuffix [for]="picker4" [disabled]="disabled"></mat-datepicker-toggle>
              <mat-datepicker #picker4></mat-datepicker>
              @for (errorKey of errorKeys(formControl); track errorKey) {
                <mat-error>
                  @if (formControl.touched && formControl.hasError(errorKey)) {
                    {{errorMessage(formControl, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          }

        </div>
