import {UntilDestroy} from "@ngneat/until-destroy";
import {Component, forwardRef, inject, OnInit} from "@angular/core";
import {
  FormBuilder,
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import numeral from 'numeral';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {Subscription} from "rxjs";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {
  compareMatch,
  LtvSelectionType,
  LtvSelectionValueOptions,
  SecurityTypeRateValue, SecurityTypeSelectionType,
  SecurityTypeSelectionValueOptions
} from "@portal-workspace/grow-shared-library";

import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {InputMaskModule} from "@ngneat/input-mask";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import {createTwoDecimalInputMask, } from '@portal-workspace/grow-ui-library';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'security-type-rate',
  templateUrl: './security-type-rate.component.html',
  styleUrls: ['./security-type-rate.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>SecurityTypeRateComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(()=>SecurityTypeRateComponent), multi: false },
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    FlexModule,
    MatButtonModule,
    MatTooltipModule
]
})
export class SecurityTypeRateComponent extends AbstractControlValueAccessor<SecurityTypeRateValue> implements Mark, OnInit {


  createTwoDecimalInputMask = createTwoDecimalInputMask();

  securityTypeOptions = SecurityTypeSelectionValueOptions;
  ltvOptions = LtvSelectionValueOptions;

  formBuilder = inject(FormBuilder);

  formControlRecords: Record<`${SecurityTypeSelectionType}-${LtvSelectionType}`, FormControl<number | null>> = {
    'first-mortgage-low': this.formBuilder.control(null, [Validators.required]),
    'first-mortgage-medium': this.formBuilder.control(null, [Validators.required]),
    'first-mortgage-high': this.formBuilder.control(null, [Validators.required]),
    'second-mortgage-low': this.formBuilder.control(null, [Validators.required]),
    'second-mortgage-medium': this.formBuilder.control(null, [Validators.required]),
    'second-mortgage-high': this.formBuilder.control(null, [Validators.required]),
    'caveat-low': this.formBuilder.control(null, [Validators.required]),
    'caveat-medium': this.formBuilder.control(null, [Validators.required]),
    'caveat-high': this.formBuilder.control(null, [Validators.required]),
  };
  formGroup: FormGroup<Record<`${SecurityTypeSelectionType}-${LtvSelectionType}`, FormControl<number | null>>> =
    this.formBuilder.group({
      'first-mortgage-low': this.formControlRecords['first-mortgage-low'],
      'first-mortgage-medium': this.formControlRecords['first-mortgage-medium'],
      'first-mortgage-high': this.formControlRecords['first-mortgage-high'],
      'second-mortgage-low': this.formControlRecords['second-mortgage-low'],
      'second-mortgage-medium': this.formControlRecords['second-mortgage-medium'],
      'second-mortgage-high': this.formControlRecords['second-mortgage-high'],
      'caveat-low': this.formControlRecords['caveat-low'],
      'caveat-medium': this.formControlRecords['caveat-medium'],
      'caveat-high': this.formControlRecords['caveat-high'],
    });

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v) => {
        if (this.formGroup.valid) {
          const val: SecurityTypeRateValue = {
            'first-mortgage': {
              'low': numeral(this.formControlRecords['first-mortgage-low'].value).value() ?? 0,
              'medium': numeral(this.formControlRecords['first-mortgage-medium'].value).value() ?? 0,
              'high': numeral(this.formControlRecords['first-mortgage-high'].value).value() ?? 0,
            },
            'second-mortgage': {
              'low': numeral(this.formControlRecords['second-mortgage-low'].value).value() ?? 0,
              'medium': numeral(this.formControlRecords['second-mortgage-medium'].value).value() ?? 0,
              'high': numeral(this.formControlRecords['second-mortgage-high'].value).value() ?? 0,
            },
            'caveat': {
              'low': numeral(this.formControlRecords['caveat-low'].value).value() ?? 0,
              'medium': numeral(this.formControlRecords['caveat-medium'].value).value() ?? 0,
              'high': numeral(this.formControlRecords['caveat-high'].value).value() ?? 0,
            },
          };
          this.propagateChange(val);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  override doWriteValue(v?: SecurityTypeRateValue | undefined): void | SecurityTypeRateValue {
    if (v) {
      this.formControlRecords['first-mortgage-low'].setValue(v["first-mortgage"].low);
      this.formControlRecords['first-mortgage-medium'].setValue(v["first-mortgage"].medium);
      this.formControlRecords['first-mortgage-high'].setValue(v["first-mortgage"].high);

      this.formControlRecords['second-mortgage-low'].setValue(v["second-mortgage"].low);
      this.formControlRecords['second-mortgage-medium'].setValue(v["second-mortgage"].medium);
      this.formControlRecords['second-mortgage-high'].setValue(v["second-mortgage"].high);

      this.formControlRecords['caveat-low'].setValue(v["caveat"].low);
      this.formControlRecords['caveat-medium'].setValue(v["caveat"].medium);
      this.formControlRecords['caveat-high'].setValue(v["caveat"].high);
    }
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }


  formControl(securityType: SecurityTypeSelectionType, ltvType: LtvSelectionType): FormControl<number | null> {
    return this.formControlRecords[`${securityType}-${ltvType}`]
  }

}
