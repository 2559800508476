<div class="policies-details-component">

  @for (policy of policies; track policy; let index = $index) {
    <div class="section">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Policy {{index + 1}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Expiry date</div>
            <div body>{{policy.expiryDate}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Inception date</div>
            <div body>{{policy.inceptionDate}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Insurer</div>
            <div body>{{policy.insurer}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Invoice number</div>
            <div body>{{policy.invoiceNumber}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Policy number</div>
            <div body>{{policy.policyNumber}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Premium amount</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{policy.premiumAmount}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Type of cover</div>
            <div body>{{policy.typeOfCover}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    </div>
  }
</div>
