<form [formGroup]="formGroup" class="business-search2-component"
  (click)="pageClick($event)">
  <mat-form-field class="width-100">
    <mat-label>{{title}}</mat-label>
    <input type="text" [placeholder]="placeholder" matInput [readonly]="readOnly" [formControl]="formControl"
      [matAutocomplete]="auto">
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWithFn" (optionSelected)="onOptionSelected($event)">
        @if (allowFreeText) {
          <div>
            <mat-option>
              <div (click)="onSearchEnter($event)">
                <span>Entity name not found, enter it manually</span>
              </div>
            </mat-option>
          </div>
        }
        @if (filteredEntries.length>0) {
          <div>
            @for (entry of filteredEntries; track entry) {
              <mat-option [value]="entry">
                <div class="option-title">{{entry.organisationName}}</div>
                <div class="option-details mat-caption">
                  @if (entry.abn) {
                    <span>ABN:{{entry.abn}}</span>
                  }
                  @if (entry.acn) {
                    <span>ACN:{{entry.acn}}</span>
                  }
                </div>
              </mat-option>
            }
          </div>
        }
      </mat-autocomplete>
      @if (state == 'loading') {
        <mat-spinner diameter="25" matSuffix></mat-spinner>
      }
      @if (state == 'selected') {
        <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix />
      }
      @if (state == 'error') {
        <img src="/assets/images/cross-danger.png" alt="chevron"  matSuffix />
      }
      <!--
      <mat-spinner diameter="25" matSuffix *ngIf="loading&&!(filteredEntries.length>0)"></mat-spinner>
      <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix *ngIf="selected" />
      -->
    </mat-form-field>
  </form>
