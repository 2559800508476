import {Component, forwardRef, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {productsSelectedValidator} from '@portal-workspace/grow-ui-library';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {ProductSelection, ProductSelectionValue} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProductSelectionComponent), multi: true },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule]
})
export class ProductSelectionComponent extends AbstractControlValueAccessor<ProductSelectionValue> implements OnInit  {

  value?: ProductSelectionValue;

  subscriptions: Subscription[] = [];

  // subscription: Subscription;


  formGroup: FormGroup <{
    assetFinance: FormControl<boolean | null>
    businessLoan: FormControl<boolean | null>
    insurancePremium: FormControl<boolean | null>
  }>;
  formControlSelectedAllProducts: FormControl<boolean | null>;
  formControlSelectedAssetFinance: FormControl<boolean | null>;
  formControlSelectedBusinessLoan: FormControl<boolean | null>;
  formControlSelectedInsurancePremium: FormControl<boolean | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlSelectedAllProducts = formBuilder.control(false);
    this.formControlSelectedAssetFinance = formBuilder.control(false);
    this.formControlSelectedBusinessLoan = formBuilder.control(false);
    this.formControlSelectedInsurancePremium = formBuilder.control(false);
    this.formGroup = formBuilder.group({
      assetFinance: this.formControlSelectedAssetFinance,
      businessLoan: this.formControlSelectedBusinessLoan,
      insurancePremium: this.formControlSelectedInsurancePremium
    });
    this.formGroup.addValidators([productsSelectedValidator(this.formGroup)])
    // this.subscription = this.formControlSelectedAllProducts.valueChanges
    //   .pipe(tap(r => {
    //   /*   this.formControlSelectedAssetFinance.setValue(r);
    //     this.formControlSelectedBusinessLoan.setValue(r);
    //     this.formControlSelectedInsurancePremium.setValue(r);
    //   })).subscribe();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  showError(): boolean {
    return this.formGroup.invalid &&
      (this.formControlSelectedAllProducts.touched || this.formControlSelectedAllProducts.dirty ||
      this.formControlSelectedAssetFinance.touched || this.formControlSelectedAssetFinance.dirty ||
      this.formControlSelectedBusinessLoan.touched || this.formControlSelectedBusinessLoan.dirty ||
      this.formControlSelectedInsurancePremium.touched || this.formControlSelectedInsurancePremium.dirty)
  }

  onChange($event: MatCheckboxChange) {
    const productSelection: ProductSelection[] = [];
    if ($event.source.name === "formControlSelectedAllProducts") {
      // Update all individual checkboxes based on the state of "Select All" checkbox
      this.formControlSelectedAssetFinance.setValue($event.checked);
      this.formControlSelectedBusinessLoan.setValue($event.checked);
      this.formControlSelectedInsurancePremium.setValue($event.checked);
    } else {
      // Update the state of "Select All" checkbox based on individual checkboxes
      const allIndividualCheckboxesChecked = (
        this.formControlSelectedAssetFinance.value &&
        this.formControlSelectedBusinessLoan.value &&
        this.formControlSelectedInsurancePremium.value
      );
      this.formControlSelectedAllProducts.setValue(allIndividualCheckboxesChecked);
    }
    if (this.formControlSelectedAssetFinance.value) {
      productSelection.push('asset-finance');
    }
     
    if (this.formControlSelectedBusinessLoan.value) {
      productSelection.push('business-loan');
    }
    if (this.formControlSelectedInsurancePremium.value) {
      productSelection.push('insurance-premium');
    }
    this.propagateChange(productSelection.length ? productSelection : null);
  }

  doWriteValue(v?: ProductSelectionValue | null): void | ProductSelectionValue {
    if (v && ([
      'asset-finance', 'business-loan', 'insurance-premium',
    ] as const).every(e => (v ?? []).includes(e))) {
      this.formControlSelectedAllProducts.setValue(true)
    } else {
      this.formControlSelectedAllProducts.setValue(false);
    }
    this.formControlSelectedAssetFinance.setValue(v?.includes('asset-finance') ?? false);
    this.formControlSelectedBusinessLoan.setValue(v?.includes('business-loan') ?? false);
    this.formControlSelectedInsurancePremium.setValue(v?.includes('insurance-premium') ?? false);
    return undefined;
  }

  reset(): void {
    this.formGroup.reset();
  }
}
