<form class="bpay-bank-details-component" [formGroup]="formGroup">
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Biller code</mat-label>
        <input [inputMask]="createBillerCodeMask" [formControl]="formControlBpayBillerCode" matInput placeholder="Enter biller code">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="">
        <mat-label>CRN</mat-label>
        <input [inputMask]="createBpayCRNMask" [formControl]="formControlBpayCrnCode" matInput placeholder="Enter CRN">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="">
        <mat-label>Biller Name (readonly)</mat-label>
        <input readonly [formControl]="formControlBpayBillerName" matInput placeholder="Biller Name">
        @for (errorKey of errorKeys(formControlBpayBillerName); track errorKey) {
          <mat-error>
            @if (formControlBpayBillerName.touched && formControlBpayBillerName.hasError(errorKey)) {
              {{errorMessage(formControlBpayBillerName, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    @if (!hideAmount) {
      <div class="col-6">
        <currency-input title="Amount" [formControl]="formControlAmount" matInput placeholder="Enter Amount" type="number"></currency-input>
      </div>
    }
  </div>
</form>
