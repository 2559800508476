import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {RiskAnalysisComponent} from './risk-analysis.component';
import { RiskRatingTableComponent } from './risk-rating-table-component/risk-rating-table.component';
import { RiskRatingChartComponent } from './risk-rating-chart-component/risk-rating-chart.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        RiskAnalysisComponent,
        RiskRatingTableComponent,
        RiskRatingChartComponent
    ],
    exports: [
        RiskAnalysisComponent,
        RiskRatingTableComponent,
        RiskRatingChartComponent
    ]
})
export class RiskAnalysisModule {

}
