import {validApplicationTypeGuard} from './abstract-application.guard';

export const validCorporateLoansGuard = () => {
  return validApplicationTypeGuard('CorporateLoans', 'e-not-corporate');
}

// NOTE: Deprecated since Angular 16, replaced by validApplicationTypeGuard() instead
// import { AbstractApplicationGuard } from './abstract-application.guard';
// import { Injectable } from '@angular/core';
// import { ApplicationTypes } from '@portal-workspace/grow-shared-library';
//
//
// @Injectable()
// export class ValidCorporateLoansGuard extends AbstractApplicationGuard {
//
//   getApplicationType(): ApplicationTypes {
//     return 'CorporateLoans';
//   }
//
//   getApplicationTypeErrorCode(): string {
//     return 'e-not-corporate';
//   }
//
//
// }
