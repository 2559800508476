import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, noSpaceValidator, setupUntilDestroy,YesNoComponent} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, AssetPurposeValue, AssetPurposeValueOptions,AssetExistingFinanceCommitmentOptions, AssetExistingFinanceCommitmentValue,AssetPurposeComponentValue, YesNoValue } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
 
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';

@UntilDestroy()
@Component({
    selector: 'asset-purpose-selection',
    templateUrl: './asset-purpose-selection.component.html',
    styleUrls: ['./asset-purpose-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetPurposeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetPurposeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatInputModule,YesNoComponent]
})
export class AssetPurposeSelectionComponent extends AbstractControlValueAccessor<AssetPurposeComponentValue> implements OnInit, Mark {

  formGroup: FormGroup<{
    assetPurpose:FormControl<AssetPurposeValue>;
    assetPurposeDescription: FormControl<string|null>,
    assetExistingFinanceCommitment: FormControl<AssetExistingFinanceCommitmentValue>,
  }>;

  formAssetPurpose: FormControl<AssetPurposeValue>;
  formControlAssetDescription: FormControl<string|null>;
  formControlAssetexistingFinanceCommitment: FormControl<AssetExistingFinanceCommitmentValue>;

  subscription?: Subscription;
  formGroupSubscription?: Subscription;
  markObservable: Subject<boolean> = new Subject<boolean>();
  _showAssetPurposeDescription = false;
  _showAssetAssetExistingFinanceCommitment = false;
  assetPurposeDescriptionHelpText = "";
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  options = AssetPurposeValueOptions;
  assetExistingFinanceCommitmentOptions = AssetExistingFinanceCommitmentOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formAssetPurpose = formBuilder.control(null, [Validators.required]);
    this.formControlAssetDescription = formBuilder.control(null);
    this.formControlAssetexistingFinanceCommitment = formBuilder.control(null);

    this.formGroup = formBuilder.group({
      assetPurpose: this.formAssetPurpose,
      assetPurposeDescription:this.formControlAssetDescription,
      assetExistingFinanceCommitment:this.formControlAssetexistingFinanceCommitment
    });

    
    this.formGroupSubscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r) => {
        if (this.formGroup.invalid) {
          this.propagateChange(null);
        } else {
          console.log('propagateChange: ', r);
          this.propagateChange(r);
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    
    this.formAssetPurpose.valueChanges.pipe(
      tap((r)=> {
         switch (r && r.type) {
          case 'replacing-existing-business-asset':
              this._showAssetPurposeDescription = false
              this.assetPurposeDescriptionHelpText=""
              this._showAssetAssetExistingFinanceCommitment = true
              this.formControlAssetexistingFinanceCommitment.setValidators([Validators.required]);
              this.formControlAssetexistingFinanceCommitment.updateValueAndValidity();
              
              this.formControlAssetDescription.setValue(null)
              this.formControlAssetDescription.clearValidators();
              this.formControlAssetDescription.updateValueAndValidity();

              break;
              case 'support-business-expansion':
              this._showAssetAssetExistingFinanceCommitment = false
              this._showAssetPurposeDescription = false
              this.assetPurposeDescriptionHelpText=""
              
              this.formControlAssetDescription.setValue(null)
              this.formControlAssetDescription.clearValidators();
              this.formControlAssetDescription.updateValueAndValidity();
              
              this.formControlAssetexistingFinanceCommitment.setValue(null)
              this.formControlAssetexistingFinanceCommitment.clearValidators();
              this.formControlAssetexistingFinanceCommitment.updateValueAndValidity();
              break;
            case 'income-generating-reason':
              this._showAssetAssetExistingFinanceCommitment = false
              this._showAssetPurposeDescription = true
              this.assetPurposeDescriptionHelpText="Please explain how"
              this.formControlAssetDescription.setValidators([Validators.required ,noSpaceValidator()]);
              this.formControlAssetDescription.updateValueAndValidity();

              this.formControlAssetexistingFinanceCommitment.setValue(null)
              this.formControlAssetexistingFinanceCommitment.clearValidators();
              this.formControlAssetexistingFinanceCommitment.updateValueAndValidity();
              break;
            case 'other-non-income-generating-reason':
              this._showAssetAssetExistingFinanceCommitment = false
              this._showAssetPurposeDescription = true
              this.assetPurposeDescriptionHelpText="Provide reason"
              this.formControlAssetDescription.setValidators([Validators.required,noSpaceValidator()]);
              this.formControlAssetDescription.updateValueAndValidity();
              
              this.formControlAssetexistingFinanceCommitment.setValue(null)
              this.formControlAssetexistingFinanceCommitment.clearValidators();
              this.formControlAssetexistingFinanceCommitment.updateValueAndValidity();
              break;
            default:
        } 
      })
    ).subscribe();
  }

  doWriteValue(v: AssetPurposeComponentValue | null |undefined): void | AssetPurposeComponentValue {
    if (v) {
      const t = AssetPurposeValueOptions.find(opt => opt.type === v.assetPurpose?.type);
      //this.formControl.setValue(t ? t : null);
      console.log('============= asset-purpose dowritevalue', v);
      this.formAssetPurpose.setValue(t ? t : null)
      this.formControlAssetDescription.setValue(v.assetPurposeDescription!)
      const c = AssetExistingFinanceCommitmentOptions.find(opt => opt.type === v.assetExistingFinanceCommitment?.type);
      this.formControlAssetexistingFinanceCommitment.setValue(c ? c : null)
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

}
