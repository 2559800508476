import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {httpOptions, } from '@portal-workspace/grow-ui-library';
import {
  ApiResponse, ContractDetails,
  ContractEmailBody, ContractStatusResponse, DeclineContractBody, DeleteContractBody,
  ExchangeDocusignTokenPayload, GenerateContractBody, GenerateContractResponse,
  PayloadApiResponse, SendContractResponse, SyncContractDetailsBody,
} from '@portal-workspace/grow-shared-library';
import { environment } from '../../environments/environment';

const URL_GET_DOCUSIGN_CONSENT_URL = () => `${environment.api2Host}/api2/get-docusign-consent-url`; // get
const URL_UPDATE_DOCUSIGN_TOKEN = (code: string) =>`${environment.api2Host}/api2/update-docusign-token?code=${code}`; // post
const URL_REFRESH_DOCUSIGN_TOKEN = () =>`${environment.api2Host}/api2/refresh-docusign-token`; // post
const URL_GET_DOCUSIGN_TOKEN = () =>`${environment.api2Host}/api2/get-docusign-token`; // get
const URL_SEND_CONTRACT_EMAILS = () => `${environment.api2Host}/api2/docusign-send-contract-emails`; // post
const URL_GET_CONTRACT_STATUS = (applicationId: number) => `${environment.api2Host}/api2/docusign-get-contract-status/${applicationId}`; // get
const URL_GENERATE_CONTRACT = ()  => `${environment.api2Host}/api2/docusign-generate-contract`; // post
const URL_DELETE_CONTRACT_FILE = ()  => `${environment.api2Host}/api2/docusign-delete-contract-file`; // post
const URL_GET_ACTIVE_CONTRACT = (applicationId: number) => `${environment.api2Host}/api2/docusign-get-active-contract-for-application/${applicationId}`; // get
const URL_DECLINE_CONTRACT = () => `${environment.api2Host}/api2/docusign-decline-contract`; // post
const URL_SYNC_CONTRACT_DETAILS_TO_SF = () => `${environment.api2Host}/api2/sync-contract-details-to-sf`; //post

@Injectable()
export class DocusignService {

  constructor(private httpClient: HttpClient) { }

  getDocusignConsentUrl(): Observable<string> {
    return this.httpClient.get<PayloadApiResponse<string>>(URL_GET_DOCUSIGN_CONSENT_URL(), httpOptions())
      .pipe(map((r: PayloadApiResponse<string>) => r.payload));
  }

  updateDocusignToken(code: string = ""): Observable<ExchangeDocusignTokenPayload> {
    return this.httpClient.post<PayloadApiResponse<ExchangeDocusignTokenPayload>>(URL_UPDATE_DOCUSIGN_TOKEN(code), {}, httpOptions())
      .pipe(map((r: PayloadApiResponse<ExchangeDocusignTokenPayload>) => r.payload));
  }

  refreshDocusignToken(): Observable<ExchangeDocusignTokenPayload> {
    return this.httpClient.post<PayloadApiResponse<ExchangeDocusignTokenPayload>>(URL_REFRESH_DOCUSIGN_TOKEN(), httpOptions())
      .pipe(map((r: PayloadApiResponse<ExchangeDocusignTokenPayload>) => r.payload));
  }

  getDocusignToken(): Observable<ExchangeDocusignTokenPayload> {
    return this.httpClient.get<PayloadApiResponse<ExchangeDocusignTokenPayload>>(URL_GET_DOCUSIGN_TOKEN(), httpOptions())
      .pipe(map((r: PayloadApiResponse<ExchangeDocusignTokenPayload>) => r.payload));
  }

  sendContractEmails(body: ContractEmailBody) {
    return this.httpClient.post<PayloadApiResponse<any>>(URL_SEND_CONTRACT_EMAILS(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<SendContractResponse>) => r.payload));
  }

  sendContractEmailsFn = (body: ContractEmailBody): Observable<SendContractResponse> => {
    return this.sendContractEmails(body);
  }

  getContractStatus(applicationId: number): Observable<ContractStatusResponse> {
    return this.httpClient.get<PayloadApiResponse<ContractStatusResponse>>(URL_GET_CONTRACT_STATUS(applicationId), httpOptions())
      .pipe(map((r: PayloadApiResponse<ContractStatusResponse>) => r.payload));
  }

  getContractStatusFn = (applicationId: number): Observable<ContractStatusResponse> => {
    return this.getContractStatus(applicationId);
  }

  generateContract(body: GenerateContractBody): Observable<GenerateContractResponse> {
    return this.httpClient.post<PayloadApiResponse<GenerateContractResponse>>(URL_GENERATE_CONTRACT(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<GenerateContractResponse>) => r.payload));
  }

  generateContractFn = (body: GenerateContractBody): Observable<GenerateContractResponse> => {
    return this.generateContract(body);
  }

  deleteContractFile(body: DeleteContractBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_DELETE_CONTRACT_FILE(), body, httpOptions());
  }

  deleteContractFileFn = (body: DeleteContractBody): Observable<ApiResponse> => {
    return this.deleteContractFile(body);
  }

  getActiveContractForApplication(applicationId: number): Observable<ContractDetails | null> {
    return this.httpClient.get<PayloadApiResponse<ContractDetails | null>>(URL_GET_ACTIVE_CONTRACT(applicationId), httpOptions())
      .pipe(map((r: PayloadApiResponse<ContractDetails | null>) => r.payload));
  }

  getActiveContractForApplicationFn = (applicationId: number): Observable<ContractDetails | null> => {
    return this.getActiveContractForApplication(applicationId);
  }

  declineContract(body: DeclineContractBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_DECLINE_CONTRACT(), body, httpOptions());
  }

  declineContractFn = (body: DeclineContractBody): Observable<ApiResponse> => {
    return this.declineContract(body);
  }

  syncContractDetailsToSf(body: SyncContractDetailsBody) {
    return this.httpClient.post<ApiResponse>(URL_SYNC_CONTRACT_DETAILS_TO_SF(), body, httpOptions());
  }

  syncContractDetailsToSfFn = (body: SyncContractDetailsBody) => {
    return this.syncContractDetailsToSf(body);
  }
}
