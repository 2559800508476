<div class="pismo-update-require-fixed-monthly-installment-flag-dialog m-4">
  <h4 mat-dialog-title>Toggle Direct Debit</h4>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        @if (currentStopDirectDebit) {
          Do you want to enable direct debit for this account?
        }

        @if (!currentStopDirectDebit) {
          Do you want to disable direct debit for this account?
        }
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayoutGap="5px"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">No</button>
      <button mat-flat-button class="w-lt-md-100" color="primary" (click)="onSave($event)">Yes</button>
    </div>
  </mat-dialog-actions>
</div>

