
<div class="driver-licence-card-number-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      @if (title) {
        <mat-label>{{title}}</mat-label>
      }
      <input matInput [inputMask]="createDriverLicenceCardNumberInputMask"
        placeholder="{{placeholder}}" type="text" [formControl]="formControl" [required]="mandatory" >
        @for (errorKey of errorKeys(formControl); track errorKey) {
          <mat-error>
            @if (formControl.touched && formControl.hasError(errorKey)) {
              {{errorMessage(formControl, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </form>
  </div>
