import {Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Application,
  AzureStorageDocument, constants, Metadata,
  PpsrAsset,
  PpsrAssetValue,
  User,
  isInternalUser,
  AssetSettlementGetApplicationAssetFn, ConfirmationDialogResult, SearchGrantorsResult, AssetSupplier, isAdmin, GetApplicationOwnerFn, CreateAssetInspectionFn, VerimotoInspectionTableDataWithInspectionDetails, NewAssetInspectionDialogResult, VerimotoLenderType, VerimotoInspectionTypeSelection, parseJSON,
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subject, Subscription, combineLatest} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  ApplicationDialogService,
  AssetSettlementRemoveApplicationAssetFn, AssetSettlementSearchAssetsFn,
  AssetSettlementUpdateApplicationAssetsFn,
  DownloadApplicationDocumentUrlFn,
  ListApplicationDocumentFn,
  getUser,
  setupUntilDestroy, openWindowAndDownload, openWindowAndDownloadWithFilename, AssetSettlementSearchGrantorsFn, GetApplicationAuditLogsFn, AuditLogComponent,
} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import { createAsyncStore, loadingFor } from '@ngneat/loadoff';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  getAssetTypePPSR,
  getSupplierName,
} from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { AssetPpsrDetailsComponent } from './asset-ppsr-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatMenuModule } from '@angular/material/menu';
import { PpsrStatusChipComponent } from '../ppsr-status-component/ppsr-status-chip.component';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe } from '@angular/common';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service';
import{CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export class AssetDetailsDataSource implements DataSource<PpsrAsset> {

  subject: Subject<PpsrAsset[]> = new BehaviorSubject<PpsrAsset[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PpsrAsset[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: PpsrAsset[]) {
    this.subject.next(data);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-details',
    templateUrl: './asset-details.component.html',
    styleUrls: ['./asset-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatFormFieldModule, MatTooltipModule, TagBoxComponent, PpsrStatusChipComponent, MatMenuModule, FlexModule, MatDividerModule, AssetPpsrDetailsComponent, AsyncPipe, LooseCurrencyPipe]
})
export class AssetDetailsComponent implements OnInit {

  subscriptions: Subscription[] = [];
  dataSource: AssetDetailsDataSource = new AssetDetailsDataSource();
  assetDetails: any = [];
  data:any;
  getAssetTypePPSR = getAssetTypePPSR;
  total = 0;
  displayedColumns: string[] = ['icon','assetNumber','assets', 'invoiceAmount', 'supplier', 'invoice', 'status', 'ppsr' ,'actions'];
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  @Input({required: true}) removeApplicationAssetFn!: AssetSettlementRemoveApplicationAssetFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) createAssetInspectionFn!: CreateAssetInspectionFn;
  @Output() onEditAsset: EventEmitter<PpsrAssetValue> = new EventEmitter<PpsrAssetValue>();
  @Output() emptyAsset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input({required: true}) searchAssetFn!: AssetSettlementSearchAssetsFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) isPpsrChecked!: Boolean;
  @Input({required: true}) isPpsrRegistered!: Boolean;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) verimotoLender!: VerimotoLenderType;
  @Input({required: false}) assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Input({required: false}) verimotoAssetInspectionTypes!: VerimotoInspectionTypeSelection[];
  @Output() onDeleteClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onPPSRStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newAssetInspection: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddAuditLog: EventEmitter<boolean> = new EventEmitter<boolean>();
  assetStatus:any=[];
  expandedElement!: any | null;
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  store = createAsyncStore();
  loader = loadingFor('tableLoading');
  loggedInUser: User | null = getUser()
  isInternalUser = isInternalUser;
  isAdmin = isAdmin;
  getSupplierName = getSupplierName;

  constructor(private applicationDialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService,
    private toastService: PortalHotToastService,
    private ppsrService: PpsrService,
  ){}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
  }

  removeAsset(control: any) {
    this.subscriptions.push(
    this.applicationDialogService.openConfirmationDialog({
      message: 'Please confirm',
      subMessage: 'Are you sure you want to delete this asset?'
    }).afterClosed().pipe(
      tap(async (r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            if (!isNaN(control.AssetId) && control.AssetId !== null) {
              this.subscriptions.push(this.removeApplicationAssetFn(control.AssetId).pipe(
                this.portalHotToastService.spinnerObservable(),
                this.portalHotToastService.snackBarObservable("Asset Deleted"),
                tap(r => {
                  this.reload();
                  this.onDeleteClick.emit(true)
                })
              ).subscribe());
            }
          }
        })
      ).subscribe()
    )
  }

  retry(){
    this.reload();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'icon': return '';
      case 'assetNumber': return '#';
      case 'assets': return 'Assets';
      case 'invoiceAmount': return 'Invoice Amount';
      case 'supplier': return 'Supplier';
      case 'invoice': return 'Invoice';
      case 'status': return 'Status';
      case 'ppsr': return 'PPSR';
      case 'supplierPpsr': return 'Supplier PPSR';
      case 'actions': return '';
      default: return column;
    }
  }

  ngOnChanges() {
    if(this.isPpsrChecked || this.isPpsrRegistered){
      this.reload();
    }
  }

  reload() {
    this.store = createAsyncStore();
    this.subscriptions.push(combineLatest([
      this.getApplicationAssetFn(this.application.ApplicationId),
      this.listApplicationDocumentFn(this.application.ApplicationId)
    ]) .pipe(
      this.toastService.spinnerObservable(),
      this.loader.tableLoading.track(),
    ).subscribe(
      ([assets, documents]: [PpsrAsset[], AzureStorageDocument[]]) => {
        this.assetDetails = this.getTaxInvoiceForAsset(assets, documents);

        this.data = this.assetDetails.length;
        if (!this.assetDetails.length){
          this.emptyAsset.emit(true);
        }

        for (let i = 0; i < this.assetDetails.length; i++) {
          if (this.assetDetails[i].PPSRStatus === 'registered') {
            this.isPpsrRegistered = true;
          }
        }
        console.log("res::", this.assetDetails)
        const asset: PpsrAsset[] = [];
        for (const assetDetail of assets) {
          asset.push(assetDetail);
        }
        this.dataSource.update(asset);
        this.assetStatus = this.assetDetails.filter((r: { status: string; }) => r.status === 'pending' || r.status === 'rejected');
        if (this.assetStatus.length) {
          this.onPPSRStatus.emit(true);
        }
        else {
          this.onPPSRStatus.emit(false);
        }
      }
    ))
  }


  getAssetDetail(stock: PpsrAsset) {
    if(stock.SettlementAssetDetails?.serialNumber){
    this.searchAssetFn(this.application.ApplicationId, {
      SearchNumber: stock.SettlementAssetDetails.serialNumber,
      NumberType:stock.SettlementAssetDetails.serialNumberType?.replace(/ +/g, ''),
    }).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        const data:any = r
        if (data?.Error) {
          let asset: any = {}
          asset.AssetId = stock.AssetId;
          asset.PPSRStatus = 'issue';
          this.updateAssetsToDatabase(asset, 'save')
          this.reload();
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try after sometime',
          });
        }
        if (r && !data.Error) {
          let asset: any = {}
          asset.AssetId = stock.AssetId;
          asset.SettlementAssetDetails = ({
            ...stock.SettlementAssetDetails,
            VehicleDetails: data,
          })
          asset.PPSRStatus = 'confirmed';
          asset.PrivateSellerDetails = stock.PrivateSellerDetails;
          this.updateAssetsToDatabase(asset, 'save')
          this.reload();
          this.applicationDialogService.successDialog({
            message: 'Success',
            subMessage: 'PPSR check completed',
          });
        }
        // else {
        //   let asset: any = {}
        //   asset.AssetId = stock.AssetId;
        //   asset.PPSRStatus = 'confirmed';
        //   this.updateAssetsToDatabase(asset, 'save')
        //   this.applicationDialogService.openAlertDialog({
        //     message: 'Warn',
        //     subMessage: 'Unable to find asset',
        //   });
        // }
      })
    ).subscribe();
    }
    else{
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Please enter valid vehicle number',
      });
    }
  }

  // getSearchGrantors(element: any) {
  //   console.log(element)
  // }

  getAssetInspection(assetId: number) {
    return this.assetInspectionTableData.find(obj => {
      const externalRefSplits = obj.ExternalRef.split('-');
      if (externalRefSplits.length > 1) {
        return Number(externalRefSplits[1]) === assetId;
      }
      return false;
    })
  }

  disableSupplierPPSR(element: PpsrAsset) {
    return !(element.SettlementAssetDetails?.supplier || (
      !element.SettlementAssetDetails?.supplier && element?.PrivateSellerDetails?.business &&
      (element?.PrivateSellerDetails?.business.abn || element?.PrivateSellerDetails?.business.acn)
    ));
  }

  getSearchGrantors(element: PpsrAsset) {
    let data: any = {}
    let abn = '', acn = '';
    if (element.SettlementAssetDetails?.supplier) {
      abn = (element.SettlementAssetDetails?.supplier as AssetSupplier)?.ABN;
      acn = (element.SettlementAssetDetails?.supplier as AssetSupplier)?.ACN ?? '';
    } else {
      abn =  element?.PrivateSellerDetails?.business?.abn ?? '';
      acn =  element?.PrivateSellerDetails?.business?.acn ?? '';
    }
    if (acn) {
      data = {
        "SearchNumber": acn,
        "NumberType": 'ACN',
        "SearchType": "G"
      }

    }
    else if (abn) {
      data = {
        "SearchNumber": abn,
        "NumberType": "ABN",
        "SearchType": "G"
      }
    }
    else {
      console.log("No abn and acn");
      this.applicationDialogService.openAlertDialog({
        message: "Alert",
        subMessage: "No ABN or ACN exists for this supplier."
      })
      return;
    }
    this.searchGrantorsFn(this.application.ApplicationId, {
      // this.searchGrantorsFn(this.application.ApplicationId, {
      SearchNumber: data.SearchNumber,
      NumberType: data.NumberType,
      SearchType: "G"
    }).pipe(
      this.toastService.spinnerObservable(),
      tap((r: SearchGrantorsResult) => {
        const data: SearchGrantorsResult = r
        if (data.Error) {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try after sometime',
          });
        } else {
          if (r.payload && r.status) {
            const grantorDetails = r.payload['SearchResult'].GrantorOrganisationSearchCriteriaVerificationDetail;
            if (grantorDetails === 'NotFound') {
              this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Grantor details not found' })
            } else {
              let asset: PpsrAsset = {}
              const collateralClassSummary = r.payload.SearchResult.SearchResultSummary.CollateralClassSummary.CollateralClassSearchSummaryDetail
              if (collateralClassSummary.length > 0) {
                //Has ppsr
                asset.AssetId = element.AssetId
                asset.SettlementAssetDetails = { ...element.SettlementAssetDetails!, supplierPPSRStatus: 'confirmed' }
                this.updateAssetsToDatabase(asset, 'save')
                this.reload();
                this.applicationDialogService.successDialog({
                  message: "Success",
                  subMessage: "Supplier PPSR check has been completed. The result file is saved in the Documents tab."
                }).afterClosed().subscribe()
              } else {
                // Do not have ppsr?
                asset.AssetId = element.AssetId
                asset.SettlementAssetDetails = { ...element.SettlementAssetDetails!, supplierPPSRStatus: 'issue' }
                this.updateAssetsToDatabase(asset, 'save')
                this.reload();
                this.applicationDialogService.successDialog({
                  message: "Success",
                  subMessage: "Supplier PPSR check has been completed. No results are found for this supplier."
                }).afterClosed().subscribe()
              }
            }
            this.reload();
          } else {
            this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please check details and try again.' })
          }
        }
      })
    ).subscribe();
  }

  async updateAssetsToDatabase(assetupdatedata: PpsrAsset, key: string) {
    console.log("assetupdatedata",assetupdatedata.SettlementAssetDetails)
    this.updateApplicationAssetsFn(assetupdatedata).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      tap(r => {
       console.log("success")
      }
      )).subscribe();
  }

  async editAsset(control: any, assetNumber:number) {
    console.log('function called on the editAsset');
    control.assetNumber=assetNumber
    console.log("Control::::",control)
    this.onEditAsset.emit(control);
  }

  async AcceptAsset(control: PpsrAsset, assetNumber: number) {
    const auditData = {
      UserId: this.loggedInUser?.UserId!,
      ApplicationId: control.ApplicationId!,
      Field: 'status',
      OldValue: control.status!,
      NewValue: 'accepted',
      AssetNumber: assetNumber,
      AssetId: control.AssetId!,
    }

    control.status = 'accepted'
    delete control.taxInvoices;
    this.updateApplicationAssetsFn(control).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      ).subscribe(() => {
      this.onPPSRStatus.emit(false);
      this.reload();
    }); 

    this.ppsrService.addAuditLog(auditData).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Audit log added'),
      tap(r => {
        this.onAddAuditLog.emit(true);
      })
    ).subscribe();

  }

  async RejectAsset(control: PpsrAsset, assetNumber: number) {
    const auditData = {
      UserId: this.loggedInUser?.UserId!,
      ApplicationId: control.ApplicationId!,
      Field: 'status',
      OldValue: control.status!,
      NewValue: 'rejected',
      AssetNumber: assetNumber,
      AssetId: control.AssetId!,
    }

    control.status = 'rejected';
    delete control.taxInvoices;
    this.updateApplicationAssetsFn(control).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      ).subscribe(() => {
      this.onPPSRStatus.emit(true);
      this.reload();
    });

    this.ppsrService.addAuditLog(auditData).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Audit log added'),
      tap(r => {
        this.onAddAuditLog.emit(true);
      })
    ).subscribe();

  }

  getTaxInvoiceForAsset(assets: PpsrAsset[], documents: AzureStorageDocument[]) {
    const taxInvoices = documents.filter(doc => {
      const tags = doc?.tags ? Object.values(doc.tags) : [];
      return tags.includes(constants.documentTypes.taxInvoice.value);
    });

    for (const taxInvoice of taxInvoices) {
      const assetIdString = (taxInvoice.metadata as Metadata)['assetid'] ?? '';
      const assetIds = assetIdString.split('-').map(id => Number(id));
      for (const assetId of assetIds) {
        const findAssetIndex = assets.findIndex(asset => asset.AssetId === assetId);
        if (findAssetIndex > -1) {
          if (assets[findAssetIndex]?.taxInvoices && assets[findAssetIndex]?.taxInvoices?.length) {
            assets[findAssetIndex] = {
              ...assets[findAssetIndex],
              taxInvoices: [
                taxInvoice,
                ...(assets[findAssetIndex].taxInvoices as AzureStorageDocument[])
              ]
            }
          } else {
            assets[findAssetIndex] = {
              ...assets[findAssetIndex],
              taxInvoices: [taxInvoice]
            }
          }
        }
      }
    }

    return assets;
  }

  onDownloadDocument(doc: AzureStorageDocument) {
    this.downloadApplicationDocumentUrlFn(doc.name, "", 0).pipe(
      this.portalHotToastService.loadingWithMessage(`Downloading ...`),
      tap((blob: Blob) => {
        openWindowAndDownloadWithFilename(((doc.metadata as Metadata)?.['name'] ?? doc.name), blob);
      }),
    ).subscribe();
  }

  createAssetInspection(element: PpsrAsset) {
    this.subscriptions.push(
      this.applicationDialogService.openNewAssetInspectionDialog({
        application: this.application,
        asset: element,
        getApplicationOwnerFn: this.getApplicationOwnerFn,
        createAssetInspectionFn: this.createAssetInspectionFn,
        verimotoLender: this.verimotoLender,
        verimotoAssetInspectionTypes: this.verimotoAssetInspectionTypes,
      }).afterClosed().subscribe(
        (result: NewAssetInspectionDialogResult | undefined) => {
          if (result && result.newInspection) {
            this.newAssetInspection.emit(true);
          }
        }
      )
    )
  }

  assetRequiresInpection(element: PpsrAsset) {
    if (element.SettlementAssetDetails?.supplier) {
      return false;
    } else {
      if (['132', '137', '130', '140'].includes(element.SettlementAssetDetails?.assetCategory ?? '')) {
        return true;
      } else if (element.SettlementAssetDetails?.assetCategory === '139' && element.SettlementAssetDetails?.assetType !== '4') {
        return true;
      } else {
        return false;
      }
    }
  }

}



