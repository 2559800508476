<div class="pismo-edit-account-dialog m-4">
  <h4 mat-dialog-title>Enable Overdraft Account</h4>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <customer-access-level-and-role-access
          [formControl]="formControlCustomerAccessLevel">
        </customer-access-level-and-role-access>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave()"  [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>
