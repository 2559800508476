import { Component, Input } from '@angular/core';
import { statusBox } from '@portal-workspace/grow-shared-library';


@Component({
    selector: 'status-box',
    templateUrl: './status-box.component.html',
    styleUrls: ['./status-box.component.scss'],
    standalone: true,
    imports: []
})
export class StatusBoxComponent {
  @Input({required: false}) type: statusBox = 'draft';
}
