import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Router} from '@angular/router';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import {RegistrationService} from '../../service/registration.service';
import {tap} from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { EmailComponentValue } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './forgot-password.page.html',
    styleUrls: ['./forgot-password.page.scss'],
    standalone: true,
    imports: [FlexModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, EmailComponent, MatButtonModule]
})
export class ForgotPasswordPage implements AfterViewInit, OnInit {

  subscriptions: Subscription[] = [];

  currentTemplate: TemplateRef<any> | null = null;

  @ViewChild('formTemplate') formTemplate!: TemplateRef<any>;
  @ViewChild('confirmationTemplate') confirmationTemplate!: TemplateRef<any>;
  // @ViewChild('setPasswordTemplate') setPasswordTemplate!: TemplateRef<any>;
  formGroup!: FormGroup<{
    email: FormControl<EmailComponentValue>
  }>;
  formControlEmail!: FormControl<EmailComponentValue>;
  formGroupPassword!: FormGroup<{
    password: FormControl<EmailComponentValue>
  }>;
  formControlPassword!: FormControl<string | null>;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private minimalLayoutService: MinimalLayoutService,
              private registrationService: RegistrationService) {
    this.formControlEmail = formBuilder.control('', [Validators.required, Validators.email]);
    this.formGroup = formBuilder.group({
      email: this.formControlEmail,
    });
    this.formControlPassword = formBuilder.control('', [Validators.required]);
    this.formGroupPassword = formBuilder.group({
      password: this.formControlPassword,
    });
  }

  ngOnInit() {
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: true,
      showAccreditationInContact: false,
      showContact: false,
    });
    const subscription = this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch(r.type) {
            case 'cross-clicked': {
              await this.router.navigate(navigationUrlForLogin());
              break;
            }
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.formTemplate;
    });
  }

  onContinue($event: Event) {
    this.registrationService.sendForgotPassword({
      Email: this.formControlEmail.value ?? '',
    }).pipe(
      // this.toastService.toastObserver2('processing request'),
      tap(r => {
        if(r.status) {
          setTimeout(() => {
            this.currentTemplate = this.confirmationTemplate;
          });
        } else {
          this.dialogService.openAlertDialog({
            message: 'Error',
            subMessage: r.message,
          });
        }
      })
    ).subscribe();
  }

  // onSavePassword($event: Event) {
  //   setTimeout(() => {
  //     this.currentTemplate = this.confirmationTemplate;
  //   });
  // }

  async onLoginAgain($event: MouseEvent) {
    await this.router.navigate(navigationUrlForLogin());
  }
}
