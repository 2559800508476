import {Component, Input} from '@angular/core';
import { TermRateForCorporateLoan } from '@portal-workspace/grow-shared-library';
import {CorporateLoanApplication} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import _ from 'lodash';
import {LooseCurrencyPipe} from "../../../pipes/loose-currency.pipe";
import {PercentagePipe} from "../../../pipes/percentage.pipe";


@Component({
    selector: 'corporate-loans-quote',
    templateUrl: './corporate-loans-quote.component.html',
    styleUrls: ['./corporate-loans-quote.component.scss'],
    standalone: true,
    imports: [FlexModule, MatDividerModule, LooseCurrencyPipe, PercentagePipe]
})
export class CorporateLoansQuoteComponent {

  _ = _;

  @Input({required: true}) application!: CorporateLoanApplication;
  @Input({required: false}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: false}) amortizationChartData!: AmortizationChartData;
  @Input({required: false}) paymentChartData!: PaymentChartData;
  @Input({required: false}) repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input({required: true}) data!:TermRateForCorporateLoan
}
