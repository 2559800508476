<div class="documents-page container-fluid">

  <div class="row">
    <div class="col-12">
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails" [defaultIcon]="'folder-outline'"></breadcrumb>
    </div>
  </div>

  @if (directoryDepth === 1) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search1">
          <input matInput class="ml-2" [readonly]="loader.tableLoading.inProgress$ | async" type="text" [formControl]="formControlSearchStep1" placeholder="Search for Company Name, ABN, Consumer Name, DoB...">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
  }

  @if (directoryDepth === 2) {
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search2">
          <input matInput class="ml-1" type="text" [formControl]="formControlSearchStep2" placeholder="Search for Application ID">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <div fxLayout="row"
          fxLayoutAlign="end center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <button class="custom-button w-lt-md-100" (click)="onUploadDocument()">
            <span class="mdi mdi-tray-arrow-up icon"></span>
            <span class="text">Upload</span>
          </button>
        </div>
      </div>
    </div>
  }

  @if (directoryDepth === 3) {
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <button class="custom-button w-lt-md-100" (click)="onUploadDocument(tagValueDepth3)">
          <span class="mdi mdi-tray-arrow-up icon"></span>
          <span class="text">Upload</span>
        </button>
      </div>
    </div>
  }



  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
        <span>Search for historical documents by Application ID</span>
        <mat-form-field class="search3">
          <input matInput class="ml-2" [readonly]="loader.tableLoading.inProgress$ | async" type="text" [formControl]="formControlSearchApplicationIdStep1" placeholder="Search for Application ID">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      @if (loader.tableLoading.inProgress$ | async) {
        <custom-content-loader></custom-content-loader>
      }
      @if (!(loader.tableLoading.inProgress$ | async)) {
        <ng-container class="documents-table">
          <!-- Layer 1 -->
          @if (directoryDepth === 1) {
            <table mat-table [dataSource]="dataSourceDepth1" multiTemplateDataRows class="width-100 mb-2">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="column-width-1">
                  <div class="cell">Company & Consumer</div>
                </th>
                <td mat-cell *matCellDef="let element" (click)="moveToDepth2(element)">
                  <div class="cell"
                    fxLayoutAlign="start center"
                    fxLayout="row wrap">
                    @if (element.type === 'company') {
                      <span class="mdi mdi-folder-outline"></span>
                      <div fxLayoutAlign="start start"
                        fxLayout="column">
                        <span>{{element['companyLegalName']}}</span>
                        <span class="date-text">ABN: {{element['companyAbn']}}</span>
                      </div>
                    }
                    @if (element.type === 'consumer') {
                      <span class="mdi mdi-folder-outline"></span>
                      <div fxLayoutAlign="start start"
                        fxLayout="column">
                        <span>{{element['consumerName']}}</span>
                        <span class="date-text">DOB: {{element['consumerDob']}}</span>
                      </div>
                    }
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="column-width-2"></th>
                <td mat-cell *matCellDef="let element" (click)="onDownloadCompany(element)">
                  <div fxLayout="row"
                    fxLayoutAlign="end center"
                    class="cell">
                    <span class="mdi mdi-tray-arrow-down cursor-pointer" matTooltip="Download All"></span>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="['name', 'action']; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: ['name', 'action'];"
                class="example-element-row">
              </tr>
            </table>
            @if (total) {
              <app-custom-paginator
                [length]="total"
                [pageIndex]="offset"
                [pageSize]="limit"
                [pageSizeOptions]="[10, 20, 25, 50, 100]"
                (page)="onPagination($event)"
              ></app-custom-paginator>
            }
          }
          <!-- Layer 2 -->
          @if (directoryDepth === 2) {
            <table mat-table [dataSource]="dataSourceDepth2" multiTemplateDataRows class="width-100 mb-2">
              <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef class="column-width-1">Name</th>
                <td mat-cell *matCellDef="let element" (click)="moveToDepth3(element)">
                  <div class="cell"
                    fxLayoutAlign="start center"
                    fxLayout="row wrap">
                    @if (element) {
                      <span class="mdi mdi-folder-outline"></span> {{displayDocumentTag(element['groupName'])}}
                    }
                    @if (!element) {
                      <span class="mdi mdi-folder-multiple-outline"></span> [...]
                    }
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="column-width-2"></th>
                <td mat-cell *matCellDef="let element" (click)="onDownloadDocumentType(element)">
                  @if (element) {
                    <div fxLayout="row"
                      fxLayoutAlign="end center"
                      class="cell"
                      >
                      <span class="mdi mdi-tray-arrow-down cursor-pointer" matTooltip="Download All"></span>
                    </div>
                  }
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="['documentType', 'action']; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: ['documentType', 'action'];"
                class="example-element-row">
              </tr>
            </table>
          }
          <!-- Layer 3 -->
          @if (directoryDepth === 3) {
            <message-box type="info">It can take up to one hour to reflect your latest document changes on this page.</message-box>
            <table mat-table [dataSource]="dataSourceDepth3" multiTemplateDataRows class="width-100 mb-2 mt-2">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="column-width-1">Name</th>
                <td mat-cell *matCellDef="let element" (click)="onMoveBackToDepth2(element)">
                  <div class="cell"
                    fxLayoutAlign="start center"
                    fxLayout="row wrap">
                    @if (element) {
                      <div fxLayoutAlign="space-between center"
                        fxLayout="row wrap"
                        class="width-100">
                        <div fxLayoutAlign="start center"
                          fxLayout="row wrap">
                          <span class="mdi mdi-file-document-outline"></span>
                          <div fxLayoutAlign="start start"
                            fxLayout="column">
                            <span>{{element?.metadata?.name}}</span>
                            <span class="date-text">{{element?.properties?.lastModified | date:'dd/MM/yyyy h:mm:ss a'}}</span>
                          </div>
                        </div>
                        @if (isDocumentHasAppId(element)) {
                          <div class="application-id">
                            Application ID: {{getDocumentAppId(element)}}
                          </div>
                        }
                      </div>
                    }
                    @if (!element) {
                      <span class="mdi mdi-folder-multiple-outline"></span> [...]
                    }
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="column-width-2"></th>
                <td mat-cell *matCellDef="let element">
                  @if (element) {
                    <div fxLayout="row"
                      fxLayoutAlign="end center"
                      class="cell"
                      >
                      <span class="mdi mdi-tray-arrow-down cursor-pointer" matTooltip="Download" (click)="onDownloadDoc(element)"></span>
                    </div>
                  }
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef=" ['name', 'action']; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: ['name', 'action'];"
                class="example-element-row">
              </tr>
            </table>
          }
        </ng-container>
      }
    </div>
  </div>
</div>

