@if (!((store.value$ | async)?.error)) {
  <div class="customer-table-component container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="center start"
          class="search-input">
          <mat-form-field class="search" fxFlex="1 1 auto">
            <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async"
              [formControl]="formControlSearch" placeholder="Search">
              <span class="mdi mdi-magnify" matPrefix></span>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          @if (loader.tableLoading.inProgress$ | async) {
            <custom-content-loader type="list"></custom-content-loader>
          }
          <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
            <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSort($event)">
              <ng-container matColumnDef="SalesforceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Salesforce Id</th>
                <td class="ellipsis" mat-cell *matCellDef="let element">{{element.SalesforceId}}</td>
              </ng-container>
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Entity name</th>
                <td class="ellipsis" mat-cell *matCellDef="let element">{{element.EntityName}}</td>
              </ng-container>
              <ng-container matColumnDef="acn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ACN</th>
                <td mat-cell *matCellDef="let element">{{element.ACN}}</td>
              </ng-container>
              <ng-container matColumnDef="abn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ABN</th>
                <td mat-cell *matCellDef="let element">{{element.ABN}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
              <tr class="cursor-pointer" (click)="onRowClicked($event, element)" mat-row
              *matRowDef="let element; columns: displayColumns"></tr>
            </table>
            @if (total) {
              <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
            }
          </div>
        </div>
      </div>
    </div>
  }
