import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  BankingPaymentType,
  BatchPayment,
  NewBatchPaymentDialogData,
  NewBatchPaymentDialogResult
} from '@portal-workspace/grow-shared-library';
import {FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  templateUrl: './new-batch-payment.dialog.html',
  styleUrls: ['./new-batch-payment.dialog.scss'],
  standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatOptionModule, NgClass, AsyncPipe, MatDialogModule]
})
export class NewBatchPaymentDialog implements OnInit{
  BankingPaymentType = BankingPaymentType;
  formGroup!: FormGroup;
  formControlType!: FormControl<BankingPaymentType | null>;
  formControlDate!: FormControl<string | null>;
  formControlUserName!: FormControl<string | null>;
  formControlUserNumber!: FormControl<number | null>;
  formControlDescription!: FormControl<string | null>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: BatchPayment,
              private dialogRef: MatDialogRef<NewBatchPaymentDialog, NewBatchPaymentDialogResult>,
              private formBuilder: FormBuilder) {
    this.formControlType = formBuilder.control(this.data?.type ?? null, [Validators.required]);
    this.formControlDate = this.formBuilder.control(this.data?.date ?? null, [Validators.required]);
    this.formControlUserName = this.formBuilder.control(this.data?.userName ?? null);
    this.formControlUserNumber = this.formBuilder.control(this.data?.userNumber ?? null);
    this.formControlDescription = this.formBuilder.control(this.data?.description ?? null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      date: this.formControlDate,
      userName: this.formControlUserName,
      userNumber: this.formControlUserNumber,
      description: this.formControlDescription,
      type: this.formControlType,
    })
  }

  ngOnInit() {
    this.formControlType.valueChanges.subscribe((v: BankingPaymentType | null) => {
      if (v === BankingPaymentType.BPAY) {
        this.formControlUserName.clearValidators();
        this.formControlUserNumber.clearValidators();
        this.formControlUserName.setValue(null);
        this.formControlUserNumber.setValue(null);
        this.formControlUserName.updateValueAndValidity();
        this.formControlUserNumber.updateValueAndValidity();
      } else if (v === BankingPaymentType.DIRECT) {
        this.formControlUserName.setValidators([Validators.required]);
        this.formControlUserNumber.setValidators([Validators.required]);
        this.formControlUserName.setValue(null);
        this.formControlUserNumber.setValue(null);
        this.formControlUserName.updateValueAndValidity();
        this.formControlUserNumber.updateValueAndValidity();
      }
    })
  }

  onSubmit(readyForSubmission: boolean) {
    if (this.formGroup.value) {
      let result = {
        readyForSubmission: readyForSubmission,
        batchPayment: {
          ...this.formGroup.value
        }
      };
      if (this.data) {
        result.batchPayment.id = this.data.id;
      }
      this.dialogRef.close(result);
    }
  }
}
