import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleValue } from '@portal-workspace/grow-shared-library';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import {ToggleModule} from "./toggle.module";
import {FlexLayoutModule} from "@angular/flex-layout";

@Component({
    selector: 'ui-lib-status-toggle',
    templateUrl: './status-toggle.component.html',
    styleUrls: ['./status-toggle.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StatusToggleComponent), multi: true }
    ],
    standalone: true,
    imports: [
    MatCardModule,
    NgClass,
    ExtendedModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule
]
})
export class StatusToggleComponent extends AbstractControlValueAccessor<SlideToggleValue> implements OnInit {
  @Input({required: false}) showCancelConfirmation: boolean = true;
  @Input({required: false}) cancelConfirmationMessage: string = `Are you sure?`;
  @Input({required: false}) defaultValue: boolean = false;

  checkedFlag = false;

  formControl!: FormControl<SlideToggleValue | null>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formControl = this.formBuilder.control(true);
  }

  doWriteValue(v: SlideToggleValue | null | undefined): void | SlideToggleValue {
    this.formControl.setValue(!!v);
    this.checkedFlag = !!v;
    return undefined;
  }

  onEnableChangeToggle($event: MatSlideToggleChange) {
    if (this.checkedFlag) { // currently checked, attempting to uncheck
      if (this.showCancelConfirmation) {
        if (confirm(this.cancelConfirmationMessage)) {
          this.checkedFlag = !this.checkedFlag;
          this.propagateChange(this.checkedFlag);
        } else {
          $event.source.checked = true;
        }
      } else {
        this.checkedFlag = !this.checkedFlag;
        this.propagateChange(this.checkedFlag);
      }
    } else { // currently unchecked, attempting to check
      this.checkedFlag = !this.checkedFlag;
      this.propagateChange(this.checkedFlag);
    }
  }
}
