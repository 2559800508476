import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AdminService, } from './admin.service';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { OriginatorBusiness } from '@portal-workspace/grow-shared-library';


@Injectable()
export class AggregatorResolver  {

  constructor(private adminService: AdminService,
              private hotToastService: PortalHotToastService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OriginatorBusiness | null> | Promise<OriginatorBusiness | null> | OriginatorBusiness | null {
    const originatorId = route.params.originatorId;
    return this.adminService.getOriginatorById(originatorId).pipe(
      map(r => {
        return r.payload;
      }),
      catchError(err => {
        console.log('Originator resolver error ', err);
        return of(null);
      })
    );
  }

}
