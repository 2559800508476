<div class="asset-quote-component">
  <payment-chart
    [data]="paymentChartData"
    [hideBrokerOrigiantionFee]="false"
    [hideBalloonPayment]="true"
    [hideBrokerage]="false"
    [delay]="1000"
    [calculationLog]="calculationLog">
  </payment-chart>
</div>
