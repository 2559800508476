import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ApplicationTypes,
  ConflictedFlowDetail,
  PublishCreditFlowConfirmationDialogData,
  PublishCreditFlowConfirmationDialogResult,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { ApplicationTypeIconComponent } from '../application-type-icon/application-type-icon.component';
@Component({
  templateUrl: './publish-credit-flow-confirmation.dialog.html',
  styleUrls: ['./publish-credit-flow-confirmation.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule,
    MatTableModule, ApplicationTypeIconComponent
  ]
})
export class PublishCreditFlowConfirmationDialog {
  conflictedFlows: ConflictedFlowDetail[] | [];
  columnsToDisplay = ['conflictingId', 'conflictingFlowName', 'product'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: PublishCreditFlowConfirmationDialogData,
    private dialogRef: MatDialogRef<PublishCreditFlowConfirmationDialog, PublishCreditFlowConfirmationDialogResult>,
  ) {
    this.conflictedFlows = this.data.conflictedFlows ?? [];
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      force: confirmation,
    })
  }
  getColumnTitles(column: string): string {
    switch (column) {
      case 'conflictingId': return 'Flow ID';
      case 'conflictingFlowName': return 'Flow Name';
      case 'product': return 'Products';
      default: return column;
    }
  }
}
