import {
    UserandPriviledges
} from '../domain/types.domain';
import {isInternalUser} from "./utils";

const twofaconfigs =  {
    updateOwnAccessLevels: [ // AccessLevel able to turn on or off their own 2fa
      'externalbroker', 'companyadmin', 'companyoperator'
    ],
    updateOthersAccessLevels: [ // AccessLevel able to turn on or off others 2fa
      'admin', 'analyst', 'internalbroker', 'operations', 'credit', 'settlement', 'salesBDM', 'salesAM',
    ],
}

export const canChangeOwn2faAccessLevel = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
    const accessLevel = u?.AccessLevel ?? '';
    return twofaconfigs.updateOwnAccessLevels.includes(accessLevel);
}

export const canChangeOthers2faAccessLevel = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean  => {
    const accessLevel = u?.AccessLevel ?? '';
    return twofaconfigs.updateOthersAccessLevels.includes(accessLevel);
}

export const canUser2faAccessBeChangedByInternalUser = (u?: Pick<UserandPriviledges, 'AccessLevel'> | null): boolean => {
  return !isInternalUser(u);
}
