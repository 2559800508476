import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {compareMatch, DscrIndustryEbitda, DscrIndustryEbitdaSelectionValue, SecondaryIndustry, SecondaryIndustrySelectionValue} from '@portal-workspace/grow-shared-library';
import {DscrIndustrySelectionModuleService} from './dscr-industry-selection-module.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'dscr-industry-ebitda-selection',
    templateUrl: './dscr-industry-ebitda-selection.component.html',
    styleUrls: ['./dscr-industry-ebitda-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DscrIndustryEbitdaSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => DscrIndustryEbitdaSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class DscrIndustryEbitdaSelectionComponent extends AbstractControlValueAccessor<DscrIndustryEbitdaSelectionValue> implements OnInit, Mark {

  formGroup: FormGroup<{
    selection: FormControl<DscrIndustryEbitdaSelectionValue>;
  }>;
  formControl: FormControl<DscrIndustryEbitdaSelectionValue>;

  subscription?: Subscription;
  formControlSubscription: Subscription;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  industryEbitdas: DscrIndustryEbitda[] = [];

  compareWithFn: (option: DscrIndustryEbitdaSelectionValue, selected: DscrIndustryEbitdaSelectionValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.name === selected.name);
  };


  constructor(private formBuilder: FormBuilder,
              private service: DscrIndustrySelectionModuleService) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.formControlSubscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscription = this.service.industryEbitdas.pipe(
      tap(s => {
        this.industryEbitdas = s;
      })
    ).subscribe();
  }

  doWriteValue(v: DscrIndustryEbitdaSelectionValue | null): void | DscrIndustryEbitdaSelectionValue {
    if (v) {
      const o = this.industryEbitdas.find(opt => opt.name === v.name);
      if (o) {
        this.formControl.setValue(o);
        return o;
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
