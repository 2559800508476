import {constants} from '../const';
import { IndividualAdditionalRole, PrivacyConsent, RoleTypes, SignerRoleTypes } from './application-service.domain';
import {Address, Individual, } from '../domain/application-service.domain';
import {ParsedAddress} from '../utils/address';
import { IdentityVerificationResult } from './rekognition.domain';

export interface ParsedDigitalIdResponse {
  firstName: string;
  middleName: string;
  lastName: string;
  country: string;
  state: string;
  postcode: string;
  suburb: string;
  rawAddress: string;
  streetName: string;
  streetNumber: string;
  streetType: string;
  unitNumber: string;
  birthdate: string /* YYYY-MM-DD */;
}

export interface UpdateIndividualData {
  title?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  guarantor?: boolean | null;
  rawAddress?: string | null;
  streetName?: string | null;
  streetNumber?: string | null;
  streetType?: string | null;
  unitNumber?: string | null;
  birthdate?: string | null;
  deleted?: boolean | null;
  signerRole?: SignerRoleTypes;
  additionalRoles?: IndividualAdditionalRole[];
}

export interface Alert {
  type:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'primary'
    | 'secondary'
    | 'light'
    | 'dark';
  message: string;
}

export interface DigitalIdResultReceived {
  status: 'WAITING' | 'RECEIVED_COMPLETE' | 'RECEIVED_INCOMPLETE';
  creationDate: Date;
  lastUpdate: Date;
  digitalIdResponse: DigitalIdResponse;
}

export interface DigitalIdResultError {
  status: 'RECEIVED_ERROR';
  creationDate: Date;
  lastUpdate: Date;
  error: any;
}

export interface IndividualWithResult extends Individual {
  result: DigitalIdResult;
}

export interface DigitalIdResponse {
  verification_status: 'in_progress' | 'completed';
  verification_session_token?: string; // when 'completed' verification_session_token can be undefined
  data_source_events: (
    | 'credit_header_check_failed'
    | 'name_and_address_matched'
    | 'name_and_dob_matched'
    | 'kyc_basic_callback_result_success'
  )[];
  watchlist: {
    check_performed: boolean;
    check_performed_date: string;
    found: boolean;
    found_sources: {
      name: string;
      category: 'pep' | 'sanctions';
    }[];
    sources_category: 'pep' | 'sanctions' | 'pep_and_sanctions';
  };
  transaction_id: string;
}

export type DigitalIdResult = DigitalIdResultError | DigitalIdResultReceived;

export type KycStatus =
  | 'WAITING'
  | 'RECEIVED_COMPLETE'
  | 'RECEIVED_INCOMPLETE'
  | 'RECEIVED_ERROR';

export interface DigitalIdResultReceived {
  status: 'WAITING' | 'RECEIVED_COMPLETE' | 'RECEIVED_INCOMPLETE';
  creationDate: Date;
  lastUpdate: Date;
  digitalIdResponse: DigitalIdResponse;
  code: string | null;
  exp?: string | null;
  isNameOrDobChanged?: boolean | null;
}

export interface DigitalIdResultError {
  status: 'RECEIVED_ERROR';
  creationDate: Date;
  lastUpdate: Date;
  error: any;
  code: string | null;
  exp?: string | null;
  isNameOrDobChanged?: boolean | null;
}

export interface ThirdPartyEntity {
  address: string;
  abn: string;
  acn: string;
  organisationName: string;
}

export type AddIndividual = {
  // Omit<Individual, 'id'>;
  Title: string;
  SurName: string;
  GivenName: string;
  MiddleName: string;
  HomeAddress: Address;
  DoB: string; // YYYY-MM-DD
  Email: string;
  MobileNumber: string;
  GuarantorFlag: boolean;
  Role: RoleTypes;
  SignerRole: SignerRoleTypes;
  thirdPartyEntity?: ThirdPartyEntity;
};

export interface IndividualWithResult extends Individual {
  result: DigitalIdResult;
  identityVerificationResult?: IdentityVerificationResult;
  privacyConsent?: PrivacyConsent[];
}

// ====== payloads

export interface DigitalIdClientInfoPayload {
  clientId: string;
  kycClientId: string;
  scriptUrl: string;
}

export interface DigitalIdUpdateAndKycVerifyUserPayload {
  message: OnCompleteMessage,
  type: 'basic' | 'premium',
}

export interface DigitalIdAuthenticationPayload {
  accessToken: string;
  tokenType: string;
  expiresIn: string;
  scope: string;
  individualInfo: {
    transaction_id: string;
    sub: string;
    birthdate: string /* YYYY-MM-DD */;
    address: {
      formatted: string;
      street_address: string;
      locality: string;
      region: string;
      postal_code: string;
      country: string;
    };
    digitalid: {
      object: string;
      address_verified: boolean;
    };
    iss: string;
    given_name: string;
    middle_name: string;
    client_id: string;
    aud: string;
    updated_at: number;
    name: string;
    exp: number;
    family_name: string;
    iat: number;
  };
}

export interface CheckUserVerificationCodePayload {
  isError: boolean;
  messages: Alert[];
  result?: CheckUserVerificationCodePayloadResult;
}

export interface CheckUserVerificationCodePayloadResult {
  id: number;
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  creationDate: Date;
  status:
    | 'WAITING'
    | 'RECEIVED_ERROR'
    | 'RECEIVED_COMPLETE'
    | 'RECEIVED_INCOMPLETE';
  digitalIdResponse: DigitalIdResponse;
  code: string;
}

export interface CheckVerificationCodePayload {
  isError: boolean;
  messages: Alert[];
  result?: CheckVerificationCodePayloadResult;
}

export interface CheckVerificationCodePayloadResult {
  id: number;
  applicationId: number;
  individualId: string;
  creationDate: Date;
  lastUpdate: Date;
  status:
    | 'WAITING'
    | 'RECEIVED_ERROR'
    | 'RECEIVED_COMPLETE'
    | 'RECEIVED_INCOMPLETE';
  description: string;
  error: string;
  digitalIdResponse: DigitalIdResponse;
  digitalIdResult: DigitalIdResult;
  code: string;
  brokerAppId: string;
  applicationStatus: string;
  individual: Individual;
  isNameOrDobChanged?: boolean | null;
  exp?: string | null;
  // commercialEntities: CommercialEntity[];
}

export type UpdateAndKycVerifyUserPayload = {
  result: DigitalIdResult;
};

export type UpdateApplicationIndividualInfoPayload = IndividualWithResult;
export type VerifyApplicationIndividualsPayload = IndividualWithResult[];

export interface UpdateApplicationIndividualDigitalIdMappingPayload {
  entriesUpdated: number;
}
export interface UpdateApplicationIndividualDigitalIdMappingInput {
  verificationStatus: 'completed' | 'in_progress';
  dataSourceEvents: string[];
  verificationSessionToken?: string;
  transactionId: string;
}

export interface GetApplicationindividualsPayload {
  applicationId: string;
  brokerAppId: string;
  status: string;
  individuals: IndividualWithResult[];
}

export interface SendAskForVerificationInfoEmailPayload {
  verificationCode: string;
  individual: Individual;
}

export interface SendVerificationSucceededNotificationEmailPayload {
  individual: Individual;
}

export type VerifyOneApplicationIndividualPayload = IndividualWithResult;

export type UpdateIndividualPayload = Individual;

export type SetupApplicationIndividualMapping = IndividualWithResult[];

export interface DigitalIdPdfFileData {
  applicationId: number;
  individualId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  email: string;
  mobile: string;
  residential: string;
  result: {
    lastUpdate: string;
    status: string;
    digitalIdResponse: {
      verification_status: string;
      verification_session_token: string;
      data_source_events: string[];
      watchlist?: {
        check_performed: boolean;
        check_performed_date: string;
        found: boolean;
        found_sources: {
          name: string;
          category: string;
        }[];
        sources_category: string;
      };
      transaction_id: string;
    };
  };
}

export interface DoKycVerificationData {
  given_name: string;
  middle_name: string;
  family_name: string;
  dob: string;
  address: ParsedAddress;
  consent: boolean;
}

export interface DoKycVerficationDataInput extends DoKycVerificationData {
  address: {
    unit_number?: string;
    street_number: string;
    street_name: string;
    street_type: string | null;
    locality: string; // suburb
    region: keyof typeof constants.stateList; // state
    postal_code: string;
    country: 'AUS';
  };
}

export interface UserDigitalIdMapping {
  id: number;
  userId: number;
  code: string;
  creationDate: string;
}

export interface DigitalIdVerifyDetail {
  data: DigitalIdResult & { verificationUrl: string }
}

export interface PersonalInfoDetail {
  data: {
    State?: string;
    Expiry_Date: string;
    DOB: string;
    Firstname: string;
    Middlename: string;
    Surname: string;
    License_No: string;
    Card_No: string;
    Licence_Class?: string;
    Postcode?: string;
    Street?: string;
    Suburb?: string;
    googleParsedAddress: string;
    addressObject?: {
      street_number: string;
      street_name: string;
      street_type: string;
      locality: string;
      region: string;
      postal_code: string;
      country: string;
    };
  };
}

export type OverdraftUserVerificationData = OverdraftUserVerificationByLicence | OverdraftUserVerificationByIndividual;

export interface OverdraftUserVerificationByLicence {
   type: 'licence',
   licence: {
      data: string,
      type: string
   }
}

export interface OverdraftUserVerificationByIndividual {
   type: 'individual',
   individual:  {
     firstName: string,
     middleName: string,
     lastName: string,
     dob: string,  // YYYY-MM-DD
     driverLicenceNumber?: string,
     driverCardNumber?: string,
     driverCardExpiry?: string,
     address: {
       unitNumber?: string,  // optional
       streetNumber: string,
       streetName: string,
       streetType: string,
       locality: string,  // suburb
       region: string,  // state
       postCode: string,
     },
     postalAddress?: {  // postal address is optional (if do not exists will be the same as address)
       unitNumber?: string,  // optional
       streetNumber: string,
       streetName: string,
       streetType: string,
       locality: string,  // suburb
       region: string,  // state
       postCode: string,
     }
   }
 }

 export interface EditKycStatusDialogData {
  kycStatus: KycStatus | undefined;
 }

 export interface EditKycStatusDialogResult {
  kycStatus: EditKycStatusValue;
  readyForSubmission: boolean;
 }

 export type EditKycStatusValue = KycStatus | null;

export interface OnCompleteMessageSuccess {
  code: string;
  transaction_id: string;
}
export interface OnCompleteMessageError {
  error: string;
  error_description: string;
  transaction_id: string;
}
export type OnCompleteMessage =
  | OnCompleteMessageSuccess
  | OnCompleteMessageError;