<div class="dateRange-component">
  <div class="col">
    <mat-form-field class="datepicker">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input controlErrorsIgnore [formGroup]="formGroup" [rangePicker]="dateRangePicker">
        <input controlErrorsIgnore
               matStartDate placeholder="Start date"
               [formControl]="formControlStartDate">
        <input controlErrorsIgnore
               matEndDate placeholder="End date"
               [formControl]="formControlEndDate">
      </mat-date-range-input>
      <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
