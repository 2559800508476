<div class="member-component">

  @for (memberFormGroup of memberFormGroups(); track memberFormGroup; let index = $index) {
    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Member {{index + 1}}</div>
        <div class="mat-body">Minimum 1 member required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <mat-button-toggle-group
          [hideSingleSelectionIndicator]="true"
          [hideMultipleSelectionIndicator]="true"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'type')">
          <mat-button-toggle value="Entity">Entity</mat-button-toggle>
          <mat-button-toggle value="Individual">Individual</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <!-- INDIVIDUAL -->
    @if (memberFormGroupFormControl(memberFormGroup, 'type').value === 'Individual' &&
      memberFormGroupFormControl(memberFormGroup, 'title')) {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection
            [mark]="markObservable"
            [formControl]="memberFormGroupFormControl(memberFormGroup, 'title')">
          </title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [mark]="markObservable" [formControl]="memberFormGroupFormControl(memberFormGroup, 'firstName')"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [mark]="markObservable" [formControl]="memberFormGroupFormControl(memberFormGroup, 'lastName')"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker
            [mark]="markObservable"
            title="Date of birth"
            [formControl]="memberFormGroupFormControl(memberFormGroup, 'dob')"
            [allowOnly18YrsPlus]="true">
          </datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
          <gender
            [mark]="markObservable"
            [formControl]="memberFormGroupFormControl(memberFormGroup, 'gender')">
          </gender>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
        <!--google-address  label="Residential address" [formControl]="memberFormGroupFormControl(memberFormGroup, 'residentialAddress')"></google-address-->
        <custom-address title="Residential address"
          [mark]="markObservable"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'residentialAddress')">
        </custom-address>
      </div>
      <div class="col-lg-3 col-md-12">
        <yes-no title="Privacy consent obtained"
          [mark]="markObservable"
          [notselected]="true"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'privacyConsentObtained')">
        </yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <yes-no title="Guarantor"
          [mark]="markObservable"
          [notselected]="true"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'guarantor')">
        </yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <property-owner-with-address
          [mark]="markObservable"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'propertyOwner')"
          [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(memberFormGroupFormControl(memberFormGroup, 'residentialAddress'))"
          [showAddressForm]="showAddressForm">
        </property-owner-with-address>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <email [mark]="markObservable" [formControl]="memberFormGroupFormControl(memberFormGroup, 'email')"></email>
      </div>
      <div class="col-lg-3 col-md-12">
        <mobile
          [mark]="markObservable" [mandatory]="true" [onlyMobile]="true"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'mobileNumber')">
        </mobile>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-12">
        <button mat-stroked-button color="warn" [disabled]="formArray.length <= 1" (click)="removeMember(memberFormGroup)">Remove Member</button>
      </div>
    </div>
    @if (showAssetsAndLiabilities) {
      <personal-assets
        [mark]="markObservable"
        (events)="onPersonalAssetEvent($event, memberFormGroup)"
      [formControl]="memberFormGroupFormControl(memberFormGroup, 'personalAssets')"></personal-assets>
    }
    @if (showAssetsAndLiabilities) {
      <personal-liabilities
        [mark]="markObservable"
        (events)="onPersonalLiabilitiesEvent($event, memberFormGroup)"
      [formControl]="memberFormGroupFormControl(memberFormGroup, 'personalLiabilities')"></personal-liabilities>
    }
  }
  <!-- ENTITY -->
  @if (memberFormGroupFormControl(memberFormGroup, 'type').value === 'Entity' &&
    memberFormGroupFormControl(memberFormGroup, 'organisation')) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <business-number-search
          [mark]="markObservable"
          [searchFn]="businessNumberSearchFn"
        [formControl]="memberFormGroupFormControl(memberFormGroup, 'organisation')"></business-number-search>
      </div>
      <div class="col-lg-3 col-md-12">
        <entity-type
          [readonly]="entityTypeReadonly"
          [mark]="markObservable"
          [formControl]="memberFormGroupFormControl(memberFormGroup, 'organisationType')">
        </entity-type>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-12">
        <button mat-stroked-button color="warn" [disabled]="formArray.length <= 1" (click)="removeMember(memberFormGroup)">Remove Member</button>
      </div>
    </div>
  }
  <div class="row separator-bottom">
    <div class="col-12">
      <button mat-stroked-button (click)="addMember()">
        <span class="mdi mdi-plus" matPrefix></span>
        Add Member
      </button>
    </div>
  </div>
}

</div>
