<div class="overdraft-account-available-list-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" [disableControl]="disabled">
      @for (overdraftAccountAvailable of overdraftAccountsAvailable; track overdraftAccountAvailable) {
        <mat-option [value]="overdraftAccountAvailable">
          {{overdraftAccountAvailable.entityName}}({{overdraftAccountAvailable.entityName}}) - {{overdraftAccountAvailable.pismoAccountNumber}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
