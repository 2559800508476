import {Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription } from 'rxjs';
import {PortalHotToastService, formControlErrorKeys, formControlErrorMessage, getUser, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RedbookDetailsComponent } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ApplicationService } from '../../service/application.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './send-privacy-consent.page.html',
    styleUrls: ['./send-privacy-consent.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatButtonModule, FlexModule, MatInputModule, EmailComponent, NameComponent, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, RedbookDetailsComponent]
})
export class SendPrivacyConsentPage implements OnInit {
  subscriptions: Subscription[] = [];
  formControlReference!: FormControl<string | null>;
  formControlCustomerName!: FormControl<string | null>;
  formControlCustomerEmail!: FormControl<string | null>;
  formControlDynamoneyUserEmail!: FormControl<string | null>;
  formGroup!: FormGroup<{
    reference: FormControl<string | null>,
    customerName: FormControl<string | null>,
    customerEmail: FormControl<string | null>,
    dynamoneyUserEmail: FormControl<string | null>,
  }>;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  user = getUser();
 
  constructor(
    private toastService: PortalHotToastService,
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
  ) {
    this.formControlReference = formBuilder.control(null);
    this.formControlCustomerName = formBuilder.control(null, [Validators.required]);
    this.formControlCustomerEmail = formBuilder.control(null, [Validators.required]);
    this.formControlDynamoneyUserEmail = formBuilder.control(this.user?.Email ?? null, [Validators.required]);

    this.formGroup = formBuilder.group({
      reference: this.formControlReference,
      customerName: this.formControlCustomerName,
      customerEmail: this.formControlCustomerEmail,
      dynamoneyUserEmail: this.formControlDynamoneyUserEmail,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  send() {
    if (this.formGroup.valid) {
      this.subscriptions.push(
        this.applicationService.sendAdminPrivacyConsentFn({
          reference: this.formControlReference.value ?? '',
          customerName: this.formControlCustomerName.value ?? '',
          customerEmail: this.formControlCustomerEmail.value ?? '',
          dynamoneyUserEmail: this.formControlDynamoneyUserEmail.value ?? ''
        }).pipe(
          this.toastService.spinnerObservable(),
          this.toastService.snackBarObservable('Privacy consent sent successfully'),
        ).subscribe()
      )
    }
  }
}
