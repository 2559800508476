import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {compareMatch, TitleSelectionValue} from '@portal-workspace/grow-shared-library';
import {Subject, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy'
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {ContactValue, SelectContactValue } from '@portal-workspace/grow-shared-library';
import { EmailComponent } from '../common fields/email.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MobileComponent } from '../mobile-component/mobile.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { NgClass } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'select-contact',
    templateUrl: './select-contact.component.html',
    styleUrls: ['./select-contact.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectContactComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => SelectContactComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatCheckboxModule, TitleSelectionComponent, MarkDirective, NameComponent, MobileComponent, NgClass, ExtendedModule, EmailComponent]
})
export class SelectContactComponent extends AbstractControlValueAccessor<SelectContactValue> implements OnInit, AfterViewInit, Mark {

  subscriptions: Subscription[] = [];

  markObservable: Subject<boolean> = new Subject<boolean>();

  @Input({required: false}) title = 'Select primary contact';
  @Input({required: false}) predefinedContactSelection: ContactValue[] = [];

  formGroup: FormGroup<{
    isManual: FormControl<boolean | null>
    title: FormControl<TitleSelectionValue | null>
    firstName: FormControl<string | null>
    lastName: FormControl<string | null>
    mobileNumber: FormControl<string | null>
    telephone: FormControl<string | null>
    email: FormControl<string | null>
    // areaCode: FormControl<string | null>
  }>;
  formControlIsManual: FormControl<boolean | null>;
  formControlPredefinedSelection: FormControl<ContactValue | null>;
  formControlTitle: FormControl<TitleSelectionValue | null>;
  formControlFirstName: FormControl<string | null>;
  formControlLastName: FormControl<string | null>;
  formControlMobileNumber: FormControl<string | null>;
  formControlTelephone: FormControl<string | null>;
  formControlEmail: FormControl<string | null>;
  // formControlAreaCode: FormControl<string | null>;

  subscription?: Subscription;
  subscription2?: Subscription;
  subscription3?: Subscription;
  subscription4?: Subscription;

  readonly = false;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlIsManual = formBuilder.control(false);
    this.formControlPredefinedSelection = formBuilder.control(null);
    this.formControlTitle = formBuilder.control(null);
    this.formControlFirstName = formBuilder.control(null);
    this.formControlLastName = formBuilder.control(null);
    this.formControlMobileNumber = formBuilder.control(null);
    this.formControlTelephone = formBuilder.control(null);
    this.formControlEmail = formBuilder.control(null);
    // this.formControlAreaCode = formBuilder.control(null);
    this.formGroup = formBuilder.group({
      // predefinedSelection: this.formControlPredefinedSelection,
      isManual: this.formControlIsManual,
      title: this.formControlTitle,
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      mobileNumber: this.formControlMobileNumber,
      telephone: this.formControlTelephone,
      email: this.formControlEmail,
      // areaCode: this.formControlAreaCode,
    });
  }


  // formGroupControl(name: string): FormControl {
  //   return this.formGroup.controls[name] as FromControl<any>;
  // }

  switchToPredefinedContact(v?: ContactValue) {
    this.formControlTitle.setValue(v ? v.title ?? null : null);
    this.formControlFirstName.setValue(v ? v.firstName ?? null : null);
    this.formControlLastName.setValue(v ? v.lastName ?? null : null);
    this.formControlMobileNumber.setValue(v ? v.mobileNumber ?? null : null);
    this.formControlTelephone.setValue(v ? v.telephone ?? null : null);
    this.formControlEmail.setValue(v ? v.email ?? null : null);
    // this.formControlAreaCode.setValue(v ? v.areaCode ?? null : null);
    this.formControlTitle.disable();
    this.formControlFirstName.disable();
    this.formControlLastName.disable();
    this.formControlMobileNumber.disable();
    this.formControlTelephone.disable();
    this.formControlEmail.disable();
    // this.formControlAreaCode.disable();
    this.formControlTitle.clearValidators();
    this.formControlTitle.updateValueAndValidity();
    this.formControlFirstName.clearValidators();
    this.formControlFirstName.updateValueAndValidity();
    this.formControlLastName.clearValidators();
    this.formControlLastName.updateValueAndValidity();
    this.formControlMobileNumber.clearValidators();
    this.formControlMobileNumber.updateValueAndValidity();
    this.formControlTelephone.clearValidators();
    this.formControlTelephone.updateValueAndValidity();
    this.formControlEmail.clearValidators();
    this.formControlEmail.updateValueAndValidity();
    // this.formControlAreaCode.clearValidators();
    //this.formControlPredefinedSelection.setValidators([Validators.required]);
    // this.readonly = true;
    // this.formGroup.updateValueAndValidity();
  }

  switchToManualContact(v?: ContactValue) {
    this.formControlTitle.setValue(v ? v.title ?? null : null);
    this.formControlFirstName.setValue(v ? v.firstName ?? null : null);
    this.formControlLastName.setValue(v ? v.lastName ?? null : null);
    this.formControlMobileNumber.setValue(v ? v.mobileNumber ?? null : null);
    this.formControlTelephone.setValue(v ? v.telephone ?? null : null);
    this.formControlEmail.setValue(v ? v.email ?? null : null);
    // this.formControlAreaCode.setValue(v ? v.areaCode ?? null : null);
    this.formControlTitle.enable();
    this.formControlFirstName.enable();
    this.formControlLastName.enable();
    this.formControlMobileNumber.enable();
    this.formControlTelephone.enable();
    this.formControlEmail.enable();
    // this.formControlAreaCode.enable();
    this.formControlTitle.setValidators([Validators.required]);
    this.formControlTitle.updateValueAndValidity();
    this.formControlFirstName.setValidators([Validators.required]);
    this.formControlFirstName.updateValueAndValidity();
    this.formControlLastName.setValidators([Validators.required]);
    this.formControlLastName.updateValueAndValidity();
    this.formControlMobileNumber.setValidators([Validators.required]);
    this.formControlMobileNumber.updateValueAndValidity();
    this.formControlEmail.setValidators([Validators.required]);
    this.formControlEmail.updateValueAndValidity();
    this.formControlPredefinedSelection.setValue(null);
    // this.formControlTelephone.setValidators();
    // this.formControlTelephone.updateValueAndValidity();
    //this.formControlAreaCode.setValidators([Validators.required]);
    //this.formControlAreaCode.updateValueAndValidity();
    // this.formControlPredefinedSelection.setValidators([Validators.required]);
    // this.readonly = false;
  }


  ngAfterViewInit(): void {
    this.subscription = this.formControlIsManual.valueChanges.pipe(
      delay(0),
      tap((r: boolean | null) => {
        if (r) { // manual
          this.formControlPredefinedSelection.disable({emitEvent: false});
          this.switchToManualContact();
        } else {
          this.formControlPredefinedSelection.enable({emitEvent: false});
          this.switchToPredefinedContact(this.formControlPredefinedSelection.value ?? undefined);
        }
      })
    ).subscribe();
    this.subscription2 = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((fgv: any) => {
        this.changes(fgv);
      })
    ).subscribe();
    this.subscription3 = this.formControlPredefinedSelection.valueChanges.pipe(
      delay(0),
      filter((v: ContactValue | null) => !!v),
      tap( (v: ContactValue | null) => {
        // NOTE: v will never be null due to the above filter operator
        this.switchToPredefinedContact(v!);
        //setTimeout(()=> this.propagateChange(v));
        this.propagateChange(v);
      })
    ).subscribe();
    this.subscription4 = this.formControlTelephone.valueChanges.pipe(
      delay(0),
      tap((v: string | null) => {
        if (v) {
          if (v.length > 2) {
            const areaCode = v.substr(0, 2);
            // this.formControlAreaCode.setValue(areaCode);
          }
        }
      })
    ).subscribe();
    // setTimeout(()=>this.changes(this.formGroup.value));
  }

  private changes(fgv: any) {
    if (this.formGroup.invalid) {
      this.propagateChange(null);
    } else {
      if (this.formControlIsManual.value) {
        this.propagateChange(fgv);
      } else {
        const v: ContactValue | null = this.formControlPredefinedSelection.value;
        this.propagateChange(v);
      }
    }
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.switchToPredefinedContact();
  }

  doWriteValue(v: SelectContactValue | null | undefined): void {
    if (v) {
      if(v.isManual) {
        this.formControlPredefinedSelection.disable();
        this.formControlIsManual.setValue(true);
        this.switchToManualContact(v);
      } else {
        this.formControlPredefinedSelection.enable();
        this.formControlPredefinedSelection.setValue(v);
        this.formControlIsManual.setValue(false);
        this.switchToPredefinedContact(v);
      }
    }
  }

  changeValidation($event: any) {
    const value = $event.checked;
    if(value){
      this.formControlPredefinedSelection.clearValidators();
    }
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  showDetails() {
    const v = this.formControlIsManual.value || this.formControlPredefinedSelection.value
    return v;
  }
}
