import {Component, OnInit} from '@angular/core';
import {AdminService, } from '../../service/admin.service';
import {RatecardList, RatecardListSortType, DEFAULT_LIMIT, DEFAULT_OFFSET,} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { Sort, MatSortModule } from '@angular/material/sort';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {Router} from '@angular/router';
import {navigationUrlForAddRatecard, navigationUrlForRateCardDetails} from '../../service/navigation-urls';
import {PageEvent} from '@angular/material/paginator';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';


export class InternalDataSource extends DataSource<RatecardList> {

  subject = new BehaviorSubject<RatecardList[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<RatecardList[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(cards: RatecardList[]) {
    this.subject.next(cards);
  }
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './rate-cards.page.html',
    styleUrls: ['./rate-cards.page.scss'],
    standalone: true,
    imports: [FlexModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe, DatePipe]
})
export class RateCardsPage implements OnInit {

  loader = loadingFor('tableLoading');
  store = createAsyncStore();
  // showError = false
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }

  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  sorts?: Exclude<RatecardListSortType, null>[number]; // {prop: 'Id' | 'Name' | 'CreateTime', dir: 'asc' | 'desc'} ;
  total = 0;

  subscriptions: Subscription[] = [];


  formControlSearch: FormControl<string | null>;

  dataSource = new InternalDataSource();
  // displayColumns= ['ratecardId', 'name', 'date', 'actions'];
  displayColumns= ['ratecardId', 'name', 'date'];

  constructor(private adminService: AdminService,
              private toastService: PortalHotToastService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.formControlSearch = formBuilder.control('');
  }

  ngOnInit(): void {
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.resetPaging();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  reload() {
    this.store = createAsyncStore();
    this.adminService.getRatecardList({
      page: {
        limit: this.limit,
        offset: this.offset,
      },
      sorts: this.sorts ? [this.sorts] : undefined,
      filter: this.filter,
    }).pipe(
      // this.toastService.toastObserver('fetch rate cards'),
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        this.total = r.total;
        this.limit = r.limit;
        this.offset = r.offset;
        this.dataSource.update(r.payload);
      })
    ).subscribe();
  }

  async onMenuClicked($event: MouseEvent, element: RatecardList) {
    console.log('clicked ', element);
    await this.router.navigate(navigationUrlForRateCardDetails(element.RatecardId));
  }

  onRowClicked($event: MouseEvent, element: RatecardList) {
    this.router.navigate(navigationUrlForRateCardDetails(element.RatecardId));
  }

  onSortData($event: Sort) {
    const col = $event.active;
    const dir = $event.direction;
    console.log('****** sort', col, dir);
    if (col && dir) {
      this.sorts = {prop: col as any, dir};
      this.resetPaging();
    } else {
      this.sorts = undefined;
    }
    this.reload();
  }

  resetPaging() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  async addRateCard($event: Event) {
    await this.router.navigate(navigationUrlForAddRatecard())
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }
}
