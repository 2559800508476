<div class="hex-color-component">
  <mat-form-field>
    <mat-label>{{title}}</mat-label>
    <input
      matInput
      [inputMask]="createHexColorInputMask"
      [disableControl]="disabled"
      [formControl]="formControl">
    <span matTextSuffix>
      <div class="color-div">
        <div class="color-div-content" [ngStyle]="{backgroundColor: bgColor}"></div>
      </div>
    </span>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
