<div class="credit-management-page container-fluid">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
          <mat-tab label="Credit Flows">
            <credit-flows
              [addCreditFlowFn]="addCreditFlowFn" 
              [getCreditFlowsFn]="getCreditFlowsFn"
              [updateCreditFlowFn]="updateCreditFlowFn"
              [copyCreditFlowFn]="copyCreditFlowFn"
              (onNavigateCreditFlow)="onNavigateCreditFlow($event)"
            ></credit-flows>
          </mat-tab>

          <mat-tab label="Credit Alerts">
            <credit-alerts
              [addCreditAlertFn]="addCreditAlertFn"
              [getCreditAlertsFn]="getCreditAlertsFn"
              [updateCreditAlertFn]="updateCreditAlertFn"
              [getCreditAlertByStatusFn]="getCreditAlertByStatusFn"
            ></credit-alerts>
          </mat-tab>

          <mat-tab label="Credit Rule Items">
            <credit-rule-items
              [getCreditRuleItemsFn]="getCreditRuleItemsFn"
              [addCreditRuleItemFn]="addCreditRuleItemFn"
              [updateCreditRuleItemFn]="updateCreditRuleItemFn"
            ></credit-rule-items>
          </mat-tab>

        </mat-tab-group>
      


      </mat-card-content>
    </mat-card>
</div>
