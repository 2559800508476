import {inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  getUser,
  hasAccessBrokerDashboard, hasAccessCardOperatorDashboard, hasAccessOverdraftDashboard, hasMultipleRole
} from '@portal-workspace/grow-ui-library';
import {
  navigationUrlForCardOperatorDashboard, navigationUrlForOverdraftCustomerDashboard,
} from '../service/navigation-urls';


export const validBrokerGuard = () => {
  return async (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser();
    if (user) {
      // if user has broker access and is accessing broker pages directly, let him through
      if (hasAccessCardOperatorDashboard(user)) {
        return await router.navigate(navigationUrlForCardOperatorDashboard());
      } else if (hasAccessBrokerDashboard(user)) {
        return true;
      } else if (hasAccessOverdraftDashboard(user)) {
        return await router.navigate(navigationUrlForOverdraftCustomerDashboard());
      }
    }
    return true;
  }
}