<div class="trustee-component">

  @for (trusteeFormGroup of trusteeFormGroups(); track trusteeFormGroup; let index = $index) {
    <div>
      <div class="row">
        <div class="col-12">
          <div class="mat-h2">Trustee {{index + 1}}</div>
          <div class="mat-body separator-bottom">Minimum 1 trustee required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [hideMultipleSelectionIndicator]="true"
            [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'type')">
            <mat-button-toggle value="Entity">Entity Trustee</mat-button-toggle>
            <mat-button-toggle value="Individual">Individual Trustee</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <!-- INDIVIDUAL -->
      @if (trusteeFormGroupFormControl(trusteeFormGroup, 'type').value === 'Individual' &&
        trusteeFormGroupFormControl(trusteeFormGroup, 'title')) {
        <div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <title-selection
                [mark]="markObservable"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'title')">
              </title-selection>
            </div>
            <div class="col-lg-3 col-md-12">
              <name title="First Name" [mark]="markObservable" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'firstName')"></name>
            </div>
            <div class="col-lg-3 col-md-12">
              <name title="Last Name" [mark]="markObservable" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'lastName')"></name>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <datepicker
                title="Date of birth"
                [mark]="markObservable"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'dob')"
                [allowOnly18YrsPlus]="true">
              </datepicker>
            </div>
            <div class="col-lg-3 col-md-12">
              <gender
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'gender')"
                [mark]="markObservable">
              </gender>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <custom-address
                title="Residential address"
                [mark]="markObservable"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'residentialAddress')">
              </custom-address>
            </div>
            <div class="col-lg-3 col-md-12">
              <yes-no
                title="Privacy consent obtained"
                [mark]="markObservable"
                [notselected]="true"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'privacyConsentObtained')">
              </yes-no>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <yes-no title="Guarantor"
                [mark]="markObservable"
                [notselected]="true"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'guarantor')">
              </yes-no>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <property-owner-with-address
                [mark]="markObservable"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'propertyOwner')"
                [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(trusteeFormGroupFormControl(trusteeFormGroup, 'residentialAddress'))"
                [showAddressForm]="showAddressForm">
              </property-owner-with-address>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <email [mark]="markObservable" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'email')"></email>
            </div>
            <div class="col-lg-3 col-md-12">
              <mobile
                [mark]="markObservable" [mandatory]="true" [onlyMobile]="true"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'mobileNumber')">
              </mobile>
            </div>
          </div>
          <div class="row separator-bottom">
            <div class="col-12">
              <button mat-stroked-button color="warn" [disabled]="formArray.length <= 1" (click)="removeTrustee(trusteeFormGroup)">Remove Trustee</button>
            </div>
          </div>
          @if (showAssetsAndLiabilities) {
            <div>
              <personal-assets
                [mark]="markObservable"
                (events)="onPersonalAssetEvent($event, trusteeFormGroup)"
              [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'personalAssets')"></personal-assets>
            </div>
          }
          <!-- row #8 -->
          @if (showAssetsAndLiabilities) {
            <div>
              <personal-liabilities
                [mark]="markObservable"
                (events)="onPersonalLiabilitiesEvent($event, trusteeFormGroup)"
              [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'personalLiabilities')"></personal-liabilities>
            </div>
          }
        </div>
      }
      <!-- ENTITY -->
      @if (trusteeFormGroupFormControl(trusteeFormGroup, 'type').value === 'Entity' &&
        trusteeFormGroupFormControl(trusteeFormGroup, 'organisation')) {
        <div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-checkbox color="primary" class="mb-3" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'manualEntry')">Enter ACN
              Manually</mat-checkbox>
            </div>
          </div>
          <div class="row">
            @if (!trusteeFormGroup.get('manualEntry')?.value) {
              <div class="col-lg-6 col-md-12">
                <business-number-search [title]="'Trustee company’s ACN number'" [mark]="markObservable"
                  [searchFn]="businessNumberSearchFn"
                [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'organisation')"></business-number-search>
              </div>
            }
            <div class="col-lg-3 col-md-12">
              <entity-type [mark]="markObservable" [readonly]="entityTypeReadonly"
              [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'organisationType')"></entity-type>
            </div>
          </div>
          <!-- MANUAL ENTTRY-->
          @if (trusteeFormGroup.get('manualEntry')?.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <acn [isMandatory]="true" [mark]="markObservable" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'stepacn')">
                </acn>
              </div>
              <div class="col-lg-4 col-md-12">
                <name title="Business name" [mark]="markObservable" [formControl]="trusteeFormGroupFormControl(trusteeFormGroup, 'businessName')">
                </name>
              </div>
            </div>
          }
          <div class="row separator-bottom">
            <div class="col-12">
              <button mat-stroked-button color="warn" [disabled]="formArray.length <= 1"
              (click)="removeTrustee(trusteeFormGroup)">Remove Trustee</button>
            </div>
          </div>
          <!--
          organisation: {{trusteeFormGroupFormControl(trusteeFormGroup, "organisation").value | json }}<br/>
          organisation valid: {{trusteeFormGroupFormControl(trusteeFormGroup, "organisation").valid | json }} <br/>
          -->
        </div>
      }
      @if (allowAddingTrustee) {
        <div class="row separator">
          <div class="col-12">
            <button mat-stroked-button (click)="addTrustee()">
              <span class="mdi mdi-plus" matPrefix></span>
              Add trustee
            </button>
          </div>
        </div>
      }
    </div>
  }
  <!--
  formGroup Valid: {{formGroup.valid | json}}<br/>
  formArray valid: {{formArray.valid | json}}<br/>
  -->
</div>
