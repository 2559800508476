import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {ApplicationSummaryComponent} from './application-summary.component';
import {CellComponent} from './cell.component';
import {AssetsSummaryComponent} from './assets-summary.component';
import {LiabilitiesSummaryComponent} from './liabilities-summary.component';
import {GuarantorsSummaryComponent} from './guarantors-summary.component';
import {DirectorsSummaryComponent} from './directors-summary.component';
import {TrusteesSummaryComponent} from './trustees-summary.component';
import {PartnersSummaryComponent} from './partners-summary.component';
import {MembersSummaryComponent} from './members-summary.component';
import {SoletradersSummaryComponent} from './soletraders-summary.component';
import {ConsumerFinanceSummaryComponent} from './consumer-finance-summary.component';
import {AssetFinanceSummaryComponent} from './asset-finance-summary.component';
import { CorporateLoanSummaryComponent } from './corporate-loan-summary.component';
import {BusinessFinanceSummaryComponent} from './business-finance-summary.component';
import {BusinessOverdraftSummaryComponent} from './business-overdraft-summary.component';
import {InsurancePremiumSummaryComponent} from './insurance-premium-summary.component';
import {PrimaryContactSummaryComponent} from './primary-contact-summary.component';
import {ReferencesSummaryComponent} from './references-summary.component';
import {CommercialFinanceSummaryComponent} from './commercial-finance-summary.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {PipeLibraryModule} from '../../pipes/pipe-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PipeLibraryModule,
        CellComponent,
        AssetsSummaryComponent,
        LiabilitiesSummaryComponent,
        DirectorsSummaryComponent,
        GuarantorsSummaryComponent,
        TrusteesSummaryComponent,
        PartnersSummaryComponent,
        MembersSummaryComponent,
        SoletradersSummaryComponent,
        PrimaryContactSummaryComponent,
        ReferencesSummaryComponent,
        ApplicationSummaryComponent,
        ConsumerFinanceSummaryComponent,
        AssetFinanceSummaryComponent,
        CorporateLoanSummaryComponent,
        BusinessFinanceSummaryComponent,
        BusinessOverdraftSummaryComponent,
        InsurancePremiumSummaryComponent,
        CommercialFinanceSummaryComponent
    ],
    exports: [
        CellComponent,
        ApplicationSummaryComponent,
        ConsumerFinanceSummaryComponent,
        AssetFinanceSummaryComponent,
        CorporateLoanSummaryComponent,
        BusinessFinanceSummaryComponent,
        BusinessOverdraftSummaryComponent,
        PartnersSummaryComponent,
        InsurancePremiumSummaryComponent,
        CommercialFinanceSummaryComponent,
    ]
})
export class ApplicationSummaryModule {

}
