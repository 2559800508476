<div class="private-seller">
  <div class="row">
    <div class="col-6 mt-3">
      <h5 class="privateSeller">Does the private seller have an ABN?</h5><br>
      <yes-no [formControl]="formControlYesNo" class="bussinessTitle" title={{title}}></yes-no>
    </div>
  </div>
  @if (formControlYesNo.value==false) {
    <form [formGroup]="formGroupPrivateSeller">
      <p class="title">Enter your private seller information</p>
      <div class="row">
        <div class="col-6">
          <name title="First Name" [formControl]="formControlFirstName" [includeDigits]="true" [mark]="markTriggerSubject(formGroupPrivateSeller)"></name>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Middle name (optional)</mat-label>
            <input matInput [formControl]="formControlMiddleName" placeholder="Enter middle name">
          </mat-form-field>
        </div>
        <div class="col-6">
          <name title="Last Name" [formControl]="formControlLastName" [includeDigits]="true" [mark]="markTriggerSubject(formGroupPrivateSeller)"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <email title="Email" [formControl]="formControlEmail" [mark]="markTriggerSubject(formGroupPrivateSeller)" ></email>
        </div>
        <div class="col-6">
          <mobile title="Mobile" [formControl]="formControlMobile" [mark]="markTriggerSubject(formGroupPrivateSeller)"></mobile>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <custom-address title="Address" [formControl]="formControlAddress" [mark]="markTriggerSubject(formGroupPrivateSeller)"></custom-address>
        </div>
      </div>
    </form>
  }
  @if (formControlYesNo.value==true) {
    <form>
      <div class="row">
        <div class="col-6">
          <business-search [allowFreeText]="false" [formControl]="formControlBusiness" [searchFn]="businessSearchFn" [mark]="markTriggerSubject(formGroupPrivateSeller)"></business-search>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <name title="First Name" [formControl]="formControlFirstName" [includeDigits]="true" [mark]="markTriggerSubject(formGroupPrivateSeller)"></name>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Middle name (optional)</mat-label>
            <input matInput [formControl]="formControlMiddleName" placeholder="Enter middle name">
          </mat-form-field>
        </div>
        <div class="col-6">
          <name title="Last Name" [formControl]="formControlLastName" [includeDigits]="true" [mark]="markTriggerSubject(formGroupPrivateSeller)"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <email title="Email" [mandatory]="true" [formControl]="formControlEmail" [mark]="markTriggerSubject(formGroupPrivateSeller)" ></email>
        </div>
        <div class="col-6">
          <mobile title="Mobile" [mandatory]="true" [formControl]="formControlMobile" [mark]="markTriggerSubject(formGroupPrivateSeller)"></mobile>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>State</mat-label>
            <mat-select [formControl]="formControlState"
              [compareWith]="stateCompareWith" >
              @for (state of states; track state) {
                <mat-option [value]="state.type">
                  {{state.type}}
                </mat-option>
              }
            </mat-select>
            @for (errorKey of errorKeys(formControlState); track errorKey) {
              <mat-error>
                @if (formControlState.touched && formControlState.hasError(errorKey)) {
                  {{errorMessage(formControlState, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  }
  @if (businessSearchValue) {
    <div class="asset-supplier-details">
      <p class="title">Selected Private Seller Details</p>
      <div class="section">
        <div class="row">
          <div class="col-6">
            <cell>
              <div label>Private Seller ABN</div>
              <div body>{{businessSearchValue.abn}}</div>
            </cell>
          </div>
          <div class="col-6">
            <cell>
              <div label>Private Seller Name</div>
              <div body>{{businessSearchValue.organisationName}}</div>
            </cell>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <cell>
              <div label>Contact</div>
              <div body>-</div>
            </cell>
          </div>
        </div>
      </div>
    </div>
  }
  <p class="title">Bank account details</p>
  <div class="row">
    <local-bank-account-detail [getInstitutionNameFn]="getInstitutionNameFn"
    [formControl]="formControlBankDetails" [mark]="markTriggerSubject(formGroupPrivateSeller)"></local-bank-account-detail>
  </div>
</div>
