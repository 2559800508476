import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {ConsumerDscrCalculatorComponent} from "./consumer-dscr-calculator.component";
import {
  Application,
  ApplicationWebService,
  ConsumerFinanceDsrServiceabliltyCalculationFn,
  CreateApplicationNoteFn,
  DeleteWebServicesFn,
  DownloadCreditorWatchReportFn,
  DuplicatedApplication,
  GetApplicationByIdFn,
  GetApplicationCreditFlowResultFn,
  GetApplicationDscrFn,
  GetApplicationOwnerFn,
  GetCreditAuditLogFn,
  GetCreditWebServicesFn,
  GetDuplicatedApplicationsFn,
  GetUserFn,
  RemoveApplicationNoteByNoteIdFn,
  RequestMoreInformationFn,
  RunCreditFlowFn,
  UpdateCreditStatusFn,
  User,
  isAdminOrCreditUser,
  isInternalUser
} from "@portal-workspace/grow-shared-library";
import { GetConsumerDscrCalculatorValueFn, UpdateConsumerDscrCalculatorValueFn } from "@portal-workspace/grow-shared-library";
import { CreditOverviewComponent } from '../../credit-component/credit-overview.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet } from '@angular/common';
import { getUser } from "../../component-utils";
import { GetNotesByApplicationIdFn } from "../../asset-listing-component/notes.component";
import { GetApplicationAuditLogsFn } from "../../asset-listing-component/audit-log.component";

@Component({
  selector: 'consumer-credit',
  templateUrl: './consumer-credit.component.html',
  styleUrls: ['./consumer-credit.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    ConsumerDscrCalculatorComponent,
    CreditOverviewComponent,
    MatTabsModule, MatCardModule, NgTemplateOutlet
  ],
})
export class ConsumerCreditComponent {

  @Input({required: true}) application!: Application;
  @Input({required: true}) getConsumerDscrCalculatorValueFn!: GetConsumerDscrCalculatorValueFn
  @Input({required: true}) updateConsumerDscrCalculatorValueFn!: UpdateConsumerDscrCalculatorValueFn;
  @Input({required: true}) consumerFinanceDscrServiceabilityCalculationFn!: ConsumerFinanceDsrServiceabliltyCalculationFn;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getApplicationDscrFn!: GetApplicationDscrFn;
  @Input({required: true}) apiUrl!: string;
  @Output() viewReport: EventEmitter<ApplicationWebService> = new EventEmitter();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  isInternalUser = isInternalUser;
  isAdminOrCreditUser = isAdminOrCreditUser;
  loggedInUser: User | null = getUser();

  onViewReport(element: ApplicationWebService) {
    this.viewReport.emit(element)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
