import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {InformationDialogData, InformationDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    templateUrl: './information-dialog.html',
    styleUrls: ['./information-dialog.scss'],
    standalone: true,
  imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class InformationDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: InformationDialogData,
              private dialogRef: MatDialogRef<InformationDialog, InformationDialogResult>,
              private formBuilder: FormBuilder) {

  }

  onSubmit($event: Event) {
    const result: InformationDialogResult = {type: 'close'}
    this.dialogRef.close(result);
  }
}
