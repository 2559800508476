
<div class="credit-rule-component">
  <form [formGroup]="formGroup">
    @if (creditRuleType === 'Formula') {
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="mb-2">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <button mat-button color="primary" (click)="onClickItem()">{{'{'}}ITEM{{'}'}}</button>
          <button mat-button color="primary" (click)="addOperator('AND')">AND</button>
          <button mat-button color="primary" (click)="addOperator('OR')">OR</button>
          <button mat-button color="primary" [matMenuTriggerFor]="equalsMenu">Equals</button>
        </div>
          <button mat-button color="primary" (click)="validateRule()">Validate</button>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <span class="date-text">*Allowed operators: ( | ) | + | - | * | / | == | != | > | < | >= | <= | IN | NOT IN | AND | OR</span>
        </div>
        <div class="col-12">
          <span class="date-text">*Press ENTER to create a new entry</span>
        </div>
      </div>
    }

    @if (creditRuleType === 'Database' || creditRuleType === 'Inteflow Database') {
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="mb-2">
        <button mat-button color="primary" (click)="onClickItem()">{{'{'}}ITEM{{'}'}}</button>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <span class="date-text">*Only SELECT query is allowed</span>
        </div>
      </div>
    }

    @if (creditRuleType === 'JSON' || creditRuleType === 'Database' || creditRuleType === 'Inteflow Database') {
      <mat-form-field class="formula-area">
        @if (title) {
          <mat-label>{{title}}</mat-label>
        }
        <textarea matInput 
          placeholder="{{placeholder}}" type="text" rows="8"
          [formControl]="formControl" [required]="mandatory" [disableControl]="disabled">
        </textarea>
          @for (errorKey of errorKeys(formControl); track errorKey) {
            <mat-error>
              @if (formControl.touched && formControl.hasError(errorKey)) {
                {{errorMessage(formControl, errorKey)}}
              }
            </mat-error>
          }
      </mat-form-field>
    }


    @if (creditRuleType === 'Formula') {
      <mat-form-field class="formula-chip-list">
        @if (title) {
          <mat-label>{{title}}</mat-label>
        }
        <mat-chip-grid #chipList aria-label="email list">
          @for (ruleObject of creditRuleObjects; track ruleObject; let index = $index) {
            <mat-chip-row (removed)="remove(index)">
              {{ruleObject}}
              <button matChipRemove>
                <span class="mdi mdi-close"></span>
              </button>
            </mat-chip-row>
          }
          <input [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            [formControl]="formControl"
            (matChipInputTokenEnd)="add($event)">
          </mat-chip-grid>
      </mat-form-field>
      @if (invalidFormulaError) {
        <div class="error">Invalid formula</div>
      }
    }
  </form>
</div>


<!-- <mat-menu #itemMenu="matMenu" class="customize-menu">
  <button mat-menu-item [matMenuTriggerFor]="defaultMenu">Default</button>
  <button mat-menu-item [matMenuTriggerFor]="applicationMenu">Application</button>
  <button mat-menu-item [matMenuTriggerFor]="bankStatementMenu">Bank Statement</button>
  <button mat-menu-item [matMenuTriggerFor]="bureauMenu">Bureau</button>
  <button mat-menu-item [matMenuTriggerFor]="equifaxMenu">Equifax</button>
  <button mat-menu-item [matMenuTriggerFor]="ppsrMenu">PPSR</button>
</mat-menu>
<mat-menu #defaultMenu="matMenu" class="customize-menu">
  @for (item of creditRuleItemSelections['Default']; track item) {
    <button mat-menu-item (click)="addItem(item)">{{item.name}}</button>
  }
</mat-menu>
<mat-menu #applicationMenu="matMenu" class="customize-menu">
  @for (item of creditRuleItemSelections['Application']; track item) {
    <button mat-menu-item (click)="addItem(item)">{{item.name}}</button>
  }
</mat-menu>
<mat-menu #bankStatementMenu="matMenu" class="customize-menu">
  @for (item of creditRuleItemSelections['Bank Statement']; track item) {
    <button mat-menu-item (click)="addItem(item)">{{item.name}}</button>
  }
</mat-menu>
<mat-menu #bureauMenu="matMenu" class="customize-menu">
  @for (item of creditRuleItemSelections['Bureau']; track item) {
    <button mat-menu-item (click)="addItem(item)">{{item.name}}</button>
  }
</mat-menu> -->

<mat-menu #equalsMenu="matMenu" class="customize-menu">
  <button mat-menu-item (click)="addOperator('<')">Less Then &lt;</button>
  <button mat-menu-item (click)="addOperator('<=')">Less Then or Equal To &lt;=</button>
  <button mat-menu-item (click)="addOperator('>')">Greater Then ></button>
  <button mat-menu-item (click)="addOperator('>=')">Greater Then or Equal To >=</button>
  <button mat-menu-item (click)="addOperator('==')">Equal To ==</button>
  <button mat-menu-item (click)="addOperator('!=')">Not Equal To !=</button>
  <button mat-menu-item (click)="addOperator('IN')">In</button>
  <button mat-menu-item (click)="addOperator('NOT IN')">Not In</button>
</mat-menu>