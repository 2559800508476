import {Component, forwardRef, inject, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {
  FormBuilder,
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { AsyncPipe, JsonPipe } from "@angular/common";
import {MatOptionModule} from "@angular/material/core";
import {CustomerAccessLevelComponent, customerUserOptions} from '../access-level-component/customer-access-level.component'
import {DisableControlDirective} from '../../directives/disable-control.directive';
import {MARK, Mark} from "@portal-workspace/grow-ui-library/mark";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {
  compareMatch, CustomerAccessLevel,
  CustomerAccessLevelAndRoleAccessValue,
  CustomerAccessLevelValue, RoleAccess
} from "@portal-workspace/grow-shared-library";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'customer-access-level-and-role-access',
  templateUrl: './customer-access-level-and-role-access.component.html',
  styleUrls: ['./customer-access-level-and-role-access.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>CustomerAccessLevelAndRoleAccessComponent),multi: true},
    { provide: MARK, useExisting: forwardRef(()=>CustomerAccessLevelAndRoleAccessComponent)},
  ],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    DisableControlDirective,
    CustomerAccessLevelComponent,
    AsyncPipe,
    JsonPipe,
    MatCheckboxModule
],
})
export class CustomerAccessLevelAndRoleAccessComponent extends AbstractControlValueAccessor<CustomerAccessLevelAndRoleAccessValue> implements OnInit, Mark {

  options = customerUserOptions;

  subscriptions: Subscription[] = [];

  formBuilder: FormBuilder = inject(FormBuilder);

  formControlCustomerAccessLevel: FormControl<CustomerAccessLevelValue> = this.formBuilder.control(null, [Validators.required]);
  formControlIsAllTransaction: FormControl<boolean | null> = this.formBuilder.control(null, [Validators.required]);
  formControlIsThirdPartyPayment: FormControl<boolean | null> = this.formBuilder.control(null, [Validators.required]);
  formControlIsBpay: FormControl<boolean | null> = this.formBuilder.control(null, [Validators.required]);

  formGroup: FormGroup<{
    customerAccessLevel: FormControl<CustomerAccessLevelValue>,
    isAllTransaction: FormControl<boolean | null>,
    isThirdPartyPayment: FormControl<boolean | null>,
    isBpay: FormControl<boolean | null>,
  }> = this.formBuilder.group({
    customerAccessLevel: this.formControlCustomerAccessLevel,
    isAllTransaction: this.formControlIsAllTransaction,
    isThirdPartyPayment: this.formControlIsThirdPartyPayment,
    isBpay: this.formControlIsBpay,
  });


  ngOnInit(): void {
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formGroup.valid) {
          // NOTE: when valid, customerAccessLevel and role Access should not be valid
          const v = this.formControlCustomerAccessLevel.value;
          if (v === 'card-admin') {
            this.formControlIsAllTransaction.setValue(true);
            this.formControlIsBpay.setValue(true);
            this.formControlIsThirdPartyPayment.setValue(true);
            this.formControlIsAllTransaction.disable();
            this.formControlIsThirdPartyPayment.disable();
            this.formControlIsBpay.disable();
          } else if (v === 'card-member') {
            this.formControlIsAllTransaction.enable();
            this.formControlIsThirdPartyPayment.enable();
            this.formControlIsBpay.enable();
          } else {
            this.formControlCustomerAccessLevel.setValue('card-disabled');
            this.formControlIsAllTransaction.setValue(false);
            this.formControlIsThirdPartyPayment.setValue(false);
            this.formControlIsAllTransaction.setValue(false);
            this.formControlCustomerAccessLevel.disable();
            this.formControlIsAllTransaction.disable();
            this.formControlIsThirdPartyPayment.disable();
            this.formControlIsBpay.disable();
          }
          const val: CustomerAccessLevelAndRoleAccessValue = {
            customerAccessLevel: this.formControlCustomerAccessLevel.value!,
            roleAccess: {
              isAllTransaction: this.formControlIsAllTransaction.value ?? false,
              isThirdPartyPayment: this.formControlIsThirdPartyPayment.value ?? false,
              isBpay: this.formControlIsBpay.value ?? false,
            }
          };
          this.propagateChange(val);
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe());
    // this.subscriptions.push(this.formControlCustomerAccessLevel.valueChanges.pipe(
    //   tap(v => {
    //     if (v === 'card-admin') {
    //      this.formControlIsAllTransaction.setValue(true);
    //      this.formControlIsBpay.setValue(true);
    //      this.formControlIsThirdPartyPayment.setValue(true);
    //      this.formControlIsAllTransaction.disable();
    //      this.formControlIsThirdPartyPayment.disable();
    //      this.formControlIsAllTransaction.disable();
    //     } else if (v === 'card-member') {
    //       this.formControlIsAllTransaction.enable();
    //       this.formControlIsThirdPartyPayment.enable();
    //       this.formControlIsAllTransaction.enable();
    //     } else {
    //       this.formControlCustomerAccessLevel.setValue('card-disabled');
    //       this.formControlIsAllTransaction.setValue(false);
    //       this.formControlIsThirdPartyPayment.setValue(false);
    //       this.formControlIsAllTransaction.setValue(false);
    //       this.formControlCustomerAccessLevel.disable();
    //       this.formControlIsAllTransaction.disable();
    //       this.formControlIsThirdPartyPayment.disable();
    //       this.formControlIsAllTransaction.disable();
    //     }
    //   })
    // ).subscribe());
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  override doWriteValue(v?: CustomerAccessLevelAndRoleAccessValue | null): void | CustomerAccessLevelAndRoleAccessValue {
    if (v) {
      this.formControlCustomerAccessLevel.setValue(v.customerAccessLevel);
      if (v.roleAccess) {
        this.formControlIsAllTransaction.setValue(v.roleAccess.isAllTransaction);
        this.formControlIsThirdPartyPayment.setValue(v.roleAccess.isThirdPartyPayment);
        this.formControlIsBpay.setValue(v.roleAccess.isBpay);
      }
    } else {
      this.formControlCustomerAccessLevel.setValue(null);
      this.formControlIsAllTransaction.setValue(false);
      this.formControlIsThirdPartyPayment.setValue(false);
      this.formControlIsBpay.setValue(false);
    }
    return undefined;
  }

}
