@if (showError) {
  <error-notification [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification>
}
@if (!showError) {
  <div class="asset-selection-component">
    @if (store.value$ | async; as state) {
      <div>
        @if (state.loading) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: state.loading}">
          @if (showAssetCategory) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <asset-category [disableControl]="disabled"
                  [key]="key"
                  [mark]="markObservable"
                  [indexFilter]="categoryIndexFilter"
                  [formControl]="formControlAssetCategory">
                </asset-category>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <asset-type [disableControl]="disabled"
                [key]="key"
                [mark]="markObservable"
                [formControl]="formControlAssetType">
              </asset-type>
            </div>
          </div>
          @if (isAssetTypeCar() || isAssetTypeTruck()) {
            <div class="row mb-4">
              <div class="col-lg-6 col-md-12">
                <mat-checkbox [disableControl]="disabled"
                  color="primary"
                  [formControl]="formControlOtherCar">
                  Make/Model not in the list
                </mat-checkbox>
              </div>
            </div>
          }
          @if (_showMakeFamilyYearAndModel) {
            <!-- make (selection), family, year and model -->
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Make</mat-label>
                  <mat-select [formControl]="formControlAssetMake"
                  (openedChange)="onSelectMakeOptionOpened($event)"
                    [disableControl]="disabled"
                    [compareWith]="assetMakeComparator">
                    @for (makeOption of makeSelection; track makeOption) {
                      <mat-option [value]="makeOption">
                        {{makeOption?.description ?? ''}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Family</mat-label>
                  <mat-select [formControl]="formControlAssetFamily"
                    [disableControl]="disabled"
                    [compareWith]="assetFamilyComparator">
                    @for (familyOption of familySelection; track familyOption) {
                      <mat-option [value]="familyOption">
                        {{familyOption?.description ?? ''}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-12">
                <mat-form-field>
                  <mat-label>Year</mat-label>
                  <mat-select [formControl]="formControlAssetYear"
                    [disableControl]="disabled"
                    [compareWith]="assetYearsComparator">
                    @for (yearOption of yearSelection; track yearOption) {
                      <mat-option [value]="yearOption">
                        {{yearOption?.description ?? ''}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Model</mat-label>
                  <mat-select [formControl]="formControlAssetModel"
                    [disableControl]="disabled"
                    [compareWith]="assetModelComparator">
                    @for (modelOption of modelSelection; track modelOption) {
                      <mat-option [value]="modelOption">
                        {{modelOption?.description ?? ''}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            @if (_showGrossVehicleWeight) {
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <mat-form-field>
                    <mat-label>Gross Vehicle Weight</mat-label>
                    <mat-select [formControl]="formControlGrossVehicleWeight"
                      [disableControl]="disabled">
                      @for (grossVehicleWeightOption of grossVehicleWeightSelection; track grossVehicleWeightOption) {
                        <mat-option [value]="grossVehicleWeightOption">
                          {{grossVehicleWeightOption}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            }
            @if (formControlAssetVehicle && formControlAssetVehicle.value) {
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <redbook-details [vehicle]="formControlAssetVehicle.value"></redbook-details>
                </div>
              </div>
            }
          }
          @if (_showMakeYearAndModel) {
            <!-- make, year and model -->
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Make</mat-label>
                  <input type="text"
                    matInput
                    [formControl]="formControlAssetMake"
                    [disableControl]="disabled">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <mat-form-field>
                    <mat-label>Model</mat-label>
                    <input type="text"
                      matInput
                      [formControl]="formControlAssetModel"
                      [disableControl]="disabled">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 col-md-12">
                    <mat-form-field>
                      <mat-label>Year</mat-label>
                      <input type="text"
                        required
                        [inputMask]="createYearMask"
                        matInput
                        [formControl]="formControlAssetYear"
                        [disableControl]="disabled">
                      </mat-form-field>
                    </div>
                  </div>
                }
                @if (_showMakeYearAndDescription) {
                  <!-- make, year & description -->
                  @if (isAssetTypeTruck()) {
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <mat-form-field>
                          <mat-label>Gross Vehicle Weight</mat-label>
                          <mat-select [formControl]="formControlGrossVehicleWeight"
                            [disableControl]="disabled">
                            @for (grossVehicleWeightOption of grossVehicleWeightSelection; track grossVehicleWeightOption) {
                              <mat-option
                                [value]="grossVehicleWeightOption">
                                {{grossVehicleWeightOption}}
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  }
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <mat-form-field>
                        <mat-label>Make</mat-label>
                        <input type="text"
                          matInput
                          [formControl]="formControlAssetMake"
                          [disableControl]="disabled">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-12">
                        <mat-form-field>
                          <mat-label>Year</mat-label>
                          <input type="text"
                            matInput
                            required
                            [inputMask]="createYearMask"
                            [formControl]="formControlAssetYear"
                            [disableControl]="disabled">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row separator-bottom">
                        <div class="col-lg-6 col-md-12">
                          <mat-form-field>
                            <mat-label>Description</mat-label>
                            <textarea matInput
                              [formControl]="formControlAssetDescription"
                              maxlength="5000"
                              [disableControl]="disabled">
                            </textarea>
                            @if (lgtooltip) {
                              <mat-hint> Please include the size of the system, number and type of panels, number and type of inverters and the panel mounting type </mat-hint>
                            }
                            @if (!lgtooltip) {
                              <mat-hint> Please include the details of the asset, such as make, model and other critical information that can impact the value of the asset </mat-hint>
                            }
                          </mat-form-field>
                        </div>
                      </div>
                    }
                    @if (_showYearAndDescription) {
                      <!-- year & description -->
                      @if (isAssetTypeTruck()) {
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <mat-form-field>
                              <mat-label>Gross Vehicle Weight</mat-label>
                              <mat-select [formControl]="formControlGrossVehicleWeight"
                                [disableControl]="disabled">
                                @for (grossVehicleWeightOption of grossVehicleWeightSelection; track grossVehicleWeightOption) {
                                  <mat-option
                                    [value]="grossVehicleWeightOption">
                                    {{grossVehicleWeightOption}}
                                  </mat-option>
                                }
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      }
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <mat-form-field>
                            <mat-label>Year</mat-label>
                            <input type="text"
                              matInput
                              required
                              [inputMask]="createYearMask"
                              [formControl]="formControlAssetYear"
                              [disableControl]="disabled">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row separator-bottom">
                          <div class="col-lg-6 col-md-12">
                            <mat-form-field>
                              <mat-label>Description</mat-label>
                              <textarea matInput
                                [formControl]="formControlAssetDescription"
                                maxlength="5000"
                                [disableControl]="disabled">
                              </textarea>
                              @if (lgtooltip) {
                                <mat-hint> Please include the size of the system, number and type of panels, number and type of inverters and the panel mounting type </mat-hint>
                              }
                              @if (!lgtooltip) {
                                <mat-hint> Please include the details of the asset, such as make, model and other critical information that can impact the value of the asset </mat-hint>
                              }
                            </mat-form-field>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
