<div class="user-page container-fluid" fxlayoutAlign="start center">
  <button class="arrow-left mb-2" (click)="onClickBack()">
    <span class="mdi mdi-arrow-left"></span>
  </button>

  @if (!user) {
    <div class="row separator">
      <div class="col-lg-6 col-md-12">
        <message-box title="Not found" type="warn">
          <span>User not found</span>
        </message-box>
      </div>
    </div>
  }


  @if (user && user.approval) {
    <div class="row separator">
      <div class="col-lg-6 col-md-12">
        <message-box title="User approved" type="warn">
          <span>User is already approved</span>
        </message-box>
      </div>
    </div>
  }

  @if (user) {
    @if (user && user.isDisabled) {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box title="User Disabled" type="warn">
            <span>This user is disabled</span>
          </message-box>
        </div>
      </div>
    }
  }

  <ng-container>
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="separator-bottom">
          <div class="row mb-4 ml-0">
            <div class="col-12">
              <span class="mat-body-strong">
                Personal Information
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <name title="First Name" [formControl]="formControlFirstName"></name>
            </div>
            <div class="col-lg-3 col-md-12">
              <name title="Last Name" [formControl]="formControlLastName"></name>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <aggregator-and-originator-list title="Company name" [getFn]="getFn"
              [formControl]="formControlAggregatorOrOriginator"></aggregator-and-originator-list>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Mobile number</mat-label>
                <input type="text" [inputMask]="createMobilePhoneNumberInputMask" matInput
                  [formControl]="formControlMobileNumber">
                  @if (user && user.mobile_verified) {
                    <span class="mdi mdi-check-circle-outline" matSuffix></span>
                  }
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input type="text" [inputMask]="createEmailInputMask" matInput [formControl]="formControlEmail">
                  @if (user && user.verified) {
                    <span class="mdi mdi-check-circle-outline" matSuffix></span>
                  }
                </mat-form-field>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <div class="separator-bottom">
            <div class="row mb-4 ml-0">
              <div class="col-12">
                <span class="mat-body-strong">
                  Access
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <access-level  [formControl]="formControlAccessLevel"></access-level>
              </div>
              <mat-divider [inset]="true"></mat-divider>
            </div>
          </div>

          <div fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <button color="warn" (click)="rejectUser(user)"
              class="w-lt-md-100"
              fxFlexAlign="start"
              fxFlexAlign.lt-md="start"
              [disabled]="formGroup1.disabled"
            mat-stroked-button>Reject user</button>
            <button color="primary" (click)="approveUser(user)"
              class="w-lt-md-100"
              fxFlexAlign="end"
              fxFlexAlign.lt-md="start"
              type="submit"
              [disabled]="!formGroup1.valid"
            mat-flat-button>Approve user</button>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
