<div class="audit-log-component">

  <table mat-table [dataSource]="dataSource" matSort class="mb-4">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        <div fxLayout="column">
          <div class="title">{{element.dateGenerated | date:'dd/MM/yyyy'}}</div>
          <div class="description">
            {{element.dateGenerated | date:'h:mm a'}}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef>Field</th>
      <td mat-cell *matCellDef="let element">{{element.field | titlecase}}</td>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef>Old Value</th>
      <td mat-cell *matCellDef="let element">
        <p class="ellipsis">{{element.oldValue}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef>New Value</th>
      <td mat-cell *matCellDef="let element">
        <p class="ellipsis">{{element.newValue}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">{{element.user.name}}</td>
    </ng-container>

    <ng-container matColumnDef="assetNumber">
      <th mat-header-cell *matHeaderCellDef>Asset / Disbursement #</th>
      <td mat-cell *matCellDef="let element">{{element.assetNumber}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  @if (total) {
    <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
    [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
  }

  @if (total<1) {
    <table>
      <tr>
        <td class="not-found"> No audit log found</td>
      </tr>
    </table>
  }

</div>
