import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { navigationUrlForLogin, navigationUrlForAccreditation, navigationUrlFor2FA, navigationUrlForReset2FASmsVerification } from '../../service/navigation-urls';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ApplicationDialogService, getUser, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { MinimalLayoutService } from '../../layout/minimal-layout/minimal-layout.service';
import { loadingFor } from '@ngneat/loadoff';
import { environment } from '../../../environments/environment';
import { PromotionResponse, User } from '@portal-workspace/grow-shared-library';
import { RegistrationService } from '../../service/registration.service';
import { TwoFactorAuthService } from '../../service/2fa.service';
import { FlexModule } from '@angular/flex-layout/flex';

@UntilDestroy({ arrayName: 'subscriptions', checkProperties: true })
@Component({
    templateUrl: './reset-2fa.page.html',
    styleUrls: ['./reset-2fa.page.scss'],
    standalone: true,
    imports: [FlexModule, RouterLink]
})
export class ResetTwoFAPage implements OnInit {
  user !: User;
  mobileNumber: string | undefined;
  code?: string;
  email?: string;
  verificationStatus: 'pending' | 'success' | 'failed' | 'verified' = 'pending';
  environment = environment;

  subscriptions: Subscription[] = [];

  loader = loadingFor('logon');

  navigationForLoginPage = navigationUrlForLogin;

  formGroup!: FormGroup<{
    email: FormControl<string | null>,
    password: FormControl<string | null>
  }>;
  formControlEmail!: FormControl<string | null>;
  formControlPassword!: FormControl<string | null>;
  inProgressLoader = loadingFor('InProgressUser');
  loginError: string | null = null;
  data?: PromotionResponse



  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private dialogService: ApplicationDialogService,
    private minimalLayoutService: MinimalLayoutService,
    private toastService: PortalHotToastService,
    private tfaService: TwoFactorAuthService,) {
    this.formControlEmail = formBuilder.control('', [Validators.required, Validators.email]);
    this.formControlPassword = formBuilder.control('', [Validators.required]);
    this.formGroup = formBuilder.group({
      email: this.formControlEmail,
      password: this.formControlPassword,
    });
  }

  hide = true;

  ngOnInit() {
    setupUntilDestroy(this);
    this.code = this.route.snapshot.params.code as string;
    if (!!!this.code) {
      this.verificationStatus = 'failed';
    } else {
      const userId = this.getUserId(this.code);
      this.ifUserVerified(userId);
    }
    this.user = getUser()!;
    let mobileNo = this.user.MobileNumber
    this.mobileNumber = mobileNo?.slice(-2)
    let email = this.user.Email
    let index = email.indexOf('@')
    let name = email.substring(0, index);
    let firstChar = email.slice(0, 2);
    let middleChar = name.charAt(name.length - 1) + '@' + email.substring(index + 1).slice(0, 1);
    var domain = email.split('.').pop();
    this.email = firstChar + '...' + middleChar + '...' + domain;
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false,
    });
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch (r.type) {
            case 'accreditation-clicked': {
              await this.router.navigate(navigationUrlForAccreditation());
            }
          }
        }
      })
    ).subscribe());
  }
  navigationFor2FA (){
    return navigationUrlFor2FA().toString()
  }
  getUserId(code: string) {
    const decodedString = atob(code);
    const decodedArray = decodedString.split('/');
    if (decodedArray && decodedArray.length) {
      return Number(decodedArray[decodedArray.length - 1]);
    } else {
      return 0;
    }
  }

  ifUserVerified(userId: number) {
    const sub = this.registrationService.getUserVerificationStatus(userId).pipe(
      this.inProgressLoader.InProgressUser.track()
    ).subscribe(response => {
      if (response && response?.payload && response?.payload?.verified) {
        this.verificationStatus = 'verified';
      }
    })
    this.subscriptions.push(sub);
  }

  sendEmailVerification($event: Event) {
    if (!!!this.user?.Email) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'No Email to send to'
      });
      return;
    }
    this.tfaService.sendResetCode2faTokenEmail({
      fname: this.user.Name ?? `${this.user.GivenName ?? ''} ${this.user.FamilyName ?? ''}`,
      UserId: this.user.UserId,
      mobile:  this.user.MobileNumber,
      email: this.user.Email
    }).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`Verification email sent to ${this.user.Email}`),
    ).subscribe();
  }

  sendSmsVerification($event: Event) {
    const number = this.checkValidMobile();
    if (number && this.user) {
      // this.adminService.sendVerificationSMSCode(number).pipe(
      this.registrationService.sendSmsVerificationCode(this.user.UserId, number).pipe(
        this.toastService.snackBarObservable(`Verification SMS send to ${number}`),
        this.toastService.spinnerObservable(),
        tap(r => {
          this.router.navigate(navigationUrlForReset2FASmsVerification());
        })
      ).subscribe();
    }
  }


  private checkValidMobile(): string | null {
    // if (!!!this.user?.PhoneNumber) {
    //   this.dialogService.openAlertDialog({
    //     message: 'Error',
    //     subMessage: 'No phone number to send SMS'
    //   });
    //   return null;
    // }
    if (!!!this.user?.MobileNumber) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'No mobile number to send SMS'
      })
      return null;
    }
    return this.user.MobileNumber;
  }
}
