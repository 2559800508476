<div class="confirm-password-admin-dialog">
  <mat-dialog-content>
    <confirm-password-admin [formControl]="formControlPassword" ></confirm-password-admin>
    <div class="row">
      <div class="col">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button fxLayoutAlign="start center" (click)="cancel($event)">Cancel</button>
          <button mat-flat-button fxLayoutAlign="end center" color="primary" (click)="changePassword($event)"
                  [disabled]="formGroup.invalid">Submit</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
