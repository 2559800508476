// ========================================================================
// === parse australian address / Address Utils
// ========================================================================

import {constants, getStates} from '../const';

// const parser = require('australia-address-parser');
import {Parser} from '@tmjeee/australia-address-parser';

import { Address, Address2, RawAddress, UnformattedAddress } from "../domain/application-service.domain";
import { Address2ComponentValue } from "../domain/component/address2-component.domain";

const parser = new Parser();

// NOTE: changes made in this function needs to be reflected in API's digitalid.controller.js as well
export type ParsedAddress = {
    unit_number?: string,
    street_number: string,
    street_name: string,
    street_type: string | null,
    locality: string, // suburb
    region: string | null,   // state
    postal_code: string,
    country: 'AUS',
}
export const toUnformattedAddress = (address: string): Address => {
    const parsedAddress = parseRawAddress(address);
    return {
        UnformattedAddress: address,
        IsManualInput: false,
        Postcode: parsedAddress?.postal_code ?? '',
        State: parsedAddress?.region ?? '',
        StreetName: parsedAddress?.street_name ?? '',
        StreetType: parsedAddress?.street_type ?? '',
        StreetNumber: parsedAddress?.street_number ?? '',
        Suburb: parsedAddress?.locality ?? '',
        UnitNumber: parsedAddress?.unit_number ?? '',
    }
}
export const toRawAddress = (address: string): Address2 => {
    const parsedAddress = parseRawAddress(address);
    return {
        RawAddress: address,
        IsManualInput: false,
        Postcode: parsedAddress?.postal_code ?? '',
        State: parsedAddress?.region ?? '',
        StreetName: parsedAddress?.street_name ?? '',
        StreetType: parsedAddress?.street_type ?? '',
        StreetNumber: parsedAddress?.street_number ?? '',
        Suburb: parsedAddress?.locality ?? '',
        UnitNumber: parsedAddress?.unit_number ?? '',
    }
}

export const fromAddress2ComponentValueToUnformattedAddress = (address: Address2ComponentValue) => {
    if (address && address.address) {
        const a: UnformattedAddress = {
            UnitNumber: address.UnitNumber,
            Suburb: address.Suburb,
            State: address.State,
            Postcode: address.Postcode,
            StreetName: address.StreetName,
            StreetNumber: address.StreetNumber,
            UnformattedAddress: address.address,
            IsManualInput: false,
            StreetType: address.StreetType,
        };
        return a;
    }
    return undefined;
}

export const fromAddress2ComponentValueToRawAddress = (address: Address2ComponentValue) => {
    if (address && address.address) {
        const a: RawAddress = {
            UnitNumber: address.UnitNumber,
            Suburb: address.Suburb,
            State: address.State,
            Postcode: address.Postcode,
            StreetName: address.StreetName,
            StreetNumber: address.StreetNumber,
            RawAddress: address.address,
            IsManualInput: false,
            StreetType: address.StreetType,
        };
        return a;
    }
    return undefined;
}


const _trim = (s?: string): string => {
    if (s) {
        return s.trim();
    }
    return '';
}
const _trim2 = (s?: string): string | null => {
    if (s) {
        return s.trim();
    }
    return null;
}

export const removeUnprintableChar = (s: string | null): string | null => {
    if (s != null) {
        return s.replace(/[^ -~]+/g, "").trim();
    } else {
        return null;
    }
}

export const parseRawAddress = (address: string): ParsedAddress | null => {
    let _address = address;
    if (_address && _address.trim().toLowerCase().endsWith('australia')) {
        _address = _address.trim().substring(0, _address.trim().length - 'australia'.length);
    }

    let addressObj: ParsedAddress | null = null;

    // Attempt #1 : unit / flat address, 1/232-232 Pete St, Hornsby, 2000 NSW
    const pattern1 = /([^/]+)\/([^ ]*)[ ]*([^]+)[ ]+([^,]+)[ ]*[,]+[ ]*([^,]+)[ ]*[ ,]+([^ ]+)[ ]+(\d+)/;
    if (pattern1.test(_address)) {
        const r = pattern1.exec(_address);
        if (r) {
          // console.log('pattern 1');
            addressObj = {
                unit_number: _trim(r[1]),
                street_number: _trim(r[2]),
                street_name: _trim(r[3]),
                street_type: _trim2(r[4]),
                locality: _trim(r[5]),
                region: _trim2(r[6]),
                postal_code: _trim(r[7]),
                country: 'AUS'
            };
        }
    }

    // Attempt #2: Level address, Level 2 Burke St, Hornsby, 1223 Vic
  const pattern = /([^ ]+)[ ]+([^ ]+)[ ]+([^]+)[ ]+([^,]+)[ ]*[,]+[ ]*([^,]+)[ ]*[ ,]+([^ ]+)[ ]+(\d+)/;
  if (!addressObj && pattern.test(_address)) {
    const r = pattern.exec(_address);
    // let _ad= _address.split(",");
    // const _r1=_ad[0].split(/\s+/g), _r2=_ad[1].split(/\s+/g), r = pattern2.exec(_address);
    if (r) {
      const secondPortionContainsNumber = containsNumber(r[2]);
      if (secondPortionContainsNumber) {
        // console.log('pattern 2');
        addressObj = {
          street_number: secondPortionContainsNumber ? `${_trim(r[1])} ${_trim(r[2])}` : '',
          street_name: secondPortionContainsNumber ? _trim(r[3]) : `${_trim(r[1])} ${_trim(r[2])} ${_trim(r[3])}`,
          street_type: _trim2(r[4]),
          locality: _trim(r[5]),
          region: _trim2(r[6]),
          postal_code: _trim(r[7]),
          country: 'AUS'
        };
      }
    }
  }


    // Attempt #3 : house address, 25 Mount St, Hornsby, 2000 Vic
    const pattern2 = /([^ ]*)[ ]*([^]+)[ ]+([^,]+)[ ]*[,]+[ ]*([^,]+)[ ]*[ ,]+([^ ]+)[ ]+(\d+)/;
    if (!addressObj && pattern2.test(_address)) {
        const r = pattern2.exec(_address);
        // let _ad= _address.split(",");
        // const _r1=_ad[0].split(/\s+/g), _r2=_ad[1].split(/\s+/g), r = pattern2.exec(_address);
        if (r) {
            // console.log('pattern 3');
            const firstPortionContainsNumber = containsNumber(r[1]);
            addressObj = {
                street_number: firstPortionContainsNumber ? _trim(r[1]) : '',
                street_name: firstPortionContainsNumber ? _trim(r[2]) : `${_trim(r[1])} ${_trim(r[2])}`,
                street_type: _trim2(r[3]),
                locality: _trim(r[4]),
                region: _trim2(r[5]),
                postal_code: _trim(r[6]),
                country: 'AUS'
            };
        }
    }

    if (!addressObj) {
       // console.log('pattern 4');
        const parsedAddress = parser.parseAddress(_address);
        addressObj = parsedAddress ? {
            unit_number: _trim(parsedAddress.unitNumber),
            street_number: _trim(parsedAddress.streetNumber),
            street_name: _trim(parsedAddress.streetName),
            street_type: _trim2(parsedAddress.streetType),
            locality: _trim(parsedAddress.suburb),
            region: _trim2(parsedAddress.state),
            postal_code: _trim(parsedAddress.postcode),
            country: 'AUS'
        } : null;
    }

    if (!addressObj) {
      // console.log('pattern 5');
      const parsedAddress = parser.parseInformalAddress(_address);
      addressObj = parsedAddress ? {
        unit_number: _trim(parsedAddress.unitNumber),
        street_number: _trim(parsedAddress.streetNumber),
        street_name: _trim(parsedAddress.streetName),
        street_type: _trim2(parsedAddress.streetType),
        locality: _trim(parsedAddress.suburb),
        region: _trim2(parsedAddress.state),
        postal_code: _trim(parsedAddress.postcode),
        country: 'AUS'
      } : null;
    }


    // lookup address street type
    if (addressObj && addressObj.street_type) {
        addressObj.street_type = parseRawStreetType(addressObj.street_type);
    }

    // lookup state
    if (addressObj && addressObj.region) {
        addressObj.region = parseRawState(addressObj.region);
    }

    return addressObj;
};

export const parseRawState = (state: string /* know as region as well */): string | null => {
    if (state) {
        const stateInUpperCase = state.toUpperCase().trim();
        if (!Object.keys(constants.stateList).includes(stateInUpperCase)) {
            // map state into abbreviated form
            const st = getStates().find(s => s.name.toUpperCase() == stateInUpperCase);
            if (st) {
                return st.type;
            } else {
                return null;
            }
        }
    }
    return state;
}

export const parseRawStreetType = (streetType: string): string | null => {
    if (streetType) {
        // if street type is in "not know" abbreviated forms eg. RD, ST
        const streetTypeInUpperCase = streetType.toUpperCase().trim();
        if (!Object.values(constants.streetTypes).includes(streetTypeInUpperCase)) {
            // map street type into abbreviated form
            const streetTypeInLowerCase = streetType.toLowerCase().trim();
            const type = (constants.streetTypes as any)[streetTypeInLowerCase];
            if (type) {
                return type;
            } else {
                return null;
            }
        }
    }
    return streetType;
}


export const containsNumber = (s: string): boolean => {
    return /\d/.test(s);
}

export const normalizeAddress = (address: string): string => {
  return (address ?? '').toLowerCase()
    .replace(',', ', ') // replace , with space
    .replace(/new\s+south\s+wales/g, ' nsw ')
    .replace(/victoria/g, ' vic ')
    .replace(/queensland/g, ' qld ')
    .replace(/tasmania/g, ' tsa ')
    .replace(/western\s+australia/g, ' wa ')
    .replace(/south\s+australia/g, ' sa ')
    .replace(/australian\s+capital\s+teritory/g, ' act ')
    .replace(/northern\s+territory/g, ' nt ')
    .replace(/\s+/g, ' ') // replace multiple spaces with one space
    .trim()
}

export const parseAddressComponentsToString= (unitNumber?: string | null,
                                              streetNumber?: string | null,
                                              streetName?: string | null,
                                              streetType?: string | null,
                                              suburb?: string | null,
                                              state?: string | null,
                                              postcode?: string | null) => {
                                              // formControlAddress?: string | null)=>{
        // const formControls = [
        //     formControlUnitNumber,
        //     formControlStreetNumber,
        //     formControlStreetName,
        //     formControlStreetType,
        //     formControlSuburb,
        //     formControlState,
        //     formControlPostcode,
        // ];
        // formControls.forEach((control) => {
        //     control.valueChanges.subscribe(() => {
        //       updateAddress();
        //     });
        // });

        // const updateAddress = () => {
            let address = unitNumber ? `${unitNumber}/${streetNumber ?? ''}` : `${streetNumber ?? ''}`;
            address += ` ${streetName ?? ''} ${streetType ?? ''}`
            address += `${suburb ? ', ' + suburb : ''}`;
            address += `${state ? ', ' + state : ''}`;
            address += `${postcode ? ' ' + postcode : ''}`;
            return address;
        // };
        // return updateAddress;
}
