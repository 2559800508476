import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, EntityTypeValue, EntityTypeValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgStyle } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import {MatInputModule} from "@angular/material/input";


@UntilDestroy()
@Component({
    selector: 'entity-type',
    templateUrl: './entity-type.component.html',
    styleUrls: ['./entity-type.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EntityTypeComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => EntityTypeComponent) },
    ],
    standalone: true,
    imports: [
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      NgStyle,
      ExtendedModule,
      MatInputModule,
      MatSelectModule,
      MatOptionModule,
      DisableControlDirective,
    ]
})
export class EntityTypeComponent extends AbstractControlValueAccessor<EntityTypeValue> implements OnInit, Mark, OnChanges {

  @Input({required: false}) title = 'Nature of business';
  @Input({required: false}) onlyAllowCompany = false;
  @Input({required: false}) readonly = false;

  options = EntityTypeValueOptions;


  compareWith = (o1: Exclude<EntityTypeValue, null>, o2: Exclude<EntityTypeValue, null>) => {
    return (o1 && o2 && o1.type === o2.type);
  };

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup!: FormGroup<{
    entityType: FormControl<EntityTypeValue>
  }>
  formControl!: FormControl<EntityTypeValue>;
  subscription?: Subscription;


  constructor(private formBuilder: FormBuilder) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if ((changes as any).readonly) {
    //   this.readonly = (changes as any).readonly.currentValue
    //   if (this.formControl) {
    //     if (this.readonly) {
    //       this.formControl.disable();
    //     } else {
    //       this.formControl.enable();
    //     }
    //   }
    // }
  }


  onlyCompanyValidator: ValidatorFn = (control: AbstractControl) => {
    const v: EntityTypeValue = control.value;
    console.log('*** company validator', v, this.formGroup);
    if (v) {
      if (v.type !== 'company') {
        console.log('*** company validator ERROR', v, this.formGroup);
        return {companyOnly: true};
      }
    }
    return null; // valid
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, this.onlyAllowCompany ? [Validators.required, this.onlyCompanyValidator]: [Validators.required]);
    this.formGroup = this.formBuilder.group({
      entityType: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap( (v: any )  => {
        console.log('entity type component', v);
        console.log('entity type component this.', this.formGroup);
        if (this.formGroup.valid /*|| this.formGroup.disabled*/) {
          // this.formControl.disable({onlySelf:false,emitEvent:true})
          this.propagateChange(v);
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe();
  }

  doWriteValue(v: EntityTypeValue | undefined): EntityTypeValue | undefined {
    if (v) {
      const t = EntityTypeValueOptions.find(opt => opt.type === v.type);
      if (t) {
        this.formControl.setValue(t);
      }
      return t ? t : null;
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
