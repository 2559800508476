<div class="security-type-rate-component">
  @for (securityTypeOption of securityTypeOptions; track securityTypeOption) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h4-custom">{{securityTypeOption.name}}</div>
      </div>
    </div>
    @for (ltvOption of ltvOptions; track ltvOption) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>LTV {{ltvOption.name}}</mat-label>
            <input matInput
              [inputMask]="createTwoDecimalInputMask"
              type="text"
              [formControl]="formControl(securityTypeOption.type, ltvOption.type)">
              <span class="mdi mdi-percent" matSuffix></span>
            </mat-form-field>
          </div>
        </div>
      }
    }
  </div>
