import {
  HttpInterceptorFn,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const HttpErrorInterceptorFn: ()=>HttpInterceptorFn = (): HttpInterceptorFn => {
  return (req, next) => next(req).pipe(
    catchError((error, caught) => {
      (error as any).request = req;
      return throwError(()=>error);
    }),
  );
}



// NOTE: DEPRECATED
// @Injectable()
// export class HttpErrorInterceptor implements HttpInterceptor {
//
//   constructor(private appInsightService: AppInsightService) {}
//
//   intercept(request: HttpRequest<any>, next: HttpHandler) {
//     return next.handle(request).pipe(
//       catchError((error: Error) => {
//         (error as any).request = request;
//         // this.logAppInsight(error, request);
//         return throwError(error);
//       })
//     )
//   }
//
//   // NOTE: not used
//   // logAppInsight(error: Error, request: HttpRequest<any>) {
//   //   if (error instanceof HttpErrorResponse) {
//   //     if (error.status == 401) {
//   //       return;
//   //     }
//   //   }
//   //   const eventUid = uuid.v4().split('-')[3];
//   //   const user = getUser();
//   //   const currentDate = moment().format('DD-MM-YYYY hh:mm:a');
//   //   const location = window?.location?.href;
//   //   const se = serializeError(error);
//   //   const stringifyError = stringifyJSON(se);
//
//   //   // this.appInsightService.logException(error, 3);
//   //   this.appInsightService.logEvent(`Http Error - ${eventUid}`, {
//   //     'eventType': 'HttpError',
//   //     'eventUid': eventUid,
//   //     'effectiveUserId': user?.UserId ?? 'unknown',
//   //     'email': user?.Email ?? 'unknown',
//   //     'date': currentDate,
//   //     'browserUrl': location,
//   //     'error': se,
//   //     // 'stringifyError': stringifyError,
//   //     'req-method': request.method,
//   //     'payload': request.body ?? 'none',
//   //     // 'request-url': request.url,
//   //     'request': request
//   //   });
//   // }
// }
