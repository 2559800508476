<div class="asset-settlement-component">
  <mat-card appearance="outlined">
    <mat-card>
      <asset-details
        [removeApplicationAssetFn]="removeApplicationAssetFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [updateApplicationAssetsFn]="updateApplicationAssetsFn"
        [application]="application"
        [isPpsrChecked]="isPpsrChecked"
        [isPpsrRegistered]="isPpsrRegistered"
        [searchAssetFn]="assetSettlementSearchAssetsFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [createAssetInspectionFn]="createAssetInspectionFn"
        [assetInspectionTableData]="assetInspectionTableData"
        [verimotoLender]="verimotoLender"
        [verimotoAssetInspectionTypes]="verimotoAssetInspectionTypes"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        (onEditAsset)="onEditAsset($event)"
        (emptyAsset)="emptyAssetFn($event)"
        (onDeleteClick)="onDeleteClick($event)"
        (onPPSRStatus)="onPPSRStatus($event)"
        (newAssetInspection)="onCreateNewAssetInspection($event)"
        (onAddAuditLog)="onAddAuditLog($event)"
      ></asset-details>
    </mat-card>
    <div class="break"></div>
    @if (assetInspectionTableData && assetInspectionTableData.length) {
      <asset-inspections
        [application]="application"
        [getAssetInspectionsForApplicationFn]="getAssetInspectionsForApplicationFn"
        [assetInspectionTableData]="assetInspectionTableData"
        [downloadVerimotoReportFn]="downloadVerimotoReportFn"
        [verimotoAssetInspectionTypes]="verimotoAssetInspectionTypes"
        [apiUrl]="apiUrl"
      ></asset-inspections>
      <div class="break"></div>
    }
    @if (isInternalUser(loggedInUser)) {
      <mat-card>
        <disbursement-table
          [getApplicationAssetFn]="getApplicationAssetFn"
          [application]="application"
          [createNewDisbursementFn]="createNewDisbursementFn"
          [updateDisbursementFn]="updateDisbursementFn"
          [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
          [syncDisbursementToSfFn]="syncDisbursementToSfFn"
          [searchSupplierFn]="searchSupplierFn"
          [paymentChartData]="paymentChartData"
          [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
          [syncPrivateSellerBankDetailsToSfFn]="syncPrivateSellerBankDetailsToSfFn"
          [syncDepositPaidToSfFn]="syncDepositPaidToSfFn"
          [getBillerNameFn]="getBillerNameFn"
          [getInstitutionNameFn]="getInstitutionNameFn"
          [validateBpayFn]="validateBpayFn"
          [addAuditLogFn]="addAuditLogFn"
        ></disbursement-table>
      </mat-card>
    }
    <div class="break"></div>
    <mat-card>
      <asset-notes
        [isAssetUpdated]="isAssetUpdated"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [onlyShowNotes]="false"
      ></asset-notes>
    </mat-card>
  </mat-card>
</div>
