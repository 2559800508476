import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { AddCreditAlertFn, Application, ApplicationCreditFlowNodeDetail, ApplicationWebService, CreditAlert, CreditorWatchReportData, DEFAULT_LIMIT, DEFAULT_OFFSET, DownloadCreditorWatchReportFn, EquifaxConsumerReportData, GetCreditAlertsFn, GetCreditFlowsFn, UpdateCreditAlertFn, UpdateCreditFlowFn, displayApplicationDateTime } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { MatTooltipModule } from '@angular/material/tooltip';

interface ApplicationWebServiceWithApplicant extends ApplicationWebService {
  applicant: string;
  applicantType: string;
}

@Component({
    selector: 'credit-result-web-services',
    templateUrl: './credit-result-web-services.component.html',
    styleUrls: ['./credit-result-web-services.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatTooltipModule,
    ]
})
export class CreditResultWebServicesComponent implements OnInit, OnChanges {
  @Input({required: true}) data!: ApplicationWebService[];
  @Input({required: true}) application!: Application;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input() apiUrl!: string;
  @Output() viewReport: EventEmitter<ApplicationWebService> = new EventEmitter();

  subscriptions: Subscription[] = [];
  moment = moment;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: ApplicationWebServiceWithApplicant[] = [];
  columnsToDisplay = ['applicant', 'type', 'lastUpdatedTime', 'report'];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
  }

  ngOnInit(): void {
    this.loadDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDataSource();
  }

  loadDataSource() {
    this.dataSource = [];
    for (const webService of this.data) {
      let applicant = '', applicantType = '';
      if (webService.level === 'individual') {
        const individual = this.application.Individuals.find(individual => individual.id === webService.individualId);
        if (individual) {
          applicant = `${individual.GivenName} ${individual.SurName} (${individual.DoB})`;
          applicantType = `${individual.Role ?? individual.SignerRole}`;
        }
      } else {
        const entity = this.application.CommercialEntities.find(entity => 
          ((webService.ABN && webService.ABN != "") ? (webService.ABN) : null) == 
          ((entity.ABN && entity.ABN != "") ? (entity.ABN) : null) 
          && 
          ((webService.ACN && webService.ACN != "") ? (webService.ACN) : null) == 
          ((entity.ACN && entity.ACN != "") ? (entity.ACN) : null) 
        )
        if (entity) {
          applicant = `${entity.LegalName}`;
          applicantType = `${entity.Type}`;
        }
      }

      this.dataSource.push({
        ...webService,
        applicant,
        applicantType,
      })
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'applicant': return 'Applicant';
      case 'type': return 'Type';
      case 'report': return 'Report';
      case 'lastUpdatedTime': return 'Updated Time';
      default: return column;
    }
  }

  onDownloadReport(element: ApplicationWebServiceWithApplicant) {
    if (element.type === 'CreditorWatch') {
      const pdfId = (element.json as CreditorWatchReportData).pdfRequestId;
      const url = `${this.apiUrl}/credit/download-creditorwatch-report/${pdfId}`;
      window.open(url)
    } else if (element.type === 'EquifaxConsumer') {
      const url = `${this.apiUrl}/credit/download-equifax-consumer-report/${element.id}`;
      window.open(url)
    } else if (element.type === 'EquifaxFraudCheck') {
      const url = `${this.apiUrl}/credit/download-equifax-fraud-check-report/${element.id}`;
      window.open(url)
    }
  }

  onViewReport(element: ApplicationWebServiceWithApplicant) {
    this.viewReport.emit(element)
  }

  getWebServiceUpdatedTime(webService: ApplicationWebService) {
    return moment(webService.lastUpdatedTime).format("DD/MM/YYYY h:mm a");
  }

  onViewJson(element: ApplicationWebServiceWithApplicant) {
    this.dialogService.openWebServiceJsonDialog({
      webService: element
    }).afterClosed().subscribe()
  }
}
