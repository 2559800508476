import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CdkTable} from '@angular/cdk/table';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { AmortisationScheduleEntry } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';



export class AmortisationScheduleDataSource extends DataSource<AmortisationScheduleEntry> {

  subject = new BehaviorSubject<AmortisationScheduleEntry[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<AmortisationScheduleEntry[]> {
    return this.subject;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(e: AmortisationScheduleEntry[]) {
    this.subject.next(e);
  }

}

export class AmortisationScheduleDataSource2 extends DataSource<AmortisationScheduleEntry['details'][number]> {

  subject = new BehaviorSubject<AmortisationScheduleEntry['details'][number][]>([]);

  constructor(parent: AmortisationScheduleEntry) {
    super();
    this.subject.next(parent.details);
  }


  connect(collectionViewer: CollectionViewer): Observable<AmortisationScheduleEntry['details'][number][]> {
    return this.subject;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

}


@Component({
    selector: 'amortisation-schedule',
    templateUrl: './amortisation-schedule.component.html',
    styleUrls: ['./amortisation-schedule.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', opacity: 0, minHeight: '0', visibility: 'invisible', display: 'none' })),
            state('expanded', style({ height: '*', opacity: '*', visibility: 'visible', display: 'inline-block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class AmortisationScheduleComponent implements OnInit, OnChanges {

  expandedEntries: AmortisationScheduleEntry[] = [];
  dataSource = new AmortisationScheduleDataSource();

  @Input({required: false}) data: AmortisationScheduleEntry[] = [];

  ngOnInit(): void {
    this.dataSource.update(this.data);
    // this.dataSource.update([
    //   {year: '2001', payment: '$1000', principal: '$200', interest: '$300', balance: '$300', details: [
    //       {monthYear: 'Jan 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Feb 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Mar 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Apr 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'May 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //     ]},
    //   {year: '2002', payment: '$1000', principal: '$200', interest: '$300', balance: '$300', details: [
    //       {monthYear: 'Jan 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Feb 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Mar 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'Apr 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //       {monthYear: 'May 2001', payment: '$100', interest: '$10', principal: '$20', balance: '$30'},
    //     ]},
    // ])
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.update(this.data);
  }

  toggleExpandRow(element: AmortisationScheduleEntry) {
    const index = this.expandedEntries.indexOf(element);
    if (index === -1) {
      this.expandedEntries.push(element);
    } else {
      this.expandedEntries.splice(index, 1);
    }
  }

  isRowExpanded(element: AmortisationScheduleEntry): boolean {
    return this.expandedEntries.indexOf(element) !== -1;
  }

  createDataSource(element: AmortisationScheduleEntry): DataSource<AmortisationScheduleEntry['details'][number]> {
    return new AmortisationScheduleDataSource2(element);
  }
}
