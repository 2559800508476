<div class="bank-account-chip-component">
  <div fxLayoutAlign="end end" fxLayout="row" fxLayoutGap="20px">
    <div class="data-box-component">
      <span class="mdi mdi-bank-outline"></span>
      <span class="title">BSB: {{bsb}}</span>
      <span class="dot"> . </span>
      <span class="title">Account No: {{accountNumber}}</span>
    </div>
  </div>
</div>

