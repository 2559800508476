import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AggregatorSearchFn } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponentSearchFn, } from '@portal-workspace/grow-ui-library';
import {
  AssetConditionValue,
  AssetSelectionComponentValue, BalloonPaymentValueOptions,
    BusinessSearchResultValue,
  BusinessSearchValue,
  CurrencyInputValue,
  FinanceTypeValue,
  PaymentFrequencyValue,
  Quote,
  SearchCompanyByABNResult, TransactionValue, YesNoValue,
} from '@portal-workspace/grow-shared-library';
import {
  RedbookSearch
} from '@portal-workspace/grow-shared-library';
import {combineLatest, of, Subscription} from 'rxjs';
import {
  calculateAssetFinanceEstimation,
  minDepositForAssetFinance,
} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { RedbookService } from '../../service/redbook.service';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import {map, tap} from 'rxjs/operators';
import {
  doMarkAll,
  markTriggerSubject
} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {Moment} from 'moment';
import {TotalPaymentBreakupDialogData} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue} from '@portal-workspace/grow-shared-library';
import {BalloonPaymentComponent } from '@portal-workspace/grow-ui-library';
import {BalloonPaymentValue} from '@portal-workspace/grow-shared-library';
import numeral from 'numeral';
import {ActivatedRoute,Router} from '@angular/router';
import {ApplicationService} from '../../service/application.service';
import {RatecardDetails,} from '@portal-workspace/grow-shared-library';
import {LoanTermValue} from '@portal-workspace/grow-shared-library';
import {AppCalculator} from '@portal-workspace/grow-shared-library';
import { navigationUrlForApplications } from '../../service/navigation-urls';
import { ASSET_FINANCE_OPTIONS_FILTER, } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { BrokerageSelectionComponent } from '@portal-workspace/grow-ui-library';

import { BalloonPaymentComponent as BalloonPaymentComponent_1 } from '@portal-workspace/grow-ui-library';
import { FinanceTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { LoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { AssetConditionComponent } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { TransactionTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './asset-finance-calculator.page.html',
    styleUrls: ['asset-finance-calculator.page.scss'],
    standalone: true,
    imports: [FlexModule, FormsModule, ReactiveFormsModule, CurrencyInputComponent, MarkDirective, AssetSelectionComponent, AssetConditionComponent, YesNoComponent, LoanTermsSelectionComponent, PaymentFrequencySelectionComponent, FinanceTypeSelectionComponent, BalloonPaymentComponent_1, BrokerageSelectionComponent, MatButtonModule, TransactionTypeSelectionComponent],
})
export class AssetFinanceCalculatorPage implements OnInit, AfterViewInit {
  ASSET_FINANCE_OPTIONS_FILTER = ASSET_FINANCE_OPTIONS_FILTER;

  MIN_DEPOSIT = 2000;

  depositMin: number | null = null;
  depositMax: number | null = null;
  assetCategoryIndexFilter = ['137', '132','130','140','136','133','134','106','135','142'];


  subscriptions: Subscription[] = [];

  markTriggerSubject = markTriggerSubject;

  formGroupCalculator!: FormGroup <{
      assetCondition: FormControl<AssetConditionValue>,
      invoiceAmount: FormControl<CurrencyInputValue>,
      depositAmount: FormControl<CurrencyInputValue>,
      loanAmount: FormControl<CurrencyInputValue>,
      asset: FormControl<AssetSelectionComponentValue>,
      loanTerms: FormControl<LoanTermValue>,
      paymentFrequency: FormControl<PaymentFrequencyValue>,
      financeType: FormControl<FinanceTypeValue>,
      docFeeFinanced: FormControl<YesNoValue>,
      balloonPayment: FormControl<BalloonPaymentValue>,
      brokerOriginationFee: FormControl<CurrencyInputValue>,
      brokerage: FormControl<BrokerageSelectionValue>,
      propertyOwner: FormControl<YesNoValue>,
      adverseOnFile: FormControl<YesNoValue>,
      equifaxScoreAboveThreshold: FormControl<YesNoValue>,
      abnGstAgeAboveThreshold: FormControl<YesNoValue>,
      transactionType: FormControl<TransactionValue>,
      previousLoan: FormControl<YesNoValue>,
  }>;
  BrokerSearchFn!: AggregatorSearchFn;
  formControlInvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlDepositAmount!: FormControl<CurrencyInputValue>;
  formControlLoanAmount!: FormControl<CurrencyInputValue>;
  formControlAsset!: FormControl<AssetSelectionComponentValue>;
  AssetSearchFn!: AssetSelectionComponentSearchFn;
  formControlAssetCondition!: FormControl<AssetConditionValue>;
  formControlLoanTerms!: FormControl<LoanTermValue>;
  formControlPaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlFinanceType!: FormControl<FinanceTypeValue>;
  formControlDocFeeFinanced!: FormControl<YesNoValue>;
  formControlBalloonPayment!: FormControl<BalloonPaymentValue>;
  formControlBrokerOriginationFee!: FormControl<CurrencyInputValue>;
  formControlBrokerage!: FormControl<BrokerageSelectionValue>;
  formControlPropertyOwner!: FormControl<YesNoValue>;
  formControlAdverseOnFile!: FormControl<YesNoValue>;
  formControlEquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlAbnGstAgeAboveThreshold!: FormControl<YesNoValue>;
  formControlPreviousLoan!: FormControl<YesNoValue>;
  formControlTransactionType!: FormControl<TransactionValue>

  rateCard!: RatecardDetails;
  @ViewChild('balloonPaymentComponent') balloonPaymentComponent!: BalloonPaymentComponent;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
    private activatedRoute: ActivatedRoute,
    private redbookService: RedbookService,
    private appService: ApplicationService,
    private router: Router) {

    this.initCalculator();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.rateCard = (this.activatedRoute.snapshot.data as any).ratecard;
    this.subscriptions.push(this.formControlInvoiceAmount.valueChanges.pipe(
      tap(r => {
       this.updateLoanAmount();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlDepositAmount.valueChanges.pipe(
      tap(r => {
        this.updateLoanAmount();
    })).subscribe());

    // deposit calculation trigger
    this.subscriptions.push(this.formControlInvoiceAmount.valueChanges.pipe(
      tap(r => {
        this.updateDepositValidation();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlPropertyOwner.valueChanges.pipe(
      tap(r => {
        this.updateDepositValidation();
      })
    ).subscribe());
    this.subscriptions.push(this.formControlAsset.valueChanges.pipe(
      tap(r => {
        this.updateDepositValidation();
      })
    ).subscribe());
  }

  ngAfterViewInit(): void {
    const sub2= combineLatest([
      this.formControlAsset.valueChanges,
      this.formControlLoanTerms.valueChanges
    ]).pipe(
      tap((r: [AssetSelectionComponentValue, LoanTermValue]) => {
        const assetSelectionValue = r[0];
        const loanTermValue = r[1];
        this.updateBalloonPaymentValidation(assetSelectionValue, loanTermValue);
      })
    ).subscribe();
    this.subscriptions.push(sub2);
  }

  updateBalloonPaymentValidation(assetSelectionValue: AssetSelectionComponentValue, loanTermValue: LoanTermValue) {
    if (assetSelectionValue && loanTermValue) {
      const assetCatgory = assetSelectionValue.category.index;
      const loanTerm = parseInt(loanTermValue.type);
      const assetYear = parseInt(assetSelectionValue.year);
      const maxRv = new AppCalculator().getMaxRV(assetCatgory, loanTerm, assetYear);
      this.balloonPaymentComponent.setMaxRv(maxRv);
    }
  }

  updateDepositValidation() {
    const asset: AssetSelectionComponentValue = this.formControlAsset.value;
    if (asset) {
      const invoiceAmount = this.formControlInvoiceAmount.value ?? 0;
      const assetCategoryIndex = asset.category.index;
      const isPropetyOwner = this.formControlPropertyOwner.value;

      if (invoiceAmount != null && assetCategoryIndex != null && isPropetyOwner != null) {
        const minDeposit = minDepositForAssetFinance(invoiceAmount, assetCategoryIndex, isPropetyOwner);
        this.depositMin = minDeposit;
        this.formControlDepositAmount.setValue(minDeposit);
      }
    }
  }

  private updateLoanAmount() {
    const invoiceAmount = this.formControlInvoiceAmount.value;
    const deposit = this.formControlDepositAmount.value;
    if (invoiceAmount != null && deposit != null) {
      let loanAmount = invoiceAmount - deposit;
      loanAmount = loanAmount < 0 ? 0 : loanAmount;
      this.formControlLoanAmount.setValue(loanAmount);
    }
  }

  initCalculator() {
    this.BrokerSearchFn = (term) => {
      return of([
        { abn: 'abn1', entityName: 'Broker1', salesforceId: 'sf1' },
        { abn: 'abn2', entityName: 'Broker2', salesforceId: 'sf2' },
        { abn: 'abn3', entityName: 'Broker3', salesforceId: 'sf3' },
      ]);
    }
    this.formControlAssetCondition = this.formBuilder.control(null, [Validators.required]);
    this.formControlInvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlDepositAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlLoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlAsset = this.formBuilder.control(null, [Validators.required]);
    this.formControlLoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlPaymentFrequency = this.formBuilder.control(null, [Validators.required]);
    this.formControlFinanceType = this.formBuilder.control(null, [Validators.required]);
    this.formControlDocFeeFinanced = this.formBuilder.control(true, [Validators.required]);
    this.formControlBalloonPayment = this.formBuilder.control(BalloonPaymentValueOptions[0],  [Validators.required]);
    this.formControlBrokerOriginationFee = this.formBuilder.control(0, [Validators.required]);
    this.formControlBrokerage = this.formBuilder.control(null, [Validators.required]);
    this.formControlPropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlAdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlEquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlAbnGstAgeAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlPreviousLoan = this.formBuilder.control(false, [Validators.required]);
    this.formControlTransactionType = this.formBuilder.control(null, [Validators.required]);
    this.AssetSearchFn = <T extends RedbookSearch>(s: T) => {
      return this.redbookService.search(s).pipe(
        // this.toastService.toastObserver(),
        map(r => {
          return r.payload;
        })
      );
    }
    this.formGroupCalculator = this.formBuilder.group({
      assetCondition: this.formControlAssetCondition,
      invoiceAmount: this.formControlInvoiceAmount,
      depositAmount: this.formControlDepositAmount,
      loanAmount: this.formControlLoanAmount,
      asset: this.formControlAsset,
      loanTerms: this.formControlLoanTerms,
      paymentFrequency: this.formControlPaymentFrequency,
      financeType: this.formControlFinanceType,
      docFeeFinanced: this.formControlDocFeeFinanced,
      balloonPayment: this.formControlBalloonPayment,
      brokerOriginationFee: this.formControlBrokerOriginationFee,
      brokerage: this.formControlBrokerage,
      propertyOwner: this.formControlPropertyOwner,
      adverseOnFile: this.formControlAdverseOnFile,
      equifaxScoreAboveThreshold: this.formControlEquifaxScoreAboveThreshold,
      abnGstAgeAboveThreshold: this.formControlAbnGstAgeAboveThreshold,
      previousLoan: this.formControlPreviousLoan,
      transactionType: this.formControlTransactionType,
    });
  }

  onCalculateEstimation(event: Event) {
    markTriggerSubject(this.formGroupCalculator).next(true);
    doMarkAll(this.formGroupCalculator);
    if (this.formGroupCalculator.invalid) {
      this.applicationDialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields across tabs are filled in.',
      });
    }else{
        this.subscriptions.push(this.applicationDialogService.openAssetQuotationDialog({
          loadFn: (date) => {
            return this.calculateEstimation(date);
          },
          isQuote: true,
          paymentChartHideBalloonPayment: false,
          paymentChartHideBrokerOriginationFee: false,
          paymentChartHideBrokerage: false,
          showPaymentChart: true,
          showArmotizationChart: true,
          showRepaymentEstimation: true,
          showArmotisationSchedule: true,
        }).afterClosed()
          .pipe(
            tap(r => {
              if (r && r.acceptedQuotation) {
                const quoteData = this.saveQuoteData();
                this.appService.saveQuote(quoteData).subscribe(async res => {
                  if (res.status) {
                    // const quoteId = res.payload.quoteId;
                    // this.toastService.quickInfoToast(`Quote saved (Id: ${quoteId})`);
                    await this.router.navigate(navigationUrlForApplications())
                  } else {
                    this.applicationDialogService.openAlertDialog({
                      message: `Error`,
                      // subMessage: " Couldn\'t save quote. Try again later."
                      subMessage: "Something is wrong. Try again."
                    });
                  }
                })
              }
            })
          ).subscribe());
      }
    }

  private saveQuoteData(): Quote {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const loanCalValues = this.formGroupCalculator.getRawValue();
    const brokerageValue: BrokerageSelectionValue = this.formControlBrokerage.value;
    const loanAmount = this.formControlLoanAmount.value!;
    const quoteValue: Quote = {
      LoanAmount: (loanCalValues.invoiceAmount ?? '0').toString(),
      RepaymentFrequency: loanCalValues.paymentFrequency?.type!,
      LoanTerm: parseInt(loanCalValues.loanTerms?.type ?? '0'),
      Residual: parseInt(loanCalValues.balloonPayment?.type ?? '0'),
      BrokeragePercentage: parseFloat(loanCalValues.brokerage?.type ?? '0'),
      BrokerageAmount: new AppCalculator().calculateBrokerageAmount(this.formControlLoanAmount.value ?? 0,Number(brokerageValue?.type ?? '0')),
      QuoteAsJson: encodeURI(JSON.stringify(loanCalValues)),
    };
    return quoteValue
  }

  private calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    // NOTE: formGroupCalculator will be valid when this is called, formControl values will not be null
    const paymentFrequencyValue: PaymentFrequencyValue =  this.formControlPaymentFrequency.value;
    const assetConditionValue: AssetConditionValue = this.formControlAssetCondition.value;
    const assetSelectionValue: AssetSelectionComponentValue = this.formControlAsset.value;
    const loanTermValue: LoanTermValue = this.formControlLoanTerms.value;
    const financeTypeValue: FinanceTypeValue = this.formControlFinanceType.value;
    const brokerageValue: BrokerageSelectionValue = this.formControlBrokerage.value;
    const balloonPaymentValue: BalloonPaymentValue = this.formControlBalloonPayment.value;
    const businessSearchValue: BusinessSearchValue = this.createBusinessSearchValue();
    const bureauReport = businessSearchValue?.result;
    const rst = calculateAssetFinanceEstimation(date, {
      type: 'AssetFinance',
      paymentFrequencyType: paymentFrequencyValue?.type ?? null,
      assetConditionType: assetConditionValue?.type ?? null,
      assetSelectionValue: assetSelectionValue!,
      assetYear: parseFloat(assetSelectionValue?.year ?? '0'),
      loanTerm: numeral(loanTermValue?.type).value() ?? 0 ,
      financeType: financeTypeValue?.type ?? null,
      loanAmount: numeral(this.formControlLoanAmount.value).value() ?? 0,
      businessSearchValue: businessSearchValue,
      existingApplicationBureauReport: bureauReport!,
      propertyOwnership: this.formControlPropertyOwner.value!,
      docFeeFinanced: this.formControlDocFeeFinanced.value!,
      brokerOriginationFee: this.formControlBrokerOriginationFee.value!,
      brokerage: Number(brokerageValue?.type ?? 0),
      adverseOnFile: this.formControlAdverseOnFile.value!,
      equifaxScoreAbove600: this.formControlEquifaxScoreAboveThreshold.value!,
      privateSaleOrLeaseback: this.formControlTransactionType.value?.type !== 'Dealer Sale',
      balloonPayment: numeral(balloonPaymentValue?.type).value() ?? 0 ,
      rateCard: this.rateCard,
      invoiceAmount: this.formControlInvoiceAmount.value!,
      deposit: this.formControlDepositAmount.value!,
      transactionType: this.formControlTransactionType?.value?.type,
    })

    return rst.totalPaymentBreakupDialogData;
  }

  createBusinessSearchValue(): BusinessSearchResultValue {
    if (this.formControlAbnGstAgeAboveThreshold.value) {
      return {
        type: 'search-result',
        result: {
          ABNAgeMonths: 999,
          GSTAgeMonths: 999
        } as SearchCompanyByABNResult
      } as BusinessSearchResultValue;
    } else {
      return {
        type: 'search-result',
          result: {
            ABNAgeMonths: 1,
            GSTAgeMonths: 1
        } as SearchCompanyByABNResult
      } as BusinessSearchResultValue;
    }
  }
}
