import {Component, OnInit} from '@angular/core';
import {ExchangeDocusignTokenPayload} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {DocusignService} from '../../service/docusign.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './docusign-oauth-callback.page.html',
    styleUrls: ['./docusign-oauth-callback.page.scss'],
    standalone: true,
    imports: []
})
export class DocusignOauthCallbackPage implements OnInit {

  code?: string;
  state?: string;
  ready = false;
  subscriptions: Subscription[] = [];

  exchangeDocusignTokenPayload?: ExchangeDocusignTokenPayload;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private docusignService: DocusignService
  ) {}

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParams.code;

    if (this.code) {
      this.subscriptions.push(
        this.docusignService.updateDocusignToken(this.code)
          .pipe(
            tap((r: ExchangeDocusignTokenPayload) => {
             console.log(r);
             if (r) {
               this.ready = true;
               this.exchangeDocusignTokenPayload = r;
             }
            })
          ).subscribe()
      )
    }
  }

}
