import {PismoPhysicalCardType} from "@portal-workspace/grow-shared-library";


export type OverdraftCustomerPhysicalCardSelectValue =  {
  type: PismoPhysicalCardType,
  name: string,
} | null

export const OverdraftCustomerPhysicalCardSelectionValueOptions: Exclude<OverdraftCustomerPhysicalCardSelectValue, null>[] = [
  {type: 'plastic', name: 'Plastic'},
  {type: 'metal', name: 'Metal'},
];
