<div class="pismo-notes-component">
  <table mat-table [dataSource]="dataSource" matSort [class]="total? 'width-100 mb-2': 'width-100'">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        Date
      </th>
      <td mat-cell *matCellDef="let element">
        <div fxLayout="column">
          <div class="title">{{element.DateGenerated | date:'dd/MM/yyyy'}}</div>
          <div class="description">
            {{element.DateGenerated | date:'h:mm a'}}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>
        User
      </th>
      <td mat-cell *matCellDef="let element" matTooltip="UserId: {{element.UserId}}">
        {{element.Name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef>
        Note
      </th>
      <td mat-cell *matCellDef="let element">
          <collapsable-expandable-text [value]="element.Note"></collapsable-expandable-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        @if (element.UserId === user.UserId) {
          <span class="mdi mdi-trash-can-outline cursor-pointer" matTooltip="Delete" (click)="onDelete(element)"></span>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  @if (total) {
    <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
    [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
  }

  @if (total<1) {
    <table>
      <tr>
        <td class="not-found"> No notes found</td>
      </tr>
    </table>
    }
</div>
