import { Component, Input, OnInit, forwardRef, OnChanges, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl
} from '@angular/forms';
import { BusinessSearchFn, createAccountNumberInputMask, createBsbInputMask, formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { BusinessSearchValue, LocalBankAccountDetailValue, GetInstitutionNameFn, Nullable, parseJSON, PrivateSeller, PrivateSellerFormValue, YesNoValue, getStates } from '@portal-workspace/grow-shared-library';
import { Mark, MARK } from '@portal-workspace/grow-ui-library/mark';
import { markTriggerSubject, doMarkAll } from '@portal-workspace/grow-ui-library';
import { Subscription, tap } from 'rxjs';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CellComponent } from '../application-summary-component/cell.component';
import { BusinessSearchComponent } from '../business-search-component/business-search.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { NameComponent } from '../name-component/name.component';

import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'private-seller',
    templateUrl: './private-seller.component.html',
    styleUrls: ['./private-seller.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PrivateSellerComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PrivateSellerComponent) },
    ],
    standalone: true,
    imports: [YesNoComponent, FormsModule, MatOptionModule, MatSelectModule, MobileComponent, EmailComponent, ReactiveFormsModule, NameComponent, MarkDirective, MatFormFieldModule, MatInputModule, CustomAddressComponent, BusinessSearchComponent, CellComponent, LocalBankAccountDetailComponent]
})

export class PrivateSellerComponent extends AbstractControlValueAccessor<PrivateSellerFormValue> implements OnInit, Mark {

  @Input({required: true}) privateSupplier!: PrivateSeller;
  @Input({required: false}) title = "";
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;

  displayedColumns: string[] = ['date', 'user', 'note'];
  states = getStates();
  markTriggerSubject = markTriggerSubject;
  doMarkAll = doMarkAll;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  stateCompareWith: (a: string, b: string) => boolean;
  formGroupPrivateSeller: UntypedFormGroup;
  formControlFirstName: UntypedFormControl;
  formControlMiddleName: UntypedFormControl;
  formControlLastName: UntypedFormControl;
  formControlAddress: UntypedFormControl;
  formControlEmail: UntypedFormControl;
  formControlMobile: UntypedFormControl;
  formControlYesNo: UntypedFormControl;
  formControlState: UntypedFormControl;
  formControlBusiness: FormControl<BusinessSearchValue>;
  subscriptions: Subscription[] = [];
  businessSearchValue!: BusinessSearchValue

  // formControlFinancialInstitution: UntypedFormControl;
  // formControlAccountName: UntypedFormControl;
  // formControlBsb: UntypedFormControl;
  // formControlAccountNumber: UntypedFormControl
  formControlBankDetails: UntypedFormControl;
  constructor(
    private formBuilder: UntypedFormBuilder,

  ) {
    super();
    this.stateCompareWith = (a: string, b: string) => {
      return ((a ?? '').toLowerCase().trim() === (b ?? '').toLowerCase().trim());
    }
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(null);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlAddress = formBuilder.control(null, [Validators.required]);
    this.formControlYesNo = formBuilder.control(false);
    this.formControlState = formBuilder.control(null, [Validators.required]);
    this.formControlBusiness = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required]);
    this.formControlMobile = formBuilder.control(null, [Validators.required]);

    // this.formControlFinancialInstitution = formBuilder.control(null, [Validators.required]);
    // this.formControlAccountName = formBuilder.control(null, [Validators.required, Validators.maxLength(50)]);
    // this.formControlBsb = formBuilder.control(null, [Validators.required]);
    // this.formControlAccountNumber = formBuilder.control(null, [Validators.required, Validators.maxLength(10)]);

    this.formControlBankDetails = formBuilder.control(null, [Validators.required]);

    this.formGroupPrivateSeller = formBuilder.group({
      name: this.formControlFirstName,
      middleName: this.formControlMiddleName,
      lastName: this.formControlLastName,
      address: this.formControlAddress,
      email: this.formControlEmail,
      mobile: this.formControlMobile,
      yesNo: this.formControlYesNo,
      business: this.formControlBusiness,
      state: this.formControlState,
      // financialInstitution: this.formControlFinancialInstitution,
      // accountName: this.formControlAccountName,
      // bsb: this.formControlBsb,
      // accountNumber: this.formControlAccountNumber
      bankDetails: this.formControlBankDetails
    })
  }

  ngOnInit(): void {
    if (this.privateSupplier) {
      const supplier: Partial<PrivateSeller> = parseJSON(this.privateSupplier)
      console.log('private supplier supplier', supplier)
      this.formControlEmail.setValue(supplier.email);
      this.formControlMobile.setValue(supplier.mobile);
      this.formControlFirstName.setValue(supplier.name);
      this.formControlMiddleName.setValue(supplier.middleName);
      this.formControlLastName.setValue(supplier.lastName);
      if (supplier.business) {
        this.formControlYesNo.setValue(true);
        this.formControlBusiness.setValue(supplier.business)
        this.formControlState.setValue(supplier.state);
        this.businessSearchValue = supplier.business
      } else {
        this.formControlYesNo.setValue(false);
        this.formControlAddress.setValue(supplier.address);
      }
      const bankDetails: LocalBankAccountDetailValue = {
        InstitutionName: supplier.bankDetails?.InstitutionName ?? null,
        AccountName: supplier.bankDetails?.AccountName ?? null,
        BSB: supplier.bankDetails?.BSB ?? null,
        AccountNumber: supplier.bankDetails?.AccountNumber ?? null,
      }
      this.formControlBankDetails.setValue(bankDetails)
    }
    this.updateFormGroupValidity()

    const sub1 = this.formControlYesNo.valueChanges.pipe(
      tap((haveAbn) => {
        this.updateFormGroupValidity(haveAbn)
      })
    ).subscribe()
    this.subscriptions.push(sub1);

    this.subscriptions.push(this.formControlBusiness.valueChanges.pipe(
      tap(r => {
        this.businessSearchValue = r
      })
    ).subscribe())
  }

  updateFormGroupValidity(haveAbn?: YesNoValue) {
    this.formControlAddress.clearValidators()
    this.formControlBusiness.clearValidators()
    this.formControlState.clearValidators()

    if (haveAbn || this.formControlYesNo.value) {
      this.formControlBusiness.setValidators(Validators.required)
      this.formControlState.setValidators(Validators.required)
    }
    else {
      this.formControlAddress.setValidators(Validators.required)
    }

    this.formControlAddress.updateValueAndValidity()
    this.formControlBusiness.updateValueAndValidity()
    this.formControlState.updateValueAndValidity()
  }

  doWriteValue(v?: PrivateSellerFormValue | undefined): void | PrivateSellerFormValue {
    console.log('do write value', v)
    if (v && v.bankDetails) {
      const bankDetails: LocalBankAccountDetailValue = {
        InstitutionName: v.bankDetails.InstitutionName ?? null,
        AccountName: v.bankDetails.AccountName ?? null,
        BSB: v.bankDetails.BSB ?? null,
        AccountNumber: v.bankDetails.AccountNumber ?? null,
      }
      this.formControlBankDetails.setValue(bankDetails)
      this.formControlFirstName.setValue(v.name ?? '');
      this.formControlMiddleName.setValue(v.middleName ?? '');
      this.formControlLastName.setValue(v.lastName ?? '');
      this.formControlAddress.setValue(v.address ?? '');
      this.formControlBusiness.setValue(v.business ?? null);
      this.formControlYesNo.setValue(v.yesNo ?? '');
      this.formControlMobile.setValue(v.mobile ?? '');
      this.formControlEmail.setValue(v.email ?? '');
      this.formControlState.setValue(v.state ?? '');
    }
    return undefined;
  }

  mark() {
    markTriggerSubject(this.formGroupPrivateSeller).next(true);
    doMarkAll(this.formGroupPrivateSeller);
  }

}
