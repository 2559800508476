import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { ApplicationErrorDialogData, ApplicationErrorDialogResult } from '@portal-workspace/grow-shared-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './application-error.dialog.html',
    styleUrls: ['./application-error.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatFormFieldModule, MatDialogModule]
})
export class ApplicationErrorDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationErrorDialogData,
              private dialogRef: MatDialogRef<ApplicationErrorDialog, ApplicationErrorDialogResult>) {
  }


  onSubmit($event: MouseEvent) {
    const result: ApplicationErrorDialogResult = {}
    this.dialogRef.close(result);
  }

  onSupport($event: MouseEvent) {
    $event.stopImmediatePropagation();
    window.open(this.data.helpCenterUrl);
    return false;
  }
}
