<div class="pismo-statement-details-page m-4">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <h3 mat-dialog-title>Statement Period {{statementPeriod}}</h3>
  </div>
  @if (stage === 'done') {
    <overdraft-account-statement-details
      [pismoAccountNumber]="pismoAccountNumber"
      [statementId]="statementId"
      [getPismoStatementDetailsFn]="getPismoStatementDetailsFn"
      [downloadStatementInCSV]="downloadStatementInCSV"
      [downloadStatementInOFX]="downloadStatementInOFX"
      [downloadStatementInPDF]="downloadStatementInPDF"
      (statementPeriodChange)="onStatementPeriodChange($event)"
    ></overdraft-account-statement-details>
  }
  @if (stage === 'error') {
    <message-box type="warn">
      Unable to load transactions, missing required parameters
    </message-box>
  }
</div>