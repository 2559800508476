import {Component, Inject, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {CurrencyInputValue, MarkAsSoldDialogData, MarkAsSoldDialogResult} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';

@UntilDestroy({arrayName: 'subscription'})
@Component({
    templateUrl: './mark-as-sold.dialog.html',
    styleUrls: ['./mark-as-sold.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, CurrencyInputComponent, MatButtonModule, FlexModule, MatDialogModule]
})
export class MarkAsSoldDialog  implements OnInit {

  title!: string;
  description!: string;
  vin!: string;
  formGroup!: FormGroup<{
    assetPrice : FormControl<number | null>
  }>;
  assetPriceControl!: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MarkAsSoldDialogData,
  private matDialogRef: MatDialogRef<MarkAsSoldDialog, MarkAsSoldDialogResult>,
  private fb: FormBuilder) {
    this.assetPriceControl = fb.control(null,Validators.required);
    this.formGroup = fb.group({
      assetPrice: this.assetPriceControl
    })
  }

  ngOnInit(): void {
    console.log("data::::",this.data);

    this.title=this.data.vehicleTitle;
    this.description=this.data.vehicleDescription;
    this.vin=this.data.vehicleVIN;
  }

  onCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {
    const result = this.formGroup.value as MarkAsSoldDialogResult;
    this.matDialogRef.close(result);
  }

}
