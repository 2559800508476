import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService,} from './auth.service';
import {CustomerUser} from '@portal-workspace/grow-shared-library';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable()
export class CustomerUserResolver  {

  constructor(private authService: AuthService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerUser | null> | Promise<CustomerUser | null> | CustomerUser | null {
    const customerId = route.params.customerId;
    if (customerId) {
      return this.authService.getCustomer(customerId)
        .pipe(
          map(r => r.payload)
        );
    }
    return null;
  }

}
