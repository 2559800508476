import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {AngularMaterialLibraryModule} from './angular-material/angular-material-library.module';
import {ComponentLibraryModule} from './components/component-library.module';
import {DirectiveLibraryModule} from './directives/directive-library.module';
import {PipeLibraryModule} from './pipes/pipe-library.module';
import {NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyDirective} from 'ngx-currency';
import {BreakpointService} from "./services/breakpoint.service";

// NOTE: not used
// const materialAnchorErrorComponent2 = (hostElement: Element, errorElement: Element) => {
//   const p = hostElement.parentElement!.parentElement!.parentElement!;
//   if (p.className.split(' ').includes('mat-form-field-wrapper')) {
//     const w = p.querySelector('.mat-form-field-subscript-wrapper')!;
//     w.insertAdjacentElement('afterend', errorElement);
//     return ()=>{};
//   }
//   return () => {
//     const errorNode = p.nextElementSibling ? (p.nextElementSibling).querySelector('mat-error') : null;
//     if (errorNode) {
//       p.nextElementSibling!.remove();
//     }
//   };
// }

const modules = [
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  ContentLoaderModule,
  NgxCurrencyDirective,

  AngularMaterialLibraryModule,
  ComponentLibraryModule,
  DirectiveLibraryModule,
  PipeLibraryModule,
]

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    InputMaskModule.forRoot({inputSelector: 'input', isAsync: true}),
    ...modules,
  ],
  exports: [
    ...modules,
    NgxMaskModule,
    InputMaskModule,
  ],
  providers: [
    { provide: BreakpointService, useClass: BreakpointService },
  ]
})
export class GrowUiLibraryModule { }

