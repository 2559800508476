@if (!((store.value$ | async)?.error)) {
  <div class="processed-pending-transactions-table-component">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Processed Transactions</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search transaction..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" multiTemplateDataRows matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.id}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Banking</th>
              <td mat-cell *matCellDef="let element">{{getBankingType(element)}}</td>
            </ng-container>
            <ng-container matColumnDef="processingCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processingCode">Type</th>
              <td mat-cell *matCellDef="let element">{{getProcessingType(element.processingCode)}}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">Amount</th>
              <td mat-cell *matCellDef="let element">{{element.amount | looseCurrency}}</td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
              <td mat-cell *matCellDef="let element">{{moment(element.date, "DDMMYYYY").format('DD/MM/YYYY')}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <tag-box [state]="element.status"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="PismoAccountId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoAccountId">Pismo Acc</th>
              <td mat-cell *matCellDef="let element">{{element.PismoAccountId}}</td>
            </ng-container>
            <ng-container matColumnDef="PismoCustomerId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoCustomerId">Pismo Cus</th>
              <td mat-cell *matCellDef="let element">{{element.PismoCustomerId}}</td>
            </ng-container>
            <ng-container matColumnDef="lastUpdatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUpdatedBy">Updated By</th>
              <td mat-cell *matCellDef="let element">{{element.GivenName ?? ''}} {{element.FamilyName ?? ''}}</td>
            </ng-container>
            <ng-container matColumnDef="lastUpdatedTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUpdatedTime">Last Updated</th>
              <td mat-cell *matCellDef="let element">{{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}</td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element;" class="expanded-row" [attr.colspan]="displayColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                
                  @if (element.type==='bpay') {
                    <div class="row mt-2">
                      <div class="col-4">Biller Code</div>
                      <div class="col-8">{{element.billerCode}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">Biller Name</div>
                      <div class="col-8">{{element.accountTitle}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">CRN</div>
                      <div class="col-8">{{element.crn}}</div>
                    </div>
                  }
                  @if (element.type!=='bpay') {
                    <div class="row mt-2">
                      <div class="col-4">Account Title</div>
                      <div class="col-8">{{element.accountTitle}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">BSB</div>
                      <div class="col-8">{{element.bsb}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">Account Number</div>
                      <div class="col-8">{{element.accountNumber}}</div>
                    </div>
                  }
                  <div class="row mt-2">
                    <div class="col-4">Reference</div>
                    <div class="col-8">{{element.reference}}</div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayColumns"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total"
              [pageIndex]="offset"
              [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]"
              (page)="onPagination($event)"
            ></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
