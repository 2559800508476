import {Component, Input} from '@angular/core';
import {PartnerValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { LiabilitiesSummaryComponent } from './liabilities-summary.component';
import { AssetsSummaryComponent } from './assets-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'partners-summary',
    template: `

@if (partners) {
  @for (partner of partners; track partner; let index = $index) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Partner {{index + 1}}</div>
      </div>
    </div>
    @if (partner.type === 'Entity') {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Type</div>
            <div body>{{partner.type}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Organisation</div>
            <div body>{{partner.organisation.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Organisation type</div>
            <div body>{{partner.organisationType.name}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ABN</div>
            <div body>{{partner.abn}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ACN</div>
            <div body>{{partner.acn}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }
    @if (partner.type === 'Individual') {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{partner.title?.name}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Type</div>
            <div body>{{partner.type}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body><p [innerHTML]="partner.firstName | ellipsis:10"></p></div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Last name</div>
            <div body><p [innerHTML]="partner.lastName | ellipsis:10"></p></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Gender</div>
            <div body>{{partner.gender}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Date of birth</div>
            <div body>{{displayableDate(partner.dob)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Residential address</div>
            <div body>{{partner.residentialAddress?.address}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Privacy consent</div>
            <div body>{{booleanToYesNo(partner.privacyConsentObtained)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @if (partner.propertyOwner) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property Owner</div>
              <div body>{{booleanToYesNo(partner.propertyOwner.propertyOwner)}}</div>
            </cell>
          </div>
        </div>
      }
      @if (partner.propertyOwner && partner.propertyOwner.propertyOwner) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Property address</div>
              <div body>{{partner.propertyOwner.address?.address}}</div>
            </cell>
          </div>
        </div>
      }
      @if (partner.propertyOwner && partner.propertyOwner.propertyOwner) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Property value</div>
              <div body>{{partner.propertyOwner.propertyValue}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Mortgage value</div>
              <div body>{{partner.propertyOwner.mortgageValue}}</div>
            </cell>
          </div>
        </div>
      }
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Guarantor</div>
            <div body>{{booleanToYesNo(partner.guarantor)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Email address</div>
            <div body>{{partner.email}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Business landline</div>
            <div body>{{partner.mobileNumber}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <assets [assets]="partner.personalAssets"></assets>
      <liabilities-summary [liabilities]="partner.personalLiabilities"></liabilities-summary>
    }
  }
}
`,
    standalone: true,
    imports: [CellComponent, MatDividerModule, AssetsSummaryComponent, LiabilitiesSummaryComponent, EllipsisPipe]
})
export class PartnersSummaryComponent {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) partners?: PartnerValue;

}
