import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ProfileComponent} from './profile.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {ConfirmPasswordModule} from '../../components/confirm-password-component/confirm-password.module';
import {MessageBoxModule} from '../../components/message-box/message-box.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {EmailModule} from '../common fields/email.module';
import {MobileModule} from '../mobile-component/mobile.module';
import { NameModule } from '../name-component/name.module';
import { SlideToggleModule } from '../slide-toggle-component/slide-toggle.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        ConfirmPasswordModule,
        MessageBoxModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        MobileModule,
        NameModule,
        SlideToggleModule,
        ProfileComponent
    ],
    exports: [
        ProfileComponent
    ]
})
export class ProfileModule {

}
