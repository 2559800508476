<div class="pismo-edit-customer-dialog m-4">
  <h3 mat-dialog-title>Edit Pismo Customer</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <name title="First Name" [formControl]="formControlFirstName"></name>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <name title="Middle Name" [formControl]="formControlMiddleName" [mandatory]="false"></name>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <name title="Last Name" [formControl]="formControlLastName"></name>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <datepicker
          [formControl]="formControldob"
          [allowOnly18YrsPlus]="true"
          title="Date of birth">
        </datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
           <mobile
           [formControl]="formControlmobileNumber"
           title = "Mobile number"
           [onlyMobile]="true"
           [mandatory]="true"
           ></mobile>
      </div>
    </div>
    
    <div class="row mb-5">
      <div class="col-12">
        <customer-access-level-and-role-access [formControl]="formControlAccessLevel" ></customer-access-level-and-role-access>
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100 pl-lt-md-3 pr-lt-md-3"
         fxLayoutGap="10px"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between"
         fxLayoutAlign.lt-md="center start">
      <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button class="ml-0 w-lt-md-100" color="primary" (click)="onSave()" [disabled]="formGroup.invalid">Save</button>
    </div>
  </mat-dialog-actions>
</div>
