import {Observable} from "rxjs";


export interface MaterialShades {
  '50': string,
  '100': string,
  '200': string,
  '300': string,
  '400': string,
  '500': string,
  '600': string,
  '700': string,
  '800': string,
  '900': string,
  'A100': string,
  'A200': string,
  'A400': string,
  'A700': string,
}


export interface WhitelabelStyles {
  'grow-primary-palette-50': string;
  'grow-primary-palette-100': string;
  'grow-primary-palette-200': string;
  'grow-primary-palette-300': string;
  'grow-primary-palette-400': string;
  'grow-primary-palette-500': string;
  'grow-primary-palette-600': string;
  'grow-primary-palette-700': string;
  'grow-primary-palette-800': string;
  'grow-primary-palette-900': string;
  'grow-primary-palette-A100': string;
  'grow-primary-palette-A200': string;
  'grow-primary-palette-A400': string;
  'grow-primary-palette-A700': string;
  'grow-primary-palette-contrast-50': string;
  'grow-primary-palette-contrast-100': string;
  'grow-primary-palette-contrast-200': string;
  'grow-primary-palette-contrast-300': string;
  'grow-primary-palette-contrast-400': string;
  'grow-primary-palette-contrast-500': string;
  'grow-primary-palette-contrast-600': string;
  'grow-primary-palette-contrast-700': string;
  'grow-primary-palette-contrast-800': string;
  'grow-primary-palette-contrast-900': string;
  'grow-primary-palette-contrast-A100': string;
  'grow-primary-palette-contrast-A200': string;
  'grow-primary-palette-contrast-A400': string;
  'grow-primary-palette-contrast-A700': string;

  'grow-accent-palette-50': string;
  'grow-accent-palette-100': string;
  'grow-accent-palette-200': string;
  'grow-accent-palette-300': string;
  'grow-accent-palette-400': string;
  'grow-accent-palette-500': string;
  'grow-accent-palette-600': string;
  'grow-accent-palette-700': string;
  'grow-accent-palette-800': string;
  'grow-accent-palette-900': string;
  'grow-accent-palette-A100': string;
  'grow-accent-palette-A200': string;
  'grow-accent-palette-A400': string;
  'grow-accent-palette-A700': string;
  'grow-accent-palette-contrast-50': string;
  'grow-accent-palette-contrast-100': string;
  'grow-accent-palette-contrast-200': string;
  'grow-accent-palette-contrast-300': string;
  'grow-accent-palette-contrast-400': string;
  'grow-accent-palette-contrast-500': string;
  'grow-accent-palette-contrast-600': string;
  'grow-accent-palette-contrast-700': string;
  'grow-accent-palette-contrast-800': string;
  'grow-accent-palette-contrast-900': string;
  'grow-accent-palette-contrast-A100': string;
  'grow-accent-palette-contrast-A200': string;
  'grow-accent-palette-contrast-A400': string;
  'grow-accent-palette-contrast-A700': string;

  'grow-warn-palette-50': string;
  'grow-warn-palette-100': string;
  'grow-warn-palette-200': string;
  'grow-warn-palette-300': string;
  'grow-warn-palette-400': string;
  'grow-warn-palette-500': string;
  'grow-warn-palette-600': string;
  'grow-warn-palette-700': string;
  'grow-warn-palette-800': string;
  'grow-warn-palette-900': string;
  'grow-warn-palette-A100': string;
  'grow-warn-palette-A200': string;
  'grow-warn-palette-A400': string;
  'grow-warn-palette-A700': string;
  'grow-warn-palette-contrast-50': string;
  'grow-warn-palette-contrast-100': string;
  'grow-warn-palette-contrast-200': string;
  'grow-warn-palette-contrast-300': string;
  'grow-warn-palette-contrast-400': string;
  'grow-warn-palette-contrast-500': string;
  'grow-warn-palette-contrast-600': string;
  'grow-warn-palette-contrast-700': string;
  'grow-warn-palette-contrast-800': string;
  'grow-warn-palette-contrast-900': string;
  'grow-warn-palette-contrast-A100': string;
  'grow-warn-palette-contrast-A200': string;
  'grow-warn-palette-contrast-A400': string;
  'grow-warn-palette-contrast-A700': string;
}

export type WhitelabelServiceEvent = WhitelabelServiceChangeEvent | WhitelabelServiceApplyChangeEvent | null;

export type CheckIfWhitelableTitleExistsFn = (title: string, excludingThisWhitelabelId?: number) =>Observable<boolean>;

/*
 *  Event type when whitelabel changes, changes are permanent and should be saved in local storage
 */
export interface WhitelabelServiceChangeEvent {
  type: 'whitelabel-change',
  payload: {
    logoLink: string | null,
    styles: WhitelabelStyles | null,
  },
};

/**
 * Event type request to apply whitelabel changes (temporary) eg. when applying a whitelable in admin page
 */
export interface WhitelabelServiceApplyChangeEvent {
  type: 'apply-whitelabel',
  payload: {
    logoLink: string,
    styles: WhitelabelStyles,
  },
};

export interface WhitelabelEntity {
  id: number,
  title: string,
  // originatorBusinessId: number,
  // originatorBusinessEntityName: string,
}

export interface WhitelabelEntityDetails extends WhitelabelEntity{
 styles: WhitelabelStyles,
 logoLink: string,
}

export type SaveWhitelabel = {
  title: string,
  styles: WhitelabelStyles,
  logoLink: string,
}

export type SaveWhiteLabelResult = {
  state: 'added' | 'updated',
  whitelabelId: number,
}

