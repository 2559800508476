import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, LoanPurposeValue, LoanPurposeValueOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'loan-purpose-selection',
    templateUrl: './loan-purpose-selection.component.html',
    styleUrls: ['./loan-purpose-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LoanPurposeSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => LoanPurposeSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class LoanPurposeSelectionComponent extends AbstractControlValueAccessor<LoanPurposeValue> implements OnInit, Mark {

  formGroup: FormGroup<{
    selection:FormControl<LoanPurposeValue>;
  }>;
  formControl: FormControl<LoanPurposeValue>;
  subscription: Subscription;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  options = LoanPurposeValueOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: LoanPurposeValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: LoanPurposeValue | null): void | LoanPurposeValue {
    if (v) {
      const t = LoanPurposeValueOptions.find(opt => opt.type === v.type);
      this.formControl.setValue(t ? t : null);
      return t;
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

}
