import {Component, forwardRef, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { FormBuilder, NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { IncomeSelectionValue, IncomePeriodOptions, IncomeSelectionPeriod, compareMatch } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { DisableControlDirective } from '../../directives/disable-control.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'income',
    templateUrl: './income.component.html',
    styleUrls: ['./income.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IncomeComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => IncomeComponent) },
    ],
    standalone: true,
    imports: [
      CurrencyInputComponent,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatSelectModule,
      MatOptionModule,
      DisableControlDirective,
    ]
})
export class IncomeComponent extends AbstractControlValueAccessor<IncomeSelectionValue> implements  OnInit, Mark {

  formGroup: FormGroup<{
    income: FormControl<number|null>,
    period: FormControl<IncomeSelectionPeriod|null>
  }>;
  formControlIncome: FormControl<number|null>;
  formControlPeriod: FormControl<IncomeSelectionPeriod|null>;

  incomePeriodOptions = IncomePeriodOptions;

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlIncome = formBuilder.control(null, [Validators.required]);
    this.formControlPeriod = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      income: this.formControlIncome,
      period: this.formControlPeriod,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const subscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
      if (this.formGroup.valid) {
        // when formGroup is valid, all these should not be falsy
        const v: IncomeSelectionValue = {
          income: this.formControlIncome.value ?? 0,
          period: this.formControlPeriod.value ?? this.incomePeriodOptions[0],
        };
        this.propagateChange(v);
      } else {
        this.propagateChange(null);
      }
    })).subscribe();
    this.subscriptions.push(subscription);
  }

  doWriteValue(v?: IncomeSelectionValue | null): void | IncomeSelectionValue {
    if (v) {
      this.formControlIncome.setValue((v.income));
      const option = this.incomePeriodOptions.find((o)=> o.type === v.period.type);
      if (option) {
        this.formControlPeriod.setValue(option);
      }
    }
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }
}

