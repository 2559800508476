import {Component, forwardRef, OnInit, Input, OnDestroy} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {createCurrencyInputMask} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Observable, Subject, Subscription} from 'rxjs';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Address2ComponentValue, PropertyOwnerWithAddressValue, compareMatch} from '@portal-workspace/grow-shared-library';
import {Mark} from '@portal-workspace/grow-ui-library';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { YesNoComponent } from '../yes-no-component/yes-no.component';


export type CopyAddressFn = () => { observable: Observable<Address2ComponentValue> , value: Address2ComponentValue };

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'property-owner-with-address',
    templateUrl: './property-owner-with-address.component.html',
    styleUrls: ['./property-owner-with-address.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PropertyOwnerWithAddressComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PropertyOwnerWithAddressComponent) },
    ],
    standalone: true,
    imports: [YesNoComponent, MarkDirective, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, CustomAddressComponent, CurrencyInputComponent]
})
export class PropertyOwnerWithAddressComponent extends AbstractControlValueAccessor<PropertyOwnerWithAddressValue> implements  OnInit, OnDestroy, Mark {

  @Input({required: true}) copyAddressFn?: CopyAddressFn;
  @Input({required: false}) showAddressForm: boolean = true
  markObservable: Subject<boolean> = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  formGroup: FormGroup<{
    address: FormControl<Address2ComponentValue>,
    propertyOwner:FormControl<boolean| null>,
    propertyValue:FormControl<number| null>,
    mortgageValue:FormControl<number|null>
  }>;
  formControlAddress: FormControl<Address2ComponentValue>;
  formControlPropertyOwner: FormControl<boolean| null>;
  formControlPropertyValue: FormControl<number| null>;
  formControlMortgageValue: FormControl<number| null>;
  formControlCopyResidentalAddress: FormControl<boolean|null>;

  addressRequired = false

  copyAddressFnSubscription: Subscription | null = null;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlPropertyOwner = this.formBuilder.control(false, [Validators.required]);
    this.formControlCopyResidentalAddress = this.formBuilder.control(false);
    this.formControlAddress = this.formBuilder.control(null);
    this.formControlPropertyValue = this.formBuilder.control(null);
    this.formControlMortgageValue = this.formBuilder.control(null);
    this.formGroup = this.formBuilder.group({
      address: this.formControlAddress,
      propertyOwner: this.formControlPropertyOwner,
      propertyValue: this.formControlPropertyValue,
      mortgageValue: this.formControlMortgageValue,
    });
    const sub = this.formControlPropertyOwner.valueChanges.pipe(
      delay(0),
      tap(r => {
        if (r) {
          this.formControlAddress.setValidators([Validators.required]);
          this.formControlPropertyValue.setValidators([Validators.required]);
          this.formControlMortgageValue.setValidators([Validators.required]);
        } else {
          this.formControlAddress.setValidators(null);
          this.formControlPropertyValue.setValidators(null);
          this.formControlMortgageValue.setValidators(null);
        }
        this.formControlAddress.updateValueAndValidity();
        this.formControlPropertyValue.updateValueAndValidity();
        this.formControlMortgageValue.updateValueAndValidity();
        this.addressRequired = !!r;
      })
    ).subscribe();
    const sub1 = this.formControlCopyResidentalAddress.valueChanges.pipe(
      // delay(0),
      tap(r => {
        this.unubscribeCopyAddressFn();
        if (r) { // checked 'copy residential address'
          if (this.copyAddressFn) {
            const copiedValueObservable = this.copyAddressFn();
            if (copiedValueObservable) {
              this.formControlAddress.setValue(copiedValueObservable.value);
              this.copyAddressFnSubscription = copiedValueObservable.observable.pipe(
                tap((address: Address2ComponentValue)  => {
                  this.formControlAddress.setValue(address);
                })
              ).subscribe();
            }
          } else {
            console.error(`***** property-owner-with-address component is missing copyAddressFn @Input({required: true})`);
          }
        }
      })).subscribe();
    const subscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          const v: PropertyOwnerWithAddressValue = {
            address: this.formGroup.value.address,
            propertyOwner: this.formGroup.value.propertyOwner??false,
            propertyValue: this.formGroup.value.propertyValue??undefined,
            mortgageValue: this.formGroup.value.mortgageValue??undefined
          }
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
    this.subscriptions.push(sub1);
  }

  doWriteValue(v: PropertyOwnerWithAddressValue | null ): void | PropertyOwnerWithAddressValue {
    if (v) {
      this.formControlPropertyOwner.setValue(v.propertyOwner);
      this.enableAddress(v.propertyOwner, v);
    }
    return undefined;
  }

  enableAddress(r: boolean, v?: PropertyOwnerWithAddressValue | null) {
    if (r) {
      this.formControlAddress.setValidators([Validators.required]);
      this.formControlAddress.setValue( v?.address ?? null);
      this.formControlPropertyValue.setValue(v?.propertyValue ?? null);
      this.formControlMortgageValue.setValue(v?.mortgageValue ?? null);
      this.formControlAddress.updateValueAndValidity();
    } else {
      this.formControlAddress.setValidators(null);
      this.formControlPropertyValue.setValidators(null);
      this.formControlMortgageValue.setValidators(null);
      this.formControlAddress.updateValueAndValidity();
      this.formControlPropertyValue.updateValueAndValidity();
      this.formControlMortgageValue.updateValueAndValidity();
    }
    this.addressRequired = r;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    setTimeout(()=> {
      // propagate initial value to formControl, cause by default property owner is false, no address required, we need to propgate this to the
      // caller formControl
      const v: PropertyOwnerWithAddressValue = {
        address: this.formGroup.value.address,
        propertyOwner: this.formGroup.value.propertyOwner??false,
        propertyValue: this.formGroup.value.propertyValue??undefined,
        mortgageValue: this.formGroup.value.mortgageValue??undefined
      }
      this.propagateChange(v);

    })
    this.formControlCopyResidentalAddress.setValue(!this.showAddressForm);
  }

  ngOnDestroy() {
    this.unubscribeCopyAddressFn();
  }

  private unubscribeCopyAddressFn() {
    this.copyAddressFnSubscription && this.copyAddressFnSubscription.unsubscribe();
    this.copyAddressFnSubscription = null;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}
