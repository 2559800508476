import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationTypeAmountComponent } from './application-type-amount.component';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { AbbreviateNumberPipe } from '../../pipes/abbreviateNumber.pipe';
@NgModule({
    imports: [
        CommonModule,
        AngularMaterialLibraryModule,
        ApplicationTypeAmountComponent,
        AbbreviateNumberPipe
    ],
    exports: [
        ApplicationTypeAmountComponent,
    ]
})
export class ApplicationTypeAmountModule {

}
