import {NgModule} from '@angular/core';
import {SideMenuComponent} from './side-menu.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        SideMenuComponent,
    ],
    exports: [
        SideMenuComponent,
    ]
})
export class SideMenuModule {

}
