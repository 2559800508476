<div class="user-customer-table">
  <div class="row mb-2">
    <div class="col-lg-6 col-md-12">
      <mat-form-field class="search">
        <input matInput [readonly]="loader.tableLoading.inProgress$ | async" type="text"
          [formControl]="formControlSearch" placeholder="Search user...">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        @if (canAccessCustomerUser) { 
        @if (type=='customer-user' && (!isSalesAM)) {
          <button class="custom-button w-lt-md-100 float-right" (click)="addUser($event)">
            <span class="mdi mdi-plus icon"></span>
            <span class="text">
              <ng-container>Add Customer User</ng-container>
            </span>
          </button>
        }
      }
      @if (canAccessBrokerUser) {  
        @if (type=='broker-user') {
          <button class="custom-button w-lt-md-100 float-right" (click)="addUser($event)">
            <span class="mdi mdi-plus icon"></span>
            <span class="text">
              <ng-container>Add Broker User</ng-container>
            </span>
          </button>
        }
      }
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort
            (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Name</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width" (click)="onRowClicked($event, element)">{{element.Name}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Email">Email</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width" (click)="onRowClicked($event, element)">{{element.Email}}</td>
            </ng-container>
            <ng-container matColumnDef="customerAccess">
              @if (type=='customer-user') {
                <div>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="CustomerAccessLevel">Access</th>
                </div>
              }
              @if (type=='broker-user') {
                <div>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="AccessLevel">Access</th>
                </div>
              }
              <td mat-cell *matCellDef="let element" class="td-width ellipsis" (click)="onRowClicked($event, element)">
                @if (type=='customer-user') {
                  {{element.customerAccessLevel | accessLevel}}
                }
                @if (type=='broker-user') {
                  {{element.AccessLevel | accessLevel}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="customerEntityName">
              @if (type=='customer-user') {
                <div>
                  <th mat-header-cell  *matHeaderCellDef mat-sort-header="EntityName">Company</th>
                </div>
              }
              @if (type=='broker-user') {
                <div>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="BusinessName">Company</th>
                </div>
              }
              <td mat-cell *matCellDef="let element" class="td-width ellipsis" (click)="onRowClicked($event, element)">
                @if (type=='customer-user') {
                  {{element.CustomerBusinessName}}
                }
                @if (type=='broker-user') {
                  {{element.OriginatorBusinessName}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width">
                @if (!element.isLock && !element.isDisabled) {
                  <span matTooltip="Active"
                  class="mdi mdi-check-circle-outline icon-margin icon-primary"></span>
                }
                @if (element.isLock) {
                  <span matTooltip="Locked"
                  class="mdi mdi-lock-outline icon-margin"></span>
                }
                @if (element.isDisabled) {
                  <span matTooltip="Disabled"
                  class="mdi mdi-cancel icon-margin"></span>
                }
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
            <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
        </div>

      </div>
    </div>
  </div>
