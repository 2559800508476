import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IdentityVerificationLinkDialogData, IdentityVerificationLinkDialogResult, SendIdVerifyLinkFn } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import moment from 'moment';
import { Buffer } from 'buffer';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './identity-verification-link.dialog.html',
    styleUrls: ['./identity-verification-link.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule, ClipboardModule, MatInputModule, MatFormFieldModule,  FormsModule, ReactiveFormsModule]
})
export class IdentityVerificationLinkDialog {
  formControlLink!: FormControl<string | null>;
  sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  email: string = '';
  name: string = '';
  link: string = '';
  subscriptions: Subscription[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IdentityVerificationLinkDialogData,
    private dialogRef: MatDialogRef<IdentityVerificationLinkDialog, IdentityVerificationLinkDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
  ) {
    this.sendIdVerifyLinkFn = data.sendIdVerifyLinkFn;
    this.name = data.name;
    this.email = data.email;
    const individualId = data.individualId;
    const applicationId = data.applicationId;
    const idVerifyUrl = data.idVerifyUrl;
    const date = moment().format('DD-MM-YYYY');
    const token = Buffer.from(`${applicationId}/${individualId}/${date}`).toString('base64');
    this.link = `${idVerifyUrl}/${token}`;
    this.formControlLink = formBuilder.control(this.link);        
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.subscriptions.push(
      this.sendIdVerifyLinkFn(this.email, this.name, this.link).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.retryableMessage({
          successMessage: 'ID verify link has been sent',
          errorMessage: 'Failed to send ID verify link',
          retryFn: ()=> {
            this.sendIdVerifyLinkFn(this.email, this.name, this.link)
          }
        })
      ).subscribe(() => {
        this.dialogRef.close();
      })
    )
  }
}
