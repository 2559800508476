import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {getUser} from '@portal-workspace/grow-ui-library';
import {navigationUrlForUnverifiedAccount} from '../service/navigation-urls';


export const validEmailAndMobileVerificationGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser();
    if (user) {
      if (!user.mobile_verified ||  // not mobile verified
        !user.verified /* not email verified */ ) {
        return router.navigate(navigationUrlForUnverifiedAccount());
      }
    }
    return true;
  }
}


// NOTE: Deprecated since Angular 16
// @Injectable()
// export class ValidEmailAndMobileVerificationGuard  {
//   constructor(private router: Router) {
//   }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     const user = getUser();
//     if (user) {
//       if (!user.mobile_verified ||  // not mobile verified
//           !user.verified /* not email verified */ ) {
//         return this.router.navigate(navigationUrlForUnverifiedAccount());
//       }
//     }
//     return true;
//   }
//
// }
