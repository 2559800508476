import {Component, forwardRef, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {createPhoneNumberInputMask } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import { delay, distinctUntilChanged, tap } from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, EmployerValue, MonthsAtCurrentEmployerOptions } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'employer',
    templateUrl: './employer.component.html',
    styleUrls: ['./employer.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EmployerComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => EmployerComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, MatSelectModule, MatOptionModule]
})
export class EmployerComponent extends AbstractControlValueAccessor<EmployerValue> implements OnInit, Mark {

  createPhoneNumberInputMask = createPhoneNumberInputMask();

  monthsAtCurrentEmployerOptions = MonthsAtCurrentEmployerOptions;

  subscriptions: Subscription[] = [];

  formGroup: FormGroup<{
    currentEmployer: FormControl<string|null>,
    currentEmployerContact: FormControl<string|null>,
    yearsAtCurrentEmployer: FormControl<number|null>,
    monthsAtCurrentEmployer: FormControl<number|null>,
    previousEmployer: FormControl<string|null>,
    previousEmployerContact: FormControl<string|null>,
  }>;
  formControlCurrentEmployer: FormControl<string|null>;
  formControlCurrentEmployerContact: FormControl<string|null>;
  formControlYearsAtCurrentEmployer: FormControl<number|null>;
  formControlMonthsAtCurrentEmployer: FormControl<number|null>;
  formControlPreviousEmployer: FormControl<string|null>;
  formControlPreviousEmployerContact: FormControl<string|null>;

  requirePreviousEmployerInfo = false;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlCurrentEmployer = this.formBuilder.control(null, [Validators.required]);
    this.formControlCurrentEmployerContact = this.formBuilder.control(null, [Validators.required]);
    this.formControlYearsAtCurrentEmployer = this.formBuilder.control(null, [Validators.required]);
    this.formControlMonthsAtCurrentEmployer = this.formBuilder.control(0, [Validators.required]);
    this.formControlPreviousEmployer = this.formBuilder.control(null);
    this.formControlPreviousEmployerContact = this.formBuilder.control(null);
    this.formGroup = this.formBuilder.group({
      currentEmployer: this.formControlCurrentEmployer,
      currentEmployerContact: this.formControlCurrentEmployerContact,
      yearsAtCurrentEmployer: this.formControlYearsAtCurrentEmployer,
      monthsAtCurrentEmployer: this.formControlMonthsAtCurrentEmployer,
      previousEmployer: this.formControlPreviousEmployer,
      previousEmployerContact: this.formControlPreviousEmployerContact,
    });
    const sub = this.formControlYearsAtCurrentEmployer.valueChanges.pipe(
      tap(r => {
        const year =  Number(this.formControlYearsAtCurrentEmployer.value);
        if (year <= 1) {
          this.requirePreviousEmployerInfo = true;
          this.formControlPreviousEmployer.setValidators([Validators.required]);
          this.formControlPreviousEmployerContact.setValidators([Validators.required]);
        } else {
          this.requirePreviousEmployerInfo = false;
          this.formControlPreviousEmployer.setValidators(null);
          this.formControlPreviousEmployerContact.setValidators(null);
        }
        this.formControlPreviousEmployerContact.updateValueAndValidity();
        this.formControlPreviousEmployer.updateValueAndValidity();
      })
    ).subscribe();
    this.subscriptions.push(sub);
    const subscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          const v: EmployerValue = {
            // with validators set, these values should not be null when formGroup is valid
            currentEmployer: this.formControlCurrentEmployer.value ?? '',
            currentEmployerContact: this.formControlCurrentEmployerContact.value ?? '',
            yearsAtCurrentEmployer: this.formControlYearsAtCurrentEmployer.value ?? 0,
            monthsAtCurrentEmployer: this.formControlMonthsAtCurrentEmployer.value ?? 0,
            previousEmployer: this.formControlPreviousEmployer.value,
            previousEmployerContact: this.formControlPreviousEmployerContact.value,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }


  doWriteValue(v: EmployerValue | null | undefined): void | EmployerValue {
    if (v) {
      this.formControlYearsAtCurrentEmployer.setValue(v.yearsAtCurrentEmployer);
      const r = MonthsAtCurrentEmployerOptions.find(o => o ===  v.monthsAtCurrentEmployer);
      if (r !== null && r !== undefined) {
        this.formControlMonthsAtCurrentEmployer.setValue(r);
      }
      this.formControlCurrentEmployer.setValue(v.currentEmployer ? v.currentEmployer : null);
      this.formControlCurrentEmployerContact.setValue(v.currentEmployerContact ? v.currentEmployerContact : null);
      this.formControlPreviousEmployer.setValue(v.previousEmployer ? v.previousEmployer : null);
      this.formControlPreviousEmployerContact.setValue(v.previousEmployerContact ? v.previousEmployerContact: null);
    }
    return undefined;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }
  mark(): void {
    this.formGroup.markAllAsTouched();
  }
}
