import {AfterViewInit, Component, forwardRef, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AuthorisedSignatoryValue, TitleSelectionValue, AuthorisedSignatoryRoleOptions, NameComponentValue, EmailComponentValue, MobileValue, AuthorisedSignatoryRole, compareMatch} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {createEmailInputMask, createPhoneNumberInputMask, formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatButtonModule } from '@angular/material/button';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { MatInputModule } from '@angular/material/input';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


export type AuthorisedSignatoryFormArray = FormArray<AuthorisedSignatoryFormGroup>;
export type AuthorisedSignatoryFormGroup = FormGroup<{
  role: FormControl<AuthorisedSignatoryRole>,
  title: FormControl<TitleSelectionValue>,
  firstName: FormControl<NameComponentValue>,
  lastName: FormControl<NameComponentValue>,
  middleName: FormControl<string | null>,
  email: FormControl<EmailComponentValue>,
  mobile: FormControl<MobileValue>
}>

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'authorised-signatory',
    templateUrl: './authorised-signatory.component.html',
    styleUrls: ['./authorised-signatory.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AuthorisedSignatoryComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AuthorisedSignatoryComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, TitleSelectionComponent, MarkDirective, NameComponent, MatInputModule, EmailComponent, MobileComponent, MatButtonModule]
})
export class AuthorisedSignatoryComponent extends AbstractControlValueAccessor<AuthorisedSignatoryValue> implements OnInit, AfterViewInit, Mark {

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();
  authorisedSignatoryRoleOptions = AuthorisedSignatoryRoleOptions;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formArray: AuthorisedSignatoryFormArray;
  formGroup: FormGroup<{
    authorisedSignatories: AuthorisedSignatoryFormArray
  }>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<AuthorisedSignatoryFormGroup>([]);
    this.formGroup = formBuilder.group({
      authorisedSignatories: this.formArray,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.formArray.length) {
        this.addAutorisedSignatory();
      }
    });
  }

  addAutorisedSignatory(a?: Exclude<AuthorisedSignatoryValue, null>[number]) {
    const formControlRole = this.formBuilder.control(a ? a.role : null, [Validators.required]);
    const formControlTitle = this.formBuilder.control(a ? a.title : null, [Validators.required]);
    const formControlFirstName = this.formBuilder.control(a ? a.firstName : null, [Validators.required]);
    const formControlMiddleName = this.formBuilder.control(a ? a.middleName : null);
    const formControlLastName = this.formBuilder.control(a ? a.lastName : null, [Validators.required]);
    const formControlEmail = this.formBuilder.control(a ? a.email : null, [Validators.required]);
    const formControlMobile = this.formBuilder.control(a ? a.mobile : null, [Validators.required]);
    const formGroup = this.formBuilder.group({
      role: formControlRole,
      title: formControlTitle,
      firstName: formControlFirstName,
      lastName: formControlLastName,
      middleName: formControlMiddleName,
      email: formControlEmail,
      mobile: formControlMobile,
    });
    this.formArray.push(formGroup);
    this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formArray.valid) {
          // NOTE: if formArray is valid, all of it's formControl should be too.
          const val = this.formArray.value.map(_r => {
            const x = _r.role ?? null;
            const v: Exclude<AuthorisedSignatoryValue, null>[number] = {
              role: x,
              title: _r.title!,
              firstName: _r.firstName!,
              lastName: _r.lastName!,
              middleName: _r.middleName ?? '',
              email: _r.email!,
              mobile: _r.mobile!,
            };
            return v;
          });
          this.propagateChange(val);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
  }

  authorisedSignatoryFormGroups(): AuthorisedSignatoryFormGroup[] {
    return this.formArray.controls;
  }

  authorisedSignatoryFormGroupFormControl(authorisedSignatoryFormGroup: AuthorisedSignatoryFormGroup, controlName: 'role' | 'title' | 'firstName' | 'lastName' | 'middleName' | 'email' | 'mobile') {
    return authorisedSignatoryFormGroup.controls[controlName];
  }

  removeAuthorisedSignatory(authorisedSignatoryFormGroup: AuthorisedSignatoryFormGroup) {
    const index = this.formArray.controls.indexOf(authorisedSignatoryFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
  }

  doWriteValue(v: AuthorisedSignatoryValue | null | undefined): void | AuthorisedSignatoryValue {
    if (v) {
      for (const entry of v) {
        this.addAutorisedSignatory(entry);
      }
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

}
