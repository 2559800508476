import {Directive, ElementRef, OnInit, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[autoSelectOnClick]',
    standalone: true
})
export class AutoSelectOnClickDirective implements OnInit {

  constructor(private c: ViewContainerRef, private e: ElementRef) {
  }

  ngOnInit() {
    this.e.nativeElement.onclick = (evt: Event) => {
      this.e.nativeElement.select();
    };
  }
}
