import {AssetSupplier} from '../../domain/ppsr-service.domain';

export type SupplierType = 'Car Buying Service or Car Broker' |
  'Equipment manufacturer backed supplier' |
  'Equipment non-manufacturer backed supplier' |
  'Private sale, Vendor with ABN' |
  'Private sale, Individual';


export const SupplierTypeOptions: { type: SupplierType, name: string }[] = [
  { type: 'Car Buying Service or Car Broker', name: 'Car Buying Service or Car Broker' },
  { type: 'Equipment manufacturer backed supplier', name: 'Equipment manufacturer backed supplier' },
  { type: 'Equipment non-manufacturer backed supplier', name: 'Equipment non-manufacturer backed supplier' },
  { type: 'Private sale, Vendor with ABN', name: 'Private sale, Vendor with ABN' },
  { type: 'Private sale, Individual', name: 'Private sale, Individual' }
]


export type PpsrAssetSupplierSearchValue = AssetSupplier | null;
