import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {EditLenderDialogData, EditLenderDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { delay, distinctUntilChanged, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './edit-lender.dialog.html',
    styleUrls: ['./edit-lender.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, NgxCurrencyDirective, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, CurrencyInputComponent]
})
export class EditLenderDialog implements OnInit {

  disableControls = false;
  formGroup: FormGroup<{
    lender: FormControl<string | null>;
  }>;
  formControlLender!: FormControl<string | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  title: string = 'Lender';
  type: 'Add' | 'Edit' = 'Edit';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: EditLenderDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EditLenderDialog, EditLenderDialogResult>) {
    this.title = dialogData.title;
    this.type = dialogData.type;
    this.formControlLender = formBuilder.control(this.type === 'Add' ? null : dialogData.lender, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      lender: this.formControlLender,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        lender: this.formControlLender.value ?? '',
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
