@if (background) {
  <div class="corporate-loans-background-details-component">
    @if (background) {
      <div class="background">
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">Background</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Business Background</div>
              <div body>{{background.whatDoesTheBusinessSell}}</div>
            </cell>
          </div>
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Purpose of facility</div>
              <div body>{{background.whatDoesTheBusinessSellTo}}</div>
            </cell>
          </div>
        </div>
      </div>
    }
  </div>
}
