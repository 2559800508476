import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CollectionViewer, DataSource, SelectionModel} from '@angular/cdk/collections';
import {User, DEFAULT_LIMIT, DEFAULT_OFFSET, isSalesAM, PortalLoginUser, isSalesBDM,} from '@portal-workspace/grow-shared-library';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { Sort, MatSortModule } from '@angular/material/sort';
import {AuthService} from '../../service/auth.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {getUser, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {AdminService} from '../../service/admin.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import { Router } from '@angular/router';
import { navigationUrlForUserDetail } from '../../service/navigation-urls';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe } from '@angular/common';

export class InternalDataSource extends DataSource<User> {

  subject = new BehaviorSubject<User[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(users: User[]) {
    this.subject.next(users);
  }
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './unverified-users.page.html',
    styleUrls: ['./unverified-users.page.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, MatSlideToggleModule, MatCheckboxModule, CustomPaginatorComponent, AsyncPipe]
})
export class UnverifiedUsersPage implements OnInit {

  loader = loadingFor('tableLoading', 'saving');
  store = createAsyncStore();
  emailMessage = 'verified';
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }
  globalSelected = false; // select all checkboxes
  dataSource = new InternalDataSource();
  selection = new SelectionModel<User>(true, []);
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  sorts?: {prop: string, dir: 'asc' | 'desc'} ;
  total = 0;
  subscriptions: Subscription[] = [];

  formControlSearch: FormControl<string | null>;
  displayColumns = ['userId', 'name', 'email', 'mobileNumber', 'actions'] ;
  user: PortalLoginUser | null = getUser();
  canAccess = isSalesAM(this.user) || isSalesBDM(this.user)
  
  constructor(private formBuilder: FormBuilder,
              private adminService: AdminService,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private router: Router,
              private authService: AuthService) {
    this.formControlSearch = formBuilder.control(null);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.resetPaging();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.reload();
  }

  reload() {
    const sub = this.authService.getUnverifiedUsers({
      page: {limit: this.limit, offset: this.offset},
      filter: this.filter,
      sorts: this.sorts ? [this.sorts] : undefined
    }).pipe(
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        const users = r.payload;
        this.total = r.total;
        this.dataSource.update(users);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onSortData($event: Sort) {
    const col = $event.active;
    const dir = $event.direction;
    if (col && dir) {
      console.log('****** sort', col, dir);
      this.sorts = {prop: col as any, dir};
      this.resetPaging();
    } else {
      this.sorts = undefined;
    }
    this.reload();
  }

  resetPaging() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onRowClicked($event: MouseEvent, element: User) {
    if(this.canAccess){
      if(element.AccessLevel !== 'externalbroker' && element.AccessLevel !== 'companyadmin' && element.AccessLevel !== 'companyoperator' && element.AccessLevel !== 'salesAM' && element.AccessLevel !== 'salesBDM'){
        this.dialogService.openAlertDialog({
          message: `Invalid Access`,
          subMessage: `You do not have this access.`,
        }).afterClosed().pipe(
          tap()
        ).subscribe();
      }else{
        this.router.navigate(navigationUrlForUserDetail(element.UserId))
    }
  }else{
    this.router.navigate(navigationUrlForUserDetail(element.UserId))
  }
}
  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize
    this.reload();
  }

  toggleAllSelection() {
    const users = this.dataSource.subject.getValue();
    this.globalSelected = !this.globalSelected;
    for (const user of users) {
      if (this.globalSelected) {
        this.selection.select(user);
      } else {
        this.selection.deselect(user);
      }
      // this.data.push(user)
    }
        // console.log('al:::', this.data);
  }

  sendAll(){
    this.authService.sendAll(this.selection.selected).pipe(
      this.loader.saving.track(),
      this.toastService.retryableMessage({
        successMessage: 'Email Sent',
        errorMessage: 'Failed to verify the Email',
        retryFn: ()=> {
          console.log('**** retry ', this);
          this.sendAll();
        }
      }),
    ).subscribe((res)=>{
      if(res){
        this.checkboxClear();
        // this.data.length = 0;
      }
    })
  }

  checkboxClear(){
    for (const user of this.selection.selected) {
      this.selection.deselect(user)
    }
  }

  onchangeEmail($event:MatSlideToggleChange, element: User){
    if($event.checked){
      this.authService.verifyEmail(element.UserId).pipe(
        this.toastService.snackBarObservable(`Email marked as verified`),
        tap(r => {

        })
      ).subscribe((res) => {
        if(res){
           this.reload();
        }
      });
    }
    else{
      this.authService.unverifyEmail(element.UserId).pipe(
        this.toastService.snackBarObservable(`Email marked as unverified`),
      ).subscribe((res) => {
        if(res){
           this.reload();
        }
      });

    }
  }

  onchangePhone($event:MatSlideToggleChange, element: User){
    if($event.checked){
      this.authService.verifyPhone(element.UserId).pipe(
        this.toastService.snackBarObservable(`Phone marked as verified`)
      ).subscribe((res) => {
        if(res){
          this.reload();
        }
      });
    }
    else{
      this.authService.unverifyPhone(element.UserId).pipe(
        this.toastService.snackBarObservable(`Phone marked as unverified`)
      ).subscribe((res) => {
        if(res){
          this.reload();
        }
      });
    }
  }

  onSelectionChanged($event: MatCheckboxChange, user: User) {
    this.globalSelected = false;
    if($event.checked) {
      this.selection.select(user);
    } else {
      this.selection.deselect(user);
    }
  }
}


