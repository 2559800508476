import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ApiResponse, DirectDebitDialogData, DirectDebitDialogResult, EditBrokerageDialogData, EditBrokerageDialogResult, EditInvoiceAmountDialogData, EditInvoiceAmountDialogResult, FinanceType, IndividualDirector, LocalBankAccountDetailValue, ManualDirectDebitFn, NewDirectPaymentBody, OverdraftDirectDebitDialogData, OverdraftDirectDebitDialogResult, PismoOnetimeRepaymentBody, PrefillDirectorsDialogData, PrefillDirectorsDialogResult} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, delay, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import _ from "lodash";
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import moment from 'moment';

class InternalDataSource extends DataSource<IndividualDirector> {

  subject = new BehaviorSubject<IndividualDirector[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<IndividualDirector[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(directors: IndividualDirector[]) {
    this.subject.next(directors);
  }

}

@Component({
    templateUrl: './prefill-directors.dialog.html',
    styleUrls: ['./prefill-directors.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, NgxCurrencyDirective, LocalBankAccountDetailComponent, MatTableModule, MatCheckboxModule, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, CurrencyInputComponent]
})
export class PrefillDirectorsDialog implements OnInit {

  directors: IndividualDirector[] = [];
  displayedColumns = ['firstName', 'lastName', 'dob', 'address', 'actions'];
  dataSource = new InternalDataSource();
  selections = new SelectionModel<IndividualDirector>(true, []);
  directorCheckbox: boolean[] = [];
  moment = moment;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: PrefillDirectorsDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<PrefillDirectorsDialog, PrefillDirectorsDialogResult>) {
    this.directors = dialogData.directors;
    this.dataSource.update(this.directors);
    this.directorCheckbox = this.directors.map(() => false);
  }

  ngOnInit(): void {

  }

  submit() {
    const selectedDirectors = [];
    for (let i = 0; i < this.directors.length; i++) {
      if (this.directorCheckbox[i]) {
        selectedDirectors.push(this.directors[i]);
      }
    }

    this.dialogRef.close({
      readyForSubmission: true,
      selectedDirectors: selectedDirectors,
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  toggleAllSelection() {
    this.directorCheckbox = this.directorCheckbox.map(() => true);
  }

  onChangeCheckbox(event: MatCheckboxChange, index: number) {
    if (event.checked) {
      this.directorCheckbox[index] = true;
    } else {
      this.directorCheckbox[index] = false
    }
  }
}
