import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AssetSupplier,
  DisbursementPayee,
  AssetDisbursementPayeeDialogData,
  AssetDisbursementPayeeDialogResult, SearchAccreditedSupplierFn, DisbursementAssetPayee
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    templateUrl: './asset-disbursement-payee.dialog.html',
    styleUrls: ['./asset-disbursement-payee.dialog.scss'],
    standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatCheckboxModule, MatInputModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class AssetDisbursementPayeeDialog {
  allPayees: DisbursementAssetPayee[] = [];
  currentPayee: DisbursementAssetPayee | null = null;
  supplier!: AssetSupplier;
  formControlPayee!: FormControl<DisbursementAssetPayee | null>;
  formControlManual: FormControl<boolean | null>;
  formControlSupplier!: FormControl<AssetSupplier | null>;
  formControlPayeeManual!: FormControl<string | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AssetDisbursementPayeeDialogData,
              private dialogRef: MatDialogRef<AssetDisbursementPayeeDialog, AssetDisbursementPayeeDialogResult>,
              private formBuilder: FormBuilder) {
    this.allPayees = this.data.allPayees;
    this.currentPayee = this.data.currentPayee;

    this.formControlPayee = this.formBuilder.control(this.currentPayee, [Validators.required]);
    this.formControlSupplier = this.formBuilder.control(null);
    this.formControlManual = this.formBuilder.control(this.currentPayee ? this.currentPayee.type == 'manual' : false);
    this.formControlPayeeManual = this.formBuilder.control(this.currentPayee?.payeeManual ?? null);

    if (this.currentPayee?.type === 'supplier') {
      this.supplier = this.currentPayee.supplier as AssetSupplier;
    }

    if (this.currentPayee?.type === 'private-seller') {
      if (this.currentPayee?.privateSeller?.business) {
        this.formControlPayee.setValue(this.allPayees.find(p =>
          p?.privateSeller?.business?.abn == this.currentPayee?.privateSeller?.business?.abn
        ) ?? null)
      } else {
        this.formControlPayee.setValue(this.allPayees.find(p =>
          p?.privateSeller?.firstName == this.currentPayee?.privateSeller?.firstName &&
          p?.privateSeller?.lastName == this.currentPayee?.privateSeller?.lastName &&
          p?.privateSeller?.middleName == this.currentPayee?.privateSeller?.middleName &&
          p?.privateSeller?.privateSellerAddress == this.currentPayee?.privateSeller?.privateSellerAddress
        ) ?? null)
      }
    }
  }

  getSupplierData(event: AssetSupplier) {
    this.formControlSupplier.setValue(event);
    this.formControlPayee.setValue({
      i: 'disbursement-asset-payee',
      type: 'supplier',
      supplier: event,
    });
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      payee: this.payee,
    });
  }

  get payee(): DisbursementAssetPayee {
    if (this.formControlManual.value) {
      return {
        i: 'disbursement-asset-payee',
        type: 'manual',
        payeeManual: this.formControlPayeeManual.value ?? "",
      }
    } else {
      return this.formControlPayee.value as DisbursementAssetPayee
    }
  }
}
