
<div class="name-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      @if (title) {
        <mat-label>{{title}}</mat-label>
      }
      <input matInput [inputMask]="includeDigits ? createNameIncludeDigitsInputMask : createNameInputMask"
        placeholder="{{placeholder}}" type="text" [maxlength]="maxLength"
        [formControl]="formControl" [required]="mandatory" [disableControl]="disabled">
        @for (errorKey of errorKeys(formControl); track errorKey) {
          <mat-error>
            @if (formControl.touched && formControl.hasError(errorKey)) {
              {{errorMessage(formControl, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </form>
  </div>
