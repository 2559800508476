import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditKycStatusDialogData, EditKycStatusDialogResult, EditKycStatusValue } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
    templateUrl: './edit-kyc-status.dialog.html',
    styleUrls: ['./edit-kyc-status.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class EditKycStatusDialog {

  formGroup: FormGroup<{
    kycStatus: FormControl<EditKycStatusValue>;
  }>;
  formControl: FormControl<EditKycStatusValue>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditKycStatusDialogData,
              private matDialogRef: MatDialogRef<EditKycStatusDialog, EditKycStatusDialogResult>,
              private formBuilder: FormBuilder) {
    this.formControl = formBuilder.control(data.kycStatus ?? null, [Validators.required]);
    this.formGroup = formBuilder.group({
      kycStatus: this.formControl,
    })
  }

  onCancel($event: Event) {
    this.matDialogRef.close({
      readyForSubmission: false,
      kycStatus: this.formControl.value
    });
  }

  onSubmit($event: Event) {
    if (this.formControl.valid) {
      this.matDialogRef.close({
        readyForSubmission: true,
        kycStatus: this.formControl.value
      });
    }
  }
}
