import {Component, OnInit, forwardRef, inject, SimpleChanges, Input} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {MARK, Mark} from "@portal-workspace/grow-ui-library/mark";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {compareMatch, NumberInputValue} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import {
  createNoDecimalInputMask, createThreeDecimalInputMask, createTwoDecimalInputMask,
  formControlErrorKeys, formControlErrorMessage,
  maxValidator,
  minMaxValidator,
  minValidator
} from "@portal-workspace/grow-ui-library";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FlexModule} from "@angular/flex-layout";
import {InputMaskModule} from "@ngneat/input-mask";
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumberInputComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => NumberInputComponent)},
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    InputMaskModule,
    DisableControlDirective,
  ]
})
export class NumberInputComponent extends AbstractControlValueAccessor<NumberInputValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  @Input({required: false}) title = 'Credit Rate Adjustment';
  @Input({required: false}) hint: string = '';
  @Input({required: false}) required = true;
  @Input({required: false}) min: number | null = 0;
  @Input({required: false}) max: number | null = null;
  @Input({required: false}) decimals: 0 | 2 | 3 = 0;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  inputMask  = createNoDecimalInputMask();

  formBuilder = inject(FormBuilder);
  formControl!: FormControl<number | null>;
  formGroup!: FormGroup<{
    number: FormControl<number | null>
  }>;

  constructor() {
    super();
    const validators = this.createValidators();
    this.formControl = this.formBuilder.control(null, validators);
    this.formGroup = this.formBuilder.group({
      number: this.formControl,
    });
  }

  ngOnInit() {
    switch(this.decimals) {
      case 0: {
        this.inputMask = createNoDecimalInputMask();
        break;
      }
      case 2: {
        this.inputMask = createTwoDecimalInputMask();
        break;
      }
      case 3: {
        this.inputMask = createThreeDecimalInputMask();
        break;
      }
    }
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
       if (this.formGroup.valid) {
          this.propagateChange(this.formControl.value!);
       } else {
         this.propagateChange(null);
       }
      })
    ).subscribe());

  }

  createValidators(): ValidatorFn[] {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.min != null && this.max != null) {
      validators.push(minMaxValidator(this.min, this.max));
    } else if (this.min != null) {
      validators.push(minValidator(this.min));
    } else if (this.max != null) {
      validators.push(maxValidator(this.max));
    }
    return validators;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if ((changes as any).min || (changes as any).max || (changes as any).required) {
        if (this.formControl) {
          const validators = this.createValidators();
          this.formControl.setValidators(validators);
          this.formControl.updateValueAndValidity();
          if (this.formControl.invalid) {
            this.formControl.setValue(null);
            // this.propagateChange(null);
          }
        }
      } else if ((changes as any).disabled) {
        if (this.formControl) {
          const isDisabled = (changes as any).disabled.currentValue;
          if (isDisabled) {
            this.formControl.disable();
          } else {
            this.formControl.enable();
          }
        }
      }
    }
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

  doWriteValue(v?: NumberInputValue | undefined): void | NumberInputValue {
    this.formControl.setValue(v ?? null);
  }
}
