<div class="credit-result-web-services">
    <div class="row">
        <div class="col-12">
              <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                <table mat-table [dataSource]="dataSource" class="width-100 mb-4">
                  @for (column of columnsToDisplay; track column) {
                    <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'column-width-1': column === 'applicant',
                    'column-width-2': column === 'type' || column === 'lastUpdatedTime',
                    'column-width-3': column === 'report',
                  }"> 
                    <div class="cell">{{getColumnTitles(column)}}</div> </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="cell">
                            @if (column === 'applicant') {
                              <div>{{element.applicant}}</div>
                              <div class="date-text">{{element.applicantType ?? ''}}</div>
                            }
                            @else if (column === 'type') {
                              {{element.type}}
                            }
                            @else if (column === 'lastUpdatedTime') {
                              {{getWebServiceUpdatedTime(element)}}
                            }
                            @else if (column === 'report') {
                              <div fxLayout="row wrap"
                                fxLayoutAlign="start center"
                                fxLayout.lt-md="column"
                                fxLayoutAlign.lt-md="center center"
                                >
                                <span class="mdi mdi-eye-outline cursor-pointer icon-margin" matTooltip="View" (click)="onViewReport(element)"></span>
                                <span class="mdi mdi-download cursor-pointer icon-margin" matTooltip="Download" (click)="onDownloadReport(element)"></span>
                                <span class="mdi mdi-xml cursor-pointer icon-margin" matTooltip="JSON" (click)="onViewJson(element)"></span>
                              </div>
                            }
                            @else {
                              {{element[column]}}
                            }
                          </div>
                        </td>
                      </ng-container>
                    }
            
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                    </tr>
                </table>

                @if (!dataSource.length) {
                  <table>
                    <tr>
                      <td class="not-found"> No external web services found</td>
                    </tr>
                  </table>
                }
              </div>
        </div>
    </div>
</div>
