<div class="disbursement-invoices-dialog">
  <mat-dialog-content>
    <h3 class="header">Please select invoices</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="invoice-chip-list">
          <mat-label>Invoice numbers</mat-label>
          <mat-chip-grid #chipList aria-label="email list">
            @for (invoice of selectedInvoices; track invoice) {
              <mat-chip-row (removed)="remove(invoice)">
                {{invoice.invoiceNumber}}
                <button matChipRemove>
                  <span class="mdi mdi-close"></span>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input #invoiceInput
            [matChipInputFor]="chipList"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            [formControl]="formControlInvoiceNumber">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              @for (invoice of filteredInvoices; track invoice) {
                <mat-option [value]="invoice.invoiceNumber">
                  {{invoice.invoiceNumber}}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      @if (!validateInvoiceLength()) {
        <div class="row">
          <div class="col-12">
            <span class="text-danger">Maximum 30 characters are allowed for invoice numbers.</span>
          </div>
        </div>
      }
      <div class="row mt-2">
        <div class="col-12">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
            <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
            <button color="primary" [disabled]="!selectedInvoices.length || !validateInvoiceLength()" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
