<div class="credit-flows">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search credit flow..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="end start"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button class="inactive w-lt-md-100" (click)="addCreditFlow()"><span class="mdi mdi-plus" matPrefix></span>Add Credit Flow</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-12">
        <mat-form-field>
          <mat-label>Published</mat-label>
          <mat-select [(value)]="selectedPublishedStatus">
            @for (status of pusblishedStatus; track status) {
              <mat-option
                [value]="status"
                (click)="onPublishedStatusSelected(status)">
                {{status.name}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-12">
        <mat-form-field>
          <mat-label>Application Type</mat-label>
          <mat-select [(value)]="selectedApplicationType">
            @for (applicationType of applicationTypes; track applicationType) {
              <mat-option
                [value]="applicationType"
                (click)="onApplicationTypeSelected(applicationType)">
                <application-type-icon [applicationType]="applicationType.type" ></application-type-icon>
                {{applicationType.name}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
        <div class="col-12">
            @if (loader.tableLoading.inProgress$ | async) {
                <custom-content-loader type="list"></custom-content-loader>
              }
              <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                <table mat-table [dataSource]="displayedData" class="width-100 mb-2">
                  @for (column of columnsToDisplay; track column) {
                    <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'column-width-1': column === 'name',
                    'column-width-2': column === 'lastUpdatedTime' || column === 'actions',
                    'column-width-3': column === 'published' || column === 'products'
                  }"> 
                    <div class="cell">{{getColumnTitles(column)}}</div> </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="cell">
                            @if (column == 'actions') {
                              <div fxLayout="row wrap"
                                fxLayoutAlign="start center"
                                fxLayout.lt-md="column"
                                fxLayoutAlign.lt-md="center center"
                                >
                                <span class="mdi mdi-pencil-outline cursor-pointer icon-margin date-text" matTooltip="Edit" (click)="editCreditFlow(element)"></span>
                                <span class="mdi mdi-content-copy cursor-pointer icon-margin date-text" matTooltip="Copy" (click)="copyCreditFlow(element)"></span>
                                @if (!element?.published) {
                                  <span class="mdi mdi-upload-outline cursor-pointer icon-margin date-text" matTooltip="Publish" (click)="publishCreditFlow(element)"></span>
                                } 
                                @else {
                                  <span class="mdi mdi-download-outline cursor-pointer icon-margin date-text" matTooltip="Unpublish" (click)="unpublishCreditFlow(element)"></span>
                                }
                                <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin date-text" matTooltip="Delete" (click)="deleteCreditFlow(element)"></span>
                              </div>
                            }
                            @if (column == 'name') {
                              <div fxLayout="row"
                                fxLayoutGap="10px"
                                fxLayoutAlign="space-between start"
                                fxLayout.lt-md="column"
                                fxLayoutAlign.lt-md="center start">
                                <div>[ID: {{element.id}}] {{element[column]}}</div>
                                <div class="date-text" fxLayout="row wrap"
                                  fxLayoutAlign="start center"
                                  fxLayout.lt-md="column"
                                  fxLayoutAlign.lt-md="center center">
                                  <span class="mdi mdi-pencil-outline cursor-pointer icon-margin hide" matTooltip="Edit Name" (click)="editCreditFlowName(element)"></span>
                                </div>
                              </div>
                  
                            }
                            @if (column == 'published') {
                              {{element.published ? 'Yes' : 'No'}}
                            }
                            @if (column == 'products') {
                              <div>
                                @for(item of extractTrueKeys(element); track item){
                                  <application-type-icon [applicationType]="item" class="mr"></application-type-icon>
                                }
                              </div>
                              }
                            @if (column == 'lastUpdatedTime') {
                              {{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}
                            }
                          </div>
                        </td>
                      </ng-container>
                    }
            
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                    </tr>
                </table>
                @if (total) {
                  <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
                  [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
                }
              </div>
        </div>
    </div>
</div>
