import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApplicationConfirmationDialogData, ApplicationConfirmationDialogResult} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './application-confirmation.dialog.html',
    styleUrls: ['./application-confirmation.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FlexModule, MatCheckboxModule, MatButtonModule, MatDialogModule]
})
export class ApplicationConfirmationDialog {

  formGroup: FormGroup<{}>;
  messagesAndControls: {m: ApplicationConfirmationDialogData['messages'][number], c: FormControl}[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data: ApplicationConfirmationDialogData,
              private dialogRef: MatDialogRef<ApplicationConfirmationDialog, ApplicationConfirmationDialogResult>,
              private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({});
    for (const message of data.messages) {
      const formControl = this.formBuilder.control(false, [Validators.requiredTrue]);
      this.formGroup.addControl(message.id, formControl);
      this.messagesAndControls.push({m: message, c: formControl});
    }
  }

  onSubmit($event: MouseEvent) {
    const result: ApplicationConfirmationDialogResult = {
      readyForSubmission: false
    };
    result.readyForSubmission = this.messagesAndControls.reduce((s , curr) => {
      result[curr.m.id] = curr.c.value;
      return s && curr.c.value;
    }, true);

    this.dialogRef.close(result);
  }

  onCancel($event: MouseEvent) {
    const result: ApplicationConfirmationDialogResult = {
      readyForSubmission: false
    };
    result.readyForSubmission = this.messagesAndControls.reduce((s , curr) => {
      result[curr.m.id] = curr.c.value;
      return false;
    }, false);

    this.dialogRef.close(result);
  }
}
