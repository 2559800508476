import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationTypes, PortalLoginUser} from '@portal-workspace/grow-shared-library';
import { AdminService } from '../../service/admin.service';
import {NotificationService} from '../../service/notification.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import { ApplicationTilesComponent } from '@portal-workspace/grow-ui-library';
import { MessageBox2Component } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';

import { RegistrationService } from '../../service/registration.service';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './direct-sale.page.html',
    styleUrls: ['./direct-sale.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, MessageBox2Component, ApplicationTilesComponent, MatButtonModule, FlexModule, ExtendedModule]
})
export class DirectSalePage implements OnInit {
  user: PortalLoginUser | null = null;
  isApproved : number | null | undefined;
  accessLevel: string|undefined;
  isDealer: boolean | undefined = false;
  environment = environment;
  subscriptions: Subscription[] = [];

  constructor(private applicationService: ApplicationService) {}

  ngOnInit(): void {}

  async navigationHandler(event: ApplicationTypes) {
    await this.applicationService.applicationTilesComponentNavigationHandlerForDirectSale(event);
  }
}
