<div class="consumer-quote-component">
  <payment-chart
    [hideBrokerOrigiantionFee]="false"
    [hideBalloonPayment]="true"
    [hideBrokerage]="true"
    [data]="paymentChartData"
    [delay]="3000"
    [calculationLog]="calculationLog">
  </payment-chart>
</div>
