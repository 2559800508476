import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Address2ComponentValue,
  EditAddressDialogData,
  EditAddressDialogResult,
} from '@portal-workspace/grow-shared-library';
import {markTriggerSubject} from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CustomAddressComponent } from '../address-component/custom-address.component';

@Component({
    templateUrl: './edit-address.dialog.html',
    styleUrls: ['./edit-address.dialog.scss'],
    standalone: true,
    imports: [CustomAddressComponent, FormsModule, ReactiveFormsModule, MarkDirective, FlexModule, MatButtonModule, MatDialogModule]
})
export class EditAddressDialog {
  formControlAddress!: FormControl<Address2ComponentValue>;
  formGroup!: FormGroup;
  markTriggerSubject = markTriggerSubject

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditAddressDialogData,
              private dialogRef: MatDialogRef<EditAddressDialog, EditAddressDialogResult>,
              private formBuilder: FormBuilder) {
    this.formControlAddress = this.formBuilder.control(this.data.address ?? null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      address: this.formControlAddress
    })
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      address: this.formControlAddress.value as Address2ComponentValue,
    })
  }
}
