import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { PaginablePayloadApiResponse, PismoAccountNotes, RemoveApplicationNoteByNoteIdFn, RemovePismoAccountNoteByNoteIdFn, User } from '@portal-workspace/grow-shared-library';
import { tap } from 'rxjs/operators';
import { getUser, setupUntilDestroy } from '../component-utils';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { ConfirmationDialogResult, DEFAULT_LIMIT, DEFAULT_OFFSET, } from '@portal-workspace/grow-shared-library'
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatTable, MatTableModule } from '@angular/material/table';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollapsableExpandableTextComponent } from '../collapsable-expandable-component/collapsable-expandable-text.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSortModule } from '@angular/material/sort';

export type GetPismoNotesByAccountNumberFn = (limit: number, offset: number, accountNumber: number) => Observable<PaginablePayloadApiResponse<PismoAccountNotes>>;

export class PismoAccountNotesDataSource extends DataSource<PismoAccountNotes> {

  subject: Subject<PismoAccountNotes[]> = new BehaviorSubject<PismoAccountNotes[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<PismoAccountNotes[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(disbursements: PismoAccountNotes[]) {
    this.subject.next(disbursements);
  }
}
export type PismoNotesComponentEvent = PismoNotesComponentTotalChangedEvent;
export interface PismoNotesComponentTotalChangedEvent {
  type: 'total-changed',
  payload: number
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'pismo-notes',
  templateUrl: './pismo-notes.component.html',
  styleUrls: ['./pismo-notes.component.scss'],
  standalone: true,
  imports: [MatTableModule, MatSortModule, FlexModule, NgClass, ExtendedModule, CollapsableExpandableTextComponent, MatTooltipModule, CustomPaginatorComponent, DatePipe]
})

export class PismoNotesComponent implements OnInit {
  @Input({ required: false }) pismoNotes: PismoAccountNotes[] | [] = [];
  @Input({ required: true }) getPismoNotesByAccountNumberFn!: GetPismoNotesByAccountNumberFn;
  @Input({ required: true }) removePismoAccountNoteByNoteIdFn!: RemovePismoAccountNoteByNoteIdFn;
  @Output() totalNotesChange = new EventEmitter<PismoNotesComponentEvent>();
  subscriptions: Subscription[] = []  
  user!: User
  dataSource = new PismoAccountNotesDataSource();
  displayedColumns: string[] = ['date', 'user', 'note', 'action'];
  @Input({ required: true }) total !: number;
  offset = DEFAULT_OFFSET;
  limit = DEFAULT_LIMIT;

  constructor(private route: ActivatedRoute,
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService
  ) { }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser()!
    this.reload()
    this.reset();
  }

  reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  private reload() {
    const accountNumber = this.route.snapshot.paramMap.get('pismoAccountNumber') || '';
    this.getPismoNotesByAccountNumberFn(this.limit, this.offset, parseInt(accountNumber)).pipe(
      tap(r => {
        this.total = r.total;
        this.pismoNotes = r.payload;
        this.dataSource.update(r.payload)
        this.totalNotesChange.emit({ type: 'total-changed', payload: this.total });  // Emit the updated total
      })
    ).subscribe();
  }

  ngOnChanges(changes: any) {
    if (changes.pismoNotes) {
      this.reload()
    }
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }
  deleteNote(noteId: number) {
    this.subscriptions.push(
      this.removePismoAccountNoteByNoteIdFn(noteId).pipe(
        this.toastService.spinnerObservable(),
        tap(r => { this.reload() }),
        this.toastService.snackBarObservable("Note successfully removed")
      ).subscribe()
    )
  }

  onDelete(element: PismoAccountNotes) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to delete this note?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          if (element.AccountNotesId) {
            this.deleteNote(element.AccountNotesId)
            this.reload()
          }
        }
      })
    )
  }
}
