<form [formGroup]="formGroup" class="confirm-password-component" fxLayout="column" fxLayoutAlign="center start">
  <mat-form-field fxFlex="1 0 auto" class="password-field">
    <input matInput type="password"  [formControl]="formControlOldPassword" placeholder="Current Password"
      [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide" class="mdi mdi-eye-outline"></mat-icon>
      <mat-hint>The password must have a minimum of 8 characters, with at least one numeric digit, one uppercase and one lowercase</mat-hint>
      @for (errorKey of errorKeys(formControlOldPassword); track errorKey) {
        <mat-error>
          @if (formControlOldPassword.touched && formControlOldPassword.hasError(errorKey)) {
            {{errorMessage(formControlOldPassword, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field fxFlex="1 0 auto" class="password-field">
      <input matInput type="password"  [formControl]="formControlPassword" placeholder="New Password"
        [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide" class="mdi mdi-eye-outline"></mat-icon>
        <mat-hint>The password must have a minimum of 8 characters, with at least one numeric digit, one uppercase and one lowercase</mat-hint>
        @for (errorKey of errorKeys(formControlPassword); track errorKey) {
          <mat-error>
            @if (formControlPassword.touched && formControlPassword.hasError(errorKey)) {
              {{errorMessage(formControlPassword, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
      <mat-form-field fxFlex="1 0 auto">
        <input matInput type="password"  [formControl]="formControlConfirmPassword"
          placeholder="Confirm Password" [type]="confirmPassword_hide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="confirmPassword_hide = !confirmPassword_hide" class="mdi mdi-eye-outline"></mat-icon>
          @for (errorKey of errorKeys(formControlConfirmPassword); track errorKey) {
            <mat-error>
              @if (formControlConfirmPassword.touched && formControlConfirmPassword.hasError(errorKey)) {
                {{errorMessage(formControlConfirmPassword, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </form>