import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  Address2,
  Application,
  ApplicationWebService,
  CreateApplicationNoteFn,
  DeleteWebServicesFn,
  DownloadCreditorWatchReportFn,
  DuplicatedApplication,
  GetApplicationByIdFn,
  GetApplicationCreditFlowResultFn,
  GetApplicationDscrFn,
  GetApplicationOwnerFn,
  GetCreditAuditLogFn,
  GetCreditWebServicesFn,
  GetDscrCalculatorValueFn,
  GetDuplicatedApplicationsFn,
  GetUserFn,
  isAdminOrCreditUser,
  RemoveApplicationNoteByNoteIdFn,
  RequestMoreInformationFn,
  RunCreditFlowFn,
  UpdateCreditStatusFn,
  UpdateDscrCalculatorValueFn,
  User,
} from '@portal-workspace/grow-shared-library';
import {
  GetApplicationAuditLogsFn,
  GetNotesByApplicationIdFn,
  getUser,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { DscrCalculatorComponent } from '../dscr-calculator.component';

import {
  isInternalUser,
} from '@portal-workspace/grow-shared-library';
import { UntypedFormBuilder} from '@angular/forms';
 
import { Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PortalHotToastService} from '../../portal-hot-toast-component/hot-toast.service';
import {ApplicationDialogService} from '../../application-dialog-component/application-dialog.service';
import { MatExpansionModule } from "@angular/material/expansion";
import { CreditOverviewComponent } from '../../credit-component/credit-overview.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet } from '@angular/common';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-credit',
    templateUrl: './asset-credit.component.html',
    styleUrls: ['./asset-credit.component.scss'],
    standalone: true,
    imports: [DscrCalculatorComponent, MatExpansionModule, CreditOverviewComponent, MatTabsModule, MatCardModule, NgTemplateOutlet]
})

export class AssetCreditComponent implements OnInit {
 
  subscriptions: Subscription[] = [];
  expandedIndex = 0;
  
  @Input() application!: Application;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getApplicationDscrFn!: GetApplicationDscrFn;
  @Input({required: true}) apiUrl!: string;
  @Output() viewReport: EventEmitter<ApplicationWebService> = new EventEmitter();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  isInternalUser = isInternalUser;
  isAdminOrCreditUser = isAdminOrCreditUser;
  loggedInUser: User | null = getUser();

  constructor(private formBuilder: UntypedFormBuilder,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              ) {
  
  }
  
  isPanelExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  expandPanel(index: number) {
    this.expandedIndex = index;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onViewReport(element: ApplicationWebService) {
    this.viewReport.emit(element)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
