import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AzureStorageDocument, getFileTags} from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatTooltipModule } from '@angular/material/tooltip';


export interface UploadedFilesListComponentEvent {
  existing: Exclude<AzureStorageDocument[], null>;
  deleted: Exclude<AzureStorageDocument[], null>;
  deletedFile: Exclude<AzureStorageDocument, null>;
}

// use value object : UploadFile
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'uploaded-files-list',
    templateUrl: './uploaded-files-list.component.html',
    styleUrls: ['./uploaded-files-list.component.scss'],
    standalone: true,
    imports: [FlexModule,MatTooltipModule]
})
export class UploadedFilesListComponent implements OnInit, OnChanges {

  subscriptions: Subscription[] = [];
  @Input({required: false}) uploadedFiles: any[] = [];
  @Input({required: false}) applicationId!: number | undefined;
  @Input({required: false}) isAzureDocument = false;
  @Output() events: EventEmitter<UploadedFilesListComponentEvent> = new EventEmitter<UploadedFilesListComponentEvent>();

  existing: Exclude<AzureStorageDocument[], null> = [];
  deleted: Exclude<AzureStorageDocument[], null> = [];
  getFileTags = getFileTags;


  files(): Exclude<AzureStorageDocument[], null> {
    return this.existing ?? [];
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.existing = this.uploadedFiles ?? [];
    this.deleted = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['uploadedFiles']) {
      this.existing = this.uploadedFiles ?? [];
    }
  }

  onDelete($event: Event, _f: Exclude<AzureStorageDocument[], null>[number]) {
    this.existing = (this.existing ?? []).filter(f => f.name != _f.name);
    this.deleted.push(_f);
    this.events.next({
      existing: this.existing, deleted: this.deleted,
      deletedFile:_f
    })
  }

  fileTagList(file: AzureStorageDocument) {
    return {
      tags: Object.values(file?.tags ?? {})
    }
  }
}
