import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { MessageBoxType } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgTemplateOutlet } from '@angular/common';


export interface MessageBoxComponentEvent {
  close(): void;
}

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
    standalone: true,
    imports: [NgClass, ExtendedModule, FlexModule, NgTemplateOutlet]
})
export class MessageBoxComponent {

  @Input({required: false}) type: MessageBoxType = 'details';
  @Input({required: false}) title = '';
  @Input({required: false}) image = '';
  @Input({required: false}) url = '';
  @Output() close = new EventEmitter<MessageBoxComponentEvent>();


  constructor(private elementRef: ElementRef) {
  }

  onClickUrl() {
    window.open(this.url, "_blank");
  }

  onClose() {
    this.close.emit(this.createEventObj());
  }


  private createEventObj(): MessageBoxComponentEvent {
    return {
      close: () => {
        if (this.elementRef) {
          const element: HTMLElement = this.elementRef.nativeElement;
          if (element.parentElement) {
            element.parentElement.removeChild(element);
          }
        }
      }
    }
  }
}
