<form class="custom-google-address-component"
      [ngClass]="{error: formGroup.invalid && formControlAddress.dirty}"
      [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput
           id="{{id}}"
           #mapInput
           placeholder="Search an address ..."
           (change)="onTextChange($event)"
           [formControl]="formControlAddress">
  </mat-form-field>
</form>
