import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';;
import { MARK } from '@portal-workspace/grow-ui-library/mark';
import { delay, distinctUntilChanged, Subscription, tap } from 'rxjs';
import { isMoment, Moment } from 'moment';
import { setupUntilDestroy } from '../component-utils';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { compareMatch, DateRangeValue } from '@portal-workspace/grow-shared-library';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'daterange',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateRangeComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => DateRangeComponent) },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule]
})
export class DateRangeComponent extends AbstractControlValueAccessor<DateRangeValue> {
  subscriptions: Subscription[] = [];

  formGroup!: FormGroup<{
    startDate: FormControl<Moment | null>,
    endDate: FormControl<Moment | null>,
  }>;

  formControlStartDate!: FormControl<Moment | null>;
  formControlEndDate!: FormControl<Moment | null>;

  @Input({required: false}) mandatory = true;


  constructor(private formBuilder: FormBuilder) {
    super();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControlStartDate = this.formBuilder.control(null, this.mandatory ? [Validators.required] : []);
    this.formControlEndDate = this.formBuilder.control(null, this.mandatory ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      startDate: this.formControlStartDate,
      endDate: this.formControlEndDate,
    });
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid || (this.formGroup.errors as any)?.matDatepickerParse) {
          if (!!(this.formGroup.errors as any)?.matDatepickerParse?.text) {
            this.formGroup.setErrors(null);
          }
          const startDate = r.startDate;
          const endDate = r.endDate;
          const data: DateRangeValue = {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          };
          this.propagateChange(data);
        }
        else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);

  }

  doWriteValue(v: DateRangeValue | null | undefined): void | DateRangeValue {
    if (v && isMoment(v)) {
      this.formControlStartDate.setValue(v.startDate);
      this.formControlEndDate.setValue(v.endDate);
    } else if (v == null || v == undefined) {
      this.formControlStartDate.setValue(null);
      this.formControlEndDate.setValue(null);
    }
    return undefined;

  }
}
