<div class="mobile-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      @if (title) {
        <mat-label>{{title}} @if (mandatory == true && onlyMobile) {
          <span> *</span>
        }</mat-label>
      }
      @if (!onlyMobile) {
        <input type="text" [inputMask]="createPhoneInputMask"
          matInput [formControl]="formControl" [disableControl]="disabled"
          >
      }
      @if (onlyMobile) {
        <input type="text" [inputMask]="createMobilePhoneNumberInputMask"
          matInput [formControl]="formControl" [disableControl]="disabled"
          >
      }
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </form>
</div>
