<div class="director-component">
  <form class="director-form" [formGroup]="formGroup">
    @for (directorFormGroup of directorFormGroups(); track directorFormGroup; let index = $index) {
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Director {{index + 1}}</div>
          <div class="mat-body separator-bottom">
            Minimum 1 director required
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection
            [mark]="markObservable"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'title')">
          </title-selection>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="First Name" [mark]="markObservable" [formControl]="directorFormGroupFormControl(directorFormGroup, 'firstName')"></name>
        </div>
        <div class="col-lg-3 col-md-12">
          <name title="Last Name" [mark]="markObservable" [formControl]="directorFormGroupFormControl(directorFormGroup, 'lastName')"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <datepicker
            [mark]="markObservable"
            title="Date of birth"
            [allowOnly18YrsPlus]="true"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'dob')">
          </datepicker>
        </div>
        <div class="col-lg-3 col-md-12">
          <gender
            [mark]="markObservable"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'gender')">
          </gender>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            [mark]="markObservable"
            title="Residential address"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'residentialAddress')">
          </custom-address>
        </div>
        <div class="col-lg-3 col-md-12">
          <yes-no title="Privacy consent obtained"
            [mark]="markObservable"
            [notselected]="true"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'privacyConsentObtained')">
          </yes-no>
        </div>
      </div>
      @if (!withPropertyOwnerAddress) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no title="Property owner"
              [mark]="markObservable"
              [formControl]="directorFormGroupFormControl(directorFormGroup, 'propertyOwner')">
            </yes-no>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no title="Guarantor"
            [mark]="markObservable"
            [notselected]="true"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'guarantor')">
          </yes-no>
        </div>
      </div>
      @if (withPropertyOwnerAddress) {
        <div class="row">
          <div class="col-12">
            <property-owner-with-address
              [mark]="markObservable"
              [formControl]="directorFormGroupFormControl(directorFormGroup, 'propertyOwner')"
              [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(directorFormGroupFormControl(directorFormGroup, 'residentialAddress'))"
              [showAddressForm]="showAddressForm">
            </property-owner-with-address>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email [mark]="markObservable"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'email')">
          </email>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile
            [mark]="markObservable" [mandatory]="true"
            [onlyMobile]="true"
            [formControl]="directorFormGroupFormControl(directorFormGroup, 'mobileNumber')">
          </mobile>
        </div>
      </div>
      @if (showAssetsAndLiabilities) {
        <personal-assets
          [formControl]="directorFormGroupFormControl(directorFormGroup, 'personalAssets')"
          [mark]="markObservable"
          (events)="onPersonalAssetsEvent($event, directorFormGroup)">
        </personal-assets>
      }
      @if (showAssetsAndLiabilities) {
        <personal-liabilities
          [formControl]="directorFormGroupFormControl(directorFormGroup, 'personalLiabilities')"
          [mark]="markObservable"
          (events)="onPersonalLiabilitiesEvent($event, directorFormGroup)">
        </personal-liabilities>
      }
      <div class="row separator-bottom">
        <div class="col-12">
          <button mat-stroked-button color="warn" (click)="onDeleteDirector($event, directorFormGroup)" [disabled]="formArray.length <= 1">Delete director</button>
        </div>
      </div>
    }

    <div class="row separator-bottom">
      <div class="col-12">
        <button mat-stroked-button (click)="onAddDirector($event)">
          <span class="mdi mdi-plus" matPrefix></span>
          Add director
        </button>
      </div>
    </div>
  </form>


  <div class="row separator-bottom">
    @for (errorKey of errorKeys(formArray); track errorKey) {
      <mat-error>
        @if (formArray.touched && formArray.hasError(errorKey)) {
          <message-box type="danger">
            {{errorMessage(formArray, errorKey)}}
          </message-box>
        }
      </mat-error>
    }
  </div>



</div>
