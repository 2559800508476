import {TitleSelectionValue} from '../component/title-selection-component.domain';

export type AuthorisedSignatoryRoleType = 'director' | 'secretary' | 'director-secretary';

export type AuthorisedSignatoryRole = { type: AuthorisedSignatoryRoleType, name: string} | null;


export type AuthorisedSignatoryValue = {
  role: AuthorisedSignatoryRole,
  title: TitleSelectionValue,
  firstName: string,
  lastName: string,
  middleName: string,
  email: string,
  mobile: string,
}[] | null

export const AuthorisedSignatoryRoleOptions: NonNullable<AuthorisedSignatoryRole>[] = [
  {type: 'director', name: 'Director'},
  {type: 'director-secretary', name: 'Director / Company Secretary'},
  {type: 'secretary', name: 'Secretary'},
];
