import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import {
  AmortisationScheduleEntry,
  AmortizationChartData,
  AssetFinanceApplication,
  isPrimaryApplicantWithABN,
  isCompanyOrEntityOrg,
  PaymentChartData,
  PpsrData,
  RepaymentEstimationEntry,
  SearchGrantorsResult,
  getAssetAvgRetail,
  getAssetGoodRetail,
  AssetSettlementGetApplicationAssetFn,
  CopyApplicationDialogData,
  CopyApplicationDialogResult,
  ApplicationTypes,
  CopyApplicationFn,
  User,
  GeoLocation,
  SaveApplicationResult,
  GetGeoLocationFn,
  getAppInfoBrokerEntityName,
  getOrganisationTypeForTitle,
  AssetSelectionComponentValue,
  AssetConditionValue,
  TransactionValue,
  YesNoValue,
  CurrencyInputValue,
  LoanTermValue,
  UpdateApplicationData,
  SaveApplicationPricingDetailsData,
  booleanToYesNo,
  GetRateCardDetailsFn,
  RatecardDetails,
  TermRate,
  getAssetCategoryIndex,
  BusinessSearchValue,
  AppCalculator,
  RepaymentEstimationFormData,
  getAssetTypeIndex,
  yesNoToBoolean,
  getBrokerOriginationFee,
  getBalloonPayment,
  getPrivateSales,
  BusinessSearchResultValue,
  SearchCompanyByABNResult,
  UpdateApplicationFn, BrokerageSelectionValue, BusinessNumberSearchValue,
  getBrokerContact,
  getProductType,
  getSwitchToEasyDoc,
  getRepaymentType,
  WithdrawApplicationFn,
  getOpportunityOwnerEmail,
} from '@portal-workspace/grow-shared-library';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetVehicle,
  getAssetMake,
  getAssetType,
  getAssetYear,
  getBrokerage,
  getLVR,
  getBrokerApplicationId,
  getBrokerFlowId,
  getBrokerName,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getAmountFinanced,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType,
  getPrimaryBusinessAddress,
  getTransactionType,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getBrokerageAmount,
  getPaymentType,
  getPreviousLoan,
  getPreviousBusiness,
  getPreviousBusinessName,
  getPreviousBusinessABN,
  getPreviousBusinessACN,
  getAssetPurpose,
  getAssetPurposeDescription,
  getAssetExistingFinanceCommitment
} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  applicationToAdverseOnFile,
  applicationToAsset,
  applicationToAssetCondition, applicationToBrokerage,
  applicationToLoanTerms,
  applicationToPropertyOwner,
  applicationToTransactionValue,
  AssetSettlementGetPpsrDetailsFn,
  AssetSettlementSearchGrantorsFn,
  createTwoDecimalInputMask,

  getUser,
  PortalHotToastService
} from '@portal-workspace/grow-ui-library';
import {BrokerageSelectionComponent} from '../../brokerage-component/brokerage-selection.component';
import {MessageBoxComponent} from '../../message-box/message-box.component';
import {isInternalUser as utilIsInternalUser} from '@portal-workspace/grow-shared-library';
import {isNotAdminOrCreditOrSalesAMInternalUser as utilIsNonAdminNonCreditInternalUser} from '@portal-workspace/grow-shared-library';
import {isExternalUser as utilIsExternalUser} from '@portal-workspace/grow-shared-library';
import {getApplicationStatus, getApplicationAssetCategory, getApplicationPrimaryIndustry} from '@portal-workspace/grow-shared-library';
import {GetUsersFunc, UserSelectionComponentEvent} from '@portal-workspace/grow-ui-library';
import {finalize, skip, switchMap, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable, Subscription, forkJoin, of} from 'rxjs';
import { LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import {PercentagePipe} from '../../../pipes/percentage.pipe';
import { ReferencesDetailsComponent } from '../references-details.component';
import { PrimaryContactDetailsComponent } from '../primary-contact-details.component';
import { AssetQuoteComponent } from './asset-quote.component';
import { IndividualDetailsComponent } from '../individual-details.component';
import { RiskAnalysisComponent } from '../../risk-analysis-component/risk-analysis.component';
import { MatDividerModule } from '@angular/material/divider';
import { UserSelectionComponent } from '../../user-selection-component/user-selection.component';
import { CellComponent } from '../../application-summary-component/cell.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { StatusBoxComponent } from '../../message-box/status-box.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet, DecimalPipe, AsyncPipe, CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AssetSelectionComponent, AssetSelectionComponentSearchFn } from '../../asset-cat-and-type-component/asset-selection.component';
import { TransactionTypeSelectionComponent } from '../../transaction-type-selection-component/transaction-type-selection.component';
import { YesNoComponent } from '../../yes-no-component/yes-no.component';
import { CurrencyInputComponent } from '../../currency-selection-component/currency-input.component';
import { LoanTermsSelectionComponent } from '../../loan-terms-selection-component/loan-terms-selection.component';
import { DisableControlDirective } from '../../../directives/disable-control.directive';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import {loadingFor} from "@ngneat/loadoff";
import {EllipsisPipe} from "../../../pipes/ellipsis.pipe";

export type AssetAppComponentActionEvent = {type: 'asset-rate-quote-saved', applicationId: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-app',
    templateUrl: './asset-app.component.html',
    styleUrls: ['./asset-app.component.scss'],
    standalone: true,
    imports: [
    YesNoComponent,
    MatCardModule,
    CurrencyInputComponent,
    ReactiveFormsModule,
    MatTabsModule,
    NgTemplateOutlet,
    FlexModule,
    StatusBoxComponent,
    MatButtonModule,
    MatFormFieldModule,
    CellComponent,
    UserSelectionComponent,
    MatDividerModule,
    RiskAnalysisComponent,
    IndividualDetailsComponent,
    AssetQuoteComponent,
    PrimaryContactDetailsComponent,
    ReferencesDetailsComponent,
    DecimalPipe,
    LooseCurrencyPipe,
    PercentagePipe,
    AssetSelectionComponent,
    TransactionTypeSelectionComponent,
    LoanTermsSelectionComponent,
    DisableControlDirective,
    InputMaskModule,
    MatInputModule,
    AsyncPipe,
    MessageBoxComponent,
    BrokerageSelectionComponent,
    EllipsisPipe,
	CommonModule
]
})
export class AssetAppComponent implements OnInit {

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerFlowId = getBrokerFlowId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getAppInfoBrokerEntityName = getAppInfoBrokerEntityName;
  getOrganisationType = getOrganisationType;
  getOrganisationTypeForTitle = getOrganisationTypeForTitle;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getLVR = getLVR;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getApplicationAssetCategory = getApplicationAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetPurpose=getAssetPurpose;
  getAssetPurposeDescription=getAssetPurposeDescription;
  getAssetExistingFinanceCommitment=getAssetExistingFinanceCommitment;
  getAssetCondition = getAssetCondition;
  getAssetVehicle = getAssetVehicle;
  getInvoiceAmount = getInvoiceAmount;
  getAmountFinanced = getAmountFinanced;
  getAssetAvgRetail = getAssetAvgRetail;
  getAssetGoodRetail = getAssetGoodRetail;
  getLoanTerms = getLoanTerms;
  getTransactionType = getTransactionType;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getApplicationPrimaryIndustry = getApplicationPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getBrokerageAmount = getBrokerageAmount;
  getPaymentType = getPaymentType;
  getBalloonPayment = getBalloonPayment;
  getPreviousLoan = getPreviousLoan;
  getPreviousBusiness = getPreviousBusiness;
  getPreviousBusinessName = getPreviousBusinessName;
  getPreviousBusinessABN = getPreviousBusinessABN;
  getPreviousBusinessACN = getPreviousBusinessACN;
  getBrokerContact = getBrokerContact;
  getProductType = getProductType;
  getSwitchToEasyDoc = getSwitchToEasyDoc;
  getRepaymentType = getRepaymentType;
  getOpportunityOwnerEmail= getOpportunityOwnerEmail;
  _isPrimaryApplicantWithABN = isPrimaryApplicantWithABN;
  _isCompanyOrEntityOrg = isCompanyOrEntityOrg;
  loggedInUser: User | null = getUser();
  user!: User;
  geoLocation: GeoLocation = {};
  @Input({required: true}) application!: AssetFinanceApplication;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  // NOTE: not used @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: true}) amortizationChartData!: AmortizationChartData;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: true}) repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input({required: false}) calculationLog?: {log: string, rate?: number}[];
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input({required: false}) ip: string = '';
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) assetSearchFn!: AssetSelectionComponentSearchFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Output() navigateToApplications = new EventEmitter();

  applicationStatus: string | null = null;
  hasTrustee: boolean = false;
  showIndividuals: boolean=false;
  isCompanyOrEntityOrg: boolean = false;
  grantorDetails: any = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
  data:any;
  subscriptions: Subscription[] = [];
  constructor(private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ){
  }
  async ngOnInit(): Promise<void> {
    this.applicationStatus = getApplicationStatus(this.application);
    this.isCompanyOrEntityOrg = this._isCompanyOrEntityOrg(this.application);
    console.log("Is Company Entity:::",isCompanyOrEntityOrg(this.application))
    this.reloadPpsrDetails();
  }

  reloadPpsrDetails(){
    this.getPpsrDetailsFn(this.application.ApplicationId).pipe(
      tap(r => {
        const r1: PpsrData | null | any = r ?? null;
        //ppsr details
        this.data=r1;
        if (r1 && r1.companySummary) {
          const companySummary = r1?.companySummary
          this.grantorDetails.Account = companySummary?.Account;
          this.grantorDetails.MotorVehicleCount = companySummary?.MotorVehicleCount
          this.grantorDetails.OtherGoodsCount = companySummary?.OtherGoodsCount
          this.grantorDetails.AllPapNoExceptCount = companySummary?.AllPapNoExceptCount
          this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
        }
      })
    ).subscribe();
  }

  getSearchGrantors(element: 'ABN' | 'ACN') {
    let data: any = {}
    let organisationName: string = '';
    if (element === 'ACN') {
      data = {
        "SearchNumber": this.application.CommercialEntities[0].ACN,
        "NumberType": element,
        "SearchType": "G"
      }
      organisationName = this.application.CommercialEntities[0].LegalName
    } if (element === 'ABN') {
      data = {
        "SearchNumber": this.application.CommercialEntities[0].ABN,
        "NumberType": element,
        "SearchType": "G"
      }
      organisationName = this.application.CommercialEntities[0].LegalName
    }
    this.searchGrantorsFn(this.application.ApplicationId, {
      // this.searchGrantorsFn(this.application.ApplicationId, {
      SearchNumber: data.SearchNumber,
      NumberType: element,
      SearchType: "G"
    }).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable("Grantor search success"),
      tap(r => {
        const data: any = r
        if (data.Error) {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try after sometime',
          });
        } else {
          if (r.payload && r.status) {
            const grantorDetails = r.payload['SearchResult'].GrantorOrganisationSearchCriteriaVerificationDetail;
            if (grantorDetails === 'NotFound') {
              this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Grantor details not found' })
            } else {
              const collateralClassSummary = r.payload.SearchResult.SearchResultSummary.CollateralClassSummary.CollateralClassSearchSummaryDetail
              if (collateralClassSummary.length > 0) {
                collateralClassSummary.forEach((element: { CollateralClass: string; CollateralClassResultCount: any; }) => {
                  if (element.CollateralClass === 'OtherGoods') {
                    this.grantorDetails.OtherGoodsCount = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'Account') {
                    this.grantorDetails.Account = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'MotorVehicle') {
                    this.grantorDetails.MotorVehicleCount = element.CollateralClassResultCount
                  }
                  if (element.CollateralClass === 'AllPapNoExcept') {
                    this.grantorDetails.AllPapNoExceptCount = element.CollateralClassResultCount
                  }
                });
              } else {
                this.applicationDialogService.openInformationDialog({ message: 'Info', subMessage: `${organisationName} has no PPSR registrations` })
                this.grantorDetails = { Account: 0, MotorVehicleCount: 0, OtherGoodsCount: 0, AllPapNoExceptCount: 0, Total: 0 }
              }
              this.grantorDetails.Total = this.grantorDetails.Account + this.grantorDetails.MotorVehicleCount + this.grantorDetails.OtherGoodsCount + this.grantorDetails.AllPapNoExceptCount
            }
            this.reloadPpsrDetails();
          } else {
            this.applicationDialogService.openAlertDialog({ message: 'Alert', subMessage: 'Please check details and try again.' })
          }
        }
      })
    ).subscribe();
  }

  isInternalUser() {
    return utilIsInternalUser(getUser())
  }

  emitChangeSubmitterEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'submitter',
    });
  }

  emitChangeAdditionalCorrespondentEvent(event: UserSelectionComponentEvent) {
    this.events.emit({
      ...event,
      type: 'additional-correspondent',
    });
  }

  onClickApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.navigateToApplications.emit();
            })
          })
        }
      })
    )
  }

  onWithdrawApplication() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Withdraw Application",
        subMessage: "Are you sure you want to withdraw this application?"
      }).afterClosed().subscribe(r => {
        if (r && r.readyForSubmission) {
          this.applicationDialogService.openWithdrawApplicationDialog({})
            .afterClosed().subscribe(result => {
              if (result && result.readyForSubmission) {
                this.withdrawApplicationFn({
                  salesforceId: this.application.AppInfoSalesforceID ?? '',
                  applicationId: this.application.ApplicationId,
                  reason: result.reason,
                  comment: result.comment,
                }).pipe(
                  this.toastService.spinnerObservable()
                ).subscribe(() => {
                  this.applicationDialogService.successDialog({
                    message: 'Success',
                    subMessage: `This application has been withdrawn successfully.`
                  }).afterClosed().subscribe(() => {
                    this.navigateToApplications.emit();
                  })
                })
              }
            })
        }
      })
    )
  }
}
