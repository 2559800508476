<div class="edit-amortised-repayment-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Edit Amortised Account Repayment</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <currency-input title="Repayment"
          [min]="0"
          [max]="creditLimit"
          [formControl]="formControlRepayment">
        </currency-input>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Comment</mat-label>
          <textarea matInput rows="6" wrap="hard" maxlength="7000" [formControl]="formControlComment"></textarea>
          @for (errorKey of errorKeys(formControlComment); track errorKey) {
            <mat-error>
              @if (formControlComment.touched && formControlComment.hasError(errorKey)) {
                {{errorMessages(formControlComment, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>

    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave()"  [disabled]="formGroup.invalid">Confirm</button>
    </div>
  </mat-dialog-content>
</div>
