<div class="customer-type-component" [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="center start">
  @if (title) {
    <mat-label class="mat-caption">{{title}}</mat-label>
  }
  <mat-button-toggle-group
    [hideSingleSelectionIndicator]="true"
    [hideMultipleSelectionIndicator]="true"
    [formControl]="formControl"
    [disableControl]="disabled">
    <mat-button-toggle value="consumer">Consumer</mat-button-toggle>
    <mat-button-toggle value="commercial">Commercial</mat-button-toggle>
  </mat-button-toggle-group>
</div>
