<div class="transaction-table">
  <table mat-table [dataSource]="displayedData" multiTemplateDataRows class="width-100 mb-2" matSort (matSortChange)="onSort($event)">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
      <th mat-header-cell mat-sort-header *matHeaderCellDef [arrowPosition]="needAlignRight(column) ? 'before' : 'after'" [ngClass]="{
        'column-width-2': column === 'date' || column === 'party',
        'column-width-3': column === 'category' || column === 'otherInfo',
        'column-width-4': column === 'transaction' && !type,
        'text-align-right': needAlignRight(column)
      }">
          @if (column !== 'icon') {
            <div class="cell">{{getColumnTitles(column)}}</div>
          }
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
          <div class="cell">
            @if (needCurrencyPipe(column)) {
              @if (!element[column]) {
              }
              @if (element[column]) {
                {{element[column] | looseCurrency}}
              }
            }
            @if (!needCurrencyPipe(column)) {
              {{element[column]}}
            }
          </div>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
    </tr>
  </table>
  @if (total) {
    <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
    [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
  }
</div>
