import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import {
  CreditRuleItem,
  CreditRuleItemFunction,
  CreditRuleItemFunctionOptions,
  CreditRuleItemOutputType,
  CreditRuleItemOutputTypeOptions,
  CreditRuleItemLevel,
  CreditRuleItemLevelOptions,
  CreditRuleItemType,
  CreditRuleItemTypeOptions,
  EditCreditRuleItemDialogData,
  EditCreditRuleItemDialogResult,
  GetCreditRuleItemsFn,
  sampleApplicationObject,
  sampleIndividualObject,
  sampleEntityObject,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from "@angular/material/expansion";
import { CreditRuleComponent } from '../credit-component/credit-rule.component';
import { ApplicationDialogService } from './application-dialog.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import moment from 'moment';

@Component({
    templateUrl: './edit-credit-rule-item.dialog.html',
    styleUrls: ['./edit-credit-rule-item.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatCheckboxModule, MatSelectModule, CreditRuleComponent, MatExpansionModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, MatDividerModule, NgxJsonViewerModule]
})
export class EditCreditRuleItemDialog implements OnInit{
  creditRuleItem: CreditRuleItem | null = null;
  formControlName!: FormControl<string | null>;
  formControlDescription!: FormControl<string | null>;
  formControlType!: FormControl<CreditRuleItemType | null>;
  formControlDefaultValue!: FormControl<string | null>;
  formControlUseDefaultValue!: FormControl<boolean | null>;
  formControlFunction!: FormControl<CreditRuleItemFunction | null>;
  formControlLevel!: FormControl<CreditRuleItemLevel | null>;
  formControlOutputType!: FormControl<CreditRuleItemOutputType | null>;
  formControlValue!: FormControl<string | null>;
  formControlSampleObjectEvaluation!: FormControl<string | null>;
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  
  formGroup!: FormGroup<{
    name: FormControl<string | null>,
    description: FormControl<string | null>,
    type: FormControl<CreditRuleItemType | null>,
    useDefaultValue: FormControl<boolean | null>,
    defaultValue: FormControl<string | null>,
    itemFunction: FormControl<CreditRuleItemFunction | null>,
    level: FormControl<CreditRuleItemLevel | null>,
    outputType: FormControl<CreditRuleItemOutputType | null>,
    value: FormControl<string | null>,
  }>;

  CreditRuleItemTypeOptions = CreditRuleItemTypeOptions;
  CreditRuleItemFunctionOptions = CreditRuleItemFunctionOptions;
  CreditRuleItemLevelOptions = CreditRuleItemLevelOptions;
  CreditRuleItemOutputTypeOptions = CreditRuleItemOutputTypeOptions;
  sampleObject: Object = {};
  displayedSampleObject: Object = {};
  Moment = moment;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCreditRuleItemDialogData,
              private dialogService: ApplicationDialogService,
              private dialogRef: MatDialogRef<EditCreditRuleItemDialog, EditCreditRuleItemDialogResult>,
              private formBuilder: FormBuilder) {
    this.creditRuleItem = this.data.creditRuleItem;
    this.getCreditRuleItemsFn = this.data.getCreditRuleItemsFn;
    this.formControlName = this.formBuilder.control(null, [Validators.required]);
    this.formControlDescription = this.formBuilder.control(null, [Validators.required]);
    this.formControlType = this.formBuilder.control(null, [Validators.required]);
    this.formControlDefaultValue = this.formBuilder.control(null);
    this.formControlUseDefaultValue = this.formBuilder.control(null);
    this.formControlFunction = this.formBuilder.control(null);
    this.formControlLevel = this.formBuilder.control(null);
    this.formControlOutputType = this.formBuilder.control(null, [Validators.required]);
    this.formControlValue = this.formBuilder.control(null);
    this.formControlSampleObjectEvaluation = this.formBuilder.control(null);

    this.formGroup = this.formBuilder.group({
      name: this.formControlName,
      description: this.formControlDescription,
      type: this.formControlType,
      useDefaultValue: this.formControlUseDefaultValue,
      defaultValue: this.formControlDefaultValue,
      itemFunction: this.formControlFunction,
      level: this.formControlLevel,
      outputType: this.formControlOutputType,
      value: this.formControlValue,
    });

    if (this.creditRuleItem) {
      this.formControlName.setValue(this.creditRuleItem.name);
      this.formControlDescription.setValue(this.creditRuleItem.description);
      this.formControlType.setValue(this.creditRuleItem.type);
      this.formControlDefaultValue.setValue(this.creditRuleItem.defaultValue);
      this.formControlUseDefaultValue.setValue(this.creditRuleItem.useDefaultValue);
      this.formControlFunction.setValue(this.creditRuleItem.itemFunction);
      this.formControlLevel.setValue(this.creditRuleItem.level);
      this.formControlOutputType.setValue(this.creditRuleItem.outputType);
      this.formControlValue.setValue(this.creditRuleItem.value);

      if (this.formControlType.value === 'JSON') {
        if (this.formControlLevel.value === 'Application') {
          this.sampleObject = sampleApplicationObject;
        } else if (this.formControlLevel.value === 'Entity') {
          this.sampleObject = sampleEntityObject;
        } else if (this.formControlLevel.value === 'Individual') {
          this.sampleObject = sampleIndividualObject;
        } else {
          this.sampleObject = {};
        }
      }
      this.displayedSampleObject = this.sampleObject;
    }
  }

  ngOnInit() {
    this.formControlUseDefaultValue.valueChanges.subscribe(value => {
      this.formControlDefaultValue.clearValidators();
      if (value) {
        this.formControlDefaultValue.setValidators([Validators.required]);
      }
      this.formControlDefaultValue.updateValueAndValidity();
    })

    this.formControlType.valueChanges.subscribe(value => {
      this.formControlLevel.clearValidators();
      if (value === 'JSON') {
        this.formControlLevel.setValidators([Validators.required]);
      }
      this.formControlLevel.updateValueAndValidity();
    })

    this.formControlLevel.valueChanges.subscribe(value => {
      if (this.formControlType.value === 'JSON') {
        if (value === 'Application') {
          this.sampleObject = sampleApplicationObject;
        } else if (value === 'Entity') {
          this.sampleObject = sampleEntityObject;
        } else if (value === 'Individual') {
          this.sampleObject = sampleIndividualObject;
        } else {
          this.sampleObject = {};
        }
      } else {
        this.sampleObject = {};
      }
      this.displayedSampleObject = this.sampleObject;
    })
  }

  onCancel() {
    this.dialogRef.close({
      readyForSubmission: false,
      creditRuleItem: null
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      if (this.formControlType.value === 'Formula') {
        const value = this.formControlValue.value
        if (!value) {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: "The formula is invalid. Please double check."
          }).afterClosed().subscribe()
        } else {
          this.dialogRef.close({
            readyForSubmission: true,
            creditRuleItem: {
              name: this.formControlName.value as string,
              description: this.formControlDescription.value as string,
              type: this.formControlType.value as CreditRuleItemType,
              useDefaultValue: !!this.formControlUseDefaultValue.value as boolean,
              defaultValue: this.formControlDefaultValue.value as string,
              itemFunction: this.formControlFunction.value as CreditRuleItemFunction,
              level: this.formControlLevel.value as CreditRuleItemLevel,
              outputType: this.formControlOutputType.value as CreditRuleItemOutputType,
              value: value,
            }
          })
        }
      } else {
        this.dialogRef.close({
          readyForSubmission: true,
          creditRuleItem: {
            name: this.formControlName.value as string,
            description: this.formControlDescription.value as string,
            type: this.formControlType.value as CreditRuleItemType,
            useDefaultValue: !!this.formControlUseDefaultValue.value as boolean,
            defaultValue: this.formControlDefaultValue.value as string,
            itemFunction: this.formControlFunction.value as CreditRuleItemFunction,
            level: this.formControlLevel.value as CreditRuleItemLevel,
            outputType: this.formControlOutputType.value as CreditRuleItemOutputType,
            value: this.formControlValue.value as string,
          }
        })
      }
    }
  }

  evaluateSampleObject() {
    if (this.formControlSampleObjectEvaluation.value) {
      const object = this.sampleObject;
      const evaluation = (this.formControlSampleObjectEvaluation.value ?? '').trim();
      try {
        const Moment = this.Moment;
        this.displayedSampleObject = eval(`object${evaluation}`) ?? {};
      } catch (e) {
        console.log(e);
        this.displayedSampleObject = {};
        this.dialogService.openAlertDialog({
          message: "Error",
          subMessage: "There was an error in the evalutation."
        }).afterClosed().subscribe()
      }
    } else {
      this.displayedSampleObject = this.sampleObject;
    }
  }

  restoreSampleObject() {
    this.displayedSampleObject = this.sampleObject;
    this.formControlSampleObjectEvaluation.setValue('');
  }
}
