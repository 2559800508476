<form class="overdraft-customer-search-component">
  <mat-form-field>
    <mat-label>Overdraft Customer</mat-label>
    <input type="text"
      autoSelectOnClick
      placeholder="Search Overdraft Customer"
      matInput
      [matAutocomplete]="auto"
      [formControl]="formControl">
      <div matPrefix class="mdi mdi-magnify"></div>
      @if (state == 'loading') {
        <mat-spinner diameter="25" matSuffix></mat-spinner>
      }
      @if (state == 'selected') {
        <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix />
      }
      @if (state == 'error') {
        <img src="/assets/images/cross-danger.png" alt="chevron"  matSuffix />
      }
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
      <mat-autocomplete #auto="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayWithFn"
        (optionSelected)="onOptionSelected($event)"
        >
        @for (entry of filteredEntries; track entry) {
          <mat-option [value]="entry">
            <div>{{entry.entityName}}</div>
          </mat-option>
        }

      </mat-autocomplete>
    </mat-form-field>
  </form>
