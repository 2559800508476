import {Component, OnInit, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {ApplicationDialogService, getUser, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AddBankingTransactionFn,
  BatchPayment, DeleteBatchPaymentFn, DeleteTransactionFn,
  EditBatchPaymentFn, EditTransactionFn, GeneratePaymentFileFn,
  GetBatchPaymentsFn, GetTransactionsFn,
  NewBatchPaymentDialogResult, User
} from '@portal-workspace/grow-shared-library';
import {of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { BPayTableComponent } from './bpay-table.component';
import { DirectPaymentTableComponent } from './direct-payment-table.component';
@UntilDestroy()
@Component({
  selector: 'batch-payment-table',
  templateUrl: './batch-payment-table.component.html',
  styleUrls: ['./batch-payment-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [BPayTableComponent, DatePipe, DirectPaymentTableComponent, FlexModule, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatMenuModule, MatDividerModule, NgClass, ExtendedModule, MatTableModule, AsyncPipe]
})

export class BatchPaymentTableComponent implements OnInit {
  dataSource: BatchPayment[] = [];
  @Input({required: true}) editBatchPaymentFn!: EditBatchPaymentFn;
  @Input({required: true}) getBatchPaymentsFn!: GetBatchPaymentsFn;
  @Input({required: true}) addBankingTransactionFn!: AddBankingTransactionFn;
  @Input({required: true}) editTransactionFn!: EditTransactionFn;
  @Input({required: true}) getTransactionsFn!: GetTransactionsFn;
  @Input({required: true}) deleteTransactionFn!: DeleteTransactionFn;
  @Input({required: true}) deleteBatchPaymentFn!: DeleteBatchPaymentFn;
  @Input({required: true}) generatePaymentFileFn!: GeneratePaymentFileFn;
  @Output() showMoreDetails = new EventEmitter();

  columnsToDisplay = ['icon', 'type', 'description', 'createdTime', 'lastUpdatedTime', 'status', 'action'];
  expandedElement!: BatchPayment | null;
  subscriptions: Subscription[] = [];
  user: User | null = getUser();
  constructor(
    private applicationDialogService: ApplicationDialogService,
  ) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
  }

  reload() {
    this.subscriptions.push(
      this.getBatchPaymentsFn(this.user?.UserId as number).subscribe((r: BatchPayment[]) => {
        console.log(r)
        this.dataSource = r;
      })
    )
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'type': return 'Type';
      case 'description': return 'Description';
      case 'createdTime': return 'Created Time';
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'status': return 'Status';
      case 'action': return '';
      default: return column;
    }
  }

  edit(element: BatchPayment) {
    this.subscriptions.push(
      this.applicationDialogService.openNewBatchPaymentDialog(element).afterClosed()
        .pipe(
          switchMap((r: NewBatchPaymentDialogResult | undefined) => {
            if (r && r.readyForSubmission) {
              return this.editBatchPaymentFn(r.batchPayment)
            } else {
              return of(null)
            }
          })
        ).subscribe(() => {
          this.reload();
      })
    )
  }

  showMore(element: BatchPayment) {
    this.showMoreDetails.emit(element);
  }

  delete(element: BatchPayment) {
    this.subscriptions.push(
      this.deleteBatchPaymentFn(element).subscribe(() => {
        this.reload();
      })
    )
  }
}
