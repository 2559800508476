<div class="send-privacy-consent-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Send Privacy Consent</div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9 col-md-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <name title="Customer Name" [formControl]="formControlCustomerName"></name>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <!-- <mat-form-field fxFlex="1 1 auto">
                <input matInput type="text" [formControl]="formControlCustomerName" placeholder="Customer Name">
                @for (errorKey of errorKeys(formControlCustomerName); track errorKey) {
                  <mat-error>
                    @if (formControlCustomerName.touched && formControlCustomerName.hasError(errorKey)) {
                      {{errorMessage(formControlCustomerName, errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field> -->
              <email title="Customer Email" placeholder="Enter the customer email address…" [formControl]="formControlCustomerEmail"></email>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <email title="User Email" [formControl]="formControlDynamoneyUserEmail"></email>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Reference</mat-label>
                <textarea matInput rows="10" wrap="hard" maxlength="7000" [formControl]="formControlReference"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button mat-flat-button class="float-right w-lt-md-100" color="primary" [disabled]="!formGroup.valid" (click)="send()">
                <span class="text">Send</span>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
