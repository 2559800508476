import { Component, Input } from "@angular/core";
import {ConsumerFinanceSummary, TitleSelectionValue} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo, displaybleDate, notNullUndefined} from '@portal-workspace/grow-shared-library';
import { ReferencesSummaryComponent } from "./references-summary.component";
import { MatDividerModule } from "@angular/material/divider";
import { CellComponent } from "./cell.component";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

import { EllipsisPipe } from "../../pipes/ellipsis.pipe";


@Component({
    selector: 'consumer-finance-summary',
    templateUrl: './consumer-finance-summary.component.html',
    styleUrls: ['./consumer-finance-summary.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, CellComponent, MatDividerModule, ReferencesSummaryComponent, EllipsisPipe]
})
export class ConsumerFinanceSummaryComponent {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) summary!: ConsumerFinanceSummary;

}
