@if (!((store.value$ | async)?.error)) {
  <div class="unverified-users-page container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Unverified Users</div>
        <div class="mat-caption">
          This shows users that are
          <ul>
            <li> Approved and Not verified (email or mobile) </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" [formControl]="formControlSearch" placeholder="Search unverified user...">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <button mat-flat-button class="float-right w-lt-md-100" color="primary" [disabled]="this.selection.selected.length == 0 || (loader.saving.inProgress$ | async)" (click)="sendAll()">
          <span class="mdi mdi-card-account-details-outline icon pr-2"></span>
          <span class="text">  {{(loader.saving.inProgress$ | async) ? "Sending Verification Notice...":"Send Verification Notice"}}</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="UserId">User ID</th>
              <td (click)="onRowClicked($event, element)" mat-cell *matCellDef="let element">{{element.UserId}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Name</th>
              <td (click)="onRowClicked($event, element)" mat-cell *matCellDef="let element">{{element.Name}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Email">Email</th>
              <td mat-cell *matCellDef="let element"> <mat-slide-toggle [disabled]="canAccess" color="primary" class="mr-3" (change)="onchangeEmail($event , element)" [checked]="element.verified == 1 ? true : false"></mat-slide-toggle>{{element.Email}}
              <br> @if (element.verified == 1) {
                <div class="emailMessage">{{emailMessage}}</div>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="mobileNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="MobileNumber">Mobile Number</th>
            <td mat-cell *matCellDef="let element"> <mat-slide-toggle [disabled]="canAccess" color="primary" class="mr-2" (change)="onchangePhone($event , element)" [checked]="element.mobile_verified == 1 ? true : false"></mat-slide-toggle> {{element.MobileNumber}}
            <br> @if (element.mobile_verified == 1) {
              <div class="emailMessage">{{emailMessage}}</div>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <span class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline"
              (click)="toggleAllSelection()"
            ></span>
          </th>
          <td mat-cell *matCellDef="let element" style="padding-right: 0!important;">
            <mat-checkbox
              color="primary"
              (change)="onSelectionChanged($event, element)"
              [checked]="selection.isSelected(element)"
            ></mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>
      @if (total) {
        <app-custom-paginator
          [length]="total"
          [pageIndex]="offset"
          [pageSize]="limit"
          [pageSizeOptions]="[10, 20, 25, 50, 100]"
          (page)="onPagination($event)"
        ></app-custom-paginator>
      }
    </div>
  </div>
</div>
</div>
}
