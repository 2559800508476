import {Pipe, PipeTransform} from "@angular/core";
import {DecimalPipe} from "@angular/common";


@Pipe({
  name: 'percentage',
  pure: true,
  standalone: true,
})
export class PercentagePipe extends DecimalPipe implements PipeTransform {

  override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): any  {
      let val = super.transform(value, digitsInfo, locale);
      return val ? `${val}%` : '';
  }

}
