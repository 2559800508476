<div class="upload-file-multi-tags">
  <mat-dialog-content class= 'max-height-100-vh'>
    <div class="row">
      <div class="col">
        <upload-file
          title="{{title}}"
          [maxFilesAllowed]="maxFileUpload"
          [defaultTags]="defaultTags"
          [allTags]="allTags"
          [subMessage]="''"
          [isShowEditButton]="true"
          [allowMultiple]="true"
          [enableCustomTag]="enableCustomTag"
          [enableNonStandardCondition]="enableNonStandardCondition"
          (selectedFileTags)="onSelectedFileTags($event)"
          (events)="onSelectOrDeleteFile($event)"></upload-file>
      </div>
    </div>
    
    @if (isDialog) {
      <div class="row separator-top">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
          <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit($event)">Save Documents</button>
        </div>
      </div>
    }
  </mat-dialog-content>
</div>
