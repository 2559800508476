<div class="personal-assets-component">
  <form class="personal-assets-form">

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Personal assets </div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-12">
        <button mat-stroked-button (click)="onAddAsset($event)">
          <span class="mdi mdi-plus" matPrefix></span>
          Add asset
        </button>
      </div>
    </div>

    @if (formArray.length) {
      <!-- have assets -->
      @for (assetFormGroup of assetFormGroups(); track assetFormGroup) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Type</mat-label>
              <mat-select [errorStateMatcher]="errorStateMatcher" [formControl]="assetFormGroupFormControl(assetFormGroup, 'assetType')">
                @for (type of assetTypes; track type) {
                  <mat-option [value]="type">{{type.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (isPropertyAsset(assetFormGroup)) {
            <div class="col-lg-4 col-md-12">
              <custom-address
                [mark]="markObservable"
                title="Address"
                [formControl]="assetFormGroupFormControl(assetFormGroup, 'address')">
              </custom-address>
            </div>
          }
          @if (!isPropertyAsset(assetFormGroup)) {
            <div class="col-lg-4 col-md-12">
              <mat-form-field>
                <mat-label>Description</mat-label>
                <input matInput [errorStateMatcher]="errorStateMatcher" [formControl]="assetFormGroupFormControl(assetFormGroup, 'description')">
              </mat-form-field>
            </div>
          }
          <div class="col-lg-3 col-md-12">
            <currency-input
              [mark]="markObservable"
              [formControl]="assetFormGroupFormControl(assetFormGroup, 'value')"
              title="Value">
            </currency-input>
          </div>
          <div class="col-lg-2 col-md-12">
            <button fxFlex="0 0 auto" mat-icon-button (click)="onRemoveAsset($event, assetFormGroup)">
              <span class="mdi mdi-close" ></span>
            </button>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12"></div>
        <div class="col-lg-6 col-md-12"></div>
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Total assets</mat-label>
            <span matPrefix class="mdi mdi-currency-usd"></span>
            <input type="text" class="ml-4" [inputMask]="createCurrencyMask" readonly matInput [formControl]="formControlTotalAssets">
          </mat-form-field>
        </div>
      </div>
    }


  </form>
</div>
