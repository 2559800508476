import { Component, OnInit, Output, EventEmitter, Input, inject } from '@angular/core';
import {
  AccessLevel, ApplicationSubmissionType,
  ApplicationTypes,
  isInternalUser,
  PortalLoginUser,
} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, getUser} from '@portal-workspace/grow-ui-library';
import { NgClass } from '@angular/common';
import { ApplicationTypeAmountComponent } from '../application-type-amount/application-type-amount.component';
import { ApplicationTypeIconComponent } from '../application-type-icon/application-type-icon.component';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'application-tiles',
    templateUrl: './application-tiles.component.html',
    styleUrls: ['./application-tiles.component.scss'],
    standalone: true,
    imports: [MatCardModule, ApplicationTypeIconComponent, ApplicationTypeAmountComponent, NgClass]
})
export class ApplicationTilesComponent implements OnInit {
  user: PortalLoginUser | null = null;
  accessLevel: AccessLevel | undefined;
  isDealer: boolean | undefined = false;
  @Input({required: true}) enableTiles! : ApplicationSubmissionType;
  @Output() clickedUrl = new EventEmitter<ApplicationTypes>();

  applicationDialogService: ApplicationDialogService;

  constructor() {
    this.applicationDialogService = inject(ApplicationDialogService);
  }

  ngOnInit(): void {
    this.user = getUser();
    this.accessLevel = this.user?.AccessLevel;
    this.isDealer = this.user?.priviledges?.includes('dealer');
  }

  onClickEmitEvent(applicationType: ApplicationTypes) {
    if (this.enableTiles[applicationType] === 'internal' && !isInternalUser(this.user)) {
      this.applicationDialogService.openInformationDialog({
        message: `Internal Access`,
        subMessage: this.enableTiles.internalAccessMessage,
      });
      return;
    }
    this.clickedUrl.emit(applicationType);
  }
}

