<div class="disbursement-amount-dialog">
    <mat-dialog-content>
      <h3 class="header">Publish Credit Flow</h3>
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <mat-label class="label-font">We found some credit flows with conflicted product types. Please see below details.</mat-label>
        </div>
      </div>
      <div class="col-12">
        <table mat-table [dataSource]="conflictedFlows" class="mat-elevation-z8">
          <ng-container matColumnDef="conflictingId">
            <th mat-header-cell *matHeaderCellDef> {{ getColumnTitles('conflictingId') }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.conflictingId }} </td>
          </ng-container>
          <ng-container matColumnDef="conflictingFlowName">
            <th mat-header-cell *matHeaderCellDef> {{ getColumnTitles('conflictingFlowName') }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.conflictingFlowName }} </td>
          </ng-container>
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef> {{ getColumnTitles('product') }} </th>
            <td mat-cell *matCellDef="let element"> 
                @for(item of (element.product); track item){
                  <application-type-icon [applicationType]="item" class="mr"></application-type-icon>
                }
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-12"  style="margin-bottom: 10px;">
            <mat-label class="label-font"> Do you want to overwrite?</mat-label>
        </div>
        <div class="col-12">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
            <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
            <button color="primary" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>