<div class="LG-rebate-calcualtor-page">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-md-8 mt-3">
          <span class="mdi mdi-information-outline mr-1"></span>
          <span class="app-font">
          Complete form to start application</span>
        </div>
      </div>
    </div>
    <div class="col mr-3">
      <div class="row" fxLayoutAlign="end center">
        <data-box title="Monthly Repayments" type="green" icon="repeat-variant" data="{{repayment | looseCurrency}}"></data-box>
      </div>
    </div>
  </div>
  <div class="form-container mt-3">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <h3> Appliation Details</h3>
          </div>
        </div>
        <form [formGroup]="formGroup">
          <div class="row mt-4">
            <div class="col-3">
              <currency-input title="Invoice amount"  [mark]="markTriggerSubject(formGroup)"
              [formControl]="formControlInvoiceAmount"></currency-input>
            </div>
            <div class="col-3">
              <loan-terms-selection  [formControl]="formControlLoanTerms" [mark]="markTriggerSubject(formGroup)">
              </loan-terms-selection>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-3">
              <mat-form-field>
                <mat-label>Application type</mat-label>
                <mat-select placeholder="Select type..." [formControl]="formControlApplicationType">
                  @for (appType of appType; track appType) {
                    <mat-option [value]="appType">
                      {{appType.viewValue}}
                    </mat-option>
                  }
                </mat-select>
                @if (formControlApplicationType.touched && formControlApplicationType.hasError('required')) {
                  <mat-error>This field is required.</mat-error>
                }
              </mat-form-field>
            </div>
            <div class="col-3">
              <asset-type  [categoryTypes]="assetCatTypes" [formControl]="formControlAssetType" [mark]="markTriggerSubject(formGroup)"></asset-type>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

  @if (submitted) {
    <div class="form-container">
      <mat-card appearance="outlined" class="mt-1">
        <mat-card-content>
          <div class="row">
            <div class="col">
              <h3>LG and Dealer support contribution</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <table class="mat-table width-50">
                <tbody>
                  <tr class="mat-row cursor-pointer ">
                    <td class="mat-cell pb-3 pt-3 pl-3">Customer Rate</td>
                    <td class="mat-cell text-right pr-3"><b>{{customerRate.toFixed(2)}}%</b></td>
                  </tr>
                  <tr class="mat-row cursor-pointer">
                    <td class="mat-cell pb-3 pt-3 pl-3">Total Rebate</td>
                    <td class="mat-cell text-right pr-3"><b>{{totalRebate.toFixed(2)| currency}}</b></td>
                  </tr>
                  <tr class="mat-row cursor-pointer">
                    <td class="mat-cell pb-3 pt-3 pl-3">LG Rebate Share</td>
                    <td class="mat-cell text-right pr-3"><b>{{lgRebateShare.toFixed(2)| currency}}</b></td>
                  </tr>
                  <tr class="mat-row cursor-pointer">
                    <td class="mat-cell pb-2 pt-3 pl-3">Dealer Rebate Share</td>
                    <td class="mat-cell text-right pr-3"><b>{{dealerRebateRate| currency}}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }

  <div class="row mt-4">
    <div class="col">
      <button mat-stroked-button="" color="primary"
        class="mat-focus-indicator mat-stroked-button mat-button-base mat-primary" class="button" (click)="resetQuote()"> Reset
      </button>
    </div>
    <div class="col" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" class="button"  (click)="onEstimate($event)" [disabled]="loader"> {{(loader) ? 'Estimating...' : 'Estimate' }} </button>
    </div>
  </div>
</div>
