import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'ppsr-status-chip',
    templateUrl: './ppsr-status-chip.component.html',
    styleUrls: ['./ppsr-status-chip.component.scss'],
    standalone: true
})

export class PpsrStatusChipComponent implements OnInit {

  @Input({required: true}) status!: string;

  toClass() {
    return this.status ? _.toLower(this.status.replace(/\s/g, '')) : '';
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
