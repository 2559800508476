import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {
    Enable2FADialogData, Enable2FADialogResult,
    User
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { tap } from 'rxjs';
import { getUser } from '../component-utils';
import { ApplicationDialogService } from './application-dialog.service';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy({ arrayName: 'subscription' })
@Component({
    templateUrl: './enable-2fa.dialog.html',
    styleUrls: ['./enable-2fa.dialog.scss'],
    standalone: true,
  imports: [QRCodeModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatButtonModule, FlexModule, MatDialogModule,MatCardModule]
})
export class Enable2FADialog implements OnInit {
  user: User | null = null;
  public verificationCode: string = "";
  formGroup!: FormGroup<{
    digits: FormControl<number | null>
  }>;
  formControlDigit!: FormControl;
  hide = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Enable2FADialogData,
    private matDialogRef: MatDialogRef<Enable2FADialog, Enable2FADialogResult>,
    private fb: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService) {
    this.formControlDigit = fb.control(null, Validators.required);
    this.formGroup = fb.group({
      digits: this.formControlDigit
    })
  }

  ngOnInit(): void {
    this.user = getUser()
    this.data.get2FaVerificationCodeFn().pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        if(r){
        this.verificationCode = r;
        }
      })
    ).subscribe();
  }

  onCancel() {
    this.matDialogRef.close();
    const email = this.user?.Email;
    if (email) {
        this.data.enableDisable2faFn(email, 0).pipe(
            tap()
        ).subscribe();
    }
  }

  onSubmit() {
    this.data.verify2faGeneratedTokenCodeFn(this.formControlDigit.value).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        if (r.status === true) {
          this.applicationDialogService
              .open2FAActivatedDialog({
                  enableDisable2faFn: this.data.enableDisable2faFn,
              })
              .afterClosed().pipe(
                    tap()
              ).subscribe();
          this.matDialogRef.close({});
        } else {
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: `Please check your 6 digit code.`
          });
        }
      })
    ).subscribe();
  }

}
