<div class="repayment-estimation-component">
  <table mat-table
         [dataSource]="dataSource">
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Amount finance</th>
      <td mat-cell *matCellDef="let element">{{element.amountFinance | looseCurrency}}</td>
    </ng-container>
    <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" class="text-center">Number of months</th>
    </ng-container>
    <ng-container matColumnDef="month-24">
      <th mat-header-cell *matHeaderCellDef class="pl-0">24</th>
      <td mat-cell *matCellDef="let element">{{element.month24 | looseCurrency}}</td>
    </ng-container>
    <ng-container matColumnDef="month-36">
      <th mat-header-cell *matHeaderCellDef>36</th>
      <td mat-cell *matCellDef="let element">{{element.month36 | looseCurrency}}</td>
    </ng-container>
    <ng-container matColumnDef="month-48">
      <th mat-header-cell *matHeaderCellDef>48</th>
      <td mat-cell *matCellDef="let element">{{element.month48 | looseCurrency}}</td>
    </ng-container>
    <ng-container matColumnDef="month-60">
      <th mat-header-cell *matHeaderCellDef>60</th>
      <td mat-cell *matCellDef="let element">{{element.month60 | looseCurrency}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['amount', 'month']"></tr>
    <tr mat-header-row *matHeaderRowDef="['month-24', 'month-36', 'month-48', 'month-60']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['amount', 'month-24', 'month-36', 'month-48', 'month-60']"></tr>
  </table>

</div>
