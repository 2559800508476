<div class="select-or-new-asset-supplier-compoennt">
  <div class="row">
    <div class="col">
      <div class="mat-h3">Select or add a new supplier</div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <asset-supplier-select
        [mark]="markObservable"
      title="Asset supplier" [getAssetSupplierFn]="getAssetSupplierFn" [formControl]="formControlAssetSupplier"></asset-supplier-select>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-checkbox color="primary" [formControl]="formControlUseNewSupplier">New supplier</mat-checkbox>
    </div>
  </div>
  @if (isNewSupplier) {
    <div>
      <div class="row separator">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <new-supplier
            [getInstitutionNameFn]="getInstitutionNameFn"
            [mark]="markObservable"
            [formControl]="formControlNewAssetSupplier"></new-supplier>
        </div>
      </div>
    </div>
  }
</div>
