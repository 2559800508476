<div class="redbook-details-component">
  <mat-accordion>
    <mat-expansion-panel [expanded]="expand">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span style="font-weight: bold;">Redbook Vehicle Details</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      @if (vehicle?.avgretail) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Average Retail</div>
          <div>{{vehicle?.avgretail | looseCurrency}}</div>
        </div>
      }
      @if (vehicle?.avgwhs) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Average Whs</div>
          <div>{{vehicle?.avgwhs}}</div>
        </div>
      }
      @if (vehicle?.bodystyle) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Body Style</div>
          <div>{{vehicle?.bodystyle}}</div>
        </div>
      }
      @if (vehicle?.description) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Description</div>
          <div>{{vehicle?.description}}</div>
        </div>
      }
      @if (vehicle?.familycode) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Family Code</div>
          <div>{{vehicle?.familycode}}</div>
        </div>
      }
      @if (vehicle?.goodretail) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Good Retail</div>
          <div>{{vehicle?.goodretail | looseCurrency}}</div>
        </div>
      }
      @if (vehicle?.goodwhs) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Good Whs</div>
          <div>{{vehicle?.goodwhs}}</div>
        </div>
      }
      @if (vehicle?.importflag) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Import Flag</div>
          <div>{{vehicle?.importflag}}</div>
        </div>
      }
      @if (vehicle?.makecode) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Make Code</div>
          <div>{{vehicle?.makecode}}</div>
        </div>
      }
      @if (vehicle?.month) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Month</div>
          <div>{{vehicle?.month}}</div>
        </div>
      }
      @if (vehicle?.newprice) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">New Price</div>
          <div>{{vehicle?.newprice | looseCurrency}}</div>
        </div>
      }
      @if (vehicle?.rbc) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Rbc</div>
          <div>{{vehicle?.rbc}}</div>
        </div>
      }
      @if (vehicle?.rbid) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Rbid</div>
          <div>{{vehicle?.rbid}}</div>
        </div>
      }
      @if (vehicle?.vehicletype) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Vehicle Type</div>
          <div>{{vehicle?.vehicletype}}</div>
        </div>
      }
      @if (vehicle?.year) {
        <div fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="vehicle-details detail-spacing"
          >
          <div class="title">Year</div>
          <div>{{vehicle?.year}}</div>
        </div>
      }
    </mat-expansion-panel>
  </mat-accordion>
</div>
