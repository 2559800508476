import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {inject, Injectable} from '@angular/core';
import {getUser} from '@portal-workspace/grow-ui-library';
import {AuthService} from '../service/auth.service';



export const alreadyLoginGuard = () => {
  return async (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const user = getUser();
    if (user) {
      // NOTE: WEB-2716 make login simpler
      // const lastVisitedUrl = getLastVisitedUrl();
      // const email = user.Email;
      // if (lastVisitedUrl && email === getLastVisitedEmail()) {
      //   const urlTree: UrlTree = this.router.parseUrl(lastVisitedUrl);
      //   if (urlTree) {
      //     return await this.router.navigateByUrl(urlTree);
      //   }
      // }
      // return await this.router.navigateByUrl(navigationUrlForNewApplication());
      await authService.redirectUser(router, user);
    }
    return true;
  }
}


// NOTE: Depreacated since Angular 16
// @Injectable()
// export class AlreadyLoginGuard  {
//
//   constructor(private router: Router, private authService: AuthService) {
//   }
//
//   async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
//     const user = getUser();
//     if (user) {
//       // NOTE: WEB-2716 make login simpler
//       // const lastVisitedUrl = getLastVisitedUrl();
//       // const email = user.Email;
//       // if (lastVisitedUrl && email === getLastVisitedEmail()) {
//       //   const urlTree: UrlTree = this.router.parseUrl(lastVisitedUrl);
//       //   if (urlTree) {
//       //     return await this.router.navigateByUrl(urlTree);
//       //   }
//       // }
//       // return await this.router.navigateByUrl(navigationUrlForNewApplication());
//       await this.authService.redirectUser(this.router, user);
//     }
//     return true;
//   }
//
// }
