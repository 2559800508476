import { AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { BpayComponentValue, BpayValidationData, BusinessNumberSearchResult, BusinessNumberSearchValue, LocalBankAccountDetailValue, DirectCreditInstitutionSearchResult, GetBillerNameFn, GetInstitutionNameFn, NotNullable, compareMatch } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Mark, MARK } from '@portal-workspace/grow-ui-library/mark';
import {
  createAccountNumberInputMask,
  createBillerCodeMask,
  createBpayCRNMask,
  createBsbInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  noSpaceValidator,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { Route, Router } from '@angular/router';

import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';



@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'local-bank-account-detail',
    templateUrl: './local-bank-account-detail.component.html',
    styleUrls: ['./local-bank-account-detail.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocalBankAccountDetailComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => LocalBankAccountDetailComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule]
})
export class LocalBankAccountDetailComponent extends AbstractControlValueAccessor<LocalBankAccountDetailValue> implements OnInit, Mark, AfterContentChecked {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  institutionBranchName?: string;

  createBsbInputMask = createBsbInputMask();
  createAccountNumberInputMask = createAccountNumberInputMask();

  @Input({required: false}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: false}) hideInstitutionName = false;

  subscriptions: Subscription[] = [];
  path!: string
  formControlInstitutionName!: FormControl<string | null>
  formControlAccountName!: FormControl<string | null>
  formControlBSB!: FormControl<string | null>
  formControlAccountNumber!: FormControl<string | null>

  formGroup!: FormGroup<{
    InstitutionName: FormControl<string | null>,
    AccountName: FormControl<string | null>,
    BSB: FormControl<string | null>,
    AccountNumber: FormControl<string | null>
  }>;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private router: Router,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlInstitutionName = this.formBuilder.control(null, this.hideInstitutionName ? [] : [Validators.required, Validators.maxLength(50),noSpaceValidator()]);
    this.formControlAccountName = this.formBuilder.control(null, [Validators.required, Validators.maxLength(50),noSpaceValidator()]);
    this.formControlBSB = this.formBuilder.control(null, [Validators.required])
    this.formControlAccountNumber = this.formBuilder.control(null, [Validators.required, Validators.maxLength(10)]);
    this.formGroup = this.formBuilder.group({
      InstitutionName: this.formControlInstitutionName,
      AccountName: this.formControlAccountName,
      BSB: this.formControlBSB,
      AccountNumber: this.formControlAccountNumber
    })

    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.formGroup.valid) {
          this.propagateChange(v)
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe())
this.path = this.router.url;
if(this.path !== '/f/suppliers' ){
    this.subscriptions.push(this.formControlBSB.valueChanges.pipe(
      tap(r => {
        if (r && r.length == 6) {
          this.applicationService.getInstitutionNameFn(r).pipe(
            tap(r => {
              if (r) {
                console.log("r--name----------",r)
                this.formControlInstitutionName.setValue(r.InstitutionName ?? r.BankNameCode ?? null)
                this.institutionBranchName = r.BSBName;
              }
            })
          ).subscribe()
        }
      })
    ).subscribe())
}
  }


  doWriteValue(v: LocalBankAccountDetailValue | null | undefined): void | LocalBankAccountDetailValue {
    if (v) {
      console.log("v---------------------------",v)
      this.formControlInstitutionName.setValue(v.InstitutionName ? v.InstitutionName.trim() : '')
      this.formControlAccountName.setValue(v.AccountName)
      this.formControlBSB.setValue(v.BSB)
      this.formControlAccountNumber.setValue(v.AccountNumber ?? null)
    } else {
      this.propagateChange(null);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
