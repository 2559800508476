<div class="first-overdraft-user-dialog">
  <mat-dialog-content class= 'max-height-100-vh'>
    <application-stepper2 [linear]="false"
      [showHeader]="false"
      #applicationStepper="applicationStepper2">
      <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
      <cdk-step [stepControl]="formGroupStep2"><ng-content *ngTemplateOutlet="step2"></ng-content></cdk-step>
      <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    </application-stepper2>
  </mat-dialog-content>
</div>

<!--
##########################################
#  STEP 1: Select email
##########################################
-->

<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Create New User</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-4">
          Please follow the steps to create the new user:
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <!-- WEB-4399: email [formControl]="formControlOverdraftUserEmail" [validators]="emailValidators" [asyncValidators]="emailAsyncValidators" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></email-->
          <email [formControl]="formControlOverdraftUserEmail" [validators]="emailValidators" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></email>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2: Add User details
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2">


      @if (overdraftUserSelections.length) {
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>User</mat-label>
              <mat-select placeholder="Select individual..." [formControl]="formControlOverdraftUserSelection">
                @for (option of overdraftUserSelections; track option) {
                  <mat-option [value]="option">{{option.GivenName}} {{option.SurName}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      }

      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Pismo Account Number</mat-label>
            <mat-select placeholder="Select Pismo Account Number ..." [formControl]="formControlPismoAccountNumber">
              @for (pismoAccountNumber of pismoAccountNumbers; track pismoAccountNumber) {
                <mat-option [value]="pismoAccountNumber">{{pismoAccountNumber}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-6">
          <customer-access-level-and-role-access [formControl]="formControlAccessLevel" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></customer-access-level-and-role-access>
        </div>
      </div>

      <!-- NOTE:
      maxLength=23 as in backend we are taking initial of firstName and
      full lastName to create printedName if firstname + lastname > 25.
      printedName should be <=25 and space is also counted.
      -->
      <div class="row pb-2">
        <div class="col-3">
          <name title="First Name" [formControl]="formControlOverdraftUserFirstName" [maxLength]="23" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></name>
        </div>
        <div class="col-3">
          <name title="Last Name" [formControl]="formControlOverdraftUserLastName" [maxLength]="23" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <name title="Middle Name" [mandatory]="false" [formControl]="formControlOverdraftUserMiddleName"></name>
        </div>
        <div class="col-3">
          <datepicker title="Date of birth" [allowOnly18YrsPlus]="true"  [formControl]="formControlOverdraftUserDob" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></datepicker>
        </div>
        <!-- <div class="col-3">
        <email [formControl]="formControlOverdraftUserEmail" [validators]="emailValidators"  [mark]="applicationStepper.markTriggerSubject(formGroup)"></email>
      </div> -->
    </div>
    <div class="row">
      <div class="col-6">
        <mobile [formControl]="formControlOverdraftUserMobile" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></mobile>
      </div>
      <!-- <div class="col-3">
      <datepicker title="Date of birth" [allowOnly18YrsPlus]="true"  [formControl]="formControlOverdraftUserDob" [mark]="applicationStepper.markTriggerSubject(formGroup)"></datepicker>
    </div> -->
  </div>
  <div class="row">
    <div class="col-6">
      <custom-address title="Residential address" [formControl]="formControlOverdraftUserAddress" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></custom-address>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <driver-licence title="Driver Licence Number" [mandatory]="false" [formControl]="formControlOverdraftUserDriverLicenceNumber" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></driver-licence>
    </div>
    <div class="col-3">
      <datepicker title="Driver Licence Expiry Date" [optional]="true" [allowOnlyTodayOrFutureDate]="true" [formControl]="formControlOverdraftUserDriverLicenceExpiryDate"></datepicker>
    </div>
    <div class="col-3">
      <driver-licence-card-number title="Driver Licence Card Number" [mandatory]="false" [formControl]="formControlOverdraftUserDriverLicenceCardNumber" [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"></driver-licence-card-number>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-9">
      <div class="mb-2">Kyc Verification</div>
      @if (!digitalIdResult) {
        <div>
          <message-box type="info">No verification results.</message-box>
        </div>
      }
      @if (digitalIdResult && digitalIdResult.status === 'RECEIVED_ERROR') {
        <div >
          <message-box type="danger">Verification cannot be performed for this individual, please contact support for details.</message-box>
        </div>
      }
      @if (digitalIdResult && digitalIdResult.status === 'RECEIVED_COMPLETE') {
        <div>
          <message-box fxFlex="grow" type="info">Digital ID has been verified successfully</message-box>
        </div>
      }
      @if (digitalIdResult && digitalIdResult.status === 'RECEIVED_INCOMPLETE') {
        <div>
          <message-box fxFlex="grow" type="warn">Digital ID verification incomplete</message-box>
        </div>
      }
    </div>
  </div>
</form>
</div>
</ng-template>

<!--
##########################################
#  STEP 3: Account Create Success
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="success-icon">
            <span class="mdi mdi-file-sign"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="mat-subtitle-1">New user is created successfully.</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">        
          @if (createOverdraftUserResponse && createOverdraftUserResponse.existingUser) {
            <div class="mat-subtitle-2">Please use your existing credential of your email address to login.</div>
          }
          @else {
            <div class="mat-subtitle-2">The user will receive an email with the login credentials shortly.</div>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

