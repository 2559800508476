import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AssetDetailsComponent } from './asset-details.component';
import { AssetNotesComponent } from './asset-notes.component';
import { NotesComponent } from './notes.component';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { CurrencySelectionModule } from '../currency-selection-component/currency-selection.module';
import { PpsrStatusModule } from '../ppsr-status-component/ppsr-status-chip.module'
import { MessageBoxModule } from '../message-box/message-box.module'
import { AuditLogComponent } from './audit-log.component';
import { AddAssetComponent } from './add-asset.component';
import { PrivateSellerComponent } from './private-seller.component';
import { AddAssetDetailsComponent } from './add-asset-details.component';
import { AssetCatAndTypeModule } from '../asset-cat-and-type-component/asset-cat-and-type.module';
import { NameModule } from '../name-component/name.module';
import { BsbModule } from '../bsb-component/bsb.module';
import { AddressModule } from '../address-component/address.module';
import { ApplicationSummaryModule } from '../application-summary-component/application-summary.module';
import { CustomPaginatorModule } from '../custom-paginator-component/custom-paginator.module';
 
import { CollapsableExpandableTextModule } from '../collapsable-expandable-component/collapsable-expandable-text.module';
import { AssetPpsrDetailsComponent } from './asset-ppsr-details.component';
import { UploadFileModule } from '../upload-file-component/upload-file.module';
import { DisbursementTableComponent } from './disbursement-table.component';
import { YesNoModule } from '../yes-no-component/yes-no.module';
import { BusinessSearchModule } from '../business-search-component/business-search.module'
import {Supplier2Component} from './supplier2.component';
import {BusinessDisbursementTableComponent} from './business-disbursement-table.component';
import { LocalBankAccountDetailModule } from '../local-bank-account-detail-component/local-bank-account-detail.module';
import { CustomAngularEditorModule } from '../custom-angular-editor-component/custom-angular-editor.module'

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialLibraryModule,
  DirectiveLibraryModule,
  PipeLibraryModule,
  PpsrStatusModule,
  MessageBoxModule,
  AssetCatAndTypeModule,
  CurrencySelectionModule,
  NameModule,
  BsbModule,
  AddressModule,
  InputMaskModule,
  ApplicationSummaryModule,
  CustomPaginatorModule,
   
  CollapsableExpandableTextModule,
  UploadFileModule,
  YesNoModule,
  BusinessSearchModule,
  LocalBankAccountDetailModule,
  CustomAngularEditorModule
];

@NgModule({
    imports: [
        ...modules,
        AssetDetailsComponent,
        AssetNotesComponent,
        NotesComponent,
        AuditLogComponent,
        AddAssetComponent,
        PrivateSellerComponent,
        AddAssetDetailsComponent,
        AssetPpsrDetailsComponent,
        DisbursementTableComponent,
        Supplier2Component,
        BusinessDisbursementTableComponent
    ],
    exports: [
        ...modules,
        AssetDetailsComponent,
        AssetNotesComponent,
        NotesComponent,
        AuditLogComponent,
        AddAssetComponent,
        PrivateSellerComponent,
        AddAssetDetailsComponent,
        AssetPpsrDetailsComponent,
        DisbursementTableComponent,
        Supplier2Component,
        BusinessDisbursementTableComponent,
    ]
})
export class AssetListingModule {

}
