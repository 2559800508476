import {Component, forwardRef, inject, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {
  createNoDecimalInputMask,
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  Mark
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import numeral from 'numeral';
import {AssetTier3Value, compareMatch} from '@portal-workspace/grow-shared-library';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';




@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'asset-tier3',
  templateUrl: './asset-tier3.component.html',
  styleUrls: ['./asset-tier3.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetTier3Component), multi: true },
    { provide: MARK, useExisting: forwardRef(() => AssetTier3Component), multi: false },
  ],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, FlexModule, MatDividerModule]
})
export class AssetTier3Component extends AbstractControlValueAccessor<AssetTier3Value> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: false}) title: string = '';
  @Input({required: false}) maxYear: number = 15;

  subscriptions: Subscription[] = [];

  private formBuilder: FormBuilder;

  createNoDecimalInputMask = createNoDecimalInputMask();
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formGroup: FormGroup<{
    newAssetBacked: FormControl<number | null>,
    usedAssetBacked: FormControl<number | null>,
  }>;
  formControlNewAssetBacked: FormControl<number | null>;
  formControlUsedAssetBacked: FormControl<number | null>;

  constructor() {
    super();
    this.formBuilder = inject(FormBuilder);
    this.formControlNewAssetBacked = this.formBuilder.control(0, [Validators.required]);
    this.formControlUsedAssetBacked = this.formBuilder.control(0, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      newAssetBacked: this.formControlNewAssetBacked,
      usedAssetBacked: this.formControlUsedAssetBacked,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r  => {
        if (this.formGroup.valid) {
          // NOTE: when formGroup is valid, Rate should not be null due to validators configured
          const v: Exclude<AssetTier3Value, null> = {
            AssetBacked: [{
              Type: 'New',
              Rate: String(r.newAssetBacked ?? 0),
            }, {
              Type: 'Used',
              Rate: String(r.usedAssetBacked ?? 0),
            }],
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();

  }

  doWriteValue(v: AssetTier3Value | null | undefined): void | AssetTier3Value {
    if (v) {
      for (const asset of v.AssetBacked) {
        if (asset.Type === 'New') {
          const rate = numeral(asset.Rate).value();
          if (rate) {
            this.formControlNewAssetBacked.setValue(rate);
          }
        } else if (asset.Type === 'Used') {
          const rate = numeral(asset.Rate).value();
          if (rate) {
            this.formControlUsedAssetBacked.setValue(rate);
          }
        }
      }
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
