import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class TopMenuService {

  subject = new BehaviorSubject(false);

  showProgress() {
    this.subject.next(true);
  }

  hideProgress() {
    this.subject.next(false);
  }

}
