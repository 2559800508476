import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { compareMatch, RepaymentTypeOptions, RepaymentTypeValue } from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'repayment-type',
  templateUrl: './repayment-type.component.html',
  styleUrls: ['./repayment-type.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RepaymentTypeComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(() => RepaymentTypeComponent) },
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class RepaymentTypeComponent extends AbstractControlValueAccessor<RepaymentTypeValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  formGroup: FormGroup<{
    selection: FormControl<RepaymentTypeValue>;
  }>;
  formControl: FormControl<RepaymentTypeValue>;

  // @Input({required: false}) disabled: boolean = false;

  options = RepaymentTypeOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r) => {
        this.propagateChange(r);
      })
    ).subscribe());
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: RepaymentTypeValue): RepaymentTypeValue|undefined  {
    if (v) {
      const t = RepaymentTypeOptions.find(opt => opt.type === v.type);
      if (t) {
        this.formControl.setValue(t);
        return t;
      }
    }
    else{
      this.formControl.setValue(null);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }
}
