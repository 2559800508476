<div class="select-web-services-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Existing Web Services</div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        Do you want to rerun any of those Web Services? Please tick the Web Services you want to rerun.
      </div>
    </div>
    @for (checkBox of checkBoxes; track checkBox; let index = $index) {
      <div class="row">
        <div class="col-12">
          <mat-checkbox color="primary" [checked]="checkBox.check" (change)="update($event.checked, index)">
            <span class="checkbox-text">[{{checkBox.webService.type}}] {{checkBox.applicant}}</span>
          </mat-checkbox>
        </div>
      </div>
    }
    <br class="mb-4">
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" (click)="submit()">Confirm</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
