<div class="asset-purpose-selection-component">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <form [formGroup]="formGroup">
        <mat-form-field>
          <mat-label>Asset purpose</mat-label>
          <mat-select [formControl]="formAssetPurpose" >
            @for (option of options; track option) {
              <mat-option [value]="option">{{option.name}}</mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(formAssetPurpose); track errorKey) {
            <mat-error>
              @if (formAssetPurpose.touched && formAssetPurpose.hasError(errorKey)) {
                {{errorMessage(formAssetPurpose, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </form>
    </div>
  </div>

  @if (_showAssetAssetExistingFinanceCommitment) {
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12" >
      <mat-form-field>
        <mat-label>Asset has an existing finance commitment</mat-label>
        <mat-select [formControl]="formControlAssetexistingFinanceCommitment" >
          @for (option of assetExistingFinanceCommitmentOptions; track option) {
            <mat-option [value]="option">{{option.name}}</mat-option>
          }
        </mat-select>
        @for (errorKey of errorKeys(formControlAssetexistingFinanceCommitment); track errorKey) {
          <mat-error>
            @if (formControlAssetexistingFinanceCommitment.touched && formControlAssetexistingFinanceCommitment.hasError(errorKey)) {
              {{errorMessage(formControlAssetexistingFinanceCommitment, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
      </div>
    </div>
  }

  @if (_showAssetPurposeDescription) {
    <div class="row ">
      <div class="col-lg-6 col-md-12 " >
        <mat-form-field>
          <mat-label>Purpose Description</mat-label>
          <textarea matInput
            [placeholder]="assetPurposeDescriptionHelpText"
            [formControl]="formControlAssetDescription"
            maxlength="5000">
          </textarea>
          @for (errorKey of errorKeys(formControlAssetDescription); track errorKey) {
            <mat-error>
              @if (formControlAssetDescription.touched && formControlAssetDescription.hasError(errorKey)) {
                {{errorMessage(formControlAssetDescription, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
  }
</div>
