import {Injectable} from '@angular/core';
import {PrimaryIndustry, getPrimaryIndustries as gpi, getSecondaryIndustries as gsi, SecondaryIndustry} from '@portal-workspace/grow-shared-library';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class IndustrySelectionModuleService {

  currentSelectedPrimaryIndustry  = new BehaviorSubject<PrimaryIndustry | null>(null);
  secondaryIndustries = new BehaviorSubject<SecondaryIndustry[]>([]);

  subscription: Subscription;

  constructor() {
    setupUntilDestroy(this);
    this.subscription = this.currentSelectedPrimaryIndustry
      .pipe(
        tap(primaryIndustry => {
          if (primaryIndustry) {
            const secondaryIndustries = gsi(primaryIndustry);
            this.secondaryIndustries.next(secondaryIndustries);
          } else {
            this.secondaryIndustries.next([]);
          }
        })
      ).subscribe();
  }


  setPrimaryIndustry(p: PrimaryIndustry | null) {
    this.currentSelectedPrimaryIndustry.next(p);
  }

  getPrimaryIndustries(): PrimaryIndustry[] {
    return gpi();
  }

}
