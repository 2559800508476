<div class="bankstatements-upload-page container">
  @if (!bankStatements) {
    <div class="row separator-bottom">
      <div class="col-6">
        <message-box type="danger" title="Invalid link">
          <div>Please use a valid link to upload your bank statements</div>
        </message-box>
      </div>
    </div>
  }

  @if (bankStatements) {
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Upload Bank Statements</div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box type="info" title="Before uploading">
          <ul>
            <li>Ensure your bank statements is valid for last three months</li>
            <li>Ensure the text is clear and readable</li>
          </ul>
        </message-box>
      </div>
    </div>
    <!-- <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <uploaded-files-list [uploadedFiles]="uploadedDocs" (events)="onDeleteUploadedDoc($event)"></uploaded-files-list>
      </div>
    </div> -->
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <upload-file class="upload-file" [maxFilesAllowed]="5" [mandatory]="true" title="Bank Statements" [formControl]="formControlBankStatements" [allowMultiple]="true"></upload-file>
      </div>
    </div>
    <button mat-flat-button color="primary" class="active w-lt-md-100" [disabled]="!formControlBankStatements.valid" (click)="uploadFilesToAzure()">Upload</button>
  }
</div>
