import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, tap } from 'rxjs';
import {loadingFor} from '@ngneat/loadoff';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {
  Application,
  AssetDisbursement,
  AssetSettlementGetApplicationAssetFn,
  AssetSupplier,
  ConfirmationDialogResult,
  CreateNewDisbursementFn,
  Disbursement,
  DisbursementAmountDialogResult,
  DisbursementAssetPayee,
  DisbursementBankDetails,
  DisbursementBankDetailsDialogResult,
  DisbursementInvoice,
  AssetDisbursementInvoicesDialogResult,
  DisbursementPayee,
  AssetDisbursementPayeeDialogResult,
  DisbursementPaymentType,
  GetDisbursementByApplicationIdFn,
  parseJSON,
  PaymentChartData,
  PpsrAsset,
  applicationStageNotAllowDisbursementChange,
  PrivateSellerDetails,
  SettlementAssetDetailsSupplier,
  SyncBankDetailsToSfFn,
  SyncDisbursementToSfFn,
  UpdateDisbursementFn,
  GetBillerNameFn,
  ValidateBPAYFn,
  BpayValidationData,
  DisbursementDialogResult,
  PrivateSeller,
  SyncPrivateSellerBankDetailsToSfFn,
  GetInstitutionNameFn,
  SyncDepositPaidToSfFn,
  getFinanceType,
  AddAuditLogFn,
  User,
  stringifyJSON
} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, PortalHotToastService, SearchSupplierFn, getUser, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest} from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';
import _ from 'lodash';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";

export class AssetDisbursementTableComponentInternalDataSource extends DataSource<AssetDisbursement> {

  subject: Subject<AssetDisbursement[]> = new BehaviorSubject<AssetDisbursement[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<AssetDisbursement[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(disbursements: AssetDisbursement[]) {
    this.subject.next(disbursements);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'disbursement-table',
    templateUrl: './disbursement-table.component.html',
    styleUrls: ['./disbursement-table.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatFormFieldModule, MatTooltipModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, TagBoxComponent, NgStyle, AsyncPipe, TruncatePipe, LooseCurrencyPipe]
})
export class DisbursementTableComponent implements OnInit, OnChanges {

  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) syncPrivateSellerBankDetailsToSfFn!: SyncPrivateSellerBankDetailsToSfFn;
  @Input({required: true}) syncDepositPaidToSfFn!: SyncDepositPaidToSfFn;
  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) validateBpayFn!: ValidateBPAYFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;

  loggedinUser: User | null = getUser();
  isRentalApplication: boolean = false;
  subscriptions: Subscription[] = [];
  dataSource = new AssetDisbursementTableComponentInternalDataSource();
  columnsToDisplay = ["payee", "invoices", "bankDetails", "amount", "action"];
  loader = loadingFor('disbursementTable');
  disbursementData: AssetDisbursement[] = [];
  allAssets: PpsrAsset[] = [];
  allPayees: DisbursementAssetPayee[] = [];
  allInvoices: DisbursementInvoice[] = [];
  totalAmount: number = 0;
  docFeeFinanced = true;
  sumUpAmount: number = 0;
  gst: number = 0;
  // WEB-3702 alreadySyncToSf = false;
  bpayMessage: string[] = []
  bpayError: boolean[] = []
  sumOfDepositAmount = 0;
  sumOfDepositAmountDynamoney = 0;
  sumOfDepositAmountSupplier = 0;
  sumOfResidual = 0;
  // depositPaidTo: 'Dynamoney' | 'Supplier' | null = null; // WEB-3702 will always be null
  applicationStageNotAllowDisbursementChange = applicationStageNotAllowDisbursementChange;

  constructor(
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.isRentalApplication = getFinanceType(this.application) == 'Rental'
    this.loadAsset();
    this.loadDisbursement(false);

    if ((this.application?.PricingDetails as any).DocFeeFinanced == 'Yes') {
      this.docFeeFinanced = true;
    } else {
      this.docFeeFinanced = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['paymentChartData']) {
      this.paymentChartData = changes['paymentChartData'].currentValue;
    }
  }

  loadDisbursement(triggerUpdateTotalAmount: boolean = true/* WEB-3702: resetDepositPaidTo: boolean = true*/) {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn<AssetDisbursement>(this.application.ApplicationId).pipe(
        this.loader.disbursementTable.track(),
      ).subscribe((r: AssetDisbursement[]) => {
        this.disbursementData = r;
        console.log('disbursement data: ', r);
        // WEB-3702
        // if (resetDepositPaidTo) {
        //   this.depositPaidTo = r[0]?.depositPaidTo ?? null
        // }
        this.setValidityofBpay();
        this.dataSource.update(this.disbursementData);
        if (triggerUpdateTotalAmount) {
          this.updateTotalAmount();
        }

        // WEB-3702:
        // if (this.disbursementData && this.disbursementData.length) {
        //   this.alreadySyncToSf = this.disbursementData[0].syncToSF;
        // }
      })
    )
  }

  setValidityofBpay = () => {
    this.disbursementData.map((data, index) => {
      if (data.bankDetails?.paymentType == 'bpay' && data.bankDetails.bpayAmount) {
        this.validateBpay(data.bankDetails, index)
      }
    })
  }

  loadAsset() {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((assets: PpsrAsset[]) => {
        this.allAssets = assets;
        this.loadInfo();
        this.loadDisbursement();
      })
    )
  }

  loadInfo() {
    // If there is deposit amount in application but none of the assets have deposit then just add the deposit amount to first asset
    if (this.paymentChartData?.deposit && this.allAssets?.length) {
      if (this.allAssets.reduce((a, b) => a + (b.SettlementAssetDetails?.depositAmount ?? 0), 0) == 0) {
        this.allAssets[0].SettlementAssetDetails!.depositAmount = this.paymentChartData.deposit
      }
    }

    // sum up residual value
    this.sumOfResidual = this.allAssets.reduce((a, b) => a + (b.SettlementAssetDetails?.residual ?? 0), 0);

    // get all payees and all invoices
    for (const asset of this.allAssets) {
      // 1. fill allPayees
      // supplier
      if (asset?.SettlementAssetDetails?.supplier) {
        const supplier = asset.SettlementAssetDetails.supplier as AssetSupplier;
        if (!this.allPayees.find(payee => payee.type === 'supplier' &&
          payee?.supplier?.SalesForceId === supplier.SalesForceId
        )) {
          this.allPayees.push({
            i: 'disbursement-asset-payee',
            type: "supplier",
            supplier: supplier,
          })
        }
      } else { // private seller
        const privateSeller = parseJSON(asset?.PrivateSellerDetails ?? "") as PrivateSeller;
        if (privateSeller?.business) {
          if (!this.allPayees.find(payee => payee.type === 'private-seller' &&
            payee?.privateSeller?.business?.abn === privateSeller?.business?.abn)) {
            console.log("push business")
            this.allPayees.push({
              i: 'disbursement-asset-payee',
              type: "private-seller",
              privateSeller: {
                business: privateSeller?.business,
                SalesForceId: privateSeller.SalesForceId,
              }
            })
          }
        } else {
          if (!this.allPayees.find(payee => payee.type === 'private-seller' &&
            payee?.privateSeller?.firstName === privateSeller.name &&
            payee?.privateSeller?.lastName === privateSeller.lastName &&
            payee?.privateSeller?.middleName === privateSeller.middleName
          )) {
            this.allPayees.push({
              i: 'disbursement-asset-payee',
              type: "private-seller",
              privateSeller: {
                firstName: privateSeller.name ?? "",
                lastName: privateSeller.lastName,
                middleName: privateSeller?.middleName,
                privateSellerAddress: privateSeller?.address,
                SalesForceId: privateSeller.SalesForceId
              }
            })
          }
        }
      }

      // 2. fill allInvoices
      if (!this.allInvoices.find(invoice => invoice.invoiceNumber === asset.invoiceNumber)){
        this.allInvoices.push({
          invoiceNumber: asset.invoiceNumber ?? "",
          invoiceAmount: asset?.SettlementAssetDetails?.InvoicePrice ?? 0,
          invoiceGst: asset?.SettlementAssetDetails?.GST ?? 0,
          depositAmount: asset?.SettlementAssetDetails?.depositAmount ?? 0,
          depositAmountDynamoney: asset?.SettlementAssetDetails?.depositAmountDynamoney ?? 0,
          depositAmountSupplier: asset?.SettlementAssetDetails?.depositAmountSupplier ?? 0,
        })
      }
    }

    console.log("all payees: ", this.allPayees);
    console.log("all invoices: ", this.allInvoices);
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'payee': return "Payee";
      case 'invoices': return "Reference";
      case 'amount': return "Amount";
      case 'bankDetails': return "Bank Details";
      case 'action':
        return '';
      default: return column;
    }
  }

  displayPayee(element: AssetDisbursement) {
    if (element.payee?.type === 'supplier') {
      return element.payee.supplier?.SupplierName;
    } else if (element.payee?.type === 'private-seller') {
      if (element.payee?.privateSeller?.business) {
        return element.payee.privateSeller?.business?.organisationName;
      } else {
        return element.payee.privateSeller?.firstName + " " + element.payee.privateSeller?.lastName;
      }
    } else if (element.payee?.type === 'manual') {
      return element.payee?.payeeManual ?? "";
    }
    return "";
  }

  displayInvoices(element: AssetDisbursement) {
    return element.invoices.map(i => i.invoiceNumber).join(", ");
  }

  updateAmount(element: Disbursement, index: number) {
    console.log('From update amount::', this.disbursementData[index])
    // WEB-3702: if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0 && this.depositPaidTo == "Supplier") {
    if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0) {
      this.disbursementData[index] = {
        ...this.disbursementData[index],
        amount: this.isRentalApplication ?
          (this.disbursementData[index].invoices.reduce((a, b) => a + ((b.invoiceAmount ?? 0) * 1.1 - (b.depositAmountSupplier ?? 0)), 0)) :
          (this.disbursementData[index].invoices.reduce((a, b) => a + ((b.invoiceAmount ?? 0) - (b.depositAmountSupplier ?? 0)), 0))
      }
    } else {
      this.disbursementData[index] = {
        ...this.disbursementData[index],
        amount: this.disbursementData[index].invoices.reduce((a, b) => a + b.invoiceAmount, 0),
      };
    }
  }

  updateTotalAmount() {
    this.totalAmount =  this.allAssets.reduce((a, b) => a + (b.SettlementAssetDetails?.InvoicePrice ?? 0), 0);
    this.sumUpAmount = this.totalAmount + (this.application.ApplicationType !== "Consumer" ? Number(this.paymentChartData.brokerageAmount) : 0) +
      Number(this.paymentChartData.brokerOriginationFee) + (this.docFeeFinanced ? Number(this.paymentChartData.docFee) : 0);
    if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0) {
      //Update Total Deposit
      this.sumOfDepositAmount = 0
      this.sumOfDepositAmountDynamoney = 0;
      this.sumOfDepositAmountSupplier = 0;
      this.disbursementData.forEach(dis => {
        this.sumOfDepositAmount += dis.invoices.reduce((a, inv) => a += inv.depositAmount ?? 0, 0)
        this.sumOfDepositAmountDynamoney += dis.invoices.reduce((a, inv) => a += inv.depositAmountDynamoney ?? 0, 0)
        this.sumOfDepositAmountSupplier += dis.invoices.reduce((a, inv) => a += inv.depositAmountSupplier ?? 0, 0)
      });
      //The sum of deposit should never be greater than the deposit of application
      if (this.sumOfDepositAmount > this.paymentChartData.deposit) {
        this.sumOfDepositAmount = this.paymentChartData.deposit;
        // WEB-3702
        this.sumOfDepositAmountDynamoney = this.paymentChartData.deposit;
        this.sumOfDepositAmountSupplier = 0;
      }
      // WEB-3702
      // if (this.depositPaidTo == 'Dynamoney') {
      //   this.sumUpAmount -= this.sumOfDepositAmount
      // }
      this.sumUpAmount -= this.sumOfDepositAmount;
      this.gst = 0;
      if (this.isRentalApplication) {
        this.gst = _.round((this.sumOfDepositAmountDynamoney / 11) + (this.sumOfDepositAmountSupplier / 11), 2);
        this.sumUpAmount += this.gst;
      }
    }
  }

  updateDisbursement(disbursement: Disbursement, callback?: ()=>void) {
    this.subscriptions.push(
      this.updateDisbursementFn(disbursement).pipe(
        this.toastService.snackBarObservable("Disbursement updated")
      ).subscribe((rst) => {
        callback && callback();
      })
    )
  }

  openPayeeDialog(element: AssetDisbursement, index: number) {
    // if (this.alreadySyncToSf) {
    //   this.openWarningDialog();
    //   return;
    // }
    this.dialogService.openAssetDisbursementPayeeDialog({
      allPayees: this.allPayees,
      currentPayee: element.payee,
    }).afterClosed().subscribe((r: AssetDisbursementPayeeDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          payee: r.payee,
        };
        console.log(r)
        this.dataSource.update(this.disbursementData);
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Payee`,
          stringifyJSON(element.payee),
          stringifyJSON(r.payee),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  openInvoicesDialog(element: AssetDisbursement, index: number) {
    // if (this.alreadySyncToSf) {
    //   this.openWarningDialog();
    //   return;
    // }
    this.dialogService.openAssetDisbursementInvoicesDialog({
      allInvoices: this.allInvoices,
      currentInvoices: element.invoices,
    }).afterClosed().subscribe((r: AssetDisbursementInvoicesDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          invoices: r.invoices,
        };
        this.updateAmount(element, index);
        this.updateTotalAmount();
        this.dataSource.update(this.disbursementData);
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Invoices`,
          stringifyJSON(element.invoices),
          stringifyJSON(r.invoices),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  openBankDetailsDialog(element: Disbursement, index: number) {
    // if (this.alreadySyncToSf) {
    //   this.openWarningDialog();
    //   return;
    // }
    const amount = this.disbursementData[index].amount ?? null
    this.dialogService.openDisbursementBankDetailsDialog({
      bankDetails: element.bankDetails,
      amount: amount,
      getBillerNameFn: this.getBillerNameFn,
      getInstitutionNameFn: this.getInstitutionNameFn

    }).afterClosed().subscribe((r: DisbursementBankDetailsDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          bankDetails: r.bankDetails,
        };
        if (r.bankDetails.bpayAmount) {
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            amount: r.bankDetails.bpayAmount
          }
          this.validateBpay(r.bankDetails, index)
        }
        this.dataSource.update(this.disbursementData);
        this.updateTotalAmount()
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Bank Details`,
          stringifyJSON(element.bankDetails),
          stringifyJSON(r.bankDetails),
          index,
          this.disbursementData[index].id
        )
        if (this.disbursementData[index].payee?.supplier?.SalesForceId && r.bankDetails?.paymentType == 'direct-debit') {
          this.syncSupplierBankDetailsToSf(this.disbursementData[index]?.payee?.supplier?.SalesForceId ?? "", r.bankDetails);
        }
        console.log(this.disbursementData[index].payee)
        if (this.disbursementData[index].payee?.privateSeller?.SalesForceId && r.bankDetails?.paymentType == 'direct-debit') {
          console.log("Saving private seller bank details")
          this.syncPrivateSellerBankDetailsToSf(this.disbursementData[index]?.payee?.privateSeller?.SalesForceId ?? "", r.bankDetails);
        }
      }
    })
  }

  openAmountDialog(element: Disbursement, index: number) {
    // if (this.alreadySyncToSf) {
    //   this.openWarningDialog();
    //   return;
    // }
    const bankDetails = this.disbursementData[index].bankDetails
    this.dialogService.openDisbursementAmountDialog({
      amount: element.amount,
      bankDetails: bankDetails
    }).afterClosed().subscribe((r: DisbursementAmountDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.disbursementData[index] = {
          ...this.disbursementData[index],
          amount: r.amount,
        };
        if (bankDetails && bankDetails.paymentType == 'bpay') {
          const newBankDetails = { ...bankDetails, bpayAmount: r.amount }
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            bankDetails: newBankDetails
          }
          this.validateBpay(newBankDetails, index)
        }
        this.dataSource.update(this.disbursementData);
        this.updateTotalAmount();
        this.updateDisbursement(this.disbursementData[index]);
        this.saveAuditLog(
          `Disbursement - Amount`,
          stringifyJSON(element.amount),
          stringifyJSON(r.amount),
          index,
          this.disbursementData[index].id
        )
      }
    })
  }

  validateBpay(bankDetails: DisbursementBankDetails, index: number) {
    if (bankDetails.paymentType == 'bpay' && bankDetails.bpayAmount) {
      const bpayValidationData: BpayValidationData = {
        BillerCode: bankDetails.bpayBillerCode!,
        CRN: bankDetails.bpayCrn!,
        Amount: bankDetails.bpayAmount
      }

      this.validateBpayFn(bpayValidationData).pipe(
        tap(r => {
          if (r) {
            this.bpayError[index] = r.isValidationSuccessful;
            this.bpayMessage[index] = r.validationErrorMessage;
          }
        })
      ).subscribe()
    }
  }

  onDelete(element: Disbursement, index: number) {
    // if (this.alreadySyncToSf) {
    //   this.openWarningDialog();
    //   return;
    // }
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to delete this disbursement?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          // delete in database
          this.disbursementData[index] = {
            ...this.disbursementData[index],
            isDeleted: true
          };
          this.updateDisbursement(this.disbursementData[index], () => {
            this.loadDisbursement()
            this.saveAuditLog(
              `Delete Disbursement`,
              '',
              '',
              index,
              this.disbursementData[index].id
            )
          });
          // this.disbursementData.splice(index, 1);
          // WEB-3702: this.depositPaidTo = this.disbursementData[0]?.depositPaidTo ?? null
          // this.setValidityofBpay()
          // this.dataSource.update(this.disbursementData);
        }
      })
    )
  }

  openWarningDialog() {
    this.dialogService.openAlertDialog({
      message: `Warning`,
      subMessage: `You cannot edit the table as the disbursement has been sent to Salesforce.`,
    });
  }

  addNewDisbursement() {
    // WEB-3702: if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0 && !this.depositPaidTo) {
    if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0) {
      this.dialogService.openDisbursementDialog({ type: 'Add' }).afterClosed().subscribe((r: DisbursementDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const newDisbursementData: AssetDisbursement = {
            i: 'asset-disbursement',
            payee: null,
            invoices: [],
            amount: 0,
            bankDetails: null,
            newDisbursement: true,
            id: 0,
            applicationId: this.application?.ApplicationId,
            lastUpdatedTime: "",
            isDeleted: false,
            // WEB-3702: syncToSF: false,
            // WEB-3702: depositPaidTo: r.depositPaidTo,
            salesforceId: null,
          };
          // WEB-3702: this.depositPaidTo = r.depositPaidTo
          this.subscriptions.push(
            this.createNewDisbursementFn(newDisbursementData).subscribe((r: number) => {
              newDisbursementData.id = r;
              this.disbursementData.push(newDisbursementData);
              this.dataSource.update(this.disbursementData);

              this.saveAuditLog(
                `Add New Disbursement`,
                '',
                stringifyJSON(newDisbursementData),
                this.disbursementData.length - 1,
                r
              )
            })
          )
          
          // WEB-3702:
          // this.subscriptions.push(
          //   this.syncDepositPaidToSfFn({
          //     salesforceId: this.application.AppInfoSalesforceID!,
          //     depositPaidTo: r.depositPaidTo
          //   }).subscribe()
          // )
        }
      })
    } else {
      const newDisbursementData: AssetDisbursement = {
        i: 'asset-disbursement',
        payee: null,
        invoices: [],
        amount: 0,
        bankDetails: null,
        newDisbursement: true,
        id: 0,
        applicationId: this.application?.ApplicationId,
        lastUpdatedTime: "",
        isDeleted: false,
        // WEB-3702: syncToSF: false,
        // WEB-3702: depositPaidTo: this.depositPaidTo,
        salesforceId: null,
      };
      this.subscriptions.push(
        this.createNewDisbursementFn(newDisbursementData).subscribe((r: number) => {
          newDisbursementData.id = r;
          this.disbursementData.push(newDisbursementData);
          this.dataSource.update(this.disbursementData);
        })
      )
    }
  }

  batchDeleteDisbursement() {
    const subs = [];
    console.log(this.disbursementData)
    for (let i = 0; i < this.disbursementData.length; i++) {
      console.log(this.disbursementData[i].id, this.disbursementData[i])
      subs.push(this.updateDisbursementFn({
        ...this.disbursementData[i],
        isDeleted: true,
      }))

      this.saveAuditLog(
        `Delete Disbursement`,
        '',
        '',
        i,
        this.disbursementData[i].id
      )
    }
    this.subscriptions.push(combineLatest(subs).subscribe());
    // WEB-3702: this.loadDisbursement(false)
    this.loadDisbursement();
  }

  batchCreateDisbursement(callback?: ()=>void) {
    const subs = [];
    for (const disbursement of this.disbursementData) {
      subs.push(this.createNewDisbursementFn(disbursement))
    }
    this.subscriptions.push(
      combineLatest(subs).pipe(
        this.toastService.snackBarObservable("Disbursement created")
      ).subscribe((rst) => {
        callback && callback();

        this.saveAuditLog(
          `Default Disbursement`,
          '',
          stringifyJSON(this.disbursementData),
          0,
          0,
        )
      })
    );
  }

  addDefaultDisbursement() {
    if (this.paymentChartData.deposit && this.paymentChartData.deposit > 0) {
      this.subscriptions.push(
        this.dialogService.openDisbursementDialog({
          type: 'Default'
        }).afterClosed().subscribe((r: DisbursementDialogResult | undefined) => {
          console.log("Disbursement Rsult", r)
          if (r && r.readyForSubmission) {
            // delete all existing disbursement
            this.batchDeleteDisbursement();

            // update current table
            // WEB-3702: this.depositPaidTo = r.depositPaidTo;
            // WEB-3702: this.getDefaultDisbursement(true);
            this.getDefaultDisbursement();
          }
        })
      )
    }
    else {
      this.subscriptions.push(
        this.dialogService.openConfirmationDialog({
          message: "Important",
          subMessage: "Default disbursement will overwrite all your existing settings. Do you wish to continue?"
        }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            // delete all existing disbursement
            this.batchDeleteDisbursement();

            // update current table
            this.getDefaultDisbursement();
          }
        }))
    }
  }

  getDefaultDisbursement(/*withDeposit: boolean = false*/) {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((assets: PpsrAsset[]) => {
        this.allAssets = assets;
        this.loadInfo();

        // get default disbursement
        const disbursementList: AssetDisbursement[] = [];
        for (const asset of this.allAssets) {
          // supplier
          let payeeIndex = -1;
          let disbursement: AssetDisbursement = {
            i: 'asset-disbursement',
            payee: null,
            invoices: [] as DisbursementInvoice[],
            amount: 0,
            bankDetails: null,
            id: 0,
            applicationId: this.application?.ApplicationId,
            lastUpdatedTime: "",
            isDeleted: false,
            // WEB-3702: syncToSF: false,
            // WEB-3702: depositPaidTo: this.depositPaidTo,
            salesforceId: null,
          };
          if (asset?.SettlementAssetDetails?.supplier) {
            const supplier = asset.SettlementAssetDetails.supplier as AssetSupplier;
            payeeIndex = this.allPayees.findIndex(
              p => p?.type === 'supplier' && p?.supplier?.SalesForceId === supplier.SalesForceId
            );
            disbursement = {
              ...disbursement,
              bankDetails: {
                paymentType: 'direct-debit',
                financialInstitution: supplier?.FinancialInstitution,
                bankAccountName: supplier?.BankAccountName,
                bankAccountBsb: supplier?.BankAccountBsb,
                bankAccountNumber: supplier?.BankAccountNumber,
              }
            }
            // if (supplier?.BankAccountName) {
            //   disbursementList[payeeIndex] = {
            //     ...disbursementList[payeeIndex],
            //     bankDetails: {
            //       paymentType: 'direct-debit',
            //       financialInstitution: supplier?.FinancialInstitution,
            //       bankAccountName: supplier?.BankAccountName,
            //       bankAccountBsb: supplier?.BankAccountBsb,
            //       bankAccountNumber: supplier?.BankAccountNumber,
            //     }
            //   }
            // }
          } else { // private seller
            const privateSeller = asset?.PrivateSellerDetails as PrivateSeller;
            if (privateSeller?.business) {
              payeeIndex = this.allPayees.findIndex(
                p => p?.type === 'private-seller' && p?.privateSeller?.business?.abn === privateSeller?.business?.abn
              )
            } else {
              payeeIndex = this.allPayees.findIndex(
                p => p?.type === 'private-seller' && p?.privateSeller?.firstName === privateSeller.name &&
                  p?.privateSeller?.lastName === privateSeller.lastName && p?.privateSeller?.middleName === privateSeller.middleName
              )
            }
            disbursement = {
              ...disbursement,
              bankDetails: {
                paymentType: 'direct-debit',
                financialInstitution: privateSeller.bankDetails?.InstitutionName!,
                bankAccountName: privateSeller.bankDetails?.AccountName!,
                bankAccountBsb: privateSeller.bankDetails?.BSB!,
                bankAccountNumber: privateSeller.bankDetails?.AccountNumber!,
              }
            }
          }

          // WEB-3902
          // const disbursementAmount = this.depositPaidTo == 'Dynamoney' ? asset?.SettlementAssetDetails?.InvoicePrice ?? 0 : ((asset.SettlementAssetDetails?.InvoicePrice ?? 0) - (asset.SettlementAssetDetails?.depositAmount ?? 0));
          const disbursementAmount = this.isRentalApplication ?
            ((asset.SettlementAssetDetails?.InvoicePrice ?? 0) * 1.1 - (asset.SettlementAssetDetails?.depositAmountSupplier ?? 0)) :
            ((asset.SettlementAssetDetails?.InvoicePrice ?? 0) - (asset.SettlementAssetDetails?.depositAmountSupplier ?? 0));
          disbursement = {
            ...disbursement,
            payee: payeeIndex > -1 ? this.allPayees[payeeIndex] : null,
            invoices: [{
              invoiceNumber: (asset.invoiceNumber ?? "").slice(0, 30),
              invoiceAmount: asset?.SettlementAssetDetails?.InvoicePrice ?? 0,
              invoiceGst: asset?.SettlementAssetDetails?.GST ?? 0,
              depositAmount: asset?.SettlementAssetDetails?.depositAmount ?? 0,
              depositAmountDynamoney: asset?.SettlementAssetDetails?.depositAmountDynamoney ?? 0,
              depositAmountSupplier: asset?.SettlementAssetDetails?.depositAmountSupplier ?? 0,
            }],
            amount: disbursementAmount,
          };
          disbursementList.push(disbursement);
        }
        this.disbursementData = disbursementList;

        // update table
        this.dataSource.update(this.disbursementData);

        // WEB-3702
        // if (withDeposit) {
        //   this.subscriptions.push(
        //     this.syncDepositPaidToSfFn({
        //       salesforceId: this.application.AppInfoSalesforceID!,
        //       depositPaidTo: this.depositPaidTo as 'Dynamoney' | 'Supplier'
        //     }).subscribe()
        //   )
        // }

        this.updateTotalAmount();
        this.batchCreateDisbursement(() => {
          this.loadDisbursement();
        });
        // WEB-3702
        // if (withDeposit) {
        //   this.loadDisbursement();
        // }
      })
    )
  }

  syncSupplierBankDetailsToSf(salesforceId: string, bankDetails: DisbursementBankDetails) {
    this.subscriptions.push(
      this.syncBankDetailsToSfFn({
        salesforceId,
        financialInstitution: bankDetails.financialInstitution ?? "",
        bankAccountName: bankDetails.bankAccountName ?? "",
        bankAccountBsb: bankDetails.bankAccountBsb ?? "",
        bankAccountNumber: bankDetails.bankAccountNumber ?? "",
      }).subscribe()
    )
  }

  syncPrivateSellerBankDetailsToSf(salesforceId: string, bankDetails: DisbursementBankDetails) {
    this.subscriptions.push(
      this.syncPrivateSellerBankDetailsToSfFn({
        salesforceId,
        financialInstitution: bankDetails.financialInstitution ?? "",
        bankAccountName: bankDetails.bankAccountName ?? "",
        bankAccountBsb: bankDetails.bankAccountBsb ?? "",
        bankAccountNumber: bankDetails.bankAccountNumber ?? "",
      }).subscribe()
    )
  }

  get validateCalculations(): boolean {
    if (this.application?.ApplicationType == 'Consumer') {
      return _.round(this.paymentChartData.principalAmt, 2) == _.round(this.sumUpAmount, 2) &&
        _.round(this.paymentChartData.rv, 2) == _.round(this.sumOfResidual, 2);
    } else {
      return _.round(this.paymentChartData.principalAmt + this.paymentChartData.brokerageAmount + (this.isRentalApplication ? this.gst : 0), 2) == _.round(this.sumUpAmount, 2) &&
        _.round(this.paymentChartData.rv, 2) == _.round(this.sumOfResidual, 2);
    }
  }

  saveAuditLog(field: string, oldValue: string, newValue: string, index: number, id: number) {
    this.addAuditLogFn({
      UserId: this.loggedinUser?.UserId!,
      ApplicationId: this.application.ApplicationId,
      Field: field,
      OldValue: oldValue,
      NewValue: newValue,
      AssetNumber: index,
      AssetId: id,
    }).subscribe()
  }
  // syncAllDisbursementToSf() {
    // if (!this.validateDisbursementDataHaveBankDetails()) {
    //   this.dialogService.openAlertDialog({
    //     message: "Warning",
    //     subMessage: `Please make sure you enter payment details for all disbursements.`,
    //   }).afterClosed().subscribe()
    // } else {
    //   const subs = [];
    //   for (const data of this.disbursementData) {
    //     subs.push(this.syncDisbursementToSfFn({
    //       disbursement: data,
    //       salesforceId: this.application.AppInfoSalesforceID ?? ""
    //     }))
    //   }
    //   this.subscriptions.push(
    //     combineLatest(subs).pipe(
    //       this.toastService.spinnerObservable(),
    //       this.toastService.snackBarObservable("Sync to SF success"),
    //     ).subscribe(() => {
    //       this.loadDisbursement();
    //     })
    //   )
    // }
  // }

  // private validateDisbursementDataHaveBankDetails(): boolean {
  //   for (const data of this.disbursementData) {
  //     if (!data?.bankDetails || !data?.bankDetails?.paymentType) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
}
