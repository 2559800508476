<div class="prefill-directors-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Select Directors</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        We found the following directors for this company. Please select the directors you want to fill in.
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table mat-table [dataSource]="directors" class="width-100 signer-table">
          <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline"
              (click)="toggleAllSelection()"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selections.toggle(element) : null"
                [checked]="selections.isSelected(element)"
              ></mat-checkbox>
            </td>
          </ng-container> -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
          </ng-container>
          <ng-container matColumnDef="dob">
            <th mat-header-cell *matHeaderCellDef>DoB</th>
            <td mat-cell *matCellDef="let element">{{moment(element.dob).format("DD/MM/YYYY")}}</td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let element">{{element?.residentialAddress?.address}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline" (click)="toggleAllSelection()"></span>
            </th>
            <td mat-cell *matCellDef="let element; let i = index;" style="text-align: center!important;">
              <mat-checkbox
                (change)="onChangeCheckbox($event, i)"
                [checked]="directorCheckbox[i]"
              color="primary"></mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" (click)="submit()">Save</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
