import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleValue } from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@Component({
    selector: 'slide-toggle',
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SlideToggleComponent), multi: true }
    ],
    standalone: true,
    imports: [MatSlideToggleModule, FormsModule, ReactiveFormsModule, DisableControlDirective]
})
export class SlideToggleComponent extends AbstractControlValueAccessor<SlideToggleValue> implements OnInit {

  @Input({required: false}) showCancelConfirmation: boolean = true;
  @Input({required: false}) cancelConfirmationMessage: string = `Are you sure?`;
  @Input({required: false}) defaultValue: boolean = false;

  @Input({required: false}) checkedFlag = false;

  formControl!: FormControl<SlideToggleValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formControl = this.formBuilder.control(true);
  }

  doWriteValue(v: SlideToggleValue | null | undefined): void | SlideToggleValue {
    this.formControl.setValue(!!v);
    this.checkedFlag = !!v;
    return undefined;
  }


  onEnableChangetoggle($event: MatSlideToggleChange) {
    if (this.checkedFlag) { // currently checked, attempting to uncheck
      if (this.showCancelConfirmation) {
        if (confirm(this.cancelConfirmationMessage)) {
          this.checkedFlag = !this.checkedFlag;
          this.propagateChange(this.checkedFlag);
        } else {
          $event.source.checked = true;
        }
      } else {
        this.checkedFlag = !this.checkedFlag;
        this.propagateChange(this.checkedFlag);
      }
    } else { // currently unchecked, attempting to check
      this.checkedFlag = !this.checkedFlag;
      this.propagateChange(this.checkedFlag);
    }
  }
}
