<div class="batch-payment-table-component">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-0': column === 'icon'
        }">
          @if (column !== 'icon') {
            <div class="cell">{{getColumnTitles(column)}}</div>
          }
        </th>
        <td mat-cell *matCellDef="let element"
          (click)="expandedElement = (column === 'action' ? expandedElement : (expandedElement === element ? null : element))">
          @if (column === 'icon') {
            <div class="cell">
              @if (expandedElement === element) {
                <span class="mdi mdi-chevron-up"></span>
              }
              @if (expandedElement !== element) {
                <span class="mdi mdi-chevron-down"></span>
              }
            </div>
          }
          @if (column === 'type') {
            <div class="cell">
              {{element[column]}}
            </div>
          }
          @if (column === 'description') {
            <div class="cell">
              {{element[column]}}
            </div>
          }
          @if (column === 'createdTime') {
            <div class="cell">
              {{element[column] | date:'dd/MM/yyyy h:mm:ss a'}}
            </div>
          }
          @if (column === 'lastUpdatedTime') {
            <div class="cell">
              {{element[column] | date:'dd/MM/yyyy h:mm:ss a'}}
            </div>
          }
          @if (column === 'status') {
            <div class="cell">
              {{element[column]}}
            </div>
          }
          @if (column === 'action') {
            <div class="cell">
              <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
              <mat-menu #menu="matMenu">
                <button class="menu-items" mat-menu-item (click)="edit(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>Edit</div>
                    <div><span class="mdi mdi-pencil-outline"></span></div>
                  </div>
                </button>
                <mat-divider></mat-divider>
                <button class="menu-items" mat-menu-item (click)="showMore(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>More</div>
                    <div><span class="mdi mdi-format-list-bulleted"></span></div>
                  </div>
                </button>
                <mat-divider></mat-divider>
                <button class="menu-items" mat-menu-item (click)="delete(element)">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>Delete</div>
                    <div><span class="mdi mdi-trash-can-outline"></span></div>
                  </div>
                </button>
              </mat-menu>
            </div>
          }
        </td>
      </ng-container>
    }

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="sub-table">
            @if (element.type === 'bpay') {
              <bpay-table
                [addBankingTransactionFn]="addBankingTransactionFn"
                [editTransactionFn]="editTransactionFn"
                [getTransactionsFn]="getTransactionsFn"
                [deleteTransactionFn]="deleteTransactionFn"
                [generatePaymentFileFn]="generatePaymentFileFn"
                [batchId]="element.id"
              ></bpay-table>
            }
            @if (element.type !== 'bpay') {
              <direct-payment-table
                [addBankingTransactionFn]="addBankingTransactionFn"
                [editTransactionFn]="editTransactionFn"
                [getTransactionsFn]="getTransactionsFn"
                [deleteTransactionFn]="deleteTransactionFn"
                [generatePaymentFileFn]="generatePaymentFileFn"
                [batchId]="element.id"
              ></direct-payment-table>
            }
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
