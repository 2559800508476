import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomContentLoaderComponent} from './custom-content-loader.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
 
@NgModule({
    imports: [
        CommonModule,
        AngularMaterialLibraryModule,
        CustomContentLoaderComponent
    ],
    exports: [
        CustomContentLoaderComponent,
    ]
})
export class CustomContentLoaderModule {

}
