<div class="accreditation-documents-component">
  <div fxLayout="row" class="header" fxLayoutGap="10px" fxLayoutAlign="end center">
    <button mat-flat-button (click)="onDownloadAll()">
      <span class="mdi mdi-tray-arrow-down" matPrefix></span>
      Download All
    </button>
  </div>

  <div class="documents-table">
    @if ((loader.documentDetails.inProgress$ | async)) {
      <custom-content-loader type="list" ></custom-content-loader>
    }
    <div [ngClass]="{hidden: (loader.documentDetails.inProgress$ | async)}">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-4">
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-4': column === 'action',
          'column-width-3': column === 'documents',
          'column-width-2': column === 'status',
          'column-width-1': column === 'groupName',
          'column-width-0': column === 'icon'
          }">
              <div class="cell">{{getColumnTitles(column)}}</div>
            </th>
            <td mat-cell *matCellDef="let element"
              (click)="expandedElement = (column === 'action' ? expandedElement : (expandedElement === element ? null : element))">
              <div class="cell">
                @if (column === 'icon') {
                  @if (expandedElement === element) {
                    <span class="mdi mdi-chevron-up"></span>
                  }
                  @if (expandedElement !== element) {
                    <span class="mdi mdi-chevron-down"></span>
                  }
                }
                @if (column === 'groupName') {
                  <div class="text-bold">
                    {{element[column]}}
                  </div>
                  <div class="date-text">{{element.description}}</div>
                }
                @if (column === 'stageRequired') {
                  <tag-box [state]="'Something here'"></tag-box>
                }
                @if (column === 'status') {
                  <tag-box [state]="element[column]"></tag-box>
                }
                @if (column === 'documents') {
                  @if (element?.docs) {
                    @for (doc of element?.docs?.slice(0, 15); track doc) {
                      <span class="mdi mdi-file-document-outline cursor-pointer" (click)="onDownloadDocument(doc)" [matTooltip]="doc?.metadata?.name"></span>
                    }
                  }
                }
                @if (column === 'action') {
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
                  </div>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item class="menu-items" (click)="onUploadDocument(element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Attach</div>
                        <div><span class="mdi mdi-attachment"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                    @if (element?.docs?.length && isInternalUser(user)) {
                      <button mat-menu-item class="menu-items" (click)="onApproveDocument(element.docs, element.value)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Accept</div>
                          <div><span class="mdi mdi-check"></span></div>
                        </div>
                      </button>
                      <mat-divider></mat-divider>
                    }
                    @if (element?.docs?.length && isInternalUser(user)) {
                      <button mat-menu-item class="menu-items" (click)="onDeclineDocument(element.docs, element.value)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Reject</div>
                          <div><span class="mdi mdi-close"></span></div>
                        </div>
                      </button>
                      <mat-divider></mat-divider>
                    }
                  </mat-menu>
                }
              </div>
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" class="expanded-row" [attr.colspan]="columnsToDisplay.length"
            [ngClass]="{'hide-border': element == expandedElement && element?.docs?.length}">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              @if (element?.docs && element?.docs?.length) {
                <accreditation-documents-sub-table class="sub-table"
                  [data]="element.docs"
                  [tagName]="element.value"
                  [approveAccreditationDocumentFn]="approveAccreditationDocumentFn"
                  [declineAccreditationDocumentFn]="declineAccreditationDocumentFn"
                  [deleteAccreditationDocumentFn]="deleteAccreditationDocumentFn"
                  [uploadAccreditationDocumentFn]="uploadAccreditationDocumentFn"
                  [downloadAccreditationDocumentUrlFn]="downloadAccreditationDocumentUrlFn"
                  [updateAccreditationDocumentTagsFn]="updateAccreditationDocumentTagsFn"
                  (reload)="reload()"
                ></accreditation-documents-sub-table>
              }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row" [ngClass]="{'drag-focus': dragElement === element}"
          (drop)="onDrop($event, element)"
          (dragover)="onDragOver($event, element)"
          [class.example-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
</div>
