import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import { CustomerTypeValue, compareMatch } from '@portal-workspace/grow-shared-library';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FlexModule } from '@angular/flex-layout/flex';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'customer-type',
    templateUrl: './customer-type.component.html',
    styleUrls: ['./customer-type.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomerTypeComponent), multi: true },
    ],
    standalone: true,
    imports: [FlexModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatButtonToggleModule, DisableControlDirective]
})
export class CustomerTypeComponent extends AbstractControlValueAccessor<CustomerTypeValue> implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: false}) title: string | null = null;

  formGroup: FormGroup<{
    control: FormControl<CustomerTypeValue>
  }>;
  formControl: FormControl<CustomerTypeValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      control: this.formControl,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        this.propagateChange(r);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }


  doWriteValue(v: CustomerTypeValue | null): void | CustomerTypeValue {
    if (v) {
      this.formControl.setValue(v);
    }
    return undefined;
  }
}
