<div class="property-owner-with-address-component">
  <div class="row">
    <div class="col-lg-3 col-md-12">
      <yes-no
        [mark]="markObservable"
        title="Property owner"
      [formControl]="formControlPropertyOwner"></yes-no>
    </div>
  </div>
  @if (addressRequired) {
    <div class="row separator-bottom">
      <div class="col-12">
        <mat-label>Property Address</mat-label>
      </div>
    </div>
    @if (copyAddressFn) {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12" >
          <mat-checkbox color="primary" class="font-check" [formControl]="formControlCopyResidentalAddress">Same as Residental Address
          </mat-checkbox>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <custom-address
          [mark]="markObservable"
          title="Property address"
        [formControl]="formControlAddress"></custom-address>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <currency-input title="Property value"
          [mark]="markObservable"
          [formControl]="formControlPropertyValue"
          >
        </currency-input>
      </div>
      <div class="col-lg-3 col-md-12">
        <currency-input title="Mortgage balance"
          [mark]="markObservable"
          [formControl]="formControlMortgageValue"
          >
        </currency-input>
      </div>
    </div>
  }
</div>
