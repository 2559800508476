import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CommonModule} from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatInputModule} from "@angular/material/input";
import {
  formControlErrorKeys,
  formControlErrorMessage,
  pxToRem, setupUntilDestroy,
  validWhitelabelTitleAsyncValidator
} from "@portal-workspace/grow-ui-library";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {CheckIfWhitelableTitleExistsFn, compareMatch, WhitelabelTitleValue} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {delay, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {UntilDestroy} from "@ngneat/until-destroy";
import {of, Subscription} from "rxjs";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'whitelabel-title',
  templateUrl: './whitelabel-title.component.html',
  styleUrls: ['./whitelabel-title.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>WhitelabelTitleComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(()=>WhitelabelTitleComponent)},
  ],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class WhitelabelTitleComponent extends AbstractControlValueAccessor<WhitelabelTitleValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  pxToRem = pxToRem;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: false}) currentWhitelabelId?: number;
  @Input({required: true}) checkIfWhitelabelTitleExistsFn!: CheckIfWhitelableTitleExistsFn;

  formBuilder = inject(FormBuilder);
  formControl!: FormControl<WhitelabelTitleValue>;
  formGroup!: FormGroup<{
    title: FormControl<WhitelabelTitleValue>,
  }>;

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, {
      validators: [Validators.required],
    });
    this.formGroup = this.formBuilder.group({
      title: this.formControl,
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formControl.valid) {
          this.subscriptions.push(this.checkIfWhitelabelTitleExistsFn(this.formControl.value!, this.currentWhitelabelId).pipe(
            tap(v => {
              if (v) { // title exists, invalid
                this.formControl.setErrors({
                  whitelabelTitle: true,
                });
                this.propagateChange(null);
              } else {
                this.propagateChange(this.formControl.value!);
              }
            })
          ).subscribe());
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe());
  }




  override doWriteValue(v?: WhitelabelTitleValue | undefined): void | WhitelabelTitleValue {
    this.formControl.setValue(v ?? null);
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }


}
