<div class="more-information-dialog m-4">
  <h6 mat-dialog-title [ngClass]="isShowRequestReviewSection ? ['mb-0', 'pb-0'] : ''">{{ title }}</h6>

  <mat-dialog-content class="pt-2" [ngClass]="{ 'pt-2': isShowRequestReviewSection }">
    <div class="row" [ngClass]="isShowRequestReviewSection ? ['mt-1'] : ''">
      <div class="col-12">
        <custom-angular-editor
          [formControl]="formControlAdditionalInformation"
          [placeholder]="notesPlaceholder"
          [height]="editorHeight"
        ></custom-angular-editor>

        <div class="row mt-3">
          <div class="col-12">
            @if (!isExternalUser) {
              <mat-accordion>
                <mat-expansion-panel [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Notification:
                      @if (isInternalOnly) {
                        <span class="ml-2">Internal Only</span>
                      }
                      @if (isInternalOnly && submitterEmailList && submitterEmailList.length > 0) {
                        <span class="ml-1">+</span>
                        
                      }
                      @if (submitterEmailList && submitterEmailList.length > 0) {
                        <span class="ml-1">
                          ({{ submitterEmailList.length }}
                          recipient{{ submitterEmailList.length > 1 ? 's' : '' }})
                        </span>
                      }
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row mb-2">
                    <div class="col-12">
                      @if (errorMessage) {
                        <mat-error class="matError">
                          {{ errorMessage }}
                        </mat-error> 
                      }
                    </div>
                    <div class="col-12">
                      @if (isInternalUser(loggedInUser)) {
                        <mat-checkbox
                        class="mr-1"
                        color="primary"
                        [formControl]="formControlInternalOnly"
                        (change)="onCheckboxChange('internalOnly', $event)"
                      >
                        Internal Only
                      </mat-checkbox>
                      <mat-checkbox
                        class="mr-1"
                        color="primary"
                        [formControl]="formControlSubmitter"
                        (change)="onCheckboxChange('submitter', $event)"
                      >
                        Submitter
                      </mat-checkbox>
                      <mat-checkbox
                        class="mr-1"
                        color="primary"
                        [formControl]="formControlCredit"
                        (change)="onCheckboxChange('credit', $event)"
                      >
                        Credit
                      </mat-checkbox>
                      <mat-checkbox
                        class="mr-1"
                        color="primary"
                        [formControl]="formControlSales"
                        (change)="onCheckboxChange('sales', $event)"
                      >
                        Sales
                      </mat-checkbox>
                      <mat-checkbox
                        class="mr-1 mb-4"
                        color="primary"
                        [formControl]="formControlSettlements"
                        (change)="onCheckboxChange('settlements', $event)"
                      >
                        Settlements
                      </mat-checkbox>                      }
                      <br>
                      @if (isInternalUser(loggedInUser)) {
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <mat-form-field class="cc-email-chip-list">
                              <mat-label>Send to email recipients*</mat-label>
                              <mat-chip-grid #submitterChipList aria-label="email list">
                                @for (email of submitterEmailList; track email) {
                                  <mat-chip-row (removed)="removeEmail(email)">
                                    {{ email }}
                                    <button matChipRemove>
                                      <span class="mdi mdi-close"></span>
                                    </button>
                                  </mat-chip-row>
                                }
                                <input
                                  [matChipInputFor]="submitterChipList"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  [matChipInputAddOnBlur]="addOnBlur"
                                  [formControl]="formControlSubmitterEmail"
                                  (matChipInputTokenEnd)="addSubmitterEmail($event)"
                                >
                              </mat-chip-grid>
                            </mat-form-field>

                            <div
                              class="add-work-list-row row align-items-center justify-content-between"
                            >
                              <div class="col-md-6">
                                @if (submitterEmailError) {
                                  <span class="mail-error">
                                    Please enter a valid email
                                  </span>
                                }
                              </div>
                              @if (isShowRequestReviewSection) {
                                <div class="col-md-6 text-right">
                                  <mat-checkbox
                                    [formControl]="formControlAssignWorklist"
                                    color="primary"
                                  >
                                    Assign Worklist
                                  </mat-checkbox>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            }
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSubmitEnabled"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </mat-dialog-actions>
</div>