import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CustomerAccessLevelAndRoleAccessValue, EnableOverdraftUserFn, EnablePismoCustomerAccountDialogData, EnablePismoCustomerAccountDialogResult } from '@portal-workspace/grow-shared-library';
import { Subscription, forkJoin, of, tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CustomerAccessLevelAndRoleAccessComponent } from '../access-level-component/customer-access-level-and-role-access.component';


@Component({
  templateUrl: './pismo-enable-account.dialog.html',
  styleUrls: ['./pismo-enable-account.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    NgClass,
    CustomerAccessLevelAndRoleAccessComponent
]
})
export class EnablePismoCustomerAccountDialog implements OnInit {

  formGroup: FormGroup<{
    customerAccessLvel: FormControl<CustomerAccessLevelAndRoleAccessValue>
  }>;
   
  formControlCustomerAccessLevel: FormControl<CustomerAccessLevelAndRoleAccessValue>;
  errorMessage: string = '';
  subscriptions: Subscription[] = [];
  userId:number;
  pismoAccountNumber:number;
  enableOverdraftUserFn!: EnableOverdraftUserFn
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: EnablePismoCustomerAccountDialogData,
    private dialogRef: MatDialogRef<EnablePismoCustomerAccountDialog, EnablePismoCustomerAccountDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.userId = data.userId;
    this.pismoAccountNumber = data.pismoAccountNumber;
    this.enableOverdraftUserFn = data.enableOverdraftUserFn;
    this.formControlCustomerAccessLevel = formBuilder.control(null, [Validators.required])
    this.formGroup = formBuilder.group({
      customerAccessLvel: this.formControlCustomerAccessLevel
    })

  }

  ngOnInit() {
        this.subscriptions.push(this.formControlCustomerAccessLevel.valueChanges.pipe(
        ).subscribe());
  }


  onSave() {
    const customerAccessLvel = this.formControlCustomerAccessLevel.value!
    this.subscriptions.push(this.enableOverdraftUserFn(this.userId, this.pismoAccountNumber,customerAccessLvel).pipe(
    this.toastService.spinnerObservable(),
    this.toastService.snackBarObservable(`User ${this.userId} with pismo account number ${this.pismoAccountNumber} enable`),
    tap(r => {
      this.dialogRef.close({
        valid: true,
      });
    })
  ).subscribe());
  }

  onCancel(event: Event) {
    this.dialogRef.close({
      valid: false,
    });
  }
}
