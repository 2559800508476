import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {
  AggregatorAndOriginatorListValue,
  AggregatorAndOriginatorListValueGetFn,
  AggregatorValue, compareMatch, NotNullable,
  OriginatorValue
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';

import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'aggregator-and-originator-list',
    templateUrl: './aggregator-and-originator-list.component.html',
    styleUrls: ['./aggregator-and-originator-list.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AggregatorAndOriginatorListComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatTooltipModule, MatOptionModule, DisableControlDirective],
})
export class AggregatorAndOriginatorListComponent extends AbstractControlValueAccessor<AggregatorAndOriginatorListValue> implements OnInit {

  @Input({required: true}) title?: string;
  @Input({required: true}) getFn!: AggregatorAndOriginatorListValueGetFn;
  // @Input({required: false}) disable: boolean = false; NOTE: not required, done through AbstractControlValueAccessor setDisabledState(...)

  subscriptions: Subscription[] = [];

  formControl: FormControl<AggregatorAndOriginatorListValue>;

  originators: NotNullable<AggregatorAndOriginatorListValue>[] = [];

  constructor(private formBuilder:FormBuilder) {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.getFn().pipe(
      tap((r: Exclude<AggregatorAndOriginatorListValue, null>[]) => {
        if (r && r.length) {
          const acc = r.reduce((acc: { orig: NotNullable<AggregatorAndOriginatorListValue>[] }, current) => {
            if (current) {
              acc.orig.push(current);
            }
            return acc;
          }, {
            orig: []
          });
          this.originators = acc.orig;
        }
      }),
    ).subscribe();
    this.subscriptions.push(sub);


    const sub2 = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: AggregatorAndOriginatorListValue) => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe();
    this.subscriptions.push(sub2);

    // if (this.disable) {
    //       this.formControl.disable();
    // }
  }

  doWriteValue(v: AggregatorAndOriginatorListValue | null | undefined): void | AggregatorAndOriginatorListValue {
    if (v) {
      console.log('******** this originators', this.originators);
      setTimeout(()=> {
        const a  = this.originators.find(a => a.OriginatorBusinessId === v.OriginatorBusinessId);
        if (a) {
          this.formControl.setValue(a);
        }
        // if (v.kind === 'Originator') {
        //   const a  = this.originators.find(a => a.OriginatorBusinessId === v.OriginatorBusinessId);
        //   if (a) {
        //     this.formControl.setValue(a);
        //   }
        // } else if (v.kind === 'Aggregator') {
        //   const a  = this.aggregators.find(a => a.OriginatorBusinessId === v.OriginatorBusinessId);
        //   if (a) {
        //     this.formControl.setValue(a);
        //   }
        // }
      }, 5000);
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable()
  //   } else {
  //     this.formControl.enable();
  //   }
  // }
}
