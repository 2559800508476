import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  User,
  isInternalUser,
  GetUserFn,
  ApproveOrRejectUserFn,
  UpdateUserFn,
  Add2faSecretKeyFn,
  UpdateUserPasswordFn,
  DisableUsersFn,
  EnableUsersFn,
  EnableDisable2faFn,
  UserType,
  CustomerListValueGetFn,
  DEFAULT_USER_TYPE,
  UserandPriviledges,
  Verify2faTokenFn,
  Get2faTotpVerificationCodeUriFn,
  PismoUpdateUserAccessFn,
  DisableOverdraftUserFn, OverdraftAccountAvailableForUserFn, AllocateUserToPismoAccountFn,
  PismoAccountMappingWithCustomerListFn,
  PismoUpdateCustomerFn,
} from '@portal-workspace/grow-shared-library';
import {
  DisableControlDirective,
  BrokerUserDetailsComponent, CustomerUserDetailsComponent,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {
  AggregatorAndOriginatorListValueGetFn
} from '@portal-workspace/grow-shared-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import {loadingFor} from '@ngneat/loadoff';
import { Location, AsyncPipe } from '@angular/common';
import { TwoFactorAuthService } from '../../service/2fa.service';
import { MatButtonModule } from '@angular/material/button';
import { SlideToggleComponent } from '@portal-workspace/grow-ui-library';
import { AccessLevelComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AggregatorAndOriginatorListComponent } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { MatCardModule } from '@angular/material/card';
import { MessageBoxComponent, } from '@portal-workspace/grow-ui-library';
import {OverdraftCustomerService} from "../../service/overdraft-customer.service";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './user.page.html',
    styleUrls: ['./user.page.scss'],
    standalone: true,
  imports: [MessageBoxComponent, MatCardModule, NameComponent, FormsModule, ReactiveFormsModule, AggregatorAndOriginatorListComponent, MatFormFieldModule, MatInputModule, InputMaskModule, MatDividerModule, AccessLevelComponent, SlideToggleComponent, MatButtonModule, AsyncPipe, DisableControlDirective, BrokerUserDetailsComponent, CustomerUserDetailsComponent],
})
export class UserPage implements OnInit, AfterViewInit {

  subscriptions: Subscription[] = [];

  doneInit = false;
  loader = loadingFor('saving');
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  // twoFAChecked:any
  // retry(){
  //   this.reload();
  // }
  isInternalUser = isInternalUser;


  userType: UserType = DEFAULT_USER_TYPE;

  aggregatorAndOriginatorListValueGetFn!: AggregatorAndOriginatorListValueGetFn;
  customerListValueGetFn!: CustomerListValueGetFn;
  getUserFn!: GetUserFn;
  approveOrRejectFn!: ApproveOrRejectUserFn;
  updateUserFn!: UpdateUserFn;
  updatePismoCustomerFn!: PismoUpdateCustomerFn;
  add2faSecretKeyFn!: Add2faSecretKeyFn;
  enableDisable2faFn!: EnableDisable2faFn;
  verify2faTokenFn!: Verify2faTokenFn;
  get2faTotpVerificationCodeUriFn!: Get2faTotpVerificationCodeUriFn;
  updateUserPasswordFn!: UpdateUserPasswordFn;
  disableUserFn!: DisableUsersFn;
  enableUserFn!: EnableUsersFn;
  pismoUpdateUserAccessFn!: PismoUpdateUserAccessFn;
  disableOverdraftUserFn!: DisableOverdraftUserFn;
  overdraftAccountAvailableForUserFn!: OverdraftAccountAvailableForUserFn;
  allocateUserToPismoAccountFn!: AllocateUserToPismoAccountFn;
  getAllPismoWithCustomerAccountFn!: PismoAccountMappingWithCustomerListFn

  user?: UserandPriviledges;


  constructor(private activatedRoute: ActivatedRoute,
              private adminService: AdminService,
              private authService: AuthService,
              private TwoFA: TwoFactorAuthService,
              private overdraftService: OverdraftCustomerService,
              private _location: Location) {

    this.aggregatorAndOriginatorListValueGetFn = this.adminService.getAggregatorsAndOriginatorsFn;
    this.customerListValueGetFn =  this.adminService.getCustomerListFn;
    this.getUserFn = this.authService.getUserFn;
    this.approveOrRejectFn = this.authService.approveOrRejectUserFn;
    this.updateUserFn = this.authService.updateUserFn;
    this.updatePismoCustomerFn = this.overdraftService.updatePismoCustomerFn;
     
    this.add2faSecretKeyFn = this.TwoFA.add2faSecretKeyFn;
    this.enableDisable2faFn = this.TwoFA.enableDisable2faFn;
    this.verify2faTokenFn = this.TwoFA.verify2faTokenFn;
    this.get2faTotpVerificationCodeUriFn = this.TwoFA.get2faTotpVerificationCodeUriFn;
    this.updateUserPasswordFn = this.authService.updateUserPasswordFn;
    this.disableUserFn = this.authService.disableUsersFn;
    this.enableUserFn = this.authService.enableUsersFn;
    this.pismoUpdateUserAccessFn = this.overdraftService.pismoUpdateUserAccessFn;
    this.disableOverdraftUserFn = this.overdraftService.disableOverdraftUserFn;
    this.overdraftAccountAvailableForUserFn = this.overdraftService.overdraftAccountAvailableForUserFn;
    this.allocateUserToPismoAccountFn = this.overdraftService.allocateUserToPismoAccountFn;
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = (this.activatedRoute.snapshot.data as any).user;
    this.userType = this.activatedRoute.snapshot.params.userType ?? DEFAULT_USER_TYPE;
    this.doneInit = true;
  }

  async onClickBack() {
    this._location.back()
  }
}
