<div class="decision-metrics">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
    @for (column of columnsToDisplay; track column) {
      <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'text-align-right': needAlignRight(column)}">
          <div class="cell">{{getColumnTitles(column)}}</div>
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
          @if (needCurrencyPipe(element)) {
            <div class="cell">{{element[column] | looseCurrency}}</div>
          }
          @if (!needCurrencyPipe(element)) {
            <div class="cell">{{element[column]}}</div>
          }
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"></tr>
  </table>
</div>
