import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {ConfigurationService} from '../service/configuration.service';
import {inject} from '@angular/core';
import {getUser,} from '@portal-workspace/grow-ui-library';
import {isInternalUser} from '@portal-workspace/grow-shared-library';
import {map} from 'rxjs/operators';
import {navigationUrlForMaintenance} from '../service/navigation-urls';


export const maintenanceModeGuard = () => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {

    const configurationService = inject(ConfigurationService);
    const router = inject(Router);

    const user = getUser();
    if (user) {
      if (isInternalUser(user)) {
        return true;
      }
      // external user, only allow when we are not in maintenance mode
      return configurationService.isMaintenanceMode().pipe(
        map(c => {
          if (c) {
            const isMaintenance = c.payload;
            const canActivate = !isMaintenance;
            if (isMaintenance) {
              return router.navigate(navigationUrlForMaintenance());
            }
            return canActivate;
          }
          return true;
        })
      );
    }
    return true;
  }
}


// NOTE: Depreacted since Angular 16
// @UntilDestroy({arrayName: 'subscriptions'})
// @Injectable()
// export class MaintenanceModeGuard  implements OnInit {
//
//   subscriptions: Subscription[] = [];
//
//   constructor(private configurationService: ConfigurationService,
//               private router: Router) {
//   }
//
//   ngOnInit(): void {
//     setupUntilDestroy(this);
//   }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     const user = getUser();
//     if (user) {
//       if (isInternalUser(user)) {
//         return true;
//       }
//       // external user, only allow when we are not in maintenance mode
//       return this.configurationService.isMaintenanceMode().pipe(
//         map(c => {
//           if (c) {
//             const isMaintenance = c.payload;
//             const canActivate = !isMaintenance;
//             if (isMaintenance) {
//               return this.router.createUrlTree(navigationUrlForMaintenance());
//             }
//             return canActivate;
//           }
//           return true;
//         })
//       );
//     }
//     return true;
//   }
// }
