import {Component, forwardRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BreakpointService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {BalloonPaymentValue, BalloonPaymentValueOptions, compareMatch} from '@portal-workspace/grow-shared-library';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'balloon-payment',
    templateUrl: './balloon-payment.component.html',
    styleUrls: ['./balloon-payment.component.scss'],
    exportAs: 'balloonPaymentComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BalloonPaymentComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BalloonPaymentComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatButtonToggleModule, DirectiveLibraryModule]
})
export class BalloonPaymentComponent extends AbstractControlValueAccessor<BalloonPaymentValue> implements OnInit, Mark, OnChanges {

  @Input({required: false}) maxRv: number | null = null;

  vertical = false // vertical button layout

  formControl: FormControl<BalloonPaymentValue>;
  formGroup: FormGroup<{
    selection: FormControl<BalloonPaymentValue>,
  }>;
  subscriptions: Subscription[] = [];

  options = [...BalloonPaymentValueOptions].map(r => {
    return {
      ...r,
      disabled: false
    }
  });

  breakpointService: BreakpointService = inject(BreakpointService);
  formBuilder: FormBuilder = inject(FormBuilder);

  constructor()  {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      selection: this.formControl,
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: BalloonPaymentValue) => {
        this.propagateChange(r);
      })
    ).subscribe());
    this.subscriptions.push(this.breakpointService.deviceSubject.pipe(
      tap(device => {
        this.vertical = device === "Mobile";
      })
    ).subscribe());

  }

  setMaxRv(maxRv: number) { // maxRV
    let lastOption = this.options[0];
    this.options.forEach(opt => {
      if (parseInt(opt.type) > maxRv) {
        (opt as any).disabled = true;
      } else {
        (opt as any).disabled = false;
        lastOption = opt;
      }
    });
    const v: BalloonPaymentValue = this.formControl.value;
    if (v && parseInt(v.type) > maxRv) {
      if (lastOption) {
        this.formControl.setValue(lastOption);
        this.propagateChange(lastOption);
      }
    }
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    const maxRvChange = changes['maxRv'];
    if (maxRvChange && maxRvChange.currentValue != null) {
      const maxRv = maxRvChange.currentValue;
      this.setMaxRv(maxRv);
    }
  }

  doWriteValue(v: BalloonPaymentValue): void {
    if (v) {
      const op = this.options.find(opt => opt.type === v.type);
      if (op) {
        this.formControl.setValue(op);
      }
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

}
