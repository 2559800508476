import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  Application,
  FirstOverdraftUserDialogData,
  FirstOverdraftUserDialogResult,
  NameComponentValue,
  DatepickerValue,
  Address2ComponentValue,
  EmailComponentValue,
  MobileValue,
  IndividualWithResult,
  DigitalIdGetApplicationIndividualsFn,
  GetApplicationindividualsPayload,
  CreateOverdraftUserFn,
  CustomerUser,
  DigitalIdResult,
  CreateOverdraftUserResponse,
  PayloadApiResponse,
  DigitalIdResponse,
  PismoCheckEmailExistsFn, CustomerAccessLevelValue,
  CustomerAccessLevelAndRoleAccessValue,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatChipsModule } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTableModule } from '@angular/material/table';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexModule } from '@angular/flex-layout/flex';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet, DecimalPipe } from '@angular/common';
import { ApplicationStepper2Component } from '../application-stepper-component/application-stepper2.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { NameComponent } from '../name-component/name.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { EmailComponent } from '../common fields/email.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { DriverLicenceComponent } from '../driver-licence-component/driver-licence.component';
import { DriverLicenceCardNumberComponent } from '../driver-licence-card-number-component/driver-licence-card-number.component';
import {
    setupUntilDestroy,
    PortalHotToastService,
    ApplicationDialogService,
    formControlErrorKeys, formControlErrorMessage,
    individualDob,
    individualResidentialAddress,
    validEmailValidator,
    setStepper2StepConfig
} from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {CustomerAccessLevelAndRoleAccessComponent } from '../access-level-component/customer-access-level-and-role-access.component';

@UntilDestroy()
@Component({
    templateUrl: './first-overdraft-user.dialog.html',
    styleUrls: ['./first-overdraft-user.dialog.scss'],
    standalone: true,
  imports: [
    ApplicationStepper2Component,
    CdkStepperModule,
    MessageBoxComponent,
    MatOptionModule,
    DriverLicenceComponent,
    DriverLicenceCardNumberComponent,
    MatSelectModule,
    NgTemplateOutlet,
    MarkDirective,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    InputMaskModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    PdfViewerModule,
    DecimalPipe,
    LooseCurrencyPipe,
    MatDialogModule,
    NameComponent,
    DatepickerComponent,
    CustomAddressComponent,
    EmailComponent,
    MobileComponent,
    CustomerAccessLevelAndRoleAccessComponent
]
})
export class FirstOverdraftUserDialog implements OnInit{

  emailValidators: ValidatorFn[];
  // WEB-4399: email and pismoAccountNumber combination needs to be unique not email
  //           the same email can be associated with different pismoAccountNumber
  // emailAsyncValidators: AsyncValidatorFn[];
  subscriptions: Subscription[] = [];
   
    
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  application: Application | null = null;
  overdraftUserSelections: IndividualWithResult[]  = [];
  pismoAccountNumbers: number[] = [];
  customer!: CustomerUser;
  getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  createOverdraftUserFn!: CreateOverdraftUserFn;
  createOverdraftUserResponse!: CreateOverdraftUserResponse;
  digitalIdResult!: DigitalIdResult;
  pismoCheckEmailExistsFn!: PismoCheckEmailExistsFn;

  // overdraft new user
  formGroupStep1!: FormGroup<{
    email: FormControl<EmailComponentValue>,
  }>;
  formGroupStep2!: FormGroup<{
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    middleName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    address: FormControl<Address2ComponentValue>,
    // email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    driverLicenceNumber: FormControl<string | null>,
    driverLicenceExpiryDate: FormControl<DatepickerValue>,
    driverLicenceCardNumber: FormControl<string | null>,
    pismoAccountNumber: FormControl<number | null>,
    accessLevel: FormControl<CustomerAccessLevelAndRoleAccessValue>,
  }>;
  formGroupStep3!: FormGroup;
  formControlOverdraftUserSelection!: FormControl<IndividualWithResult | null>;
  formControlPismoAccountNumber!: FormControl<number | null>;
  formControlAccessLevel!: FormControl<CustomerAccessLevelAndRoleAccessValue>;
  formControlOverdraftUserFirstName!: FormControl<NameComponentValue>;
  formControlOverdraftUserLastName!: FormControl<NameComponentValue>;
  formControlOverdraftUserMiddleName!: FormControl<NameComponentValue>;
  formControlOverdraftUserDob!: FormControl<DatepickerValue>;
  formControlOverdraftUserAddress!: FormControl<Address2ComponentValue>;
  formControlOverdraftUserEmail!: FormControl<EmailComponentValue>;
  formControlOverdraftUserMobile!: FormControl<MobileValue>;
  formControlOverdraftUserDriverLicenceNumber!: FormControl<string | null>;
  formControlOverdraftUserDriverLicenceExpiryDate!: FormControl<DatepickerValue>;
  formControlOverdraftUserDriverLicenceCardNumber!: FormControl<string | null>;
  formControlOverdraftUserKycVerification!: FormControl<DigitalIdResponse | null>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FirstOverdraftUserDialogData,
    private dialogRef: MatDialogRef<FirstOverdraftUserDialog, FirstOverdraftUserDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.emailValidators = [Validators.required, Validators.email];
    // this.emailAsyncValidators = [validEmailValidator(data.validEmailCheckFn)];

    this.application = data.application;
    this.pismoAccountNumbers = data.pismoAccountNumbers;
    this.customer = data.customer;
    this.getApplicationIndividualsFn = data.getApplicationIndividualsFn;
    this.createOverdraftUserFn = data.createOverdraftUserFn;
    this.pismoCheckEmailExistsFn = data.pismoCheckEmailExistsFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.init();
    this.populate();
  }

  init() {
    // step 1
    this.formControlOverdraftUserFirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftUserLastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftUserMiddleName = this.formBuilder.control(null);
    this.formControlOverdraftUserDob = this.formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftUserAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftUserEmail = this.formBuilder.control(null, this.emailValidators);
    this.formControlOverdraftUserMobile = this.formBuilder.control(null, [Validators.required]);
    this.formControlOverdraftUserDriverLicenceNumber = this.formBuilder.control(null);
    this.formControlOverdraftUserDriverLicenceExpiryDate = this.formBuilder.control(null);
    this.formControlOverdraftUserDriverLicenceCardNumber = this.formBuilder.control(null);
    this.formControlOverdraftUserKycVerification = this.formBuilder.control(null);
    this.formControlOverdraftUserSelection = this.formBuilder.control(null);
    this.formControlPismoAccountNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlAccessLevel = this.formBuilder.control(null, [Validators.required]);

    this.formGroupStep1 = this.formBuilder.group({
      email: this.formControlOverdraftUserEmail,
    })

    
    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: async stepper => {
        this.formGroupStep1.markAllAsTouched();
        this.onCheckEmail(stepper);
      },
      nextStepButtonText:"Next",
      previousStepButtonText:"Close",
      previousStepClickedFn: async (stepper) => {
        this.onClickClose()
      }
    });


    // step 2:
    this.formGroupStep2 = this.formBuilder.group({
      firstName: this.formControlOverdraftUserFirstName,
      lastName: this.formControlOverdraftUserLastName,
      middleName: this.formControlOverdraftUserMiddleName,
      dob: this.formControlOverdraftUserDob,
      address: this.formControlOverdraftUserAddress,
      // email: this.formControlOverdraftUserEmail,
      mobile: this.formControlOverdraftUserMobile,
      driverLicenceNumber: this.formControlOverdraftUserDriverLicenceNumber,
      driverLicenceExpiryDate: this.formControlOverdraftUserDriverLicenceExpiryDate,
      driverLicenceCardNumber: this.formControlOverdraftUserDriverLicenceCardNumber,
      pismoAccountNumber: this.formControlPismoAccountNumber,
      accessLevel: this.formControlAccessLevel,
    })
     
     
    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        this.formGroupStep2.markAllAsTouched();
        this.onCreateUser(stepper);
      },
      nextStepButtonText:"Create User",
    });

    // step 3
    this.formGroupStep3 = this.formBuilder.group({})
    setStepper2StepConfig(this.formGroupStep3, {
      nextStepClickedFn: async stepper => {
        this.dialogRef.close(true);
      },
      nextStepButtonText:"Got it",
    });
  }

  populate() {
    if (this.application) {
      this.subscriptions.push(
        this.getApplicationIndividualsFn(this.application.ApplicationId).subscribe(
          (result: GetApplicationindividualsPayload | null) => {
            if (result) {
              this.overdraftUserSelections = result?.individuals?.filter(i => i.SignerRole === 'GuarantorSigner' && !i.deleted) ?? [];
              if (this.overdraftUserSelections.length) {
                this.formControlOverdraftUserSelection.setValue(this.overdraftUserSelections[0])
              }
            }
          }
        )
      )
    }


    this.subscriptions.push(
      this.formControlOverdraftUserSelection.valueChanges.subscribe((individual: IndividualWithResult | null) => {
        if (individual) {
          this.formControlOverdraftUserFirstName.setValue(individual.GivenName);
          this.formControlOverdraftUserLastName.setValue(individual.SurName);
          this.formControlOverdraftUserMiddleName.setValue(individual.MiddleName);
          this.formControlOverdraftUserDob.setValue(individualDob(individual));
          this.formControlOverdraftUserAddress.setValue(individualResidentialAddress(individual));
          this.formControlOverdraftUserEmail.setValue(individual.Email);
          this.formControlOverdraftUserMobile.setValue(individual.MobileNumber);
          this.digitalIdResult = individual.result;
          this.formControlOverdraftUserKycVerification.setValue(this.digitalIdResult ? this.digitalIdResult.status !== 'RECEIVED_ERROR' ? this.digitalIdResult.digitalIdResponse : null:null);
        }
      })
    )
  }

  onClickClose() {
    this.dialogRef.close();
  }

  onCheckEmail(stepper: CdkStepper){
    // NOTE: form control for email will be valid at this stage
    this.formGroupStep1.markAllAsTouched();
    const email = this.formControlOverdraftUserEmail.value!;
    const pismoAccountNumber = this.pismoAccountNumbers[0];
    if (!pismoAccountNumber) {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'No pismo account number was provided',
      });
      return;
    }
    this.subscriptions.push(
      this.pismoCheckEmailExistsFn(email, pismoAccountNumber).pipe(
        this.toastService.spinnerObservable()
      ).subscribe((response: PayloadApiResponse<{status: boolean}>) => {
        if (response.status) {
          stepper.next()
        } else {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: `The email ${email} has already been used for another Pismo customer in Pismo Account ${pismoAccountNumber}`
          })
        }
      })
    )
  }

  onCreateUser(stepper: CdkStepper) {
    this.formGroupStep2.markAllAsTouched();
    const accessLevel = this.formControlAccessLevel.value;
     
    this.subscriptions.push(
      this.createOverdraftUserFn({
        customerUser: {
          GivenName: this.formControlOverdraftUserFirstName.value ?? '',
          FamilyName: this.formControlOverdraftUserLastName.value ?? '',
          customerAccessLevel: accessLevel?.customerAccessLevel?? 'card-member',
          RoleAccess: accessLevel?.roleAccess?? {"isAllTransaction":false,"isThirdPartyPayment":false,"isBpay":false},
          Email: this.formControlOverdraftUserEmail.value ?? '',
          CustomerId: this.customer.CustomerId,
          IsCustomer: true,
          MiddleName: this.formControlOverdraftUserMiddleName.value ?? '',
          MobileNumber: this.formControlOverdraftUserMobile.value ?? '',
          Password: '',
          dob: this.formControlOverdraftUserDob.value ? this.formControlOverdraftUserDob.value.format('YYYY-MM-DD') : undefined,
          ResidentialAddress: this.formControlOverdraftUserAddress.value,
          PostalAddress: this.formControlOverdraftUserAddress.value,
          kycStatus: this.digitalIdResult?.status ?? undefined,
          kycVerificationResult: this.formControlOverdraftUserKycVerification.value ?? undefined,
          kycVerificationError: this.digitalIdResult?.status === 'RECEIVED_COMPLETE'
            ? undefined : this.formControlOverdraftUserKycVerification.value as unknown as string
        },
        pismoAccountNumber: this.formControlPismoAccountNumber.value!,
        entityName:this.application?.entityName ?? ''
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe((response: PayloadApiResponse<CreateOverdraftUserResponse>) => {
        console.log(response)
        if (response.status) {
          this.createOverdraftUserResponse = response.payload;
          stepper.next()
        } else if(response.message){
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: response.message
          })
        }else {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: 'Please try again'
          })
        }

      })
    )
  }

}
