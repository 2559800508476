import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  Address2ComponentValue, BusinessNumberSearchValue,
  DatepickerValue,
  EmailComponentValue, GuarantorValue, MobileValue,
  NameComponentValue, SignerRoleTypes, ThirdPartyEntity,
  TitleSelectionValue
} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {YesNoValue} from '@portal-workspace/grow-shared-library';
import {GenderValue} from '@portal-workspace/grow-shared-library';
import {
  createEmailInputMask,
  createPhoneNumberInputMask,
  duplicateIndividualEmailValidator, duplicateIndividualMobileValidator,
  formControlErrorKeys, formControlErrorMessage, MobileComponentEvent
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription, combineLatest } from 'rxjs';
import {tap} from 'rxjs/operators';
import {
  IndividualFormDialogData,
  IndividualFormDialogResult
} from '@portal-workspace/grow-shared-library';
import { EmailComponentEvent, EmailComponent } from '../common fields/email.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MobileComponent } from '../mobile-component/mobile.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NameComponent } from '../name-component/name.component';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { BusinessNumberSearchComponent } from '../business-number-search-component/business-number-search.component';

import { MatCheckboxModule } from '@angular/material/checkbox';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './individual-form.dialog.html',
    styleUrls: ['./individual-form.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule, BusinessNumberSearchComponent, TitleSelectionComponent, NameComponent, MatFormFieldModule, MatInputModule, DatepickerComponent, GenderComponent, CustomAddressComponent, MatSelectModule, MatOptionModule, EmailComponent, MobileComponent, FlexModule, MatButtonModule]
})
export class IndividualFormDialog implements OnInit {

  duplicateEmailValidator!: ValidatorFn;
  duplicateMobileValidator!: ValidatorFn;

  emailComponentErrors: ValidationErrors | null = null;   // hack: to access Email component errors
  mobileComponentErrors: ValidationErrors | null = null;  // hack: to access Mobile component errors

  subscriptions: Subscription[] = [];
  mandatoryField !: boolean

  formGroup: FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    middleName: FormControl<string | null>,
    lastName: FormControl<NameComponentValue>,
    residentialAddress: FormControl<Address2ComponentValue>,
    dob: FormControl<DatepickerValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    role: FormControl<SignerRoleTypes>,
    // guarantor: FormControl<YesNoValue>,
    gender: FormControl<GenderValue>,
    isThirdPartyEntity: FormControl<boolean | null>,
    thirdPartyEntity: FormControl<BusinessNumberSearchValue>,
  }>;
  formControlTitle: FormControl<TitleSelectionValue>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlMiddleName: FormControl<string | null>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlResidentialAddress: FormControl<Address2ComponentValue>;
  formControlDob: FormControl<DatepickerValue>;
  formControlEmail: FormControl<EmailComponentValue>;
  formControlMobile: FormControl<MobileValue>;
  formControlSignerRole: FormControl<SignerRoleTypes>;
  formControlIsThirdPartyEntity: FormControl<boolean | null>;
  formControlThirdPartyEntity: FormControl<BusinessNumberSearchValue>;
  // formControlGuarantor: FormControl<GuarantorValue>;
  formControlGender: FormControl<GenderValue>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IndividualFormDialogData,
              private matDialogRef: MatDialogRef<IndividualFormDialog, IndividualFormDialogResult>,
              private formBuilder: FormBuilder) {
    this.formControlTitle = formBuilder.control(null, [Validators.required]);
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(null);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlResidentialAddress = formBuilder.control(null, [Validators.required]);
    this.formControlDob = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null);
    this.formControlMobile = formBuilder.control(null);
    this.formControlSignerRole = formBuilder.control(null, [Validators.required]);
    this.formControlIsThirdPartyEntity = formBuilder.control(false);
    this.formControlThirdPartyEntity = formBuilder.control(null);
    // this.formControlGuarantor = formBuilder.control(false, [Validators.required]);
    this.formControlGender = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      title: this.formControlTitle,
      firstName: this.formControlFirstName,
      middleName: this.formControlMiddleName,
      lastName: this.formControlLastName,
      residentialAddress: this.formControlResidentialAddress,
      dob: this.formControlDob,
      email: this.formControlEmail,
      mobile: this.formControlMobile,
      role: this.formControlSignerRole,
      isThirdPartyEntity: this.formControlIsThirdPartyEntity,
      thirdPartyEntity: this.formControlThirdPartyEntity,
      // guarantor: this.formControlGuarantor,
      gender: this.formControlGender,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.duplicateEmailValidator = duplicateIndividualEmailValidator(this.data.existingIndividuals);
    this.duplicateMobileValidator = duplicateIndividualMobileValidator(this.data.existingIndividuals);
    this.subscriptions.push(this.formControlIsThirdPartyEntity.valueChanges.pipe(
      tap(r => {
        if (r) {
          this.formControlThirdPartyEntity.setValidators([Validators.required]);
        } else {
          this.formControlThirdPartyEntity.clearValidators();
        }
        this.formControlThirdPartyEntity.updateValueAndValidity();
      })
    ).subscribe());

    const sub= combineLatest([
      this.formControlIsThirdPartyEntity.valueChanges,
      this.formControlSignerRole.valueChanges
    ]).pipe(
      tap((r) => {
          if (r[0] === false && r[1] === 'Others') {
            this.formControlEmail.removeValidators(Validators.required);
            this.formControlMobile.removeValidators(Validators.required);
            this.mandatoryField = false
          } else {
            this.formControlEmail.addValidators(Validators.required)
            this.formControlMobile.addValidators(Validators.required);
            this.mandatoryField = true
          }
          this.formControlEmail.updateValueAndValidity();
          this.formControlMobile.updateValueAndValidity();
      })
    ).subscribe();
    this.subscriptions.push(sub);

    this.subscriptions.push(this.formControlSignerRole.valueChanges.pipe(
        tap(r => {
          if (r) {
            if (this.formControlIsThirdPartyEntity.value === false && r === 'Others') {
              this.formControlEmail.removeValidators(Validators.required);
              this.formControlMobile.removeValidators(Validators.required);
              this.mandatoryField = false
            } else {
              this.formControlEmail.addValidators(Validators.required)
              this.formControlMobile.addValidators(Validators.required);
              this.mandatoryField = true
            }
            this.formControlEmail.updateValueAndValidity();
            this.formControlMobile.updateValueAndValidity();
          }
        })
      ).subscribe());

    // Note: WEB-2335 The document generation dialog should not allow generation if email and mobile are missing
    // this.subscriptions.push(this.formControlSignerRole.valueChanges.pipe(
    //   tap(r => {
    //     if (r) {
    //       if (r === 'Guarantor' || r === 'GuarantorSigner') {
    //         this.formControlEmail.addValidators(Validators.required);
    //         this.formControlMobile.addValidators(Validators.required);
    //       } else {
    //         this.formControlEmail.removeValidators(Validators.required)
    //         this.formControlMobile.removeValidators(Validators.required);
    //       }
    //       this.formControlEmail.updateValueAndValidity();
    //       this.formControlMobile.updateValueAndValidity();
    //     }
    //   })
    // ).subscribe());
  }

  onCancel() {
    this.matDialogRef.close({ cancelled: true, valid: this.formGroup.valid });
  }

  onSubmit() {
    // NOTE: when the formGroup is valid, properties under it should not be null
    const result = (this.formControlThirdPartyEntity.value)?.result;
    const businessReport = result ? result['australian-business-register-report'] : null;
    const thirdPartyEntity: ThirdPartyEntity | undefined = businessReport && businessReport.type == 'result' && this.formControlThirdPartyEntity.value && this.formControlIsThirdPartyEntity.value ? {
      abn: this.formControlThirdPartyEntity.value?.abn ?? "",
      acn: this.formControlThirdPartyEntity.value?.acn ?? "",
      address: `${businessReport?.BusinessAddressState ?? ""}, ${businessReport?.BusinessAddressPostcode ?? ""}`,
      organisationName: this.formControlThirdPartyEntity.value?.name ?? "",
    } : undefined;
    const i: IndividualFormDialogResult = {
      cancelled: false,
      valid: this.formGroup.valid,
      individual: {
        title: this.formControlTitle.value!,
        dob: this.formControlDob.value!,
        gender: this.formControlGender.value!,
        lastName: this.formControlLastName.value!,
        firstName: this.formControlFirstName.value!,
        residentialAddress: this.formControlResidentialAddress.value!,
        email: this.formControlEmail.value ?? '',
        mobile: this.formControlMobile.value ?? '',
        middleName: this.formControlMiddleName.value ?? '',
        role: (this.formControlSignerRole.value == 'Guarantor' || this.formControlSignerRole.value == 'GuarantorSigner' ? 'Guarantor' : null),
        signerRole: this.formControlSignerRole.value,
        guarantor: (this.formControlSignerRole.value == 'Guarantor' || this.formControlSignerRole.value == 'GuarantorSigner' ? true : false),
        thirdPartyEntity,
      }
    };
    this.matDialogRef.close(i);
  }


  disableSubmission(): boolean {
    // NOTE: formGroup can be valid (no validators set for it)
    //       when email / mobile have eg duplication errors
    //       where we want to disable to submission button
    return (
      this.formGroup.invalid ||
      this.emailComponentErrors != null ||
      this.mobileComponentErrors != null
    );
  }

  onEmailEvent($event: EmailComponentEvent) {
    this.emailComponentErrors = $event.errors;
  }

  onMobileEvent($event: MobileComponentEvent) {
    this.mobileComponentErrors = $event.errors;
  }
}
