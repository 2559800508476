
export type AuditLogContext = 'Broker' | 'Auth' | 'Salesforce' | 'Cron' | 'System' | 'Application' | 'OFAC' | 'Pismo' | 'User' | 'Overdraft' | 'KycVerification' | 'Accreditation';
export type AuditLogSubContext<C extends AuditLogContext> =
  C extends 'Broker' ? 'ApplicationUpdate' :
  // C extends 'TradeCustomer' ? 'CustomerDetails' | 'DeclineDrawdown' | 'ApproveDrawdown' | 'ExtendApprovedDrawdown' | 'RejectApprovedDrawdown' | 'RemovePaywayTransaction' :
  C extends 'Auth' ? 'Login' | 'JWTTokenExpired' | 'JWTTokenInvalid' | 'Logout' | 'InvalidExpoAppId' | 'RefreshJWTToken' :
  C extends 'Salesforce' ? 'StageChange' | 'Contact' :
  C extends 'Cron' ? 'syncOfac' | 'fetchRbaRate' | 'refreshDocusignToken' | 'syncSuppliers' | 'businessNews' | 'syncDocuments'| 'cronFetchPaymentResponse' | 'cronGenerateBatchPayment' | 'cronChargeAnnualFee' | 'ofacReport' | 'cronHandleAbaTransactions' :
  C extends 'System' ? 'sendVerificationSMS':
  C extends 'Application' ? 'PPSRRegistered' | 'NonStandardCondition' | 'DeletedDraft' | 'RateChange' | 'NewVerimotoInspection':
  // C extends 'Pismo' ? 'PaymentLimit' | 'ManualTransaction' :
  C extends 'User' ? 'UserDetailsChange' :
  C extends 'Overdraft' ? 'createOverdraftAccount' | 'createOverdraftUser' | 'postPayout' | 'postFacilityEstablishmentFee' | 'postBrokerage' | 'postDocumentationFee' | 'postDocumentationFeeReversal' | 'transferredBalance' | 'manualTransaction' | 'changeAccountStatus' | 'postSecurityAndLegalFee' | 'paymentLimit' | 'updateAccountLimit' | 'updateCustomer' | 'changeCardStatus' | 'reissueCard' | 'updateRequireMonthlyFixedInstallmentFlag' | 'sendPayoutEstimation' | 'generatePayout' | 'cardEmbossing' | 'disablePismoCustomer' | 'increaseAccountLimit' | 'manualDirectDebit' | 'updateMonthlyFacilityFee' | 'updateStopDirectDebitFlag':
  C extends 'KycVerification' ? 'mobile-verification' | 'portal-verification' | 'admin-verification' :
  C extends 'Accreditation' ? 'approveAccreditation' | 'declineAccreditation' :
  never
;


export interface AuditLog<T extends AuditLogContext = AuditLogContext> {
  id: number,
  user: {
    userId: number,
    name: string,
    email: string,
  } | null,
  creationDate: string,
  context: T,
  subContext: AuditLogSubContext<T>,
  message: string,
}
