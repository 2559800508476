import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { createCurrencyInputMask, doMarkAll, markTriggerSubject } from '@portal-workspace/grow-ui-library';
import {
  AssetCategoryType,
  getAssetCategoryTypes,
  getCommercialOnlyAssetCategoryTypes,
  LoanTermValueOptions
} from '@portal-workspace/grow-shared-library';
import { CurrencyInputValue } from '@portal-workspace/grow-shared-library';
import { LoanTermsSelectionComponent, } from '@portal-workspace/grow-ui-library';
import { LoanTermValue } from '@portal-workspace/grow-shared-library';
import { AssetCategoryTypeSelectionComponent, } from '@portal-workspace/grow-ui-library';
import { AssetCategoryTypeSelectionValue } from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { AssetCategoryTypeSelectionComponent as AssetCategoryTypeSelectionComponent_1 } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { CurrencyPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoanTermsSelectionComponent as LoanTermsSelectionComponent_1 } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { MatCardModule } from '@angular/material/card';
import { DataBoxComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';

export interface TableElement {
  customerRate: string;
  rate: string;
}
interface AppType {
  value: 'consumer' | 'commercial';
  viewValue: string;
}

const appType: AppType[] = [
  { value: 'consumer', viewValue: 'Residential' },
  { value: 'commercial', viewValue: 'Commercial' },
];

const RatesForLoanTerms: {
  term: number, rate: number,
}[] = [
    { term: 84, rate: 0 },
    { term: 72, rate: 0 },
    { term: 60, rate: 0 },
    { term: 48, rate: 0 },
    { term: 36, rate: 0 },
    { term: 24, rate: 0 },
  ];

const RatesForInvoiceAmount: {
  invoiceAmount: number, rate: number
}[] = [
    { invoiceAmount: 150000.00, rate: -0.15 },
    { invoiceAmount: 39999.99, rate: -0.15 },
    { invoiceAmount: 2999.99, rate: 0 },
  ];



@Component({
    templateUrl: './lg-rebate-calculator.page.html',
    styleUrls: ['./lg-rebate-calculator.page.scss'],
    standalone: true,
    imports: [FlexModule, DataBoxComponent, MatCardModule, FormsModule, ReactiveFormsModule, CurrencyInputComponent, MarkDirective, LoanTermsSelectionComponent_1, MatFormFieldModule, MatSelectModule, MatOptionModule, AssetCategoryTypeSelectionComponent_1, MatButtonModule, CurrencyPipe, LooseCurrencyPipe]
})
export class LgRebateCalculatorPage implements OnInit {

  markTriggerSubject = markTriggerSubject;
  @ViewChildren(LoanTermsSelectionComponent) childLoanTerms !: QueryList<LoanTermsSelectionComponent>
  @ViewChildren(AssetCategoryTypeSelectionComponent) childAssetCategory !: QueryList<AssetCategoryTypeSelectionComponent>


  loader = false;
  // monthlyRepayment: number = 0;
  customerRate: number = 0;
  totalRebate: number = 0;
  lgRebateShare: number = 0;
  dealerRebateShare: number = 0;

  formGroup: FormGroup<{
    invoiceAmount: FormControl<CurrencyInputValue>,
    loanTerms: FormControl<LoanTermValue>,
    applicationType: FormControl<AppType | null>,
    assetType: FormControl<AssetCategoryTypeSelectionValue>
  }>;
  formControlInvoiceAmount: FormControl<CurrencyInputValue>;
  formControlLoanTerms: FormControl<LoanTermValue>;
  formControlApplicationType: FormControl<AppType | null>;
  formControlAssetType: FormControl<AssetCategoryTypeSelectionValue>;

  appType = appType;
  displayedColumns: string[] = ['customerRate', 'rate'];
  // dataSource = DEFAULT_DATA;

  assetCatTypes!: AssetCategoryType[];
  loanInvoiceAmount!: number;
  terms: any;
  termsBaseRate = 7.50;
  financeRateArray: any = [
    0.00,
    -0.15,
    -0.15
  ]
  rate!:number;
  totalGrowRebate!: number;
  dealerRebateRate: number = 0;
  lgRebateRate!: number;
  repayment: number = 0;
  documentationFee!: string;
  submitted:boolean=false;

  constructor(private formBuilder: FormBuilder,private dialogService: ApplicationDialogService) {
    this.assetCatTypes = getCommercialOnlyAssetCategoryTypes();
    this.formControlInvoiceAmount = formBuilder.control(null, [Validators.required]);
    this.formControlLoanTerms = formBuilder.control(null, [Validators.required]);
    this.formControlApplicationType = formBuilder.control(null, [Validators.required]);
    this.formControlAssetType = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      invoiceAmount: this.formControlInvoiceAmount,
      loanTerms: this.formControlLoanTerms,
      applicationType: this.formControlApplicationType,
      assetType: this.formControlAssetType,
    });
  }


  ngOnInit() {
  }

  onEstimate($event: Event) {
    this.loader = true
    markTriggerSubject(this.formGroup).next(true);
    doMarkAll(this.formGroup);
    if (this.formGroup.invalid) {
      this.loader = false
      this.dialogService.openAlertDialog({
        message: 'Missing Information',
        subMessage: 'Make sure all fields are filled in.',
      });
    }else{
    this.loader = true

    this.submitted=true;
    const invoiceAmount: Exclude<CurrencyInputValue, null> = this.formControlInvoiceAmount.value ?? 0;
    const loanTerms: Exclude<LoanTermValue, null> = this.formControlLoanTerms.value ?? LoanTermValueOptions[0];
    const applicationType: AppType = this.formControlApplicationType.value ?? appType[0];
    const assetType: Exclude<AssetCategoryTypeSelectionValue, null> = this.formControlAssetType.value!;
    let customerRate = 0;
      if (applicationType.value === 'consumer' && assetType.index === '2') { // lg-panels
        this.customerRate = 4.99;
      }
      if (applicationType.value === 'commercial' && assetType.index === '2') { // lg-panels
        this.customerRate = 5.99
      }

      if (applicationType.value === 'commercial' && assetType.index === '4') { // non-lg-panels
        this.customerRate = 7.50;
      }
      if (applicationType.value === 'consumer' && assetType.index === '4') { // non-lg-panels
        this.customerRate = 8;
      }
      if (assetType && assetType.index === '3') { // lg-mono-panels
        this.customerRate = 7.50;
      }
      this.loanInvoiceAmount = +invoiceAmount;
      this.terms = loanTerms.type;
      this.calculatePMT((this.customerRate / 100) / 12, this.terms, -this.loanInvoiceAmount, 0, 1);
      this.calculateRate();
      this.loader = false
    }
  }

  resetQuote(){
    this.submitted=false;
    this.repayment=0;
    this.formControlInvoiceAmount.reset();
    this.formControlApplicationType.reset();
    this.childLoanTerms.forEach(c => c.reset())
    this.childAssetCategory.forEach(c => c.reset())
  }

  calculateRate() {
    let termsRate;
    let financeRate;
    const assetType: Exclude<AssetCategoryTypeSelectionValue, null> = this.formControlAssetType.value!;
    if (this.terms >= RatesForLoanTerms[5].term && this.terms < RatesForLoanTerms[4].term) {
      termsRate = 0
    } else if (this.terms >= RatesForLoanTerms[4].term && this.terms < RatesForLoanTerms[3].term) {
      termsRate = 0;
    } else if (this.terms >= RatesForLoanTerms[3].term && this.terms < RatesForLoanTerms[2].term) {
      termsRate = 0;
    } else if (this.terms >= RatesForLoanTerms[2].term && this.terms < RatesForLoanTerms[1].term) {
      termsRate = 0;
    } else if (this.terms >= RatesForLoanTerms[1].term && this.terms < RatesForLoanTerms[0].term) {
      termsRate = 0;
    } else {
      termsRate = 0;
    }

    if (this.loanInvoiceAmount < RatesForInvoiceAmount[2].invoiceAmount) {
      financeRate = 'N/A'
    } else if (this.loanInvoiceAmount >= RatesForInvoiceAmount[2].invoiceAmount && this.loanInvoiceAmount < RatesForInvoiceAmount[1].invoiceAmount) {
      financeRate = this.financeRateArray[0];
    } else if (this.loanInvoiceAmount >= RatesForInvoiceAmount[1].invoiceAmount && this.loanInvoiceAmount < RatesForInvoiceAmount[0].invoiceAmount) {
      financeRate = this.financeRateArray[1];
    } else {
      financeRate = this.financeRateArray[2]
    }
    console.log(this.termsBaseRate, financeRate, termsRate);
    if (financeRate != 'N/A') {
      this.rate = this.termsBaseRate + financeRate + termsRate;
    } else {
      this.rate = 0
    }

    if (assetType.index === '3' || assetType.index === '4') {
      this.totalRebate = 0;
      this.lgRebateRate = 0;
      this.lgRebateShare = 0;
      this.totalGrowRebate = (this.totalRebate * 7) / 100
      this.dealerRebateRate = 0;
    } else {
      this.totalRebate = this.loanInvoiceAmount + this.PV((this.rate / 100) / 12, this.terms, this.repayment, 0, 1)
      this.lgRebateRate = 30;
      this.lgRebateShare = (this.totalRebate * this.lgRebateRate) / 100;
      this.totalGrowRebate = (this.totalRebate * 7) / 100
      this.dealerRebateRate = (this.totalRebate - 0.07 - this.totalGrowRebate - this.lgRebateShare);
    }
  }
  calculatePMT(ir: number, np: number, pv: number, fv: number, type: number) {
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
      console.log(-(pv + fv) / np);

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * pv * (pvif + fv) / (pvif - 1);
    if (type === 1)
      pmt /= (1 + ir);
    this.repayment = pmt;
    this.totalRebate = this.loanInvoiceAmount + this.PV((7.50 / 100) / 12, this.terms, this.repayment, 0, 1);
    this.documentationFee = (this.loanInvoiceAmount < RatesForInvoiceAmount[1].invoiceAmount ? (this.totalRebate * 10) / 100 : 0).toFixed(2)
    //this.totalGrowRebate = this.totalGrowRebate.toFixed(2)

  }

  PV(rate: number, periods: number, payment: number, future: number, type: 0 | 1) {
    // Initialize type;
    console.log(payment)
    var type = (typeof type === 'undefined') ? 0 : type;

    // Evaluate rate and periods (TODO: replace with secure expression evaluator)
    // rate = eval(rate);
    // periods = eval(periods);

    // Return present value
    if (rate === 0) {
      return - payment * periods - future;
    } else {
      return (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods);
    }
  }
}
