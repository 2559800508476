import {Component, Input} from '@angular/core';
import {BusinessLoansApplication} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import { PaymentChartComponent } from '../../chart-component/payment-chart.component';


@Component({
    selector: 'business-quote',
    templateUrl: './business-quote.component.html',
    styleUrls: ['./business-quote.component.scss'],
    standalone: true,
    imports: [PaymentChartComponent]
})
export class BusinessQuoteComponent {

  @Input({required: true}) application!: BusinessLoansApplication;
  @Input({required: false}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: true}) amortizationChartData!: AmortizationChartData;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: false}) repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input({required: false}) isBusinessLoans: boolean = true;
  @Input({required: false}) calculationLog?: {log: string, rate?: number}[];
}
