import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {compareMatch, UserListValue} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';


export type UserListValueGetFn = ()=>Observable<Exclude<UserListValue, null>[]>;



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UserListComponent), multi: true }
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class UserListComponent extends AbstractControlValueAccessor<UserListValue> implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: true}) title?: string;

  formControl: FormControl<UserListValue>;

  users: Exclude<UserListValue, null>[] = [];

  @Input({required: true}) getFn!: UserListValueGetFn


  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.getFn().pipe(
      tap(r => {
        const u = r?.filter(r => (!!r.name && !!r.name.trim())) ?? [];
        this.users = u;
      })
    ).subscribe();
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: UserListValue | null | undefined): void | UserListValue {
    if (v) {
      setTimeout(()=> {
        const o: UserListValue | undefined = this.users.find(opt => opt.userId == v.userId);
        if (o) {
          this.formControl.setValue(o);
        }
      }, 1000);
    }
    return undefined;
  }

}
