<div class="edit-kyc-status-dialog">
  <mat-dialog-content>
    <h3 class="header">Edit Kyc Status</h3>
    <mat-form-field>
      <mat-label>Edit Kyc Status</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option [value]="'WAITING'">WAITING</mat-option>
        <mat-option [value]="'RECEIVED_COMPLETE'">RECEIVED_COMPLETE</mat-option>
        <mat-option [value]="'RECEIVED_INCOMPLETE'">RECEIVED_INCOMPLETE</mat-option>
        <mat-option [value]="'RECEIVED_ERROR'">RECEIVED_ERROR</mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button [disabled]="!formControl.valid" (click)="onSubmit($event)">Submit</button>
    </div>
  </mat-dialog-content>
</div>
