import { NgModule } from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {InputMaskModule} from "@ngneat/input-mask";
import {DisableControlDirective} from "../../directives/disable-control.directive";
import {PercentageInputComponent} from "../percentage-input-component/percentage-input.component";
import {NumberInputComponent} from "./number-input.component";



@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    FlexModule,
    InputMaskModule,
    DisableControlDirective,
    PercentageInputComponent,
    NumberInputComponent,
  ],
  exports: [
    NumberInputComponent,
  ],
})
export class NumberInputModule {

}
