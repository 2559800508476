import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiResponse, CompaniesSearchComponentValue, PayloadApiResponse, SendEmailResponse} from '@portal-workspace/grow-shared-library';
import {CompaniesSearchFn} from '@portal-workspace/grow-ui-library';

const URL_REGISTER_BROKER = ()=>`${environment.api2Host}/api2/broker-signup/create`; // post
const URL_SEND_FORGOT_PASSWORD = ()=>`${environment.api2Host}/api2/broker-signup/forgot-pwd`; // post
const URL_RESET_PASSWORD = ()=>`${environment.api2Host}/api2/broker-signup/reset-password`; // post
const URL_VERIFY_RESET_PASSWORD_CODE = ()=>`${environment.api2Host}/api2/broker-signup/verify-forgot-code`; // post
const URL_SEARCH_ORIGINATORS_BY_NAME = (name: string)=>`${environment.api2Host}/api2/originator_business_new/getOriginators2/${name}`; //get
const URL_VERIFY_USER_EMAIL_VERIFICATION = ()=>`${environment.api2Host}/api2/login/verify-user-email-verification`; //post
const URL_SEND_SMS_VERIFICATION_CODE = ()=>`${environment.api2Host}/api2/login/send-sms`; // post
const URL_VERIFY_SMS_VERIFICATION_CODE = ()=>`${environment.api2Host}/api2/login/verify-sms`; // post
const URL_GET_USER_VERIFICATION_STATUS = (userId: number)=>`${environment.api2Host}/api2/broker-signup/user-verification-status/${userId}`; // get
const URL_SEND_VERIFY_EMAIL = () => `${environment.api2Host}/api2/login/send-user-email-verification`;  // post


@Injectable()
export class RegistrationService {

  constructor(private httpClient: HttpClient) {
  }

  sendSmsVerificationCode(userId: number, mobileNumber: string): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_SEND_SMS_VERIFICATION_CODE(), {
      user_id: userId,
      phone_number: mobileNumber,
    }, httpOptions()).pipe(
      tap(r => {
        return {
          status: r.status,
          message: r.message,
        }
      })
    );
  }


  // send verify email email
  sendVerificationEmail(opt: {
    UserId: number |undefined,
    fname: string,
    mobile: string |undefined,
    email: string,
  }): Observable<PayloadApiResponse<SendEmailResponse>> {
    return this.httpClient.post<PayloadApiResponse<SendEmailResponse>>(URL_SEND_VERIFY_EMAIL(), opt, httpOptions()).pipe();
  }

  verifySmsVerificationCode(code: string, userId: number, mobileNumber: string): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_VERIFY_SMS_VERIFICATION_CODE(), {
      code: code,
      user_id: userId,
      mobile_number: mobileNumber,
    }, httpOptions()).pipe(
      tap(r => {
        return {
          status: r.status,
          message: r.message,
        }
      })
    )
  }

  verifyEmailCode(code: string): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_VERIFY_USER_EMAIL_VERIFICATION(), {
      code
    }, httpOptions()).pipe(
      map(r => {
        return {
          status: r.status,
          message: r.message,
        }
      })
    )
  }

  companiesSearchFn: CompaniesSearchFn = (search: string) => {
    return this.searchOriginators(search).pipe(
      map(r => (r.payload ?? [])));
  }

  searchOriginators(name: string): Observable<PayloadApiResponse<Exclude<CompaniesSearchComponentValue, null>[]>> {
    return this.httpClient.get<PayloadApiResponse<Exclude<CompaniesSearchComponentValue, null>[]>>(URL_SEARCH_ORIGINATORS_BY_NAME(name), httpOptions()).pipe();
  }

  verifyResetPasswordCode(code: string): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_VERIFY_RESET_PASSWORD_CODE(), {
      code
    }, httpOptions()).pipe(
      map(r => {
        return {
          status: r.status,
          message: r.message,
        }
      }
    ));
  }

  resetPassword(data: {
    Password: string,
    Email: string,
  }) {
    return this.httpClient.post<{status: boolean, message: string}>(URL_RESET_PASSWORD(), data, httpOptions()).pipe(
      map(r => {
        return {
          status: r.status,
          message: r.message,
        }
      })
    );
  }


  sendForgotPassword(data: {
    Email: string,
  }): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_SEND_FORGOT_PASSWORD(), data, httpOptions()).pipe(
      map((r ) => {
        return {
          status: r.status,
          message: r.message
        }
      })
    );
  }

  registerBroker(register: {
    Email: string,
    MobileNumber: string,
    GivenName: string,
    FamilyName: string,
    Password: string,
    companyName: string,
    OriginatorBusinessId: number|undefined,
    // AggregatorId:number|undefined
  }): Observable<ApiResponse> {
    return this.httpClient.post<{status: boolean, message: string}>(URL_REGISTER_BROKER(), {
      ...register,
      AccessLevel: 'externalbroker',
    }, httpOptions()).pipe(
      map(r => {
        return {
          status: r.status,
          message: r.message,
        }
      })
    );
  }

  getUserVerificationStatus(userId: number): Observable<PayloadApiResponse<{verified: boolean}>> {
    return this.httpClient.get<{verified: boolean}>(URL_GET_USER_VERIFICATION_STATUS(userId), httpOptions()).pipe(
      map(r => {
        return {
          status: !!r,
          message: '',
          payload: r
        }
      })
    );
  }

}
