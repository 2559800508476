<div class="new-batch-payment-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="flex-start start">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">
            New Batch Payment
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label> Payment Type</mat-label>
            <mat-select [formControl]="formControlType">
              <mat-option [value]="BankingPaymentType.DIRECT">Direct Payment</mat-option>
              <mat-option [value]="BankingPaymentType.BPAY">BPay</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input [formControl]="formControlDate" matInput placeholder="Enter date">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <input [formControl]="formControlDescription" matInput placeholder="Enter description">
          </mat-form-field>
        </div>
      </div>

      @if (formControlType.value === BankingPaymentType.DIRECT) {
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>User Name</mat-label>
              <input [formControl]="formControlUserName" matInput placeholder="Enter user name">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>User Number</mat-label>
              <input [formControl]="formControlUserNumber" matInput placeholder="Enter user number">
            </mat-form-field>
          </div>
        </div>
      }

      <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
        <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
        <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
