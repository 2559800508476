@if (!((store.value$ | async)?.error)) {
  <div class="pending-approval-transactions-table-component">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Pending Approval Transactions</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search transaction..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      @if (isAdminOrOperationsOrAnalyst) {
        <div class="col-lg-6 col-md-12" 
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="end start"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <button mat-flat-button class="inactive w-lt-md-100" (click)="approveAll()">Approve All</button>
          <button mat-flat-button class="inactive w-lt-md-100" (click)="approveSelected()">Approve Selected</button>
        </div>
      }
    </div>

    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" multiTemplateDataRows matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <span class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline" (click)="toggleAllSelection()"></span>
              </th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"
                ></mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.id}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Banking</th>
              <td mat-cell *matCellDef="let element">{{getBankingType(element)}}</td>
            </ng-container>
            <ng-container matColumnDef="processingCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processingCode">Type</th>
              <td mat-cell *matCellDef="let element">{{getProcessingType(element.processingCode)}}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="amount">Amount</th>
              <td mat-cell *matCellDef="let element">{{element.amount | looseCurrency}}</td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
              <td mat-cell *matCellDef="let element">{{moment(element.date, "DDMMYYYY").format('DD/MM/YYYY')}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <tag-box [state]="element.status"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="PismoAccountId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoAccountId">Pismo Acc</th>
              <td mat-cell *matCellDef="let element">{{element.PismoAccountId}}</td>
            </ng-container>
            <ng-container matColumnDef="PismoCustomerId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="PismoCustomerId">Pismo Cus</th>
              <td mat-cell *matCellDef="let element">{{element.PismoCustomerId}}</td>
            </ng-container>
            <ng-container matColumnDef="createdTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="createdTime">Created</th>
              <td mat-cell *matCellDef="let element">{{moment(element.createdTime).format('DD/MM/YYYY HH:mm:ss')}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="row wrap"
                  fxLayoutAlign="start center"
                  fxLayout.lt-md="column"
                  fxLayoutAlign.lt-md="center center">
                  <span class="mdi mdi-check cursor-pointer icon-margin mr-2" matTooltip="Approve" (click)="onApprove(element)"></span>
                  <span class="mdi mdi-close cursor-pointer icon-margin" matTooltip="Reject" (click)="onReject(element)"></span>
                </div>
              </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element;" class="expanded-row" [attr.colspan]="displayColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                
                  @if (element.type==='bpay') {
                    <div class="row mt-2">
                      <div class="col-4">Biller Code</div>
                      <div class="col-8">{{element.billerCode}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">Biller Name</div>
                      <div class="col-8">{{element.accountTitle}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">CRN</div>
                      <div class="col-8">{{element.crn}}</div>
                    </div>
                  }
                  @if (element.type!=='bpay') {
                    <div class="row mt-2">
                      <div class="col-4">Account Title</div>
                      <div class="col-8">{{element.accountTitle}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">BSB</div>
                      <div class="col-8">{{element.bsb}}</div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4">Account Number</div>
                      <div class="col-8">{{element.accountNumber}}</div>
                    </div>
                  }
                  <div class="row mt-2">
                    <div class="col-4">Reference</div>
                    <div class="col-8">{{element.reference}}</div>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayColumns"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total"
              [pageIndex]="offset"
              [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]"
              (page)="onPagination($event)"
            ></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
