import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  createAbnInputMask,
  setupUntilDestroy,
  PortalHotToastService,
  createAcnInputMask,
} from '@portal-workspace/grow-ui-library';
import { MARK } from '@portal-workspace/grow-ui-library/mark';
import { combineLatest, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AbnComponentValue,
  AcnComponentValue,
  CustomerTypeValue,
  CustomerUser, GetCustomerFn,
  GetRbaRateFn
} from '@portal-workspace/grow-shared-library';
import { NgStyle } from '@angular/common';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { createAsyncState, createAsyncStore } from '@ngneat/loadoff';
import { CustomerTypeComponent } from '../customer-type-component/customer-type.component';


@UntilDestroy()
@Component({
  selector: 'overdraft-customer-details',
  templateUrl: './overdraft-customer-details.component.html',
  styleUrls: ['./overdraft-customer-details.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OvedraftCustomerDetailsComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(() => OvedraftCustomerDetailsComponent) },
  ],
  standalone: true,
  imports: [MatFormFieldModule, CustomerTypeComponent, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, NgStyle, DisableControlDirective]
})

export class OvedraftCustomerDetailsComponent implements OnInit {

  @Input({required: true}) customerId!: number;
  @Input({required: true}) getCustomerFn!: GetCustomerFn;
  @Input({required: true}) getRbaRateFn!: GetRbaRateFn;
  @Input({required: false}) allowProfileEdit: boolean = false;

  subscriptions: Subscription[] = [];
  formGroup1!: FormGroup<{
    customerType: FormControl<CustomerTypeValue>,
    entityName: FormControl<string | null>,
    entityType: FormControl<string | null>,
    abn: FormControl<AbnComponentValue | string>,
    acn: FormControl<string | null>,
    address: FormControl<string | null>,
  }>;

  formControlCustomerType!: FormControl<CustomerTypeValue>;
  formControlEntityName!: FormControl<string | null>;
  formControlEntityType!: FormControl<string | null>;
  formControlAbn!: FormControl<AbnComponentValue>;
  formControlAcn!: FormControl<AcnComponentValue>;
  formControlAddress!: FormControl<string | null>;

  SalesforceId: string | undefined;
  customer: CustomerUser | null = null;
  rbaRate: number | null = null;
  errorTitle = 'Error Occurred!'
  errorMessage = 'Please try again.'
  showAcn: boolean = false
  store = createAsyncStore();
  retry() {
    this.reload();
  }
  createAcnInputMask = createAcnInputMask();
  createAbnInputMask = createAbnInputMask();


  constructor(private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.formControlCustomerType = formBuilder.control(null);
    this.formControlEntityName = formBuilder.control(null);
    this.formControlEntityType = formBuilder.control(null);
    this.formControlAbn = formBuilder.control(null);
    this.formControlAcn = formBuilder.control(null);
    this.formControlAddress = formBuilder.control(null);
    this.formGroup1 = formBuilder.group({
      customerType: this.formControlCustomerType,
      entityName: this.formControlEntityName,
      entityType: this.formControlEntityType,
      abn: this.formControlAbn,
      acn: this.formControlAcn,
      address: this.formControlAddress,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    if (this.allowProfileEdit == false) {
      this.formControlCustomerType.disable()
      this.formControlEntityName.disable()
      this.formControlEntityType.disable()
      this.formControlAbn.disable()
      this.formControlAcn.disable()
      this.formControlAddress.disable()
    }
    // this.customer = (this.activatedRoute.snapshot.data as any).customerUser;

    if (this.customerId) {
      this.reload();
      console.log('===================this.customer: ', this.customer);
    }
    else {
      // this.showError = true
      this.toastService.publishErrorNotification({
        type: 'error',
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      });
      this.store.value$ = of(createAsyncState({
        error: new Error('missing customer')
      }));
      return;
    }

    const sub = this.formControlCustomerType.valueChanges.pipe(
      tap((r: CustomerTypeValue) => {
        switch (r) {
          case 'consumer':
            (<FormGroup>this.formGroup1).removeControl('entityType');
            (<FormGroup>this.formGroup1).removeControl('entityName');
            (<FormGroup>this.formGroup1).removeControl('abn');
            (<FormGroup>this.formGroup1).removeControl('acn');
            this.formGroup1.updateValueAndValidity();
            this.showAcn = false;
            break;
          case 'commercial':
            this.formGroup1.addControl('entityType', this.formControlEntityType);
            this.formGroup1.addControl('entityName', this.formControlEntityName);
            this.formGroup1.addControl('abn', this.formControlAbn);
            this.formGroup1.addControl('acn', this.formControlAcn);
            this.formGroup1.updateValueAndValidity();
            this.showAcn = true;
            break;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: AbnComponentValue) {

  }

  reload() {
    //m const customerId = this.activatedRoute.snapshot.params['customerId'];;
    const customerId = this.customerId;
    this.subscriptions.push(combineLatest([
      this.getCustomerFn(customerId),
      this.getRbaRateFn(),
    ]).pipe(
      this.store.track(),
      map(([customer, rbaRate]) => {
        this.customer = customer;
        this.rbaRate = rbaRate;
        this.update();
      })).subscribe());
  }

  update(): void {
    console.log('*** customer', this.customer);

    if (this.customer) {
      this.formControlEntityType.setValue(this.customer.EntityType ?? null);
      this.formControlEntityName.setValue(this.customer.EntityName ?? null);
      this.formControlAbn.setValue(this.customer.ABN ?? null);
      this.formControlAcn.setValue(this.customer.ACN ?? null);
      this.formControlCustomerType.setValue(this.customer.CustomerType ?? null);
      this.SalesforceId = this.customer.SalesforceId
      this.formControlAddress.setValue(this.customer.Address?.RawAddress ?? null);
    }
  }

}
