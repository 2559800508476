import {Component, forwardRef, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {createTwoDecimalInputMask, Mark} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {compareMatch, MinMaxRateValue, NonAssetRateCardValue} from '@portal-workspace/grow-shared-library';
import {AssetCategoryRateValue} from '@portal-workspace/grow-shared-library';
import {AssetTypeRateValue} from '@portal-workspace/grow-shared-library';
import {requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { AssetTypeRateComponent } from './asset-type-rate.component';
import { AssetCategoryRateComponent } from './asset-category-rate.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MinMaxRateComponent } from './min-max-rate.component';
import { MatDividerModule } from '@angular/material/divider';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'non-asset-rate-card',
    templateUrl: './non-asset-rate-card.component.html',
    styleUrls: ['./non-asset-rate-card.component.scss'],
    exportAs: 'nonAssetRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NonAssetRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => NonAssetRateCardComponent), multi: false },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, InputMaskModule, ReactiveFormsModule, MatDividerModule, MinMaxRateComponent, CurrencyInputComponent, AssetCategoryRateComponent, AssetTypeRateComponent]
})
export class NonAssetRateCardComponent extends AbstractControlValueAccessor<NonAssetRateCardValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formGroup: FormGroup<{
    baseInterestRate: FormControl<number | null>,
    loanAMount: FormControl<MinMaxRateValue>,
    eotAge: FormControl<MinMaxRateValue>,
    loanTerms: FormControl<MinMaxRateValue>,
    brokerage: FormControl<MinMaxRateValue>,
    adverseOnFile: FormControl<number | null>,
    privateSale: FormControl<number | null>,
    privateSaleDocFee: FormControl<number | null>,
    noPropertyOwnership: FormControl<number | null>,
    lowEquifaxScore: FormControl<number | null>,
    carsPropertyOwner: FormControl<number | null>,
    assetConditionUsed: FormControl<number | null>,
    docFee: FormControl<MinMaxRateValue>,
    assetCategory: FormControl<AssetCategoryRateValue>,
    assetType: FormControl<AssetTypeRateValue>,
  }>;
  formControlBaseInterestRate: FormControl<number | null>;
  formControlLoanAmount: FormControl<MinMaxRateValue>;
  formControlEotAge: FormControl<MinMaxRateValue>;
  formControlLoanTerms: FormControl<MinMaxRateValue>;
  formControlBrokerage: FormControl<MinMaxRateValue>;
  formControlAdverseOnFile: FormControl<number | null>;
  formControlPrivateSale: FormControl<number | null>;
  formControlPrivateSaleDocFee: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlNoPropertyOwnership: FormControl<number | null>;
  formControlCarsPropertyOwner: FormControl<number | null>;
  formControlLowEquifaxScore: FormControl<number | null>;
  formControlAssetConditionUsed: FormControl<number | null>;
  formControlAssetCategory: FormControl<AssetCategoryRateValue>;
  formControlAssetType: FormControl<AssetTypeRateValue>;

  constructor(private formBuilder: FormBuilder) {
   super();
   this.formControlBaseInterestRate = formBuilder.control(0, [Validators.required]);
   this.formControlLoanAmount = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlEotAge = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlLoanTerms = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlBrokerage = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlAdverseOnFile = formBuilder.control(0, [Validators.required]);
   this.formControlPrivateSale = formBuilder.control(0, [Validators.required]);
   this.formControlPrivateSaleDocFee = formBuilder.control(0, [Validators.required]);
   this.formControlNoPropertyOwnership = formBuilder.control(0, [Validators.required]);
   this.formControlLowEquifaxScore = formBuilder.control(0, [Validators.required]);
   this.formControlCarsPropertyOwner = formBuilder.control(0, [Validators.required]);
   this.formControlAssetConditionUsed = formBuilder.control(0, [Validators.required]);
   this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlAssetCategory = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formControlAssetType = formBuilder.control([], [requiredAllowEmptyValidator]);
   this.formGroup = formBuilder.group({
     baseInterestRate: this.formControlBaseInterestRate,
     loanAMount: this.formControlLoanAmount,
     eotAge: this.formControlEotAge,
     loanTerms: this.formControlLoanTerms,
     brokerage: this.formControlBrokerage,
     adverseOnFile: this.formControlAdverseOnFile,
     privateSale: this.formControlPrivateSale,
     privateSaleDocFee: this.formControlPrivateSaleDocFee,
     noPropertyOwnership: this.formControlNoPropertyOwnership,
     lowEquifaxScore: this.formControlLowEquifaxScore,
     carsPropertyOwner: this.formControlCarsPropertyOwner,
     assetConditionUsed: this.formControlAssetConditionUsed,
     docFee: this.formControlDocFee,
     assetCategory: this.formControlAssetCategory,
     assetType: this.formControlAssetType,
   });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          // NOTE: properties of r will not be null when FormGroup is valid, validators should be in place to handle that
          const v: NonAssetRateCardValue = {
            baseInterestRate: r.baseInterestRate ?? 0,
            loanAmount: r.loanAMount ?? [],
            eotAge: r.eotAge ?? [],
            loanTerms: r.loanTerms ?? [],
            brokerage: r.brokerage ?? [],
            adverseOnFile: r.adverseOnFile ?? 0,
            privateSale: r.privateSale ?? 0,
            privateSaleDocFee: r.privateSaleDocFee ?? 0,
            noPropertyOwnership: r.noPropertyOwnership ?? 0,
            carsPropertyOwner: r.carsPropertyOwner ?? 0,
            lowEquifaxScore: r.lowEquifaxScore ?? 0,
            assetConditionUsed: r.assetConditionUsed ?? 0,
            docFee: r.docFee ?? [],
            assetCategory: r.assetCategory ?? [],
            assetType: r.assetType ?? [],
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: NonAssetRateCardValue | null | undefined): void | NonAssetRateCardValue {
    if (v) {
      this.formControlBaseInterestRate.setValue(v.baseInterestRate);
      this.formControlLoanAmount.setValue(v.loanAmount ?? []);
      this.formControlEotAge.setValue(v.eotAge ?? []);
      this.formControlLoanTerms.setValue(v.loanTerms ?? []);
      this.formControlBrokerage.setValue(v.brokerage ?? []);
      this.formControlAdverseOnFile.setValue(v.adverseOnFile);
      this.formControlPrivateSale.setValue(v.privateSale);
      this.formControlPrivateSaleDocFee.setValue(v.privateSaleDocFee);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlNoPropertyOwnership.setValue(v.noPropertyOwnership);
      this.formControlCarsPropertyOwner.setValue(v.carsPropertyOwner);
      this.formControlLowEquifaxScore.setValue(v.lowEquifaxScore);
      this.formControlAssetConditionUsed.setValue(v.assetConditionUsed);
      this.formControlAssetCategory.setValue(v.assetCategory ?? []);
      this.formControlAssetType.setValue(v.assetType ?? []);
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
