import {Component, Inject, OnInit} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FlexModule} from "@angular/flex-layout/flex";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {
  BusinessPricingComparisonDialogData, BusinessPricingComparisonDialogDatasource,
  BusinessPricingComparisonDialogResult, CurrencyInputValue,
  TotalPaymentBreakupDialogData
} from "@portal-workspace/grow-shared-library";
import {MatCardModule} from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {EditableTextComponent} from '../../components/editable-component/editable-text.component';
import {EditablePercentageComponent} from '../../components/editable-component/editable-percentage.component';
import {EditableNumberComponent} from '../../components/editable-component/editable-number.component';
import {EditableCurrencyComponent} from '../../components/editable-component/editable-currency.component';
import {Subscription} from "rxjs";
import {ApplicationDialogService, setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {PercentagePipe} from '../../pipes/percentage.pipe';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './business-pricing-comparison.dialog.html',
  styleUrls: ['./business-pricing-comparison.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    MatCardModule,
    JsonPipe,
    AsyncPipe,
    MatButtonModule,
    LooseCurrencyPipe,
    EditableTextComponent,
    EditablePercentageComponent,
    EditableNumberComponent,
    EditableCurrencyComponent,
    PercentagePipe
]
})
export class BusinessPricingComparisonDialog implements OnInit {

  subscriptions: Subscription[] = [];

  dataSource: BusinessPricingComparisonDialogDatasource = [];
  currentValue: TotalPaymentBreakupDialogData;
  newValue: TotalPaymentBreakupDialogData;

  formControlDocFee!: FormControl<CurrencyInputValue>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BusinessPricingComparisonDialogData,
    private dialogRef: MatDialogRef<BusinessPricingComparisonDialog, BusinessPricingComparisonDialogResult>,
    private applicationDialogService: ApplicationDialogService,
    private formBuilder: FormBuilder,
  ) {
    this.currentValue = data.currentValue
    this.newValue = data.newValue
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlDocFee = this.formBuilder.control(this.newValue.paymentChartData.docFee, [Validators.required]);
    this.subscriptions.push(this.formControlDocFee.valueChanges.pipe(
      // doc fee was changed
    ).subscribe());
    this.dataSource = [
      {
        type: 'currency',
        name: 'Your Weekly Payment',
        currentValue: this.currentValue.paymentChartData.emiAmt,
        newValue: this.newValue.paymentChartData.emiAmt,
      },
      {
        type: 'currency',
        name: 'Amount financed (incl Brokerage)',
        currentValue: (this.currentValue.paymentChartData.principalAmt + this.currentValue.paymentChartData.brokerageAmount),
        newValue: (this.newValue.paymentChartData.principalAmt + this.newValue.paymentChartData.brokerageAmount),
      },
      {
        type: 'currency',
        name: 'Total Interest',
        currentValue: this.currentValue.paymentChartData.interestAmt,
        newValue: this.newValue.paymentChartData.interestAmt,
      },
      {
        type: 'currency',
        name: 'Total Payment',
        currentValue: this.currentValue.paymentChartData.totalAmt,
        newValue: this.newValue.paymentChartData.totalAmt,
      },
      {
        type: 'percentage',
        name: 'Interest Rate',
        currentValue: this.currentValue.paymentChartData.totalInterest,
        newValue: this.newValue.paymentChartData.totalInterest,
        newValueClick: (newValue) => {
          this.applicationDialogService.openInterestRateCalculationLogDialog({
            logs: this.newValue?.calculationLog ?? [],
          });
        },
      },
      {
        type: 'text',
        name: 'Loan Terms',
        currentValue: `${this.currentValue.paymentChartData.loanTerm} months`,
        newValue: `${this.newValue.paymentChartData.loanTerm} months`
      },
      {
        type: 'currency',
        name: 'Brokerage Amount',
        currentValue: this.currentValue.paymentChartData.brokerageAmount,
        newValue: this.newValue.paymentChartData.brokerageAmount,
      },
      {
        type: 'currency',
        name: 'Doc fee',
        currentValue: this.currentValue.paymentChartData.docFee,
        newValue: this.newValue.paymentChartData.docFee,
        formControl: this.formControlDocFee,
      },
    ];
  }
  onAcceptQuotation($event: Event) {
    this.dialogRef.close({
      acceptedQuotation: true,
      docFee: this.formControlDocFee.value ?? this.newValue.paymentChartData.docFee ,
    });
  }

  onClose($event: Event) {
    this.dialogRef.close();
  }

}
