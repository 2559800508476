import {AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, Validators, FormBuilder, FormGroup, FormControl, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {createCurrencyInputMask} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, CustomErrorStateMatcher} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  Address2ComponentValue, compareMatch, CurrencyInputValue, PersonalAsset, PersonalAssetsValue, PersonalAssetValueOptions, PersonalAssetValueOptionsType,
  PersonalNonPropertyAsset,
  PersonalPropertyAsset
} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { InputMaskModule } from '@ngneat/input-mask';
import { FlexModule } from '@angular/flex-layout/flex';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatInputModule } from '@angular/material/input';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';



export interface PersonalAssetComponentEvent {
  entries: PersonalAsset[];
}

export type PersonalAssetsFormArray = FormArray<PersonalAssetFormGroup>;
export type PersonalAssetFormGroup = FormGroup<{
  assetType: FormControl<PersonalAssetValueOptionsType[number]>,
  value: FormControl<CurrencyInputValue>,
  address: FormControl<Address2ComponentValue>,
  description: FormControl<string | null>,
}>

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'personal-assets',
    templateUrl: './personal-assets.component.html',
    styleUrls: ['./personal-assets.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PersonalAssetsComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PersonalAssetsComponent) },
    ],
    standalone: true,
    imports: [FormsModule, MatButtonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, CustomAddressComponent, MarkDirective, MatInputModule, CurrencyInputComponent, FlexModule, InputMaskModule]
})
export class PersonalAssetsComponent extends AbstractControlValueAccessor<PersonalAssetsValue> implements OnInit, AfterViewInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();

  errorStateMatcher = new CustomErrorStateMatcher();

  createCurrencyMask = createCurrencyInputMask();

  @Output() events: EventEmitter<PersonalAssetComponentEvent> = new EventEmitter<PersonalAssetComponentEvent>();

  assetTypes = PersonalAssetValueOptions;

  formGroup: FormGroup<{
    personalAssets: PersonalAssetsFormArray,
    totalAssets: FormControl<number | null>,
  }>;
  formControlTotalAssets: FormControl<number | null>;
  formArray: PersonalAssetsFormArray;
  subscriptions: Subscription[] = [];


  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<PersonalAssetFormGroup>([]);
    this.formControlTotalAssets = formBuilder.control({value: 0, disabled: false});
    this.formGroup = formBuilder.group({
      personalAssets: this.formArray,
      totalAssets: this.formControlTotalAssets,
    });
  }

  private changes() {
    // deal with total asset value
    const totalAsset = this.formArray.controls.reduce((total:number, control: AbstractControl) => {
      const fg = control as FormGroup;
      const val = parseFloat((fg.controls as any).value.value);
      const v = isNaN(val) ? 0 : val;
      return (total + v);
    }, 0);
    this.formControlTotalAssets.setValue(totalAsset);

    // deal with propagation
    if (this.formArray.invalid) {
      // propagate null
      this.propagateChange(null);
    } else {
      // propagate formArray.value
      const v: PersonalAssetsValue = {
        total: totalAsset,
        assets: this.formArrayToPersonalAsset(this.formArray) ?? [],
      };
      this.propagateChange(v);
    }
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r ) => {
        this.changes();
      })
    ).subscribe());
  }

  ngAfterViewInit(): void {
    // setTimeout(()=> this.changes());
  }

  assetFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  assetFormGroupFormControl(assetFormGroup: AbstractControl, controlName: 'assetType' | 'address' | 'value' | 'description'): FormControl {
    return (assetFormGroup as FormGroup).controls[controlName] as FormControl;
  }

  addAsset(a?: Exclude<PersonalAssetsValue, null>['assets'][number]) {
    const formControlValue = this.formBuilder.control(a ? a .value : null, [Validators.required]);
    const formControlAssetType = this.formBuilder.control<PersonalAsset['assetType']|null>(a ? a.assetType : null, [Validators.required]);
    const formGroup: FormGroup = this.formBuilder.group({
      assetType: formControlAssetType,
      value: formControlValue,
    });
    const sub = formControlAssetType.valueChanges.pipe(
      delay(0),
      tap((r: PersonalAsset['assetType']|null) => {
        if (r) {
          if (r.type === 'property') {
            formGroup.removeControl('description');
            formGroup.addControl('address', this.formBuilder.control(a ? (a as PersonalPropertyAsset).address : null, [Validators.required]));
          } else {
            formGroup.removeControl('address');
            formGroup.addControl('description', this.formBuilder.control(a ? (a as PersonalNonPropertyAsset).description : null, [Validators.required]));
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    if (a && a.assetType.type === 'property') {
      formGroup.addControl('address', this.formBuilder.control(a ? (a as PersonalPropertyAsset).address : null, [Validators.required]));
    } else {
      formGroup.addControl('description', this.formBuilder.control(a ? (a as PersonalNonPropertyAsset).description : '', [Validators.required]));
    }
    this.formArray.push(formGroup);
    this.events.emit({ entries: this.formArrayToPersonalAsset(this.formArray) ?? [] });
  }

  removeAsset(assetFormGroup: FormGroup) {
    const index = this.formArray.controls.indexOf(assetFormGroup);
    if (index !== -1) {
      Object.keys(assetFormGroup.controls).forEach(key => {
        assetFormGroup.controls[key].setErrors(null);
      })
      this.formArray.removeAt(index);
    }
    this.events.emit({ entries: this.formArrayToPersonalAsset(this.formArray) ?? [] });
  }

  onAddAsset($event: Event) {
    this.addAsset();
  }

  onRemoveAsset($event: Event, assetFormGroup: FormGroup) {
    this.removeAsset(assetFormGroup);
  }

  doWriteValue(v: PersonalAssetsValue | null | undefined): void {
    this.formArray.clear();
    if (v && v.assets) {
      for (const asset of v.assets) {
        this.addAsset(asset);
      }
    }
  }

  isPropertyAsset(assetFormGroup: FormGroup) {
    return !!(assetFormGroup.controls as any).address;
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  formArrayToPersonalAsset(formArray: PersonalAssetsFormArray): PersonalAsset[] | null {
    if (this.formArray.invalid) {
      return null;
    }
    const personalAssets =  (this.formArray.value ?? []).reduce((acc: PersonalAsset[], cur) => {
      if (cur.assetType) {
        switch(cur.assetType?.type) {
          // NOTE: validator should make sure that when formArray is valid, none of cur properties should be null
          case 'property': {
            const p: PersonalPropertyAsset = {
              assetType: cur.assetType,
              value: cur.value!,
              address: cur.address!
            };
            acc.push(p);
            break;
          }
          default: {
            const p: PersonalNonPropertyAsset = {
              assetType: cur.assetType,
              value: cur.value!,
              description: cur.description!,
            };
            acc.push(p);
            break;
          }
        }
      }
      return acc;
    }, [])
    return personalAssets;
  }
}
