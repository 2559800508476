import {Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  NotNullable,
  SalesforceContactSameAccountSelectionValue,
  GetSalesforceContactSameAccountFunc,
} from '@portal-workspace/grow-shared-library';
import {MatFormFieldModule} from '@angular/material/form-field';
import _ from 'lodash';

import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, getUser, setupUntilDestroy} from '../component-utils';
import {Subscription} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'salesforce-contact-same-account-selection',
  templateUrl: './salesforce-contact-same-account-selection.component.html',
  styleUrls: ['./salesforce-contact-same-account-selection.component.scss'],
  standalone: true,
  exportAs: 'SalesforceContactSameAccountSelectionComponent',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SalesforceContactSameAccountSelectionComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => SalesforceContactSameAccountSelectionComponent)},
  ],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatInputModule,
]
})
export class SalesforceContactSameAccountSelectionComponent extends AbstractControlValueAccessor<SalesforceContactSameAccountSelectionValue> implements OnInit, OnChanges, Mark {

  @Input({required: true}) getFunc!: GetSalesforceContactSameAccountFunc;
  @Input({required: false}) salesforceAccountId: string | null = null;
  @Input({required: false}) isMandatory = true;
  @Input({required: false}) title: string | null = null;

  subscriptions: Subscription[] = [];
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  users: NotNullable<SalesforceContactSameAccountSelectionValue>[] = [];
  filteredOptions: NotNullable<SalesforceContactSameAccountSelectionValue>[] = [];
  user = getUser();

  errorMessage = formControlErrorMessage;
  errorKeys = formControlErrorKeys;

  compareWith: (a: SalesforceContactSameAccountSelectionValue, b: SalesforceContactSameAccountSelectionValue) => boolean = (a, b) => {
    return (!!a && !!b && a?.Id === b?.Id);
  };

  displayBroker: (a: SalesforceContactSameAccountSelectionValue) => string = (a) => {
    return a ? `${a.FirstName} ${a.LastName} (${a.Email})` : ``
  }


  formControl!: FormControl<SalesforceContactSameAccountSelectionValue>;
  formGroup!: FormGroup<{
    users: FormControl<SalesforceContactSameAccountSelectionValue>
  }>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salesforceAccountId'] ) {
      this.reload();
    }
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
  }

  reload() {
    if (this.salesforceAccountId) {
      this.subscriptions.push(this.getFunc(this.salesforceAccountId).pipe(tap(r => {
        this.users = r ?? [];
        this.filteredOptions = this.users.slice();
        if (this.formControl) {
          const userSfContactId = this.user?.sfContactId;
          if (userSfContactId) {
            const userObject = this.users.find(u => u.Id === userSfContactId);
            if (userObject) {
              this.formControl.setValue(userObject);
            } else {
              this.formControl.setValue(null);
            }
          } else {
            this.formControl.setValue(null);
          }
        }
      })).subscribe());
    } else {
      this.users = [];
      this.filteredOptions = [];
      if (this.formControl) {
        this.formControl.setValue(null);
      }
    }
  }

  doWriteValue(v:
      Pick<Exclude<SalesforceContactSameAccountSelectionValue, null>, 'FirstName' | 'LastName' | 'Id'> |
      SalesforceContactSameAccountSelectionValue |
      undefined) {
    if (v) {
      if (this.salesforceAccountId) {
        this.subscriptions.push(this.getFunc(this.salesforceAccountId).pipe(
          delay(1000),
          tap(r => {
          this.users = r ?? [];
          this.filteredOptions = this.users.slice();
          const us = this.users.find(
            u => (
              u.Id === v.Id
            )) ?? null;
          this.formControl.setValue(us);
        })).subscribe());
      }
    }
    return undefined;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, this.isMandatory ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      users:  this.formControl,
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(this.formControl.value);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.users.filter(o => 
      `${o.FirstName} ${o.LastName}`.toLowerCase().includes(filterValue)
    );
  }
}
