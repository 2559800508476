import {Component, OnInit} from '@angular/core';
import {
  RedbookSearchByFamiliesResponse,
  RedbookSearchByMakeResponse,
  RedbookSearchByVehiclesResponse,
  RedbookSearchByYearsResponse,
} from '@portal-workspace/grow-shared-library';
import {delay, first, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription } from 'rxjs';
import {PortalHotToastService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RedbookService} from '../../service/redbook.service';
import { RedbookDetailsComponent } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './redbook.page.html',
    styleUrls: ['./redbook.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, RedbookDetailsComponent]
})
export class RedbookPage implements OnInit {
  subscriptions: Subscription[] = [];
  makeSelection: RedbookSearchByMakeResponse = [];
  familySelection: RedbookSearchByFamiliesResponse = [];
  yearSelection: RedbookSearchByYearsResponse = [];
  modelSelection: RedbookSearchByVehiclesResponse = [];
  vehicleSelection: RedbookSearchByVehiclesResponse[number] | string | null = null;
  formControlAssetMake: FormControl<RedbookSearchByMakeResponse[number]|string|null>;
  formControlAssetFamily: FormControl<RedbookSearchByFamiliesResponse[number]|string|null>;
  formControlAssetYear: FormControl<RedbookSearchByYearsResponse[number]|string|null>;
  formControlAssetModel: FormControl<RedbookSearchByVehiclesResponse[number]|string|null>;
  formControlAssetVehicle: FormControl<RedbookSearchByVehiclesResponse[number]|null>;
  formControlAssetVehicles: FormControl<RedbookSearchByVehiclesResponse|null>;
  constructor(
    private toastService: PortalHotToastService,
    private redbookService: RedbookService,
    private formBuilder: FormBuilder,
  ) {
    this.formControlAssetYear = formBuilder.control(null);
    this.formControlAssetMake = formBuilder.control(null);
    this.formControlAssetFamily = formBuilder.control(null);
    this.formControlAssetModel = formBuilder.control(null);
    this.formControlAssetVehicle = formBuilder.control(null);
    this.formControlAssetVehicles = formBuilder.control(null);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.redbookService.assetSearchFn({ action: 'makes', type: 'all'}).subscribe(
      (r: RedbookSearchByMakeResponse) => {
        this.makeSelection = r;
        console.log(r)
      }
    )

    this.subscriptions.push(
      this.formControlAssetMake.valueChanges.pipe(
        delay(0),
        tap((r: RedbookSearchByMakeResponse[number]|string|null) => {
          if (r && (typeof r != 'string') && r.makecode) {
            this.redbookService.assetSearchFn({action: 'families', makecode: r.makecode, type: 'all'}).pipe(
              delay(0),
              first(),
              tap((r: RedbookSearchByFamiliesResponse) => {
                this.familySelection = r;
                console.log(r)
                this.yearSelection = [];
                this.modelSelection = [];
                this.formControlAssetFamily.setValue(null);
                this.formControlAssetYear.setValue(null);
                this.formControlAssetModel.setValue(null);
                this.formControlAssetVehicle.setValue(null);
              })
            ).subscribe();
          }
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlAssetFamily.valueChanges.pipe(
        delay(0),
        tap((r: RedbookSearchByFamiliesResponse[number] | string | null) => {
          if (r && (typeof r != 'string') && r.makecode && r.familycode) {
            this.redbookService.assetSearchFn({action: 'years', makecode: r.makecode, familycode: r.familycode, type: 'all'}).pipe(
              delay(0),
              tap((r: RedbookSearchByYearsResponse) => {
                this.yearSelection = r;
                this.modelSelection = [];
                this.formControlAssetYear.setValue(null);
                this.formControlAssetModel.setValue(null);
                this.formControlAssetVehicle.setValue(null);
              })
            ).subscribe();
          }
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlAssetYear.valueChanges.pipe(
        delay(0),
        tap((r: RedbookSearchByYearsResponse[number]|string|null) => {
          if (r && (typeof r != 'string') && r.makecode && r.familycode && r.year) {
            this.redbookService.assetSearchFn({action: 'vehicles', makecode: r.makecode, familycode: r.familycode, year: r.year, type: 'all'}).pipe(
              delay(0),
              tap((r: RedbookSearchByVehiclesResponse) => {
                this.modelSelection = r;
                this.formControlAssetVehicles.setValue(r);
                this.formControlAssetModel.setValue(null);
                this.formControlAssetVehicle.setValue(null);
              })
            ).subscribe();
          }
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlAssetModel.valueChanges.pipe(
        delay(0),
        tap((r: RedbookSearchByVehiclesResponse[number]|string|null) => {
          if (r && (typeof r != 'string')) {
            this.vehicleSelection = r;
            this.formControlAssetVehicle.setValue(r);
          }
        })
      ).subscribe()
    )
  }
}
