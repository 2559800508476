import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, of, Subscription } from 'rxjs';
import {BusinessNumberSearchFn, AnnualRevenueValue, individualGarantors, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { BusinessSearchFn } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchFn } from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import { Moment } from 'moment';
import {
  AbnComponentValue, AcnComponentValue,
  Address2ComponentValue, AggregatorSearchComponentValue,
  AssetCategoryTypeSelectionValue, BrokerageSelectionValueOptions,
  BusinessSearchValue,
  ConsumerFinanceSummary, CurrencyInputValue, DatepickerValue, EmailComponentValue,
  EmployerValue,
  EmploymentStatusSelectionValue,
  GenderValue,
  IncomeSelectionValue,
  MaritialStatusSelectionValue, MobileValue,
  NameComponentValue,
  PrimaryIndustrySelectionValue,
  PropertyOwnerWithAddressValue,
  ReferenceValue,
  SecondaryIndustrySelectionValue,
  SelectContactValue, TermRateForAssetFinance,
  TimeInAddressValue,
  TitleSelectionValue, UploadFileValue,
  YesNoValue
} from '@portal-workspace/grow-shared-library';
import { map, tap } from 'rxjs/operators';
import { RedbookService } from '../../service/redbook.service';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import {
  GuarantorValue,
  IndividualGuarantor
} from '@portal-workspace/grow-shared-library';
import { ContactValue } from '@portal-workspace/grow-shared-library';
import { ReferenceComponentEvent } from '@portal-workspace/grow-ui-library';
import {
  TrusteeValue
} from '@portal-workspace/grow-shared-library';
import {
  SoleTraderValue
} from '@portal-workspace/grow-shared-library';
import {
  DirectorValue
} from '@portal-workspace/grow-shared-library';
import {
  PartnerValue
} from '@portal-workspace/grow-shared-library';
import { MemberValue } from '@portal-workspace/grow-shared-library';
import { EntityTypeValue, EntityTypes, EntityTypeValueOptions as EntityTypeOptions } from '@portal-workspace/grow-shared-library';
import { ApplicationService } from '../../service/application.service'
import { RatecardDetails } from '@portal-workspace/grow-shared-library';
import {
  applicantsThatAreGuarantor,
  createAbnInputMask,
  createAcnInputMask,
  createCurrencyInputMask,
  createEmailInputMask,
  createPhoneNumberInputMask,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, getAddress2ComponentValueFormControlValueFn} from '@portal-workspace/grow-ui-library';
import {
  calculateSolarAssetFinanceEstimation
} from '@portal-workspace/grow-shared-library';
// import {setCurrentStepFn} from '../../component/application-stepper-component/application-stepper.component';
import { AssetCategoryType, getCommercialOnlyAssetCategoryTypes } from '@portal-workspace/grow-shared-library';
import { requiredAllowEmptyValidator } from '@portal-workspace/grow-ui-library';
import { CommercialFinanceSummary } from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import { navigationUrlForSolarPage } from '../../service/navigation-urls';
import { ApplicationApplicant, User, GetRateCardDetailsFn } from '@portal-workspace/grow-shared-library';
import { BrokerageSelectionValue } from '@portal-workspace/grow-shared-library';
import { AssetCategorySelectionValue } from '@portal-workspace/grow-shared-library';
import { AppCalculator, ConsumerFinanceFormData, TermRate } from '@portal-workspace/grow-shared-library';
import { TotalPaymentBreakupDialogData } from '@portal-workspace/grow-shared-library';
import { PaymentFrequencyValue } from '@portal-workspace/grow-shared-library';
import { LoanTermValue } from '@portal-workspace/grow-shared-library';
import numeral from 'numeral';
import { CommercialFinanceSummaryComponent } from '@portal-workspace/grow-ui-library';
import { SelectContactComponent } from '@portal-workspace/grow-ui-library';
import { GuarantorComponent } from '@portal-workspace/grow-ui-library';
import { MemberComponent } from '@portal-workspace/grow-ui-library';
import { DirectorComponent } from '@portal-workspace/grow-ui-library';
import { PartnerComponent } from '@portal-workspace/grow-ui-library';
import { SoleTraderComponent } from '@portal-workspace/grow-ui-library';
import { TrusteeComponent } from '@portal-workspace/grow-ui-library';
import { SecondaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { PrimaryIndustrySelectionComponent } from '@portal-workspace/grow-ui-library';
import { AnnualRevenueComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent } from '@portal-workspace/grow-ui-library';
import { ConsumerFinanceSummaryComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { EmployerComponent } from '@portal-workspace/grow-ui-library';
import { IncomeComponent } from '@portal-workspace/grow-ui-library';
import { EmploymentStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MaritialStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { PropertyOwnerWithAddressComponent } from '@portal-workspace/grow-ui-library';
import { TimeInAddressComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { GenderComponent } from '@portal-workspace/grow-ui-library';
import { DatepickerComponent } from '@portal-workspace/grow-ui-library';
import { TitleSelectionComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { CommercialLoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssetCategoryTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationStepper2Component,setStepper2StepConfig } from '@portal-workspace/grow-ui-library';
import { EligibilityComponent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
    templateUrl: './solar.page.html',
    styleUrls: ['./solar.page.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatButtonModule, EligibilityComponent, ApplicationStepper2Component, CdkStepperModule, FormsModule, ReactiveFormsModule, NameComponent, AggregatorSearchComponent, MarkDirective, CurrencyInputComponent, AssetCategoryTypeSelectionComponent, MatFormFieldModule, MatInputModule, CommercialLoanTermsSelectionComponent, PaymentFrequencySelectionComponent, YesNoComponent, ApplicationHeaderSegmentComponent, TitleSelectionComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, TimeInAddressComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, MaritialStatusSelectionComponent, EmploymentStatusSelectionComponent, IncomeComponent, EmployerComponent, ReferenceComponent, MessageBoxComponent, UploadFileComponent, ConsumerFinanceSummaryComponent, BusinessSearchComponent, EntityTypeComponent, AbnComponent, AcnComponent, AnnualRevenueComponent, PrimaryIndustrySelectionComponent, SecondaryIndustrySelectionComponent, TrusteeComponent, SoleTraderComponent, PartnerComponent, DirectorComponent, MemberComponent, GuarantorComponent, SelectContactComponent, CommercialFinanceSummaryComponent]
})
export class SolarPage implements AfterViewInit {
  currentTemplate: TemplateRef<any> | null = null;
  consumerType: any
  @ViewChild('getStarted') getStartedTemplate!: TemplateRef<any>;
  @ViewChild('eligibility') eligibilityTemplate!: TemplateRef<any>;
  @ViewChild('steps') stepsTemplate!: TemplateRef<any>;
  @ViewChild('indexStep') indexStep!: TemplateRef<any>;

  subscriptions: Subscription[] = [];
  // global: step 3 onwards
  applicationId: string = 'Not available';
  organisationName: string = 'Not available';
  businessSearchPlaceholder:string="Enter name or ABN…"

 

  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;
  getRatecardDetailsFn!: GetRateCardDetailsFn;
  user!:User
  // Consumer STEP 1
  formControlStep1FirstName!: FormControl<NameComponentValue>;
  formControlStep1LastName!: FormControl<NameComponentValue>;
  consumerFormGroupStep1!: FormGroup<{
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
  }>;

  // Consumer STEP 2:
  step2MonthlyRepayment: string = '';
  step2EstimatedDrawdownDate: Moment = moment();
  step2AssetTypes!: AssetCategoryType[];
  step2BrokerSearchFn!: AggregatorSearchFn;
  formControlStep2Broker!: FormControl<AggregatorSearchComponentValue>;
  formControlStep2InvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlStep2DepositAmount!: FormControl<CurrencyInputValue>;
  formControlStep2LoanAmount!: FormControl<CurrencyInputValue>;
  formControlStep2AssetType!: FormControl<AssetCategoryTypeSelectionValue>;
  formControlStep2AssetDescription!: FormControl<string | null>;
  formControlStep2LoanTerms!: FormControl<LoanTermValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formGroupStep2!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    invoiceAmount: FormControl<CurrencyInputValue>,
    depositAmount: FormControl<CurrencyInputValue>,
    loanAmount: FormControl<CurrencyInputValue>,
    assetType: FormControl<AssetCategoryTypeSelectionValue>,
    assetDescription:FormControl<string | null>,
    loanTerms: FormControl<LoanTermValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
  }>;
  terms: TermRateForAssetFinance | null = null;          // available after chart popups
  calculator: AppCalculator | null = null;   // available after chart popups


  // Consumer STEP 3
  formControlStep3Title!: FormControl<TitleSelectionValue>;
  formControlStep3FirstName!: FormControl<NameComponentValue>;
  formControlStep3LastName!: FormControl<NameComponentValue>;
  formControlStep3Dob!: FormControl<DatepickerValue>;
  formControlStep3Gender!: FormControl<GenderValue>;
  formControlStep3ResidentialAddress!: FormControl<Address2ComponentValue>;
  formControlStep3TimeInAddress!: FormControl<TimeInAddressValue>;
  formControlStep3PrivacyConsent!: FormControl<YesNoValue>;
  formControlStep3PropertyOwnerWithAddress!: FormControl<PropertyOwnerWithAddressValue>;
  formControlStep3Email!: FormControl<EmailComponentValue>;
  formControlStep3Mobile!: FormControl<MobileValue>;
  formControlStep3MaritalStatus!: FormControl<MaritialStatusSelectionValue>;
  formControlStep3NumberOfDependants!: FormControl<string | null>;
  formControlStep3EmploymentStatus!: FormControl<EmploymentStatusSelectionValue>;
  formControlStep3RegularIncome!: FormControl<IncomeSelectionValue>;
  formControlStep3Employer!: FormControl<EmployerValue>;
  consumerFormGroupStep3!: FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    gender: FormControl<GenderValue>,
    residentialAddress: FormControl<Address2ComponentValue>,
    timeAtAddress: FormControl<TimeInAddressValue>,
    privacyConsent: FormControl<YesNoValue>,
    propertyOwner: FormControl<PropertyOwnerWithAddressValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    maritalStatus: FormControl<MaritialStatusSelectionValue>,
    numberOfDependants: FormControl<string | null>,
    employmentStatus: FormControl<EmploymentStatusSelectionValue>,
    regularIncome: FormControl<IncomeSelectionValue>,
    employer: FormControl<EmployerValue>,
  }>;

  // Consumer STEP 4
  formControlStep4Title!: FormControl<TitleSelectionValue>;
  formControlStep4FirstName!: FormControl<NameComponentValue>;
  formControlStep4LastName!: FormControl<NameComponentValue>;
  formControlStep4Dob!: FormControl<DatepickerValue>;
  formControlStep4Gender!: FormControl<GenderValue>;
  formControlStep4Email!: FormControl<EmailComponentValue>;
  formControlStep4Mobile!: FormControl<MobileValue>;
  formControlStep4EmploymentStatus!: FormControl<EmploymentStatusSelectionValue>;
  formControlStep4RegularIncome!: FormControl<IncomeSelectionValue>;
  formControlStep4EmployerName!: FormControl<string | null>;
  formControlStep4EmployerContact!: FormControl<MobileValue>;
  formControlStep4Guarantor!: FormControl<YesNoValue>;
  formControlStep4PrivacyConsent!: FormControl<YesNoValue>;
  step4ShowPrivacyConsent = false;
  consumerFormGroupStep4!: FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    gender: FormControl<GenderValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    employmentStatus: FormControl<EmploymentStatusSelectionValue>,
    regularIncome: FormControl<IncomeSelectionValue>,
    employerName: FormControl<string | null>,
    employerContact: FormControl<MobileValue>,
    guarantor: FormControl<YesNoValue>,
    privacyConsent: FormControl<YesNoValue>,
  }>;

  // Consumer STEP 5
  formControlStep5HomeLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Electricity!: FormControl<CurrencyInputValue>;
  formControlStep5CarLoans!: FormControl<CurrencyInputValue>;
  formControlStep5OtherUtilities!: FormControl<CurrencyInputValue>;
  formControlStep5PersonalLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Education!: FormControl<CurrencyInputValue>;
  formControlStep5TotalCreditCardLimits!: FormControl<CurrencyInputValue>;
  formControlStep5Groceries!: FormControl<CurrencyInputValue>;
  formControlStep5OtherLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Insurance!: FormControl<CurrencyInputValue>;
  formControlStep5ShareOfMonthlyLivingExpanses!: FormControl<number | null>;
  consumerFormGroupStep5!: FormGroup<{
    homeLoans: FormControl<CurrencyInputValue>,
    electricity: FormControl<CurrencyInputValue>,
    carLoans: FormControl<CurrencyInputValue>,
    otherUtilities: FormControl<CurrencyInputValue>,
    personalLoans: FormControl<CurrencyInputValue>,
    education: FormControl<CurrencyInputValue>,
    totalCreditCardLimits: FormControl<CurrencyInputValue>,
    groceries: FormControl<CurrencyInputValue>,
    otherLoans: FormControl<CurrencyInputValue>,
    insurance: FormControl<CurrencyInputValue>,
    shareOfMonthlyLivingExpanses: FormControl<number | null>,
  }>;

  //Consumer STEP 6
  formControlStep6References!: FormControl<ReferenceValue>;
  consumerFormGroupStep6!: FormGroup<{
    references: FormControl<ReferenceValue>
  }>;

  // Consumer STEP 7
  formControlStep7ApplicantDriverLicenceFront!: FormControl<UploadFileValue>;
  formControlStep7ApplicantSpouseDriverLicenceFront!: FormControl<UploadFileValue>;
  formControlStep7OtherSupportingDocuments!: FormControl<UploadFileValue>;
  formControlStep7ApplicationNotes!: FormControl<string | null>;
  consumerFormGroupStep7!: FormGroup<{
    applicantDriverLicenceFront: FormControl<UploadFileValue>,
    applicantSpouseDriverLicenceFront: FormControl<UploadFileValue>,
    otherSupportingDocuments: FormControl<UploadFileValue>,
    applicationNotes: FormControl<string | null>,
  }>;

  // Consumer STEP 8
  step8Summary!: ConsumerFinanceSummary;
  consumerFormGroupStep8!: FormGroup<{}>;


  //Commercial Form Group and Controls

  // Commercial STEP 1:
  step1BusinessSearchFn!: BusinessSearchFn;
  formControlStep1Business!: FormControl<BusinessSearchValue>;
  commercialFormGroupStep1!: FormGroup<{
    business: FormControl<BusinessSearchValue>,
  }>;


  // Commercial STEP 3:
  step3OnwardsOrganisationType: EntityTypes | null = null;
  formControlStep3OrganisationType!: FormControl<EntityTypeValue>;
  formControlStep3Abn!: FormControl<AbnComponentValue>;
  formControlStep3Acn!: FormControl<AcnComponentValue>;
  formControlStep3AnnualRevenue!: FormControl<AnnualRevenueValue>;
  formControlStep3OperatesInCommercialPremises!: FormControl<YesNoValue>;
  formControlStep3PrimaryIndustry!: FormControl<PrimaryIndustrySelectionValue>;
  formControlStep3IndustrySector!: FormControl<SecondaryIndustrySelectionValue>;
  formControlStep3PrimaryBusinessAddress!: FormControl<Address2ComponentValue>;
  formControlStep3BusinessLandline!: FormControl<MobileValue>;
  commercialFormGroupStep3!: FormGroup<{
    organisationType: FormControl<EntityTypeValue>,
      abn: FormControl<AbnComponentValue>,
      acn: FormControl<AcnComponentValue>,
      annualRevenue: FormControl<AnnualRevenueValue>,
      operatesInCommercialPremises: FormControl<YesNoValue>,
      primaryIndustry: FormControl<PrimaryIndustrySelectionValue>,
      industrySector: FormControl<SecondaryIndustrySelectionValue>,
      primaryBusinessAddress: FormControl<Address2ComponentValue>,
      businessLandline: FormControl<MobileValue>,
  }>;

  // Commercial STEP 4:
  formControlStep4Applicants!: FormControl<ApplicationApplicant>;
  commercialFormGroupStep4!: FormGroup<{
    applicants: FormControl<ApplicationApplicant>
  }>;

  // Commercial STEP 5:
  step5CompanySearchFn!: BusinessSearchFn;
  step5BusinessNumberSearchFn!: BusinessNumberSearchFn;
  step5MandatoryGuarantors: IndividualGuarantor[] = [];
  formControlStep5Guarantors!: FormControl<GuarantorValue>;
  commercialFormGroupStep5!: FormGroup<{
    guarantors: FormControl<GuarantorValue>
  }>;

  // Commercial STEP 6:
  step6PredefinedContactSelection!: ContactValue[];
  formControlStep6PrimaryContact!: FormControl<SelectContactValue>;
  step6PossiblePrimaryContacts: ContactValue[] = [];
  commercialFormGroupStep6!: FormGroup<{
    primaryContact: FormControl<SelectContactValue>
  }>;

  // Commercial STEP 7:
  formControlStep7References!: FormControl<ReferenceValue>;
  commercialFormGroupStep7!: FormGroup<{
    references: FormControl<ReferenceValue>
  }>;

  // Commercial STEP 8:
  formControlStep8OtherSupportingDocuments!: FormControl<UploadFileValue>;
  formControlStep8ApplicationNotes!: FormControl<string | null>;
  step8DriverLicenses: { id: string, name: string, formControl: FormControl<UploadFileValue> }[] = [];
  commercialFormGroupStep8!: FormGroup<{}>;

  // Commercial STEP 9:
  step9Summary!: CommercialFinanceSummary;
  commercialFormGroupStep9!: FormGroup<{}>;

  rateCard: RatecardDetails | null = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: PortalHotToastService,
    private redbookService: RedbookService,
    private applicationDialogService: ApplicationDialogService,
    private applicationService: ApplicationService,
    private userService:AuthService,) {
    this.consumerinitStep1();
    this.consumerinitStep2();
    this.consumerinitStep3();
    this.consumerinitStep4();
    this.consumerinitStep5();
    this.consumerinitStep6();
    this.consumerinitStep7();
    this.consumerinitStep8();

    this.commercialinitStep1();
    this.commercialinitStep2();
    this.commercialinitStep3();
    this.commercialinitStep4();
    this.commercialinitStep5();
    this.commercialinitStep6();
    this.commercialinitStep7();
    this.commercialinitStep8();
    this.commercialinitStep9();
  }

  ngOnInit() {
    setupUntilDestroy(this);
  }


  consumerinitStep1() {
    this.formControlStep1FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1LastName = this.formBuilder.control(null, [Validators.required]);
    this.consumerFormGroupStep1 = this.formBuilder.group({
      firstName: this.formControlStep1FirstName,
      lastName: this.formControlStep1LastName,
    });
  }

  consumerinitStep2() {
    this.step2AssetTypes = getCommercialOnlyAssetCategoryTypes();
    this.step2BrokerSearchFn = (term) => {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.formControlStep2Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetDescription = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(false, [Validators.required]);
    this.formGroupStep2 = this.formBuilder.group({
      broker: this.formControlStep2Broker,
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      assetType: this.formControlStep2AssetType,
      assetDescription:this.formControlStep2AssetDescription,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
    });
    const subscription = combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0] ?? 0;
        const depositAmount = r[1] ?? 0;
        const loanAmount = (invoiceAmount - depositAmount > 0) ? (invoiceAmount - depositAmount) : 0;
        this.formControlStep2LoanAmount.setValue(loanAmount);
      })
    ).subscribe();
    this.subscriptions.push(subscription);

     
    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        this.step2SubmitCalculateMontlyRepaymentAndEstimatedDrawdownForCharts(stepper);
      },
      nextStepButtonText: 'Calculate Repayments',
    });
    
    
  }

  consumerinitStep3() {
    this.formControlStep3Title = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Dob = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Gender = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3ResidentialAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3TimeInAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrivacyConsent = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PropertyOwnerWithAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Email = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Mobile = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3MaritalStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3NumberOfDependants = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3EmploymentStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3RegularIncome = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Employer = this.formBuilder.control(null, [Validators.required]);
    this.consumerFormGroupStep3 = this.formBuilder.group({
      title: this.formControlStep3Title,
      firstName: this.formControlStep3FirstName,
      lastName: this.formControlStep3LastName,
      dob: this.formControlStep3Dob,
      gender: this.formControlStep3Gender,
      residentialAddress: this.formControlStep3ResidentialAddress,
      timeAtAddress: this.formControlStep3TimeInAddress,
      privacyConsent: this.formControlStep3PrivacyConsent,
      propertyOwner: this.formControlStep3PropertyOwnerWithAddress,
      email: this.formControlStep3Email,
      mobile: this.formControlStep3Mobile,
      maritalStatus: this.formControlStep3MaritalStatus,
      numberOfDependants: this.formControlStep3NumberOfDependants,
      employmentStatus: this.formControlStep3EmploymentStatus,
      regularIncome: this.formControlStep3RegularIncome,
      employer: this.formControlStep3Employer,
    });
  }

  consumerinitStep4() {
    this.formControlStep4Title = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Dob = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Gender = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Email = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Mobile = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmploymentStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4RegularIncome = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmployerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmployerContact = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Guarantor = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep4PrivacyConsent = this.formBuilder.control(false);
    const sub = this.formControlStep4Guarantor.valueChanges.pipe(
      // delay(0),
      tap(r => {
        if (r === true) {
          this.formControlStep4PrivacyConsent.setValidators([Validators.requiredTrue]);
          this.formControlStep4PrivacyConsent.updateValueAndValidity();
          this.step4ShowPrivacyConsent = true;
        } else {
          this.step4ShowPrivacyConsent = false;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.consumerFormGroupStep4 = this.formBuilder.group({
      title: this.formControlStep4Title,
      firstName: this.formControlStep4FirstName,
      lastName: this.formControlStep4LastName,
      dob: this.formControlStep4Dob,
      gender: this.formControlStep4Gender,
      email: this.formControlStep4Email,
      mobile: this.formControlStep4Mobile,
      employmentStatus: this.formControlStep4EmploymentStatus,
      regularIncome: this.formControlStep4RegularIncome,
      employerName: this.formControlStep4EmployerName,
      employerContact: this.formControlStep4EmployerContact,
      guarantor: this.formControlStep4Guarantor,
      privacyConsent: this.formControlStep4PrivacyConsent,
    });

 
    setStepper2StepConfig(this.consumerFormGroupStep4, {
      nextStepClickedFn: async stepper => {
          const sub = this.applicationDialogService.openCreditInfoExchangeAlertDialog().afterClosed().pipe(
            tap(r => {
              if (r && r.proceed) {
                stepper.next();
              }
            })
          ).subscribe();
          this.subscriptions.push(sub);
      },
    });
    
  }

  consumerinitStep5() {
    this.formControlStep5HomeLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Electricity = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5CarLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5OtherUtilities = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5PersonalLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Education = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5TotalCreditCardLimits = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Groceries = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5OtherLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Insurance = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5ShareOfMonthlyLivingExpanses = this.formBuilder.control(null, [Validators.required]);
    this.consumerFormGroupStep5 = this.formBuilder.group({
      homeLoans: this.formControlStep5HomeLoans,
      electricity: this.formControlStep5Electricity,
      carLoans: this.formControlStep5CarLoans,
      otherUtilities: this.formControlStep5OtherUtilities,
      personalLoans: this.formControlStep5PersonalLoans,
      education: this.formControlStep5Education,
      totalCreditCardLimits: this.formControlStep5TotalCreditCardLimits,
      groceries: this.formControlStep5Groceries,
      otherLoans: this.formControlStep5OtherLoans,
      insurance: this.formControlStep5Insurance,
      shareOfMonthlyLivingExpanses: this.formControlStep5ShareOfMonthlyLivingExpanses,
    });
  }

  consumerinitStep6() {
    // this.formControlStep6References = this.formBuilder.control(null, [Validators.required]);
    // this.consumerFormGroupStep6 = this.formBuilder.group({
    //   references: this.formControlStep6References,
    // });

    // this.step6CompanySearchFn = (search) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // };
    this.formControlStep6References = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.consumerFormGroupStep6 = this.formBuilder.group({
      references: this.formControlStep6References,
    });
  }

  consumerinitStep7() {
    this.formControlStep7ApplicantDriverLicenceFront = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep7ApplicantSpouseDriverLicenceFront = this.formBuilder.control(null);
    this.formControlStep7ApplicationNotes = this.formBuilder.control(null);
    this.formControlStep7OtherSupportingDocuments = this.formBuilder.control(null);
    this.consumerFormGroupStep7 = this.formBuilder.group({
      applicantDriverLicenceFront: this.formControlStep7ApplicantDriverLicenceFront,
      applicantSpouseDriverLicenceFront: this.formControlStep7ApplicantSpouseDriverLicenceFront,
      otherSupportingDocuments: this.formControlStep7OtherSupportingDocuments,
      applicationNotes: this.formControlStep7ApplicationNotes,
    });
 
    setStepper2StepConfig(this.consumerFormGroupStep7, {
      stepSelectionEventFn: e => {
         if (this.step3OnWardsHasSpouse()) {
            this.formControlStep7ApplicantSpouseDriverLicenceFront.setValidators([Validators.required]);
            this.formControlStep7ApplicantSpouseDriverLicenceFront.updateValueAndValidity();
          }
      }
    
    });
  }

  consumerinitStep8() {
    this.consumerFormGroupStep8 = this.formBuilder.group({});
   
    setStepper2StepConfig(this.consumerFormGroupStep8, {
      stepSelectionEventFn: e => {
          this.step8Summary = {
            repayment: this.step2MonthlyRepayment,
            estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
            applicationId: this.applicationId,
            repaymentFrequency: this.formControlStep2PaymentFrequency.value?.type ?? 'Monthly',
            title: this.formControlStep3Title.value,
            firstName: this.formControlStep3FirstName.value!,
            lastName: this.formControlStep3LastName.value!,
            dob: this.formControlStep3Dob.value!,
            gender: this.formControlStep3Gender.value,
            privacyConsent: this.formControlStep3PrivacyConsent.value,
            timeInAddress: this.formControlStep3TimeInAddress.value,
            propertyOwnerWithAddress: this.formControlStep3PropertyOwnerWithAddress.value,
            email: this.formControlStep3Email.value!,
            mobileNumber: this.formControlStep3Mobile.value!,
            maritalStatus: this.formControlStep3MaritalStatus.value,
            numberOfDependants: this.formControlStep3NumberOfDependants.value!,
            employmentStatus: this.formControlStep3EmploymentStatus.value,
            regularIncome: this.formControlStep3RegularIncome.value,
            employer: this.formControlStep3Employer.value,
            brokerContact:null,
            spouse: this.step3OnWardsHasSpouse() ? {
              title: this.formControlStep4Title.value,
              firstName: this.formControlStep4FirstName.value!,
              lastName: this.formControlStep4LastName.value!,
              dob: this.formControlStep4Dob.value!,
              gender: this.formControlStep4Gender.value,
              email: this.formControlStep4Email.value!,
              mobileNumber: this.formControlStep4Mobile.value!,
              employmentStatus: this.formControlStep4EmploymentStatus.value,
              regularIncome: this.formControlStep4RegularIncome.value,
              employer: this.formControlStep4EmployerName.value!,
              employerContact: this.formControlStep4EmployerContact.value!,
              guarantor: this.formControlStep4Guarantor.value,
              privacyConsent: this.formControlStep4PrivacyConsent.value,
            } : undefined,
    
            expanses: {
              homeLoans: String(this.formControlStep5HomeLoans.value ?? 0),
              electricity: String(this.formControlStep5Electricity.value ?? 0),
              carLoans: String(this.formControlStep5CarLoans.value ?? 0),
              otherUtilities: String(this.formControlStep5OtherUtilities.value ?? 0),
              personalLoans: String(this.formControlStep5PersonalLoans.value ?? 0),
              education: String(this.formControlStep5Education.value ?? 0),
              totalCreditCardLimits: String(this.formControlStep5TotalCreditCardLimits.value ?? 0),
              groceries: String(this.formControlStep5Groceries.value ?? 0),
              otherLoans: String(this.formControlStep5OtherLoans.value ?? 0),
              insurance: String(this.formControlStep5Insurance.value ?? 0),
              shareMonthlyLivingExpanses: String(this.formControlStep5ShareOfMonthlyLivingExpanses.value ?? 0),
            },
    
            references: this.formControlStep6References.value,
    
            applicationNotes: this.formControlStep7ApplicationNotes.value ?? '',
          }
      }, nextStepClickedFn:  stepper => {
        this.finalSubmission(stepper, this.step8Summary);
      },
    
    });

  }


  commercialinitStep1() {
    // this.step1BusinessSearchFn = (search: string) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // }
    this.step1BusinessSearchFn = this.applicationService.businessSearchFn;
    this.formControlStep1Business = this.formBuilder.control(null, [Validators.required]);
    this.commercialFormGroupStep1 = this.formBuilder.group({
      business: this.formControlStep1Business,
    });
  }
  commercialinitStep2() {
    this.step2AssetTypes = getCommercialOnlyAssetCategoryTypes();
    this.step2BrokerSearchFn = (term) => {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.formControlStep2Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2AssetDescription = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(false, [Validators.required]);
    this.formGroupStep2 = this.formBuilder.group({
      broker: this.formControlStep2Broker,
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      assetType: this.formControlStep2AssetType,
      assetDescription:this.formControlStep2AssetDescription,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
    });
    const subscription = combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0] ?? 0;
        const depositAmount = r[1] ?? 0;
        const loanAmount = (invoiceAmount - depositAmount > 0) ? (invoiceAmount - depositAmount) : 0;
        this.formControlStep2LoanAmount.setValue(loanAmount);
      })
    ).subscribe();
    this.subscriptions.push(subscription);
     
   
    setStepper2StepConfig(this.formGroupStep2, {
      nextStepButtonText: 'Calculate Repayments',
      nextStepClickedFn: stepper => {
        this.step2SubmitCalculateMontlyRepaymentAndEstimatedDrawdownForCharts(stepper);
      }   
   });
   
  }
  commercialinitStep3() {
    this.formControlStep3OrganisationType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Abn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Acn = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3AnnualRevenue = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3OperatesInCommercialPremises = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep3PrimaryIndustry = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3IndustrySector = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrimaryBusinessAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3BusinessLandline = this.formBuilder.control(null);
    this.commercialFormGroupStep3 = this.formBuilder.group({
      organisationType: this.formControlStep3OrganisationType,
      abn: this.formControlStep3Abn,
      acn: this.formControlStep3Acn,
      annualRevenue: this.formControlStep3AnnualRevenue,
      operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises,
      primaryIndustry: this.formControlStep3PrimaryIndustry,
      industrySector: this.formControlStep3IndustrySector,
      primaryBusinessAddress: this.formControlStep3PrimaryBusinessAddress,
      businessLandline: this.formControlStep3BusinessLandline,
    });
    const sub = this.formControlStep3OrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => {
        if (r) {
          if (this.formControlStep4Applicants) {
            this.formControlStep4Applicants.setValue(null);
          }
          this.step3OnwardsOrganisationType = r.type;
          if (r.type === 'company') {
            this.formControlStep3Acn.setValidators([Validators.required]);
          } else {
            this.formControlStep3Acn.setValidators(null);
            this.formControlStep3Acn.updateValueAndValidity();
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
   
    setStepper2StepConfig(this.commercialFormGroupStep3, {
       
          nextStepClickedFn: stepper => {
               this.applicationDialogService.openCreditInfoExchangeAlertDialog().afterClosed().pipe(
            tap(r => {
              if (r && r.proceed) {
                stepper.next();
              }
            })
          ).subscribe()
      }
    
    });
    
  }
  commercialinitStep4() {
    this.formControlStep4Applicants = this.formBuilder.control(null, [Validators.required]);
    this.commercialFormGroupStep4 = this.formBuilder.group({
      applicants: this.formControlStep4Applicants
    });
  }
  commercialinitStep5() {
    // this.step5CompanySearchFn = (search) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // };
    this.step5CompanySearchFn = this.applicationService.businessSearchFn;
    this.step5BusinessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.formControlStep5Guarantors = this.formBuilder.control(null);
    this.commercialFormGroupStep5 = this.formBuilder.group({
      guarantors: this.formControlStep5Guarantors,
    });
   
    setStepper2StepConfig(this.commercialFormGroupStep5, {
      stepSelectionEventFn: e => {
        const guarantors = this.step5OnwardsApplicantsThatAreGuarantor();
        this.step5MandatoryGuarantors = guarantors;
      }
    });
  }

  commercialinitStep6() {
    this.step6PredefinedContactSelection = [];
    this.formControlStep6PrimaryContact = this.formBuilder.control(null, [Validators.required]);
    this.commercialFormGroupStep6 = this.formBuilder.group({
      primaryContact: this.formControlStep6PrimaryContact,
    });
    setStepper2StepConfig(this.commercialFormGroupStep6, {
     
      
    stepSelectionEventFn: e => {
       const mandatoryGuarantors: IndividualGuarantor[] = this.step5MandatoryGuarantors;
          const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
          const possiblePrimaryContacts: ContactValue[] = [];
          const applicants: ApplicationApplicant = this.formControlStep4Applicants.value;
    
                // //individual can be primary contact
                // if(applicants) {
                //   const c: ContactValue[] = applicantsToPrimaryContact(applicants);
                //   if(c) {
                //     possiblePrimaryContacts.push(...c);
                //   }
                // }
          // all guarantors can be primary contact
          for (const g of mandatoryGuarantors) {
            const c: ContactValue = {
              isManual: false,
              email: g.email,
              mobileNumber: g.mobileNumber,
              areaCode: '',
              lastName: g.lastName,
              firstName: g.firstName,
              title: g.title,
              telephone: g.mobileNumber,
            };
            possiblePrimaryContacts.push(c);
          }
          if (guarantors) {
            for (const g of guarantors) {
              if (g.type === 'Individual') {
                const c: ContactValue = {
                  isManual: false,
                  email: g.email,
                  mobileNumber: g.mobileNumber,
                  areaCode: '',
                  lastName: g.lastName,
                  firstName: g.firstName,
                  title: g.title,
                  telephone: g.mobileNumber,
                };
                possiblePrimaryContacts.push(c);
              }
            }
          }
          this.step6PossiblePrimaryContacts = possiblePrimaryContacts;
    }
    
    });
    
  }
  commercialinitStep7() {
    // this.step7CompanySearchFn = (search) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // };
    this.formControlStep7References = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.commercialFormGroupStep7 = this.formBuilder.group({
      references: this.formControlStep7References,
    });
  }
  commercialinitStep8() {
    this.formControlStep8OtherSupportingDocuments = this.formBuilder.control(null);
    this.formControlStep8ApplicationNotes = this.formBuilder.control(null);
    this.commercialFormGroupStep8 = this.formBuilder.group({});
     
    setStepper2StepConfig(this.commercialFormGroupStep8, {
      nextStepButtonText: 'Next to Review',
      stepSelectionEventFn: e => {
            const applicantsThatAreGuarantor: IndividualGuarantor[] = this.step5OnwardsApplicantsThatAreGuarantor();
            const guarantors: IndividualGuarantor[] = this.step5OnwardsIndividualGuarantors();
            const allThatRequireDriverLicences = [...applicantsThatAreGuarantor, ...guarantors];
      
            const driverLicences: { id: string, name: string, formControl: FormControl }[] = [];
            for (const requireDriverLicence of allThatRequireDriverLicences) {
              const id = `${_.lowerCase(requireDriverLicence.firstName)}${_.lowerCase(requireDriverLicence.middleName)}${_.lowerCase(requireDriverLicence.lastName)}`;
              const existingEntry = this.step8DriverLicenses.find(e => e.id === id);
              if (existingEntry) {
                driverLicences.push(existingEntry);
              } else {
                const name = `${requireDriverLicence.firstName} ${requireDriverLicence.lastName}`;
                const formControl = this.formBuilder.control(null, [Validators.required]);
                driverLicences.push({ id, name, formControl })
              }
            }
            this.step8DriverLicenses = driverLicences;
      }
    
    });

  }
  commercialinitStep9() {
    this.commercialFormGroupStep9 = this.formBuilder.group({});

    setStepper2StepConfig(this.commercialFormGroupStep9, {
      nextStepButtonText: 'Submit',
      nextStepClickedFn: stepper => {
             this.commercialfinalSubmission(stepper, this.step9Summary!);
      },
    
    stepSelectionEventFn: e => {
      this.step9Summary = {
            monthlyRepayment: this.step2MonthlyRepayment,
            estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
            applicationId: this.applicationId,
    
            organisation: this.formControlStep1Business.value,
            broker: this.formControlStep2Broker.value,
    
            invoiceAmount: String(this.formControlStep2InvoiceAmount.value),
            depositAmount: String(this.formControlStep2DepositAmount.value),
            loanAmount: String(this.formControlStep2LoanAmount.value),
            loanTerms: this.formControlStep2LoanTerms.value,
            paymentFrequency: this.formControlStep2PaymentFrequency.value,
            propertyOwner: this.formControlStep2PropertyOwner.value,
    
            assetCategoryType: this.formControlStep2AssetType.value,
            organisationType: this.formControlStep3OrganisationType.value,
            abn: this.formControlStep3Abn.value,
            acn: this.formControlStep3Acn.value,
            annualRevenue: String(this.formControlStep3AnnualRevenue.value ?? 0),
            operatesInCommercialPremises: this.formControlStep3OperatesInCommercialPremises.value,
            primaryIndustry: this.formControlStep3PrimaryIndustry.value,
            industrySector: this.formControlStep3IndustrySector.value,
            address: this.formControlStep3PrimaryBusinessAddress.value,
            businessLandline: this.formControlStep3BusinessLandline.value,
    
            applicants: this.formControlStep4Applicants.value ?? [],
    
            guarantors: this.formControlStep5Guarantors.value,
    
            primaryContact: this.formControlStep6PrimaryContact.value,
    
            references: this.formControlStep7References.value,
    
            applicationNotes: this.formControlStep8ApplicationNotes.value ?? '',
          }
    }
    
    });
  }

  finalSubmission(stepper: CdkStepper, summary: ConsumerFinanceSummary) {
    this.applicationDialogService.openConsumerFinanceApplicationConfirmationDialog()
      .afterClosed().pipe(
        tap(r => {
          console.log('******** ready for submission', r);
          if (r && r.readyForSubmission) {
            // todo: perform submission
            this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(r => {
                stepper.reset();
                stepper.next();
              })
            ).subscribe(res=>{
              this.router.navigate(navigationUrlForSolarPage());
              this.currentTemplate=this.indexStep
            });
          }
        })
      ).subscribe();
  }
  commercialfinalSubmission(stepper: CdkStepper, summary: CommercialFinanceSummary) {
    // todo: perform submission
    this.applicationDialogService.openCommercialFinanceApplicationConfirmationDialog()
      .afterClosed().pipe(
        tap(r => {
          console.log('******** ready for submission', r);
          if (r && r.readyForSubmission) {
            // todo: perform submission
            this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(r => {
                stepper.reset();
                stepper.next();
              })
            ).subscribe(res=>{
              this.router.navigate(navigationUrlForSolarPage());
              this.currentTemplate=this.indexStep
            });
          }
        })
      ).subscribe();
  }

  private step2SubmitCalculateMontlyRepaymentAndEstimatedDrawdownForCharts(stepper: CdkStepper) {
    // todo: workout monthly repayment and estimated darwdown date, populate chart data
    // calculate and populate
    let assetSelectionValue: AssetCategorySelectionValue = this.formControlStep2AssetType.value;
    const brokerageValue: BrokerageSelectionValue = BrokerageSelectionValueOptions[40]; // 4% brokerage (fixed)

    // this.step2MonthlyRepayment = '12000';
    this.step2EstimatedDrawdownDate = moment();
    console.log("Rate Card:::::",this.rateCard)
    const calculator = new AppCalculator();
    if(this.consumerType==='Commercial'){

      const subscription = this.applicationDialogService.openAssetQuotationDialog({
        loadFn: (date) => {
          const rst = calculateSolarAssetFinanceEstimation(date, {
            paymentFrequencyValue: this.formControlStep2PaymentFrequency.value,
            assetConditionValue: {type:'New',name:''},
            assetSelectionValue,
            assetYear:parseFloat('0'),
            loanTermValue: this.formControlStep2LoanTerms.value,
            financeType: {type:'chattel-mortgage',name:'Chattel Mortgage'},
            balloonPaymentType: {type:'0',name:''},
            brokerageValue,
            loanAmount: Number(this.formControlStep2LoanAmount.value) ?? 0,
            businessSearchValue: this.formControlStep1Business.value,
            existingApplicationBureauReport: null,
            propertyOwnership: false,
            docFeeFinanced: false,
            brokerOriginationFee: 0,
            brokerage: Number(brokerageValue?.type ?? 0),
            adverseOnFile: false,
            equifaxScoreAbove600: true,
            privateSaleOrLeaseback: false,
            balloonPayment: 0,
            assetCategory:{index:'106',value:''},
            rateCard: this.rateCard,
          })
          this.calculator = rst.calculator;
          this.terms = rst.terms;
          this.step2MonthlyRepayment = (rst.repayment).toString();
          return rst.totalPaymentBreakupDialogData;
        },
        isQuote: false,
        paymentChartHideBalloonPayment: false,
        paymentChartHideBrokerOriginationFee: false,
        paymentChartHideBrokerage: false,
        showPaymentChart: true,
        showArmotizationChart: true,
        showRepaymentEstimation: true,
        showArmotisationSchedule: true,
      }).afterClosed()
        .pipe(
          tap(r => {
            if (r && r.acceptedQuotation) {
              stepper.next();
            }
          })
        ).subscribe();
      this.subscriptions.push(subscription);
    } else {
      const subscription = this.applicationDialogService.openAssetQuotationDialog({
        loadFn: (date) => {
          return this.calculateEstimation(date);
        },
        isQuote: false,
        paymentChartHideBalloonPayment: false,
        paymentChartHideBrokerOriginationFee: false,
        paymentChartHideBrokerage: false,
        showPaymentChart: true,
        showArmotizationChart: true,
        showRepaymentEstimation: true,
        showArmotisationSchedule: true,
      }).afterClosed()
        .pipe(
          tap(r => {
          })
        ).subscribe();
      this.subscriptions.push(subscription);
    }
  }
  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    let assetSelectionValue: AssetCategorySelectionValue = this.formControlStep2AssetType.value;
    const brokerageValue: BrokerageSelectionValue = BrokerageSelectionValueOptions[40]; // 4% fixed
    const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;

    const calculator = new AppCalculator();
    const loanAmount = numeral(this.formControlStep2LoanAmount.value).value() ?? 0;
    console.log("Asset Type value from form::::",assetSelectionValue)
    const formData: ConsumerFinanceFormData = {
      assetCondition: 'New',
      assetCategory: '106',
      assetType:this.formControlStep2AssetType.value?.index ?? '',
      assetSpec: {
        year: parseFloat('0'),
        vehicle:[],
        goodretail:0,
        newprice:0
      },
      // ageOfAssetToday: ageOfAssetToday,
      loanAmount: loanAmount,
      loanTerms: parseFloat(loanTermValue?.type ?? '0'),
      propertyOwner: this.formControlStep2PropertyOwner.value ?? false,
      docFeeFinanced: false,
      brokerOriginationFee: 0,
      //brokerage: Number(brokerageValue?.type ?? 0),
      adverseOnFile: false,
      equifaxScoreAbove600: true,
      privateSaleORLeaseback: false,
      balloonPaymentPercent: 0,
      financeType:'Chattel Mortgage' ,
      invoiceAmount:0
    }

    this.terms = calculator.getTotalInterestRateForConsumerFinance(
      this.rateCard!,
      formData,
      [],
      // Number(balloonPaymentType?.type ?? '0'),
    );
    console.log('***************************** calculateInterest',this.terms);
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.setRepaymentFrequency(paymentFrequencyValue?.type ?? 'Monthly');
    calculator.setPrinicipalValue(this.terms.prinicipalAmount);
    calculator.setInterestValue(this.terms.totalInterest);
    calculator.setloanValue(Number(loanTermValue?.type ?? '0'));
    calculator.setResidualAmount(this.terms.RV);
    calculator.setBrokeragePercent(Number(brokerageValue?.type ?? '0'));
    calculator.setBrokerageAmount(this.terms.brokerageAmount)
    calculator.refreshUI();
    this.calculator = calculator;

    // this.step2MonthlyRepayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
      this.terms,
      paymentFrequencyValue?.type ?? 'Monthly',
      'advance',
    );
    const rst5 = calculator.getAmortisationData();
    const x: TotalPaymentBreakupDialogData = {
      repaymentEstimationData: rst4.map((rst ) =>({
        amountFinance: String(rst.amountFinance),
        month24: String(rst.data.find((d ) => d.loanTerm == 24)?.repayment ?? 0),
        month36: String(rst.data.find((d ) => d.loanTerm == 36)?.repayment ?? 0),
        month48: String(rst.data.find((d ) => d.loanTerm == 48)?.repayment ?? 0),
        month60: String(rst.data.find((d ) => d.loanTerm == 60)?.repayment ?? 0),
      })),
      amortizationScheduleData: rst5.map(rst => ({
        year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        details: rst.childRows.map(d =>({
          monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
          principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        }))
      })),
      paymentChartData: {
        amountFinanced: rst1.pricipalAmt,
        totalInterest: this.terms.totalInterest,
        emiAmt: calculator.emiAmt,
        paymentFrequency: calculator.repaymentFrequency,
        principalAmt: calculator.principalAmt,
        interestAmt: calculator.interestAmt,
        totalAmt: calculator.totalAmt,
        loanTerm: calculator.loanValue, // loanValue from calculator
        lvr: this.terms.LVR,
        rv: this.terms.RV,
        brokerageAmount: this.terms.brokerageAmount,
        docFee: this.terms.docFee,
        brokerOriginationFee: this.terms.brokerOriginationFee,
        applicationType:'Consumer'
      },
      amortizationChartData: {
        estimatedDrawdownDate: moment(calculator.dateValue),
        annualData: rst2.map(rst => ({
          year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        })),
        quarterlyData: rst3.map(rst => ({
          quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        }))
      },
      calculationLog: this.terms.calculationLog,
    };

    console.log('*************** getRepaymentEstimationData rateCard', this.rateCard);
    console.log('*************** getRepaymentEstimationData formData', formData);
    // console.log('*************** getRepaymentEstimationData vehicles', assetSelectionValue?.vehicles);
    console.log('*************** getRepaymentEstimationData DonutChartData', rst1);
    console.log('*************** getRepaymentEstimationData BarChartYearlyData', rst2);
    console.log('*************** getRepaymentEstimationData BarChartQuaterlyData', rst3);
    console.log('*************** getRepaymentEstimationData RepaymentEstimationData', rst4);
    console.log('*************** getRepaymentEstimationData AmortisationData', rst5);

    return x;
  }

  private step5OnwardsApplicantsThatAreGuarantor(): IndividualGuarantor[] {
    const applicant: TrusteeValue | MemberValue | PartnerValue | SoleTraderValue | DirectorValue = this.formControlStep4Applicants.value;
    const guarantors = applicantsThatAreGuarantor(applicant);
    return guarantors;
  }

  private step5OnwardsIndividualGuarantors(): IndividualGuarantor[] {
    const guarantors: GuarantorValue = this.formControlStep5Guarantors.value;
    return individualGarantors(guarantors);
  }

  // step6ReferenceEvent($event: ReferenceComponentEvent) {
  // }
  // step7ReferenceEvent($event: ReferenceComponentEvent) {
  // }


  step3OnWardsHasSpouse() {
    return this.formControlStep3MaritalStatus.value &&
      (this.formControlStep3MaritalStatus.value.type === 'married' ||
        this.formControlStep3MaritalStatus.value.type === 'defacto');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.indexStep;
    });
  }

  onGetStarted(consumerType: string) {
    if (consumerType === 'Consumer') {
      this.consumerType = 'Consumer'
      this.currentTemplate = this.eligibilityTemplate
      const sub = this.applicationService.getRatecardDetailsFn(172, 'Consumer').pipe(
        tap(r => {
          this.rateCard=r
        })).subscribe();
      this.subscriptions.push(sub);
    } else {
      this.consumerType = 'Commercial'
      this.currentTemplate = this.eligibilityTemplate
      const sub = this.applicationService.getRatecardDetailsFn(172, 'AssetFinance').pipe(
        tap(r => {
          this.rateCard=r
        })).subscribe();
      this.subscriptions.push(sub);
    }
  }

  onGotIt() {
    setTimeout(() => {
      this.currentTemplate = this.stepsTemplate
    });
  }
  onGoBack() {
    setTimeout(() => {
      this.currentTemplate = this.indexStep;
    });
  }

}
