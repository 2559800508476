
<div class="bsb-component">
  <mat-form-field>
    <mat-label>BSB</mat-label>
    <input type="text" [inputMask]="createBsbInputMask" placeholder="Enter BSB..."  matInput [formControl]="formControl">
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
