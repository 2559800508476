<div class="armotisation-schedule-component">
  <table mat-table
         multiTemplateDataRows
         [dataSource]="dataSource">
    <ng-cotainer matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>Year</th>
      <td mat-cell *matCellDef="let element">{{element.year}}</td>
    </ng-cotainer>
    <ng-cotainer matColumnDef="payment">
      <th mat-header-cell *matHeaderCellDef>Payment</th>
      <td mat-cell *matCellDef="let element">{{element.payment | looseCurrency}}</td>
    </ng-cotainer>
    <ng-cotainer matColumnDef="principal">
      <th mat-header-cell *matHeaderCellDef>Principal paid</th>
      <td mat-cell *matCellDef="let element">{{element.principal | looseCurrency}}</td>
    </ng-cotainer>
    <ng-cotainer matColumnDef="interest">
      <th mat-header-cell *matHeaderCellDef>Interest paid</th>
      <td mat-cell *matCellDef="let element">{{element.interest | looseCurrency}}</td>
    </ng-cotainer>
    <ng-cotainer matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Balance</th>
      <td mat-cell *matCellDef="let element">{{element.balance | looseCurrency}}</td>
    </ng-cotainer>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="5">
        <div class="expanded-details" [@detailExpand]="isRowExpanded(element) ? 'expanded' : 'collapsed'">
          <table mat-table
                 [dataSource]="createDataSource(element)">
            <ng-cotainer matColumnDef="monthYear">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">{{element.monthYear}}</td>
            </ng-cotainer>
            <ng-cotainer matColumnDef="payment2">
              <th mat-header-cell *matHeaderCellDef>Payment</th>
              <td mat-cell *matCellDef="let element">{{element.payment}}</td>
            </ng-cotainer>
            <ng-cotainer matColumnDef="principal2">
              <th mat-header-cell *matHeaderCellDef>Principal paid</th>
              <td mat-cell *matCellDef="let element">{{element.principal | looseCurrency}}</td>
            </ng-cotainer>
            <ng-cotainer matColumnDef="interest2">
              <th mat-header-cell *matHeaderCellDef>Interest paid</th>
              <td mat-cell *matCellDef="let element">{{element.interest | looseCurrency}}</td>
            </ng-cotainer>
            <ng-cotainer matColumnDef="balance2">
              <th mat-header-cell *matHeaderCellDef>Balance</th>
              <td mat-cell *matCellDef="let element">{{element.balance | looseCurrency}}</td>
            </ng-cotainer>

            <tr mat-header-row *matHeaderRowDef="['monthYear', 'payment2', 'principal2', 'interest2', 'balance2']"></tr>
            <tr mat-row *matRowDef="let element; columns: ['monthYear', 'payment2', 'principal2', 'interest2', 'balance2']"></tr>
          </table>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="['year', 'payment', 'principal', 'interest', 'balance']"></tr>
    <tr mat-row *matRowDef="let element; columns: ['year', 'payment', 'principal', 'interest', 'balance']"
        (click)="toggleExpandRow(element)"
        [ngClass]="{'expanded-row': isRowExpanded(element)}"
        class="element-row"
    ></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="expanded-row-detail"></tr>

  </table>

</div>
