<div class="ppsr-asset-supplier-search-component">
  <div class="row">
    <div class="mat-h4-custom">
      <span class="ml-3">Supplier</span>
    </div>
  </div>
  <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
    <mat-tab label="SELECT SUPPLIER">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Supplier ABN</mat-label>
            <input matInput [formControl]="formControlSearch" (keypress)="keyPressNumbers($event)"
              type="input" [matAutocomplete]="auto" [readonly]="disabled">
              <mat-autocomplete (optionSelected)="onOptionSelected($event)"
                [displayWith]="displayWithFn"
                #auto="matAutocomplete">
                @for (option of assetSuppliers; track option) {
                  <mat-option [value]="option">
                    {{option.ABN}} - {{option.SupplierName}}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mat-body width-50 mr-4" fxLayoutAlign="start center">Supplier Name: {{supplierName}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mat-body width-50 mr-4" fxLayoutAlign="start center">Supplier Address: {{supplierAddress}}</div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="PRIVATE SELLER">
        <form [formGroup]="formGroupNewSupplier">
          <div class="row">
            <div class="col-4">
              <name title="First name" [formControl]="formControlFirstName"></name>
            </div>
            <div class="col-4">
              <mat-form-field>
                <mat-label>Middle name</mat-label>
                <input matInput [formControl]="formControlMiddleName" placeholder="Enter name ...">
              </mat-form-field>
            </div>
            <div class="col-4">
              <anme title="Last name" [formControl]="formControlLastName"></anme>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <custom-address  title="Supplier address" [formControl]="formControlNewSupplierAddress"></custom-address>
            </div>
          </div>
          <div class="row">
            <div class="mat-h4-custom">
              <span class="ml-3">Bank Account Details</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label> Financial Insitituition Name</mat-label>
                <input matInput [formControl]="formControlInsitituitionName" placeholder="Enter name ...">
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label> Bank Account Name</mat-label>
                <input matInput [formControl]="formControlBankAccountName" maxlength = "100" placeholder="Enter Account name ...">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <bsb [formControl]="formControlBSB"></bsb>
            </div>
            <div class="col-6">
              <account-number [formControl]="formControlAccountNumber"></account-number>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button mat-stroked-button [disabled]="formGroupNewSupplier.invalid" (click)="submitForAccreditation()">Submit for accreditation</button>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>

