import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs';
import { PromotionResponse } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './promotion.page.html',
    styleUrls: ['./promotion.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, FlexModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class PromotionPage implements OnInit {
  showPromotion?: boolean = true;
  type: string = 'login';
  data ?: PromotionResponse

  @Output() onSaving = new EventEmitter<boolean>();
  formGroup: FormGroup<{
    message: FormControl<string | null>;
    link: FormControl<string | null>;
    showMessage: FormControl<boolean | null>;
  }>;

  formControlShowPromo: FormControl<boolean | null>
  formControlPromotionMessage: FormControl<string | null>;
  formControlPromotionLink: FormControl<string | null>;


  constructor(private formBuilder: FormBuilder,
    private adminService: AdminService,
    private toastService: PortalHotToastService) {
    this.formControlShowPromo = formBuilder.control(null);
    this.formControlPromotionMessage = formBuilder.control(null);
    this.formControlPromotionLink = formBuilder.control(null);


    this.formGroup = formBuilder.group({
      message: this.formControlPromotionMessage,
      link: this.formControlPromotionLink,
      showMessage: this.formControlShowPromo
    });
  }


  ngOnInit(): void {
    this.adminService.getPromotionByType(this.type).pipe(
             tap(r => {
              if(r.payload){
                const m = r.payload;
                m.showMessage === true ? this.formControlShowPromo.setValue(true) : this.formControlShowPromo.setValue(false);
                m.message ? this.formControlPromotionMessage.setValue(m.message) : '';
                m.link ? this.formControlPromotionLink.setValue(m.link) : '';
                this.data = r.payload
                console.log(this.data, "Data");
              }
              })
            ).subscribe();  
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('index => ', tabChangeEvent.index);
    if (tabChangeEvent.index == 0){
      this.type = 'login'
      this.adminService.getPromotionByType(this.type).pipe(
        tap(r => {
          const m = r.payload;
          m.showMessage === true ? this.formControlShowPromo.setValue(true) : this.formControlShowPromo.setValue(false);
          m.message ? this.formControlPromotionMessage.setValue(m.message) : '';
          m.link ? this.formControlPromotionLink.setValue(m.link) : '';
          this.data = r.payload
          console.log(this.data, "Data");
        })
      ).subscribe();
    }  
    else{
      this.type = 'application'
      this.adminService.getPromotionByType(this.type).pipe(
        tap(r => {
          const m = r.payload;
          m.showMessage === true ? this.formControlShowPromo.setValue(true) : this.formControlShowPromo.setValue(false);
          m.message ? this.formControlPromotionMessage.setValue(m.message) : '';
          m.link ? this.formControlPromotionLink.setValue(m.link) : '';
          this.data = r.payload
          console.log(this.data, "Data");
        })
      ).subscribe();
    }    
    this.formGroup.reset();
  }

  savePromotion() {
    const data = {
      type: this.type,
      message: this.formControlPromotionMessage.value,
      link: this.formControlPromotionLink.value,
      showMessage: this.formControlShowPromo.value
    }
    this.adminService.createPromotion(data as any).pipe(
      this.toastService.retryableMessage({
        successMessage: 'Promotion added',
        errorMessage: 'Failed to add promotion',
        retryFn: () => {
          this.savePromotion();
        },
      }),
      this.toastService.spinnerObservable(),
      tap(r => {
        // this.formGroup.reset();
      })
    ).subscribe();
  }
}
