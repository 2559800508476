import {ControlValueAccessor} from '@angular/forms';

export abstract class AbstractControlValueAccessor<V> implements ControlValueAccessor {

  onTouchFn?: ()=>void;
  onChangeFn?: (v?: V | null)=>void;
  disabled = false;

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: V | null): void {
    const o = this.doWriteValue(obj);
    this.propagateChange(o ? o : obj);
  }

  propagateChange(v?: V | null) {
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v);
  }

  abstract doWriteValue(v?: V | null): V | void;

}
