import {Component, Input} from '@angular/core';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';



@Component({
    selector: 'references-summary',
    template: `
    @if (references) {
      @for (reference of references; track reference; let index = $index) {
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">Reference {{index + 1}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Company</div>
              <div body>{{reference.company}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Phone number</div>
              <div body>{{reference.phoneNumber}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Contact person</div>
              <div body>{{reference.contactPerson}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Reference type</div>
              <div body>{{reference.referenceType.name}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
    }
    `,
    standalone: true,
    imports: [CellComponent, MatDividerModule]
})
export class ReferencesSummaryComponent {

  @Input({required: true}) references?: ReferenceValue;

}
