<form class="state-selection-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Issuing State</mat-label>
    <mat-select placeholder="Select state..." (selectionChange)="onSelectionChange($event)" [formControl]="formControl">
      @for (option of options; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
      }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</form>
