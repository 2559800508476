import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {UserSelectionComponent} from './user-selection.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {UserSameCompanySelectionComponent} from './user-same-company-selection.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        UserSelectionComponent,
        UserSameCompanySelectionComponent,
    ],
    exports: [
        UserSelectionComponent,
        UserSameCompanySelectionComponent,
    ]
})
export class UserSelectionModule {

}
