import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn, DeleteApplicationDocumentFn, DownloadAccreditationDocumentUrlFn,
  getUser, ListAccreditationDocumentFn, openWindowAndDownloadWithFilename,
  PortalHotToastService,
  setupUntilDestroy, UploadAccreditationDocumentFn,
  UpdateApplicationDocumentTagsFn, DownloadAllAccreditationDocumentUrlFn,
} from '@portal-workspace/grow-ui-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {
  GroupedDocument,
  User,
  isInternalUser,
  AzureStorageDocument,
  Accreditation,
  formGroupedDocumentData,
  UploadFilesMultiTagsDialogResult, filesToBase64Files, accreditationDocumentTags, ConfirmationDialogResult, UploadAzureFile, Metadata
} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {loadingFor} from '@ngneat/loadoff';
import { AccreditationDocumentsSubTableComponent } from './accreditation-documents-sub-table.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {CustomContentLoaderComponent} from '../custom-content-loader-component/custom-content-loader.component';

export class AccreditationDocumentsComponentInternalDataSource extends DataSource<GroupedDocument> {

  subject: Subject<GroupedDocument[]> = new BehaviorSubject<GroupedDocument[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<GroupedDocument[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(docs: GroupedDocument[]) {
    this.subject.next(docs);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'accreditation-documents',
    templateUrl: './accreditation-documents.component.html',
    styleUrls: ['./accreditation-documents.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatFormFieldModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, TagBoxComponent, MatTooltipModule, MatMenuModule, MatDividerModule, AccreditationDocumentsSubTableComponent, AsyncPipe]
})
export class AccreditationDocumentsComponent implements OnInit {
  @Input({required: true}) approveAccreditationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineAccreditationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteAccreditationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) uploadAccreditationDocumentFn!: UploadAccreditationDocumentFn;
  @Input({required: true}) listAccreditationDocumentFn!: ListAccreditationDocumentFn;
  @Input({required: true}) downloadAccreditationDocumentUrlFn!: DownloadAccreditationDocumentUrlFn;
  @Input({required: true}) updateAccreditationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) downloadAllAccreditationDocumentUrlFn!: DownloadAllAccreditationDocumentUrlFn;

  @Input({required: true}) accreditation!: Accreditation;
  subscriptions: Subscription[] = [];
  loader = loadingFor('documentDetails');
  dataSource = new AccreditationDocumentsComponentInternalDataSource();
  columnsToDisplay: string[] = ['icon', 'groupName', 'status', 'documents', 'action'];
  expandedElement!: GroupedDocument | null | undefined;
  dragElement!: GroupedDocument | null | undefined;
  isInternalUser = isInternalUser;
  formGroupedDocumentData = formGroupedDocumentData;
  Math = Math;
  docs: GroupedDocument[] = [];
  user: User | null = null;

  ngOnInit(){
    setupUntilDestroy(this);
    this.user = getUser();
    this.reload();
  }

  constructor(
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {}

  reload() {
    this.subscriptions.push(
      this.listAccreditationDocumentFn(this.accreditation.SalesforceId).pipe(
        this.loader.documentDetails.track(),
        tap((r: AzureStorageDocument[]) => {
          console.log(r);
          this.docs = this.formGroupedDocumentData(r, accreditationDocumentTags());
          if (this.expandedElement) {
            this.expandedElement = this.docs.find(obj => obj.value === (this.expandedElement as GroupedDocument).value);
          }
          this.dataSource.update(this.docs);
        })
      ).subscribe()
    )
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'groupName': return 'Document & Description';
      case 'status': return 'Status';
      case 'documents': return 'Documents';
      case 'action': return '';
      case 'icon':
        return '';
      default: return column;
    }
  }

  onDownloadAll() {
    this.subscriptions.push(
      this.downloadAllAccreditationDocumentUrlFn(
        this.accreditation.SalesforceId,
        this.accreditation.companyName,
        (this.user)?.UserId ?? 0
      ).pipe(
        this.toastService.loadingWithMessage('Downloading...'),
        tap(blob => {
          openWindowAndDownloadWithFilename(this.accreditation.companyName + '.zip', blob)
        })
      ).subscribe()
    )
  }

  async onDownloadDocument(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.downloadAccreditationDocumentUrlFn(doc.name, "").pipe(
        this.toastService.loadingWithMessage('Downloading...'),
        tap(blob => {
          openWindowAndDownloadWithFilename((doc.metadata as any)?.name ?? doc.name, blob)
        })
      ).subscribe()
    )
  }

  onUploadDocument(fileType?: string) {
    this.subscriptions.push(
      this.dialogService.openUploadFileMultiTagsDialog({
        title: 'Upload document',
        tags: fileType ? [fileType] : [],
        allTags: accreditationDocumentTags()
      }).afterClosed().pipe(
        tap(async (r: UploadFilesMultiTagsDialogResult | undefined) => {
          if(r && r.valid) {
          this.uploadAccreditationDocumentFn(
            this.accreditation.SalesforceId,
            await filesToBase64Files(r?.files ?? []), []
          ).subscribe(() => {
            this.reload();
          })
        }
      })
      
      ).subscribe()
    )
  }

  onApproveDocument(docs: AzureStorageDocument[], statusName: string) {
    const subs = docs.map(doc => this.approveAccreditationDocumentFn(doc.name, "", statusName, this.user?.UserId));
    this.subscriptions.push(
      combineLatest(subs).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Documents Approved',
          errorMessage: 'Failed to approve the documents',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onApproveDocument(docs, statusName);
          }
        }),
        tap(r => {
          this.reload();
        })
      ).subscribe()
    );
  }

  onDeclineDocument(docs: AzureStorageDocument[], statusName: string) {
    const subs = docs.map(doc => this.declineAccreditationDocumentFn(doc.name, "", statusName, this.user?.UserId));
    this.subscriptions.push(
      combineLatest(subs).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Documents Declined',
          errorMessage: 'Failed to decline the documents',
          retryFn: () => {
            console.log('**** retry ', this);
            this.onDeclineDocument(docs, statusName);
          }
        }),
        tap(r => {
          this.reload();
        })
      ).subscribe()
    );
  }

  onDeleteDocument(tagName: string, groupName: string) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to delete all documents in ' + (groupName ?? 'this tag') + '?'
      }).afterClosed().pipe(
        tap(async (r: ConfirmationDialogResult | undefined) => {
            if (r && r.readyForSubmission) {

            }
          }
        )).subscribe(() => {
        this.reload();
      })
    )
  }

  async onDrop(event: DragEvent, element: GroupedDocument) {
    event.preventDefault();
    this.dragElement = null;
    const files = event.dataTransfer?.files;
    const fileArray = [];
    if (files && files.length > 0) {
      for (const f of Array.from(files)) {
        (f as UploadAzureFile).tags = [element.value];
        (f as UploadAzureFile).metadata = this.metadata;
        fileArray.push(f);
      }
      this.subscriptions.push(
        this.uploadAccreditationDocumentFn(
          this.accreditation.SalesforceId,
          await filesToBase64Files(fileArray), []
        ).subscribe(() => {
          this.reload();
        })
      )
    }
  }

  onDragOver(event: DragEvent, element: GroupedDocument) {
    event.preventDefault();
    this.dragElement = element;
  }

  get metadata() {
    return {
      abn: this.accreditation?.abn ?? "",
      legalname: this.accreditation?.companyName ?? "",
    }
  }
}
