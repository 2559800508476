@if (showError) {
  <div
    class="error-notification2 separator-bottom"
    fxLayout="row"
    fxLayoutGap="10px"
    >
    <span fxFlex="0 0 auto" class="mdi mdi-alert-box-outline"></span>
    <div fxLayout="column">
      <span fxFlex="1 0" class="title">{{ errorTitle }}</span>
    </div>
    <div fxLayout="column">
      <span fxFlex="1 0" class="message">{{ errorMessage }}</span>
    </div>
    <div fxLayout="column">
      <span fxFlex="1 0" class="solution">{{ errorSolution }}</span>
    </div>
    <div fxLayout="column" class="refresh">
      <span
        fxFlex="1 0 auto"
        class="mdi mdi-refresh cursor-pointer"
        (click)="retryClicked()"
      ></span>
    </div>
  </div>
}
