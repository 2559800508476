import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import { RepaymentEstimationEntry } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MatTableModule } from '@angular/material/table';

export class RepaymentEstimationComponentDataSource extends DataSource<RepaymentEstimationEntry>{

  subject = new BehaviorSubject<RepaymentEstimationEntry[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<RepaymentEstimationEntry[]> {
    return this.subject;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(d: RepaymentEstimationEntry[]) {
    this.subject.next(d);
  }
}


@Component({
    selector: 'repayment-estimation',
    templateUrl: './repayment-estimation.component.html',
    styleUrls: ['./repayment-estimation.component.scss'],
    standalone: true,
    imports: [MatTableModule, LooseCurrencyPipe]
})
export class RepaymentEstimationComponent implements OnInit, OnChanges {

  @Input({required: false}) data: RepaymentEstimationEntry[] = [];

  dataSource = new RepaymentEstimationComponentDataSource();

  ngOnInit(): void {
    // this.dataSource.update([
    //   {amountFinance: '$7,000.00', month24: '$337.92', month36: '$237.31', month48: '$187.30', month60: '$157.53'},
    //   {amountFinance: '$8,000.00', month24: '$327.92', month36: '$237.31', month48: '$187.30', month60: '$157.53'},
    //   {amountFinance: '$9,000.00', month24: '$317.92', month36: '$237.31', month48: '$187.30', month60: '$157.53'},
    //   {amountFinance: '$10,000.00', month24: '$237.92', month36: '$237.31', month48: '$187.30', month60: '$157.53'},
    //   {amountFinance: '$14,000.00', month24: '$137.92', month36: '$237.31', month48: '$187.30', month60: '$157.53'},
    // ])
    this.dataSource.update(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.update(this.data);
  }

  sanitise(amount: any) {
    if (amount && String(amount).startsWith('$')) {
      return String(amount).substr(1);
    }
    return amount;
  }

}
