import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { AddCreditAlertFn, Application, ApplicationCreditFlow, ApplicationCreditFlowNodeDetail, CreditAlert, DEFAULT_LIMIT, DEFAULT_OFFSET, DuplicatedApplication, GetCreditAlertsFn, GetCreditFlowsFn, UpdateCreditAlertFn, UpdateCreditFlowFn, displayApplicationDateTime } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { TagBoxComponent } from '../message-box/tag-box.component';

@Component({
    selector: 'duplicated-applications',
    templateUrl: './duplicated-applications.component.html',
    styleUrls: ['./duplicated-applications.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      TagBoxComponent,
    ]
})
export class DuplicatedApplicationsComponent implements OnInit, OnChanges {
  @Input({required: true}) duplicatedApplications!: DuplicatedApplication[];
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;

  subscriptions: Subscription[] = [];
  moment = moment;
  displayApplicationDateTime = displayApplicationDateTime;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = 10;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: Application[] = [];
  displayedData: Application[] = [];
  columnsToDisplay = ['application', 'stage', 'status', 'description'];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = this.duplicatedApplications;
    this.initPagination();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.duplicatedApplications;
    this.initPagination();
  }

  getApplicationCreateTime(app: DuplicatedApplication) {
    return displayApplicationDateTime(moment(app.CreateTime));
  }

  initPagination() {
    this.total = this.dataSource.length;
    this.limit = 10;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedData = this.dataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  getApplicantName(app: DuplicatedApplication) {
    const entityLegalName = (app as any)['CommercialEntityLegalName'];
    if (entityLegalName) {
      return entityLegalName;
    } else if (app.ApplicationType === 'Consumer') {
      const givenName = (app as any)['IndividualGivenName'];
      const surName = (app as any)['IndividualSurName'];
      if (givenName || surName) {
        return `${givenName ?? ''} ${surName ?? ''}`.trim();
      }
    }
    return '';
  }

  onClick(app: DuplicatedApplication) {
    this.clickApplicationEvent.emit(app);
  }
}
