import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  compareMatch,
  PaymentFrequencyType,
  PaymentFrequencyValue,
  PaymentFrequencyValueOptions
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';



@UntilDestroy()
@Component({
    selector: 'payment-frequency-selection',
    templateUrl: './payment-frequency-selection.component.html',
    styleUrls: ['./payment-frequency-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PaymentFrequencySelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PaymentFrequencySelectionComponent) },
    ],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class PaymentFrequencySelectionComponent extends AbstractControlValueAccessor<PaymentFrequencyValue> implements OnInit, Mark {

  compareWithFn: (option: PaymentFrequencyValue, selected: PaymentFrequencyValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  formGroup: FormGroup<{
    selection: FormControl<PaymentFrequencyValue>
  }>;
  formControl: FormControl<PaymentFrequencyValue>;
  subscription: Subscription;
  @Input({required: false}) disabled: boolean = false;
  options = PaymentFrequencyValueOptions;
  // @Input({required: false}) isBusinessloans: boolean = false;

  @Input({required: false}) filter: PaymentFrequencyType[] = [];


  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r: PaymentFrequencyValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    // if (this.isBusinessloans) {
    //   this.options = PaymentFrequencyValueOptions.filter((a) => a.type === 'Weekly')
    // } else {
    //   this.options = PaymentFrequencyValueOptions
    // }
    this.options = PaymentFrequencyValueOptions.filter(op => !this.filter.includes(op.type));
  }

  doWriteValue(v: PaymentFrequencyValue): PaymentFrequencyValue | undefined {
    if (v) {
      const t = PaymentFrequencyValueOptions.find(opt => opt.type === v.type);
      if (t) {
        this.formControl.setValue(t);
        return t;
      }
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
