import {validApplicationTypeGuard} from './abstract-application.guard';

export const validCommercialFinanceGuard = () => {
  return validApplicationTypeGuard('Commercial', 'e-not-commercial');
}



// NOTE: Deprecated since Angular 16, replaced by validApplicationTypeGuard() instead
// import {Injectable} from '@angular/core';
// import {AbstractApplicationGuard} from './abstract-application.guard';
// import {ApplicationTypes} from '@portal-workspace/grow-shared-library';
//
//
// @Injectable()
// export class ValidCommercialFinanceGuard extends AbstractApplicationGuard {
//
//   getApplicationType(): ApplicationTypes {
//     return 'Commercial';
//   }
//
//   getApplicationTypeErrorCode(): string {
//     return 'e-not-commercial';
//   }
//
// }
