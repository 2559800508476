import { Component, forwardRef, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { Subscription } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, MaritialStatusSelectionValue, MaritialStatusSelectionValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'maritial-status-selection',
    templateUrl: './maritial-status-selection.component.html',
    styleUrls: ['./maritial-status-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MaritialStatusSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => MaritialStatusSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class MaritialStatusSelectionComponent extends AbstractControlValueAccessor<MaritialStatusSelectionValue> implements OnInit, Mark {

  options = MaritialStatusSelectionValueOptions;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    selection: FormControl<MaritialStatusSelectionValue>
  }>;
  formControl: FormControl<MaritialStatusSelectionValue>;

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required])
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    const subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((v: MaritialStatusSelectionValue) => {
        if (this.formControl.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }


  doWriteValue(v: MaritialStatusSelectionValue): void | MaritialStatusSelectionValue {
    if (v) {
      const index = this.options.findIndex(r => r && r.type === v.type);
      if (index !== -1) {
        this.formControl.setValue(this.options[index]);
      }
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
