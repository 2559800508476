import {Component, Input} from '@angular/core';
import {AssetFinanceApplication} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import { PaymentChartComponent } from '../../chart-component/payment-chart.component';


@Component({
    selector: 'asset-quote',
    templateUrl: './asset-quote.component.html',
    styleUrls: ['./asset-quote.component.scss'],
    standalone: true,
    imports: [PaymentChartComponent]
})
export class AssetQuoteComponent {

  @Input() application!: AssetFinanceApplication;
  @Input() amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input() amortizationChartData!: AmortizationChartData;
  @Input() paymentChartData!: PaymentChartData;
  @Input() repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input() calculationLog?: {log: string, rate?: number}[];
}
