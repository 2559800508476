<div class="credit-report-component">
  <div class="row">
    <div class="col-12 btn-space header"
      fxLayoutGap="10px"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start"
      >
        <button mat-flat-button class="w-lt-md-100" (click)="onLeaveReport()">
          <span class="label"> Back </span>
        </button>
        <button mat-flat-button class="w-lt-md-100" (click)="onDownloadReport()">
          <span class="label"> Download </span>
        </button>
      </div>
  </div>

  @if (webService && webService.type !== 'EquifaxFraudCheck') {
    <div class="row">
      <div class="col-12">
        <pdf-viewer [src]="pdf"
          [original-size]="false"
          [zoom]="0.9"
          style="width: 1080px; height: 880px; display: block;"
        ></pdf-viewer>
      </div>
    </div>
  }
  @if (webService && reportHtml && webService.type === 'EquifaxFraudCheck') {
    <div class="row">
      <div class="col-12">
        <div [innerHtml]="reportHtml"></div>
      </div>
    </div>
  }


</div>
