import {Component, forwardRef, inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {
  createNoDecimalInputMask,
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from '../component-utils';
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {AssetTier4Value, compareMatch} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import _ from 'lodash';
import {FlexModule} from '@angular/flex-layout';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import {InputMaskModule} from '@ngneat/input-mask';
import numeral from 'numeral';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'asset-tier4',
  templateUrl: './asset-tier4.component.html',
  styleUrls: ['./asset-tier4.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetTier4Component), multi: true},
    {provide: MARK, useExisting: forwardRef(() => AssetTier4Component), multi: false},
  ],
  standalone: true,
  imports: [
    InputMaskModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule
]
})
export class AssetTier4Component extends AbstractControlValueAccessor<AssetTier4Value> implements  OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  createNoDecimalInputMask = createNoDecimalInputMask();
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  @Input({required: false}) title: string = '';

  subscriptions: Subscription[] = [];

  private formBuilder: FormBuilder;

  formGroup: FormGroup<{
    newAssetBacked: FormControl<number | null>,
    usedAssetBacked: FormControl<number | null>,
  }>;
  formControlNewAssetBacked: FormControl<number | null>;
  formControlUsedAssetBacked: FormControl<number | null>;

  constructor() {
    super();
    this.formBuilder = inject(FormBuilder);
    this.formControlNewAssetBacked = this.formBuilder.control(0, [Validators.required]);
    this.formControlUsedAssetBacked = this.formBuilder.control(0, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      newAssetBacked: this.formControlNewAssetBacked,
      usedAssetBacked: this.formControlUsedAssetBacked,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r  => {
        if (this.formGroup.valid) {
          // NOTE: when formGroup is valid, Rate should not be null due to validators configured
          const v: Exclude<AssetTier4Value, null> = {
            AssetBacked: [{
              Type: 'New',
              Rate: String(r.newAssetBacked ?? 0),
            }, {
              Type: 'Used',
              Rate: String(r.usedAssetBacked ?? 0),
            }],
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();

  }

  doWriteValue(v: AssetTier4Value | null | undefined): void | AssetTier4Value {
    if (v) {
      for (const asset of v.AssetBacked) {
        if (asset.Type === 'New') {
          const rate = numeral(asset.Rate).value();
          if (rate) {
            this.formControlNewAssetBacked.setValue(rate);
          }
        } else if (asset.Type === 'Used') {
          const rate = numeral(asset.Rate).value();
          if (rate) {
            this.formControlUsedAssetBacked.setValue(rate);
          }
        }
      }
    }
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
