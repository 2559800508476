import {inject, Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {httpOptions} from "@portal-workspace/grow-ui-library";
import {InstitutionStatus, PayloadApiResponse} from "@portal-workspace/grow-shared-library";
import {Observable} from "rxjs";


const URL_QUERY_BANKFEEDS_INSTITUTIONS =  () => `${environment.api2Host}/api2/bankfeeds/institutions-status`; // GET


@Injectable()
export class BankfeedsService {

  httpClient = inject(HttpClient);

  queryBankfeedsInstitutions(): Observable<PayloadApiResponse<InstitutionStatus[]>>{
    return this.httpClient.get<PayloadApiResponse<InstitutionStatus[]>>(URL_QUERY_BANKFEEDS_INSTITUTIONS(), httpOptions());
  }

}
