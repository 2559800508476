import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, Validators, FormArray, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AssetCategory, compareMatch, getAssetCategories} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  Mark
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import numeral from 'numeral';
import {AssetCategoryRateValue} from '@portal-workspace/grow-shared-library';

import {MARK} from '@portal-workspace/grow-ui-library/mark';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-category-rate',
    templateUrl: './asset-category-rate.component.html',
    styleUrls: ['./asset-category-rate.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetCategoryRateComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetCategoryRateComponent), multi: false },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatInputModule, InputMaskModule, FlexModule, MatButtonModule]
})
export class AssetCategoryRateComponent extends AbstractControlValueAccessor<AssetCategoryRateValue> implements OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: true}) title?: string;

  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  categories: AssetCategory[] = getAssetCategories();

  formArray: FormArray<FormGroup<{
    rate: FormControl<number | null>,
    category: FormControl<string | null>,
  }>>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<FormGroup<{
      rate: FormControl<number | null>,
      category: FormControl<string | null>,
    }>>([]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r  => {
        if (this.formArray.valid) {
          const v = r.map((_v: any) => {
            return {
              Cat: _v.category ?? '',
              Rate: numeral(_v.rate).value() ?? 0,
            }
          });
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })).subscribe();
    this.subscriptions.push(sub);
  }

  add(v?: Exclude<AssetCategoryRateValue, null>[number]) {
    const formControlRate: FormControl<number | null> = this.formBuilder.control(v && v.Rate ?  v.Rate : 0, [Validators.required]);
    const formControlCategory: FormControl<string | null> = this.formBuilder.control(v?.Cat ?? null, [Validators.required]);
    const formGroup = this.formBuilder.group({
      rate: formControlRate,
      category: formControlCategory,
    });
    this.formArray.push(formGroup);
  }

  remove() {
    if (this.formArray.length > 0) {
      this.formArray.removeAt(this.formArray.length -1);
    }
  }

  doWriteValue(v: AssetCategoryRateValue | null | undefined): void | AssetCategoryRateValue {
    if (v) {
      for (const _v of v) {
        this.add(_v);
      }
    }
    return undefined;
  }


  formGroupControl(formGroup: AbstractControl, name: 'category' | 'rate'): FormControl<string | null> | FormControl<number | null> {
    return (formGroup as FormGroup<{
      rate: FormControl<number | null>,
      category: FormControl<string | null>,
    }>).controls[name] as FormControl<string | null> | FormControl<number | null>;
  }

  onAdd($event: MouseEvent) {
    this.add();
  }

  onRemove($event: MouseEvent) {
    this.remove();
  }

  mark() {
    this.formArray.markAllAsTouched();
  }
}
