<div class="user-page container-fluid" fxlayoutAlign="start center">
  <button class="arrow-left mb-2" (click)="onClickBack()">
    <span class="mdi mdi-arrow-left"></span>
  </button>
  @if (doneInit && (!user)) {
    <div class="row separator">
      <div class="col-lg-6 col-md-12">
        <message-box title="Not found" type="warn">
          <span>User not found</span>
        </message-box>
      </div>
    </div>
  }

  @if (user) {
    @if (userType === 'customer-user') {
      <customer-user-details
        [user]="user"
        [customerListValueGetFn]="customerListValueGetFn"
        [getUserFn]="getUserFn"
        [approveOrRejectFn]="approveOrRejectFn"
        [updateUserFn]="updateUserFn"
        [updatePismoCustomerFn]="updatePismoCustomerFn"
        [add2faSecretKeyFn]="add2faSecretKeyFn"
        [enableDisable2faFn]="enableDisable2faFn"
        [updateUserPasswordFn]="updateUserPasswordFn"
        [verify2faTokenFn]="verify2faTokenFn"
        [get2faTotpVerificationCodeUriFn]="get2faTotpVerificationCodeUriFn"
        [disableUserFn]="disableUserFn"
        [enableUserFn]="enableUserFn"
        [pismoUpdateUserAccessFn]="pismoUpdateUserAccessFn"
        [disableOverdraftUserFn]="disableOverdraftUserFn"
        [overdraftAccountAvailableForUserFn]="overdraftAccountAvailableForUserFn"
        [allocateUserToPismoAccountFn]="allocateUserToPismoAccountFn">
      </customer-user-details>
    } @else {
      @if (user) {
        <broker-user-details
          [user]="user"
          [aggregatorAndOriginatorListValueGetFn]="aggregatorAndOriginatorListValueGetFn"
          [getUserFn]="getUserFn"
          [approveOrRejectFn]="approveOrRejectFn"
          [updateUserFn]="updateUserFn"
          [add2faSecretKeyFn]="add2faSecretKeyFn"
          [enableDisable2faFn]="enableDisable2faFn"
          [updateUserPasswordFn]="updateUserPasswordFn"
          [verify2faTokenFn]="verify2faTokenFn"
          [get2faTotpVerificationCodeUriFn]="get2faTotpVerificationCodeUriFn"
          [disableUserFn]="disableUserFn"
          [enableUserFn]="enableUserFn">
        </broker-user-details>
      }
    }
  }
</div>

