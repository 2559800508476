<div class="credit-flows">

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search credit alert..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="end start"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button class="inactive w-lt-md-100" (click)="addCreditAlert()"><span class="mdi mdi-plus" matPrefix></span>Add Credit Alert</button>
      </div>
    </div>

    <div class="row">
        <div class="col-12">
            @if (loader.tableLoading.inProgress$ | async) {
                <custom-content-loader type="list"></custom-content-loader>
              }
              <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                <table mat-table [dataSource]="displayedData" class="width-100 mb-2">
                  @for (column of columnsToDisplay; track column) {
                    <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'column-width-1': column !== 'name',
                    'column-width-2': column === 'name',
                  }"> 
                    <div class="cell">{{getColumnTitles(column)}}</div> </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="cell">
                            @if (column == 'actions') {
                              <div fxLayout="row wrap"
                                fxLayoutAlign="start center"
                                fxLayout.lt-md="column"
                                fxLayoutAlign.lt-md="center center"
                                >
                                <span class="mdi mdi-pencil-outline cursor-pointer icon-margin date-text" matTooltip="Edit" (click)="editCreditAlert(element)"></span>
                                <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin date-text" matTooltip="Delete" (click)="deleteCreditAlert(element)"></span>
                              </div>
                            }
                            @else if (column == 'lastUpdatedBy') {
                                {{element?.GivenName ?? ''}} {{element?.FamilyName ?? ''}}
                              }
                            @else if (column == 'lastUpdatedTime') {
                              {{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}
                            }
                            @else if (column == 'status') {
                              <tag-box [state]="element.status"></tag-box>
                            }
                            @else {
                              {{element[column]}}
                            }
                          </div>
                        </td>
                      </ng-container>
                    }
            
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                    </tr>
                </table>
                @if (total) {
                  <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
                  [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
                }
              </div>
        </div>
    </div>
</div>
