export type LoanPurposeType = 'asset-purchase' | 'business-acquisition' | 'cash-flow' | 'growth-capital' | 'management-buy-out-buy-in' |
  'pay-tax' | 'property-development' | 'property-purchase' | 'purchase-of-shares' | 'refinance' | 'repay-directors-loan' | 'start-up-funding' |
  'vehicle-leasing' | 'working-capital';

export type LoanPurposeValue = {
  type: LoanPurposeType,
  name: string,
} | null;

export const LoanPurposeValueOptions: Exclude<LoanPurposeValue, null>[] = [
  {type: 'asset-purchase', name: 'Asset Purchase'},
  {type: 'business-acquisition', name: 'Business Acquisition'},
  {type: 'cash-flow', name: 'Cash Flow'},
  {type: 'growth-capital', name: 'Growth Capital'},
  {type: 'management-buy-out-buy-in', name: 'Management Buy Out, Buy In'},
  {type: 'pay-tax', name: 'Pay Tax / Crown Debts'},
  {type: 'property-development', name: 'Property Development'},
  {type: 'property-purchase', name: 'Property Purchase'},
  {type: 'purchase-of-shares', name: 'Purchase of Shares'},
  {type: 'refinance', name: 'Re-finance existing borrowing'},
  {type: 'repay-directors-loan', name: 'Repay Directors Loan'},
  {type: 'start-up-funding', name: 'Start-up Funding'},
  {type: 'vehicle-leasing', name: 'Vehicle Leasing'},
  {type: 'working-capital', name: 'Working Capital'},
]

