<div class="commission-chart-component">
  <!-- <div gdArea="chart" class="chart-wrapper">
    <div class="canvas-wrapper">
      <canvas #canvasElement id="{{chartId}}"></canvas>
      <div class="chart-text">
        <div class="mat-h3">{{repayment | currency}}</div>
        <div class="mat-body">{{paymentFrequency}} Payment</div>
      </div>
    </div>
  </div> -->
  <div gdArea="data" class="data">
    <div fxLayout="row" class="separator">
      <div class="mat-body width-66">
        <div class="amount-financed-logo"></div>
        <div class="amount-financed-logo-text">{{paymentFrequency}} Payment</div>
      </div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{repaymentPlusMonthlyAccountKeepingFee | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">{{paymentFrequency}} Installment</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{repayment | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Monthly Account Keeping Fee</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{monthlyAccountKeepingFee | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Interest Rate</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{interestRate | percentage : '1.2-2'}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Comparison Rate</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{comparisonRate | percentage : '1.2-2'}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>





    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Amount Financed</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{amountFinanced | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Total Interest</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{interestAmt | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Total Payment</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{totalPayment | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Loan Terms</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{loanTerms}} Months</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Invoice Amount</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{invoiceAmt | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Deposit (incl GST)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{deposit | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Balloon Payment (Residual)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{residual | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Doc Fee (incl PPSR Fee)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{docFee | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>



    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Commission (ex GST)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{commission | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Credit Assistance Fee (ex GST)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{creditAssitanceFee | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-66">Total Earnings (ex GST)</div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{totalEarned | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div fxLayout="row" class="separator">
      <div class="mat-body width-66">
        <div class="total-interest-logo"></div>
        <div class="total-interest-logo-text">Total Earnings (incl GST)</div>
      </div>
      <div class="mat-body width-33 mr-4" fxLayoutAlign="end center">{{totalEarnedInclGst | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>
</div>
