import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DirectorComponent} from './director.component';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {AddressModule} from '../address-component/address.module';
import {YesNoModule} from '../yes-no-component/yes-no.module';
import {GenderModule} from '../gender-component/gender.module';
import {PersonalLiabilitiesComponent} from './personal-liabilities.component';
import {PersonalAssetsComponent} from './personal-assets.component';
import {GuarantorComponent} from './guarantor.component';
import {BusinessSearchModule} from '../business-search-component/business-search.module';
import {EntityTypeModule} from '../entity-type-component/entity-type.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {TrusteeComponent} from './trustee.component';
import {MemberComponent} from './member.component';
import {PartnerComponent} from './partner.component';
import {SoleTraderComponent} from './sole-trader.component';
import {PropertyOwnerWithAddressModule} from '../property-owner-with-address-component/property-owner-with-address.module';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {DatepickerModule} from '../datepicker-component/datepicker.module';
import {MessageBoxModule} from '../message-box/message-box.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {EmailModule} from '../common fields/email.module'
import {AcnModule} from '../acn-component/acn.module';
import {MobileModule} from '../mobile-component/mobile.module';
import {NameModule} from '../name-component/name.module';
import {AbnModule} from '../abn-component/abn.module';
import {BusinessNumberSearchModule} from '../business-number-search-component/business-number-search.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        TitleSelectionModule,
        AddressModule,
        YesNoModule,
        GenderModule,
        BusinessSearchModule,
        EntityTypeModule,
        PropertyOwnerWithAddressModule,
        CurrencySelectionModule,
        DatepickerModule,
        MessageBoxModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        EmailModule,
        AbnModule,
        AcnModule,
        MobileModule,
        NameModule,
        BusinessNumberSearchModule,
        DirectorComponent,
        PersonalLiabilitiesComponent,
        PersonalAssetsComponent,
        GuarantorComponent,
        TrusteeComponent,
        MemberComponent,
        PartnerComponent,
        SoleTraderComponent
    ],
    exports: [
        DirectorComponent,
        PersonalLiabilitiesComponent,
        PersonalAssetsComponent,
        GuarantorComponent,
        TrusteeComponent,
        MemberComponent,
        PartnerComponent,
        SoleTraderComponent,
    ]
})
export class IndividualAndEntityInfoModule {

}
