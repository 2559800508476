import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  navigationUrlForLogin,
  navigationUrlForReset2FA,
  navigationUrlForAccreditation, navigationUrlForApplications
} from '../../service/navigation-urls';
import { Router, RouterLink } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {
  ApplicationDialogService,
  getUser,
  setupUntilDestroy,
  store2faEnteredWhenLogin,
  ToolbarComponentEvent
} from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { MinimalLayoutService } from '../../layout/minimal-layout/minimal-layout.service';
import { loadingFor } from '@ngneat/loadoff';
import { environment } from '../../../environments/environment';
import { PromotionResponse, User } from '@portal-workspace/grow-shared-library';
import { AdminService } from '../../service/admin.service';
import { TwoFactorAuthService } from '../../service/2fa.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy({ arrayName: 'subscriptions', checkProperties: true })
@Component({
    templateUrl: './2fa.page.html',
    styleUrls: ['./2fa.page.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatTooltipModule, FlexModule, RouterLink, MatButtonModule, AsyncPipe]
})
export class TwoFAPage implements OnInit {

  environment = environment;

  subscriptions: Subscription[] = [];

  loader = loadingFor('logon');
  navigationUrlForReset2FA = navigationUrlForReset2FA

  // flag2fa: boolean = false;
  // navigationForLoginPage = navigationUrlForLogin;

  formGroup!: FormGroup<{
    flag: FormControl<boolean | null>,
    digit: FormControl;
  }>;
  formControl2faFlag!: FormControl<boolean | null>;
  formControlDigit!: FormControl;
  // loginSubscription?: Subscription;

  // loginError: string | null = null;
  data?: PromotionResponse
  user: User | null =null;



  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private TwoFA: TwoFactorAuthService,
    private applicationDialogService: ApplicationDialogService,
    private minimalLayoutService: MinimalLayoutService,
    private toastService: PortalHotToastService) {
    this.formControl2faFlag = formBuilder.control(false);
    this.formControlDigit = formBuilder.control(null, Validators.required);
    this.formGroup = formBuilder.group({
      flag: this.formControl2faFlag,
      digit: this.formControlDigit,
    });
  }

  hide = true;

  ngOnInit() {
    setupUntilDestroy(this);
    this.user = getUser();
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false,
    });
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch (r.type) {
            case 'accreditation-clicked': {
              await this.router.navigate(navigationUrlForAccreditation());
            }
          }
        }
      })
    ).subscribe());
    // this.subscriptions.push(this.formControl2faFlag.valueChanges.pipe(
    //   tap(r => {
    //     // const checked = r ?? false;
    //     // this.onChange({checked})
    //   })
    // ).subscribe());
  }

  onTopMenuEvent($event: ToolbarComponentEvent['type']) {
    switch ($event) {
      case 'logout':
        this.authService.logout().pipe(
          this.toastService.spinnerObservable(),
          tap(async r => {
            if (r.status) {
              await this.router.navigate(navigationUrlForLogin());
            }
          })
        ).subscribe();
        break;
    }
  }

  async onLogin($event: MouseEvent) {
    let digits = this.formControlDigit.value
    const remember = this.formControl2faFlag.value ?? false;
    this.TwoFA.verify2faToken(digits, remember).pipe(
      this.toastService.spinnerObservable(),
      tap(async r => {
        if (r.payload?.status) {
          store2faEnteredWhenLogin(true);
          await this.router.navigate(navigationUrlForApplications());
        } else {
          store2faEnteredWhenLogin(false);
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: `Please check your 6 digit code.`
          });
        }
      })
    ).subscribe();
  }

  /* navigationUrlForReset2FA () {
    return navigationUrlForReset2FA(this.router).toString()
  }  */
  // onChange(e: {checked: boolean}) { // here e is a boolean, true if checked, otherwise false
  //   if(e.checked === true){
  //   this.TwoFA.register2faToken(1,this.user!.UserId).pipe(
  //     tap(async r => {
  //     })
  //   ).subscribe();
  //   }else{
  //     this.TwoFA.register2faToken(0,this.user!.UserId).pipe(
  //       tap(async r => {
  //       })
  //     ).subscribe();
  //   }
  // }
}
