<div class="CES-NPS-modal-component" gdRows="auto auto" gdColumns="auto auto" fxLayoutAlign="center center">
  <div>
    <div class="submit-div">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="mdi mdi-checkbox-marked-circle light icon"></span>
      </div>
      <br>
      <div class="mat-h3" style="text-align: center;">
        Your application has been submitted <br>successfully
      </div>
    </div>
    <div class="experience-div-main" >
      <div fxLayout="row" fxLayoutAlign="center center" class="mat-h4-custom">
        How was your experience?
      </div>
      <br>
      <div class="rating-text">
        <mat-label>How satisfied are you with the process of the application?</mat-label>
        <div class="align-right">
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
        </div>
      </div>
      <div class="rating-text">
        <mat-label> How easy it was for you to create this application?</mat-label>
        <div class="align-right">
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
        </div>
      </div>
      <div class="rating-text">
        <mat-label> How would you rate the support? </mat-label>
        <div class="align-right">
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
          <span class="mdi mdi-star"></span>
        </div>
      </div>
      <div class="recommend-div-main">
        <mat-label class="recommend-div"> How likely is it that
          you would recommend Dynamoney to a friend or colleague? </mat-label>
        <div style="width: 100%;">
          <h6 class="align-left">Not at all likely </h6>
          <h6 class="align-right">Extremely likely</h6>
        </div>
        <div style="display: flex; width: 100%;">
          <span mat-stroked-button color="primary" class="custom-rating">0</span>
          <span mat-stroked-button color="primary" class="custom-rating">1</span>
          <span mat-stroked-button color="primary" class="custom-rating">2</span>
          <span mat-stroked-button color="primary" class="custom-rating">3</span>
          <span mat-stroked-button color="primary" class="custom-rating">4</span>
          <span mat-stroked-button color="primary" class="custom-rating">5</span>
          <span mat-stroked-button color="primary" class="custom-rating">6</span>
          <span mat-stroked-button color="primary" class="custom-rating">7</span>
          <span mat-stroked-button color="primary" class="custom-rating">8</span>
          <span mat-stroked-button color="primary" class="custom-rating">9</span>
          <span mat-stroked-button color="primary" class="custom-rating">10</span>
        </div>
      </div>
      <br>
      <p>
        <mat-form-field appearance="outline" style="min-width: 585px; max-width: 585px;">
          <input matInput placeholder="General comment (optional)">
          <textarea matInput style="max-height: 100px;min-height: 100px;"></textarea>
        </mat-form-field>
      </p>
    </div>
    <button mat-stroked-button color="primary" class="align-right"
      style="margin-top: 10px; padding: 8px 20px ;">Done</button>
  </div>
</div>