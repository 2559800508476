import {Injectable} from '@angular/core';
import { AssetSelectionComponentSearchFn, } from '@portal-workspace/grow-ui-library';
import {
  RedbookSearch, RedbookSearchByVehicles,
  RedbookSearchByVehiclesResponse,
  RedbookSearchResponse
} from '@portal-workspace/grow-shared-library';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import {PayloadApiResponse} from '@portal-workspace/grow-shared-library';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const URL_REDBOOK = `${environment.api2Host}/api2/redbook`;

@Injectable()
export class RedbookService {

  constructor(private httpClient: HttpClient) {
  }

  assetSearchFn: AssetSelectionComponentSearchFn = <T extends RedbookSearch>(s: T) => {
    return this.search(s).pipe(
      // this.toastService.toastObserver(),
      map( r => {
        return r.payload;
      })
    );
  }

  search<T extends RedbookSearch>(p: T):Observable<PayloadApiResponse<RedbookSearchResponse<T>>> {
    return this.httpClient.post<{status:boolean,message:string,payload:RedbookSearchResponse<T>}>(URL_REDBOOK, p, httpOptions()).pipe(
      map(r => {
        const apiResponse: PayloadApiResponse<RedbookSearchResponse<T>> = {
          status: true,
          message: 'Success',
          payload: r.payload,
        };
        return apiResponse;
      }),
    );
  }

  // NOTE: not used
  // getVehicleList(makecode: string, familycode: number, year: number): Observable<PayloadApiResponse<RedbookSearchByVehiclesResponse>> {
  //   return this.search<RedbookSearchByVehicles>({action: 'vehicles', makecode, familycode, year, type: 'car'});
  // }

}
