import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {
  ApiResponse,
  BatchPayment, BPayPayment, DirectPayment,
  GetBankingTransaction,
  NewBankingTransactionBody,
  NewBatchPaymentBody,
  NewBPayPaymentBody,
  NewDirectPaymentBody,
  NewTransactionResponse,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PayloadApiResponse,
  PaymentApiResponse,
  PendingApprovalTransaction,
  PismoOnetimeRepaymentBody,
  UpdateAllPendingTransactionsBody,
  UpdatePendingTransactionsBody
} from '@portal-workspace/grow-shared-library';
import {httpOptions, paginationUrl, toPaginationInfo} from '@portal-workspace/grow-ui-library';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

const URL_ADD_NEW_BANKING_TRANSACTION = () =>`${environment.api2Host}/api2/add-new-banking-transaction`; // post
const URL_ADD_NEW_BATCH_PAYMENT = () =>`${environment.api2Host}/api2/add-new-batch-payment`; // post
const URL_EDIT_BANKING_TRANSACTION = () =>`${environment.api2Host}/api2/edit-banking-transaction`; // post
const URL_EDIT_BATCH_PAYMENT = () =>`${environment.api2Host}/api2/edit-batch-payment`; // post
const URL_GET_BATCH_PAYMENT_BY_USER = (userId: number) =>`${environment.api2Host}/api2/get-batch-payment-by-user/${userId}`; // get
const URL_GET_TRANSACTIONS_BY_BATCH = (batchId: number) =>`${environment.api2Host}/api2/get-transactions-by-batch/${batchId}`; // get
const URL_GENERATE_PAYMENT_FILE = () =>`${environment.api2Host}/api2/generate-payment-file`; // post
const URL_GET_BANKING_TRANSACTIONS = (paginationInfo : PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/banking-transactions`, paginationInfo); // get
const URL_PISMO_PAYMENT_BANK_TRANSFER = (accountId: number) => `${environment.api2Host}/api2/pismo/payment/direct-payment/${accountId}` // post
const URL_PISMO_PAYMENT_BPAY = (accountId: number) => `${environment.api2Host}/api2/pismo/payment/bpay/${accountId}` //post
const URL_PAY_OVERDRAFT_ACCOUNT = (accountId: number) => `${environment.api2Host}/api2/pismo/pay-overdraft-account/${accountId}` //post
const URL_GET_PENDING_APPROVAL_TRANSACTIONS = (paginationInfo : PaginationInfo) =>  paginationUrl(`${environment.api2Host}/api2/pending-approval-transactions`, paginationInfo); // get
const URL_GET_PROCESSED_PENDING_TRANSACTIONS = (paginationInfo : PaginationInfo) =>  paginationUrl(`${environment.api2Host}/api2/processed-pending-transactions`, paginationInfo); // get
const URL_UPDATE_PENDING_TRANSACTIONS = () => `${environment.api2Host}/api2/update-pending-transactions`; // post
const URL_UPDATE_ALL_PENDING_TRANSACTIONS = () => `${environment.api2Host}/api2/update-all-pending-transactions`; // post

@Injectable()
export class BankingService {

  constructor(private httpClient: HttpClient) {
  }

  addNewBatchPayment(body: NewBatchPaymentBody): Observable<number> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_ADD_NEW_BATCH_PAYMENT(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<number>) => r.payload))
  }

  addNewTransaction(body: NewBankingTransactionBody): Observable<number> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_ADD_NEW_BANKING_TRANSACTION(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<number>) => r.payload))
  }

  addNewTransactionFn = (body: NewBankingTransactionBody): Observable<number> => {
    return this.addNewTransaction(body);
  }

  editBatchPayment(body: Partial<BatchPayment>) {
    return this.httpClient.post<ApiResponse>(URL_EDIT_BATCH_PAYMENT(), body, httpOptions());
  }

  editBatchPaymentFn = (body: Partial<BatchPayment>): Observable<ApiResponse> => {
    return this.editBatchPayment(body);
  }

  editTransaction(body: Partial<DirectPayment | BPayPayment>) {
    return this.httpClient.post<ApiResponse>(URL_EDIT_BANKING_TRANSACTION(), body, httpOptions());
  }

  editTransactionFn = (body: Partial<DirectPayment | BPayPayment>): Observable<ApiResponse> => {
    return this.editTransaction(body);
  }

  deleteTransactionFn = (body: DirectPayment | BPayPayment): Observable<ApiResponse> => {
    return this.editTransactionFn({
      ...body,
      isDeleted: true
    })
  }

  deleteBatchPaymentFn = (body: BatchPayment): Observable<ApiResponse> => {
    return this.editBatchPaymentFn({
      ...body,
      isDeleted: true
    })
  }

  getBatchPaymentsFn = (userId: number): Observable<BatchPayment[]> => {
    return this.httpClient.get<PayloadApiResponse<BatchPayment[]>>(URL_GET_BATCH_PAYMENT_BY_USER(userId), httpOptions())
      .pipe(map((r: PayloadApiResponse<BatchPayment[]>) => r.payload))
  }

  getTransactionByBatchFn = (batchId: number): Observable<DirectPayment[] | BPayPayment[]> => {
    return this.httpClient.get<PayloadApiResponse<DirectPayment[] | BPayPayment[]>>(URL_GET_TRANSACTIONS_BY_BATCH(batchId), httpOptions())
      .pipe(map((r: PayloadApiResponse<DirectPayment[] | BPayPayment[]>) => r.payload))
  }

  generatePaymentFileFn = (batchId: number): Observable<string> => {
    return this.httpClient.post<PayloadApiResponse<string>>(URL_GENERATE_PAYMENT_FILE(), { batchId }, httpOptions())
      .pipe(map((r: PayloadApiResponse<string>) => r.payload))
  }

  getBankingTransactions(opt: {
    page: {
      limit: number,
      offset: number,
    },
    filter: string,
    sorts: any,
  }): Observable<PaginablePayloadApiResponse<GetBankingTransaction>> {
    return this.httpClient.get<PaginablePayloadApiResponse<GetBankingTransaction>>(URL_GET_BANKING_TRANSACTIONS(toPaginationInfo(opt)), httpOptions())
  }

  pismoPaymentBankTransferFn = (data: NewDirectPaymentBody, pismoAccountId: number): Observable<PaymentApiResponse> => {
    return this.httpClient.post<PayloadApiResponse<PaymentApiResponse>>(URL_PISMO_PAYMENT_BANK_TRANSFER(pismoAccountId), data, httpOptions())
      .pipe(map((r: PayloadApiResponse<PaymentApiResponse>) => r.payload));
  }

  pismoPaymentBpayFn = (data: NewBPayPaymentBody, pismoAccountId: number): Observable<PaymentApiResponse> => {
    return this.httpClient.post<PayloadApiResponse<PaymentApiResponse>>(URL_PISMO_PAYMENT_BPAY(pismoAccountId), data, httpOptions())
      .pipe(map((r: PayloadApiResponse<PaymentApiResponse>) => r.payload));
  }

  pismoPayOverdraftAccountFn = (data: PismoOnetimeRepaymentBody, pismoAccountId: number): Observable<NewTransactionResponse> => {
    return this.httpClient.post<PayloadApiResponse<NewTransactionResponse>>(URL_PAY_OVERDRAFT_ACCOUNT(pismoAccountId), data, httpOptions())
      .pipe(map((r: PayloadApiResponse<NewTransactionResponse>) => r.payload));
  }

  getPendingApprovalTransactionsFn = (opt: {
    page: {
      limit: number,
      offset: number,
    },
    filter: string,
    sorts: any,
  }): Observable<PaginablePayloadApiResponse<PendingApprovalTransaction>> => {
    return this.httpClient.get<PaginablePayloadApiResponse<PendingApprovalTransaction>>(URL_GET_PENDING_APPROVAL_TRANSACTIONS(toPaginationInfo(opt)), httpOptions())
  }

  getProcessedPendingTransactionsFn = (opt: {
    page: {
      limit: number,
      offset: number,
    },
    filter: string,
    sorts: any,
  }): Observable<PaginablePayloadApiResponse<PendingApprovalTransaction>> => {
    return this.httpClient.get<PaginablePayloadApiResponse<PendingApprovalTransaction>>(URL_GET_PROCESSED_PENDING_TRANSACTIONS(toPaginationInfo(opt)), httpOptions())
  }

  updatePendingTransactionsFn = (data: UpdatePendingTransactionsBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_PENDING_TRANSACTIONS(), data, httpOptions());
  }

  updateAllPendingTransactionsFn = (data: UpdateAllPendingTransactionsBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_ALL_PENDING_TRANSACTIONS(), data, httpOptions());
  }
}
