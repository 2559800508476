import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { TagBoxComponent } from '../message-box/tag-box.component';

@Component({
  templateUrl: './unsaved-changes.dialog.html',
  styleUrls: ['./unsaved-changes.dialog.scss'],
  standalone: true,
  imports: [FlexModule, MatButtonModule, MatDialogModule, TagBoxComponent]
})
export class UnsavedChangesDialog {

  // kycResult: any;
  unsavedChanges: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: string[],
    private matDialogRef: MatDialogRef<UnsavedChangesDialog, boolean>) {

    this.unsavedChanges = data;
  }

  onSaveChanges = () => {
    this.matDialogRef.close(true);
  }

  onContinueWithoutSaving = () => {
    this.matDialogRef.close(false);
  }

}
