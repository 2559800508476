import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { DirectPaymentTableComponent } from './direct-payment-table.component';
import { BPayTableComponent } from './bpay-table.component';
import { BatchPaymentTableComponent } from './batch-payment-table.component';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { PendingApprovalTransactionsTableComponent } from './pending-approval-transactions-table.component';
import { ProcessedPendingTransactionsTableComponent } from './processed-pending-transactions-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularMaterialLibraryModule,
    DirectiveLibraryModule,
    InputMaskModule,
    PipeLibraryModule,
    DirectPaymentTableComponent,
    BPayTableComponent,
    BatchPaymentTableComponent,
    PendingApprovalTransactionsTableComponent,
    ProcessedPendingTransactionsTableComponent,
  ],
  exports: [
    DirectPaymentTableComponent,
    BPayTableComponent,
    BatchPaymentTableComponent,
    PendingApprovalTransactionsTableComponent,
    ProcessedPendingTransactionsTableComponent,
  ]
})
export class BankingModule {

}
