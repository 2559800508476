<div class="customer-access-level-component">
  <mat-form-field>
    <mat-label>Customer Access level</mat-label>
    <mat-select [formControl]="formControl" [disableControl]="disabled">
      @for (option of options; track option) {
        <mat-option [value]="option.type">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
