<div class="consumer-documents-component">
    <document-details
      [apiUrl]="apiUrl"
      (events)="onDocumentDetailsEvent($event)"
      (saveNote)="onSaveNote()"
      (scrollEvent)="scrollToChildTwo()"
      [application]="application"
      [listApplicationDocumentFn]="listApplicationDocumentFn"
      [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
      [approveApplicationDocumentFn]="approveApplicationDocumentFn"
      [declineApplicationDocumentFn]="declineApplicationDocumentFn"
      [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
      [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
      [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
      [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
       
      [createDocumentWorklistFn]="createDocumentWorklistFn"
      [completeDocumentWorklistFn]="completeDocumentWorklistFn"
      [generateApprovalNoticeFn]="generateApprovalNoticeFn"
      [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
      [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
      [getApplicationOwnerFn]="getApplicationOwnerFn"
      [undoApplicationDocumentFn]="undoApplicationDocumentFn"
      [getUserFn]="getUserFn"
      [isAssetEmpty]="isAssetEmpty"
      [notAllAssetAccepted]="notAllAssetAccepted"
      [missingBankDetails]="missingBankDetails"
      [updateApplicationStageFn]="updateApplicationStageFn"
      [getApplicationAssetFn]="getApplicationAssetFn"
      [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
      [settleLoanFn]="settleLoanFn"
      [getApplicationByIdFn]="getApplicationByIdFn"
      [totalNotesCount]="totalNotesCount"
    ></document-details>

  <div class="notes">
    <asset-notes
      #assetNotesComponent
      [application]="application"
      [createDocumentWorklistFn]="createDocumentWorklistFn"
      [completeDocumentWorklistFn]="completeDocumentWorklistFn"
      [isAssetNoteEditorShow] = "false"
      [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
      [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
      [createApplicationNoteFn]="createApplicationNoteFn"
      [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
      (totalNotesEvent)="totalNotes($event)"
    ></asset-notes>
  </div>
</div>
