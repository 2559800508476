import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {confirmPasswordValidator, formControlErrorKeys, formControlErrorMessage, getUser} from '@portal-workspace/grow-ui-library';
import {combineLatest, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { compareMatch, confirmPassword, ConfirmPasswordValue,OldPasswordValue, toUser, User } from '@portal-workspace/grow-shared-library';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

// NOTE: please use value object: ConfirmPasswordValue
@UntilDestroy()
@Component({
    selector: 'confirm-password',
    templateUrl: './confirm-password.component.html',
    styleUrls: ['./confirm-password.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConfirmPasswordComponent), multi: true },
    ],
    standalone: true,
    imports: [FormsModule, FlexModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule]
})
export class ConfirmPasswordComponent implements OnInit,  ControlValueAccessor {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup: FormGroup<{
    password: FormControl<ConfirmPasswordValue>,
    confirmPassword: FormControl<ConfirmPasswordValue>
    oldPassword : FormControl<OldPasswordValue>
  }>;
  formControlPassword: FormControl<ConfirmPasswordValue>;
  formControlConfirmPassword: FormControl<ConfirmPasswordValue>;
  formControlOldPassword: FormControl<OldPasswordValue>;
  user: User | null = null;
  onTouchFn?: ()=>void;
  onChangeFn?: (v?: ConfirmPasswordValue)=>void;
  disabled: boolean = false;
  data!: confirmPassword;
  value?: ConfirmPasswordValue;
  @Output() allData : EventEmitter<confirmPassword> = new EventEmitter();

  subscription: Subscription;

  constructor(private formBuilder: FormBuilder) {
    this.formControlPassword = formBuilder.control('', [Validators.required,Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
    this.formControlConfirmPassword = formBuilder.control('', [Validators.required,Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
    this.formControlOldPassword = formBuilder.control('')
    const validator = confirmPasswordValidator(this.formControlPassword, this.formControlConfirmPassword);
    this.formControlConfirmPassword.addValidators([validator]);
    this.formGroup = formBuilder.group({
      password: this.formControlPassword,
      confirmPassword: this.formControlConfirmPassword,
      oldPassword: this.formControlOldPassword,
    });
    this.subscription = combineLatest([
      this.formControlPassword.valueChanges,
      this.formControlConfirmPassword.valueChanges,
    ]).pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        const password = r[0];
        const confirmPassword = r[1];
        this.formControlPassword.updateValueAndValidity({onlySelf: false, emitEvent: false});
        this.formControlConfirmPassword.updateValueAndValidity({onlySelf: false, emitEvent: false});
        this.propagateChange(this.formGroup.valid? password : null);
      })
    ).subscribe();
  }

  hide = true;
  confirmPassword_hide = true;
  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser();
    this.formGroup.valueChanges.pipe(
      tap((r)=> {
        this.data = r
        this.allData.emit(this.data)
        }
      )
    ).subscribe()
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  writeValue(password?: ConfirmPasswordValue): void {
    this.formControlPassword.setValue(password ? password : '');
    this.formControlConfirmPassword.setValue(password ? password: '');
    this.propagateChange(password);
  }

  propagateChange(password?: ConfirmPasswordValue) {
    this.value = password;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(password ? password : null);
  }



}

