import {Component, Input} from '@angular/core';
import { TermRateForBusinessOverdraft } from '@portal-workspace/grow-shared-library';
import {BusinessLoansApplication, BusinessOverdraftApplication} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CurrencyPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import _ from 'lodash';
import {LooseCurrencyPipe} from '../../../pipes/loose-currency.pipe';
import { PercentagePipe } from '../../../pipes/percentage.pipe';
@Component({
    selector: 'business-overdraft-quote',
    templateUrl: './business-overdraft-quote.component.html',
    styleUrls: ['./business-overdraft-quote.component.scss'],
    standalone: true,
    imports: [FlexModule, MatDividerModule, CurrencyPipe, LooseCurrencyPipe, PercentagePipe]
})
export class BusinessOverdraftQuoteComponent {

  _ = _;
  
  @Input({required: true}) application!: BusinessOverdraftApplication;
  @Input({required: false}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: false}) amortizationChartData!: AmortizationChartData;
  @Input({required: false}) paymentChartData!: PaymentChartData;
  @Input({required: false}) repaymentEstimationData!: RepaymentEstimationEntry[];
  @Input({required: true}) data!:TermRateForBusinessOverdraft
}
