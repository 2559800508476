export type PaymentTermType = '30' | '60' | '90' | '120' | '150' | '180'

export type PaymentTermValue = {
  type: PaymentTermType,
  name: string,
} | null;

export const PaymentTermValueOptions: Exclude<PaymentTermValue, null>[] = [
  {type: '30', name: '30 Days'},
  {type: '60', name: '60 Days'},
  {type: '90', name: '90 Days'},
  {type: '120', name: '120 Days'},
  {type: '150', name: '150 Days'},
  {type: '180', name: '180 Days'}
];

