<div class="add-customer-user-form-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-h2">{{ existingUser ? 'Edit Customer User' : 'Add Customer User' }}</div>
      </div>
    </div>
    <form [formGroup]="formGroup1">

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <mat-form-field>
            <mat-label >Pismo Customer</mat-label>
            <mat-select required [formControl]="formControlPismoAccountCustomer" >
              @for(pismoAccountCustomer of pismoAccountCustomers; track pismoAccountCustomer) {
                <mat-option [value]="pismoAccountCustomer" class="mt-2">
                  {{pismoAccountCustomer.EntityName}} ({{pismoAccountCustomer.pismoAccountNumber}})
                </mat-option>
              }
            </mat-select>
            @for(errorKey of errorKeys(formControlPismoAccountCustomer); track errorKey) {
              <mat-error>
                @if (formControlPismoAccountCustomer.touched && formControlPismoAccountCustomer.hasError(errorKey)) {
                  <ng-container>
                    {{errorMessage(formControlPismoAccountCustomer, errorKey)}}
                  </ng-container>
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div fxLayout="row"
            fxLayoutGap="5px"
            fxLayoutAlign="space-between center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <button mat-stroked-button class="w-lt-md-100" (click)="cancel($event)">Cancel</button>
            <button mat-flat-button class="w-lt-md-100" color="primary" (click)="submit($event)"
                    [disabled]="formGroup1.invalid">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
