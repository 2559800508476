<div class="direct-application-top-menu-component"
    fxLayout="column"
    fxLayoutAlign="top center">
  <mat-toolbar color="primary">
    <img class="logo" src="/assets/images/DYNA_Logo_103023_Master-Rev.svg">

    <!-- separator / spacer -->
    <span fxFlex="grow"></span>

    <span class="version mat-caption">{{gitTagOrVersion}}</span>
  </mat-toolbar>
  <mat-progress-bar [ngClass]="{visible: isProgressBarVisible}" mode="indeterminate"></mat-progress-bar>
</div>
