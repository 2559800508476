import {Component, forwardRef, inject, Input, OnInit, SimpleChanges} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from "@angular/forms";

import {FlexModule} from "@angular/flex-layout/flex";
import {
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  maxValidator,
  minMaxValidator,
  minValidator, setupUntilDestroy,
} from "@portal-workspace/grow-ui-library";
import {InputMaskModule} from "@ngneat/input-mask";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {PercentageInputValue} from "@portal-workspace/grow-shared-library";
import {DisableControlDirective} from '../../directives/disable-control.directive';
import {UntilDestroy} from "@ngneat/until-destroy";
import {delay, tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import numeral from "numeral";
import {MARK} from '@portal-workspace/grow-ui-library/mark';
import {MatInputModule} from "@angular/material/input";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'percentage-input',
  templateUrl: './percentage-input.component.html',
  styleUrls: ['./percentage-input.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=> PercentageInputComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(()=>PercentageInputComponent)}
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexModule,
    InputMaskModule,
    DisableControlDirective
]
})
export class PercentageInputComponent extends AbstractControlValueAccessor<PercentageInputValue> implements OnInit {

  @Input({required: false}) title = 'Credit Rate Adjustment';
  @Input({required: false}) hint: string = '';
  @Input({required: false}) required = true;
  @Input({required: false}) min: number | null = 0;
  @Input({required: false}) max: number | null = null;

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formBuilder: FormBuilder = inject(FormBuilder);
  formControl: FormControl<number | null>;
  formGroup: FormGroup<{
    percentage: FormControl<number | null>,
  }>

  constructor() {
    super();
    const validators = this.createValidators();
    this.formControl = this.formBuilder.control(null, validators);
    this.formGroup = this.formBuilder.group({
      percentage: this.formControl,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      tap(v => {
        if (this.formGroup.valid) {
          const val = numeral(this.formControl.value).value();
          this.propagateChange(val);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  createValidators(): ValidatorFn[] {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.min != null && this.max != null) {
      validators.push(minMaxValidator(this.min, this.max));
    } else if (this.min != null) {
      validators.push(minValidator(this.min));
    } else if (this.max != null) {
      validators.push(maxValidator(this.max));
    }
    return validators;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if ((changes as any).min || (changes as any).max || (changes as any).required) {
        if (this.formControl) {
          const validators = this.createValidators();
          this.formControl.setValidators(validators);
          this.formControl.updateValueAndValidity();
          if (this.formControl.invalid) {
            this.formControl.setValue(null);
            // this.propagateChange(null);
          }
        }
      } else if ((changes as any).disabled) {
        if (this.formControl) {
          const isDisabled = (changes as any).disabled.currentValue;
          if (isDisabled) {
            this.formControl.disable();
          } else {
            this.formControl.enable();
          }
        }
      }
    }
  }

  doWriteValue(v?: PercentageInputValue | undefined): void | PercentageInputValue {
    if (v != null || v != undefined) {
      this.formControl.setValue(v);
    }
    return undefined;
  }
}
