import {Component, Input, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule, 
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApplicationDialogService, getUser,
  PortalHotToastService,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {BehaviorSubject, Observable, Subscription, of} from 'rxjs';
import {
  AddBankingTransactionFn, BPayPayment,
  ConfirmationDialogResult, DeleteTransactionFn,
  DirectPayment, DirectPaymentBatchDialogResult,
  DirectPaymentTransactionDialogResult, EditTransactionFn, GeneratePaymentFileFn, GetTransactionsFn, User
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {loadingFor} from '@ngneat/loadoff';
import {Sort, MatSortModule} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';

export class DirectPaymentDataSource extends DataSource<DirectPayment> {

  subject = new BehaviorSubject<DirectPayment[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<DirectPayment[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(payments: DirectPayment[]) {
    this.subject.next(payments);
  }
}
@UntilDestroy()
@Component({
  selector: 'direct-payment-table',
  templateUrl: './direct-payment-table.component.html',
  styleUrls: ['./direct-payment-table.component.scss'],
  standalone: true,
  imports: [FlexModule, FormsModule, ReactiveFormsModule, DatePipe, MatSortModule, LooseCurrencyPipe, MatButtonModule, MatTooltipModule, MatFormFieldModule, MatMenuModule, MatDividerModule, NgClass, ExtendedModule, MatTableModule, AsyncPipe]
})

export class DirectPaymentTableComponent implements OnInit {
  displayColumnsDirectPayment = ['date', 'type', 'amount', 'bsb', 'accountNumber', 'accountTitle', 'reference', 'status', 'action'];
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  subscriptions: Subscription[] = [];
  formControlSearchDirectPayment!: FormControl<string | null>;
  loader = loadingFor('directPaymentTable');
  directPaymentDataSource = new DirectPaymentDataSource();
  payments: DirectPayment[] = []
  //   = [
  //   {
  //     id: 1,
  //     type: 'direct-debit',
  //     bsb: "062303",
  //     accountNumber: "11111465",
  //     amount: 0.64,
  //     date: "27022023",
  //     accountTitle: "Jimmy Shen",
  //     reference: "16889-90000068",
  //   }, {
  //     id: 2,
  //     type: 'direct-debit',
  //     bsb: "063353",
  //     accountNumber: "10125575",
  //     amount: 369.71,
  //     date: "27022023",
  //     accountTitle: "Allgifts Australia",
  //     reference: "16890-90000069",
  //   }, {
  //     id: 3,
  //     type: 'direct-debit',
  //     bsb: "032188",
  //     accountNumber: "379919",
  //     amount: 380.04,
  //     date: "27022023",
  //     accountTitle: "David William Robertson",
  //     reference: "16892-90000102",
  //   }, {
  //     id: 4,
  //     type: 'direct-debit',
  //     bsb: "112908",
  //     accountNumber: "468613339",
  //     amount: 10000,
  //     date: "27022023",
  //     accountTitle: "MHMR Pty Ltd",
  //     reference: "16897-90000107",
  //   }, {
  //     id: 5,
  //     type: 'direct-debit',
  //     bsb: "484799",
  //     accountNumber: "609837655",
  //     amount: 26.43,
  //     date: "27022023",
  //     accountTitle: "M1 TIMBER WORKS PTY LTD",
  //     reference: "16895-90000127",
  //   }, {
  //     id: 6,
  //     type: 'direct-credit',
  //     bsb: "033001",
  //     accountNumber: "166054",
  //     amount: 11230.82,
  //     date: "27022023",
  //     accountTitle: "603 273 365 PTY",
  //     reference: "DIRECT DEBIT",
  //   }
  // ];
  @Input({required: true}) addBankingTransactionFn!: AddBankingTransactionFn;
  @Input({required: true}) editTransactionFn!: EditTransactionFn;
  @Input({required: true}) getTransactionsFn!: GetTransactionsFn;
  @Input({required: true}) deleteTransactionFn!: DeleteTransactionFn;
  @Input({required: true}) generatePaymentFileFn!: GeneratePaymentFileFn;
  @Input({required: false}) batchId!: number | null;
  user: User | null = getUser();

  constructor(
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {
    this.formControlSearchDirectPayment = formBuilder.control(null);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
  }

  reload() {
    if (this.batchId) {
      this.subscriptions.push(
        this.getTransactionsFn(this.batchId).subscribe((r: BPayPayment[] | DirectPayment[]) => {
          this.payments = r as DirectPayment[];
          this.directPaymentDataSource.update(this.payments)
        })
      )
    }
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = {prop: $event.active, dir: $event.direction};
    } else {
      this.sorts = null;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'id': return 'ID';
      case 'date': return 'Date';
      case 'type': return 'Type';
      case 'amount': return 'Amount';
      case 'billerCode': return 'Biller Code';
      case 'crn': return 'CRN';
      case 'reference': return 'Reference';
      case 'bsb': return 'BSB';
      case 'accountNumber': return 'Account Number';
      case 'accountTitle': return 'Account Title';
      case 'status': return 'Status';
      case 'action': return '';
      default: return column;
    }
  }

  editDirectPayment(directPayment: DirectPayment | undefined = undefined) {
    this.subscriptions.push(
      this.applicationDialogService.openDirectPaymentTransactionDialog({
        directPayment
      }).afterClosed().subscribe((r: DirectPaymentTransactionDialogResult | undefined) => {
        if (r && r?.readyForSubmission) {
          // edit
          if (r?.directPayment?.id) {
            const index = this.payments.findIndex(p => p.id === r.directPayment.id);
            if (index > -1) {
              this.editTransactionFn(r.directPayment).subscribe(() => {
                this.reload();
              })
              // TO DO: reload
              //this.payments[index] = r.directPayment;
            }
          } else { // add new
            this.subscriptions.push(
              this.addBankingTransactionFn({
                ...r.directPayment,
                userId: this.user?.UserId ?? 0,
                batchId: this.batchId ?? 0,
                type: 'direct-debit',
                billerCode: '',
                crn: '',
                reference: '',
                trackingId: '',
              }).subscribe((id: number) => {
                this.reload();
                // this.payments.push({
                //   ...r.directPayment,
                //   id: id,
                // })
              })
            )
            // TO DO: reload
            // const lastId = this.payments[this.payments.length - 1].id;
            // this.payments.push({
            //   ...r.directPayment,
            //   id: lastId + 1,
            // })
          }
          //this.reload();
        }
      })
    )
  }

  makePayment() {
    this.applicationDialogService.openDirectPaymentBatchDialog({
      payments: this.payments
    }).afterClosed().subscribe((r: DirectPaymentBatchDialogResult | undefined) => {
      if (r && r.readyForSubmission) {
        this.applicationDialogService.openConfirmationDialog({
          message: "Please confirm",
          subMessage: "Incorrect payment details can lead to big issues"
        }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            // connect to backend endpoint
            if (this.batchId) {
              this.subscriptions.push(
                this.generatePaymentFileFn(this.batchId).subscribe((fileName: string) => {
                  this.applicationDialogService.successDialog({
                    message: 'Success',
                    subMessage: `Payment file ${fileName} generated and sent to iLink.`
                  }).afterClosed().subscribe()
                })
              )
            } else {
              this.applicationDialogService.openAlertDialog({
                message: 'Error',
                subMessage: "Batch Id not found"
              })
            }
          }
        })
      }
    })
  }

  delete(element: DirectPayment) {
    this.subscriptions.push(
      this.deleteTransactionFn(element).subscribe(() => {
        this.reload();
      })
    )
  }
}
