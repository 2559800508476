import {Priviledges} from '../types.domain';

export type SideMenuComponentTreeNodeType =
  'profile' |
  'notifications' |
  'dashboard' |
  'overdraft-product' |
  'applications' |
  'new-application' |
  'asset-finance' |
  'business-finance' |
  'business-overdraft' |
  'insurance-premium' |
  'consumer-asset-finance' |
  'commercial-finance' |
  'consumer-finance' |
  'calculator' |
  'asset-finance-calculator' |
  'business-loans-calculator' |
  'business-overdraft-calculator' |
  'business-and-overdraft-borrowing-estimator' |
  'pismo-accounts' |
  'bank-statements' |
  'bank-statements-analysis' |
  'bank-statements-status' |
  'documents' |
  'document-worklist' |
  'credit-worklist' |
  'client-services-worklist' |
  'settlements-worklist' |
  'redbook' |
  'accreditation' |
  'admin' |
  'users' |
  'pending-approvals' |
  'unverified-users' |
  'rate-cards' |
  'customers' |
  'companies' |
  'reporting' |
  'transactions' |
  'pending-approval-transactions' |
  'audit-logs' |
  'notifications-management' |
  'set-maintenance' |
  'oauth' |
  'promotion' |

  'unsigned-invoices' |
  'manual-payment' |

  'banking' |
  'lg-rebate-calculator' |
  'aggregators' |
  'bsa-settings' | 
  'send-privacy-consent' | 
  'credit-management' |
  'bsa-settings' |
  'send-privacy-consent' |
  

  'whitelabel-settings' |

  'salesforce'
;

export interface SideMenuComponentTreeNode {
  name: string;
  icon?: string;
  accessLevels: Priviledges;
  type?: SideMenuComponentTreeNodeType;
  children: SideMenuComponentTreeNode[];
}

export type SideMenuComponentMenuType = 'profile' | 'notifications' | 'product-guide' | 'faq' | 'contact-us' | 'accreditation-listing' | 'send-privacy-consent';

export interface SideMenuComponentFlatTreeNode {
  expandable: boolean;
  level: number;
  treeNode: SideMenuComponentTreeNode;
}
