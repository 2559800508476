import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '../component-utils';
import { ActivatedRoute } from '@angular/router';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApplicationWebService, CreditorWatchReportData, GetWebServiceReportFn, WebServiceType } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'credit-report',
    templateUrl: './credit-report.component.html',
    styleUrls: ['./credit-report.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatSortModule, FlexModule, CustomPaginatorComponent, TitleCasePipe, DatePipe, MatButtonModule, MatCardModule, PdfViewerModule]
})

export class CreditReportComponent implements OnInit {
 
  subscriptions: Subscription[] = [];
  webServiceId!: number;
  pdf!: Uint8Array;
  webService!: ApplicationWebService;
  reportHtml!: SafeHtml

  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;
  @Input({required: true}) apiUrl!: string;
  @Output() leaveCreditReport: EventEmitter<void> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.webServiceId = this.route.snapshot.params['innerTabParam'];
    console.log('===webserviceid: ', this.webServiceId);

    if (this.webServiceId) {
      this.subscriptions.push(
        this.getWebServiceReportFn(this.webServiceId).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(response => {
          if (response && response.data) {
            this.webService = response.webService;
            if (this.webService.type === 'EquifaxFraudCheck') {
              this.reportHtml = this.sanitizer.bypassSecurityTrustHtml(response.data);
            }
            if (this.webService.type !== 'EquifaxFraudCheck') {
              this.base64ToArrayBuffer(response.data);
            }
          }
        })
      )
    }
  }

  onLeaveReport() {
    this.leaveCreditReport.emit();
  }

  onDownloadReport() {
    if (this.webService && this.webService.type === 'CreditorWatch') {
      const pdfId = (this.webService.json as CreditorWatchReportData).pdfRequestId;
      const url = `${this.apiUrl}/credit/download-creditorwatch-report/${pdfId}`;
      window.open(url)
    } else if (this.webService && this.webService.type === 'EquifaxConsumer') {
      const url = `${this.apiUrl}/credit/download-equifax-consumer-report/${this.webService.id}`;
      window.open(url)
    } else if (this.webService && this.webService.type === 'EquifaxFraudCheck') {
      const url = `${this.apiUrl}/credit/download-equifax-fraud-check-report/${this.webService.id}`;
      window.open(url)
    }
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.pdf = (bytes.buffer as Uint8Array);
  }
}
