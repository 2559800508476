import {Component, EventEmitter, forwardRef, OnInit, Output, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {setupUntilDestroy, getAddress2ComponentValueFormControlValueFn} from '@portal-workspace/grow-ui-library';
import {
  compareMatch,
  DatepickerValue,
  EmailComponentValue,
  GenderValue,
  MobileValue,
  NameComponentValue
} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {YesNoValue} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {TitleSelectionValue} from '@portal-workspace/grow-shared-library';
import {PropertyOwnerWithAddressValue} from '@portal-workspace/grow-shared-library';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {SoleTraderValue} from '@portal-workspace/grow-shared-library';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { PropertyOwnerWithAddressComponent } from '../property-owner-with-address-component/property-owner-with-address.component';
import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';

export interface SoleTraderComponentEvent {
  valid: boolean;
  entry: SoleTraderValue;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'sole-trader',
    templateUrl: './sole-trader.component.html',
    styleUrls: ['./sole-trader.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SoleTraderComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => SoleTraderComponent) },
    ],
    standalone: true,
    imports: [TitleSelectionComponent, MarkDirective, FormsModule, ReactiveFormsModule, NameComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, YesNoComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent]
})
export class SoleTraderComponent extends AbstractControlValueAccessor<SoleTraderValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];

  markObservable: Subject<boolean> = new Subject<boolean>();


  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  formGroup: FormGroup<{
    kind: FormControl<'SoleTrader'|null>;
    title: FormControl<TitleSelectionValue>;
    firstName: FormControl<NameComponentValue>;
    lastName: FormControl<NameComponentValue>;
    gender: FormControl<GenderValue>;
    dob: FormControl<DatepickerValue>;
    residentialAddress: FormControl<Address2ComponentValue>;
    privacyConsentObtained: FormControl<YesNoValue>;
    propertyOwner: FormControl<PropertyOwnerWithAddressValue>;
    guarantor: FormControl<YesNoValue>;
    email: FormControl<EmailComponentValue>;
    mobile: FormControl<MobileValue>;
  }>
  formControlKind: FormControl<'SoleTrader'|null>;
  formControlTitle: FormControl<TitleSelectionValue>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlGender: FormControl<GenderValue>;
  formControlDob: FormControl<DatepickerValue>;
  formControlResidentialAddress: FormControl<Address2ComponentValue>;
  formControlPrivacyConsent: FormControl<YesNoValue>;
  formControlPropertyOwner: FormControl<PropertyOwnerWithAddressValue>;
  formControlGuarantor: FormControl<YesNoValue>;
  formControlEmailAddress: FormControl<EmailComponentValue>;
  formControlMobileNumber: FormControl<MobileValue>;

  subscription: Subscription;
  @Input({required: false}) showAddressForm = true;
  @Output() events: EventEmitter<SoleTraderComponentEvent>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.events = new EventEmitter<SoleTraderComponentEvent>();
    this.formControlKind = formBuilder.control('SoleTrader');
    this.formControlTitle = formBuilder.control(null, [Validators.required]);
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlGender = formBuilder.control(null, [Validators.required]);
    this.formControlDob = formBuilder.control(null, [Validators.required]);
    this.formControlResidentialAddress = formBuilder.control(null, [Validators.required]);
    this.formControlPrivacyConsent = formBuilder.control(null, [Validators.required]);
    this.formControlPropertyOwner = formBuilder.control({propertyOwner: false}, [Validators.required]);
    this.formControlGuarantor = formBuilder.control(true, [Validators.required]);
    this.formControlEmailAddress = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      kind: this.formControlKind,
      title: this.formControlTitle,
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      gender: this.formControlGender,
      dob: this.formControlDob,
      residentialAddress: this.formControlResidentialAddress,
      privacyConsentObtained: this.formControlPrivacyConsent,
      propertyOwner: this.formControlPropertyOwner,
      guarantor: this.formControlGuarantor,
      email: this.formControlEmailAddress,
      mobile: this.formControlMobileNumber,
    });

    this.subscription = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.invalid) {
          this.propagateChange(null);
        } else {
          this.propagateChange(this.formGroup.value as SoleTraderValue);
        }
        this.events.emit({valid: this.formGroup.valid, entry: this.formGroup.value as SoleTraderValue})
      })
    ).subscribe();
  }

  ngOnInit() {
    setupUntilDestroy(this);
  }

  doWriteValue(v: SoleTraderValue | undefined): void | SoleTraderValue {
    console.log('**************** doWriteValue ofr SoleTrader', v);
    this.formControlTitle.setValue(v ? v.title : null);
    this.formControlFirstName.setValue(v ? v.firstName : null);
    this.formControlLastName.setValue(v ? v.lastName : null);
    this.formControlGender.setValue(v ? v.gender : null);
    this.formControlDob.setValue(v ? v.dob : null);
    this.formControlResidentialAddress.setValue(v ? v.residentialAddress : null);
    this.formControlPrivacyConsent.setValue(v ? v.privacyConsentObtained : null);
    this.formControlPropertyOwner.setValue(v ? v.propertyOwner : {propertyOwner: false});
    this.formControlGuarantor.setValue(v ? v.guarantor : true);
    this.formControlEmailAddress.setValue(v ? v.email: null);
    this.formControlMobileNumber.setValue(v ? v.mobile : null);
    return undefined;
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  test() {
    console.log('test', this.formGroup);
  }
}
