import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PortalHotToastService, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { Subscription, tap } from 'rxjs';
import { DeleteBsaLenderFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, PromotionResponse, UpdateBsaExcludedLenderFn, UpdateBsaLenderFn, DeleteBsaExcludedLenderFn, AddBsaLenderFn, AddBsaExcludedLenderFn, AddCreditFlowFn, GetCreditFlowsFn, UpdateCreditFlowFn, GetCreditAlertsFn, UpdateCreditAlertFn, AddCreditAlertFn, CopyCreditFlowFn, GetCreditRuleItemsFn, AddCreditRuleItemFn, UpdateCreditRuleItemFn, GetCreditAlertByStatusFn } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ApplicationService } from '../../service/application.service';
import { CreditFlowsComponent, EditCreditFlowComponent } from '@portal-workspace/grow-ui-library';
import { Router } from '@angular/router';
import { navigationUrlForEditCreditFlow } from '../../service/navigation-urls';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreditAlertsComponent } from '@portal-workspace/grow-ui-library';
import { CreditRuleItemsComponent } from '@portal-workspace/grow-ui-library';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './credit-management.page.html',
    styleUrls: ['./credit-management.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, CreditRuleItemsComponent, CreditAlertsComponent, CreditFlowsComponent, MatTooltipModule, EditCreditFlowComponent, FlexModule, MatChipsModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class CreditManagementPage implements OnInit {
  addCreditFlowFn!: AddCreditFlowFn;
  getCreditFlowsFn!: GetCreditFlowsFn;
  updateCreditFlowFn!: UpdateCreditFlowFn;
  addCreditAlertFn!: AddCreditAlertFn;
  getCreditAlertsFn!: GetCreditAlertsFn;
  updateCreditAlertFn!: UpdateCreditAlertFn;
  copyCreditFlowFn!: CopyCreditFlowFn;
  getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  addCreditRuleItemFn!: AddCreditRuleItemFn;
  updateCreditRuleItemFn!: UpdateCreditRuleItemFn;
  getCreditAlertByStatusFn!: GetCreditAlertByStatusFn;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private toastService: PortalHotToastService
  ) {
    this.addCreditFlowFn = this.applicationService.addCreditFlowFn;
    this.getCreditFlowsFn = this.applicationService.getCreditFlowsFn;
    this.updateCreditFlowFn = this.applicationService.updateCreditFlowFn;
    this.addCreditAlertFn = this.applicationService.addCreditAlertFn;
    this.getCreditAlertsFn = this.applicationService.getCreditAlertsFn;
    this.updateCreditAlertFn = this.applicationService.updateCreditAlertFn;
    this.copyCreditFlowFn = this.applicationService.copyCreditFlowFn;
    this.getCreditRuleItemsFn = this.applicationService.getCreditRuleItemsFn;
    this.addCreditRuleItemFn = this.applicationService.addCreditRuleItemFn;
    this.updateCreditRuleItemFn = this.applicationService.updateCreditRuleItemFn;
    this.getCreditAlertByStatusFn = this.applicationService.getCreditAlertByStatusFn;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  async addCreditFlow() {
    await this.router.navigate(navigationUrlForEditCreditFlow(0));
  }

  async onNavigateCreditFlow($event: number) {
    await this.router.navigate(navigationUrlForEditCreditFlow($event));
  }
}
