<div class="user-same-company-selection-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" [compareWith]="compareWith">
      @for (u of users; track u) {
        <mat-option [value]="u">{{u.Name}} ({{u.Email}})</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
