<div class="unverified-page container-fluid">

  @if (loader.loading.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  @if (user) {
    <div fxLayout="column" fxLayoutAlign="center start">
      @if (!user.mobile_verified || !user.verified) {
        <mat-card appearance="outlined">
          <mat-card-content>
            @if (!user.mobile_verified) {
              <div>
                <div class="mat-h4-custom">
                  Please verify your mobile, set the verification code send through SMS to verify your mobile.
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>SMS verification code</mat-label>
                    <input matInput [formControl]="formControlSmsVerificationCode">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <button mat-flat-button color="primary" (click)="sendSmsVerification($event)">Send verification SMS</button>
                  <button mat-flat-button color="primary" [disabled]="formControlSmsVerificationCode.invalid" (click)="verifySms($event)">Verify SMS</button>
                </div>
                <div class="separator">
                  <mat-divider [inset]="true"></mat-divider>
                </div>
              </div>
            }
            @if (!user.verified) {
              <div class="separator-top">
                <div class="mat-h4-custom">
                  Please verify your email, click the button to send a verification email to your email address with link to verify your email address.
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <button mat-flat-button color="primary" (click)="sendEmailVerification($event)">Send Email Verification</button>
                </div>
                <div class="separator">
                  <mat-divider [inset]="true"></mat-divider>
                </div>
              </div>
            }
            <div>
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <button mat-flat-button color="primary" (click)="switchUser($event)">Switch user</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      }
      @if (user.mobile_verified && user.verified) {
        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="mat-h4-custom">
              User already verified, please re-login.
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button mat-flat-button color="primary" (click)="reLogin($event)">Re-Login</button>
            </div>
          </mat-card-content>
        </mat-card>
      }
    </div>
  }
</div>
