import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {CoverTypeSelectionComponent} from './cover-type-selection.component';
import {InsurerSelectionComponent} from './insurer-selection.componen';
import {PolicyComponent} from './policy.component';
import {YesNoModule} from '../yes-no-component/yes-no.module';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {DatepickerModule} from '../datepicker-component/datepicker.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        YesNoModule,
        CurrencySelectionModule,
        DatepickerModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        CoverTypeSelectionComponent,
        InsurerSelectionComponent,
        PolicyComponent
    ],
    exports: [
        CoverTypeSelectionComponent,
        InsurerSelectionComponent,
        PolicyComponent,
    ]
})
export class InsurerSelectionModule {

}
