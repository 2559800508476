import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { BpayBankDetailsComponent } from './bpay-bank-details.component'
import { CurrencySelectionModule } from '../currency-selection-component/currency-selection.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        InputMaskModule,
        CurrencySelectionModule,
        BpayBankDetailsComponent
    ],
    exports: [
        BpayBankDetailsComponent,
    ]
})
export class BpayBankDetailsModule {

}
