<div class="section">
  <div class="row">
    <div class="col">
      <div class="mat-h3">Driving Licence Details</div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <cell>
        <div label>Title</div>
        <div body>{{accreditation.title}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>First name</div>
        <div body><p [innerHTML]="accreditation.givenName | ellipsis:10"></p></div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>Last name</div>
        <div body><p [innerHTML]="accreditation.surName | ellipsis:10"></p></div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-3">
      <cell>
        <div label>Date of birth</div>
        <div body>{{accreditation.dob|date: 'dd/MM/YYYY'}}</div>
      </cell>
    </div>
    <div class="col-6">
      <cell>
        <div label>Residential address</div>
        <div body>{{getAddress()}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Email address</div>
        <div body>{{accreditation.email}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>Mobile number</div>
        <div body><!--span>+61</span-->{{accreditation.mobileNumber}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Driver Licence Number</div>
        <div body>{{accreditation.driverLicenceNumber}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
  <div class="row">
    <div class="col-6">
      <cell>
        <div label>Driver Licence Issuing State</div>
        <div body>{{accreditation.driverLicenceIssuingState}}</div>
      </cell>
    </div>
    <div class="col-3">
      <cell>
        <div label>Driver Licence Expiry Date</div>
        <div body>{{accreditation.driverLicenceExpiryDate | date: 'dd/MM/yyyy'}}</div>
      </cell>
    </div>
  </div>
  <mat-divider class="ml-0" [inset]="true"></mat-divider>
</div>

