<div class="business-overdraft-pricing-comparison-dialog">
  <mat-dialog-content>
    <mat-card appearance="outlined">
      <mat-card-content>
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let data">{{data.name}}</td>
          </ng-container>
          <ng-container matColumnDef="currentValue">
            <th mat-header-cell *matHeaderCellDef>Current Value</th>
            <td mat-cell *matCellDef="let data">
              @if (data.type == 'text') {
                <span>{{data.currentValue ?? ''}}</span>
              }
              @if (data.type == 'currency') {
                <span>{{data.currentValue ?? '' | looseCurrency}}</span>
              }
              @if (data.type == 'percentage') {
                <span>{{(data.currentValue ?? '') | percentage: '1.0-2'}}</span>
              }
              @if (data.currentValueClick) {
                <span class="mdi mdi-information-outline cursor-pointer" (click)="data.currentValueClick(data.newValue)"></span>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="newValue">
            <th mat-header-cell *matHeaderCellDef>New Value</th>
            <td mat-cell *matCellDef="let data">
              @if (data.type == 'text') {
                <span>
                  @if (data.formControl) {
                    <!-- editable -->
                    <editable-text [formControl]="data.formControl">
                    </editable-text>
                  }
                  @if (!data.formControl) {
                    <!-- not editable -->
                    {{data.newValue ?? ''}}
                  }
                  @if (data.newValueClick) {
                    <span class="mdi mdi-information-outline cursor-pointer" (click)="data.newValueClick(data.newValue)"></span>
                  }
                </span>
              }
              @if (data.type == 'currency') {
                <span>
                  @if (data.formControl) {
                    <!-- editable -->
                    <editable-currency [formControl]="data.formControl">
                    </editable-currency>
                  }
                  @if (!data.formControl) {
                    <!-- not editable -->
                    {{data.newValue ?? '' | looseCurrency}}
                  }
                  @if (data.newValueClick) {
                    <span class="mdi mdi-information-outline cursor-pointer" (click)="data.newValueClick(data.newValue)"></span>
                  }
                </span>
              }
              @if (data.type == 'percentage') {
                <span>
                  @if (data.formControl) {
                    <!-- editable -->
                    <editable-percentage [formControl]="data.formControl">
                    </editable-percentage>
                  }
                  @if (!data.formControl) {
                    <!-- not editable -->
                    {{data.newValue ?? '' | percentage}}
                  }
                  @if (data.newValueClick) {
                    <span class="mdi mdi-information-outline cursor-pointer" (click)="data.newValueClick(data.newValue)"></span>
                  }
                </span>
              }
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['name', 'currentValue', 'newValue']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'currentValue', 'newValue']"></tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign="space-between center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start"
          class="w-100">
          <button mat-stroked-button color="primary"
            (click)="onClose($event)">
            Cancel
          </button>
          <button mat-flat-button
            class="w-lt-md-100"
            color="primary"
            (click)="onAcceptQuotation($event)">
            Save
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </mat-dialog-content>
</div>
