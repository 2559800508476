import { Component, Input, OnInit } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs/operators';
import { TradeAdminService } from '../../service/trade-admin.service';
import { openWindowAndDownloadWithFilename, PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgTemplateOutlet, NgClass, NgStyle } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { OverdraftCustomerService } from '../../service/overdraft-customer.service';

export interface TradeReportingEntry {
  type:
    'reporting-banking-transactions' |
    'reporting-brokerage-transactions' |
    'reporting-pismo-accounting-events' |
    'reporting-pismo-accounts-balances' |
    'reporting-pismo-accounts-limits' |
    'reporting-pismo-transactions-balance' |
    'reporting-pismo-transactions-denied' |
    'reporting-pismo-portfolio-balance' |
    'reporting-pismo-arrears' |
    'reporting-pismo-accounting-raw' |
    'reporting-pismo-accounting-aggregated' |
    'reporting-pismo-internal-accounts' | 
  'reporting-authorization-clearing' |
  'reporting-pismo-applepay',
  description: string,
}

export class TradeReportingPageDataSource extends DataSource<TradeReportingEntry> {

  subject: BehaviorSubject<TradeReportingEntry[]> = new BehaviorSubject<TradeReportingEntry[]>([]);

  expandedElement: TradeReportingEntry | null = null;

  connect(collectionViewer: CollectionViewer): Observable<TradeReportingEntry[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(entries: TradeReportingEntry[]) {
    this.subject.next(entries);
  }
}

const ALL_REPORTING_ENTRIES: TradeReportingEntry[] = [
  {type: 'reporting-banking-transactions', description: 'Banking transaction listing'},
  {type: 'reporting-brokerage-transactions', description: 'Brokerage transaction listing'},
  {type: 'reporting-pismo-accounting-events', description: 'Pismo Accounting Events'},
  {type: 'reporting-pismo-accounts-balances', description: 'Pismo Accounts Balances'},
  {type: 'reporting-pismo-accounts-limits', description: 'Pismo Accounts Limits'},
  {type: 'reporting-pismo-transactions-balance', description: 'Pismo Transactions Balance'},
  {type: 'reporting-pismo-transactions-denied', description: 'Pismo Transactions Denied'},
  {type: 'reporting-pismo-portfolio-balance', description: 'Pismo Portfolio Balance'},
  {type: 'reporting-pismo-arrears', description: 'Pismo Arrears Report'},
  {type: 'reporting-pismo-accounting-raw', description: 'Pismo Raw Accounting Report'},
  {type: 'reporting-pismo-accounting-aggregated', description: 'Pismo Aggregated Accounting Report'},
  {type: 'reporting-pismo-internal-accounts', description: 'Pismo Internal Accounts Report'},
  {type: 'reporting-authorization-clearing', description: 'Mastercard Transactions Report'},
  { type: 'reporting-pismo-applepay', description: 'Pismo Apple Pay Report' },
];

@Component({
    templateUrl: './reporting.page.html',
    styleUrls: ['./reporting.page.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', display: 'block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatTableModule, MatTooltipModule, NgTemplateOutlet, NgClass, ExtendedModule, NgStyle]
})
export class ReportingPage implements OnInit {

  @Input({required: false}) paywayCheckbox = false
  expandedElement: TradeReportingEntry | null = null;

  dataSource = new TradeReportingPageDataSource();
  columnsToDisplay = ['dir', 'description', 'action'];

  constructor(private tradeAdminService: TradeAdminService,
    private overdraftCustomerService: OverdraftCustomerService,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,) {
  }

  ngOnInit(): void {
    this.dataSource.update(ALL_REPORTING_ENTRIES);
  }

  isTableRowExpanded(element: TradeReportingEntry): boolean {
    return (this.expandedElement === element);
  }

  expandTableRow(element: TradeReportingEntry) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  generateReport(event: Event, element: TradeReportingEntry) {
    event.stopPropagation();
    switch (element.type) {
      case 'reporting-banking-transactions': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Without date range it will download all transactions`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = `${moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000`
              const endDateNew = `${moment(r.endDate ?? moment(), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000`
              console.log(startDateNew, endDateNew);
              this.tradeAdminService.getReportForBankingTransactions(startDateNew,endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-banking-transactions-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-brokerage-transactions': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Without date range it will download all transactions`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = `${moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000`
              const endDateNew = `${moment(r.endDate ?? moment(), 'DD/MM/YYYY').utc().format("YYYY-MM-DD HH:mm:ss")}.000`
              this.tradeAdminService.getReportForBrokerageTransactions(startDateNew,endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-brokerage-transactions-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-accounting-events': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('accounting_events', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-accounting-events-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-accounts-balances': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('accounts_balances', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-accounts-balances-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-accounts-limits': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('accounts_limits', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-accounts-limits-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-transactions-balance': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('transactions_balance', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-transactions-balance-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-transactions-denied': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('transactions_denied', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-transactions-denied-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-portfolio-balance': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('portfolio_balance', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-portfolio-balance-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-accounting-raw': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoRawAccountingReport(startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-accounting-raw-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-accounting-aggregated': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2024-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoAggregatedAccountingReport(startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-accounting-aggregated-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-internal-accounts': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoInternalAccountsReport(startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-pismo-internal-accounts-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-arrears': {
        this.overdraftCustomerService.generatePismoArrearsReport().pipe(
          this.toastService.loadingWithMessage('Downloading...'),
          tap(blob => {
            openWindowAndDownloadWithFilename(`report-pismo-arrears-${new Date().getTime()}`, blob);
          })
        ).subscribe();
        break;
      }
      case 'reporting-authorization-clearing': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
          tap(r => {
            console.log('***** AFTER CLOSE', r);
            if (r && r.type == 'ok') {
              const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              const endDateNew = moment(r.endDate ?? moment(new Date("2099-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
              this.overdraftCustomerService.generatePismoReports('authorization_clearing', startDateNew, endDateNew).pipe(
                this.toastService.loadingWithMessage('Downloading...'),
                tap(blob => {
                  openWindowAndDownloadWithFilename(`report-mastercard-transactions-${new Date().getTime()}`, blob);
                })
              ).subscribe();
            }
          })
        ).subscribe()
        break;
      }
      case 'reporting-pismo-applepay': {
        this.applicationDialogService.openDateRangeDialog({
          message: `Please select the date range`,
          mandatory: false,
        })
          .afterClosed().pipe(
            tap(r => {
              console.log('***** AFTER CLOSE', r);
              if (r && r.type == 'ok') {
                const startDateNew = moment(r.startDate ?? moment(new Date("2023-01-01")), 'DD/MM/YYYY').format("YYYY-MM-DD");
                const endDateNew = moment(r.endDate ?? moment(), 'DD/MM/YYYY').format("YYYY-MM-DD");
                this.overdraftCustomerService.generatePismoApplePayReport(startDateNew, endDateNew).pipe(
                  this.toastService.loadingWithMessage('Please wait, it might take a few minutes to generate the report...'),
                  tap(blob => {
                    openWindowAndDownloadWithFilename(`report-pismo-applepay-${new Date().getTime()}`, blob);
                  })
                ).subscribe();
              }
            })
          ).subscribe()
        break;
      }
    }
  }
}
