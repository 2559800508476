import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  DigitalIdService
} from '../../service/digital-id.service';
import {
  CheckVerificationCodePayloadResult, DigitalIdResponse, DigitalIdResult,
  DigitalIdResultError,
  DigitalIdResultReceived,
  IndividualWithResult,
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { parseDigitalIdResponse, DigitalIdAuthenticateFn, DigitalIdGetClientIdFn, ApplicationDialogService, PortalHotToastService, DigitalIdVerifyOneApplicationIndividualFn, TagBoxComponent } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {finalize, tap} from 'rxjs/operators';
import {concat, Subscription} from 'rxjs';
import {DigitalIdComponentEvent} from '@portal-workspace/grow-shared-library';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import { DigitalIdComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './digital-id-individual-verification.page.html',
    styleUrls: ['./digital-id-individual-verification.page.scss'],
    standalone: true,
  imports: [MessageBoxComponent, DigitalIdComponent, MatButtonModule, TagBoxComponent]
})
export class DigitalIdIndividualVerificationPage  implements  OnInit {

  getClientFn!: DigitalIdGetClientIdFn;
  authenticateFn!: DigitalIdAuthenticateFn;
  verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;

  subscriptions: Subscription[] = [];

  alerts: string[] = [];
  messages: string[] = [];
  checkResult?: CheckVerificationCodePayloadResult;
  verificationSessionToken?: string;
  allowDigitalIdVerification = false;
  verificationResult?: DigitalIdResult;
  doingVerification = false;
  digitalIdResponse: DigitalIdResponse | null = null;
  verificationCode: string | null = null;

  constructor(private digitalIdService: DigitalIdService,
              private minimalLayoutService: MinimalLayoutService,
    private dialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService,
              private route: ActivatedRoute) {
    this.getClientFn = this.digitalIdService.clientIdFn;
    this.authenticateFn = this.digitalIdService.authenticateFn;
    this.verifyOneApplicationIndividualFn = this.digitalIdService.verifyOneApplicationIndividualFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false
    });
    const verificationCode = this.route.snapshot.params.verificationCode;
    if (!verificationCode) {
      this.alerts.push('Invalid verification code');
      return;
    }
    this.verificationCode = verificationCode;
    this.refresh();
  }

  refresh() {
    this.checkResult = undefined;
    if (!this.verificationCode) {
      return;
    }
    const sub = this.digitalIdService.checkVerificationCode(this.verificationCode)
      .pipe(
        tap(r => {
          if (r.payload.isError) {
            const messages = r.payload.messages.map(a => a.message);
            this.alerts.push(...messages);
          } else {
            const messages = r.payload.messages.map(a => a.message);
            this.messages.push(...messages);
            this.checkResult = r.payload.result;
            const _digitalIdResponse = r.payload.result!.digitalIdResponse;
            this.digitalIdResponse = _digitalIdResponse;
            if (_digitalIdResponse && _digitalIdResponse.verification_session_token) {
              // todo: DigitalIdVerificationSessionToken
              this.verificationSessionToken = _digitalIdResponse.verification_session_token;
            }
            this.allowDigitalIdVerification = true;
          }
        })
      ).subscribe();
    this.subscriptions.push(sub);
  }

  verificationStatusDisplay(verificationStatus?: string): string {
    console.log('verificationStatus', verificationStatus)
    if (verificationStatus) {
      switch (verificationStatus) {
        case 'RECEIVED_COMPLETE':
          return 'Completed';
        case 'RECEIVED_INCOMPLETE' || 'WAITING':
          return 'In Progress';
        case 'RECEIVED_INCOMPLETE' || 'RECEIVED_ERROR':
          return 'Failed';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  verifyNow() {
    if ((!!!this.checkResult?.isNameOrDobChanged) && this.checkResult?.exp && Number(this.checkResult?.exp) > Math.floor(Date.now() / 1000)) {
      this.dialogService.openDigitalIdVerifyDialog({
        individual: { ...this.checkResult.individual, result: { ...this.checkResult.digitalIdResult } },
        kycResult: { ...this.checkResult.digitalIdResult },
        getClientFn: this.getClientFn,
        authenticateFn: this.authenticateFn,
        digitalIdOnCompleteCallbackFn: this.onDigitalIdEvent,
      })
        .afterClosed()
        .pipe(
          tap(r => {
            this.refresh();
          })
        ).subscribe();
    }
    else {
      this.subscriptions.push(
        this.verifyOneApplicationIndividualFn(this.checkResult?.applicationId!, this.checkResult?.individualId!)
          .pipe(
            this.portalHotToastService.spinnerObservable(),
          )
          .subscribe(
            (response: IndividualWithResult) => {
              console.log('===== individual response: ', response)
              if (response.result.status === 'RECEIVED_COMPLETE') {
              } else {
                this.dialogService.openDigitalIdVerifyDialog({
                  individual: response,
                  kycResult: response.result,
                  getClientFn: this.getClientFn,
                  authenticateFn: this.authenticateFn,
                  digitalIdOnCompleteCallbackFn: this.onDigitalIdEvent,
                })
                  .afterClosed()
                  .pipe(
                    tap(r => {
                      this.refresh();
                    })
                  ).subscribe();
              }
            }
          )
      )
    }

  }

  onDigitalIdEvent = ($event: DigitalIdComponentEvent, individual: IndividualWithResult) => {
    console.log('this.checkResult', this.checkResult)
    console.log('******************* onDigitalIdEvent ', $event);
    switch ($event.authResult.type) {
      case 'error': {
        this.alerts = [];
        this.alerts.push(`${$event.authResult.error} - ${$event.authResult.errorDescription}`);
        break;
      }
      case 'success': {
        const rst = parseDigitalIdResponse($event);
        console.log('digitalId result', $event.authResult);
        console.log('parsed result', rst);
        if (!this.checkResult) {
          alert(`Run digital id verification first`);
          return;
        }
        const applicationId = this.checkResult.applicationId;
        const individualId = this.checkResult.individualId;
        this.doingVerification = true;
        this.allowDigitalIdVerification = false;
        concat(
          this.digitalIdService
            .updateApplicationIndividualDigitalIdMapping(applicationId, individualId, {
              dataSourceEvents: [
                'kyc_basic_callback_result_success'
              ],
              transactionId: $event.transactionId,
              verificationSessionToken: undefined,
              verificationStatus: 'completed',
            }).pipe(
            tap(r => {
              console.log('updated individualDigitalIdMapping', r);
            })
          ),
          this.digitalIdService
            .sendVerificationSucceededNotificationEmail(applicationId, individualId)
            .pipe(
              tap(r => {
                console.log(`verification succeeded notification email send`, r);
              })
            ),
          this.digitalIdService
            .updateApplicationIndividualInfo(applicationId, individualId, rst)
            .pipe(
              tap(r => {
                console.log(r);
                this.verificationResult = r.payload.result;
                switch (this.verificationResult.status) {
                  case 'RECEIVED_ERROR': {
                    const _r: DigitalIdResultError = this.verificationResult;
                    this.allowDigitalIdVerification = true;
                    break;
                  }
                  case 'RECEIVED_INCOMPLETE':
                    this.allowDigitalIdVerification = true;
                    break;
                  case 'WAITING': {
                    const _r: DigitalIdResultReceived = this.verificationResult;
                    this.allowDigitalIdVerification = true;
                    break;
                  }
                  case 'RECEIVED_COMPLETE': {
                    this.allowDigitalIdVerification = false;
                    break;
                  }
                }
              }),
            ),
        ).pipe(
          finalize(async () => {
            this.doingVerification = false;
          })
        ).subscribe();

        break;
      }
    }

  }
}
