import {Component, Input, OnInit} from '@angular/core';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss'],
    standalone: true,
    imports: [FlexModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class DashboardCardComponent  implements OnInit {

  @Input({required: false}) title: string | null = null;
  @Input({required: false}) amount: string | null = null;
  @Input({required: false}) borderStyle: 'full' | 'bottom' = 'full';
  @Input({required: false}) borderColor: 'green' | 'blue' | 'solid-grey' | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
