<div class="mark-as-sold-dialog">
  <mat-dialog-content>
    <div class="header row">
      <div class="col-md-11"><h3 class="mb-0">Enable two-factor auth</h3></div>
      <div class="col-md-1 mt-1"><span class="mdi mdi-close" (click)="onCancel()"></span></div>
    </div>

    <form [formGroup]="formGroup">
      <div>
        <mat-form-field fxFlex="1 1 auto">
          <input matInput [formControl]="passwordControl" placeholder="Enter your current password" [type]="hide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="hide = !hide" class="mdi mdi-eye-outline"></mat-icon>
        </mat-form-field>
      </div>

      <div class="footer">
        <!-- <button color="primary" fxFlexAlign="start" mat-stroked-button (click)="onCancel()">Cancel</button> -->
        <button color="primary" fxFlexAlign="end" type="submit" mat-flat-button
                [disabled]="formGroup.invalid" (click)="onSubmit()">Continue</button>
      </div>

    </form>
  </mat-dialog-content>
</div>
