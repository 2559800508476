import {Component, Input, OnInit} from '@angular/core';
import {Application, ConsumerIndividual, Individual, IndividualWithAssetsAndLiabilities, CommercialEntity} from '@portal-workspace/grow-shared-library';
import {booleanToYesNo} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet, DatePipe } from '@angular/common';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'individual-details',
    templateUrl: './individual-details.component.html',
    styleUrls: ['./individual-details.component.scss'],
    standalone: true,
    imports: [FlexModule, NgTemplateOutlet, CellComponent, MatDividerModule, DatePipe, EllipsisPipe]
})
export class IndividualDetailsComponent implements OnInit {

  booleanToYesNo = booleanToYesNo;

  @Input({required: true}) application!: Application;
  @Input({required: true}) type: 'all-non-guarantors' | 'guarantors-only' | 'spouse-only' = 'all-non-guarantors';

  hasEntries: boolean = false;

  applicants: Individual[] = [];
  directors: Individual[] = [];
  guarantors: Individual[] = [];
  partners: Individual[] = [];
  shareHolders: Individual[] = [];
  soleTraders: Individual[] = [];
  trustees: Individual[] = [];
  spouses: Individual[] = [];

  commonGuarantor:number = 0;
  commonNonGuarantor:number = 0;

  commercialEntity: CommercialEntity[]=[];
  commercialTrustee: CommercialEntity[]=[];
  commercialGuarantor: CommercialEntity[]=[];
  commercialPartner: CommercialEntity[]=[];
  commercialMember: CommercialEntity[]=[];
  
  ngOnInit(): void {

    const individualsWithGuarantorRole = this.application.Individuals.filter((data) => data.Role === 'Guarantor');
    const guarantorCount = individualsWithGuarantorRole.length;
    this.commonGuarantor = guarantorCount;
    
    const individualsWithoutGuarantorRole = this.application.Individuals.filter((data) => data.Role !=='Guarantor');
    const nonGuarantorCount1 = individualsWithoutGuarantorRole.length;
    this.commonNonGuarantor = nonGuarantorCount1;
    (this.application.Individuals ?? []).forEach((ind: Individual | ConsumerIndividual | IndividualWithAssetsAndLiabilities) => {
      switch(ind.Role) {
        case 'Applicant':
          if (this.type === 'all-non-guarantors') {
            this.applicants.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'Director':
          if (this.type === 'all-non-guarantors') {
            this.directors.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'Guarantor':
          if (this.type === 'guarantors-only') {
            this.guarantors.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'Partner':
          if (this.type === 'all-non-guarantors') {
            this.partners.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'Shareholder':
          if (this.type === 'all-non-guarantors') {
            this.shareHolders.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'SoleTrader':
          if (this.type === 'all-non-guarantors') {
            this.soleTraders.push(ind);
            this.hasEntries = true;
          }
          break
        case 'Trustee':
          if (this.type === 'all-non-guarantors') {
            this.trustees.push(ind);
            this.hasEntries = true;
          }
          break;
        case 'Spouse':
          if (this.type === 'spouse-only') {
            this.spouses.push(ind);
            this.hasEntries = true;
          }
          break;
      }
    });

    const commercialEntities = this.application.CommercialEntities ?? [];
    const commercialEntitiesToIterate = commercialEntities.slice(1);
    commercialEntitiesToIterate.forEach((com:CommercialEntity)=>{

      switch(com.Type){
        case 'Guarantor':
          if(this.type==='guarantors-only'){
            this.commercialGuarantor.push(com)
            this.hasEntries = true;
          }
          break;
        case 'Trustee':
          if(this.type==='all-non-guarantors'){
            this.commercialTrustee.push(com)
            this.hasEntries = true;
          }
          break;
        case 'Partner':
          if(this.type==='all-non-guarantors'){
            this.commercialPartner.push(com)
            this.hasEntries = true;
          }
          break;
        case 'Member':
          if(this.type==='all-non-guarantors'){
            this.commercialMember.push(com)
            this.hasEntries = true;
          }
          break;
      }
    })

  }

}
