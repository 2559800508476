import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
// import {AngularMaterialComponentsModule} from '../../angular-material-components.module';
import {AccessLevelComponent} from './access-level.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {CustomerAccessLevelComponent} from './customer-access-level.component';
import {CustomerAccessLevelAndRoleAccessComponent} from "./customer-access-level-and-role-access.component";
// import {GrowUiLibraryModule} from '@portal-workspace/grow-ui-library';


const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialLibraryModule,
]

@NgModule({
    imports: [
        ...modules,
        AccessLevelComponent,
        CustomerAccessLevelComponent,
        CustomerAccessLevelAndRoleAccessComponent,
    ],
    exports: [
        AccessLevelComponent,
        CustomerAccessLevelComponent,
        CustomerAccessLevelAndRoleAccessComponent,
        ...modules,
    ]
})
export class AccessLevelModule {

}
