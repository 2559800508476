<div class="credit-info-exchange-alert-dialog" fxLayout="column" fxLayoutGap="20px">
  <mat-dialog-content>
    <message-box type="warn" title="Your attention is required">
      The next step will exchange information with major credit bureaus in Australia. Please confirm the relevant permissions have been obtained from the customer before proceeding further.
    </message-box>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3">
      <button mat-stroked-button color="primary" (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onProceed($event)">Proceed</button>
    </div>
  </mat-dialog-content>
</div>
