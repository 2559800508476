<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->
@if (!((store.value$ | async)?.error)) {
  <div class="pending-approvals-page container-fluid">
    <div class="row separator-bottom">
      <div class="col-12">
        <div class="mat-h3">Pending Approvals</div>
        <div class="mat-body-1">
          Shows pending approval users, when the 'Rejected users' is checked, this shows users that have been rejected regardless of the verification status.
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <input matInput type="text" [readonly]="loader.tableLoading.inProgress$ | async" [formControl]="formControlSearch" placeholder="Search pending request...">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <div fxLayoutGap="5px"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center center">
          <span class="">
            <mat-checkbox [formControl]="formControlOnlyRejectedEntries" color="primary">Rejected users</mat-checkbox>
          </span>
          <button class="custom-button w-lt-md-100" (click)="rejectSelectedUsers()">
            <span class="mdi mdi-cancel icon"></span>
            <span class="text">Reject selected</span>
          </button>
          <button class="custom-button w-lt-md-100" (click)="approveSelectedUsers()">
            <span class="mdi mdi-check-circle-outline icon"></span>
            <span class="text">Approve selected</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader type="list"></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="userId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="UserId">User ID</th>
              <td mat-cell *matCellDef="let element">{{element.UserId}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Name</th>
              <td mat-cell *matCellDef="let element" (click)="onRowClicked($event, element)">{{element.Name}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Email">Email</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width" (click)="onRowClicked($event, element)">{{element.Email}}</td>
            </ng-container>
            <ng-container matColumnDef="mobileNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="MobileNumber">Mobile number</th>
              <td mat-cell *matCellDef="let element" (click)="onRowClicked($event, element)">{{element.MobileNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="BusinessName">Broker</th>
              <td mat-cell *matCellDef="let element" class="ellipsis td-width" (click)="onRowClicked($event, element)">{{element.BusinessName}}</td>
            </ng-container>
            <ng-container matColumnDef="entityName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="EntityName">Customer</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.customerEntityName}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                <span
                  class="cursor-pointer mdi mdi-checkbox-multiple-blank-outline"
                (click)="toggleAllSelection()"></span>
              </th>
              <td mat-cell *matCellDef="let element" style="padding-right: 0!important;">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"
                ></mat-checkbox>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total"
              [pageIndex]="offset"
              [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]"
              (page)="onPagination($event)"
            ></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
