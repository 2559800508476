import {Injectable} from '@angular/core';
import {Observable, Observer, Subject} from 'rxjs';
import {MinimalLayoutHeaderInput, MinimalLayoutHeaderOutput} from './minimal-layout-header.component';

@Injectable()
export class MinimalLayoutService {

  private headerInput: Subject<MinimalLayoutHeaderInput> = new Subject<MinimalLayoutHeaderInput>();
  private headerOutput: Subject<MinimalLayoutHeaderOutput> = new Subject<MinimalLayoutHeaderOutput>();

  settings(settings: MinimalLayoutHeaderInput) {
    this.headerInput.next(settings);
  }

  settingChanges(): Observable<MinimalLayoutHeaderInput> {
    return this.headerInput.asObservable();
  }

  headerEventChanges(): Observable<MinimalLayoutHeaderOutput> {
    return this.headerOutput.asObservable();
  }

  fireHeaderEventChanges(event: MinimalLayoutHeaderOutput) {
    this.headerOutput.next(event);
  }

}
