import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BusinessSearchComponent} from './business-search.component';
import {BusinessSearch2Component } from './business-search2.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        BusinessSearchComponent,
        BusinessSearch2Component
    ],
    exports: [
        BusinessSearchComponent,
        BusinessSearch2Component,
    ]
})
export class BusinessSearchModule {

}
