export type PaymentFrequencyType = 'Monthly' | 'Fortnightly' | 'Weekly';
export type PaymentFrequencyValue = {
  type: PaymentFrequencyType,
  name: string,
} | null;


export const PaymentFrequencyValueOptions: Exclude<PaymentFrequencyValue, null>[] = [
  { type: 'Weekly', name: 'Weekly'},
  { type: 'Fortnightly', name: 'Fortnightly'},
  { type: 'Monthly', name: 'Monthly'},
];
