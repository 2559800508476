<div class="all-application-search-page container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field class="search">
        <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async"
          [formControl]="formControlSearch" (change)="onSearch($event)" placeholder="Search application by ID or Name">
          <!-- <input matInput #searchText type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" (change)="onSearch($event)" placeholder="Search application by ID or Name"> -->
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
    <div class="row separator-top">
      <div class="col body">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="applicationId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>App ID</th>
              <td class="col-app-id" mat-cell *matCellDef="let application">
                <application-type-icon [applicationType]="application.ApplicationType"></application-type-icon> {{application.BrokerAppId}}<br/>
                <span class="date-text">{{getApplicationCreateTime(application)}}</span>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="appName">
              <th class="col-appName" mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>App Name</span>
              </th>
              <td mat-cell *matCellDef="let application">
                @if (application.Source == 'external') {
                  <span class="mr-1 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
                }
                @if (application.Source == 'direct') {
                  <span class="mr-1 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
                }
                {{getApplicantName(application)}}<br/>
                @if (showBrokerName()) {
                  <span class="date-text">{{application.BrokerName}}</span>
                }
              </td>
            </ng-container> -->
            <ng-container matColumnDef="appName">
              <th class="col-appName" mat-header-cell *matHeaderCellDef>
                <span>App Name</span>
              </th>
              <td mat-cell *matCellDef="let application">
                @if (application.Source == 'external') {
                  <span class="mr-1 mdi mdi-alpha-e-circle"
                        matTooltip="External source application"></span>
                }
                @if (application.Source == 'direct') {
                  <span class="mr-1 mdi mdi-alpha-d-circle"
                        matTooltip="Direct source application"></span>
                }
                {{ getApplicantName(application) }}<br/>
              </td>
            </ng-container>
            <ng-container matColumnDef="appStatus">
              <th class="col-appStatus" mat-header-cell *matHeaderCellDef mat-sort-header="Status">Status</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStatus(application) ?? ''"></tag-box>
              </td>
            </ng-container>
            <ng-container matColumnDef="appStage">
              <th class="col-appStage" mat-header-cell *matHeaderCellDef mat-sort-header="AppInfoStageName">Stage</th>
              <td mat-cell *matCellDef="let application">
                <tag-box [state]="getApplicationStage(application)"></tag-box>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr class="cursor-pointer" (click)="onRowClicked($event, application)" mat-row *matRowDef="let application; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total" [pageIndex]="offset" [pageSize]="limit"
            [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
