
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {loadingFor} from '@ngneat/loadoff';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {AccreditationService} from '../../service/accreditation.service'
import { createAsyncStore } from '@ngneat/loadoff';
import { AccreditationListingSortType, AllBusinessTypes, DEFAULT_BUSINESS_TYPE_FILTER, DEFAULT_LIMIT, DEFAULT_OFFSET, PromotionResponse, getAccreditationNumber } from '@portal-workspace/grow-shared-library';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MessageBoxComponentEvent,
  PortalHotToastService,
  CustomContentLoaderComponent,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import { Router } from '@angular/router';
import { navigationUrlForAccreditationTabbing } from '../../service/navigation-urls';
import { ApplicationService } from '../../service/application.service';
import { Sort, MatSortModule } from '@angular/material/sort';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { BusinessTypeIconComponent } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { AsyncPipe, NgClass } from '@angular/common';

import { AdminService } from '../../service/admin.service';
import {UntilDestroy} from "@ngneat/until-destroy";


export class InternalDataSource extends DataSource<any> {

  subject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(accreditations: any[]) {
    this.subject.next(accreditations);
  }
}

export interface BusinessTypeFilter {
  type: AllBusinessTypes,
  name: string;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './accreditation-listing.page.html',
    styleUrls: ['./accreditation-listing.page.scss'],
    standalone: true,
    imports: [
    MessageBoxComponent,
    FlexModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    BusinessTypeIconComponent,
    MatTableModule,
    MatSortModule,
    TagBoxComponent,
    CustomPaginatorComponent,
    AsyncPipe,
    NgClass,
    CustomContentLoaderComponent
]
})

export class AccreditationListingPage implements OnInit {

  dataSource = new InternalDataSource();
  subscriptions: Subscription[] = [];
  loader = loadingFor('tableLoading');
  displayColumns = ['accreditationId', 'companyName', 'status', 'stage'];
  store = createAsyncStore();
  limit: number = DEFAULT_LIMIT;
  offset: number = DEFAULT_OFFSET;
  total: number = 0;
  sorts: AccreditationListingSortType = null;
  search = '';
  promo ?: PromotionResponse;
  fetchedData: any[] = [];
  businessTypes: BusinessTypeFilter[] = [
    DEFAULT_BUSINESS_TYPE_FILTER,
    { type: 'broker', name: 'Broker' },
    { type: 'vendor', name: 'Vendor' },
    { type: 'supplier', name: 'Supplier' },
    { type: 'dealer', name: 'Dealer' },
  ];
  selectedBusinessType: BusinessTypeFilter = DEFAULT_BUSINESS_TYPE_FILTER;

  formControlSearch: FormControl<string | null>;
  @ViewChild('searchText') searchTextRef!: ElementRef<HTMLInputElement>;

  constructor(private accreditationService: AccreditationService,
              private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private adminService: AdminService,
              private router: Router,
              private applicationService: ApplicationService) {
    this.formControlSearch = this.formBuilder.control('');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reloadPromos();
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.search = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  private reloadPromos() {
    this.subscriptions.push(this.adminService.getPromotionByType('application').pipe(
      tap(r => {
        this.promo = r.payload
      })
    ).subscribe());
  }

  getAccreditationId(accreditationId: number) {
    return getAccreditationNumber(accreditationId);
  }
  // onSortData($event: Sort) {
  //   if ($event.direction && $event.active) {
  //     this.sorts = [{ prop: $event.active, dir: $event.direction }] as AccreditationListingSortType;
  //     console.log(this.sorts);

  //   } else {
  //     this.sorts = null;
  //   }
  //   this.reset();
  //   this.reload();
  // }
  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      if ($event.active == 'status') {
        // Perform custom sorting for 'status' column
        const sortedData = this.fetchedData.sort((a, b) => {
          const statusA = this.getApplicationStatus(a.Stage, a.SalesforceId) ?? '';
          const statusB = this.getApplicationStatus(b.Stage, b.SalesforceId) ?? '';
          if ($event.direction === 'asc') {
            return statusA.localeCompare(statusB);
          } else {
            return statusB.localeCompare(statusA);
          }
        });
        this.dataSource.update(sortedData);
      } else {
        this.sorts = [{ prop: $event.active, dir: $event.direction }] as AccreditationListingSortType;
        this.reset();
        this.reload();
      }
    } else {
      this.sorts = null;
      this.reset();
      this.reload();
    }
  }
  private reload() {
    this.store = createAsyncStore();
    this.subscriptions.push(this.accreditationService.getAccreditationList({
        page: {
          limit: this.limit, offset: this.offset,
        },
        filter: this.search,
        sorts: this.sorts,
      },
      this.selectedBusinessType.type,
    ).pipe(
      this.toastService.spinnerObservable(),
      this.loader.tableLoading.track(),
      this.store.track(),
      tap((r)=> {
        this.limit = r.limit;
        this.offset = r.offset;
        this.total = r.total;
        console.log(r, 'rrr')
        this.fetchedData = r.payload;
        this.dataSource.update(r.payload);
      })
    ).subscribe());
  }

  private resetPagination() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  onBusinessTypeSelected(value: any) {
    this.selectedBusinessType = value;
    this.clearSearch();
    this.resetPagination();
    this.reload();
  }

  clearSearch() {
    this.search = '';
    this.searchTextRef.nativeElement.value = '';
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.reload();
  }

  onSearch($event: Event) {
    this.search = ($event.target as HTMLInputElement).value;
    this.resetPagination();
    this.reload();
  }

  closePromo($event: MessageBoxComponentEvent) {
    $event.close();
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  async onAccreditationClick($event: MouseEvent, accreditation: any) {
    await this.router.navigate(navigationUrlForAccreditationTabbing(String(accreditation.AccreditationId)));
  }

  getApplicationStatus(stageName: string, salesforceid: string) {
    const stage = stageName;
    const salesforceId = salesforceid;
    return this.applicationService.getApplicationStatus2(stage, salesforceId);
  }

}
