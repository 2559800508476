<div class="eligibility-component" fxLayout="column" gdRows="auto auto" gdColumns="auto auto" fxLayoutAlign="center center">
    <div class="p-4" style="background-color: white; width: 40%;">
        <div class="row ">
            <div class="col">
                <message-box type="info" title="Eligibility">
                    <p class="mat-body">If you do not meet the eligibility criteria, we recommend you do not
                        proceed with this application.</p>
                </message-box>
            </div>
        </div>
        <div class="row separator-top">
            <div class="col body">
                <table mat-table [dataSource]="whoCanApply" class="mat-elevation-z8">

                    <ng-container matColumnDef="icon">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <span class="{{element.icon}}"></span> </td>
                    </ng-container>

                    <ng-container matColumnDef="content">
                        <th mat-header-cell *matHeaderCellDef> Who can apply </th>
                        <td mat-cell *matCellDef="let element" class="content-width"> {{element.content}} </td>
                    </ng-container>

                    <ng-container matColumnDef="symbol">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <span
                                class="{{element.symbol}} symbol-color"></span> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="whoCanApplyColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: whoCanApplyColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="row separator-top">
            <div class="col body">
                <table mat-table [dataSource]="infoNeeded" class="mat-elevation-z8">

                    <ng-container matColumnDef="icon1">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <span class="{{element.icon1}}"></span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="content1">
                        <th mat-header-cell *matHeaderCellDef> Information needed</th>
                        <td mat-cell *matCellDef="let element" class="content-width"> {{element.content1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="symbol1">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <span
                                class="{{element.symbol1}} symbol-color"></span> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="infoNeededColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: infoNeededColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <button mat-stroked-button="" color="primary"
                    class="mat-focus-indicator mat-stroked-button mat-button-base mat-primary"
                    (click)="onGoBackChild()"> Back </button>
            </div>
            <div class="col" fxLayoutAlign="end center">
                <button mat-flat-button color="primary" (click)="onGotItChild()"> Got it </button>
            </div>
        </div>
    </div>
</div>