<span
  class="business-type-icon-component"
  [matTooltip]="
    businessType === 'broker'
      ? 'Broker'
      : businessType === 'vendor'
      ? 'Vendor'
      : businessType === 'supplier'
      ? 'Supplier'
      : businessType === 'dealer'
      ? 'Dealer'
      : ''
  "
  matTooltipPosition="above"
  matTooltipClass="icon-toolTip"
  [ngClass]="[
    'mdi',
    'application-type-icon',
    businessType === 'broker'
      ? 'mdi-account-outline'
      : businessType === 'vendor'
      ? 'mdi-account-box-outline'
      : businessType === 'supplier'
      ? 'mdi-account-convert-outline'
      : businessType === 'dealer'
      ? 'mdi-account-arrow-right-outline'
      : businessType === 'All'
      ? 'mdi-account-group-outline'
      : ''
  ]"
></span>
