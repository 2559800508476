<div class="reset-tfa-page container-fluid">

    <div class="row">
      <div class="col-12"
           fxLayoutGap="10px"
           fxLayout="column"
           fxLayoutAlign="center center">
        <div class="mat-h2">Reset your Authenticator</div>
        <div class="row mat-body">Select an option below.</div>
      </div>
    </div>

    <div class="row mt-5" (click)="sendSmsVerification($event)">
      <div class="col-12 cursor-pointer"
           fxLayoutGap="10px"
           fxLayout="row"
           fxLayoutAlign="center start">
        <div class="">
          <span class="mdi mdi-message-text-outline"
                style="font-size: 25px;"></span>
        </div>
        <div>
          <div class="mat-h4-custom">Get a reset code at ••• ••• ••{{mobileNumber}}</div>
          <div>Receive the text message</div>
        </div>
        <div class="">
          <span style="padding-left:50px;" class="mdi mdi-chevron-right"></span>
        </div>
      </div>
    </div>

    <div class="row mt-5" (click)="sendEmailVerification($event)">
      <div class="col-12 cursor-pointer"
           fxLayoutGap="10px"
           fxLayout="row"
           fxLayoutAlign="center start">
        <div class="">
          <span class="mdi mdi-email-outline"
                style="font-size: 25px;">
          </span>
        </div>
        <div>
          <div class="mat-h4-custom">Get a reset code via Email</div>
          <div>We’ll send it to {{email}}</div>
        </div>
        <div class="mt-2 ml-4"> <span style="padding-left:70px;" class="mdi mdi-chevron-right"></span></div>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-12" fxLayout="column" fxLayoutAlign="center center">
        <a href="#" [routerLink]="navigationFor2FA()">
          <span class="mdi mdi-arrow-left mr-1"></span>
          Go back
        </a>
      </div>
    </div>

 </div>
