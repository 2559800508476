import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { AggregatorSearchComponentValue, compareMatch } from '@portal-workspace/grow-shared-library';
import { formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

export type AggregatorSearchFn  = (term: string)=>Observable<Exclude<AggregatorSearchComponentValue, null>[]>;

// NOTE: value from this component will be AggregatorSearchComponentValue
@Component({
    selector: 'aggregator-search',
    templateUrl: './aggregator-search.component.html',
    styleUrls: ['./aggregator-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AggregatorSearchComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AggregatorSearchComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatTooltipModule, MatOptionModule, DisableControlDirective]
})
export class AggregatorSearchComponent implements OnInit, ControlValueAccessor, OnChanges, Mark {

  onChangeFn?: (v?: AggregatorSearchComponentValue)=>void;
  onTouchFn?: ()=>void;
  value?: AggregatorSearchComponentValue;
  toolTipValue: string | undefined;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscription?: Subscription;

  @Input({ required: false }) title: string | null = null ;
  @Input({ required: false }) mandatory = false;
  @Input({ required: true }) searchFn!: AggregatorSearchFn;
  @Input({required: false}) disabled: boolean = false;


  formGroup: FormGroup<{
    search: FormControl<AggregatorSearchComponentValue | string>;
  }>;
  formControlSearch: FormControl<AggregatorSearchComponentValue | string>;
  displayWithFn: (v: AggregatorSearchComponentValue)=>string;
  filteredSearchEntries: Exclude<AggregatorSearchComponentValue, null>[] = [];


  constructor(private formBuilder: FormBuilder) {
    this.displayWithFn = (v) => v ? `${v.entityName} ${v.abn!=null?'- abn '+v.abn:''}` : '';
    this.formControlSearch = formBuilder.control('', [Validators.required]);
    this.formGroup = formBuilder.group({
      search: this.formControlSearch,
    });
  }

  ngOnInit(): void {
    this.subscription = this.formControlSearch.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r === 'string'),
      switchMap(r => {
        if (r === '') {
          this.propagateChange(null);
          return of(null);
        } else {
          // r will always be stirng due to filter operator above
          return this.searchFn(r as string);
        }
      }),
      tap(r => {
        if (!!!r || r.length == 0) {
          this.formControlSearch.setErrors({aggregatorSearch: true});
          this.propagateChange(null);
        }
        this.filteredSearchEntries = r ?? [];
      })
    ).subscribe();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  // setDisabledState(isDisabled: boolean): void {
  //   this.disable = isDisabled;
  //   if (isDisabled) {
  //     this.formControlSearch.disable();
  //   } else {
  //     this.formControlSearch.enable();
  //   }
  // }

  writeValue(obj?: AggregatorSearchComponentValue): void {
    this.value = obj;
    this.formControlSearch.setValue(obj?? null);
    this.propagateChange(obj);
  }

  propagateChange(v?: AggregatorSearchComponentValue) {
    this.value = v;
    this.onTouchFn && this.onTouchFn();
    this.onChangeFn && this.onChangeFn(v ? v : null);
  }

  onSelected($event: MatAutocompleteSelectedEvent) {
    let entityName= $event.option.value.entityName
    let abn = $event.option.value.abn
    this.toolTipValue= entityName +"- ABN:" +abn;
    this.propagateChange($event.option.value ? $event.option.value : null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.formControlSearch.disable();
      } else {
        this.formControlSearch.enable();
      }
    }
    if ((changes as any).mandatory) {
      const isMandatory = (changes as any).mandatory.currentValue;
      if (isMandatory) {
        this.formControlSearch.setValidators([Validators.required]);
      } else {
        this.formControlSearch.clearValidators();
      }
      this.formControlSearch.updateValueAndValidity();
    }
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

}
