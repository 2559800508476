import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {Application, } from '@portal-workspace/grow-shared-library';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';


@Injectable()
export class ApplicationResolver  {

  constructor(private applicationService: ApplicationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Application | null> | Promise<Application | null> | Application | null {
    const applicationId = route.params.applicationId;
    if (applicationId) {
      return this.applicationService.getApplicationById(applicationId).pipe(
        map(r => {
          console.log('************ RESOLVE ', applicationId, r.payload);
          return r.payload;
        }),
        catchError((err) => {
          console.log(`error resolving application with id ${applicationId}`, err);
          return of(null);
        })
      );
    }
    return null;
  }

}
