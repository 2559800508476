
@if (!((store.value$ | async)?.error)) {
  <div class="customer-details-page container-fluid">
    <button class="arrow-left mb-2" (click)="onClickBack()">
      <span class="mdi mdi-arrow-left"></span>
    </button>
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start" (selectedTabChange)="onChangeTab($event)">
      <mat-tab >
        <ng-template mat-tab-label label="DETAILS">
          <span class="mdi mdi-information-outline"></span>
          DETAILS
        </ng-template>
        <ng-container *ngTemplateOutlet="details"></ng-container>
      </mat-tab>
      @if (existingPismoAccountMappings.length) {
        <mat-tab label="OVERDRAFT">
          <ng-template mat-tab-label>
            <span class="mdi mdi-account-cash-outline"></span>
            OVERDRAFT
          </ng-template>
          <ng-container *ngTemplateOutlet="overdraft"></ng-container>
        </mat-tab>
      }
    </mat-tab-group>
  </div>
}

<!--
### Details template
-->
<ng-template #details>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-md-12 btn-space header"
          fxLayoutGap="10px"
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
           
            <button mat-flat-button class="w-lt-md-100" (click)="onCreateOverdraftAccount()">
              <span class="mdi mdi-plus" matPrefix></span>
              <span class="label"> New Overdraft</span>
            </button>
           
        </div>
      </div>
      @if (customerId) {
        <overdraft-customer-details
          [customerId]="customerId"
          [getCustomerFn]="getCustomerFn"
          [getRbaRateFn]="getRbaRateFn">
        </overdraft-customer-details>
      }
    </mat-card-content>
  </mat-card>
</ng-template>

<!--
#####  Overdraft Template
-->
<ng-template #overdraft>
  <div>
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start" (selectedTabChange)="onChangeTab($event)">
          <mat-tab label="OVERDRAFT ACCOUNT DETAILS">
            <ng-container *ngTemplateOutlet="overdraftAccountDetails"></ng-container>
          </mat-tab>
          <mat-tab label="OVERDRAFT ACCOUNT USERS">
            <ng-container *ngTemplateOutlet="overdraftAccountUsers"></ng-container>
          </mat-tab>
        </mat-tab-group>

        <ng-template #overdraftAccountDetails>
          <div class="row mb-2">
            <div class="col-12">
              <span class="mat-subtitle-1">Overdraft Account Details</span>
            </div>
          </div>
          @if (pismoAccountAndOpportunityDetails && customer) {
            <overdraft-account-details
              [customer]="customer"
              [existingPismoAccountMappings]="existingPismoAccountMappings"
              [canEdit]="isCurrentUserAdminOrOperationsOrAnalyst"
              [updatePismoAccountPaymentLimitFn]="updatePismoAccountPaymentLimitFn"
              [getOpportunitiesForCustomerFn]="getOpportunitiesForCustomerFn"
              [getPismoAccountAndOpportunityDetailsFn]="getPismoAccountAndOpportunityDetailsFn"
              [validEmailCheckFn]="validEmailCheckFn"
              [getUserByEmailFn]="getUserByEmailFn"
              [getApplicationIndividualsFn]="getApplicationIndividualsFn"
              [createOverdraftUserFn]="createOverdraftUserFn"
              [createAccountInterestRateFn]="createAccountInterestRateFn"
              [postOverdraftAccountFeeFn]="postOverdraftAccountFeeFn"
              [getPismoTransactionTypeByIdFn]="getPismoTransactionTypeByIdFn"
              [postManualTransactionFn]="postManualTransactionFn"
              [getPismoTransactionFlowFn]="getPismoTransactionFlowFn"
              [getPismoCardsForAccountFn]="getPismoCardsForAccountFn"
              [getPismoTransactionTypesFn]="getPismoTransactionTypesFn"
              [updatePismoAccountLimitFn]="updatePismoAccountLimitFn"
              [getAllPismoAccountStatusFn]="getAllPismoAccountStatusFn"
              [getPismoAccountStatusReasonsFn]="getPismoAccountStatusReasonsFn"
              [updatePismoAccountStatusFn]="pismoUpdateAccountStatusFn"
              [rollbackPismoAccountStatusFn]="pismoRollbackAccountStatusFn"
              [pismoCheckEmailExistsFn]="pismoCheckEmailExistsFn"
              [pismoGetBuiltInTransactionTypesFn]="pismoGetBuiltInTransactionTypesFn"
              [pismoUpdateRequireMonthlyFixedInstallmentFlagFn]="pismoUpdateRequireMonthlyFixedInstallmentFlagFn"
              [pismoUpdateStopDirectDebitFlagFn]="pismoUpdateStopDirectDebitFlagFn"
              (events)="onPismoAccountDetailsEvent($event)"
              [pismoGetPaidFacilityFeeFn]="pismoGetPaidFacilityFeeFn"
              [pismoGetPendingInterestFn]="pismoGetPendingInterestFn"
              [getPismoCustomerForAccountFn]="getPismoCustomerForAccountFn"
              [pismoSendPayoutNotificationFn]="pismoSendPayoutNotificationFn"
              [pismoGeneratePayoutFn]="pismoGeneratePayoutFn"
              [getPendingPaymentsFn]="getPendingPaymentsFn"
              [pismoGetAccountInterestRateFn]="pismoGetAccountInterestRateFn"
              [getDefaultRatecardDetailsFn]="getDefaultRatecardDetailsFn"
              [getAccountDetailsFromSfFn]="getAccountDetailsFromSfFn"
              [getRbaRateFn]="getRbaRateFn"
              [getCustomerFn]="getCustomerFn"
              [overdraftCustomerSfRecordId]="environment.OverdraftCustomerRecordTypeId"
              [overdraftAccountLimitIncreaseFn]="overdraftAccountLimitIncreaseFn"
              [manualDirectDebitFn]="manualDirectDebitFn"
              [updateMonthlyFacilityFeeFn]="updateMonthlyFacilityFeeFn"
              [updateAmortisedRepaymentFn]="updateAmortisedRepaymentFn"
              >
            </overdraft-account-details>
          }
        </ng-template>
        <ng-template #overdraftAccountUsers>
          <div>
            <div class="row mb-2">
              <div class="col-12">
                <span class="mat-subtitle-1">Overdraft Account Users</span>
              </div>
            </div>
            <overdraft-account-users
              [disableOverdraftUserFn]="disableOverdraftUserFn"
              [enableOverdraftUserFn]="enableOverdraftUserFn"
              [existingPismoAccountMappings]="existingPismoAccountMappings"
              [getPismoCustomerForAccountFn]="getPismoCustomerForAccountFn"
              [updateUserKycStatusFn]="updateUserKycStatusFn"
              [sendForgotPasswordFn]="sendForgotPasswordFn"
              [pismoUpdateCustomerFn]="pismoUpdateCustomerFn"
              [getPismoCardsForCustomerFn]="getPismoCardsForCustomerFn"
              [pismoChangeCardStatusFn]="changePismoCardStatusFn"
              [pismoGetCardReissueReasonsFn]="pismoGetCardReissueReasonsFn"
              [pismoReissueCardFn]="pismoReissueCardFn"
              [getTokenInfoFn]="getTokenInfoFn">
            </overdraft-account-users>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
