<form class="facility-establishment-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Facility Establishment Fee %</mat-label>
    <mat-select [formControl]="formControl">
      @for (option of options; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</form>
