<div class="personal-liabilities-component">
  <form class="personal-liabilities-form">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Personal liabilities</div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-12">
        <button mat-stroked-button (click)="onAddLiability($event)">
          <span class="mdi mdi-plus" matPrefix></span>
          Add liability
        </button>
      </div>
    </div>

    @if (formArray.length) {
      <!-- have liabilities -->
      @for (liabilityFormGroup of liabilitiesFormGroups(); track liabilityFormGroup) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Type</mat-label>
              <mat-select  [errorStateMatcher]="errorStateMatcher" [formControl]="liabilitiesFormGroupFormControl(liabilityFormGroup, 'liabilityType')">
                @for (type of liabilityTypes; track type) {
                  <mat-option [value]="type">{{type.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (isMortgageLiability(liabilityFormGroup)) {
            <div class="col-lg-4 col-md-12">
            <!--google-address controlsErrorIgnore title="Address" [formControl]="liabilitiesFormGroupFormControl(liabilityFormGroup, 'address')"></google-address-->
            <custom-address
              [mark]="markObservable"
              title="Address"
              [formControl]="liabilitiesFormGroupFormControl(liabilityFormGroup, 'address')">
            </custom-address>
          </div>
        }
        @if (!isMortgageLiability(liabilityFormGroup)) {
          <div class="col-lg-4 col-md-12">
            <mat-form-field>
              <mat-label>Financier</mat-label>
              <input matInput [errorStateMatcher]="errorStateMatcher" [formControl]="liabilitiesFormGroupFormControl(liabilityFormGroup, 'financier')">
            </mat-form-field>
          </div>
        }
        <div class="col-lg-3 col-md-12">
          <currency-input
            [mark]="markObservable"
            [formControl]="liabilitiesFormGroupFormControl(liabilityFormGroup, 'value')"
            title="Value">
          </currency-input>
        </div>
        <div class="col-lg-2 col-md-12"><button mat-icon-button (click)="onRemoveLiability($event, liabilityFormGroup)">
          <span class="mdi mdi-close"></span>
        </button></div>
      </div>
    }
    <div class="row">
      <div class="col-lg-3 col-md-12"></div>
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          <mat-label>Total liabilities</mat-label>
          <span matPrefix class="mdi mdi-currency-usd"></span>
          <input type="text" class="ml-4" readonly [inputMask]="createCurrencyMask" matInput [formControl]="formControlTotalLiabilities">
        </mat-form-field>
      </div>
    </div>
  }
</form>
</div>
