<div class="credit-result-alerts">
    <div class="row">
        <div class="col-12">
              <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                <table mat-table [dataSource]="dataSource" class="width-100 mb-4">
                  @for (column of columnsToDisplay; track column) {
                    <ng-container matColumnDef="{{column}}">
                  <th mat-header-cell *matHeaderCellDef [ngClass]="{
                    'column-width-1': column === 'runId',
                    'column-width-2': column === 'alert',
                    'column-width-3': column === 'alertStatus' || column === 'alertCode' || column === 'creditFlowNode',
                  }"> 
                    <div class="cell">{{getColumnTitles(column)}}</div> </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="cell">
                            @if (column === 'alertStatus') {
                              @if (element.alertStatus) {
                                <tag-box [state]="element.alertStatus"></tag-box>
                              }
                            }
                            @else if (column === 'runId') {
                              <div>{{element.runId}}</div>
                              <div class="date-text">{{element.lastUpdatedTime | date:'dd/MM/yyyy h:mm a'}}</div>
                            }
                            @else if (column === 'alert') {
                              <div class="date-text">{{element.applicant}}</div>
                              <div>{{element[column]}}</div>
                            }
                            @else {
                              {{element[column]}}
                            }
                          </div>
                        </td>
                      </ng-container>
                    }
            
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                    </tr>
                </table>

                @if (!dataSource.length) {
                  <table>
                    <tr>
                      <td class="not-found"> No alerts found</td>
                    </tr>
                  </table>
                }
              </div>
        </div>
    </div>
</div>
