import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { PPSRRegisterDialogData, PPSRRegisterDialogResult } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './PPSR-register.dialog.html',
    styleUrls: ['./PPSR-register.dialog.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule, MatDialogModule]
})
export class PPSRRegisterDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PPSRRegisterDialogData,
              private dialogRef: MatDialogRef<PPSRRegisterDialog, PPSRRegisterDialogResult>) {
  }

  onSubmit($event: MouseEvent) {
    const result: PPSRRegisterDialogResult = {}
    this.dialogRef.close(result);
  }

  onCancel($event: MouseEvent){
    this.dialogRef.close()
  }
}

