<div class="pismo-display-transactions-page m-4">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <h3 mat-dialog-title>Pismo transactions</h3>
  </div>
  @if (stage === 'init') {
    Loading ...
  }
  @if (stage === 'done') {
    <overdraft-account-transactions
      [pismoAccountNumber]="pismoAccountNumber"
      [getPismoTransactionsFn]="getPismoTransactionsFn"
      [getTransactionDetailsFn]="getTransactionDetailsFn"
      [getPismoAccountDetailsFn]="getPismoAccountDetailsFn"
      [getCardFn]="getCardFn"
      [getTransactionFromTimelineFn]="getTransactionFromTimelineFn"
    ></overdraft-account-transactions>
  }
  @if (stage === 'error') {
    <message-box type="warn">
      Unable to load transactions, missing required parameters
    </message-box>
  }
  <!--
  <ng-container>
    <list-content-loader *ngIf="loader.tableLoading.inProgress$ | async"></list-content-loader>
    <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async)}">

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2" matSort>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef>{{getColumnTitles(column)}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="column === 'icon'">
              <span
                class="{{getTransactionAttributes(transactionCategory(element))}} {{toClass(element)}} icon">
              </span>
            </ng-container>
            <ng-container *ngIf="column === 'description'">
              {{soft_descriptor(element)}}
              <br><span>
              {{getDate(createdAt(element))}} {{getTime(createdAt(element))}}
            </span>
          </ng-container>
          <ng-container *ngIf="column === 'amount'">
                            <div class="pr-3" [ngStyle]="{
                            'color': is_credit(element) ? 'var(--portal2-primary-default)' :
                                     isPendingTransaction(element) ? 'var(--portal2-sg-blue-grey-lighten-1)' :
                                     'var(--portal2-sg-blue-grey-darken-4)',
                                     'text-align': 'right'
                          }">
            {{getRelativeTransactionAmount((amount(element)), is_credit(element))}}</div>
            <br><span *ngIf="isPendingTransaction(element)">Pending</span>
          </ng-container>
        </td>
      </ng-container>

      <-- Expanded Content Column - The detail row is made up of this one column that spans across all columns --
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="row mt-3">
              <div class="col">
                <div class="row pt-0">
                  <div class="col-12">
                    <div class="ml-3">
                      <mat-divider></mat-divider>
                      <ng-container class="ml-3">
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-arrow-top-right icon"></span>
                          </div>
                          <div class="col-3 title">To</div>
                          <div class="col-3">
                          {{transactionDetails?.metadata?.AccountTitle}}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-check icon"></span>
                          </div>
                          <div class="col-3 title">Pismo Account Number</div>
                          <div class="col-3">{{transactionDetails?.account_id}}</div>
                        </div>

                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-check icon"></span>
                          </div>
                          <div class="col-3 title">From</div>
                          <div class="col-6">{{accountDetails?.account_name}} {{accountDetails?.account_id}}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-arrow-top-right icon"></span>
                          </div>
                          <div class="col-3 title">Category</div>
                          <div class="col-3">
                          {{transactionDetails?.processing_description}}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-information-outline icon"></span>
                          </div>
                          <div class="col-3 title">Failure Reason</div>
                          <div class="col-3">{{transactionDetails?.metadata?.['Reason']}}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-credit-card icon"></span>
                          </div>
                          <div class="col-3 title">Transaction reference number
                          </div>
                          <div class="col-3">
                          {{transactionDetails?.authorization?.tracking_id}}</div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row pismo-account-details">
                          <div>
                            <span class="mdi mdi-credit-card icon"></span>
                          </div>
                          <div class="col-3 title">Description</div>
                          <div class="col-3">
                          {{transactionDetails?.transaction_type?.description}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row cursor-pointer"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element; onRowClicked(element)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
      (click)="expandedElement = null"></tr>
    </table>
    <app-custom-paginator *ngIf="total" [length]="total" [pageIndex]="offset" [pageSize]="limit"
    [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
  </div>
</ng-container>
-->
</div>
