<div class="oauth-page container-fluid">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="DOCUSIGN">
        <div class="row button-group">
          <div class="col"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="10px">
            <button mat-stroked-button (click)="doDocusignAuth()">Authenticate with Docusign</button>
            <button mat-stroked-button (click)="refreshDocusignToken()">Refresh Docusign Token</button>
            <button mat-stroked-button (click)="checkCurrentDocusignToken()">Check current Docusign Token</button>
          </div>
        </div>
        @if (docusignResult) {
          <div class="row">
            <div class="col-12">
              <!-- show results -->
              @if (currentDocusignTokenPayload) {
                <p>Current Token</p>
              }
              @if (refreshDocusignTokenPayload) {
                <p>Refreshed Token</p>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ng-container *ngTemplateOutlet="docusignTable; context: docusignResult"></ng-container>
            </div>
          </div>
        }
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
</div>

<ng-template #docusignTable let-payload>
  @if (payload && payload.isError) {
    <div class="table-section">
      <table class="exchange-result-table table-bordered">
        <tr>
          <th>Error</th>
          <td>{{payload.error}}</td>
        </tr>
      </table>
    </div>
  }
  @if (payload && !payload.isError) {
    <div>
      <table class="exchange-result-table table-bordered">
        <tr>
          <td>access_token</td>
          <td class="token">{{payload.access_token}}</td>
        </tr>
        <tr>
          <td>expires_in</td>
          <td>{{payload.expires_in}}</td>
        </tr>
        <tr>
          <td>id_token</td>
          <td class="token">{{payload.id_token}}</td>
        </tr>
        <tr>
          <td>refresh_token</td>
          <td class="token">{{payload.refresh_token}}</td>
        </tr>
        <tr>
          <td>scope</td>
          <td>{{payload.scope}}</td>
        </tr>
        <tr>
          <td>token_type</td>
          <td>{{payload.token_type}}</td>
        </tr>
      </table>
    </div>
  }
</ng-template>
