import {Component, Input, OnInit} from '@angular/core';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'currency-chip',
    templateUrl: './currency-chip.component.html',
    styleUrls: ['./currency-chip.component.scss'],
    standalone: true,
    imports: [FlexModule, LooseCurrencyPipe]
})
export class CurrencyChipComponent implements OnInit {
  @Input({required: true}) colorTheme: string | 'green' | 'black' | 'red' = 'green';
  @Input({required: false}) title: string | null = null;
  @Input({required: false}) amount : string | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
