import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { BusinessNumberSearchResult, BusinessNumberSearchValue, compareMatch, NotNullable } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {debounceTime, Observable, of, startWith, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import _ from 'lodash';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';

import { AutoSelectOnClickDirective } from '../../directives/auto-select-on-click.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';

export interface BusinessNumberSearchFn {
  (businessNumber: string): Observable<BusinessNumberSearchResult>
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-number-search',
    templateUrl: './business-number-search.component.html',
    styleUrls: ['./business-number-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BusinessNumberSearchComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BusinessNumberSearchComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, DisableControlDirective, AutoSelectOnClickDirective, MatOptionModule, MatProgressSpinnerModule]
})
export class BusinessNumberSearchComponent extends AbstractControlValueAccessor<BusinessNumberSearchValue> implements OnInit, Mark{

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  displayWithFn: (v: BusinessNumberSearchValue)=>string = (v) => {
    if (v) {
      let name = v.name ?? '';
      return name;
    }
    return '';
  };


  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;
  @Input({required: true}) searchFn!: BusinessNumberSearchFn;

  @Input({required: false}) mandatory: boolean = true;
  @Input({required: false}) title: string  = ' ACN or ABN number' ;


  state: 'loading' | 'selected' | 'error' | 'done' | '' = '';

  subscriptions: Subscription[] = [];


  filteredEntries: NotNullable<BusinessNumberSearchValue>[] = [];

  formGroup!: FormGroup<{
    search: FormControl<BusinessNumberSearchValue | string>
  }>;
  formControl!: FormControl<BusinessNumberSearchValue | string>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  displayName(entry: BusinessNumberSearchValue) {
    return entry?.displayName ?? entry?.name ?? '';
  }

  ngOnInit() {
    setupUntilDestroy(this);
    console.log('Business number search componene', this.searchFn)
    this.formControl = this.formBuilder.control(null, this.mandatory ? [ Validators.required ] : []);
    this.formGroup = this.formBuilder.group({
      search: this.formControl,
    })
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      startWith(null),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r == 'string'),
      map(r => _.replace((r ?? '').toString(), / /g, '')),
      switchMap(_businessNumber => {
        // const acn = numeral(_acn).value();
        const businessNumber = _businessNumber;
        if (!businessNumber) {
          this.propagateChange(null);
          return of(null);
        } else {
          this.state = 'loading';
          console.log('**** business search', businessNumber);
          return this.searchFn(businessNumber as string);
        }
      }),
      map(_r => {
        console.log('**** _r', _r);
        this.state = 'done';
        const a = []; // entries of filtered result
        if (_r && _r.length) {
          for (const r of _r) {
            if (r['australian-business-register-report'] &&
            r['australian-business-register-report'].type == 'result' &&
            r['australian-business-register-report']?.['ABN']) {
                const _abn = r['australian-business-register-report']?.['ABN'];
                const v: BusinessNumberSearchValue = {
                  acn: String(r['australian-business-register-report']?.['ASICNumber'] ?? ''),
                  abn: _abn ? String(_abn) : undefined,
                  name: r['australian-business-register-report']?.['Non-IndividualName'] ??
                    r['australian-business-register-report']?.['IndividualName']  ??
                    r['match-name'] ?? '',
                  displayName: r['match-name'] ?? '',
                  result: r,
                }
                a.push(v);
            } else if (r['asic-report'] &&
              r['asic-report'].type == 'result' &&
              r['asic-report']?.['asic-name']) {  // we have an ACN or ABN search result
              const _abn = r['asic-report']?.['asic-a-b-n'];
              const v: BusinessNumberSearchValue = {
                acn: String(r['asic-report']?.['asic-organisation-number'] ?? ''),
                abn: _abn ? String(_abn) : undefined,
                name: r['asic-report']['asic-name'] ??
                  r['match-name'] ?? '',
                displayName: r['match-name'] ?? '',
                result: r,
              }
              a.push(v);
            }
          }
          if (a.length == 0) {
            this.formControl.setErrors({
              businessNumberSearch: true,
            });
            this.propagateChange(null);
          }
          this.filteredEntries = a;
        } else {
          this.formControl.setErrors({
            businessNumberSearch: true,
          })
          this.propagateChange(null);
          this.filteredEntries = [];
        }
      })
    ).subscribe());
  }


  doWriteValue(v: BusinessNumberSearchValue | null | undefined): void | BusinessNumberSearchValue {
    if (v && v.name) {
      setTimeout(() => {
        if (this.autocompleteTrigger) {
          const options = this.autocompleteTrigger.autocomplete.options.toArray();
          console.log('options============', options)
          const optionFound = v.abn ? options.find((o) => {
            const val = o.value;
            return (val && val.abn === v.abn);
          }) : false;
          console.log('optionFound:', optionFound);
          if (optionFound) {
            this.formControl.setValue(optionFound.value);
          } else {
            // this.formControl.setValue((v.acn ? v.acn : v.abn) ?? '');
            this.formControl.setValue(v);
          }
          this.propagateChange(v);
        }
      }, 1000);
    } else {
      this.formControl.reset();
      this.propagateChange(null);
    }
    return undefined;
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.state = 'selected';
    const v: BusinessNumberSearchValue = $event.option.value;
    this.formControl.setValue(v);
    this.propagateChange(v);
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  mark() {
    this.formGroup.markAllAsTouched();
  }
}
