import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map} from 'rxjs/operators';
import {
  DigitalIdAuthenticationPayload,
  DigitalIdClientInfoPayload,
  DigitalIdComponentEvent,
  DigitalIdOnCompleteCallbackFn,
  DigitalIdResult,
  IndividualWithResult,
  OnCompleteMessage,
  OnCompleteMessageError,
  OnCompleteMessageSuccess
} from '@portal-workspace/grow-shared-library';
import { isOnSuccessMessageAnError } from '../component-utils';
import { Observable } from 'rxjs';

declare global {
  interface Window {
    digitalId: any;
  }
}

export type DigitalIdGetClientIdFn =
  (token?: string) => Observable<DigitalIdClientInfoPayload>;
export type DigitalIdAuthenticateFn = (
  data: { code: string; transaction_id: string },
  type: 'basic' | 'premium'
) => Observable<DigitalIdAuthenticationPayload>;

export interface DigitalIdVerifyDialogData {
  individual: IndividualWithResult,
  kycResult: DigitalIdResult,
  getClientFn: DigitalIdGetClientIdFn,
  authenticateFn: DigitalIdAuthenticateFn,
  digitalIdOnCompleteCallbackFn: any,
}

export let counter = 1;

@Component({
  selector: 'app-digital-id',
  templateUrl: './digital-id.component.html',
  styleUrls: ['./digital-id.component.scss'],
  standalone: true,
})
export class DigitalIdComponent implements OnInit {
  @Output() events: EventEmitter<DigitalIdComponentEvent> = new EventEmitter();
  @Input({required: false}) selectorId = `digitalClient-${counter++}`;
  @Input({required: false}) verificationSessionToken?: string = undefined;
  @Input({required: false}) type: 'basic' | 'premium' = 'basic';

  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: false}) onCompleteCallback?: DigitalIdOnCompleteCallbackFn;
  @Input({ required: false }) onClick?: () => void;
  @Input({required: false}) token?: string;
  @Input({required: false}) uxMode?: string = 'popup'

  constructor(private elementRef: ElementRef) {}

  clientId?: string; // premium
  kycClientId?: string; // basic
  scriptUrl?: string;

  log: string = '';

  ngOnInit(): void {
    this.getClientFn(this.token)
      .pipe(
        map((r: DigitalIdClientInfoPayload) => {
          this.log = r.clientId;

          this.clientId = r.clientId;
          this.kycClientId = r.kycClientId;
          this.scriptUrl = r.scriptUrl;

          const script = document.createElement('script');
          script.src = this.scriptUrl;
          script.async = true;

          (this.elementRef.nativeElement as HTMLElement).appendChild(script);
          script.onload = () => {
            this.initDigitalId(this.selectorId);
          };
        })
      )
      .subscribe();
  }

  initDigitalId(selectorId: string) {
    const _onLoadComplete = this._onLoadComplete.bind(this);
    const _onComplete = this._onComplete.bind(this);
    const _onClick = this._onClick.bind(this);
    const _onKeepAlive = this._onKeepAlive.bind(this);
    const clientId = this.type === 'basic' ? this.kycClientId : this.clientId;
    try {
      window.digitalId.init({
        selector: `#${selectorId}`,
        // selector: `#digitalClient`,
        clientId,
        uxMode: this.uxMode,
        onLoadComplete() {
          _onLoadComplete();
        },
        onComplete(msg: OnCompleteMessage) {
          _onComplete(msg);
        },
        onClick(opts: any) {
          _onClick(opts);
        },
        onKeepAlive() {
          _onKeepAlive();
        },
      });
      console.log(
        `** init DigitalId: ${this.type} - ${clientId} - ${this.scriptUrl}`
      );
    } catch (err) {
      console.error(
        `** error initDigitalId: ${this.type} - ${clientId} - ${this.scriptUrl}`,
        err
      );
    }
  }

  _onLoadComplete() {
    console.log('**** onLoadComplete');
  }

  _onComplete(msg: OnCompleteMessage) {
    console.log('**** oncomplete', msg);
    if (isOnSuccessMessageAnError(msg)) {
      const errMsg: OnCompleteMessageError = msg;
      console.log('DigitalId Error message', errMsg);
      const evt: DigitalIdComponentEvent = {
        transactionId: errMsg.transaction_id,
        selectorId: this.selectorId,
        authResult: {
          type: 'error',
          error: errMsg.error,
          errorDescription: errMsg.error_description,
        },
      };
      if (this.onCompleteCallback) {
        this.onCompleteCallback(evt);
      }
      this.events.emit(evt);
    } else {
      const successMsg: OnCompleteMessageSuccess = msg;
      console.log('DigitalId success message', successMsg);
      this.authenticateFn(successMsg, this.type).subscribe(
        (r: DigitalIdAuthenticationPayload) => {
          console.log('DigitalId Post Success authenticate result', r);
          const evt: DigitalIdComponentEvent = {
            transactionId: successMsg.transaction_id,
            selectorId: this.selectorId,
            authResult: {
              type: 'success',
              payload: r,
            },
          };
          if (this.onCompleteCallback) {
            this.onCompleteCallback(evt);
          }
          this.events.emit(evt);
        }
      );
    }
  }

  _onClick(opts: any) {
    console.log(
      `digitalId component onclick, session Token ${this.verificationSessionToken}`
    );
    if (this.verificationSessionToken) {
      opts.verificationSessionToken = this.verificationSessionToken;
    }
    if (this.onClick) {
      this.onClick();
    }
  }

  _onKeepAlive() {}
}
