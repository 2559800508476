<form class="dscr-industry-selection-component" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Industry</mat-label>
    <mat-select [formControl]="formControl" placeholder="Select industry..." [compareWith]="compareWithFn">
      @for (dscrIndustry of dscrIndustries; track dscrIndustry) {
        <mat-option [value]="dscrIndustry">
          {{dscrIndustry.name}}
        </mat-option>
      }
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</form>


