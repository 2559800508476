import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../service/registration.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {clearAllStorage } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import {loadingFor} from '@ngneat/loadoff';
import {AuthService} from '../../service/auth.service';

import { MatButtonModule } from '@angular/material/button';
import { MessageBoxComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { AsyncPipe } from '@angular/common';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './email-verification.page.html',
    styleUrls: ['./email-verification.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, MatButtonModule, AsyncPipe, CustomContentLoaderComponent]
})
export class EmailVerificationPage implements OnInit {

  code?: string;

  verificationStatus: 'pending' | 'success' | 'failed' | 'verified' = 'pending';
  message = '';

  subscriptions: Subscription[] = [];
  inProgressLoader = loadingFor('InProgressUser');

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private toastService: PortalHotToastService,
              private minimalLayoutService: MinimalLayoutService,
              private registrationService: RegistrationService) {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.code = this.route.snapshot.params.code as string;
    if (!!!this.code) {
      this.verificationStatus = 'failed';
    } else {
      const userId = this.getUserId(this.code);
      this.ifUserVerified(userId);
    }
    this.minimalLayoutService.settings({
      showPrev: false,
      showContact: true,
      showAccreditationInContact: false,
      showCross: false,
    });
  }

  getUserId(code: string) {
    const decodedString = atob(code);
    const decodedArray = decodedString.split('/');
    if (decodedArray && decodedArray.length) {
      return Number(decodedArray[decodedArray.length - 1]);
    } else {
      return 0;
    }
  }

  ifUserVerified(userId: number) {
    const sub = this.registrationService.getUserVerificationStatus(userId).pipe(
      this.inProgressLoader.InProgressUser.track()
    ).subscribe(response => {
      if (response && response?.payload && response?.payload?.verified) {
        this.verificationStatus = 'verified';
      }
    })
    this.subscriptions.push(sub);
  }

  verify() {
    if (this.code) {
      const sub = this.registrationService.verifyEmailCode(this.code).pipe(
        // this.toastService.toastObserver2('verify email'),
        tap(r => {
          if (r.status) {
            this.verificationStatus = 'success';
          } else {
            this.verificationStatus = 'failed';
          }
          this.message = r.message;
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }


  async relogin($event: Event) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin());
  }
}
