import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  Application,
  GetUserFn,
  QuotationNotificationDialogData, QuotationNotificationDialogResult, UserandPriviledges
} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {setupUntilDestroy, validateEmail} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatDividerModule } from '@angular/material/divider';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './quotation-notification.dialog.html',
    styleUrls: ['./quotation-notification.dialog.scss'],
    standalone: true,
    imports: [MatDividerModule, FlexModule, MatFormFieldModule, MatChipsModule, FormsModule, ReactiveFormsModule, CustomAngularEditorComponent, MatCheckboxModule, MatButtonModule, MatDialogModule]
})
export class QuotationNotificationDialog implements OnInit{

  formGroup!: FormGroup;
  emailList: string[] = [];
  formControlEmail!: FormControl<string | null>;
  emailError = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  application!: Application;

  subscriptions: Subscription[] = [];
  placeholder = 'Extra additional info...';
  getUserFn!: GetUserFn;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuotationNotificationDialogData,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<QuotationNotificationDialog, QuotationNotificationDialogResult>
  ) {
    this.formControlEmail = this.fb.control(null);
  }

  ngOnInit() {
    setupUntilDestroy(this);

    // email
    this.subscriptions.push(
      this.formControlEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.emailError = false;
        }
      })
    )
  }

  onCancel() {
    this.matDialogRef.close()
  }

  onSubmit() {
    this.matDialogRef.close({
      readyForSubmission: true,
      emailList: this.emailList,
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.emailList.push(value);
      }
      // Clear the input value
      event.chipInput!.clear();
      this.emailError = false;
    } else if (value) {
      this.emailError = true;
    }
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);
    }
  }
}
