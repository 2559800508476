import { Component, EventEmitter, forwardRef, OnInit, Output, ViewChild, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AccountNumberValue,
  AssetSupplier, BsbComponentValue,
  compareMatch,
  fromAddress2ComponentValueToRawAddress,
  getOrgTypeByEntityText, MobileValue, NameComponentValue,
  PpsrAssetSupplierSearchValue,
  SaveSupplierData,
  SaveSupplierResult, SupplierType,
  SupplierTypeOptions,
  toRawAddress,
} from '@portal-workspace/grow-shared-library';
import { debounceTime, distinctUntilChanged, tap, map, startWith, filter } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { createAbnInputMask, createPhoneNumberInputMask, toEntityType } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Observable, Subject, Subscription } from 'rxjs';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteModule } from '@angular/material/autocomplete';
import { BusinessSearchFn, } from '../business-search-component/business-search.component';
import { BusinessSearchValue } from '@portal-workspace/grow-shared-library';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { Address2ComponentValue } from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { autocompleteObjectValidator } from '@portal-workspace/grow-ui-library';
 import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatButtonModule } from '@angular/material/button';
import { AccountNumberComponent } from '../account-number-component/account-number.component';
import { BsbComponent } from '../bsb-component/bsb.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { NameComponent } from '../name-component/name.component';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';


export type SearchSupplierFn = (abn: string, accreditedOnly?: boolean)=> Observable<AssetSupplier[]>;
export type SaveSupplierFn = (supplier: SaveSupplierData) => Observable<SaveSupplierResult>;

export interface PpsrAssetSupplierSearchEvent {
  type: 'submitForAccreditation',
  supplierType: string,
  supplierName: string,
  abn: string;
  address: Address2ComponentValue,
  contact: string;
}


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'ppsr-asset-supplier-search',
    templateUrl: './ppsr-asset-supplier-search.component.html',
    styleUrls: ['./ppsr-asset-supplier-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PpsrAssetSupplierSearchComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PpsrAssetSupplierSearchComponent) }
    ],
    standalone: true,
    imports: [MatTabsModule, MatFormFieldModule, MatInputModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, FlexModule, NameComponent, CustomAddressComponent, BsbComponent, AccountNumberComponent, MatButtonModule]
})
export class PpsrAssetSupplierSearchComponent extends AbstractControlValueAccessor<PpsrAssetSupplierSearchValue> implements OnInit, Mark {

  options = SupplierTypeOptions;

  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) saveSupplierFn!: SaveSupplierFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: false}) disabled: boolean = false;
  @Output() events: EventEmitter<PpsrAssetSupplierSearchEvent> = new EventEmitter<PpsrAssetSupplierSearchEvent>();

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;


  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();


  formGroupNewSupplier: FormGroup<{
    type: FormControl<SupplierType | null>,
    name: FormControl<BusinessSearchValue>,
    abn: FormControl<string | null>,
    address: FormControl<Address2ComponentValue>,
    contact: FormControl<MobileValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    middleName: FormControl<string | null>,
    insitituitionName: FormControl<string | null>,
    bankAccountName: FormControl<string | null>,
    BSB: FormControl<BsbComponentValue>,
    bankAccountNumber: FormControl<AccountNumberValue>,
  }>;
  formControlNewSupplierType: FormControl<SupplierType | null>;
  formControlNewSupplierName: FormControl<BusinessSearchValue>;
  formControlNewSupplierAbn: FormControl<string | null>;
  formControlNewSupplierAddress: FormControl<Address2ComponentValue>;
  formControlNewSupplierContact: FormControl<MobileValue>;
  formControlSupplierId: FormControl<string | null>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlMiddleName: FormControl<NameComponentValue>;
  formControlInsitituitionName: FormControl<string | null>;
  formControlBankAccountName: FormControl<string | null>;
  formControlBSB: FormControl<BsbComponentValue>;
  formControlAccountNumber: FormControl<AccountNumberValue>;
  formControlSearch: FormControl<string | AssetSupplier | null>;
  formControlAbn: FormControl<string | null>;
  formControlContact: FormControl<MobileValue>;

  supplierName:string | undefined;
  supplierAddress: any;

  assetSuppliers: AssetSupplier[] = [];
  step1SearchFn!: BusinessSearchFn
  newSupplierEntityType: any
  displayWithFn: (assetSupplier: AssetSupplier) => string = (assetSupplier) => {
    return assetSupplier?.ABN ?? '';
  };

  constructor(private formBuilder: FormBuilder,private toastService: PortalHotToastService,) {
    super();
    this.formControlSearch = formBuilder.control(null, [autocompleteObjectValidator(),Validators.required]);
    this.formControlAbn = formBuilder.control(null, [Validators.required]);
    this.formControlContact = formBuilder.control(null, [Validators.required]);

    this.formControlNewSupplierType = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierName = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierAbn = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierAddress = formBuilder.control(null, [Validators.required]);
    this.formControlNewSupplierContact = formBuilder.control(null, [Validators.required]);
    this.formControlSupplierId = formBuilder.control(null);
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(null);
    this.formControlInsitituitionName = formBuilder.control(null);
    this.formControlBankAccountName = formBuilder.control(null);
    this.formControlBSB = formBuilder.control(null);
    this.formControlAccountNumber = formBuilder.control(null);
    this.formGroupNewSupplier = formBuilder.group({
      type: this.formControlNewSupplierType,
      name: this.formControlNewSupplierName,
      abn: this.formControlNewSupplierAbn,
      address: this.formControlNewSupplierAddress,
      contact: this.formControlNewSupplierContact,
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      middleName: this.formControlMiddleName,
      insitituitionName: this.formControlInsitituitionName,
      bankAccountName: this.formControlBankAccountName,
      BSB: this.formControlBSB,
      bankAccountNumber: this.formControlAccountNumber
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formControlSearch.valueChanges.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r === 'string'),
      tap(r => {
        if (r) {
          // NOTE: search will always be string here due to filter rxjs/operator
          const search = r as string;
          this.subscriptions.push(this.searchSupplierFn(search).pipe(
            tap(rs => {
              this.assetSuppliers = rs;
            })
          ).subscribe());
          this.subscriptions.push(sub);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    // this.step1SearchFn = (search: string) => {
    //   return this.applicationService.searchCompanyByName(search).pipe(map(r => r.payload));
    // }
    this.step1SearchFn = this.businessSearchFn;
    const sub1 = this.formControlNewSupplierName.valueChanges.pipe(
      tap((r: BusinessSearchValue) => {
        if(r?.abn){
          this.formControlNewSupplierAbn.setValue(r.abn);
        }
        if (r && r.type === 'search-result') { // NOTE: when we did a business search through ASIC/ABN
            const entityTypeCode = r?.result?.entityType.entityTypeCode;
            const entityTypeDescription: any = r?.result?.entityType.entityDescription;
            if (entityTypeCode !== undefined) {
                if (entityTypeCode === 'IND') {
                    this.newSupplierEntityType = 'sole-trader'
                } else {
                    this.newSupplierEntityType = getOrgTypeByEntityText(entityTypeDescription)
                }
            }
        } else { // else we can only guess through organisation name, default to 'Other'
            this.newSupplierEntityType = getOrgTypeByEntityText(r?.organisationName ?? '');
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);
  }

  doWriteValue(v: PpsrAssetSupplierSearchValue | null | undefined): void | PpsrAssetSupplierSearchValue {
    if (v) {
      console.log('***** ppsr-asset-search', v);
      setTimeout(() => {
        if (this.autocompleteTrigger) {
          const options = this.autocompleteTrigger.autocomplete.options.toArray();
          const optionFound = options.find((o) => {
            const _v: AssetSupplier = o.value;
            return (_v && v.ABN === _v.ABN);
          });
          if (optionFound) {
            this.formControlSearch.setValue(optionFound.value);
          } else {
            this.formControlSearch.setValue(v);
          }
          this.propagateChange(v);
        }
      }, 1000);
      this.formControlSearch.setValue(v.ABN);
      this.formControlAbn.setValue(v.ABN);
      this.formControlContact.setValue(v.Contact);
      this.formControlSupplierId.setValue(v.SalesForceId)
    }
    return undefined;
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    const selected: PpsrAssetSupplierSearchValue = $event.option.value;
    console.log("Selected::", selected)
    if (selected) {
      this.formControlSearch.setValue(selected);
      this.supplierName = selected.SupplierName;
      this.supplierAddress = selected.SupplierAddress;
      this.formControlAbn.setValue(selected.ABN);
      this.formControlContact.setValue(selected.Contact);
      this.formControlSupplierId.setValue(selected.SalesForceId)
      this.propagateChange(selected);
    }
  }


  submitForAccreditation() {
    // NOTE: upon submission formGroupNewSupplier will be valid, hence all formControl in it with validators should be too
    const e: PpsrAssetSupplierSearchEvent = {
      abn: this.formControlNewSupplierAbn.value ?? '',
      address: this.formControlNewSupplierAddress.value,
      supplierType: this.formControlNewSupplierType.value ?? '',
      contact: this.formControlNewSupplierContact.value ?? '',
      supplierName: this.formControlNewSupplierName.value ? this.formControlNewSupplierName.value.organisationName : '',
      type: 'submitForAccreditation',
    };
    let supplier: any = {}
    const supplierName: any = e.supplierName
    console.log(this.newSupplierEntityType)
    supplier.businessAddress = e.address;
    supplier.abn = e.abn
    supplier.Products = { "assetFinance": true, "tradeFinance": false, "invoiceFinance": false, "businessLoan": false, "insurancePremium": false };
    supplier.entityType = toEntityType(this.newSupplierEntityType)
    supplier.natureofBusiness = 'supplier'
    supplier.entityName = supplierName.organisationName
    supplier.officeNumber = e.contact
    supplier.companyName = supplierName.organisationName
    supplier.heldAccreditationBefore = false
    supplier.ispartOfgroupOrAggregator = false
    supplier.hasAnotherName = false
    supplier.hasAustralianCreditLicence = false
    supplier.declaredBankrupt = false
    supplier.refusedAccreditation = false
    supplier.convictedOfFraud = false
    // this.ppsrService.saveSupplier(supplier).pipe(tap(r => {
    // })).subscribe()
    this.subscriptions.push(this.saveSupplierFn(supplier).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`Accreditation submitted`),
      tap(r => {
        // this.formControlSearch.setValue(this.formControlNewSupplierName.value)
        // this.formControlContact.setValue(this.formControlNewSupplierContact.value)
        // this.formControlAbn.setValue(this.formControlNewSupplierAbn.value)
        // this.formControlSupplierId.setValue(r.Oppid)
        const data: PpsrAssetSupplierSearchValue = {
          SalesForceId: (r as any).Oppid as string,
          SupplierName: (this.formControlNewSupplierName.value ? this.formControlNewSupplierName.value.organisationName : ''),
          ABN: this.formControlAbn.value as string,
          SupplierAddress: fromAddress2ComponentValueToRawAddress(this.formControlNewSupplierAddress.value)!,
          Contact: this.formControlNewSupplierContact.value ?? '',
          AssetSupplierId: 0,
          SupplierType: this.formControlNewSupplierType.value ?? '',
          isDeleted: false,
          isAccredited: false,
          isPrivateSeller:false,
          FinancialInstitution: '',
          BankAccountName: '',
          BankAccountBsb: '',
          BankAccountNumber: '',
        }
        this.events.next(e);
        this.propagateChange(data)
      })
    ).subscribe());
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  mark() {
    this.formGroupNewSupplier.markAllAsTouched();
    this.markObservable.next(true);

  }
}
