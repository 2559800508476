import {Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output, Self} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl, Validators, FormBuilder, FormGroup, FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  getEntityType,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, getAddress2ComponentValueFormControlValueFn} from '@portal-workspace/grow-ui-library';
import {
  BusinessNumberSearchResult,
  BusinessNumberSearchValue,
  compareMatch,
  EntityTypeCompanyOption,
  EntityTypes,
  EntityTypeValue
} from '@portal-workspace/grow-shared-library';
import { PersonalAssetComponentEvent, PersonalAssetsComponent } from './personal-assets.component';
import { PersonalLiabilitiesComponentEvent, PersonalLiabilitiesComponent } from './personal-liabilities.component';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  IndividualOrEntity
} from '@portal-workspace/grow-shared-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {EntityTrustee, IndividualTrustee, TrusteeValue} from '@portal-workspace/grow-shared-library';
import { BusinessNumberSearchFn, BusinessNumberSearchComponent } from '../business-number-search-component/business-number-search.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EntityTypeComponent } from '../entity-type-component/entity-type.component';
import { MatButtonModule } from '@angular/material/button';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { PropertyOwnerWithAddressComponent } from '../property-owner-with-address-component/property-owner-with-address.component';
import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { AcnComponent } from '../acn-component/acn.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { JsonPipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';


export interface TrusteeComponentEvent {
  valid: boolean;
  entries: (IndividualTrustee | EntityTrustee)[];
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'trustee',
    templateUrl: './trustee.component.html',
    styleUrls: ['./trustee.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TrusteeComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => TrusteeComponent) },
    ],
    standalone: true,
    imports: [MatCheckboxModule, MatButtonToggleModule, FormsModule, ReactiveFormsModule, TitleSelectionComponent, MarkDirective, NameComponent, AcnComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, YesNoComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, MatButtonModule, PersonalAssetsComponent, PersonalLiabilitiesComponent, BusinessNumberSearchComponent, EntityTypeComponent, MatFormFieldModule, JsonPipe]
})
export class TrusteeComponent extends AbstractControlValueAccessor<TrusteeValue> implements OnInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();

  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  formGroup: FormGroup;
  formArray: FormArray;

  subscription?: Subscription;
  subscriptions: Subscription[] = [];

  disableControls:boolean = true;
  allowAddingTrustee:boolean = false;
  entityTypeReadonly = true;

  // @Input({required: false}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: false}) showAssetsAndLiabilities = true;
  @Input({required: false}) showAddressForm = true;
  @Output() events: EventEmitter<TrusteeComponentEvent>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.events = new EventEmitter<TrusteeComponentEvent>();
    this.formArray = formBuilder.array([]);
    this.formGroup = formBuilder.group({
      trustees: this.formArray,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscription = this.formArray.valueChanges.pipe(
      delay(10),
      distinctUntilChanged(compareMatch),
      tap(array => {
        if (!this.formArray.valid) {
          this.propagateChange(null);
        } else {
          const trustees = ((this.formArray.value ?? []).map((v:any) => {
            if (v.type === 'Individual') {
              return v;
            }
            else { // Entity
              if (!v.manualEntry && !v.organisation?.name) { // most probably invalid
                return null;
              }
              // this.propagateChange(this.formArray.value);
              const acn = v.manualEntry ? v.stepacn : v.organisation?.acn;
              const name = v.manualEntry ? v.businessName : v.organisation?.name;
              const abn = v.manualEntry ? '' : v.organisation?.abn;
              const e: EntityTrustee = {
                kind: 'Trustee',
                type: 'Entity',
                manualEntry: v.manualEntry,
                organisation: {
                  acn,
                  abn,
                  name,
                  displayName: name,
                },
                organisationType: v.organisationType,
                abn: abn,
                acn: acn,
              }
              return e;
            }
          }).filter((v:any) => !!v));
          this.propagateChange(trustees.length ? trustees : null);
        }
        this.events.emit({valid: this.formArray.valid, entries: this.formArray.value})
      })
    ).subscribe();
  }


  trusteeFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  trusteeFormGroupFormControl(trusteeFormGroup: FormGroup, controlName: string) {
    return trusteeFormGroup.controls[controlName] as FormControl;
  }

  onPersonalAssetEvent($event: PersonalAssetComponentEvent, trusteeFormGroup: FormGroup) {
    if ($event.entries && $event.entries.length) { // there are assets entries
      const formControl = this.trusteeFormGroupFormControl(trusteeFormGroup, 'personalAssets');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.trusteeFormGroupFormControl(trusteeFormGroup, 'personalAssets');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }

  onPersonalLiabilitiesEvent($event: PersonalLiabilitiesComponentEvent, trusteeFormGroup: FormGroup) {
    if ($event.entries && $event.entries.length) {
      const formControl = this.trusteeFormGroupFormControl(trusteeFormGroup, 'personalLiabilities');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.trusteeFormGroupFormControl(trusteeFormGroup, 'personalLiabilities');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }

  removeTrustee(trusteeFormGroup: FormGroup) {
    const index = this.formArray.controls.indexOf(trusteeFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    this.events.emit({valid: this.formArray.valid, entries: this.formArray.value});
  }

  addTrustee(g?: Exclude<TrusteeValue, null>[number]) {
    const formControlKind = this.formBuilder.control('Trustee');
    const formControlIndividualOrEntity = this.formBuilder.control<IndividualOrEntity>('Entity', [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(null);
    const formControlPersonalLiabilities = this.formBuilder.control(null);
    const formGroup = this.formBuilder.group({
      kind: formControlKind,
      type: formControlIndividualOrEntity,
      personalAssets: formControlPersonalAssets,
      personalLiabilities: formControlPersonalLiabilities,
    });
    if (g) {
      switch(g.type) {
        case 'Individual': {
          (formGroup.controls as any).type.setValue('Individual');
          this.switchToIndividualTrustee(formGroup, g);
          break;
        }
        case 'Entity': {
          (formGroup.controls as any).type.setValue('Entity');
          this.switchToEntityTrustee(formGroup, g);
          break;
        }
      }
    } else {
      this.switchToEntityTrustee(formGroup, g);
    }
    this.formArray.push(formGroup);

    this.subscriptions.push(formControlIndividualOrEntity.valueChanges.pipe(
      delay(0),
      tap((r: IndividualOrEntity|null) => {
        switch (r) {
          case 'Individual': {
            this.switchToIndividualTrustee(formGroup);
            break;
          }
          case 'Entity': {
            this.switchToEntityTrustee(formGroup);
            break;
          }
        }
      })
    ).subscribe());

    this.events.emit({valid: this.formArray.valid, entries: this.formArray.value});
  }

  doWriteValue(v: TrusteeValue | null | undefined): void | TrusteeValue {
    if (v) {
      for (const o of v) {
        this.addTrustee(o);
      }
    } else {
      this.addTrustee();
    }
    return undefined;
  }


  private switchToIndividualTrustee(formGroup: FormGroup, trustee?: IndividualTrustee) {
    const formControlTitle = this.formBuilder.control(trustee ? trustee.title : null, [Validators.required]);
    const formControlFirstName = this.formBuilder.control(trustee ? trustee.firstName : null, [Validators.required]);
    const formControlLastName = this.formBuilder.control(trustee ? trustee.lastName : null, [Validators.required]);
    const formControlMiddleName = this.formBuilder.control(trustee ? trustee.middleName : null);
    const formControlGender = this.formBuilder.control(trustee ? trustee.gender : 'Male', [Validators.required]);
    const formControlDob = this.formBuilder.control(trustee ? trustee.dob : null, [Validators.required]);
    const formControlResidentialAddress = this.formBuilder.control(trustee ? trustee.residentialAddress : null, [Validators.required]);
    const formControlPrivacyConsentObtained = this.formBuilder.control(trustee ? trustee.privacyConsentObtained : null, [Validators.required]);
    const formControlPropertyOwner = this.formBuilder.control(trustee ? trustee.propertyOwner : {propertyOwner: false}, [Validators.required]);
    const formControlGuarantor = this.formBuilder.control(trustee ? trustee.guarantor : null, [Validators.required]);
    const formControlEmail = this.formBuilder.control(trustee ? trustee.email : null, [Validators.required, Validators.required]);
    const formControlMobileNumber = this.formBuilder.control(trustee ? trustee.mobileNumber : null, [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(trustee ? trustee.personalAssets : null);
    const formControlPersonalLiabilities = this.formBuilder.control(trustee ? trustee.personalLiabilities : null);

    formGroup.removeControl('organisation');
    formGroup.removeControl('organisationType');
    // formGroup.removeControl('acn');
    // formGroup.removeControl('abn');
    formGroup.removeControl('manualEntry');
    formGroup.removeControl('stepacn');
    formGroup.removeControl('businessName');



    formGroup.addControl('title', formControlTitle);
    formGroup.addControl('firstName', formControlFirstName);
    formGroup.addControl('lastName', formControlLastName);
    formGroup.addControl('middleName', formControlMiddleName);
    formGroup.addControl('gender', formControlGender);
    formGroup.addControl('dob', formControlDob);
    formGroup.addControl('residentialAddress', formControlResidentialAddress);
    formGroup.addControl('privacyConsentObtained', formControlPrivacyConsentObtained);
    formGroup.addControl('propertyOwner', formControlPropertyOwner);
    formGroup.addControl('guarantor', formControlGuarantor);
    formGroup.addControl('email', formControlEmail);
    formGroup.addControl('mobileNumber', formControlMobileNumber);
    formGroup.addControl('personalAssets', formControlPersonalAssets);
    formGroup.addControl('personalLiabilities', formControlPersonalLiabilities);
    this.allowAddingTrustee=true;
  }



  private switchToEntityTrustee(formGroup: FormGroup, trustee?: EntityTrustee) {
    const manualEntry = trustee ? trustee.manualEntry : false;

    const formControlOrganisation = this.formBuilder.control(!manualEntry ? trustee?.organisation ?? null : null, manualEntry ? [] : [Validators.required]);
    const formControlOrganisationType = this.formBuilder.control(!manualEntry ? trustee?.organisationType ?? EntityTypeCompanyOption : EntityTypeCompanyOption, manualEntry ? [] : [Validators.required] ); // entity-type-selection
    // const formControlAcn = this.formBuilder.control(!manualEntry ? trustee?.acn ?? null : null);
    // const formControlAbn = this.formBuilder.control(!manualEntry ? trustee?.abn ?? null : null);

    const formControlBusinessName = this.formBuilder.control(manualEntry ? trustee?.organisation?.name ?? null : null, manualEntry ? [Validators.required] : []);
    const formControlStepAcn = this.formBuilder.control(manualEntry ? trustee?.acn ?? null : null, manualEntry ? [Validators.required] : []);

    const formControlStepAllowManual = this.formBuilder.control<boolean | null>(manualEntry);



    formGroup.removeControl('title');
    formGroup.removeControl('firstName');
    formGroup.removeControl('lastName');
    formGroup.removeControl('middleName');
    formGroup.removeControl('gender');
    formGroup.removeControl('dob');
    formGroup.removeControl('residentialAddress');
    formGroup.removeControl('privacyConsentObtained');
    formGroup.removeControl('propertyOwner');
    formGroup.removeControl('guarantor');
    formGroup.removeControl('email');
    formGroup.removeControl('mobileNumber');
    formGroup.removeControl('personalAssets');
    formGroup.removeControl('personalLiabilities');

    formGroup.addControl('organisation', formControlOrganisation);
    formGroup.addControl('organisationType', formControlOrganisationType);
    // formGroup.addControl('acn', formControlAcn);
    // formGroup.addControl('abn', formControlAbn);
    formGroup.addControl('manualEntry',formControlStepAllowManual);
    formGroup.addControl('stepacn',formControlStepAcn);
    formGroup.addControl('businessName',formControlBusinessName);
    this.allowAddingTrustee=false;


    this.subscriptions.push(formControlOrganisation.valueChanges.pipe(
      tap((r: BusinessNumberSearchValue) => {
        if (r) {
          // const abn = r.abn;
          // let acn = r.acn;
          // if (acn) {
          //   // add leading zeros if less than 9 digits
          //   acn = 9 - acn.length > 0 ? '0'.repeat(9 - acn.length) + acn : acn;
          //   formControlAcn.setValue(acn);
          // } else {
          //   formControlAcn.setValue('');
          // }
          // if (abn) {
          //   formControlAbn.setValue(abn);
          // } else {
          //   formControlAbn.setValue('');
          // }
          if (r.result) {
            const entityType = getEntityType(r.result)
            formControlOrganisationType.setValue(entityType)
            if (entityType?.type == 'other') {
              this.entityTypeReadonly = false
            } else {
              this.entityTypeReadonly = true
            }
          } else {
            this.entityTypeReadonly = !!formControlOrganisationType.value
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(formControlStepAllowManual.valueChanges.pipe(
      delay(0),
      tap((r: any) => {
        if (r) {
          formControlStepAcn.setValidators(Validators.required)
          formControlBusinessName.setValidators(Validators.required)
          formControlOrganisation.clearValidators();
          formControlOrganisation.setValue(null);
        }
        else{
          formControlStepAcn.clearValidators();
          formControlStepAcn.setValue(null);
          formControlBusinessName.clearValidators();
          formControlBusinessName.setValue(null);
          formControlOrganisation.setValidators(Validators.required)
          formControlOrganisation.updateValueAndValidity();
        }
        // formGroup.updateValueAndValidity();
        this.formArray.updateValueAndValidity();
      })
    ).subscribe());

    this.formArray.updateValueAndValidity();
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}

