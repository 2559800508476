import {Component, forwardRef, inject, OnInit} from "@angular/core";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {EditableNumberValue} from "@portal-workspace/grow-shared-library";
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from "@angular/forms";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {Mark, MARK} from "@portal-workspace/grow-ui-library/mark";
import {MatInputModule} from "@angular/material/input";
import {InputMaskModule} from "@ngneat/input-mask";
import {
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
} from "@portal-workspace/grow-ui-library";
import numeral from "numeral";
import {Mode} from "@ngneat/edit-in-place/lib/mode";
import {EditableComponent, EditableSaveDirective, EditModeDirective, ViewModeDirective} from "@ngneat/edit-in-place";

@Component({
  selector: 'editable-number',
  templateUrl: './editable-number.component.html',
  styleUrls: ['./editable-number.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    JsonPipe,
    AsyncPipe,
    MatTooltipModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    InputMaskModule,
    EditableComponent,
    EditableSaveDirective,
    EditModeDirective,
    ViewModeDirective,
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=> EditableNumberComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(()=> EditableNumberComponent), multi: true},
  ]
})
export class EditableNumberComponent extends AbstractControlValueAccessor<EditableNumberValue> implements OnInit, Mark {

  mode: Mode = 'view';

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  readValue: EditableNumberValue = null;
  formControl!: FormControl<EditableNumberValue>;

  formBuilder: FormBuilder = inject(FormBuilder);

  constructor() {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

  ngOnInit(): void {
  }

  doWriteValue(v?: EditableNumberValue | undefined): void | EditableNumberValue {
    if (v != null || v != undefined) {
      this.readValue = v;
      this.formControl.setValue(v);
    } else {
      this.readValue = null;
      this.formControl.setValue(null);
    }
  }

  onSave() {
    if (this.formControl.valid) {
      this.readValue = this.formControl.value;
      this.propagateChange(numeral(this.readValue).value());
    } else {
      this.propagateChange(null);
    }
  }

  modeChange($event: Mode) {
    this.mode = $event;
  }
}
