import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy , formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {compareMatch, DriverLicenceComponentValue} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import { createDriverLicenceNumberInputMask } from '@portal-workspace/grow-ui-library';
import {DisableControlDirective} from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'driver-licence',
    templateUrl: './driver-licence.component.html',
    styleUrls: ['./driver-licence.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DriverLicenceComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => DriverLicenceComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective]
})
export class DriverLicenceComponent extends AbstractControlValueAccessor<DriverLicenceComponentValue> implements OnInit, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  @Input({required: false}) placeholder = 'Enter driver licence...';
  @Input({required: false}) title = 'Driver Licence';
  formControl!: FormControl<DriverLicenceComponentValue>;
  createDriverLicenceNumberInputMask = createDriverLicenceNumberInputMask();
  @Input({required: false}) mandatory = true;
  formGroup!: FormGroup<{
    driverLicence: FormControl<DriverLicenceComponentValue>
  }>;
  subscription?: Subscription;

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control('', this.mandatory ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      driverLicence: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.formGroup.valid || this.formGroup.disabled) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null)
        }
      })
    ).subscribe();
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  doWriteValue(v: DriverLicenceComponentValue) {
    this.formControl.setValue(v);
  }

  mark() {
    this.formControl.markAllAsTouched();
  }

}
