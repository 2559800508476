import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Moment } from 'moment';
import { CorporateLoanSummary, EntityTypeValue } from '@portal-workspace/grow-shared-library';
import { PrimaryIndustrySelectionValue } from '@portal-workspace/grow-shared-library';
import { SecondaryIndustrySelectionValue } from '@portal-workspace/grow-shared-library';
import { AddressComponentValue } from '@portal-workspace/grow-shared-library';
import { TrusteeValue } from '@portal-workspace/grow-shared-library';
import { MemberValue } from '@portal-workspace/grow-shared-library'
import { PartnerValue } from '@portal-workspace/grow-shared-library';
import { DirectorValue } from '@portal-workspace/grow-shared-library';
import { SoleTraderValue } from '@portal-workspace/grow-shared-library';
import { GuarantorValue } from '@portal-workspace/grow-shared-library';
import { ContactValue } from '@portal-workspace/grow-shared-library';
import { ReferenceValue } from '@portal-workspace/grow-shared-library';
import { booleanToYesNo, displaybleDate, notNullUndefined } from '@portal-workspace/grow-shared-library';
import { categoriesApplicants } from '@portal-workspace/grow-ui-library';
import { BusinessSearchValue } from '@portal-workspace/grow-shared-library';
import { YesNoValue } from '@portal-workspace/grow-shared-library';
import { ReferencesSummaryComponent } from './references-summary.component';
import { PrimaryContactSummaryComponent } from './primary-contact-summary.component';
import { GuarantorsSummaryComponent } from './guarantors-summary.component';
import { SoletradersSummaryComponent } from './soletraders-summary.component';
import { MembersSummaryComponent } from './members-summary.component';
import { PartnersSummaryComponent } from './partners-summary.component';
import { TrusteesSummaryComponent } from './trustees-summary.component';
import { DirectorsSummaryComponent } from './directors-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from './cell.component';




@Component({
    selector: 'corporate-loan-summary',
    templateUrl: './corporate-loan-summary.component.html',
    styleUrls: ['./corporate-loan-summary.component.scss'],
    standalone: true,
    imports: [CellComponent, MatDividerModule, DirectorsSummaryComponent, TrusteesSummaryComponent, PartnersSummaryComponent, MembersSummaryComponent, SoletradersSummaryComponent, GuarantorsSummaryComponent, PrimaryContactSummaryComponent, ReferencesSummaryComponent]
})
export class CorporateLoanSummaryComponent implements OnInit, OnChanges {

  booleanToYesNo = booleanToYesNo;
  displayableDate = displaybleDate;
  notNullUndefined = notNullUndefined;

  @Input({required: true}) summary!: CorporateLoanSummary;
  _trustee?: Exclude<TrusteeValue, null>;
  _members?: Exclude<MemberValue, null>;
  _soleTraders?: (Exclude<SoleTraderValue, null>)[];
  _directors?: Exclude<DirectorValue, null>;
  _partners?: Exclude<PartnerValue, null>;

  ngOnInit(): void {
  }
  ngOnChanges() {
    if (this.summary && this.summary.applicants) {
      const r = categoriesApplicants(this.summary.applicants);
      this._directors = r.directors.length ? r.directors : undefined;
      this._trustee = r.trustees.length ? r.trustees : undefined;
      this._members = r.members.length ? r.members : undefined;
      this._partners = r.partners.length ? r.partners : undefined;
      this._soleTraders = r.soletraders.length ? r.soletraders : undefined;
    }
  }
}
