<div class="postcode-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <input matInput
           type="text"
           [inputMask]="createPostcodeInputMask"
           [formControl]="formControl"
           [disableControl]="disabled">
    @if (hint) {
      <mat-hint>{{hint}}</mat-hint>
    }
    @for(errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if(formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
  </mat-form-field>
</div>
