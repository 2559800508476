import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  BPayPayment,
  BPayTransactionDialogData, BPayTransactionDialogResult
} from '@portal-workspace/grow-shared-library';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';

@Component({
  templateUrl: './bpay-transaction.dialog.html',
  styleUrls: ['./bpay-transaction.dialog.scss'],
  standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatOptionModule, MatDialogModule]
})
export class BPayTransactionDialog {
  formControlDate!: FormControl<string | null>;
  formControlAmount!: FormControl<number | null>;
  formControlBillerCode!: FormControl<string | null>;
  formControlCrn!: FormControl<string | null>;
  formControlReference!: FormControl<string | null>;
  formGroup!: FormGroup;
  bpay: BPayPayment | undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: BPayTransactionDialogData,
              private dialogRef: MatDialogRef<BPayTransactionDialog, BPayTransactionDialogResult>,
              private formBuilder: FormBuilder) {
    this.bpay = data?.bpay;
    this.formControlDate = this.formBuilder.control(this.bpay?.date ?? null, [Validators.required]);
    this.formControlAmount = this.formBuilder.control(this.bpay?.amount ?? null, [Validators.required]);
    this.formControlBillerCode = this.formBuilder.control(this.bpay?.billerCode ?? null, [Validators.required]);
    this.formControlCrn = this.formBuilder.control(this.bpay?.crn ?? null, [Validators.required]);
    this.formControlReference = this.formBuilder.control(this.bpay?.reference ?? null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      date: this.formControlDate,
      amount: this.formControlAmount,
      billerCode: this.formControlBillerCode,
      crn: this.formControlCrn,
      reference: this.formControlReference,
    })
  }

  onSubmit(confirmation: boolean) {
    const bpayValue = this.bpay ? {
        id: this.bpay?.id ?? 0, // with id - edit; id == 0, new
        ...this.formGroup.value
      } : this.formGroup.value;
    this.dialogRef.close({
      readyForSubmission: confirmation,
      bpay: bpayValue
    })
  }
}
