export type FacilityEstablishmentType = '0' |
  '0.1' | '0.2' | '0.3' | '0.4' | '0.5' | '0.6' | '0.7' | '0.8' | '0.9' | '1.0' | '1.1' | '1.2' | '1.3' | '1.4' | '1.5' | '1.6' | '1.7' | '1.8' | '1.9' | '2.0' |
  '2.1' | '2.2' | '2.3' | '2.4' | '2.5' | '2.6' | '2.7' | '2.8' | '2.9' | '3.0' | '3.1' | '3.2' | '3.3' | '3.4' | '3.5' | '3.6' | '3.7' | '3.8' | '3.9' | '4.0' |
  '4.1' | '4.2' | '4.3' | '4.4' | '4.5' | '4.6' | '4.7' | '4.8' | '4.9' | '5.0';
export type FacilityEstablishmentValue = {
  type: FacilityEstablishmentType,
  name: string;
} | null;


export const FacilityEstablishmentValueOptions: Exclude<FacilityEstablishmentValue, null>[] = [
  {type: '0', name: '0%'},
  {type: '0.1', name: '0.10%'},
  {type: '0.2', name: '0.20%'},
  {type: '0.3', name: '0.30%'},
  {type: '0.4', name: '0.40%'},
  {type: '0.5', name: '0.50%'},
  {type: '0.6', name: '0.60%'},
  {type: '0.7', name: '0.70%'},
  {type: '0.8', name: '0.80%'},
  {type: '0.9', name: '0.90%'},
  {type: '1.0', name: '1.00%'},
  {type: '1.1', name: '1.10%'},
  {type: '1.2', name: '1.20%'},
  {type: '1.3', name: '1.30%'},
  {type: '1.4', name: '1.40%'},
  {type: '1.5', name: '1.50%'},
  {type: '1.6', name: '1.60%'},
  {type: '1.7', name: '1.70%'},
  {type: '1.8', name: '1.80%'},
  {type: '1.9', name: '1.90%'},
  {type: '2.0', name: '2.00%'},
  {type: '2.1', name: '2.10%'},
  {type: '2.2', name: '2.20%'},
  {type: '2.3', name: '2.30%'},
  {type: '2.4', name: '2.40%'},
  {type: '2.5', name: '2.50%'},
  {type: '2.6', name: '2.60%'},
  {type: '2.7', name: '2.70%'},
  {type: '2.8', name: '2.80%'},
  {type: '2.9', name: '2.90%'},
  {type: '3.0', name: '3.00%'},
  {type: '3.1', name: '3.10%'},
  {type: '3.2', name: '3.20%'},
  {type: '3.3', name: '3.30%'},
  {type: '3.4', name: '3.40%'},
  {type: '3.5', name: '3.50%'},
  {type: '3.6', name: '3.60%'},
  {type: '3.7', name: '3.70%'},
  {type: '3.8', name: '3.80%'},
  {type: '3.9', name: '3.90%'},
  {type: '4.0', name: '4.00%'},
  {type: '4.1', name: '4.10%'},
  {type: '4.2', name: '4.20%'},
  {type: '4.3', name: '4.30%'},
  {type: '4.4', name: '4.40%'},
  {type: '4.5', name: '4.50%'},
  {type: '4.6', name: '4.60%'},
  {type: '4.7', name: '4.70%'},
  {type: '4.8', name: '4.80%'},
  {type: '4.9', name: '4.90%'},
  {type: '5.0', name: '5.00%'},
];
