<div class="range-slider-component">
  <div fxLayout="column" fxLayoutGap="30px" [ngClass]="{'show-permanent-tick': showPermanentTick}">
    @if (title) {
      <div>{{title}} <span class="amount-label">: {{formatLabel(formControlStart.value)}} - {{formatLabel(formControlEnd.value)}}</span></div>
    }
    <div>
      <mat-slider
        class="w-100"
        [disabled]="disabled"
        color="primary"
        [max]="max"
        [min]="min"
        [step]="step"
        [showTickMarks]="false"
        [discrete]="true"
        [displayWith]="formatLabel">
        <input matSliderStartThumb [formControl]="formControlStart"/>
        <input matSliderEndThumb [formControl]="formControlEnd"/>
      </mat-slider>
    </div>
  </div>
</div>
