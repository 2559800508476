import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output,SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALUE_ACCESSOR, Validators, FormGroup, FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule,ValidationErrors,ValidatorFn } from '@angular/forms';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {
  compareMatch,
  DatepickerValue,
  EmailComponentValue,
  MobileValue,
  NameComponentValue,
  TitleSelectionValue
} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {GenderValue,
  NotNullable
} from '@portal-workspace/grow-shared-library';
import {YesNoValue} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy, getAddress2ComponentValueFormControlValueFn,formControlErrorKeys,formControlErrorMessage,
  fieldErrorMessages
} from '@portal-workspace/grow-ui-library';
import {Subject, Subscription} from 'rxjs';
import { PersonalAssetComponentEvent, PersonalAssetsComponent } from './personal-assets.component';
import { PersonalLiabilitiesComponentEvent, PersonalLiabilitiesComponent } from './personal-liabilities.component';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {PropertyOwnerWithAddressValue} from '@portal-workspace/grow-shared-library';
import {Address2ComponentValue, PersonalAssetsValue, PersonalLiabilitiesValue} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { DirectorValue ,
  IndividualDirector
} from '@portal-workspace/grow-shared-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent,EmailComponentEvent } from '../common fields/email.component';
import { PropertyOwnerWithAddressComponent } from '../property-owner-with-address-component/property-owner-with-address.component';
import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';

import { MessageBoxComponent } from '../message-box/message-box.component';

export interface DirectorComponentEvent {
  valid: boolean,
  entries: DirectorValue;
}

export type DirectorsFormArray = FormArray<DirectorFormGroup>;
export type DirectorFormGroup = FormGroup<{
  kind: FormControl<'Director' | null>,
  type: FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null>,
  title?: FormControl<NotNullable<IndividualDirector>['title'] | null>,
  firstName?: FormControl<NotNullable<IndividualDirector>['firstName'] | null>,
  middleName?: FormControl<NotNullable<IndividualDirector>['middleName'] | null>,
  lastName?: FormControl<NotNullable<IndividualDirector>['lastName'] | null>,
  gender?: FormControl<NotNullable<IndividualDirector>['gender'] | null>,
  dob?: FormControl<NotNullable<IndividualDirector>['dob'] | null>,
  residentialAddress?: FormControl<NotNullable<IndividualDirector>['residentialAddress'] | null>,
  privacyConsentObtained?: FormControl<NotNullable<IndividualDirector>['privacyConsentObtained'] | null>,
  propertyOwner?: FormControl<NotNullable<IndividualDirector>['propertyOwner'] | null>,
  email?: FormControl<NotNullable<IndividualDirector>['email'] | null>,
  guarantor?:FormControl<NotNullable<IndividualDirector>['guarantor'] | null>,
  mobileNumber?: FormControl<NotNullable<IndividualDirector>['mobileNumber'] | null>,
  personalAssets?: FormControl<NotNullable<IndividualDirector>['personalAssets'] | null>,
  personalLiabilities?: FormControl<NotNullable<IndividualDirector>['personalLiabilities'] | null>,
}>

export type PossibleDirectorFormGroupControls =
  FormControl<'Director' | null> |
  FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null> |
  FormControl<TitleSelectionValue> |
  FormControl<NameComponentValue> |
  FormControl<DatepickerValue> |
  FormControl<GenderValue> |
  FormControl<Address2ComponentValue> |
  FormControl<YesNoValue | PropertyOwnerWithAddressValue> |
  FormControl<YesNoValue> |
  FormControl<EmailComponentValue> |
  FormControl<MobileValue> |
  FormControl<PersonalAssetsValue> |
  FormControl<PersonalLiabilitiesValue>;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'director',
    templateUrl: './director.component.html',
    styleUrls: ['./director.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DirectorComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => DirectorComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TitleSelectionComponent, MarkDirective, NameComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, YesNoComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, PersonalAssetsComponent, PersonalLiabilitiesComponent, MatButtonModule, MatFormFieldModule, MessageBoxComponent]
})
export class DirectorComponent extends AbstractControlValueAccessor<DirectorValue> implements OnInit, AfterViewInit, Mark {


  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();

  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  formGroup: FormGroup<{
    directors: DirectorsFormArray,
  }>;
  formArray: DirectorsFormArray;

  @Input({required: false}) withPropertyOwnerAddress = true;
  @Input({required: false}) showAssetsAndLiabilities = true;
  @Input({required: false}) checkDuplicateEmailValidator = true;
  @Input({required: false}) showAddressForm = true;
  @Output() events: EventEmitter<DirectorComponentEvent> = new EventEmitter<DirectorComponentEvent>();

  subscription?: Subscription;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formArray = formBuilder.array<DirectorFormGroup>([]);
    this.formGroup = formBuilder.group({
      directors: this.formArray,
    });
  }



  ngOnInit(): void {
    setupUntilDestroy(this);

    this.subscription = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(array => {
        let directorValue = null;
        if (this.formArray.invalid) {
          this.propagateChange(null);
        } else {
          directorValue = this.formArrayToDirectorValue(this.formArray);
          this.propagateChange(directorValue);
        }
        this.events.emit({valid: this.formArray.valid, entries: directorValue});
      })
    ).subscribe();

    this.formArray.setValidators(this.duplicateDirectorEmailValidator());
  }

  ngAfterViewInit(): void {
    if (this.formArray.length === 0) {
      setTimeout(() => {
        this.addDirector();
      });
    }
  }

  directorFormGroups(): DirectorFormGroup[] {
    return this.formArray.controls;
  }

  directorFormGroupFormControl(formGroup: FormGroup, name: string): PossibleDirectorFormGroupControls {
    return (formGroup.controls[name] as PossibleDirectorFormGroupControls);
  }



  duplicateDirectorEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fa = control as FormArray;

      // all the director form groups that has duplicated email
      const directorsFormGroups = fa.controls.filter((g) => {
        const email = (((g as FormGroup).controls as any).email as FormControl)?.value;

        const allEmails = this.formArray.value?.map((director) => director?.email ?? '').filter((email) => !!email);

        const filteredEmails = allEmails.filter(directorEmail => directorEmail === email);
        if (email && filteredEmails.length > 1) {
          return true;
        } else {
          return false;
        }
      });

      if (directorsFormGroups && directorsFormGroups.length > 0) {
        return {
          duplicateDirectorEmail: {
            details: directorsFormGroups
              .map(ind => `${(ind as any).controls.email.value ?? ''} ${(ind as any).controls.firstName.value ?? ''} ${(ind as any).controls.lastName.value ?? ''}`)
          }
        }
      }
      return null;
    }
  }



  addDirector(d?: Exclude<DirectorValue, null>[number]) {

    const formControlKind = this.formBuilder.control<'Director' | null>('Director');
    const formControlType = this.formBuilder.control<'WithPropertyAddress' | 'WithoutPropertyAddress' | null>(this.withPropertyOwnerAddress ? 'WithPropertyAddress' : 'WithoutPropertyAddress');
    const formGroup = this.formBuilder.group<{
      kind: FormControl<'Director' | null>,
      type: FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null>,
    }>({
      type: formControlType,
      kind: formControlKind,
    });
    this.switchToIndividualDirector(formGroup, d);
    this.formArray.push(formGroup);

    setTimeout(() => this.events.emit({valid: this.formArray.valid, entries: this.formArrayToDirectorValue(this.formArray)}));
  }



  private switchToIndividualDirector(formGroup: DirectorFormGroup, d?: IndividualDirector) {
    const formControlTitle = this.formBuilder.control(d ? d.title : null, [Validators.required]);
    const formControlFirstName = this.formBuilder.control(d ? d.firstName : null, [Validators.required]);
    const formControlMiddleName = this.formBuilder.control(d ? d.middleName : null);
    const formControlLastName = this.formBuilder.control(d ? d.lastName : null, [Validators.required]);

    const formControlGender = this.formBuilder.control(d ? d.gender : 'Male', [Validators.required]);
    const formControlDob = this.formBuilder.control(d ? d.dob : null, [Validators.required]);
    const formControlResidentialAddress = this.formBuilder.control(d ? d.residentialAddress : null, [Validators.required]);
    const formControlPrivacyConsentObtained = this.formBuilder.control(d ? d.privacyConsentObtained : false, [Validators.required]);
    const formControlPropertyOwner =
      this.withPropertyOwnerAddress ?
        this.formBuilder.control(d ? d.propertyOwner : {propertyOwner: false}, [Validators.required]):
      this.formBuilder.control(d ? d.propertyOwner : false, [Validators.required]);
    const formControlEmail = this.formBuilder.control(d ? d.email : null, [Validators.required]);
    const formControlGuarantor = this.formBuilder.control(d ? d.guarantor : null, [Validators.required]);
    const formControlMobileNumber = this.formBuilder.control(d ? d.mobileNumber : null, [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(d ? d.personalAssets : null);
    const formControlPersonalLiabilities = this.formBuilder.control(d ? d.personalLiabilities : null);

    formGroup.addControl('title', formControlTitle);
    formGroup.addControl('firstName', formControlFirstName);
    formGroup.addControl('middleName', formControlMiddleName);
    formGroup.addControl('lastName', formControlLastName);
    formGroup.addControl('guarantor', formControlGuarantor);
    formGroup.addControl('gender', formControlGender);
    formGroup.addControl('dob', formControlDob);
    formGroup.addControl('residentialAddress', formControlResidentialAddress);
    formGroup.addControl('privacyConsentObtained', formControlPrivacyConsentObtained);
    formGroup.addControl('propertyOwner', formControlPropertyOwner);
    formGroup.addControl('email', formControlEmail);
    formGroup.addControl('mobileNumber', formControlMobileNumber);
    formGroup.addControl('personalAssets', formControlPersonalAssets);
    formGroup.addControl('personalLiabilities', formControlPersonalLiabilities);

  }


  deleteDirector(directorFormGroup: DirectorFormGroup) {
    const index = this.formArray.controls.findIndex(fg => fg === directorFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    this.events.emit({valid: this.formArray.valid, entries: this.formArrayToDirectorValue(this.formArray)});
  }

  doWriteValue(v: DirectorValue | null | undefined): void {
    this.formArray.clear();
    if (v) {
      for (const d of v) {
        this.addDirector(d);
      }
    }
    console.log('******************* doWriteValue director', v, this.formArray);
  }

  onDeleteDirector($event: MouseEvent, directorFormGroup: DirectorFormGroup) {
    this.deleteDirector(directorFormGroup);
  }

  onAddDirector($event: MouseEvent) {
    this.addDirector();
  }

  onPersonalLiabilitiesEvent($event: PersonalLiabilitiesComponentEvent, directorFormGroup: DirectorFormGroup) {
    const liabilities = $event.entries;
    const formControl = this.directorFormGroupFormControl(directorFormGroup, 'personalLiabilities');
    if (liabilities && liabilities.length) {
      formControl.setValidators([Validators.required]);
    } else {
      formControl.clearValidators();
    }
    formControl.updateValueAndValidity();
  }

  onPersonalAssetsEvent($event: PersonalAssetComponentEvent, directorFormGroup: DirectorFormGroup) {
    const assets = $event.entries;
    const formControl = this.directorFormGroupFormControl(directorFormGroup, 'personalAssets');
    if (assets && assets.length) {
      formControl.setValidators([Validators.required]);
    } else {
      formControl.clearValidators();
    }
    formControl.updateValueAndValidity();
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  formArrayToDirectorValue(formArray: DirectorsFormArray): NotNullable<DirectorValue> {

      const directorValue = this.formArray.value.map(d => {
        // NOTE: when formArray is valid, peroperties of d should not be null, validators added should ensure that
        const v: Exclude<DirectorValue, null>[number] = {
          kind: "Director" ,
          type: this.withPropertyOwnerAddress ? 'WithPropertyAddress' : 'WithoutPropertyAddress' as any,
          title: d.title!,
          firstName: d.firstName!,
          middleName: d.middleName ?? '',
          lastName: d.lastName!,
          dob: d.dob!,
          gender: d.gender!,
          residentialAddress: d.residentialAddress!,
          privacyConsentObtained: d.privacyConsentObtained!,
          propertyOwner: d.propertyOwner!,
          guarantor: d.guarantor!,
          email: d.email!,
          mobileNumber: d.mobileNumber!,
          personalAssets: d.personalAssets!,
          personalLiabilities: d.personalLiabilities!,
        };
        return v;
      });
      return directorValue;

  }



}


