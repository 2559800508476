<div class="dscr-calculator-component">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Customer Name</mat-label>
            <input matInput type="text" [readonly]="true" [formControl]="formControlCustomerName">
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12">
          <currency-input title="Loan amount" [readonly]="true" [required]="false" [formControl]="formControlLoanAmount">
          </currency-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <dscr-industry-selection [formControl]="formControlIndustry">
          </dscr-industry-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <dscr-industry-ebitda-selection [formControl]="formControlIndustryEbitda">
          </dscr-industry-ebitda-selection>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>% Adjustment (if required -/+)</mat-label>
            <input matInput
              [inputMask]="createThreeDecimalInputMask"
              [formControl]="formControlAdjustmentPercent">
              <span class="mdi mdi-percent" matSuffix></span>
              <!-- <mat-hint>Adjustment %</mat-hint> -->
              @for (errorKey of errorKeys(formControlAdjustmentPercent); track errorKey) {
                <mat-error>
                  @if (formControlAdjustmentPercent.touched && formControlAdjustmentPercent.hasError(errorKey)) {
                    {{errorMessage(formControlAdjustmentPercent, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Margin adopted</mat-label>
              <input matInput
                [inputMask]="createThreeDecimalInputMask"
                [formControl]="formControlMarginAdoptedPrecent">
                <span class="mdi mdi-percent" matSuffix></span>
                <!-- <mat-hint>Margin adopted</mat-hint> -->
                @for (errorKey of errorKeys(formControlMarginAdoptedPrecent); track errorKey) {
                  <mat-error>
                    @if (formControlMarginAdoptedPrecent.touched && formControlMarginAdoptedPrecent.hasError(errorKey)) {
                      {{errorMessage(formControlMarginAdoptedPrecent, errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 3 months" [required]="false" [formControl]="formControlMonthlySalesAverage3Months">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 6 months" [required]="false" [formControl]="formControlMonthlySalesAverage6Months">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Monthly Sales Average - 12 months" [required]="false" [formControl]="formControlMonthlySalesAverage12Months">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Average Monthly Sales Adopted*" [required]="false" [formControl]="formControlAverageMonthlySalesAdopted">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Sales Adjustment (if required -/+)" [required]="false" [formControl]="formControlSalesAdjustment">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Average Monthly Sales Adopted after Adjustment*" [readonly]="true" [required]="false" [formControl]="formControlAverageMonthlySalesAdoptedAfterAdjustment">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Cash Available for Debt Service (Monthly)" [readonly]="true" [required]="false" [formControl]="formControlCashAvailableForDebtServiceMonthly">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Existing Debt Commitments (Monthly)*" [required]="false" [formControl]="formControlExistingDebtCommitmentsMonthly">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="ATO Payment Plan (Monthly)" [required]="false" [formControl]="formControlAtoPaymentPlanMonthly">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Additional commitmetnents if required" [required]="false" [formControl]="formControlAdditionalCommitments">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input [title]="'Proposed Dyna Commitment (Monthly)'" [readonly]="true" [required]="false" [formControl]="formControlProposedCommitmentsMonthly">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Total Financing Commitments (Monthly)" [readonly]="true" [required]="false" [formControl]="formControlTotalFinancingCommitmentsMonthly">
              </currency-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <currency-input title="Debt Service Cover Ratio" [prefix]="false" [readonly]="true" [required]="false" [highlight]="true" [formControl]="formControlDebtServiceCoverRatio">
              </currency-input>
            </div>
            <div class="col-lg-6 col-md-12">
              <currency-input title="Proposed Loan Amount < 1.5x Average Monthly Sales" [readonly]="true" [required]="false" [formControl]="formControlProposedLoanAmount">
              </currency-input>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
