import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AsyncPipe } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service'
import { getUser, openWindowAndDownloadWithFilename, setupUntilDestroy, } from "../component-utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { loadingFor } from "@ngneat/loadoff";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import {
    GetPismoStatementDetailsFn, OverdraftStatementTemplateData, PismoDownloadStatementFn, PismoStatementDetail, STANDARD_MOMENT_DATE_FORMAT, TransactionStatement,
} from "@portal-workspace/grow-shared-library";
import { tap } from "rxjs/operators";
import { MatDividerModule } from "@angular/material/divider";
import moment from 'moment';
import { CdkTableModule } from "@angular/cdk/table";
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";


export class OverdraftAccountStatementDetailTransactionsInternalDataSource implements DataSource<TransactionStatement> {

    subject: BehaviorSubject<TransactionStatement[]> = new BehaviorSubject<TransactionStatement[]>([]);

    connect(collectionViewer: CollectionViewer): Observable<TransactionStatement[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
    }

    update(c: TransactionStatement[]) {
        this.subject.next(c);
    }
}
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'overdraft-account-statement-details',
    standalone: true,
    templateUrl: './overdraft-account-statement-details.component.html',
    styleUrls: ['./overdraft-account-statement-details.component.scss'],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    AsyncPipe,
    MatInputModule,
    ContentLoaderModule,
    MatFormFieldModule,
    MatTableModule,
    MatDividerModule,
    CdkTableModule,
    CustomContentLoaderComponent
],
})
export class OverdraftAccountStatementDetailsComponent implements OnInit {

    loggedInUser = getUser();
    moment = moment;
    STANDARD_MOMENT_DATE_FORMAT = STANDARD_MOMENT_DATE_FORMAT;

    loader = loadingFor('tableLoading');
    subscriptions: Subscription[] = [];

    displayedColumns = ['date', 'card', 'description', 'amount']

    datasource!: OverdraftStatementTemplateData;
    transactionDatasource = new OverdraftAccountStatementDetailTransactionsInternalDataSource();
    statement_period!: string
    canAccess = this.loggedInUser?.priviledges?.includes('admin') || this.loggedInUser?.priviledges?.includes('operations') 
    @Input({ required: true }) pismoAccountNumber!: number;
    @Input({ required: true }) statementId!: number;
    @Input({ required: true }) getPismoStatementDetailsFn!: GetPismoStatementDetailsFn;
    @Input({ required: true }) downloadStatementInCSV!: PismoDownloadStatementFn;
    @Input({ required: true }) downloadStatementInOFX!: PismoDownloadStatementFn;
    @Input({ required: true }) downloadStatementInPDF!: PismoDownloadStatementFn;
    @Output() statementPeriodChange: EventEmitter<string> = new EventEmitter<string>();

    toastService: PortalHotToastService;

    constructor() {
        this.toastService = inject(PortalHotToastService);
    }


    ngOnInit() {
        setupUntilDestroy(this);
        this.reload();
    }

    getColumnTitles(column: string): string {
        switch (column) {
            case 'date': return 'Date';
            case 'card': return 'Card';
            case 'description': return 'Description';
            case 'amount': return 'Amount';
            default: return column;
        }
    }

    reload() {
        this.subscriptions.push(
            this.getPismoStatementDetailsFn(this.pismoAccountNumber, this.statementId)
                .pipe(
                    this.loader.tableLoading.track(),
                    tap(r => {
                        this.datasource = r;
                        console.log('this.datacourse: ', this.datasource);
                        this.transactionDatasource.update(r.transactions);
                        this.statement_period =
                            this.datasource?.stmt?.period ?
                                `${moment(this.datasource?.stmt?.cycleOpeningDate).format(this.STANDARD_MOMENT_DATE_FORMAT )} - 
                                 ${moment(this.datasource?.stmt?.cycleClosingDate).format(this.STANDARD_MOMENT_DATE_FORMAT )}` : '';
                        
                        // Emit the statement_period value
                        this.statementPeriodChange.emit(this.statement_period);
                    })
                ).subscribe());
    }

    downloadInPDF() {
        this.subscriptions.push(this.downloadStatementInPDF(
            this.pismoAccountNumber, this.statementId
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}`, blob);
            })
        ).subscribe());
    }

    downloadInCSV() {
        this.subscriptions.push(this.downloadStatementInCSV(
            this.pismoAccountNumber, this.statementId
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}`, blob);
            })
        ).subscribe());
    }

    downloadInOFX() {
        this.subscriptions.push(this.downloadStatementInOFX(
            this.pismoAccountNumber, this.statementId
        ).pipe(
            this.toastService.loadingWithMessage('Downloading...'),
            tap(blob=> {
              openWindowAndDownloadWithFilename(`Statement-details-${new Date().getTime()}.ofx`, blob);
            })
        ).subscribe());
    }



}
