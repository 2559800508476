import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {SalesforceContactSameAccountSelectionComponent} from './salesforce-contact-same-account-selection.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        SalesforceContactSameAccountSelectionComponent,
    ],
    exports: [
        SalesforceContactSameAccountSelectionComponent,
    ]
})
export class SalesforceContactSameAccountSelectionModule {

}
