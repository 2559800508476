import {RepaymentFrequencyType} from '../../app-calculator';

export interface PaymentChartData {
  totalInterest: number;
  amountFinanced: number;
  emiAmt: number;
  paymentFrequency: RepaymentFrequencyType,
  principalAmt: number;
  interestAmt: number;
  totalAmt: number,
  loanTerm: number; // loanValue from calculator
  lvr: number;
  rv: number,
  brokerageAmount: number;
  docFee: number;
  brokerOriginationFee: number;
  applicationType: String,
  deposit?: number;
  invoiceAmount?: number;
  displayedInterest?: number;
  commission?: number;
  totalEarned?: number;
  totalEarnedInclGst?: number;
  pvOfDifferenceOfRepayments?: number;
  amountToUs?: number;
  comparisionRate?: number;
  monthlyAccountKeepingFee?: number;
  repaymentPlusMonthlyAccountKeepingFee?: number;
}

