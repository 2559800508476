<div class="lender-table">
  <div class="row mb-2">
    <div class="col-lg-6 col-md-12">
      <mat-form-field class="search">
        <input matInput [readonly]="loader.tableLoading.inProgress$ | async" type="text"
          [formControl]="formControlSearch" placeholder="Search lender...">
          <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
    </div>

    <div class="col-lg-6 col-md-12"
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="end center">
      <button class="custom-button w-lt-md-100" (click)="add()">
        <span class="mdi mdi-plus icon"></span>
        <span class="text">Add</span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      @if (loader.tableLoading.inProgress$ | async) {
        <custom-content-loader type="list"></custom-content-loader>
      }
      <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
        <table mat-table [dataSource]="displayedData" class="width-100 mb-2">
          @for (column of columnsToDisplay; track column) {
            <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
            'column-width-1': column === 'lender',
            'column-width-2': column === 'lastUpdatedTime' || column === 'actions',
          }"> 
            <div class="cell">{{getColumnTitles(column)}}</div> </th>
                <td mat-cell *matCellDef="let element">
                  <div class="cell ellipsis" style="max-width: 600px;" matTooltip="{{element[column]}}">
                    @if (column == 'actions') {
                      <div fxLayout="row wrap"
                        fxLayoutAlign="start center"
                        fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="center center"
                        >
                        <span class="mdi mdi-pencil cursor-pointer icon-margin" matTooltip="Edit" (click)="onEdit(element)"></span>
                        <span class="mdi mdi-trash-can-outline cursor-pointer icon-margin" matTooltip="Delete" (click)="onDelete(element)"></span>
                      </div>
                    }
                    @if (column == 'lender') {
                      {{element[column]}}
                    }
                    @if (column == 'lastUpdatedTime') {
                      {{element[column]| date:'dd/MM/yyyy h:mm:ss a'}}
                    }
                  </div>
                </td>
              </ng-container>
            }
    
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
            </tr>
        </table>
        @if (total) {
          <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
          [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
        }
      </div>

    </div>
  </div>
  </div>
