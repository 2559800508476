import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from '../../service/admin.service';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PortalHotToastService, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { Subscription, tap } from 'rxjs';
import { DeleteBsaLenderFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, PromotionResponse, UpdateBsaExcludedLenderFn, UpdateBsaLenderFn, DeleteBsaExcludedLenderFn, AddBsaLenderFn, AddBsaExcludedLenderFn } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ApplicationService } from '../../service/application.service';
import { LenderTableComponent } from '@portal-workspace/grow-ui-library';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './bank-statement-analysis-settings.page.html',
    styleUrls: ['./bank-statement-analysis-settings.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, LenderTableComponent, FlexModule, MatChipsModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class BankStatementAnalysisSettingsPage implements OnInit {

  lenderList: string[] = [];
  excludedLenderList: string[] = [];
  formControlLender!: FormControl<string | null>;
  formControlExcludedLender!: FormControl<string | null>;
  addOnBlur = true;
  subscriptions: Subscription[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  getBsaLenderListFn!: GetBsaLenderListFn;
  getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  updateBsaLenderFn!: UpdateBsaLenderFn;
  updateBsaExcludedLenderFn!: UpdateBsaExcludedLenderFn;
  deleteBsaLenderFn!: DeleteBsaLenderFn;
  deleteBsaExcludedLenderFn!: DeleteBsaExcludedLenderFn
  addBsaLenderFn!: AddBsaLenderFn;
  addBsaExcludedLenderFn!: AddBsaExcludedLenderFn;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private toastService: PortalHotToastService
  ) {
    this.formControlLender = formBuilder.control(null);
    this.formControlExcludedLender = formBuilder.control(null);

    this.getBsaLenderListFn = this.applicationService.getBsaLenderListFn;
    this.getBsaExcludedLenderListFn = this.applicationService.getBsaExcludedLenderListFn;
    this.updateBsaLenderFn = this.applicationService.updateBsaLenderFn;
    this.updateBsaExcludedLenderFn = this.applicationService.updateBsaExcludedLenderFn;
    this.deleteBsaLenderFn = this.applicationService.deleteBsaLenderFn;
    this.deleteBsaExcludedLenderFn = this.applicationService.deleteBsaExcludedLenderFn;
    this.addBsaLenderFn = this.applicationService.addBsaLenderFn;
    this.addBsaExcludedLenderFn = this.applicationService.addBsaExcludedLenderFn;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    // this.subscriptions.push(
    //   this.applicationService.getBsaLenderListFn().pipe(
    //     this.toastService.spinnerObservable()
    //   ).subscribe(lenders => {
    //     this.lenderList = lenders;
    //   })
    // )

    // this.subscriptions.push(
    //   this.applicationService.getBsaExcludedLenderListFn().pipe(
    //     this.toastService.spinnerObservable()
    //   ).subscribe(lenders => {
    //     this.excludedLenderList = lenders;
    //   })
    // )
  }

  // removeLender(lender: string): void {
  //   const index = this.lenderList.indexOf(lender);

  //   if (index >= 0) {
  //     this.lenderList.splice(index, 1);
  //   }
  // }

  // addLender(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();
  //   // Add chip
  //   if (value) {
  //     this.lenderList.push(value);
  //   }
  //   // Clear the input value
  //   event.chipInput!.clear();
  // }

  // removeExcludedLender(lender: string): void {
  //   const index = this.excludedLenderList.indexOf(lender);

  //   if (index >= 0) {
  //     this.excludedLenderList.splice(index, 1);
  //   }
  // }

  // addExcludedLender(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();
  //   // Add chip
  //   if (value) {
  //     this.excludedLenderList.push(value);
  //   }
  //   // Clear the input value
  //   event.chipInput!.clear();
  // }


  // saveLenderList() {
  //   this.subscriptions.push(
  //     this.applicationService.updateBsaLenderList(this.lenderList).pipe(
  //       this.toastService.retryableMessage({
  //         successMessage: 'Lender list saved',
  //         errorMessage: 'Failed to save lender list',
  //         retryFn: () => {
  //           this.saveLenderList();
  //         },
  //       }),
  //       this.toastService.spinnerObservable(),
  //     ).subscribe()
  //   )
  // }

  // saveExcludedLenderList() {
  //   this.subscriptions.push(
  //     this.applicationService.updateBsaExcludedLenderList(this.excludedLenderList).pipe(
  //       this.toastService.retryableMessage({
  //         successMessage: 'Excluded lender list saved',
  //         errorMessage: 'Failed to save excluded lender list',
  //         retryFn: () => {
  //           this.saveExcludedLenderList();
  //         },
  //       }),
  //       this.toastService.spinnerObservable(),
  //     ).subscribe()
  //   )
  // }
}
