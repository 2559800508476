import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  AssetFinanceProductType,
  EditInvoiceAmountDialogData,
  EditInvoiceAmountDialogResult,
  FinanceType,
  minDepositForAssetFinance,
  minDepositForConsumerAssetFinance, minDepositForPricingAssetFinance,
  minDepositForPricingConsumerAssetFinance
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription, tap } from 'rxjs';
import { formControlErrorKeys, formControlErrorMessage, setupUntilDestroy } from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import _ from "lodash";
import { ApplicationDialogService } from './application-dialog.service';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './edit-invoice-amount.dialog.html',
    styleUrls: ['./edit-invoice-amount.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InputMaskModule, NgxCurrencyDirective, LocalBankAccountDetailComponent, MatInputModule, MatFormFieldModule, FlexModule, MatButtonModule, MatDialogModule, CurrencyInputComponent]
})
export class EditInvoiceAmountDialog implements OnInit {

  disableControls = false;
  formGroup: FormGroup<{
    invoiceAmount: FormControl<number | null>;
    depositAmount: FormControl<number | null>;
  }>;
  formControlInvoiceAmount!: FormControl<number | null>;
  formControlDepositAmount!: FormControl<number | null>;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  options = { prefix: '', thousands: ',', decimal: '.', inputMode: NgxCurrencyInputMode.Natural, align: 'left' }

  subscriptions: Subscription[] = [];

  title: string = 'Edit Invoice Amount';
  min!: number;
  // max: number | null = null;
  originalLoanAmount!: number;
  limitMaxInvoiceAmount!: boolean;
  financeType!: FinanceType;
  minDeposit: number | null = null;
  maxDeposit: number | null = null;
  isConsumer: boolean;
  isPropetyOwner: boolean;
  assetCategoryIndex: string;
  productType?: AssetFinanceProductType;
  isCreditOrAdmin: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: EditInvoiceAmountDialogData,
              private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService,
              private dialogRef: MatDialogRef<EditInvoiceAmountDialog, EditInvoiceAmountDialogResult>) {
    this.title = dialogData.title;
    this.min = dialogData.min;
    this.financeType = dialogData.financeType;
    this.originalLoanAmount = dialogData.originalLoanAmount;
    this.limitMaxInvoiceAmount = dialogData.limitMaxInvoiceAmount;
    this.minDeposit = dialogData.minDeposit ?? 0;
    this.maxDeposit = dialogData.maxDeposit;
    this.assetCategoryIndex = dialogData.assetCategoryIndex;
    this.isPropetyOwner = dialogData.isPropetyOwner;
    this.isCreditOrAdmin = dialogData.isCreditOrAdmin;
    this.isConsumer = dialogData.isConsumer;
    this.productType = dialogData.productType;
    this.formControlInvoiceAmount = formBuilder.control(dialogData.invoiceAmount,
      [Validators.required, Validators.min(this.min)]);
    this.formControlDepositAmount = formBuilder.control(dialogData.depositAmount,
      this.maxDeposit  == null ?
      [Validators.required, Validators.min(this.minDeposit)] :
      [Validators.required, Validators.min(this.minDeposit), Validators.max(this.maxDeposit)]);
    this.formGroup = this.formBuilder.group({
      invoiceAmount: this.formControlInvoiceAmount,
      depositAmount: this.formControlDepositAmount,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.subscriptions.push(
      this.formControlInvoiceAmount.valueChanges.pipe(
        tap(_invoiceAmount => {
          const invoiceAmount = _invoiceAmount ?? 0;
          this.maxDeposit = _.round(invoiceAmount - 2000 < 0 ? invoiceAmount : invoiceAmount - 2000, 2); // $2000 minimum loan amount
          this.minDeposit = this.isConsumer ?
            minDepositForPricingConsumerAssetFinance(this.isCreditOrAdmin, invoiceAmount, this.assetCategoryIndex, this.isPropetyOwner) :
            minDepositForPricingAssetFinance(this.isCreditOrAdmin, invoiceAmount, this.assetCategoryIndex, this.isPropetyOwner, this.productType);
          this.formControlDepositAmount.clearValidators();
          this.formControlDepositAmount.setValidators(
            [Validators.required, Validators.min(this.minDeposit ?? 0), Validators.max(this.maxDeposit)]);

          this.formControlDepositAmount.updateValueAndValidity();
        })
      ).subscribe()
    );
  }

  submit() {
    if (this.formGroup.valid) {
      if (this.limitMaxInvoiceAmount) {
        const depositAmount = this.formControlDepositAmount.value ?? 0;
        const invoiceAmount = this.formControlInvoiceAmount.value ?? 0;
        const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
        const deposit = this.financeType === 'rental' ? depositAmountExGST : depositAmount;
        const loanAmount = parseFloat((invoiceAmount - deposit > 0) ? (invoiceAmount - deposit).toFixed(2) : '0');

        if (loanAmount > _.round(1.1 * this.originalLoanAmount)) {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: `Your enterred loan amount ($${loanAmount}) exceed the limitation. Max allowed loan amount: $${_.round(1.1 * this.originalLoanAmount)}`
          }).afterClosed().subscribe()
          return;
        }
      }
      this.dialogRef.close({
        readyForSubmission: true,
        invoiceAmount: this.formControlInvoiceAmount.value as number,
        depositAmount: this.formControlDepositAmount.value as number,
      })
    }

  }

  cancel() {
    this.dialogRef.close();
  }
}
