<div class="yes-no-dialog">
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="content">
        <div class="mat-h3">
          <span class="mdi mdi-alert-circle-outline color-warn"></span>
          {{data.message}}
        </div>
        <div class="mat-body width-100">
          <span [innerHTML]="data.subMessage"></span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
        <button color="primary" (click)="onSubmit(false)" mat-stroked-button>No</button>
        <button color="primary" (click)="onSubmit(true)" mat-flat-button>Yes</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
