<div class="tfa-page container-fluid">
  <div class="mat-h2 text-center">2-Step Verification</div>
  <div class="row text-center">
    <div class="col-lg-12 col-md-7">
      Enter a 6-digit code from your two-factor authentication mobile app
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <form (ngSubmit)="onLogin($event)">
        <mat-form-field>
          <input matInput [formControl]="formControlDigit" maxlength="6" minlength="6" required placeholder="000 000">
        </mat-form-field>
      <button type="submit" style="display: none;"></button> <!-- Hidden button to trigger form submit on Enter -->
      </form>
    </div>
  </div>


  <!-- NOTE: do not allow remember for the time being, we alredy auto refresh the access token
  <div class="row">
    <div class="col">
      <mat-checkbox class="rememberme" color="primary" #rememberMeTooltip="matTooltip"
        matTooltip="Don’t ask again on this device" [formControl]="formControl2faFlag">
        Don’t ask again on this device
      </mat-checkbox>
    </div>
  </div>
  -->
  <div class="row mt-4">
    <div class="col-12 p-0">
      <div class="separator-bottom"
        fxFlex="1 1 auto"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center center"
        fxLayoutAlign.lt-md="center center">
        <div class="mat-body bottom-text icon-hover mr-1 fw-bolder">Got a problem with your Authenticator?</div>
        <a href="#" [routerLink]="navigationUrlForReset2FA()">Reset it now<span class="mdi mdi-arrow-right"></span></a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button mat-flat-button color="primary" (click)="onLogin($event)"
        class="w-100"
        [disabled]="formGroup.invalid || (loader.logon.inProgress$ | async)">
        @if (!(loader.logon.inProgress$ | async)) {
          <span>Sign In</span>
        }
        @if (loader.logon.inProgress$ | async) {
          <span>Signing in ...</span>
        }
      </button>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12"
      fxLayout="column"
      fxLayoutAlign="center center">
      <a (click)="onTopMenuEvent('logout')" class="cursor-pointer">
        <span class="mdi mdi-arrow-left mr-2"></span>Go back to Login
      </a>
    </div>
  </div>
</div>
