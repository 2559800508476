<div class="edit-brokerage-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Edit Brokerage</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <currency-input 
          title="Brokerage Amount" 
          [formControl]="formControlBrokerageAmount"
          (keyup)="onChangeBrokerageAmount($event)"
          [max]="MAX_BROKERAGE_AMOUNT">
        </currency-input>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12">
        OR
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <percentage-input
                title="Brokerage Percentage"
                [formControl]="formControlBrokeragePercentage"
                (keyup)="onChangeBrokeragePercentage($event)"
                [max]="MAX_BROKERAGE_PERCENTAGE">
        </percentage-input>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button color="primary" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" [disabled]="formGroup.invalid" (click)="submit()">Save</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
