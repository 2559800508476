<div class="overdraft-customer-details-component">
  <form [formGroup]="formGroup1">
    <div class="row separator-top">
      <div class="col-lg-6 col-md-12">
        <customer-type [attr.disabled]="true" [formControl]="formControlCustomerType"></customer-type>
      </div>
    </div>

    <div [ngStyle]="{display: formControlCustomerType.value ==='commercial' ? 'block' : 'none'}">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Entity name</mat-label>
            <input matInput type="text" [formControl]="formControlEntityName">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        @if (SalesforceId !== null) {
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Salesforce Id</mat-label>
              <input matInput type="text" disabled [value]="SalesforceId ?? ''">
            </mat-form-field>
          </div>
        }
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Entity type</mat-label>
            <input matInput type="text" [formControl]="formControlEntityType">
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>ABN</mat-label>
            <input matInput [inputMask]="createAbnInputMask" type="text" [formControl]="formControlAbn">
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
          @if (showAcn) {
            <div>
              @if (showAcn) {
                <mat-form-field>
                  <mat-label>ACN</mat-label>
                  <input matInput [inputMask]="createAcnInputMask" type="text" [formControl]="formControlAcn">
                </mat-form-field>
              }
            </div>
          }
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Address</mat-label>
            <input matInput type="text" [formControl]="formControlAddress">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
