import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {clearAllStorage} from '@portal-workspace/grow-ui-library';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    templateUrl: './unactivated.page.html',
    styleUrls: ['./unactivated.page.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule]
})
export class UnactivatedPage implements OnInit {

  constructor(private minimalLayoutService: MinimalLayoutService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.minimalLayoutService.settings({
      showAccreditationInContact: false,
      showContact: true,
      showCross: false,
      showPrev: false,
    });
    clearAllStorage();
  }


  async reLogin($event: MouseEvent) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin());
  }
}
