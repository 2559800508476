import { NgModule } from '@angular/core';
import { AccessLevelModule } from './access-level-component/access-level.module';
import { AnnualRevenueModule } from './annual-revenue-component/annual-revenue.module';
import { AddressModule } from './address-component/address.module';
import { AggregatorSearchModule } from './aggregator-search-component/aggregator-search.module';
import { ToggleModule } from './toggle-component/toggle.module';
import { BankAccountChipModule } from './bank-account-chip-component/bank-account-chip.module';
import { DateChipModule } from './date-chip-component/date-chip.module';
import { CurrencyChipModule } from './currency-chip-component/currency-chip.module';
import { YesNoModule } from './yes-no-component/yes-no.module';
import { UserSelectionModule } from './user-selection-component/user-selection.module';
import { UploadFileModule } from './upload-file-component/upload-file.module';
import { TitleSelectionModule } from './title-selection-component/title-selection.module';
import { TimeInAddressModule } from './time-in-address/time-in-address.module';
import { StateSelectionModule } from './state-selection-component/state-selection.module';
import { SliderModule } from './slider-component/slider.module';
import { SlideToggleModule } from './slide-toggle-component/slide-toggle.module';
import { SignaturePadModule } from './signature-pad-component/signature-pad.module';
import { SelectContactModule } from './select-contact-component/select-contact.module';
import { SalesforceContactSameAccountSelectionModule } from './salesforce-contact-same-account-selection-component/salesforce-contact-same-account-selection.module';
import { RadioOptionsModule } from './radio-options-component/radio-options.module';
import { ProductSelectionModule } from './product-selection-component/product-selection.module';
import { PaymentFrequencySelectionModule } from './payment-frequency-selection-component/payment-frequency-selection.module';
import { NatureOfBusinessModule } from './nature-of-business-component/nature-of-business.module';
import { MaritialStatusSelectionModule } from './maritial-status-selection-component/maritial-status-selection.module';
import { LoanTermsSelectionModule } from './loan-terms-selection-component/loan-terms-selection.module';
import { IncomeModule } from './income-selection-component/income.module';
import { IndustrySelectionModule } from './industry-selection-component/industry-selection.module';
import { InsurerSelectionModule } from './insurer-selection-component/insurer-selection.module';
import { LoanPurposeSelectionModule } from './loan-purpose-selection-component/loan-purpose-selection.module';
import { CurrencySelectionModule } from './currency-selection-component/currency-selection.module';
import { DatepickerModule } from './datepicker-component/datepicker.module';
import { MobileModule } from './mobile-component/mobile.module';
import { CustomerTypeModule } from './customer-type-component/customer-type.module';
import { EmploymentStatusSelectionModule } from './employment-status-selection-component/employment-status-selection.module';
import { FacilityEstablishmentModule } from './facility-establishment-component/facility-establishment.module';
import { FinanceTypeSelectionModule } from './finance-type-component/finance-type-selection.module';
import { GenderModule } from './gender-component/gender.module';
import { NotificationModule } from './notification-component/notification.module';
import { MessageBoxModule } from './message-box/message-box.module';
import { BrokerageSelectionModule } from './brokerage-component/brokerage-selection.module';
import { ConfirmPasswordModule } from './confirm-password-component/confirm-password.module';
import { ConfirmPasswordAdminModule } from './confirm-password-admin-component/confirm-password-admin.module';
import { ProfileModule } from './profile-component/profile.module';
// import {AvatarModule} from './avatar-component/avatar.module';
import { BalloonPaymentModule } from './balloon-payment-component/balloon-payment.module';
import { AssetCatAndTypeModule } from './asset-cat-and-type-component/asset-cat-and-type.module';
import { AssetSupplierSelectModule } from './asset-supplier-select-component/asset-supplier-select.module';
import { BusinessSearchModule } from './business-search-component/business-search.module';
import { EntityTypeModule } from './entity-type-component/entity-type.module';
import { IndividualAndEntityInfoModule } from './individual-and-entity-info-component/individual-and-entity-info.module';
import { PropertyOwnerWithAddressModule } from './property-owner-with-address-component/property-owner-with-address.module';
import { EmployerModule } from './employer-component/employer.module';
import { CompaniesSearchModule } from './company-search-component/companies-search.module';
import { BreadcrumbModule } from './breadcrumb-component/breadcrumb.module';
import { ChartModule } from './chart-component/chart.module';
//import {CustomerBalanceSummaryModule} from './customer-balance-summary-component/customer-balance-summary.module';
import { EligibilityModule } from './eligibility-component/eligibility.module';
import { ReferenceModule } from './reference-component/reference.module';
import { ApplicationDialogModule } from './application-dialog-component/application-dialog.module';
import { AuthorisedSignatoryModule } from './authorised-signatory-component/authorised-signatory.module';
import { SideMenuModule } from './sidemenu-component/side-menu.module';
import { TopMenuModule } from './topmenu-component/top-menu.module';
import { CESNPSmodalModule } from './CES-NPS-modal-component/CES-NPS-modal.module';
import { ApplicationStepperModule } from './application-stepper-component/application-stepper.module';
import { CustomPaginatorModule } from './custom-paginator-component/custom-paginator.module';
import { CustomContentLoaderModule } from './custom-content-loader-component/custom-content-loader.module';
import { RiskAnalysisModule } from './risk-analysis-component/risk-analysis.module';
import { PortalDashboardModule } from './portal-dashboard-component/portal-dashboard.module';
import { RateCardModule } from './rate-card-component/rate-card.module';
import { PpsrStatusModule } from './ppsr-status-component/ppsr-status-chip.module';
import { ApplicationSummaryModule } from './application-summary-component/application-summary.module';
import { ApplicationDetailsModule } from './application-details-component/application-details.module';
import { DigitalIdModule } from './digital-id-component/digital-id.module';
import { PortalHotToastModule } from './portal-hot-toast-component/portal-hot-toast.module';
import { DashboardCardModule } from './dashboard-card/dashboard-card.module';
import { BankStatementModule } from './bank-statement-component/bank-statement.module';
import { EmailModule } from './common fields/email.module';
import { AbnModule } from './abn-component/abn.module';
import { AcnModule } from './acn-component/acn.module';
import { AccountNumberModule } from './account-number-component/account-number.module';
import { NameModule } from './name-component/name.module';
import { AssetListingModule } from './asset-listing-component/asset-listing.module';
import { CollapsableExpandableTextModule } from './collapsable-expandable-component/collapsable-expandable-text.module';
import { ServiceabilityRatioModule } from './serviceability-ratio-component/serviceability-ratio.module';
import { ApplicationTypeIconModule } from './application-type-icon/application-type-icon.module';
import { BusinessNumberSearchModule } from './business-number-search-component/business-number-search.module';
import { ApplicationTilesModule } from './application-tiles-component/application-tiles-component.module';
import { ApplicationTypeAmountModule } from './application-type-amount/application-type-amount.module';
import { BusinessTypeIconModule } from './business-type-icon/business-type-icon.module'
import { AccreditationDetailsModule } from './accreditation-details-component/accreditation-details.module'
import { BpayBankDetailsModule } from './bpay-bank-details-component/bpay-bank-details.module';
import { UserTableModule } from './user-table-component/user-table.module';
import { LocalBankAccountDetailModule } from './local-bank-account-detail-component/local-bank-account-detail.module';
import { OverdraftModule } from './overdraft-customer-components/overdraft-customer.module';
import { BankingModule } from './banking-component/banking.module';
import { CustomAngularEditorModule } from './custom-angular-editor-component/custom-angular-editor.module';
import { UserDetailsModule } from './user-details-component/user-details.module';
import { DscrIndustrySelectionModule } from './dscr-industry-selection-component/dscr-industry-selection.module';
import { EditableComponentModule } from "./editable-component/editable-component.module";
import { ImageModule } from './image-component/image.module';
import { CreditModule } from './credit-component/credit.module';
import { LtvSelectionModule } from "./ltv-component/ltv-selection.module";
import { SecurityTypeSelectionModule } from "./security-type-component/security-type-selection.module";
import { NumberInputModule } from "./number-input-component/number-input.module";
import { PercentageInputModule } from "./percentage-input-component/percentage-input.module";
import { BasiqStatementModule } from './basiq-statement-component/basiq-statement.module';
import { PortalThemeModule } from "./portal-theme-component/portal-theme.module";
import { RepaymentTypeModule } from "./repayment-type-component/repayment-type.module";
const components: any[] = [
];

const componentModules: any[] = [
  AcnModule,
  AccessLevelModule,
  AnnualRevenueModule,
  AddressModule,
  AggregatorSearchModule,
  ToggleModule,
  BankAccountChipModule,
  DashboardCardModule,
  DateChipModule,
  CurrencyChipModule,
  YesNoModule,
  UserSelectionModule,

  UploadFileModule,
  TitleSelectionModule,
  TimeInAddressModule,
  StateSelectionModule,
  SliderModule,
  SlideToggleModule,
  SignaturePadModule,
  SelectContactModule,
  SalesforceContactSameAccountSelectionModule,
  RadioOptionsModule,
  ProductSelectionModule,
  PaymentFrequencySelectionModule,
  NatureOfBusinessModule,
  MaritialStatusSelectionModule,
  LoanTermsSelectionModule,
  IncomeModule,
  IndustrySelectionModule,
  InsurerSelectionModule,
  LoanPurposeSelectionModule,
  CurrencySelectionModule,
  DatepickerModule,
  MobileModule,
  CustomerTypeModule,
  EmploymentStatusSelectionModule,
  FacilityEstablishmentModule,
  FinanceTypeSelectionModule,
  GenderModule,
  NotificationModule,
  MessageBoxModule,
  BrokerageSelectionModule,
  ConfirmPasswordModule,
  ConfirmPasswordAdminModule,
  ProfileModule,
  // AvatarModule,
  BalloonPaymentModule,
  AssetCatAndTypeModule,
  AssetSupplierSelectModule,
  BusinessSearchModule,
  EntityTypeModule,
  IndividualAndEntityInfoModule,
  PropertyOwnerWithAddressModule,
  EmployerModule,
  CompaniesSearchModule,
  BreadcrumbModule,
  ChartModule,

  EligibilityModule,
  ReferenceModule,
  ApplicationDialogModule,
  AuthorisedSignatoryModule,
  TopMenuModule,
  SideMenuModule,
  CESNPSmodalModule,
  ApplicationStepperModule,
  CustomPaginatorModule,
  CustomContentLoaderModule,
  RiskAnalysisModule,
  PortalDashboardModule,
  RateCardModule,
  PpsrStatusModule,
  ApplicationSummaryModule,
  ApplicationDetailsModule,
  DigitalIdModule,
  PortalHotToastModule,
  BankStatementModule,
  EmailModule,
  AbnModule,
  AccountNumberModule,
  NameModule,
  AssetListingModule,
  CollapsableExpandableTextModule,
  ServiceabilityRatioModule,
  ApplicationTypeIconModule,
  ApplicationTypeAmountModule,
  BusinessNumberSearchModule,
  ApplicationTilesModule,
  BusinessTypeIconModule,
  AccreditationDetailsModule,
  BpayBankDetailsModule,
  UserTableModule,
  UserDetailsModule,
  LocalBankAccountDetailModule,
  OverdraftModule,
  CustomAngularEditorModule,
  BankingModule,
  DscrIndustrySelectionModule,
  EditableComponentModule,
  ImageModule,
  LtvSelectionModule,
  SecurityTypeSelectionModule,
  PercentageInputModule,
  NumberInputModule,
  CreditModule,
  BasiqStatementModule,
  PortalThemeModule,
  RepaymentTypeModule,
];

@NgModule({
  imports: [
    ...componentModules,
    ...components,
  ],
  exports: [
    ...components,
    ...componentModules,
  ],
})
export class ComponentLibraryModule { }
