// ellipsis.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'ellipsis',
  standalone: true,
})
export class EllipsisPipe implements PipeTransform {
  private defaultMaxLength: number = 10;

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, maxLength: number = this.defaultMaxLength): string {
    const truncatedValue = value.length > maxLength ? value.substring(0, maxLength) + ' ...' : value;

    return `<span title="${value}">${truncatedValue}</span>`;
  }
}
