import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {CommonModule} from "@angular/common";
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {
  compareMatch,
  GetAllWhitelabelsFn,
  WhitelabelEntity,
  WhitelabelSelectionValue
} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {formControlErrorKeys, formControlErrorMessage, pxToRem, setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'whitelabel-selection',
  templateUrl: './whitelabel-selection.component.html',
  styleUrls: ['./whitelabel-selection.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>WhitelabelSelectionComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(()=>WhitelabelSelectionComponent)},
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    CommonModule,
  ],
})
export class WhitelabelSelectionComponent extends AbstractControlValueAccessor<WhitelabelSelectionValue> implements OnInit, Mark {

    pxToRem = pxToRem;
    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;

    subscriptions: Subscription[] = [];

    entries: WhitelabelEntity[] = [];

    compareWith: (a: WhitelabelSelectionValue, b: WhitelabelSelectionValue) => boolean = (a, b) => {
      return (!!a && !!b && a?.id === b?.id);
    };

    @Input({required: true}) getAllWhitelabelsFn!: GetAllWhitelabelsFn;

    formBuilder = inject(FormBuilder);
    formControl!: FormControl<WhitelabelSelectionValue>;
    formGroup!: FormGroup<{
      whitelabel: FormControl<WhitelabelSelectionValue>
    }>;


    ngOnInit(): void {
      setupUntilDestroy(this);
      this.formControl = this.formBuilder.control(null, []);
      this.formGroup = this.formBuilder.group({
        whitelabel: this.formControl,
      });
      this.subscriptions.push(this.getAllWhitelabelsFn().pipe(
        tap(r => {
          this.entries = r;
        })
      ).subscribe());
      this.subscriptions.push(this.formGroup.valueChanges.pipe(
        delay(0),
        distinctUntilChanged(compareMatch),
        tap(r => {
          if (this.formControl.valid) {
            this.propagateChange(this.formControl.value);
          } else {
            this.propagateChange(null);
          }
        })
      ).subscribe());
    }

    mark(): void {
      this.formGroup.markAllAsTouched();
    }

    override doWriteValue(v?: WhitelabelSelectionValue | undefined): void | WhitelabelSelectionValue {
      this.formControl.setValue(v ?? null);
    }

}
