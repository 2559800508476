<div class="asset-ppsr-details-component">
  @if (vehicleDetails && vehicleDetails.IdentifierNumber !== '') {
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <cell>
            <div label class="title">Additional Motor Vehicle Details · NEVDIS
              <span class="mdi mdi-alert-circle-outline " matSuffix></span>
            </div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Identifier number</div>
            <div body>{{vehicleDetails && vehicleDetails.IdentifierNumber ? vehicleDetails.IdentifierNumber : '--'}}</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Identifier type</div>
            <div body>{{vehicleDetails && vehicleDetails.Identifiertype ? vehicleDetails.Identifiertype : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Vehicle type</div>
            <div body>{{vehicleDetails && vehicleDetails.VehicleType ? vehicleDetails.VehicleType : '--'}}</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Make</div>
            <div body>{{vehicleDetails && vehicleDetails.Make ? vehicleDetails.Make : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
        </div>
        <div class="col-6">
          <cell>
            <div label>Model</div>
            <div body>{{vehicleDetails && vehicleDetails.Model ? vehicleDetails.Model : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Body type</div>
            <div body>{{vehicleDetails && vehicleDetails.BodyType ? vehicleDetails.BodyType : '--'}}</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Engine number</div>
            <div body>{{vehicleDetails && vehicleDetails.EngineNumber ? vehicleDetails.EngineNumber : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Colour</div>
            <div body>{{vehicleDetails && vehicleDetails.Colour ? vehicleDetails.Colour : '--'}}</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>State vehicle registered</div>
            <div body>{{vehicleDetails && vehicleDetails.StateVehicleRegistered ? vehicleDetails.StateVehicleRegistered
            : '--'}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Registration plate number</div>
            <div body>{{vehicleDetails && vehicleDetails.RegistrationPlateNumber ? vehicleDetails.RegistrationPlateNumber
            : '--'}}</div>
          </cell>
        </div>
        <div class="col-6">
          <cell>
            <div label>Year of compliance</div>
            <div body>{{vehicleDetails && vehicleDetails.ComplianceYearMonth ? vehicleDetails.ComplianceYearMonth : '--'}}
            </div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Registration expiry</div>
            <div body>{{vehicleDetails && vehicleDetails.RegistrationExpiry ? vehicleDetails.RegistrationExpiry : '--'}}
            </div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="col-6">
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Year of manufacture</div>
            <div body>{{vehicleDetails && vehicleDetails.ManufactureYear ? vehicleDetails.ManufactureYear : '--'}}</div>
          </cell>
        </div>
      </div>
    </div>
  }

  @if (vehicleDetails && (vehicleDetails.IdentifierNumber === '' || vehicleDetails.IdentifierNumber === null)) {
    <div class="container-fluid">
      <div class="not-found"> No Nevdis data was found here</div>
    </div>
  }
</div>


