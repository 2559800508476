import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import {AppCalculator, TermRate, TotalPaymentBreakupDialogData, TotalPaymentBreakupDialogData2, TotalPaymentBreakupDialogResult} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { TotalPaymentBreakupComponent } from '../chart-component/total-payment-breakup.component';

import { FlexModule } from '@angular/flex-layout/flex';

// export type fun = (date: Moment) => {
//   terms: TermRate,
//   calculator: AppCalculator,
//   amortizationScheduleData: AmortisationScheduleEntry[];
//   amortizationChartData: AmortizationChartData;
//   paymentChartData: PaymentChartData;
//   repaymentEstimationData: RepaymentEstimationEntry[];
// };



@Component({
    templateUrl: './total-payment-breakup.dialog.html',
    styleUrls: ['./total-payment-breakup.dialog.scss'],
    standalone: true,
    imports: [FlexModule, TotalPaymentBreakupComponent, MatButtonModule, MatDialogModule]
})
export class TotalPaymentBreakupDialog implements OnInit {

  data: TotalPaymentBreakupDialogData | null = null;
  isQuote!: boolean; 

  constructor(@Inject(MAT_DIALOG_DATA) public opt: TotalPaymentBreakupDialogData2,
              private dialogRef: MatDialogRef<TotalPaymentBreakupDialog, TotalPaymentBreakupDialogResult>) {
  }

  onAcceptQuotation($event: Event) {
    this.dialogRef.close({acceptedQuotation: true});
  }
  onClose($event: Event){
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.data = this.opt.loadFn(moment());
    this.isQuote = this.opt.isQuote ?? false;
  }

  onDateChange($event: moment.Moment) {
    console.log('***** reload date change', $event);
    this.data = this.opt.loadFn($event);
    console.log('***** reload date change data', this.data);
  }
}
