<div class="private-supplier-form-dialog">
  <mat-dialog-content class= 'max-height-100-vh'>
    <div class="row">
      <div class="col">
        <div class="mat-h3">Add new supplier</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <form>

      <!-- <business-search [placeholder]="businessSearchPlaceholder" [searchFn]="step1SearchFn"></business-search> -->
      <div class="row mg">
        <div class="col">
          <business-search [allowFreeText]="false"  [formControl]="formControlNewSupplierName" [placeholder]="businessSearchPlaceholder" [searchFn]="step1SearchFn"></business-search>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <custom-address [formControl]="formControlNewSupplierAddress"  title="Supplier address" ></custom-address>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row mg">
        <div class="col">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-stroked-button (click)="cancel($event)">Cancel</button>
            <button mat-flat-button color="primary" (click)="submitForAccreditation()" [disabled]="formGroupNewSupplier.invalid">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
