<div class="confirm-password-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-md-11">
        <h3>Change password</h3>
      </div>
      <div class="col-md-1" (click)="cancel($event)">
        <span class="mdi mdi-close"></span>
      </div>
    </div>
    <confirm-password [formControl]="formControlPassword" (allData)="getData($event)"></confirm-password>
    <div class="row">
      <div class="col">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="width-100" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start start">
            <div class="mat-body bottom-text icon-hover"> <a href="#" [routerLink]="navigationForForgotPassword()" (click)="cancel($event)">Forgot password?</a></div>
          </div>
          <!-- <button mat-stroked-button fxLayoutAlign="start center" (click)="cancel($event)">Cancel</button> -->
          <button mat-flat-button [disabled]="formGroup.invalid" fxLayoutAlign="end center" color="primary"  (click)="changePassword($event)"
          >Submit</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
