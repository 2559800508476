import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {IncomeComponent} from './income.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        CurrencySelectionModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        IncomeComponent
    ],
    exports: [
        IncomeComponent,
    ]
})
export class IncomeModule {

}
