import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  BusinessDisbursementPayeeDialogData,
  BusinessDisbursementPayeeDialogResult,
  BusinessNumberSearchValue,
  PpsrAssetSupplierSearchValue,
  YesNoSelectionValue
} from '@portal-workspace/grow-shared-library';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import { BusinessNumberSearchFn, markTriggerSubject, SearchSupplierFn, Supplier2ComponentEvent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { Supplier2Component } from '../asset-listing-component/supplier2.component';

import { YesNoComponent } from '../yes-no-component/yes-no.component';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './business-disbursement-payee.dialog.html',
    styleUrls: ['./business-disbursement-payee.dialog.scss'],
    standalone: true,
    imports: [YesNoComponent, FormsModule, ReactiveFormsModule, Supplier2Component, MarkDirective, FlexModule, MatButtonModule, MatDialogModule]
})
export class BusinessDisbursementPayeeDialog implements OnInit {


  subscriptions: Subscription[] =  [];
  isCustomer: boolean = false;
  customerSfId: string;

  markTriggerSubject = markTriggerSubject;
  searchSupplierFn!: SearchSupplierFn;

  formGroup: FormGroup<{
    supplier: FormControl<PpsrAssetSupplierSearchValue | null>
  }>;
  formControlCreditorOrCustomer: FormControl<YesNoSelectionValue | null>;
  formControlSupplier: FormControl<PpsrAssetSupplierSearchValue | null>

  constructor( @Inject(MAT_DIALOG_DATA) private data: BusinessDisbursementPayeeDialogData,
               private dialogRef: MatDialogRef<BusinessDisbursementPayeeDialog, BusinessDisbursementPayeeDialogResult>,
    private formBuilder: FormBuilder) {
    this.searchSupplierFn = data.searchSupplierFn;
    this.customerSfId = data.customerSfId;
    this.formControlSupplier = formBuilder.control(null, [Validators.required]);
    this.formControlCreditorOrCustomer = formBuilder.control(true, [Validators.required]);
    this.formGroup = formBuilder.group({
      supplier: this.formControlSupplier
    });
    if (data.currentPayee?.supplier) {
      this.formControlSupplier.setValue(data.currentPayee.supplier)
    }
    if (data.currentPayee?.customerSfId) {
      this.formControlCreditorOrCustomer.setValue(false)
      this.isCustomer = true
      this.formControlSupplier.setValidators(null)
      this.formControlSupplier.updateValueAndValidity()
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.formControlCreditorOrCustomer.valueChanges.pipe(
      tap(creditor => {
        if (creditor) {
          this.isCustomer = false;
          this.formControlSupplier.setValidators(Validators.required)
          this.formControlSupplier.updateValueAndValidity()
        } else {
          this.isCustomer = true;
          this.formControlSupplier.setValidators(null)
          this.formControlSupplier.updateValueAndValidity()
        }
      })
    ).subscribe())
  }

  getSupplierData(event: Supplier2ComponentEvent) {
    console.log("Supplier:::", event);
    this.formControlSupplier.setValue(event.supplier);
  }

  onSubmit(isSubmit: boolean) {
    if (isSubmit) {
      if (this.isCustomer) {
        this.dialogRef.close({
          type: 'submit',
          payee: {
            type: 'customer',
            customerSfId: this.customerSfId
          }
        })
      } else {
        this.dialogRef.close({
          type: 'submit',
          payee: {
            type: 'supplier',
            supplier: this.formControlSupplier.value!
          }
        });
      }
      return;
    }
    this.dialogRef.close({
      type: 'cancel',
    });
  }
}
