import {Component, forwardRef, inject, OnInit} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {
  formControlErrorKeys,
  formControlErrorMessage, setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {compareMatch, LtvSelectionValue, LtvSelectionValueOptions} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'ltv-selection',
  templateUrl: './ltv-selection.component.html',
  styleUrls: ['./ltv-selection.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>LtvSelectionComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>LtvSelectionComponent)},
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgClass,
    FlexLayoutModule,
    MatSelectModule,
    JsonPipe,
    AsyncPipe,
    MatOptionModule,
    DisableControlDirective
]
})
export class LtvSelectionComponent extends AbstractControlValueAccessor<LtvSelectionValue> implements Mark, OnInit {

  subscriptions: Subscription[] = [];

  formBuilder = inject(FormBuilder);
  formControl: FormControl<LtvSelectionValue> = this.formBuilder.control(null, [Validators.required]);
  formGroup: FormGroup<{
    ltv: FormControl<LtvSelectionValue>,
  }> = this.formBuilder.group({
    ltv: this.formControl,
  });

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  options = LtvSelectionValueOptions;

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formGroup.valid) {
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  doWriteValue(v: LtvSelectionValue | null | undefined): void | LtvSelectionValue {
    this.formControl.setValue(v ?? null);
    return undefined;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }


}
