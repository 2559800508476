import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {formControlErrorKeys, formControlErrorMessage, pxToRem, setupUntilDestroy} from '@portal-workspace/grow-ui-library';

import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {
  compareMatch,
  Ratecard,
  RateCardListValue,
  WhitelabelSelectionValue
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

export type GetFn = ()=>Observable<Exclude<RateCardListValue, null>[]>;


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'rate-card-list',
    templateUrl: './rate-card-list.component.html',
    styleUrls: ['./rate-card-list.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RateCardListComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class RateCardListComponent extends AbstractControlValueAccessor<RateCardListValue> implements OnInit {

  pxToRem = pxToRem;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  compareWith: (a: RateCardListValue, b: RateCardListValue) => boolean = (a, b) => {
    return (!!a && !!b && a?.RatecardId === b?.RatecardId);
  };


  @Input({required: false}) title = 'Rate cards';
  @Input({required: true}) getFn!: GetFn;

  subscriptions: Subscription[] = [];

  values: Exclude<RateCardListValue, null>[] = [];

  formControl: FormControl<RateCardListValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.getFn().pipe(
      tap(r => {
        this.values = r;
      })).subscribe();
    this.subscriptions.push(sub);
    const sub1 = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })

    ).subscribe();
    this.subscriptions.push(sub1);
  }

  doWriteValue(v: RateCardListValue | null | undefined): void | RateCardListValue {
    this.formControl.setValue(v ?? null);
    // if (v) {
    //   setTimeout(()=> {
    //     const r = this.values.find(opt => opt.RatecardId === v.RatecardId);
    //     if (r) {
    //       this.formControl.setValue(r);
    //     }
    //   }, 5000);
    // }
    // return undefined;
  }
}
