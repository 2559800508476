import { Component, Input, OnInit } from '@angular/core';
import { GrowRiskRating } from '../risk-analysis.component';


@Component({
    selector: 'risk-rating-chart',
    templateUrl: './risk-rating-chart.component.html',
    styleUrls: ['./risk-rating-chart.component.scss'],
    standalone: true
})
export class RiskRatingChartComponent implements OnInit {

  panelOpen = false;

  @Input({required: true}) growRiskRatingNumber!: number;

  growRiskRating!: GrowRiskRating;
  constructor(){

  }

  ngOnInit(): void {
    this.growRiskRating = new GrowRiskRating(this.growRiskRatingNumber);
  }
}
