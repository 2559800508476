import {Component, Inject, inject, OnInit} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {
  applicantsPrivacyConsent,
  ApplicantsPrivacyConsentDialogData,
  ApplicantsPrivacyConsentDialogResult
} from "@portal-workspace/grow-shared-library";
import {MatTableModule} from "@angular/material/table";
import {MatSlideToggleChange, MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MessageBoxComponent} from "../message-box/message-box.component";

@Component({
  templateUrl: './applicants-privacy-consent.dialog.html',
  styleUrls: ['./applicants-privacy-consent.dialog.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatSlideToggleModule,
    MessageBoxComponent,
    JsonPipe,
    AsyncPipe,
  ]
})
export class ApplicantsPrivacyConsentDialog implements OnInit {

  displayedColumns = ['name', 'control'];

  data: ApplicantsPrivacyConsentDialogData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<ApplicantsPrivacyConsentDialog, ApplicantsPrivacyConsentDialogResult>);

  results: Map<ApplicantsPrivacyConsentDialogData['applicants'][number], boolean> = new Map();

  ngOnInit() {
    this.data.applicants.forEach(applicant => {
      this.results.set(applicant, applicant.privacyConsentObtained);
    });
  }

  toggleApplicantPrivacyConsent($event: MatSlideToggleChange, applicant: ApplicantsPrivacyConsentDialogData['applicants'][number]) {
    const acceptPrivacyConsent = $event.checked;
    this.results.set(applicant, acceptPrivacyConsent);
  }

  onCancel($event: Event) {
    this.dialogRef.close({type: 'cancel'});
  }

  onSave() {
    // copy acceptPrivacyConsent to applicant's privacyConsentObtained property
    const applicants = [...this.results.entries()].map(([applicant, acceptPrivacyConsent]) => {
      applicant.privacyConsentObtained = acceptPrivacyConsent;
      return applicant;
    });

    const {
      atLeastOneWithPrivacyConsent,
      allHasPrivacyConsent,
      withoutPrivacyConsent
    } = applicantsPrivacyConsent(applicants);

    let type = "error";
    if(atLeastOneWithPrivacyConsent){
      type = 'save'
    } 
    this.dialogRef.close({
      type:type,
      applicants,
    });
  }

}
