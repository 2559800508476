import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  nameIncludeDigitsWithoutBlankSpaceValidator,
  phoneValidator,
} from '@portal-workspace/grow-ui-library';
import {
  BusinessSearchValue,
  compareMatch,
  Reference2,
  ReferenceType,
  ReferenceValue,
  ReferenceValueOptions
} from '@portal-workspace/grow-shared-library';
import {Subject, Subscription} from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import {setupUntilDestroy, CustomErrorStateMatcher} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


export type ReferenceFormGroup = FormGroup<{
  company: FormControl<string | null>,
  phoneNumber: FormControl<string | null>,
  contactPerson: FormControl<string | null>,
  referenceType: FormControl<Reference2['referenceType'] | null>,
}>;

export type ReferenceFormControls = FormControl<string | null> | FormControl<Reference2['referenceType'] | null>;


export interface ReferenceComponentEvent {
  entries: ReferenceValue,
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ReferenceComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => ReferenceComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule]
})
export class ReferenceComponent extends AbstractControlValueAccessor<ReferenceValue> implements OnInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();

  subsriptions: Subscription[] = [];
  errorMessage = formControlErrorMessage;
  errorKeys = formControlErrorKeys;

  errorStateMatcher = new CustomErrorStateMatcher()


  referenceTypes = ReferenceValueOptions;

  formGroup: FormGroup<{
    references: FormArray<ReferenceFormGroup>
  }>;
  formArray: FormArray<ReferenceFormGroup>;
  subscription: Subscription;


  @Input({required: false}) mandatory = false;
  @Input({required: false}) description = 'Reference is optional';
  @Output() events: EventEmitter<ReferenceComponentEvent>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.events = new EventEmitter<ReferenceComponentEvent>();
    this.formArray = formBuilder.array<ReferenceFormGroup>([]);
    this.formGroup = formBuilder.group({
      references: this.formArray,
    });
    this.subscription = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r) => {
        if (this.formArray.invalid) {
          this.propagateChange(null);
        } else {
          // NOTE: when formArray is valid, properties in vs will not be null
          const vs = this.formArrayToReferenceValue(this.formArray);
          this.propagateChange(vs);
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    setTimeout(() => {
      if (this.mandatory && this.formArray.length <= 0) {
        this.addReference();
        this.propagateChange(null);
      } else if (!this.mandatory) {
        const v = this.formArrayToReferenceValue(this.formArray);
        this.propagateChange(v);
      }
    });
  }

  addReference(ref?: Reference2) {
    const formControlCompany = this.formBuilder.control(ref ? ref.company : null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
    const formControlPhoneNumber = this.formBuilder.control(ref ? ref.phoneNumber : null, [Validators.required, phoneValidator()]);
    const formControlContactPerson = this.formBuilder.control(ref ? ref.contactPerson : null, [Validators.required, nameIncludeDigitsWithoutBlankSpaceValidator()]);
    const formControlReferenceType = this.formBuilder.control(ref ? ref.referenceType : null, [Validators.required]);
    const formGroup = this.formBuilder.group({
      company: formControlCompany,
      phoneNumber: formControlPhoneNumber,
      contactPerson: formControlContactPerson,
      referenceType: formControlReferenceType,
    });
    this.formArray.push(formGroup);
    this.events.next({
      entries: this.formArrayToReferenceValue(this.formArray),
    })
  }

  removeReference(referenceFormGroup: ReferenceFormGroup) {
    const index = this.formArray.controls.indexOf(referenceFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    this.events.next({
      entries: this.formArrayToReferenceValue(this.formArray)
    })
  }


  referenceFormGroups(): ReferenceFormGroup[] {
    return this.formArray.controls;
  }

  referenceFormGroupFormControl(referenceFormGroup: ReferenceFormGroup, controlName: 'company' | 'phoneNumber' | 'contactPerson' | 'referenceType' ): ReferenceFormControls {
    return referenceFormGroup.controls[controlName];
  }


  doWriteValue(v: ReferenceValue | null | undefined) {
    if (v) {
      for (const reference of v) {
        this.addReference(reference);
      }
      return this.formArrayToReferenceValue(this.formArray);
    }
    return this.mandatory ? null : [];
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  formArrayToReferenceValue(formArray: FormArray<FormGroup>): ReferenceValue {
    const vs: Exclude<ReferenceValue, null> = (formArray.value ?? []).reduce((acc: Exclude<ReferenceValue, null>, ref) => {
      const v: Reference2 = {
        company: ref.company ?? '',
        referenceType: ref.referenceType ?? '',
        phoneNumber: ref.phoneNumber ?? '',
        contactPerson: ref.contactPerson ?? '',
      };
      acc.push(v);
      return acc;
    }, []);
    return vs;
  }
}
