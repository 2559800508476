<div class="generate-payout-dialog m-4">
  <!-- <h4 mat-dialog-title>Generate Payout</h4> -->
  <mat-dialog-content class="pt-2">
    <form [formGroup]="formGroup" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Add Recipients</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field class="cc-email-chip-list">
            <mat-label>TO</mat-label>
            <mat-chip-grid [formControl]="formControlEmailSet"  [required]="true" #chipList aria-label="email list">
              @for (email of emailList; track email) {
                <mat-chip-row (removed)="remove(email)">
                  {{email}}
                  <button matChipRemove>
                    <span class="mdi mdi-close"></span>
                  </button>
                </mat-chip-row>
              }
              <input
                matInput
                [required]="true"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                [formControl]="formControlEmail"
                (matChipInputTokenEnd)="add($event)">
              </mat-chip-grid>
            </mat-form-field>
            @for (errorKey of errorKeys(formControlEmail); track errorKey) {
              <mat-error>
                @if (formControlEmail.touched && formControlEmail.hasError(errorKey)) {
                  {{errorMessage(formControlEmail, errorKey)}}
                }
              </mat-error>
            }

          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <mat-form-field class="cc-email-chip-list">
              <mat-label>CC</mat-label>
              <mat-chip-grid #ccChipList aria-label="email list">
                @for (email of ccEmailList; track email) {
                  <mat-chip-row (removed)="remove(email, true)">
                    {{email}}
                    <button matChipRemove>
                      <span class="mdi mdi-close"></span>
                    </button>
                  </mat-chip-row>
                }
                <input
                  [matChipInputFor]="ccChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  [formControl]="formControlCcEmail"
                  (matChipInputTokenEnd)="add($event, true)">
                </mat-chip-grid>
              </mat-form-field>
              @for (errorKey of errorKeys(formControlCcEmail); track errorKey) {
                <mat-error>
                  @if (formControlCcEmail.touched && formControlCcEmail.hasError(errorKey)) {
                    {{errorMessage(formControlCcEmail, errorKey)}}
                  }
                </mat-error>
              }

            </div>
          </div>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="width-100" fxLayoutGap="5px"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-md="center start">
          <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
          <button mat-flat-button class="w-lt-md-100" color="primary" (click)="onSave()" [disabled]="formGroup.invalid">Send</button>
        </div>
      </mat-dialog-actions>
    </div>
