import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InputMaskModule} from '@ngneat/input-mask';
import {LoanTermsSelectionComponent} from './loan-terms-selection.component';
import {BusinessLoanTermsSelectionComponent} from './business-loan-terms-selection.component';
import {CommercialLoanTermsSelectionComponent} from './commercial-loan-terms-selection.component';
import {ConsumerLoanTermsSelectionComponent} from './consumer-loan-terms-selection.component';
import { PaymentTermsSelectionComponent } from './payment-terms-selection.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { LoanTermsSelectionWithInputComponent } from './loan-terms-selection-with-input.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        LoanTermsSelectionComponent,
        BusinessLoanTermsSelectionComponent,
        CommercialLoanTermsSelectionComponent,
        ConsumerLoanTermsSelectionComponent,
        PaymentTermsSelectionComponent,
        LoanTermsSelectionWithInputComponent,
    ],
    exports: [
        LoanTermsSelectionComponent,
        BusinessLoanTermsSelectionComponent,
        CommercialLoanTermsSelectionComponent,
        ConsumerLoanTermsSelectionComponent,
        PaymentTermsSelectionComponent,
        LoanTermsSelectionWithInputComponent,
    ]
})
export class LoanTermsSelectionModule {

}
