import {Component, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    selector: 'message-box-2',
    templateUrl: './message-box-2.component.html',
    styleUrls: ['./message-box-2.component.scss'],
    standalone: true,
    imports: [FlexModule, NgTemplateOutlet]
})
export class MessageBox2Component {

  @Input({required: false}) type: 'details' | 'info' | 'danger' | 'warn' = 'details';
  @Input({required: false}) title = ''
  @Output() close = new EventEmitter<void>();

  constructor(private viewContainer: ViewContainerRef) {
  }

  onClose() {
    this.close.emit()
    this.viewContainer
      .element
      .nativeElement
      .parentElement
      .removeChild(this.viewContainer.element.nativeElement);
  }

}
