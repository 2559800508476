import {Component, forwardRef, OnInit} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {AssetTier1Value, AssetTier4Value, compareMatch, TransactionTypeRateValue} from '@portal-workspace/grow-shared-library';
import {AssetTier2Value} from '@portal-workspace/grow-shared-library';
import {AssetTier3Value} from '@portal-workspace/grow-shared-library';
import {MinMaxRateValue} from '@portal-workspace/grow-shared-library';
import {AssetCategoryRateValue} from '@portal-workspace/grow-shared-library';
import {AssetTypeRateValue} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {requiredAllowEmptyValidator} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, } from '@portal-workspace/grow-ui-library';
import {MARK, MarkDirective, Mark } from '@portal-workspace/grow-ui-library/mark';

import {AssetRateCardValue} from '@portal-workspace/grow-shared-library';
import { AssetTypeRateComponent } from './asset-type-rate.component';
import { AssetCategoryRateComponent } from './asset-category-rate.component';
import { MinMaxRateComponent } from './min-max-rate.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssetTier3Component } from './asset-tier3.component';
import { AssetTier1Component } from './asset-tier1.component';
import { MatDividerModule } from '@angular/material/divider';
import { AssetTier2Component } from './asset-tier2.component';
import { AssetTier4Component } from './asset-tier4.component';
import {
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,} from '../component-utils';
import { TransactionTypeRateComponent } from '../transaction-type-selection-component/transaction-type-rate.component';



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-rate-card',
    templateUrl: './asset-rate-card.component.html',
    styleUrls: ['./asset-rate-card.component.scss'],
    exportAs: 'assetRateCardComponent',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetRateCardComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => AssetRateCardComponent), multi: false },
    ],
    standalone: true,
  imports: [
    AssetTier1Component,
    AssetTier2Component,
    AssetTier3Component,
    AssetTier4Component,
    MarkDirective,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    InputMaskModule,
    CurrencyInputComponent,
    MinMaxRateComponent,
    AssetCategoryRateComponent,
    AssetTypeRateComponent,
    TransactionTypeRateComponent
]
})
export class AssetRateCardComponent extends AbstractControlValueAccessor<AssetRateCardValue> implements  OnInit, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  tier1AssetTitle = `Tier 1: Passenger Vehicles & Light Commercial`;
  tier2AssetTitle = `Tier 2: Trucks, Trailers and buses, Yellow goods and material handling, Caravans`;
  tier3AssetTitle = `Tier 3: Medical, Plant & Equipment, Solar System`;
  tier4AssetTitle = `Tier 4: IT/Office Technology, Fitout, Other Tertiary`;

  triggerMark = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formGroup: FormGroup<{
    assetTier1: FormControl<AssetTier1Value>,
    assetTier2: FormControl<AssetTier2Value>,
    assetTier3: FormControl<AssetTier3Value>,
    assetTier4: FormControl<AssetTier4Value>,
    capRate: FormControl<number | null>,
    truck12T: FormControl<number | null>,
    noAssetBacked: FormControl<number | null>,
    transactionTypeRate: FormControl<TransactionTypeRateValue>,
    // privateSales: FormControl<number | null>
    // privateSalesDocFee: FormControl<number | null>,
    docFee: FormControl<MinMaxRateValue>,
    adverseOnFile: FormControl<number | null>,
    gstAge: FormControl<MinMaxRateValue>,
    lowEquifax: FormControl<number | null>,
    uplift: FormControl<number | null>;
    brokerage: FormControl<MinMaxRateValue>,
    assetCategory: FormControl<AssetCategoryRateValue>,
    assetType: FormControl<AssetTypeRateValue>,
    loanTerms: FormControl<MinMaxRateValue>,
    loanAmount: FormControl<MinMaxRateValue>,
  }>;
  formControlTier1Asset: FormControl<AssetTier1Value>;
  formControlTier2Asset: FormControl<AssetTier2Value>;
  formControlTier3Asset: FormControl<AssetTier3Value>;
  formControlTier4Asset: FormControl<AssetTier4Value>;
  formControlCapRate: FormControl<number | null>;
  formControlTruck12T: FormControl<number | null>;
  formControlNoAssetBacked: FormControl<number | null>;
  formControlTransactionTypeRate: FormControl<TransactionTypeRateValue>;
  // formControlPrivateSale: FormControl<number | null>;
  // formControlPrivateSaleDocFee: FormControl<number | null>;
  formControlDocFee: FormControl<MinMaxRateValue>;
  formControlAdverseOnFile: FormControl<number | null>;
  formControlGstAge: FormControl<MinMaxRateValue>;
  formControlLowEquifax: FormControl<number | null>;
  formControlLowDepositUplift: FormControl<number | null>;
  formControlBrokerage: FormControl<MinMaxRateValue>;
  formControlAssetCategpry: FormControl<AssetCategoryRateValue>;
  formControlAssetType: FormControl<AssetTypeRateValue>;
  formControlLoanTerms: FormControl<MinMaxRateValue>;
  formControlLoanAmount: FormControl<MinMaxRateValue>;


  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlTier1Asset = formBuilder.control(null, [Validators.required]);
    this.formControlTier2Asset = formBuilder.control(null, [Validators.required]);
    this.formControlTier3Asset = formBuilder.control(null, [Validators.required]);
    this.formControlTier4Asset = formBuilder.control(null, [Validators.required]);
    this.formControlCapRate = formBuilder.control(0, [Validators.required]);
    this.formControlTruck12T = formBuilder.control(0, [Validators.required]);
    this.formControlNoAssetBacked = formBuilder.control(0, [Validators.required]);
    this.formControlTransactionTypeRate = formBuilder.control(null, [Validators.required]);
    // this.formControlPrivateSale = formBuilder.control(0, [Validators.required]);
    // this.formControlPrivateSaleDocFee = formBuilder.control(0, [Validators.required]);
    this.formControlDocFee = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAdverseOnFile = formBuilder.control(0, [Validators.required]);
    this.formControlGstAge = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlLowEquifax = formBuilder.control(0, [Validators.required]);
    this.formControlLowDepositUplift = formBuilder.control(0, [Validators.required]);
    this.formControlBrokerage = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAssetCategpry = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlAssetType = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formControlLoanTerms = formBuilder.control([],[requiredAllowEmptyValidator]);
    this.formControlLoanAmount = formBuilder.control([], [requiredAllowEmptyValidator]);
    this.formGroup = this.formBuilder.group({
      assetTier1: this.formControlTier1Asset,
      assetTier2: this.formControlTier2Asset,
      assetTier3: this.formControlTier3Asset,
      assetTier4: this.formControlTier4Asset,
      capRate: this.formControlCapRate,
      truck12T: this.formControlTruck12T,
      noAssetBacked: this.formControlNoAssetBacked,
      transactionTypeRate: this.formControlTransactionTypeRate,
      // privateSales: this.formControlPrivateSale,
      // privateSalesDocFee: this.formControlPrivateSaleDocFee,
      docFee: this.formControlDocFee,
      adverseOnFile: this.formControlAdverseOnFile,
      gstAge: this.formControlGstAge,
      lowEquifax: this.formControlLowEquifax,
      uplift: this.formControlLowDepositUplift,
      brokerage: this.formControlBrokerage,
      assetCategory: this.formControlAssetCategpry,
      assetType: this.formControlAssetType,
      loanTerms:this.formControlLoanTerms,
      loanAmount: this.formControlLoanAmount
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          // NOTE: when formGroup is valid, the values should not be null as required validators are provided
          const v: AssetRateCardValue = {
            assetTier1: this.formControlTier1Asset.value,
            assetTier2: this.formControlTier2Asset.value,
            assetTier3: this.formControlTier3Asset.value,
            assetTier4: this.formControlTier4Asset.value,
            capRate: this.formControlCapRate.value ?? 0,
            truck12T: this.formControlTruck12T.value ?? 0,
            noAssetBacked: this.formControlNoAssetBacked.value ?? 0,
            transactionTypeRate: this.formControlTransactionTypeRate.value,
            // privateSales: this.formControlPrivateSale.value ?? 0,
            // privateSalesDocFee: this.formControlPrivateSaleDocFee.value ?? 0,
            adverseOnFile: this.formControlAdverseOnFile.value ?? 0,
            lowEquifax: this.formControlLowEquifax.value ?? 0,
            lowDepositUplift: this.formControlLowDepositUplift.value ?? 0,
            gstAge: this.formControlGstAge.value,
            docFee: this.formControlDocFee.value,
            brokerage: this.formControlBrokerage.value,
            assetCategory: this.formControlAssetCategpry.value,
            assetType: this.formControlAssetType.value,
            loanTerms: this.formControlLoanTerms.value,
            loanAmount: this.formControlLoanAmount.value,
          };
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      }),
    ).subscribe();
    this.subscriptions.push(sub);
  }


  doWriteValue(v: AssetRateCardValue | null | undefined): void | AssetRateCardValue {
    console.log('***** asset-rate-card component doWriteValue', v);
    if (v) {
      this.formControlTier1Asset.setValue(v.assetTier1);
      this.formControlTier2Asset.setValue(v.assetTier2);
      this.formControlTier3Asset.setValue(v.assetTier3);
      this.formControlTier4Asset.setValue(v.assetTier4);
      this.formControlCapRate.setValue(v.capRate);
      this.formControlTruck12T.setValue(v.truck12T);
      this.formControlNoAssetBacked.setValue(v.noAssetBacked);
      this.formControlTransactionTypeRate.setValue(v.transactionTypeRate);
      // this.formControlPrivateSale.setValue(v.privateSales);
      this.formControlDocFee.setValue(v.docFee ?? []);
      this.formControlAdverseOnFile.setValue(v.adverseOnFile);
      this.formControlGstAge.setValue(v.gstAge ?? []);
      this.formControlLowEquifax.setValue(v.lowEquifax);
      this.formControlLowDepositUplift.setValue(v.lowDepositUplift ?? 0); // TODO: set the default value tp 1 or remove it?
      this.formControlBrokerage.setValue(v.brokerage ?? []);
      this.formControlAssetCategpry.setValue(v.assetCategory ?? []);
      this.formControlAssetType.setValue(v.assetType ?? []);
      // this.formControlPrivateSaleDocFee.setValue(v.privateSalesDocFee);
      this.formControlLoanTerms.setValue(v.loanTerms ?? []);
      this.formControlLoanAmount.setValue(v.loanAmount ?? []);
    }
    return undefined;
  }

  mark() {
    this.triggerMark.next(true);
    this.formGroup.markAllAsTouched();
  }
}
