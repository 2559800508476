import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BypassFaceCompareFn,
  CorporateLoanApplication,
  CreateApplicationNoteFn,
  DeleteIdentityVerificationFn,
  DigitalIdGetApplicationIndividualsFn,
  DownloadDocumentFromAzureFn,
  SendPrivacyConsentEmailFn,
  GetApplicationByIdFn,
  getApplicationStage,
  GetUserFn,
  isInternalUser,
  RefreshBankStatementFn,
  RemoveApplicationNoteByNoteIdFn,
  SendIdVerifyLinkFn,
  UpdateApplicationStageInSfFn,
  User,
  isAdminOrCreditUserOrSalesAM,
  isAdminOrCreditUser,
  RatecardDetails,
  TermRateForCorporateLoan,
  CorporateLoanPaymentBreakupDialogData,
  GetContractStatusFn,
  UpdateApplicationFn,
  UpdateApplicationSfFn,
  GetRateCardDetailsFn,
  calculateCorporateLoanEstimation,
  LoanTermType,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  GetApplicationOwnerFn,
  AssetSettlementGetApplicationAssetFn,
  GetDisbursementByApplicationIdFn, SettleLoanFn, GetBsaLenderListFn, GetBsaExcludedLenderListFn, GetDscrCalculatorValueFn, UpdateDscrCalculatorValueFn, SaveBsaCalculatorFn, GetBsaCalculatorFn,
  CopyApplicationFn,
  GetGeoLocationFn,
  applicationStageAllowGenerateContract,
  SendContractEmailsFn,
  GenerateContractFn,
  DeleteContractFileFn,
  SyncContractDetailsToSfFn,
  GetAccountDetailsFromSfFn,
  GetActiveContractForApplicationFn,
  ContractDetails,
  DeclineContractFn,
  Disbursement,
  validateDisbursementData,
  ApiResponse,
  AzureStorageDocument,
  Application,
  GroupedDocument,
  formGroupedDocumentData,
  unclassifiedDocuments,
  groupStandardCondition,
  constants,
  applicationStageAllowSettleLoan,
  GetDscrCalculatorHistoryFn,
  GetOriginatorBusinessByIdFn,
  RunCreditFlowFn,
  GetCreditWebServicesFn,
  GetApplicationCreditFlowResultFn,
  DownloadCreditorWatchReportFn,
  UpdateCreditStatusFn,
  GetCreditAuditLogFn,
  ApplicationSelectionObject,
  ApplicationWebService,
  GetWebServiceReportFn,
  GetDuplicatedApplicationsFn,
  DuplicatedApplication,
  DeleteWebServicesFn,
  GetBasiqCustomerMappingFn,
  GetBasiqStatementDataForCompanyFn,
  RefreshBasiqConnectionsFn,
  GetBankStatementAndBasiqDataStatusFn,
  RequestMoreInformationFn,
  WithdrawApplicationFn,
  isNonSalesInternalUser,
  GetApplicationDscrFn,
} from '@portal-workspace/grow-shared-library';
import { GetBankStatementsAnalysisFn } from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import { ApplicationSelectionType, primaryCommercialEntity, } from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  GetOriginatorByIdFn,
  ListApplicationDocumentFn,
  BusinessSearchFn,
   
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  CreateDocumentWorklistFn,
  CompleteDocumentWorklistFn,
  GetNotesByApplicationIdFn,
  BusinessNumberSearchFn,
  UndoApplicationDocumentFn,
  AssetDocumentsComponent,
  PortalHotToastService,
  ApplicationDialogService,
  CorporateLoansPricingComponentEvent,
  setupUntilDestroy,
  applicationToPropertyOwner,
  applicationToLoanTerms,
  applicationToAdverseOnFile,
  applicationToEquifaxScoreAboveThreshold,
  applicationToDirectorScore,
  applicationToLoanAmount,
  applicationToFacilityEstablishmentFee,
  applicationToFacilityEstablishmentFeePercent,
  applicationToSecurityType, applicationToLtv,
  GetApplicationAuditLogsFn,
  checkContractIndividuals,
  applicationDefaultDocuments, applicationToDocFee, applicationToBrokerageAmount,
} from '@portal-workspace/grow-ui-library';
import { UpdateApplicationDocumentFn } from '../application-details.module';
import { GetUsersFunc, UserSelectionComponentEvent } from '@portal-workspace/grow-ui-library';
import { getUser } from '@portal-workspace/grow-ui-library';
import { CorporateLoansDocumentsComponent } from './corporate-loans-documents.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { CorporateLoansAppComponent } from './corporate-loans-app.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { BankComponent } from '../bank.component'
import {CorporateLoansPricingComponent} from "./corporate-loans-pricing.component";
import {CorporateLoansSettlementComponent} from "./corporate-loans-settlement.component";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription, combineLatest, of} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import moment from 'moment';
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';
import { CorporateLoansCreditComponent } from './corporate-loans-credit.component';
import { CreditReportComponent } from '../../credit-component/credit-report.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';

export type CorporateTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'corporate-loans-application-details',
    templateUrl: './corporate-loans-application-details.component.html',
    styleUrls: ['./corporate-loans-application-details.component.scss'],
    standalone: true,
    imports: [
    FlexModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    CorporateLoansAppComponent,
    KycVerificationComponent,
    CorporateLoansDocumentsComponent,
    BankComponent,
    AssetDocumentsComponent,
    CorporateLoansPricingComponent,
    ApplicationTypeIconComponent,
    CorporateLoansSettlementComponent,
    CorporateLoansCreditComponent,
    CreditReportComponent,
    MessageBoxComponent,
]
})
export class CorporateLoansApplicationDetailsComponent {

  tabs: CorporateTab[] = [];

  ratecard: RatecardDetails | null = null;

  isInternalUser = isInternalUser;
  applicationStageAllowGenerateContract = applicationStageAllowGenerateContract;
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  groupStandardCondition = groupStandardCondition;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'bank-statement', 'kyc-aml', 'documents', 'asset', 'pricing', 'credit-report'];

  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();

  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
   
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) application!: CorporateLoanApplication;

  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) generateContractFn!: GenerateContractFn;
  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) sendContractEmailsFn!: SendContractEmailsFn;
  @Input({required: true}) deleteContractFileFn!: DeleteContractFileFn;
  @Input({required: true}) syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  @Input({required: true}) getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input({required: true}) getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  @Input({required: true}) declineContractFn!: DeclineContractFn;
  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;

  breadcrumbTrails: string[] = ['Applications'];
  activeContract: ContractDetails | null = null;

  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent) => void = (evt) => { };
  @Input({required: true}) allowContractGeneration!: boolean;

  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex: number = 0;
  getApplicationStage = getApplicationStage;

  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: false}) ip: string = '';
  @Input({required: false}) initialSelection: ApplicationSelectionType | null = null;
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Input({required: true}) getApplicationDscrFn!: GetApplicationDscrFn;

  @Output() navigateToApplications = new EventEmitter();
  @Output() selectionEvent: EventEmitter<ApplicationSelectionObject> = new EventEmitter<ApplicationSelectionObject>();
  @Output() events = new EventEmitter<UserSelectionComponentEvent & { type: 'submitter' | 'additional-correspondent' }>();
  @Output() corporateLoansPricingEvents = new EventEmitter<CorporateLoansPricingComponentEvent>();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  data!: TermRateForCorporateLoan;
  rst!: CorporateLoanPaymentBreakupDialogData;

  isAdminOrCreditUser = false;
  isAdminOrCreditUserOrSalesAM = false;

  constructor(
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService
  ){}


  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index:(this.tabs.length)});
    if (isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['credit', 'credit-report'], iconClass: 'mdi-calculator', name: 'CREDIT', index: (this.tabs.length)});
    }
    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['asset'], iconClass: 'mdi-handshake-outline', name: 'CONTRACT', index: (this.tabs.length)});
    if (this.isAdminOrCreditUserOrSalesAM || isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['pricing'], iconClass: 'mdi-currency-usd', name: 'PRICING', index: (this.tabs.length)});
    }
  }

  // getApplicationTabIndex(tab: ApplicationSelectionType) {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.isAdminOrCreditUser = isAdminOrCreditUser(this.loggedInUser);
    this.isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.loggedInUser);
    this.initTabs();
    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }

    this.subscriptions.push(this.getRatecardDetailsFn(this.application.UserId, 'CorporateLoans').pipe(
      this.toastService.spinnerObservable(),
      tap((r: RatecardDetails) => {
        this.ratecard = r;
        this.recalculateEstimation();
      })
    ).subscribe());

    this.subscriptions.push(this.corporateLoansPricingEvents.subscribe(evt => {
      switch(evt.type) {
        case 'corporate-loan-pricing-saved': {
          this.data = evt.rst.term;
          this.rst = evt.rst;
          break;
        }
      }
    }));


    this.getActiveContract(); //show signer button on page load event
  }

  onViewReport(element: ApplicationWebService) {
    this.currentSection = 'credit-report';
    console.log(this.currentSection)
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: String(element.id),
    });
  }

  onLeaveReport(event: void) {
    this.currentSection = 'credit';
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  recalculateEstimation() {
    if (this.ratecard) {
      const ratecard = this.ratecard;
      const date = moment().add(1, 'day');
      {
        const loanTermValue = applicationToLoanTerms(this.application);
        const loanTermType = loanTermValue?.type ?? null;
        const propertyOwner =  applicationToPropertyOwner(this.application);
        const adverseOnFile = applicationToAdverseOnFile(this.application);
        const lowEquifaxScore = applicationToEquifaxScoreAboveThreshold(this.application);
        const directorScore = applicationToDirectorScore(this.application);
        const loanAmount = applicationToLoanAmount(this.application) ?? 0;
        const facilityEstablishmentFee = applicationToFacilityEstablishmentFee(this.application) ?? 0;
        const facilityEstablishmentFeePercentValue = applicationToFacilityEstablishmentFeePercent(this.application);
        const facilityEstablishmentFeePrecent = facilityEstablishmentFeePercentValue ? Number(facilityEstablishmentFeePercentValue.type) : 0;
        const securityType = applicationToSecurityType(this.application) ?? undefined;
        const ltvType = applicationToLtv(this.application) ?? undefined;
        const brokerageAmount = applicationToBrokerageAmount(this.application) ?? undefined;
        const docFee = applicationToDocFee(this.application) as number;

        const rst = calculateCorporateLoanEstimation(date, {
          type: 'CorporateLoan',
          loanTermType,
          // loanAmount,
          propertyOwner,
          adverseOnFile,
          lowEquifaxScore,
          directorScore,
          rateCard: ratecard,
          facilityLimit: loanAmount,
          facilityEstablishmentFeePercent: facilityEstablishmentFeePrecent,
          brokerageAmount: brokerageAmount,
          docFee: docFee,
          securityType,
          ltvType,
        });

        this.data = rst.term;
        this.rst = rst;
      }
    }
  }


  openGenerateContractDialog() {

    const ok = checkContractIndividuals(this.application, this.dialogService);
    if (ok) {
      this.subscriptions.push(
        this.dialogService.openGenerateContractDialog({
          sendContractEmailsFn: this.sendContractEmailsFn,
          getRatecardDetailsFn: this.getRatecardDetailsFn,
          getApplicationIndividualsFn: this.getApplicationIndividualsFn,
          generateContractFn: this.generateContractFn,
          deleteContractFileFn: this.deleteContractFileFn,
          application: this.application,
          apiUrl: this.apiUrl,
          syncContractDetailsToSfFn: this.syncContractDetailsToSfFn,
          getApplicationOwnerFn: this.getApplicationOwnerFn,
          getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
          updateApplicationFn: this.updateApplicationFn,
          getOriginatorBusinessByIdFn: this.getOriginatorBusinessByIdFn,
        }).afterClosed().subscribe(() => {
          this.getActiveContract();
        })
      );
    }
  }

  getActiveContract() {
    this.subscriptions.push(
      this.getActiveContractForApplicationFn(this.application.ApplicationId)
        .subscribe((r: ContractDetails | null) => {
          this.activeContract = r;
        })
    )
  }

  openContractStatusDialog() {
    this.subscriptions.push(
      this.dialogService.openContractStatusDialog({
        getContractStatusFn: this.getContractStatusFn,
        applicationId: this.application.ApplicationId,
        declineContractFn: this.declineContractFn,
        envelopeId: this.activeContract?.envelopeId ?? ""
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }

  doSendDisbursementToSf() {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn(this.application.ApplicationId).pipe(
        switchMap((disbursements: Disbursement[]) => {
          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
          return this.settleLoanFn({
            assets: [],
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
      })
    )
  }

  onSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.dialogService.openSettleLoanDialog()
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      // check documents first
      this.subscriptions.push(
        combineLatest([
          this.listApplicationDocumentFn(this.application.ApplicationId),
          this.getApplicationByIdFn(this.application.ApplicationId)
        ]).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
          if (r) {
            let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
              app?.DocumentTypes?.length ? app?.DocumentTypes
              : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
              '',
            );
            docs = this.groupStandardCondition(docs, this.application);
            docs = this.displayRequiredDocumentsOnly(docs);
            const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
            const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
            const standardConditionDocs = docs.filter(d => d?.standardCondition);
            const pendingGroups = [
              ...standardDocs.filter(group => group.status === 'Pending'),
              ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
              ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
            ];
            console.log(pendingGroups);
            if (pendingGroups.length) {
              // do not allow settle loan
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: 'You cannot settle loan as you still have pending documents',
              }).afterClosed().subscribe();
            } else {
              this.doUpdateApplicationStage();
            }
          }
        })
      )
    }
  }

  private displayRequiredDocumentsOnly(docs: GroupedDocument[]) {
    return docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }


  doUpdateApplicationStage() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? "",
        stageName: 'QA'
      }).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response.status) {
          this.dialogService.successDialog({
            message: 'Success',
            subMessage: "Application sent to QA queue",
          }).afterClosed().subscribe();
        } else {
          this.dialogService.openAlertDialog({
            message: 'Error',
            subMessage: response.message,
          }).afterClosed().subscribe();
        }
      })
    )
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  onCorporateLoansPricingEvents($event: CorporateLoansPricingComponentEvent) {
    this.corporateLoansPricingEvents.emit($event);
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
