<div class="dashboard-card-component">
  <div class="widthSize">
    <div class="box-2 {{borderColor ?? ''}}" [ngClass]="(borderStyle == 'full') ? 'fullBorder': 'bottomBorder'"
      fxFlex="1 1 auto">
      <div class="title">{{title}}</div>
      <div class="content">
        <span class="mat-h3">{{amount | looseCurrency}}</span>
        <div class="childContent button mt-4">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
