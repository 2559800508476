import {Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';

import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { BoundingBoxData } from '@portal-workspace/grow-shared-library';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'image-with-bounding-box',
    templateUrl: './image-with-bounding-box.component.html',
    styleUrls: ['./image-with-bounding-box.component.scss'],
    standalone: true,
    imports: [CurrencyInputComponent]
})
export class ImageWithBoundingBoxComponent implements  OnInit, AfterViewInit, OnChanges {
  @Input({required: true}) src!: string; 
  @Input({required: false}) boundingBoxes: BoundingBoxData[] = [];
  @Input({required: true}) text!: string;

  id = "canvas" + (Math.floor(Math.random() * 100) + 1);

  rectangles: HTMLDivElement[] = [];

  constructor() {}

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  ngAfterViewInit(): void {
    this.initDrawRect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['boundingBoxes']) {
      for (const div of this.rectangles) {
        div.remove();
      }
      this.rectangles = [];

      this.initDrawRect();
    }
  }

  initDrawRect(){
    const canvas = document.getElementById(this.id);

    if (canvas) {
      for (const boundingBox of this.boundingBoxes) {
        if (Object.keys(boundingBox).length) {
        const elementDiv = document.createElement("div");
          elementDiv.className = "rectangle";
          elementDiv.style.left = boundingBox.BoundingBox.Left * 100 + "%";
          elementDiv.style.top = boundingBox.BoundingBox.Top * 100 + "%";
          elementDiv.style.height = boundingBox.BoundingBox.Height * 100 + "%";
          elementDiv.style.width = boundingBox.BoundingBox.Width * 100 + "%";
          elementDiv.style.border = "solid 2px red";
          elementDiv.style.position = "absolute";
          elementDiv.style.zIndex = "99999";
          canvas.appendChild(elementDiv);

          this.rectangles.push(elementDiv);
        }
      }
    }
  }

  get imageAvailable() {
    return this.src.startsWith("data:image");
  }
}

