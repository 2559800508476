import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Subscription } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Application, CreditDecisioningReasonDialogData, CreditDecisioningReasonDialogResult, creditDeclineApprovalReasonOptions, creditDeclineReasonOptions} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@UntilDestroy()
@Component({
    templateUrl: './credit-decisioning-reason.dialog.html',
    styleUrls: ['./credit-decisioning-reason.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, MatInputModule, ReactiveFormsModule, MatOptionModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class CreditDecisioningReasonDialog implements OnInit {

  formControlReasonSelection!: FormControl<string | null>;
  formControlReason!: FormControl<string | null>;
  subscription?: Subscription;
  application!: Application;
  reasonOptions = creditDeclineApprovalReasonOptions;
  formGroup!: FormGroup<{
    reasonSelection: FormControl<string | null>,
    reason: FormControl<string | null>,
  }>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: CreditDecisioningReasonDialogData,
    private dialogRef: MatDialogRef<CreditDecisioningReasonDialog, CreditDecisioningReasonDialogResult>,
  ) {
    if (data.status === 'Declined') {
      this.reasonOptions = creditDeclineReasonOptions;
    }
    this.formControlReason = this.formBuilder.control(null, [Validators.required, Validators.max(10000)]);
    this.formControlReasonSelection = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      reasonSelection: this.formControlReasonSelection,
      reason: this.formControlReason,
    })
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  onSubmit(readyForSubmission: boolean) {
    if (readyForSubmission) {
      this.dialogRef.close({
        readyForSubmission,
        reasonSelection: this.formControlReasonSelection.value ?? '',
        reason: this.formControlReason.value ?? ''
      })
    } else {
      this.dialogRef.close()
    }
  }
}
