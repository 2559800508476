export const OriginatorTypeValueOptions: {type: OriginatorType, name: string}[] = [
  {type: 'broker', name: 'Broker'},
  {type: 'vendor', name: 'Vendor'},
  {type: 'grow', name: 'Grow'},
  {type: 'customer', name: 'Customer'},
  {type: 'aggregator', name: 'Aggregator'},
  {type: 'dealer', name: 'Dealer'},
];

export type OriginatorType = 'broker' | 'vendor' | 'grow' | 'customer' | 'dealer' | 'aggregator';

export type OriginatorTypeValue = {type: OriginatorType, name: string} | null;
