import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TitleSelectionModule} from '../title-selection-component/title-selection.module';
import {BusinessSearchModule} from '../business-search-component/business-search.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {AssetRateCardComponent} from './asset-rate-card.component';
import {NonAssetRateCardComponent} from './non-asset-rate-card.component';
import {AssetTypeRateComponent} from './asset-type-rate.component';
import {AssetCategoryRateComponent} from './asset-category-rate.component';
import {MinMaxRateComponent} from './min-max-rate.component';
import {AssetTier1Component} from './asset-tier1.component';
import {AssetTier2Component} from './asset-tier2.component';
import {AssetTier3Component} from './asset-tier3.component';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {BusinessLoanRateCardComponent} from './business-loan-rate-card.component';
import {RateCardListComponent} from './rate-card-list.component';
import {InsurancePremiumRateCardComponent} from './insurance-premium-rate-card.component';
import { BusinessOverdraftRateCardComponent } from './business-overdraft-rate-card.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import { ConsumerRateCardComponent } from './consumer-rate-card.component';
import {CorporateLoanRateCardComponent} from "./corporate-loan-rate-card.component";
import {SecurityTypeRateComponent} from './security-type-rate.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TitleSelectionModule,
        BusinessSearchModule,
        InputMaskModule,
        CurrencySelectionModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        AssetRateCardComponent,
        BusinessLoanRateCardComponent,
        BusinessOverdraftRateCardComponent,
        NonAssetRateCardComponent,
        AssetTypeRateComponent,
        AssetCategoryRateComponent,
        MinMaxRateComponent,
        AssetTier1Component,
        AssetTier2Component,
        AssetTier3Component,
        RateCardListComponent,
        InsurancePremiumRateCardComponent,
        ConsumerRateCardComponent,
        CorporateLoanRateCardComponent,
        SecurityTypeRateComponent,
    ],
    exports: [
        AssetRateCardComponent,
        BusinessLoanRateCardComponent,
        BusinessOverdraftRateCardComponent,
        NonAssetRateCardComponent,
        MinMaxRateComponent,
        AssetCategoryRateComponent,
        AssetTypeRateComponent,
        AssetTier1Component,
        AssetTier2Component,
        AssetTier3Component,
        RateCardListComponent,
        InsurancePremiumRateCardComponent,
        ConsumerRateCardComponent,
        CorporateLoanRateCardComponent,
        SecurityTypeRateComponent,
    ]
})
export class RateCardModule {}
