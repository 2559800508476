import {PortalEnvironmentType, constants} from '@portal-workspace/grow-shared-library';

export const environment: PortalEnvironmentType = {
  production: false,
  enableServiceWorker: false,
  gitTag: "v4.6.6-291-ge8dd7c1367",
  gitHash: "e8dd7c1367e38770b8909399364acfd741c0f0bb",
  api2Host: "https://apitest.dynamoney.com",
  agmApiKey: 'AIzaSyAGN-Wr7Kf6PU5vkcYbta9gvGg_sRfaoY0',
  submitApplicationTermsAndConditionsUrl: 'https://apitest.dynamoney.com/api2/site-assets/tandc/T&Cs.pdf',
  helpCenterUrl: 'https://dynamoney.com',
  facebookUrl: 'https://www.facebook.com/growfinancelimited',
  linkedinUrl: 'https://www.linkedin.com/company/dynamoney',
  fxApiKey: '87KJ7Y88739DHEXU',
  invoiceFinanceUrl: 'https://uat-invoicefinance.dynamoney.com/registration/welcome',
  contactUsUrl: 'https://www.dynamoney.com/contact-us',
  brokerProductGuideUrl: 'https://www.dynamoney.com/product-guide',
  assetProductGuideUrl: 'https://dynamoney.com/wp-content/uploads/2024/10/Commercial-AF-Product-Guide-Oct24.pdf',
  bankStatements: 'https://staging-bankstatements.dynamoney.com',
  idVerify: 'https://idverifytest.dynamoney.com',
  GrowFinanceGroupSalesforceId: '0018t0000031dE4AAI', // NOTE: this is (application AppInfo BrokerSalesforceId) / OriginatorBusiness or Arggregator SalesforceId
  OverdraftCustomerRecordTypeId: '0128t0000004I2jAAE',
  GrowFinanceGroupName: "DYNAMONEY",
  appInsightsKey: '9a544814-1d2a-48b4-b89f-673b6a5dab82',
  autoRefreshToken: true,
  applicationSubmission: {
    disabledMessage: 'We have temporarily ceased accepting new applications for this product. Please follow us on the relaunch of this product.',
    internalAccessMessage: `Currently this application is only open for internal users. Please follow us for more information.`,
    AssetFinance: 'enabled',
    BusinessLoans: 'enabled',
    TradeFinance: 'terminated',
    Consumer: 'enabled',
    InsurancePremium: 'enabled',
    Commercial: 'enabled',
    BusinessOverdraft: 'enabled',
    CorporateLoans: 'enabled',

  },
  directSaleSubmission: {
    disabledMessage: 'We have temporarily ceased accepting new applications for this product. Please follow us on the relaunch of this product.',
    internalAccessMessage: `Currently this application is only open for internal users. Please follow us for more information.`,
    AssetFinance: 'terminated',
    BusinessLoans: 'enabled',
    TradeFinance: 'terminated',
    Consumer: 'terminated',
    InsurancePremium: 'terminated',
    Commercial: 'terminated',
    BusinessOverdraft: 'enabled',
    CorporateLoans: 'terminated',

  },
  directSaleCompanyId: 2516,
  directSaleUserId: 938,
  directSaleUserEmail: "jimmys@growfinance.com.au",
  directSaleUserFirstName: "Jimmy",
  directSaleUserLastName: "Shen",
  localStorage: {
    encryptionKey: 'a3YKnFwJ/X+hIoAnnPNoIrViZhWzT95LTK5b2dSMIe6MJEGfiQoCsQulAh9FuoBf',
  },
  verimoto: {
    lender: {
      name: "Dynamoney-Formally Grow Finance",
      guid: "81996595-814D-4AB8-8EB7-E8EECB37CC61"
    },
    verimotoInspectionTypes: constants.verimotoInspectionTypesSandbox
  },
  customerPortalUrl: 'https://customertest.dynamoney.com'
};

