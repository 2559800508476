export type Reference2 = {
  company: string,
  phoneNumber: string;
  contactPerson: string;
  referenceType: {
    type: ReferenceType,
    name: string,
  };
}

export type ReferenceValue = Reference2[] | null;

export type ReferenceType = 'financier' | 'accountant' | 'solicitor' | 'bankManager' | 'realEstateAgent' | 'financialPlanner';

export const ReferenceValueOptions: Reference2['referenceType'][] = [
  {type: 'financier',         name: 'Financier'},
  {type: 'accountant',        name: 'Accountant'},
  {type: 'solicitor',         name: 'Solicitor'},
  {type: 'bankManager',       name: 'Bank Manager'},
  {type: 'realEstateAgent',   name: 'Real Estate Agent'},
  {type: 'financialPlanner',  name: 'Financial Planner'},
];
