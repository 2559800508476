
<div class="editable-text-component">
  <editable (modeChange)="modeChange($event)"
    [ngClass]="{'edit': mode == 'edit', 'view': mode == 'view'}">
    <ng-template editMode>
      <div fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">
        <mat-form-field>
          <input [formControl]="formControl"
            matInput
            type="text">
            @for (errorKey of errorKeys(formControl); track errorKey) {
              <mat-error>
                @if (formControl.touched && formControl.hasError(errorKey)) {
                  {{errorMessage(formControl, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
          <button mat-flat-button
            (click)="onSave()"
            editableOnSave>
            <span class="mdi mdi-check-outline"
            matTooltip="Save"></span>
          </button>
        </div>
      </ng-template>
      <ng-template viewMode>
        <div fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="5px">
          <span class="w-100">{{readValue ?? ''}}</span>
          <span class="mdi mdi-pencil-outline"
          matTooltip="Edit"></span>
        </div>
      </ng-template>
    </editable>

  </div>
