<div class="faq-page container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="faq-title separator-bottom ml-2" fxLayout="row" fxLayoutAlign="center center">
            <span class="mdi mdi-lifebuoy"></span>
            <div class="mat-h2">Frequently Asked Questions</div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>How to submit an application</div>
            </div>

          </mat-expansion-panel-header>
          <ng-container>
            <ol class="pl-4">
              <li>Select the <a routerLink="/s/new-application"><u>New Application</u>
                </a> tab on the left sidebar menu.</li>
              <li>Select the product which you would like to apply for.</li>
              <li>Follow the prompts and fill in the required fields.</li>
              <li> You will then receive a summarised quotation based on the details you have provided. If you would
                like to proceed, click accept the quotation.</li>
              <li>Follow the prompts and fill in the required fields. A final summary will then show outlining the
                details of your application.
                the “Submit” button when you are ready to process your application.</li>
              Select the New Application tab on the left sidebar menu.
              <li>Click the “Submit” button when you are ready to process your application.</li>

            </ol>
          </ng-container>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>Complete Mobile and Email Verification</div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <div class="pl-4">
              <h4>Phone and Email Verification</h4>
              <p>After you sign up, you must complete phone and email verification to submit your first application.</p>
              <h4>Verifying your phone number</h4>
              <p>As soon as you sign up, a verification code will be sent to your phone number via text message. Enter
                the verification code from the text message when prompted in the Dynamoney app to verify your phone
                number. </p>
              <h4>Verifying your email address</h4>
              <p>After you sign up, and any other time your update your email address, we’ll send a message to the email
                address that you entered. Click the link in the email to verify ownership of your email address, and
                you’re all set. </p>
              <p>If you’re not seeing the email, try searching your inbox for Dynamoney and check your spam or junk
                folder. </p>
              <h4>Have a problem with phone or email verification?</h4>
              <p>We’re sorry that you experienced an issue with our portal. To report a technical problem or get any
                help, please <a href="https://www.dynamoney.com/contact-us" target="_blank" rel="noopener"><u>contact our support team
                  directly.</u></a></p>
            </div>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>Adding a new user</div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <ol class="pl-4">
              <li>Click on the Admin tab under your organisation on the left sidebar menu.</li>
              <li>A drop-down menu will appear, click <u>Users</u></li>
              <li>On the top right of the page there click the “Add user” button.</li>
              <li>A pop-up form will appear. Fill in the required fields and submit a new user.</li>

            </ol>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>Understanding the status of your application</div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <p>The status bar helps you understand where your application is in the queue. The categories and their
              definitions are as follow:</p>
            <div class="row">
              <div class="col-md-2">
                <tag-box state="draft"></tag-box>
              </div>
              <div class="col-md-8  mb-3">
                <p>Your application has not been submitted and all changes made have been saved.</p>
              </div>
              <div class="col-md-2 mb-3">
                <tag-box state="under Review"></tag-box>
              </div>
              <div class="col-md-8">
                <p>Your application has been successfully submitted to our credit team and has joined the queue.</p>
              </div>
              <div class="col-md-2">
                <tag-box state="settlement"></tag-box>
              </div>
              <div class="col-md-8">
                <p>Your application has been credit approved. The outcome of your application will be revealed shortly.
                </p>
              </div>

              <div class="col-md-2">
                <tag-box state="Settled"></tag-box>
              </div>
              <div class="col-md-8">
                <p>Congratulations, your application has been successful.</p>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>Reporting a technical problem</div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <p>We’re sorry that you experienced an issue with our portal. To report a technical problem or get any help,
              please <a href="https://www.dynamoney.com/contact-us" target="_blank" rel="noopener"><u>contact our support team directly</u></a>.
            </p>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div fxLayout="column" class="supplier-name">
              <div>How to upload additional documents for an application</div>
            </div>
          </mat-expansion-panel-header>
          <ng-container>
            <div class="pl-4">
              <p>You can upload documents both by using the Drag & Drop feature or by selecting files from your
                computer.</p>
              <h4>Drag and Drop:</h4>
              <ol class="pl-4">
                <li>Select the file/s on your computer you want to upload.</li>
                <li>Drag them to the Drag and Drop zone, stroked by a dashed line.</li>
                <li>Release file/s, i.e. Drop them.</li>
                <li>Your file/s will be uploaded atomatically.</li>
              </ol>
              <h4>Select and upload</h4>
              <ol class="pl-4">
                <li>Click to the Drag and Drop zone, stroked by a dashed line.</li>
                <li>Select the file/s on your computer you want to upload.</li>
                <li>Submit your selection.</li>
                <li>Your file/s will be uploaded atomatically.</li>
              </ol>
              <h4>Please note, the allowed format of files you are able to upload are listed inside the Drag and Drop
                zone (e.g., PNG, JPG and PDF are allowed). The different applications might require different documents,
                so allowed formats might vary from case to case.</h4>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="more-faq"
           fxLayoutGap="10px"
           fxLayout="row"
           fxLayoutAlign="end center"
           fxLayout.lt-md="column"
           fxLayoutAlign.lt-md="center start">
        <button mat-flat-button
                color="primary"
                class="w-lt-md-100"
                (click)="onStillNeedHelpClick($event)">
            Still need help?
            <span class="mdi mdi-lifebuoy"></span>
        </button>
      </div>
    </div>
  </div>
</div>
