import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PrimaryIndustrySelectionComponent} from './primary-industry-selection.component';
import {SecondaryIndustrySelectionComponent} from './secondary-industry-selection.component';
import {IndustrySelectionModuleService} from './industry-selection-module.service';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PrimaryIndustrySelectionComponent,
        SecondaryIndustrySelectionComponent
    ],
    exports: [
        PrimaryIndustrySelectionComponent,
        SecondaryIndustrySelectionComponent,
    ],
    providers: [
        { provide: IndustrySelectionModuleService, useClass: IndustrySelectionModuleService },
    ]
})
export class IndustrySelectionModule {

}
