import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {
    TFAActivatedDialogData, TFAActivatedDialogResult,
    User
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getUser } from '../component-utils';
import { tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy({ arrayName: 'subscription' })
@Component({
    templateUrl: './2fa-activated.dialog.html',
    styleUrls: ['./2fa-activated.dialog.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatButtonModule, FlexModule, MatDialogModule,MatCardModule]
})
export class TFAActivatedDialog implements OnInit {
  user: User | null = null;
  formGroup!: FormGroup<{
    password: FormControl<number | null>
  }>;
  passwordControl!: FormControl;
  hide = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: TFAActivatedDialogData,
    private matDialogRef: MatDialogRef<TFAActivatedDialog, TFAActivatedDialogResult>,
    private fb: FormBuilder,
    private toastService: PortalHotToastService,
  ) {
    this.passwordControl = fb.control(null, Validators.required);
    this.formGroup = fb.group({
      password: this.passwordControl
    })
  }

  ngOnInit(): void {
    this.user = getUser()
  }

  onCancel() {
    this.matDialogRef.close();
  }

  onSubmit() {
    const email = this.user?.Email;
    if (email) {
        this.data.enableDisable2faFn(email, 1).pipe(
            this.toastService.spinnerObservable(),
            tap(r=>{
                this.matDialogRef.close();
            })
        ).subscribe();
    }
  }

}
