import {Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
// import {AddressComponentValue} from '../address-component/google-address.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {createCurrencyInputMask, PersonalAssetsFormArray} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, CustomErrorStateMatcher} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  Address2ComponentValue,
  compareMatch,
  CurrencyInputValue,
  PersonalAsset,
  PersonalLiabilitiesValueOptionType, PersonalNonPropertyAsset, PersonalPropertyAsset
} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  PersonalAssetValueOptions,
  PersonalLiabilitiesValue,
  PersonalLiabilitiesValueOption,
  PersonalLiability,
  PersonalMortgageLiability,
  PersonalNonMortgageLiability
} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatInputModule } from '@angular/material/input';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';


export interface PersonalLiabilitiesComponentEvent {
  entries: PersonalLiability[];
}

export type PersonalLiabilitiesFormArray = FormArray<PersonalLiabilityFormGroup>;
export type PersonalLiabilityFormGroup = FormGroup<{
  liabilityType: FormControl<PersonalLiabilitiesValueOptionType[number]>,
  value: FormControl<CurrencyInputValue>,
  address: FormControl<Address2ComponentValue>,
  financier: FormControl<string | null>,
}>

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'personal-liabilities',
    templateUrl: './personal-liabilities.component.html',
    styleUrls: ['./personal-liabilities.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PersonalLiabilitiesComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PersonalLiabilitiesComponent) },
    ],
    standalone: true,
    imports: [FormsModule, MatButtonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, CustomAddressComponent, MarkDirective, MatInputModule, CurrencyInputComponent, InputMaskModule]
})
export class PersonalLiabilitiesComponent extends AbstractControlValueAccessor<PersonalLiabilitiesValue> implements OnInit, Mark {

  markObservable: Subject<boolean> = new Subject<boolean>();

  errorStateMatcher = new CustomErrorStateMatcher();

  subscriptions: Subscription[] = [];

  createCurrencyMask = createCurrencyInputMask();

  @Output() events: EventEmitter<PersonalLiabilitiesComponentEvent> = new EventEmitter();

  liabilityTypes = PersonalLiabilitiesValueOption;

  formGroup: FormGroup<{
    personalLiabilities: PersonalLiabilitiesFormArray,
    totalLiabilities: FormControl<number | null>,
  }>;
  formControlTotalLiabilities: FormControl<number | null>;
  formArray: PersonalLiabilitiesFormArray;
  subscription?: Subscription;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlTotalLiabilities = formBuilder.control({value: 0, disabled: false});
    this.formArray = formBuilder.array<PersonalLiabilityFormGroup>([]);
    this.formGroup = formBuilder.group({
      personalLiabilities: this.formArray,
      totalLiabilities: this.formControlTotalLiabilities,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscription = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r) => {
        // ### deal with total liabilities value
        const totalLiabilities = this.formArray.controls.reduce((total: number, control: AbstractControl) => {
          const fg = control as FormGroup;
          const val = parseFloat((fg.controls as any).value.value);
          const v = isNaN(val) ? 0 : val;
          return (total + v);
        }, 0);
        this.formControlTotalLiabilities.setValue(totalLiabilities);

        // ### deal with propagation
        if (this.formArray.invalid) {
          this.propagateChange(null);
        } else {
          this.propagateChange({
            total: totalLiabilities,
            liabilities: this.formArrayToPersonalLiabilities(this.formArray) ?? [],
          });
        }
      })
    ).subscribe();
  }

  addLiability(a?: Exclude<PersonalLiabilitiesValue, null>['liabilities'][number]) {
    const formControlValue = this.formBuilder.control(a ? a.value : null, [Validators.required]);
    const formControlLiabilityType = this.formBuilder.control(a ? a.liabilityType : null, [Validators.required]);
    const formGroup: FormGroup = this.formBuilder.group({
      liabilityType: formControlLiabilityType,
      value: formControlValue,
    });
    const sub = formControlLiabilityType.valueChanges.pipe(
      delay(0),
      tap((r: PersonalLiability['liabilityType']|null) => {
        if (r?.type === 'mortgage') {
          formGroup.removeControl('financier');
          formGroup.addControl('address', this.formBuilder.control(a ? (a as PersonalMortgageLiability).address : null, [Validators.required]));
        } else {
          formGroup.removeControl('address');
          formGroup.addControl('financier', this.formBuilder.control(a ? (a as PersonalNonMortgageLiability).financier : null, [Validators.required]));
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    if (a && a.liabilityType.type === 'mortgage') {
      // formGroup.removeControl('financier');
      formGroup.addControl('address', this.formBuilder.control(a ? (a as PersonalMortgageLiability).address : null, [Validators.required]));
    } else {
      // formGroup.removeControl('address');
      formGroup.addControl('financier', this.formBuilder.control(a ? (a as PersonalNonMortgageLiability).financier : null, [Validators.required]));
    }
    this.formArray.push(formGroup);
    this.events.emit({ entries: this.formArrayToPersonalLiabilities(this.formArray) ?? []});
  }

  removeLiability(liabilityFormGroup: FormGroup) {
    const index = this.formArray.controls.indexOf(liabilityFormGroup);
    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    this.events.emit({ entries: this.formArrayToPersonalLiabilities(this.formArray) ?? [] });
  }


  liabilitiesFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  liabilitiesFormGroupFormControl(liabilityFormGroup: FormGroup, controlName: 'liabilityType' | 'address' | 'value' | 'financier'): FormControl {
    return liabilityFormGroup.controls[controlName] as FormControl;
  }

  onAddLiability($event: Event) {
    this.addLiability();
  }

  onRemoveLiability($event: Event, liabilityFormGroup: FormGroup) {
    this.removeLiability(liabilityFormGroup);
  }

  doWriteValue(v: PersonalLiabilitiesValue | null | undefined): void {
    this.formArray.clear()
    if (v) {
      for (const liability of v.liabilities) {
        this.addLiability(liability);
      }
    }
  }

  isMortgageLiability(liabilityFormGroup: FormGroup) {
    return (liabilityFormGroup.controls as any).address;
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }

  formArrayToPersonalLiabilities(formArray: PersonalLiabilitiesFormArray): PersonalLiability[] | null {
    if (this.formArray.invalid) {
      return null;
    }
    const personalLiabilities =  (this.formArray.value ?? []).reduce((acc: PersonalLiability[], cur) => {
      if (cur.liabilityType) {
        switch(cur.liabilityType?.type) {
          // NOTE: validator should make sure that when formArray is valid, none of cur properties should be null
          case 'mortgage': {
            const p: PersonalMortgageLiability = {
              liabilityType: cur.liabilityType,
              value: cur.value!,
              address: cur.address!
            };
            acc.push(p);
            break;
          }
          default: {
            const p: PersonalNonMortgageLiability = {
              liabilityType: cur.liabilityType,
              value: cur.value!,
              financier: cur.financier!,
            };
            acc.push(p);
            break;
          }
        }
      }
      return acc;
    }, [])
    return personalLiabilities;
  }
}
