<div class="pismo-activate-or-deactivate-card-dialog m-4">
  <h3 mat-dialog-title>Cards Settings and Details</h3>
  <mat-dialog-content>
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <div class="info-bar">
          <div class="icon">
            <span class="tooltip-icon mdi mdi-help-circle-outline ml-1"></span>
          </div>
          <div>
            <span>Only Normal and Blocked status cards can be updated</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="info-bar">
          <div class="icon">
            <span class="tooltip-icon mdi mdi-cancel ml-1"></span>
          </div>
          @if(cardDetails.status === 'NORMAL'){
            <div>
              <div >Card is active</div>
              <div class="mat-small" >Toggle off to deactivate the card</div>
            </div>
          }
          @if(cardDetails.status === 'BLOCKED'){
            <div>
              <div >Card is blocked</div>
              <div class="mat-small" >Toggle off to activate the card</div>
            </div>
          }
          @if(cardDetails.status !== 'NORMAL' && cardDetails.status !== 'BLOCKED'){
            <div>
              <div >Card is inactive</div>
              <div class="mat-small" >Cannot activate this card</div>
            </div>
          }
          @if(cardDetails.status === 'NORMAL' || cardDetails.status === 'BLOCKED'){
            <div class="toggle">
              <slide-toggle [formControl]="slideToggleFormControl">
              </slide-toggle>
            </div>
          }
        </div>
      </div>
    </div>
    <ng-container>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="details-table">
        <ng-container matColumnDef="printed_name">
          <th mat-header-cell *matHeaderCellDef>Printed Name</th>
          <td mat-cell *matCellDef="let element">{{ element.printed_name }}</td>
        </ng-container>
        <ng-container matColumnDef="last_4_digits">
          <th mat-header-cell *matHeaderCellDef>Card last 4 digits</th>
          <td mat-cell *matCellDef="let element">
            {{ element.last_4_digits }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>
        <ng-container matColumnDef="expiration_date">
          <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.expiration_date | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="activeDeactiveToggle">
          <th mat-header-cell *matHeaderCellDef>Card Status</th>
          <td mat-cell *matCellDef="let element">
            <slide-toggle [formControl]="element.slideToggleFormControl">
            </slide-toggle>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div
              fxLayout="row wrap"
              fxLayoutAlign="start center"
              fxLayout.lt-md="column"
              fxLayoutAlign.lt-md="center center"
            >
              <span
                class="mdi mdi-credit-card-refresh-outline cursor-pointer"
                matTooltip="Reissue Card"
                (click)="onReissueCard()"
              ></span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayColumns"
          class="example-element-row"
        >
          <!-- (click)="tokenDetails && tokenDetails.length > 0 && expandedElement = expandedElement === element ? null : element"> -->
        </tr>
      </table>
      @if(tokenDetails && tokenDetails.length > 0){
        <table mat-table [dataSource]="tokenDetailsDataSource" class="details-table mb-3 mt-3">
          <!-- Define columns for DPAN, Device Type, and other details -->
          <ng-container matColumnDef="dpan">
            <th mat-header-cell *matHeaderCellDef>DPAN</th>
            <td mat-cell *matCellDef="let detail">
              {{ detail.network_data.TokenSuffix ?? 'N/A' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceId">
            <th mat-header-cell *matHeaderCellDef>Device Id</th>
            <td mat-cell *matCellDef="let detail">
              {{ detail.network_data.Device.DeviceType ?? 'N/A' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="deviceType">
            <th mat-header-cell *matHeaderCellDef>Device Type</th>
            <td mat-cell *matCellDef="let detail">
              {{
                detail.network_data.Device.DeviceDescription ?? 'N/A'
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="platform">
            <th mat-header-cell *matHeaderCellDef>Platform</th>
            <td mat-cell *matCellDef="let detail">
              {{ detail.network_data.TokenRequestorName ?? 'N/A' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let detail">
              {{ detail.token.status ?? 'N/A' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="mastercard">
            <th mat-header-cell *matHeaderCellDef>Mastercard</th>
            <td mat-cell *matCellDef="let detail">
              {{ detail.network_data.CurrentStatusDescription ?? 'N/A' }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'dpan',
              'deviceId',
              'deviceType',
              'platform',
              'status',
              'mastercard'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let detail;
              columns: [
                'dpan',
                'deviceId',
                'deviceType',
                'platform',
                'status',
                'mastercard'
              ]
            "
          ></tr>
        </table>
      }
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
