import {Component, OnInit} from '@angular/core';

import {DigitalIdComponent, MessageBoxComponent} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {concat, Subscription} from 'rxjs';
import {DigitalIdService} from '../../service/digital-id.service';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {ActivatedRoute} from '@angular/router';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {DigitalIdAuthenticateFn, DigitalIdGetClientIdFn} from '@portal-workspace/grow-ui-library';
import {finalize, tap} from 'rxjs/operators';
import {
  CheckUserVerificationCodePayloadResult,
  DigitalIdResult,
  DigitalIdComponentEvent
} from '@portal-workspace/grow-shared-library';
import {parseDigitalIdResponse} from '@portal-workspace/grow-ui-library';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './digital-id-user-verification.page.html',
  styleUrls: ['./digital-id-user-verification.page.scss'],
  standalone: true,
  imports: [MessageBoxComponent, DigitalIdComponent]
})
export class DigitalIdUserVerificationPage implements OnInit {

  getClientFn!: DigitalIdGetClientIdFn;
  authenticateFn!: DigitalIdAuthenticateFn;

  subscriptions: Subscription[] = [];

  alerts: string[] = [];
  messages: string[] = [];
  checkResult?: CheckUserVerificationCodePayloadResult;
  verificationSessionToken?: string;
  allowDigitalIdVerification = false;
  verificationResult?: DigitalIdResult;
  doingVerification = false;

  verificationCode?: string;
  token?: string;

  constructor(private digitalIdService: DigitalIdService,
              private minimalLayoutService: MinimalLayoutService,
              private route: ActivatedRoute) {
    this.getClientFn = this.digitalIdService.clientIdFn;
    this.authenticateFn = this.digitalIdService.authenticateFn;
  }

  sendReactNativeMessage(message: string) {
    if (window && (window as any).ReactNativeWebView) {
      try {
        (window as any).ReactNativeWebView.postMessage(message);
      } catch (err) {
        // swallow all errors
      }
    }
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false
    });
    this.verificationCode = this.route.snapshot.params.verificationCode;
    this.token = this.route.snapshot.params.token;
    if (!this.verificationCode) {
      this.alerts.push('Invalid verification code');
      return;
    }
    this.subscriptions.push(this.digitalIdService.checkUserVerificationCode(this.verificationCode)
      .pipe(
        tap(r => {
          if (r.payload.isError) {
            const messages = r.payload.messages.map(a => a.message);
            this.alerts.push(...messages);
          } else {
            const messages = r.payload.messages.map(a => a.message);
            this.messages.push(...messages);
            this.checkResult = r.payload.result;
            const digitalIdResponse = r.payload.result!.digitalIdResponse;
            if (digitalIdResponse && digitalIdResponse.verification_session_token) {
              // todo: DigitalIdVerificationSessionToken
              this.verificationSessionToken = digitalIdResponse.verification_session_token;
            }
            this.allowDigitalIdVerification = true;
          }
        })
      ).subscribe());
  }

  onDigitalIdEvent($event: DigitalIdComponentEvent) {
    // console.log('******************* onDigitalIdEvent ', $event);
    switch ($event.authResult.type) {
      case 'error': {
        this.alerts = [];
        this.alerts.push(`${$event.authResult.error} - ${$event.authResult.errorDescription}`);
        this.sendReactNativeMessage('DigitalIdVerificationError');
        break;
      }
      case 'success': {
        this.allowDigitalIdVerification = false;
        const rst = parseDigitalIdResponse($event);
        // console.log('digitalId result', $event.authResult);
        // console.log('parsed result', rst);
        if (!this.checkResult) {
          alert(`Run digital id verification first`);
          return;
        }
        const userId = this.checkResult.userId;
        this.doingVerification = true;
        concat(
          this.digitalIdService
            .updateAndKycVerifyUser(userId, {
              verificationCode: this.verificationCode!, // will not be able to get here if verificationCode is falsy
              digitalIdAuthenticationPayload: $event.authResult.payload
            })
            .pipe(
              tap(r => {
                // console.log(r);
                this.verificationResult = r.payload.result;
                const status = this.verificationResult?.status;
                this.sendReactNativeMessage(`DigitalIdVerificationStatus-${status}`);
              }),
            ),
        ).pipe(
          finalize(async () => {
            this.doingVerification = false;
          })
        ).subscribe();

        break;
      }
    }

  }
}

