import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DigitalIdComponentEvent, DigitalIdResult, IndividualWithResult, isInternalUserOrBroker } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import _ from 'lodash';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { DatePipe } from '@angular/common';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { DigitalIdAuthenticateFn, DigitalIdComponent, DigitalIdGetClientIdFn, DigitalIdVerifyDialogData } from '../digital-id-component/digital-id.component';
import { getUser } from '../component-utils';

@Component({
  templateUrl: './digital-id-verify.dialog.html',
  styleUrls: ['./digital-id-verify.dialog.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, TagBoxComponent, DatePipe, TruncatePipe, DigitalIdComponent]
})
export class DigitalIdVerifyDialog {

  kycResult: any;
  getClientFn: DigitalIdGetClientIdFn;
  authenticateFn: DigitalIdAuthenticateFn;
  completeCallbackFn: any;
  individual: IndividualWithResult;
  private onClickCalled: boolean = false;
  private closeTimeout: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DigitalIdVerifyDialogData,
    private matDialogRef: MatDialogRef<DigitalIdVerifyDialog, IndividualWithResult>,
    private formBuilder: FormBuilder) {

    this.kycResult = data.kycResult;
    this.getClientFn = data.getClientFn;
    this.authenticateFn = data.authenticateFn;
    this.completeCallbackFn = data.digitalIdOnCompleteCallbackFn.bind(this);
    this.individual = data.individual;

    // Set a timeout to close the dialog after 5 minutes (300000 ms)
    this.closeTimeout = setTimeout(() => {
      if (!this.onClickCalled) {
        this.matDialogRef.close();
      }
    }, 300000); // 5 mins

  }

  onClick = () => {
    this.onClickCalled = true;
    clearTimeout(this.closeTimeout);
  }

  onComplete = (_kycResult: any) => {
    this.completeCallbackFn(_kycResult, this.individual);
    this.matDialogRef.close();
  }

  capitalize(s: any): string {
    return s.toString() ? _.startCase(s.toString()) : s;
  }

  defaultString(s: any): string {
    return s ? s : 'Not available';
  }

  getVerificationSessionToken(result: DigitalIdResult): string {
    const digitalIdResult = result;
    switch (digitalIdResult.status) {
      case 'RECEIVED_INCOMPLETE': {
        return digitalIdResult.digitalIdResponse?.verification_session_token ?? '';
      }
    }
    return '';
  }

  verificationStatusDisplay(verificationStatus?: string): string {
    if (verificationStatus) {
      switch (verificationStatus) {
        case 'completed':
          return 'Completed';
        case 'in_progress':
          return 'In Progress';
        case 'failed':
          return 'Failed';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  datasourceEventDisplay(event: string): string {
    if (event) {
      switch (event) {
        case 'credit_header_check_failed':
          return 'Credit header check failed';
        case 'name_and_address_matched':
          return 'Name and address matched';
        case 'name_and_dob_matched':
          return 'Name and DOB matched';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  sourcesCategoryDisplay(sources_category: string): string {
    if (sources_category) {
      switch (sources_category) {
        case 'pep':
          return 'Pep';
        case 'sanctions':
          return 'Sanctions';
        case 'pep_and_sanctions':
          return 'Pep and Sanctions';
        case '':
          return 'Not available';
      }
    }
    return 'Not available';
  }

  isCustomer(): boolean {
    return !isInternalUserOrBroker(getUser());
  }

  async onDigitalIdEvent($event: DigitalIdComponentEvent) {
    console.log('***** digital-id-result', $event);
  }

}
