import {Component, OnInit} from '@angular/core';
import { MinimalLayoutHeaderComponent } from './minimal-layout-header.component';
import {MinimalLayoutService} from './minimal-layout.service';
import {LayoutService} from '../../service/layout.service';
import { RouterOutlet } from '@angular/router';
import {ErrorNotification2, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import {WhitelabelService} from "../../service/whitelabel.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Observable} from "rxjs";
import {PortalThemeServiceEvent, WhitelabelServiceEvent} from "@portal-workspace/grow-shared-library";
import {PortalThemeService} from "../../service/portal-theme.service";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './minimal.layout.html',
    styleUrls: ['./minimal.layout.scss'],
    providers: [
        { provide: LayoutService, useClass: LayoutService },
        { provide: MinimalLayoutService, useClass: MinimalLayoutService },
    ],
    standalone: true,
    imports: [FlexModule, MinimalLayoutHeaderComponent, ErrorNotification2, RouterOutlet]
})
export class MinimalLayout implements OnInit {


  whitelabelServiceEventObserver!: Observable<WhitelabelServiceEvent>;
  portalThemeObservable!: Observable<PortalThemeServiceEvent>;

  constructor(private minimalLayoutService: MinimalLayoutService,
              private whitelabelService: WhitelabelService,
              private portalThemeService: PortalThemeService) {
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.whitelabelServiceEventObserver = this.whitelabelService.subject.asObservable();
    this.portalThemeObservable = this.portalThemeService.subject.asObservable();
  }


}
