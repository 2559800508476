import {DestroyRef, inject, Injectable} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {BehaviorSubject, Subject, Subscription, takeUntil} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {distinctUntilChanged, tap} from "rxjs/operators";


export const BREAKPOINTS_MAP: {[p: string]: BreakpointTypes} = {
  [Breakpoints.XSmall]: 'XSmall',
  [Breakpoints.Small]: 'Small',
  [Breakpoints.Medium]: 'Medium',
  [Breakpoints.Large]: 'Large',
  [Breakpoints.XLarge]: 'XLarge',
} as const;

export type BreakpointTypes = 'XSmall' | 'Small' | 'Medium' | 'Large' | 'XLarge';

const MobileBreakpointTypes = ['XSmall', 'Small', 'Medium'];


@Injectable()
export class BreakpointService {

  breakpointObservable: BreakpointObserver;
  private _breakpointsSubject = new BehaviorSubject<BreakpointTypes[]>([]);
  breakpointsSubject = this._breakpointsSubject.asObservable();
  private _deviceSubject  = new BehaviorSubject<"Desktop" | "Mobile"  | null>(null);
  deviceSubject = this._deviceSubject.asObservable().pipe(
    distinctUntilChanged(),
  );



  constructor() {
    this.breakpointObservable = inject(BreakpointObserver)
    const destroyRef = inject(DestroyRef);
    this.breakpointObservable.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).pipe(
      takeUntilDestroyed(destroyRef),
      tap(r => {
        const breakpoints: BreakpointTypes[] = [];
        for (const breakpoint of Object.keys(r.breakpoints)) {
          if (r.breakpoints[breakpoint]) { // this break is active
            breakpoints.push(BREAKPOINTS_MAP[breakpoint]);
          }
        }
        this._breakpointsSubject.next(breakpoints);
        this._deviceSubject.next(this.isMobile(breakpoints) ? 'Mobile' : 'Desktop');
      })
    ).subscribe();
  }

  isMobile(breakpoints: BreakpointTypes[]): boolean {
    return (breakpoints ?? []).some(element => MobileBreakpointTypes.includes(element));
  }
}
