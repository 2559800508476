import {Component, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DatepickerComponent} from './datepicker.component';
import {InputMaskModule} from '@ngneat/input-mask';
import {Datepicker2Component} from './datepicker2.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DatepickerComponent,
        Datepicker2Component
    ],
    exports: [
        DatepickerComponent,
        Datepicker2Component,
    ]
})
export class DatepickerModule {

}
