<div class="registration-page container-fluid">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>

<ng-template #step1>
  <div class="step1">
    <div class="mat-h3 text-align-center">Set up your broker account</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroupStep1">
      <div fxFlex="1 0 auto" class="form-field">
        <companies-search
          [searchFn]="companiesSearchFn"
           
          [allowCancellation]="true"
          [formControl]="formControlCompany"
          >
        </companies-search>
      </div>
      @if (showApplicantFrom) {
        <div fxFlex="1 0 auto" class="form-field">
          <name title="First Name" [formControl]=formControlFirstName></name>
        </div>
        <div fxFlex="1 0 auto" class="form-field">
          <name title="Last Name" [formControl]=formControlLastName></name>
        </div>
        <mat-form-field>
          <input matInput [inputMask]="createEmailInputMask" type="text" matTooltip="{{formControlEmail.value}}" [formControl]="formControlEmail" placeholder="Email">
          @for (errorKey of errorKeys(formControlEmail); track errorKey) {
            <mat-error>
              @if (formControlEmail.touched && formControlEmail.hasError(errorKey)) {
                {{errorMessage(formControlEmail, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <input matInput [inputMask]="createPhoneNumberInputMask" type="text" [formControl]="formControlMobileNumber" placeholder="Mobile number">
        </mat-form-field>
        <div fxFlex="1 0 auto" class="form-field">
          <confirm-password-admin [formControl]="formControlConfirmPassword" ></confirm-password-admin>
        </div>
      }
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutGap="10px" class="form-field">
        <mat-checkbox color="primary" (change)="accreditionsPage($event)">
        </mat-checkbox>
        <span class="mat-body form-text mt-2">
          I couldn't find my company
        </span>
      </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-flat-button color="primary" [disabled]="formGroupStep1.invalid" (click)="onFinishSetup($event)">Create an account</button>
      </div>
      <div fxFlex="1 0 auto" class="form-field desc-1">
        <span class="mat-body form-text">
          By clicking 'Create an account', I agree to use of my details to contact me about my specific request or inquiry,
          and I agree to the Terms of Use and Privacy Notice.
        </span>
      </div>
      <div fxFlex="1 1 auto" class="width-100 separator-bottom">
        <mat-divider [inset]="false"></mat-divider>
      </div>
      <div class="width-100" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
        <div class="mat-h4-custom">Already have an account?</div>
      </div>
      <div fxFlex="1 1 auto" class="form-button">
        <button mat-stroked-button color="primary"  (click)="onLoginAgain($event)">Sign In</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmation>
  <div class="confirmation">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center">
      Your registration has been send in for review. You should receive further notification through email.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="form-button">
      <button fxFlex="1 0 auto" mat-stroked-button color="primary" (click)="onLoginAgain($event)">Login Now</button>
    </div>
  </div>
</ng-template>
