import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AssetSettlementGetApplicationAssetFn,
   CreateApplicationNoteFn,
  isInternalUser,
  RemoveApplicationNoteByNoteIdFn,
  TermRateForCorporateLoan,
  User,
  CorporateLoanApplication
} from '@portal-workspace/grow-shared-library';
import {GetApplicationAuditLogsFn} from '../../asset-listing-component/audit-log.component';
import {GetNotesByApplicationIdFn} from '../../asset-listing-component/notes.component';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {Subject, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { getUser, setupUntilDestroy } from '../../component-utils';
import { AssetNotesComponent } from '../../asset-listing-component/asset-notes.component';
import { CorporateLoansQuoteComponent } from './corporate-loans-quote.component';

import { MatCardModule } from '@angular/material/card';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'corporate-loans-settlement',
  templateUrl: './corporate-loans-settlement.component.html',
  styleUrls: ['./corporate-loans-settlement.component.scss'],
  providers: [
    { provide: MARK, useExisting: forwardRef(() => CorporateLoansSettlementComponent) }
  ],
  standalone: true,
  imports: [MatCardModule, CorporateLoansQuoteComponent, AssetNotesComponent]
})
export class CorporateLoansSettlementComponent implements OnInit, Mark {

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();

  @Input({required: true}) application!: CorporateLoanApplication;
  @Input({required: true}) data!: TermRateForCorporateLoan;
  @Input({required: false}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;

  isAssetUpdated!: Boolean;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  
  isInternalUser = isInternalUser
  loggedInUser: User | null = getUser()

  mark(): void {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }
}
