import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  requiredToBeTrueValidator,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {compareMatch, YesNoValue} from '@portal-workspace/grow-shared-library';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { DisableControlDirective } from '../../directives/disable-control.directive';


@UntilDestroy()
@Component({
    selector: 'yes-no',
    templateUrl: './yes-no.component.html',
    styleUrls: ['./yes-no.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YesNoComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => YesNoComponent) },
    ],
    standalone: true,
    imports: [FlexModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, NgClass, ExtendedModule, MatButtonToggleModule, DisableControlDirective]
})
export class YesNoComponent extends AbstractControlValueAccessor<YesNoValue> implements OnInit,Mark {

  @Input({required: false}) title = 'Operates in a commercial premise?';
  @Input({required: false}) notselected:boolean=false;   // allow null to be null, else null is treated as false
  @Input({required: false}) yesText = 'Yes';
  @Input({required: false}) noText = 'No';
  @Input({required: false}) mustBeTrue = false; // if true selection must be true else validation error

  formGroup!: FormGroup<{
    control: FormControl<YesNoValue>
  }>;
  formControl!: FormControl<YesNoValue>;
  subscription?: Subscription;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const validators = this.mustBeTrue ? [Validators.required, requiredToBeTrueValidator()] : [Validators.required];
    this.formControl = this.formBuilder.control(true, validators);
    this.formGroup = this.formBuilder.group({
      control: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.mustBeTrue) {
          if (!r) {
            this.propagateChange(null);
          } else {
            this.propagateChange(r);
          }
        } else {
          this.propagateChange(r);
        }
      })
    ).subscribe();
  }

  doWriteValue(v: YesNoValue | undefined): void {
    if(this.notselected)
      this.formControl.setValue(v==true || v==false ? v : null);
    else
      this.formControl.setValue(v ? v : false);
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
