import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { LooseDatePipe } from '../../pipes/loose-date.pipe';
import { getUser, openWindowAndDownloadWithFilename, setupUntilDestroy, } from "../component-utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import {
    DEFAULT_LIMIT,
   GetPismoAccountTimelineFn, PismoTimeline,
} from "@portal-workspace/grow-shared-library";
import { tap } from "rxjs/operators";
import moment from 'moment';
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { PageEvent } from "@angular/material/paginator";
import { CustomPaginatorComponent } from "../custom-paginator-component/custom-paginator/custom-paginator.component";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'overdraft-account-timeline',
    standalone: true,
    templateUrl: './overdraft-account-timeline.component.html',
    styleUrls: ['./overdraft-account-timeline.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    AsyncPipe,
    JsonPipe,
    FlexModule,
    MatInputModule,
    ContentLoaderModule,
    NgClass,
    MatFormFieldModule,
    LooseDatePipe,
    NgxJsonViewerModule,
    CustomPaginatorComponent,
],
})
export class OverdraftAccountTimelineComponent implements OnInit {

    moment = moment
    loggedInUser = getUser();
    total = 0;
    limit = DEFAULT_LIMIT;
    offset = 0;

    subscriptions: Subscription[] = [];
    timeline: PismoTimeline[] = [];
    dataSource: PismoTimeline[] = [];
    displayColumns = ['type', 'category', 'timestamp'];
    expandedElement!: PismoTimeline | null;

    @Input({ required: true }) pismoAccountNumber!: number;
    @Input({ required: true }) getPismoAccountTimelineFn!: GetPismoAccountTimelineFn;

    toastService: PortalHotToastService;

    constructor() {
        this.toastService = inject(PortalHotToastService);
    }


    ngOnInit() {
        setupUntilDestroy(this);
        this.subscriptions.push(
            this.getPismoAccountTimelineFn(this.pismoAccountNumber).pipe(
                this.toastService.spinnerObservable()
            ).subscribe(timeline => {
                console.log('=timeline: ', timeline)
                this.timeline = timeline;
                this.total = this.timeline.length;
                this.reload();
            })
        )
    }


    reload() {
        this.dataSource = this.timeline.slice(this.offset * this.limit, this.offset * this.limit + this.limit);
    }

    onPagination($event: PageEvent) {
        this.offset = $event.pageIndex;
        this.limit = $event.pageSize;
        this.reload();
    }
}
