import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { AddCreditAlertFn, CreditAlert, DEFAULT_LIMIT, DEFAULT_OFFSET, GetCreditAlertByStatusFn, GetCreditAlertsFn, GetCreditFlowsFn, UpdateCreditAlertFn, UpdateCreditFlowFn } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TagBoxComponent } from '../message-box/tag-box.component';
import moment from 'moment';

@Component({
    selector: 'credit-alerts',
    templateUrl: './credit-alerts.component.html',
    styleUrls: ['./credit-alerts.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      TagBoxComponent,
    ]
})
export class CreditAlertsComponent implements OnInit {
  @Input({required: true}) getCreditAlertsFn!: GetCreditAlertsFn;
  @Input({required: true}) updateCreditAlertFn!: UpdateCreditAlertFn;
  @Input({required: true}) addCreditAlertFn!: AddCreditAlertFn;
  @Input ({required: true}) getCreditAlertByStatusFn!: GetCreditAlertByStatusFn; 

  subscriptions: Subscription[] = [];
  moment = moment;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: CreditAlert[] = [];
  filteredDataSource: CreditAlert[] = [];
  displayedData: CreditAlert[] = [];
  columnsToDisplay = ['name', 'status', 'code', 'actions'];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.formControlSearch = this.formBuilder.control(null);
  }


  ngOnInit(): void {
    this.reload();

    this.subscriptions.push(
      this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(r => {
          if (r) {
            const text = r.trim().toLowerCase();
            this.filteredDataSource = this.dataSource.filter(alert => 
              (`${alert.name.trim().toLowerCase()} ${alert.status.trim().toLowerCase()}`).includes(text)
            );
            this.initPagination();
          } else {
            this.filteredDataSource = this.dataSource;
            this.initPagination();
          }
        })
      ).subscribe()
    )
  }

  reload() {
    this.subscriptions.push(
      this.getCreditAlertsFn().pipe(
        this.loader.tableLoading.track()
      ).subscribe(flows => {
        this.dataSource = flows;
        this.filteredDataSource = this.dataSource;
        this.initPagination();
      })
    )
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'name': return 'Name';
      case 'status': return 'Status';
      case 'code': return 'Code';
      // case 'lastUpdatedTime': return 'Last Updated Time';
      // case 'lastUpdatedBy': return 'Last Updated By';
      case 'actions': return '';
      default: return column;
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    console.log("Error Filter Data", this.filteredDataSource)
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  
  }
  
  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  addCreditAlert() {
    this.subscriptions.push(
      this.dialogService.openEditCreditAlertDialog({
        name: '',
        description: '',
        code: '',
        status: null,
        getCreditAlertByStatusFn: this.getCreditAlertByStatusFn
        
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.addCreditAlertFn({
              name: response.name,
              description: response.description,
              status: response.status,
              code: response.code,
            }).pipe(
              this.toastService.spinnerObservable()
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  editCreditAlert(element: CreditAlert) {
    this.subscriptions.push(
      this.dialogService.openEditCreditAlertDialog({
        name: element.name,
        description: element.description,
        code: element.code,
        status: element.status,
        getCreditAlertByStatusFn: this.getCreditAlertByStatusFn
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.updateCreditAlertFn({
              name: response.name,
              description: response.description,
              status: response.status,
              code: response.code,
            }, element.id).pipe(
              this.toastService.snackBarObservable(`Credit alert updated!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  deleteCreditAlert(element: CreditAlert) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Alert",
        subMessage: "Are you sure you want to delete this credit alert"
      }).afterClosed().subscribe(
        response => {
          if(response?.readyForSubmission) {
            this.updateCreditAlertFn({isDeleted: true}, element.id).pipe(
              this.toastService.snackBarObservable(`Credit alert deleted!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }
}
