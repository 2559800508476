import {Component} from '@angular/core';
import {ConsumerAssetFinancePage} from '../consumer-asset-finance-page/consumer-asset-finance.page';
import {AssetSelectionComponentValue} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {FinanceTypeValueOptions as FinanceTypeOptions} from '@portal-workspace/grow-shared-library';
import {AssetConditionValueOptions as AssetConditionOptions} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValueOptions as BrokerageSelectionOptions} from '@portal-workspace/grow-shared-library';
import {BalloonPaymentValueOptions as BalloonPaymentOptions} from '@portal-workspace/grow-shared-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { EmployerComponent } from '@portal-workspace/grow-ui-library';
import { IncomeComponent } from '@portal-workspace/grow-ui-library';
import { EmploymentStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaritialStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { PropertyOwnerWithAddressComponent } from '@portal-workspace/grow-ui-library';
import { TimeInAddressComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { GenderComponent } from '@portal-workspace/grow-ui-library';
import { DatepickerComponent } from '@portal-workspace/grow-ui-library';
import { TitleSelectionComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { ConsumerLoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ApplicationStepper2Component } from '@portal-workspace/grow-ui-library';
import { FormControl } from '@angular/forms';
import { YesNoValue } from '@portal-workspace/grow-shared-library';

@Component({
    templateUrl: './consumer-finance.page.html',
    styleUrls: ['./consumer-finance.page.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, NgTemplateOutlet, FormsModule, ReactiveFormsModule, NameComponent, AggregatorSearchComponent, MarkDirective, CurrencyInputComponent, AssetSelectionComponent, ConsumerLoanTermsSelectionComponent, PaymentFrequencySelectionComponent, YesNoComponent, ApplicationHeaderSegmentComponent, TitleSelectionComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, TimeInAddressComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, MaritialStatusSelectionComponent, MatFormFieldModule, MatInputModule, EmploymentStatusSelectionComponent, IncomeComponent, EmployerComponent, ReferenceComponent, MessageBoxComponent, UploadFileComponent]
})
export class ConsumerFinancePage extends ConsumerAssetFinancePage {


  step2AssetCategoryIndexFilter = ['106'];
  formControlStep2PrivateSale!: FormControl<YesNoValue>;

  initStep2() {
    super.initStep2();
    // const a: AssetSelectionComponentValue = null;

    const financeTypeOption = FinanceTypeOptions.find(o => o.type === 'chattel-mortgage');
    const assetConditionOption = AssetConditionOptions.find(o => o.type === 'New');
    const brokerageSelectionOption = BrokerageSelectionOptions.find(o => o.type === '0');
    const balloonPaymentOption = BalloonPaymentOptions.find(o => o.type === '0');

    this.formControlStep2FinanceType.setValue(financeTypeOption ?? null);
    this.formControlStep2DocFeeFinanced.setValue(false);
    this.formControlStep2AssetCondition.setValue(assetConditionOption ?? null);
    this.formControlStep2PrivateSale.setValue(false);
    this.formControlStep2EquifaxScoreAboveThreshold.setValue(true);
    this.formControlStep2AdverseOnFile.setValue(false);
    // this.formControlStep2Brokerage.setValue(brokerageSelectionOption ?? null);
    this.formControlStep2BalloonPayment.setValue(balloonPaymentOption ?? null);
    this.formControlStep2CreditAssistanceFee.setValue(0);
  }

  // async mapToInteflowData(): Promise<UpdateApplicationData> {
  //   const d = await super.mapToInteflowData();
  //   if (d && d.AssetSpec) {
  //     d.AssetSpec.year = String(moment().year());
  //   }
  //   return d;
  // }
}
