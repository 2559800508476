<div class="aggregator-and-originator-list-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" matTooltip="{{formControl.value!==null?formControl.value!.EntityName:'' }}" [disableControl]="disabled">
      @for (originator of originators; track originator) {
        <mat-option [value]="originator" class="mt-2">
          {{originator.EntityName}} ({{originator.SalesforceId}})
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
