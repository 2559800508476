import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {UntilDestroy} from '@ngneat/until-destroy';
import { setupUntilDestroy, OverdraftCustomerSearchComponent, CustomContentLoaderComponent, getUser, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import { ApplicationSortTypes, PortalLoginUser, SimplifiedApplication, displayApplicationDateTime, isAssociatedWithAggregator, isInternalUser } from "@portal-workspace/grow-shared-library";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { ApplicationService, } from '../../service/application.service';
import { BehaviorSubject, Observable, Subscription, tap} from "rxjs";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatTableModule } from '@angular/material/table';
import { ApplicationTypeIconComponent } from '@portal-workspace/grow-ui-library';
import { AsyncPipe, NgClass } from '@angular/common';
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { createAsyncStore, loadingFor } from "@ngneat/loadoff";
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router } from "@angular/router";
import { navigationUrlForApplication } from "../../service/navigation-urls";
import moment from "moment";

import { Sort, MatSortModule } from '@angular/material/sort';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import {PageEvent} from "@angular/material/paginator";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/operators";


export class InternalDataSources extends DataSource<SimplifiedApplication> {

    subject: BehaviorSubject<SimplifiedApplication[]> = new BehaviorSubject<SimplifiedApplication[]>([]);

    connect(_collectionViewer: CollectionViewer): Observable<SimplifiedApplication[]> {
      return this.subject.asObservable();
    }

    disconnect(_collectionViewer: CollectionViewer): void {
      this.subject.complete();
    }

    update(applications: SimplifiedApplication[]) {
      this.subject.next(applications);
    }
  }


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './all-application-search.page.html',
    styleUrls: ['./all-application-search.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatInputModule, OverdraftCustomerSearchComponent, MatTableModule, ApplicationTypeIconComponent, CustomContentLoaderComponent, AsyncPipe, NgClass, MatFormFieldModule, ReactiveFormsModule, TagBoxComponent, MatSortModule, CustomPaginatorComponent]
})

export class AllApplicationSearchPage implements OnInit {

    @ViewChild('searchText') searchTextRef!: ElementRef<HTMLInputElement>;
    search = '';
    user: PortalLoginUser | null = null;
    subscriptions: Subscription[] = [];
    loader = loadingFor('tableLoading');
    errorTitle =  'Error Occurred!'
    errorMessage = 'Please try again.'
    store = createAsyncStore();
    sorts?: ApplicationSortTypes = undefined;
    total = 0;
    limit = DEFAULT_LIMIT;
    offset = DEFAULT_OFFSET;
    filter = '';
    retry(){
        this.reload(this.offset, this.limit, this.search, this.sorts);
    }
    formControlSearch: FormControl<string | null>;

    dataSource = new InternalDataSources();

    displayColumns = ['applicationId','appName','appStatus','appStage'];

    constructor(
        private applicationService: ApplicationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: PortalHotToastService,
    ){
        this.formControlSearch = this.formBuilder.control('');
    }
      ngOnInit(): void {
        setupUntilDestroy(this);
        this.user = getUser();
        this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          tap(r => {
            this.search = r ?? '';
            this.reset();
            this.reload(this.offset, this.limit, this.search, this.sorts);
          })
        ).subscribe());
      }

      ngAfterViewInit(): void {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const forceReload = queryParams.reload === 'true';
        setTimeout(()=>{
          this.reload();
        });
      }

      onSearch($event: Event) {
        // this.search = ($event.target as HTMLInputElement).value;
        // this.reset();
        // this.reload(this.offset, this.limit, this.search, this.sorts);
      }

      onSortData($event: Sort) {
          if ($event.direction && $event.active) {
            this.sorts = [{prop: $event.active, dir: $event.direction}] as ApplicationSortTypes;
          } else {
            this.sorts = null;
          }
          this.reset();
          this.reload(this.offset, this.limit, this.search, this.sorts);
        }

      onPagination($event: PageEvent) {
          this.limit = $event.pageSize;
          this.offset = $event.pageIndex;
          this.reload(this.offset, this.limit, this.search, this.sorts);
      }

      reload(
        offset: number = DEFAULT_OFFSET,
        limit: number = DEFAULT_LIMIT,
        search: string = '',
        sorts?: ApplicationSortTypes) {
        this.store= createAsyncStore();
        this.subscriptions.push(this.applicationService.getAllApplicationSearch({
          page: {offset, limit},
          filter: search,
          sorts
          })
        .pipe(
            this.loader.tableLoading.track(),
            this.toastService.publishErrorNotificationObservable({
                errorTitle: this.errorTitle,
                errorMessage: this.errorMessage,
                retryFn: this.retry.bind(this),
            }),
            this.store.track(),
            tap(r =>{
                this.total= r.total;
                this.dataSource.update(r.payload)
            })
        ).subscribe())
      }

      getApplicationCreateTime(app: SimplifiedApplication) {
          return displayApplicationDateTime(moment(app.CreateTime));
      }

      getApplicantName(app: SimplifiedApplication) {
          const entityLegalName = (app as any)['indexAppName'];
          if (entityLegalName) {
            return entityLegalName;
          } else if (app.ApplicationType === 'Consumer') {
            const givenName = (app as any)['IndividualGivenName'];
            const surName = (app as any)['IndividualSurName'];
            if (givenName || surName) {
              return `${givenName ?? ''} ${surName ?? ''}`.trim();
            }
          }
          return '';
      }

      showBrokerName(): boolean {
          return isInternalUser(this.user) || isAssociatedWithAggregator(this.user);
      }

      getApplicationStatus(app: SimplifiedApplication) {
          const stage = (app as any).AppInfoStageName;
          const salesforceId = (app as any).AppInfoSalesforceID;
          return this.applicationService.getApplicationStatus2(stage, salesforceId);
      }

      getApplicationStage(app: SimplifiedApplication) {
          const stage = (app as any).AppInfoStageName;
          return this.applicationService.getApplicationStage2(stage);
      }

      async onRowClicked($event: MouseEvent, element: SimplifiedApplication) {
          const applicationId = element.ApplicationId;
          await this.router.navigate(navigationUrlForApplication(String(element.ApplicationId), 'app'));
      }

      reset() {
          this.limit = DEFAULT_LIMIT;
          this.offset = 0;
      }

}
