<div class="overdraft-account-statement-details-component container-fluid">
  @if (loader.tableLoading.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }

  @if (!(loader.tableLoading.inProgress$ | async) && datasource) {
    <div class="row mb-2">
      @if (canAccess) {  
      <div class="col-lg-3 col-md-12">
      </div>
      <div class="col-lg-9 col-md-12">
        <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="end center" fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center center">
          <button class="custom-button w-lt-md-100" (click)="downloadInPDF()">
            <span class="mdi mdi-file-pdf-box"></span>
            <span class="text">Download PDF</span>
          </button>
          <button class="custom-button w-lt-md-100" (click)="downloadInCSV()">
            <span class="mdi mdi-file-document-outline icon"></span>
            <span class="text">Download CSV</span>
          </button>
          <button class="custom-button w-lt-md-100" (click)="downloadInOFX()">
            <span class="mdi mdi-file-document-outline icon"></span>
            <span class="text">Download OFX</span>
          </button>
        </div>
      </div>
    }
    </div>
    <!--First row-->
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <div class="row pismo-account-details header">
          <span class="mdi mdi-information-outline mx-2"></span>
          <div class="col-lg-6 col-md-12">Account Holder</div>
        </div>
        <div class="section">
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Attn: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.contact.name}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-5 col-md-12 title">Business Name:</div>
            <div class="col-lg-7 col-md-12 amount">{{datasource.company.name}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-5 col-md-12 title">Addr:</div>
            <div class="col-lg-7 col-md-12 amount">{{datasource.company.address}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-5 col-md-12 title">ABN: </div>
            <div class="col-lg-7 col-md-12 amount">{{datasource.company.abn}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-5 col-md-12 title">ACN: </div>
            <div class="col-lg-7 col-md-12 amount">{{datasource.company.acn}}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 rowHeight">
        <div class="row pismo-account-details header">
          <span class="mdi mdi-account-circle-outline mx-2"></span>
          <div class="col-lg-5 col-md-12">Account Number</div>
          <div class="col-lg-5 col-md-12 amount">{{datasource.pismo.account}}</div>
        </div>
        <div class="section">
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Statement Period: </div>
            <div class="col-lg-6 col-md-12 amount">{{statement_period}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Credit Limit: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.creditLimit}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Available Credit: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.avaliableCredit}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Current Variable Rate: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.rate}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
    <!--Second row-->
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <div class="row pismo-account-details header">
          <span class="mdi mdi-file-document-outline mx-2"></span>
          <div class="col-lg-10 col-md-12">Transaction Summary for this Period ({{statement_period}})</div>
        </div>
        <div class="section">
          <div class="row pismo-account-details font-weight-bold">
            <div class="col-lg-6 col-md-12">Opening Balance: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.opening}}</div>
          </div>
          <mat-divider class="blackColor"></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Purchases and other debits: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.debits}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Other credits: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.credits}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Interest: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.interest}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Fees: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.monthlyFacilityFee}}</div>
          </div>
          <mat-divider class="blackColor"></mat-divider>
          <div class="row pismo-account-details font-weight-bold">
            <div class="col-lg-7 col-md-12">Closing balance on {{' '}}
            {{moment(datasource.stmt.cycleClosingDate).format(STANDARD_MOMENT_DATE_FORMAT)}} </div>
            <div class="col-lg-5 col-md-12 amount">{{datasource.stmt.closing}}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 rowHeight">
        <div class="row pismo-account-details header">
          <span class="mdi mdi-chart-pie mx-2"></span>
          <div class="col-lg-10 col-md-12">What You Need to Pay This Period </div>
        </div>
        <div class="section">
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">A minimum payment of: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.minPymt}}</div>
          </div>
          <mat-divider class="blackColor"></mat-divider>
          <div class="row pismo-account-details">
            <div class="col-lg-6 col-md-12 title">Total amount due: </div>
            <div class="col-lg-6 col-md-12 amount">{{datasource.stmt.amtDue}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="row pismo-account-details font-weight-bold">
            <div class="col-lg-7 col-md-12"> Amount due by {{datasource.stmt.dueDate}} </div>
            <div class="col-lg-5 col-md-12 amount">{{datasource.stmt.minPymt}}</div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
    <!--Third row-->
    <div class="row mb-3">
      <div class="col-lg-6 col-md-12">
        <div class="section">
          <div class="row pismo-account-details font-weight-bold">
            <span class="mdi mdi-bank-outline mx-2"></span>
            <div class="col-lg-10 col-md-12">Your Direct Debit Plan</div>
          </div>
        </div>
        <div class="section">
          <div class="row pismo-account-details">
            <div class="col-lg-12 col-md-12">
              You're arranged for{{' '}}<span class="font-weight-bold">{{datasource.stmt.minPymt}}</span>
              {{' '}}
              to be direct debited from your nominated account (BSB: {{datasource.dd.bsb}},
              Acct: {{datasource.dd.acct}}) on{{' '}}
              <span class="font-weight-bold">{{datasource.stmt.dueDate}}</span>
              . To change your debit or make additional payments log in to my
              Dynamoney.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="row mb-3">
      <div class="col-12">
        <div class="row pismo-account-details header">
          <span class="mdi mdi-file-document-outline mx-2"></span>
          <div class="col-lg-10 col-md-12">Your Transactions this period ({{statement_period}})</div>
        </div>
        <table mat-table [dataSource]="transactionDatasource" class="mb-4 section">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">{{element.date}}</td>
          </ng-container>
          <ng-container matColumnDef="card">
            <th mat-header-cell *matHeaderCellDef>Card</th>
            <td mat-cell *matCellDef="let element">{{element.cardLast4Digits}}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{element.description}}</td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">{{element.amt}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  }
</div>