<div class="business-overdraft-quote-component">

  <div class="container">
    <!-- row 1 -->
    <div class="row separator">
      <div class="col-lg-8 col-md-12 mat-body">Facility limit</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.facilitylimit)) {
          {{data.facilitylimit | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body">Margin </div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.interestRate)) {
          {{data.margin |percentage}} 
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body">RBA Rate </div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.rbaRate)) {
          {{data.rbaRate | percentage}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body">Interest Rate </div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.interestRate)) {
          {{data.interestRate |percentage}} 
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 2 -->
    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body">Facility Establishment Fee</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.facilityEstablishmentFee)) {
          {{data.facilityEstablishmentFee | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 3-->
    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body ">Doc fee</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.docFee)) {
          {{data.docFee | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 4-->
    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body">Monthly Facility Fee</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.monthlyFacilityFee)) {
          {{data.monthlyFacilityFee | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 5-->
    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body ">Brokerage(ex GST)</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.brokerageDollar)) {
          {{data.brokerageDollar | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 6-->
    <div class="separator row">
      <div class="col-lg-8 col-md-12 mat-body ">Amount available</div>
      <div class="col-lg-4 col-md-12 mat-body" fxLayoutAlign="end center">
        @if (data && !_.isNil(data.amountAvailable)) {
          {{data.amountAvailable | looseCurrency}}
        }
      </div>
    </div>
  </div>
</div>
