@if (!((store.value$ | async)?.error)) {
  <div class="companies-page container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Companies</div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field class="search">
          <input matInput type="text" class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" placeholder="Search company..." [formControl]="formControlSearch">
          <span class="mdi mdi-magnify" matPrefix></span>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        @if (loader.tableLoading.inProgress$ | async) {
          <custom-content-loader></custom-content-loader>
        }
        <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
          <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
            <ng-container matColumnDef="ID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="OriginatorBusinessId">ID</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.OriginatorBusinessId}}</td>
            </ng-container>
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="EntityName">Company Name</th>
              <td mat-cell *matCellDef="let element" class="td-width ellipsis">{{element.EntityName}}</td>
            </ng-container>
            <ng-container matColumnDef="abn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="ABN">ABN</th>
              <td mat-cell *matCellDef="let element">{{element.ABN}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Type">Type</th>
              <td mat-cell *matCellDef="let element">{{element.Type}}</td>
            </ng-container>
            <ng-container matColumnDef="salesforceId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="SalesforceId">Salesforce ID</th>
              <td mat-cell *matCellDef="let element">{{element.SalesforceId}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky: true"></tr>
            <tr (click)="onRowClicked($event, row)" mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
          @if (total) {
            <app-custom-paginator
              [length]="total"
              [pageIndex]="offset"
              [pageSize]="limit"
              [pageSizeOptions]="[10, 20, 25, 50, 100]"
              (page)="onPagination($event)">
            </app-custom-paginator>
          }
        </div>
      </div>
    </div>
  </div>
}
