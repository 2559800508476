import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { SuccessDialogData, SuccessDialogResult } from '@portal-workspace/grow-shared-library';
import {FlexModule} from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';

@Component({
  templateUrl: './success.dialog.html',
  styleUrls: ['./success.dialog.scss'],
  standalone: true,
  imports: [
    MatDialogModule, FlexModule, MatButtonModule
  ]
})
export class SuccessDialog {

  constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessDialogData,
    private dialogRef: MatDialogRef<SuccessDialog, SuccessDialogResult>,
    private formBuilder: FormBuilder) {

  }

  onSubmit($event: Event) {
    const result: SuccessDialogResult = {}
    this.dialogRef.close(result);
  }
}
