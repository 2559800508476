<div class="email-verification-page container-fluid">
  @if (!(inProgressLoader.InProgressUser.inProgress$ | async)) {
    @if (verificationStatus === 'success') {
      <div class="row">
        <div class="col-12">
          <message-box title="Success" type="info">
            Your email has been verified.
          </message-box>
          <div class="separator-top">
            <button mat-flat-button (click)="relogin($event)">Re-Login</button>
          </div>
        </div>
      </div>
    }
    @if (verificationStatus === 'verified') {
      <div class="row">
        <div class="col-12">
          <message-box title="Success" type="info">
            You have been verified. Please click the button below to login.
          </message-box>
          <div class="separator-top">
            <button mat-flat-button (click)="relogin($event)">Login</button>
          </div>
        </div>
      </div>
    }
    @if (verificationStatus === 'failed') {
      <div class="row">
        <div class="col-12">
          <message-box title="Error" type="danger">
            Invalid code or expired link - {{this.message}}
          </message-box>
        </div>
      </div>
    }
    @if (verificationStatus === 'pending') {
      <div class="row">
        <div class="col-12">
          <div class="mat-h4-custom text-align-center">Please verify your email by clicking the button.</div>
          <button mat-stroked-button class="width-100" (click)="verify()">Verify email</button>
        </div>
      </div>
    }
  }

  @if ((inProgressLoader.InProgressUser.inProgress$ | async)) {
    <div class="row">
      <div class="col-12">
        <custom-content-loader ></custom-content-loader>
      </div>
    </div>
  }
</div>
