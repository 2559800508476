<div class="new-asset-inspection-dialog m-4">
  <h3 mat-dialog-title>New Asset Inspection</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <name title="Seller's First Name" [formControl]="formControlSellerFirstName"></name>
      </div>
      <div class="col-md-12 col-lg-6">
        <name title="Seller's Last Name" [formControl]="formControlSellerLastName"></name>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <email title="Seller's Email" [formControl]="formControlSellerEmail" ></email>
      </div>
      <div class="col-md-12 col-lg-6">
        <mobile title="Seller's Mobile" [formControl]="formControlSellerMobile"></mobile>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <name title="Buyer's First Name (optional)" [mandatory]="false" [formControl]="formControlBuyerFirstName"></name>
      </div>
      <div class="col-md-12 col-lg-6">
        <name title="Buyer's Last Name (optional)" [mandatory]="false" [formControl]="formControlBuyerLastName"></name>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <email title="Buyer's Email (optional)" [mandatory]="false" [formControl]="formControlBuyerEmail" ></email>
      </div>
      <div class="col-md-12 col-lg-6">
        <mobile title="Buyer's Mobile (optional)" [mandatory]="false" [formControl]="formControlBuyerMobile"></mobile>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <mat-form-field>
          <mat-label>Asset</mat-label>
          <mat-select placeholder="Select asset..." [formControl]="formControlAsset">
            @for (option of assetSelections; track option) {
              <mat-option [value]="option">{{option.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12 col-lg-6">
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select placeholder="Select state..." [formControl]="formControlState">
            @for (option of stateSelections; track option) {
              <mat-option [value]="option">{{option.stateCode}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-6">
        <mat-form-field>
          <mat-label>Inspection Type</mat-label>
          <mat-select placeholder="Select inspection type..." [formControl]="formControlInspectionType">
            @for (option of inspectionTypeSelections; track option) {
              <mat-option [value]="option">{{option.inspectionType}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="col-md-12 col-lg-6">
      <mat-form-field>
        <mat-label>Lender Reference</mat-label>
        <input [formControl]="formControlLenderReference" matInput placeholder="Enter lender reference" [disabled]="true" type="text">
        <mat-error *ngFor="let errorKey of errorKeys(formControlLenderReference)">
          <ng-container *ngIf="formControlLenderReference.touched && formControlLenderReference.hasError(errorKey)">
            {{errorMessage(formControlLenderReference, errorKey)}}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div> -->
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div class="width-100"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign= "space-between"
    fxLayoutAlign.lt-md="center start"
    fxLayoutGap="5px">
    <button mat-stroked-button class="w-lt-md-100" (click)="onCancel($event)">Cancel</button>
    <button mat-flat-button color="primary" class="w-lt-md-100" (click)="onSave()"  [disabled]="formGroup.invalid">Save</button>
  </div>
</mat-dialog-actions>
</div>
