import {Directive, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TimeInAddressComponent} from './time-in-address.component';
import {AddressModule} from '../address-component/address.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        AddressModule,
        TimeInAddressComponent
    ],
    exports: [
        TimeInAddressComponent,
    ]
})
export class TimeInAddressModule {
}
