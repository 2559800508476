<div class="guarantor-component">


  @if (formArray.errors && formArray.errors.noIndividualGuarantors) {
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box type="info">
          At least one individual guarantor is required.
        </message-box>
      </div>
    </div>
  }
  <form [formGroup]="formGroup">
    @for (mandatoryGuarantor of mandatoryGuarantors; track mandatoryGuarantor; let index = $index) {
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Guarantor {{index + 1}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>First name</mat-label>
            <input  type="text" [disabled]="true" matInput [value]="mandatoryGuarantor?.firstName">
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Last name</mat-label>
            <input  type="text" [disabled]="true" matInput [value]="mandatoryGuarantor?.lastName">
          </mat-form-field>
        </div>
      </div>
    }

    @for (guarantorFormGroup of guarantorFormGroups(); track guarantorFormGroup; let index = $index) {
      <ng-container class="separator">
        <div class="row">
          <div class="col-12">
            @if (mandatoryGuarantors) {
              <div class="mat-h3">Guarantor {{(mandatoryGuarantors.length ? mandatoryGuarantors.length : 0) + (index + 1)}}</div>
            }
          <!--div class="mat-h3">Guarantor {{index + 1}}</div-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [hideMultipleSelectionIndicator]="true"
            [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'type')">
            <mat-button-toggle value="Individual">Individual</mat-button-toggle>
            <mat-button-toggle value="Entity">Entity</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <!-- INDIVIDUAL -->
      @if (guarantorFormGroupFormControl(guarantorFormGroup, 'type').value === 'Individual' &&
        guarantorFormGroupFormControl(guarantorFormGroup, 'title')) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <title-selection
              [mark]="markObservable"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'title')">
            </title-selection>
          </div>
          <div class="col-lg-3 col-md-12">
            <name title="First Name" [mark]="markObservable" [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'firstName')"></name>
          </div>
          <div class="col-lg-3 col-md-12">
            <name title="Last Name" [mark]="markObservable" [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'lastName')"></name>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <datepicker
              [mark]="markObservable"
              title="Date of birth"
              [allowOnly18YrsPlus]="true"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'dob')">
            </datepicker>
          </div>
          <div class="col-lg-3 col-md-12">
            <gender
              [mark]="markObservable"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'gender')">
            </gender>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <custom-address
              [mark]="markObservable"
              title="Residential address"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'residentialAddress')">
            </custom-address>
          </div>
          <div class="col-lg-3 col-md-12">
            <yes-no
              [mark]="markObservable"
              [mustBeTrue]="true"
              title="Privacy consent obtained (must be YES)"
            [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'privacyConsentObtained')"></yes-no>
          </div>
        </div>
        @if (!withPropertyOwnerAddress) {
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <yes-no
                [mark]="markObservable"
                title="Property owner"
                [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'propertyOwner')">
              </yes-no>
            </div>
          </div>
        }
        @if (withPropertyOwnerAddress) {
          <div class="row">
            <div class="col-12">
              <property-owner-with-address
                [mark]="markObservable"
                [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'propertyOwner')"
                [copyAddressFn]="getAddress2ComponentValueFormControlValueFn(guarantorFormGroupFormControl(guarantorFormGroup, 'residentialAddress'))"
                [showAddressForm]="showAddressForm">
              </property-owner-with-address>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <email [mark]="markObservable"
            [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'email')"></email>
          </div>
          <div class="col-lg-3 col-md-12">
            <mobile
              [mark]="markObservable" [mandatory]="true"
              [onlyMobile]="true"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'mobileNumber')">
            </mobile>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-12">
            <button mat-stroked-button color="warn" (click)="removeGuarantor(guarantorFormGroup)">Remove Guarantor</button>
          </div>
        </div>
        @if (showAssetsAndLiabilities) {
          <personal-assets
            [mark]="markObservable"
            (events)="onPersonalAssetEvent($event, guarantorFormGroup)"
          [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'personalAssets')"></personal-assets>
        }
        @if (showAssetsAndLiabilities) {
          <personal-liabilities
            [mark]="markObservable"
            (events)="onPersonalLiabilitiesEvent($event, guarantorFormGroup)"
          [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'personalLiabilities')"></personal-liabilities>
        }
      }
      <!-- ENTITY -->
      @if (guarantorFormGroupFormControl(guarantorFormGroup, 'type').value === 'Entity' &&
        guarantorFormGroupFormControl(guarantorFormGroup, 'organisation')) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <!--
            <business-search2
              [mark]="markObservable"
              [searchFn]="businessSearchFn"
            [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'organisation')"></business-search2>
            -->
            <business-number-search
              [mark]="markObservable"
              [searchFn]="businessNumberSearchFn"
            [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'organisation')"></business-number-search>
          </div>
          <div class="col-lg-3 col-md-12">
            <entity-type
              [readonly]="entityTypeReadonly"
              [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'organisationType')">
            </entity-type>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-3">
            <mat-form-field>
              <mat-label>ABN (readonly)</mat-label>
              <input readonly type="text" matInput [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'abn')">
            </mat-form-field>
          </div>
          <div class="col-3" *ngIf="companySelected">
            <mat-form-field>
              <mat-label>ACN (readonly)</mat-label>
              <input type="text" readonly matInput [formControl]="guarantorFormGroupFormControl(guarantorFormGroup, 'acn')">
            </mat-form-field>
          </div>
        </div>
        -->
        <div class="row separator-bottom">
          <div class="col-12">
            <button mat-stroked-button color="warn" (click)="removeGuarantor(guarantorFormGroup)">Remove Guarantor</button>
          </div>
        </div>
      }
    </ng-container>
  }
<!-- </div> -->
<div class="row separator-bottom">
  <div class="col-12">
    <button mat-stroked-button (click)="addGuarantor()" [disabled]="formArray.length >= maxNumber">
      <span class="mdi mdi-plus" matPrefix></span>
      Add guarantor
    </button>
  </div>
</div>
</form>


<div class="row separator-bottom">
  @for (errorKey of errorKeys(formArray); track errorKey) {
    <mat-error>
      @if (formArray.touched && formArray.hasError(errorKey)) {
        <message-box type="danger">
          {{errorMessage(formArray, errorKey)}}
        </message-box>
      }
    </mat-error>
  }
</div>


</div>
