<div class="originator-list-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" placeholder="Select a company...">
      @for (originator of originators; track originator) {
        <mat-option [value]="originator" class="mt-2">
          {{originator.name}} ({{originator.salesforceId}})
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
