export const MonthsAtCurrentEmployerOptions = [0,1,2,3,4,5,6,7,8,9,10,11];

export type EmployerValue = {
  currentEmployer: string,
  currentEmployerContact: string,
  yearsAtCurrentEmployer: number,
  monthsAtCurrentEmployer: number,
  previousEmployer?: string | null,
  previousEmployerContact?: string | null,
} | null;
