export interface InterestRateCalculationLog {
  log: string;
  rate?: number;
}

export interface InterestRateCalculationLogDialogData {
  logs: (InterestRateCalculationLog & {subLog?: InterestRateCalculationLog[]})[];
}

export interface InterestRateCalculationLogDialogResult {
}
