<div class="customer-list-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" matTooltip="{{formControl.value!==null?formControl.value!.EntityName:'' }}" [disabled]="disabled">
      @for (customer of customers; track customer) {
        <mat-option [value]="customer" class="mt-2">
          {{customer.EntityName}} ({{customer.SalesforceId}})
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
