
<div class="gender-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Gender</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option [value]="'Male'">Male</mat-option>
        <mat-option [value]="'Female'">Female</mat-option>
      </mat-select>
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </form>
</div>
