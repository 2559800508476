import {inject, Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import {Subject, switchMap, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {getUser, storeTokens} from '@portal-workspace/grow-ui-library';
import {HTTP_X_GROW_AUTH_HEADER, PayloadApiResponse, RefreshTokenPayload} from '@portal-workspace/grow-shared-library';
import {environment} from '../../environments/environment';

export const AutoRefreshTokenInterceptorFn: ()=>HttpInterceptorFn = (): HttpInterceptorFn => {
  let subject = new Subject<PayloadApiResponse<RefreshTokenPayload>>();
  const refreshTokenFn = (authService: AuthService, refreshToken: string) => {
    subject.subscribe({
      complete: ()=> {
        subject = new Subject();
      }
    });
    if (subject.observers.length == 1) {
      authService.refreshToken(refreshToken).pipe(
        tap(v => {
          console.log(`== api call to refresh token ==`, v);
        })
      ).subscribe(subject);
    }
    return subject;
  }
  return (req, next) => {
    const authService = inject(AuthService);
    const user = getUser();
    if (environment.autoRefreshToken && user && user.refreshToken) {
      return next(req).pipe(
        catchError((error, caught) => {
          if (error &&
            (error instanceof HttpErrorResponse) &&
            error.status === 401 /* Unauthenticated */  &&
            error.headers.get(HTTP_X_GROW_AUTH_HEADER) === 'jwt-expired'
          ) {
            const refreshToken = user.refreshToken;
            // return this.authService.refreshToken(refreshToken).pipe(
            return refreshTokenFn(authService, refreshToken).pipe(
              switchMap(r => {
                if (r.status && r.payload) { // only when refresh is successful
                  const user = r.payload.user;
                  const refreshToken = r.payload.refreshToken;
                  const accessToken = r.payload.accessToken;
                  const token = {
                    user,
                    refreshToken,
                    accessToken
                  };
                  storeTokens(token);
                  const newReq = req.clone({
                    setHeaders: {'Authorization': `Bearer ${accessToken}`},
                  });
                  console.log(`== refreshed token ==`, token);
                  return next(newReq);
                }
                return throwError(()=>error);
              })
            );
          }
          return throwError(()=>error);
        })
      );
    } else {
      return next(req);
    }
  }
}

// @Injectable()
// export class AutRefreshTokenInterceptor implements  HttpInterceptor {
//
//   subject = new Subject<PayloadApiResponse<RefreshTokenPayload>>();
//
//   constructor(private authService: AuthService) {
//   }
//
//
//   refreshToken(refreshToken: string) {
//     this.subject.subscribe({
//       complete: ()=> {
//         this.subject = new Subject();
//       }
//     });
//     if (this.subject.observers.length == 1) {
//       this.authService.refreshToken(refreshToken).pipe(
//         tap(v => {
//           console.log(`== api call to refresh token ==`, v);
//         })
//       ).subscribe(this.subject);
//     }
//     return this.subject;
//   }
//
//
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const user = getUser();
//     if (environment.autoRefreshToken && user && user.refreshToken) {
//       return next.handle(req).pipe(
//         catchError((error, caught) => {
//           if (error &&
//             (error instanceof HttpErrorResponse) &&
//             error.status === 401 /* Unauthenticated */  &&
//             error.headers.get(HTTP_X_GROW_AUTH_HEADER) === 'jwt-expired'
//           ) {
//             const refreshToken = user.refreshToken;
//             // return this.authService.refreshToken(refreshToken).pipe(
//             return this.refreshToken(refreshToken).pipe(
//               switchMap(r => {
//                 if (r.status && r.payload) { // only when refresh is successful
//                   const user = r.payload.user;
//                   const refreshToken = r.payload.refreshToken;
//                   const accessToken = r.payload.accessToken;
//                   const token = {
//                     user,
//                     refreshToken,
//                     accessToken
//                   };
//                   storeTokens(token);
//                   const newReq = req.clone({
//                     setHeaders: {'Authorization': `Bearer ${accessToken}`},
//                   });
//                   console.log(`== refreshed token ==`, token);
//                   return next.handle(newReq);
//                 }
//                 return throwError(()=>error);
//               })
//             );
//           }
//           return throwError(error);
//         })
//       );
//     } else {
//       return next.handle(req);
//     }
//   }
// }
