import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CdkStep, CdkStepper, StepperSelectionEvent, CdkStepperModule } from '@angular/cdk/stepper';
import {Directionality} from '@angular/cdk/bidi';
import { doMarkAll, fieldErrorMessages, markTriggerSubject, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {doMarkAll as componentUtilDoMarkAll} from '../component-utils';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import {  navigationUrlForApplications, navigationUrlForNewApplication } from 'apps/portal2/src/app/service/navigation-urls';
import { ActivatedRoute, Params, Router } from '@angular/router';
 


// export const setNextButtonText = (stepFormGroup: FormGroup, nextStepButtonText: string) => {
//   util.setNextButtonText(stepFormGroup, nextStepButtonText);
// }
//
// export const setCurrentStepFn = (stepFormGroup: FormGroup, fn: (e: StepperSelectionEvent)=>void) => {
//   util.setCurrentStepFn(stepFormGroup, fn);
// }
//
// export const setSubmitStepFn = (stepFormGroup: FormGroup, fn: (s: CdkStepper)=>void) => {
//   util.setSubmitStepFn(stepFormGroup, fn);
// }


// NOTE: DEPRECATED use application-stepper2.component.ts (ApplicationStepper2Component)
@UntilDestroy()
@Component({
    selector: 'application-stepper',
    templateUrl: './application-stepper.component.html',
    styleUrls: ['./application-stepper.component.scss'],
    providers: [
        { provide: CdkStepper, useExisting: forwardRef(() => ApplicationStepperComponent) },
    ],
    exportAs: 'applicationStepper',
    standalone: true,
    imports: [FlexModule, MatProgressBarModule, MatCardModule, NgTemplateOutlet, MatButtonModule, CdkStepperModule]
})
export class ApplicationStepperComponent extends CdkStepper implements OnInit, AfterViewInit, OnDestroy {

  markTriggerSubject = markTriggerSubject;

  subscription?: Subscription;
  progress = 0;

  currentStepNumber: number = 0;
     
  @Input({required: false}) showErrorDetailsInPopup = true;
  @Input({required: false}) showHeader = true;
  @Input({required: false}) disableFirstBackButton = true;
  @Input({required: false}) showSecondarySubmitButton = false;
  @Input({required: false}) secondarySubmitButtonSteps = [0];
  @Output() onClickFirstBackButton = new EventEmitter();

  constructor(_dir: Directionality, _changeDetectorRef: ChangeDetectorRef, _elementRef: ElementRef<HTMLElement>,
              private dialogService: ApplicationDialogService,
              private router: Router,
              private route: ActivatedRoute
            ) {
    super(_dir, _changeDetectorRef, _elementRef);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscription = this.selectionChange.pipe(
      tap((e: StepperSelectionEvent) => {
        const currentStepFn = this.getCurrentStepFn(e.selectedStep);
        if (currentStepFn && typeof currentStepFn === 'function') {
          currentStepFn(e);
        }
        const currentStep = e.selectedIndex + 1;
        this.updateProgress(currentStep);
      })
    ).subscribe();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateProgress(1);
  }

  updateProgress(currentStep: number) {
    setTimeout(() => {
      this.currentStepNumber = currentStep;
      const total = this.steps.length;
      this.progress = (currentStep/total * 100);
    });
  }

  test($event: MouseEvent) {
    console.log('**** selected step control', this.selected?.stepControl);
  }

  getNextButtonText() {
    if (this.selected && (this.selected.stepControl as any)['nextStepButtonText']) {
      return (this.selected.stepControl as any)['nextStepButtonText'];
    }
    return 'Next';
  }

  getSecondaryButtonText() {
    if (this.selected && (this.selected.stepControl as any)['secondaryButtonText']) {
      return (this.selected.stepControl as any)['secondaryButtonText'];
    }
    return 'Next';
  }

  getCurrentStepFn(step: CdkStep) {
    if (step &&
        (step.stepControl as any)['currentStepFn'] &&
        (typeof (step.stepControl as any)['currentStepFn'] === 'function')) {
      return (step.stepControl as any)['currentStepFn'];
    }
    return null;
  }

  getSubmitStepFn() {
    if (this.selected &&
        (this.selected.stepControl as any)['submitStepFn'] &&
        (typeof (this.selected.stepControl as any)['submitStepFn'] === 'function')) {
      return (this.selected.stepControl as any)['submitStepFn'];
    }
    return null;
  }

  getSecondaryButtonFn() {
    if (this.selected &&
      (this.selected.stepControl as any)['secondaryButtonFn'] &&
      (typeof (this.selected.stepControl as any)['secondaryButtonFn'] === 'function')) {
      return (this.selected.stepControl as any)['secondaryButtonFn'];
    }
    return null;
  }

  isNextButtonVisible(): boolean {
    if (this.selected &&
      (this.selected.stepControl as any)['nextStepButtonVisible'] === false) {
      return false;
    }
    return true;
  }

  isPrevButtonVisible(): boolean {
    if (this.selected &&
      (this.selected.stepControl as any)['prevStepButtonVisible'] === false) {
      return false;
    }
    return true;
  }

  // markTriggerSubject(stepControl: FormGroup): Subject<boolean> {
  //   if (!(stepControl as any)['markTrigger']) {
  //     (stepControl as any)['markTrigger'] = new Subject();
  //   }
  //   return  (stepControl as any)['markTrigger'];
  // }

  clickFirstBackButton() {
    this.onClickFirstBackButton.emit();
  }
 

  invokeSecondaryButtonFn() {
    const fn = this.getSecondaryButtonFn();
    if (fn) {
      fn(this);
    }
  }

  invokeSubmitStepFn() {
    const fn = this.getSubmitStepFn();
    if (fn) {
      const formGroup = this.selected?.stepControl as FormGroup;
      this.markTriggerSubject(formGroup).next(true);
      const errors = this.doMarkAll(formGroup);
      if (formGroup.valid) {
        fn(this);
      } else {
        let subMessage = ''
        let message = 'Validation Error'
        if (formGroup.errors) {
          const errorType: string = Object.keys(formGroup.errors)[0]
          if (Object.keys(fieldErrorMessages).includes(errorType)) {
            message = 'Error'
            subMessage = (fieldErrorMessages as any)[errorType]
          }
        } else {
          console.log('stepper validation errors', errors);
          subMessage = this.showErrorDetailsInPopup ?
            `Please check the following validation issue: ${errors.join(', ')}` :
            `Please insert missing fields highlighted in red`;
        }
        this.dialogService.openAlertDialog({
          message: message,
          subMessage,
        });
      }
    } else {
      const formGroup = this.selected?.stepControl as FormGroup;
      this.markTriggerSubject(formGroup).next(true);
      const errors = this.doMarkAll(formGroup);
      if (formGroup.valid) {
        this.next();
      } else {
        console.log("Form Group", formGroup)
        console.log('stepper validation errors', errors);
        const subMessage = this.showErrorDetailsInPopup ?
          `Please check the following validation issue: ${errors.join(', ')}`:
          `Please insert missing fields highlighted in red`;
        this.dialogService.openAlertDialog({
          message: 'Validation Error',
          subMessage,
        });
      }
    }
  }

  doMarkAll(a: AbstractControl, errors: string[] = [], k: string | null = null): string[] {
    return componentUtilDoMarkAll(a, errors, k);
  }

  doMark(a: AbstractControl) {
    a.markAllAsTouched();
    a.markAsDirty();
    a.updateValueAndValidity({emitEvent: false});
  }
}
