<div class="reference-component">
  <form [formGroup]="formGroup" >

    @for (referenceFormGroup of referenceFormGroups(); track referenceFormGroup; let index = $index) {
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Reference {{index + 1}}</div>
          <div class="mat-body">{{description}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Business name</mat-label>
            <input  type="text" [errorStateMatcher]="errorStateMatcher" matInput [formControl]="referenceFormGroupFormControl(referenceFormGroup, 'company')" placeholder="Enter business name...">
            @for (errorKey of errorKeys(referenceFormGroupFormControl(referenceFormGroup, 'company')); track errorKey) {
              <mat-error>
                @if (referenceFormGroupFormControl(referenceFormGroup, 'company').touched && referenceFormGroupFormControl(referenceFormGroup, 'company').hasError(errorKey)) {
                  {{errorMessage(referenceFormGroupFormControl(referenceFormGroup, 'company'), errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-12">
          <mat-form-field>
            <mat-label>Phone number</mat-label>
            <input type="text" [errorStateMatcher]="errorStateMatcher" matInput
              [formControl]="referenceFormGroupFormControl(referenceFormGroup, 'phoneNumber')" placeholder="Enter phone number...">
              @for (errorKey of errorKeys(referenceFormGroupFormControl(referenceFormGroup, 'phoneNumber')); track errorKey) {
                <mat-error>
                  @if (referenceFormGroupFormControl(referenceFormGroup, 'phoneNumber').touched && referenceFormGroupFormControl(referenceFormGroup, 'phoneNumber').hasError(errorKey)) {
                    {{errorMessage(referenceFormGroupFormControl(referenceFormGroup, 'phoneNumber'), errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <mat-form-field>
              <mat-label>Contact person</mat-label>
              <input  type="text" [errorStateMatcher]="errorStateMatcher" matInput [formControl]="referenceFormGroupFormControl(referenceFormGroup, 'contactPerson')" placeholder="Enter name...">
              @for (errorKey of errorKeys(referenceFormGroupFormControl(referenceFormGroup, 'contactPerson')); track errorKey) {
                <mat-error>
                  @if (referenceFormGroupFormControl(referenceFormGroup, 'contactPerson').touched && referenceFormGroupFormControl(referenceFormGroup, 'contactPerson').hasError(errorKey)) {
                    {{errorMessage(referenceFormGroupFormControl(referenceFormGroup, 'contactPerson'), errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Type</mat-label>
              <mat-select
                [errorStateMatcher]="errorStateMatcher"
                [formControl]="referenceFormGroupFormControl(referenceFormGroup, 'referenceType')" placeholder="Select type...">
                @for (reference of referenceTypes; track reference) {
                  <mat-option [value]="reference">
                    {{reference.name}}
                  </mat-option>
                }
              </mat-select>
              @for (errorKey of errorKeys(referenceFormGroupFormControl(referenceFormGroup, 'referenceType')); track errorKey) {
                <mat-error>
                  @if (referenceFormGroupFormControl(referenceFormGroup, 'referenceType').touched && referenceFormGroupFormControl(referenceFormGroup, 'referenceType').hasError(errorKey)) {
                    {{errorMessage(referenceFormGroupFormControl(referenceFormGroup, 'referenceType'), errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-12">
            <button mat-stroked-button color="warn" [disabled]="mandatory && formArray.length <= 1" (click)="removeReference(referenceFormGroup)">Remove reference</button>
          </div>
        </div>
      }

      <div class="row separator-bottom">
        <div class="col-12">
          <button mat-stroked-button color="primary" (click)="addReference()"><span class="mdi mdi-plus" matPrefix></span>Add reference</button>
        </div>
      </div>

    </form>
  </div>
