<div class="breadcrumb-component">
  <ul>
    <li>
      <a class="cursor-pointer" (click)="onBackClicked()">
        @if (!defaultIcon) {
          <span class="mdi mdi-arrow-left"></span>
        }
        @if (defaultIcon) {
          @if (trails.length <= 1) {
            <span class="mdi mdi-{{defaultIcon}}"></span>
          }
          @if (trails.length > 1) {
            <span class="mdi mdi-arrow-left"></span>
          }
        }
      </a>
    </li>
    @for (trail of trails; track trail) {
      <li (click)="onClick(trail)" [ngClass]="trail == 'Applications' ? 'cursor-pointer' : ''">
      <!--a class="trails">{{trail | slice:0:30}}{{ trail.length > 30 ? '...' : '' }}</a-->
      <a class="trails"><span>{{trail | truncate : 30}}</span></a>
    </li>
  }
</ul>
</div>
