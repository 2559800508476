<div class="bank-statement-analysis-settings-page container-fluid">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
          <mat-tab label="LENDER LIST">
            <!-- <div class="row mb-4 mt-4">
              <div class="col-lg-8 col-md-12">
                <mat-form-field class="lender-list">
                  <mat-label>Lender List</mat-label>
                  <mat-chip-grid #lenderChipList aria-label="lender list">
                    @for (lender of lenderList; track lender) {
                      <mat-chip-row (removed)="removeLender(lender)">
                        {{lender}}
                        <button matChipRemove>
                          <span class="mdi mdi-close"></span>
                        </button>
                      </mat-chip-row>
                    }
                    <input [matChipInputFor]="lenderChipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      [formControl]="formControlLender"
                      (matChipInputTokenEnd)="addLender($event)">
                  </mat-chip-grid>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12"
                   fxLayout="row"
                   fxLayoutGap="10px"
                   fxLayoutAlign="space-between center"
                   fxLayout.lt-md="column"
                   fxLayoutAlign.lt-md="center start">
                <button mat-stroked-button color="primary" class="w-lt-md-100">Cancel</button>
                <button mat-flat-button color="primary" class="w-lt-md-100" (click)="saveLenderList()">Save</button>
              </div>
            </div> -->
            <lender-table
             [type]="'lender'"
             [getBsaLenderListFn]="getBsaLenderListFn"
             [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
             [updateBsaLenderFn]="updateBsaLenderFn"
             [updateBsaExcludedLenderFn]="updateBsaExcludedLenderFn"
             [deleteBsaLenderFn]="deleteBsaLenderFn"
             [deleteBsaExcludedLenderFn]="deleteBsaExcludedLenderFn"
             [addBsaLenderFn]="addBsaLenderFn"
             [addBsaExcludedLenderFn]="addBsaExcludedLenderFn"
            ></lender-table>
          </mat-tab>

          <mat-tab label="EXCLUDED LENDER LIST">
            <lender-table
             [type]="'excluded-lender'"
             [getBsaLenderListFn]="getBsaLenderListFn"
             [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
             [updateBsaLenderFn]="updateBsaLenderFn"
             [updateBsaExcludedLenderFn]="updateBsaExcludedLenderFn"
             [deleteBsaLenderFn]="deleteBsaLenderFn"
             [deleteBsaExcludedLenderFn]="deleteBsaExcludedLenderFn"
             [addBsaLenderFn]="addBsaLenderFn"
             [addBsaExcludedLenderFn]="addBsaExcludedLenderFn"
            ></lender-table>
            <!-- <div class="row mb-4 mt-4">
              <div class="col-lg-8 col-md-12">
                <mat-form-field class="lender-list">
                  <mat-label>Excluded Lender List</mat-label>
                  <mat-chip-grid #excludedLenderChipList aria-label="lender list">
                    @for (lender of excludedLenderList; track lender) {
                      <mat-chip-row (removed)="removeExcludedLender(lender)">
                        {{lender}}
                        <button matChipRemove>
                          <span class="mdi mdi-close"></span>
                        </button>
                      </mat-chip-row>
                    }
                    <input [matChipInputFor]="excludedLenderChipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      [formControl]="formControlExcludedLender"
                      (matChipInputTokenEnd)="addExcludedLender($event)">
                  </mat-chip-grid>
                </mat-form-field>
              </div>
            </div>
            
            <div class="row mt-3">
              <div class="col-12"
                   fxLayout="row"
                   fxLayoutGap="10px"
                   fxLayoutAlign="space-between center"
                   fxLayout.lt-md="column"
                   fxLayoutAlign.lt-md="center start">
                <button mat-stroked-button color="primary" class="w-lt-md-100">Cancel</button>
                <button mat-flat-button color="primary" class="w-lt-md-100" (click)="saveExcludedLenderList()">Save</button>
              </div>
            </div> -->
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
</div>
