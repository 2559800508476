<div class="loan-terms-selection-with-input-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Loan term (in months)</mat-label>
      <input type="text"
           [inputMask]="inputMask"
           matInput
           [formControl]="formControl"
           [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption (optionSelected)="onTermSelected($event)" #auto="matAutocomplete">
        @for (option of options; track option) {
          <mat-option [value]="option.type">{{option.name}}</mat-option>
        }
      </mat-autocomplete>
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </form>
</div>
