import {Component, Input} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';


@Component({
    selector: 'application-header-segment',
    templateUrl: './application-header-segment.component.html',
    styleUrls: ['./application-header-segment.component.scss'],
    standalone: true,
    imports: [MatDividerModule]
})
export class ApplicationHeaderSegmentComponent {

  @Input({required: false}) applicationId: string | null   = 'Not available';
  @Input({required: false}) title: string = 'Organisation'
  @Input({required: false}) titleValue: string = 'Not available';
}
