import { environment } from "../../environments/environment";
import {inject, Injectable, INJECTOR} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {httpOptions} from "@portal-workspace/grow-ui-library";
import {Observable} from "rxjs";
import {
  BrokerflowBankstatement,
  BrokerflowBankstatementEstimates,
  PayloadApiResponse
} from "@portal-workspace/grow-shared-library";

const URL_BROKERFLOW_SUBMISSION = (brokerflowId: string, abn: string) => `${environment.api2Host}/api2/brokerflow/submission/${brokerflowId}/${abn}`; // GET

@Injectable()
export class BrokerflowService {

  httpClient = inject(HttpClient);

  brokerflowSubmission(brokerflowId: string, abn: string): Observable<PayloadApiResponse<BrokerflowBankstatement | null>> {
    return this.httpClient.get<PayloadApiResponse<BrokerflowBankstatement | null>>(
      URL_BROKERFLOW_SUBMISSION(brokerflowId, abn), httpOptions());
  }

}
