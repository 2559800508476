import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AssetCategoryTypeSelectionValue,
  compareMatch,
  CurrencyInputValue, PpsrAssetSupplierSearchValue,
  PpsrAssetValue,
  VehicleDetails
} from '@portal-workspace/grow-shared-library';
import {constants, getVehicleSerialNumberType, VehicleSerialNumberType} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {AssetCategorySelectionValue} from '@portal-workspace/grow-shared-library';
import { PpsrAssetSupplierSearchEvent, SaveSupplierFn, SearchSupplierFn, PpsrAssetSupplierSearchComponent } from './ppsr-asset-supplier-search.component';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BusinessSearchFn, createYearInputMask, CurrencyInputComponent, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {Subject, Subscription} from 'rxjs';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {validVINValidator} from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CurrencyInputComponent as CurrencyInputComponent_1 } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { InputMaskModule } from '@ngneat/input-mask';
import { AssetCategoryTypeSelectionComponent } from './asset-category-type-selection.component';
import { AssetCategorySelectionComponent } from './asset-category-selection.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FlexModule } from '@angular/flex-layout/flex';
const vehicleSerialNumberTypeOptions: VehicleSerialNumberType[] = getVehicleSerialNumberType();



export type PpsrAssetComponentEvent  = PpsrAssetComponentAddEvent | PpsrAssetComponentRemoveEvent | PpsrAssetComponentSearchEvent;

export interface PpsrAssetComponentAddEvent {
  type: 'Add'
}

export interface PpsrAssetComponentRemoveEvent {
  type: 'Remove'
}

export interface PpsrAssetComponentSearchEvent {
  type: 'Search',
  serialNumberType: string,
  serialNumber: string,
}

let KEY = 1;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'ppsr-asset',
    templateUrl: './ppsr-asset.component.html',
    styleUrls: ['./ppsr-asset.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PpsrAssetComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => PpsrAssetComponent) }
    ],
    standalone: true,
    imports: [FlexModule, MatFormFieldModule, MatInputModule, MessageBoxComponent, AssetCategorySelectionComponent, FormsModule, ReactiveFormsModule, AssetCategoryTypeSelectionComponent, InputMaskModule, MatSelectModule, MatOptionModule, CurrencyInputComponent_1, MarkDirective, MatButtonModule, PpsrAssetSupplierSearchComponent]
})
export class PpsrAssetComponent extends AbstractControlValueAccessor<PpsrAssetValue> implements OnInit, Mark {

  key = `KEY_${KEY++}`;

  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) saveSupplierFn!: SaveSupplierFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: false}) allowDelete = false;
  @Input({required: true}) title?: string;
  @Input({required: true}) applicationId!: number;
  @Input({required: false}) disableControl: boolean = false;
  @Output() events: EventEmitter<PpsrAssetComponentEvent> = new EventEmitter<PpsrAssetComponentEvent>();
  @Output() supplierEvent: EventEmitter<PpsrAssetSupplierSearchEvent> = new EventEmitter<PpsrAssetSupplierSearchEvent>();

  createYearMask = createYearInputMask();
  vehicleSerialNumberTypeOptions = vehicleSerialNumberTypeOptions;

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();

  showMake:boolean=false;
  showSerialNumber = false;
  isSupplierAddAsset=false;

  formGroup: FormGroup<{
    assetCategory: FormControl<AssetCategorySelectionValue>,
    assetType: FormControl<AssetCategoryTypeSelectionValue>,
    make: FormControl<string | null>,
    year: FormControl<string | null>,
    model: FormControl<string | null>,
    description: FormControl<string | null>,
    serialNumberType: FormControl<string | null>,
    serialNumber: FormControl<string | null>,
    invoicePrice: FormControl<CurrencyInputValue>,
    gst: FormControl<CurrencyInputValue>,
    supplier: FormControl<PpsrAssetSupplierSearchValue>,
    assetId: FormControl<number | null>,
    salesforceId: FormControl<string | null>,
    vehicleDetails: FormControl<VehicleDetails | null>
  }>;
  formControlAssetCategory: FormControl<AssetCategorySelectionValue>;
  formControlAssetType: FormControl<AssetCategoryTypeSelectionValue>;
  formControlAssetMake: FormControl<string | null>;
  formControlAssetYear: FormControl<string | null>;
  formControlAssetModel: FormControl<string | null>;
  formControlAssetDescription: FormControl<string | null>;
  formControlSerialNumberType: FormControl<string | null>;
  formControlSerialNumber: FormControl<string | null>;
  formControlInvoicePrice: FormControl<CurrencyInputValue>;
  formControlGst: FormControl<CurrencyInputValue>;
  formControlSupplier: FormControl<PpsrAssetSupplierSearchValue>;
  formControlAssetId: FormControl<number | null>;
  formControlSalesforceId: FormControl<string | null>;
  formControlVehicleDetails: FormControl<VehicleDetails | null>;

  constructor(private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService) {
    super();
    this.formControlAssetCategory = formBuilder.control(null, [Validators.required]);
    this.formControlAssetType = formBuilder.control(null, [Validators.required]);
    this.formControlAssetMake = formBuilder.control(null);
    this.formControlAssetYear = formBuilder.control(null);
    this.formControlAssetModel = formBuilder.control(null);
    this.formControlAssetDescription = formBuilder.control(null, [Validators.required]);
    this.formControlSerialNumberType = formBuilder.control(null);
    this.formControlSerialNumber = formBuilder.control(null);
    this.formControlInvoicePrice = formBuilder.control(0);
    this.formControlGst = formBuilder.control(0);
    this.formControlSupplier = formBuilder.control(null);
    this.formControlAssetId = formBuilder.control(null);
    this.formControlSalesforceId = formBuilder.control(null);
    this.formControlVehicleDetails = formBuilder.control(null);
    this.formGroup = formBuilder.group({
      assetCategory: this.formControlAssetCategory,
      assetType: this.formControlAssetType,
      make:this.formControlAssetMake,
      year:this.formControlAssetYear,
      model:this.formControlAssetModel,
      description: this.formControlAssetDescription,
      serialNumberType: this.formControlSerialNumberType,
      serialNumber: this.formControlSerialNumber,
      invoicePrice: this.formControlInvoicePrice,
      gst: this.formControlGst,
      supplier: this.formControlSupplier,
      assetId: this.formControlAssetId,
      salesforceId: this.formControlSalesforceId,
      vehicleDetails: this.formControlVehicleDetails,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    const sub = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((r) => {
        const v: PpsrAssetValue = {
          assetCategory: this.formControlAssetCategory.value,
          assetType: this.formControlAssetType.value,
          description: this.formControlAssetDescription.value!,
          year: this.formControlAssetYear.value ? Number(this.formControlAssetYear.value) : null,
          serialNumberType: this.formControlSerialNumberType.value ?? '', // todo: fix
          serialNumber: this.formControlSerialNumber.value ?? '', // todo: fix
          invoicePrice: this.formControlInvoicePrice.value ?? 0,  // todo: fix
          assetNumber: null,
          salesforceId: '',
          gst: this.formControlGst.value ?? 0,  // todo: fix
          supplier: {
            SupplierType: this.formControlSupplier.value?.SupplierType ?? null,
            ABN: this.formControlSupplier.value?.ABN ?? null,
            ACN: null,
            SupplierName: this.formControlSupplier.value?.SupplierName ?? '',
            SupplierAddress: this.formControlSupplier.value?.SupplierAddress!, // todo: fix this
            Contact: this.formControlSupplier.value?.Contact ?? '',
            isManualSupplier: false,  // todo: fix
            isExistingSupplier: false, // todo: fix
            SupplierId: String(this.formControlSupplier.value?.AssetSupplierId),
            SalesForceId: this.formControlSupplier.value?.SalesForceId ?? undefined,
          },
          AssetId: this.formControlAssetId.value,
          vehicleDetails: this.formControlVehicleDetails.value,
        };
        this.propagateChange(v);
      })
    ).subscribe();
    const sub2 = this.formControlInvoicePrice.valueChanges.pipe(
      tap(r => {
        const invoicePrice = r!;
        const GSTAmount = Number((invoicePrice / 11).toFixed(2))
        this.formControlGst.setValue(GSTAmount);
      })
    ).subscribe();
    const sub3 = this.formControlAssetCategory.valueChanges.pipe(
      tap((r: AssetCategorySelectionValue) => {
        if (r) {
          switch(r.index) {
            case '130':
            case '132':
            case '137': {
              this.formControlSerialNumberType.setValidators([Validators.required]);
              this.formControlSerialNumber.setValidators([Validators.required]);
              this.formControlSerialNumber.updateValueAndValidity();
              this.formControlSerialNumberType.updateValueAndValidity();
              this.showSerialNumber = true;
              this.formControlSerialNumberType.valueChanges.subscribe(() => {
                if(this.formControlSerialNumberType.value==='VIN'){
                  this.formControlSerialNumber.setValidators([validVINValidator()]);
                  this.formControlSerialNumber.updateValueAndValidity();
                }else{
                  this.formControlSerialNumber.clearValidators();
                  this.formControlSerialNumberType.setValidators(Validators.required);
                  this.formControlSerialNumber.updateValueAndValidity();
                }
              })
              this.showMake=true;
              break;
            }
            default: {
              this.formControlSerialNumberType.clearValidators();
              this.formControlSerialNumber.clearValidators();
              this.formControlSerialNumber.updateValueAndValidity();
              this.formControlSerialNumberType.updateValueAndValidity();
              this.showSerialNumber = false;
              this.showMake=false;
              break;
            }
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.subscriptions.push(sub2);
    this.subscriptions.push(sub3);
  }

  doWriteValue(v: PpsrAssetValue | null | undefined): void | PpsrAssetValue {
    console.log('*************** ppsr-asset write value', v);
    if (v) {
      this.formControlAssetCategory.setValue(v.assetCategory);
      setTimeout(()=> {
        this.formControlAssetType.setValue(v.assetType);
      }, 1000);
      this.formControlAssetDescription.setValue(v.description);
      this.formControlSerialNumberType.setValue(v.serialNumberType);
      this.formControlSerialNumber.setValue(v.serialNumber);
      this.formControlInvoicePrice.setValue(v.invoicePrice);
      this.formControlGst.setValue(v.gst);
      // NOTE: why is v.supplier.SupplierId type different from AssetSupplierId ?
      // NOTE: make sure props is valid? if there is a suppierId it is valid ??
      const val: PpsrAssetSupplierSearchValue = (v.supplier && v.supplier.SupplierId)? {
        AssetSupplierId: Number(v.supplier.SupplierId),
        SupplierType: v.supplier.SupplierType ?? '',
        ABN: v.supplier.ABN ?? '',
        SupplierAddress: v.supplier.SupplierAddress,
        Contact: v.supplier.Contact,
        SalesForceId: v.supplier.SalesForceId ?? '',
        SupplierName: v.supplier.SupplierName,
        isDeleted: false,
        isAccredited: false,
        isPrivateSeller: false,
        FinancialInstitution: '',
        BankAccountName: '',
        BankAccountBsb: '',
        BankAccountNumber: '',
      } : null;
      this.formControlSupplier.setValue(val);
      this.formControlAssetId.setValue(v.AssetId);
      this.formControlSalesforceId.setValue(v.salesforceId);
      this.formControlVehicleDetails.setValue(v.vehicleDetails!);
    }
    return undefined;
  }


  searchAsset($event: Event) {
    if (this.formControlSerialNumberType.invalid || this.formControlSerialNumber.invalid) {
      this.dialogService.openAlertDialog({
        message: 'Warn',
        subMessage: 'Must have serial number and serial number type to search',
      });
      return;
    }
    this.events.next({
      type: 'Search',
      serialNumber: this.formControlSerialNumber.value!,
      serialNumberType: this.formControlSerialNumberType.value!,
    })

  }

  onAddAsset($event: Event) {
    this.events.next({
      type: 'Add',
    });
    this.isSupplierAddAsset=false;
  }

  onAddAssetWithSupplier($event: Event) {
    this.events.next({
      type: 'Add',
    });
    this.isSupplierAddAsset=true;
  }
  onRemoveAsset($event: Event) {
    this.events.next({
      type: 'Remove',
    });
  }

  onSupplierEvent($event: PpsrAssetSupplierSearchEvent) {
    console.log("Event::",$event)
    this.supplierEvent.next($event)
    // this.formControlSupplier.setValue($event)
  }
  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}
