<div class="asset-inspections-component">
  <h3 class="header">Asset Inspections</h3>

  @if (loader.tableLoading.inProgress$ | async) {
    <div class="loader-padding">
      <custom-content-loader type="list" ></custom-content-loader>
    </div>
  }

  <div class="asset-details" [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async)}">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100">
      @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef >{{getColumnTitles(column)}}</th>
        <td mat-cell *matCellDef="let element;let i=dataIndex;" [ngClass]="{
        'cell-padding': column !== 'id' && column !== 'actions',
        'header-cell-padding': column === 'assetNumber',
        'tail-cell-padding': column === 'actions'
        }">
            @if (column === 'id') {
              {{element.VerimotoInspectionId}}
            }
            @if (column === 'icon') {
              @if (element == expandedElement) {
                <span class="mdi mdi-chevron-up" matSuffix></span>
              }
              @if (element !== expandedElement) {
                <span class="mdi mdi-chevron-down" matSuffix></span>
              }
            }
            @if (column === 'seller') {
              <div class="title">{{element.SellerName + ' ' + element.SellerSurName}}</div>
              <div class="description">
                {{element.SellerEmail}}
                <br>
                  {{element.SellerPhoneNumber}}
                </div>
              }
              @if (column === 'asset') {
                {{getAssetName(element.AssetGuid)}}
                <!-- {{element?.SettlementAssetDetails?.InvoicePrice | looseCurrency}} -->
              }
              @if (column === 'state') {
                {{element.StateCode}}
              }
              @if (column === 'inspectionType') {
                {{getInspectionType(element.InspectionTypeGuid)}}
              }
              @if (column === 'lastUpdatedTime') {
                {{element.LastUpdatedTime | date:'dd/MM/yyyy h:mm:ss a'}}
              }
              @if (column === 'status') {
                <asset-inspection-status-chip status="{{element.Status}}"></asset-inspection-status-chip>
              }
              @if (column === 'actions') {
                @if (element.Status === 'Completed') {
                  <span class="mdi mdi-download cursor-pointer" [matTooltip]="'Download PDF'" (click)="downloadPdf(element)"></span>
                }
              }
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" [class]="expandedElement ? 'expanded-row':''">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="asset-inspection-details-bg">
                <div class="asset-inspection-details">
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Created Time</div>
                        <div body>{{element.CreatedTime | date:'dd/MM/yyyy h:mm:ss a'}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Completion Percentage</div>
                        <div body>{{element.inspectionDetails.completionPercentage}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Documents Accepted</div>
                        <div body>{{element.inspectionDetails.documentsAccepted}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Documents Pending</div>
                        <div body>{{element.inspectionDetails.documentsPending}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Documents Processed</div>
                        <div body>{{element.inspectionDetails.documentsProcessed}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Documents Rejected</div>
                        <div body>{{element.inspectionDetails.documentsRejected}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Documents Uploaded</div>
                        <div body>{{element.inspectionDetails.documentsUploaded}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Total Documents</div>
                        <div body>{{element.inspectionDetails.totalDocuments}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>External Reference</div>
                        <div body>{{element.inspectionDetails.externalRef}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Reference Number</div>
                        <div body>{{element.inspectionDetails.referenceNumber}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Buyer First Name</div>
                        <div body><p [innerHTML]="element.inspectionDetails.buyer.name | ellipsis:10"></p></div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Buyer Last Name</div>
                        <div body><p [innerHTML]="element.inspectionDetails.buyer.surName | ellipsis:10"></p></div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Buyer Email</div>
                        <div body>{{element.inspectionDetails.buyer.email}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Buyer Phone Number</div>
                        <div body>{{element.inspectionDetails.buyer.phoneNumber}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Primary Broker or First Name</div>
                        <div body>{{element.inspectionDetails.primaryBrokerOrLender.name}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Primary Broker or Last Name</div>
                        <div body>{{element.inspectionDetails.primaryBrokerOrLender.surName}}</div>
                      </cell>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div class="row">
                    <div class="col-6">
                      <cell>
                        <div label>Primary Broker or Lender Email</div>
                        <div body>{{element.inspectionDetails.primaryBrokerOrLender.email}}</div>
                      </cell>
                    </div>
                    <div class="col-6">
                      <cell>
                        <div label>Primary Broker or Lender Phone Number</div>
                        <div body>{{element.inspectionDetails.primaryBrokerOrLender.mobile}}</div>
                      </cell>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row" *ngIf="element.PPSRStatus === 'confirmed' || element.PPSRStatus === 'registered' ">
              <div class="col">
                <asset-ppsr-details *ngIf="element.SettlementAssetDetails.VehicleDetails !== null"
                [vehicleDetails]="element.SettlementAssetDetails.VehicleDetails"></asset-ppsr-details>
                <div class="not-found" *ngIf="element.SettlementAssetDetails.VehicleDetails === null">
                  No Vehicle detail found
                </div>
              </div>
            </div> -->

            <!-- <div class="row"  *ngIf="element.PPSRStatus !== 'confirmed' && element.PPSRStatus !== 'registered' ">
            <div class="col">
              <div class="not-found-bg">
                <div class="not-found">
                  No PPSR check was performed for this asset type
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
    (click)="expandedElement = null"></tr>
  </table>
</div>
</div>
