import { Component, Input, OnInit } from '@angular/core';
import {AllApplicationTypes, ApplicationTypes, constants} from '@portal-workspace/grow-shared-library';
import { AbbreviateNumberPipe } from '../../pipes/abbreviateNumber.pipe';


@Component({
    selector: 'application-type-amount',
    templateUrl: './application-type-amount.component.html',
    styleUrls: ['./application-type-amount.component.scss'],
    standalone: true,
    imports: [AbbreviateNumberPipe]
})
export class ApplicationTypeAmountComponent implements OnInit {
  @Input({required: true}) applicationType!: AllApplicationTypes;
  OVERDRAFT_SLIDER_BORROWING_MIN = constants.products.overdraft.minBorrowing
  OVERDRAFT_SLIDER_BORROWING_MAX = constants.products.overdraft.maxBorrowing
  BUSINESS_SLIDER_BORROWING_MIN = constants.products.business.minBorrowing
  BUSINESS_SLIDER_BORROWING_MAX = constants.products.business.maxBorrowing
  ASSET_SLIDER_BORROWING_MIN = constants.products.asset.minBorrowing
  ASSET_SLIDER_BORROWING_MAX = constants.products.asset.maxBorrowing
  INVOICE_SLIDER_BORROWING_MIN = constants.products.invoice.minBorrowing
  INVOICE_SLIDER_BORROWING_MAX = constants.products.invoice.maxBorrowing
  INSURANCE_SLIDER_BORROWING_MIN = constants.products.insurance.minBorrowing
  INSURANCE_SLIDER_BORROWING_MAX = constants.products.insurance.maxBorrowing
  CONSUMER_SLIDER_BORROWING_MIN = constants.products.consumer.minBorrowing
  CONSUMER_SLIDER_BORROWING_MAX = constants.products.consumer.maxBorrowing
  CORPORATE_SLIDER_BORROWING_MIN = constants.products.corporate.minBorrowing
  CORPORATE_SLIDER_BORROWING_MAX = constants.products.corporate.maxBorrowing
  constructor() { }

  ngOnInit(): void {
  }

}
