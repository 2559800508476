import {LoanTermValue} from './loan-terms-selection-component.domain';

export const BusinessLoanTermValueOptions: Exclude<LoanTermValue, null>[] = [
  {type: '6',  name: '6 Months'},
  {type: '12', name: '12 Months'},
  {type: '18', name: '18 Months'},
  {type: '24', name: '24 Months'},
  {type: '30', name: '30 Months'},
  {type: '36', name: '36 Months'},
  {type: '42', name: '42 Months'},
  {type: '48', name: '48 Months'},
  {type: '60', name: '60 Months'},
];
