<div class="manual-business-search-dialog m-2">
  <h3 mat-dialog-title>Manual Business Search</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Organisation Name</mat-label>
          <input matInput [formControl]="formControlOrganisationName">
          @for (errorKey of errorKeys(formControlOrganisationName); track errorKey) {
            <mat-error>
              @if (formControlOrganisationName.touched && formControlOrganisationName.hasError(errorKey)) {
                {{errorMessage(formControlOrganisationName, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <abn [formControl]="formControlAbn"></abn>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <acn [formControl]="formControlAcn" [isMandatory]="acnMandatory"></acn>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSave($event)">Save</button>
    </div>
  </mat-dialog-actions>
</div>
