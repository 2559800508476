<div class="credit-rule-mapping-dialog">
  <mat-dialog-content>
    <h3 class="header">Credit Rule</h3>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input [formControl]="formControlName" matInput placeholder="Enter credit rule name" type="text">
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Level</mat-label>
          <mat-select [formControl]="formControlLevel">
            <mat-option [value]="'Application'">Application</mat-option>
            <mat-option [value]="'Entity'">Entity</mat-option>
            <mat-option [value]="'Individual'">Individual</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Alert</mat-label>
          <mat-select [formControl]="formControlAlertId">
            @for (option of alertOptions; track option) {
              <mat-option [value]="option.id">{{option.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Action (executed subject to the satisfaction of the credit rule)</mat-label>
          <mat-select [formControl]="formControlAction">
            @for (option of creditRuleMappingActionOptions; track option) {
              <mat-option [value]="option">{{option}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      @if (nodeType === 'route') {
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Next Node</mat-label>
            <mat-select [formControl]="formControlNextNode">
              @for (option of nextNodeOptions; track option) {
                <mat-option [value]="option.id">{{option.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (formControlLevel.value === 'Entity' || formControlLevel.value === 'Individual') {
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Condition for Entities/Individuals</mat-label>
            <mat-select [formControl]="formControlConditionVariable">
              @for (option of creditRuleItemOptions; track option) {
                <mat-option [value]="option.id">{{option.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-2">
      <div class="col-12">
        <credit-rule [formControl]="formControlRule" [getCreditRuleItemsFn]="getCreditRuleItemsFn"></credit-rule>
      </div>
    </div>

    <!-- <div class="row mb-4">
      <div class="col-12">
        <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  @if (formControlLevel.value === 'entity') {
                    Entity Data Type
                  }
                  @if (formControlLevel.value === 'individual') {
                    Individual Data Type
                  }
                </mat-panel-title>
              </mat-expansion-panel-header>
              @if (formControlLevel.value === 'entity') {
                <div class="data-type">ABN: string</div>
                <div class="data-type">ABNAgeMonths: number</div>
                <div class="data-type">EntityType: 'P/L' | 'PTNR' | 'PTY' | 'SLTR' | 'TRST'</div>
                <div class="data-type">IndustrySector: string</div>
                <div class="data-type">BusinessCategory: string</div>
                <div class="data-type">LegalName: string</div>
                <div class="data-type">OperateatCommercialAddressFlag: boolean</div>
                <div class="data-type">PrimaryInduestry: string</div>
                <div class="data-type">Revenue: number</div>
                <div class="data-type">TradingName: string</div>
                <div class="data-type">Type: string</div>
                <div class="data-type">manualEntry: boolean</div>
                <div class="data-type">PrinciplePlaceofBusiness: object</div>
                <div class="data-type-secondary">IsManualInput: boolean</div>
                <div class="data-type-secondary">Postcode: string</div>
                <div class="data-type-secondary">State: string</div>
                <div class="data-type-secondary">StreetName: string</div>
                <div class="data-type-secondary">StreetNumber: string</div>
                <div class="data-type-secondary">StreetType: string</div>
                <div class="data-type-secondary">Suburb: string</div>
                <div class="data-type-secondary">UnformattedAddress: string</div>
                <div class="data-type-secondary">UnitNumber: string</div>
              }
              @if (formControlLevel.value === 'individual') {
                <div class="data-type">DoB: string</div>
                <div class="data-type">Email: string</div>
                <div class="data-type">Gender: string</div>
                <div class="data-type">GuarantorFlag: boolean</div>
                <div class="data-type">GivenName: string</div>
                <div class="data-type">MiddleName: string</div>
                <div class="data-type">SurName: string</div>
                <div class="data-type">MobileNumber: string</div>
                <div class="data-type">MortgageBalance: number</div>
                <div class="data-type">PrivacyConsent: boolean</div>
                <div class="data-type">PropertyOwnerFlag: boolean</div>
                <div class="data-type">PropertyValue: number</div>
                <div class="data-type">Role: 'Applicant' | 'Director' | 'Guarantor' | 'Partner' | 'Shareholder' | 'SoleTrader' | 'Trustee' | 'Spouse'</div>
                <div class="data-type">SignerRole: 'Signer' | 'Guarantor' | 'GuarantorSigner' | 'Others' | 'CorporateGuarantor'</div>
                <div class="data-type">AddressofPropertyOwned: object</div>
                <div class="data-type-secondary">Postcode: string</div>
                <div class="data-type-secondary">State: string</div>
                <div class="data-type-secondary">StreetName: string</div>
                <div class="data-type-secondary">StreetNumber: string</div>
                <div class="data-type-secondary">StreetType: string</div>
                <div class="data-type-secondary">Suburb: string</div>
                <div class="data-type-secondary">UnformattedAddress: string</div>
                <div class="data-type-secondary">UnitNumber: string</div>
                <div class="data-type">HomeAddress: object</div>
                <div class="data-type-secondary">Postcode: string</div>
                <div class="data-type-secondary">State: string</div>
                <div class="data-type-secondary">StreetName: string</div>
                <div class="data-type-secondary">StreetNumber: string</div>
                <div class="data-type-secondary">StreetType: string</div>
                <div class="data-type-secondary">Suburb: string</div>
                <div class="data-type-secondary">UnformattedAddress: string</div>
                <div class="data-type-secondary">UnitNumber: string</div>
              }
            </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" (click)="onCancel()" mat-stroked-button>Close</button>
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit()" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
