import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LoanTermsSelectionComponent, } from './loan-terms-selection.component';
import {LoanTermValue} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {ConsumerLoanTermValueOptions} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';

@UntilDestroy()
@Component({
    selector: 'consumer-loan-terms-selection',
    templateUrl: './loan-terms-selection.component.html',
    styleUrls: ['./loan-terms-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConsumerLoanTermsSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => ConsumerLoanTermsSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, DisableControlDirective]
})
export class ConsumerLoanTermsSelectionComponent extends LoanTermsSelectionComponent implements Mark {
  mark() {
    this.formGroup.markAllAsTouched();
  }
  options = ConsumerLoanTermValueOptions;
}
