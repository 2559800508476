import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {InputMaskModule} from "@ngneat/input-mask";
import {PercentageInputComponent} from '../../components/percentage-input-component/percentage-input.component';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import {MatInputModule} from "@angular/material/input";

@NgModule({
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    FlexModule,
    InputMaskModule,
    DisableControlDirective,
    PercentageInputComponent,
  ],
  exports: [
    PercentageInputComponent,
  ]
})
export class PercentageInputModule {

}
