import {
  AccessLevel,
  CustomerAccessLevel,
  UserType,
  compareMatch,
  CustomerAccessLevelValue
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import {MatOptionModule} from '@angular/material/core';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import {DisableControlDirective} from "../../directives/disable-control.directive";

export const customerUserOptions: {type: CustomerAccessLevel, name: string}[] = [
  {type: 'card-admin', name: 'Card Admin'},
  {type: 'card-member', name: 'Card Member'},
];



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'customer-access-level',
  templateUrl: './customer-access-level.component.html',
  styleUrls: ['./customer-access-level.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomerAccessLevelComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(() => CustomerAccessLevelComponent) },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    DisableControlDirective
]
})
export class CustomerAccessLevelComponent extends AbstractControlValueAccessor<CustomerAccessLevelValue> implements OnInit, Mark {

  options = customerUserOptions;

  subscriptions: Subscription[] = [];

  formControl: FormControl<CustomerAccessLevelValue>;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: CustomerAccessLevelValue | undefined): void | CustomerAccessLevelValue {
    if (v) {
      this.formControl.setValue(v);
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }

  mark() {
    this.formControl.markAllAsTouched();
  }
}
