import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AggregatorSearchComponent} from './aggregator-search.component';
import {AggregatorAndOriginatorListComponent} from './aggregator-and-originator-list.component';
import {UserListComponent} from './user-list.component';
import {OriginatorListComponent} from './originator-list.component';
import {AggregatorListComponent} from './aggregator-list.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';
import {CustomerListComponent} from './customer-list.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  AngularMaterialLibraryModule,
  DirectiveLibraryModule,
];

const components = [
  AggregatorSearchComponent,
  AggregatorAndOriginatorListComponent,
  UserListComponent,
  OriginatorListComponent,
  AggregatorListComponent,
  CustomerListComponent,
];

@NgModule({
    imports: [
        ...modules,
        ...components
    ],
    exports: [
        ...modules,
        ...components,
    ]
})
export class AggregatorSearchModule {

}
