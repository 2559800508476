<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->
@if (!((store.value$ | async)?.error)) {
  <div class="rate-card-details-page container-fluid" [formGroup]="formGroup">
    <button class="arrow-left mb-2" (click)="onClickBack()">
      <span class="mdi mdi-arrow-left"></span>
    </button>
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start" class="custom-tab">
      <mat-tab label="ASSET FINANCE"><ng-container *ngTemplateOutlet="assetFinance"></ng-container></mat-tab>
      <mat-tab label="BUSINESS TERM LOAN"><ng-container *ngTemplateOutlet="businessLoan"></ng-container></mat-tab>
      <mat-tab label="BUSINESS OVERDRAFT"><ng-container *ngTemplateOutlet="businessOverdraft"></ng-container></mat-tab>
      <mat-tab label="CORPORATE LOAN"><ng-container *ngTemplateOutlet="corporateLoan"></ng-container></mat-tab>
      <mat-tab label="INSURANCE PREMIUM"><ng-container *ngTemplateOutlet="insurancePremium"></ng-container></mat-tab>
      <mat-tab label="CONSUMER"><ng-container *ngTemplateOutlet="consumer"></ng-container></mat-tab>
    </mat-tab-group>
    <div class="row separator">
      <div class="col-12"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button class="w-lt-md-100" (click)="cancel($event)" mat-stroked-button color="primary">Cancel</button>
        @if (ratecards) {
          <button class="w-lt-md-100" (click)="update($event)" mat-flat-button color="primary" [disabled]="(loader.update.inProgress$ | async)">{{(loader.update.inProgress$ | async) ? 'Updating Rate Card...' : 'Update Rate Card' }}</button>
        }
        @if (!ratecards) {
          <button class="w-lt-md-100" (click)="save($event)" mat-flat-button color="primary" [disabled]="(loader.save.inProgress$ | async) ">{{(loader.save.inProgress$ | async) ? 'Saving Rate Card...' : 'Save Rate Card' }}</button>
        }
      </div>
    </div>
  </div>
}

<ng-template #assetFinance>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxlength="25">
            @for (errorKey of errorKeys(formControlRateCardName); track errorKey) {
              <mat-error>
                @if (formControlRateCardName.touched && formControlRateCardName.hasError(errorKey)) {
                  {{errorMessages(formControlRateCardName, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <asset-rate-card #assetRateCardComponent="assetRateCardComponent" [mark]="triggerMark"  [formControl]="formControlAssetFinanceRateCard"></asset-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #businessLoan>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxlength="25">
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <business-loan-rate-card #businessLoanRateCardComponent="businessLoanRateCardComponent" [mark]="triggerMark"  [formControl]="formControlBusinessLoanRateCard"></business-loan-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #businessOverdraft>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxlength="25">
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <business-overdraft-rate-card #businessOverdraftRateCardComponent="businessOverdraftRateCardComponent" [mark]="triggerMark"  [formControl]="formControlBusinessOverdraftRateCard"></business-overdraft-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #corporateLoan>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxLength="25">
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <corporate-loan-rate-card #corporateLoanRateCardComponent="corporateLoanRateCardComponent" [mark]="triggerMark" [formControl]="formControlCorporateLoanRateCard"></corporate-loan-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #insurancePremium>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxlength="25">
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <insurance-premium-rate-card #insurancePremiumRateCardComponent="insurancePremiumRateCardComponent" [mark]="triggerMark"  [formControl]="formControlInsurancePremiumRateCard"></insurance-premium-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #consumer>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field class="rate-card-name">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="formControlRateCardName" maxlength="25">
          </mat-form-field>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col">
          <mat-divider [inset]="true"></mat-divider>
        </div>
      </div>
      <consumer-rate-card #consumerRateCardComponent="consumerRateCardComponent"  [mark]="triggerMark" [formControl]="formControlConsumerRateCard"></consumer-rate-card>
    </mat-card-content>
  </mat-card>
</ng-template>
