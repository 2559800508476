<form class="business-number-search-component" [formGroup]="formGroup">
  <mat-form-field class="width-100">
    <mat-label>{{title}}</mat-label>
    <input type="text"
      autoSelectOnClick
      placeholder="ABN or ACN number"
      matInput
      [matAutocomplete]="auto"
      [formControl]="formControl">
      <div matPrefix class="mdi mdi-magnify"></div>
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
      <mat-autocomplete #auto="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayWithFn"
        (optionSelected)="onOptionSelected($event)"
        >
        @for (entry of filteredEntries; track entry) {
          <mat-option [value]="entry">
            <div class="option-title">{{displayName(entry)}}</div>
            <div class="option-details mat-caption">
              @if (entry.abn) {
                <span class="abn">ABN:{{entry.abn}}</span>
              }
              @if (entry.acn) {
                <span class="acn">ACN:{{entry.acn}}</span>
              }
            </div>
          </mat-option>
        }
      </mat-autocomplete>
      @if (state == 'loading') {
        <mat-spinner diameter="25" matSuffix></mat-spinner>
      }
      @if (state == 'selected') {
        <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix />
      }
      @if (state == 'error') {
        <img src="/assets/images/cross-danger.png" alt="chevron"  matSuffix />
      }
      @if (formControl.value && ($any(formControl.value).abn || $any(formControl.value).acn)) {
        <mat-hint>
          @if ($any(formControl.value).abn) {
            ABN: {{$any(formControl.value).abn}}
          }
          @if ($any(formControl.value).acn) {
            ACN: {{$any(formControl.value).acn}}
          }
        </mat-hint>
      }
    </mat-form-field>
  </form>
