import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {FlexModule} from "@angular/flex-layout/flex";
import { AsyncPipe, JsonPipe } from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {ComponentLibraryModule} from "../component-library.module";
import { AutoSelectOnClickDirective } from '../../directives/auto-select-on-click.directive';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from '../../components/component-utils';
import {
  compareMatch,
  NotNullable,
  OverdraftCustomerSearchValue,
  SearchOverdraftCustomerResult,
} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {UntilDestroy} from "@ngneat/until-destroy";
import {debounceTime, Observable, of, Subscription} from "rxjs";
import {distinctUntilChanged, filter, map, startWith, switchMap} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';

export interface OverdraftCustomerSearchFn {
  (search: string): Observable<SearchOverdraftCustomerResult>;
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'overdraft-customer-search',
  templateUrl: './overdraft-customer-search.component.html',
  styleUrls: ['./overdraft-customer-search.component.scss'],
  standalone: true,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>OverdraftCustomerSearchComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(()=>OverdraftCustomerSearchComponent)},
  ],
  imports: [
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
    MatInputModule,
    AutoSelectOnClickDirective,
    ComponentLibraryModule
]
})
export class OverdraftCustomerSearchComponent extends AbstractControlValueAccessor<OverdraftCustomerSearchValue> implements OnInit, Mark  {

  subscriptions: Subscription[] = [];

  state: 'loading' | 'selected' | 'error' | 'done' | '' = '';

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formBuilder: FormBuilder;
  formControl: FormControl<OverdraftCustomerSearchValue>;

  filteredEntries: NotNullable<OverdraftCustomerSearchValue>[] = [];

  @Input({required: true}) searchFn!: OverdraftCustomerSearchFn;

  displayWithFn: (v: OverdraftCustomerSearchValue) => string = (v) => {
    if (v) {
      let name = v.entityName ?? '';
      return name;
    }
    return '';
  }

  constructor() {
    super();
    this.formBuilder = inject(FormBuilder);
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      startWith(null),
      debounceTime(1000),
      distinctUntilChanged(compareMatch),
      filter(r => typeof r  == 'string'),
      switchMap((search: string) => {
        if (!search) {
          this.propagateChange(null);
          return of(null);
        } else {
          this.state = 'loading';
          return this.searchFn(search);
        }
      }),
      map((_r: SearchOverdraftCustomerResult | null) => {
        const _filteredEntries: SearchOverdraftCustomerResult = [];
        if (_r) {
          _filteredEntries.push(..._r);
        }
        this.propagateChange(null);
        this.filteredEntries = _filteredEntries;
        this.state = 'done';
      })
    ).subscribe());
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.state = 'selected';
    const v: OverdraftCustomerSearchValue = $event.option.value;
    this.formControl.setValue(v);
    this.propagateChange(v);
  }

  doWriteValue(v: OverdraftCustomerSearchValue | null | undefined): void | OverdraftCustomerSearchValue {
    if (v) {
      this.formControl.setValue(v);
    }
    return undefined;
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

}
