import {
  AfterContentChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators, FormGroup, FormArray, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  BusinessNumberSearchFn,
  getEntityType,
  fieldErrorMessages,
  formControlErrorKeys,
  formControlErrorMessage, requiredToBeTrueValidator
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy, CustomErrorStateMatcher, getAddress2ComponentValueFormControlValueFn} from '@portal-workspace/grow-ui-library';
import {Subject, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {
  BusinessNumberSearchValue,
  compareMatch,
  EntityGuarantor,
  GuarantorValue,
  IndividualGuarantor,
  IndividualGuarantorWithAddress,
  IndividualGuarantorWithoutAddress,
  IndividualOrEntity,
  NotNullable,
  PropertyOwnerWithAddressValue,
} from '@portal-workspace/grow-shared-library';
import {EntityTypeValue} from '@portal-workspace/grow-shared-library';
import { PersonalAssetComponentEvent, PersonalAssetsComponent } from './personal-assets.component';
import { PersonalLiabilitiesComponentEvent, PersonalLiabilitiesComponent } from './personal-liabilities.component';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { EntityTypeComponent } from '../entity-type-component/entity-type.component';
import { BusinessNumberSearchComponent } from '../business-number-search-component/business-number-search.component';
import { MatButtonModule } from '@angular/material/button';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent, EmailComponentEvent } from '../common fields/email.component';
import { PropertyOwnerWithAddressComponent } from '../property-owner-with-address-component/property-owner-with-address.component';
import { YesNoComponent } from '../yes-no-component/yes-no.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { GenderComponent } from '../gender-component/gender.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { NameComponent } from '../name-component/name.component';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '../message-box/message-box.component';



export interface GuarantorComponentEvent {
  entries: (IndividualGuarantorWithAddress | IndividualGuarantorWithoutAddress | EntityGuarantor)[];
}

export type IndividualOrEntityFormArrayType = FormArray<IndividualOrEntityFormGroupType>;
export type IndividualOrEntityFormGroupType = FormGroup<{
  type: FormControl<'Individual' | 'Entity' | null>,
  kind: FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null>,

  // entity
  organisation?: FormControl<NotNullable<EntityGuarantor>['organisation'] | null>,
  organisationType?: FormControl<NotNullable<EntityGuarantor>['organisationType'] | null>,
  abn?: FormControl<NotNullable<EntityGuarantor>['abn'] | null>,
  acn?: FormControl<NotNullable<EntityGuarantor>['acn'] | null>,

  // individual
  title?: FormControl<NotNullable<IndividualGuarantor>['title'] | null>,
  firstName?: FormControl<NotNullable<IndividualGuarantor>['firstName'] | null>,
  lastName?: FormControl<NotNullable<IndividualGuarantor>['lastName'] | null>,
  middleName?: FormControl<NotNullable<IndividualGuarantor>['middleName'] | null>,
  gender?: FormControl<NotNullable<IndividualGuarantor>['gender'] | null>,
  dob?: FormControl<NotNullable<IndividualGuarantor>['dob'] | null>,
  residentialAddress?: FormControl<NotNullable<IndividualGuarantor>['residentialAddress'] | null>,
  privacyConsentObtained?: FormControl<NotNullable<IndividualGuarantor>['privacyConsentObtained'] | null>,
  propertyOwner?: FormControl<NotNullable<IndividualGuarantor>['propertyOwner'] | null>,
  email?: FormControl<NotNullable<IndividualGuarantor>['email'] | null>,
  mobileNumber?: FormControl<NotNullable<IndividualGuarantor>['mobileNumber'] | null>,
  personalAssets?: FormControl<NotNullable<IndividualGuarantor>['personalAssets'] | null>,
  personalLiabilities?: FormControl<NotNullable<IndividualGuarantor>['personalLiabilities'] | null>,
}>;


@UntilDestroy({arrayName: 'suscriptions'})
@Component({
    selector: 'guarantor',
    templateUrl: './guarantor.component.html',
    styleUrls: ['./guarantor.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GuarantorComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => GuarantorComponent) },
    ],
    standalone: true,
    imports: [MessageBoxComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonToggleModule, TitleSelectionComponent, MarkDirective, NameComponent, DatepickerComponent, GenderComponent, CustomAddressComponent, YesNoComponent, PropertyOwnerWithAddressComponent, EmailComponent, MobileComponent, MatButtonModule, PersonalAssetsComponent, PersonalLiabilitiesComponent, BusinessNumberSearchComponent, EntityTypeComponent]
})
export class GuarantorComponent extends AbstractControlValueAccessor<GuarantorValue> implements OnInit, AfterViewInit, AfterContentChecked, OnChanges, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  markObservable: Subject<boolean> = new Subject<boolean>();
  errorStateMatcher = new CustomErrorStateMatcher();


  getAddress2ComponentValueFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  formGroup: FormGroup<{
    guarantors: IndividualOrEntityFormArrayType,
  }>;
  formArray: IndividualOrEntityFormArrayType;

  subscriptions: Subscription[] = [];

  companySelected: boolean = false;

  @Input({required: false}) mandatoryGuarantors: IndividualGuarantor[] = [];
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: false}) withPropertyOwnerAddress = true;
  @Input({required: false}) showAssetsAndLiabilities = true;
  @Input({required: false}) showAddressForm = true;
  @Input({required: false}) atLeastOneInvdividualGuarantor = true;
  @Input({required: false}) maxNumber = 4;
  @Output() events: EventEmitter<GuarantorComponentEvent>;
  entityTypeReadonly = true;

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.events = new EventEmitter();
    this.formArray = formBuilder.array<IndividualOrEntityFormGroupType>([],
      this.atLeastOneInvdividualGuarantor ?
          [this.atLeastOneIndividualGuarantorValidator()] : []);
    this.formGroup = formBuilder.group({
      guarantors: this.formArray,
    });
  }


  // an internal validator - make sure at least one individual validator is there
  atLeastOneIndividualGuarantorValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fa = control as FormArray;

      if (this.mandatoryGuarantors && this.mandatoryGuarantors.length) { // have a mandatory guarantor, no errors
        return null;
      }

      const individualsFormGroups = fa.controls.filter((g: AbstractControl) => {
        return ((((g as FormGroup).controls as any).type as FormControl).value === 'Individual');
      })
      if (individualsFormGroups && individualsFormGroups.length <= 0) { // no individuals, error
        return {
          noIndividualGuarantors: true
        }
      }
      return null;
    }
  }



  duplicateGuarantorEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fa = control as FormArray;
      const individualsFormGroups = fa.controls.filter((g) => {
        const email = (((g as FormGroup).controls as any).email as FormControl)?.value

        const allEmails: string [] = this.formArray.value?.map((guarantor) => guarantor?.email ?? '')?.filter((email) => !!email);
        if((allEmails.length + this.mandatoryGuarantors.length) != allEmails.length){
            this.mandatoryGuarantors.map((mandatoryGuarantor) => {
              const email = mandatoryGuarantor?.email ?? '';
              if (email) {
                allEmails.push(email);
              }
            });
        }

        const filteredEmails = allEmails.filter(guarantorEmail => guarantorEmail === email);
        if (email && filteredEmails.length > 1) {
          return true
        } else {
          return false;
        }
      });

      if (individualsFormGroups && individualsFormGroups.length > 0) {
        return {
          duplicateGuarantorEmail: {
            details: individualsFormGroups
              .map(ind => `${(ind as any).controls.email.value ?? ''} ${(ind as any).controls.firstName.value ?? ''} ${(ind as any).controls.lastName.value ?? ''}`)
          }
        }
      }
      return null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changes();
    if ((changes as any).mandatoryGuarantors) {
      setTimeout(() => {
        const hasMandatoryGuarantors = (this.mandatoryGuarantors && this.mandatoryGuarantors.length);
        const hasGuarantors = (this.formArray && this.formArray.length);
        if (!hasMandatoryGuarantors && !hasGuarantors) {
          this.addGuarantor();
        } else if (hasMandatoryGuarantors && hasGuarantors) {
          for (const _g of this.formArray.controls) {
            const g = _g as FormGroup;

            if (this.isFormGroupEmpty(g)) {
              this.removeGuarantor(g);
            }
          }
        }
        this.formArray.updateValueAndValidity();
        this.changes();
      });
    }
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  private changes() {
    if (this.formArray.invalid) {
      this.propagateChange(null);
    } else {
      const val = this.toGuarantorValue(this.formArray);
      this.propagateChange(val);
    }
  }

  toGuarantorValue(formArray: IndividualOrEntityFormArrayType): NotNullable<GuarantorValue> {
    const guarantorValue: GuarantorValue = [];
    for (const formArrayEntry of formArray.value) {
      switch(formArrayEntry.type) {
        case 'Individual': {
          if (formArrayEntry.kind == 'WithPropertyAddress') {
            // when we are here the validators should make sure those formControl values are not falsy
            const individualGuarantor: IndividualGuarantorWithAddress = {
              type: 'Individual',
              kind: 'WithPropertyAddress',
              title: formArrayEntry.title!,
              firstName: formArrayEntry.firstName!,
              lastName: formArrayEntry.lastName!,
              middleName: formArrayEntry.middleName!,
              gender: formArrayEntry.gender!,
              dob: formArrayEntry.dob!,
              residentialAddress: formArrayEntry.residentialAddress!,
              privacyConsentObtained: formArrayEntry.privacyConsentObtained!,
              propertyOwner: formArrayEntry.propertyOwner! as PropertyOwnerWithAddressValue, // hack
              email: formArrayEntry.email!,
              mobileNumber: formArrayEntry.mobileNumber!,
              personalAssets: formArrayEntry.personalAssets!,
              personalLiabilities: formArrayEntry.personalLiabilities!,
            }
            guarantorValue.push(individualGuarantor);
          } else if (formArrayEntry.kind == 'WithoutPropertyAddress') {
            const individualGuarantor: IndividualGuarantorWithoutAddress = {
              type: 'Individual',
              kind: 'WithoutPropertyAddress',
              title: formArrayEntry.title!,
              firstName: formArrayEntry.firstName!,
              lastName: formArrayEntry.lastName!,
              middleName: formArrayEntry.middleName!,
              gender: formArrayEntry.gender!,
              dob: formArrayEntry.dob!,
              residentialAddress: formArrayEntry.residentialAddress!,
              privacyConsentObtained: formArrayEntry.privacyConsentObtained!,
              propertyOwner: formArrayEntry.propertyOwner! as boolean, // hack
              email: formArrayEntry.email!,
              mobileNumber: formArrayEntry.mobileNumber!,
              personalAssets: formArrayEntry.personalAssets!,
              personalLiabilities: formArrayEntry.personalLiabilities!,
            }
            guarantorValue.push(individualGuarantor);
          }
          break;
        }
        case 'Entity': {
          const entityGuarantor: EntityGuarantor = {
            type: 'Entity',
            organisation: formArrayEntry.organisation!,
            organisationType: formArrayEntry.organisationType!,
            abn: formArrayEntry.abn!,
            acn: formArrayEntry.acn!,
          }
          guarantorValue.push(entityGuarantor);
          break;
        }
      }
    }
    return guarantorValue;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);

    const subscription = this.formArray.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap ((r: FormGroup) => { // guarantor formGroup
        this.changes();
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.changes();
    });
  }

  guarantorFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  guarantorFormGroupFormControl(guarantorFormGroup: FormGroup, controlName: string) {
    return guarantorFormGroup.controls[controlName] as FormControl;
  }



  private isFormGroupEmpty(fg: FormGroup): boolean {
    if ((fg.controls as any).type.value === 'Individual') {

      // title, firstName, lastName, middleName, dob, email, mobileNumber, personalAssets, personalLiabilities
      return (
        (fg.controls)['title'].value == null &&
        (fg.controls)['firstName'].value == null &&
        (fg.controls)['lastName'].value == null &&
        (fg.controls)['middleName'].value == null &&
        (fg.controls)['dob'].value == null &&
        (fg.controls)['email'].value == null &&
        (fg.controls)['mobileNumber'].value == null &&
        (fg.controls)['personalAssets'].value == null &&
        (fg.controls)['personalLiabilities'].value == null
      );
    } else if ((fg.controls as any).type.value === 'Entity') {
      // organisation, organisationType, abn, acn
      return (
        (fg.controls)['organisation'].value == null &&
        (fg.controls)['organisationType'].value == null &&
        (fg.controls)['abn'].value == null &&
        (fg.controls)['acn'].value == null
      );
    }
    return false;
  }

  private switchToIndividualGuarantor(formGroup: IndividualOrEntityFormGroupType, guarantor?: IndividualGuarantor) {

    const formControlTitle = this.formBuilder.control(guarantor ? guarantor.title : null, [Validators.required]);
    const formControlFirstName = this.formBuilder.control(guarantor ? guarantor.firstName : null, [Validators.required]);
    const formControlLastName = this.formBuilder.control(guarantor ? guarantor.lastName : null, [Validators.required]);
    const formControlMiddleName = this.formBuilder.control(guarantor ? guarantor.middleName : null);
    const formControlGender = this.formBuilder.control(guarantor ? guarantor.gender : 'Male', [Validators.required]);
    const formControlDob = this.formBuilder.control(guarantor ? guarantor.dob : null, [Validators.required]);
    const formControlResidentialAddress = this.formBuilder.control(guarantor ? guarantor.residentialAddress : null, [Validators.required]);
    const formControlPrivacyConsentObtained = this.formBuilder.control(guarantor ? guarantor.privacyConsentObtained : false, [Validators.required, requiredToBeTrueValidator()]);
    const formControlPropertyOwner =
      this.withPropertyOwnerAddress ?
        this.formBuilder.control(guarantor ? guarantor.propertyOwner : {propertyOwner: false}, [Validators.required]):
        this.formBuilder.control(guarantor ? guarantor.propertyOwner : false, [Validators.required]);
    const formControlEmail = this.formBuilder.control(guarantor ? guarantor.email : null, [Validators.required]);

    const formControlMobileNumber = this.formBuilder.control(guarantor ? guarantor.mobileNumber : null, [Validators.required]);
    const formControlPersonalAssets = this.formBuilder.control(guarantor ? guarantor.personalAssets : null);
    const formControlPersonalLiabilities = this.formBuilder.control(guarantor ? guarantor.personalLiabilities : null);


    formGroup.removeControl('organisation');
    formGroup.removeControl('organisationType');
    formGroup.removeControl('abn');
    formGroup.removeControl('acn');

    formGroup.addControl('title', formControlTitle);
    formGroup.addControl('firstName', formControlFirstName);
    formGroup.addControl('lastName', formControlLastName);
    formGroup.addControl('middleName', formControlMiddleName);
    formGroup.addControl('gender', formControlGender);
    formGroup.addControl('dob', formControlDob);
    formGroup.addControl('residentialAddress', formControlResidentialAddress);
    formGroup.addControl('privacyConsentObtained', formControlPrivacyConsentObtained);
    formGroup.addControl('propertyOwner', formControlPropertyOwner);
    formGroup.addControl('email', formControlEmail);
    formGroup.addControl('mobileNumber', formControlMobileNumber);
    formGroup.addControl('personalAssets', formControlPersonalAssets);
    formGroup.addControl('personalLiabilities', formControlPersonalLiabilities);

    this.formArray.setValidators(this.duplicateGuarantorEmailValidator());
    this.formArray.updateValueAndValidity();
  }

  private switchToEntityGuarantor(formGroup: IndividualOrEntityFormGroupType, guarantor?: EntityGuarantor) {
    const formControlOrganisation = this.formBuilder.control(guarantor ? guarantor.organisation : null, [Validators.required]); // business-search
    const formControlOrganisationType = this.formBuilder.control(guarantor ? guarantor.organisationType : null, [Validators.required]); // entity-type-selection
    const formControlAcn = this.formBuilder.control(guarantor ? guarantor.acn : null );
    const formControlAbn = this.formBuilder.control(guarantor ? guarantor.abn : null );

    this.subscriptions.push(formControlOrganisation.valueChanges.pipe(
      tap((r: BusinessNumberSearchValue) => {
        if (r) {
          const abn = r.abn;
          let acn = r.acn;
          if (acn) {
            // add leading zeros if less than 9 digits
            acn = 9 - acn.length > 0 ? '0'.repeat(9 - acn.length) + acn : acn;
            formControlAcn.setValue(acn);
          } else {
            formControlAcn.setValue('');
          }
          if (abn) {
            formControlAbn.setValue(abn);
          } else {
            formControlAbn.setValue('');
          }
          if (r.result) {
            const entityType = getEntityType(r.result)
            formControlOrganisationType.setValue(entityType)
            if (entityType?.type == 'other') {
              this.entityTypeReadonly = false
            } else {
              this.entityTypeReadonly = true
            }
          } else {
            this.entityTypeReadonly = !!formControlOrganisationType.value
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(formControlOrganisationType.valueChanges.pipe(
      tap((r: EntityTypeValue) => {
        if (r) {
          const entityType = r.type;
          if (entityType === 'company') {
            this.companySelected = true;
            formControlAcn.setValidators([Validators.required,Validators.minLength(9)]);
            formControlAcn.updateValueAndValidity()
          } else if (entityType === 'trust') {
            this.companySelected = true;
            formControlAcn.setValidators([Validators.minLength(9)]);
            formControlAcn.updateValueAndValidity()
          }
          else {
            this.companySelected = false;
            formControlAcn.clearValidators();
            formControlAcn.updateValueAndValidity();
          }
        }
      })
    ).subscribe());

    formGroup.removeControl('title');
    formGroup.removeControl('firstName');
    formGroup.removeControl('lastName');
    formGroup.removeControl('middleName');
    formGroup.removeControl('gender');
    formGroup.removeControl('dob');
    formGroup.removeControl('residentialAddress');
    formGroup.removeControl('privacyConsentObtained');
    formGroup.removeControl('propertyOwner');
    formGroup.removeControl('email');
    formGroup.removeControl('mobileNumber');
    formGroup.removeControl('personalAssets');
    formGroup.removeControl('personalLiabilities');

    formGroup.addControl('organisation', formControlOrganisation);
    formGroup.addControl('organisationType', formControlOrganisationType);
    formGroup.addControl('abn', formControlAbn);
    formGroup.addControl('acn', formControlAcn);

    this.formArray.clearValidators();
    this.formArray.updateValueAndValidity();
  }

  addGuarantor(g?: Exclude<GuarantorValue, null>[number]) {
    const formControlIndividualOrEntity = this.formBuilder.control(g ? g.type : 'Individual', [Validators.required]);
    const formControlKind: FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null> = this.formBuilder.control(this.withPropertyOwnerAddress ? 'WithPropertyAddress' : 'WithoutPropertyAddress');
    const formGroup = this.formBuilder.group<{
      type: FormControl<'Individual' | 'Entity' | null>,
      kind: FormControl<'WithPropertyAddress' | 'WithoutPropertyAddress' | null>,
    }>({
      type: formControlIndividualOrEntity,
      kind: formControlKind,
    });
    if (g) {
     switch(g.type) {
       case 'Individual': {
         this.switchToIndividualGuarantor(formGroup, g);
         break;
       }
       case 'Entity': {
         this.switchToEntityGuarantor(formGroup, g);
         break;
       }
     }
    } else {
      this.switchToIndividualGuarantor(formGroup, g);
    }
    this.formArray.push(formGroup);

    const subscription = formControlIndividualOrEntity.valueChanges.pipe(
      tap((r: IndividualOrEntity|null) => {
        switch (r) {
          case 'Individual': {
            this.switchToIndividualGuarantor(formGroup);
            break;
          }
          case 'Entity': {
            this.switchToEntityGuarantor(formGroup);
            break;
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
    const val = this.toGuarantorValue(this.formArray);
    this.events.emit({entries: val});
    // this.events.emit({entries: this.formArray.value});
  }

  removeGuarantor(guarantorFormGroup: FormGroup) {
    const index = this.formArray.controls.indexOf(guarantorFormGroup);

    if (index !== -1) {
      this.formArray.removeAt(index);
    }
    const val = this.toGuarantorValue(this.formArray);
    this.events.emit({entries: val});
    // this.events.emit({entries: this.formArray.value});
  }

  doWriteValue(v?: GuarantorValue): void {
    if (v) {
        for (const o of v) {
          this.addGuarantor(o);
        }
    }
  }

  onPersonalAssetEvent($event: PersonalAssetComponentEvent, guarantorFormGroup: IndividualOrEntityFormGroupType) {
    if ($event.entries && $event.entries.length) { // there are assets entries
      const formControl = this.guarantorFormGroupFormControl(guarantorFormGroup, 'personalAssets');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.guarantorFormGroupFormControl(guarantorFormGroup, 'personalAssets');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }


  onPersonalLiabilitiesEvent($event: PersonalLiabilitiesComponentEvent, guarantorFormGroup: IndividualOrEntityFormGroupType) {
    if ($event.entries && $event.entries.length) {
      const formControl = this.guarantorFormGroupFormControl(guarantorFormGroup, 'personalLiabilities');
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity()
    } else {
      const formControl = this.guarantorFormGroupFormControl(guarantorFormGroup, 'personalLiabilities');
      formControl.clearValidators();
      formControl.updateValueAndValidity()
    }
  }

  mark() {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);
  }
}
