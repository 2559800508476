<div class="overdraft-customer-physical-card-select-component">
  <mat-form-field>
    @if (title) {
      <mat-label>{{title}}</mat-label>
    }
    <mat-select [formControl]="formControl" [disableControl]="disabled">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{option.name}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
