import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Application, AzureStorageDocument, BankStatementsUploadObject, UploadFileValue} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService, PortalHotToastService, UploadedFilesListComponentEvent, duplicateFileNameValidator, maxFileUploadValidator} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import { MessageBox2Component } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Subscription, switchMap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './bank-statements-upload.page.html',
    styleUrls: ['./bank-statements-upload.page.scss'],
    standalone: true,
    imports: [NgClass, MessageBoxComponent, MatDividerModule, UploadedFilesListComponent, UploadFileComponent, MessageBox2Component, MatCheckboxModule, ReactiveFormsModule, MatFormFieldModule, PdfViewerModule, MatButtonModule, FlexModule, ExtendedModule]
})
export class BankStatementsUploadPage implements OnInit {
  environment = environment;
  subscriptions: Subscription[] = [];
  bankStatements!: BankStatementsUploadObject;
  uploadedDocs: AzureStorageDocument[] = [];
  deletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];
  formControlBankStatements!: FormControl<UploadFileValue>;
  application!: Application;

  constructor(
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private formBuilder: FormBuilder,
  ) {
    this.formControlBankStatements = this.formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(2)])
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.bankStatements = (this.route.snapshot.data as any).bankStatements;
    console.log('===bs: ', this.bankStatements)

  }

  onDeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.deletedUploadedDocs = [...$event.deleted];
  }

  async uploadFilesToAzure() {
    if (this.formControlBankStatements.value) {
      (await this.applicationService.uploadBankStatementsFn(
        this.formControlBankStatements.value, 
        this.bankStatements.applicationId,
      )).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.retryableMessage({
          successMessage: 'Bank Statements Successfully Uploaded',
          errorMessage: 'Failed to upload the bank statements',
          retryFn: async () => {
            await this.uploadFilesToAzure()
          }
        })
      ).subscribe()
    }
  }
}
