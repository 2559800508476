<div class="pismo-edit-account-dialog m-4">
  <h3 mat-dialog-title>Select a credit user</h3>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Credit user</mat-label>
          <mat-select
            placeholder="Select a credit user..."
            [formControl]="formControlUser"
            >
            @for (option of creditUsers; track option) {
              <mat-option [value]="option"
                >{{option.GivenName}} {{option.FamilyName}}</mat-option
                >
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="formControlUser.invalid" (click)="onConfirm()">
          Confirm
        </button>
      </div>
    </mat-dialog-actions>
  </div>
