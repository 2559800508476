<div class="application-header-segment">
  <div class="row separator-bottom">
    <div class="col-3">
        <div class="title">Application ID</div>
        {{applicationId}}
    </div>
    <div class="row ml-3">
      <div clas="col-3">
        <div class="title">{{title}}</div>
        {{titleValue}}
      </div>
    </div>
  </div>
</div>
<mat-divider class="separator" [inset]="true"></mat-divider>
