import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output} from '@angular/core';
import {Application, ApplicationWebService, CreateApplicationNoteFn, CreditOverviewSelection, DeleteWebServicesFn, DownloadCreditorWatchReportFn, DuplicatedApplication, GetApplicationByIdFn, GetApplicationCreditFlowResultFn, GetApplicationDscrFn, GetApplicationOwnerFn, GetCreditAuditLogFn, GetCreditWebServicesFn, GetDuplicatedApplicationsFn, GetUserFn, RemoveApplicationNoteByNoteIdFn, RequestMoreInformationFn, RunCreditFlowFn, UpdateCreditStatusFn} from '@portal-workspace/grow-shared-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatCardModule } from '@angular/material/card';
import { CreditResultComponent } from './credit-result.component';
import { GetApplicationAuditLogsFn } from '../asset-listing-component/audit-log.component';
import { GetNotesByApplicationIdFn } from '../asset-listing-component/notes.component';

export type CreditTab = {selectionType: CreditOverviewSelection, name: string, index: number};

@Component({
    selector: 'credit-overview',
    templateUrl: './credit-overview.component.html',
    styleUrls: ['./credit-overview.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      MatTabsModule, 
      NgClass, 
      ExtendedModule, 
      MatCardModule,
      CreditResultComponent,
    ]
})
export class CreditOverviewComponent implements OnInit {
  @Input({required: true}) application!: Application;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getApplicationDscrFn!: GetApplicationDscrFn;
  @Input({required: true}) apiUrl!: string;
  @Output() viewReport: EventEmitter<ApplicationWebService> = new EventEmitter();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  subscriptions: Subscription[] = [];
  currentSection: CreditOverviewSelection = 'result';
  tabs: CreditTab[] = [];

  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
  ) {}

  ngOnInit(): void {
    this.tabs = [
      { selectionType: 'entity', name: 'Entity', index: 0 },
      { selectionType: 'director', name: 'Director', index: 1 },
      { selectionType: 'guarantor', name: 'Guarantor', index: 2 },
      { selectionType: 'summary', name: 'Summary', index: 3 },
      { selectionType: 'result', name: 'Result', index: 4 },
    ]
  }

  async setCurrentSelection(tabIndex: number) {
    const tabFound = this.tabs.find(t => t.index == tabIndex);
    const selection = tabFound ? tabFound.selectionType : 'result' /* default selection if not found */ ;
    this.currentSection = selection as CreditOverviewSelection;
  }

  onViewReport(element: ApplicationWebService) {
    this.viewReport.emit(element)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
