<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->
@if (!((store.value$ | async)?.error)) {
  <div class="rate-card-page container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="mat-h3">Rate cards</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <div fxLayout="column">
            <mat-form-field class="search">
              <input matInput class="ml-4" [readonly]="loader.tableLoading.inProgress$ | async" type="text"
                [formControl]="formControlSearch" placeholder="Search rate card...">
                <span class="mdi mdi-magnify" matPrefix></span>
              </mat-form-field>
            </div>
            <div fxLayout="column">
              <button class="custom-button float-right w-lt-md-100" (click)="addRateCard($event)">
                <span class="mdi mdi-plus icon"></span>
                <span class="text">Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          @if (loader.tableLoading.inProgress$ | async) {
            <custom-content-loader type="list"></custom-content-loader>
          }
          <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
            <table mat-table [dataSource]="dataSource" class="width-100 mb-2" matSort (matSortChange)="onSortData($event)">
              <ng-container matColumnDef="ratecardId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="RatecardId">Id</th>
                <td mat-cell *matCellDef="let element">{{element.RatecardId}}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Name">Name</th>
                <td mat-cell *matCellDef="let element">{{element.Name}}</td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="CreateTime">Date</th>
                <td mat-cell *matCellDef="let element">{{element.CreateTime | date:'dd/MM/yyyy' }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayColumns ; sticky:true"></tr>
              <tr (click)="onRowClicked($event, row)" mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
            @if (total) {
              <app-custom-paginator
                [length]="total"
                [pageIndex]="offset"
                [pageSize]="limit"
                [pageSizeOptions]="[10, 20, 25, 50, 100]"
                (page)="onPagination($event)"
              ></app-custom-paginator>
            }
          </div>
        </div>
      </div>
    </div>
  }
