import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PropertyOwnerWithAddressComponent} from './property-owner-with-address.component';
import {AddressModule} from '../address-component/address.module';
import {YesNoModule} from '../yes-no-component/yes-no.module';
import {InputMaskModule} from '@ngneat/input-mask';
import {CurrencySelectionModule} from '../currency-selection-component/currency-selection.module';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AddressModule,
        YesNoModule,
        InputMaskModule,
        CurrencySelectionModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PropertyOwnerWithAddressComponent
    ],
    exports: [
        PropertyOwnerWithAddressComponent,
    ]
})
export class PropertyOwnerWithAddressModule {

}
