<div class="set-maintenance-page container-fluid">
  <mat-card appearance="outlined" class="col-md-12 col-lg-9">
    <mat-card-content>
      <div class="mat-h3">Set maintenance mode</div>
      <ui-lib-status-toggle
        [showCancelConfirmation]="false"
        [formControl]="formControlMaintenanceMode">
      </ui-lib-status-toggle>
    </mat-card-content>
  </mat-card>
</div>
