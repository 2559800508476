<div class="force-reset-password-page">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>
<ng-template #resetPasswordTemplate>
  <div class="reset-password-form-template">
    <div class="mat-h3 text-align-center">Set up your password</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroup" >
      <div fxFlex="1 0 auto" class="form-field">
        <confirm-password-admin [formControl]="formControlConfirmPassword" ></confirm-password-admin>
      </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-stroked-button color="primary" [disabled]="formGroup.invalid" (click)="onSavePassword($event)">Save my password</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmationTemplate>
  <div class="confirmation-template">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center separator-bottom">
      Your password has been set and you can now login by clicking the button below.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="form-button">
      <button fxFlex="1 0 auto" mat-stroked-button color="primary" (click)="onLoginAgain($event)">Login Now</button>
    </div>
  </div>
</ng-template>
