import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RegistrationService} from '../../service/registration.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {clearAllStorage, getUser} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import { ConfirmPasswordAdminValue } from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmPasswordAdminComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet } from '@angular/common';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './force-reset-password.page.html',
    styleUrls: ['./force-reset-password.page.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, FormsModule, FlexModule, ReactiveFormsModule, ConfirmPasswordAdminComponent, MatButtonModule]
})
export class ForceResetPasswordPage implements OnInit, AfterViewInit {

  subscriptions: Subscription[] = [];

  @ViewChild('resetPasswordTemplate') resetPaswordTemplate!: TemplateRef<any>;
  @ViewChild('confirmationTemplate') confirmationTemplate!: TemplateRef<any>;

  formGroup: FormGroup<{
    confirmPassword:FormControl<ConfirmPasswordAdminValue>;
  }>;
  formControlConfirmPassword: FormControl<ConfirmPasswordAdminValue>;

  currentTemplate: TemplateRef<any> | null = null;

  constructor(private formBuilder: FormBuilder,
              private registrationService: RegistrationService,
              private activatedRoute: ActivatedRoute,
              private toastService: PortalHotToastService,
              private minimalLayoutService: MinimalLayoutService,
              private router: Router) {
    this.formControlConfirmPassword = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      confirmPassword: this.formControlConfirmPassword,
    });
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showContact: false,
      showCross: true,
      showAccreditationInContact: false,
    });
    const sub = this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch(r.type) {
            case 'cross-clicked': {
              clearAllStorage();
              await this.router.navigate(navigationUrlForLogin());
              break;
            }
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.resetPaswordTemplate;
    });
  }


  onSavePassword($event: Event) {
    const user = getUser();
    if (user && user.Email) {
      const email = user.Email;
      this.registrationService.resetPassword({
        Email: email,
        Password: this.formControlConfirmPassword.value as string,
      }).pipe(
        // this.toastService.toastObserver('update passsword'),
        tap(r => {
          if (r.status) {
            clearAllStorage();
            setTimeout(() => {
              this.currentTemplate = this.confirmationTemplate;
            });
          }
        })
      ).subscribe();
    }
  }

  async onLoginAgain($event: MouseEvent) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin())
  }
}
