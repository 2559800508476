import {AfterViewInit, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BusinessSearchComponent, BusinessSearchFn, duplicateFileNameValidator, formControlErrorKeys, formControlErrorMessage, maxFileUploadValidator, NameComponent, noSpaceValidator, ProductSelectionComponent, toInteflowLegalName } from '@portal-workspace/grow-ui-library';
import {
  BusinessSearchValue, constants,
  DocumentTag,
  isDriverLicenceDetailsError,
  removeUnprintableChar,
  StateSelectionType,
  UploadAzureFiles,
  UploadAzureFilesValue,
} from '@portal-workspace/grow-shared-library';
import { Location, NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import {Subject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  createAbnInputMask,
  createAcnInputMask, createEmailInputMask,
  createPhoneNumberInputMask,
  toEntityType,
  toEntityTypeValue,
} from '@portal-workspace/grow-ui-library';
import {toUnformattedAddress,
  filesToBase64Files, defaultDocuments, accreditationDocumentTags
} from '@portal-workspace/grow-shared-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {AggregatorSearchFn} from '@portal-workspace/grow-ui-library';
import {AggregatorSearchComponentValue, } from '@portal-workspace/grow-shared-library';
import {environment} from '../../../environments/environment';
import {ApplicationService} from '../../service/application.service';
import {
  FetchABRdataByABNResult,
} from '@portal-workspace/grow-shared-library';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import {AccreditationService} from '../../service/accreditation.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {NatureOfBusinessValue} from '@portal-workspace/grow-shared-library';
import {EntityTypeValue} from '@portal-workspace/grow-shared-library';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import {TitleSelectionValue} from '@portal-workspace/grow-shared-library';
import {ProductSelectionValue} from '@portal-workspace/grow-shared-library';
import {StateSelectionValue} from '@portal-workspace/grow-shared-library';
import {Moment} from 'moment';
import {UploadFileValue} from '@portal-workspace/grow-shared-library';
import {Base64File, PayloadApiResponse} from '@portal-workspace/grow-shared-library';
import {navigationUrlForSignup} from '../../service/navigation-urls';
import {Router} from '@angular/router';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {loadingFor} from '@ngneat/loadoff';
import moment from 'moment';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import { UploadFilesMultiTagsComponent } from '@portal-workspace/grow-ui-library';
import { StateSelectionComponent } from '@portal-workspace/grow-ui-library';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { DatepickerComponent } from '@portal-workspace/grow-ui-library';
import { TitleSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { EntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { NatureOfBusinessComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ProductSelectionComponent as ProductSelectionComponent_1 } from '@portal-workspace/grow-ui-library';
import { BusinessSearchComponent as BusinessSearchComponent_1 } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './accreditation.page.html',
    styleUrls: ['./accreditation.page.scss'],
    standalone: true,
    imports: [MatStepperModule, NgTemplateOutlet, NameComponent, FormsModule, FlexModule, ReactiveFormsModule, BusinessSearchComponent_1, ProductSelectionComponent_1, MatButtonModule, MatDividerModule, NatureOfBusinessComponent, MarkDirective, UploadFileComponent, EntityTypeComponent, AbnComponent, MatFormFieldModule, MatInputModule, AcnComponent, InputMaskModule, CustomAddressComponent, MatSlideToggleModule, NgClass, ExtendedModule, AggregatorSearchComponent, MobileComponent, MessageBoxComponent, TitleSelectionComponent, DatepickerComponent, EmailComponent, StateSelectionComponent, UploadFilesMultiTagsComponent, AsyncPipe]
})
export class AccreditationPage implements OnInit, AfterViewInit {

  @ViewChildren(ProductSelectionComponent) childProductSelection !: QueryList<ProductSelectionComponent>
  @ViewChildren(BusinessSearchComponent) childBusinessSearch !: QueryList<BusinessSearchComponent>
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  loader = loadingFor('submit');
  showApplicantFrom = false;

  subscriptions: Subscription[] = [];

  step2MarkTriggerSubject: Subject<boolean> = new Subject();
  step3MarkTriggerSubject: Subject<boolean> = new Subject();

  createAbnInputMask = createAbnInputMask();
  createAcnInputMask = createAcnInputMask();
  createPhoneNumberInputMask = createPhoneNumberInputMask();
  createEmailInputMask = createEmailInputMask();
  defaultDocuments = defaultDocuments
  getAllAccreditationDefaultTags = accreditationDocumentTags
  environment = environment;

  @ViewChild('stepper', {read: MatStepper}) stepper!: MatStepper;

  @ViewChild('step1') step1Template!: TemplateRef<any>;
  @ViewChild('step2') step2Template!: TemplateRef<any>;
  @ViewChild('step3') step3Template!: TemplateRef<any>;
  @ViewChild('step4') step4Template!: TemplateRef<any>;
  @ViewChild('step5') step5Template!: TemplateRef<any>;
  @ViewChild('step6') step6Template!: TemplateRef<any>;
  @ViewChild('step7') step7Template!: TemplateRef<any>;

  currentTemplate!: TemplateRef<any>;

  applicationId: string = '';

  // step 1:
  // abnCompanySearchFn!: CompaniesSearchFn;
  abnCompanySearchFn!: BusinessSearchFn;
  formGroupStep1!: FormGroup;
  formGroupStepInit!:FormGroup
  // formControlCompany: FormControl<CompaniesSearchComponentValue>;
  formControlCompany: FormControl<BusinessSearchValue>;
  formControlProductSelection: FormControl<ProductSelectionValue>;

  // step 2:
  showAggregatorSearch = false;
  showAcn = false;
  showTrusteeInfo = false;
  aggregatorSearchFn!: AggregatorSearchFn;
  formGroupStep2!: FormGroup;
  formControlNatureOfBusiness: FormControl<NatureOfBusinessValue>;
  formControlEntityType: FormControl<EntityTypeValue>;
  formControlAbn: FormControl<string | null>;
  formControlEntityName: FormControl<string | null>;
  formControlAcn: FormControl<string | null>;
  formControlBusinessAddress: FormControl<Address2ComponentValue>;
  formControlHeldAccreditationBefore: FormControl<boolean | null>;
  formControlHeldAccreditationName: FormControl<string | null>;
  formControlIsPartOfGroupOrAggregator: FormControl<boolean | null>;
  formControlGroupOrAggregator: FormControl<AggregatorSearchComponentValue>;
  formControlOfficePhoneNumber: FormControl<string | null>;
  formControlTrustCompanyName: FormControl<string | null>;
  formControlTrustCompanyAcn: FormControl<string | null>;
  heldAccreditationBeforeSubscription: Subscription;
  isGroupOrAggregatorSubscription: Subscription;
  base64_string:any
  formControlreferralAgreement: FormControl<UploadFileValue>;

  // step 3:
  formGroupStep3!: FormGroup;
  formControlTitle: FormControl<TitleSelectionValue>;
  formControlFirstName: FormControl<string | null>;
  formControlLastName: FormControl<string | null>;
  formControlDob: FormControl<Moment | null>;
  formControlMobileNumber: FormControl<string | null>;
  formControlEmail: FormControl<string | null>;
  formControlResidentialAddress: FormControl<Address2ComponentValue>;
  formControlDriverLicence: FormControl<UploadFileValue>;
  formControlDriverLicenceNumber: FormControl<string | null>;
  formControlDriverLicenceIssuingState: FormControl<StateSelectionValue>;
  formControlDriverLicenceExpiryDate: FormControl<Moment | null>;

  // step 4
  showAustralianCreditLicenceNumber = false;
  holdAustralianCreditLicenceSubscription: Subscription;
  formGroupStep4!: FormGroup;
  formControlHoldAustralianCreditLicence: FormControl<boolean | null>;
  formControlAustralianCreditLicenceNumber: FormControl<string | null>;
  formControlDirectorOrEmployeeOfLicencedEntity: FormControl<boolean | null>;

  // step 5
  showConvictedFraudOrAccreditationCancellationDetails = false;
  hasConvictedFraudOrAccreditationCancellationSubscription: Subscription;
  formGroupStep5!: FormGroup;
  formControlHasBankruptcy: FormControl<boolean | null>;
  formControlHasAccreditationRefusal: FormControl<boolean | null>;
  formControlHasConvictedFraudOrAccreditationCancellation: FormControl<boolean | null>;
  formControlConvictedFraudOrAccreditationCancellationDetails: FormControl<string | null>;

  // step 6 : broker
  formGroupStep6Broker!: FormGroup;
  formControlIndustryMembershipFileUpload: FormControl<UploadAzureFilesValue>;
  formControlPublicIndemnityInsuranceFileUpload: FormControl<UploadAzureFilesValue>;
  formControlOtherSupportingDocumentsFileUpload: FormControl<UploadAzureFilesValue>;

  // step 6: vendor
  formGroupStep6Vendor!: FormGroup;

  // step 6: supplier
  formGroupStep6Supplier!: FormGroup;


  // step 7
  formGroupStep7: FormGroup;
  formControlSubscriptionEmail: FormControl<string | null>;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private _location: Location,
              private accreditationService: AccreditationService,
              private toastService: PortalHotToastService,
              private minimalLayoutService: MinimalLayoutService,
              private applicationService: ApplicationService,
              private applicationDialogService: ApplicationDialogService) {
    // step 1
    // this.abnCompanySearchFn = this.applicationService.companySearchFn;
    this.abnCompanySearchFn = this.applicationService.businessSearchFn;
    console.log('***** search company ', name);
    this.formControlCompany = formBuilder.control(null, [Validators.required]);
    this.formControlProductSelection = formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = formBuilder.group({
      company: this.formControlCompany,
      productSelection: this.formControlProductSelection,
    });

    const sub = this.formControlCompany.valueChanges.pipe(
      // tap((r: CompaniesSearchComponentValue) {
      tap((r: BusinessSearchValue) => {
        if (r) {
          if (r.abn && this.formControlAbn) {
            this.formControlAbn.setValue(r.abn);
            this.applicationService.fetchABRdataByABN(r.abn).then(res => {
              let orgData: FetchABRdataByABNResult = res
              console.log("Organisation data::",orgData)
              this.formControlEntityType.setValue(toEntityTypeValue(orgData.entityType ?? null))
              console.log("Entity type form control::",this.formControlEntityType)
            })
          }
          if (r.acn && this.formControlAcn) {
            this.formControlAcn.setValue(r.acn);
          }
          // if (r.entityName && this.formControlEntityName) {
          //   this.formControlEntityName.setValue(r.entityName);
          if (r.organisationName && this.formControlEntityName) {
            this.formControlEntityName.setValue(r.organisationName);
          }
          this.formGroupStep1.updateValueAndValidity();
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
    this.formGroupStepInit = formBuilder.group({
      company: this.formControlCompany,
    });


    // step 2
    this.formControlNatureOfBusiness = formBuilder.control(null, [Validators.required]);
    this.formControlEntityType = formBuilder.control(null, [Validators.required]);
    this.formControlAbn = formBuilder.control('', [Validators.required]);
    this.formControlEntityName = formBuilder.control('', [Validators.required]);
    this.formControlBusinessAddress = formBuilder.control(null, [Validators.required]);
    this.formControlHeldAccreditationBefore = formBuilder.control(false);
    this.formControlHeldAccreditationName = formBuilder.control('');
    this.formControlIsPartOfGroupOrAggregator = formBuilder.control(false);
    this.formControlGroupOrAggregator = formBuilder.control(null);
    this.formControlOfficePhoneNumber = formBuilder.control('', [Validators.required]);
    this.formControlAcn = formBuilder.control('');
    this.formControlTrustCompanyName = formBuilder.control('');
    this.formControlTrustCompanyAcn = formBuilder.control('');
    this.formControlreferralAgreement = formBuilder.control(null);
    this.formControlreferralAgreement = formBuilder.control(null);
    this.subscriptions.push(this.formControlNatureOfBusiness.valueChanges.pipe(
      tap(v => {
        if(this.isBroker())
          this.formControlreferralAgreement.setValidators([Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(10)]);
        else
          this.formControlreferralAgreement.clearValidators();
        this.formControlreferralAgreement.updateValueAndValidity();
      })
    ).subscribe());
    this.formGroupStep2 = formBuilder.group({
      natureOfBusiness: this.formControlNatureOfBusiness,
      entityType: this.formControlEntityType,
      abn: this.formControlAbn,
      entityName: this.formControlEntityName,
      acn: this.formControlAcn,
      businessAddress: this.formControlBusinessAddress,
      heldAccreditationBefore: this.formControlHeldAccreditationBefore,
      heldAccreditationName: this.formControlHeldAccreditationName,
      isPartOfGroupOrAggregagor: this.formControlIsPartOfGroupOrAggregator,
      groupOrAggregator: this.formControlGroupOrAggregator,
      officePhoneNumber: this.formControlOfficePhoneNumber,
      referralAgreement: this.formControlreferralAgreement,
    })
    this.heldAccreditationBeforeSubscription = this.formControlHeldAccreditationBefore.valueChanges.pipe(
      tap(r => {
        if (r === true) {
          this.formControlHeldAccreditationName.setValidators([Validators.required]);
        } else {
          this.formControlHeldAccreditationName.clearValidators();
        }
        this.formControlHeldAccreditationName.updateValueAndValidity();
      })
    ).subscribe();
    this.isGroupOrAggregatorSubscription = this.formControlIsPartOfGroupOrAggregator.valueChanges.pipe(
      tap(r => {
        if (r === true) {
          this.showAggregatorSearch = true;
        } else {
          this.showAggregatorSearch = false;
        }
      })
    ).subscribe();
    this.formControlEntityType.valueChanges.pipe(
      tap((r: EntityTypeValue) => {
        if (r) {
          switch(r.type) {
            case 'sole-trader': {
              this.showAcn = false;
              this.showTrusteeInfo = false;
              this.formControlAcn.clearValidators();
              this.formControlTrustCompanyAcn.clearValidators();
              this.formControlTrustCompanyName.clearValidators();
              break;
            }
            case 'trust': {
              this.showAcn = false;
              this.showTrusteeInfo = true;
              this.formControlAcn.clearValidators();
              this.formControlTrustCompanyAcn.setValidators([Validators.required]);
              this.formControlTrustCompanyName.setValidators([Validators.required]);
              break;
            }
            case 'company': {
              this.showAcn = true;
              this.showTrusteeInfo = false;
              this.formControlAcn.setValidators([Validators.required]);
              this.formControlTrustCompanyAcn.clearValidators();
              this.formControlTrustCompanyName.clearValidators();
              break;
            }
            default: {
              this.showAcn = false;
              this.showTrusteeInfo = false;
              this.formControlAcn.clearValidators();
              this.formControlTrustCompanyAcn.clearValidators();
              this.formControlTrustCompanyName.clearValidators();
              break;
            }
          }
          this.formControlAcn.updateValueAndValidity();
          this.formControlTrustCompanyAcn.updateValueAndValidity();
          this.formControlTrustCompanyName.updateValueAndValidity();
        }
      })
    ).subscribe();


    // step 3
    this.formControlTitle = formBuilder.control(null, [Validators.required]);
    this.formControlFirstName = formBuilder.control('', [Validators.required]);
    this.formControlLastName = formBuilder.control('', [Validators.required]);
    this.formControlDob = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control('', [Validators.required]);
    this.formControlDriverLicence = formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(2)]);
    this.formControlEmail = formBuilder.control('', [Validators.required]);
    this.formControlResidentialAddress = formBuilder.control(null, [Validators.required]);
    this.formControlDriverLicenceNumber = formBuilder.control('', [Validators.required,noSpaceValidator()]);
    this.formControlDriverLicenceIssuingState = formBuilder.control(null, [Validators.required]);
    this.formControlDriverLicenceExpiryDate = formBuilder.control(null, [Validators.required]);
    this.formGroupStep3 = formBuilder.group({

      title: this.formControlTitle,
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      email:this.formControlEmail,
      dob: this.formControlDob,
      mobileNumber: this.formControlMobileNumber,
      residentialAddress: this.formControlResidentialAddress,
      driverLicence: this.formControlDriverLicence,
      driverLicenceNumber: this.formControlDriverLicenceNumber,
      driverLicenceIssuingState: this.formControlDriverLicenceIssuingState,
      driverLicenceExpiryDate: this.formControlDriverLicenceExpiryDate,
    });


    // step 4
    this.formControlHoldAustralianCreditLicence = formBuilder.control(false);
    this.formControlAustralianCreditLicenceNumber = formBuilder.control('');
    this.formControlDirectorOrEmployeeOfLicencedEntity = formBuilder.control(false);
    this.formGroupStep4 = formBuilder.group({
      holdAustralianCreditLicence: this.formControlHoldAustralianCreditLicence,
      australianCreditLicenceNumber: this.formControlAustralianCreditLicenceNumber,
      directorOrEmployeeOfLicencedEntity: this.formControlDirectorOrEmployeeOfLicencedEntity,
    });
    this.holdAustralianCreditLicenceSubscription = this.formControlHoldAustralianCreditLicence.valueChanges.pipe(
      tap(r => {
        if (r === true) {
          this.showAustralianCreditLicenceNumber = true;
          this.formControlAustralianCreditLicenceNumber.setValidators([Validators.required]);
        } else {
          this.showAustralianCreditLicenceNumber = false;
          this.formControlAustralianCreditLicenceNumber.clearValidators();
        }
        this.formControlAustralianCreditLicenceNumber.updateValueAndValidity({onlySelf: true, emitEvent: false});
      })
    ).subscribe();


    // step 5
    this.formControlHasBankruptcy = formBuilder.control(false);
    this.formControlHasAccreditationRefusal = formBuilder.control(false);
    this.formControlHasConvictedFraudOrAccreditationCancellation = formBuilder.control(false);
    this.formControlConvictedFraudOrAccreditationCancellationDetails = formBuilder.control('');
    this.formGroupStep5 = formBuilder.group({
      hasBankruptcy: this.formControlHasBankruptcy,
      hasAccreditationRefusal: this.formControlHasAccreditationRefusal,
      hasConvictedFraudOrAccreditationCancellation: this.formControlHasConvictedFraudOrAccreditationCancellation,
      convictedFraudOrAccreditationCancellationDetails: this.formControlConvictedFraudOrAccreditationCancellationDetails,
    });
    this.hasConvictedFraudOrAccreditationCancellationSubscription = this.formControlHasConvictedFraudOrAccreditationCancellation.valueChanges.pipe(
      tap(r => {
        if (r === true) {
          this.showConvictedFraudOrAccreditationCancellationDetails = true;
          this.formControlConvictedFraudOrAccreditationCancellationDetails.setValidators([Validators.required]);
        } else {
          this.showConvictedFraudOrAccreditationCancellationDetails = false;
          this.formControlConvictedFraudOrAccreditationCancellationDetails.clearValidators();
        }
        this.formControlConvictedFraudOrAccreditationCancellationDetails.updateValueAndValidity({onlySelf: true, emitEvent: false});
      })
    ).subscribe();


    // step 6: Broker
    this.formControlIndustryMembershipFileUpload = formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(10)]);
    this.formControlPublicIndemnityInsuranceFileUpload = formBuilder.control(null, [Validators.required,duplicateFileNameValidator(),maxFileUploadValidator(10)]);
    this.formControlOtherSupportingDocumentsFileUpload = formBuilder.control(null);
    this.formGroupStep6Broker = formBuilder.group({
      industryMembershipFileUpload: this.formControlIndustryMembershipFileUpload,
      publicIndemnityInsuranceFileUpload: this.formControlPublicIndemnityInsuranceFileUpload,
      otherSupportingDocumentsFileUpload: this.formControlOtherSupportingDocumentsFileUpload,
    });

    // step 6: Vendor
    this.formGroupStep6Vendor = formBuilder.group({
      otherSupportingDocumentsFileUpload: this.formControlOtherSupportingDocumentsFileUpload,
    })

    // step 6: Supplier
    this.formGroupStep6Supplier = formBuilder.group({
      otherSupportingDocumentsFileUpload: this.formControlOtherSupportingDocumentsFileUpload,
    })

    // step 7
    this.formControlSubscriptionEmail = formBuilder.control('');
    this.formGroupStep7 = formBuilder.group({
      subscriptionEmail: this.formControlSubscriptionEmail,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: true,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false,
    });
    this.subscriptions.push(this.formControlDriverLicence.valueChanges.pipe(
      tap((file: UploadFileValue) => {
        this.showApplicantFrom = !!file;
      })
    ).subscribe());
    this.aggregatorSearchFn = this.accreditationService.aggregatorSearchFn;
    // this.aggregatorSearchFn = (term: string) => {
    //   return this.accreditationService.aggregatorSearch(term).pipe(map(r => {
    //     return r.payload.map(rst => ({
    //        abn: rst.ABN,
    //        entityName: rst.EntityName,
    //        salesforceId: rst.SalesforceId
    //     }))
    //   }));
    // }
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(r => {
        if (r) {
          switch(r.type) {
            case 'prev-clicked': {
              if (this.stepper.selectedIndex === 0) {
                this._location.back();
              } else {
                if (this.stepper.selectedIndex === 6) { // last step
                  this.stepper.reset();
                } else {
                  this.stepper.previous();
                }
              }
              break;
            }
          }
        }
      })
    ).subscribe());
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.currentTemplate = this.step1Template);
  }

  isBroker(): boolean {
    const natureOfBusiness: NatureOfBusinessValue = this.formControlNatureOfBusiness.value;
    return (natureOfBusiness === 'broker');
  }

  isVendor(): boolean {
    const natureOfBusiness: NatureOfBusinessValue = this.formControlNatureOfBusiness.value;
    return (natureOfBusiness === 'vendor');
  }

  isSupplier(): boolean {
    const natureOfBusiness: NatureOfBusinessValue = this.formControlNatureOfBusiness.value;
    return (natureOfBusiness === 'supplier');
  }

  isDealer(): boolean {
    const natureOfBusiness: NatureOfBusinessValue = this.formControlNatureOfBusiness.value;
    return (natureOfBusiness === 'dealer');
  }
  onContinueToStepInit($event: MouseEvent){
    this.accreditationService.getNextAccreditationNumber().pipe(
      // this.toastService.toastObserver('retrieving accreditation number'),
      tap(r => {
        this.applicationId = r.payload.applicantNumber;
        this.stepper.next();
      })
    ).subscribe();
  }
  onContinueToStep2($event: MouseEvent) {

    this.minimalLayoutService.settings({
      showPrev: true,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false,
    });
    this.accreditationService.getNextAccreditationNumber().pipe(
      // this.toastService.toastObserver('retrieving accreditation number'),
      tap(r => {
        this.applicationId = r.payload.applicantNumber;
        this.stepper.next();
      })
    ).subscribe();
  }

  onContinueToStep3($event: MouseEvent) {
    this.stepper.next();
    this.step2MarkTriggerSubject.next(true);
  }

  onBackToStep1($event: MouseEvent) {
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false,
    });
    this.stepper.previous();
  }

  get otherDocMetadata() {
    const b: BusinessSearchValue = this.formControlCompany.value;
    const abn = this.formControlAbn.value ?? '';
    const legalName = toInteflowLegalName(b) ?? '';

    return {
      abn: abn,
      legalname: legalName
    };
  }

  onUploadDriverLicenceEvent($event: File[]) {
    console.log("On driver license upload::", $event)
    const file = $event[0]
    if (!file) {
      return;
    }
    const fileExtension = file.name.substr(-3);
    console.log("File extension::", fileExtension)
    if (['jpg', 'png', 'pdf'].indexOf(fileExtension) === -1) {
      console.log("Only PNG, JPG and PDF are allowed.")
      this.toastService.error(`Only PNG, JPG and PDF are allowed.`);
      return;
    } else if (file.size > 10485760) { /* check if greater then 10MB */
      console.log("File size exceeds")
      // this.MyModalService.alert({content: `File size exceeds the allowed limit of 10MB`});
      return;
    }
    else{
      console.log("in else")
      const reader = new FileReader();
      const fileName = file.name;
      reader.readAsDataURL(file);
      reader.onload = async (ev) => {
        this.base64_string = (ev.target as FileReader).result;
        let data: any = null;
        data = this.base64_string.split(',')[1];
        this.accreditationService.getDriverLicenceDetails({ data:data, type: file.type }).pipe(
          this.toastService.spinnerObservable(),
          // this.toastService.toastObserver('retrieving driver licence details'),
          tap(r => {
            if (!isDriverLicenceDetailsError(r.payload)) {
              console.log(r.payload)
              if(r.payload.State && r.payload.Expiry_Date){
                // const drivinglicenceIssuingState: StateSelectionValue = r.payload.State;
                const expirydate = new Date(r.payload.Expiry_Date)
                let fixDate=(moment(expirydate).format("DD/MM/YYYY")).split('/')
                this.formControlDriverLicenceIssuingState.setValue({ type: r.payload.State as StateSelectionType, name: r.payload.State as string })
                this.formControlDriverLicenceExpiryDate.setValue(moment([fixDate[2], parseInt(fixDate[1]) -1, fixDate[0]]))
              }
              this.formControlDob.setValue(moment(r.payload.DOB));
              this.formControlFirstName.setValue(r.payload.Firstname);
              this.formControlLastName.setValue(r.payload.Surname);
              this.formControlDriverLicenceNumber.setValue(r.payload.License_No);
              this.formControlResidentialAddress.setValue({
                address: `${r.payload.Street} ${r.payload.Suburb} ${r.payload.State} ${r.payload.Postcode}`,
                StreetNumber: '',
                StreetName: r.payload.Street,
                StreetType: '',
                Suburb: r.payload.Suburb,
                State: r.payload.State,
                UnitNumber: '',
                Postcode: r.payload.Postcode,
              });
              this.formControlResidentialAddress.updateValueAndValidity();
            }

            console.log("Step 3 form::",this.formGroupStep3)
          })
        ).subscribe();


      }
    }
  }

  onUploadIntroducerReferralAgreement($event: File[]){
    console.log("On upload::", $event)
    const file = $event[0]
    if (!file) {
      return;
    }
    const fileExtension = file.name.substr(-3);
    console.log("File extension::", fileExtension)
    if (['pdf'].indexOf(fileExtension) === -1) {
      console.log("Only PDF is allowed.")
      this.toastService.error(`Only PDF is allowed.`);
      this.formControlreferralAgreement.setValue(null);
      return;
    }
  }

  onBackToStep2($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step2Template);
    this.stepper.previous();
  }

  onContinueToStep4($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step4Template);
    this.stepper.next();
    this.step3MarkTriggerSubject.next(true);
  }

  onBackToStep3($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step3Template);
    this.stepper.previous();
  }

  onContinueToStep5($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step5Template);
    this.stepper.next();
  }

  onBackToStep4($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step4Template);
    this.stepper.previous();
  }

  onContinueToStep6($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step6Template);
    this.stepper.next();
  }

  onBackToStep5($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step5Template);
    this.stepper.previous();
  }

  async onContinueToStep7($event: MouseEvent) {
    // setTimeout(() => this.currentTemplate = this.step7Template);
    await this.submitAccreditation();
    // this.stepper.next();
  }


  async submitAccreditation() {
    const company: BusinessSearchValue = this.formControlCompany.value;
    const natureOfBusiness: NatureOfBusinessValue = this.formControlNatureOfBusiness.value;
    const entityType: EntityTypeValue = this.formControlEntityType.value;
    const businessAddress: Address2ComponentValue = this.formControlBusinessAddress.value;
    const productSelection: ProductSelectionValue = this.formControlProductSelection.value;
    const isPartOfAggregator = this.formControlIsPartOfGroupOrAggregator.value;
    const aggregator: AggregatorSearchComponentValue = this.formControlGroupOrAggregator.value;


    const apiReqData = {
      companyName: company?.organisationName ?? '',
      natureofBusiness: natureOfBusiness,
      entityType: toEntityType(entityType),
      abn: this.formControlAbn.value ?? '',
      acn: this.showAcn ? (this.formControlAcn.value ?? '') : '',
      entityName: this.formControlEntityName.value ?? '',
      trusteeCompanyName: this.showTrusteeInfo ? this.formControlTrustCompanyName.value : null,
      trusteeCompanyACN: this.showTrusteeInfo ? this.formControlTrustCompanyAcn.value : null,
      businessAddress: businessAddress ? toUnformattedAddress(businessAddress.address): null,
      heldAccreditationBefore: this.formControlHeldAccreditationBefore.value ?? false,
      growbizAccreditationId: this.formControlHeldAccreditationBefore.value ? this.formControlHeldAccreditationName.value : null,
      ispartOfgroupOrAggregator: isPartOfAggregator ?? false,
      groupOrAggregatorName: isPartOfAggregator && aggregator ? aggregator.salesforceId : null,
      officeNumber: this.formControlOfficePhoneNumber.value ?? '',
      hasAustralianCreditLicence: this.formControlHoldAustralianCreditLicence.value ?? false,
      privacyConfirmation: true,
      declaredBankrupt: this.formControlHasBankruptcy.value ?? false,
      refusedAccreditation: this.formControlHasAccreditationRefusal.value ?? false,
      convictedOfFraud: this.formControlHasConvictedFraudOrAccreditationCancellation.value ?? false,
      dirORemployeeOfLicenceEntity: this.formControlDirectorOrEmployeeOfLicencedEntity.value ?? false,
      business: company,
      Products: {
        assetFinance: (productSelection ?? []).includes('asset-finance'),
        businessLoan: (productSelection ?? []).includes('business-loan'),
        insurancePremium: (productSelection ?? []).includes('insurance-premium'),
      },

      // uploadedDocuments: [],
      // uploadedDocuments: [{
      //   name: '',
      //   base64: '',
      //   type: '',
      //   size: '',
      // }],
    };

    // australian credit licence number
    if (this.formControlHoldAustralianCreditLicence.value) {
      (apiReqData as any).australianCreditLicenceNumber = this.formControlAustralianCreditLicenceNumber.value;
    }

    // convicted of frauds details
    if (this.formControlHasConvictedFraudOrAccreditationCancellation.value) {
      (apiReqData as any).convictedOfFraudDetails = this.formControlConvictedFraudOrAccreditationCancellationDetails.value;
    }

    let uploadFiles = []
    let data = {};
    if (this.isBroker() || this.isVendor() || this.isDealer()) {
      // add in step3 info
      const dob: Moment | null = this.formControlDob.value;
      const title: TitleSelectionValue = this.formControlTitle.value;
      const driverLicence: UploadFileValue = this.formControlDriverLicence.value;
      const fileArr = this.formControlDriverLicence.value!.map((f) => {
        (f as any).tags = [constants.documentTypes.driversLicence.value];
        (f as any).metadata = this.otherDocMetadata;
        return f;
      });
      const driverLicenceBase64: Base64File[] =  await filesToBase64Files(fileArr ?? []);
      const driverLicenceState: StateSelectionValue = this.formControlDriverLicenceIssuingState.value;
      const driverLicenceExpiryDate: Moment | null = this.formControlDriverLicenceExpiryDate.value;
      const residentialAddress: Address2ComponentValue = this.formControlResidentialAddress.value;
      data = {
        title: title?.type ?? null,
        givenName: this.formControlFirstName.value,
        middleName: null,
        surName: this.formControlLastName.value,
        preferredName: this.formControlFirstName.value ,
        mobileNumber: this.formControlMobileNumber.value,
        residentialAddress: residentialAddress ? toUnformattedAddress(residentialAddress.address) : null,
        driverLicence: driverLicenceBase64.length ? driverLicenceBase64[0].base64 : null,
        driverLicenceNumber: removeUnprintableChar(this.formControlDriverLicenceNumber.value),
        driverLicenceExpiryDate: driverLicenceExpiryDate,
        driverLicenceIssuingState: driverLicenceState?.type ?? null,
        email: this.formControlEmail.value,
        dob: dob?.format('YYYY-MM-DD'),
        hasAnotherName: false,
      }
      if (driverLicenceBase64.length) {
        uploadFiles.push(...driverLicenceBase64);
      }
    }

    if (this.isBroker()||this.isDealer()) {
      const files1: File[] = this.formControlIndustryMembershipFileUpload.value ?? [];
      const files1Arr = this.formControlIndustryMembershipFileUpload.value!.map((f) => {
        (f as any).tags = [constants.documentTypes.industryMemberships.value];
        (f as any).metadata = this.otherDocMetadata;
        return f;
      });
      const files2: File[] = this.formControlPublicIndemnityInsuranceFileUpload.value ?? [];
      const files2Arr = this.formControlPublicIndemnityInsuranceFileUpload.value!.map((f) => {
        (f as any).tags = [constants.documentTypes.professionalIndemnityInsurance.value];
        (f as any).metadata = this.otherDocMetadata;
        return f;
      });
      const files3: File[] = this.formControlOtherSupportingDocumentsFileUpload.value ?? [];
      // const files4: File[] = this.formControlOtherSupportingDocumentsFileUpload.value;
      const base64Files1: Base64File[] = await filesToBase64Files(files1Arr ?? []);
      const base64Files2: Base64File[] = await filesToBase64Files(files2Arr ?? []);
      if (files3 !== null) {
        const base64Files3: Base64File[] = await filesToBase64Files(files3);
        uploadFiles.push(...base64Files3);
      }
      // const base64Files4: Base64File[] = await filesToBase64Files(files4);
      uploadFiles.push(...base64Files1);
      uploadFiles.push(...base64Files2);

      // uploadFiles.push(...base64Files4);
    }
    if (this.isVendor() || this.isSupplier()) {
      const files: UploadFileValue = this.formControlOtherSupportingDocumentsFileUpload.value;
      if(files) {
        const base64Files: Base64File[] = await filesToBase64Files(files);
        uploadFiles.push(...base64Files);
      }
    }
    if(this.isBroker()){
      const files: UploadFileValue = this.formControlreferralAgreement.value ?? [];
      const filesArr = this.formControlreferralAgreement.value!.map((f) => {
        (f as any).tags = [constants.documentTypes.introducerReferralAgreement.value];
        (f as any).metadata = this.otherDocMetadata;
        return f;
      });
      const base64Files: Base64File[] = await filesToBase64Files(filesArr ?? []);
      uploadFiles.push(...base64Files);
    }

    const apiData = {
      ...apiReqData,
      ...data,
      uploadedDocuments: [...uploadFiles]
    };

    console.log('************ submit accreditation', apiData);
    const sub = this.accreditationService.submitAccreditation(apiData).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        if (r && r.status) {
          this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
            tap(async r => {
              this.stepper.next();
              this.minimalLayoutService.settings({
                showPrev: false,
                showCross: true,
                showContact: true,
                showAccreditationInContact: false,
              })
            })
          ).subscribe();
        } else {
          this.applicationDialogService.openAlertDialog({
            message: `Error`,
            subMessage: r.message
          });
        }
      })
    ).subscribe();
    this.subscriptions.push(sub)
    const subscription = this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch(r.type) {
            case 'cross-clicked': {
              window.location.reload();
              // this.stepper.reset();
              // this.minimalLayoutService.settings({
              //   showPrev: true,
              //   showCross: false,
              //   showContact: true,
              //   showAccreditationInContact: false,
              // });
              // this.childProductSelection.forEach(c => c.reset())
              // this.childBusinessSearch.forEach(c => c.reset())
              break;
            }
          }
        }
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  async onSignUpClicked($event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    await this.router.navigate(navigationUrlForSignup());
  }

  onStep6DocEvent(files: UploadAzureFiles) {
    if(files && files.length > 0) {
      this.formControlOtherSupportingDocumentsFileUpload.setValue(files)
    } else {
      this.formControlOtherSupportingDocumentsFileUpload.setValue(null)
    }
  }

  getAllDefaultTags(): DocumentTag[] {
    return this.getAllAccreditationDefaultTags()
  }
}

