import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  BsbComponentValue,
  CurrencyInputValue,
  TaxInvoiceDetailsDialogData,
  TaxInvoiceDetailsDialogResult,
  TaxInvoiceDetailsSuccess,
  NameComponentValue,
  DatepickerValue,
  AccountNumberValue, STANDARD_INPUT_DATE_FORMAT
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BsbComponent } from '../bsb-component/bsb.component';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { NameComponent } from '../name-component/name.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { AccountNumberComponent } from '../account-number-component/account-number.component';
import {
  noSpaceValidator,
  formControlErrorKeys,
  formControlErrorMessage,
} from '@portal-workspace/grow-ui-library';

@Component({
    templateUrl: './tax-invoice-details.dialog.html',
    styleUrls: ['./tax-invoice-details.dialog.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, FlexModule, MatButtonModule, MatDialogModule, BsbComponent, CurrencyInputComponent, NameComponent, DatepickerComponent, AccountNumberComponent]
})
export class TaxInvoiceDetailsDialog implements OnInit, AfterContentChecked {
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formGroup!: FormGroup<{
    invoiceId: FormControl<string | null>,
    invoiceDate: FormControl<DatepickerValue | null>,
    accountName: FormControl<NameComponentValue | null>,
    bsb: FormControl<BsbComponentValue>,
    accountNumber: FormControl<AccountNumberValue | null>,
    financialInstitution: FormControl<string | null>,
    amount: FormControl<CurrencyInputValue>
  }>;
  formControlInvoiceId!: FormControl<string | null>;
  formControlInvoiceDate!: FormControl<DatepickerValue | null>;
  formControlAccountName!: FormControl<NameComponentValue | null>;
  formControlBsb!: FormControl<BsbComponentValue>;
  formControlAccountNumber!: FormControl<AccountNumberValue | null>;
  formControlFinancialInstitution!: FormControl<string | null>;
  formControlAmount!: FormControl<CurrencyInputValue>;
  taxInvoiceDetails!: TaxInvoiceDetailsSuccess;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TaxInvoiceDetailsDialogData,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaxInvoiceDetailsDialog, TaxInvoiceDetailsDialogResult>,
    private formBuilder: FormBuilder) {
    this.taxInvoiceDetails = data.taxInvoiceDetails;
    this.formControlInvoiceId = this.formBuilder.control(null,[Validators.required, Validators.maxLength(15),noSpaceValidator()]);
    this.formControlInvoiceDate = this.formBuilder.control(null,[Validators.required]);
    this.formControlAccountName = this.formBuilder.control(null,[Validators.required]);
    this.formControlBsb = this.formBuilder.control(null,[Validators.required]);
    this.formControlAccountNumber = this.formBuilder.control(null,[Validators.required,noSpaceValidator()]);
    this.formControlFinancialInstitution = this.formBuilder.control(null,[Validators.required, Validators.maxLength(15),noSpaceValidator()]);
    this.formControlAmount = this.formBuilder.control(null,[Validators.required]);

    this.formGroup = this.formBuilder.group({
      invoiceId: this.formControlInvoiceId,
      invoiceDate: this.formControlInvoiceDate,
      accountName: this.formControlAccountName,
      bsb: this.formControlBsb,
      accountNumber: this.formControlAccountNumber,
      financialInstitution: this.formControlFinancialInstitution,
      amount: this.formControlAmount
    })
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.populate();
  }

  populate() {
    if (this.taxInvoiceDetails) {
      this.formControlInvoiceId.setValue(this.taxInvoiceDetails.InvoiceId);
      this.formControlInvoiceDate.setValue(this.taxInvoiceDetails.InvoiceDate as any);
      this.formControlAccountName.setValue(this.taxInvoiceDetails.AccountName);
      this.formControlBsb.setValue(this.taxInvoiceDetails.Bsb);
      this.formControlAccountNumber.setValue(this.taxInvoiceDetails.AccountNumber);
      this.formControlFinancialInstitution.setValue(this.taxInvoiceDetails.FinancialInstitution);
      this.formControlAmount.setValue(parseInt(this.taxInvoiceDetails.Amount));
    }
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      invoiceDetails: this.formGroupInvoiceDetails,
    })
  }

  get formGroupInvoiceDetails() {
    return {
      invoiceId: this.formControlInvoiceId.value ?? "",
      invoiceDate: this.formControlInvoiceDate.value?.format(STANDARD_INPUT_DATE_FORMAT)  ?? "",
      accountName: this.formControlAccountName.value ?? "",
      bsb: this.formControlBsb.value ?? "",
      accountNumber: this.formControlAccountNumber.value ?? "",
      financialInstitution: this.formControlFinancialInstitution.value ?? "",
      amount: this.formControlAmount.value ?? 0
    }
  }
}
