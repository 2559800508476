<div class="ppsr-asset-component">
  <div class="row">
    <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="mat-h4-custom">
        @if (title) {
          <span>{{title}}</span>
        }
        @if (showSerialNumber) {
          <span class="mdi mdi-magnify cursor-pointer p-1" (click)="searchAsset($event)"></span>
        }
      </div>
      <div>
        @if (disableControl) {
          <button class="mdi mdi-plus-box-outline cursor-pointer p-1 button"  disabled="{{disableControl}}" (click)="onAddAsset($event)"></button>
        }
        @if (disableControl) {
          <button class="mdi mdi-trash-can-outline cursor-pointer p-1 button"  disabled="{{disableControl}}" (click)="onRemoveAsset($event)"></button>
        }
        @if (!disableControl) {
          <button class="mdi mdi-plus-box-outline cursor-pointer p-1 button"   (click)="onAddAsset($event)"></button>
        }
        @if (!disableControl && allowDelete) {
          <button class="mdi mdi-trash-can-outline cursor-pointer p-1 button"  (click)="onRemoveAsset($event)"></button>
        }
      </div>
    </div>
  </div>

  @if (formGroup.value) {
    <div>
      @if (formControlVehicleDetails.value) {
        @if (!formControlVehicleDetails.value.NoVehicleInfo) {
          <div class="row mt-2">
            <div class="col-12">
              <h4 class="mat-h4-custom">Vehicle details </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Number of PPSR Registration</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.ResultCount" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label>Body Type</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.BodyType" disabled>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Colour</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.Colour" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Compliance Year Month</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.ComplianceYearMonth" disabled>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Engine Number</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.EngineNumber" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Make</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.Make" disabled>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Manufacture Year</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.ManufactureYear" disabled>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Model </mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.Model" disabled>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Vehicle Type</mat-label>
                <input matInput type="text" [value]="formControlVehicleDetails.value.VehicleType" disabled>
              </mat-form-field>
            </div>
          </div>
        }
        @if (formGroup.controls.vehicleDetails.value && formGroup.controls.vehicleDetails.value.NoVehicleInfo) {
          <div class="row mt-2">
            <div class="col-12">
              <message-box [type]="'danger'">
                NEVDIS has responded with no vehicle information for this serial number. This is possibly due to an error in the serial number you entered.
              </message-box>
            </div>
          </div>
        }
      }
    </div>
  }

  <div class="row mt-4">
    <div class="col-6">
      <asset-category [key]="key" [formControl]="formControlAssetCategory" [disabled]="disableControl" ></asset-category>
    </div>
    <div class="col-6">
      <asset-type [key]="key" [formControl]="formControlAssetType" [disabled]="disableControl"></asset-type>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Asset description</mat-label>
        <textarea matInput [formControl]="formControlAssetDescription"  [readonly]="disableControl" ></textarea>
      </mat-form-field>
    </div>
  </div>
  @if (showMake) {
    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Make</mat-label>
          <input type="text" matInput [formControl]="formControlAssetMake">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field >
          <mat-label>Model</mat-label>
          <input type="text" matInput [formControl]="formControlAssetModel">
        </mat-form-field>
      </div>
    </div>
  }
  @if (showMake) {
    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Year</mat-label>
          <input type="text" required [inputMask]="createYearMask" matInput [formControl]="formControlAssetYear">
        </mat-form-field>
      </div>
    </div>
  }
  @if (showSerialNumber) {
    <div class="row">
      <div class="col-6">
        <mat-form-field >
          <mat-label>Serial number type</mat-label>
          <mat-select [formControl]="formControlSerialNumberType" [disabled]="disableControl">
            @for (option of vehicleSerialNumberTypeOptions; track option) {
              <mat-option [value]="option.key">{{option.key}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>{{formControlSerialNumberType.value}}</mat-label>
          <input matInput type="text" [formControl]="formControlSerialNumber" [readonly]="disableControl" >
        </mat-form-field>
      </div>
    </div>
  }

  <div class="row">
    <div class="col-6">
      <currency-input [mark]="markObservable"  title="Invoice price (incl GST)"  [formControl]="formControlInvoicePrice" [disabled]="disableControl"></currency-input>

    </div>
    <div class="col-6">
      <currency-input title="GST"  [formControl]="formControlGst" [readonly]="true" [disabled]="disableControl"></currency-input>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button mat-stroked-button (click)="onAddAssetWithSupplier($event)"> Add Asset</button>
      <!-- <button class="mdi mdi-plus-box-outline cursor-pointer p-1 button"  (click)="onAddAsset($event)">addddddddddddd</button> -->
    </div>
  </div>

  @if (isSupplierAddAsset) {
    <div class="row">
      <div class="col">
        <ppsr-asset-supplier-search
          [searchSupplierFn]="searchSupplierFn"
          [saveSupplierFn]="saveSupplierFn"
          [businessSearchFn]="businessSearchFn"
          (events)="onSupplierEvent($event)"
          [formControl]="formControlSupplier"
          [disabled]="disableControl">
        </ppsr-asset-supplier-search>
      </div>
    </div>
  }

</div>
