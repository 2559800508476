<div class="editable-currency-component"
     [ngClass]="{'edit': mode == 'edit', 'view': mode == 'view'}">
  <editable (modeChange)="modeChange($event)">
    <ng-template editMode>
      <div fxLayout="row"
           fxLayoutAlign="start start"
           fxLayoutGap="5px">
        <currency-input
          title=""
          [formControl]="formControl">
        </currency-input>
        <button mat-flat-button
                (click)="onSave()"
                editableOnSave>
          <span class="mdi mdi-check-outline"
                matTooltip="Save"></span>
        </button>
      </div>
    </ng-template>
    <ng-template viewMode>
      <div fxLayout="row"
           fxLayoutAlign="start start"
           fxLayoutGap="5px">
        <span class="w-100">{{readValue ?? '' | looseCurrency}}</span>
        <span class="mdi mdi-pencil-outline"
            matTooltip="Edit"></span>
      </div>
    </ng-template>
  </editable>
</div>
