<div class="business-disbursement-invoices-dialog">
  <mat-dialog-content>
    <h3 class="header">Please enter reference number</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Reference number</mat-label>
          <input #invoiceInput
            matInput
            [formControl]="formControlInvoiceNumber">
            @for (errorKey of errorKeys(formControlInvoiceNumber); track errorKey) {
              <mat-error>
                @if (formControlInvoiceNumber.touched && formControlInvoiceNumber.hasError(errorKey)) {
                  {{errorMessage(formControlInvoiceNumber, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
            <button color="primary" (click)="onSubmit(false)" mat-stroked-button>Close</button>
            <button color="primary" [disabled]="!formControlInvoiceNumber.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
