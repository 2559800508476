<div class="supplier2-component">
  <p class="title">Please find and select a {{title}} by its ABN number</p>
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>{{title}} ABN</mat-label>
        <!--
        NOTE:
        [inputMask]="createAbnInputMask" will break the way mat-autocomplete works we can't use it here
        -->
        <input matInput [formControl]="formControlSearch"
          type="text"
          [matAutocomplete]="auto"
          [readonly]="disabled">
          @for (errorKey of errorKeys(formControlSearch); track errorKey) {
            <mat-error>
              @if (formControlSearch.touched && formControlSearch.hasError(errorKey)) {
                {{errorMessage(formControlSearch, errorKey)}}
              }
            </mat-error>
          }
          <mat-autocomplete (optionSelected)="onOptionSelected($event)" [displayWith]="displayWithFn"
            #auto="matAutocomplete">
            @for (option of filteredAssetSuppliers; track option) {
              <mat-option [value]="option">
                {{option.ABN}} - {{option.SupplierName}}
              </mat-option>
            }
            @if (!flag) {
              <mat-option (click)="addSupplier()" value="-1">
                Click here to add supplier <mat-icon class="mdi mdi-arrow-top-right" matSuffix></mat-icon>
              </mat-option>
            }
          </mat-autocomplete>
          @if (state == 'loading') {
            <mat-spinner diameter="25" matSuffix></mat-spinner>
          }
          @if (state == 'selected') {
            <img src="/assets/images/check-circle.svg" alt="chevron" class="filter-green" matSuffix />
          }
        </mat-form-field>
      </div>
    </div>
    @if (selectedSupplier) {
      <div class="asset-supplier-details">
        <p class="title">Selected Supplier Details</p>
        <div class="section">
          <div class="row reduceSpace">
            <div class="col-6">
              <cell>
                <div label>Supplier ABN</div>
                <div body>{{selectedSupplier.ABN}}</div>
              </cell>
            </div>
            <div class="col-6">
              <cell>
                <div label>Contact</div>
                <div body>{{selectedSupplier.Contact ? selectedSupplier.Contact : '-'}}</div>
              </cell>
            </div>
          </div>
          <div class="row reduceSpace">
            <div class="col-6">
              <cell>
                <div label>Supplier Name</div>
                <div body>{{selectedSupplier.SupplierName}}</div>
              </cell>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
