<div class="transaction-type-rate-component">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Transaction Type Rate</mat-label>
          <input [inputMask]="createTwoDecimalInputMask" matInput [formControl]="formControlRate" [required]="mandatory">
          <span class="mdi mdi-percent" matSuffix></span>
          @for (errorKey of errorKeys(formControlRate); track errorKey) {
            <mat-error>
              @if (formControlRate.touched && formControlRate.hasError(errorKey)) {
                {{errorMessage(formControlRate, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-6">
        <currency-input title="Additional Doc Fee" [formControl]="formControlAdditionalDocFee"></currency-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-chip-listbox [formControl]="formControlChips" [multiple]="true" >
          @for (option of transactionTypeOptions; track option) {
            <mat-chip-option [value]="option">
              {{option.name}}
            </mat-chip-option>
          }
        </mat-chip-listbox>
        @for (errorKey of errorKeys(formControlChips); track errorKey) {
          <mat-error>
            @if (formControlChips.touched && formControlChips.hasError(errorKey)) {
              {{errorMessage(formControlChips, errorKey)}}
            }
          </mat-error>
        }
      </div>
    </div>
  </form>
</div>
