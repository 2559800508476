<div class="disbursement-payee-dialog">
  <mat-dialog-content>
    <h3 class="header">Tax Invoice Details</h3>
    <div class="row">
      <div class="col-8">
      </div>
    </div>
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Invoice Id</mat-label>
            <input matInput type="text" [formControl]="formControlInvoiceId">
            @for (errorKey of errorKeys(formControlInvoiceId); track errorKey) {
              <mat-error>
                @if (formControlInvoiceId.touched && formControlInvoiceId.hasError(errorKey)) {
                  {{errorMessage(formControlInvoiceId, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="col-6">
          <datepicker
            title="Invoice Date"
            [formControl]="formControlInvoiceDate">
          </datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <name title="Account Name"  [formControl]="formControlAccountName"></name>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Financial Institution</mat-label>
            <input matInput type="text" required [formControl]="formControlFinancialInstitution">
            @for (errorKey of errorKeys(formControlFinancialInstitution); track errorKey) {
              <mat-error>
                @if (formControlFinancialInstitution.touched && formControlFinancialInstitution.hasError(errorKey)) {
                  {{errorMessage(formControlFinancialInstitution, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <bsb [formControl]="formControlBsb"></bsb>
        </div>
        <div class="col-6">
          <account-number [formControl]="formControlAccountNumber"></account-number>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <currency-input title="Amount" [formControl]="formControlAmount"></currency-input>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="width-100">
          <button color="primary" [disabled]="!formGroup.valid" (click)="onSubmit(true)" mat-flat-button>Confirm</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
