export type TitleSelectionType = 'Prof' | 'Dr' | 'Mr' | 'Mrs' | 'Ms';
export type TitleSelectionValue = {
  type: TitleSelectionType,
  name: string,
} | null;

export const TitleSelectionValueOptions: Exclude<TitleSelectionValue, null>[] = [
  {type: 'Prof', name: 'Prof.'},
  {type: 'Dr', name: 'Dr.'},
  {type: 'Mr', name: 'Mr.'},
  {type: 'Ms', name: 'Ms.'},
  {type: 'Mrs', name: 'Mrs.'},
];
