import {Component, forwardRef, inject, OnInit} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {Mark} from "@portal-workspace/grow-ui-library";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from "@angular/forms";
import {MARK} from "@portal-workspace/grow-ui-library/mark";
import {CurrencyInputComponent} from '../../components/currency-selection-component/currency-input.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {CurrencyInputValue} from "@portal-workspace/grow-shared-library";
import {LooseCurrencyPipe} from '../../pipes/loose-currency.pipe';
import {Mode} from "@ngneat/edit-in-place/lib/mode";
import {EditableComponent, EditableSaveDirective, EditModeDirective, ViewModeDirective} from "@ngneat/edit-in-place";



@Component({
  selector: 'editable-currency',
  templateUrl: './editable-currency.component.html',
  styleUrls: ['./editable-currency.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    JsonPipe,
    AsyncPipe,
    CurrencyInputComponent,
    MatTooltipModule,
    ReactiveFormsModule,
    MatButtonModule,
    LooseCurrencyPipe,
    EditableComponent,
    EditableSaveDirective,
    EditModeDirective,
    ViewModeDirective,
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EditableCurrencyComponent), multi: true },
    { provide: MARK, useExisting: forwardRef(() => EditableCurrencyComponent), multi: true },
  ]
})
export class EditableCurrencyComponent extends AbstractControlValueAccessor<CurrencyInputValue> implements OnInit, Mark {

  mode: Mode = 'view';

  readValue: CurrencyInputValue = null;
  formControl!: FormControl<CurrencyInputValue>;

  formBuilder = inject(FormBuilder);

  constructor() {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required]);
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

  ngOnInit(): void {
  }

  doWriteValue(v?: CurrencyInputValue | undefined): void | CurrencyInputValue {
    if (v != null || v != undefined) {
      this.readValue = v;
      this.formControl.setValue(v);
    } else {
      this.readValue = null;
      this.formControl.setValue(null);
    }
  }

  onSave() {
    if (this.formControl.valid) {
      this.readValue = this.formControl.value;
      this.propagateChange(this.readValue);
    } else {
      this.propagateChange(null);
    }
  }

  modeChange($event: Mode) {
    this.mode = $event;
  }
}

