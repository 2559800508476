<div class="overdraft-account-transactions-component">
  @if (loader.tableLoading.inProgress$ | async) {
    <div class="p-2">
      <custom-content-loader type="list" ></custom-content-loader>
    </div>
  }
  <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">

    <div class="row mt-2 mr-2">
      <div class="col-12 header" fxLayout="row" fxLayoutAlign="end start" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button class="btn btn-lg" (click)="reload()">
          <span class="mdi mdi-refresh custom-icon-size" matPrefix></span>
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2" matSort>
      @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef>{{getColumnTitles(column)}}</th>
          <td mat-cell *matCellDef="let element">
            @if (column === 'icon') {
              <span
                class="{{getTransactionAttributes(transactionCategory(element))}} {{toClass(element)}} icon">
              </span>
            }
            @if (column === 'description') {
              {{soft_descriptor(element)}}
              <br><span>
              {{getDate(createdAt(element))}} {{getTime(createdAt(element))}}
            </span>
          }
          @if (column === 'amount') {
                        <div class="pr-3" [ngStyle]="{
                            'color': isPendingTransaction(element) ? 'var(--portal2-sg-blue-grey-lighten-1)' :
                                     is_credit(element) ? 'var(--portal2-primary-default)' :
                                     'var(--portal2-sg-blue-grey-darken-4)',
                                     'text-align': 'right'
                          }">
              {{getRelativeTransactionAmount((amount(element)), is_credit(element))}}
              <br>@if (isPendingTransaction(element)) {
                <span>Pending</span>
              }
            </div>
          }
         </td>
      </ng-container>
    }

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        @if (isRowExpanded(element) && (loader.rowContentLoading.inProgress$ | async)) {
          <div class="p-3">
            <custom-content-loader type="list" ></custom-content-loader>
          </div>
        }
        @if (!(isRowExpanded(element) && (loader.rowContentLoading.inProgress$ | async))) {
          <div class="example-element-detail"
            [@detailExpand]="isRowExpanded(element) ? 'expanded' : 'collapsed'">
            <div class="row">
              <div class="col">
                <div class="row pt-0">
                  <div class="col-12">
                    <div class="ml-3">
                    <!--mat-divider></mat-divider-->
                    <ng-container class="ml-3">
                      <!-- {{amount | async}} -->
                      @if (transactionDetails?.id ?? transactionDetails?.account_id) {
                        <div>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">To</div>
                            <div class="col-3">
                            {{transactionDetails?.metadata?.AccountTitle}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">Pismo Account Number</div>
                            <div class="col-3">{{transactionDetails?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">From</div>
                            <div class="col-6">{{accountDetails?.account_name}} {{accountDetails?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">Category</div>
                            <div class="col-3">
                            {{transactionDetails?.processing_description}}</div>
                          </div>
                          @if(transactionDetails?.metadata?.['Reason']) {
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-information-outline icon"></span>
                            </div>
                            <div class="col-3 title">Failure Reason</div>
                            <div class="col-3">{{transactionDetails?.metadata?.['Reason']}}</div>
                          </div>
                          }
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Transaction reference number
                            </div>
                            <div class="col-3">
                            {{transactionDetails?.authorization?.tracking_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Transaction Type</div>
                            <div class="col-3">
                            {{transactionDetails?.transaction_type?.description}}</div>
                          </div>
                          @if (transactionDetails?.metadata?.Description) {
                            <mat-divider></mat-divider>
                            <div class="row pismo-account-details">
                              <div>
                                <span class="mdi mdi-information-outline icon"></span>
                              </div>
                              <div class="col-3 title">Description</div>
                              <div class="col-3 word-wrap">
                              {{transactionDetails?.metadata?.Description}}</div>
                            </div>
                          }
                        </div>
                      }
                      @if (pendingPaymentTransaction?.account_id) {
                        <div>
                          @if (pendingPaymentTransaction?.metadata?.AccountTitle) {
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">To</div>
                            <div class="col-3">
                              {{pendingPaymentTransaction?.metadata?.AccountTitle}}
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                        }
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">Pismo Account Number</div>
                            <div class="col-3">{{pendingPaymentTransaction?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          @if(accountDetails?.account_name || accountDetails?.account_id) {
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">From</div>
                            <div class="col-6">{{accountDetails?.account_name}} {{accountDetails?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          }
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">Category</div>
                            <div class="col-3">
                            {{pendingPaymentTransaction?.type}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-information-outline icon"></span>
                            </div>
                            <div class="col-3 title">Failure Reason</div>
                            <div class="col-3">{{pendingPaymentTransaction?.metadata?.['Reason']}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Transaction reference number
                            </div>
                            <div class="col-3">
                            {{pendingPaymentTransaction?.data?.item?.id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Description</div>
                            <div class="col-3">
                            {{transactionDetails?.transaction_type?.description}}</div>
                          </div>
                        </div>
                      }
                      @if (pendingTransactionDetails?.data?.item?.id) {
                        <div>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">Pismo Account Number</div>
                            <div class="col-3">{{pendingTransactionDetails?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">Card Member</div>
                            <div class="col-3">
                            {{cardDetails?.printed_name}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-information-outline icon"></span>
                            </div>
                            <div class="col-3 title">Transaction reference number
                            </div>
                            <div class="col-3">
                            {{pendingTransactionDetails?.data?.item?.id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Card last four digit</div>
                            <div class="col-3">{{cardDetails?.last_4_digits}}</div>
                          </div>
                          @if (pendingTransactionDetails?.data?.item?.token_information?.network_token) {
                            <div>
                              <mat-divider></mat-divider>
                              <div class="row pismo-account-details">
                                <div>
                                  <span class="mdi mdi-credit-card icon"></span>
                                </div>
                                <div class="col-3 title">Token number</div>
                                <div class="col-3">
                                {{pendingTransactionDetails?.data?.item?.token_information?.network_token ?? 'Not found'}}</div>
                              </div>
                            </div>
                          }
                        </div>
                      }
                      @if ((merchantTransaction)?.account_id) {
                        <div>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-check icon"></span>
                            </div>
                            <div class="col-3 title">Pismo Account Number</div>
                            <div class="col-3">{{merchantTransaction?.account_id}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-arrow-top-right icon"></span>
                            </div>
                            <div class="col-3 title">Card Member</div>
                            <div class="col-3">
                            {{cardDetails?.printed_name}}</div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-information-outline icon"></span>
                            </div>
                            <div class="col-3 title">Transaction reference number
                            </div>
                            <div class="col-3">
                              <!-- {{merchantTransaction?.id}} -->
                            {{getMerchantTransactionId(merchantTransaction)}}
                          </div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="row pismo-account-details">
                            <div>
                              <span class="mdi mdi-credit-card icon"></span>
                            </div>
                            <div class="col-3 title">Card last four digit</div>
                            <div class="col-3">{{cardDetails?.last_4_digits}}</div>
                          </div>
                          @if (getMerchantNetworkToken(merchantTransaction)) {
                            <div>
                              <mat-divider></mat-divider>
                              <div class="row pismo-account-details">
                                <div>
                                  <span class="mdi mdi-credit-card icon"></span>
                                </div>
                                <div class="col-3 title">Token number</div>
                                <div class="col-3">
                                {{getMerchantNetworkToken(merchantTransaction)}}</div>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
    class="example-element-row cursor-pointer"
    [class.example-expanded-row]="isRowExpanded(element)"
    (click)="onRowClicked(element)">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
  (click)="expandedElement = null"></tr>
</table>
@if (total<1) {
  <table class="width-100">
    <tr>
      <td class="not-found"> No transactions found</td>
    </tr>
  </table>
}
@if (total) {
  <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
  [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
}

</div>
</div>
