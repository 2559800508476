import {Component, Input} from '@angular/core';

import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    selector: 'data-box-2',
    templateUrl: './data-box-2.component.html',
    styleUrls: ['./data-box-2.component.scss'],
    standalone: true,
    imports: [FlexModule]
})
export class DataBox2Component {

  @Input({required: false}) type: 'green'| 'red' | 'blue' | 'solid-grey' = 'green';
  @Input({required: false}) title: string = '';




}
