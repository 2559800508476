import {ChangeDetectorRef, Component, inject, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  Application,
  ApplicationCreditFlowNodeDetailTableData,
  ApplicationCreditOfficer,
  ApplicationOwner,
  CONTRACT_EMAIL_DOMAIN,
  CREDIT_EMAIL_DOMAIN,
  CustomerAngularEditorComponentValue,
  DocumentReviewDialogData,
  GetApplicationCreditOfficerFn,
  GetApplicationOwnerFn,
  GetUserFn,
  GroupedDocument,
  isExternalUser,
  MoreInformationDialogData, MoreInformationDialogResult, SALES_EMAIL_DOMAIN, User, UserandPriviledges
} from '@portal-workspace/grow-shared-library';
import {Subscription, tap} from 'rxjs';
import {getUser, PortalHotToastService, setupUntilDestroy, validateEmail} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { isInternalUser } from '@portal-workspace/grow-shared-library';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './more-information.dialog.html',
    styleUrls: ['./more-information.dialog.scss'],
    standalone: true,
    imports: [MatDividerModule, FlexModule, MatFormFieldModule, MatChipsModule, FormsModule, ReactiveFormsModule, CustomAngularEditorComponent, MatCheckboxModule, MatButtonModule, MatDialogModule, MatExpansionModule, CommonModule, MatCardModule, MatTooltipModule]
})
export class MoreInformationDialog implements OnInit{

  formControlAdditionalInformation!: FormControl<string | null>;
  formControlRemoveFromWorklist!: FormControl<boolean | null>;
  formControlCredit!: FormControl<boolean | null>;
  formGroup!: FormGroup;
  emailList: string[] = [];
  formControlEmail!: FormControl<string | null>;
  emailError = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  application!: Application;
  submitterEmailList: string[] = [];
  data: DocumentReviewDialogData = inject(MAT_DIALOG_DATA);
  subscriptions: Subscription[] = [];
  placeholder = 'Enter the additional information...';
  getUserFn!: GetUserFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn;
  getApplicationCreditOfficerFn!: GetApplicationCreditOfficerFn;
  alltagStatus: string[] = [];
  loggedInUser: User | null = getUser();
  isInternalUser = isInternalUser;
  creditEmail: string = "";
  submitterEmailListWithKeyName: { control: FormControl | null ; email: string; }[] = [];
  otherEmailList: string[] = [];
  submitterEmailError = false;
  formControlApplicationNotes!: FormControl<string | null>;
  isSubmitEnabled: boolean = false;
  filtertagStatus: string[] = [];
  formControlInternalOnly!: FormControl<boolean | null>;
  formControlSales!: FormControl<boolean | null>;
  formControlSettlements!: FormControl<boolean | null>;
  formControlSubmitter: FormControl<boolean | null>;
  salesEmail: string = "";
  isInternalOnly: boolean = false;
  formControlSubmitterEmail!: FormControl<string | null>;
  isShowRequestReviewSection: Boolean = false;
  title: string = 'Credit Review Documents';
  notesPlaceholder: string = `Provide background information to support your request to review these documents.\n\nOur team will respond to you as soon as possible.`
  editorHeight = 200;
  allTags: GroupedDocument[] = [];
  filterTag: GroupedDocument[] = [];
  removedTag: GroupedDocument[] = [];
  selectableTags: GroupedDocument[] = [];
  pendingCount: number = 0;
  inProgressCount: number = 0;
  isDocumentsConditionsExpanded = false;
  formControlAssignWorklist:FormControl<boolean | null>;
  errorMessage: string = '';
  isExternalUser : boolean = isExternalUser(this.loggedInUser);
  selectedCheckboxCount: number = 0;
  private isAdditionalInfoNonEmpty = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: MoreInformationDialogData,
    private fb: FormBuilder,
    private toastService: PortalHotToastService,
    private matDialogRef: MatDialogRef<MoreInformationDialog, MoreInformationDialogResult>,
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    


  ) {
    this.formControlAdditionalInformation = this.fb.control('');
    this.isShowRequestReviewSection = dialogData.isShowRequestReviewSection
    this.formControlAssignWorklist = this.formBuilder.control(false)
    this.formControlInternalOnly = this.formBuilder.control(false);
    this.formControlCredit = this.formBuilder.control(false);
    this.formControlSales = this.formBuilder.control(false);
    this.formControlSettlements = this.formBuilder.control(false);
    this.formControlSubmitter = this.formBuilder.control(false);
    this.formControlEmail = this.fb.control(null);
    this.formControlSubmitterEmail = this.formBuilder.control(null);
    this.formControlApplicationNotes = this.formBuilder.control('')
    this.getUserFn = dialogData.getUserFn;
    this.getApplicationOwnerFn = dialogData.getApplicationOwnerFn;
    this.formGroup = this.fb.group({
      additionalInformation: this.formControlAdditionalInformation,
    });
    
    if(!this.isShowRequestReviewSection){
      this.notesPlaceholder = "";
      this.editorHeight = 100
    }
    
    if(this.isExternalUser && this.dialogData.groupNames.filter(arg=>arg.status === "In Progress").length){
      this.isDocumentsConditionsExpanded=true
    }
    
    if(this.isShowRequestReviewSection){
      if(isInternalUser(this.loggedInUser)){
        this.formControlCredit.setValue(true)
        this.formControlSubmitter.setValue(true)
      }
    }
    
    // Initialize checkboxStates based on initial form control values
    this.checkboxStates = {
      internalOnly: this.formControlInternalOnly.value ?? false,
      submitter: this.formControlSubmitter.value ?? false,
      sales: this.formControlSales.value ?? false,
      credit: this.formControlCredit.value ?? false,
      settlements: this.formControlSettlements.value ?? false,
    };

  }

  ngOnInit() {
    setupUntilDestroy(this);

    // email list
    this.application = this.dialogData.application;
    // this.selectedCheckboxCount = Object.values(this.checkboxStates).filter(state => state).length;
    this.isAdditionalInfoNonEmpty = false;

    this.updateSubmitEnabled();

    this.subscriptions.push(
      this.getUserFn(this.application.UserId, false).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(
        (user: UserandPriviledges | null) => {
          if (user) {
            this.emailList.push(user.Email);
          }
        }
      )
    )

    this.subscriptions.push(
      this.getApplicationOwnerFn(this.application.AppInfoSalesforceID ?? '').pipe(
        this.toastService.spinnerObservable()
      ).subscribe(
        (applicationOwner: ApplicationOwner | null) => {
          if (applicationOwner) {
            this.emailList.push(applicationOwner.Email);
          }
        }
      )
    )

    this.subscriptions.push(
      this.formControlEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.emailError = false;
        }
      })
    )

    this.getApplicationCreditOfficerFn = this.applicationService.getApplicationCreditOfficerFn;
    this.getUserFn = this.authService.getUserFn;
    this.getApplicationOwnerFn = this.applicationService.getApplicationOwnerFn;
    this.getApplicationCreditOfficerFn = this.applicationService.getApplicationCreditOfficerFn
    this.application = this.dialogData.application;
     
    if(this.isShowRequestReviewSection && isInternalUser(this.loggedInUser)){
      this.addEmail(this.application.AppInfo.UserEmail, this.formControlSubmitter);
      if(!this.creditEmail && this.application.AppInfoSalesforceID){
        this.getApplicationCreditOfficerFn(this.application.AppInfoSalesforceID)
          .pipe(this.toastService.spinnerObservable())
          .subscribe((Application: ApplicationCreditOfficer | null) => {
            if (Application && Application.CreditOfficerEmail) {
              const email = Application.CreditOfficerEmail;
              this.creditEmail = email;
            } else {
              this.creditEmail = CREDIT_EMAIL_DOMAIN;
            }
            this.addEmail(this.creditEmail, this.formControlCredit);
          }
        );
      } else if (!this.creditEmail){
        this.creditEmail = CREDIT_EMAIL_DOMAIN;
        this.addEmail(this.creditEmail, this.formControlCredit);
      } else { this.addEmail(this.creditEmail, this.formControlCredit); }
    }
     
    // Credit Officer
    this.subscriptions.push(this.formControlCredit.valueChanges.pipe(
      tap(arg => {
        if(arg){
          if(!this.creditEmail && _.has(this.application, "AppInfoSalesforceID")  && this.application.AppInfoSalesforceID){
            this.getApplicationCreditOfficerFn(this.application.AppInfoSalesforceID)
            .pipe(this.toastService.spinnerObservable())
            .subscribe((Application: ApplicationCreditOfficer | null) => {
                if (Application && Application.CreditOfficerEmail) {
                  const email = Application.CreditOfficerEmail;
                  this.creditEmail = email 
                } else {
                  this.creditEmail = CREDIT_EMAIL_DOMAIN;
                }
                this.addEmail(this.creditEmail, this.formControlCredit);
              },
            );
          } else if(!this.creditEmail){
            this.creditEmail = CREDIT_EMAIL_DOMAIN;
            this.addEmail(this.creditEmail, this.formControlCredit);
          } else { this.addEmail(this.creditEmail, this.formControlCredit); }
        } else {
          if(this.creditEmail){
            this.removeEmail(this.creditEmail);
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSales.valueChanges.pipe(
      tap(arg => {
        if(arg){
          if(!this.salesEmail && this.application.AppInfoSalesforceID){
            this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
            .pipe(this.toastService.spinnerObservable())
            .subscribe(
              (Application: ApplicationOwner | null) => {
                if (Application && Application.Email) {
                  const email = Application.Email;
                  this.salesEmail = email 
                } else {
                  this.salesEmail = SALES_EMAIL_DOMAIN;
                }
                this.addEmail(this.salesEmail, this.formControlSales);
              },
            );
          } else if(!this.salesEmail){
            this.salesEmail = SALES_EMAIL_DOMAIN;
            this.addEmail(this.salesEmail, this.formControlSales);
          } else { this.addEmail(this.salesEmail, this.formControlSales); }
        } else {
          if(this.salesEmail){
            this.removeEmail(this.salesEmail);
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlInternalOnly.valueChanges.pipe(
      tap(value => {
        if(value){
          this.formControlSubmitter.setValue(false);
          this.isInternalOnly = true
          this.removeEmail(this.application.AppInfo.UserEmail)
        } else {
          this.isInternalOnly = false
        }
      })
    ).subscribe());
    
    this.subscriptions.push(this.formControlSettlements.valueChanges.pipe(
      tap(arg => {
        if(arg){
          this.addEmail(CONTRACT_EMAIL_DOMAIN, this.formControlSettlements);
        }else {
          this.removeEmail(CONTRACT_EMAIL_DOMAIN)
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSubmitter.valueChanges.pipe(
      tap(value => {
         if(value){
          this.formControlInternalOnly.setValue(false);
          this.addEmail(this.application.AppInfo.UserEmail, this.formControlSubmitter);
         } else {
          this.removeEmail(this.application.AppInfo.UserEmail)
         }
      })
      
    ).subscribe());

    // this.subscriptions.push(this.formControlAdditionalInformation.valueChanges.pipe(
    //   tap(value => {
    //     if ((value && !_.isEmpty(value.replace(/<[^>]*>/g, '').trim()))){
    //       this.isSubmitEnabled = false
    //     } else {
    //       this.isSubmitEnabled = true
    //     }
    //   })
    // ).subscribe());

    this.subscriptions.push(this.formControlAdditionalInformation.valueChanges.pipe(
      tap(value => {
        this.isAdditionalInfoNonEmpty = !!(value && !_.isEmpty(value.replace(/<[^>]*>/g, '').trim()));
        this.updateSubmitEnabled();
      })
    ).subscribe());

    this.subscriptions.push(
      this.formControlSubmitterEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.submitterEmailError = false;
        }
      })
    );

  }

  onCancel() {
    this.matDialogRef.close({
      readyForSubmission: false,
      additionalInformation: this.formControlAdditionalInformation.value ?? '',
      emailList: this.emailList,
      isInternalOnly: this.isInternalOnly,
      isCreditSelect: this.formControlCredit.value ?? false,
      isSalesSelect: this.formControlSales.value ?? false,
      isSettlementsSelect: this.formControlSettlements.value ?? false,
      isSubmitterSelect: this.formControlSubmitter.value ?? false,
    })
  }

  onSubmit() {
    this.matDialogRef.close({

      readyForSubmission: true,
      isCreditSelect: this.formControlCredit.value ?? false,
      isSalesSelect: this.formControlSales.value ?? false,
      isSettlementsSelect: this.formControlSettlements.value ?? false,
      isSubmitterSelect: this.formControlSubmitter.value ?? false,
      additionalInformation: this.formControlAdditionalInformation.value ?? '',
      emailList: this.emailList,
      isInternalOnly: this.isInternalOnly,
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.emailList.push(value);
      }
      // Clear the input value
      event.chipInput!.clear();
      this.emailError = false;

    } 
    else if (value) {
      this.emailError = true;
    }
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);
    }
  }

  addEmail(email: string, control?: FormControl): void {
    if (email && !_.includes(this.submitterEmailList, email)) {
      this.submitterEmailList.push(email);
      if (control) {
        this.submitterEmailListWithKeyName.push({ control: control, email: email });
      }
    }
  }

  removeEmail(email: string): void{
    if (email) {
      _.pull(this.submitterEmailList, email);
      _.pull(this.otherEmailList, email);
      const result = _.find(this.submitterEmailListWithKeyName, { email: email });
      if(result){
        this.submitterEmailListWithKeyName = this.submitterEmailListWithKeyName.filter(item => item.email !== email);
        if (result.control) {
          result.control.setValue(false);
        }
      }
    }
  }

  addSubmitterEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.addEmail(value);
        if (!_.includes(this.otherEmailList, value)) {
          this.otherEmailList.push(value)
        }
      }
      // Clear the input value
      event.chipInput!.clear();
      this.submitterEmailError = false;
    
      if((this.formControlAdditionalInformation.value && !_.isEmpty(this.formControlAdditionalInformation.value.replace(/<[^>]*>/g, '').trim()))){
        this.isSubmitEnabled = false
      }
    } else if (value) {
      this.isSubmitEnabled = true
      this.submitterEmailError = true;
    } else if (!value) {
      this.isSubmitEnabled = false
    }
  }

  checkboxStates: {
    internalOnly: boolean;
    submitter: boolean;
    sales: boolean;
    credit: boolean;
    settlements: boolean;
  } = {
    internalOnly: false,
    submitter: false,
    sales: false,
    credit: false,
    settlements: false,
  };

  onCheckboxChange(key: keyof typeof this.checkboxStates, event: MatCheckboxChange) {
    this.checkboxStates[key] = event.checked;
  
    // Update selected checkbox count
    this.selectedCheckboxCount = Object.values(this.checkboxStates).filter(state => state).length;
  
    // Recalculate submit enabled state
    this.updateSubmitEnabled();
  }

  private updateSubmitEnabled() {
    // Set isSubmitEnabled to true only if no checkboxes are selected AND additional information is empty
    this.isSubmitEnabled = this.selectedCheckboxCount === 0 && !this.isAdditionalInfoNonEmpty;
  }

}

