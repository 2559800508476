import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Revenue } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'monthly-revenue-table',
    templateUrl: './monthly-revenue-table.component.html',
    styleUrls: ['./monthly-revenue-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, LooseCurrencyPipe]
})
export class MonthlyRevenueTableComponent implements OnInit, OnChanges {
  constructor() {}
  @Input({required: true}) data!: Revenue;
  dataSource: Revenue[] = [];
  columnsToDisplay = ['revenue','wages','credit','income'];

  ngOnInit(): void {
    this.dataSource = [this.data];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = [this.data];
  }
}
