import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {YesNoComponent} from './yes-no.component';
import {YesNoSelectionComponent} from './yes-no-selection.component';
import {AngularMaterialLibraryModule} from '../../angular-material/angular-material-library.module';
import {DirectiveLibraryModule} from '../../directives/directive-library.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        YesNoComponent,
        YesNoSelectionComponent
    ],
    exports: [
        YesNoComponent,
        YesNoSelectionComponent,
    ]
})
export class YesNoModule {

}
