import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  CorporateLoanPaymentBreakupDialogData2,
  CorporateLoanPaymentBreakupDialogData,
  CorporateLoanPaymentBreakupDialogResult,
} from '@portal-workspace/grow-shared-library';
import { CurrencyPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { GridModule } from '@angular/flex-layout/grid';
import { FlexModule } from '@angular/flex-layout/flex';
import {PercentagePipe} from "../../pipes/percentage.pipe";
import {ApplicationDialogService} from "./application-dialog.service";



@Component({
  templateUrl: './corporate-loan-payment-breakup.dialog.html',
  styleUrls: ['./corporate-loan-payment-breakup.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    GridModule,
    MatDividerModule,
    MatButtonModule,
    CurrencyPipe,
    MatDialogModule,
    PercentagePipe
]
})
export class CorporateLoanPaymentBreakupDialog implements OnInit {

  data: CorporateLoanPaymentBreakupDialogData| null = null;
  isQuote!: boolean;
  hideBrokerage!: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public opt: CorporateLoanPaymentBreakupDialogData2,
              private dialogRef: MatDialogRef<CorporateLoanPaymentBreakupDialog, CorporateLoanPaymentBreakupDialogResult>,
              private applicationDialogService: ApplicationDialogService) {
  }

  onAcceptQuotation($event: Event) {
    this.dialogRef.close({acceptedQuotation: true});
  }
  onClose($event: Event){
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.data = this.opt.loadFn();
    this.isQuote = this.opt.isQuote ?? false;
    this.hideBrokerage = !!this.opt.hideBrokerage;
  }

  // onDateChange($event: moment.Moment) {
  //   console.log('***** reload date change', $event);
  //   this.data = this.opt.loadFn();
  //   console.log('***** reload date change data', this.data);
  // }

  onInterestRateClick() {
    if (this.data?.term?.calculationLog) {
      this.applicationDialogService.openInterestRateCalculationLogDialog({ logs: this.data?.term?.calculationLog ?? [] });
    } else {
      this.applicationDialogService.openAlertDialog({
        message: `Info`,
        subMessage: `No interest calculation log available`,
      });
    }
  }
}
