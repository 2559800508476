import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { AddCreditAlertFn, AddCreditRuleItemFn, CreditAlert, CreditRuleItem, DEFAULT_LIMIT, DEFAULT_OFFSET, GetCreditAlertsFn, GetCreditFlowsFn, GetCreditRuleItemsFn, NewCreditRuleItemBody, UpdateCreditAlertFn, UpdateCreditFlowFn, UpdateCreditRuleItemFn } from '@portal-workspace/grow-shared-library';
import { FlexModule } from '@angular/flex-layout';
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DatePipe, AsyncPipe, } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import moment from 'moment';

@Component({
    selector: 'credit-rule-items',
    templateUrl: './credit-rule-items.component.html',
    styleUrls: ['./credit-rule-items.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      NgClass, 
      ExtendedModule, 
      FlexModule,
      CustomContentLoaderComponent,
      CustomPaginatorComponent,
      MatSortModule,
      DatePipe,
      AsyncPipe,
      MatButtonModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatTooltipModule,
    ]
})
export class CreditRuleItemsComponent implements OnInit {
  @Input({required: true}) getCreditRuleItemsFn!: GetCreditRuleItemsFn;
  @Input({required: true}) addCreditRuleItemFn!: AddCreditRuleItemFn;
  @Input({required: true}) updateCreditRuleItemFn!: UpdateCreditRuleItemFn;

  subscriptions: Subscription[] = [];
  moment = moment;
  formControlSearch!: FormControl<string | null>;

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  loader = loadingFor('tableLoading');
  sorts: { prop: string, dir: 'asc' | 'desc' } | null = null;
  dataSource: CreditRuleItem[] = [];
  filteredDataSource: CreditRuleItem[] = [];
  displayedData: CreditRuleItem[] = [];
  columnsToDisplay = ['name', 'type', 'outputType', 'actions'];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
  ) {
    this.formControlSearch = this.formBuilder.control(null);
  }

  ngOnInit(): void {
    this.reload();

    this.subscriptions.push(
      this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(r => {
          if (r) {
            const text = r.trim().toLowerCase();
            this.filteredDataSource = this.dataSource.filter(item => 
              (`${item.name.trim().toLowerCase()} ${item.type.trim().toLowerCase()}`).includes(text)
            );
            this.initPagination();
          } else {
            this.filteredDataSource = this.dataSource;
            this.initPagination();
          }
        })
      ).subscribe()
    )
  }

  reload() {
    this.subscriptions.push(
      this.getCreditRuleItemsFn().pipe(
        this.loader.tableLoading.track()
      ).subscribe(items => {
        this.dataSource = items;
        this.filteredDataSource = this.dataSource;
        this.initPagination();
      })
    )
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'name': return 'Name';
      case 'type': return 'Mapping Type';
      case 'itemFunction': return 'Function';
      case 'outputType': return 'Output';
      case 'value': return 'Value';
      case 'actions': return '';
      default: return column;
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }
  
  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  add() {
    this.subscriptions.push(
      this.dialogService.openEditCreditRuleItemDialog({
        creditRuleItem: null,
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.addCreditRuleItemFn(
              response.creditRuleItem as NewCreditRuleItemBody
            ).pipe(
              this.toastService.snackBarObservable(`Credit rule item added!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  edit(element: CreditRuleItem) {
    this.subscriptions.push(
      this.dialogService.openEditCreditRuleItemDialog({
        creditRuleItem: element,
        getCreditRuleItemsFn: this.getCreditRuleItemsFn,
      }).afterClosed().subscribe(
        response => {
          if (response?.readyForSubmission) {
            this.updateCreditRuleItemFn(
              response.creditRuleItem as NewCreditRuleItemBody,
              element.id,
            ).pipe(
              this.toastService.snackBarObservable(`Credit rule item updated!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }

  delete(element: CreditRuleItem) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Alert",
        subMessage: "Are you sure you want to delete this credit rule item"
      }).afterClosed().subscribe(
        response => {
          if(response?.readyForSubmission) {
            this.updateCreditRuleItemFn({isDeleted: true}, element.id).pipe(
              this.toastService.snackBarObservable(`Credit rule item deleted!`),
            ).subscribe(() => {
              this.reload();
            })
          }
        }
      )
    )
  }
}
