<div class="digital-id-individual-verification-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3 text-align-center">Digital Identity Verification</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      @for (alert of alerts; track alert) {
      <message-box title="Alert" type="danger"> {{alert}} </message-box>
      } @for (message of messages; track message) {
      <message-box title="Info" type="info"> {{message}} </message-box>
      }
    </div>
  </div>

  @if (checkResult) {
  <div class="row">
    <div class="col-12 text-align-center">
      As part of your application {{checkResult.brokerAppId}}, we are unable to
      verify your Information as provided to us by your broker. Please confirm
      your name is correct, then complete the additional steps for the
      verification. This process is highly secure and it is handled by Digital
      iD, a product provided by Australia Post.
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center">
      <ul class="list-unstyled">
        <li>
          {{checkResult.individual.Title}} {{checkResult.individual.GivenName}}
          {{checkResult.individual.MiddleName ?
          checkResult.individual.MiddleName : ''}}
          {{checkResult.individual.SurName}} ({{checkResult.individual.Email}})
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-align-center">
      @if (allowDigitalIdVerification) {
      <button mat-stroked-button (click)="verifyNow()" class="w-lt-md-100">
        Verify Now
      </button>
      <!-- <app-digital-id
            [type]="'basic'"
            [getClientFn]="getClientFn"
            [authenticateFn]="authenticateFn"
            [verificationSessionToken]="verificationSessionToken"
            (events)="onDigitalIdEvent($event)">
          </app-digital-id> -->
      }
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 text-align-center">
      <button mat-stroked-button (click)="refresh()" class="w-lt-md-100">
        Refresh
      </button>
    </div>
  </div>
  } @if (doingVerification) {
  <div class="row mt-3">
    <div class="col-12">
      <message-box>Trying to show verification result ...</message-box>
    </div>
  </div>
  } @if (verificationResult) {
  <div class="row mt-3">
    <div class="col-12">
      @if (verificationResult.status === 'RECEIVED_ERROR') {
      <!-- error -->
      <table class="verification-result-table">
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Status</td>
          <td class="mat-cell cdk-cell">
            <tag-box
              [state]="verificationStatusDisplay(verificationResult.status)"
            ></tag-box>
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Creation Date</td>
          <td class="mat-cell cdk-cell">{{verificationResult.creationDate}}</td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Last Update</td>
          <td class="mat-cell cdk-cell">{{verificationResult.lastUpdate}}</td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Error</td>
          <td class="mat-cell cdk-cell">{{verificationResult.error}}</td>
        </tr>
      </table>
      } @if (verificationResult.status !== 'RECEIVED_ERROR') {
      <!-- success -->
      <table class="verification-result-table">
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Status</td>
          <td class="mat-cell cdk-cell">
            <tag-box
              [state]="verificationStatusDisplay(verificationResult.status)"
            ></tag-box>
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Creation Date</td>
          <td class="mat-cell cdk-cell">{{verificationResult.creationDate}}</td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Last Update</td>
          <td class="mat-cell cdk-cell">{{verificationResult.lastUpdate}}</td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Verification Status</td>
          <td class="mat-cell cdk-cell">
            {{verificationResult.digitalIdResponse.verification_status}}
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">
            Verification Session Token
          </td>
          <td class="mat-cell cdk-cell">
            {{verificationResult.digitalIdResponse.verification_session_token}}
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Data Source Events</td>
          <td class="mat-cell cdk-cell">
            {{verificationResult.digitalIdResponse.data_source_events}}
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Transaction Id</td>
          <td class="mat-cell cdk-cell">
            {{verificationResult.digitalIdResponse.transaction_id}}
          </td>
        </tr>
        <tr class="mat-header-row cdk-header-row">
          <td class="mat-header-cell cdk-header-cell">Watchlist</td>
          <td calss="mat-cell cdk-cell">
            @if (verificationResult.digitalIdResponse.watchlist) {
            <ul>
              <li>
                {{verificationResult.digitalIdResponse.watchlist.sources_category}}
              </li>
              <li>
                {{verificationResult.digitalIdResponse.watchlist.check_performed}}
              </li>
              <li>
                {{verificationResult.digitalIdResponse.watchlist.check_performed_date}}
              </li>
              @for (foundSource of
              verificationResult.digitalIdResponse.watchlist.found_sources;
              track foundSource) {
              <ul>
                <li>{{foundSource?.name}} - {{foundSource?.category}}</li>
              </ul>
              }
            </ul>
            }
          </td>
        </tr>
      </table>
      }
    </div>
  </div>
  }
</div>
