import {Component, OnInit} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {BankTransferDialogResult, BankingTransactionSortType, BankingTransactionStatus, BpayDialogResult, DEFAULT_LIMIT, DEFAULT_OFFSET, DirectDebitDialogResult, GetBankingTransaction, GetBillerNameFn, PismoTransactionProcessingCode, User, getProcessingType} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApplicationDialogService, PortalHotToastService, getUser} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { Sort, MatSortModule } from '@angular/material/sort';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {createAsyncStore, loadingFor} from '@ngneat/loadoff';
import { CustomPaginatorComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
 
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, AsyncPipe } from '@angular/common';
import { BankingService } from '../../service/banking.service';
import { LooseCurrencyPipe } from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ApplicationService } from '../../service/application.service';

export class InternalDataSource extends DataSource<GetBankingTransaction> {

  subject = new BehaviorSubject<GetBankingTransaction[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<GetBankingTransaction[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(originator: GetBankingTransaction[]) {
    this.subject.next(originator);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './transactions.page.html',
    styleUrls: ['./transactions.page.scss'],
    standalone: true,
    animations: [
      trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
    imports: [MatFormFieldModule, FlexModule, LooseCurrencyPipe, MatInputModule, MatTooltipModule, MatMenuModule, MatButtonModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe]
})
export class TransactionsPage implements OnInit {

  loader = loadingFor('tableLoading');
  store = createAsyncStore();
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  moment = moment;
  expandedElement!: GetBankingTransaction | null;
  PismoTransactionProcessingCode = PismoTransactionProcessingCode;
  getProcessingType = getProcessingType;
  loggedInUser: User | null = getUser()

  retry(){
    this.reload();
  }

  subscriptions: Subscription[] = [];

  dataSource = new InternalDataSource();

  displayColumns = ['id', 'type', 'processingCode', 'amount', 'date', 'status', 'PismoAccountId', 'PismoCustomerId', 'lastUpdatedTime', 'actions'];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = 0;
  filter = '';
  sorts: BankingTransactionSortType = null;

  formControlSearch: FormControl<string | null>;
  getBillerNameFn!: GetBillerNameFn;

  constructor(private formBuilder: FormBuilder,
              private toastService: PortalHotToastService,
              private applicationService: ApplicationService,
              private applicationDialogService: ApplicationDialogService,
              private bankingService: BankingService) {
    this.formControlSearch = this.formBuilder.control('');
    this.getBillerNameFn = this.applicationService.getBillerNameFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.reload();
    const sub = this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = [{prop: $event.active, dir: $event.direction}] as BankingTransactionSortType;
    } else {
      this.sorts = null;
    }
    this.reset();
    this.reload();
  }

  private reload() {
    this.store = createAsyncStore();
    const sub = this.bankingService.getBankingTransactions({
      page: {
        limit: this.limit, offset: this.offset,
      },
      filter: this.filter,
      sorts: this.sorts,
    }).pipe(
      this.loader.tableLoading.track(),
      this.toastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      }),
      this.store.track(),
      tap(r => {
        console.log('==============r: ', r);
        this.total = r.total;
        this.dataSource.update(r.payload);
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  getTransactionStatus(transaction: GetBankingTransaction) {
    if (transaction.processedTransactionStatus) {
      return transaction.processedTransactionStatus;
    } else if (!transaction.pendingTransactionCreatedTime) {
      return 'Created';
    } else {
      return 'Pending';
    }
  }

  getBankingType(transaction: GetBankingTransaction) {
    const type = transaction.type;
    switch (type) {
      case 'bpay':
        return 'BPAY';
      case 'direct-credit':
        return 'Direct Credit';
      case 'direct-debit':
        return 'Direct Debit';
      default:
        return type;
    }
  }

  showRetryButton(status: BankingTransactionStatus | 'Created' | 'Pending') {
    return ['CSV-failed', 'DEEDS-failed', 'DERPS-failed'].includes(status);
  }

  private reset() {
    // this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  retryTransaction(transaction: GetBankingTransaction) {
    if (transaction.type === 'direct-debit') {
      this.subscriptions.push(
        this.applicationDialogService.openDirectDebitDialog({
          data: transaction,
          userId: this.loggedInUser?.UserId ?? 0
        }).afterClosed().subscribe((response: DirectDebitDialogResult | undefined) => {
          console.log(response)
          if (response && response?.readyForSubmission) {
            this.bankingService.pismoPayOverdraftAccountFn(
              response.result, 
              response.result.pismoAccountId
            ).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Payment success`),
            ).subscribe()
          }
        })
      )
    } else if (transaction.type === 'direct-credit') {
      this.subscriptions.push(
        this.applicationDialogService.openBankTransferDialog({
          data: transaction,
          userId: this.loggedInUser?.UserId ?? 0
        }).afterClosed().subscribe((response: BankTransferDialogResult | undefined) => {
          console.log(response)
          if (response && response?.readyForSubmission) {
            this.bankingService.pismoPaymentBankTransferFn(
              response.result, 
              response.result.PismoAccountId
            ).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Payment success`),
            ).subscribe()
          }
        })
      )
    } else if (transaction.type === 'bpay') {
      this.subscriptions.push(
        this.applicationDialogService.openBpayDialog({
          getBillerNameFn: this.getBillerNameFn,
          data: transaction,
          userId: this.loggedInUser?.UserId ?? 0
        }).afterClosed().subscribe((response: BpayDialogResult | undefined) => {
          console.log(response)
          if (response && response?.readyForSubmission) {
            this.bankingService.pismoPaymentBpayFn(
              response.result, 
              response.result.PismoAccountId
            ).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Payment success`),
            ).subscribe()
          }
        })
      )
    }
  }
 
}
