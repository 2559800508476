<div class="approval-notice-dialog">
  <mat-dialog-content class="max-height-100-vh">
    <application-stepper2 [linear]="false"
      [showHeader]="false"
      #applicationStepper="applicationStepper2">
      <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
      <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
      <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    </application-stepper2>
  </mat-dialog-content>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <!--      <div class="row">-->
      <!--        <div class="col-12">-->
      <!--          <div class="mat-subheading-2 header">Approval Notice</div>-->
    <!--        </div>-->
  <!--      </div>-->
  <div class="row">
    <div class="col-12">
      <div class="mat-subtitle-1 header">Add Recepients</div>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <mat-form-field class="cc-email-chip-list">
        <mat-label>TO</mat-label>
        <mat-chip-grid #chipList aria-label="email list">
          @for (email of emailListStep1; track email) {
            <mat-chip-row (removed)="remove(email)">
              {{email}}
              <button matChipRemove>
                <span class="mdi mdi-close"></span>
              </button>
            </mat-chip-row>
          }
          <input [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            [formControl]="formControlStep1Email"
            (matChipInputTokenEnd)="add($event)">
          </mat-chip-grid>
        </mat-form-field>
        @if (emailListStep1Error) {
          <span class="error">
            Please enter a valid email
          </span>
        }
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <mat-form-field class="cc-email-chip-list">
          <mat-label>CC</mat-label>
          <mat-chip-grid #ccChipList aria-label="email list">
            @for (email of ccEmailListStep1; track email) {
              <mat-chip-row (removed)="remove(email, true)">
                {{email}}
                <button matChipRemove>
                  <span class="mdi mdi-close"></span>
                </button>
              </mat-chip-row>
            }
            <input [matChipInputFor]="ccChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              [formControl]="formControlStep1CcEmail"
              (matChipInputTokenEnd)="add($event, true)">
            </mat-chip-grid>
          </mat-form-field>
          @if (ccEmailListStep1Error) {
            <span class="error">
              Please enter a valid email
            </span>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep3" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">
            Approval Notice
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <pdf-viewer [src]="approvalNoticeBuffer"
            [original-size]="false"
            [zoom]="0.9"
            style="width: 1080px; height: 500px; display: block;"
          ></pdf-viewer>
        </div>
      </div>
    </form>
  </div>
</ng-template>



<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3" >
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="success-icon">
            <span class="mdi mdi-file-sign"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="mat-subtitle-1">The approval notice has been sent successfully</div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
