import { Observable } from "rxjs"
import { ApiResponse } from "./types.domain"

export interface ApplicationNotes {
  ApplicationNotesId: number,
  UserId: number,
  ApplicationId: number,
  Name:string,
  Email:string,
  DateGenerated: string,
  Note: string,
  visibleToInternalStaff: boolean,
  creditNote: boolean,
}

export type RemoveApplicationNoteByNoteIdFn = (noteId: number) => Observable<ApiResponse>

