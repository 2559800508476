<div class="currency-input-dialog">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>{{data.title}}</mat-label>
      <input matInput [formControl]="formControl" type="text">
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button [disabled]="formGroup.invalid" (click)="onSubmit($event)">Submit</button>
    </div>
  </mat-dialog-content>
</div>
