<div class="credit-result-alerts">
    <div class="row">
        <div class="col-12">
              <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">
                <table mat-table [dataSource]="displayedData" class="width-100 mb-4">
                  <ng-container matColumnDef="application">
                    <th mat-header-cell *matHeaderCellDef> 
                      <div class="cell">Application</div> 
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell">
                        <div class="cursor-pointer application" (click)="onClick(element)">
                          {{element?.AppInfo?.BrokerAppID ?? ('G00' + element.ApplicationId)}}
                        </div>
                        <div class="date-text">
                          {{getApplicationCreateTime(element)}}
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="stage">
                    <th mat-header-cell *matHeaderCellDef> 
                      <div class="cell">Stage</div> 
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell">
                        <tag-box [state]="element.AppInfo.StageName ?? 'Draft'"></tag-box>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> 
                      <div class="cell">Credit Decision</div> 
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell">
                        <tag-box [state]="element.CreditOfficerStatus"></tag-box>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> 
                      <div class="cell">Description</div> 
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="cell">
                        <div>{{getApplicantName(element)}}</div>
                        <div>{{element.duplicatedReason}}</div>
                      </div>
                    </td>
                  </ng-container>
            
            
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
                </table>

                @if (!total) {
                  <table>
                    <tr>
                      <td class="not-found"> No duplicated applications</td>
                    </tr>
                  </table>
                }

                @if (total) {
                  <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
                  [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
                }
              </div>
        </div>
    </div>
</div>
