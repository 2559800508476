import {Component, Input} from '@angular/core';
import {User} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';


@Component({
    selector: 'CESNPSmodal',
    templateUrl: './CES-NPS-modal.component.html',
    styleUrls: ['./CES-NPS-modal.component.scss'],
    standalone: true,
    imports: [GridModule, FlexModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class CESNPSmodalComponent {

  @Input({required: true}) user?: User;

}
