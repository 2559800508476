import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CreditAlert,
  CreditAlertOptions,
  CreditAlertStatus,
  EditCreditAlertDialogData, EditCreditAlertDialogResult, GetCreditAlertByStatusFn,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Subscription, generate } from 'rxjs';
import { stat } from 'fs';

@Component({
    templateUrl: './edit-credit-alert.dialog.html',
    styleUrls: ['./edit-credit-alert.dialog.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, FlexModule, MatButtonModule, MatDialogModule, MatSelectModule]
})
export class EditCreditAlertDialog implements OnInit{
  formControlName!: FormControl<string | null>;
  formControlDescription!: FormControl<string | null>;
  formControlStatus!: FormControl<CreditAlertStatus | null>;
  formControlCode!: FormControl<string | null>;

  subscriptions: Subscription[] = [];


  creditAlertOptions = CreditAlertOptions;
  getCreditAlertByStatusFn!: GetCreditAlertByStatusFn;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCreditAlertDialogData,
              private dialogRef: MatDialogRef<EditCreditAlertDialog, EditCreditAlertDialogResult>,
              private formBuilder: FormBuilder) {
    const name = this.data.name ?? '';
    const description = this.data.description ?? '';
    const status = this.data.status ?? null;
    const code = this.data.code ?? '';
    this.getCreditAlertByStatusFn = this.data.getCreditAlertByStatusFn
    this.formControlName = this.formBuilder.control(name, [Validators.required]);
    this.formControlDescription = this.formBuilder.control(description, [Validators.required]);
    this.formControlStatus = this.formBuilder.control(status, [Validators.required]);
    this.formControlCode = this.formBuilder.control(code, [Validators.required]);
  }

  ngOnInit(): void {

    this.subscriptions.push(

    // Listen to changes in `status` to trigger placeholder updates
    this.formControlStatus.valueChanges.subscribe((status) => {
      this.formControlCode.updateValueAndValidity({ emitEvent: false });
      if (status) {
        this.subscriptions.push(
          this.getCreditAlertByStatusFn(status).subscribe((statusCount: number) => {
            const updatedStatusCount = statusCount + 1;
            const codePrefix = status === 'Decline' ? 'CRDL' :
                              status === 'Refer' ? 'CRRF' :
                              status === 'Validate' ? 'CRVD' : 'CR';
            this.formControlCode.setValue(`${codePrefix}${updatedStatusCount}`);
          })
        )
      }
    }));
  }

  onSubmit(confirmation: boolean) {
    this.dialogRef.close({
      readyForSubmission: confirmation,
      name: this.formControlName.value ?? '',
      description: this.formControlDescription.value ?? '',
      status: this.formControlStatus.value as CreditAlertStatus,
      code: this.formControlCode.value ?? '',
    })
  }
}
