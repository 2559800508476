import { JsonPipe, NgClass } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DEFAULT_MIN_FILE_UPLOAD_SIZE,
  DocumentTag,
  FileWithTags,
  mimeMatch as match,
  UploadFileValue
} from '@portal-workspace/grow-shared-library';
import { ApplicationDialogService, fieldErrorMessages } from '@portal-workspace/grow-ui-library';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import _ from 'lodash';
import mime from 'mime';
import { tap } from 'rxjs/operators';



// NOTE: value object for this component: UploadFileValue
@Component({
    selector: 'upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadFileComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => UploadFileComponent) },
    ],
    standalone: true,
    imports: [
      JsonPipe,
      NgClass,
      ExtendedModule,
      MatCardModule,
      FlexModule,
      MatFormFieldModule,
      MatChipsModule,
      MatTooltipModule,
      MatButtonModule
    ]
})

export class UploadFileComponent implements ControlValueAccessor, OnInit, Mark {

  // subscriptions: Subscription[] = [];

  onTouchFn?: ()=>void;
  onChangeFn?: (v?: UploadFileValue)=>void;

  disabled = false;
  isDirty = false;
  hasErrors?: boolean = false;
  errorMessages: string[] = [];

  @ViewChild('fileInputRef') fileInputRef!: ElementRef;
  @Output() events: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() selectedFileTags = new EventEmitter<FileWithTags[]>();

  @Input({required: false}) minFileSize: number | null = DEFAULT_MIN_FILE_UPLOAD_SIZE;
  @Input({required: false}) title?: string = 'Driver licence';
  @Input({required: false}) message?: string = 'Drop files here or click to upload';
  @Input({required: false}) subMessage: string | null = null;
  @Input({required: false}) mandatory = false;
  @Input({required: false}) isShowFlieList?: boolean = true;
  @Input({required: false}) isShowEditButton?: boolean = false;
  @Input({required: false}) allowMultiple?: boolean = false;
  @Input({required: false}) maxFilesAllowed = 1;
  @Input({required: false}) allowDuplicateFiles?: boolean = true;
  @Input({required: false}) defaultTags: DocumentTag[] = [];
  @Input({required: false}) allTags: DocumentTag[] = [];
  @Input({required: false}) enableCustomTag?: boolean = false;
  @Input({required: false}) enableNonStandardCondition?: boolean = false;
  @Input({required: false}) acceptFileTypes: string[] = [
    '.doc',
    '.docm',
    '.docx',
    '.gif',
    '.htm',
    '.html',
    '.zip', // 'application/zip'
    'application/x-zip-compressed',
    'application/zip-compressed',
    'application/x-zip',
    '.jpg',
    '.jpeg',
    '.xls',
    '.xlsx',
    '.csv',
    '.pdf',
    '.png',
    '.ppt',
    '.pptx',
    '.rar',
    '.tif',
    '.tiff',
    '.txt',
    'image/*'
  ];

  // private errorStatus: boolean = false;
  // private errorMessageList: string[] = [];
  mimeTypes: string[] = [];  // computed mime types
  inDropableState = false;
  files: File[] = [];
  tags: DocumentTag[] = [];
  filesWithTags: FileWithTags[] = [];
  MAX_TAG_ALLOWED = 10;

  duplicateFileNames: string[] = [];
  bellowMinFileSizeFileNames: string[] = [];

  constructor(private cdr: ChangeDetectorRef,
              private applicationDialogService: ApplicationDialogService,
              private injector: Injector) {
  }

  ngOnInit() {
    this.mimeTypes = this.acceptFileTypes
      .map(t => {
        if (t.indexOf('/')>0) {
          return t;
        }
        const v = mime.getType(t) ?? '';
        return v;
      }).filter(t => t);
      // this.updateValidationStatus()
      this.checkErrors(this.files);

  }

  private checkErrors(files: File[]) {
    const _hasErrors = this.isDirty &&
      (
        (this.mandatory && files.length < 1) ||
        (files.length > this.maxFilesAllowed) ||
        (this.duplicateFileNames.length > 0)
      ) || this.bellowMinFileSizeFileNames.length > 0
    ;
    this.hasErrors = _hasErrors;
    const _errorMessages: string[] = [];
    if (_hasErrors) {
      if (this.mandatory && this.files.length < 1) {
        _errorMessages.push(fieldErrorMessages.required);
      }
      if (this.files.length > this.maxFilesAllowed) {
        _errorMessages.push(fieldErrorMessages.uploadFile({maxFiles: this.maxFilesAllowed, total: this.files.length}));
      }
      if (this.duplicateFileNames.length) {
        _errorMessages.push(fieldErrorMessages.uploadDuplicateFile({filename: this.duplicateFileNames.join(',')}));
        this.duplicateFileNames = [];
      }
      if (this.bellowMinFileSizeFileNames.length) {
        for (const f of this.bellowMinFileSizeFileNames) {
          _errorMessages.push(fieldErrorMessages.uploadMinFileSize({filename: f, minFileSize: this.minFileSize!}));
        }
      }
    }
    this.errorMessages = _errorMessages;
  }

  fileInputChange($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const files = ($event.target as HTMLInputElement).files;
    this.addFiles(files);
    inputElement.value = ''; // clear filelist
  }

  onClick($event: Event) {
    this.markAsDirty();
    (this.fileInputRef.nativeElement as HTMLInputElement).click();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.inDropableState = true;
    this.markAsDirty();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "copy";
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.markAsDirty();
    this.inDropableState = false;
    const files = event.dataTransfer?.files;
    this.addFiles(files);
  }

  onDragLeave($event: DragEvent) {
    this.markAsDirty();
    this.inDropableState = false;
  }

  onDragEnter($event: DragEvent) {
    this.markAsDirty();
    this.inDropableState = true;
  }

  deleteFile($event: MouseEvent, file: File) {
    this.markAsDirty();
    this.files = this.files.filter(f => {
      return f !== file;
    });
    this.filesWithTags = this.filesWithTags.filter(f => {
      return f.fileName !== file.name;
    });

    this.events.emit(this.files);
    if (this.files.length) {
      this.propagateChange(this.files);
    } else {
      this.propagateChange(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: UploadFileValue): void {
    if (obj == null) {
      this.markAsUntouched();
    }
    this.propagateChange(obj);
  }

  propagateChange(v?: UploadFileValue) {
    this.files = v ? v : [];
    this.onTouchFn && this.onTouchFn();
    this.checkErrors(this.files);
     //this.onChangeFn && this.onChangeFn(this.hasErrors ? null : this.files.length ? this.files : null);
     this.onChangeFn && this.onChangeFn(this.files.length ? this.files : null);
  }

  markAsDirty() {
    this.isDirty = true;
  }

  mark(): void {
    this.markAsDirty();
    this.cdr.markForCheck();
  }

  markAsUntouched() {
    this.isDirty = false;
    const ngControl = this.injector.get(NgControl);
    if (ngControl && ngControl.control) {
      ngControl.control.markAsUntouched();
    }
  }

  private addFiles(files?: FileList | null) {
    if (files && files.length) {

      const rejectedFile: File[] = [];
      let _files: File[] = [...(this.files ?? [])]; // effective files after passing all filtering
      for (const _file of Array.from(files)) {
        if (this.matchMimeTypes(_file)) {
          _files.push(_file);
        } else {
          rejectedFile.push(_file);
        }
      }
      if (rejectedFile.length) {
        this.applicationDialogService.openAlertDialog({
          message: `Invalid file types`,
          subMessage: `Files (${rejectedFile.map(f => f.name).join(',')}) has invalid types`,
        });
        return;
      }

      // handle duplicate file names
      if (this.allowDuplicateFiles) {
        this.duplicateFileNames = Object.entries(
          _.countBy(_files.map(file => file.name))) // array group by filename and it's count eg. [{'file1': 2, 'file2': 1, 'file3': 1}]
          .filter(entry => entry[1] > 1)      // filter those have count > 1
          .map(entry => entry[0])             // get the filename
      }

      // handle minFileSize validation
      if (this.minFileSize != null && this.minFileSize >= 0) {
        const filesBelowMinSize = _files
          .filter(f => {
            return (f.size <= this.minFileSize!);
          })
          .map(f => {
            _.remove(_files, (currentFile)=> (f === currentFile));
            return f.name;
          })
        ;
        this.bellowMinFileSizeFileNames.push(...filesBelowMinSize);
      }

      const addedNewFileCheck = _files.filter(file => !this.files.some(prevFile => prevFile.name === file.name));
     
      const m = _files;
      this.files = m
      
      for (let i = 0; i < files.length; i++) {
        const f = files.item(i)
        if(this.defaultTags && files && f){
          this.addFileWithTags(f,this.defaultTags)
        }
      }

      if (addedNewFileCheck.length === 1 && this.isShowEditButton && !this.hasTagsFile(addedNewFileCheck[0])) {
        this.openSelectableTagsDialog(addedNewFileCheck[0])
      }

      this.events.emit(this.files);
      if (this.files.length) {
        this.propagateChange(this.files);
      } else {
        this.propagateChange(null);
      }
    }
  }

  private getFileExtension(filename: string): string {
    return "." + filename.split('.').pop() || '';
  }

  private matchMimeTypes(file: File) {
    if (this.mimeTypes.length == 0) { // no mime types, assume match
      return true;
    }

    for (const mimeType of this.mimeTypes) {
      const matched = match(file.type, mimeType);
      if (matched) {
        return true;
      }else if(file.type === ""){
        const ext = this.getFileExtension(file.name);
        const type = mime.getType(ext)
        if(type){
          const matched = match(type, mimeType);
          if (matched) {
            return true;
          }
        }
      }
    }
    return false;
  }

  openSelectableTagsDialog(file: File){
    this.applicationDialogService.openSelectableTagsDialog({
      fileName: file.name,
      allTags: this.allTags,
      previouslySelectedTags: this.getTagsByFileName(file),
      enableCustomTag: this.enableCustomTag,
      enableNonStandardCondition: this.enableNonStandardCondition
    }).afterClosed().pipe(
      tap((r) => {
        if (r && r.valid) {
          this.tags = r.selectedTags
          this.addFileWithTags(file,r.selectedTags)
        }
      })
    ).subscribe()
  }

  editFile($event: MouseEvent, file: File) {
    this.openSelectableTagsDialog(file)
  }

  addFileWithTags(fileName: File, fileTags: DocumentTag[]): void {
    const fileIndex = this.getFileIndexByFilename(fileName)
    const existingFile = this.filesWithTags[fileIndex];
    if (existingFile) {
      // Override the tags for the existing file
       existingFile.fileTags = fileTags;
    } else {
      // Add a new file with tags
      const newFileWithTags: FileWithTags = {  fileName:fileName.name ,fileTags };
      this.filesWithTags[fileIndex] = newFileWithTags
    }
    this.selectedFileTags.emit(this.filesWithTags)
  }

  removeSingleTagFromFile(file: File, tagValue: DocumentTag): void {
    const existingFileIndex = this.getFileIndexByFilename(file)
    //this.filesWithTags[existingFileIndex].fileTags.filter(tag => tag.value !== tagValue.value);
    const fileData = this.filesWithTags[existingFileIndex];
    if (fileData) {
      fileData.fileTags = fileData.fileTags.filter(tag => tag.value !== tagValue.value);
    }
    this.selectedFileTags.emit(this.filesWithTags)
  }

  getFileIndexByFilename(file: File){
    return  this.files.indexOf(file);
  }

  getTagsByFileName(file: File): DocumentTag[] {
    const index = this.getFileIndexByFilename(file)
    const tagList = this.filesWithTags[index];
    return tagList ? tagList.fileTags : [];
  }

  hasTagsFile(file: File):boolean {
    const index = this.getFileIndexByFilename(file)
    const tagList = this.filesWithTags[index];
    if(this.isShowEditButton){
      if(tagList && tagList.fileTags.length > 0){
          return true
      }else {
        return false
      }
    }else {
      return true
    }
  }
}
