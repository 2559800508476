import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PismoCardDetailsWithAccountId, PismoCardReIssueReason, PismoGetCardReissueReasonsFn, PismoReissueCardDetails, PismoReissueCardDialogData, PismoReissueCardDialogResult, PismoReissueCardFn, filterCardReissueReasons } from '@portal-workspace/grow-shared-library';
import { Subscription } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { displayCardReissueReason } from '@portal-workspace/grow-shared-library';


@Component({
  templateUrl: './pismo-reissue-card.dialog.html',
  styleUrls: ['./pismo-reissue-card.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass
]
})
export class PismoReissueCardDialog implements OnInit {

  pismoAccountNumber: number;
  cardDetails: PismoCardDetailsWithAccountId;
  subscriptions: Subscription[] = [];
  formGroup!: FormGroup<{
    reissueReason: FormControl<PismoCardReIssueReason | null>
  }>;
  formControlReissueReason!: FormControl<PismoCardReIssueReason | null>;
  allReissueReasons: PismoCardReIssueReason[] = [];

  pismoGetCardReissueReasonsFn!: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn!: PismoReissueCardFn;
  displayCardReissueReason=displayCardReissueReason;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PismoReissueCardDialogData,
    private dialogRef: MatDialogRef<PismoReissueCardDialog, PismoReissueCardDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.pismoAccountNumber = data.cardDetails.accountId;
    this.cardDetails = data.cardDetails;
    this.pismoGetCardReissueReasonsFn = data.pismoGetCardReissueReasonsFn;
    this.pismoReissueCardFn = data.pismoReissueCardFn;

    this.formControlReissueReason = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      reissueReason: this.formControlReissueReason
    })
  }

  ngOnInit() {
    this.subscriptions.push(
      this.pismoGetCardReissueReasonsFn(this.pismoAccountNumber)
        .pipe(this.toastService.spinnerObservable())
        .subscribe((reasons: PismoCardReIssueReason[]) => {
          this.allReissueReasons = filterCardReissueReasons(reasons);
      })
    )
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const data: PismoReissueCardDetails = {
        reason_id: (this.formControlReissueReason.value as PismoCardReIssueReason).id,
        printed_name: this.cardDetails.printed_name,
        abu_enabled: true,
        cvv_rotation_interval_hours: 24,
        contactless_enabled: true,
        mode_type: 'SINGLE'
      }

      this.pismoReissueCardFn(this.pismoAccountNumber, this.cardDetails.customerId, this.cardDetails.id, data).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('Card Reissue Success!')
      ).subscribe(result => {
        this.dialogRef.close();
      })
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
