import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from '@angular/forms';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {
  GetUserSameCompanyFunc,
  NotNullable,
  UserSameCompanySelectionValue,
  UserSelectionValue
} from '@portal-workspace/grow-shared-library';
import {MatFormFieldModule} from '@angular/material/form-field';

import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '../component-utils';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'user-same-company-selection',
  templateUrl: './user-same-company-selection.component.html',
  styleUrls: ['./user-same-company-selection.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UserSameCompanySelectionComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(() => UserSameCompanySelectionComponent)},
  ],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule
]
})
export class UserSameCompanySelectionComponent extends AbstractControlValueAccessor<UserSameCompanySelectionValue> implements OnInit, OnChanges, Mark {

  @Input({required: true}) getFunc!: GetUserSameCompanyFunc;
  @Input({required: false}) salesforceId: string | null = null;
  @Input({required: false}) isMandatory = true;
  @Input({required: false}) title: string | null = null;

  subscriptions: Subscription[] = [];

  users: NotNullable<UserSameCompanySelectionValue>[] = [];

  compareWith: (a: UserSameCompanySelectionValue, b: UserSameCompanySelectionValue) => boolean = (a, b) => {
    return (!!a && !!b && a?.UserId === b?.UserId);
  };


  formControl!: FormControl<UserSameCompanySelectionValue>;
  formGroup!: FormGroup<{
    users: FormControl<UserSameCompanySelectionValue>
  }>;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salesforceId'] ) {
      this.reload();
    }
  }

  mark(): void {
  }

  reload() {
    console.log('******* reload', this.salesforceId);
    if (this.salesforceId) {
      this.subscriptions.push(this.getFunc(this.salesforceId).pipe(tap(r => {
        this.users = r ?? [];
        if (this.formControl) {
          this.formControl.setValue(null);
        }
      })).subscribe());
    } else {
      this.users = [];
      if (this.formControl) {
        this.formControl.setValue(null);
      }
    }
  }

  doWriteValue(v: UserSameCompanySelectionValue | null | undefined): void | UserSameCompanySelectionValue {
    if (v) {
      if (this.salesforceId) {
        this.subscriptions.push(this.getFunc(this.salesforceId).pipe(tap(r => {
          this.users = r ?? [];
          const us = this.users.find(u => u.UserId == v.UserId) ?? null;
          this.formControl.setValue(us);
        })).subscribe());
      }
    }
    return undefined;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control(null, this.isMandatory ? [Validators.required] : []);
    this.formGroup = this.formBuilder.group({
      users:  this.formControl,
    });
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(this.formControl.value);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

}
