import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  BrokerageSelectionType,
  BrokerageSelectionValue,
  BrokerageSelectionValueOptions,
  compareMatch
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@UntilDestroy()
@Component({
    selector: 'brokerage-selection',
    templateUrl: './brokerage-selection.component.html',
    styleUrls: ['./brokerage-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BrokerageSelectionComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => BrokerageSelectionComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule]
})
export class BrokerageSelectionComponent extends AbstractControlValueAccessor<BrokerageSelectionValue> implements OnInit, Mark {

  options = BrokerageSelectionValueOptions;

  formGroup: FormGroup;
  formControl: FormControl<BrokerageSelectionValue>;
  subscription: Subscription;

  @Input({required: false}) max: number | null = null;
  @Input({required: false}) default: BrokerageSelectionType = '4.0';

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      selection: this.formControl,
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: BrokerageSelectionValue) => {
        this.propagateChange(r);
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    if (this.max) {
      this.options = BrokerageSelectionValueOptions.filter(item => parseFloat(item.type) <= this.max!);
    } else {
      this.options = BrokerageSelectionValueOptions;
    }
  }

  doWriteValue(v: BrokerageSelectionValue | undefined): void | BrokerageSelectionValue {
    const toSelect = this.options.find(x => x.type === this.default);
    let defaultValue = toSelect;
    if (v) {
      const value = this.options.find(x => x.name == v?.name);
      defaultValue = value ? value : toSelect;
    }
    if (defaultValue) {
      this.formControl.setValue(defaultValue);
      setTimeout(() => this.propagateChange(defaultValue), 1000);
    }else{
      this.formControl.setValue(null)
    }
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
