import {Component, inject, Input, OnInit, ViewChild} from "@angular/core";
import {
  calcApplicationLvrCalculatorValue,
  CurrencyInputValue,
  GetLvrCalculatorValueFn,
  LvrCalculatorValue,
  UpdateLvrCalculatorValueFn
} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {debounceTime, skip, Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";

import {MatTable, MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CurrencyInputComponent} from "../currency-selection-component/currency-input.component";
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from "../component-utils";
import {CdkTableModule} from "@angular/cdk/table";
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout/flex";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {PortalHotToastService} from "../portal-hot-toast-component/hot-toast.service";


export type AssetFormControls = {
  descriptionFormControl: FormControl<string | null>,
  valueFormControl: FormControl<CurrencyInputValue>,
};
export type LiabilityFormControls = {
  descriptionFormControl: FormControl<string | null>,
  valueFormControl: FormControl<CurrencyInputValue>,
};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'lvr-calculator',
    templateUrl: './lvr-calculator.component.html',
    styleUrls: ['./lvr-calculator.component.scss'],
    standalone: true,
    imports: [
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyInputComponent,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    CdkTableModule,
    MatCardModule,
    FlexModule,
    LooseCurrencyPipe
],
})
export class LvrCalculatorComponent implements OnInit {

    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;

    assetDisplayedColumns = ['assetDescription', 'assetValue', 'actions' ];
    liabilitiesDisplayedColumns = ['liabilityDescription', 'liabilityValue', 'actions' ];

    @ViewChild("liabilitiesTable") liabilitiesTable!: MatTable<LiabilityFormControls>;
    @ViewChild("assetTable") assetTable!: MatTable<AssetFormControls>;

    @Input({required: true}) getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
    @Input({required: true}) updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;
    @Input({required: true}) applicationId!: number;

    subscriptions: Subscription[] = [];

    assets: AssetFormControls[] = [];
    liabilities: LiabilityFormControls[] = [];
    totalAssets: number = 0;
    totalLiabilities: number = 0;

    hotToastService!: PortalHotToastService;

    formBuilder: FormBuilder;
    formGroup: FormGroup<{
        [k: string]:
          AssetFormControls['descriptionFormControl'] | AssetFormControls['valueFormControl'] |
          LiabilityFormControls['descriptionFormControl'] | LiabilityFormControls['valueFormControl'],
        proposedLoan: FormControl<CurrencyInputValue>,
    }>; // assets and liabilities;
    formControlCurrentLvr: FormControl<number | null>;
    formControlProposedLoan: FormControl<CurrencyInputValue>;
    formControlProposedLvr: FormControl<number | null>;


    constructor() {
      this.formBuilder = inject(FormBuilder);
      this.hotToastService = inject(PortalHotToastService);
      this.formControlProposedLoan = this.formBuilder.control(null, [Validators.required]);
      this.formControlCurrentLvr = this.formBuilder.control(null);
      this.formControlCurrentLvr.disable();
      this.formControlProposedLvr = this.formBuilder.control(null);
      this.formControlProposedLvr.disable();
      this.formGroup = this.formBuilder.group({
          proposedLoan: this.formControlProposedLoan
      });
    }

    ngOnInit(): void {
        setupUntilDestroy(this);
        this.subscriptions.push(this.getLvrCalculatorValueFn(this.applicationId).pipe(
            tap(r => {
              (r.assets ?? []).map(asset => {
                this.addAsset(asset);
              });
              (r.liabilities ?? []).map(liability => {
                this.addLiability(liability);
              });
              this.totalAssets = r.totalAssets;
              this.totalLiabilities = r.totalLiabilities;
              this.formControlCurrentLvr.setValue(r.currentLvrPercent);
              this.formControlProposedLvr.setValue(r.proposedLvrPercent);
              this.formControlProposedLoan.setValue(r.proposedLoan);


              this.subscriptions.push(this.formGroup.valueChanges.pipe(
                tap(r => {
                  // console.log('*** form group value changes', this.formGroup, this.formGroup.valid);
                  if (this.formGroup.valid) {
                    this.calc();
                  }
                }),
                debounceTime(2000),
                skip(1), // skip the first trigger caused by ngInit() populating asset & liabilities formControls
                tap(r => {
                  if (this.formGroup.valid) {
                    console.log('***** ready to save', this.formControlProposedLoan.value);
                    this.updateLvrCalculatorValueFn(this.applicationId, {
                      assets: this.assets.map(a => ({ description: a.descriptionFormControl.value ?? '', value: a.valueFormControl.value ?? 0 })),
                      liabilities: this.liabilities.map(l => ({ description: l.descriptionFormControl.value ?? '', value: l.valueFormControl.value ?? 0 })),
                      proposedLoan: this.formControlProposedLoan.value ?? 0,
                    }).pipe(
                      this.hotToastService.snackBarObservable(`Auto saved!`),
                    ).subscribe();
                  }
                })
              ).subscribe());
            }),
        ).subscribe());
    }

    addAsset(asset?: LvrCalculatorValue['assets'][number]) {
      const assetPlusFormControl: AssetFormControls = asset ? {
        descriptionFormControl: this.formBuilder.control(asset.description, [Validators.required]),
        valueFormControl: this.formBuilder.control(asset.value, [Validators.required])
      } : {
        descriptionFormControl: this.formBuilder.control(null, [Validators.required]),
        valueFormControl: this.formBuilder.control(null, [Validators.required])
      };
      const length = this.assets.push(assetPlusFormControl);
      const index = length - 1;
      this.formGroup.addControl(`asset-description-${index}`, assetPlusFormControl.descriptionFormControl, {emitEvent: false});
      this.formGroup.addControl(`asset-value-${index}`, assetPlusFormControl.valueFormControl, {emitEvent: false});
      this.assetTable.renderRows();
      this.formGroup.updateValueAndValidity();
    }

    removeAsset(asset: AssetFormControls) {
      const index = this.assets.findIndex((a) => a == asset);
      this.assets = this.assets.filter(a => a != asset);
      if (index >= 0) {
        (this.formGroup as any).removeControl(`asset-description-${index}`);
        (this.formGroup as any).removeControl(`asset-value-${index}`);
      }
      this.assetTable.renderRows();
      this.formGroup.updateValueAndValidity();
    }

    addLiability(liability?: LvrCalculatorValue['liabilities'][number]) {
      const liabilityPlusFormControls: LiabilityFormControls = liability ? {
        descriptionFormControl: this.formBuilder.control(liability.description, [Validators.required]),
        valueFormControl: this.formBuilder.control(liability.value, [Validators.required])
      } : {
        descriptionFormControl: this.formBuilder.control(null, [Validators.required]),
        valueFormControl: this.formBuilder.control(null, [Validators.required])
      };
      const length = this.liabilities.push(liabilityPlusFormControls);
      const index = length - 1;
      this.formGroup.addControl(`liability-description-${index}`, liabilityPlusFormControls.descriptionFormControl, {emitEvent: false});
      this.formGroup.addControl(`liability-value-${index}`, liabilityPlusFormControls.valueFormControl, {emitEvent: false});
      this.liabilitiesTable.renderRows();
      this.formGroup.updateValueAndValidity();
    }

    removeLiability(liability: LiabilityFormControls) {
      const index = this.liabilities.findIndex((l) => l == liability);
      this.liabilities = this.liabilities.filter(l => l != liability);
      if (index >= 0) {
        (this.formGroup as any).removeControl(`liability-description-${index}`);
        (this.formGroup as any).removeControl(`liability-value-${index}`);
      }
      this.liabilitiesTable.renderRows();
      this.formGroup.updateValueAndValidity();
    }

    // calculate the computed values based on assets and liabilities
    calc() {
      const currentProposedLoan = this.formControlProposedLoan.value;
      const {
        totalLiabilities,
        totalAssets,
        currentLvrPercent,
        proposedLvrPercent,
        proposedLoan
      } = calcApplicationLvrCalculatorValue({
        assets: this.assets.map(a => ({
          value: a.valueFormControl.value ?? 0,
          description: a.descriptionFormControl.value ?? '',
        })),
        liabilities: this.liabilities.map(l => ({
          value: l.valueFormControl.value ?? 0,
          description: l.descriptionFormControl.value ?? '',
        })),
        proposedLoan: currentProposedLoan ?? 0,
      });
      this.totalAssets = totalAssets;
      this.totalLiabilities = totalLiabilities;
      this.formControlCurrentLvr.setValue(currentLvrPercent);
      this.formControlProposedLvr.setValue(proposedLvrPercent);
    }
}
