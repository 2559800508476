import {inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import {
  navigationUrlForNewApplication,
} from '../service/navigation-urls';
import { getUser } from '@portal-workspace/grow-ui-library';

export const validTransactionsGuard = () => {
  return async (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser();
    if (user) {
      console.log("user?.priviledges",user?.priviledges.includes('operations24') || user?.priviledges.includes('operations'))
      if(user?.priviledges.includes('operations24') || user?.priviledges.includes('operations') || user?.priviledges.includes('admin')){
        return true;
      }
      return router.navigate(navigationUrlForNewApplication())
    }
    return true;
  }
}




